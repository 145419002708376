import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import SendIcon from '@mui/icons-material/Send';
import TimerIcon from '@mui/icons-material/Timer';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import { Box } from '@mui/material';
import React from 'react';

const iconSx = {
  transform: 'scale(0.9)',
  position: 'relative',
  top: '6px',
  right: '2px',
};

const ExceptionalActivitiesStatus = (asManager = false, status = '') => {
  const pickIcon = () => {
    switch (status) {
      case 'Requested':
        return asManager ? (
          <Box color="darkGrey.darker">
            <HourglassEmptyIcon sx={iconSx} /> À Confirmer
          </Box>
        ) : (
          <Box color="info.main">
            <SendIcon sx={iconSx} /> Demandée
          </Box>
        );
      case 'Approved':
        return (
          <Box color="orange.main">
            <TimerIcon sx={iconSx} /> À récupérer
          </Box>
        );
      case 'Lost':
        return (
          <Box color="darkRed.main">
            <TimerOffIcon sx={iconSx} /> Perdue
          </Box>
        );
      case 'Completed':
        return (
          <Box color="success.main">
            <DoneIcon sx={iconSx} /> Récupérée
          </Box>
        );
      default:
        return (
          <Box color="error.main">
            <CloseIcon sx={iconSx} /> Refusée
          </Box>
        );
    }
  };

  return <Box sx={{ position: 'relative', bottom: '5px' }}>{pickIcon()}</Box>;
};

export default ExceptionalActivitiesStatus;
