import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Typography } from '@mui/material';
import PageWrapper from 'components/commons/PageWrapper';
import PongoButton from 'components/MUIOverload/PongoButton';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

interface ErrorPageProps {
  text: string;
  logo: ReactElement;
}

const ErrorPage = (properties: ErrorPageProps) => {
  const history = useHistory();

  function RedirectToHome() {
    history.push('/home');
  }

  return (
    <PageWrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 20,
        }}
      >
        <Box
          sx={{
            width: '150px',
            height: '150px',
            color: 'primary.main',
          }}
        >
          {properties.logo}
        </Box>
        <Typography
          variant="h3Bold"
          component="h3"
          sx={{
            lineHeight: '30px',
            textTransform: 'uppercase',
            pt: 5,
            color: 'primary.main',
          }}
        >
          {properties.text}
        </Typography>
        <PongoButton
          sx={{
            mt: 1,
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={RedirectToHome}
        >
          <ArrowBackIcon sx={{ mr: 0.5 }} />
          <span>{"Retour à la page d'accueil"}</span>
        </PongoButton>
      </Box>
    </PageWrapper>
  );
};

ErrorPage.defaultProps = {
  text: "Vous n'êtes pas autorisé à accéder à cette page",
  logo: <CancelIcon sx={{ width: '100%', height: '100%' }} />,
};

export default ErrorPage;
