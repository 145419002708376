import { Search } from '@mui/icons-material';
import { Autocomplete, Box, InputAdornment, TextField } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { setBillNumberFilter } from 'store/purchaseOrder';

import styles from './styles/Filters.module.scss';

interface FilterBillNumberProps {
  filterWidth: number;
}

export default function FilterBillNumber({
  filterWidth,
}: FilterBillNumberProps) {
  const dispatch = useDispatch();
  const allBills = useSelector((state) => state.purchaseOrder.allBills);
  const billNumberFilter = useSelector(
    (state) => state.purchaseOrder.billNumberFilter
  );
  const options: string[] = _.sortBy(
    _.flatMap(allBills, (bill) => bill.billNumber || []),
    [
      (o) => {
        return Number.parseInt(o);
      },
    ]
  );

  return (
    <Autocomplete
      autoHighlight
      autoComplete
      id="filter-select-mission"
      value={billNumberFilter}
      options={options}
      className={styles.filterSelect}
      onChange={(event, value: string | null) => {
        dispatch(setBillNumberFilter(value || ''));
      }}
      renderOption={(props, option) => (
        <Box component={'li'} {...props}>
          {option}
        </Box>
      )}
      getOptionLabel={(option) => {
        return option ? option : '';
      }}
      sx={{ width: filterWidth }}
      renderInput={(parameters) => (
        <TextField
          variant="standard"
          label={'Numéro de facture'}
          {...parameters}
          InputProps={{
            ...parameters.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
