import UpdateStatusFooter from 'components/MissionFollowUp/BillDetail/BillDetail/UpdateStatusFooter';
import GroupEditButtons from 'components/MissionFollowUp/OverallListBill/GroupEditButtons/index';
import {
  BillNode,
  PurchaseOrderNode,
  useAllBillsPaginatedLazyQuery,
  useFetchBillsOfPurchaseOrderLazyQuery,
} from 'generated/graphql';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'store';
import {
  setAllBills,
  setCurrentPurchaseOrder,
  setGroupEdit,
  setSelectedBills,
} from 'store/purchaseOrder';

import { useBillsTableContext } from '../TablePaginationListBills/BillsTableContextProvider';

interface StatusGroupEditButtonsProps {
  isInActionsMenu?: boolean;
}

export default function StatusGroupEditButtons({
  isInActionsMenu = false,
}: StatusGroupEditButtonsProps) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { page, rowsPerPage } = useBillsTableContext();
  const groupEdit = useSelector((state) => state.purchaseOrder.groupEdit);
  const selectedMissions = useSelector(
    (state) => state.activity.selectedMissions
  );
  const filterStatus = useSelector((state) => state.purchaseOrder.statusFilter);
  const directorFilter = useSelector((state) => state.users.directorFilter);
  const creatorFilter = useSelector((state) => state.users.creatorFilter);

  const [refreshAllBills] = useAllBillsPaginatedLazyQuery({
    onCompleted: (data) => {
      dispatch(setAllBills(data.allBillsPaginated?.bills as BillNode[]));
    },
    onError: () => {
      enqueueSnackbar(`Erreur lors de la récupération des données`, {
        variant: 'error',
      });
    },
  });

  const { search } = useLocation();
  const poId = new URLSearchParams(search).get('po_id');

  const [updateStorePurchaseOrder] = useFetchBillsOfPurchaseOrderLazyQuery({
    variables: { purchaseOrderId64: poId ?? '' },
    onCompleted: (data) => {
      const purchaseOrder = data.purchaseOrder as PurchaseOrderNode;
      dispatch(
        setCurrentPurchaseOrder({
          ...purchaseOrder,
          bills: purchaseOrder.bills?.map((bill) => ({
            ...bill,
            purchaseOrder,
          })),
        } as PurchaseOrderNode)
      );
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const callBack = () => {
    refreshAllBills({
      variables: {
        status: filterStatus,
        activityIds: _.map(selectedMissions, 'id'),
        directorId: directorFilter?.id,
        creatorId: creatorFilter?.id,
        pageNbr: page,
        pageSize: rowsPerPage,
      },
    });
    if (poId) {
      updateStorePurchaseOrder();
    }
    dispatch(setGroupEdit(!groupEdit));
    dispatch(setSelectedBills([]));
  };

  const validateButton = <UpdateStatusFooter groupUpdate callBack={callBack} />;

  return (
    <GroupEditButtons
      validateButton={validateButton}
      isInActionsMenu={isInActionsMenu}
    />
  );
}
