import { Table, TableProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableHeaderContextProvider from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import {
  EmployeeNode,
  useAllEmployeesWithProviderOccupationQuery,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { displayEmployee } from 'utils';

import { useAdrContext } from './AdrContextProvider';
import ProviderAdrHistoryTableBody from './ProviderAdrHistoryTableBody';
import ProviderAdrHistoryTableHeader from './ProviderAdrHistoryTableHeader';
import ProviderAdrTableBody from './ProviderAdrTableBody';
import ProviderAdrTableHeader from './ProviderAdrTableHeader';

export const StyledAdrTable = styled(Table)<TableProps>(({ theme }) => ({
  '.MuiTableRow-root': {
    height: '48px',
    border: `solid`,
    borderWidth: '0 1px 0 1px',
    borderColor: `${theme.palette.darkGrey.dark}`,
  },
  '.MuiTableCell-root': {
    height: 1,
  },
  '@-moz-document url-prefix()': {
    '.MuiTableCell-root': {
      height: '100%',
    },
  },
  border: `solid`,
  borderWidth: '1px 1px 1px 1px',
  borderColor: `${theme.palette.darkGrey.dark}`,
  tableLayout: 'fixed',
}));

export default function ProviderAdrTable() {
  const { year, isShowingHistory } = useAdrContext();
  const currentYear = moment().year();
  const isCurrentYear = year === currentYear;

  const { data, refetch } = useAllEmployeesWithProviderOccupationQuery({
    variables: { year },
  });

  const providers: EmployeeNode[] =
    data?.allEmployeesWithProviderOccupation as EmployeeNode[];
  const sortedProviders = _.sortBy(providers, [
    (node) => {
      return displayEmployee(node.firstName, node.lastName);
    },
  ]);

  return (
    <StyledAdrTable size={'small'}>
      {isShowingHistory ? (
        <TableHeaderContextProvider
          currentYear={isCurrentYear ? moment() : moment(String(year))}
          monthOffset={-4}
        >
          <ProviderAdrHistoryTableHeader />
          <ProviderAdrHistoryTableBody providers={sortedProviders} />
        </TableHeaderContextProvider>
      ) : (
        <>
          <ProviderAdrTableHeader />
          <ProviderAdrTableBody
            providers={sortedProviders}
            refetchProviders={refetch}
          />
        </>
      )}
    </StyledAdrTable>
  );
}
