import { Box, Tab, Tabs } from '@mui/material';
import { createCsvFile } from 'components/commons/PolyExportButton';
import PolyModal from 'components/MUIOverload/PolyDialog/PolyModal';
import { EmployeeOccupations, useUserInfo } from 'components/User/UserProvider';
import {
  ActivityNode,
  List_Field_Bill_Export,
  List_Filterable_Dates,
  useExportAllAccountantBillsLazyQuery,
  useExportAllBillsLazyQuery,
  useExportAllEuBillsStateLazyQuery,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'store';

import ExportAccountantForm from './ExportAccountantForm';
import ExportBillDataForm from './ExportBillDataForm';
import ExportEuBillStateForm from './ExportEuBillStateForm';

export const billExportFieldsCorrespondance = {
  [List_Field_Bill_Export.Status]: 'Status',
  [List_Field_Bill_Export.Title]: 'Libellé facture',
  [List_Field_Bill_Export.Mission]: 'Mission',
  [List_Field_Bill_Export.CorporateName]: 'Client à facturer',
  [List_Field_Bill_Export.BilledMonth]: 'Mois facturé(s)',
  [List_Field_Bill_Export.Date]: 'À facturer le',
  [List_Field_Bill_Export.BillingDate]: 'Facturé le',
  [List_Field_Bill_Export.DueDate]: "Date d'échéance",
  [List_Field_Bill_Export.PaymentDate]: 'Payé le',
  [List_Field_Bill_Export.Amount]: 'Montant',
  [List_Field_Bill_Export.UpdatedAt]: 'Date de mise à jour',
  [List_Field_Bill_Export.CreatedAt]: 'Date de création',
  [List_Field_Bill_Export.Creator]: 'Créateur',
  [List_Field_Bill_Export.Director]: 'Directeur de mission',
  [List_Field_Bill_Export.Chief]: 'Chef de mission',
  [List_Field_Bill_Export.BillNumber]: 'Numéro de facture',
  [List_Field_Bill_Export.PoId]: 'Identifiant du bon de commande',
  [List_Field_Bill_Export.PoName]: 'Nom du bon de commande',
  [List_Field_Bill_Export.PoNumber]: 'Numéro du bon de commande',
  [List_Field_Bill_Export.PoDate]: 'Période du bon de commande',
  [List_Field_Bill_Export.PoCreationDate]:
    'Date de création du bon de commande',
  [List_Field_Bill_Export.PoUpdateDate]:
    'Date de mise à jour du bon de commande',
  [List_Field_Bill_Export.PoCurrency]: 'Monnaie du bon de commande',
  [List_Field_Bill_Export.PoTotal]: 'Total du bon de commande',
  [List_Field_Bill_Export.ContactName]: 'Nom du contact',
  [List_Field_Bill_Export.ContactEmail]: 'Email du contact',
  [List_Field_Bill_Export.ContactPhone]: 'Téléphone du contact',
  [List_Field_Bill_Export.AccountantName]: 'Nom du contact comptable',
  [List_Field_Bill_Export.AccountantEmail]: 'Email du contact comptable',
  [List_Field_Bill_Export.AccountantPhone]: 'Téléphone du contact comptable',
  [List_Field_Bill_Export.MarketRef]: 'Référence',
  [List_Field_Bill_Export.CollaboratorsDays]:
    'Collaborateur(s) et nombre de jours de la facture',
  [List_Field_Bill_Export.Collaborators]: 'Collaborateur(s) du bon de commande',
  [List_Field_Bill_Export.BillingModalities]: 'Modalité(s) de facturation',
};

const initialState = {
  exportBillData: {
    listFields: Object.values(List_Field_Bill_Export),
    dateToFilter: List_Filterable_Dates.BilledMonth,
    dateRange: [
      moment().startOf('week').toDate(),
      moment().endOf('week').toDate(),
    ] as [Date, Date],
  },
  exportUeBillState: {
    month: moment().startOf('month').toDate(),
  },
  exportAccountant: {
    month: moment().startOf('month').toDate(),
  },
};

const TabsExportBill = [
  {
    label: 'Export données Factures',
    value: 0,
    component: <ExportBillDataForm />,
    users_with_access: [],
  },
  {
    label: 'Export comptable',
    value: 1,
    component: <ExportAccountantForm />,
    users_with_access: [EmployeeOccupations.CONTROL, EmployeeOccupations.DG],
  },
  {
    label: 'Export état factures UE',
    value: 2,
    component: <ExportEuBillStateForm />,
    users_with_access: [EmployeeOccupations.CONTROL, EmployeeOccupations.DG],
  },
];

interface ExportBillsModalProps {
  isOpen: boolean;
  handleClose: () => void;
  title: string;
  isPayment?: boolean;
}

export default function ExportBillsModal({
  isOpen,
  handleClose,
  title,
  isPayment,
}: ExportBillsModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  function showExportErrorSnackBar() {
    enqueueSnackbar(`Erreur lors du téléchargement des données`, {
      variant: 'error',
    });
  }
  const {
    filterStatus,
    filterPaymentStatus,
    toExportMissions,
    directorFilter,
    creatorFilter,
    billNumberFilter,
  } = useExportBillFilter();

  const { isDG, isControl, isSuperuser } = useUserInfo();
  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const form = useForm({
    defaultValues: initialState,
    shouldUnregister: false,
  });

  const [exportAllBillsQuery] = useExportAllBillsLazyQuery({
    onCompleted: (data) => {
      if (data && data.exportAllBills) {
        createCsvFile(data.exportAllBills, 'factures_polyconseil.csv');
      }
    },
    onError: () => {
      showExportErrorSnackBar();
    },
  });

  const [exportAllAccountantBillsQuery] = useExportAllAccountantBillsLazyQuery({
    onCompleted: (data) => {
      if (data && data.exportAllAccountantBills) {
        createCsvFile(
          data.exportAllAccountantBills,
          'factures_comptable_polyconseil.csv'
        );
      }
    },
    onError: () => {
      showExportErrorSnackBar();
    },
  });

  const [exportAllEuBillsStateQuery] = useExportAllEuBillsStateLazyQuery({
    onCompleted: (data) => {
      if (data && data.exportAllEuBillsState) {
        createCsvFile(
          data.exportAllEuBillsState,
          'factures_ue_polyconseil.csv'
        );
      }
    },
    onError: () => {
      showExportErrorSnackBar();
    },
  });

  const onClickExport = () => {
    const tabsForm = form.getValues();
    switch (tabValue) {
      case 0:
        exportAllBillsQuery({
          variables: {
            dateFrom: moment(tabsForm.exportBillData.dateRange[0]).format(
              'YYYY-MM-DD'
            ),
            dateTo: moment(tabsForm.exportBillData.dateRange[1]).format(
              'YYYY-MM-DD'
            ),
            status: filterStatus,
            paymentStatus: filterPaymentStatus,
            activityIds: toExportMissions,
            directorId: directorFilter?.id,
            creatorId: creatorFilter?.id,
            billNumber: billNumberFilter,
            isPayment: isPayment,
            listFields: tabsForm.exportBillData.listFields,
            dateToFilter: tabsForm.exportBillData.dateToFilter,
          },
        });
        break;
      case 1:
        exportAllAccountantBillsQuery({
          variables: {
            month: moment(tabsForm.exportAccountant.month).format('YYYY-MM-DD'),
          },
        });
        break;
      case 2:
        exportAllEuBillsStateQuery({
          variables: {
            month: moment(tabsForm.exportUeBillState.month).format(
              'YYYY-MM-DD'
            ),
          },
        });
        break;
      default:
        break;
    }
  };

  return (
    <FormProvider {...form}>
      <PolyModal
        open={isOpen}
        onClose={handleClose}
        polyDialogTitle={title}
        handlePrimary={onClickExport}
        primaryButtonName={'Télécharger'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEnforceFocus={true}
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: '700px',
            width: '660px',
          },
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="Export factures"
              sx={{
                textTransform: 'capitalize',
              }}
            >
              {TabsExportBill.map((tab, index) => {
                const isUserHasAccess = checkAccessToTab(
                  { isDG, isControl, isSuperuser },
                  index
                );
                if (!isUserHasAccess) {
                  return null;
                }
                return (
                  <Tab
                    key={index}
                    label={tab.label}
                    {...a11yProps(tab.value)}
                  />
                );
              }).filter(Boolean)}
            </Tabs>
          </Box>
          <Box sx={{ height: '200px', pt: 2 }}>
            {TabsExportBill[tabValue].component}
          </Box>
        </Box>
      </PolyModal>
    </FormProvider>
  );
}

function useExportBillFilter() {
  const filterStatus = useSelector((state) => state.purchaseOrder.statusFilter);
  const filterPaymentStatus = useSelector(
    (state) => state.purchaseOrder.statusPaymentFilter
  );
  const currentMission = useSelector((state) => state.activity.currentMission);
  const selectedMissions = useSelector(
    (state) => state.activity.selectedMissions as ActivityNode[]
  );
  const toExportMissions = currentMission?.id
    ? [currentMission.id]
    : _.map(selectedMissions, (mission) => mission.id);

  const directorFilter = useSelector((state) => state.users.directorFilter);
  const creatorFilter = useSelector((state) => state.users.creatorFilter);
  const billNumberFilter = useSelector(
    (state) => state.purchaseOrder.billNumberFilter
  );
  return {
    filterStatus,
    filterPaymentStatus,
    toExportMissions,
    directorFilter,
    creatorFilter,
    billNumberFilter,
  };
}

function a11yProps(index: number) {
  return {
    id: `export-bill-${index}`,
  };
}

function checkAccessToTab(
  userInfos: { isDG: boolean; isControl: boolean; isSuperuser: boolean },
  tabValue: number
) {
  if (TabsExportBill[tabValue].users_with_access.length === 0) {
    return true;
  }
  const isDGHasAccess =
    TabsExportBill[tabValue].users_with_access.includes(
      EmployeeOccupations.DG
    ) && userInfos.isDG;
  const isControlHasAccess =
    TabsExportBill[tabValue].users_with_access.includes(
      EmployeeOccupations.CONTROL
    ) && userInfos.isControl;

  return isDGHasAccess || isControlHasAccess || userInfos.isSuperuser;
}
