import { Checkbox } from '@mui/material';
import { TableCellProps } from '@mui/material/TableCell/TableCell';
import React, { ReactElement } from 'react';

import PolyTableCell from '../PolyTableCell';
import PolyTableHead from '../PolyTableHead';
import PolyTableRow from '../PolyTableRow';
import PolyTableSortLabel from '../PolyTableSortLabel';
import { usePolyDataTableContext } from './context/PolyDataGridContextProvider';
import styles from './styles/PolyDataGrid.module.scss';

export interface PolyDataGridColumn<T> {
  headerName: string;
  propertyName?: string | string[];
  isSortable?: boolean;
  align?: TableCellProps['align'];
  render: (data: T) => ReactElement;
  width?: string;
}

interface PolyDataGridHeadProps<T> {
  columns: PolyDataGridColumn<T>[];
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: string | string[]
  ) => void;
  orderBy?: string | string[];
  order?: 'asc' | 'desc';
  isCheckable?: boolean;
}

export default function PolyDataGridHead<T>({
  columns,
  onRequestSort,
  orderBy,
  order,
  isCheckable,
}: PolyDataGridHeadProps<T>) {
  const { setHasGlobalChecked, checkedRows, checkableRows } =
    usePolyDataTableContext();

  return (
    <PolyTableHead>
      <PolyTableRow>
        {isCheckable && (
          <PolyTableCell key={'checked'} className={styles.checkHead}>
            <Checkbox
              className={styles.checkBox}
              color={'primary'}
              indeterminate={
                checkedRows.size > 0 && checkedRows.size !== checkableRows.size
              }
              checked={checkedRows.size > 0}
              onChange={(event, checked) => {
                setHasGlobalChecked({ checked: checked });
              }}
            />
          </PolyTableCell>
        )}
        {columns.map(({ isSortable = true, ...column }, index) => {
          const isActive = orderBy ? orderBy === column.propertyName : false;

          return (
            <PolyTableCell
              key={index}
              style={{ width: column.width }}
              align={column.align}
            >
              <PolyTableSortLabel
                active={isActive}
                direction={isActive ? order : undefined}
                onClick={(event) =>
                  onRequestSort(event, column.propertyName || column.headerName)
                }
                disabled={!isSortable}
                style={{ width: column.width }}
              >
                {column.headerName}
              </PolyTableSortLabel>
            </PolyTableCell>
          );
        })}
      </PolyTableRow>
    </PolyTableHead>
  );
}
