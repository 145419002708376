import { Box, Link, Stack, Typography } from '@mui/material';
import { AppItem } from 'pages/ApplicationsPage';
import React from 'react';

interface ApplicationCardProps {
  application: AppItem;
}

const ApplicationCard = ({ application }: ApplicationCardProps) => {
  return (
    <Link target="_blank" rel="noopener noreferrer" href={application.url}>
      <Stack
        sx={{
          border: '2px solid',
          borderColor: 'standardGrey.dark',
          borderRadius: '8px',
          py: 3,
          px: 2.5,
          gap: 1.5,
          height: 140,
          transition: 'background-color .6s ease-out 100ms, transform .3s',
          '&:hover': {
            backgroundColor: 'primary.light',
            transform: 'scale(1.015)',
          },
        }}
      >
        <Box
          sx={{
            overflow: 'hidden',
            borderRadius: '8px',
            width: 42,
            height: 42,
            backgroundColor: 'standardGrey.dark',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            component="img"
            src={application.icon}
            alt={application.name}
            height={30}
          />
        </Box>
        <Typography variant="bodySemiBold" pt={1}>
          {application.name}
        </Typography>
        <Typography variant="bodyS" color="text.secondary">
          {application.description}
        </Typography>
      </Stack>
    </Link>
  );
};

export default ApplicationCard;
