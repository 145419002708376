import {
  ActivitiesActivityBillingTypeChoices,
  BillingActivityInformationNode,
} from 'generated/graphql';
import _ from 'lodash';
import moment, { Moment } from 'moment';

const hasPurchaseOrderLastMonth = (
  billingMission: BillingActivityInformationNode
): boolean => {
  return _.some(billingMission.purchaseOrders, (po) => {
    return moment(po.periodEnding).isSame(
      billingMission.activity?.expirationDate,
      'month'
    );
  });
};

const shouldDisplayOneMonthAfterExpiration = (
  billingMission: BillingActivityInformationNode
): boolean | undefined => {
  return (
    billingMission.activity?.billingType ===
      ActivitiesActivityBillingTypeChoices.Tm &&
    hasPurchaseOrderLastMonth(billingMission)
  );
};

export function getMissionLastActiveMonth(
  billingMission: BillingActivityInformationNode
): Moment {
  return shouldDisplayOneMonthAfterExpiration(billingMission)
    ? moment(billingMission.activity?.expirationDate).add(1, 'M')
    : moment(billingMission.activity?.expirationDate);
}
