import { HighlightOffOutlined } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ExceptionalActivitiesDotButton from 'components/ExceptionalActivities/ExceptionalActivitiesDotButton';
import stylesDelete from 'components/ExceptionalActivities/ExceptionalActivitiesDotButton/styles/ButtonDelete.module.scss';
import stylesEdit from 'components/ExceptionalActivities/ExceptionalActivitiesDotButton/styles/ButtonEdit.module.scss';
import stylesSee from 'components/ExceptionalActivities/ExceptionalActivitiesDotButton/styles/ButtonSee.module.scss';
import { DotOption } from 'components/ExceptionalActivities/ExceptionalActivitiesDotButton/utils';
import DateCell from 'components/MUIOverload/PolyDataGrid/components/DateCell';
import DurationCell from 'components/MUIOverload/PolyDataGrid/components/DurationCell';
import ExceptionalActivitiesStatus from 'components/MUIOverload/PolyDataGrid/components/ExceptionalActivitiesStatus/ExceptionalActivitiesStatus';
import StringCell from 'components/MUIOverload/PolyDataGrid/components/StringCell';
import UndefinedCell from 'components/MUIOverload/PolyDataGrid/components/UndefinedCell/UndefinedCell';
import { PolyDataGridColumn } from 'components/MUIOverload/PolyDataGrid/PolyDataGridHead';
import {
  OvertimeRequestsQuery,
  useDeleteOvertimeRequestMutation,
  useOvertimeRequestsLazyQuery,
} from 'generated/graphql';
import { useSnackbar } from 'notistack';
import { ExceptionalActivitiesTitles } from 'poly-constants';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { displayEmployee } from 'utils';

import { isUndefined } from '../utils';
import ExceptionalActivitiesRequestPanel from './ExceptionalActivitiesRequestPanel';
import {
  AuthorizationDataType,
  RequestLookupState,
} from './ExceptionalActivitiesRequestPanelManager';

const body =
  "Vous consultez ici le statut de vos demandes d’activités exceptionnelles et les temps de récupération associés.\nÀ compter de la date de réalisation de l'activité exceptionnelle, vous avez 30 jours calendaires pour effectuer votre récupération.\nTant que votre manager n'a pas validé votre demande, il vous est possible de la modifier ou de la supprimer.";
const getColumns = (
  handleEdit: (data: AuthorizationDataType) => void,
  handleDelete: (data: AuthorizationDataType) => Promise<void>,
  handleSee: (data: AuthorizationDataType) => void
) => {
  const columns: PolyDataGridColumn<
    DeepExtractTypeSkipArrays<OvertimeRequestsQuery, ['overtimeRequests']>
  >[] = [
    {
      headerName: 'Date demande',
      isSortable: false,
      width: '90px',
      render: (data) => DateCell({ date: data.createdAt }),
    },
    {
      headerName: 'Jour travaillé',
      isSortable: false,
      width: '90px',
      render: (data) => DateCell({ date: data.overtimeDateAt }),
    },
    {
      headerName: 'Manager',
      isSortable: false,
      width: '130px',
      render: (data) =>
        StringCell({
          data: displayEmployee(
            data.authorization.manager.firstName,
            data.authorization.manager.lastName
          ),
        }),
    },
    {
      headerName: 'Mission',
      isSortable: false,
      width: '200px',
      render: (data) => StringCell({ data: data.authorization.activity.name }),
    },
    {
      headerName: 'Durée',
      isSortable: false,
      width: '40px',
      render: (data) => DurationCell({ duration: data.duration }),
    },
    {
      headerName: 'Tps de récup',
      isSortable: false,
      width: '80px',
      render: (data) =>
        UndefinedCell({
          isUndefined: isUndefined(data.status),
          render: () => DurationCell({ duration: data.recoveryRemaining }),
        }),
    },
    {
      headerName: 'Date limite',
      isSortable: false,
      width: '80px',
      render: (data) =>
        UndefinedCell({
          isUndefined: isUndefined(data.status),
          render: () => DateCell({ date: data.expireAt }),
        }),
    },
    {
      headerName: 'Statut',
      isSortable: false,
      render: (data) => ExceptionalActivitiesStatus(false, data.status),
    },
    {
      headerName: '',
      isSortable: false,
      render: (data) =>
        ExceptionalActivitiesDotButton({
          data: data,
          options:
            data.status === 'Requested' ? toConfirmDotOptions : otherDotOptions,
        }),
    },
  ];

  const toConfirmDotOptions: DotOption<AuthorizationDataType>[] = [
    {
      name: 'Modifier',
      handle: handleEdit,
      Icon: EditIcon,
      styles: stylesEdit,
    },
    {
      name: 'Supprimer',
      handle: handleDelete,
      hasModalVerification: true,
      modalTitle: 'Suppression de la demande',
      modalBody: 'Êtes-vous sûr(e) de vouloir supprimer la demande ?',
      Icon: HighlightOffOutlined,
      styles: stylesDelete,
    },
  ];

  const otherDotOptions: DotOption<AuthorizationDataType>[] = [
    {
      name: 'Voir',
      handle: handleSee,
      Icon: VisibilityOutlinedIcon,
      styles: stylesSee,
    },
  ];
  return columns;
};

const ExceptionalActivitiesRequestPanelEmployee = () => {
  const history = useHistory();
  const [overtimeRequestData, setOvertimeRequestData] =
    useState<OvertimeRequestsQuery>();
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [fetchOvertimeRequest] = useOvertimeRequestsLazyQuery({
    onCompleted: (data) => {
      setOvertimeRequestData(data);
      setLoading(false);
    },
    onError: () => {
      enqueueSnackbar('Problème de récuperation des données', {
        variant: 'error',
      });
    },
  });
  useEffect(() => {
    setLoading(true);
    fetchOvertimeRequest({ variables: { asManager: false } });
  }, [fetchOvertimeRequest]);
  const [deleteOvertimeRequest] = useDeleteOvertimeRequestMutation();
  const handleEdit = (data: AuthorizationDataType) => {
    const state: RequestLookupState = {
      action: 'modify',
      query: data,
      asManager: false,
    };
    history.push('/request-lookup', state);
  };

  const handleSee = (data: AuthorizationDataType) => {
    const state: RequestLookupState = {
      action: 'see',
      query: data,
      asManager: false,
    };
    history.push('/request-lookup', state);
  };

  const handleDelete = async (
    data: DeepExtractTypeSkipArrays<OvertimeRequestsQuery, ['overtimeRequests']>
    // eslint-disable-next-line @typescript-eslint/require-await
  ) => {
    try {
      setLoading(true);
      await deleteOvertimeRequest({
        variables: {
          objectId: data.id,
        },
      });
    } catch {
      enqueueSnackbar('Problème de suppression des données', {
        variant: 'error',
      });
    } finally {
      // eslint-disable-next-line
      setOvertimeRequestData(undefined);
      fetchOvertimeRequest({ variables: { asManager: false } });
    }
  };

  return (
    <ExceptionalActivitiesRequestPanel
      title={ExceptionalActivitiesTitles.declaration}
      body={body}
      columns={getColumns(handleEdit, handleDelete, handleSee)}
      asManager={false}
      overtimeRequestsData={overtimeRequestData}
      loadingOvertimeRequests={loading}
    />
  );
};

export default ExceptionalActivitiesRequestPanelEmployee;
