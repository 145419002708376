import { Stack } from '@mui/material';
import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import TabHeader from 'components/commons/TabHeader';
import BillingTablePagination from 'components/MissionFollowUp/OverallListBill/BillingTablePagination';
import BillsAmountSum from 'components/MissionFollowUp/OverallListBill/BillsAmountSum';
import DisplayBillStatus from 'components/MissionFollowUp/OverallListBill/DisplayStatus/DisplayBillStatus';
import ExportBillsButton from 'components/MissionFollowUp/OverallListBill/ExportBillsButton';
import FilterBillNumber from 'components/MissionFollowUp/OverallListBill/Filters/FilterBillNumber';
import FilterCreator from 'components/MissionFollowUp/OverallListBill/Filters/FilterCreator';
import FilterDirector from 'components/MissionFollowUp/OverallListBill/Filters/FilterDirector';
import FilterStatusPayment from 'components/MissionFollowUp/OverallListBill/Filters/FilterPaymentStatus';
import PaymentStatusGroupEditButtons from 'components/MissionFollowUp/OverallListBill/GroupEditButtons/PaymentStatusGroupEditButtons';
import PaymentFollowUpTable from 'components/MissionFollowUp/OverallListBill/PaymentFollowUp/PaymentFollowUpTable';
import BillsTableContextProvider from 'components/MissionFollowUp/OverallListBill/TablePaginationListBills/BillsTableContextProvider';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import { useUserInfo } from 'components/User/UserProvider';
import {
  ActivityNode,
  BillingBillStatusChoices,
  BillNode,
  useAllBillsPaginatedQuery,
} from 'generated/graphql';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import InternalMissionPage from 'pages/ErrorPage/InternalMissionPage';
import { PageTitles } from 'poly-constants';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { setAllBills } from 'store/purchaseOrder';
import { setCreatorFilter, setDirectorFilter } from 'store/users';
import { checkIfAllMissionsAreInternal } from 'utils';

import styles from './styles/PaymentFollowUp.module.scss';

export default function PaymentFollowUp() {
  const SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED =
    !!useEnvironmentVariable('send_bill_email');
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { isControl, isSuperuser } = useUserInfo();
  const statusPaymentFilter = useSelector(
    (state) => state.purchaseOrder.statusPaymentFilter
  );
  const billNumberFilter = useSelector(
    (state) => state.purchaseOrder.billNumberFilter
  );
  const directorFilter = useSelector((state) => state.users.directorFilter);
  const creatorFilter = useSelector((state) => state.users.creatorFilter);
  useEffect(() => {
    dispatch(setDirectorFilter(null));
    dispatch(setCreatorFilter(null));
  }, [dispatch]);
  const currentMission = useSelector((state) => state.activity.currentMission);
  const selectedMissions = useSelector(
    (state) => state.activity.selectedMissions as ActivityNode[]
  );
  const missionIds = currentMission?.id
    ? [currentMission.id]
    : _.map(selectedMissions, (mission) => mission.id);
  const filterWidthValue = 175;
  const [nbrBills, setNbrBills] = useState(0);
  const [pageNbr, setPageNbr] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [filter, setFilter] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const { loading } = useAllBillsPaginatedQuery({
    variables: {
      // no need to filter by status, isPayment option applies the filter in the back
      isPayment: true,
      paymentStatus: statusPaymentFilter,
      activityIds: missionIds,
      billNumber: billNumberFilter,
      directorId: directorFilter?.id,
      creatorId: creatorFilter?.id,
      orderBy: filter ? [filter] : ['-billing_date'],
      pageNbr: pageNbr,
      pageSize: pageSize,
    },
    onCompleted: (data) => {
      dispatch(setAllBills(data.allBillsPaginated?.bills as BillNode[]));
      setNbrBills(data.allBillsPaginated?.billsNumber || 0);
      setTotalAmount(data.allBillsPaginated?.totalAmount || 0);
    },
    onError: () => {
      enqueueSnackbar(`Erreur lors de la récupération des données`, {
        variant: 'error',
      });
    },
  });

  if (checkIfAllMissionsAreInternal(selectedMissions)) {
    return <InternalMissionPage page={PageTitles.paymentsPage} />;
  }

  const pageDesctiption = (
    <div className={styles.text}>
      <div className={styles.description}>
        Retrouvez ici toutes les factures au statut
        <span className={styles.status}>
          <DisplayBillStatus
            status={
              SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED
                ? BillingBillStatusChoices.Sent
                : BillingBillStatusChoices.Billed
            }
          />
        </span>
      </div>
    </div>
  );

  return (
    <>
      <TabHeader
        title={PageTitles.paymentsPage}
        count={nbrBills}
        filterComponents={
          <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={1}>
              <FilterBillNumber filterWidth={filterWidthValue} />
              {(isControl || isSuperuser) && (
                <>
                  <FilterDirector filterWidth={filterWidthValue} />
                  <FilterCreator filterWidth={filterWidthValue} />
                </>
              )}
            </Stack>
            <FilterStatusPayment />
            {!!statusPaymentFilter.length && !loading && (
              <BillsAmountSum euroSum={totalAmount} />
            )}
          </Stack>
        }
        actionComponents={
          <>
            {(isControl || isSuperuser) && <PaymentStatusGroupEditButtons />}
            <ExportBillsButton isPayment={true} sx={{ ml: 1 }} />
          </>
        }
      />
      {pageDesctiption}
      {!loading ? (
        <BillsTableContextProvider>
          <div className={styles.billTable} key={'billTable'}>
            <BillingTablePagination
              totalLen={nbrBills}
              pageNbr={pageNbr}
              setPageNbr={setPageNbr}
              pageSize={pageSize}
              setPageSize={setPageSize}
              paginationLabel="Factures par page"
              sx={{
                p: 1,
                pr: 0,
              }}
            />
            <PaymentFollowUpTable
              pageNbr={pageNbr}
              pageSize={pageSize}
              filter={filter}
              setFilter={setFilter}
              setTotalAmount={setTotalAmount}
              setNbrBills={setNbrBills}
            />
          </div>
        </BillsTableContextProvider>
      ) : (
        <div className={styles.loader} key={'loader'}>
          <LoadingPlaceholder />
        </div>
      )}
    </>
  );
}
