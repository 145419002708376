import { GetAppOutlined } from '@mui/icons-material';
import { ButtonProps } from '@mui/material';
import { SxProps } from '@mui/system';
import { Buffer } from 'buffer';
import * as fileSaver from 'file-saver';
import React from 'react';

import PongoButton from '../../MUIOverload/PongoButton';

export const createCsvFile = (data: string, fileName: string) => {
  // filename must contain extension
  // for excel import compatibility
  const file_utf = Buffer.from('\uFEFF');
  const buff = Buffer.from(data, 'base64');
  const buffer = Buffer.concat([file_utf, buff]);
  const blob = new Blob([buffer], {
    type: 'text/csv;charset=utf-8"',
  });
  fileSaver.saveAs(blob, fileName);
};

export const createPdfFile = (data: string, fileName: string) => {
  // filename must contain extension
  // for excel import compatibility
  const file_utf = Buffer.from('\uFEFF');
  const buff = Buffer.from(data, 'base64');
  const buffer = Buffer.concat([file_utf, buff]);
  const blob = new Blob([buffer], {
    type: 'application/pdf"',
  });
  fileSaver.saveAs(blob, fileName);
};

interface PolyExportButtonInterface {
  sx?: SxProps;
  displayIcon?: boolean;
}

const PolyExportButton = (props: PolyExportButtonInterface & ButtonProps) => {
  const { sx, displayIcon = true, ...others } = props;

  return (
    <PongoButton
      variant="contained"
      onClick={props.onClick}
      {...others}
      sx={sx}
      buttonStyle="secondary"
      startIcon={displayIcon ? <GetAppOutlined /> : undefined}
    >
      {props.children}
    </PongoButton>
  );
};

export default PolyExportButton;
