import StepLabel, { StepLabelProps } from '@mui/material/StepLabel';
import React from 'react';

import PolyCustomStepIcon from '../PolyCustomStepIcon';
import PolyStepIcon from '../PolyStepIcon';

const PolyStepLabel = (properties: StepLabelProps) => {
  const getIcon = () => {
    return typeof properties.icon === 'number' ||
      typeof properties.icon === 'string'
      ? PolyStepIcon
      : PolyCustomStepIcon;
  };

  return (
    <StepLabel color="inherit" StepIconComponent={getIcon()} {...properties} />
  );
};

export default PolyStepLabel;
