import ClearIcon from '@mui/icons-material/Clear';
import clsx from 'clsx';
import PolyFooter from 'components/MUIOverload/PolyFooter';
import PongoButton from 'components/MUIOverload/PongoButton';
import React from 'react';

import styles from '../styles/ActiveBody.module.scss';

interface StepTwoErrorProps {
  title: string;
  body: string;
  emailSubject: string;
  onHandleResetServerError: () => void;
  sendEmail: (emailSubject: string) => void;
}

const ErrorStep = (properties: StepTwoErrorProps) => {
  return (
    <div>
      <div className={styles.root}>
        <div className={clsx(styles.title, styles.title_error)}>
          <ClearIcon className={styles.error_icon} />
          {properties.title}
        </div>
        <div className={styles.body}>{properties.body}</div>
        <div className={styles.contact_button_div}>
          <PongoButton
            color="primary"
            variant="contained"
            className={styles.contact_button}
            onClick={() => properties.sendEmail(properties.emailSubject)}
          >
            Contacter sysadmin
          </PongoButton>
        </div>
      </div>
      <PolyFooter>
        <PongoButton
          color="primary"
          variant="contained"
          onClick={properties.onHandleResetServerError}
        >
          Precedent
        </PongoButton>
      </PolyFooter>
    </div>
  );
};

export default ErrorStep;
