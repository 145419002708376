import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import Alert, { AlertProps } from '@mui/material/Alert';
import React from 'react';

const PolyAlert: React.FC<AlertProps> = (props) => {
  const { severity } = props;

  const getIcon = () => {
    switch (severity) {
      case 'success':
        return <CheckCircleIcon />;
      case 'warning':
        return <WarningIcon />;
      case 'info':
        return <InfoIcon />;
      case 'error':
        return <ErrorIcon />;
      default:
        return null;
    }
  };

  return <Alert icon={getIcon()} {...props} />;
};

export default PolyAlert;
