import DatePicker from 'components/commons/DatePicker';
import InputContainer from 'components/commons/InputContainer/InputContainer';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import styles from './styles/limitDate.module.scss';

interface LimitDateProps {
  defaultDate: Date;
  disabled: boolean;
}

const LimitDate = (properties: LimitDateProps) => {
  const { control } = useFormContext();
  return (
    <InputContainer label={'Date limite de Récupération'}>
      <div className={styles.container}>
        <Controller
          name={'limitDate'}
          control={control}
          render={(properties_) => (
            <DatePicker disabled={properties.disabled} {...properties_} />
          )}
        />
      </div>
    </InputContainer>
  );
};

export default LimitDate;
