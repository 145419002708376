import PersonIcon from '@mui/icons-material/Person';
import { Box, Divider, ListItemText, Tooltip } from '@mui/material';
import { Icon, ListItemIcon, Menu, MenuItem } from '@mui/material';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import AvatarInitial from 'components/commons/AvatarInitial/AvatarInitial';
import { useAuth } from 'components/User/Authenticator';
import { EmployeeOccupations, useUserInfo } from 'components/User/UserProvider';
import {
  EmployeeNode,
  EmployeesEmployeeGradeChoices,
  MeEmployeeFragment,
} from 'generated/graphql';
import { useImageRequest } from 'hooks/useImageRequest';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { displayEmployeeNode, isDev, isEhp } from 'utils';

import { useActiveTab } from './hooks/useActiveTab';
import styles from './styles/NavbarTools.module.scss';

interface DevRoleChipsProps {
  employee: MeEmployeeFragment | null | undefined;
  occupation: EmployeeOccupations;
}

const DevRoleChips = ({ employee, occupation }: DevRoleChipsProps) => {
  const isComex = employee?.grade === EmployeesEmployeeGradeChoices.Comex;
  const isControl = occupation === EmployeeOccupations.CONTROL;
  const isMissionChief = employee?.isMissionChief;
  const isMissionDirector = employee?.isMissionDirector;
  const isExternalMissionChief = employee?.isExternalMissionChief;

  return (
    <>
      {isComex && (
        <Chip
          size="small"
          label="COMEX"
          sx={{ backgroundColor: 'comexOrange.main', color: 'white' }}
        />
      )}
      {isControl && (
        <Chip
          size="small"
          label="CDG"
          sx={{ backgroundColor: '#bf7319', color: 'white' }}
        />
      )}
      {!isExternalMissionChief && isMissionChief && (
        <Tooltip title="Mission Chief">
          <Chip
            size="small"
            label="MC"
            sx={{ backgroundColor: '#127615', color: 'white' }}
          />
        </Tooltip>
      )}
      {isExternalMissionChief && (
        <Tooltip title="External Mission Chief">
          <Chip
            size="small"
            label="EMC"
            sx={{ backgroundColor: '#ba1a9b', color: 'white' }}
          />
        </Tooltip>
      )}
      {isMissionDirector && (
        <Tooltip title="Mission Director">
          <Chip
            size="small"
            label="MD"
            sx={{ backgroundColor: 'blueviolet', color: 'white' }}
          />
        </Tooltip>
      )}
    </>
  );
};

interface NavbarAppsMenuProps {
  displayAvatar?: boolean;
}

export default function NavbarTools({
  displayAvatar = true,
}: NavbarAppsMenuProps) {
  const history = useHistory();
  const { employee } = useUserInfo();
  const employeeNode = employee as EmployeeNode;
  const auth = useAuth();
  const activeTab = useActiveTab();

  const isDevOrEHP = isDev || isEhp;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const image = useImageRequest(employee?.profilePicture?.filename);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    image.refetch();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderProfileMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          handleMenuClose();
          history.push('/user');
        }}
      >
        <AvatarInitial employee={employeeNode} imageSrc={image.response} />
        <ListItemText>{displayEmployeeNode(employeeNode, false)}</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => auth.logout()} aria-label="logout">
        <ListItemIcon>
          <Icon fontSize="small">logout</Icon>
        </ListItemIcon>
        Déconnexion
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ mr: 2 }}>
      <div className={styles.root}>
        {isDevOrEHP && (
          <DevRoleChips
            employee={employee}
            occupation={employee?.occupation as EmployeeOccupations}
          />
        )}
        {displayAvatar && (
          <Tooltip title={'Profil'}>
            <IconButton
              aria-label="user"
              className={styles.icon}
              onClick={handleProfileMenuOpen}
              size="large"
            >
              <PersonIcon
                color={activeTab === '/user' ? 'primary' : 'inherit'}
              />
            </IconButton>
          </Tooltip>
        )}
        {renderProfileMenu}
      </div>
    </Box>
  );
}
