import { TableCell, TableHead, TableRow, Typography } from '@mui/material';
import clsx from 'clsx';
import { stickyHeaderSx } from 'components/commons/SX/commonSx';
import {
  BorderedTableCell,
  CenteredTableCell,
} from 'components/commons/Tables/FlatTable';
import ArrowsUpdateMonthsTableHeader from 'components/commons/Tables/Header/ArrowsUpdateMonthsTableHeader';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import styles from 'components/Revenue/Estimated/EstimatedRevenueTables/EstimatedRevenueMissionTable/styles/TableCell.module.scss';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

import {
  enableMonthDecrement,
  enableMonthIncrement,
} from '../../../Reporting/Profitability/utils';
import { estimatedRevenueBackgroundColorSx } from '../utils';

const RevenueTableHeader = () => {
  const { displayedMonths } = useTableHeaderContext();
  return (
    <TableHead sx={stickyHeaderSx}>
      <TableRow className={styles.noUnderline} sx={{ verticalAlign: 'bottom' }}>
        <TableCell className={clsx(styles.noUnderline, styles.nameCellWidth)} />
        {_.map(displayedMonths, (month, index) => {
          return (
            <TableCell key={`header--${month.format('MMM')}`} sx={{ p: 0 }}>
              {index === 0 && (
                <Typography color={'textSecondary'} fontSize={'0.875rem'}>
                  Prévisionnel
                </Typography>
              )}
            </TableCell>
          );
        })}
        <TableCell className={styles.noUnderline} colSpan={1} />
      </TableRow>
      <TableRow>
        <BorderedTableCell>
          <Typography fontWeight={'bold'} fontSize="0.875rem">
            Mission
          </Typography>
        </BorderedTableCell>
        {_.map(displayedMonths, (month) => {
          return (
            <CenteredTableCell
              sx={{
                width: '10%',
                ...estimatedRevenueBackgroundColorSx,
              }}
              key={`header-${month.format('MMM')}`}
            >
              <ArrowsUpdateMonthsTableHeader
                month={month}
                enableMonthIncrement={enableMonthIncrement(month)}
                enableMonthDecrement={enableMonthDecrement(month)}
              >
                <Typography
                  fontWeight={
                    month.isSame(moment(), 'month') ? 'bold' : 'normal'
                  }
                  fontSize="0.875rem"
                  sx={{ textTransform: 'capitalize' }}
                >
                  {month.format('MMM')}
                </Typography>
              </ArrowsUpdateMonthsTableHeader>
            </CenteredTableCell>
          );
        })}
        <CenteredTableCell className={styles.monthCell}>
          <Typography fontWeight={'bold'} fontSize={'0.875rem'}>
            Objectif
          </Typography>
        </CenteredTableCell>
      </TableRow>
    </TableHead>
  );
};

export default RevenueTableHeader;
