import { Box, Stack, Tooltip } from '@mui/material';
import { useImageRequest } from 'hooks/useImageRequest';
import { DEFAULT_PROFILE_PICTURE_PATH } from 'poly-constants';
import React, { useEffect, useState } from 'react';

interface TrophyPictureDisplayerProps {
  filename: string | undefined;
  name: string | undefined;
  size?: number;
}

const isBase64 = (imgSrc: string) => {
  return imgSrc.includes('base64,');
};

export default function TrophyPictureDisplayer({
  filename,
  name,
  size = 80,
}: TrophyPictureDisplayerProps) {
  const { response: image } = useImageRequest(filename, true);
  const [imageSource, setImageSource] = useState(DEFAULT_PROFILE_PICTURE_PATH);

  useEffect(() => {
    if (image) {
      setImageSource(image);
    } else if (filename && isBase64(filename)) {
      setImageSource(filename);
    }
  }, [setImageSource, image, filename]);

  return (
    <Tooltip title={name} arrow placement={'bottom'}>
      <Stack
        height={size}
        width={size}
        borderRadius={10}
        overflow="hidden"
        sx={{
          margin: 'auto',
        }}
      >
        {imageSource && (
          <Box
            component="img"
            src={imageSource}
            alt={'trophy'}
            title={name ? name : ''}
            loading="lazy"
            style={{ pointerEvents: 'none' }}
          />
        )}
      </Stack>
    </Tooltip>
  );
}
