import { EmployeeOccupations } from 'components/User/UserProvider';
import {
  EmployeeNode,
  EmployeesEmployeeContractTypeChoices,
} from 'generated/graphql';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { totalDaysToWork, totalDaysToWorkV2 } from 'pages/ActivityPage/utils';

import { formattedActivityMonitoring } from './Table/Body/utils';

export const getTimeSpentByWeek = (
  week: moment.Moment,
  employee?: EmployeeNode
): number => {
  const timeByActivity = _.filter(employee?.activityMonitoring, (am) => {
    return isActivityMonitoringInCurrentWeekPart(am, week);
  });
  return _.sumBy(timeByActivity, 'timeSpent');
};

export function isActivityMonitoringInCurrentWeekPart(
  activityMonitoring: formattedActivityMonitoring,
  week: moment.Moment
) {
  if (
    activityMonitoring.endDate &&
    week.isSameOrAfter(moment('2023-01-01'), 'year')
  ) {
    return (
      week.isSame(activityMonitoring.date, 'week') &&
      week.isSame(activityMonitoring.date, 'month')
    );
  }
  return week.isSame(activityMonitoring.date, 'week');
}

export const isCorrectEmployeeWorkingDaysOnWeek = (
  timeSpent: number,
  week: moment.Moment,
  employee?: EmployeeNode
): boolean => {
  const beginning_week = week.clone().startOf('week');
  const ending_week = week.clone().endOf('week');
  if (
    (employee?.hiringDate && moment(employee?.hiringDate) >= ending_week) ||
    (employee?.leavingDate && moment(employee?.leavingDate) <= beginning_week)
  ) {
    return true;
  }

  const daysToWorkInCurrentWeek = getDaysToWorkInCurrentWeek(week, employee);

  if (
    employee?.contractType === EmployeesEmployeeContractTypeChoices.Provider
  ) {
    return timeSpent >= daysToWorkInCurrentWeek;
  }
  return timeSpent === daysToWorkInCurrentWeek;
};

export const getDaysToWorkInCurrentWeek = (
  week: moment.Moment,
  employee?: EmployeeNode
): number => {
  if (week.year() < 2023) {
    return totalDaysToWork(employee, week);
  } else {
    let ending_day;
    if (week.day() === 1 && week.clone().day(5).month() !== week.month()) {
      ending_day = week.clone().endOf('month');
    } else {
      ending_day = week.clone().day(5);
    }
    return totalDaysToWorkV2(employee, week, ending_day);
  }
};

export const checkIfEmployeeIsCorrectOnMonth = (
  displayedWeeks: Array<Moment>,
  employee?: EmployeeNode
): boolean => {
  if (employee?.occupation === EmployeeOccupations.DG) {
    return true;
  }
  const pastWeek = _.filter(
    displayedWeeks,
    (week) => week.isBefore(moment(), 'week') || week.month() === 11
  );
  let isOnError = false;
  _.forEach(pastWeek, (week) => {
    const timeSpent = getTimeSpentByWeek(week, employee);
    isOnError =
      isOnError ||
      !isCorrectEmployeeWorkingDaysOnWeek(timeSpent, week, employee);
  });
  return !isOnError;
};
