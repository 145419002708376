import Typography from '@mui/material/Typography';
import TabHeader from 'components/commons/TabHeader';
import React from 'react';

interface InternalMissionPageProps {
  page: string;
}

export default function InternalMissionPage({
  page,
}: InternalMissionPageProps) {
  const pageLowerCase = page.toLowerCase();
  return (
    <>
      <TabHeader title={page} />
      <Typography sx={{ marginTop: 5 }}>
        Vous avez sélectionné une ou plusieurs missions internes qui ne
        possèdent donc pas de page {pageLowerCase}.
      </Typography>
    </>
  );
}
