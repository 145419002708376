import { Divider } from '@mui/material';
import clsx from 'clsx';
import DisplayBillPaymentStatus from 'components/MissionFollowUp/OverallListBill/DisplayStatus/DisplayBillPaymentStatus';
import DisplayBillStatus from 'components/MissionFollowUp/OverallListBill/DisplayStatus/DisplayBillStatus';
import styles from 'components/MissionFollowUp/OverallListBill/DisplayStatus/styles/DisplayBillStatusAdvancement.module.scss';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import {
  BillingBillPaymentStatusChoices,
  BillingBillStatusChoices,
  BillNode,
  FetchHistoryQuery,
  useFetchHistoryLazyQuery,
} from 'generated/graphql';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';

import statusStyles from './styles/Status.module.scss';

interface DisplayBillCurrentStatusAdvancementProperties {
  bill: BillNode;
}

interface BillStatusAdvancement {
  name: string;
  icon: JSX.Element;
  style: string;
  display: boolean;
  active: boolean;
}

export default function DisplayBillStatusAdvancement(
  properties: DisplayBillCurrentStatusAdvancementProperties
) {
  const SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED =
    !!useEnvironmentVariable('send_bill_email');
  const isBilled = !!properties.bill.billNumber;
  const isCancelState =
    properties.bill.status === BillingBillStatusChoices.ToCancel ||
    properties.bill.status === BillingBillStatusChoices.Canceled;
  const billStatus = properties.bill.status;
  const billPaymentStatus = properties.bill.paymentStatus;
  const isLitigation =
    billPaymentStatus === BillingBillPaymentStatusChoices.Litigation;
  const isRecovering =
    billPaymentStatus === BillingBillPaymentStatusChoices.Recovering;
  const isLate = isLitigation || isRecovering;
  const [billHistory, setBillHistory] = useState({} as FetchHistoryQuery);
  const { enqueueSnackbar } = useSnackbar();

  const [getBillHistory] = useFetchHistoryLazyQuery({
    onCompleted: (data) => {
      setBillHistory(data);
    },
    onError: () => {
      enqueueSnackbar(`Impossible de récupérer l'historique`, {
        variant: 'error',
      });
    },
  });

  useEffect(() => {
    if (properties.bill.id) {
      getBillHistory({ variables: { billId: properties.bill.id } });
    }
  }, [properties.bill.id, getBillHistory]);

  const lastUpdateWithPaymentStatusChanges = billHistory.history
    ?.find((history) => {
      return history.changedFields?.some(
        (changedField) => changedField.field === 'payment_status'
      );
    })
    ?.changedFields?.find(
      (changedField) => changedField.field === 'payment_status'
    );

  const lastUpdatedPaymentStatus =
    lastUpdateWithPaymentStatusChanges?.new ===
    BillingBillPaymentStatusChoices.Paid
      ? lastUpdateWithPaymentStatusChanges.old
      : lastUpdateWithPaymentStatusChanges?.new;

  const paymentStatusToDisplayAsHistory = lastUpdatedPaymentStatus
    ? lastUpdatedPaymentStatus
    : BillingBillPaymentStatusChoices.Pending;

  const hasBeenInLitigation =
    paymentStatusToDisplayAsHistory ===
    BillingBillPaymentStatusChoices.Litigation;
  const hasBeenInRecovering =
    paymentStatusToDisplayAsHistory ===
    BillingBillPaymentStatusChoices.Recovering;
  const hasBeenLate = hasBeenInLitigation || hasBeenInRecovering;

  const statusList: BillStatusAdvancement[] = [
    {
      name: 'Brouillon',
      icon: (
        <DisplayBillStatus
          status={BillingBillStatusChoices.Draft}
          iconOnly
          disabled={billStatus !== BillingBillStatusChoices.Draft}
          iconSize={'25px'}
        />
      ),
      style: statusStyles.draft,
      display: true,
      active: billStatus === BillingBillStatusChoices.Draft,
    },
    {
      name: 'À facturer',
      icon: (
        <DisplayBillStatus
          status={BillingBillStatusChoices.ToBill}
          iconOnly
          disabled={billStatus !== BillingBillStatusChoices.ToBill}
          iconSize={'25px'}
        />
      ),
      style: SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED
        ? statusStyles.toBillNew
        : statusStyles.toBill,
      display: true,
      active: billStatus === BillingBillStatusChoices.ToBill,
    },
    {
      name: SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED ? 'Comptabilisée' : 'Facturée',
      icon: (
        <DisplayBillStatus
          status={BillingBillStatusChoices.Billed}
          iconOnly
          disabled={
            SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED
              ? billStatus !== BillingBillStatusChoices.Billed ||
                billPaymentStatus !== BillingBillPaymentStatusChoices.Pending
              : true
          }
          iconSize={'25px'}
        />
      ),
      style: SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED
        ? statusStyles.billed
        : statusStyles.sent,
      display: isBilled || !isCancelState,
      active:
        SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED &&
        billStatus === BillingBillStatusChoices.Billed &&
        billPaymentStatus === BillingBillPaymentStatusChoices.Pending,
    },
    {
      name: 'Envoyée',
      icon: (
        <DisplayBillStatus
          status={BillingBillStatusChoices.Sent}
          iconOnly
          disabled
          iconSize={'25px'}
        />
      ),
      style: statusStyles.sent,
      display:
        SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED && (isBilled || !isCancelState),
      active: false,
    },
    {
      name: 'En attente',
      icon: (
        <DisplayBillPaymentStatus
          status={BillingBillPaymentStatusChoices.Pending}
          iconOnly
          disabled={
            (SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED
              ? billStatus !== BillingBillStatusChoices.Sent
              : billStatus !== BillingBillStatusChoices.Billed) ||
            billPaymentStatus !== BillingBillPaymentStatusChoices.Pending
          }
          iconSize={'25px'}
        />
      ),
      style: statusStyles.billPaymentPending,
      display: isBilled && !hasBeenLate && !isLate,
      active:
        (SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED
          ? billStatus === BillingBillStatusChoices.Sent
          : billStatus === BillingBillStatusChoices.Billed) &&
        billPaymentStatus !== BillingBillPaymentStatusChoices.Paid &&
        !isLate,
    },
    {
      name: 'A annuler',
      icon: (
        <DisplayBillStatus
          status={BillingBillStatusChoices.ToCancel}
          iconOnly
          disabled={billStatus !== BillingBillStatusChoices.ToCancel}
          iconSize={'25px'}
        />
      ),
      style: statusStyles.toCancel,
      display: isCancelState,
      active: billStatus === BillingBillStatusChoices.ToCancel,
    },
    {
      name: 'Annulée',
      icon: (
        <DisplayBillStatus
          status={BillingBillStatusChoices.Canceled}
          iconOnly
          disabled={billStatus !== BillingBillStatusChoices.Canceled}
          iconSize={'25px'}
        />
      ),
      style: statusStyles.canceled,
      display: isCancelState,
      active: billStatus === BillingBillStatusChoices.Canceled,
    },
    {
      name: 'En litige',
      icon: (
        <DisplayBillPaymentStatus
          status={BillingBillPaymentStatusChoices.Litigation}
          iconOnly
          iconSize={'25px'}
          disabled={billStatus !== BillingBillStatusChoices.Canceled}
        />
      ),
      style: statusStyles.litigtion,
      display: !isCancelState && (isLitigation || hasBeenInLitigation),
      active: isLitigation,
    },
    {
      name: 'En recouvrement',
      icon: (
        <DisplayBillPaymentStatus
          status={BillingBillPaymentStatusChoices.Recovering}
          iconOnly
          disabled={billStatus !== BillingBillStatusChoices.Canceled}
          iconSize={'25px'}
        />
      ),
      style: statusStyles.recovering,
      display: !isCancelState && (isRecovering || hasBeenInRecovering),
      active: isRecovering,
    },
    {
      name: 'Payée',
      icon: (
        <DisplayBillPaymentStatus
          status={BillingBillPaymentStatusChoices.Paid}
          iconOnly
          disabled={billPaymentStatus !== BillingBillPaymentStatusChoices.Paid}
          iconSize={'25px'}
        />
      ),
      style: statusStyles.billPaymentPaid,
      display: !isCancelState,
      active: billPaymentStatus === BillingBillPaymentStatusChoices.Paid,
    },
  ];

  const listToDisplay = _.filter(statusList, (status) => {
    return !!status.display;
  });

  const getStyleIcon = (status: BillStatusAdvancement) =>
    status.active
      ? clsx(styles.activeStatus, status.style)
      : styles.inactiveStatus;

  const getStyleText = (status: BillStatusAdvancement) =>
    status.active ? clsx(styles.activeText, status.style) : styles.inactiveText;

  return (
    <div className={styles.root}>
      {_.map(listToDisplay, (status, index) => {
        return (
          <React.Fragment key={`status-display-${index}`}>
            <span className={getStyleIcon(status)}>
              {status.icon}
              <div className={getStyleText(status)}>{status.name}</div>
            </span>
            {index !== listToDisplay.length - 1 && (
              <Divider className={styles.divider} />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}
