import { Checkbox, CheckboxProps, InputLabel } from '@mui/material';
import React from 'react';

interface PolyCheckboxProps {
  properties: CheckboxProps;
  label: string | undefined;
}

const PolyCheckbox = (properties: PolyCheckboxProps) => {
  const checkbox = React.useRef<HTMLButtonElement>(null);
  const onClick = () => {
    if (checkbox && checkbox.current && checkbox.current.firstChild) {
      const button = checkbox.current.firstChild
        .firstChild as HTMLButtonElement;
      button.click();
    }
  };
  return (
    <>
      <Checkbox {...properties.properties} ref={checkbox} color="primary" />
      {properties.label && (
        <InputLabel onClick={onClick}>{properties.label}</InputLabel>
      )}
    </>
  );
};

export default PolyCheckbox;
