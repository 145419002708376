import RequestButton from 'components/ExceptionalActivities/RequestButton';
import DateCell from 'components/MUIOverload/PolyDataGrid/components/DateCell';
import DateRangeCell from 'components/MUIOverload/PolyDataGrid/components/DateRangeCell';
import DurationCell from 'components/MUIOverload/PolyDataGrid/components/DurationCell';
import StringCell from 'components/MUIOverload/PolyDataGrid/components/StringCell';
import { PolyDataGridColumn } from 'components/MUIOverload/PolyDataGrid/PolyDataGridHead';
import {
  OvertimeAuthorizationsQuery,
  useOvertimeAuthorizationsLazyQuery,
} from 'generated/graphql';
import { useSnackbar } from 'notistack';
import { ExceptionalActivitiesTitles } from 'poly-constants';
import React, { useEffect, useState } from 'react';
import { displayEmployee } from 'utils';

import { OvertimeAuthorization } from '../types';
import { computeTotalDuration } from '../utils';
import ExceptionalActivitiesAuthorizationsPanel from './ExceptionalActivitiesAuthorizationsPanel';

const content =
  "Vous visualisez ici les autorisations de déclaration d'activité exceptionnelle accordées par votre manager. Il s'agit des périodes durant lesquelles vous êtes autorisés à formuler une demande d'activité exceptionnelle.\n" +
  '\n' +
  'Pour rappel : une activité exceptionnelle consiste à avoir travaillé au-delà de votre volume de travail hebdomadaire et/ou le samedi sans avoir pu récupérer ce temps en repos la semaine même où cette activité a été réalisée.';

const columns: PolyDataGridColumn<
  DeepExtractTypeSkipArrays<
    OvertimeAuthorizationsQuery,
    ['overtimeAuthorizations']
  >
>[] = [
  {
    headerName: 'Date',
    isSortable: false,
    render: (data) => DateCell({ date: data.createdAt }),
  },
  {
    headerName: 'Manager',
    isSortable: false,
    width: '110px',
    render: (data) =>
      StringCell({
        data: displayEmployee(data.manager.firstName, data.manager.lastName),
      }),
  },
  {
    headerName: 'Mission',
    isSortable: false,
    width: '230px',
    render: (data) => StringCell({ data: data.activity.name }),
  },
  {
    headerName: 'Période accordée',
    isSortable: false,
    width: '272px',
    render: (data) =>
      DateRangeCell({ startDate: data.periodStart, endDate: data.periodEnd }),
  },
  {
    headerName: 'Demandes',
    width: '60px',
    isSortable: false,
    render: (data) => DurationCell({ duration: computeTotalDuration(data) }),
  },
  {
    headerName: '',
    isSortable: false,
    render: (data) =>
      RequestButton({
        authorizationData: data,
        periodStart: data.periodStart,
        periodEnd: data.periodEnd,
        mission: data.activity.name,
        managerFirstName: data.manager.firstName,
        managerLastName: data.manager.lastName,
      }),
  },
];

const ExceptionalActivitiesMyAuthorizationsPanel = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [overtimeData, setOvertimeData] = useState<OvertimeAuthorization>();
  const [fetchOvertimeAuthorization] = useOvertimeAuthorizationsLazyQuery({
    onCompleted: (data) => {
      setOvertimeData(data);
      setLoading(false);
    },
    onError: () => {
      enqueueSnackbar('Problème de récuperation des données', {
        variant: 'error',
      });
    },
  });
  useEffect(() => {
    setLoading(true);
    fetchOvertimeAuthorization({ variables: { asManager: false } });
  }, [fetchOvertimeAuthorization]);
  return (
    <ExceptionalActivitiesAuthorizationsPanel
      title={ExceptionalActivitiesTitles.myAuthorization}
      body={content}
      loading={loading}
      columns={columns}
      asManager={false}
      overtimeAuthorizationsData={overtimeData}
    />
  );
};

export default ExceptionalActivitiesMyAuthorizationsPanel;
