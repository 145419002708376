import CloseIcon from '@mui/icons-material/Close';
import { Box, DialogContent, IconButton, Typography } from '@mui/material';
import DisplayMission from 'components/commons/DisplayMission';
import { displayRangeMonthPeriod } from 'components/MissionFollowUp/BillDetail/BillDetail/utils';
import PolyGridInputForm from 'components/MissionFollowUp/PolyGridInputForm';
import PolyDataGrid from 'components/MUIOverload/PolyDataGrid';
import StringCell from 'components/MUIOverload/PolyDataGrid/components/StringCell';
import { PolyDataGridColumn } from 'components/MUIOverload/PolyDataGrid/PolyDataGridHead';
import PolyDialog from 'components/MUIOverload/PolyDialog';
import PolyFooter from 'components/MUIOverload/PolyFooter';
import PongoButton from 'components/MUIOverload/PongoButton';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import { ActivityNode, BillNode } from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { totalFormat } from 'pages/ActivityPage/utils';
import { requiredForm } from 'pages/MissionFollowUp/formValidators';
import { POLY_DATE } from 'poly-constants';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useSelector } from 'store';

export interface BillNumberInput {
  number: string;
  id: string;
}

export const billModalColumns = [
  {
    headerName: 'Libellé',
    isSortable: false,
    propertyName: 'title',
    width: '170px',
    render: (data: BillNode) =>
      StringCell({
        data: data.title,
      }),
  },
  {
    headerName: 'Mission',
    isSortable: false,
    propertyName: 'purchaseOrder.billingActivityInformation.activity.name',
    width: '140px',
    render: function renderMission(data) {
      return data?.purchaseOrder !== undefined ? (
        <DisplayMission
          mission={
            data?.purchaseOrder?.billingActivityInformation
              .activity as ActivityNode
          }
        />
      ) : (
        <></>
      );
    },
  },
  {
    headerName: 'N° BDC',
    isSortable: false,
    propertyName: 'numberPO',
    width: '100px',
    render: (data) =>
      StringCell({
        data: data.purchaseOrder?.number,
      }),
  },
  {
    headerName: 'Mois facturé(s)',
    isSortable: false,
    propertyName: 'billingMonth',
    width: '130px',
    render: (data) =>
      StringCell({
        data: displayRangeMonthPeriod(data.periodBeginning, data.periodEnding),
      }),
  },
  {
    headerName: 'À facturer le',
    isSortable: false,
    propertyName: 'date',
    width: '100px',
    render: (data) => StringCell({ data: moment(data.date).format(POLY_DATE) }),
  },
  {
    headerName: 'Montant (HT)',
    isSortable: false,
    propertyName: 'total',
    width: '100px',
    render: (data) =>
      StringCell(
        data.purchaseOrder !== undefined
          ? {
              data: totalFormat(data.total, data.purchaseOrder.currency),
            }
          : { data: undefined }
      ),
  },
] as PolyDataGridColumn<DeepExtractTypeSkipArrays<BillNode, []>>[];

interface BillActionModalProps {
  open: boolean;
  title: string;
  closeModal: () => void;
  validateModal?: () => void;
  bodyModal: JSX.Element | string;
  alerts?: JSX.Element | null;
  disabled?: boolean;
  displayBillRows?: boolean;
  groupAction?: boolean;
  billed?: boolean;
  formProvider?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form: any;
    submit: () => void;
  };
  customFooter?: JSX.Element;
}

export default function BillActionModal({
  open,
  title,
  closeModal,
  validateModal,
  bodyModal,
  alerts = null,
  disabled = false,
  displayBillRows = true,
  groupAction,
  billed,
  formProvider,
  customFooter,
}: BillActionModalProps) {
  const allBills = useSelector((state) => state.purchaseOrder.allBills);
  const currentBill = useSelector((state) => state.purchaseOrder.currentBill);
  const selectedBills = useSelector(
    (state) => state.purchaseOrder.selectedBills
  );
  const form = formProvider?.form;
  const chronoFeatureFlag = useEnvironmentVariable('chrono');

  const getRows = (
    _orderBy?: string | string[],
    _order?: 'asc' | 'desc'
  ): BillNode[] => {
    const rows = groupAction ? selectedBills : [currentBill];
    _.forEach(rows, (bill, index) => {
      const fieldName = `bills[${index}]`;
      form?.register(`${fieldName}.id`);
      form?.setValue(`${fieldName}.id`, bill.id);
    });
    return rows;
  };

  function uniqueBillsNumber(billNumber: string) {
    if (_.find(allBills, { billNumber: billNumber })) {
      return `Le numéro de facture « ${billNumber} »  existe déjà`;
    }
    return true;
  }

  const inputColumn:
    | PolyDataGridColumn<DeepExtractTypeSkipArrays<BillNode, []>>[]
    | [] =
    billed && !chronoFeatureFlag
      ? [
          {
            headerName: 'Facture N°',
            isSortable: false,
            propertyName: 'total',
            width: '100px',
            render: function renderInput(data) {
              const fieldName = `bills[${_.indexOf(getRows(), data)}]`;
              return (
                <PolyGridInputForm
                  hiddenLabel
                  defaultValue={data.billNumber ?? ''}
                  formName={`${fieldName}.number`}
                  validateRules={{
                    required: requiredForm,
                    validate: {
                      duplicate: uniqueBillsNumber,
                    },
                  }}
                />
              );
            },
          },
        ]
      : [];
  return (
    <PolyDialog
      maxWidth={'lg'}
      open={open}
      onClose={closeModal}
      disableEnforceFocus={true}
    >
      <IconButton
        onClick={closeModal}
        sx={{ position: 'absolute', top: 0, right: '5px' }}
        color={'primary'}
        size="large"
      >
        <CloseIcon />
      </IconButton>
      <FormProvider {...form}>
        <form onSubmit={formProvider?.submit}>
          <DialogContent>
            <Typography
              variant="h3Bold"
              sx={{ mb: 1, color: 'primary.main' }}
              component="h3"
            >
              {title}
            </Typography>
            {alerts}
            {displayBillRows && (
              <Box sx={{ my: 3 }}>
                <PolyDataGrid
                  columns={[...billModalColumns, ...inputColumn]}
                  getRows={getRows}
                />
              </Box>
            )}
            {bodyModal}
            <PolyFooter>
              {customFooter ? (
                customFooter
              ) : (
                <>
                  <PongoButton
                    variant={'text'}
                    onClick={closeModal}
                    sx={{ mr: 1 }}
                  >
                    Annuler
                  </PongoButton>
                  <PongoButton
                    onClick={formProvider?.submit || validateModal}
                    variant={'contained'}
                    disabled={disabled}
                  >
                    Valider
                  </PongoButton>
                </>
              )}
            </PolyFooter>
          </DialogContent>
        </form>
      </FormProvider>
    </PolyDialog>
  );
}
