import { EmployeeNode } from 'generated/graphql';
import moment from 'moment';
import { comaStringToFloat } from 'pages/ActivityPage/utils';

export const requiredForm = {
  type: 'required',
  value: true,
  message: 'Ce champ est obligatoire',
};

export const requiredField = {
  type: 'required',
  value: true,
  message: 'Champ requis',
};

export const isArrayFilled = (array?: EmployeeNode[]) => {
  if (!array) {
    return true;
  }
  return array.length > 0 || 'Ce champ est obligatoire';
};

export const isNumber = (value?: string) => {
  if (!value) {
    return true;
  }
  return (
    !Number.isNaN(comaStringToFloat(value)) || 'Le montant doit être un nombre'
  );
};

export const isNumberPositive = (value?: string) => {
  if (!value) {
    return true;
  }
  return (
    (!Number.isNaN(comaStringToFloat(value)) && comaStringToFloat(value) > 0) ||
    'Le montant doit être supérieur à 0'
  );
};

export const isNumberNullOrPositive = (value?: string) => {
  if (!value) {
    return true;
  }
  return (
    (!Number.isNaN(comaStringToFloat(value)) &&
      comaStringToFloat(value) >= 0) ||
    'Le montant saisi doit être un nombre positif'
  );
};

export const isDate = (value?: string) => {
  if (!value) {
    return true;
  }
  return moment(value).isValid() || `la date n'est pas valide`;
};

export const isPercentage = (value: string) => {
  return (
    (!Number.isNaN(comaStringToFloat(value)) &&
      comaStringToFloat(value) >= 0 &&
      comaStringToFloat(value) <= 100) ||
    'La remise doit être comprise entre 0 et 100'
  );
};

export const checkEmailFormat = (email: string) => {
  const regexEmail = /^[\d.A-z-]+@[\d.A-z-]+\.[\d.A-z-]{2,}$/;
  return !email || regexEmail.test(email)
    ? true
    : "Format d'adresse email invalide";
};
