import { ApolloError } from '@apollo/client';
import BackdropLoading from 'components/commons/BackdropLoading';
import PageWrapper from 'components/commons/PageWrapper';
import PolyPaper from 'components/MUIOverload/PolyPaper';
import { useUserInfo } from 'components/User/UserProvider';
import { MeDocument, useValidateConsentMutation } from 'generated/graphql';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import AddProfilePictureStep from './AddProfilePictureStep/AddProfilePictureStep';
import ConfirmationStep from './ConfirmationStep/ConfirmationStep';
import CustomStepper from './CustomStepper/CustomStepper';
import ErrorStep from './ErrorStep/ErrorStep';
import FinalStep from './FinalStep/FinalStep';
import IntroductionStep from './IntroductionStep/IntroductionStep';
import { getFormattedErrorGraphql } from './utils';

enum StepPage {
  // error need to be < 0
  ErrorPage = -1,

  FirstPage = 0,
  SecondPage = 1,
  ThirdPage = 2,
  FourthPage = 3,
  // need to be at the end
  Redirect,
}

const stepsName = [
  'Bienvenue',
  "Suivi d'activité",
  'Photo de profil',
  'Terminé',
];

// TODO: get sysadmin email from conf
const sysadminEmail = 'sysadmin@polyconseil.fr';

const sendEmail = (emailSubject: string) => {
  window.location.href = `mailto:${sysadminEmail}?subject=${emailSubject}`;
};

const FirstConnectionPage = () => {
  const history = useHistory();
  const userInfo = useUserInfo();
  const [errorTitle, setErrorTitle] = useState('');
  const [errorBody, setErrorBody] = useState('');
  const [errorEmailSubject, setErrorEmailSubject] = useState('');
  const [activeStep, setActiveStep] = React.useState<StepPage>(
    StepPage.FirstPage
  );

  const [validateConsent, { loading: loadingValidateConsent }] =
    useValidateConsentMutation({
      refetchQueries: [{ query: MeDocument }],
      awaitRefetchQueries: true,
      onError: (error) => {
        handleErrorServer(
          error,
          "Erreur confirmation suivi d'activité",
          '[INTRA][Validation]'
        );
      },
    });

  const onHandleBack = () => {
    setActiveStep((previousState) => previousState - 1);
  };

  const onHandleNext = () => {
    setActiveStep((previousState) => previousState + 1);
  };

  const onHandleServerError = (
    title: string,
    body: string,
    emailSubject: string
  ) => {
    setErrorTitle(title);
    setErrorBody(body);
    setErrorEmailSubject(emailSubject);

    setActiveStep(StepPage.ErrorPage);
  };

  const handleErrorServer = (
    error: ApolloError,
    title: string,
    emailSubject: string
  ) => {
    const formattedError = getFormattedErrorGraphql(error);
    onHandleServerError(
      title,
      formattedError,
      `${emailSubject}${formattedError}`
    );
  };

  const onHandleResetServerError = () => {
    setActiveStep(StepPage.FirstPage);
  };

  const exitStepper = () => {
    history.replace('/');
  };

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case StepPage.ErrorPage:
        return (
          <ErrorStep
            title={errorTitle}
            body={errorBody}
            emailSubject={errorEmailSubject}
            onHandleResetServerError={onHandleResetServerError}
            sendEmail={sendEmail}
          />
        );
      case StepPage.FirstPage:
        return <IntroductionStep onHandleNext={onHandleNext} />;
      case StepPage.SecondPage:
        return (
          <ConfirmationStep
            onHandleNext={onHandleNext}
            onHandleBack={onHandleBack}
            renderConfirmationStepActive={() =>
              !userInfo.employee?.hasValidatedConsent
            }
            sendValidationBack={async () => {
              const { data } = await validateConsent();
              return data?.updateEmployee?.employee?.hasValidatedConsent;
            }}
          />
        );
      case StepPage.ThirdPage:
        return (
          <AddProfilePictureStep
            onHandleNext={onHandleNext}
            onHandleBack={onHandleBack}
          />
        );
      case StepPage.FourthPage:
        return (
          <FinalStep onHandleBack={onHandleBack} onHandleNext={exitStepper} />
        );
      default:
        return <></>;
    }
  };

  return (
    <PageWrapper large>
      <PolyPaper sx={{ p: 2 }}>
        <CustomStepper activeStep={Math.abs(activeStep)} steps={stepsName} />
        {getStepContent(activeStep)}
      </PolyPaper>
      <BackdropLoading open={loadingValidateConsent} />
    </PageWrapper>
  );
};

export default FirstConnectionPage;
