import LinkIcon from '@mui/icons-material/Link';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Card, Stack, Typography, useTheme } from '@mui/material';
import DownloadFile from 'components/commons/FileTransfer/DownloadFile/DownloadFile';
import RedirectButton from 'components/commons/RedirectButton/RedirectButton';
import BillDetailView from 'components/MissionFollowUp/BillDetail/BillDetail/BillView/BillDetailView';
import UpdateStatusFooter from 'components/MissionFollowUp/BillDetail/BillDetail/UpdateStatusFooter';
import DisplayBillStatusAdvancement from 'components/MissionFollowUp/OverallListBill/DisplayStatus/DisplayBillStatusAdvancement';
import PongoButton from 'components/MUIOverload/PongoButton';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import { useUserInfo } from 'components/User/UserProvider';
import { useGeneratePdfBillLazyQuery } from 'generated/graphql';
import Moment from 'moment';
import { POLY_DATE_MONTH_TIME } from 'poly-constants';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'store';
import { isChiefOrDirectorOfActivity, OpacitySuffix } from 'utils';

import BillOptions from '../../BillOptions';
import { isBillProformaStatus } from '../../utils';

export default function BillView() {
  const theme = useTheme();
  const { employee, isControl, isSuperuser } = useUserInfo();
  const history = useHistory();
  const currentBill = useSelector((state) => state.purchaseOrder.currentBill);
  const currentPurchaseOrders = useSelector(
    (state) => state.purchaseOrder.currentPurchaseOrder
  );
  const currentMission = useSelector((state) => state.activity.currentMission);
  const { pathname } = useLocation();
  const isBillPdfActive = !!useEnvironmentVariable('bill_pdf');

  const createHrefToId = (idReference: string) => {
    return history.createHref({
      pathname,
      search: `?bill_id=${idReference}&po_id=${currentPurchaseOrders.id}&mission_id=${currentMission.id}`,
    });
  };

  const isActivityChiefOrDirector = isChiefOrDirectorOfActivity(
    currentMission,
    employee
  );

  window.scrollTo(0, 0);

  const [viewPDFInNewTab] = useGeneratePdfBillLazyQuery({
    variables: {
      billId: currentBill?.id,
    },
    onCompleted: (data) => {
      if (data?.generatePdfBill) {
        const byteCharacters = atob(data?.generatePdfBill);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
      }
    },
  });

  const LinkCredit = () => {
    if (currentBill.billReference) {
      return (
        <RedirectButton
          redirectionPath={createHrefToId(currentBill.billReference.id)}
          title={'Redirection vers la facture annulée correspondante'}
          startIcon={<LinkIcon fontSize={'large'} />}
        >
          Facture annulée
        </RedirectButton>
      );
    } else if (currentBill.creditNote) {
      return (
        <RedirectButton
          redirectionPath={createHrefToId(currentBill.creditNote.id)}
          title={"Redirection vers l'avoir correspondant"}
          startIcon={<LinkIcon fontSize={'large'} />}
        >
          Avoir
        </RedirectButton>
      );
    }
    return <React.Fragment />;
  };

  return (
    <Card
      variant="outlined"
      sx={{
        p: 3,
        mb: 2,
        borderRadius: 2,
        backgroundColor: `${theme.palette.standardGrey.main}${OpacitySuffix.FORTY_PERCENT}`,
        borderColor: theme.palette.disableGrey.main,
      }}
    >
      <Stack direction="row" alignItems="flex-start">
        <Typography
          variant="h2Bold"
          component="h2"
          sx={{
            mr: 1,
            mb: 2,
            width: 1,
          }}
        >
          {currentBill.title}
          <Typography
            variant={'h2'}
            component="span"
            sx={{ ml: 1, fontWeight: 'normal' }}
          >
            {currentBill.billNumber
              ? ` - Facture N°${currentBill.billNumber}`
              : ''}
          </Typography>
        </Typography>
        <Stack direction="row" alignItems="center" spacing={1}>
          {isBillPdfActive && isBillProformaStatus(currentBill) && (
            <PongoButton
              variant="contained"
              buttonStyle="secondary"
              startIcon={<VisibilityIcon />}
              onClick={() => viewPDFInNewTab()}
              uppercase={false}
            >
              Proforma
            </PongoButton>
          )}
          {isBillPdfActive &&
            currentBill.billPdf &&
            !isBillProformaStatus(currentBill) && (
              <DownloadFile
                filename={currentBill.billPdf.filename || ''}
                title={'PDF'}
                isSingleButton={true}
              />
            )}
          <LinkCredit />
          <BillOptions />
        </Stack>
      </Stack>
      <Typography
        variant="bodyBold"
        sx={{
          color: 'text.secondary',
          display: 'flex',
          mb: 2,
        }}
      >
        Dernière modification :
        <Typography variant="body" sx={{ ml: 1, color: 'text.secondary' }}>
          {Moment(currentBill.updatedAt).format(POLY_DATE_MONTH_TIME)}
        </Typography>
      </Typography>
      <Box sx={{ display: 'flex', ml: 1, mb: 2.5 }}>
        <DisplayBillStatusAdvancement bill={currentBill} />
      </Box>
      <BillDetailView />
      {(isActivityChiefOrDirector || isControl || isSuperuser) && (
        <Stack direction={'row'} justifyContent={'flex-end'} sx={{ mt: 2 }}>
          <UpdateStatusFooter />
        </Stack>
      )}
    </Card>
  );
}
