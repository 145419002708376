import { FormControl, MenuItem, Stack } from '@mui/material';
import ActivityIndicator from 'components/commons/ActivityIndicator';
import LabelTextFieldGrid from 'components/MissionFollowUp/LabelTextFieldGrid';
import {
  ActivitiesIndicatorClientSatisfactionChoices as ClientSatisfactionChoices,
  ActivitiesIndicatorEfficiencyChoices as EfficiencyChoices,
  IndicatorChoices,
} from 'generated/graphql';
import { requiredForm } from 'pages/MissionFollowUp/formValidators';
import React from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';

export type IndicatorFormData = {
  efficiency: IndicatorChoices;
  clientSatisfaction: IndicatorChoices;
  comment?: string;
};

interface IndicatorFormProps {
  isCommentRequired: boolean;
  control: UseFormMethods<IndicatorFormData>['control'];
  errors: UseFormMethods<IndicatorFormData>['errors'];
}

export default function IndicatorForm({
  isCommentRequired,
  control,
  errors,
}: IndicatorFormProps) {
  return (
    <Stack sx={{ minWidth: 450, gap: 4, mx: 1, my: 2 }}>
      <FormControl fullWidth>
        <Controller
          rules={{ required: requiredForm }}
          control={control}
          name="efficiency"
          defaultValue=""
          render={({ value, onChange }) => {
            return (
              <LabelTextFieldGrid
                title="Statut Équipe"
                value={value}
                onChange={(event) => onChange(event.target.value)}
                errorform={errors.efficiency}
                customspacingside={0}
                customspacingtop={0}
                required
                select
              >
                {Object.values(EfficiencyChoices)
                  .reverse()
                  .map((indicator) => (
                    <MenuItem key={indicator} value={indicator}>
                      <ActivityIndicator indicator={indicator} />
                    </MenuItem>
                  ))}
              </LabelTextFieldGrid>
            );
          }}
        />
      </FormControl>
      <FormControl fullWidth>
        <Controller
          rules={{ required: requiredForm }}
          control={control}
          name="clientSatisfaction"
          defaultValue=""
          render={({ value, onChange }) => {
            return (
              <LabelTextFieldGrid
                title="Statut Client"
                value={value}
                onChange={(event) => onChange(event.target.value)}
                errorform={errors.clientSatisfaction}
                customspacingside={0}
                customspacingtop={0}
                required
                select
              >
                {Object.values(ClientSatisfactionChoices)
                  .reverse()
                  .map((indicator) => (
                    <MenuItem key={indicator} value={indicator}>
                      <ActivityIndicator indicator={indicator} />
                    </MenuItem>
                  ))}
              </LabelTextFieldGrid>
            );
          }}
        />
      </FormControl>
      <FormControl fullWidth>
        <Controller
          rules={isCommentRequired ? { required: requiredForm } : undefined}
          control={control}
          name="comment"
          defaultValue=""
          render={({ value, onChange }) => {
            return (
              <LabelTextFieldGrid
                title="Commentaire"
                placeholder="Commentaire"
                value={value}
                onChange={(event) => onChange(event.target.value)}
                required={isCommentRequired}
                errorform={errors.comment}
                customspacingside={0}
                customspacingtop={0}
                rows={4}
                multiline
              />
            );
          }}
        />
      </FormControl>
    </Stack>
  );
}
