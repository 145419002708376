import PageNotFound from 'pages/ErrorPage/PageNotFound/PageNotFound';
import React, { ReactNode } from 'react';
import { Route, Switch } from 'react-router-dom';

interface PolySwitchProps {
  children: ReactNode;
}

export default function PolySwitch(properties: PolySwitchProps) {
  return (
    <Switch>
      {properties.children}
      <Route component={PageNotFound} />
    </Switch>
  );
}
