import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, DialogContent, IconButton, Typography } from '@mui/material';
import { billModalColumns } from 'components/MissionFollowUp/BillDetail/Modals/BillActionModal/BillActionModal';
import LabelTextFieldGrid from 'components/MissionFollowUp/LabelTextFieldGrid';
import PolyDataGrid from 'components/MUIOverload/PolyDataGrid';
import PolyDialog from 'components/MUIOverload/PolyDialog';
import PongoButton from 'components/MUIOverload/PongoButton';
import { BillNode, useDeleteBillMutation } from 'generated/graphql';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'store';
import { removeBill, setCurrentBill } from 'store/purchaseOrder';

import { isBillAlreadyBilled } from '../../utils';

interface BillActionModalProps {
  open: boolean;
  closeModal: () => void;
}

const DeleteBillModal = (properties: BillActionModalProps) => {
  const currentBill = useSelector((state) => state.purchaseOrder.currentBill);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const missionId = useSelector((state) => state.activity.currentMission.id);
  const dispatch = useDispatch();
  const isCurrentBillAlreadyBilled = isBillAlreadyBilled(currentBill);

  const getRows = (_orderBy?: string | string[], _order?: 'asc' | 'desc') => {
    return [currentBill];
  };

  const [deleteBill] = useDeleteBillMutation({
    onCompleted: () => {
      enqueueSnackbar(`Facture « ${currentBill.title} » supprimée`, {
        variant: 'success',
      });
      history.push({
        pathname: '/mission-followup/missions/billing-followup',
        search: `?mission_id=${missionId}`,
      });
      dispatch(removeBill(currentBill.id));
      dispatch(setCurrentBill({} as BillNode));
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const onDelete = async () => {
    await deleteBill({
      variables: {
        bill_id: currentBill.id,
      },
    });
  };

  return (
    <>
      <PolyDialog
        maxWidth={'md'}
        open={properties.open}
        onClose={properties.closeModal}
        disableEnforceFocus={true}
      >
        <IconButton
          onClick={properties.closeModal}
          sx={{ position: 'absolute', top: 0, right: '5px' }}
          color={'primary'}
          size="large"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography variant="h3Bold" color="primary" component="h3">
            Supprimer la facture
          </Typography>
          <Box sx={{ my: 2.5 }}>
            <PolyDataGrid columns={billModalColumns} getRows={getRows} />
          </Box>
          <LabelTextFieldGrid
            title={"Commentaire justifiant la demande d'annulation"}
            multiline
            rows={4}
            disabled
            sizegrid={12}
            customspacingtop={0}
            customspacingside={0}
            sx={{ mb: 2.5 }}
            value={_.get(currentBill, 'cancellationCommentary.message')}
          />
          <Typography>
            Êtes-vous sûr(e) de vouloir supprimer définitivement cette facture ?
            Vous ne pourrez plus récupérer les données de cette dernière.
          </Typography>
          {isCurrentBillAlreadyBilled && (
            <Typography variant="bodyBold">
              Attention : Si la facture a déjà été créée dans Talentia, vous
              devez absolument l&apos;annuler et lui générer un avoir.
            </Typography>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <PongoButton
              variant={'contained'}
              onClick={() => onDelete()}
              startIcon={<DeleteIcon />}
            >
              Supprimer définitivement
            </PongoButton>
          </Box>
        </DialogContent>
      </PolyDialog>
    </>
  );
};

export default DeleteBillModal;
