import { People, Search } from '@mui/icons-material';
import {
  Autocomplete,
  AutocompleteRenderGroupParams,
  AutocompleteRenderInputParams,
  createFilterOptions,
  FilterOptionsState,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { ListBoxComponent } from 'components/commons/Virtualization/ListBoxComponent';
import { usePolyTrombiContext } from 'contexts/Phonebook/PolyTrombiContextProvider';
import { EmployeeNode } from 'generated/graphql';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { forceCheck } from 'react-lazyload';
import { useHistory } from 'react-router-dom';
import { displayEmployeeNode } from 'utils';

import PolyTrombiSearchBarItem from './PolyTrombiSearchBarItem';
import PolyTrombiSearchBarItemSkeleton from './PolyTrombiSearchBarItemSkeleton';

interface AutocompletePaperProps {
  searchProps: React.HTMLAttributes<HTMLElement>;
  isAnyMatchingEmployee: boolean;
  isLoading?: boolean;
}

const filterPolyTrombiOptions = createFilterOptions({
  stringify: (employee: EmployeeNode): string => {
    const toMatchFields = [
      employee.firstName,
      employee.lastName,
      employee.firstName,
    ];
    return _.join(toMatchFields, ' ');
  },
});

const stringifyEmployee = (employee: EmployeeNode) => {
  const toMatchFields = [
    employee.firstName,
    employee.lastName,
    employee.firstName,
  ];
  return _.join(toMatchFields, ' ')
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036F]/g, '');
};

export default function PolyTrombiSearchBar() {
  const history = useHistory();
  const {
    searchedEmployees,
    setSearchedEmployees,
    allEmployees,
    setShowSkeleton,
    isLoading,
  } = usePolyTrombiContext();
  const [isAnyMatchingEmployee, setIsAnyMatchingEmployee] =
    useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string | null>(null);

  const filterOptions = (
    options: EmployeeNode[],
    state: FilterOptionsState<EmployeeNode>
  ) => {
    const filtered = filterPolyTrombiOptions(options, state);
    const matchingEmployees = allEmployees.filter((employee) =>
      stringifyEmployee(employee).includes(
        state.inputValue
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036F]/g, '')
      )
    );
    setIsAnyMatchingEmployee(matchingEmployees.length !== 0);
    return filtered;
  };

  const handleOnChange = async (
    _event: object,
    value: EmployeeNode | string | null
  ) => {
    if (typeof value === 'string') {
      const matchingEmployees = allEmployees.filter((employee) =>
        stringifyEmployee(employee).includes(
          value
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036F]/g, '')
        )
      );
      // This await is necessary for proper lazy loading
      (await matchingEmployees.length) !== 0 &&
        setSearchedEmployees(matchingEmployees);
      forceCheck();
      setInputValue(value);
    } else if (value && value.__typename === 'EmployeeNode') {
      // This await is necessary for proper lazy loading
      await setSearchedEmployees([value]);
      // This await is necessary for proper lazy loading
      await history.push({
        search: '?employee_id=' + value.id,
      });
      forceCheck();
      setInputValue(`${value.firstName} ${value.lastName.toUpperCase()}`);
    } else {
      setSearchedEmployees(allEmployees);
      setShowSkeleton(true);
      setInputValue(null);
    }
  };

  useEffect(() => {
    if (searchedEmployees.length === allEmployees.length) {
      setInputValue('');
    }
  }, [searchedEmployees, allEmployees]);

  return (
    <Autocomplete
      freeSolo
      value={inputValue}
      options={isLoading ? new Array(10).fill(1) : allEmployees}
      filterOptions={filterOptions}
      popupIcon={false}
      disableListWrap
      disablePortal
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        return displayEmployeeNode(option, false);
      }}
      onChange={handleOnChange}
      ListboxComponent={
        ListBoxComponent as React.ComponentType<
          React.HTMLAttributes<HTMLElement>
        >
      }
      groupBy={() => 'Résultats'}
      PaperComponent={(props) => {
        return (
          <AutocompletePaper
            searchProps={props}
            isAnyMatchingEmployee={isAnyMatchingEmployee}
            isLoading={isLoading}
          />
        );
      }}
      renderGroup={AutocompleteRenderEmployeeGroup}
      renderOption={(props, option) =>
        isLoading ? (
          <PolyTrombiSearchBarItemSkeleton style={{}} props={props} />
        ) : (
          <PolyTrombiSearchBarItem style={{}} props={props} employee={option} />
        )
      }
      renderInput={AutocompleteInputRenderer}
      sx={{ width: { xs: '100%', sm: 'auto' } }}
    />
  );
}

function AutocompleteRenderEmployeeGroup(
  params: AutocompleteRenderGroupParams
) {
  const { allEmployees, isLoading } = usePolyTrombiContext();

  const itemData = React.Children.toArray(params.children);

  return [
    <Stack key={params.key} direction={'row'} alignItems={'center'}>
      <Typography
        sx={{
          pl: 2,
          pr: 1,
        }}
        variant="bodyBold"
      >
        {params.group}
      </Typography>
      {allEmployees.length !== itemData.length && !isLoading && (
        <Typography variant="bodySBold">({itemData.length})</Typography>
      )}
    </Stack>,
    params.children,
  ];
}

function AutocompletePaper({
  isAnyMatchingEmployee,
  searchProps,
  isLoading,
}: AutocompletePaperProps) {
  const NO_EMPLOYEE_FOUND_MESSAGE = 'Aucun résultat trouvé';
  const theme = useTheme();

  if (!isAnyMatchingEmployee && !isLoading) {
    return (
      <Paper elevation={3} sx={{ mt: 1 }}>
        <Stack
          alignItems="center"
          justifyContent="center"
          spacing={1}
          sx={{
            height: 130,
          }}
        >
          <People
            fontSize="large"
            sx={{
              p: 1,
              border: '1px dashed',
              borderRadius: 30,
              color: theme.palette.text.secondary,
              opacity: 0.6,
            }}
          />
          <Typography variant="bodyBold">
            {NO_EMPLOYEE_FOUND_MESSAGE}
          </Typography>
        </Stack>
      </Paper>
    );
  }
  return (
    <Paper elevation={3} sx={{ mt: 1 }}>
      {searchProps.children}
    </Paper>
  );
}

function AutocompleteInputRenderer(props: AutocompleteRenderInputParams) {
  const theme = useTheme();
  const [isFocused, setIsFocused] = useState(false);

  return (
    <TextField
      {...props}
      placeholder="Rechercher un collaborateur"
      variant="standard"
      sx={{
        backgroundColor: theme.palette.background.default,
        boxSizing: 'border-box',
        width: { xs: '100%', sm: 325, xl: 432 },
        height: { xs: 56, sm: 42 },
        borderRadius: 2,
        border: '3px solid',
        borderColor: isFocused
          ? theme.palette.info.light
          : theme.palette.background.default,
        input: {
          '&::placeholder': {
            opacity: isFocused ? 0.5 : 1,
          },
        },
        '& .MuiAutocomplete-clearIndicator': {
          mr: 1,
        },
      }}
      InputProps={{
        ...props.InputProps,
        disableUnderline: true,
        sx: {
          fontSize: theme.typography.bodyBold?.fontSize,
          fontWeight: theme.typography.bodyBold?.fontWeight,
          height: '100%',
        },
        startAdornment: (
          <InputAdornment sx={{ marginLeft: 2 }} position="start">
            <Search sx={{ color: theme.palette.text.primary }} />
          </InputAdornment>
        ),
        onFocus: () => setIsFocused(true),
        onBlur: () => setIsFocused(false),
      }}
    />
  );
}
