import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton, Stack } from '@mui/material';
import { SxProps } from '@mui/material/styles';
import React from 'react';
import { useHistory } from 'react-router-dom';

import PolyTitle from '../PolyTitle';

interface PolyHeaderInterface {
  children: React.ReactNode;
  goBackLocation?: Location;
  sx?: SxProps;
}
export default function PolyHeader({
  children,
  goBackLocation,
  sx,
}: PolyHeaderInterface) {
  const history = useHistory();
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      spacing={1}
      sx={{ mb: 3, ...sx }}
    >
      {goBackLocation && (
        <IconButton
          aria-label="back"
          onClick={() => history.push(goBackLocation)}
          size="large"
        >
          <ArrowBackIcon />
        </IconButton>
      )}
      <PolyTitle>{children}</PolyTitle>
    </Stack>
  );
}
