import { Grid, GridProps, SxProps, Theme } from '@mui/material';
import React from 'react';

import styles from './styles/PolyGrid.module.scss';

interface PolyGridProps {
  customspacingtop?: number;
  customspacingside?: number;
  sx?: SxProps<Theme>;
}

const PolyGrid = (properties: GridProps & PolyGridProps) => {
  return (
    <Grid
      {...properties}
      classes={styles}
      style={{
        paddingTop: properties.customspacingtop,
        paddingLeft: properties.customspacingside,
        paddingRight: properties.customspacingside,
      }}
    />
  );
};

PolyGrid.defaultProps = {
  customspacingtop: 30,
  customspacingside: 15,
};
export default PolyGrid;
