import AvatarInitial from 'components/commons/AvatarInitial/AvatarInitial';
import { CommentaryDisplay } from 'components/commons/ListCommentaries/index';
import { displayDateDetails } from 'components/commons/ListCommentaries/utils';
import React from 'react';
import { displayEmployee } from 'utils';

import styles from './styles/ListCommentaries.module.scss';

interface DisplayCommentaryProps {
  commentary: CommentaryDisplay;
}

export default function DisplayCommentary(properties: DisplayCommentaryProps) {
  const creator = properties.commentary.creator;
  const date = new Date(properties.commentary.createdAt);

  return (
    <div className={styles.root}>
      <AvatarInitial employee={creator} />
      <div className={styles.infosContainer}>
        <div className={styles.headerContainer}>
          <div className={styles.nameContainer}>
            {creator
              ? displayEmployee(creator.firstName, creator.lastName)
              : ''}
          </div>
          <div className={styles.dateContainer}>{displayDateDetails(date)}</div>
        </div>
        <div>
          <span className={properties.commentary.className}>
            {properties.commentary.prefix}
          </span>
          {properties.commentary.message}
        </div>
      </div>
    </div>
  );
}
