import { Stack } from '@mui/material';
import { ActivityNode } from 'generated/graphql';
import React from 'react';
import { useSelector } from 'store';
import { getMissionTypeString } from 'utils';

import MissionForm from '../../MissionFollowUp/BillDetail/MissionInitialization/MissionForm';
import PolyAlert from '../PolyAlert';
import PolyAlertTitle from '../PolyAlertTitle';

interface MissionPageWrapperProps {
  isEditMode: boolean;
  closeModal?: () => void;
  hasSuccessfullyInit?: () => void;
  refetch?: () => void;
  handleLoadedContent?: () => void;
  mission?: ActivityNode;
  activeStep?: number;
}

const MissionPageWrapper = ({
  isEditMode,
  closeModal,
  hasSuccessfullyInit,
  refetch,
  handleLoadedContent,
  mission: missionProp,
  activeStep,
}: MissionPageWrapperProps) => {
  const currentMission = useSelector((state) => state.activity.currentMission);
  const mission = missionProp ? missionProp : currentMission;

  const handleMissionFormReady = () => {
    if (handleLoadedContent) {
      handleLoadedContent();
    }
  };

  const isMissionInternal = getMissionTypeString(mission) === 'interne';

  return (
    <Stack>
      {isEditMode ? (
        !isMissionInternal && (
          <PolyAlert severity="warning" variant="outlined">
            <PolyAlertTitle color="warning">Attention ! </PolyAlertTitle>
            Les informations suivantes sont indispensables à la création de bons
            de commande, prestations ou factures. Si vous éditez ces données,{' '}
            <strong>
              les changements affecteront toutes les factures de cette mission.
            </strong>
          </PolyAlert>
        )
      ) : (
        <PolyAlert severity="warning" variant="outlined">
          Veuillez renseigner les informations obligatoires afin d’accéder à la
          suite des fonctionnalités.
        </PolyAlert>
      )}

      <MissionForm
        closeModal={closeModal}
        hasSuccessfullyInit={hasSuccessfullyInit}
        refetch={refetch}
        onLoaded={handleMissionFormReady}
        mission={mission}
        defaultActiveStep={activeStep}
      />
    </Stack>
  );
};

export default MissionPageWrapper;

MissionPageWrapper.defaultProps = {
  isEditMode: false,
};
