import { OvertimeAuthorizationsQuery } from 'generated/graphql';

export const isUndefined = (status?: string) => {
  return status === undefined || status === 'Requested' || status === 'Denied';
};

export const computeTotalDuration = (
  overtimeAuthorizations: DeepExtractTypeSkipArrays<
    OvertimeAuthorizationsQuery,
    ['overtimeAuthorizations']
  >
): number => {
  let result = 0;

  overtimeAuthorizations.overtimeRequests?.forEach((overtimeRequest) => {
    result += overtimeRequest.duration;
  });

  return result;
};
