import { Add } from '@mui/icons-material';
import { Box } from '@mui/material';
import { AbsenceTable, AbsenceWarningTip } from 'components/AbsencePage';
import PageWrapper from 'components/commons/PageWrapper';
import TabHeader from 'components/commons/TabHeader';
import PongoButton from 'components/MUIOverload/PongoButton';
import AbsenceContextProvider, {
  useAbsenceContext,
} from 'contexts/Absence/AbsenceContextProvider';
import AbsenceDeclarationModal from 'pages/AbsenceDeclarationModal';
import { PageTitles } from 'poly-constants';
import React from 'react';

function AbsencePage() {
  return (
    <PageWrapper noLimit>
      <AbsenceContextProvider>
        <TabHeader
          title={PageTitles.absencePage}
          actionComponents={<AddAbsenceButton />}
          ignoreMissionHeader
        />
        <AbsenceWarningTip />
        <AbsenceTable />
        <AbsenceDeclarationModal />
      </AbsenceContextProvider>
    </PageWrapper>
  );
}

function AddAbsenceButton() {
  const { setModalOpen, setModalMode } = useAbsenceContext();

  return (
    <PongoButton
      variant="contained"
      startIcon={<Add />}
      onClick={() => {
        setModalMode('create');
        setModalOpen(true);
      }}
    >
      <Box sx={{ ml: 0.5, textTransform: 'uppercase' }}>
        Ajouter une absence
      </Box>
    </PongoButton>
  );
}

export default AbsencePage;
