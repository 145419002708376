import TabHeader from 'components/commons/TabHeader';
import { PageTitles } from 'poly-constants';
import React from 'react';

interface BillsTabHeaderProps {
  actionComponents?: JSX.Element;
  filterComponents?: React.ReactNode;
  count?: number;
}

export default function BillsTabHeader({
  actionComponents,
  filterComponents,
  count = 0,
}: BillsTabHeaderProps) {
  return (
    <TabHeader
      title={PageTitles.billsPage}
      count={count}
      actionComponents={actionComponents}
      filterComponents={filterComponents}
    />
  );
}
