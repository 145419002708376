import AddAlarmIcon from '@mui/icons-material/AddAlarm';
import AppsIcon from '@mui/icons-material/Apps';
import CachedIcon from '@mui/icons-material/Cached';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined';
import GroupIcon from '@mui/icons-material/Group';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import MovieIcon from '@mui/icons-material/Movie';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PersonIcon from '@mui/icons-material/Person';
import { Grid } from '@mui/material';
import PolyTitle from 'components/commons/PolyTitle';
import CardHomePage from 'components/HomePage/CardHomePage';
import CarouselContextProvider from 'components/HomePage/HomeCarousel/CarouselContextProvider';
import EventCarousel from 'components/HomePage/HomeCarousel/EventCarousel';
import HomeGrid from 'components/HomePage/HomeGrid';
import HomeGridItem from 'components/HomePage/HomeGridItem';
import ModalHomePage from 'components/HomePage/ModalHomePage';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import {
  EmployeeNode,
  EventNode,
  useAllEventsQuery,
  usePolyNewsQuery,
} from 'generated/graphql';
import HTMLReactParser from 'html-react-parser';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './styles/HomePage.module.scss';

const Collaborators = () => {
  return (
    <div className={styles.listContainer}>
      <div className={styles.listItems}>
        <ImportContactsIcon className={styles.listIcons} />
        <div className={styles.listText}>
          <h1 className={styles.listBullet}>•</h1>
          <div className={styles.listTextContent}>
            <Link to={'/phonebook'}>PolyTrombi</Link> : retrouvez l’annuaire de
            tous les colaborateurs de Polyconseil !
          </div>
        </div>
      </div>

      <div className={styles.listItems}>
        <CachedIcon className={styles.listIcons} />
        <div className={styles.listText}>
          <h1 className={styles.listBullet}>•</h1>
          <div className={styles.listTextContent}>
            <Link to={'/activity'}>Suivi d’activité</Link> : complétez votre
            suivi d’activité par semaine sur la page “Déclaration”
          </div>
        </div>
      </div>

      <div className={styles.listItems}>
        <AddAlarmIcon className={styles.listIcons} />
        <div className={styles.listText}>
          <h1 className={styles.listBullet}>•</h1>
          <div className={styles.listTextContent}>
            <Link to={'/my-activity/exceptional-activities/my_authorization'}>
              Activités exceptionnelles{' '}
            </Link>
            : enregistrez vos temps d’activités exceptionnelles
          </div>
        </div>
      </div>

      <div className={styles.listItems}>
        <AppsIcon className={styles.listIcons} />
        <div className={styles.listText}>
          <h1 className={styles.listBullet}>•</h1>
          <div className={styles.listTextContent}>
            Liens externes : retrouvez à tout moment les liens aux autres
            applications Polyconseil dans la barre de navigation
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className={styles.listItems}>
        <HelpOutlineIcon className={styles.listIcons} />
        <div className={styles.listText}>
          <div className={styles.listTextContent}>
            Une question, un retour ? Rendez-vous sur le channel Slack
            <a
              href={'https://polyconseil.slack.com/archives/C01GYPUHHAR'}
              target="_blank"
              rel="noopener noreferrer"
            >
              &nbsp;#pongo-polyconseil
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const Managers = () => {
  return (
    <div className={styles.listContainer}>
      <div className={styles.listItems}>
        <PeopleOutlineIcon className={styles.listIcons} />
        <div className={styles.listText}>
          <h1 className={styles.listBullet}>•</h1>
          <div className={styles.listTextContent}>
            <Link to={'/mission-followup/activity/collaborators'}>
              Suivi temps équipe{' '}
            </Link>
            : parcourez un résumé des suivis d’activité de vos équipes
          </div>
        </div>
      </div>

      <div className={styles.listItems}>
        <CheckIcon className={styles.listIcons} />
        <div className={styles.listText}>
          <h1 className={styles.listBullet}>•</h1>
          <div className={styles.listTextContent}>
            <Link
              to={'/mission-followup/activity/validate_activity_monitoring'}
            >
              Validation suivi régie{' '}
            </Link>
            : validez les temps à facturer pour vos missions de type régie
          </div>
        </div>
      </div>

      <div className={styles.listItems}>
        <InsertDriveFileOutlinedIcon className={styles.listIcons} />
        <div className={styles.listText}>
          <h1 className={styles.listBullet}>•</h1>
          <div className={styles.listTextContent}>
            <Link to={'/mission-followup/missions/billing-followup'}>
              Facturation - suivi de factures{' '}
            </Link>
            : créez puis envoyez vos factures de mission à la comptabilité
          </div>
        </div>
      </div>

      <div className={styles.listItems}>
        <EuroOutlinedIcon className={styles.listIcons} />
        <div className={styles.listText}>
          <h1 className={styles.listBullet}>•</h1>
          <div className={styles.listTextContent}>
            <Link to={'/mission-followup/missions/payments-followup'}>
              Facturation - suivi de paiements{' '}
            </Link>
            : suivez le statut de vos factures une fois envoyées
          </div>
        </div>
      </div>

      <div className={styles.listItems}>
        <ErrorOutlineIcon className={styles.listIcons} />
        <div className={styles.listText}>
          <div className={styles.listTextContent}>
            En cas d’erreur sur le staffing, merci de vous reporter à&nbsp;
            <a
              href={'https://app.napta.io/'}
              target="_blank"
              rel="noopener noreferrer"
            >
              Napta
            </a>
            .
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className={styles.listItems}>
        <HelpOutlineIcon className={styles.listIcons} />
        <div className={styles.listText}>
          <div className={styles.listTextContent}>
            Une question, un retour ? Rendez-vous sur le channel Slack
            <a
              href={'https://polyconseil.slack.com/archives/C01GYPUHHAR'}
              target="_blank"
              rel="noopener noreferrer"
            >
              &nbsp;#pongo-polyconseil
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const WorkOff = () => {
  const ABSENCES_FEATURE_FLAG = useEnvironmentVariable('absences');

  return (
    <div className={styles.listContainer}>
      <div className={styles.newListItem}>
        <div className={styles.listTextNewContent}>
          <h1 className={styles.listBullet}>1.</h1>
          Faites une demande écrite auprès de votre manager de mission, en
          mettant en copie votre PM
        </div>
      </div>

      <div className={styles.newListItem}>
        <div className={styles.listTextNewContent}>
          <h1 className={styles.listBullet}>2.</h1>
          <div className={styles.listTextContent}>
            Une fois votre demande validée par votre manager de mission, il
            faudra poser ces jours dans&nbsp;
            <a
              href={'https://rh.polyconseil.fr/'}
              target={'_blank'}
              rel={'noopener noreferrer'}
            >
              l{"'"}espace HR
            </a>
            &nbsp;(votre PM doit avoir l{"'"}accord écrit de votre manager de
            mission pour pouvoir valider l{"'"}absence dans HR)
          </div>
        </div>
      </div>

      <div className={styles.newListItem}>
        <MovieIcon className={styles.listIcons} sx={{ pl: 5 }} />
        <div className={styles.listTextContent} style={{ paddingLeft: '5px' }}>
          <a
            href={
              'https://drive.google.com/file/d/1xM8XE7UzXOeihTLwCZAjlUo9jwNsqKIt/view'
            }
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            Tuto outil HR
          </a>
        </div>
      </div>

      {!ABSENCES_FEATURE_FLAG && (
        <>
          <div className={styles.newListItem}>
            <div className={styles.listTextNewContent}>
              <div className={styles.listBullet}>3.</div>
              <div className={styles.listTextContent}>
                Saisissez l’absence dans le&nbsp;
                <a
                  href={
                    'https://calendar.google.com/calendar/u/0/r?cid=Y19zODFobTRpZW1qMm1qdHAxMDBlZzFxcjQ5a0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t'
                  }
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  Google Calendar&nbsp;
                </a>
                pour qu’elle apparaisse dans votre suivi d’activité sur Pongo
                (synchronisation toutes les 5 minutes)
              </div>
            </div>
          </div>

          <div className={styles.newListItem}>
            <MovieIcon
              className={styles.listIcons}
              style={{ paddingLeft: '40px' }}
            />
            <div
              className={styles.listTextContent}
              style={{ paddingLeft: '5px' }}
            >
              <a
                href={
                  'https://drive.google.com/file/d/1aWA3dlXn86Sb0JUu-kKXgldseRlvq9mo/view?usp=sharing'
                }
                target={'_blank'}
                rel={'noopener noreferrer'}
              >
                Tuto Gcal
              </a>
            </div>
          </div>

          <div className={styles.newListItem}>
            <div className={styles.listTextNewContent}>
              <h1 className={styles.listBullet}>4.</h1>
              <div className={styles.listTextContent}>
                Mettez à jour&nbsp;
                <a
                  href={'https://polyconseil.coviflex.com/'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Coviflex
                </a>
                &nbsp;pour bien indiquer votre période d’absence
              </div>
            </div>
          </div>

          <div className={styles.newListItem}>
            <div className={styles.listTextNewContent}>
              <h1 className={styles.listBullet}>5.</h1>
              Configurez votre boite mail ainsi que votre Calendrier Google
              individuel pour bien notifier vos collègues de votre absence si
              ils tentent de vous contacter
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const HomeContent = () => {
  const { data: polyNewsData } = usePolyNewsQuery();
  const { data: eventsData } = useAllEventsQuery();
  const EVENTS_FEATURE_FLAG = useEnvironmentVariable('events');
  const [collaboratorsModalVisibility, setCollaboratorsModalVisibility] =
    useState(false);
  const [managersModalVisibility, setManagersModalVisibility] = useState(false);
  const [congesModalVisibility, setCongesModalVisibility] = useState(false);

  return (
    <React.Fragment>
      {EVENTS_FEATURE_FLAG && eventsData && (
        <CarouselContextProvider
          allEvents={(eventsData?.allEvents as EventNode[]) || undefined}
        >
          <EventCarousel />
        </CarouselContextProvider>
      )}
      <PolyTitle sx={{ paddingTop: 2.5 }}>What&apos;s new ?</PolyTitle>
      <div className={styles.homeContainer}>
        <HomeGrid>
          {_.map(polyNewsData?.polyNews, (polyNews) => {
            return (
              <HomeGridItem
                title={polyNews.title || ''}
                content={HTMLReactParser(polyNews.body || '')}
                creator={polyNews.creator as EmployeeNode}
                date={moment(polyNews.createdAt)}
                hasButtonClose={false}
              />
            );
          })}
        </HomeGrid>
        <HomeGrid>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} lg={6}>
              <CardHomePage
                title={'Congés'}
                content={'Comment poser mes congés ?'}
                setModalVisibility={() => {
                  setCongesModalVisibility(true);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <CardHomePage
                title={'Collaborateurs'}
                content={
                  'Que dois-je remplir chaque semaine en tant que collaborateur ?'
                }
                setModalVisibility={() => {
                  setCollaboratorsModalVisibility(true);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CardHomePage
                title={'Managers'}
                content={'Qu’est ce que je peux faire en tant que manager ?'}
                setModalVisibility={() => {
                  setManagersModalVisibility(true);
                }}
              />
            </Grid>
          </Grid>
        </HomeGrid>
        {congesModalVisibility && (
          <ModalHomePage
            title={'Congés'}
            content={WorkOff()}
            headerIcon={<HelpOutlineIcon />}
            modalVisibility={congesModalVisibility}
            setModalVisibility={() => setCongesModalVisibility(false)}
            onClose={() => {
              setCongesModalVisibility(false);
            }}
          />
        )}

        {collaboratorsModalVisibility && (
          <ModalHomePage
            title={'Collaborateurs'}
            content={Collaborators()}
            headerIcon={<PersonIcon />}
            modalVisibility={collaboratorsModalVisibility}
            setModalVisibility={() => setCollaboratorsModalVisibility(false)}
            onClose={() => {
              setCollaboratorsModalVisibility(false);
            }}
          />
        )}
        {managersModalVisibility && (
          <ModalHomePage
            title={'Managers'}
            content={Managers()}
            headerIcon={<GroupIcon />}
            modalVisibility={managersModalVisibility}
            setModalVisibility={() => setManagersModalVisibility(false)}
            onClose={() => {
              setManagersModalVisibility(false);
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default HomeContent;
