import {
  EmployeeSkillNode,
  SkillCategoryNode,
  SkillNode,
} from 'generated/graphql';
import _ from 'lodash';

export function removeEmployeeSkillsFromSkillCategories(
  categories: SkillCategoryNode[],
  employeeSkills: EmployeeSkillNode[]
): SkillCategoryNode[] {
  const employeeSkillIds = new Set(_.map(employeeSkills, 'skill.id'));

  return _.map(categories, (category) => ({
    ...category,
    skills: _.filter(
      category.skills,
      (skill) => !employeeSkillIds.has(skill?.id)
    ),
  }));
}

export function addSkillToSkillCategories(
  categories: SkillCategoryNode[],
  newSkill: SkillNode
): SkillCategoryNode[] {
  const categoryExists = _.some(categories, { id: newSkill.category?.id });
  if (categoryExists) {
    return _.map(categories, (category) => {
      if (category.id === newSkill.category?.id) {
        return {
          ...category,
          skills: [...(category.skills || []), newSkill],
        };
      }
      return category;
    });
  } else {
    const newCategory: SkillCategoryNode = {
      id: newSkill.category?.id,
      name: newSkill.category?.name,
      skills: [newSkill],
    };
    return _.sortBy([...categories, newCategory], 'name');
  }
}

export function removeSkillFromSkillCategories(
  categories: SkillCategoryNode[],
  skillId: string
): SkillCategoryNode[] {
  return _.chain(categories)
    .map((category) => ({
      ...category,
      skills: _.filter(category.skills, (skill) => skill?.id !== skillId),
    }))
    .filter((category) => !_.isEmpty(category.skills))
    .value();
}

export function addSkillToEmployeeSkills(
  employeeSkills: EmployeeSkillNode[],
  newEmployeeSkill: EmployeeSkillNode
): EmployeeSkillNode[] {
  return _.concat(newEmployeeSkill, employeeSkills);
}

export function removeSkillFromEmployeeSkills(
  employeeSkills: EmployeeSkillNode[],
  skillId: string
): EmployeeSkillNode[] {
  return _.filter(
    employeeSkills,
    (employeeSkill) => employeeSkill.skill.id !== skillId
  );
}

export function updateSkillLevelInEmployeeSkills(
  employeeSkills: EmployeeSkillNode[],
  skillId: string,
  newLevel: number
): EmployeeSkillNode[] {
  return _.map(employeeSkills, (employeeSkill) => {
    if (employeeSkill.skill.id === skillId) {
      return {
        ...employeeSkill,
        level: newLevel,
      };
    }
    return employeeSkill;
  });
}
