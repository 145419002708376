import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { SvgIcon } from '@mui/material';

import stylesDelete from './styles/ButtonDelete.module.scss';
import stylesEdit from './styles/ButtonEdit.module.scss';

export interface DotOption<T> {
  name: string;
  handle: (data: T) => void;
  Icon: typeof SvgIcon;
  styles: DotStyles;
  modalBody?: string;
  modalTitle?: string;
  hasModalVerification?: boolean;
}

interface DotStyles {
  root: string;
  icon: string;
  text: string;
}

export const createDeleteAndEditOptions = <T>(
  handleDelete: (data: T) => Promise<void> | void,
  handleEdit: (data: T) => void
): Array<DotOption<T>> => {
  return [
    {
      name: 'Modifier',
      handle: handleEdit,
      Icon: EditIcon,
      styles: stylesEdit,
    },
    {
      name: 'Supprimer',
      handle: handleDelete,
      Icon: CancelIcon,
      styles: stylesDelete,
    },
  ];
};
