import { Icon, Stack, Typography } from '@mui/material';
import { BillingBillSendingMethodChoices } from 'generated/graphql';
import React from 'react';

import {
  getSendingMethodIcon,
  getSendingMethodWording,
} from './BillDetail/utils';

interface DisplayBillSendingMethodProps {
  sendingMethod: BillingBillSendingMethodChoices;
}

export default function DisplayBillSendingMethod({
  sendingMethod,
}: DisplayBillSendingMethodProps) {
  return (
    <Stack direction={'row'} alignItems={'center'} gap={0.75}>
      <Icon color="primary">{getSendingMethodIcon(sendingMethod)}</Icon>
      <Typography component="span" color="primary">
        {getSendingMethodWording(sendingMethod)}
      </Typography>
    </Stack>
  );
}
