import { Box, Button, ButtonProps, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { ElementType } from 'react';
import { OpacitySuffix } from 'utils';

interface PongoButtonProps extends ButtonProps {
  loading?: boolean;
  isFocus?: boolean;
  buttonStyle?: 'primary' | 'secondary' | 'input';
  autoHeight?: boolean;
  uppercase?: boolean;
  component?: ElementType;
}

const PongoButton = ({
  loading,
  isFocus,
  buttonStyle,
  autoHeight,
  component,
  uppercase = true,
  ...buttonProperties
}: PongoButtonProps) => {
  const { typography, palette } = useTheme();

  const secondaryStyle = {
    backgroundColor: isFocus
      ? palette.info.light
      : `${palette.info.light}${OpacitySuffix.FIFTY_PERCENT}`,
    color: palette.primary.main,
    ':hover': {
      backgroundColor: palette.info.light,
      boxShadow: 'none',
    },
  };
  const inputStyle = {
    backgroundColor: isFocus ? palette.info.light : palette.background.default,
    color: palette.text.primary,
    ':hover': {
      backgroundColor: palette.info.light,
      boxShadow: 'none',
    },
  };

  return (
    <Button
      {...(component ? { component: component } : {})}
      disabled={buttonProperties.disabled || loading}
      {...buttonProperties}
      sx={{
        ...typography.buttonBold,
        borderRadius: 2,
        height: autoHeight ? null : 42,
        boxShadow: 'none',
        minWidth: '90px',
        lineHeight: '1',
        whiteSpace: 'nowrap',
        textTransform: uppercase ? 'uppercase' : 'none',
        ':hover': {
          boxShadow: 'none',
        },
        '& .MuiButton-startIcon': {
          m: 0,
          mr: buttonProperties.children ? 1 : 0,
        },
        '& .MuiButton-endIcon': { m: 0, ml: buttonProperties.children ? 1 : 0 },
        ...(buttonStyle === 'secondary' ? secondaryStyle : null),
        ...(buttonStyle === 'input' ? inputStyle : null),
        ...buttonProperties.sx,
      }}
    >
      {buttonProperties.children}
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            inset: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={24} />
        </Box>
      )}
    </Button>
  );
};

export default PongoButton;
