import { TableRow, Tooltip } from '@mui/material';
import EmployeeCell from 'components/commons/Tables/EmployeeCell';
import { isTrimesterStart } from 'components/commons/Tables/Header/ArrowsUpdateTrimesterTableHeader';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import CollapsePolyTableCell from 'components/MUIOverload/PolyTableCell/CollapsePolyTableCell';
import ChildCollapsePolyTableRow from 'components/MUIOverload/PolyTableRow/ChildCollapsePolyTableRow';
import TransitionPolyTableRow from 'components/MUIOverload/PolyTableRow/TransitionPolyTableRow';
import ProfitabilityNotAssignedCell from 'components/Reporting/Profitability/GlobalView/ProfitabilityTableBody/Rows/Cells/ProfitabilityNotAssignedCell';
import {
  EmployeeType,
  monthTotalTimeAndRevenue,
} from 'components/Reporting/Profitability/MissionView/ProfitabilityTableBody/ProfitabilityMissionViewTableBody';
import ProfitabilityEmployeeRenderValues from 'components/Reporting/Profitability/MissionView/ProfitabilityTableBody/Rows/ProfitabilityEmployeeRenderValues';
import {
  isEmployeeIntern,
  StyledCell,
} from 'components/Reporting/Profitability/utils';
import styles from 'components/Revenue/Estimated/EstimatedRevenueTables/EstimatedRevenueGlobalTable/styles/TableCell.module.scss';
import {
  ActivityMonitoringNode,
  ActivityNode,
  AssignmentNode,
  ContractorReportingParameterNode,
  EmployeesEmployeeContractTypeChoices,
} from 'generated/graphql';
import _ from 'lodash';
import { Moment } from 'moment';
import moment from 'moment/moment';
import React, { useState } from 'react';

import ProfitabilityEmployeeSubMissionRow from './ProfitabilityEmployeeSubMissionRow';

interface ProfitabilityEmployeeRowProps {
  employee: EmployeeType;
  isTrimesterView: boolean;
  totalTimeAndRevenues: monthTotalTimeAndRevenue[];
  activity: ActivityNode;
  isExcludingIntern: boolean;
}

export function getTotalTimeSpent(
  employee: EmployeeType,
  ams: ActivityMonitoringNode[],
  month: Moment,
  isTrimesterView: boolean,
  isExcludingIntern: boolean
): number {
  let processedAmList;
  if (isTrimesterView) {
    const secondMonth = month.clone().add(1, 'month');
    const thirdMonth = month.clone().add(2, 'month');
    processedAmList = _.filter(
      ams,
      (am) =>
        (moment(am.date).isSame(month, 'month') &&
          (!isExcludingIntern ||
            !isEmployeeIntern(
              getEffectiveEmployeeContractType(
                employee,
                month
              ) as EmployeesEmployeeContractTypeChoices
            ))) ||
        (moment(am.date).isSame(secondMonth, 'month') &&
          (!isExcludingIntern ||
            !isEmployeeIntern(
              getEffectiveEmployeeContractType(
                employee,
                secondMonth
              ) as EmployeesEmployeeContractTypeChoices
            ))) ||
        (moment(am.date).isSame(thirdMonth, 'month') &&
          (!isExcludingIntern ||
            !isEmployeeIntern(
              getEffectiveEmployeeContractType(
                employee,
                thirdMonth
              ) as EmployeesEmployeeContractTypeChoices
            )))
    );
  } else {
    processedAmList = _.filter(
      ams,
      (am) =>
        moment(am.date).isSame(month, 'month') &&
        (!isExcludingIntern ||
          !isEmployeeIntern(
            getEffectiveEmployeeContractType(
              employee,
              month
            ) as EmployeesEmployeeContractTypeChoices
          ))
    );
  }
  return _.sumBy(processedAmList, 'timeSpent');
}

export function getEmployeeAdrForMonth(
  employee: EmployeeType,
  month: Moment,
  isExcludingIntern: boolean
): number {
  if (
    isExcludingIntern &&
    isEmployeeIntern(
      getEffectiveEmployeeContractType(
        employee,
        month
      ) as EmployeesEmployeeContractTypeChoices
    )
  ) {
    return 0;
  } else if (_.isEmpty(employee.contractorReportingParameters)) {
    return (
      _(employee.monthToEmployeeReportingParameter.get(month.month()))
        .orderBy(['effectiveFrom'], ['desc'])
        .filter((param) =>
          month.isSameOrAfter(moment(param.effectiveFrom), 'date')
        )
        .first()?.adr || 0
    );
  } else {
    return (
      _(
        (employee.contractorReportingParameters ||
          []) as ContractorReportingParameterNode[]
      )
        .orderBy(['effectiveFrom'], ['desc'])
        .filter((param) =>
          month.isSameOrAfter(moment(param.effectiveFrom), 'date')
        )
        .first()?.adr || 0
    );
  }
}

export function getEmployeeAdrForTrimester(
  employee: EmployeeType,
  month: Moment,
  activityMonitorings: ActivityMonitoringNode[],
  isExcludingIntern: boolean
): number {
  const secondMonth = month.clone().add(1, 'month');
  const thirdMonth = month.clone().add(2, 'month');
  const adrFirstMonth = getEmployeeAdrForMonth(
    employee,
    month,
    isExcludingIntern
  );
  const timeFirstMonth = getTotalTimeSpent(
    employee,
    activityMonitorings || [],
    month,
    false,
    isExcludingIntern
  );
  const adrSecondMonth = getEmployeeAdrForMonth(
    employee,
    secondMonth,
    isExcludingIntern
  );
  const timeSecondMonth = getTotalTimeSpent(
    employee,
    activityMonitorings || [],
    secondMonth,
    false,
    isExcludingIntern
  );
  const adrThirdMonth = getEmployeeAdrForMonth(
    employee,
    thirdMonth,
    isExcludingIntern
  );
  const timeThirdMonth = getTotalTimeSpent(
    employee,
    activityMonitorings || [],
    thirdMonth,
    false,
    isExcludingIntern
  );
  return (
    (adrFirstMonth * timeFirstMonth +
      adrSecondMonth * timeSecondMonth +
      adrThirdMonth * timeThirdMonth) /
    (timeFirstMonth + timeSecondMonth + timeThirdMonth)
  );
}

export function isAssigned(
  month: Moment,
  isTrimester: boolean,
  assignments?: AssignmentNode[]
) {
  const periodStart = month.clone().startOf('month');
  const periodEnd = month.clone().endOf('month');

  if (isTrimester) {
    periodEnd.add(2, 'months').endOf('month');
  }
  return _.some(
    assignments,
    (assignment) =>
      (periodEnd.isAfter(assignment.beginningDate) &&
        periodStart.isBefore(assignment.expirationDate)) ||
      (!assignment.beginningDate && !assignment.expirationDate)
  );
}

export function hasActivityMonitoring(
  month: Moment,
  isTrimesterView: boolean,
  ams: ActivityMonitoringNode[] | undefined
) {
  const periodStart = month.clone().startOf('month');
  const periodEnd = month.clone().endOf('month');

  if (isTrimesterView) {
    periodEnd.add(2, 'months').endOf('month');
  }
  return ams?.some((am) =>
    moment(am.date).isBetween(periodStart, periodEnd, 'month', '[]')
  );
}

export function getEffectiveEmployeeContractType(
  employee: EmployeeType,
  month: Moment
): EmployeesEmployeeContractTypeChoices | null {
  const employeeHistory = employee.employeeHistory;
  let foundDate: String | null = null;
  let effectiveContractType: EmployeesEmployeeContractTypeChoices | null = null;
  if (employeeHistory !== undefined) {
    for (const node of employeeHistory) {
      if (node.historyDate && node.historyDate <= month?.format('YYYY-MM-DD')) {
        if (!foundDate || node.historyDate > foundDate) {
          foundDate = node.historyDate;
          if (
            node.contractType !== undefined &&
            node.contractType === 'intern pre job'
          ) {
            effectiveContractType =
              EmployeesEmployeeContractTypeChoices.InternPreJob;
          } else if (
            node.contractType !== undefined &&
            node.contractType === 'cdi'
          ) {
            effectiveContractType = EmployeesEmployeeContractTypeChoices.Cdi;
          } else {
            effectiveContractType = null;
          }
        }
      }
    }
  }

  return effectiveContractType;
}

export function isEmployeeInternOnPeriod(
  employee: EmployeeType,
  month: Moment,
  isTrimesterView: boolean
): boolean {
  const months = isTrimesterView
    ? [month, month.clone().add(1, 'month'), month.clone().add(1, 'month')]
    : [month];
  return _.every(months, (month) =>
    isEmployeeIntern(
      getEffectiveEmployeeContractType(
        employee,
        month
      ) as EmployeesEmployeeContractTypeChoices
    )
  );
}

export default function ProfitabilityEmployeeRow({
  employee,
  isTrimesterView,
  totalTimeAndRevenues,
  activity,
  isExcludingIntern,
}: ProfitabilityEmployeeRowProps) {
  const { displayedMonths } = useTableHeaderContext();
  const monthsToDisplay = isTrimesterView
    ? _.filter(displayedMonths, (month) => isTrimesterStart(month))
    : displayedMonths;
  const [openCollapse, setOpenCollapse] = useState(false);
  function hasActivityMonitoringButNoAssignment(
    monthsToDisplay: Moment[],
    isTrimesterView: boolean,
    employee: EmployeeType
  ) {
    if (
      _.some(
        monthsToDisplay,
        (month) =>
          !isAssigned(month, isTrimesterView, employee.assignments) &&
          hasActivityMonitoring(
            month,
            isTrimesterView,
            employee.activityMonitoring
          )
      )
    ) {
      return (
        <StyledCell
          sx={{
            width: '300px',
            border: '1px solid',
            borderColor: 'orange.main',
            textAlign: 'left',
            paddingLeft: '16px',
          }}
        >
          <Tooltip
            title={
              "Le collaborateur a déclaré du temps mais n'est plus assigné à cette mission."
            }
            arrow
          >
            {EmployeeCell({
              employee: employee,
              effectiveEmployeeContractType: getEffectiveEmployeeContractType(
                employee,
                monthsToDisplay[0]
              ),
              sx: { fontSize: '14px' },
            })}
          </Tooltip>
        </StyledCell>
      );
    } else {
      return (
        <CollapsePolyTableCell
          open={openCollapse}
          setOpen={setOpenCollapse}
          className={styles.tableCell}
          hasSubActivities={activity.subActivities?.length !== 0}
        >
          <EmployeeCell
            employee={employee}
            effectiveEmployeeContractType={getEffectiveEmployeeContractType(
              employee,
              monthsToDisplay[0]
            )}
          />
        </CollapsePolyTableCell>
      );
    }
  }

  return (
    <>
      <TableRow>
        <>
          {hasActivityMonitoringButNoAssignment(
            displayedMonths,
            isTrimesterView,
            employee
          )}
          {_.map(monthsToDisplay, (month) => {
            if (
              !isAssigned(month, isTrimesterView, employee.assignments) &&
              !hasActivityMonitoring(
                month,
                isTrimesterView,
                employee.activityMonitoring
              )
            ) {
              return (
                <ProfitabilityNotAssignedCell
                  key={`month-${month.format('MMMM')}-employee-${employee.id}`}
                />
              );
            }
            if (!isTrimesterView || isTrimesterStart(month)) {
              const totalTimeAndRevenue = totalTimeAndRevenues.find(
                (totalTimeAndRevenue) =>
                  month.isSame(moment(totalTimeAndRevenue.month), 'month')
              );

              const isWarning = !isAssigned(
                month,
                isTrimesterView,
                employee.assignments
              );

              return (
                <ProfitabilityEmployeeRenderValues
                  month={month}
                  totalRevenue={totalTimeAndRevenue?.totalRevenue}
                  totalTime={totalTimeAndRevenue?.totalTime}
                  totalCollaboratorTime={getTotalTimeSpent(
                    employee,
                    employee.activityMonitoring || [],
                    month,
                    isTrimesterView,
                    isExcludingIntern
                  )}
                  adrCollaborator={
                    isTrimesterView
                      ? getEmployeeAdrForTrimester(
                          employee,
                          month,
                          employee.activityMonitoring as ActivityMonitoringNode[],
                          isExcludingIntern
                        )
                      : getEmployeeAdrForMonth(
                          employee,
                          month,
                          isExcludingIntern
                        )
                  }
                  sx={{
                    fontSize: '0.8rem',
                    ...(isWarning && {
                      border: '1px solid',
                      borderColor: 'orange.main',
                    }),
                  }}
                  isWarning={isWarning}
                  key={`month-${month.format('MMMM')}-employee-${employee.id}`}
                  isExcludingIntern={isExcludingIntern}
                  isEmployeeInternOnPeriod={isEmployeeInternOnPeriod(
                    employee,
                    month,
                    isTrimesterView
                  )}
                />
              );
            }
            return <></>;
          })}
        </>
      </TableRow>
      <TransitionPolyTableRow open={openCollapse}>
        {(status) => {
          return (
            <>
              {_.map(activity.subActivities, (subActivity) => {
                return (
                  <ChildCollapsePolyTableRow
                    status={status}
                    key={`sub-activity-${subActivity.id}-employee-${employee.id}`}
                  >
                    <ProfitabilityEmployeeSubMissionRow
                      employee={employee}
                      isTrimesterView={isTrimesterView}
                      subActivity={subActivity}
                      isExcludingIntern={isExcludingIntern}
                    />
                  </ChildCollapsePolyTableRow>
                );
              })}
            </>
          );
        }}
      </TransitionPolyTableRow>
    </>
  );
}
