import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';

import styles from './styles/PolyPopperDrawer.module.scss';

interface PolyPopperDrawerProps {
  variant: 'small' | 'medium' | 'big';
  orientation: 'left' | 'right';
}

const PolyPopperDrawer = ({
  children,
  variant,
  orientation,
}: PropsWithChildren<PolyPopperDrawerProps>) => {
  return (
    <div className={styles.root}>
      <div
        className={clsx(styles.triangle, {
          [styles.smallTriangle]: variant === 'small',
          [styles.mediumTriangle]: variant === 'medium',
          [styles.bigTriangle]: variant === 'big',
        })}
      />
      <div
        className={clsx(styles.rectangle, {
          [styles.rectangleRight]: orientation === 'right',

          [styles.smallRectangle]: variant === 'small',
          [styles.smallRectangleRight]:
            variant === 'small' && orientation === 'right',

          [styles.mediumRectangle]: variant === 'medium',
          [styles.mediumRectangleRight]:
            variant === 'medium' && orientation === 'right',

          [styles.bigRectangle]: variant === 'big',
          [styles.bigRectangleRight]:
            variant === 'big' && orientation === 'right',
        })}
      >
        <div
          className={clsx({ [styles.childrenRight]: orientation === 'right' })}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default PolyPopperDrawer;
