import Section from 'components/commons/Section';
import SectionTitle from 'components/commons/SectionTitle';
import MissionsList from 'components/Phonebook/EmployeeCard/Missions/MissionsList';
import {
  ActivityNode,
  EmployeeNode,
  useAllActivitiesForUserQuery,
} from 'generated/graphql';
import _ from 'lodash';
import React from 'react';
import { graphQlDateFormatter } from 'utils';

interface UserMissionsListProps {
  employee: EmployeeNode;
}

const UserMissionsList = ({ employee }: UserMissionsListProps) => {
  const { data: activities } = useAllActivitiesForUserQuery({
    variables: {
      employeeId: employee.id,
      dateFrom: graphQlDateFormatter(new Date()),
      dateTo: graphQlDateFormatter(new Date()),
    },
  });

  const filteredActivities = _.filter(
    activities?.allActivitiesForUser,
    (activity) => !activity?.isShared && !activity?.mainActivity
  );

  return (
    <Section
      sx={{
        height: '250px',
      }}
    >
      <SectionTitle>Missions</SectionTitle>
      <MissionsList
        activities={filteredActivities as ActivityNode[]}
        assignments={employee.assignments}
        showTitle={false}
        sx={{ overflow: 'auto', height: '100%' }}
      />
    </Section>
  );
};

export default UserMissionsList;
