import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PolyModal from 'components/MUIOverload/PolyDialog/PolyModal';
import {
  EmployeeParameterInput,
  EmployeeReportingParameterNode,
  ReportingEmployeeGrade,
  useCreateEmployeeReportingParametersMutation,
} from 'generated/graphql';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useForm } from 'react-hook-form';

import { useAdrContext } from '../AdrContextProvider';

interface ModalProps {
  open: boolean;
  setOpen: (p: boolean) => void;
  data?: EmployeeReportingParameterNode;
  setCurrentAdr: (p: number) => void;
  currentAdr: number;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: `solid`,
  borderWidth: '1px 1px 1px 1px',
  borderColor: theme.palette.secondary[300],
}));

export default function OccupationAdrModal({
  open,
  setOpen,
  data,
  setCurrentAdr,
  currentAdr,
}: ModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { year } = useAdrContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onSubmit',
  });

  const [callMutation] = useCreateEmployeeReportingParametersMutation({
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    onCompleted: (data) => {
      enqueueSnackbar(`TJM mis à jour`, {
        variant: 'success',
      });
      setCurrentAdr(data.createEmployeeReportingParameters?.[0].adr || 0);
      setOpen(false);
    },
  });

  const saveData = async (formValues: { adr: number }) => {
    const mutationParameter: EmployeeParameterInput = {
      adr: Number.parseFloat(formValues.adr.toString()),
      occupation: data?.occupation as string,
      grade: data?.grade.valueOf() as ReportingEmployeeGrade,
    };

    await callMutation({
      variables: {
        year,
        employeeParameterList: [mutationParameter],
      },
    });
  };

  return (
    <PolyModal
      open={open}
      onClose={() => setOpen(false)}
      polyDialogTitle="Édition de TJM"
      handlePrimary={handleSubmit(saveData)}
      childrenInForm
    >
      <>
        <Table sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Typography fontWeight={'bold'}>Rôle</Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography fontWeight={'bold'}>Contrat</Typography>
              </StyledTableCell>
              <StyledTableCell align="center" width="100px">
                <Typography fontWeight={'bold'}>TJM Coût</Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell>{data?.occupation}</StyledTableCell>
              <StyledTableCell>{data?.grade}</StyledTableCell>
              <StyledTableCell>
                <TextField
                  variant="standard"
                  size={'small'}
                  name="adr"
                  placeholder={currentAdr.toString()}
                  inputRef={register({
                    required: '* Champ requis',
                    min: {
                      value: 1,
                      message: '* Le TJM doit être supérieur à 0',
                    },
                    valueAsNumber: true,
                  })}
                  color="secondary"
                  error={!!errors.adr?.message}
                />
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
        {errors.adr && (
          <Typography sx={{ color: 'red', paddingTop: '20px' }}>
            {errors.adr.message}
          </Typography>
        )}
      </>
    </PolyModal>
  );
}
