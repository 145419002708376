import { Grid } from '@mui/material';
import AddItem from 'components/commons/ItemInteractions/AddItem/AddItem';
import { AverageDailyRateCollaboratorNode } from 'generated/graphql';
import useOnMountEffect from 'hooks/useOnMountEffect';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'store';

import PoFormCollaboratorCard from './PoFormCollaboratorCard';
import { PoFormMode } from './PurchaseOrderForm';

interface PoFormCollaboratorsProps {
  fieldName: string;
  required?: boolean;
  mode?: PoFormMode;
  includeAllCollaborators: boolean;
  setIncludeAllCollaborators: (param: boolean) => void;
}

export default function PoFormCollaborators({
  fieldName,
  required,
  mode,
  includeAllCollaborators,
  setIncludeAllCollaborators,
}: PoFormCollaboratorsProps) {
  const form = useFormContext();
  const { watch, setValue, control, unregister } = form;
  const employees = useSelector(
    (state) => state.users.currentActivityEmployees
  );

  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
  });

  useOnMountEffect(() => {
    if (mode === PoFormMode.CREATE && fields.length === 0) {
      append({ collaborator: null, dailyRate: '', title: '' });
    }
  });

  useEffect(() => {
    if (includeAllCollaborators) {
      _.map(employees, (employee) => {
        if (!fields.some((e) => e.collaborator?.id === employee.id)) {
          append({ collaborator: employee });
        }
      });
      if (fields[0] && !fields[0].collaborator) {
        remove(0);
      }
    }
  }, [includeAllCollaborators, append, employees, fields, remove]);

  const deleteCollaborator = (index: number) => {
    remove(index);
    // if required, tmcontract mission must have at least one collaborator
    if (required && fields.length === 1) {
      append({ collaborator: null, dailyRate: '', title: '' });
    }
    unregister(`${fieldName}.collaborator`);
    form.clearErrors(`${fieldName}.collaborator`);
    form.clearErrors(`${fieldName}.title`);
    form.clearErrors(`${fieldName}.dailyRate`);
    setIncludeAllCollaborators(false);
  };

  return (
    <React.Fragment>
      {_.map(fields, (field, index) => {
        const fieldNameIndex = `${fieldName}.${index}`;
        if (`${watch(`${fieldNameIndex}.dailyRate`)}`.includes('.')) {
          setValue(
            `${fieldNameIndex}.dailyRate`,
            `${watch(`${fieldNameIndex}.dailyRate`)}`.replace('.', ',')
          );
        }
        return (
          <PoFormCollaboratorCard
            key={field.id}
            index={index}
            fieldName={fieldNameIndex}
            itemCollaborator={field as AverageDailyRateCollaboratorNode}
            deleteCollaborator={() => deleteCollaborator(index)}
          />
        );
      })}
      <Grid
        item
        xs={6}
        sx={{
          height: '220px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <AddItem
          onClick={() =>
            append({ collaborator: null, dailyRate: '', title: '' })
          }
          description={'Nouveau collaborateur'}
        />
      </Grid>
    </React.Fragment>
  );
}
