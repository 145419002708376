import BackdropLoading from 'components/commons/BackdropLoading';
import PageContent from 'components/commons/PageContent';
import { useOvertimeRequestsQuery } from 'generated/graphql';
import React from 'react';

import { filterOvertimeRequest } from './utils';

const TotalRecuperationTime = () => {
  const { data: overtimeRequestsData, loading: loadingOvertimeRequests } =
    useOvertimeRequestsQuery({ variables: { asManager: false } });

  const getTotalRecuperationTime = (): number => {
    let result = 0;

    for (const request of filterOvertimeRequest(
      overtimeRequestsData?.overtimeRequests
    ) ?? []) {
      result += request.recoveryRemaining;
    }

    return result;
  };

  return (
    <>
      <PageContent>
        Vous disposez de {getTotalRecuperationTime()} jours supplémentaires à
        récupérer
      </PageContent>
      <BackdropLoading open={loadingOvertimeRequests} />
    </>
  );
};

export default TotalRecuperationTime;
