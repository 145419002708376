import { Stack } from '@mui/material';
import clsx from 'clsx';
import AvatarInitial from 'components/commons/AvatarInitial/AvatarInitial';
import {
  BorderedTableCell,
  CenteredTableCell,
} from 'components/commons/Tables/FlatTable';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import ChildCollapsePolyTableRow from 'components/MUIOverload/PolyTableRow/ChildCollapsePolyTableRow';
import PolyTooltip from 'components/MUIOverload/PolyTooltip';
import {
  TableMode,
  useTableContext,
} from 'components/Revenue/RevenueGlobalTable/context/TableContextProvider';
import { BillingActivityInformationRevenue } from 'components/Revenue/RevenueGlobalTable/RevenueTableBodyByClient';
import styles from 'components/Revenue/RevenueGlobalTable/styles/TableCell.module.scss';
import DisplayValueTableCell from 'components/Revenue/Tables/RevenueTableCells/DisplayValueTableCell';
import OutOfRangeTableCell from 'components/Revenue/Tables/RevenueTableCells/OutOfRangeTableCell';
import {
  ActivityNode,
  BillingPurchaseOrderCurrencyChoices,
} from 'generated/graphql';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { totalFormat } from 'pages/ActivityPage/utils';
import { POLY_DATE_MONTH } from 'poly-constants';
import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { TransitionStatus } from 'react-transition-group/Transition';
import { displayEmployeeNode } from 'utils';

import { getMissionLastActiveMonth } from '../utils';

interface PoRowProps {
  billingMission: BillingActivityInformationRevenue;
  currentMonthNotValidated?: boolean;
  status: TransitionStatus;
  amountPerMonthForActivity?:
    | {
        amounts: number[] | undefined;
        activity: ActivityNode | undefined;
      }
    | undefined;
}

export default function MissionRow({
  billingMission,
  status,
  currentMonthNotValidated,
  amountPerMonthForActivity,
}: PoRowProps) {
  const { displayedMonths, mode } = useTableContext();
  const { currentYear } = useTableHeaderContext();
  const history = useHistory();
  const { pathname } = useLocation();
  const mission = billingMission.billingMission.activity as ActivityNode;

  const missionPath = history.createHref({
    pathname: pathname,
    search: `?mission_id=${mission.id}&year=${currentYear.year()}`,
  });

  const isActive = (month: Moment) => {
    return month.isBetween(
      mission.startDate,
      getMissionLastActiveMonth(billingMission.billingMission),
      'month',
      '[]'
    );
  };

  const isActiveInYear = (year: Moment) => {
    return year.isBetween(
      mission.startDate,
      getMissionLastActiveMonth(billingMission.billingMission),
      'year',
      '[]'
    );
  };

  const hasPurchaseOrder = (month: Moment) => {
    return billingMission.billingMission.purchaseOrders?.find((po) =>
      month.isBetween(po.periodBeginning, po.periodEnding, 'month', '[]')
    );
  };

  if (!isActiveInYear(displayedMonths[0])) {
    return <></>;
  }
  return (
    <>
      <ChildCollapsePolyTableRow
        status={status}
        className={styles.revenueRowHeight}
      >
        <BorderedTableCell>
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <PolyTooltip title={displayEmployeeNode(mission.director, false)}>
              <span>
                <AvatarInitial employee={mission.director} />
              </span>
            </PolyTooltip>
            <Link
              to={missionPath}
              rel={'noopener noreferrer'}
              className={styles.link}
            >
              <div>{mission.name}</div>
            </Link>
          </Stack>
        </BorderedTableCell>
        {_.map(displayedMonths, (month) => {
          if (
            !isActive(month) ||
            (mode === TableMode.ACTUAL &&
              !hasPurchaseOrder(month) &&
              !isActive(month))
          ) {
            return mode === TableMode.ACTUAL ? (
              <OutOfRangeTableCell
                key={`total-mission-${
                  billingMission.billingMission.id
                }-${month.format(POLY_DATE_MONTH)}`}
                title={
                  !isActive(month)
                    ? "La mission n'est pas active sur ce mois"
                    : 'La mission n’a pas de BDC actif sur ce mois'
                }
              />
            ) : (
              <OutOfRangeTableCell
                key={`total-mission-${
                  billingMission.billingMission.id
                }-${month.format(POLY_DATE_MONTH)}`}
                title="Aucune facture facturée ce mois"
              />
            );
          }
          const missionTotal = _.find(billingMission.totals, (total) =>
            total.month.isSame(month)
          );
          if (missionTotal?.amount || mode === TableMode.ACTUAL) {
            return (
              <DisplayValueTableCell
                key={`total-mission-${
                  billingMission.billingMission.id
                }-${month.format(POLY_DATE_MONTH)}`}
                title={
                  month.isSame(moment(), 'month') &&
                  moment().isSame(currentYear, 'year') &&
                  currentMonthNotValidated
                    ? 'Le CA de la mission pour ce mois n’a pas été validé'
                    : ''
                }
                className={styles.missionText}
                sx={{
                  ...(month.isSame(moment(), 'month') &&
                    moment().isSame(currentYear, 'year') &&
                    currentMonthNotValidated && {
                      borderColor: 'orange.main',
                    }),
                }}
              >
                {totalFormat(
                  mode === TableMode.BILLED
                    ? amountPerMonthForActivity?.amounts?.[month.get('month')]
                    : missionTotal?.amount || 0,
                  BillingPurchaseOrderCurrencyChoices.Eur
                )}
              </DisplayValueTableCell>
            );
          }
          return (
            <OutOfRangeTableCell
              key={`total-mission-${
                billingMission.billingMission.id
              }-${month.format(POLY_DATE_MONTH)}`}
              title="Aucune facture facturée ce mois"
            />
          );
        })}
        <CenteredTableCell
          className={clsx(styles.missionText)}
          padding={'none'}
        >
          {totalFormat(
            mode === TableMode.BILLED
              ? _.sum(amountPerMonthForActivity?.amounts)
              : billingMission.total,
            BillingPurchaseOrderCurrencyChoices.Eur
          )}
        </CenteredTableCell>
      </ChildCollapsePolyTableRow>
    </>
  );
}
