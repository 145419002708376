import { Tooltip, TooltipProps } from '@mui/material';
import { tooltipSx } from 'components/commons/SX/commonSx';
import React from 'react';

export enum PolyTooltipMode {
  NORMAL,
  ERROR,
}

interface PolyTooltipProps {
  mode?: PolyTooltipMode;
}

export default function PolyTooltip({
  mode,
  children,
  ...toolTipProperties
}: PolyTooltipProps & TooltipProps) {
  return (
    <Tooltip
      {...toolTipProperties}
      sx={{
        ...(mode === PolyTooltipMode.ERROR && {
          backgroundColor: 'error.main',
          ...tooltipSx,
        }),
      }}
    >
      {children}
    </Tooltip>
  );
}

PolyTooltip.defaultProps = {
  mode: PolyTooltipMode.NORMAL,
};
