import { DialogContent, Stack } from '@mui/material';
import PolyDialog from 'components/MUIOverload/PolyDialog';
import PolyModalHeader from 'components/MUIOverload/PolyDialog/DefaultComponents/PolyModalHeader';
import React from 'react';

import TrophyImageResizer from '../TrophyImageResizer';

export interface TrophyResizeModalProps {
  isOpen: boolean;
  imgSrc: string;
  handleClose: () => void;
  setImgSrc: (dataUrl: string) => void;
  setTrophyImage: (file: File | undefined) => void;
}

export default function TrophyResizeModal({
  isOpen,
  handleClose,
  imgSrc,
  setImgSrc,
  setTrophyImage,
}: TrophyResizeModalProps) {
  return (
    <PolyDialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEnforceFocus={true}
    >
      <DialogContent>
        <PolyModalHeader
          handleClose={handleClose}
          polyDialogTitle={"Redimension de l'image"}
          sx={{ paddingBottom: 2 }}
        />
        <Stack>
          <TrophyImageResizer
            src={imgSrc}
            handleClose={handleClose}
            onSave={async (dataURL: string) => {
              const blob = await (await fetch(dataURL)).blob();
              const file = new File([blob], 'trophy.jpg');
              setTrophyImage(file);
              setImgSrc(dataURL);
              handleClose();
            }}
          />
        </Stack>
      </DialogContent>
    </PolyDialog>
  );
}
