import { AppBar, Box } from '@mui/material';
import { useUserInfo } from 'components/User/UserProvider';
import React from 'react';

import { useActiveTab } from './hooks/useActiveTab';
import NavbarMenu from './NavbarMenu';
import NavBarTools from './NavbarTools';
import { NavigationItem } from './utils';

export const MOBILE_NAVBAR_MIN_HEIGHT = '65px';

const MobileNavbar = () => {
  const { isFirstCo } = useUserInfo();
  const mobileNavigationItems: NavigationItem[] = [
    {
      name: 'PolyTrombi',
      to: '/phonebook',
    },
    {
      name: 'Applications',
      to: '/applications',
    },
    {
      name: 'Déclaration',
      to: '/my-activity/working-days',
    },
  ];

  const activeTab = useActiveTab();

  return (
    <AppBar
      position="sticky"
      sx={{
        bgcolor: 'white',
        width: 1,
        zIndex: (theme) => theme.zIndex.drawer + 1,
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.30)',
      }}
    >
      <Box
        sx={{
          width: 1,
          display: 'flex',
          alignItems: 'center',
          minHeight: MOBILE_NAVBAR_MIN_HEIGHT,
        }}
      >
        <NavbarMenu navigationItems={mobileNavigationItems} value={activeTab} />
        <NavBarTools displayAvatar={!isFirstCo} />
      </Box>
    </AppBar>
  );
};

export default MobileNavbar;
