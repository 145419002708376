import { Chip } from '@mui/material';
import {
  ActivitiesActivityBillingTypeChoices,
  ActivitiesActivityTypeChoices,
  ActivityNode,
} from 'generated/graphql';
import { uniq } from 'lodash';
import moment from 'moment';
import { EMPTY_FIELD } from 'poly-constants';
import React from 'react';

export enum ActivityStatusEnum {
  ONGOING = 'En cours',
  FUTURE = 'Future',
  PAST = 'Passée',
}

export enum ActivityTypeEnum {
  TM = 'Régie',
  PACKAGE = 'Forfait',
  INT = 'Interne',
  CFT = 'AO',
  HR = 'Vie du cabinet',
}

export const getAllSectorsNames = (allActivities: ActivityNode[]) => {
  const sectorsNames: string[] = [];

  allActivities.forEach((activity) => {
    if (
      !activity.billingInformation ||
      !activity.billingInformation.billingClient ||
      !activity.billingInformation.billingClient.sector
    ) {
      sectorsNames.push(EMPTY_FIELD);
      return;
    }

    sectorsNames.push(activity.billingInformation.billingClient.sector.name);
  });

  return uniq(sectorsNames);
};

export const getActivityStatus = (
  startDate: Date | undefined,
  expirationDate?: string | Date | undefined
) => {
  if (moment(expirationDate).isBefore(moment(), 'day')) {
    return ActivityStatusEnum.PAST;
  }
  if (startDate && moment(startDate).isAfter(moment())) {
    return ActivityStatusEnum.FUTURE;
  }
  return ActivityStatusEnum.ONGOING;
};

export const getActivityStatusChip = (status: ActivityStatusEnum) => {
  let variant: 'passed' | 'upcoming' | 'pending' = 'pending';

  switch (status) {
    case ActivityStatusEnum.PAST:
      variant = 'pending';
      break;
    case ActivityStatusEnum.ONGOING:
      variant = 'passed';
      break;
    case ActivityStatusEnum.FUTURE:
      variant = 'upcoming';
      break;
    default:
      break;
  }

  return <Chip label={status} variant={variant} size="small" />;
};

export const getActivityTypeName = (activity: ActivityNode) => {
  const billingType = activity.billingType;
  const type = activity.type;

  switch (type) {
    case ActivitiesActivityTypeChoices.Cft:
      return ActivityTypeEnum.CFT;
    case ActivitiesActivityTypeChoices.Int:
      return ActivityTypeEnum.INT;
    case ActivitiesActivityTypeChoices.Hr:
      return ActivityTypeEnum.HR;
    case ActivitiesActivityTypeChoices.Ext:
      if (billingType === ActivitiesActivityBillingTypeChoices.Tm) {
        return ActivityTypeEnum.TM;
      } else {
        return ActivityTypeEnum.PACKAGE;
      }
    default:
      return EMPTY_FIELD;
  }
};
