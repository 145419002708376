import PersonTwoToneIcon from '@mui/icons-material/PersonTwoTone';
import WorkTwoToneIcon from '@mui/icons-material/WorkTwoTone';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { DateRangePicker } from 'components/commons/DateRangePicker';
import { Dayjs } from 'dayjs';
import { ActivityNode, EmployeeNode } from 'generated/graphql';
import moment from 'moment';
import { EMPTY_FIELD, POLY_DATE } from 'poly-constants';
import * as React from 'react';

const RangeShortcutsPanel: React.FC<{
  children: React.ReactNode;
  activity: ActivityNode;
  employee: EmployeeNode;
}> = ({ children, activity, employee }) => {
  return (
    <React.Fragment>
      <Box sx={{ m: 2 }} display="flex" gap={2}>
        <Stack width={250} gap={3.875} mt={0.5}>
          <Typography color={'text.primary'} align="center">
            Informations utiles
          </Typography>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <WorkTwoToneIcon fontSize="small" color="secondary" />
            <Typography color={'text.secondary'} variant={'bodySemiBold'}>
              Infos mission
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Stack direction="column">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={'space-between'}
              >
                <Typography color={'text.secondary'} variant={'bodyS'}>
                  Date de début
                </Typography>
                <Typography variant="bodySSemiBold">
                  {(activity.startDate &&
                    moment(activity.startDate).format(POLY_DATE)) ||
                    EMPTY_FIELD}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="column">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={'space-between'}
              >
                <Typography color={'text.secondary'} variant={'bodyS'}>
                  Date de fin
                </Typography>
                <Typography variant="bodySSemiBold">
                  {(activity.expirationDate &&
                    moment(activity.expirationDate).format(POLY_DATE)) ||
                    EMPTY_FIELD}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <PersonTwoToneIcon fontSize="small" color="secondary" />
            <Typography color={'text.secondary'} variant={'bodySemiBold'}>
              Infos collaborateur
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Stack direction="column">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={'space-between'}
              >
                <Typography color={'text.secondary'} variant={'bodyS'}>
                  Date d&apos;embauche
                </Typography>
                <Typography variant="bodySSemiBold">
                  {(employee.hiringDate &&
                    moment(employee.hiringDate).format(POLY_DATE)) ||
                    EMPTY_FIELD}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="column">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={'space-between'}
              >
                <Typography color={'text.secondary'} variant={'bodyS'}>
                  Date de départ
                </Typography>
                <Typography variant="bodySSemiBold">
                  {(employee.leavingDate &&
                    moment(employee.leavingDate).format(POLY_DATE)) ||
                    EMPTY_FIELD}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Divider orientation="vertical" />
      </Box>
      {children}
    </React.Fragment>
  );
};

interface AssignmentDateRangePickerProps {
  value: [Date, Date];
  onChange: (d: [Date, Date]) => void;
  onError?: () => void;
  onClearError?: () => void;
  activity: ActivityNode;
  employee: EmployeeNode;
  disabled?: boolean;
}

export default function AssignmentDateRangePicker({
  value,
  onChange,
  onError,
  onClearError,
  activity,
  employee,
  disabled = false,
}: AssignmentDateRangePickerProps) {
  const minDate =
    activity.startDate && employee.hiringDate
      ? moment
          .max(moment(activity.startDate), moment(employee.hiringDate))
          .toDate()
      : activity.startDate || employee.hiringDate;
  const maxDate =
    activity.expirationDate && employee.leavingDate
      ? moment
          .min(moment(activity.expirationDate), moment(employee.leavingDate))
          .toDate()
      : activity.expirationDate || employee.leavingDate;

  return (
    <DateRangePicker
      disabled={disabled}
      textDisabled={disabled}
      dateRange={value}
      disableFuture={false}
      handleDateRangeChange={onChange}
      handleDateRangeError={onError}
      handleDateRangeClearError={onClearError}
      allowSameDayRange
      externallyControlled
      minDate={new Date(minDate)}
      maxDate={new Date(maxDate)}
      shouldDisableDate={isWeekend}
      components={{
        PaperContent: RangeShortcutsPanel,
      }}
      componentsProps={{ paperContent: { activity, employee } }}
      PaperProps={{ sx: { display: 'flex', flexDirection: 'row' } }}
    />
  );
}

const isWeekend = (date: Dayjs) => {
  const day = date.day();
  return day === 0 || day === 6;
};
