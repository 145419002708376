import { Box, useMediaQuery, useTheme } from '@mui/material';
import DesktopNavbar from 'components/Navigation/DesktopNavbar';
import NavbarContextProvider from 'components/Navigation/DesktopNavbar/NavbarContextProvider';
import MobileNavbar from 'components/Navigation/MobileNavbar';
import PolySwitch from 'components/Navigation/PolySwitch';
import PolyTrombiWrapper from 'components/Phonebook/PolyTrombiWrapper/PolyTrombiWrapper';
import { useUserInfo } from 'components/User/UserProvider';
import { useTrackPageView } from 'MatomoContextProvider';
import ApplicationsPage from 'pages/ApplicationsPage';
import ClientPage from 'pages/ClientPage';
import EmployeePage from 'pages/EmployeePage';
import HomePage from 'pages/HomePage';
import MissionPage from 'pages/MissionPage';
import TrophyPage from 'pages/Polytrombi/TrophyPage';
import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isUserPongoCollaborator } from 'utils';

import ActivityPage from '../ActivityPage';
import RequestLookup from '../ExceptionalActivities/RequestLookup';
import FirstConnectionPage from '../FirstConnectionPage';
import MissionFollowUpV2 from '../MissionFollowUpV2';
import UserPage from '../UserPage';

const URL_REDIRECT_ACTIVITIES = '/my-activity/working-days';
export const STANDARD_SIDEBAR_DRAWER_WIDTH = '300px';
export const COLLAPSED_SIDEBAR_DRAWER_WIDTH = '84px';
export const DRAWER_STATE_LOCAL_STORAGE_KEY = 'isDrawerOpen';

const MainPage = () => {
  useTrackPageView(); //Necessary to track pages on Matomo
  const userInfo = useUserInfo();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));
  const storedDrawerState = localStorage.getItem(
    DRAWER_STATE_LOCAL_STORAGE_KEY
  );
  const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(
    storedDrawerState ? JSON.parse(storedDrawerState) : true
  );
  useEffect(() => {
    localStorage.setItem(
      DRAWER_STATE_LOCAL_STORAGE_KEY,
      JSON.stringify(isDrawerOpen)
    );
  }, [isDrawerOpen]);
  const drawerWidth = isDrawerOpen
    ? STANDARD_SIDEBAR_DRAWER_WIDTH
    : COLLAPSED_SIDEBAR_DRAWER_WIDTH;

  const isFirstCo = userInfo.isFirstCo;

  const isPongoCollaborator = isUserPongoCollaborator(userInfo);

  return (
    <Box>
      <NavbarContextProvider
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        drawerWidth={drawerWidth}
      >
        {isPhone ? <MobileNavbar /> : <DesktopNavbar />}
        <Box
          sx={{
            height: '100%',
            ...(!isPhone && {
              position: 'relative',
              left: drawerWidth,
              width: `calc(100% - ${drawerWidth})`,
            }),
          }}
        >
          <PolySwitch>
            <Route
              exact
              path="/user/first_connection"
              component={FirstConnectionPage}
            />
            {userInfo.employee && isFirstCo && (
              <Redirect from="/" to="/user/first_connection" />
            )}
            {userInfo.employee && !isPhone && (
              <Redirect exact from="/" to="/home" />
            )}
            {userInfo.employee && !isPhone && (
              <Redirect from="/activity" to={URL_REDIRECT_ACTIVITIES} />
            )}
            <Route exact path="/phonebook" component={PolyTrombiWrapper} />
            <Route exact path="/applications" component={ApplicationsPage} />
            <Route path="/my-activity" component={ActivityPage} />
            <Route exact path="/user" component={UserPage} />
            {!isPhone && (
              <PolySwitch>
                <Route exact path="/home" component={HomePage} />
                <Route path="/mission-followup" component={MissionFollowUpV2} />
                <Route path="/employees" component={EmployeePage} />
                <Route path="/activities" component={MissionPage} />
                <Route path={'/request-lookup'} component={RequestLookup} />
                {isPongoCollaborator && (
                  <Route
                    exact
                    path="/phonebook/trophy"
                    component={TrophyPage}
                  />
                )}
                <Route path="/clients" component={ClientPage} />
              </PolySwitch>
            )}
            {isPhone && <Redirect to="/phonebook" />}
          </PolySwitch>
        </Box>
      </NavbarContextProvider>
    </Box>
  );
};

export default MainPage;
