import { School, SupervisedUserCircle } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { EmployeesEmployeeContractTypeChoices } from 'generated/graphql';
import React from 'react';

interface ContractTypeIconProps {
  type: EmployeesEmployeeContractTypeChoices;
  className?: string;
}

const ContractTypeIcon = (properties: ContractTypeIconProps) => {
  switch (properties.type) {
    case EmployeesEmployeeContractTypeChoices.StudentApprentice:
      return (
        <Tooltip title={'Alternant'}>
          <School className={properties.className} />
        </Tooltip>
      );
    case EmployeesEmployeeContractTypeChoices.Intern:
    case EmployeesEmployeeContractTypeChoices.InternPreJob:
      return (
        <Tooltip title={'Stagiaire'}>
          <School className={properties.className} />
        </Tooltip>
      );
    case EmployeesEmployeeContractTypeChoices.Provider:
      return (
        <Tooltip title="Prestataire">
          <SupervisedUserCircle className={properties.className} />
        </Tooltip>
      );
    default:
      return <span />;
  }
};

export default ContractTypeIcon;
