import PongoButton from 'components/MUIOverload/PongoButton';
import React, { useState } from 'react';

import OccupationAdrAddModal from './OccupationAdrAddModal';

interface OccupationAdrAddButtonProps {
  occupations: string[];
  refetch: () => void;
}

export default function OccupationAdrAddButton({
  occupations,
  refetch,
}: OccupationAdrAddButtonProps) {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  return (
    <>
      <PongoButton
        variant={'contained'}
        buttonStyle="secondary"
        onClick={() => setIsAddModalOpen(true)}
        size="small"
      >
        Ajouter un rôle
      </PongoButton>
      <OccupationAdrAddModal
        occupations={occupations}
        open={isAddModalOpen}
        handleValidation={refetch}
        handleClose={() => setIsAddModalOpen(false)}
      />
    </>
  );
}
