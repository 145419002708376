import MessageIcon from '@mui/icons-material/Message';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import {
  BillNode,
  CommentaryNode,
  useCreateCommentaryMutation,
} from 'generated/graphql';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { setCurrentBill } from 'store/purchaseOrder';

import styles from './styles/ListCommentaries.module.scss';

interface AddCommentaryProps {
  onSubmit: () => void;
}

interface SubmitType {
  commentary: string;
}

export default function AddCommentary(properties: AddCommentaryProps) {
  const { register, handleSubmit, reset, watch } = useForm();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [sendCommentary] = useCreateCommentaryMutation({
    onCompleted: (data) => {
      const commentaries = data.createCommentary?.bill?.commentaries;
      if (!commentaries) {
        enqueueSnackbar('Erreur lors de la récupération des commentaires', {
          variant: 'error',
        });
        return;
      }
      const bill: BillNode = {
        ...currentBill,
        commentaries: commentaries as CommentaryNode[],
      };
      dispatch(setCurrentBill(bill));
      properties.onSubmit();
      reset();
    },
    onError: () => {
      enqueueSnackbar("Erreur lors de l'enregistrement du commentaire", {
        variant: 'error',
      });
    },
  });
  const currentBill = useSelector((state) => state.purchaseOrder.currentBill);

  function onSubmit(values: SubmitType) {
    sendCommentary({
      variables: {
        message: values.commentary,
        billId: currentBill.id,
      },
    });
  }

  return (
    <div className={styles.addContainer}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          variant={'outlined'}
          sx={{ color: 'text.secondary' }}
          className={styles.addInput}
          placeholder={'Ajouter un commentaire...'}
          inputRef={register({ required: true })}
          data-testid="input-comment"
          name={'commentary'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  type={'submit'}
                  disabled={!watch('commentary')}
                  data-testid={'submitCommentary'}
                  size="large"
                >
                  <MessageIcon
                    className={
                      !watch('commentary') ? styles.messageIconDisabled : ''
                    }
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </form>
    </div>
  );
}
