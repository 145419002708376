import { Box, Grid } from '@mui/material';
import FieldDisplay from 'components/commons/FieldDisplay/FieldDisplay';
import { totalFormat } from 'pages/ActivityPage/utils';
import React, { ReactElement } from 'react';
import { useSelector } from 'store';

interface BilledAmountProps {
  total: number;
}

export default function BilledAmount({
  total,
}: BilledAmountProps): ReactElement {
  const currentPurchaseOrder = useSelector(
    (state) => state.purchaseOrder.currentPurchaseOrder
  );
  return (
    <Grid xs={6}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          pl: 2,
          pt: 2,
        }}
      >
        <FieldDisplay
          title={'Montant facturé (HT)'}
          value={totalFormat(total, currentPurchaseOrder.currency)}
          titleVariant={'h3Bold'}
          valueVariant={'h3Bold'}
        />
      </Box>
    </Grid>
  );
}
