import BillActionModal, {
  BillNumberInput,
} from 'components/MissionFollowUp/BillDetail/Modals/BillActionModal/BillActionModal';
import styles from 'components/MissionFollowUp/BillDetail/Modals/CreateOrUpdateBillModal/styles/BillModal.module.scss';
import DisplayBillStatus from 'components/MissionFollowUp/OverallListBill/DisplayStatus/DisplayBillStatus';
import {
  BillingBillStatusChoices,
  BillNode,
  useUpdateBillStatusMutation,
} from 'generated/graphql';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { setCurrentBill } from 'store/purchaseOrder';

interface ManagerStatusUpdateModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  groupAction?: boolean;
}

export default function ManagerUpdateStatusModal({
  open,
  onClose,
  onSuccess,
  groupAction,
}: ManagerStatusUpdateModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const currentBill = useSelector((state) => state.purchaseOrder.currentBill);
  const selectedBills = useSelector(
    (state) => state.purchaseOrder.selectedBills
  );

  const [updateStatus] = useUpdateBillStatusMutation({
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    onCompleted: (data) => {
      const bill = data.updateBillStatus?.bills?.[0] as BillNode;
      enqueueSnackbar(
        groupAction
          ? 'Les statuts des factures ont été mis à jour'
          : ` Le statut de la facture « ${currentBill.title} » a été mis à jour`,
        {
          variant: 'success',
        }
      );
      if (!groupAction) {
        dispatch(setCurrentBill(bill));
      }
      if (onSuccess) {
        onSuccess();
      }
      onClose();
    },
  });

  const defaultValues = {
    bills: [] as BillNumberInput[],
    status: BillingBillStatusChoices.ToBill,
  };

  const form = useForm({
    defaultValues: defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldUnregister: false,
  });

  const onSubmit = async (submitValues: typeof defaultValues) => {
    await updateStatus({
      variables: {
        status: submitValues.status,
        bills: _.map(submitValues.bills, (bill) => {
          return {
            id: bill.id || '',
            billNumber: bill.number,
          };
        }),
      },
    });
  };

  return (
    <BillActionModal
      open={open}
      title={
        'Valider ' + (selectedBills.length > 1 ? 'les factures' : 'la facture')
      }
      closeModal={onClose}
      formProvider={{
        form: form,
        submit: () => form.handleSubmit(onSubmit)(),
      }}
      groupAction={groupAction}
      bodyModal={
        <>
          <p>
            Souhaitez-vous transmettre
            {selectedBills.length > 1 ? ' ces factures' : ' cette facture'} à la
            comptabilité ?
          </p>
          <p className={styles.text}>
            {selectedBills.length > 1
              ? 'Ces factures passeront '
              : 'Cette facture passera '}
            du statut
            <span className={styles.statusIcon}>
              <DisplayBillStatus status={BillingBillStatusChoices.Draft} />
            </span>
            au statut
            <span className={styles.statusIcon}>
              <DisplayBillStatus status={BillingBillStatusChoices.ToBill} />
            </span>
          </p>
          <p className={styles.bold}>
            Attention : Aucune modification de votre part ne sera possible
            ensuite
          </p>
        </>
      }
    />
  );
}
