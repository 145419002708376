import MessageIcon from '@mui/icons-material/Message';
import { Tooltip, Typography } from '@mui/material';
import React from 'react';

interface CommentTooltipProps {
  comment: string;
}

const CommentTooltip = ({ comment }: CommentTooltipProps) => (
  <Tooltip
    title={
      <Typography whiteSpace="pre-wrap" variant="labelMedium" color="white">
        {comment}
      </Typography>
    }
  >
    <MessageIcon fontSize="small" sx={{ color: 'standardGrey.light' }} />
  </Tooltip>
);

export default CommentTooltip;
