import { Box, Stack, Typography } from '@mui/material';
import ButtonTab from 'components/commons/ButtonTab/ButtonTab';
import NoResultIcon from 'components/commons/NoResultIcon/NoResultIcon';
import { usePolyTrombiContext } from 'contexts/Phonebook/PolyTrombiContextProvider';
import { ActivityNode } from 'generated/graphql';
import _ from 'lodash';
import React, { useState } from 'react';

import EmployeeTable from '../EmployeeTable';
import DescriptionDrawer from './DescriptionDrawer';

interface MissionEmployeesTableProps {
  mission: ActivityNode;
}

export default function MissionEmployeeList({
  mission,
}: MissionEmployeesTableProps) {
  const { displayedEmployees } = usePolyTrombiContext();
  const [drawerOpened, setDrawerOpened] = useState<boolean>(false);
  const missionEmployees = _.filter(displayedEmployees, (employee) => {
    return _.some(
      mission.assignments,
      (assignment) => assignment?.employee?.id === employee.id
    );
  });

  const toggleDrawer = () => {
    setDrawerOpened(!drawerOpened);
  };

  return (
    <Box mb={4}>
      <>
        <Stack direction="column" alignItems="flex-start" sx={{ mb: 3 }}>
          <Stack
            direction="row"
            alignItems="center"
            sx={{ justifyItems: 'center', gap: 1, marginBottom: 1 }}
          >
            <Typography variant="h2Bold" component="h2">
              {mission.name}
            </Typography>
            <Typography variant="h3Bold" component="h3">
              ({missionEmployees.length})
            </Typography>
          </Stack>
          <ButtonTab
            buttonTitle="Voir la description"
            onClick={() => toggleDrawer()}
          />
        </Stack>
        {missionEmployees.length === 0 ? (
          <NoResultIcon />
        ) : (
          <EmployeeTable employees={missionEmployees} />
        )}
      </>
      <DescriptionDrawer
        activityId={mission.id}
        drawerOpened={drawerOpened}
        setDrawerOpened={setDrawerOpened}
      />
    </Box>
  );
}
