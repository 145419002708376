import { Divider, Link, Stack, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';
import { SxProps } from '@mui/system';
import { OverridableStringUnion } from '@mui/types';
import React from 'react';

interface FieldDisplayProperties {
  title: string;
  value?: string | JSX.Element;
  divider?: boolean;
  valueVariant?: OverridableStringUnion<
    Variant | 'inherit',
    TypographyPropsVariantOverrides
  >;
  titleVariant?: OverridableStringUnion<
    Variant | 'inherit',
    TypographyPropsVariantOverrides
  >;
  flexDirection?: 'row' | 'column';
  noWrap?: boolean;
  showColon?: boolean;
  sx?: SxProps;
  isHyperLink?: boolean;
  openNewTab?: boolean;
}

export default function FieldDisplay({
  title,
  value,
  divider,
  valueVariant,
  titleVariant,
  flexDirection = 'row',
  noWrap = true,
  showColon = true,
  isHyperLink = false,
  openNewTab = false,
  sx,
}: FieldDisplayProperties) {
  return (
    <Stack
      direction={flexDirection}
      justifyContent={'space-between'}
      alignItems={flexDirection === 'row' ? 'center' : 'flex-start'}
      sx={sx}
    >
      <Typography
        color={'textSecondary'}
        flexGrow={1}
        sx={{ mr: 1 }}
        display={'flex'}
        noWrap={noWrap}
        variant={titleVariant}
      >
        {title}
        {showColon && ' :'}
      </Typography>
      {divider && <Divider style={{ flexGrow: 25 }} />}
      <Typography
        color={'textPrimary'}
        fontWeight={''}
        flexGrow={1}
        display={'flex'}
        noWrap={noWrap}
        justifyContent={isHyperLink ? 'flex-start' : 'flex-end'}
        variant={valueVariant}
        sx={isHyperLink ? { maxWidth: '25rem' } : undefined}
      >
        {isHyperLink && typeof value === 'string' ? (
          <Link
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
            href={value}
            target={openNewTab ? '_blank' : undefined}
            rel={openNewTab ? 'noopener noreferrer' : undefined}
          >
            {value}
          </Link>
        ) : (
          value || ''
        )}
      </Typography>
    </Stack>
  );
}
