import { Button, Link, Tooltip, Typography } from '@mui/material';
import React from 'react';

interface Props {
  children: string | undefined;
  href: string;
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  size?: 'small' | 'medium' | 'large';
  icon?: JSX.Element | undefined;
  textMaxWidth?: number;
  openNewTab?: boolean;
  showTooltip?: boolean;
}

const ButtonLink = ({
  children,
  href,
  color = 'inherit',
  size = 'medium',
  icon,
  textMaxWidth = 300,
  openNewTab = false,
  showTooltip = false,
}: Props) => {
  return (
    <Link
      href={href}
      target={openNewTab ? '_blank' : undefined}
      rel={openNewTab ? 'noopener noreferrer' : undefined}
    >
      <Button variant="text" size={size} color={color} startIcon={icon}>
        <Tooltip title={children} disableHoverListener={!showTooltip} arrow>
          <Typography
            noWrap
            component="span"
            sx={{
              fontWeight: 'bold',
              fontSize: 'inherit',
              color: 'inherit',
              maxWidth: textMaxWidth,
            }}
          >
            {children}
          </Typography>
        </Tooltip>
      </Button>
    </Link>
  );
};

export default ButtonLink;
