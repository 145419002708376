import { Box } from '@mui/material';
import PolyDataGrid from 'components/MUIOverload/PolyDataGrid';
import StringCell from 'components/MUIOverload/PolyDataGrid/components/StringCell';
import { PolyDataGridColumn } from 'components/MUIOverload/PolyDataGrid/PolyDataGridHead';
import { TrophyNode } from 'generated/graphql';
import React, { useCallback, useMemo } from 'react';

interface TrophyTableProps {
  trophies: TrophyNode[];
  refetch: () => void;
  setOpenEditModal: () => void;
  setOpenDeleteModal: () => void;
  setCurrentTrophy: (arg0: TrophyNode) => void;
}

const TrophyTable = ({
  trophies,
  setOpenEditModal,
  setCurrentTrophy,
}: TrophyTableProps) => {
  const columns: PolyDataGridColumn<
    DeepExtractTypeSkipArrays<TrophyNode, []>
  >[] = useMemo(
    () => [
      {
        headerName: 'Trophée',
        propertyName: 'Trophy',
        isSortable: true,
        render: (data) => StringCell({ data: data.name, noTooltip: true }),
      },
      {
        headerName: 'Date',
        propertyName: 'createdAt',
        isSortable: false,
        render: (data) =>
          StringCell({
            data: data.createdAt,
            noTooltip: true,
          }),
      },
      {
        headerName: 'Nombre de trophées remis',
        propertyName: 'NumberOfEmployeesWithTrophy',
        isSortable: false,
        width: '5%',
        render: (data) =>
          StringCell({
            data: data.employees?.length.toString(),
            noTooltip: true,
          }),
      },
    ],
    []
  );

  const onClickCallBack = useCallback(
    (selectedTrophy: TrophyNode) => {
      setCurrentTrophy(selectedTrophy);
      setOpenEditModal();
    },
    [setOpenEditModal, setCurrentTrophy]
  );

  const rowsActive = useCallback(
    (_orderBy?: string | string[], _order?: 'asc' | 'desc') => {
      return trophies;
    },
    [trophies]
  );

  return (
    <Box mt={4}>
      <PolyDataGrid
        columns={columns}
        getRows={rowsActive}
        rowsCallback={onClickCallBack}
      />
    </Box>
  );
};

export default TrophyTable;
