import { Box } from '@mui/material';
import DisplayBillPaymentStatus from 'components/MissionFollowUp/OverallListBill/DisplayStatus/DisplayBillPaymentStatus';
import DisplayBillStatus from 'components/MissionFollowUp/OverallListBill/DisplayStatus/DisplayBillStatus';
import {
  BillingBillPaymentStatusChoices,
  BillingBillStatusChoices,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { POLY_DATE } from 'poly-constants';
import React from 'react';

export enum BillField {
  'date' = 'À facturer le',
  'billing_date' = 'Date de facturation',
  'due_date' = "Date d'échéance",
  'payment_date' = 'Date de paiement',
  'title' = 'Libellé',
  'period_beginning' = 'Date de début',
  'period_ending' = 'Date de fin',
  'amount' = 'Montant',
  'total' = 'Montant',
  'bill_number' = 'Numéro de facture',
  'status' = 'Statut',
  'payment_status' = 'Statut de paiement',
  'bill_pdf' = 'PDF Facture',
  'attachments' = 'Pièces jointes',
}

export interface HistoryChange {
  field?: keyof typeof BillField;
  old?: string;
  new?: string;
}

export const formatTimeStamp = (date: string | undefined) => {
  return date
    ? moment(date).format(POLY_DATE).toString() +
        ' à ' +
        moment(date).format('HH:mm').toString()
    : '';
};

const getBillField = (changedFields: Array<HistoryChange>) => {
  const fieldNames = _.flatMap(changedFields, (change) => {
    return change.field ? BillField[change.field] : [];
  });
  return fieldNames.length === 1
    ? fieldNames[0]
    : fieldNames.slice(0, -1).join(', ') + ' et ' + fieldNames.slice(-1);
};

export const getDescription = (
  changedFields: Array<HistoryChange>,
  isPDFGenerated: boolean,
  PDFFilename?: string
) => {
  const modifyStatus = _.find(changedFields, (change) => {
    return change.field === 'status';
  });
  const oldStatus = modifyStatus?.old as BillingBillStatusChoices;
  const newStatus = modifyStatus?.new as BillingBillStatusChoices;
  if (modifyStatus) {
    return (
      <>
        {' a modifié le statut de la facture de'}
        <Box
          component="span"
          sx={{
            display: 'inline-flex',
            transform: 'scale(0.9)',
            position: 'relative',
            top: 4,
          }}
        >
          <DisplayBillStatus status={oldStatus} />
        </Box>
        {' à '}
        <Box
          component="span"
          sx={{
            display: 'inline-flex',
            transform: 'scale(0.9)',
            position: 'relative',
            top: 4,
          }}
        >
          <DisplayBillStatus status={newStatus} />
        </Box>
      </>
    );
  }

  const modifyPaymentStatus = _.find(changedFields, (change) => {
    return change.field === 'payment_status';
  });
  const oldPaymentStatus =
    modifyPaymentStatus?.old as BillingBillPaymentStatusChoices;
  const newPaymentStatus =
    modifyPaymentStatus?.new as BillingBillPaymentStatusChoices;
  if (modifyPaymentStatus) {
    return (
      <>
        {' a modifié le statut de la facture de'}
        <Box
          component="span"
          sx={{
            display: 'inline-flex',
            transform: 'scale(0.9)',
            position: 'relative',
            top: 4,
          }}
        >
          <DisplayBillPaymentStatus status={oldPaymentStatus} />
        </Box>
        {' à '}
        <Box
          component="span"
          sx={{
            display: 'inline-flex',
            transform: 'scale(0.9)',
            position: 'relative',
            top: 4,
          }}
        >
          <DisplayBillPaymentStatus status={newPaymentStatus} />
        </Box>
      </>
    );
  }

  if (isPDFGenerated) {
    return <> Le PDF Facture a été généré : {PDFFilename}</>;
  }

  if (
    _.some(changedFields, (change) => {
      return change.field === 'attachments';
    })
  ) {
    return <>{' a modifié les pièces jointes'}</>;
  }

  const singleChange = changedFields.length === 1;
  return (
    <>
      {singleChange ? ' a modifié le champ ' : ' a modifié les champs '}
      <Box component="span" sx={{ fontWeight: 'bold', fontStyle: 'italic' }}>
        {getBillField(changedFields)}
      </Box>
      {' de la facture'}
    </>
  );
};
