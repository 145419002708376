import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconButton, TableCell } from '@mui/material';
import { Typography } from '@mui/material';
import ChildCollapsePolyTableRow from 'components/MUIOverload/PolyTableRow/ChildCollapsePolyTableRow';
import { useTableContext } from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/context/TableContextProvider';
import {
  getPoMode,
  PoMode,
} from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/RevenueRows/PoRow';
import PoTotalRow from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/RevenueRows/PoTotalRow';
import RevenueFormTableCell from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/RevenueTableCells/RevenueFormTableCell';
import collapseStyles from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/styles/CollapseStyles.module.scss';
import { isInRange } from 'components/Revenue/Tables/util';
import { PurchaseOrderNode } from 'generated/graphql';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';

import revenueTableStyles from '../styles/TableCell.module.scss';
import styles from './styles/ValidationModal.module.scss';

interface ValidationModalPoRowProps {
  po: PurchaseOrderNode;
  month: Moment;
}

const ValidationModalPoRow = ({ po, month }: ValidationModalPoRowProps) => {
  const { activity } = useTableContext();
  const poMode = getPoMode(activity, po);
  const isSimple = poMode === PoMode.SIMPLE;
  const isTask = poMode === PoMode.TASK;
  const [openCollapse, setOpenCollapse] = useState(isSimple);

  const getTaskValidationCells = (
    po: PurchaseOrderNode,
    status: TransitionStatus
  ) => {
    return _.map(po.tasks, (task) => {
      if (!isInRange(task.periodBeginning, task.periodEnding, month)) {
        return <React.Fragment key={`validate-task-${task.id}`} />;
      }
      return (
        <ChildCollapsePolyTableRow
          key={`validate-task-${task.id}`}
          className={revenueTableStyles.poRow}
          status={status || 'entered'}
        >
          <TableCell className={collapseStyles.nameCell}>{task.name}</TableCell>
          <RevenueFormTableCell
            keyForm={`revenuesByPo.${po.id}.revenues.${task.id}`}
            period={{
              start: task.periodBeginning,
              end: task.periodEnding,
            }}
            purchaseOrder={po}
            month={month}
            viewOnly
          />
        </ChildCollapsePolyTableRow>
      );
    });
  };

  if (
    month.isBefore(po.periodBeginning, 'month') ||
    month.isAfter(moment(po.periodEnding).add(1, 'months'), 'month')
  ) {
    return <React.Fragment key={`validate-${po.id}-${month}`} />;
  }

  return (
    <React.Fragment>
      <ChildCollapsePolyTableRow
        key={`${po.id}-${month}-valid`}
        status={'entered'}
        className={revenueTableStyles.poRow}
      >
        <TableCell className={revenueTableStyles.regularTableCell}>
          <div className={collapseStyles.collapseContainer}>
            <Typography
              sx={{ padding: 1, whiteSpace: 'normal', textAlign: 'left' }}
            >
              {po.name}
            </Typography>
            <IconButton
              size="small"
              onClick={() => setOpenCollapse(!openCollapse)}
              className={styles.collapseButton}
              data-testid={'collapseButton'}
            >
              {openCollapse ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </div>
        </TableCell>
        {isSimple ? (
          <RevenueFormTableCell
            keyForm={`revenuesByPo.${po.id}.revenues`}
            period={{
              start: po.periodBeginning,
              end: po.periodEnding,
            }}
            purchaseOrder={po}
            month={month}
            viewOnly
            isSimple
          />
        ) : (
          <PoTotalRow purchaseOrder={po} month={month} />
        )}
      </ChildCollapsePolyTableRow>
      <Transition unmountOnExit timeout={250} in={openCollapse}>
        {(status) => {
          return (
            <React.Fragment>
              {isTask && getTaskValidationCells(po, status)}
            </React.Fragment>
          );
        }}
      </Transition>
    </React.Fragment>
  );
};

export default ValidationModalPoRow;
