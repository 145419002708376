import { Box, Chip, Divider, List, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FilterListItem from 'components/Phonebook/PhonebookFinder/FilterListItem';
import { checkEmployeeStatusWithFilter } from 'components/Phonebook/PhonebookFinder/utils';
import PolyTrombiSearchFilterDropdownMenu from 'components/Phonebook/PolyTrombiSearchFilters/PolyTrombiSearchFilterDropdownMenu';
import { getStatusName } from 'components/Phonebook/utils';
import { usePolyTrombiContext } from 'contexts/Phonebook/PolyTrombiContextProvider';
import {
  EmployeesEmployeeContractTypeChoices,
  EmployeesEmployeeGradeChoices,
} from 'generated/graphql';
import _ from 'lodash';
import React from 'react';

export default function PolyTrombiStatusSearchFilter() {
  const { palette } = useTheme();
  const {
    searchedEmployees,
    employeesFilteredByMissions,
    employeesFilteredByRole,
    selectedStatus,
    setSelectedStatus,
    setShowSkeleton,
  } = usePolyTrombiContext();

  const employeesConsideredForStatusCount = [
    searchedEmployees,
    employeesFilteredByMissions,
    employeesFilteredByRole,
  ].reduce((array1, array2) =>
    array1.filter((employee1) =>
      array2.some((employee2) => employee2.id === employee1.id)
    )
  );

  const getStatusFilterEmployeeCount = (
    status: EmployeesEmployeeContractTypeChoices | EmployeesEmployeeGradeChoices
  ) => {
    const employeeCount = _.filter(
      employeesConsideredForStatusCount,
      (employee) => checkEmployeeStatusWithFilter([status], employee)
    ).length;

    return employeeCount;
  };

  const statusList = [
    EmployeesEmployeeContractTypeChoices.StudentApprentice,
    EmployeesEmployeeContractTypeChoices.Cdi,
    EmployeesEmployeeGradeChoices.Coman,
    EmployeesEmployeeGradeChoices.Comex,
    EmployeesEmployeeContractTypeChoices.Provider,
    EmployeesEmployeeContractTypeChoices.Intern,
  ];

  const statusListWithCount = statusList
    .map((status) => ({
      name: status,
      count: getStatusFilterEmployeeCount(status),
    }))
    .sort((first, second) => second.count - first.count);

  const displayedStatus = _.filter(
    statusListWithCount,
    (statusFromList) =>
      !_.some(selectedStatus, (status) => status === statusFromList.name)
  );

  const handleStatusSelect = (
    targetStatus:
      | EmployeesEmployeeGradeChoices
      | EmployeesEmployeeContractTypeChoices
  ) => {
    if (!_.some(selectedStatus, (status) => status === targetStatus)) {
      setSelectedStatus([...selectedStatus, targetStatus]);
      setShowSkeleton(true);
      window.scrollTo(0, 0);
    }
  };

  const handleStatusDelete = (
    targetStatus:
      | EmployeesEmployeeGradeChoices
      | EmployeesEmployeeContractTypeChoices
  ) => {
    setSelectedStatus(
      _.filter(selectedStatus, (status) => status !== targetStatus)
    );
    setShowSkeleton(true);
    window.scrollTo(0, 0);
  };

  return (
    <PolyTrombiSearchFilterDropdownMenu
      title="Statuts"
      filterCount={selectedStatus.length}
    >
      <Box sx={{ width: 220 }}>
        <Box>
          {_.map(
            selectedStatus,
            (
              status:
                | EmployeesEmployeeGradeChoices
                | EmployeesEmployeeContractTypeChoices
            ) => {
              const statusName = getStatusName(status);
              return (
                <Chip
                  key={'selected-status-' + statusName}
                  label={
                    <Typography variant="bodyBold" color="info.main">
                      {statusName}
                    </Typography>
                  }
                  sx={{ mr: 1, mb: 1, backgroundColor: palette.info.light }}
                  onDelete={() => handleStatusDelete(status)}
                />
              );
            }
          )}
        </Box>
        {selectedStatus.length > 0 &&
          selectedStatus.length < statusList.length && (
            <Divider sx={{ mb: 1, borderColor: palette.info.main }} />
          )}
        <List disablePadding>
          {_.map(displayedStatus, (status) => {
            return (
              <FilterListItem
                key={'displayed-status-' + status.name}
                onClick={() => handleStatusSelect(status.name)}
              >
                <Stack direction="row" alignItems="center">
                  {getStatusName(status.name)}
                  <Typography
                    variant="bodySSemiBold"
                    color="info.main"
                    sx={{ ml: 0.5 }}
                  >
                    ({status.count})
                  </Typography>
                </Stack>
              </FilterListItem>
            );
          })}
        </List>
      </Box>
    </PolyTrombiSearchFilterDropdownMenu>
  );
}
