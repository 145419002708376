import { TableCell, TableCellProps } from '@mui/material';
import clsx from 'clsx';
import styles from 'components/Revenue/RevenueGlobalTable/styles/TableCell.module.scss';
import React from 'react';

const TotalTableCell = (properties: TableCellProps) => {
  return (
    <TableCell
      {...properties}
      className={clsx(
        properties.className,
        styles.regularTableCell,
        styles.boldTotal
      )}
    >
      {properties.children}
    </TableCell>
  );
};

export default TotalTableCell;
