import moment from 'moment';

export const generateColorFromString = function (str: string) {
  let hash = 0;
  const rgb = [0, 0, 0];
  const bitsToShift = 3;
  if (str.length === 0) {
    return hash;
  }
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << bitsToShift) - hash);
  }
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 255;
    rgb[i] = value > 200 ? 200 : value;
  }
  return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
};

export const getFormattedDate = (datetime: string) => {
  return moment(datetime).format('DD/MM');
};

export const getFormattedTime = (datetime: string) => {
  const time = moment(datetime).format('HH:mm');
  if (time === '00:00' || time === '23:59') {
    return null;
  } else {
    return time;
  }
};

export const formatDateRange = (
  startDate?: string,
  startTime?: string | null,
  endDate?: string,
  endTime?: string | null
): string => {
  const isAllDay = !startTime && !endTime;
  const isSameDay = startDate === endDate;

  const formattedStartTime = startTime || '';
  const formattedEndTime = endTime || '';

  if (!isSameDay) {
    return `${startDate} ${formattedStartTime} - ${endDate} ${formattedEndTime}`;
  }
  if (isAllDay) {
    return startDate || '';
  }
  return `${startDate} \u00A0\u00A0  ${formattedStartTime} - ${formattedEndTime}`;
};
