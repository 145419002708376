import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React, { useState } from 'react';

interface DayInputProps {
  defaultValue: number;
  handleChange: (numberOfDays: number) => void;
  disabledOption?: true | false;
  max?: number;
}

const buildSelectItem = (max: number) => {
  const listItem = [];

  for (let index = 0.25; index <= max; index += 0.25) {
    listItem.push(
      <MenuItem value={index} key={`dayInput-${index}`}>{`${String(
        index
      )} J`}</MenuItem>
    );
  }
  return listItem;
};

const DayInput = ({
  defaultValue,
  handleChange,
  disabledOption,
  max = 1,
}: DayInputProps) => {
  const [day, setDay] = useState(defaultValue);

  const onChangeDay = (event: SelectChangeEvent<unknown>) => {
    setDay(event.target.value as number);
    handleChange(event.target.value as number);
  };

  return (
    <FormControl
      variant="outlined"
      sx={{ m: 1, width: '120px', minWidth: '120px' }}
    >
      <Select
        id="filled-end-adornment"
        value={day}
        onChange={onChangeDay}
        disabled={disabledOption}
        variant="standard"
      >
        {buildSelectItem(max)}
      </Select>
    </FormControl>
  );
};

export default DayInput;
