import PolySwitch from 'components/Navigation/PolySwitch';
import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import urljoin from 'url-join';

import EmployeeDetailPage from './EmployeePage';
import EmployeesPage from './EmployeesPage';

const EmployeePage = () => {
  const { path } = useRouteMatch();

  return (
    <PolySwitch>
      <Route exact path={path} component={EmployeesPage} />
      <Route exact path={urljoin(path, ':id')} component={EmployeeDetailPage} />
    </PolySwitch>
  );
};

export default EmployeePage;
