import { Typography } from '@mui/material';
import {
  BorderedTableCell,
  CenteredTableCell,
} from 'components/commons/Tables/FlatTable';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import TextFieldNumberFormat from 'components/commons/TextFieldFormat/TextFieldNumberFormat';
import PolyGridInputForm from 'components/MissionFollowUp/PolyGridInputForm';
import ChildCollapsePolyTableRow from 'components/MUIOverload/PolyTableRow/ChildCollapsePolyTableRow';
import { handleSuggestionOnEnter } from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/RevenueTableCells/CellTypes/RevenueCellInput';
import { DisplayedRevenueProspective } from 'components/Revenue/Estimated/EstimatedRevenueTables/EstimatedRevenueGlobalTable/context/TableContextProvider';
import { ProspectiveModal } from 'components/Revenue/Estimated/EstimatedRevenueTables/EstimatedRevenueGlobalTable/RevenueRows/ProspectiveModal';
import { estimatedRevenueBackgroundColorSx } from 'components/Revenue/Estimated/utils';
import { useUserInfo } from 'components/User/UserProvider';
import {
  BillingClientNode,
  BillingPurchaseOrderCurrencyChoices,
  EmployeesEmployeeGradeChoices,
} from 'generated/graphql';
import _ from 'lodash';
import { totalFormat } from 'pages/ActivityPage/utils';
import { isNumberNullOrPositive } from 'pages/MissionFollowUp/formValidators';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { TransitionStatus } from 'react-transition-group/Transition';

interface ProspectiveRowProps {
  client?: BillingClientNode;
  dpr?: DisplayedRevenueProspective;
  index?: number;
  selfRemove?: () => void;
  status: TransitionStatus;
}

const ProspectiveRow = (properties: ProspectiveRowProps) => {
  const [openModal, setOpenModal] = useState(false);
  const { displayedMonths, currentYear } = useTableHeaderContext();
  const { client, dpr, index, status, selfRemove } = properties;
  const { setValue, watch } = useFormContext();
  const { employee } = useUserInfo();
  const form = useFormContext();

  const isComex = employee?.grade === EmployeesEmployeeGradeChoices.Comex;

  const revenueProspective = _.find(
    client?.revenueProspectives,
    (revenueProspective) => {
      return revenueProspective.year === currentYear.year();
    }
  );

  const formName = useMemo(() => {
    return client
      ? `revenueProspectivesByClient.${client?.id}`
      : `revenueProspectives.${index}`;
  }, [client, index]);

  useEffect(() => {
    if (client) {
      setValue(`${formName}.id`, revenueProspective?.id);
    }
  }, [client, formName, revenueProspective, setValue]);

  if (!client && !dpr) {
    return <React.Fragment />;
  }

  return (
    <ChildCollapsePolyTableRow status={status}>
      {dpr && (
        <ProspectiveModal
          mode={'edit'}
          open={openModal}
          close={() => setOpenModal(false)}
          onSuccess={(newDpr) => {
            setValue(`${formName}.name`, newDpr.name);
          }}
          onDelete={() => {
            if (selfRemove) {
              selfRemove();
            }
          }}
          revenueProspective={dpr}
        />
      )}
      <BorderedTableCell onClick={() => setOpenModal(true)}>
        <Typography
          color={'text.secondary'}
          sx={{
            '&:hover': {
              textDecoration: dpr ? 'underline' : undefined,
              cursor: dpr ? 'pointer' : undefined,
            },
            fontSize: '0.875rem',
          }}
        >
          {dpr
            ? watch(`${formName}.name`)
            : 'Autres - Prévisionnel hors mission'}
        </Typography>
      </BorderedTableCell>
      {_.map(displayedMonths, (month) => {
        return (
          <CenteredTableCell
            sx={{
              ...estimatedRevenueBackgroundColorSx,
            }}
            key={`header-${month.format('MMM')}`}
          />
        );
      })}
      <CenteredTableCell>
        {isComex ? (
          <PolyGridInputForm
            hiddenLabel
            fullWidth
            variant="filled"
            alignment="center"
            formName={`${formName}.objective`}
            defaultValue={revenueProspective?.objective || dpr?.objective || 0}
            onKeyPress={handleSuggestionOnEnter}
            InputProps={{
              inputComponent: TextFieldNumberFormat as never,
            }}
            inputProps={{
              sx: { p: 0.5 },
            }}
            validateRules={{
              validate: {
                isNumberPositive: isNumberNullOrPositive,
              },
            }}
          />
        ) : (
          <Typography fontWeight={'bold'} fontSize="0.875rem">
            {totalFormat(
              form.watch(`${formName}.objective`) ||
                revenueProspective?.objective ||
                dpr?.objective ||
                0,
              BillingPurchaseOrderCurrencyChoices.Eur
            )}
          </Typography>
        )}
      </CenteredTableCell>
    </ChildCollapsePolyTableRow>
  );
};

export default ProspectiveRow;
