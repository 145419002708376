import {
  Autocomplete,
  Box,
  Checkbox,
  DialogContentText,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DateRangePicker } from 'components/commons/DateRangePicker';
import {
  List_Field_Bill_Export,
  List_Filterable_Dates,
} from 'generated/graphql';
import _ from 'lodash';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { billExportFieldsCorrespondance } from '.';

const EXPORT_BILLS_DATA_BODY =
  'Téléchargez ici les factures apparaissant dans le tableau précédent (filtres inclus) selon la période \
   de votre choix en choisissant les dates et les données que vous souhaitez exporter.';

export const LABEL_XS = 5;
export const INPUT_XS = 7;

export default function ExportBillDataForm() {
  const form = useFormContext();
  const filterableDateFields = Object.values(List_Filterable_Dates);
  const selectableFields = Object.values(List_Field_Bill_Export);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DialogContentText id="alert-dialog-description">
          {EXPORT_BILLS_DATA_BODY}
        </DialogContentText>
      </Grid>
      <Grid item xs={LABEL_XS}>
        <Controller
          name="exportBillData.dateToFilter"
          control={form.control}
          render={({ onChange, value }) => (
            <Autocomplete
              disableClearable
              autoHighlight
              autoComplete
              id="filter-date"
              options={filterableDateFields}
              getOptionLabel={(option) =>
                billExportFieldsCorrespondance[
                  option as keyof typeof billExportFieldsCorrespondance
                ]
              }
              onChange={(_, data) => onChange(data)}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  {
                    billExportFieldsCorrespondance[
                      option as keyof typeof billExportFieldsCorrespondance
                    ]
                  }
                </Box>
              )}
              size="small"
              sx={{ width: 200, mr: 12.5 }}
              value={value}
              renderInput={(parameters) => (
                <TextField
                  variant="outlined"
                  label="Filtrer sur le champ"
                  sx={{ color: 'text.secondary' }}
                  {...parameters}
                  InputProps={{
                    sx: { fontWeight: 'bold' },
                    ...parameters.InputProps,
                  }}
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={INPUT_XS}>
        <Controller
          name="exportBillData.dateRange"
          render={({ onChange, value }) => (
            <DateRangePicker
              dateRange={value}
              disableFuture={false}
              handleDateRangeChange={onChange}
              externallyControlled
            />
          )}
          control={form.control}
        />
      </Grid>

      <Grid
        item
        xs={LABEL_XS}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }}>Champs de données :</Typography>
      </Grid>
      <Grid item xs={INPUT_XS}>
        <Controller
          name="exportBillData.listFields"
          control={form.control}
          render={({ onChange, value }) => (
            <Select
              sx={{ width: '100%' }}
              value={value}
              renderValue={(selected) => {
                if (selected.length === selectableFields.length) {
                  return 'Tous les champs';
                }
                const selectedConverted = _.map(
                  selected,
                  (selectedElement) =>
                    billExportFieldsCorrespondance[
                      selectedElement as keyof typeof billExportFieldsCorrespondance
                    ]
                );
                return selectedConverted.join(', ');
              }}
              onChange={onChange}
              multiple
              size="small"
            >
              {_.map(selectableFields, (fieldName) => {
                return (
                  <MenuItem key={fieldName} value={fieldName}>
                    <Checkbox
                      checked={_.some(
                        form.watch('exportBillData.listFields'),
                        (fn) => fn === fieldName
                      )}
                    />
                    <ListItemText
                      primary={billExportFieldsCorrespondance[fieldName]}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          )}
        />
      </Grid>
    </Grid>
  );
}
