import React, { createContext, useContext } from 'react';

interface AuthContext {
  logout: (message?: string) => void;
}

// eslint-disable-next-line
const AuthContext = createContext<AuthContext | undefined>(undefined);

export const useAuth = () => {
  const config = useContext(AuthContext);
  if (!config) {
    throw new Error('useAuth() can only be used inside AuthProvider');
  }
  return config;
};

export const AuthProvider = ({
  value,
  children,
}: React.PropsWithChildren<{ value: AuthContext }>): JSX.Element => {
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
export { AuthContext };
