import FlashOnIcon from '@mui/icons-material/FlashOn';
import { Box, Menu, MenuItem, popoverClasses } from '@mui/material';
import { useTheme } from '@mui/styles';
import PongoButton from 'components/MUIOverload/PongoButton';
import _ from 'lodash';
import React, { CSSProperties, useState } from 'react';
import { OpacitySuffix } from 'utils';

interface Option {
  title: string;
  icon: JSX.Element;
  onClick: () => void;
  display?: boolean;
  style?: CSSProperties;
}

interface ActionsMenuProps {
  options: Option[];
  className?: string;
}

function ActionsMenu(properties: ActionsMenuProps) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [anchorElement, setAnchorElement] = React.useState<
    HTMLElement | undefined
  >();
  const filteredOptions = properties.options.filter((option) => option.display);

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorElement(event.currentTarget);
    setOpen(!open);
  }

  function handleClose() {
    setOpen(!open);
  }

  function onSelectOption(onClick: () => void) {
    setOpen(!open);
    onClick();
  }

  return (
    <>
      <PongoButton
        variant="contained"
        onClick={handleClick}
        startIcon={<FlashOnIcon />}
      >
        <Box>Actions</Box>
      </PongoButton>

      <Menu
        open={open}
        onClose={handleClose}
        anchorEl={anchorElement}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          marginTop: 1,
          [`& .${popoverClasses.paper}`]: {
            borderRadius: 2,
            background: theme.palette.primary.darker,
            boxShadow: 'none',
          },
        }}
        MenuListProps={{
          sx: {
            padding: 1.5,
          },
        }}
      >
        {_.map(filteredOptions, (option, index) => {
          return (
            <MenuItem
              key={`options-${index}-${option.title}`}
              onClick={() => onSelectOption(option.onClick)}
              sx={{
                ...{
                  color: theme.palette.contrastText.main,
                  fontSize: '14px',
                  fontWeight: '500',
                  paddingY: 0.5,
                  paddingX: 1,
                  marginTop: index !== 0 && 1.5,
                  transition: 'background-color 250ms',
                  ':hover': {
                    borderRadius: 1,
                    backgroundColor:
                      theme.palette.text.secondary +
                      OpacitySuffix.SIXTY_PERCENT,
                  },
                  '&& .MuiTouchRipple-child': {
                    backgroundColor:
                      theme.palette.text.secondary +
                      OpacitySuffix.SIXTY_PERCENT,
                  },
                },
                ...option.style,
              }}
            >
              <Box sx={{ mr: 1, display: 'flex', alignItems: 'center' }}>
                {option.icon}
              </Box>
              {option.title}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

export default ActionsMenu;
export type { Option };
