import { SxProps } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {
  getTimeSpentByWeek,
  isActivityMonitoringInCurrentWeekPart,
  isCorrectEmployeeWorkingDaysOnWeek,
} from 'components/ActivityMonitoring/ActivityMonitoringTable/utils';
import MissionTypeIcon from 'components/commons/MissionTypeIcon';
import { ActivityMonitoringNode, EmployeeNode } from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { totalDaysToWork } from 'pages/ActivityPage/utils';
import React from 'react';
import cellStyles from 'styles/ActivityPage/Cell.module.scss';

import styles from '../../../styles/TableCell.module.scss';
import { FormattedMission } from '../utils';

export const isEmployeeHiredOnWeek = (
  employee: EmployeeNode | undefined,
  week: moment.Moment
) => {
  return !(
    (employee?.hiringDate &&
      moment(employee?.hiringDate) > week.clone().day(5)) ||
    (employee?.leavingDate &&
      moment(employee?.leavingDate) < week.clone().day(1))
  );
};

export const hasTooManyDaysOnWeek = (
  week: moment.Moment,
  employee?: EmployeeNode
) => {
  const timeSpent = getTimeSpentByWeek(week, employee);
  return timeSpent > totalDaysToWork(employee, week);
};

export const checkWeekError = (
  employee: EmployeeNode | undefined,
  week: moment.Moment
) => {
  const isPastWeek = week.isBefore(moment(), 'week');
  if (!isPastWeek) {
    return false;
  }
  const timeSpent = getTimeSpentByWeek(week, employee);
  return !isCorrectEmployeeWorkingDaysOnWeek(timeSpent, week, employee);
};

export function getCurrentWeekPartTimeSpent(
  mission: FormattedMission,
  week: moment.Moment
) {
  const currentMissionAMs = _.filter(mission.missions, (activity) => {
    return isActivityMonitoringInCurrentWeekPart(activity, week);
  });
  return _.sumBy(currentMissionAMs, 'timeSpent');
}

export const getCellStyleError = (
  firstElement: boolean,
  lastElement: boolean
) => {
  if (firstElement && lastElement) {
    return styles.firstLastInvalidCell;
  } else if (firstElement) {
    return styles.firstInvalidCell;
  } else if (lastElement) {
    return styles.lastInvalidCell;
  } else if (!lastElement) {
    return cellStyles.isInvalid;
  } else {
    return '';
  }
};

interface CellEmployeeNotHiredOnWeekProps {
  week: moment.Moment;
  sx?: SxProps;
}

export function CellEmployeeNotHiredOnWeek({
  week,
  sx,
}: CellEmployeeNotHiredOnWeekProps) {
  return (
    <Tooltip
      title="Le collaborateur n'était pas à Polyconseil pour cette semaine"
      arrow
      key={`row-team${week}`}
    >
      <TableCell
        sx={sx}
        key={`row-team${week}`}
        className={styles.hatchedCell}
      />
    </Tooltip>
  );
}

interface DisplayMissionAndClientProps {
  missionDetail: FormattedMission;
}

export function DisplayMissionAndClient({
  missionDetail,
}: DisplayMissionAndClientProps) {
  return (
    <div className={styles.missionContainer}>
      <MissionTypeIcon
        isTmContracts={missionDetail.isTm}
        className={styles.missionIcon}
        type={missionDetail.type}
        tooltip={true}
      />
      <Typography color={'textPrimary'}>
        {missionDetail.name}
        {missionDetail.clientName && (
          <Typography component={'span'} color={'textSecondary'}>
            {` \\ ${missionDetail.clientName}`}
          </Typography>
        )}
      </Typography>
    </div>
  );
}

interface TypographyDisplayWeekValueProps {
  weekValue: number | undefined;
}

export function TypographyDisplayWeekValue({
  weekValue,
}: TypographyDisplayWeekValueProps) {
  return <Typography color={'textSecondary'}>{weekValue || '-'}</Typography>;
}

export function getAmWithComment(
  employee: EmployeeNode,
  week: moment.Moment,
  missionId: string
) {
  return _.find(employee?.activityMonitoring, (am) => {
    if (isActivityMonitoringInCurrentWeekPart(am, week)) {
      return am.comment && am.activity.id === missionId;
    }
  }) as ActivityMonitoringNode;
}
