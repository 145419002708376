import { SxProps, Theme, Typography } from '@mui/material';
import { BillingPurchaseOrderCurrencyChoices } from 'generated/graphql';
import { totalFormat } from 'pages/ActivityPage/utils';
import React from 'react';

interface ProfitabilityRenderAmountProperties {
  amount: number | undefined;
  unit: 'percentage' | 'currency';
  sx?: SxProps<Theme>;
  wrapThousands?: boolean;
}

export default function ProfitabilityRenderAmount({
  amount,
  unit,
  sx,
  wrapThousands = false,
}: ProfitabilityRenderAmountProperties) {
  if (amount === undefined) {
    return <Typography sx={sx}>-</Typography>;
  }

  const sign = amount > 0 ? '+' : '';

  return (
    <Typography
      color={(theme) =>
        amount > 0 ? theme.palette.success.main : theme.palette.error.main
      }
      sx={sx}
    >
      {unit === 'percentage' && `${sign}${totalFormat(amount)}%`}
      {unit === 'currency' &&
        `${sign}${totalFormat(
          amount,
          BillingPurchaseOrderCurrencyChoices.Eur,
          wrapThousands
        )}`}
    </Typography>
  );
}
