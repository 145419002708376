import { AddCircleOutline } from '@mui/icons-material';
import { Button, Fade, Grid, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import React from 'react';

import { RequestDay } from './RequestContent';
import RequestItem from './RequestItem';

interface RequestContentGridProps {
  listToDisplay: Array<RequestDay>;
  addItemRequest: () => void;
  deleteItemRequest: (requestDayItem: RequestDay) => void;
}

const RequestContentGrid = (properties: RequestContentGridProps) => {
  return (
    <Grid
      sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}
    >
      {_.map(properties.listToDisplay, (itemRequest) => {
        return (
          <Fade in={true} key={`itemRequest-${itemRequest.id}`}>
            <Grid item xs={4}>
              <RequestItem
                requestDay={itemRequest}
                deleteSelf={() => properties.deleteItemRequest(itemRequest)}
              />
            </Grid>
          </Fade>
        );
      })}
      <Grid
        item
        xs={4}
        sx={{
          height: '220px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button onClick={properties.addItemRequest}>
          <Stack
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            color="primary"
          >
            <AddCircleOutline sx={{ m: 0.5 }} />
            <Typography variant="bodyBold" color="primary">
              Nouvelle date
            </Typography>
          </Stack>
        </Button>
      </Grid>
    </Grid>
  );
};

export default RequestContentGrid;
