import PolyExportButton from 'components/commons/PolyExportButton';
import { useTableContext } from 'contexts/ActivityMonitoringTable/TableContextProvider';
import React, { useState } from 'react';

import ExportModal from './ExportModal';

interface ExportButtonProps {
  displayIcon?: boolean;
}

export default function ExportButton({
  displayIcon = true,
}: ExportButtonProps) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const { filterLatecomers, filterProvider } = useTableContext();

  let modalTitle = 'Exporter les données du suivi temps équipe';
  if (filterLatecomers && filterProvider) {
    modalTitle =
      'Exporter les données des semaines incomplètes des prestataires';
  } else {
    if (filterLatecomers) {
      modalTitle = 'Exporter les données des semaines incomplètes';
    }
    if (filterProvider) {
      modalTitle = 'Exporter les données du suivi des prestataires';
    }
  }

  return (
    <>
      <PolyExportButton displayIcon={displayIcon} onClick={toggleModal}>
        Exporter
      </PolyExportButton>
      <ExportModal
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        title={modalTitle}
        body={
          "Téléchargez ici le suivi d'activité de vos collaborateurs sur leurs missions respectives en choisissant les dates ainsi que les champs de données qui vous intéressent."
        }
      />
    </>
  );
}
