import RuleIcon from '@mui/icons-material/Rule';
import { Box, Button } from '@mui/material';
import { useFilterContext } from 'components/commons/FilterContext/FilterContextProvider';
import React from 'react';

export default function NonValidatedRevenueButton() {
  const { filterNotValidatedRevenue, setFilterNotValidatedRevenue } =
    useFilterContext();
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        borderRadius: '20px',
        border: '1px solid',
        borderColor: 'darkGrey.main',
        width: 'fit-content',
        display: 'flex',
        position: 'relative',
      }}
    >
      <Button
        sx={{
          borderRadius: '20px',
          letterSpacing: 'normal',
          textTransform: 'none',
          color: 'text.primary',
          ...(filterNotValidatedRevenue && {
            backgroundColor: 'orange.main',
            color: 'white',
            '&:hover': {
              backgroundColor: 'orange.main',
              color: 'white',
            },
          }),
        }}
        onClick={() => {
          setFilterNotValidatedRevenue(!filterNotValidatedRevenue);
        }}
      >
        <RuleIcon sx={{ mr: 1 }} />
        CAs non validés
      </Button>
    </Box>
  );
}
