import MatomoTracker from '@jonkoops/matomo-tracker';
import { MatomoProvider } from '@jonkoops/matomo-tracker-react';
import { createInstance } from '@jonkoops/matomo-tracker-react';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { ReactNode, useEffect, useMemo } from 'react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { isProd } from 'utils';

enum MatomoURLBase {
  EHP = 'https://polyconseil.matomo.cloud/',
  PROD = 'https://polyconseil.matomo.cloud/',
}

enum MatomoEnvironmentId {
  EHP = 4,
  PROD = 5,
}

const getMatomoUrlBase = (): string => {
  if (isProd) {
    return MatomoURLBase.PROD;
  }
  return MatomoURLBase.EHP;
};

const getMatomoEnvironmentId = (): number => {
  if (isProd) {
    return MatomoEnvironmentId.PROD;
  }
  return MatomoEnvironmentId.EHP;
};

export const useTrackPageView = () => {
  const { pathname } = useLocation();
  const { trackPageView } = useMatomo();
  useEffect(() => {
    trackPageView({
      href: pathname,
    });
  }, [trackPageView, pathname]);
};

export const MatomoContextProvider = ({
  children,
}: {
  children?: ReactNode;
}): JSX.Element => {
  const urlBase: string = getMatomoUrlBase();
  const siteId: number = getMatomoEnvironmentId();

  const instance = useMemo<MatomoTracker>(() => {
    return createInstance({
      urlBase: urlBase, //The Matomo server URL
      siteId: siteId, //The ID of the website we're tracking a visit/action for
      linkTracking: false, //Necessary on a React stack
    });
  }, [urlBase, siteId]);

  return <MatomoProvider value={instance}>{children}</MatomoProvider>;
};
