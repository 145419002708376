import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Button, Grid } from '@mui/material';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import _ from 'lodash';
import { Moment } from 'moment';
import React, { PropsWithChildren } from 'react';

interface ArrowsUpdateMonthsTableHeaderProperties {
  month: Moment;
  indicator?: JSX.Element;
  currentYear?: Moment;
  isGlobalView?: boolean;
  setCurrentYear?: (newYear: Moment) => void;
  setIsYearSubtracted?: (changeYear: boolean) => void;
  setIsYearAdded?: (changeYear: boolean) => void;
  enableMonthIncrement?: boolean;
  enableMonthDecrement?: boolean;
}

export default function ArrowsUpdateMonthsTableHeader({
  month,
  setCurrentYear,
  setIsYearSubtracted,
  setIsYearAdded,
  isGlobalView,
  enableMonthIncrement,
  enableMonthDecrement,
  indicator,
  children,
}: PropsWithChildren<ArrowsUpdateMonthsTableHeaderProperties>) {
  const { displayedMonths, incrementMonth, decrementMonth, currentYear } =
    useTableHeaderContext();

  return (
    <Grid
      container
      sx={{ height: 1, width: '100%' }}
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Grid item xs={4} sx={{ height: '100%', textAlign: 'left' }}>
        {_.first(displayedMonths) === month && enableMonthDecrement ? (
          <Button
            sx={{
              minWidth: '10px',
              width: '10px',
              height: '47px',
              borderRadius: 0,
              color: 'white',
              backgroundColor: 'darkGrey.darker',
            }}
            aria-label={'Précédent'}
            onClick={() => {
              if (
                currentYear.clone().startOf('years').isSame(month, 'month') &&
                setCurrentYear &&
                setIsYearSubtracted &&
                setIsYearAdded
              ) {
                setCurrentYear(
                  currentYear.clone().subtract(1, 'year').endOf('years')
                );
                setIsYearSubtracted(true);
                setIsYearAdded(false);
              } else if (
                setCurrentYear &&
                setIsYearSubtracted &&
                isGlobalView
              ) {
                setIsYearSubtracted(false);
                setCurrentYear(currentYear.clone().subtract(1, 'month'));
              }
              decrementMonth();
            }}
          >
            <NavigateBeforeIcon />
          </Button>
        ) : (
          <Box sx={{ minWidth: '10px', width: '10px' }} />
        )}
      </Grid>
      <Grid item xs={4} display={'flex'} justifyContent={'center'}>
        {children}
      </Grid>
      <Grid
        item
        xs={4}
        display={'flex'}
        justifyContent={'flex-end'}
        sx={{ height: '100%' }}
      >
        <>
          {indicator}
          {_.last(displayedMonths) === month && enableMonthIncrement ? (
            <Button
              sx={{
                minWidth: '10px',
                width: '10px',
                borderRadius: 0,
                height: '47px',
                ml: 1,
                color: 'white',
                backgroundColor: 'darkGrey.darker',
              }}
              aria-label={'Suivant'}
              onClick={() => {
                if (
                  currentYear.clone().endOf('years').isSame(month, 'month') &&
                  setCurrentYear
                ) {
                  setCurrentYear(currentYear.clone().add(1, 'year'));
                }
                if (
                  setIsYearAdded &&
                  setIsYearSubtracted &&
                  month.month() === 11
                ) {
                  setIsYearAdded(true);
                  setIsYearSubtracted(false);
                }
                incrementMonth();
                if (
                  setCurrentYear &&
                  isGlobalView &&
                  currentYear.isSame(month, 'month')
                ) {
                  setCurrentYear(currentYear.clone().add(1, 'month'));
                }
              }}
            >
              <NavigateNextIcon />
            </Button>
          ) : (
            <Box sx={{ minWidth: '10px', width: '10px' }} />
          )}
        </>
      </Grid>
    </Grid>
  );
}
