import moment from 'moment';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

interface AdrContextProps {
  year: number;
}

interface AdrContextInterface extends AdrContextProps {
  isEdit: boolean;
  setIsEdit: (p: boolean) => void;
  isShowingHistory: boolean;
  setIsShowingHistory: (p: boolean) => void;
}

const AdrContext = createContext<AdrContextInterface>({
  year: moment().year(),
  isEdit: false,
  setIsEdit: () => {
    throw new Error('function setIsEdit not set');
  },
  isShowingHistory: false,
  setIsShowingHistory: () => {
    throw new Error('function setIsShowingHistory not set');
  },
});

export const useAdrContext = () => {
  const adrContext = useContext(AdrContext);
  return adrContext;
};

const AdrContextProvider = ({
  year,
  children,
}: PropsWithChildren<AdrContextProps>) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isShowingHistory, setIsShowingHistory] = useState<boolean>(false);

  return (
    <AdrContext.Provider
      value={{
        year,
        isEdit,
        setIsEdit,
        isShowingHistory,
        setIsShowingHistory,
      }}
    >
      {children}
    </AdrContext.Provider>
  );
};

export default AdrContextProvider;
