import { TableCell } from '@mui/material';
import React from 'react';

interface ProfitabilityDataComingCellProps {
  reason?: string;
  colSpan?: number;
}

export default function ProfitabilityDataComingCell({
  reason,
  colSpan = 3,
}: ProfitabilityDataComingCellProps) {
  return (
    <TableCell
      align={'center'}
      colSpan={colSpan}
      sx={{
        fontSize: '0.8rem',
        backgroundColor: (theme) => theme.palette.action.hover,
      }}
    >
      {reason && `${reason} - `}Données à venir
    </TableCell>
  );
}
