import { Box, Stack, Typography } from '@mui/material';
import FormSystem from 'components/commons/FormSystem';
import PolyFooter from 'components/MUIOverload/PolyFooter';
import PongoButton from 'components/MUIOverload/PongoButton';
import { RecoveryDayMoment } from 'poly-constants';
import React, { useState } from 'react';

import FieldDate from './FieldDate';
import FieldDuration from './FieldDuration';

interface FormInputs {
  date: Date;
}

interface RecoveryMomentFormProps {
  overtimeAuthorizationId: string;
  handleClose: () => void;
  createRecoveryMoment: (
    id: string,
    date: Date,
    duration: number,
    dayMoment: string
  ) => Promise<void>;
  maxRecoveryDuration: number;
  expireAt: string;
}

const RecoveryMomentForm = ({
  handleClose,
  overtimeAuthorizationId,
  createRecoveryMoment,
  maxRecoveryDuration,
  expireAt,
}: RecoveryMomentFormProps) => {
  const [dayDetail, setDayDetail] = useState({
    recoveryTime: 0.25,
    moment: RecoveryDayMoment.morning,
  });

  return (
    <Box sx={{ m: 'auto' }}>
      <FormSystem
        onSubmit={async (data: FormInputs) => {
          await createRecoveryMoment(
            overtimeAuthorizationId,
            data.date,
            dayDetail.recoveryTime,
            dayDetail.moment
          );

          handleClose();
        }}
      >
        <Typography
          variant="h3Bold"
          component="h3"
          sx={{
            color: 'primary.main',
            pt: 1,
            pb: 3,
          }}
        >
          Récupération d{"'"}activités exceptionnelles
        </Typography>
        <Stack sx={{ padding: 2, flexDirection: 'column' }}>
          <FieldDate expireAt={expireAt} />
          <FieldDuration
            onChange={setDayDetail}
            maxRecoveryDuration={maxRecoveryDuration}
          />
        </Stack>
        <PolyFooter>
          <PongoButton onClick={handleClose}>Annuler</PongoButton>
          <PongoButton type="submit" color="primary" variant="contained">
            Valider
          </PongoButton>
        </PolyFooter>
      </FormSystem>
    </Box>
  );
};

export default RecoveryMomentForm;
