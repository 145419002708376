import FieldDisplay from 'components/commons/FieldDisplay/FieldDisplay';
import { ModalityViewProps } from 'components/MissionFollowUp/BillDetail/BillDetail/BillView/MissionSummaryView/ModalitiesView/ModalityEmailView';
import React from 'react';

export default function ModalityUploadView(properties: ModalityViewProps) {
  return (
    <>
      <FieldDisplay
        title={'URL'}
        value={properties.modality.billingUrl}
        flexDirection="column"
        noWrap={false}
        showColon={false}
        titleVariant="bodyBold"
        valueVariant="bodySemiBold"
        isHyperLink
        openNewTab
      />
      <FieldDisplay
        title={'Code du service exécutant'}
        value={properties.modality.billingCode}
        flexDirection="column"
        noWrap={false}
        showColon={false}
        titleVariant="bodyBold"
        valueVariant="bodySemiBold"
      />
    </>
  );
}
