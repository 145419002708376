import { Box, Chip, Divider, List, Stack, Typography } from '@mui/material';
import { getEmployeeOccupationInfo } from 'components/Phonebook/EmployeeCard/EmployeeOccupationIndicator/EmployeeOccupationIndicator';
import FilterListItem from 'components/Phonebook/PhonebookFinder/FilterListItem';
import { checkEmployeeRolesWithFilter } from 'components/Phonebook/PhonebookFinder/utils';
import PolyTrombiSearchFilterDropdownMenu from 'components/Phonebook/PolyTrombiSearchFilters/PolyTrombiSearchFilterDropdownMenu';
import { EmployeesEmployeeOccupationChoices } from 'components/Phonebook/utils';
import { usePolyTrombiContext } from 'contexts/Phonebook/PolyTrombiContextProvider';
import _ from 'lodash';
import React from 'react';

export default function PolyTrombiRoleSearchFilter() {
  const {
    searchedEmployees,
    employeesFilteredByMissions,
    employeesFilteredByStatus,
    selectedOccupations,
    setSelectedOccupations,
    setShowSkeleton,
  } = usePolyTrombiContext();

  const employeesConsideredForRoleCount = [
    searchedEmployees,
    employeesFilteredByMissions,
    employeesFilteredByStatus,
  ].reduce((array1, array2) =>
    array1.filter((employee1) =>
      array2.some((employee2) => employee2.id === employee1.id)
    )
  );

  const getRoleFilterEmployeeCount = (role: string) => {
    const employeeCount = _.filter(
      employeesConsideredForRoleCount,
      (employee) => checkEmployeeRolesWithFilter([role], employee.occupation)
    ).length;

    return employeeCount;
  };

  const roleListWithCount = _.map(
    EmployeesEmployeeOccupationChoices,
    (role) => {
      const occupationName = getEmployeeOccupationInfo(
        role,
        false
      ).occupationName;
      return {
        name: occupationName,
        count: getRoleFilterEmployeeCount(occupationName),
      };
    }
  ).sort((first, second) => second.count - first.count);

  const displayedOptions = _.filter(
    roleListWithCount,
    (occupation) => !selectedOccupations.includes(occupation.name)
  );

  const handleDeleteOccupationFilter = (occupation: string) => {
    setSelectedOccupations(
      _.filter(
        selectedOccupations,
        (selectedOccupation) => occupation !== selectedOccupation
      )
    );
    setShowSkeleton(true);
    window.scrollTo(0, 0);
  };

  const handleAddOccupationFilter = (occupation: string) => {
    setSelectedOccupations([...selectedOccupations, occupation]);
    setShowSkeleton(true);
    window.scrollTo(0, 0);
  };

  return (
    <PolyTrombiSearchFilterDropdownMenu
      title="Rôles"
      filterCount={selectedOccupations.length}
      isScrollable={true}
      maxHeight={220}
    >
      <Box sx={{ width: 220 }}>
        {selectedOccupations.length > 0 && (
          <>
            <Box sx={{ my: 1 }}>
              {_.map(selectedOccupations, (occupation) => {
                return (
                  <Chip
                    key={'selected-occupation-' + occupation}
                    label={
                      <Typography variant="bodyBold" color="info.main">
                        {occupation}
                      </Typography>
                    }
                    sx={{ mr: 1, mb: 1, backgroundColor: 'info.light' }}
                    onDelete={() => handleDeleteOccupationFilter(occupation)}
                  />
                );
              })}
            </Box>
            <Divider sx={{ mb: 1, borderColor: 'info.main' }} />
          </>
        )}
        <List disablePadding>
          {_.map(displayedOptions, (option) => {
            return (
              <FilterListItem
                key={'displayed-occupation-' + option}
                onClick={() => handleAddOccupationFilter(option.name)}
              >
                <Stack direction="row" alignItems="center">
                  {option.name}
                  <Typography
                    variant="bodySSemiBold"
                    color="info.main"
                    sx={{ ml: 0.5 }}
                  >
                    ({option.count})
                  </Typography>
                </Stack>
              </FilterListItem>
            );
          })}
        </List>
      </Box>
    </PolyTrombiSearchFilterDropdownMenu>
  );
}
