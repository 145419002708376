import { Typography } from '@mui/material';
import PageContent from 'components/commons/PageContent';
import PageWrapper from 'components/commons/PageWrapper';
import PolyTitle from 'components/commons/PolyTitle';
import React from 'react';

import RequestContentParent from './RequestContentParent';

const title = 'Demande d’activités exceptionnelles';

const getBody = () => {
  return (
    <>
      <Typography pb={1}>
        {"Vous êtes en train de réaliser une demande d'activité exceptionnelle sur une période préalablement discutée et validée par votre manager.\n" +
          'Veillez à en informer préalablement votre manager avant toute demande.'}
      </Typography>
      <Typography pb={1}>
        {'Pour rappel :  Le temps de travail contractuel est de 39h hebdomadaires.\n' +
          'Pour compenser les heures qui pourraient être effectuées en dépassement de ces 39h hebdomadaires, 8 jours de RTT sont attribués aux collaborateurs (tel que prévu à l’article 3.2 de l’accord collectif relatif à l’aménagement du temps de travail).\n' +
          'Les stagiaires et les alternants ne sont pas concernés par ces RTT.'}
      </Typography>
      <Typography pb={1}>
        {'Dans le cadre d’heures effectuées, à titre exceptionnel, le soir et le samedi, ces heures pourront donner lieu à récupération.Cette activité dite exceptionnelle est saisissable au quart de jour (0,25). \n' +
          'Les journées de récupération devront ensuite être posées dans un délai maximum de 30 jours.'}
      </Typography>
      <Typography pb={1}>
        {
          "Tant que la date de réalisation de l'activité exceptionnelle n'a pas été dépassée, vous pouvez librement modifier votre demande. Vous pourrez suivre le statut de cette demande dans votre historique personnel."
        }
      </Typography>
    </>
  );
};
const ExceptionalActivitiesNewRequest = () => {
  return (
    <>
      <PageWrapper noLimit>
        <PolyTitle>{title}</PolyTitle>
        <PageContent>{getBody()}</PageContent>
        <RequestContentParent />
      </PageWrapper>
    </>
  );
};

export default ExceptionalActivitiesNewRequest;
