import { Tooltip, Typography } from '@mui/material';
import PolyAlert from 'components/commons/PolyAlert';
import { useUserInfo } from 'components/User/UserProvider';
import moment from 'moment';
import React from 'react';

const formatMonths = (months: string[]) => {
  return months
    .map((month, index) => {
      const formatedMonth = moment(month).format('MMMM YYYY');
      if (index === months.length - 2) {
        return `${formatedMonth} et `;
      }
      if (index === months.length - 1) {
        return `${formatedMonth}`;
      }

      return `${formatedMonth}, `;
    })
    .join('');
};

const Warnings = () => {
  const { employee } = useUserInfo();

  if (
    !employee?.updateAuthorization ||
    !employee?.updateAuthorization.months ||
    employee?.updateAuthorization.months?.length === 0
  ) {
    return null;
  }

  const wordSuffix = employee.updateAuthorization.months.length > 1 ? 's' : '';
  const formatedMonths = formatMonths(employee?.updateAuthorization.months);

  const warningMessage = `Modifications exceptionnelles pour le${wordSuffix} mois suivant${wordSuffix} : ${formatedMonths}.`;
  const modificationPeriod = `La modification est possible jusqu'au ${moment(
    employee?.updateAuthorization.validUntil
  ).format('DD MMMM YYYY')}`;

  return (
    <PolyAlert severity="warning" variant="outlined" sx={{ mt: 1 }}>
      <Tooltip title={modificationPeriod} placement="right-end">
        <Typography
          color="warning.main"
          component={'p'}
          style={{ cursor: 'pointer' }}
          sx={{ width: 'fit-content', fontWeight: 600 }}
        >
          Rendu de main temporaire
        </Typography>
      </Tooltip>
      {warningMessage}
    </PolyAlert>
  );
};

export default Warnings;
