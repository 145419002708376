import {
  AdrGraphNode,
  SectorNode,
  useAdrGraphQuery,
  useGetAllSectorsQuery,
} from 'generated/graphql';
import { useSnackbar } from 'notistack';
import React, { createContext, useContext, useState } from 'react';

export enum AdrGraphMissionFilters {
  ALL_MISSIONS = 'allMissions',
  BILLABLE_MISSIONS = 'onlyBillableMissions',
  MISSIONS_BY_SECTOR = 'missionsBySector',
}

export interface CurveOptions {
  includeInterns: boolean;
  selectionType: AdrGraphMissionFilters;
  sectorsIds: string[];
}

export const defaultOptions = {
  includeInterns: true,
  selectionType: AdrGraphMissionFilters.ALL_MISSIONS,
  sectorsIds: [],
};

export interface Curve {
  id: number;
  data: number[];
  options: CurveOptions;
  name: string;
  color?: string;
}

export interface AdrGraphContextProps {
  displayedCurves: Curve[];
  setDisplayedCurves: (curves: Curve[]) => void;
  sectors: SectorNode[];
  graphData: AdrGraphNode | undefined;
  loading: boolean;
  setLoading: (value: boolean) => void;
}

export interface AdrGraphContextProviderProps {
  children: React.ReactNode;
  year: number;
}

export const AdrGraphContext = createContext<AdrGraphContextProps>({
  displayedCurves: [],
  setDisplayedCurves: () => {
    throw new Error('function setDisplayedCurves not set');
  },
  sectors: [],
  graphData: undefined,
  loading: false,
  setLoading: () => {
    throw new Error('function setLoading not set');
  },
});

export const useAdrGraphContext = () => {
  const graphContext = useContext(AdrGraphContext);
  if (!graphContext) {
    throw new Error('useContext() cannot be called on such a page');
  }
  return graphContext;
};

export default function AdrGraphContextProvider({
  children,
  year,
}: AdrGraphContextProviderProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [displayedCurves, setDisplayedCurves] = useState<Curve[]>([]);
  const [loading, setLoading] = useState(true);
  const { data: sectorsData } = useGetAllSectorsQuery();
  const { data: graphQueryData } = useAdrGraphQuery({
    variables: {
      year: year,
    },
    onCompleted: (data) => {
      const allMissionsData = data.adrGraph?.allMissionsData || [];
      if (displayedCurves.length === 0 && allMissionsData) {
        setDisplayedCurves([
          {
            id: 0,
            data: allMissionsData,
            options: defaultOptions,
            name: 'Toutes les missions',
          },
        ]);
      }
      setLoading(false);
    },
    onError: () => {
      enqueueSnackbar('Problème lors de la récupération des TJM', {
        variant: 'error',
      });
    },
  });
  const sectors = sectorsData?.allSectors as SectorNode[];
  const graphData = graphQueryData?.adrGraph;
  return (
    <AdrGraphContext.Provider
      value={{
        displayedCurves,
        setDisplayedCurves,
        sectors,
        graphData,
        loading,
        setLoading,
      }}
    >
      {children}
    </AdrGraphContext.Provider>
  );
}
