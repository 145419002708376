import { TableBody } from '@mui/material';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import { useEstimatedContext } from 'components/Revenue/Estimated/context/EstimatedContextProvider';
import ClientRow from 'components/Revenue/Estimated/EstimatedRevenueTables/EstimatedRevenueGlobalTable/RevenueRows/ClientRow';
import ProspectiveClientRow from 'components/Revenue/Estimated/EstimatedRevenueTables/EstimatedRevenueGlobalTable/RevenueRows/ProspectiveClientRow';
import styles from 'components/Revenue/Estimated/EstimatedRevenueTables/EstimatedRevenueGlobalTable/styles/TableCell.module.scss';
import { useUserInfo } from 'components/User/UserProvider';
import {
  ActivityNode,
  BillingClientNode,
  EmployeesEmployeeGradeChoices,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { Moment } from 'moment';
import React from 'react';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import { useSelector } from 'store';
import { getMissionLastActiveMonth } from 'utils';

import MissionRow from './RevenueRows/MissionRow';

export interface AmountMonth {
  amount: number;
  month: Moment;
  hasComputedEstimation?: boolean;
}
export interface EstimatedMissionForYear {
  mission: ActivityNode;
  actualTotals: AmountMonth[];
  estimatedTotals: AmountMonth[];
  total: number;
  objective: number;
}

export interface EstimatedClientForYear {
  estimatedMissions: EstimatedMissionForYear[];
  client: BillingClientNode;
  actualTotals: AmountMonth[];
  estimatedTotals: AmountMonth[];
  dateRange: { start: Moment; end: Moment };
  total: number;
  objective: number;
}

export default function RevenueTableBody() {
  const selectedMissions = useSelector(
    (state) => state.activity.selectedMissions
  );
  const { clients } = useEstimatedContext();
  const { displayedMonths } = useTableHeaderContext();
  const { employee, isControl, isSuperuser } = useUserInfo();
  const isComex = employee?.grade === EmployeesEmployeeGradeChoices.Comex;
  const startMonth = displayedMonths[0].clone().startOf('month');
  const endMonth = displayedMonths[displayedMonths.length - 1]
    .clone()
    .endOf('month');

  const displayClientMissions = (
    client: EstimatedClientForYear,
    status: TransitionStatus
  ) => {
    return (
      <>
        {_.map(client.estimatedMissions, (estimatedMission) => {
          if (
            moment(estimatedMission.mission.startDate).isBefore(endMonth) &&
            getMissionLastActiveMonth(estimatedMission.mission).isAfter(
              startMonth
            )
          ) {
            return (
              <MissionRow
                estimatedMission={estimatedMission}
                status={status}
                key={`mission-${estimatedMission.mission.id}`}
              />
            );
          }
        })}
      </>
    );
  };

  return (
    <TableBody className={styles.tableBody}>
      {_.map(clients, (client) => {
        const startDisplay = displayedMonths[0].clone().startOf('month');
        const endDisplay = displayedMonths[displayedMonths.length - 1]
          .clone()
          .endOf('month');
        const isRangeValid =
          client.dateRange.start.isSameOrBefore(endDisplay) &&
          client.dateRange.end.isSameOrAfter(startDisplay);
        return (
          <Transition
            mountOnEnter
            unmountOnExit
            timeout={250}
            key={`client-${client.client.id}`}
            in={isRangeValid}
          >
            {(status) => {
              return (
                <>
                  {selectedMissions.length > 0 ? (
                    displayClientMissions(client, status)
                  ) : (
                    <ClientRow estimatedClient={client} status={status} />
                  )}
                </>
              );
            }}
          </Transition>
        );
      })}
      {(isControl || isComex || isSuperuser) &&
        selectedMissions.length === 0 && <ProspectiveClientRow />}
    </TableBody>
  );
}
