import { Edit } from '@mui/icons-material';
import React, { ReactElement } from 'react';

import PongoButton from '../../MUIOverload/PongoButton';
import { useAdrContext } from './AdrContextProvider';

export default function EditSaveButton(): ReactElement {
  const { isEdit, setIsEdit, setIsShowingHistory } = useAdrContext();

  return (
    <PongoButton
      variant={'contained'}
      buttonStyle={isEdit ? 'primary' : 'secondary'}
      startIcon={<Edit />}
      onClick={() => {
        if (!isEdit) {
          setIsShowingHistory(false);
        }
        setIsEdit(!isEdit);
      }}
      size="small"
    >
      Éditer
    </PongoButton>
  );
}
