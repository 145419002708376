import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import ActivityIndicator from 'components/commons/ActivityIndicator';
import AvatarNameAndOccupationStack from 'components/commons/AvatarNameAndOccupationStack/AvatarNameAndOccupationStack';
import CommentTooltip from 'components/Indicators/ActivityIndicatorTable/CommentTooltip';
import PongoButton from 'components/MUIOverload/PongoButton';
import { EmployeeNode, useIndicatorHistoryQuery } from 'generated/graphql';
import moment from 'moment';
import { EMPTY_FIELD, POLY_DATE } from 'poly-constants';
import React from 'react';

interface IndicatorHistoryModalProps {
  activityId: string;
  open: boolean;
  handleClose: () => void;
}

export default function IndicatorHistoryModal({
  activityId,
  open,
  handleClose,
}: IndicatorHistoryModalProps) {
  const theme = useTheme();
  const { data } = useIndicatorHistoryQuery({
    variables: { activityId },
  });

  return (
    <Dialog open={open} maxWidth="lg">
      <DialogContent
        sx={{
          overflowX: 'hidden',
          backgroundColor: theme.palette.modalBackground.main,
          pt: 0,
        }}
        data-testid="staffing-modal"
      >
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack flexDirection="row" alignItems="center" sx={{ py: 2 }}>
            <Typography color="text.secondary" variant="h3Bold" sx={{ mr: 2 }}>
              Historique indicateurs mission
            </Typography>
          </Stack>
          <IconButton edge="end" onClick={handleClose} size="large">
            <CloseIcon />
          </IconButton>
        </Stack>
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            p: 1.5,
            borderRadius: '8px',
          }}
        >
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableHeaderCell title="Date" />
                  <TableHeaderCell title="Statut Client" />
                  <TableHeaderCell title="Statut Équipe" />
                  <TableHeaderCell title="Commentaire" />
                  <TableHeaderCell title="Mis à jour par" />
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.indicatorHistory?.map(
                  ({
                    efficiency,
                    clientSatisfaction,
                    comment,
                    createdBy,
                    createdAt,
                  }) => {
                    return (
                      <TableRow key={createdAt}>
                        <TableCell component="th" scope="row">
                          {moment(createdAt).format(POLY_DATE)}
                        </TableCell>
                        <TableCell>
                          <ActivityIndicator indicator={clientSatisfaction} />
                        </TableCell>
                        <TableCell>
                          <ActivityIndicator indicator={efficiency} />
                        </TableCell>
                        <TableCell align="center">
                          {comment ? (
                            <CommentTooltip comment={comment} />
                          ) : (
                            EMPTY_FIELD
                          )}
                        </TableCell>
                        <TableCell>
                          {createdBy ? (
                            <AvatarNameAndOccupationStack
                              employee={createdBy as EmployeeNode}
                              withOccupation={false}
                            />
                          ) : (
                            EMPTY_FIELD
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack alignItems="flex-end" pt={3}>
            <PongoButton variant="contained" onClick={handleClose}>
              Fermer
            </PongoButton>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

const TableHeaderCell = ({ title }: { title: string }) => (
  <TableCell>
    <Typography variant="bodySemiBold">{title}</Typography>
  </TableCell>
);
