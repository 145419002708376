import { SettingsOutlined } from '@mui/icons-material';
import {
  Box,
  FormControlLabel,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import TabHeader from 'components/commons/TabHeader';
import YearSelector from 'components/commons/YearSelector/YearSelector';
import PongoButton from 'components/MUIOverload/PongoButton';
import ProfitabilityGlobalViewExportButton from 'components/Reporting/Profitability/GlobalView/ProfitabilityExportButton';
import ProfitabilityTableContextProvider from 'components/Reporting/Profitability/GlobalView/ProfitabilityTableContextProvider';
import { useUserInfo } from 'components/User/UserProvider';
import { EmployeesEmployeeGradeChoices } from 'generated/graphql';
import moment from 'moment/moment';
import { PageTitles } from 'poly-constants';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import urlJoin from 'url-join';

import { redirectViewMonthly, redirectViewTrimester } from './Reporting';
import ReportingProfitabilityMonthlyTable from './ReportingProfitabilityMonthlyTable';
import ReportingProfitabilityTrimesterTable from './ReportingProfitabilityTrimesterTable';

export default function ReportingProfitabilityGlobalView() {
  const [isYearSubtracted, setIsYearSubtracted] = useState(false);
  const [isYearAdded, setIsYearAdded] = useState(false);
  const [currentYear, setCurrentYear] = useState(moment().subtract(1, 'month'));
  const [isExcludingIntern, setisExcludingIntern] = useState(false);
  const { pathname, search } = useLocation();
  const isTrimesterView = pathname.includes('trimester-view');
  const history = useHistory();

  const { employee, isControl, isSuperuser } = useUserInfo();
  const isComex = employee?.grade === EmployeesEmployeeGradeChoices.Comex;

  function changeView() {
    if (isTrimesterView) {
      redirectViewMonthly(history, search);
    } else {
      redirectViewTrimester(history, search);
    }
  }

  function getLastValidatedMonthEndDate() {
    if (currentYear.year() === moment().year() && currentYear.month() === 1) {
      return '';
    } else if (currentYear.year() === moment().year()) {
      return moment().startOf('month').subtract(1, 'day').format('DD MMMM');
    } else {
      return currentYear.clone().endOf('year').format('DD MMMM');
    }
  }
  function handleWithInternChange(event: React.SyntheticEvent) {
    const target = event.target as HTMLInputElement;
    setisExcludingIntern(target.checked);
  }
  function toggleIsExcludingIntern() {
    setisExcludingIntern(!isExcludingIntern);
  }

  return (
    <>
      <TabHeader
        title={PageTitles.profitabilityPage}
        yearSelectorComponent={
          <YearSelector
            currentYear={currentYear}
            setCurrentYear={(NewYear: moment.Moment) => {
              setCurrentYear(NewYear);
              setIsYearSubtracted(false);
              setIsYearAdded(false);
            }}
            from={moment('2022-01-01')}
            to={moment()}
          />
        }
        filterComponents={
          <Stack direction={'row'} alignItems={'center'} gap={2} mb={0.5}>
            <FormControlLabel
              label={
                <Tooltip title="Vue trimestrielle">
                  <Typography>Vue trimestrielle</Typography>
                </Tooltip>
              }
              control={<Switch />}
              onChange={changeView}
              checked={isTrimesterView}
              labelPlacement="end"
            />
            <FormControlLabel
              label={
                <Tooltip title="Sans stagiaires">
                  <Box
                    component="img"
                    alt="without intern logo"
                    src={urlJoin(
                      '/',
                      process.env.PUBLIC_URL,
                      'img',
                      'without-intern.png'
                    )}
                    sx={{ height: '1.5em', width: '1.5em' }}
                  />
                </Tooltip>
              }
              control={<Switch />}
              onChange={handleWithInternChange}
              checked={isExcludingIntern}
              disableTypography={true}
              labelPlacement="end"
            />
          </Stack>
        }
        actionComponents={
          <>
            <ProfitabilityGlobalViewExportButton
              year={currentYear.year()}
              isTrimester={isTrimesterView}
              isExcludingIntern={isExcludingIntern}
              lastValidatedMonthEndDate={getLastValidatedMonthEndDate()}
            />
            {(isControl || isComex || isSuperuser) && (
              <PongoButton
                sx={{ mr: 0 }}
                variant={'contained'}
                startIcon={<SettingsOutlined />}
                onClick={() => {
                  history.push({
                    pathname: 'role-adr',
                    state: { year: currentYear.year() },
                  });
                }}
              >
                COÛTS PAR RÔLES
              </PongoButton>
            )}
          </>
        }
      />

      <ProfitabilityTableContextProvider
        isExcludingIntern={isExcludingIntern}
        toggleExcludingIntern={toggleIsExcludingIntern}
      >
        {isTrimesterView ? (
          <ReportingProfitabilityTrimesterTable
            currentYear={currentYear}
            setCurrentYear={setCurrentYear}
          />
        ) : (
          <ReportingProfitabilityMonthlyTable
            currentYear={currentYear}
            setCurrentYear={setCurrentYear}
            isYearSubtracted={isYearSubtracted}
            isYearAdded={isYearAdded}
            setIsYearSubtracted={setIsYearSubtracted}
            setIsYearAdded={setIsYearAdded}
          />
        )}
      </ProfitabilityTableContextProvider>
    </>
  );
}
