import { Box } from '@mui/material';
import { DataGridPro, DataGridProProps, frFR } from '@mui/x-data-grid-pro';
import React from 'react';

interface MuixTableProps extends DataGridProProps {
  containerHeight?: number | string;
  containerWidth?: number | string;
}

/**
 * this is a wrapper around DataGrid from \@mui/x-data-grid
 *
 * it allows to set the height and width of the container
 */
export default function MuixTable({
  containerHeight,
  containerWidth,
  ...tableProps
}: MuixTableProps) {
  return (
    <Box sx={{ height: containerHeight, width: containerWidth }}>
      <DataGridPro
        {...tableProps}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
      />
    </Box>
  );
}
