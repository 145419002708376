import { PurchaseOrderNode } from 'generated/graphql';
import _ from 'lodash';
import moment, { Moment } from 'moment';

export function isInRange(from: Moment, to: Moment, date: Moment) {
  return date.isSameOrAfter(from, 'month') && date.isSameOrBefore(to, 'month');
}

export function isMonthValidated(
  month: Moment,
  po: PurchaseOrderNode
): boolean {
  const revenue = _.find(po.revenues, (revenue) =>
    moment(revenue.month).isSame(month, 'month')
  );
  return !!revenue?.validated;
}

export function checkExceptionalMonth(
  month: Moment,
  exceptionalMonth?: Moment
): boolean {
  return exceptionalMonth ? month.isSame(exceptionalMonth, 'month') : false;
}

export function isInValidatedRangeMonths(
  rangeValidatedMonth: RangeMonth | undefined,
  month: Moment
) {
  return (
    rangeValidatedMonth &&
    month.isSameOrAfter(rangeValidatedMonth.from, 'month') &&
    month.isSameOrBefore(rangeValidatedMonth.to, 'month')
  );
}

export interface RangeMonth {
  from: Moment;
  to: Moment;
}
