import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Grid, MenuItem, TextField } from '@mui/material';
import {
  BillingAddressNode,
  BillingBillingAddressCategoryChoices,
} from 'generated/graphql';
import { useCorporateInfoFromSiretLazyQuery } from 'generated/graphql';
import { ProviderContext } from 'notistack';
import React, { useEffect } from 'react';
import { UseFormMethods } from 'react-hook-form';

import LabelTextFieldGrid from '../LabelTextFieldGrid';
interface BillingClientAddressProps {
  register: UseFormMethods['register'];
  watch: UseFormMethods['watch'];
  setValue: UseFormMethods['setValue'];
  errors: UseFormMethods['errors'];
  move: (index: number, to: number) => void;
  index: number;
  item: ModalAddress;
  enqueueSnackbar: ProviderContext['enqueueSnackbar'];
  dirtyFields: UseFormMethods['formState']['dirtyFields'];
}

export type ModalAddress = BillingAddressNode & { automatic: boolean };

export default function BillingClientAddress({
  register,
  watch,
  index,
  item,
  setValue,
  move,
  enqueueSnackbar,
  errors,
  dirtyFields,
}: BillingClientAddressProps) {
  const [isClientCategoryFR, setIsClientCategoryFR] = React.useState(
    item.category === 'FR' || item.category === undefined
  );
  const requiredField = 'Ce champ est requis';

  const [getEntrepriseInfoFromSiret, loading] =
    useCorporateInfoFromSiretLazyQuery({
      onCompleted: (data) => {
        const { addressLine1, zipCode, city, country } =
          data?.corporateInfoFromSiret || {};

        fillAddress({
          label:
            'Adresse declarée sur la base INSEE pour ' +
            watch('addresses')[index].siretNumber,
          address: addressLine1,
          zipCode: zipCode,
          city: city,
          country: country,
        });
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
    });

  function handleVerifySiret(): void {
    const formAddressValues = watch('addresses');
    getEntrepriseInfoFromSiret({
      variables: {
        siret: formAddressValues[index].siretNumber,
      },
    });
  }
  function fillAddress(address: {
    label?: string;
    address?: string;
    zipCode?: string;
    city?: string;
    country?: string;
  }): void {
    setValue(`addresses[${index}].label`, address.label, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue(`addresses[${index}].address`, address.address, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue(`addresses[${index}].zipCode`, address.zipCode, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue(`addresses[${index}].city`, address.city, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue(`addresses[${index}].country`, address.country, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }
  useEffect(() => {
    setValue(
      `addresses[${index}].category`,
      item.category ?? BillingBillingAddressCategoryChoices.Fr
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          ml: 0,
        }}
      >
        <Grid item xs={4}>
          <TextField
            select
            size="small"
            inputRef={
              register({
                name: `addresses[${index}].category`,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              }) as any
            }
            id="localisation-select"
            label="Localisation"
            onChange={(e) => {
              const value = e.target
                .value as BillingBillingAddressCategoryChoices;
              setValue(`addresses[${index}].category`, value, {
                shouldValidate: true,
                shouldDirty: true,
              });
              setIsClientCategoryFR(
                e.target.value === BillingBillingAddressCategoryChoices.Fr
              );
            }}
            defaultValue={
              item.category ?? BillingBillingAddressCategoryChoices.Fr
            }
            fullWidth
            placeholder="Localisation"
            required
            data-testid="localisation-select"
          >
            <MenuItem
              key={'key-' + BillingBillingAddressCategoryChoices.Fr}
              value={BillingBillingAddressCategoryChoices.Fr}
            >
              France
            </MenuItem>
            <MenuItem
              key={'key-' + BillingBillingAddressCategoryChoices.Ue}
              value={BillingBillingAddressCategoryChoices.Ue}
            >
              Union Européenne (hors France)
            </MenuItem>
            <MenuItem
              key={'key-' + BillingBillingAddressCategoryChoices.Int}
              value={BillingBillingAddressCategoryChoices.Int}
            >
              Hors Union Européenne
            </MenuItem>
          </TextField>
        </Grid>
        <Grid
          item
          xs={8}
          display={isClientCategoryFR ? 'flex' : 'none'}
          data-testid="siret-input"
          sx={{ alignItems: 'center' }}
        >
          <LabelTextFieldGrid
            inputRef={register()}
            name={`addresses[${index}].siretNumber`}
            title="N° SIRET"
            defaultValue={item.siretNumber}
            customspacingtop={0}
            customspacingside={0}
            sizegrid={8.25}
          />
          <LoadingButton
            variant="contained"
            size="small"
            onClick={handleVerifySiret}
            sx={{ ml: 2 }}
            loading={loading.loading}
          >
            Vérifier
          </LoadingButton>
        </Grid>
      </Grid>
      <LabelTextFieldGrid
        inputRef={register({ required: requiredField })}
        name={`addresses[${index}].label`}
        errorform={errors.addresses?.[index]?.label}
        required
        title={
          index === 0
            ? "Intitulé de l'adresse principale"
            : "Intitulé de l'adresse secondaire"
        }
        defaultValue={item.label || ''}
        sizegrid={12}
        shrink={dirtyFields.addresses?.[index]?.label ?? false}
      />
      <LabelTextFieldGrid
        inputRef={register()}
        name={`addresses[${index}].address`}
        title={'Adresse'}
        defaultValue={item.address || ''}
        sizegrid={12}
        shrink={dirtyFields.addresses?.[index]?.address ?? false}
      />
      <LabelTextFieldGrid
        inputRef={register({ required: requiredField })}
        name={`addresses[${index}].city`}
        errorform={errors.addresses?.[index]?.city}
        required
        title="Ville"
        defaultValue={item.city || ''}
        sizegrid={7}
        shrink={dirtyFields.addresses?.[index]?.city ?? false}
      />
      <LabelTextFieldGrid
        inputRef={register({ required: requiredField })}
        name={`addresses[${index}].zipCode`}
        errorform={errors.addresses?.[index]?.zipCode}
        required
        title="Code postal"
        defaultValue={item.zipCode || ''}
        sizegrid={5}
        shrink={dirtyFields.addresses?.[index]?.zipCode ?? false}
      />
      <Grid
        container
        item
        xs={12}
        direction="row"
        alignItems={'flex-end'}
        justifyContent={'space-between'}
      >
        <LabelTextFieldGrid
          inputRef={register({ required: requiredField })}
          name={`addresses[${index}].country`}
          required
          errorform={errors.addresses?.[index]?.country}
          title="Pays"
          defaultValue={item.country || ''}
          sizegrid={6}
          shrink={dirtyFields.addresses?.[index]?.country ?? false}
        />
        {index !== 0 && (
          <Button
            onClick={() => move(index, 0)}
            variant={'contained'}
            color={'primary'}
            size={'small'}
            sx={{ height: 40, mr: 2 }}
          >
            Passer en adresse principale
          </Button>
        )}
      </Grid>
      <LabelTextFieldGrid
        inputRef={register()}
        name={`addresses[${index}].id`}
        defaultValue={item.id || ''}
        hidden
      />
    </>
  );
}
