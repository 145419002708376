import AddCommentary from 'components/commons/ListCommentaries/AddCommentary';
import DisplayCommentary from 'components/commons/ListCommentaries/DisplayCommentary';
import { CommentaryNode } from 'generated/graphql';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'store';

import styles from './styles/ListCommentaries.module.scss';

export interface CommentaryDisplay extends CommentaryNode {
  className?: string;
  prefix?: string;
}

export default function ListCommentaries() {
  const [anchor, setAnchor] = useState<HTMLDivElement | null>();
  const currentBill = useSelector((state) => state.purchaseOrder.currentBill);
  const [commentaries, setCommentaries] = useState<CommentaryDisplay[]>();

  function scrollBottom() {
    anchor?.scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(() => {
    scrollBottom();
  });

  useEffect(() => {
    const billCommentaries = currentBill.commentaries as CommentaryDisplay[];
    const billCancellation = {
      ...currentBill.cancellationCommentary,
      prefix: "Demande d'annulation - ",
      className: styles.cancellationCommentary,
    } as CommentaryDisplay;

    const concatCommentaries: CommentaryDisplay[] = billCancellation.message
      ? [...(billCommentaries || []), billCancellation]
      : billCommentaries;

    setCommentaries(_.orderBy(concatCommentaries, ['createdAt'], ['asc']));
  }, [currentBill.commentaries, currentBill.cancellationCommentary]);
  return (
    <div className={styles.container}>
      <div className={styles.messagesContainer}>
        {_.map(commentaries, (commentary) => (
          <DisplayCommentary
            commentary={commentary}
            key={`${commentary.id}-cancelation_commentary`}
          />
        ))}
        {commentaries?.length === 0 && (
          <div className={styles.noComment}>
            Aucun commentaire pour le moment
          </div>
        )}
        <div ref={(element) => setAnchor(element)} />
      </div>
      <AddCommentary onSubmit={() => scrollBottom()} />
    </div>
  );
}
