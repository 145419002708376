import { TableCell, Typography } from '@mui/material';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import {
  BillingPurchaseOrderCurrencyChoices,
  ContractorReportingParameterNode,
  EmployeeNode,
} from 'generated/graphql';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { totalFormat } from 'pages/ActivityPage/utils';
import React from 'react';
import { displayEmployee } from 'utils';

import { StyledTableRow } from './ProviderAdrTableRow';

interface OccupationAdrHistoryTableRowProps {
  provider: EmployeeNode;
}

export default function ProviderAdrHistoryTableRow({
  provider,
}: OccupationAdrHistoryTableRowProps) {
  const { displayedMonths } = useTableHeaderContext();

  return (
    <StyledTableRow>
      <TableCell>
        <Typography>
          {displayEmployee(provider.firstName, provider.lastName)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>{provider.occupation}</Typography>
      </TableCell>
      {_.map(displayedMonths, (month) => {
        const mostRecentProviderAdr = getMostRecentParameterBeforeMonth(
          month,
          provider.contractorReportingParameters
        );
        const isEffectiveFromThisMonth =
          !!mostRecentProviderAdr &&
          month.isSame(moment(mostRecentProviderAdr.effectiveFrom), 'month');
        return (
          <TableCell>
            <Typography
              textAlign="center"
              color={isEffectiveFromThisMonth ? '' : 'text.secondary'}
            >
              {totalFormat(
                mostRecentProviderAdr?.adr || 0,
                BillingPurchaseOrderCurrencyChoices.Eur
              )}
            </Typography>
          </TableCell>
        );
      })}
    </StyledTableRow>
  );
}

function getMostRecentParameterBeforeMonth(
  month: Moment,
  adrs?: ContractorReportingParameterNode[]
): ContractorReportingParameterNode | undefined {
  const adrsEffectiveFromBeforeThisMonth = adrs?.filter(({ effectiveFrom }) =>
    moment(effectiveFrom).isSameOrBefore(month, 'month')
  );
  // assuming the backend has already reverse sorted the adrs by creation date
  return adrsEffectiveFromBeforeThisMonth?.[0];
}
