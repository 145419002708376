import { Fade, FormControlLabel, RadioGroup } from '@mui/material';
import DayInput from 'components/commons/DayInput';
import PolyRadioButton from 'components/MUIOverload/PolyRadioButton';
import { RecoveryDayMoment } from 'poly-constants';
import React, { useState } from 'react';

import styles from './styles/FieldDuration.module.scss';

interface FieldDurationProps {
  onChange: (dayDetail: { recoveryTime: number; moment: string }) => void;
  maxRecoveryDuration: number;
}

const FieldDuration = (properties: FieldDurationProps) => {
  const [moment, setMoment] = useState(RecoveryDayMoment.morning);
  const [recoveryTime, setRecoveryTime] = useState(0.25);

  const handleMomentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = (event.target as HTMLInputElement).value;
    setMoment(newValue);
    properties.onChange({
      recoveryTime,
      moment: newValue,
    });
  };

  const onChangeDayTime = (recoveryTime: number) => {
    setRecoveryTime(recoveryTime);
    properties.onChange({
      recoveryTime,
      moment: recoveryTime > 0.5 ? RecoveryDayMoment.allDay : moment,
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.numberDay}>Nombre de jours </div>
      <DayInput
        defaultValue={0.25}
        handleChange={onChangeDayTime}
        max={properties.maxRecoveryDuration}
      />
      <Fade in={recoveryTime <= 0.5}>
        <div className={styles.root}>
          <div className={styles.halfDay}>Demi-journée </div>
          <RadioGroup
            aria-label="demi-journée"
            name="Demi-journée"
            value={moment}
            onChange={handleMomentChange}
          >
            <FormControlLabel
              value={RecoveryDayMoment.morning}
              control={<PolyRadioButton />}
              label="Matin"
            />
            <FormControlLabel
              value={RecoveryDayMoment.afternoon}
              control={<PolyRadioButton />}
              label="Après-midi"
            />
          </RadioGroup>
        </div>
      </Fade>
    </div>
  );
};

export default FieldDuration;
