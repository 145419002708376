import { Edit } from '@mui/icons-material';
import { Box, Grid, Stack } from '@mui/material';
import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import Section from 'components/commons/Section';
import SectionTitle from 'components/commons/SectionTitle';
import SkillChip from 'components/commons/SkillChip';
import NoSkills from 'components/EmployeePage/EmployeeSkills/NoSkills';
import PongoButton from 'components/MUIOverload/PongoButton';
import {
  EmployeeNode,
  EmployeeSkillNode,
  useAllEmployeeSkillsForEmployeeQuery,
} from 'generated/graphql';
import _ from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { scrollbarParams } from 'utils';

import SkillLevelsInfoTooltipTitle from './SkillLevelsInfoTooltipTitle';
import SkillsModal from './SkillsModal';

interface EmployeeSkillsProps {
  employee: EmployeeNode;
  isEditable?: boolean;
}

export default function EmployeeSkills({
  employee,
  isEditable,
}: EmployeeSkillsProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data, loading, refetch } = useAllEmployeeSkillsForEmployeeQuery({
    variables: {
      employeeId: employee.id,
    },
    onError: () => {
      enqueueSnackbar(`Erreur lors du chargement des compétences.`, {
        variant: 'error',
      });
    },
  });

  const employeeSkills = (data?.allEmployeeSkillsForEmployee ||
    []) as EmployeeSkillNode[];

  return (
    <Section
      sx={{
        maxHeight: '250px',
      }}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <SectionTitle infoTooltipTitle={<SkillLevelsInfoTooltipTitle />}>
          Compétences
        </SectionTitle>
        {isEditable && employeeSkills.length > 0 && (
          <PongoButton
            variant="contained"
            size="small"
            startIcon={<Edit />}
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            Modifier
          </PongoButton>
        )}
      </Stack>

      {loading ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <LoadingPlaceholder />
        </Box>
      ) : (
        <>
          {employeeSkills.length > 0 ? (
            <Grid
              container
              gap={1}
              sx={{
                pr: 0.5,
                ...scrollbarParams,
              }}
            >
              {_.map(employeeSkills, (employeeSkill, index) => {
                return (
                  <Grid item key={`skills-grid-item-${index}`}>
                    <SkillChip
                      label={employeeSkill.skill.name}
                      level={employeeSkill.level}
                    />
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <NoSkills
              onAddClick={
                isEditable
                  ? () => {
                      setIsModalOpen(true);
                    }
                  : undefined
              }
            />
          )}
        </>
      )}

      <>
        {isModalOpen && (
          <SkillsModal
            isOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            employee={employee}
            employeeSkills={employeeSkills}
            refetchEmployeeSkills={refetch}
          />
        )}
      </>
    </Section>
  );
}
