import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { DialogContent, IconButton, Typography } from '@mui/material';
import PolyDialog from 'components/MUIOverload/PolyDialog';
import PolyDialogTitle from 'components/MUIOverload/PolyDialogTitle';
import PolyFooter from 'components/MUIOverload/PolyFooter';
import PongoButton from 'components/MUIOverload/PongoButton';
import { TrophyNode, useDeleteTrophyMutation } from 'generated/graphql';
import { useSnackbar } from 'notistack';
import React from 'react';

export interface TrophyModalProps {
  isOpen: boolean;
  handleClose: () => void;
  trophy?: TrophyNode;
  refetch: () => void;
}

const DeleteTrophyModal = ({
  isOpen,
  handleClose,
  trophy,
  refetch,
}: TrophyModalProps) => {
  const { enqueueSnackbar } = useSnackbar();
  if (!trophy && isOpen) {
    handleClose();
  }

  const [deleteTrophy] = useDeleteTrophyMutation({
    onCompleted: () => {
      enqueueSnackbar(
        `Le trophée « ${trophy?.name || ''} » a bien été supprimé`,
        {
          variant: 'success',
        }
      );
      refetch();
      handleClose();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const handleDelete = () => {
    deleteTrophy({
      variables: {
        trophyId: trophy?.id || '',
      },
    });
  };

  return (
    <PolyDialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEnforceFocus={true}
    >
      <DialogContent sx={{ width: '500px' }}>
        <IconButton
          onClick={handleClose}
          color={'primary'}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
        <PolyDialogTitle sx={{ pl: 0 }}>Supprimer un trophée</PolyDialogTitle>

        <Typography sx={{ mt: 2 }}>
          Souhaitez-vous supprimer ce trophée ?
        </Typography>
        <PolyFooter>
          <PongoButton onClick={handleClose}>Annuler</PongoButton>
          <PongoButton
            variant={'contained'}
            color={'primary'}
            type="submit"
            onClick={() => handleDelete()}
            startIcon={<DeleteIcon />}
          >
            Supprimer trophée
          </PongoButton>
        </PolyFooter>
      </DialogContent>
    </PolyDialog>
  );
};

export default DeleteTrophyModal;
