import { PlaylistAddCheck } from '@mui/icons-material';
import PongoButton from 'components/MUIOverload/PongoButton';
import React, { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { setGroupEdit } from 'store/purchaseOrder';

interface GroupEditButtonsProps {
  validateButton?: ReactNode;
  isInActionsMenu?: boolean;
}

const GroupEditButtons = ({ validateButton }: GroupEditButtonsProps) => {
  const dispatch = useDispatch();
  const groupEdit = useSelector((state) => state.purchaseOrder.groupEdit);
  const selectedBills = useSelector(
    (state) => state.purchaseOrder.selectedBills
  );

  return (
    <>
      <PongoButton
        onClick={() => dispatch(setGroupEdit(!groupEdit))}
        variant={'contained'}
        buttonStyle={groupEdit ? 'primary' : 'secondary'}
        color={'primary'}
        startIcon={<PlaylistAddCheck />}
        sx={{ mr: 1 }}
      >
        {groupEdit ? "Quitter l'édition groupée" : 'édition groupée'}
      </PongoButton>
      {groupEdit && selectedBills.length > 0 && validateButton}
    </>
  );
};

export default GroupEditButtons;
