import Box from '@mui/material/Box';
import { useNavbarContextProvider } from 'components/Navigation/DesktopNavbar/NavbarContextProvider';
import PolySwitch from 'components/Navigation/PolySwitch';
import TopHorizontalSearchBar, {
  TOP_HORIZONTAL_SEARCH_BAR_HEIGHT,
} from 'components/Navigation/SearchMissionBar/TopHorizontalSearchBar';
import { useUserInfo } from 'components/User/UserProvider';
import ActivityPage from 'pages/ActivityPage';
import DashboardPage from 'pages/DashboardPage';
import MissionFollowUp from 'pages/MissionFollowUp';
import Reporting from 'pages/ReportingPage/Reporting';
import RevenuePage from 'pages/RevenuePage';
import React from 'react';
import { Redirect, Route, useLocation, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'store';
import urlJoin from 'url-join';

const MissionFollowUpV2 = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const { employee, isControl, isComex, isSuperuser } = useUserInfo();
  const isMissionChief = employee?.isMissionChief;
  const isExternalMissionChief = employee?.isExternalMissionChief;
  const { drawerWidth } = useNavbarContextProvider();
  const dashboardUrl = urlJoin(path, 'dashboard');
  const indicatorsUrl = urlJoin(path, 'reporting/analysis');
  const selectedMissions = useSelector(
    (state) => state.activity.selectedMissions
  );

  const displayTopHorizontalSearchBar = ![indicatorsUrl].includes(pathname);
  const hideSearchBar = [dashboardUrl].includes(pathname);
  const topOffset =
    !displayTopHorizontalSearchBar ||
    (hideSearchBar && selectedMissions.length <= 0)
      ? '0px'
      : TOP_HORIZONTAL_SEARCH_BAR_HEIGHT;

  return (
    <Box sx={{ display: 'flex' }}>
      {displayTopHorizontalSearchBar && (
        <TopHorizontalSearchBar hideSearchBar={hideSearchBar} />
      )}

      <Box
        sx={{
          position: 'fixed',
          top: `calc(${topOffset})`,
          left: drawerWidth,
          width: `calc(100% - ${drawerWidth})`,
          height: `calc(100% - ${topOffset} )`,
          overflowX: 'auto',
          overflowY: 'scroll',
        }}
      >
        <PolySwitch>
          <Redirect
            exact
            from={path}
            to={urlJoin(
              path,
              isControl || isSuperuser ? 'activity' : '/dashboard'
            )}
          />
          <PolySwitch>
            <Route exact path={dashboardUrl} component={DashboardPage} />
            <Route path={urlJoin(path, 'activity')} component={ActivityPage} />
            {(isExternalMissionChief ||
              isComex ||
              isControl ||
              isSuperuser) && (
              <Route path={urlJoin(path, 'revenue')} component={RevenuePage} />
            )}
            <Route
              path={urlJoin(path, 'missions')}
              component={MissionFollowUp}
            />
            {(isMissionChief || isComex || isControl || isSuperuser) && (
              <Route path={urlJoin(path, 'reporting')} component={Reporting} />
            )}
          </PolySwitch>
        </PolySwitch>
      </Box>
    </Box>
  );
};

export default MissionFollowUpV2;
