import PongoButton from 'components/MUIOverload/PongoButton';
import React from 'react';
import { Link } from 'react-router-dom';

interface RedirectButtonProps {
  startIcon?: JSX.Element;
  title?: string;
  redirectionPath?: string;
}

const RedirectButton = ({
  redirectionPath,
  startIcon,
  title,
  children,
}: React.PropsWithChildren<RedirectButtonProps>) => {
  return (
    <React.Fragment>
      <Link
        to={redirectionPath || ''}
        target={'_blank'}
        rel={'noopener noreferrer'}
      >
        <PongoButton
          uppercase={false}
          title={title}
          variant={'contained'}
          buttonStyle={'secondary'}
          size="small"
          startIcon={startIcon}
        >
          {children}
        </PongoButton>
      </Link>
    </React.Fragment>
  );
};

export default RedirectButton;
