import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import {
  OvertimeAuthorizationNode,
  useOvertimeAuthorizationQuery,
} from 'generated/graphql';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useLocation } from 'react-router-dom';

import AuthorizationContent from '../ExceptionalActivitiesAuthorizations/AuthorizationContent';
import RequestContent from './RequestContent';

const RequestContentParent = () => {
  const { enqueueSnackbar } = useSnackbar();

  const search = useLocation().search;

  const { data, loading } = useOvertimeAuthorizationQuery({
    variables: {
      objectId: new URLSearchParams(search).get('mission_id') || '',
    },
    onError: () => {
      enqueueSnackbar('Problème de récupération des données', {
        variant: 'error',
      });
    },
  });

  return (
    <div>
      {data?.overtimeAuthorization && (
        <>
          <AuthorizationContent
            authorizationData={
              data?.overtimeAuthorization as OvertimeAuthorizationNode
            }
          />
          <RequestContent authorizationData={data?.overtimeAuthorization} />
        </>
      )}
      {loading && (
        <div>
          <LoadingPlaceholder />
        </div>
      )}
    </div>
  );
};

export default RequestContentParent;
