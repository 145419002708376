import { Search } from '@mui/icons-material';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import PolyModal from 'components/MUIOverload/PolyDialog/PolyModal';
import { useCreateEmployeeReportingParametersForOccupationMutation } from 'generated/graphql';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

interface OccupationAdrAddModalProps {
  occupations: string[];
  open: boolean;
  handleValidation: () => void;
  handleClose: () => void;
}

export default function OccupationAdrAddModal({
  occupations,
  open,
  handleValidation,
  handleClose,
}: OccupationAdrAddModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedOccupation, setSelectedOccupation] = useState('');

  const [createEmployeeReportingParameters] =
    useCreateEmployeeReportingParametersForOccupationMutation({
      onCompleted: () => {
        enqueueSnackbar(
          `Les coûts du rôle « ${selectedOccupation} » ont bien été créés aux coûts par défaut`,
          {
            variant: 'success',
          }
        );
        handleValidation();
        handleClose();
        setSelectedOccupation('');
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
    });

  const handleCreate = () => {
    if (selectedOccupation !== '') {
      createEmployeeReportingParameters({
        variables: { occupation: selectedOccupation },
      });
    }
  };

  return (
    <PolyModal
      open={open}
      onClose={handleClose}
      polyDialogTitle="Sélectionnez un rôle à ajouter"
      handlePrimary={handleCreate}
    >
      <Autocomplete
        sx={{ marginBottom: 6, width: 500 }}
        options={occupations}
        onChange={(event, value) => setSelectedOccupation(value || '')}
        renderInput={(parameters) => (
          <TextField
            label="Role"
            color={'secondary'}
            variant={'standard'}
            {...parameters}
            InputProps={{
              ...parameters.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </PolyModal>
  );
}
