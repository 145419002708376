import {
  Box,
  Button,
  Chip,
  Stack,
  SxProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { getUrlList } from 'components/Navigation/DesktopNavbar/utils';
import { AssignmentNodeActivity } from 'generated/graphql';
import moment from 'moment';
import { POLY_DATE } from 'poly-constants';
import React from 'react';
import { useHistory } from 'react-router-dom';
import urljoin from 'url-join';

import MissionTypeIcon from '../MissionTypeIcon';

interface MissionNameDisplayProps {
  mission: string;
  missionId?: string;
  currentAssignment?: AssignmentNodeActivity;
  type: string;
  isTmContracts: boolean;
  callBack?: () => void;
  haveBorder: boolean;
  customRadius?: string;
  sx?: SxProps;
  iconSx?: SxProps;
}

const MissionNameDisplay = (properties: MissionNameDisplayProps) => {
  const { activities } = getUrlList();
  const history = useHistory();
  const handleClick = () => {
    if (properties.missionId) {
      history.push({
        pathname: urljoin(
          activities,
          properties.missionId as string,
          'general_info'
        ),
        search: `?origin=${history.location.pathname}`,
      });
    }
  };
  const missionName = (
    <Stack flexDirection={'row'} alignItems={'center'} gap={1} mr={1}>
      <MissionTypeIcon
        sx={{
          fontSize: '18px',
          ...properties.iconSx,
        }}
        type={properties.type}
        isTmContracts={properties.isTmContracts}
        tooltip
      />
      <Typography
        variant="bodySBold"
        onClick={() => handleClick()}
        sx={{
          ...properties.sx,
          cursor: properties.missionId ? 'pointer' : undefined,
        }}
      >
        {properties.mission}
      </Typography>

      {properties.currentAssignment?.staffingRate && (
        <Tooltip
          title={`Du ${moment(
            properties.currentAssignment?.beginningDate
          ).format(POLY_DATE)} au ${moment(
            properties.currentAssignment?.expirationDate
          ).format(POLY_DATE)}`}
        >
          <Typography variant="body">
            <Chip
              label={`${properties.currentAssignment?.staffingRate}\u00A0%`}
              size="small"
            />
          </Typography>
        </Tooltip>
      )}
    </Stack>
  );

  return properties.callBack ? (
    <Box
      sx={{
        alignItems: 'center',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'center',
        width: 'fit-content',
        ...(properties.haveBorder && {
          background: 'background.default',
          border: '1px solid',
          borderColor: 'darkGrey.main',
          borderRadius: '5px',
        }),
        borderRadius: properties.customRadius,
        ...properties.sx,
      }}
    >
      <Button
        sx={{
          borderRadius: properties.customRadius,
          letterSpacing: 'normal',
          textTransform: 'none',
          paddingRight: '10px',
          paddingBottom: '4px',
          paddingTop: '4px',
        }}
        onClick={properties.callBack}
      >
        {missionName}
      </Button>
    </Box>
  ) : (
    <Typography
      sx={{
        alignItems: 'center',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'center',
        width: 'fit-content',
        ...(properties.haveBorder && {
          background: 'background.default',
          border: '1px solid',
          borderColor: 'darkGrey.main',
          borderRadius: '5px',
        }),
      }}
    >
      {missionName}
    </Typography>
  );
};

MissionNameDisplay.defaultProps = {
  isTmContracts: false,
  type: 'int',
  callBack: undefined,
  haveBorder: true,
};

export default MissionNameDisplay;
