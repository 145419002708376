import { Table, TableContainer, TableHead } from '@mui/material';
import commonStyles from 'components/commons/css/commons.module.scss';
import PageWrapper from 'components/commons/PageWrapper';
import TabHeader from 'components/commons/TabHeader';
import { useUserInfo } from 'components/User/UserProvider';
import TableContextProvider from 'contexts/ActivityMonitoringTable/TableContextProvider';
import TableHeaderContextProvider from 'contexts/ActivityMonitoringTable/TableHeaderContextProvider';
import { ActivityNode, useAllSharedActivitiesQuery } from 'generated/graphql';
import { useSnackbar } from 'notistack';
import { PageTitles } from 'poly-constants';
import React from 'react';

import TablePaginationMonitoring from '../TablePaginationMonitoring';
import AuthAndHistoryButtonsAndModals from './AuthAndHistoryButtonsAndModals';
import MissionFilter from './MissionFilter/MissionFilter';
import ExportButton from './Table/Header/ExportButton';
import MonitoringTableHeader from './Table/MonitoringTableHeader';
import TableBodyByUser from './Table/TableBodyByUser';

export default function ActivityMonitoringTable() {
  const { enqueueSnackbar } = useSnackbar();

  const { isControl, isSuperuser } = useUserInfo();

  const { data: sharedActivities } = useAllSharedActivitiesQuery({
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });
  const actionComponents =
    isControl || isSuperuser ? <AuthAndHistoryButtonsAndModals /> : undefined;
  const exportButtonComponent = !(isControl || isSuperuser) ? (
    <ExportButton displayIcon />
  ) : undefined;
  return (
    <PageWrapper noLimit>
      <TableContextProvider
        sharedActivities={
          sharedActivities?.allSharedActivities as ActivityNode[]
        }
      >
        <TabHeader
          title={PageTitles.activityMonitoring}
          filterComponents={<MissionFilter />}
          actionComponents={actionComponents}
          buttonComponent={exportButtonComponent}
        />
        <TableContainer sx={{ mt: 0, overflow: 'unset' }}>
          <Table sx={{ borderCollapse: 'separate' }}>
            <TableHead className={commonStyles.stickyHeader}>
              <TableHeaderContextProvider>
                <TablePaginationMonitoring />
                <MonitoringTableHeader />
              </TableHeaderContextProvider>
            </TableHead>
            <TableBodyByUser />
          </Table>
        </TableContainer>
      </TableContextProvider>
    </PageWrapper>
  );
}
