import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import Typography from '@mui/material/Typography';
import PolyDialog from 'components/MUIOverload/PolyDialog';
import PolyDialogActions from 'components/MUIOverload/PolyDialog/DefaultComponents/PolyDialogActions';
import PolyModalHeader from 'components/MUIOverload/PolyDialog/DefaultComponents/PolyModalHeader';
import { useUserInfo } from 'components/User/UserProvider';
import {
  EmployeeWeeklyContractNode,
  useUpdateEmployeeWorkingDaysMutation,
} from 'generated/graphql';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

import ContractualDaysTableBody from './ContractualDaysTable/ContractualDaysTableBody';
import ContractualDaysTableHeader from './ContractualDaysTable/ContractualDaysTableHeader';
import { getTotalWorkingDays } from './utils';

interface DetermineUserContractualDaysModalProps {
  open: boolean;
  handleClose: () => void;
  updateIsValidWorkingDays: (totalWorkingDays: number) => void;
  userWeeklyContract: EmployeeWeeklyContractNode;
  refetchWeeklyContract: Function;
}

export default function UserContractualDaysModal({
  open,
  handleClose,
  updateIsValidWorkingDays,
  userWeeklyContract,
  refetchWeeklyContract,
}: DetermineUserContractualDaysModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { refetch } = useUserInfo();

  const [monday, setMonday] = useState(userWeeklyContract.monday);
  const [tuesday, setTuesday] = useState(userWeeklyContract.tuesday);
  const [wednesday, setWednesday] = useState(userWeeklyContract.wednesday);
  const [thursday, setThursday] = useState(userWeeklyContract.thursday);
  const [friday, setFriday] = useState(userWeeklyContract.friday);
  const saturday = userWeeklyContract.saturday;
  const sunday = userWeeklyContract.sunday;

  const workingDaysStates = [
    { values: monday, setValues: setMonday },
    { values: tuesday, setValues: setTuesday },
    { values: wednesday, setValues: setWednesday },
    { values: thursday, setValues: setThursday },
    { values: friday, setValues: setFriday },
  ];

  const handleCloseAndResetStates = () => {
    setMonday(userWeeklyContract.monday);
    setTuesday(userWeeklyContract.tuesday);
    setWednesday(userWeeklyContract.wednesday);
    setThursday(userWeeklyContract.thursday);
    setFriday(userWeeklyContract.friday);
    handleClose();
  };

  const totalWorkingDays = getTotalWorkingDays(workingDaysStates);
  updateIsValidWorkingDays(totalWorkingDays);
  const isValidTotalWorkingDays =
    totalWorkingDays === userWeeklyContract.weeklyContract;

  const [executeEmployeeWorkingDaysMutation] =
    useUpdateEmployeeWorkingDaysMutation({
      onCompleted: () => {
        refetchWeeklyContract();
        handleClose();
        enqueueSnackbar(`Jours contractuels mis à jour`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar(
          `Erreur lors de l'enregistrement des jours contractuels`,
          {
            variant: 'error',
          }
        );
      },
    });

  const handleSubmit = () => {
    executeEmployeeWorkingDaysMutation({
      variables: {
        monday: monday,
        tuesday: tuesday,
        wednesday: wednesday,
        thursday: thursday,
        friday: friday,
        saturday: saturday,
        sunday: sunday,
      },
    });
    refetch();
  };

  return (
    <>
      <PolyDialog open={open} onClose={handleCloseAndResetStates}>
        <Box sx={{ padding: 2, paddingBottom: 1 }}>
          <PolyModalHeader
            handleClose={handleCloseAndResetStates}
            polyDialogTitle={'Renseigner mes jours contractuels'}
            sx={{ paddingBottom: 2 }}
          />
          <Table>
            <ContractualDaysTableHeader />
            <ContractualDaysTableBody workingDaysStates={workingDaysStates} />
          </Table>
          <Typography sx={{ marginTop: 1 }}>
            Jours contractuels renseignés :
            <Typography
              sx={{
                display: 'inline',
                color: isValidTotalWorkingDays ? '' : 'error.main',
              }}
            >
              {` ${totalWorkingDays}/${userWeeklyContract.weeklyContract}`}
            </Typography>
          </Typography>
          <PolyDialogActions
            handleSecondary={handleCloseAndResetStates}
            handlePrimary={handleSubmit}
            isPrimaryButtonDisabled={!isValidTotalWorkingDays}
          />
        </Box>
      </PolyDialog>
    </>
  );
}
