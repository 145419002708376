import { Box, Stack, Typography } from '@mui/material';
import AvatarInitial from 'components/commons/AvatarInitial/AvatarInitial';
import PolyTooltip from 'components/MUIOverload/PolyTooltip';
import { HistoryAction, PurchaseOrderHistoryNode } from 'generated/graphql';
import moment from 'moment';
import { totalFormat } from 'pages/ActivityPage/utils';
import { POLY_DATE } from 'poly-constants';
import React from 'react';
import { useSelector } from 'store';
import { displayEmployee } from 'utils';

interface PurchaseOrderHistoryUnitProps {
  historyNode: PurchaseOrderHistoryNode;
}

export default function PurchaseOrderHistoryUnit({
  historyNode,
}: PurchaseOrderHistoryUnitProps) {
  const action = historyNode.action || HistoryAction.None;
  const currentPurchaseOrders = useSelector(
    (state) => state.purchaseOrder.currentPurchaseOrder
  );
  const fieldWithoutValueDisplay = new Set([
    'billing_activity_information',
    'billing_address',
    'attachments',
    'document',
  ]);
  const displayFunctionPerField: { [name: string]: (arg: string) => string } = {
    period_beginning: (arg) => moment(arg).format(POLY_DATE),
    period_ending: (arg) => moment(arg).format(POLY_DATE),
    created_at: (arg) => moment(arg).format(POLY_DATE),
    total: (arg) => totalFormat(Number(arg), currentPurchaseOrders.currency),
    daily_rate: (arg) =>
      totalFormat(Number(arg), currentPurchaseOrders.currency),
  };

  const get_value_display = (
    action: HistoryAction,
    field: string,
    oldValue: string,
    newValue: string
  ) => {
    if (fieldWithoutValueDisplay.has(field)) {
      return '';
    }
    if (field === 'daily_rate') {
      newValue = newValue.split('-')[1].trim();
      oldValue = oldValue.split('-')[1].trim();
    }
    const displayNewValue =
      field in displayFunctionPerField
        ? displayFunctionPerField[field](newValue)
        : newValue;
    const displayOldValue =
      field in displayFunctionPerField
        ? displayFunctionPerField[field](oldValue)
        : oldValue;
    switch (action) {
      case HistoryAction.Add:
        if (displayNewValue === '') {
          return <></>;
        }
        return (
          <>
            &quot;
            <Box fontWeight="fontWeightMedium" display="inline">
              {displayNewValue}
            </Box>
            &quot;
          </>
        );
      case HistoryAction.Delete:
        if (displayOldValue === '' && field === 'collaborators') {
          return <></>;
        }
        return (
          <>
            &quot;
            <Box fontWeight="fontWeightMedium" display="inline">
              {displayOldValue}
            </Box>
            &quot;
          </>
        );
      case HistoryAction.Change:
        if (field === 'collaborators') {
          return (
            <>
              &quot;
              <Box fontWeight="fontWeightMedium" display="inline">
                {displayOldValue}
              </Box>
              &quot; -&gt; &quot;
              <Box fontWeight="fontWeightMedium" display="inline">
                {displayNewValue}
              </Box>
              &quot;
            </>
          );
        }
        if (field === 'has_temporary_document') {
          return (
            <>
              &quot;
              <Box fontWeight="fontWeightMedium" display="inline">
                {displayOldValue === 'true'
                  ? field_translation.true
                  : field_translation.false}
              </Box>
              &quot; -&gt; &quot;
              <Box fontWeight="fontWeightMedium" display="inline">
                {displayNewValue === 'true'
                  ? field_translation.true
                  : field_translation.false}
              </Box>
              &quot;
            </>
          );
        }
        return (
          <>
            &quot;
            <Box fontWeight="fontWeightMedium" display="inline">
              {displayOldValue}
            </Box>
            &quot; -&gt; &quot;
            <Box fontWeight="fontWeightMedium" display="inline">
              {displayNewValue}
            </Box>
            &quot;
          </>
        );
      case HistoryAction.Create:
        return '';
      default:
        return '(pas de changement)';
    }
  };

  const get_action_translation = (action: HistoryAction) => {
    switch (action) {
      case HistoryAction.Add:
        return 'Ajout';
      case HistoryAction.Delete:
        return 'Suppression';
      case HistoryAction.Change:
        return 'Modification';
      case HistoryAction.Create:
        return 'Création';
      default:
        return '';
    }
  };

  const field_translation: { [name: string]: string } = {
    billing_activity_information: 'du client :',
    billing_address: "de l'adresse de facturation :",
    name: 'du nom :',
    number: 'du numéro de bdc :',
    period_beginning: 'de la date de début :',
    period_ending: 'de la date de fin :',
    created_at: 'de la date de création :',
    currency: 'de la monnaie :',
    attachments: 'de fichier annexe',
    document: 'du justificatif',
    has_temporary_document: "de l'état 'pas de justificatif' :",
    total: 'du montant total :',
    currency_conversion_rate: 'du taux de conversion en euro :',
    po: 'du BDC',
    daily_rate: 'du TJM de',
    title: 'du libellé de',
    collaborator: 'de collaborateur :',
    NA: '',
    submission: 'sous-mission',
    true: 'Vrai',
    false: 'Faux',
  };

  const fieldName = () => {
    let name = '';
    if ((historyNode.field || 'NA') in field_translation) {
      name = name + field_translation[historyNode.field || 'NA'];
      if (historyNode.collaborator) {
        name = name + ' ' + historyNode.collaborator + ' : ';
      }
    } else if (historyNode.field === 'collaborators') {
      name = ' de ';
      if (!historyNode.changeCount) {
        name = name + 'collaborateurs';
      } else {
        name =
          ' de ' +
          historyNode.changeCount +
          ' ' +
          (historyNode.changeCount && historyNode.changeCount > 1
            ? 'collaborateurs'
            : 'collaborateur');
      }
    }
    if (historyNode.subMission) {
      name =
        name +
        ` sur ${historyNode.subMission
          .replace('submission', field_translation.submission)
          .replace('for po', field_translation.po)}`;
    }
    return name;
  };

  return (
    <Stack
      sx={{
        pb: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <PolyTooltip
        title={displayEmployee(
          historyNode.user?.firstName || 'Inconnu',
          historyNode.user?.lastName,
          false
        )}
      >
        <span>
          <AvatarInitial employee={historyNode.user} />
        </span>
      </PolyTooltip>
      <Stack>
        <Typography sx={{ color: 'gray', fontSize: '10px' }}>
          {historyNode.date &&
            moment(historyNode.date).format('DD-MM-YYYY HH:mm')}
        </Typography>
        <Stack sx={{ flexDirection: 'column' }}>
          <Typography>
            {`${get_action_translation(action)} ${fieldName()}${' '}`}
          </Typography>
          <Typography sx={{ ml: 1 }}>
            {get_value_display(
              action,
              historyNode.field || '',
              historyNode.oldValue || '',
              historyNode.newValue || ''
            )}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
