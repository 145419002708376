import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

const LoadingPlaceholder = () => {
  return (
    <Box sx={{ width: '100%', textAlign: 'center' }}>
      <Box sx={{ position: 'relative', display: 'inline-block' }}>
        <CircularProgress
          variant="determinate"
          size={50}
          thickness={4}
          value={100}
          sx={{ color: 'primary.light' }}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
            strokeLinecap: 'round',
          }}
          color="primary"
          size={50}
          thickness={4}
        />
      </Box>
    </Box>
  );
};

export default LoadingPlaceholder;
