import {
  GridSize,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
} from '@mui/material';
import PolyGrid from 'components/MUIOverload/PolyGrid';
import React from 'react';
import { FieldError } from 'react-hook-form';

import styles from './styles/LabelTextFieldGrid.module.scss';

export interface LabelTextFieldProps {
  title?: string;
  required: boolean;
  hidden?: boolean;
  sizegrid?: GridSize;
  customspacingtop?: number;
  customspacingside?: number;
  error?: boolean;
  customElementInTitle?: JSX.Element;
  errorform?: FieldError;
  sx?: SxProps<Theme>;
  shrink?: boolean;
}

const LabelTextFieldGrid = (
  properties: LabelTextFieldProps & TextFieldProps
) => {
  const { customElementInTitle, title, ...TextFieldNativeProperties } =
    properties;
  return (
    <PolyGrid
      item
      xs={properties.sizegrid}
      customspacingside={properties.customspacingside}
      customspacingtop={properties.customspacingtop}
      hidden={properties.hidden}
      sx={properties.sx}
    >
      <TextField
        {...TextFieldNativeProperties}
        required={false}
        className={properties.className ?? ''}
        variant="outlined"
        size={'small'}
        fullWidth
        InputLabelProps={{
          shrink: properties.shrink ?? properties.disabled ? true : undefined,
        }}
        error={properties.error || !!properties.errorform}
        helperText={properties.errorform?.message}
        InputProps={{
          sx: { backgroundColor: 'default' },
          ...TextFieldNativeProperties.InputProps,
        }}
        label={
          title && (
            <div>
              {title}
              {properties.required && (
                <span className={styles.asterisk}>*</span>
              )}
              {customElementInTitle}
            </div>
          )
        }
      />
    </PolyGrid>
  );
};

LabelTextFieldGrid.defaultProps = {
  required: false,
  hidden: false,
  sizegrid: 4,
  customspacingside: 15,
  customspacingtop: 30,
};

export default LabelTextFieldGrid;
