import { Paper, PaperProps } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import styles from './styles/PolyPaper.module.scss';

const PolyPaper = (properties: PaperProps) => {
  return (
    <Paper
      {...properties}
      square
      className={clsx(styles.root, properties.className)}
    />
  );
};

export default PolyPaper;
