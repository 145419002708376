import {
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { EMPLOYEE_CARD_STYLE } from 'components/Phonebook/EmployeeCard/EmployeeCard';
import React from 'react';

import EmployeeAvatarSkeleton from './EmployeeAvatarSkeleton';

export default function EmployeeCardSkeleton() {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Stack
      sx={{
        ...EMPLOYEE_CARD_STYLE,
        backgroundColor: theme.palette.background.default,
      }}
    >
      {/* Firstname */}
      <Typography
        variant={isPhone ? 'bodySBold' : 'bodyBold'}
        sx={{
          pt: 0.25,
          maxWidth: `120px`,
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        <Skeleton variant="rounded" animation="wave" width="60px" />
      </Typography>
      {/* Lastname */}
      <Typography
        variant={isPhone ? 'bodySBold' : 'bodyBold'}
        sx={{
          pt: 0.75,
          maxWidth: '184px',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        <Skeleton variant="rounded" animation="wave" width="100px" />
      </Typography>
      {/* Role */}
      <Typography
        variant={isPhone ? 'body2' : 'bodySSemiBold'}
        fontSize={'0.875rem'}
        sx={{
          py: 1,
          maxWidth: '184px',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        <Skeleton variant="rounded" animation="wave" width="140px" />
      </Typography>
      {/* Image */}
      <EmployeeAvatarSkeleton />
    </Stack>
  );
}
