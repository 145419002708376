import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import SandboxRibbon from 'components/commons/SandboxRibbon';
import * as React from 'react';
import { isDev, isEhp } from 'utils';

import DrawerFooter from './DrawerFooter';
import DrawerHeader from './DrawerHeader';
import DrawerMainMenu from './DrawerMainMenu';
import { useNavbarContextProvider } from './NavbarContextProvider';
import NavbarDownload from './NavbarDownload';
import { DRAWER_TRANSITION_DURATION, TRANSITION_STYLE } from './utils';

const getDrawerBackgroundColor = () => {
  if (isEhp) {
    return 'ehpBlue.main';
  }
  if (isDev) {
    return 'devGreen.main';
  }
  return 'lightGrey.main';
};

export default function DesktopNavbar() {
  const { isDrawerOpen, drawerWidth } = useNavbarContextProvider();
  const isDevOrEHP = isDev || isEhp;

  return (
    <Drawer
      variant="permanent"
      open={isDrawerOpen}
      PaperProps={{
        sx: {
          width: drawerWidth,
          px: isDrawerOpen ? 3.5 : 0,
          py: 3,
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          overflowY: 'visible',
          backgroundColor: getDrawerBackgroundColor(),
          transition: `width ${TRANSITION_STYLE}`,
        },
      }}
      sx={{
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        zIndex: 3,
      }}
      transitionDuration={{
        appear: DRAWER_TRANSITION_DURATION,
        enter: DRAWER_TRANSITION_DURATION,
        exit: DRAWER_TRANSITION_DURATION,
      }}
    >
      <DrawerHeader />
      <Divider sx={{ mb: 2 }} />
      <DrawerMainMenu />
      {isDevOrEHP && isDrawerOpen && <SandboxRibbon />}
      <DrawerFooter />
      <NavbarDownload />
    </Drawer>
  );
}
