import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Box, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import React from 'react';

interface AddItemProps {
  onClick: () => void;
  description: string;
  sx?: SxProps;
}

export default function AddItem(properties: AddItemProps) {
  const { sx, description } = properties;
  const { palette } = useTheme();
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', ...sx }}>
      <Box
        sx={{
          width: '129px',
          height: '81px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          color: palette.primary.main,
          '&:hover': {
            backgroundColor: palette.backgroundHover.main,
            cursor: 'pointer',
          },
        }}
        onClick={properties.onClick}
      >
        <AddCircleOutlineOutlinedIcon
          sx={{ width: '100%', mb: 1, backgroundColor: 'unset' }}
        />
        <Box
          sx={{
            display: 'flex',
            textAlign: 'center',
            textTransform: 'uppercase',
            backgroundColor: 'unset',
          }}
        >
          {description}
        </Box>
      </Box>
    </Box>
  );
}
