import PolyModal from 'components/MUIOverload/PolyDialog/PolyModal';
import {
  IndicatorChoices,
  useCreateActivityIndicatorMutation,
  useIndicatorHistoryQuery,
} from 'generated/graphql';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useForm } from 'react-hook-form';

import IndicatorForm, { IndicatorFormData } from './IndicatorForm';

interface IndicatorModalProps {
  activityId: string;
  open: boolean;
  handleClose: () => void;
  refetch: () => void;
}

export default function IndicatorModal({
  activityId,
  open,
  handleClose,
  refetch,
}: IndicatorModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control, watch, errors } = useForm<IndicatorFormData>();
  const { refetch: refetchHistory } = useIndicatorHistoryQuery({
    variables: { activityId },
  });

  const [createActivityIndicator] = useCreateActivityIndicatorMutation({
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const efficiency = watch('efficiency');
  const clientSatisfaction = watch('clientSatisfaction');
  const comment = watch('comment');
  const isCommentRequired = [efficiency, clientSatisfaction].includes(
    IndicatorChoices.Critical
  );
  const isPrimaryButtonDisabled =
    !efficiency || !clientSatisfaction || (isCommentRequired && !comment);

  const createIndicator = async (formValues: IndicatorFormData) => {
    await createActivityIndicator({
      variables: {
        activityId,
        ...formValues,
      },
    });
    handleClose();
    refetch();
    refetchHistory();
  };

  return (
    <PolyModal
      open={open}
      onClose={handleClose}
      polyDialogTitle="Indicateurs missions"
      handlePrimary={handleSubmit(createIndicator)}
      isPrimaryButtonDisabled={isPrimaryButtonDisabled}
    >
      <IndicatorForm
        isCommentRequired={isCommentRequired}
        control={control}
        errors={errors}
      />
    </PolyModal>
  );
}
