import { TableCell, TableRow } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import clsx from 'clsx';
import ActivityMonthPicker from 'components/ActivityPage/ActivityMonthPicker';
import commonStyles from 'components/commons/css/commons.module.scss';
import ColumnLabel from 'components/commons/Tables/ColumnLabel';
import {
  buildColumnLabels,
  buildColumnLabelsV2,
} from 'components/commons/Tables/utils';
import { useUserInfo } from 'components/User/UserProvider';
import _ from 'lodash';
import moment from 'moment';
import { hasUpdateAMAuthValid, isEditableCell } from 'pages/ActivityPage/utils';
import { ACTIVITY_DATE_FORMAT } from 'poly-constants';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import headStyle from 'styles/ActivityPage/HeaderTable.module.scss';
import styles from 'styles/ActivityPage/PrincipalTable.module.scss';
import underlinedStyles from 'styles/ActivityPage/UnderlinedRow.module.scss';

import { useTableContext } from './context/TableContextProvider';

interface PrincipalTableHeadProps {
  viewMonth: string;
  setDisplayedWeeks: React.Dispatch<React.SetStateAction<number>>;
  incrementPeriod: () => void;
  decrementPeriod: () => void;
  isLoading: boolean;
}
const getClassName = (hasUpdateAmAuth: boolean, isEditableCell: boolean) => {
  if (hasUpdateAmAuth && isEditableCell) {
    return styles.handBackWeeksColor;
  }
  return !isEditableCell ? styles.unModifiableWeeksColor : '';
};

const PrincipalTableHead = ({
  viewMonth,
  setDisplayedWeeks,
  incrementPeriod,
  decrementPeriod,
  isLoading,
}: PrincipalTableHeadProps) => {
  const { employee } = useUserInfo();
  const { setWeekRange } = useTableContext();
  const { unregister } = useFormContext();
  const today = moment();
  const currentMonth = moment(viewMonth, ACTIVITY_DATE_FORMAT);
  const [columnLabels, setColumnLabels] = useState(
    currentMonth.isSameOrAfter(moment('2023-01-01'))
      ? buildColumnLabelsV2(viewMonth)
      : buildColumnLabels(viewMonth)
  );

  useEffect(() => {
    const localLabels = moment(viewMonth, ACTIVITY_DATE_FORMAT).isSameOrAfter(
      moment('2023-01-01')
    )
      ? buildColumnLabelsV2(viewMonth)
      : buildColumnLabels(viewMonth);
    setColumnLabels(localLabels);
    setWeekRange(localLabels);
    setDisplayedWeeks(localLabels.length);
  }, [setDisplayedWeeks, setWeekRange, setColumnLabels, viewMonth]);

  // clears the form context when changing month
  const handleClickDecrement = () => {
    unregister('ActivityMonitoring');
    decrementPeriod();
  };
  const handleClickIncrement = () => {
    unregister('ActivityMonitoring');
    incrementPeriod();
  };

  return (
    <TableHead className={commonStyles.stickyHeader}>
      <TableRow className={underlinedStyles.noUnderline}>
        <TableCell
          className={headStyle.selectMonthCell}
          colSpan={columnLabels.length + 1}
        >
          <ActivityMonthPicker
            viewMonth={viewMonth}
            incrementPeriod={handleClickIncrement}
            decrementPeriod={handleClickDecrement}
          />
        </TableCell>
      </TableRow>
      {!isLoading && (
        <TableRow className={underlinedStyles.fullUnderline}>
          <TableCell className={styles.emptyMissionCell} />
          {_.map(columnLabels, (column) => {
            const isEditable = isEditableCell(
              employee,
              today,
              column.start,
              column.end
            );
            const hasUpdateAmAuth = hasUpdateAMAuthValid(
              employee?.updateAuthorization || {},
              employee?.updateAuthorization?.months || undefined,
              column.start
            );
            const weekLabel = `S${column.start.format('WW')}${
              column.weekPart ? ' - ' + column.weekPart : ''
            }`;
            return (
              <ColumnLabel
                id={column.start.format()}
                className={clsx(getClassName(hasUpdateAmAuth, isEditable))}
                key={weekLabel}
                hasUpdateAMAuthValid={hasUpdateAmAuth}
                updateAmValidUntil={employee?.updateAuthorization?.validUntil}
                isEditable={isEditable}
                endDate={column.end}
                startDate={column.start}
                weekPart={column?.weekPart}
              />
            );
          })}
        </TableRow>
      )}
    </TableHead>
  );
};

export default PrincipalTableHead;
