import { Grid, TableCell, TableHead, TableRow, useTheme } from '@mui/material';
import { stickyHeaderSx } from 'components/commons/SX/commonSx';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import { useTableContext } from 'components/Revenue/RevenueGlobalTable/context/TableContextProvider';
import NavigationButton from 'components/Revenue/utils/NavigationButton';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

export default function RevenueTableHeader() {
  const {
    displayedMonths,
    incrementMonth,
    decrementMonth,
    dateRange,
    setYear,
  } = useTableContext();

  const { palette } = useTheme();

  const { currentYear } = useTableHeaderContext();

  if (!displayedMonths[0].isSame(currentYear, 'year')) {
    setYear(currentYear.year());
  }

  return (
    <TableHead sx={stickyHeaderSx}>
      <TableRow sx={{ height: '30px' }}>
        <TableCell sx={{ position: 'relative', width: '20%' }}>
          Client
        </TableCell>
        {_.map(displayedMonths, (month, index) => {
          return (
            <TableCell
              sx={{
                pb: 0,
                px: 0,
                position: 'relative',
                textTransform: 'capitalize',
                alignItems: 'center',
              }}
              style={{
                ...(month.isSame(moment(), 'month') && {
                  color: palette.primary.main,
                  boxShadow: `inset 0 0 ${palette.primary.main}, inset 0 -4px ${palette.primary.main}`,
                }),
              }}
              key={`header-${month.format('MMM')}`}
            >
              <Grid container direction={'row'} alignItems={'center'}>
                <Grid item xs={2} display={'flex'} alignItems={'center'}>
                  {index === 0 && dateRange.start.month() !== 0 && (
                    <NavigationButton
                      navigation={decrementMonth}
                      type="before"
                    />
                  )}
                </Grid>
                <Grid item xs={8} display={'flex'} justifyContent={'center'}>
                  {month.format('MMM')}
                </Grid>
                <Grid item xs={2} display={'flex'} justifyContent={'end'}>
                  {index === displayedMonths.length - 1 &&
                    dateRange.end.month() !== 11 && (
                      <NavigationButton
                        navigation={incrementMonth}
                        type="next"
                      />
                    )}
                </Grid>
              </Grid>
            </TableCell>
          );
        })}
        <TableCell sx={{ textAlign: 'center' }}>Total année</TableCell>
      </TableRow>
    </TableHead>
  );
}
