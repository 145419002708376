import { Box, Grid } from '@mui/material';
import EmployeeStaffing from 'components/commons/EmployeeStaffing/EmployeeStaffing';
import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import PageWrapper from 'components/commons/PageWrapper';
import PolyHeader from 'components/commons/PolyHeader';
import EmployeeSkills from 'components/EmployeePage/EmployeeSkills';
import { getUrlList } from 'components/Navigation/DesktopNavbar/utils';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import { useUserInfo } from 'components/User/UserProvider';
import { EmployeeNode, useEmployeeQuery } from 'generated/graphql';
import { enqueueSnackbar } from 'notistack';
import UserInfo from 'pages/UserPage/UserInfo';
import { DATA_LOADING_ERROR_MESSAGE } from 'poly-constants';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

const EmployeePage = () => {
  const ADMIN_BACK_OFFICE_FLAG = useEnvironmentVariable('admin_back_office');
  const { employee: userEmployee } = useUserInfo();
  const { id } = useParams<{ id: string }>();
  const { employees } = getUrlList();
  const history = useHistory();
  const { data, loading, refetch } = useEmployeeQuery({
    variables: {
      employeeId: id,
    },
    onError: () => {
      enqueueSnackbar(DATA_LOADING_ERROR_MESSAGE, {
        variant: 'error',
      });
      history.push(employees);
    },
  });
  const employee = data?.employee;
  return (
    <PageWrapper noLimit>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            height: '800px',
            alignItems: 'center',
          }}
        >
          <LoadingPlaceholder />
        </Box>
      ) : (
        <>
          <PolyHeader goBackLocation={employees as unknown as Location}>
            {employee?.firstName} {employee?.lastName}
          </PolyHeader>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {employee && <UserInfo employee={employee as EmployeeNode} />}
            </Grid>
            {ADMIN_BACK_OFFICE_FLAG && employee?.id && (
              <Grid item xs={12}>
                <EmployeeSkills
                  employee={employee as EmployeeNode}
                  isEditable={userEmployee?.id === id}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              {employee && (
                <EmployeeStaffing
                  employee={employee as EmployeeNode}
                  refetchEmployee={refetch}
                />
              )}
            </Grid>
          </Grid>
        </>
      )}
    </PageWrapper>
  );
};

export default EmployeePage;
