import { Divider } from '@mui/material';
import { defaults } from 'chart.js';
import TabHeader from 'components/commons/TabHeader';
import YearSelector from 'components/commons/YearSelector/YearSelector';
import ActivityIndicatorTable from 'components/Indicators/ActivityIndicatorTable/ActivityIndicatorTable';
import MultiAdrGraph from 'components/Reporting/Indicators/MultiAdrGraph';
import AdrGraphContextProvider from 'components/Reporting/Indicators/MultiAdrGraph/context/AdrGraphContextProvider';
import BillableStaffingWrapper from 'components/Reporting/Indicators/Staffing/BillableStaffingWrapper';
import { useAllActivitiesForRevenueAnalysisQuery } from 'generated/graphql';
import moment, { Moment } from 'moment';
import { PageTitles } from 'poly-constants';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

defaults.font.family = ['Outfit', 'Open Sans'].join(',');

export default function IndicatorsPage() {
  const history = useHistory();
  const { search } = useLocation();

  let year = Number(new URLSearchParams(search).get('year')) || moment().year();
  const handleYearChange = (date: Moment) => {
    year = date.year();
    history.push({ search: `?year=${date.year()}` });
  };

  // Check if we are in the first month of the current year because there is no data to fetch
  const isInJanuary = moment().month() + 1 === 1 && moment().year() === year;

  return (
    <>
      <TabHeader title={PageTitles.missionsSummaryPage} ignoreMissionHeader />
      <ActivityIndicatorTable
        queryHook={useAllActivitiesForRevenueAnalysisQuery}
      />
      <Divider
        sx={{ my: 3, borderColor: 'standardGrey.dark', borderWidth: '1px' }}
      />
      <TabHeader
        title={PageTitles.keyIndicatorsPage}
        yearSelectorComponent={
          <YearSelector
            currentYear={moment({ year })}
            setCurrentYear={handleYearChange}
            from={moment('2022-01-01')}
            to={moment()}
          />
        }
        ignoreMissionHeader
      />

      <AdrGraphContextProvider year={year}>
        <MultiAdrGraph isInJanuary={isInJanuary} />
      </AdrGraphContextProvider>

      <BillableStaffingWrapper isInJanuary={isInJanuary} year={year} />
    </>
  );
}
