import { FilterList } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  useTheme,
} from '@mui/material';
import Popper from '@mui/material/Popper';
import PongoButton from 'components/MUIOverload/PongoButton';
import { EmployeesEmployeeOccupationChoices } from 'components/Phonebook/utils';
import RoleChip from 'components/Reporting/Indicators/Filter/RoleChip';
import _ from 'lodash';
import React, { ReactElement } from 'react';

import FilterChip from './GraphFilterChip';
import {
  contractTypeList,
  FilterData,
  FilterOptionTypes,
  gradeList,
} from './utils';

interface GraphFilterProps {
  optionsSelected: FilterData[];
  setOptionsSelected: (param: FilterData[]) => void;
  isContractTypeFilterOn?: boolean;
  isGradeFilterOn?: boolean;
  isOccupationFilterOn?: boolean;
}

export default function GraphFilter({
  optionsSelected,
  setOptionsSelected,
  isContractTypeFilterOn,
  isGradeFilterOn,
  isOccupationFilterOn,
}: GraphFilterProps): ReactElement {
  const theme = useTheme();

  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(
    null
  );
  const open = Boolean(anchorElement);
  const id = open ? 'simple-popper' : undefined;

  const handlePopper = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(anchorElement ? null : event.currentTarget);
  };

  const resetSearchOptions = () => {
    setOptionsSelected([]);
  };

  function deleteFilter(id: string) {
    const remainingOptions = _.filter(
      optionsSelected,
      (option) => option.id !== id
    );
    setOptionsSelected(remainingOptions);
  }

  function addFilter(id: string, type: FilterOptionTypes) {
    const temporaryRole: FilterData = {
      id: id,
      __typename: type,
    };
    optionsSelected.push(temporaryRole);
    const newOptions = _.uniqBy(optionsSelected, 'id');
    setOptionsSelected(newOptions);
  }

  const isSelected = (filter: string, optionsSelected: FilterData[]) => {
    return _.some(
      optionsSelected,
      (option) => (option as FilterData).id === filter
    );
  };

  function onFilter(filter: string, type: FilterOptionTypes) {
    if (isSelected(filter, optionsSelected)) {
      deleteFilter(filter);
      return;
    }
    addFilter(filter, type);
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ mr: 4 }}>
        <ButtonGroup>
          <PongoButton
            variant="contained"
            buttonStyle="secondary"
            startIcon={<FilterList />}
            onClick={handlePopper}
          >
            Filtres
          </PongoButton>
          {!!optionsSelected.length && (
            <PongoButton
              variant="contained"
              buttonStyle="secondary"
              onClick={resetSearchOptions}
            >
              <DeleteIcon />
            </PongoButton>
          )}
        </ButtonGroup>
      </Box>
      {anchorElement && (
        <ClickAwayListener onClickAway={() => setAnchorElement(null)}>
          <Popper
            id={id}
            open={open}
            anchorEl={anchorElement}
            disablePortal={true}
            placement={'bottom'}
            style={{ zIndex: '1' }}
          >
            <div>
              <Box
                sx={{
                  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
                  borderRadius: '2px',
                  padding: '16px 32px',
                  mt: '5px',
                  position: 'relative',
                  left: '10px',
                  bgcolor: theme.palette.background.paper,
                }}
              >
                <Grid container columnSpacing={2}>
                  {isContractTypeFilterOn && (
                    <Grid item>
                      <span>Grades</span>
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          flexDirection: 'column',
                          width: '150px',
                        }}
                      >
                        {_(gradeList)
                          .map((grade) => (
                            <FilterChip
                              key={grade}
                              status={grade}
                              onClick={() =>
                                onFilter(grade, FilterOptionTypes.Grade)
                              }
                              isSelected={isSelected(grade, optionsSelected)}
                            />
                          ))
                          .value()}
                      </Box>
                    </Grid>
                  )}
                  {isGradeFilterOn && (
                    <Grid item>
                      <span>Contrats</span>
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          flexDirection: 'column',
                          width: '150px',
                        }}
                      >
                        {_(contractTypeList)
                          .map((contractType) => (
                            <FilterChip
                              key={contractType}
                              status={contractType}
                              onClick={() =>
                                onFilter(
                                  contractType,
                                  FilterOptionTypes.Contract
                                )
                              }
                              isSelected={isSelected(
                                contractType,
                                optionsSelected
                              )}
                            />
                          ))
                          .value()}
                      </Box>
                    </Grid>
                  )}
                  {isOccupationFilterOn && (
                    <Grid item>
                      <span>Rôles</span>
                      <Grid
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          flexDirection: 'column',
                          width: '350px',
                          maxHeight: '350px',
                        }}
                        container
                        columnSpacing={2}
                      >
                        {_(EmployeesEmployeeOccupationChoices)
                          .map((occupation) => (
                            <Grid item key={occupation}>
                              <RoleChip
                                role={occupation}
                                onClick={() =>
                                  onFilter(
                                    occupation,
                                    FilterOptionTypes.Occupation
                                  )
                                }
                                isSelected={isSelected(
                                  occupation,
                                  optionsSelected
                                )}
                              />
                            </Grid>
                          ))
                          .value()}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </div>
          </Popper>
        </ClickAwayListener>
      )}
    </Box>
  );
}
