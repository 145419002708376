import { TableCell, TableRow } from '@mui/material';
import clsx from 'clsx';
import commonStyles from 'components/commons/css/commons.module.scss';
import { useTableContext } from 'components/Revenue/BilledRevenueTables/BilledRevenueMissionTable/context/TableContextProvider';
import TotalTableCell from 'components/Revenue/Tables/RevenueTableCells/TotalTableCell';
import { BillingPurchaseOrderCurrencyChoices } from 'generated/graphql';
import _ from 'lodash';
import { totalFormat } from 'pages/ActivityPage/utils';
import React from 'react';

import styles from '../styles/TableCell.module.scss';

interface TotalRowProps {
  totalPerDisplayedMonths?: number[];
}

const TotalRow = ({ totalPerDisplayedMonths }: TotalRowProps) => {
  const { displayedMonths } = useTableContext();

  return (
    <TableRow
      className={clsx(
        commonStyles.stickyFooter,
        styles.revenueRowIn,
        styles.revenueRowHeight
      )}
    >
      <TableCell
        className={clsx(styles.totalColumn, styles.boldTotal)}
        colSpan={2}
      >
        CA Facturé
      </TableCell>
      {_.map(displayedMonths, (month, index) => {
        return (
          <TotalTableCell key={`total-${month}`}>
            {totalFormat(
              totalPerDisplayedMonths?.[index],
              BillingPurchaseOrderCurrencyChoices.Eur
            )}
          </TotalTableCell>
        );
      })}
    </TableRow>
  );
};

export default TotalRow;
