import { TableCell, TableCellProps, Tooltip } from '@mui/material';
import clsx from 'clsx';
import styles from 'components/Revenue/RevenueGlobalTable/styles/TableCell.module.scss';
import React from 'react';

export default function DisplayValueTableCell(properties: TableCellProps) {
  const { title, ...otherProps } = properties;
  if (title) {
    return (
      <Tooltip title={title} arrow>
        <TableCell
          {...otherProps}
          className={clsx(properties.className, styles.regularTableCell)}
        />
      </Tooltip>
    );
  }
  return (
    <TableCell
      {...properties}
      className={clsx(properties.className, styles.regularTableCell)}
    />
  );
}
