import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card, { CardProps } from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import clsx from 'clsx';
import React from 'react';

import styles from './styles/ExpandableCard.module.scss';

interface ExpendableCardProps {
  collapsedContentHeight: number;
  isExpanded: boolean;
  collapseEnabled: boolean;
}

const ExpandableCard = (properties: ExpendableCardProps & CardProps) => {
  const {
    collapsedContentHeight,
    isExpanded,
    collapseEnabled,
    ...cardProperties
  } = properties;
  const [expanded, setExpanded] = React.useState(isExpanded);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card {...cardProperties}>
      <Collapse
        in={!collapseEnabled || expanded}
        timeout={300}
        collapsedSize={collapseEnabled ? collapsedContentHeight : undefined}
      >
        {properties.children}
      </Collapse>
      {collapseEnabled && (
        <CardActions
          disableSpacing
          className={clsx(styles.actionContainer, {
            [styles.actionContainerOpen]: expanded,
          })}
        >
          <IconButton
            size={'small'}
            className={clsx(styles.expand, {
              [styles.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
      )}
    </Card>
  );
};

ExpandableCard.defaultProps = {
  isExpanded: false,
  collapseEnabled: true,
};

export default ExpandableCard;
