import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useMonthWorkDaysQuery } from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React from 'react';
import headStyle from 'styles/ActivityPage/HeaderTable.module.scss';

interface MonthWithNumberOfBusinessDaysDisplayerProps {
  viewMonth: string;
  month: moment.Moment;
}

export default function MonthWithNumberOfBusinessDaysDisplayer({
  viewMonth,
  month,
}: MonthWithNumberOfBusinessDaysDisplayerProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { data } = useMonthWorkDaysQuery({
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const numberOfBusinessDaysInThisMonth = _.find(data?.monthWorkDays, (value) =>
    moment(value?.month).isSame(month, 'month')
  )?.workDays;

  return (
    <Stack direction={'row'} justifyContent={'center'}>
      <p className={headStyle.monthSpan}>{viewMonth}</p>
      <Typography color={'textSecondary'} ml={1}>
        {numberOfBusinessDaysInThisMonth}j
      </Typography>
    </Stack>
  );
}
