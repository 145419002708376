import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import PolyModal from 'components/MUIOverload/PolyDialog/PolyModal';
import {
  AllEmployeesMinimalQuery,
  UpdateAmAuthorizationUpdateInput,
  useAllEmployeesMinimalQuery,
  useUpdateUpdateAuthorizationMutation,
} from 'generated/graphql';
import { Moment } from 'moment';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { displayEmployee } from 'utils';

import CollaboratorsSelector from './CollaboratorsSelector';
import MonthsSelector from './MonthsSelector';

export type Employees = DeepExtractTypeSkipArrays<
  AllEmployeesMinimalQuery,
  ['allEmployees']
>;

export type Options = {
  label: string;
  value: string;
  color: string;
};

/*
  If in December, it's possible to give hand back for December so display the last 12 months + December
  else display the last 12 months
*/
function getLast12MonthsAndDecemberIfInDecember() {
  const newMonths = [];
  const isInDecember = moment().get('month') === 11;
  const start = isInDecember ? 0 : 1;
  const end = 13;
  for (let index = start; index < end; index++) {
    const newMonth = moment().clone().add(-index, 'month');
    newMonths.push(newMonth);
  }
  return newMonths;
}

interface AuthorizationModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export default function AuthorizationModal({
  open,
  setOpen,
}: AuthorizationModalProps) {
  const [employeesIds, setEmployeesIds] = useState<string[]>([]);
  const [allEmployees, setAllEmployees] = useState<Options[]>([]);
  const [monthsSelected, setMonthsSelected] = useState<string[]>([]);
  const [allMonths] = useState<Moment[]>(
    getLast12MonthsAndDecemberIfInDecember()
  );
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [createUpdateAuthorizations] = useUpdateUpdateAuthorizationMutation({
    onError: () => {
      enqueueSnackbar("Problème d'envoi des autorisations", {
        variant: 'error',
      });
    },
  });

  useAllEmployeesMinimalQuery({
    onCompleted: (data) => {
      const employees = data.allEmployeesMinimal?.map((employee) => {
        return {
          label: displayEmployee(employee.firstName, employee.lastName),
          value: employee.id,
          color: `#${String(
            Math.floor(Math.random() * 16777215)
              .toString(16)
              .padStart(6, '0')
          )}`,
        };
      });

      if (employees !== null && employees !== undefined) {
        setAllEmployees(employees);
      }
    },
    onError: () => {
      enqueueSnackbar('Problème de récupération des collaborateurs', {
        variant: 'error',
      });
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const submitAuthorization = async () => {
    setLoading(true);
    const list_ids: UpdateAmAuthorizationUpdateInput = {
      listEmployeesIds: employeesIds,
      listMonths: monthsSelected,
    };
    await createUpdateAuthorizations({
      variables: {
        input: list_ids,
      },
    });
    setLoading(false);
    handleClose();
  };

  const handleChange = (options: Options[]) => {
    const new_list: string[] = Array.isArray(options)
      ? options.map((item: Options) => item.value)
      : [];
    setEmployeesIds(new_list);
    return options;
  };

  const handleChangeMonths = (options: Moment[]) => {
    const sortedOptions = options.sort((prev, next) => {
      if (prev.year() !== next.year()) {
        return next.year() - prev.year();
      }
      return next.month() - prev.month();
    });
    const newList: string[] = Array.isArray(options)
      ? sortedOptions.map((month) => month.format('YYYY-MM-DD'))
      : [];
    setMonthsSelected(newList);
    return sortedOptions;
  };

  return (
    <PolyModal
      open={open}
      onClose={handleClose}
      polyDialogTitle={'Rendre la main aux collaborateurs'}
      handlePrimary={submitAuthorization}
      primaryButtonName={'Valider'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={'md'}
      fullWidth
    >
      <Box sx={{ height: '37vh' }}>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '500px',
            }}
          >
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <Stack>
            <CollaboratorsSelector
              allEmployees={allEmployees}
              handleChange={handleChange}
            />
            <MonthsSelector
              allMonths={allMonths}
              handleChangeMonths={handleChangeMonths}
            />
          </Stack>
        )}
      </Box>
    </PolyModal>
  );
}
