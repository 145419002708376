import { ADRByRole } from 'pages/ReportingPage/OccupationAdr/OccupationAdrPage';
import React from 'react';

import { StyledAdrTable } from '../../ProviderAdrTable';
import OccupationAdrTableBody from './OccupationAdrTableBody';
import OccupationAdrTableHeader from './OccupationAdrTableHeader';

interface OccupationAdrTableProps {
  allEmployeeReportingByRole: ADRByRole[];
  refetch: () => void;
}

export default function OccupationAdrTable({
  allEmployeeReportingByRole,
  refetch,
}: OccupationAdrTableProps) {
  return (
    <StyledAdrTable size={'small'}>
      <OccupationAdrTableHeader />
      <OccupationAdrTableBody
        allEmployeeReportingByRole={allEmployeeReportingByRole}
        refetch={refetch}
      />
    </StyledAdrTable>
  );
}
