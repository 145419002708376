import AttachFileIcon from '@mui/icons-material/AttachFile';
import Create from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import HistoryIcon from '@mui/icons-material/History';
import ActionsMenu from 'components/commons/ActionsMenu/ActionsMenu';
import DownloadPoFilesModal from 'components/MissionFollowUp/DownloadFilesModal/DownloadPoFilesModal';
import DeleteModal from 'components/MissionFollowUp/Modals/DeleteModal';
import { useUserInfo } from 'components/User/UserProvider';
import {
  PurchaseOrderNode,
  useDeletePurchaseOrderMutation,
} from 'generated/graphql';
import { useSnackbar } from 'notistack';
import { default as React, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'store';
import {
  removePurchaseOrder,
  setCurrentPurchaseOrder,
} from 'store/purchaseOrder';
import { isChiefOrDirectorOfActivity } from 'utils';

import PurchaseOrderHistoryModal from '../PurchaseOrderHistoryModal';

interface ListBillsOptionsProps {
  handleClickEdit: () => void;
  handleClickDuplicate: () => void;
}

export default function ListBillsOptions({
  handleClickEdit,
  handleClickDuplicate,
}: ListBillsOptionsProps) {
  const { employee, isControl, isDG, isSuperuser } = useUserInfo();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentPurchaseOrder = useSelector(
    (state) => state.purchaseOrder.currentPurchaseOrder
  );
  const currentMission = useSelector((state) => state.activity.currentMission);
  const isActivityChiefOrDirector = isChiefOrDirectorOfActivity(
    currentMission,
    employee
  );
  const [openDelete, setOpenDelete] = useState(false);
  const [modalDownloadVisibility, setModalDownloadVisibility] = useState(false);
  const [modalHistoryVisibility, setModalHistoryVisibility] = useState(false);

  const handleDelete = async () => {
    await deletePurchaseOrder({
      variables: {
        purchaseOrder_id: currentPurchaseOrder.id,
      },
    });
  };

  const onDelete = () => {
    if (!deleteDisabled) {
      enqueueSnackbar(`Des factures sont associées à ce bon de commande`, {
        variant: 'error',
      });
    } else {
      setOpenDelete(true);
    }
  };

  const [deletePurchaseOrder] = useDeletePurchaseOrderMutation({
    onCompleted: () => {
      enqueueSnackbar(
        `Bon de commande « ${currentPurchaseOrder.name} » supprimé`,
        {
          variant: 'success',
        }
      );
      dispatch(removePurchaseOrder(currentPurchaseOrder.id));
      dispatch(setCurrentPurchaseOrder({} as PurchaseOrderNode));
      setOpenDelete(false);
      history.goBack();
    },
    onError: (error) => {
      if (error.message === 'the purchase order has some bills associated') {
        enqueueSnackbar(
          `Des factures sont associées au bon de commande « ${currentPurchaseOrder.name} »`,
          {
            variant: 'error',
          }
        );
      } else {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      }
      setOpenDelete(false);
    },
  });

  const deleteDisabled =
    !currentPurchaseOrder.bills || currentPurchaseOrder.bills.length === 0;

  const options = [
    {
      icon: <AttachFileIcon sx={{ mr: 1 }} />,
      title: 'Pièces jointes',
      onClick: () => setModalDownloadVisibility(!modalDownloadVisibility),
      display: true,
    },
    {
      icon: <Create sx={{ mr: 1 }} />,
      title: 'Modifier',
      onClick: handleClickEdit,
      display: isActivityChiefOrDirector || isControl || isDG || isSuperuser,
    },
    {
      icon: <FileCopyIcon sx={{ mr: 1 }} />,
      title: 'Dupliquer',
      onClick: handleClickDuplicate,
      display: isActivityChiefOrDirector || isSuperuser,
    },
    {
      icon: <HistoryIcon sx={{ mr: 1 }} />,
      title: 'Historique',
      onClick: () => setModalHistoryVisibility(true),
      display: true,
    },
    {
      icon: <DeleteIcon sx={{ mr: 1, color: 'error.main' }} />,
      title: 'Supprimer',
      onClick: onDelete,
      display: isActivityChiefOrDirector || isSuperuser,
    },
  ];

  return (
    <>
      <ActionsMenu options={options} />
      <DeleteModal
        open={openDelete}
        onDelete={handleDelete}
        closeModal={() => {
          setOpenDelete(false);
        }}
        title={'Supprimer le bon de commande'}
        content={
          'Êtes-vous sûr de vouloir supprimer définitivement ce bon de commande?'
        }
      />
      <DownloadPoFilesModal
        open={modalDownloadVisibility}
        onClose={() => setModalDownloadVisibility(!modalDownloadVisibility)}
      />
      <PurchaseOrderHistoryModal
        open={modalHistoryVisibility}
        onClose={() => setModalHistoryVisibility(false)}
      />
    </>
  );
}
