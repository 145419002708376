import {
  BillingPurchaseOrderCurrencyChoices,
  PurchaseOrderNode,
  useUpdatePurchaseOrderMutation,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { totalFormat } from 'pages/ActivityPage/utils';
import React, { ReactElement } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { setCurrentPurchaseOrder } from 'store/purchaseOrder';

import { CreatePurchaseOrderFormProps } from './CreatePurchaseOrderForm';
import PurchaseOrderForm, { PoFormMode } from './PurchaseOrderForm';
import {
  buildUpdatePurchaseOrderMutationVariables,
  translatePurchaseOrderErrorMessage,
} from './utils';

interface EditPurchaseOrderFormProps
  extends Omit<CreatePurchaseOrderFormProps, 'defaultValues'> {
  purchaseOrderId?: string;
}

export default function EditPurchaseOrderForm({
  purchaseOrderId,
  validCallback,
  cancelCallback,
}: EditPurchaseOrderFormProps): ReactElement {
  const currentMission = useSelector((state) => state.activity.currentMission);
  const currentPurchaseOrder = useSelector(
    (state) => state.purchaseOrder.currentPurchaseOrder
  );
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [updatePurchaseOrder, { loading }] = useUpdatePurchaseOrderMutation({
    onError: (error) => {
      const errorMessage = translatePurchaseOrderErrorMessage(error.message);
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        style: { maxWidth: '600px' },
      });
    },
    onCompleted: ({ updatePurchaseOrder: newPo }) => {
      if (newPo) {
        dispatch(setCurrentPurchaseOrder(newPo as PurchaseOrderNode));
        enqueueSnackbar(
          `Le bon de commande « ${newPo.name} » a été mis à jour`,
          { variant: 'success' }
        );
        if (validCallback) {
          validCallback();
        }
      }
    },
  });

  const formDefaultValues = {
    purchaseOrderName: currentPurchaseOrder.name || '',
    purchaseOrderNum: currentPurchaseOrder.number || '',
    collaborators: currentPurchaseOrder.collaborators || [],
    isSubMission: (currentPurchaseOrder.poSubMissions?.length || 0) > 0,
    total: totalFormat(currentPurchaseOrder.total),
    currency:
      currentPurchaseOrder.currency || BillingPurchaseOrderCurrencyChoices.Eur,
    tasks: currentPurchaseOrder.tasks || [],
    poSubMissions: currentPurchaseOrder.poSubMissions || [],
    smAdrs: true,
    periodStart: currentPurchaseOrder.periodBeginning
      ? new Date(currentPurchaseOrder.periodBeginning)
      : moment().startOf('month').toDate(),
    periodEnd: currentPurchaseOrder.periodEnding
      ? new Date(currentPurchaseOrder.periodEnding)
      : moment().endOf('month').toDate(),
    document: {
      id: currentPurchaseOrder.document?.id || '',
      filename: currentPurchaseOrder.document?.filename || '',
      file: undefined,
    },
    hasTemporaryDocument: currentPurchaseOrder.hasTemporaryDocument,
    attachments: _.map(currentPurchaseOrder.attachments, ({ id, filename }) => {
      return {
        id,
        key: id,
        filename: filename,
        file: undefined,
      };
    }),
    address: currentPurchaseOrder.billingAddress?.id || '',
    billingAddresses:
      currentMission.billingInformation?.billingClient?.addresses || [],
  };

  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: formDefaultValues,
    criteriaMode: 'firstError',
    shouldUnregister: false,
  });

  async function onSubmit(submitValues: typeof formDefaultValues) {
    if (loading || submitValues.collaborators === undefined) {
      return;
    }

    await updatePurchaseOrder({
      variables: buildUpdatePurchaseOrderMutationVariables(
        submitValues,
        currentMission,
        purchaseOrderId || ''
      ),
    });
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <PurchaseOrderForm
          defaultValues={formDefaultValues}
          cancelCallback={cancelCallback}
          loading={loading}
          mode={PoFormMode.EDIT}
        />
      </form>
    </FormProvider>
  );
}
