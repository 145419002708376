import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconButton, Stack, TableRow, Typography } from '@mui/material';
import MultiTableCell from '@mui/material/TableCell';
import get from 'lodash/get';
import { ActivitySectionEnum } from 'poly-constants';
import React, { useState } from 'react';

import { Activity } from '.';
import EmptyCells from './EmptyCells';
import MissionRows from './MissionRows';

interface MissionCategoryProps {
  category: string;
  listActivity: Array<Activity>;
  defaultExpanded?: boolean;
}

const MissionCategory = ({
  category,
  listActivity,
  defaultExpanded = false,
}: React.PropsWithChildren<MissionCategoryProps>) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const accordionToggle = () => {
    setIsExpanded((oldState) => !oldState);
  };
  const name = `${get(ActivitySectionEnum, category, category)[0]}${get(
    ActivitySectionEnum,
    category,
    category
  )
    .toLowerCase()
    .slice(1)}`;

  return (
    <>
      <TableRow>
        <MultiTableCell
          sx={{
            borderBottom: 'none',
            py: 1,
            maxWidth: '25vw',
            minWidth: 350,
            alignItems: 'center',
            display: 'inline-block',
            width: '100%',
          }}
        >
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Typography variant="bodySBold" sx={{ color: 'primary.main' }}>
              {name}
            </Typography>
            <IconButton
              size="small"
              onClick={accordionToggle}
              data-testid={'collapseButton'}
              aria-label={`toggle-category-${category}`}
            >
              {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Stack>
        </MultiTableCell>
        <EmptyCells
          key={`${category}-2`}
          id={category}
          showCategoryTotal={!isExpanded}
          listActivity={listActivity}
        />
      </TableRow>

      <MissionRows
        key={`row-${category}`}
        category={category as keyof typeof ActivitySectionEnum}
        listActivity={listActivity}
        display={isExpanded}
      />
    </>
  );
};
export default MissionCategory;
