import { Credentials } from './utils';

const buildHeader = (
  credential: Credentials,
  option?: RequestInit
): RequestInit => {
  const optionsWithToken = option || {};
  const previousHeader = option?.headers || {};
  const auth = `JWT ${credential.access_token}`;

  const newOptions = {
    ...optionsWithToken,
    headers: {
      ...previousHeader,
      Authorization: auth,
    },
  };
  return newOptions;
};

const isResponseAuh = (response: Response): boolean => {
  return response.status !== 401;
};

export const fetchWithCredentials = (
  credential: Credentials,
  uri: RequestInfo,
  options?: RequestInit
) => {
  const optionToken = buildHeader(credential, options);
  return fetch(uri, optionToken);
};

export const buildFetch = (
  credential: React.MutableRefObject<Credentials | undefined>,
  refreshSession: (credentials: Credentials) => Promise<void>
) => {
  return async (uri: RequestInfo, options?: RequestInit) => {
    if (credential.current) {
      const response = await fetchWithCredentials(
        credential.current,
        uri,
        options
      );
      if (isResponseAuh(response)) {
        return response;
      }

      await refreshSession(credential.current);
      return fetchWithCredentials(credential.current, uri, options);
    }
    return fetch(uri, options);
  };
};
