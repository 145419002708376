import { TableBody, useTheme } from '@mui/material';
import { BorderedTableCell } from 'components/commons/Tables/FlatTable';
import { NO_SECTOR_NAME } from 'components/MissionFollowUp/Sector/utils';
import PolyDataGridEmptyBody from 'components/MUIOverload/PolyDataGrid/PolyDataGridEmptyBody';
import ProfitabilityMissionRow from 'components/Reporting/Profitability/GlobalView/ProfitabilityTableBody/Rows/ProfitabilityMissionRow';
import { useUserInfo } from 'components/User/UserProvider';
import {
  ActivitiesActivityTypeChoices,
  ActivityNode,
  BillingClientNode,
  PublicHoliday,
  SectorNode,
} from 'generated/graphql';
import _ from 'lodash';
import { Moment } from 'moment';
import React from 'react';
import { Transition } from 'react-transition-group';

import ProfitabilitySectorRow from './Rows/ProfitabilitySectorRow';

export interface ClientType extends BillingClientNode {
  activities: ActivityNode[];
}

export function transformProfitsByClient(
  activitiesProfits: ActivityNode[],
  clientsProfits: BillingClientNode[],
  polyconseilClient: BillingClientNode,
  nonInitExternalMissionsClient: BillingClientNode
): ClientType[] {
  const activitiesProfitsByClient = _.groupBy(
    activitiesProfits,
    (activityProfit) => {
      if (
        !activityProfit.billingInformation &&
        activityProfit.type === ActivitiesActivityTypeChoices.Ext
      ) {
        return nonInitExternalMissionsClient.id;
      }
      if (activityProfit.billingInformation) {
        return activityProfit.billingInformation?.billingClient?.id;
      }
      return polyconseilClient.id;
    }
  );

  const profitsByClients = _.map([...clientsProfits], (client) => {
    return {
      ...client,
      activities: activitiesProfitsByClient[client.id],
    };
  });
  return profitsByClients;
}

export interface SectorType extends SectorNode {
  clients: ClientType[];
}

export function transformClientsBySector(
  clients: ClientType[],
  profitsBySector: SectorNode[]
): SectorType[] {
  const clientProfitsBySector = _.groupBy(clients, 'sector.id');
  const formattedSectors = _.map(profitsBySector, (sector) => ({
    ...sector,
    clients: clientProfitsBySector[sector.id],
  }));
  return formattedSectors.sort((a, b) => {
    if (a.name === NO_SECTOR_NAME) {
      return 1;
    }
    if (b.name === NO_SECTOR_NAME) {
      return -1;
    } else {
      return a.name.localeCompare(b.name);
    }
  });
}

export interface ProfitabilityTableInterface {
  profitsBySectors: SectorNode[];
  profitsByClient: ClientType[];
  activities?: ActivityNode[];
  polyconseilClient: BillingClientNode;
  nonInitExternalMissionsClient: BillingClientNode;
  filteredMission?: boolean;
  publicHolidays: PublicHoliday[];
  currentYear: Moment;
  yearToDateMonthWorkDays?: number;
}

export default function ProfitabilityTableBody({
  profitsBySectors,
  profitsByClient,
  activities,
  currentYear,
  polyconseilClient,
  nonInitExternalMissionsClient,
  filteredMission,
  publicHolidays,
  yearToDateMonthWorkDays,
}: ProfitabilityTableInterface) {
  const { palette } = useTheme();
  const { isControl, isComex, isSuperuser } = useUserInfo();
  if (!activities?.length) {
    return <PolyDataGridEmptyBody columnsNumber={11} />;
  }

  return (
    <TableBody>
      {!filteredMission &&
      (isControl || isComex || isSuperuser) &&
      profitsBySectors ? (
        <>
          {_.map(
            transformClientsBySector(profitsByClient, profitsBySectors),
            (sector) => {
              return (
                <Transition
                  mountOnEnter
                  unmountOnExit
                  timeout={250}
                  key={`secteur-${sector.id}`}
                  in={true}
                >
                  {(status) => {
                    return (
                      <>
                        <tr>
                          <BorderedTableCell
                            sx={{ backgroundColor: palette.background.default }}
                            colSpan={11}
                          />
                        </tr>
                        <ProfitabilitySectorRow
                          currentYear={currentYear}
                          status={status}
                          sector={sector}
                          polyconseilClient={polyconseilClient}
                          nonInitExternalMissionsClient={
                            nonInitExternalMissionsClient
                          }
                          publicHolidays={publicHolidays as PublicHoliday[]}
                          yearToDateMonthWorkDays={yearToDateMonthWorkDays}
                        />
                      </>
                    );
                  }}
                </Transition>
              );
            }
          )}
          <tr>
            <BorderedTableCell
              sx={{ backgroundColor: palette.background.default }}
              colSpan={11}
            />
          </tr>
        </>
      ) : (
        _.map(activities, (activity) => {
          return (
            <Transition
              mountOnEnter
              unmountOnExit
              timeout={250}
              key={`Activities-as-chief-for-user`}
              in={true}
            >
              {(status) => {
                return (
                  <ProfitabilityMissionRow
                    currentYear={currentYear}
                    status={status}
                    activity={activity}
                    publicHolidays={publicHolidays as PublicHoliday[]}
                    yearToDateMonthWorkDays={yearToDateMonthWorkDays}
                  />
                );
              }}
            </Transition>
          );
        })
      )}
    </TableBody>
  );
}
