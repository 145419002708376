import BillableStaffingContextProvider from 'contexts/Reporting/BillableStaffingContextProvider';
import React, { ReactElement } from 'react';

import { GraphProps } from '../utils';
import BillableStaffingFilter from './BillableStaffingFilter';
import BillableStaffingGraph from './BillableStaffingGraph';
import BillableStaffingHeader from './BillableStaffingHeader';

export default function BillableStaffingWrapper({
  year,
  isInJanuary,
}: GraphProps): ReactElement {
  return (
    <>
      <BillableStaffingHeader />
      <BillableStaffingContextProvider>
        {!isInJanuary && <BillableStaffingFilter />}
        <BillableStaffingGraph isInJanuary={isInJanuary} year={year} />
      </BillableStaffingContextProvider>
    </>
  );
}
