import { ButtonBase, ButtonProps, Typography, useTheme } from '@mui/material';
import React from 'react';

interface ButtonTabProps {
  buttonTitle: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const ButtonTab = (properties: ButtonProps & ButtonTabProps) => {
  const { buttonTitle, onClick, ...buttonProperties } = properties;
  const { palette } = useTheme();

  return (
    <ButtonBase
      onClick={onClick}
      sx={{
        width: 'auto',
        height: '25px',
        color: palette.primary.main,
        borderBottom: '2px solid',
        borderColor: palette.primary.main,
        alignItems: 'flex-start',
      }}
      {...buttonProperties}
      disabled={properties.disabled}
    >
      <Typography variant="bodyBold" color="inherit">
        {buttonTitle}
      </Typography>
    </ButtonBase>
  );
};

export default ButtonTab;
