import { TableCell } from '@mui/material';
import clsx from 'clsx';
import { useTableContext } from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/context/TableContextProvider';
import {
  getPoMode,
  PoMode,
} from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/RevenueRows/PoRow';
import styles from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/styles/TableCell.module.scss';
import OutOfRangeTableCell from 'components/Revenue/Tables/RevenueTableCells/OutOfRangeTableCell';
import {
  ActivitiesActivityBillingTypeChoices,
  AverageDailyRateCollaboratorNode,
  BillingPurchaseOrderCurrencyChoices,
  PurchaseOrderNode,
  PurchaseOrderTaskNode,
} from 'generated/graphql';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { comaStringToFloat, totalFormat } from 'pages/ActivityPage/utils';
import { RevenuesTypeForm } from 'pages/RevenuePage/ActualRevenuePage/ActualRevenueMissionPage';
import { POLY_DATE_MONTH } from 'poly-constants';
import React from 'react';
import { useFormContext } from 'react-hook-form';

interface TotalTableCellProps {
  month: Moment;
}

export const getTotalPurchaserOrder = (purchaseOrder: PurchaseOrderNode) => {
  // Total is only set when purchase order is not tm else we have to guess it
  return (
    purchaseOrder.total ||
    _.sumBy(purchaseOrder.collaborators, 'dailyRate') * 18
  );
};

const totalRevenuesInputForMonth = (
  input: RevenuesTypeForm,
  month: Moment,
  po: PurchaseOrderNode,
  adr?: AverageDailyRateCollaboratorNode,
  task?: PurchaseOrderTaskNode
) => {
  const stringMonth = month.format(POLY_DATE_MONTH);
  const valueRevenue = _.pick(input, [stringMonth])?.[stringMonth];
  if (valueRevenue !== undefined) {
    return Number(comaStringToFloat(valueRevenue?.amount || 0) || 0);
  }
  // If doesn't exist, get it from the data
  const revenue = _.find(po.revenues, (revenue) => {
    const sameMonth = moment(revenue.month).isSame(month, 'month');
    const sameTask = task ? task.id === revenue.task?.id : true;
    return sameMonth && sameTask;
  });

  return revenue?.amount || 0;
};

export const totalRevenuesForPoMonth = (
  poMode: PoMode,
  po: PurchaseOrderNode,
  month: Moment,
  watch: (name: string) => RevenuesTypeForm
) => {
  // First get input PO for this month
  let revenueForPo = 0;
  if (poMode === PoMode.SIMPLE) {
    const input = watch(`revenuesByPo.${po.id}.revenues`);
    revenueForPo = totalRevenuesInputForMonth(input, month, po);
  } else if (poMode === PoMode.TASK) {
    const revenues = _.flatMap(po.tasks, (task) => {
      const input = watch(`revenuesByPo.${po.id}.revenues.${task.id}`);
      return totalRevenuesInputForMonth(input, month, po, undefined, task);
    });
    revenueForPo = _.sum(revenues);
  }
  return revenueForPo;
};

const TotalTableCell = ({ month }: TotalTableCellProps) => {
  const { purchaseOrders, purchaseOrdersRange, activity } = useTableContext();
  const { watch } = useFormContext();

  const stringMonth = month.format(POLY_DATE_MONTH);
  const key = `total.${stringMonth}`;

  const hasPurchaseOrderLastMonth = (): boolean => {
    return _.some(purchaseOrders, (po) => {
      return moment(po.periodEnding).isSame(activity.expirationDate, 'month');
    });
  };

  const shouldDisplayOneMonthAfterExpiration = (): boolean => {
    return (
      activity.billingType === ActivitiesActivityBillingTypeChoices.Tm &&
      hasPurchaseOrderLastMonth()
    );
  };

  const getLastActiveMonth = (): Moment => {
    return shouldDisplayOneMonthAfterExpiration()
      ? moment(activity.expirationDate).add(1, 'M')
      : moment(activity.expirationDate);
  };

  if (
    !month.isSameOrAfter(
      moment.min([activity.startDate, purchaseOrdersRange.start]),
      'month'
    ) ||
    !month.isSameOrBefore(
      moment.max([getLastActiveMonth(), purchaseOrdersRange.end]),
      'month'
    )
  ) {
    return (
      <OutOfRangeTableCell
        key={key}
        title={"La mission n'est pas active sur ce mois"}
      />
    );
  }

  // Getting all values to sum them up
  const totalByPo = _.map(purchaseOrders, (po) => {
    const poMode = getPoMode(activity, po);
    return totalRevenuesForPoMonth(poMode, po, month, watch);
  });

  const total = _.sum(totalByPo);

  return (
    <TableCell
      className={clsx(
        styles.regularTableCell,
        styles.oldMonthCell,
        styles.bold
      )}
      key={key}
    >
      {totalFormat(total, BillingPurchaseOrderCurrencyChoices.Eur)}
    </TableCell>
  );
};

export default TotalTableCell;
