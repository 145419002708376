import { Grid } from '@mui/material';
import UploadFileForm from 'components/commons/FileTransfer/UploadFile/UploadFileForm';
import GridItem from 'components/MissionFollowUp/GridItem';
import _ from 'lodash';
import React, { ReactElement } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

const defaultFile = {
  id: '',
  filename: '',
  file: undefined,
};

interface BillFilesProps {
  isEditing: boolean;
  isViewing: boolean;
}

export default function BillFiles({
  isEditing,
  isViewing,
}: BillFilesProps): ReactElement {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'attachments',
    keyName: 'key',
  });

  // when there is no document or all document are already set, append upload field
  if (!_.find(fields, { filename: '' })) {
    append(defaultFile);
  }
  // TODO: why do we get a second empty one on form cancel
  if (_.filter(fields, { filename: '' }).length > 1) {
    remove(fields.length - 1);
  }

  return (
    <Grid container>
      <GridItem title={'Fichiers Annexes'} customspacingtop={20} sizegrid={12}>
        {_.map(fields, (field, index) => {
          const name = `attachments[${index}]`;
          if (
            (field.filename === '' && (isEditing || !isViewing)) ||
            field.filename !== ''
          ) {
            return (
              <UploadFileForm
                key={field.key}
                callback={() => append(defaultFile)}
                deleteCallback={() => remove(index)}
                formname={name}
                isEditing={isEditing}
              />
            );
          }
          return <></>;
        })}
      </GridItem>
    </Grid>
  );
}
