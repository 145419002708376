import { Typography } from '@mui/material';
import { BillingPurchaseOrderCurrencyChoices } from 'generated/graphql';
import { totalFormat } from 'pages/ActivityPage/utils';
import { EMPTY_FIELD } from 'poly-constants';
import React from 'react';

interface ProfitProps {
  profit: number;
  highlightProfit?: boolean;
}

const Profit = ({ profit, highlightProfit = false }: ProfitProps) => {
  if (!profit) {
    return <>{EMPTY_FIELD}</>;
  }

  const formattedTotalProfit = totalFormat(
    profit,
    BillingPurchaseOrderCurrencyChoices.Eur,
    true
  );

  if (!highlightProfit) {
    return (
      <Typography variant="bodySemiBold">{formattedTotalProfit}</Typography>
    );
  }

  return (
    <Typography
      variant="bodySemiBold"
      color={profit > 0 ? 'success.dark' : 'error.dark'}
    >
      {profit > 0 && '+'}
      {formattedTotalProfit}
    </Typography>
  );
};

export default Profit;
