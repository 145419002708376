import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import EmployeeAvatarRenderer from 'components/Phonebook/EmployeeCard/EmployeeAvatarRenderer/EmployeeAvatarRenderer';
import EmployeeProfileModal from 'components/Phonebook/EmployeeCard/EmployeeProfileModal/EmployeeProfileModal';
import {
  AvatarDisplayMode,
  AvatarDisplaySize,
} from 'components/Phonebook/utils';
import { EmployeeNode } from 'generated/graphql';
import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import { useHistory, useLocation } from 'react-router-dom';

import { getEmployeeOccupationInfo } from './EmployeeOccupationIndicator/EmployeeOccupationIndicator';
import EmployeeProfileDrawer from './EmployeeProfileMobile/EmployeeProfileDrawer';
import { GradeChip } from './GradeChip';

interface EmployeeCardProps {
  employee: EmployeeNode;
}

export const EMPLOYEE_CARD_STYLE = {
  width: { xs: '100%', sm: '208px' },
  height: { xs: '100%', sm: '289px' },
  borderRadius: 1.5,
  padding: 1.5,
  boxSizing: 'border-box',
};

export default function EmployeeCard({ employee }: EmployeeCardProps) {
  const theme = useTheme();
  const firstNameWidth = isComexOrComan(employee) ? 120 : 180;
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));
  const { search } = useLocation();
  const id = new URLSearchParams(search).get('employee_id');
  const [profileModalVisibility, setProfileModalVisibility] = useState(
    employee.id === id
  );
  const history = useHistory();
  const onCloseEmployeeProfile = () => {
    setProfileModalVisibility(false);
    history.push({ search: '' });
  };

  useEffect(() => {
    setProfileModalVisibility(employee.id === id);
  }, [employee.id, id]);

  return (
    <>
      <Stack
        sx={{
          ...EMPLOYEE_CARD_STYLE,
          backgroundColor: theme.palette.background.default,
          '&:hover': {
            backgroundColor: theme.palette.backgroundHover.main,
            cursor: 'pointer',
          },
        }}
        onClick={() => {
          setProfileModalVisibility(true);
          history.push({ search: '?employee_id=' + employee.id });
        }}
      >
        <Stack direction="row" justifyContent={'space-between'}>
          <Typography
            variant={isPhone ? 'bodySBold' : 'bodyBold'}
            sx={{
              maxWidth: `${firstNameWidth}px`,
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {employee.firstName}
          </Typography>
          <GradeChip employee={employee} />
        </Stack>
        <Typography
          variant={isPhone ? 'bodySBold' : 'bodyBold'}
          sx={{
            maxWidth: '184px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {employee.lastName}
        </Typography>
        <Typography
          variant={isPhone ? 'body2' : 'bodySSemiBold'}
          fontSize={'0.875rem'}
          sx={{
            py: 1,
            maxWidth: '184px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {getEmployeeOccupationInfo(employee.occupation, true).occupationName}
        </Typography>
        <LazyLoad offset={300} once key={`employee-${employee.id}`}>
          <EmployeeAvatarRenderer
            employee={employee}
            parameters={{
              mode: AvatarDisplayMode.NONE,
              backgroundColor: 'white',
              size: isPhone
                ? AvatarDisplaySize.PHONE
                : AvatarDisplaySize.STANDARD,
            }}
          />
        </LazyLoad>
      </Stack>
      {profileModalVisibility &&
        (isPhone ? (
          <EmployeeProfileDrawer
            open={profileModalVisibility}
            onClose={onCloseEmployeeProfile}
            employee={employee}
          />
        ) : (
          <EmployeeProfileModal
            open={profileModalVisibility}
            onClose={onCloseEmployeeProfile}
            employee={employee}
          />
        ))}
    </>
  );
}
function isComexOrComan(employee: EmployeeNode): boolean {
  return ['coman', 'comex'].includes(employee.grade.toLowerCase());
}
