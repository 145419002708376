import { IconButton, IconButtonProps } from '@mui/material';
import React from 'react';

import styles from './styles/PolyIconButton.module.scss';

const PolyIconButton = (properties: IconButtonProps) => {
  return <IconButton {...properties} classes={styles} size="large" />;
};

export default PolyIconButton;
