import { Box, Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';

interface FieldDivProps {
  label: string;
}

const InputContainer = (properties: PropsWithChildren<FieldDivProps>) => {
  return (
    <Box sx={{ display: 'unset', m: '10px 20px' }}>
      <Typography variant="label" sx={{ mb: 1 }}>
        {properties.label}
      </Typography>
      <Box>{properties.children}</Box>
    </Box>
  );
};

export default InputContainer;
