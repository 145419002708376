import { Typography } from '@mui/material';
import React from 'react';

import styles from './styles/InfosDisplay.module.scss';

interface InfosProps {
  label: string | JSX.Element;
  content: string | JSX.Element | undefined;
}

const SummaryInfosDisplay = ({ label, content }: InfosProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.label}>{label}</div>
      {content && content !== ' ' ? (
        <Typography className={styles.content} textAlign={'left'}>
          {content}
        </Typography>
      ) : (
        <Typography className={styles.content} sx={{ fontWeight: 'medium' }}>
          A définir
        </Typography>
      )}
    </div>
  );
};

export default SummaryInfosDisplay;
