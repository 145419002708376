import { Box, DialogContent, Typography } from '@mui/material';
import { billModalColumns } from 'components/MissionFollowUp/BillDetail/Modals/BillActionModal/BillActionModal';
import ControlUpdateBillModal from 'components/MissionFollowUp/BillDetail/Modals/ControlUpdateBillModal/ControlUpdateBillModal';
import PolyDataGrid from 'components/MUIOverload/PolyDataGrid';
import PolyModal from 'components/MUIOverload/PolyDialog/PolyModal';
import {
  BillingBillStatusChoices,
  BillNode,
  useDeleteBillMutation,
} from 'generated/graphql';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'store';
import { removeBill, setCurrentBill } from 'store/purchaseOrder';

interface DeleteOrCancelModalProps {
  open: boolean;
  closeModal: () => void;
}

export default function ControlDeleteModal(
  properties: DeleteOrCancelModalProps
) {
  const [open, setOpen] = useState(false);
  const currentBill = useSelector((state) => state.purchaseOrder.currentBill);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { search } = useLocation();
  const missionId = useSelector((state) => state.activity.currentMission.id);
  const poId = new URLSearchParams(search).get('po_id');
  const history = useHistory();

  const getRows = (_orderBy?: string | string[], _order?: 'asc' | 'desc') => {
    return [currentBill];
  };

  const [deleteBill] = useDeleteBillMutation({
    onCompleted: () => {
      enqueueSnackbar(`Facture « ${currentBill.title} » supprimée`, {
        variant: 'success',
      });
      dispatch(removeBill(currentBill.id));
      dispatch(setCurrentBill({} as BillNode));
      history.push({
        pathname: '/mission-followup/missions/details/purchase_order',
        search: `?mission_id=${missionId}&po_id=${poId}`,
      });
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const onDelete = async () => {
    await deleteBill({
      variables: {
        bill_id: currentBill.id,
      },
    });
  };

  return (
    <>
      <PolyModal
        maxWidth={'lg'}
        open={properties.open}
        onClose={properties.closeModal}
        disableEnforceFocus={true}
        polyDialogTitle={'Supprimer la facture'}
        handlePrimary={onDelete}
        primaryButtonName={'Supprimer définitivement'}
      >
        <DialogContent>
          <Box sx={{ my: 2.5 }}>
            <PolyDataGrid columns={billModalColumns} getRows={getRows} />
          </Box>
          <Typography>
            Souhaitez-vous annuler ou supprimer définitivement cette facture ?
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <ControlUpdateBillModal
              open={open}
              desiredBillStatus={BillingBillStatusChoices.Canceled}
              onSuccess={() => {
                setOpen(false);
                properties.closeModal();
              }}
              onClose={() => {
                setOpen(false);
              }}
            />
          </Box>
        </DialogContent>
      </PolyModal>
    </>
  );
}
