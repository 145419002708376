import { Grid, Stack, SxProps, Typography } from '@mui/material';
import { TrophyNode } from 'generated/graphql';
import _ from 'lodash';
import React from 'react';
import { scrollbarParams } from 'utils';

import NoTrophy from './NoTrophy';
import TrophiesSkeleton from './TrophiesSkeleton';
import TrophyPictureDisplayer from './TrophyPictureDisplayer';
interface TrophiesListProps {
  trophies?: TrophyNode[];
  sx?: SxProps;
  showTitle?: boolean;
}

export default function TrophiesList({
  trophies,
  sx,
  showTitle = true,
}: TrophiesListProps) {
  return (
    <Stack key={'trophies'} sx={sx}>
      {showTitle && <Typography variant={'bodyBold'}>Trophées</Typography>}
      {trophies && trophies.length === 0 ? (
        <NoTrophy />
      ) : (
        <Grid
          container
          sx={{
            width: '100%',
            height: '100%',
            pt: showTitle ? 2 : 0,
            display: 'grid',
            justifyContent: 'space-between',
            gridColumnGap: '15px',
            gridTemplateColumns: 'repeat(auto-fill, minmax(50px, 50px))',
            ...scrollbarParams,
          }}
        >
          {!trophies && <TrophiesSkeleton />}
          {_.map(trophies, (trophy) => {
            return (
              <Grid item key={trophy.id} xs={4}>
                <TrophyPictureDisplayer
                  filename={trophy?.trophyPicture?.filename}
                  name={trophy?.name}
                  size={50}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
    </Stack>
  );
}
