import { Delete } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Chip,
  DialogContent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import PolyDialog from 'components/MUIOverload/PolyDialog';
import PolyModalHeader from 'components/MUIOverload/PolyDialog/DefaultComponents/PolyModalHeader';
import PolyFooter from 'components/MUIOverload/PolyFooter';
import PongoButton from 'components/MUIOverload/PongoButton';
import TrophyResizeModal from 'components/Phonebook/TrophyResizeModal';
import {
  EmployeeNode,
  TrophyNode,
  useCreateOrUpdateTrophyMutation,
} from 'generated/graphql';
import _ from 'lodash';
import { SaveButtonName } from 'poly-constants';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { displayEmployee } from 'utils';

import TrophyPictureDisplayer from '../EmployeeCard/Trophies/TrophyPictureDisplayer';

export interface TrophyModalProps {
  trophy?: TrophyNode;
  allEmployees: EmployeeNode[];
  isOpen: boolean;

  handleClose: () => void;
  handleOpenDelete: () => void;
  refetch: () => void;
}

export default function TrophyModal({
  trophy,
  isOpen,
  handleClose,
  refetch,
  allEmployees,
  handleOpenDelete,
}: TrophyModalProps) {
  const [currentEmployee, setCurrentEmployee] = useState<EmployeeNode>();
  const [selectedEmployees, setSelectedEmployees] = useState<EmployeeNode[]>(
    []
  );
  const [imgSrc, setImgSrc] = useState<string>('');
  const [isResizeOpen, setOpen] = useState<boolean>(false);
  const [trophyImage, setTrophyImage] = useState<File>();
  const [name, setName] = useState<string>('');
  const { handleSubmit, register } = useForm({
    reValidateMode: 'onChange',
  });
  const onTagChange = (
    _event: React.SyntheticEvent,
    employee: EmployeeNode | null
  ) => {
    if (employee && !_.includes(selectedEmployees, employee)) {
      setCurrentEmployee(employee);
      setSelectedEmployees([...selectedEmployees, employee]);
      setCurrentEmployee(undefined);
    }
  };

  const deleteEmployee = (deleteEmployee: EmployeeNode) => {
    setSelectedEmployees(
      _.filter(selectedEmployees, (employee) => employee !== deleteEmployee)
    );
  };

  const [executeTrophyMutation] = useCreateOrUpdateTrophyMutation({
    onCompleted: () => {
      refetch();
      setImgSrc('');
      handleClose();
    },
  });

  const onSubmit = () => {
    const employeeIds = _.map(selectedEmployees, (employee) => employee.id);
    executeTrophyMutation({
      variables: {
        trophyId: trophy?.id || undefined,
        name: name,
        employeeIds: employeeIds,
        picture: trophyImage,
      },
    });
  };

  const onUploadClick = (
    event: React.ChangeEvent<HTMLInputElement> | undefined
  ) => {
    const files = event?.target.files;

    if (files && files.length > 0 && files[0]) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        if (reader.result) {
          setImgSrc(reader.result.toString());
        } else {
          setImgSrc('');
        }
      });
      reader.readAsDataURL(files[0]);
      setTrophyImage(files[0]);
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  useEffect(() => {
    setName(trophy?.name || '');
    setImgSrc(trophy?.trophyPicture ? trophy.trophyPicture?.filename : '');
    setSelectedEmployees(trophy?.employees || []);
    setTrophyImage(undefined);
  }, [isOpen, trophy]);

  return (
    <PolyDialog
      open={isOpen}
      onClose={() => {
        setImgSrc('');
        handleClose();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEnforceFocus={true}
      PaperProps={{ sx: { width: '600px' } }}
    >
      <DialogContent>
        <PolyModalHeader
          handleClose={() => {
            setImgSrc('');
            handleClose();
          }}
          polyDialogTitle={
            !trophy ? "Création d'un trophée" : 'Édition de trophée'
          }
          sx={{ paddingBottom: 2 }}
        />
        <form>
          <Stack>
            <TextField
              sx={{ width: 1, mr: 2, mb: 2 }}
              inputRef={register({ required: true })}
              value={name}
              onChange={handleNameChange}
              id="trophyName"
              name="trophyName"
              label="Nom du trophée"
              variant="outlined"
              required
            />
          </Stack>
          <Typography sx={{ pb: 1 }}>
            Veuillez sélectionner le(s) collaborateur(s) à qui vous souhaitez
            remettre un trophée
          </Typography>
          <Stack display={'flex'} flexDirection={'row'} sx={{ width: 1 }}>
            <Autocomplete
              blurOnSelect
              selectOnFocus
              options={_.filter(
                allEmployees,
                (employee) => !_.includes(selectedEmployees, employee)
              )}
              value={currentEmployee}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={'Ajouter des collaborateurs'}
                  variant="standard"
                />
              )}
              getOptionLabel={(option) =>
                option ? displayEmployee(option.firstName, option.lastName) : ''
              }
              onChange={onTagChange}
              sx={{ width: '100%' }}
            />
          </Stack>
          <Stack
            display={'flex'}
            flexDirection={'row'}
            flexWrap={'wrap'}
            sx={{ py: 1, width: 1 }}
          >
            {_.map(selectedEmployees, (employee) => {
              return (
                <Chip
                  label={displayEmployee(employee.firstName, employee.lastName)}
                  sx={{ mr: 1, mb: 1 }}
                  onDelete={() => deleteEmployee(employee)}
                />
              );
            })}
          </Stack>
          <Typography sx={{ pb: 1 }}>
            Veuillez insérer une image afin de créer votre trophée
          </Typography>
          <Stack flexDirection={'row'} alignItems={'center'}>
            <Box display="flex" flexDirection="column">
              <PongoButton
                component="label"
                buttonStyle="secondary"
                variant="contained"
              >
                Téléverser
                <input
                  style={{ display: 'none' }}
                  id="trophyPhoto"
                  name="trophyPhoto"
                  type="file"
                  accept="image/*"
                  onChange={onUploadClick}
                />
              </PongoButton>
              {imgSrc ? (
                <PongoButton
                  style={{ marginTop: '5px' }}
                  variant={'contained'}
                  color={'primary'}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Redimensionner
                </PongoButton>
              ) : undefined}
            </Box>
            <Stack sx={{ pl: 4 }}>
              <TrophyPictureDisplayer filename={imgSrc} name={name} />
            </Stack>
          </Stack>
          <TrophyResizeModal
            isOpen={isResizeOpen}
            handleClose={() => {
              setOpen(false);
            }}
            setImgSrc={setImgSrc}
            imgSrc={imgSrc}
            setTrophyImage={setTrophyImage}
          />
          <PolyFooter>
            <Stack
              display={'flex'}
              justifyContent={'space-between'}
              flexDirection={'row'}
              sx={{ width: 1 }}
            >
              {trophy ? (
                <PongoButton
                  onClick={() => {
                    handleOpenDelete();
                  }}
                  buttonStyle="secondary"
                  variant={'contained'}
                  startIcon={<Delete />}
                >
                  Supprimer
                </PongoButton>
              ) : (
                <div />
              )}
              <Stack display={'flex'} flexDirection={'row'}>
                <PongoButton
                  onClick={() => {
                    setImgSrc('');
                    handleClose();
                  }}
                >
                  Annuler
                </PongoButton>
                <PongoButton
                  variant={'contained'}
                  color={'primary'}
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                >
                  {SaveButtonName}
                </PongoButton>
              </Stack>
            </Stack>
          </PolyFooter>
        </form>
      </DialogContent>
    </PolyDialog>
  );
}
