import PolyDataGrid from 'components/MUIOverload/PolyDataGrid';
import { PolyDataGridColumn } from 'components/MUIOverload/PolyDataGrid/PolyDataGridHead';
import { AllPurchaseOrderQuery, PurchaseOrderNode } from 'generated/graphql';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'store';
import { setCurrentPurchaseOrder } from 'store/purchaseOrder';

import { getBackFilter } from '../utils';
import { getColumnsForTablePurchaseOrders } from './utils';

interface TablePurchaseOrdersProps {
  setFilter: (p: string) => void;
}

export default function TablePurchaseOrders({
  setFilter,
}: TablePurchaseOrdersProps) {
  const missionId = useSelector((state) => state.activity.currentMission.id);
  const dispatch = useDispatch();
  const history = useHistory();
  const purchaseOrders = useSelector(
    (state) => state.purchaseOrder.purchaseOrders
  );
  const columns: PolyDataGridColumn<
    DeepExtractTypeSkipArrays<
      AllPurchaseOrderQuery,
      ['allPurchaseOrder', 'purchaseOrders']
    >
  >[] = getColumnsForTablePurchaseOrders();

  const rowsActive = (
    _orderBy?: string | string[],
    _order?: 'asc' | 'desc'
  ) => {
    if (_orderBy && !Array.isArray(_orderBy)) {
      setFilter(getBackFilter(_orderBy as string, _order));
    }
    return purchaseOrders;
  };

  function onClickCallback(
    purchaseOrder: DeepExtractTypeSkipArrays<
      AllPurchaseOrderQuery,
      ['allPurchaseOrder', 'purchaseOrders']
    >
  ) {
    dispatch(setCurrentPurchaseOrder(purchaseOrder as PurchaseOrderNode));

    history.push({
      pathname: `/mission-followup/missions/details/purchase_order`,
      search: `?mission_id=${
        missionId || purchaseOrder.billingActivityInformation.activity?.id
      }&po_id=${purchaseOrder.id}`,
    });
  }

  return (
    <>
      <PolyDataGrid
        columns={columns}
        getRows={rowsActive}
        rowsCallback={onClickCallback}
      />
    </>
  );
}
