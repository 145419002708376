import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Button,
  Grow,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material';
import { timeForDrawerUnfilterDebounce } from 'poly-constants';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import DrawerCollapse from './DrawerCollapse';
import { useNavbarContextProvider } from './NavbarContextProvider';
import { DRAWER_TRANSITION_DURATION } from './utils';

interface DrawerListItemProps {
  text: string;
  icon?: JSX.Element;
  path: string;
  isExact?: boolean;
  isMenuExpanded?: boolean;
  isStrict?: boolean;
  showActiveStyle?: boolean;
  handleCollapse?: () => void;
  keepFilterWithSecondClick?: boolean;
  children?: React.ReactNode;
  handleMouseOver?: (
    text: string,
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void;
  handleMouseOut?: () => void;
}

const DrawerListItem = ({
  text,
  icon,
  path,
  isExact = true,
  isMenuExpanded = false,
  isStrict = false,
  showActiveStyle,
  handleCollapse,
  keepFilterWithSecondClick,
  children,
  handleMouseOver,
  handleMouseOut,
}: DrawerListItemProps) => {
  const { palette } = useTheme();
  const { isDrawerOpen } = useNavbarContextProvider();
  const [currentPath, setCurrentPath] = useState<string>(path);
  const [isCollapseButtonVisible, setIsCollapseButtonVisible] =
    useState<boolean>(isDrawerOpen);
  if (!showActiveStyle && currentPath !== path) {
    setCurrentPath(path);
  }
  const getTextColor = () => {
    if (icon && showActiveStyle) {
      return palette.standardGrey.main;
    } else if (showActiveStyle) {
      return palette.info.main;
    }
    return palette.standardGrey.light;
  };

  const getBackgroundColor = () => {
    if (icon && showActiveStyle) {
      return palette.primary.main;
    }
    if (showActiveStyle) {
      return palette.primary.light;
    }
    return null;
  };

  return (
    <ListItem
      sx={{
        px: isDrawerOpen ? 0 : 2.5,
        py: 0,
        display: 'block',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: icon && showActiveStyle ? 'none' : '100%',
          minHeight: 40,
          borderRadius: icon && showActiveStyle ? 2 : 1,
          backgroundColor: getBackgroundColor(),
        }}
      >
        <ListItemButton
          component={NavLink}
          onClick={() => {
            if (!keepFilterWithSecondClick) {
              setTimeout(() => {
                setCurrentPath(path.split('?')[0]);
              }, timeForDrawerUnfilterDebounce);
            }
          }}
          to={showActiveStyle ? currentPath : path}
          exact={isExact}
          strict={isStrict}
          sx={{
            py: icon ? 1 : 0.5,
            px: 1,
            justifyContent: 'center',
            borderRadius: 1,
            ':hover': {
              backgroundColor:
                getBackgroundColor() === 'info.main' ? 'info.main' : '',
            },
          }}
          onMouseOver={(event: React.MouseEvent<HTMLElement, MouseEvent>) =>
            handleMouseOver?.(text, event)
          }
          onMouseOut={handleMouseOut}
        >
          {icon && (
            <ListItemIcon
              sx={{
                minWidth: 7,
                color: getTextColor(),
                px: 1,
              }}
            >
              {icon}
            </ListItemIcon>
          )}
          <Grow
            in={isDrawerOpen}
            timeout={{
              enter: DRAWER_TRANSITION_DURATION,
              exit: DRAWER_TRANSITION_DURATION,
            }}
            onExit={() => {
              setIsCollapseButtonVisible(false);
            }}
            onEnter={() => {
              const timeout = setTimeout(() => {
                setIsCollapseButtonVisible(true);
              }, DRAWER_TRANSITION_DURATION / 2);
              return () => clearTimeout(timeout);
            }}
            unmountOnExit
          >
            <ListItemText
              primary={text}
              sx={{
                margin: 0,
                pl: isDrawerOpen ? 1 : 0,
                fontWeight: 500,
                color: getTextColor(),
              }}
              primaryTypographyProps={{
                variant: icon ? 'bodySemiBold' : 'bodySSemiBold',
                sx: { color: getTextColor() },
              }}
            />
          </Grow>
        </ListItemButton>
        {handleCollapse && (
          <Grow
            in={isCollapseButtonVisible}
            timeout={{
              enter: DRAWER_TRANSITION_DURATION / 2,
              exit: 0,
            }}
            unmountOnExit
          >
            <Button
              sx={{
                minWidth: 0.15,
                padding: 1,
                px: icon && showActiveStyle ? 0 : 2,
                borderRadius: 1,
                color: getTextColor(),
              }}
              onClick={() => handleCollapse()}
            >
              {isMenuExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Button>
          </Grow>
        )}
      </Box>
      {handleCollapse && isDrawerOpen && (
        <DrawerCollapse in={isMenuExpanded}>{children}</DrawerCollapse>
      )}
    </ListItem>
  );
};

export default DrawerListItem;
