import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import {
  Box,
  Card,
  CardContent,
  Fade,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import { BillSectionTitle } from 'components/MissionFollowUp/BillDetail/BillSectionTitle';
import LabelTextFieldGrid from 'components/MissionFollowUp/LabelTextFieldGrid';
import PongoButton from 'components/MUIOverload/PongoButton';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import { BillingProvisionBillTypeChoices } from 'generated/graphql';
import _ from 'lodash';
import { comaStringToFloat, totalFormat } from 'pages/ActivityPage/utils';
import { requiredForm } from 'pages/MissionFollowUp/formValidators';
import React, { ReactElement, useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'store';

interface ProvisionBillFormProps {
  disabled: boolean;
}

const provisionBillTypeTableConversion = {
  [BillingProvisionBillTypeChoices.Standard]: 'Prestation normale',
  [BillingProvisionBillTypeChoices.ExpenseReport]:
    'Refacturation de notes de frais',
};

const provisionBillTypeOptionList = [
  BillingProvisionBillTypeChoices.Standard,
  BillingProvisionBillTypeChoices.ExpenseReport,
];

export default function ProvisionBillForm({
  disabled,
}: ProvisionBillFormProps): ReactElement {
  const BILL_PDF_FEATURE_FLAG = useEnvironmentVariable('bill_pdf');
  const { currency, tasks } = useSelector(
    (state) => state.purchaseOrder.currentPurchaseOrder
  );

  const { control, getValues, register, setValue, errors } = useFormContext();
  const {
    fields: provisionBills,
    append: appendProvisionBill,
    remove: removeProvisionBill,
  } = useFieldArray({
    control,
    name: 'provisionBills',
  });

  const [splitByTaskTriggered, triggerSplitByTask] = useState(false);
  useEffect(() => {
    if (
      splitByTaskTriggered &&
      tasks !== undefined &&
      !_.isEmpty(tasks) &&
      _.isEmpty(provisionBills)
    ) {
      appendProvisionBill(tasks.map(({ name }) => ({ name })));
    }
    triggerSplitByTask(false);
  }, [appendProvisionBill, provisionBills, splitByTaskTriggered, tasks]);

  return (
    <Grid container>
      <BillSectionTitle
        Title={
          <>
            Prestations à facturer
            {!_.isEmpty(tasks) && (
              <PongoButton
                variant={'contained'}
                sx={{ ml: 2 }}
                buttonStyle="secondary"
                size={'small'}
                startIcon={<ContentCutIcon />}
                onClick={() => {
                  removeProvisionBill();
                  triggerSplitByTask(true);
                }}
              >
                Découper par chantier
              </PongoButton>
            )}
          </>
        }
      />
      {_.map(provisionBills, (field, index) => {
        const provisionBillType = getValues(`provisionBills.${index}.type`);
        const provisionBillTypeDefaultChoice = provisionBillType
          ? provisionBillType
          : (BillingProvisionBillTypeChoices.Standard as string);
        return (
          <Fade key={`provision-bill-${field.id}`} in>
            <Card
              variant="outlined"
              sx={{
                width: '100%',
                mx: 0,
                my: 1,
                display: 'flex',
                alignItems: 'center',
                gap: 0,
                justifyContent: 'space-between',
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  gap: '16px',
                }}
              >
                {BILL_PDF_FEATURE_FLAG && (
                  <Select
                    required
                    defaultValue={provisionBillTypeDefaultChoice}
                    onChange={(e) =>
                      setValue(`provisionBills.${index}.type`, e.target.value)
                    }
                    sx={{ width: '33%', height: '40px' }}
                  >
                    {_.map(
                      provisionBillTypeOptionList,
                      (fieldName: BillingProvisionBillTypeChoices) => {
                        return (
                          <MenuItem key={fieldName} value={fieldName}>
                            <ListItemText
                              primary={
                                provisionBillTypeTableConversion[fieldName]
                              }
                            />
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                )}
                <LabelTextFieldGrid
                  title={'Libellé de la prestation / Désignation'}
                  defaultValue={getValues(`provisionBills.${index}.name`)}
                  onChange={(e) =>
                    setValue(`provisionBills.${index}.name`, e.target.value)
                  }
                  name={`provisionBills.${index}.name`}
                  inputRef={register({ required: requiredForm })}
                  errorform={errors.provisionBills?.[index]?.name}
                  disabled={disabled}
                  required
                  sizegrid={BILL_PDF_FEATURE_FLAG ? 4 : 6}
                  customspacingtop={0}
                  customspacingside={0}
                />
                <LabelTextFieldGrid
                  title={'Prix unitaire (HT)'}
                  defaultValue={getValues(`provisionBills.${index}.amount`)}
                  onBlur={(e) =>
                    e.target.value &&
                    setValue(
                      `provisionBills.${index}.amount`,
                      totalFormat(comaStringToFloat(e.target.value), currency)
                    )
                  }
                  disabled={disabled}
                  name={`provisionBills.${index}.amount`}
                  inputRef={register({ required: requiredForm })}
                  errorform={errors.provisionBills?.[index]?.amount}
                  required
                  sizegrid={BILL_PDF_FEATURE_FLAG ? 4 : 6}
                  customspacingtop={0}
                  customspacingside={0}
                />
              </CardContent>
              <Box sx={{ height: '100%', padding: '0' }}>
                <IconButton
                  onClick={() => removeProvisionBill(index)}
                  sx={{
                    width: '30px',
                    height: '100%',
                    borderRadius: '0px',
                  }}
                  color="error"
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Card>
          </Fade>
        );
      })}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          margin: '5px',
        }}
      >
        <PongoButton
          variant="contained"
          size={'small'}
          startIcon={<AddCircleOutlineOutlinedIcon />}
          onClick={() => appendProvisionBill({})}
        >
          Nouvelle prestation
        </PongoButton>
      </Box>
    </Grid>
  );
}
