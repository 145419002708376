import { createCsvFile } from 'components/commons/PolyExportButton';
import {
  DownloadNode,
  useDeleteExportInErrorMutation,
  useDownloadExportLazyQuery,
  useFetchAllDownloadsLazyQuery,
} from 'generated/graphql';
import _ from 'lodash';
import { closeSnackbar, useSnackbar } from 'notistack';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import {
  setNumberOfPollsSinceFirstEmpty,
  setWaitingDownload,
} from 'store/download';
import { useInterval } from 'usehooks-ts';

export default function NavbarDownload() {
  const { enqueueSnackbar } = useSnackbar();
  const hasDownloads = useSelector((state) => state.download.waitingDownload);
  const numberOfPollsSinceFirstEmpty = useSelector(
    (state) => state.download.numberOfPollsSinceFirstEmpty
  );
  const dispatch = useDispatch();
  const [downloadFile] = useDownloadExportLazyQuery();
  const handleDownloadFile = async (download: DownloadNode) => {
    const { data } = await downloadFile({
      variables: { fileId: download.id, deleteFile: true },
      onError: (error) => {
        closeSnackbar();
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
    });
    if (data?.downloadExport) {
      createCsvFile(
        data.downloadExport,
        download.filename.split('/').reverse()[0]
      );
    }
  };

  const [fetchAllDownloads] = useFetchAllDownloadsLazyQuery({
    onCompleted: (data) => {
      if (data.allDownloads !== undefined) {
        _.map(data.allDownloads as DownloadNode[], (downloadLocal) => {
          if (downloadLocal.ready) {
            handleDownloadFile(downloadLocal);
          }
        });
        if (data.allDownloads.length === 0 && hasDownloads) {
          if (numberOfPollsSinceFirstEmpty >= 2) {
            dispatch(setWaitingDownload(false));
          } else if (data.allDownloads.length === 0) {
            dispatch(
              setNumberOfPollsSinceFirstEmpty(numberOfPollsSinceFirstEmpty + 1)
            );
          }
        } else if (numberOfPollsSinceFirstEmpty !== 0) {
          dispatch(setNumberOfPollsSinceFirstEmpty(0));
        }
      }
    },
    onError: (e) => {
      closeSnackbar();
      enqueueSnackbar(e.message, {
        variant: 'error',
      });
      dispatch(setWaitingDownload(false));
      deleteExportInError();
    },
  });

  const [deleteExportInError] = useDeleteExportInErrorMutation({
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  useInterval(() => {
    if (hasDownloads) {
      fetchAllDownloads();
    }
  }, 1000);

  return <></>;
}
