import FormSystem from 'components/commons/FormSystem';
import { DateRange } from 'components/commons/Tables/utils';
import {
  ActivityNode,
  BillingClientNode,
  BillingNode,
  EmployeeNode,
} from 'generated/graphql';
import _ from 'lodash';
import { Moment } from 'moment';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export interface BillingCellType {
  billing?: BillingNode;
  month: Moment;
  mission: ActivityNode;
  employee: EmployeeNode;
}

export interface ITimeSpent {
  yearlySum: number;
  totalSum: number;
}

export interface TableContextProviderProps {
  baseMonth: Moment;
  setBaseMonth: (p: Moment) => void;
  dateRange: DateRange;
  currentMonth: Moment;
  billings: _.Dictionary<BillingNode>;
  missions: ActivityNode[];
  sumOfActivities: _.Dictionary<ITimeSpent>;
  refetchTimeSpent: () => void;
  isComputing: {
    billing: boolean;
    timeSpentYear: boolean;
    timeSpentTotal: boolean;
  };
}

type ValidationTableMode = 'validation' | 'edit' | undefined;
export interface TableContextProperties extends TableContextProviderProps {
  mode: ValidationTableMode;
  setMode: (p: ValidationTableMode) => void;
  billingCells: _.Dictionary<BillingCellType>;
  setBillingCells: (p: _.Dictionary<BillingCellType>) => void;
  selectedBillingValidations: Set<BillingCellType>;
  setSelectedBillingValidations: (p: Set<BillingCellType>) => void;
  incrementBaseMonth: () => void;
  decrementBaseMonth: () => void;
  displayedMonths: Moment[];
  setFilterMissionsIds: (ids: string[]) => void;
  filterMissionsIds: string[];
  setFilterCollabIds: (ids: string[]) => void;
  filterCollabIds: string[];
  setFilterClientIds: (ids: string[]) => void;
  filterClientIds: string[];
  allCollaborators: EmployeeNode[];
  allClients: BillingClientNode[];
  isContextCreated: boolean;
}

const TableContext = createContext<TableContextProperties | null>(null);
export const useTableContext = () => {
  const tableContext = useContext(TableContext);
  if (!tableContext) {
    return {} as TableContextProperties;
  }
  return tableContext;
};

const getDisplayedMonths = (month: Moment) => {
  const start = month.clone().subtract(4, 'month');
  const result: Moment[] = [];
  while (start.isSameOrBefore(month, 'month')) {
    result.push(start.clone());
    start.add(1, 'month');
  }
  return result;
};
const TableContextProvider = (
  properties: PropsWithChildren<TableContextProviderProps>
) => {
  const [filterMissionsIds, setFilterMissionsIds] = useState<string[]>([]);
  const [filterCollabIds, setFilterCollabIds] = useState<string[]>([]);
  const [filterClientIds, setFilterClientIds] = useState<string[]>([]);
  const [displayedMonths, setDisplayedMonths] = useState<Moment[]>([]);
  const [mode, setMode] = useState<ValidationTableMode>();
  const [billingCells, setBillingCells] = useState<
    _.Dictionary<BillingCellType>
  >({});
  const [selectedBillingValidations, setSelectedBillingValidations] = useState<
    Set<BillingCellType>
  >(new Set());

  const allCollaborators = useMemo(
    () =>
      _(properties.missions)
        .flatMap((mission) => mission.assignments)
        .flatMap((assignment) => assignment?.employee || [])
        .uniqBy('id')
        .orderBy(
          (employee) =>
            employee.lastName.toLowerCase() + employee.firstName.toLowerCase()
        )
        .value(),
    [properties.missions]
  );

  const allClients = useMemo(
    () =>
      _(properties.missions)
        .flatMap((mission) => mission.billingInformation?.billingClient || [])
        .uniqBy('id')
        .orderBy((client) => client.corporateName?.toLowerCase())
        .value(),
    [properties.missions]
  );

  const incrementBaseMonth = () => {
    properties.setBaseMonth(properties.baseMonth.clone().add(1, 'month'));
  };
  const decrementBaseMonth = () => {
    properties.setBaseMonth(properties.baseMonth.clone().subtract(1, 'month'));
  };

  useEffect(() => {
    setDisplayedMonths(getDisplayedMonths(properties.baseMonth));
  }, [properties.baseMonth]);
  return (
    <>
      <TableContext.Provider
        value={{
          ...properties,
          mode,
          setMode,
          billingCells,
          setBillingCells,
          selectedBillingValidations,
          setSelectedBillingValidations,
          incrementBaseMonth,
          decrementBaseMonth,
          filterMissionsIds,
          setFilterMissionsIds,
          filterCollabIds,
          setFilterCollabIds,
          filterClientIds,
          setFilterClientIds,
          displayedMonths,
          allCollaborators: allCollaborators as EmployeeNode[],
          allClients: allClients,
          isContextCreated: true,
        }}
      >
        <FormSystem
          useFormArgs={{
            mode: 'onChange',
            criteriaMode: 'all',
            reValidateMode: 'onChange',
            shouldUnregister: true,
            shouldFocusError: true,
          }}
        >
          {properties.children}
        </FormSystem>
      </TableContext.Provider>
    </>
  );
};

export default TableContextProvider;
