import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useAuth } from 'components/User/Authenticator';
import {
  getNewCredentials,
  useCredential,
} from 'components/User/Authenticator/hooks/useCredentials';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

import { Credentials } from '../components/User/Authenticator/utils';

export function useRequestLazyQuery<T>(
  config: AxiosRequestConfig,
  onSuccess: (response: AxiosResponse) => T
): (
  url?: string,
  isTimeStamped?: boolean
) => Promise<{
  response: T | undefined;
  error: Error | undefined;
}> {
  const [credentials, handleCredentials] = useCredential();
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();
  const logout = (message = '') => {
    if (message) {
      enqueueSnackbar(message, {
        variant: 'error',
      });
    }
    auth.logout();
  };

  function createRequest(params: {}, credentials: Credentials | undefined) {
    return axios.create({
      params,
      timeout: 20000,
      headers: {
        Authorization: 'JWT ' + credentials?.access_token,
      },
      ...config,
    });
  }

  const fetch = useCallback(
    async (url?: string, isTimeStamped?: boolean) => {
      let response = undefined;
      let error = undefined;
      if (!url) {
        error = new Error('No URL provided');
        return { response, error };
      }

      let params = {};

      if (isTimeStamped) {
        params = {
          t: new Date().getTime(),
        };
      }

      const request = createRequest(params, credentials.current);
      await request
        .get(url)
        .then((axiosResponse) => {
          response = onSuccess(axiosResponse);
        })
        .catch(async (axiosError) => {
          if (axiosError.response?.status === 401) {
            const newCredentials = await getNewCredentials(logout);
            handleCredentials(newCredentials);
            // Retry the request with the new token
            const retryRequest = createRequest(params, newCredentials);
            await retryRequest
              .get(url)
              .then((axiosResponse) => {
                response = onSuccess(axiosResponse);
              })
              .catch((retryError) => {
                error = retryError;
              });
          } else {
            error = axiosError;
          }
        });

      return { response, error };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [config, credentials, handleCredentials, onSuccess]
  );

  return fetch;
}
