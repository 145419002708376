import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

interface PongoTextFieldProps {
  loading?: boolean;
  isFocus?: boolean;
  fieldStyle?: 'primary' | 'secondary';
  startAdornment?: React.ReactFragment;
  endAdornment?: React.ReactFragment;
}

const PongoTextField = (properties: TextFieldProps & PongoTextFieldProps) => {
  const { palette } = useTheme();
  const { ...textFieldProperties } = properties;
  const backgroundColor =
    properties.fieldStyle === 'secondary'
      ? palette.info.light
      : palette.background.default;
  const textColor =
    properties.fieldStyle === 'secondary'
      ? palette.info.main
      : palette.text.primary;

  const inputProps = {
    disableUnderline: true,
    sx: {
      backgroundColor: backgroundColor,
      color: textColor,
      padding: '7px 12px',
      borderRadius: 2,
      fontSize: 14,
      fontWeight: 500,
    },
    startAdornment: properties.startAdornment ? (
      <InputAdornment position="start">
        {properties.startAdornment}
      </InputAdornment>
    ) : null,
    endAdornment: properties.endAdornment ? (
      <InputAdornment position="end">{properties.endAdornment}</InputAdornment>
    ) : null,
  };

  return (
    <TextField
      variant="standard"
      sx={{
        ...properties.sx,
      }}
      InputProps={inputProps}
      {...textFieldProperties}
    />
  );
};

export default PongoTextField;
