import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AttachMoneyIcon from '@mui/icons-material/EuroOutlined';
import WarningIcon from '@mui/icons-material/ReportProblemOutlined';
import TimerIcon from '@mui/icons-material/Timer';
import clsx from 'clsx';
import styles from 'components/MissionFollowUp/OverallListBill/DisplayStatus/styles/Status.module.scss';
import { BillingBillPaymentStatusChoices } from 'generated/graphql';
import React from 'react';

interface DisplayBillPaymentStatusProps {
  status: BillingBillPaymentStatusChoices;
  iconOnly?: boolean;
  checked?: boolean;
  disabled?: boolean;
  iconSize?: string;
}

export default function DisplayBillPaymentStatus(
  properties: DisplayBillPaymentStatusProps
) {
  const billStatusItem = (
    icon: JSX.Element,
    className: string,
    title: string
  ) => {
    return (
      <div className={styles.statusContainer}>
        {icon}
        {!properties.iconOnly && (
          <h1
            className={clsx(
              properties.checked ? styles.checked : className,
              styles.billTitle
            )}
          >
            {title}
          </h1>
        )}
      </div>
    );
  };

  // prettier-ignore
  const getIconStyle = (style: string) =>
    properties.checked
      ? styles.checked
      : (properties.disabled
      ? styles.disabled
      : style);

  switch (properties.status) {
    case BillingBillPaymentStatusChoices.Paid:
      return billStatusItem(
        <AttachMoneyIcon
          className={getIconStyle(styles.billPaymentPaid)}
          sx={{ fontSize: properties.iconSize ? properties.iconSize : '20px' }}
        />,
        styles.billPaymentPaid,
        'Payée'
      );
    case BillingBillPaymentStatusChoices.Litigation:
      return billStatusItem(
        <WarningIcon
          className={getIconStyle(styles.billPaymentLitigation)}
          sx={{ fontSize: properties.iconSize ? properties.iconSize : '20px' }}
        />,
        styles.billPaymentLitigation,
        'En litige'
      );
    case BillingBillPaymentStatusChoices.Pending:
      return billStatusItem(
        <TimerIcon
          className={getIconStyle(styles.billPaymentPending)}
          sx={{ fontSize: properties.iconSize ? properties.iconSize : '20px' }}
        />,
        styles.billPaymentPending,
        'En attente'
      );
    case BillingBillPaymentStatusChoices.Recovering:
      return billStatusItem(
        <ErrorOutlineIcon
          className={getIconStyle(styles.billPaymentRecovering)}
          sx={{ fontSize: properties.iconSize ? properties.iconSize : '20px' }}
        />,
        styles.billPaymentRecovering,
        'En recouvrement'
      );
    default:
      return <></>;
  }
}
