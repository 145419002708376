import { Box } from '@mui/material';
import React from 'react';

interface BillSectionTitleProps {
  refreshFunctionComponent?: JSX.Element;
  addButton?: JSX.Element;
  Title: string | JSX.Element;
}

export const BillSectionTitle = ({
  refreshFunctionComponent,
  addButton,
  Title,
}: BillSectionTitleProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        margin: '20px 0px 5px',
        gridGap: '10px',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {!!Title && Title}
      {!!addButton && addButton}
      {!!refreshFunctionComponent && refreshFunctionComponent}
    </Box>
  );
};
