import { Stack, Typography } from '@mui/material';
import FieldDisplay from 'components/commons/FieldDisplay/FieldDisplay';
import CollaboratorsListView from 'components/MissionFollowUp/BillDetail/BillDetail/BillView/CollaboratorsListView';
import SubMissionsCollaboratorsView from 'components/MissionFollowUp/BillDetail/BillDetail/BillView/SubMissionsCollaboratorsView';
import {
  displayRangeMonthPeriod,
  getSendingMethodWording,
} from 'components/MissionFollowUp/BillDetail/BillDetail/utils';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import {
  BillingBillingAddressCategoryChoices,
  BillNode,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment/moment';
import { totalFormat } from 'pages/ActivityPage/utils';
import { FRENCH_VAT_RATE, POLY_DATE } from 'poly-constants';
import React from 'react';
import { useSelector } from 'store';

function hasSubMissions(bill: BillNode) {
  return _.some(
    bill.collaboratorsDays,
    (collaboratorDays) =>
      !_.isEmpty(collaboratorDays.collaboratorRate.poSubMissions)
  );
}

function isTM(bill: BillNode) {
  return !_.isEmpty(bill.collaboratorsDays);
}

function getTotalBeforeDiscount(bill: BillNode): number {
  const totalBeforeDiscount =
    _.sumBy(
      bill.collaboratorsDays,
      (collaborator) =>
        _.sumBy(collaborator.daysNumbers, 'timeForTmContracts') *
        (collaborator.collaboratorRate.dailyRate || 0)
    ) + _.sumBy(bill.provisionBills, 'amount');

  const isCreditNote = bill.billReference;
  if (isCreditNote) {
    return -1 * totalBeforeDiscount;
  }
  return totalBeforeDiscount;
}

export default function BillDetailView() {
  const currency = useSelector(
    (state) => state.purchaseOrder.currentPurchaseOrder.currency
  );
  const currentBill = useSelector((state) => state.purchaseOrder.currentBill);
  const currentBillingAddressCategory = useSelector(
    (state) =>
      state.activity.currentMission.billingInformation?.billingClient
        ?.mainAddress?.category
  );

  const SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED =
    useEnvironmentVariable('send_bill_email');

  return (
    <Stack direction={'column'} spacing={3} sx={{ px: 2 }}>
      <Typography variant={'h2'} sx={{ mt: 4, mb: 1, mr: 2 }}>
        Détails de la facture
      </Typography>
      <Stack direction={'row'}>
        <FieldDisplay
          title={'Mois facturés'}
          titleVariant={'h3Bold'}
          valueVariant={'h3Bold'}
          value={displayRangeMonthPeriod(
            currentBill.periodBeginning,
            currentBill.periodEnding
          )}
        />
      </Stack>
      <Stack
        direction={'row'}
        gap={0.5}
        flexWrap={'wrap'}
        justifyContent={'flex-start'}
      >
        <FieldDisplay
          sx={{ pr: 6 }}
          title={
            SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED
              ? 'À comptabiliser le'
              : 'À facturer le'
          }
          value={moment(currentBill.date).format(POLY_DATE)}
        />
        {currentBill.billingDate && (
          <FieldDisplay
            sx={{ pr: 6 }}
            title={
              SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED
                ? 'Comptabilisée le'
                : 'Facturée le'
            }
            value={moment(currentBill.billingDate).format(POLY_DATE)}
          />
        )}
        {currentBill.dueDate && (
          <FieldDisplay
            sx={{ pr: 6 }}
            title={
              SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED
                ? 'Echéance'
                : "Date d'échéance"
            }
            value={moment(currentBill.dueDate).format(POLY_DATE)}
          />
        )}
        {SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED && currentBill.sendingMethod && (
          <FieldDisplay
            sx={{ pr: 6 }}
            title={"Mode d'envoi"}
            value={getSendingMethodWording(currentBill.sendingMethod)}
          />
        )}
        {SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED &&
          currentBill.sendingDatetime && (
            <FieldDisplay
              sx={{ pr: 6 }}
              title={'Envoyée le'}
              value={moment(currentBill.sendingDatetime).format(POLY_DATE)}
            />
          )}
        {currentBill.paymentDate && (
          <FieldDisplay
            sx={{ pr: 6 }}
            title={'Payé le'}
            value={moment(currentBill.paymentDate).format(POLY_DATE)}
          />
        )}
      </Stack>
      {isTM(currentBill) && !hasSubMissions(currentBill) && (
        <CollaboratorsListView collaborators={currentBill.collaboratorsDays} />
      )}
      {hasSubMissions(currentBill) && <SubMissionsCollaboratorsView />}
      <Stack direction={'column'} spacing={2} sx={{ pr: 2 }}>
        {currentBill.provisionBills?.map(({ name, amount }, i) => (
          <FieldDisplay
            title={name}
            value={totalFormat(amount, currency)}
            divider
            key={`provision-bill-detail-${i}`}
          />
        ))}
        <FieldDisplay
          title={'Montant à facturer (HT)'}
          value={totalFormat(getTotalBeforeDiscount(currentBill), currency)}
          divider
        />
        <FieldDisplay
          title={'Montant facturé (HT)'}
          value={totalFormat(currentBill.total, currency)}
          valueVariant={'h2Bold'}
          divider
        />
        {currentBillingAddressCategory ===
          BillingBillingAddressCategoryChoices.Fr && (
          <>
            <FieldDisplay
              title={'TVA (20%)'}
              value={totalFormat(currentBill.total * FRENCH_VAT_RATE, currency)}
            />
            <FieldDisplay
              title={'Montant facturé (TTC)'}
              value={totalFormat(
                currentBill.total * (1 + FRENCH_VAT_RATE),
                currency
              )}
              valueVariant={'h2Bold'}
              divider
            />
          </>
        )}
      </Stack>
    </Stack>
  );
}
