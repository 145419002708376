import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import _ from 'lodash';
import React from 'react';

interface FieldSelectionProps {
  listFields: string[];
  setListFields: Function;
  selectableFields: string[];
  fieldsCorrespondance: { [key: string]: string };
}

export default function FieldSelection({
  listFields,
  setListFields,
  selectableFields,
  fieldsCorrespondance,
}: FieldSelectionProps) {
  const handleSelectChange = (event: SelectChangeEvent<typeof listFields>) => {
    const value = event.target.value;
    // We check whether the last field selected is the 'select all' field, and we select/deselect all fields accordingly
    if (value[value.length - 1] === 'all') {
      setListFields(
        listFields.length === selectableFields.length ? [] : selectableFields
      );
    } else {
      setListFields(value as string[]);
    }
  };

  return (
    <Select
      sx={{ width: '100%' }}
      value={listFields}
      renderValue={(selected) => {
        if (selected.length === selectableFields.length) {
          return 'Tous les champs';
        }
        const selectedConverted = _.map(
          selected,
          (selectedElement) => fieldsCorrespondance[selectedElement]
        );
        return selectedConverted.join(', ');
      }}
      onChange={handleSelectChange}
      multiple
      size="small"
    >
      <MenuItem value="all">
        <Checkbox
          checked={listFields.length === selectableFields.length}
          indeterminate={
            listFields.length > 0 && listFields.length < selectableFields.length
          }
        />
        <ListItemText primary={'Sélectionner tous les champs'} />
      </MenuItem>
      {_.map(selectableFields, (fieldName) => {
        return (
          <MenuItem key={fieldName} value={fieldName} sx={{ pl: 5 }}>
            <Checkbox checked={_.some(listFields, (fn) => fn === fieldName)} />
            <ListItemText primary={fieldsCorrespondance[fieldName]} />
          </MenuItem>
        );
      })}
    </Select>
  );
}
