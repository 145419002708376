import FilterContextProvider from 'components/commons/FilterContext/FilterContextProvider';
import TableHeaderContextProvider from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import PolySwitch from 'components/Navigation/PolySwitch';
import AccessWrapper from 'components/User/AccessWrapper';
import { EmployeeOccupations } from 'components/User/UserProvider';
import { EmployeesEmployeeGradeChoices } from 'generated/graphql';
import moment from 'moment';
import InternalMissionPage from 'pages/ErrorPage/InternalMissionPage';
import EstimatedRevenueHomePageWrapper from 'pages/RevenuePage/EstimatedRevenuePage/EstimatedRevenueHomePageWrapper';
import EstimatedRevenueMissionPage from 'pages/RevenuePage/EstimatedRevenuePage/EstimatedRevenueMissionPage';
import { PageTitles } from 'poly-constants';
import React, { useEffect, useState } from 'react';
import {
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { useSelector } from 'store';
import urlJoin from 'url-join';
import { checkIfAllMissionsAreInternal } from 'utils';

export default function EstimatedRevenuePage() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const [selectedYear, setSelectedYear] = useState(
    moment(urlParams.get('year') ?? moment().year().toString())
  );
  const selectedMissions = useSelector(
    (state) => state.activity.selectedMissions
  );
  const currentMissionId = useSelector(
    (state) => state.activity.currentMission?.id
  );

  useEffect(
    () => {
      const urlYear = urlParams.get('year') || '';
      const yearStr = selectedYear.year().toString();

      if (urlYear !== yearStr) {
        urlParams.set('year', yearStr);
        history.push({
          search: urlParams.toString(),
        });
      }
    },
    // eslint-disable-next-line
    [selectedYear, history]
  );

  if (
    selectedMissions.length > 0 &&
    checkIfAllMissionsAreInternal(selectedMissions)
  ) {
    return <InternalMissionPage page={PageTitles.estimatedRevenuePage} />;
  }

  return (
    <PolySwitch>
      <AccessWrapper
        occupations={[EmployeeOccupations.CONTROL, EmployeeOccupations.DG]}
        grades={[EmployeesEmployeeGradeChoices.Comex]}
        restrictMissionChief={true}
      >
        <TableHeaderContextProvider
          currentYear={selectedYear}
          setCurrentYear={setSelectedYear}
        >
          <FilterContextProvider>
            <Route
              exact
              path={urlJoin(path, '')}
              component={
                currentMissionId
                  ? EstimatedRevenueMissionPage
                  : EstimatedRevenueHomePageWrapper
              }
            />
          </FilterContextProvider>
        </TableHeaderContextProvider>
      </AccessWrapper>
    </PolySwitch>
  );
}
