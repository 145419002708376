import { Checkbox, SxProps } from '@mui/material';
import React, { useEffect, useState } from 'react';

import PolyTableCell from '../PolyTableCell';
import PolyTableRow from '../PolyTableRow';
import { usePolyDataTableContext } from './context/PolyDataGridContextProvider';
import { PolyDataGridColumn } from './PolyDataGridHead';
import styles from './styles/PolyDataGrid.module.scss';

interface PolyDataGridRowProps<T> {
  columns: PolyDataGridColumn<T>[];
  row: T;
  rowsCallback?: (element: T) => void;
  selectedRowsCallback?: (element: T, selected: boolean) => void;
  isRowSelectEnabled?: (element: T) => boolean;
  className?: string;
  isSelectableProp?: boolean;
  sx?: SxProps;
}

export default function PolyDataGridRow<T>({
  columns,
  row,
  rowsCallback,
  selectedRowsCallback,
  isRowSelectEnabled,
  className,
  isSelectableProp,
  sx,
}: PolyDataGridRowProps<T>) {
  const {
    hasGlobalChecked,
    checkedRows,
    setCheckedRows,
    checkableRows,
    setCheckableRows,
  } = usePolyDataTableContext();
  const [isChecked, setIsChecked] = useState(false);
  const isSelectable = !!isRowSelectEnabled && isRowSelectEnabled(row);
  const [isRowSelectable, setIsRowSelectable] = useState<boolean>(isSelectable);
  const [isHovering, setIsHovering] = useState<boolean>(false);

  if (
    isRowSelectable &&
    selectedRowsCallback &&
    isSelectableProp &&
    !checkableRows.has(row)
  ) {
    checkableRows.add(row);
    setCheckableRows(checkableRows);
  }

  const handleCheck = (isChecked: boolean) => {
    if (
      isRowSelectable &&
      selectedRowsCallback &&
      isSelectableProp &&
      ((isChecked && !checkedRows.has(row)) ||
        (!isChecked && checkedRows.has(row)))
    ) {
      selectedRowsCallback(row, isChecked);
      isChecked ? checkedRows.add(row) : checkedRows.delete(row);
      setCheckedRows(new Set(checkedRows));
      setIsChecked(isChecked);
    }
  };

  useEffect(() => {
    if (hasGlobalChecked) {
      handleCheck(hasGlobalChecked.checked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasGlobalChecked]);

  useEffect(() => {
    setIsRowSelectable(isSelectable);
  }, [isRowSelectable, isSelectable]);

  return (
    <PolyTableRow
      onClick={() => {
        if (rowsCallback && !isSelectableProp) {
          rowsCallback(row);
        }
        if (isRowSelectable && selectedRowsCallback && isSelectableProp) {
          handleCheck(!isChecked);
        }
      }}
      className={`${className} ${styles.verticalBaselineAlign} ${
        rowsCallback && isHovering ? styles.hovering : ''
      }`}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      sx={sx}
    >
      {isSelectableProp && (
        <PolyTableCell key={'checked'}>
          <Checkbox
            disabled={!isRowSelectable}
            className={styles.checkBox}
            checked={isChecked}
            color={'primary'}
          />
        </PolyTableCell>
      )}
      {columns.map(({ render, width, align }, index) => (
        <PolyTableCell
          key={index}
          style={{ maxWidth: width, verticalAlign: 'middle' }}
          align={align}
          className={styles.alignRight}
        >
          <div className={styles.maxWidth}>{render(row)}</div>
        </PolyTableCell>
      ))}
    </PolyTableRow>
  );
}
