import { Stack, Switch, TableRow, Tooltip, Typography } from '@mui/material';
import {
  BorderedTableCell,
  CenteredTableCell,
} from 'components/commons/Tables/FlatTable';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

interface ProfitabilityMissionViewTableHeaderColumnsNamesProps {
  monthsToDisplay: moment.Moment[];
}

export function ProfitabilityMissionViewTableHeaderColumnsNames({
  monthsToDisplay,
}: ProfitabilityMissionViewTableHeaderColumnsNamesProps) {
  const { isShowingMarginRate, toggleShowMarginRate } = useTableHeaderContext();

  return (
    <TableRow sx={{ tableLayout: 'fixed', width: '100%' }}>
      <BorderedTableCell sx={{ width: '150px' }}>
        Collaborateur
      </BorderedTableCell>
      {_.map(monthsToDisplay, (month) => (
        <React.Fragment key={`headerTitle-${month.format('MMM')}`}>
          <CenteredTableCell sx={{ whiteSpace: 'normal' }}>
            <Tooltip
              title={
                'CA réalisé * déclaration jours / total jours travaillés mission'
              }
              arrow
            >
              <div>CA estimé</div>
            </Tooltip>
          </CenteredTableCell>

          <CenteredTableCell sx={{ whiteSpace: 'normal' }}>
            <Tooltip title={'déclaration jours * TJM'} arrow>
              <div>Coût mensuel</div>
            </Tooltip>
          </CenteredTableCell>

          <CenteredTableCell sx={{ whiteSpace: 'normal' }}>
            <Tooltip
              title={
                isShowingMarginRate
                  ? '(CA estimé - Coût mensuel) / CA estimé * 100'
                  : 'CA estimé - Coût mensuel'
              }
              arrow
            >
              <Stack alignItems="center">
                Rentabilité estimée
                <Stack direction="row">
                  <Typography color={'text.secondary'}>€</Typography>
                  <Switch
                    onClick={toggleShowMarginRate}
                    checked={isShowingMarginRate}
                    size="small"
                  />
                  <Typography color={'text.secondary'}>%</Typography>
                </Stack>
              </Stack>
            </Tooltip>
          </CenteredTableCell>
        </React.Fragment>
      ))}
    </TableRow>
  );
}
