import LoadingPage from 'components/commons/LoadingPage';
import { useAuth } from 'components/User/Authenticator';
import {
  EmployeesEmployeeGradeChoices,
  MeEmployeeFragment,
  useMeQuery,
} from 'generated/graphql';
import React, { createContext, useContext, useEffect } from 'react';
import { isEmployeeSupport } from 'utils';

export type Assignment = DeepExtractTypeSkipArrays<
  MeEmployeeFragment,
  ['assignments']
>;

export enum EmployeeOccupations {
  COMMERCIAL = 'Commercial',
  CONSULTANT = 'Consultant',
  CONTROL = 'Finance',
  DATA = 'Data',
  DESIGNER = 'Designer',
  DEV = 'Développeur',
  DEV_OPS = 'Dev-ops',
  DG = 'Direction Générale',
  HR = 'RH',
  MIXED = 'Mixte',
  OFFICE_MANAGEMENT = 'Office Management',
  RECRUITING = 'Recrutement',
  OTHER = 'Other',
  UI_UX = 'UI UX',
}

export interface UserContextProps {
  employee: MeEmployeeFragment | null | undefined;
  permissions: string[];
  isSuperuser: boolean;
  isComex: boolean;
  isControl: boolean;
  isDG: boolean;
  isExternalMissionChief: boolean;
  isFirstCo: boolean;
  isHR: boolean;
  isMissionChief: boolean;
  isStandardUser: boolean;
  isDirector: boolean;
  isSupport: boolean;
  isTmActivityMissionChief: boolean;
  refetch: () => void;
}

const UserContext = createContext<null | UserContextProps>(null);

export const useUserInfo = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserInfo() can only be used when authenticated');
  }
  return context;
};

export const UserProvider = ({
  children,
}: React.PropsWithChildren<{}>): JSX.Element => {
  const auth = useAuth();
  const { loading, data, error, refetch } = useMeQuery();

  useEffect(() => {
    if (!loading && error) {
      auth.logout(`Erreur lors du chargement de l'utilisateur: ${error}`);
    }
  }, [auth, error, loading]);

  const buildContext = () => {
    const context = {
      employee: undefined,
      permissions: [],
      isSuperuser: false,
      isComex: false,
      isControl: false,
      isDG: false,
      isExternalMissionChief: false,
      isHR: false,
      isMissionChief: false,
      isStandardUser: true,
      isSupport: false,
      isTmActivityMissionChief: false,
      isDirector: false,
      isFirstCo: true,
      refetch: refetch,
    } as UserContextProps;

    const requestIsFirstCo = () => {
      if (process.env.REACT_APP_SKIP_FIRST_CO) {
        return false;
      }
      return !context.employee?.hasValidatedConsent;
    };

    if (data && data.userDetails) {
      context.employee = data.userDetails;
      context.permissions = data.userDetails.user?.permissions || [];
      context.isSuperuser = !!context.employee?.user?.isSuperuser;
      context.isComex =
        context.employee?.grade === EmployeesEmployeeGradeChoices.Comex;
      context.isControl =
        context.employee?.occupation === EmployeeOccupations.CONTROL;
      context.isDG = context.employee?.occupation === EmployeeOccupations.DG;
      context.isExternalMissionChief =
        context.employee?.isExternalMissionChief ?? false;
      context.isHR = context.employee?.occupation === EmployeeOccupations.HR;
      context.isMissionChief = context.employee?.isMissionChief ?? false;
      context.isStandardUser =
        !context.isDG &&
        !context.isControl &&
        !context.isHR &&
        !context.isSupport;
      context.isSupport = isEmployeeSupport(context.employee);
      context.isTmActivityMissionChief =
        context.employee?.isTmActivityMissionChief ?? false;
      context.isFirstCo = requestIsFirstCo();
    }
    return context;
  };

  if (loading && !data) {
    return <LoadingPage loading={loading} />;
  }

  return (
    <UserContext.Provider value={buildContext()}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
