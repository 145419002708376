import { DialogContent } from '@mui/material';
import BillAddAttachments from 'components/MissionFollowUp/DownloadFilesModal/BillAddAttachments';
import DownloadAttachments from 'components/MissionFollowUp/DownloadFilesModal/DownloadAttachments';
import styles from 'components/MissionFollowUp/DownloadFilesModal/styles/DownloadFilesModal.module.scss';
import PolyDialog from 'components/MUIOverload/PolyDialog';
import PolyModalHeader from 'components/MUIOverload/PolyDialog/DefaultComponents/PolyModalHeader';
import { AttachmentInput } from 'generated/graphql';
import _ from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'store';

import { AttachmentType } from '../BillDetail/utils';

interface DownloadFilesModalProps {
  open: boolean;
  onClose: () => void;
}

export interface BillAttachmentFormType {
  billId: string;
  attachments: AttachmentType[];
}

export default function DownloadBillFilesModal(
  properties: DownloadFilesModalProps
) {
  const currentBill = useSelector((state) => state.purchaseOrder.currentBill);
  const [attachmentsToDelete, setAttachmentsToDelete] = useState<
    AttachmentInput[]
  >([]);

  async function deleteFile(idToDelete: string) {
    setAttachmentsToDelete([...attachmentsToDelete, { id: idToDelete }]);
  }

  return (
    <PolyDialog
      maxWidth={'md'}
      open={properties.open}
      onClose={properties.onClose}
      disableEnforceFocus={true}
    >
      <div className={styles.root}>
        <DialogContent>
          <PolyModalHeader
            polyDialogTitle={'Pièces jointes facture'}
            handleClose={properties.onClose}
            sx={{ pb: 2 }}
          />
          <DownloadAttachments
            attachments={_.filter(
              currentBill?.attachments,
              (a) =>
                !_.some(
                  attachmentsToDelete,
                  (attachment) => attachment.id === a.id
                )
            )}
            onDelete={deleteFile}
          />
          <BillAddAttachments
            billId={currentBill.id}
            validCallback={properties.onClose}
            attachmentsToDelete={attachmentsToDelete}
          />
        </DialogContent>
      </div>
    </PolyDialog>
  );
}
