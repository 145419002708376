import { ActivityNode } from 'generated/graphql';
import React, { createContext, PropsWithChildren, useContext } from 'react';

export interface TableContextProps {
  activity: ActivityNode;
  hasRightToEdit: boolean;
}

export interface TableContextProviderProps {
  children?: React.ReactNode;
  activity: ActivityNode;
  hasRightToEdit: boolean;
}

const TableContext = createContext<TableContextProps>({
  activity: {} as ActivityNode,
  hasRightToEdit: false,
});

export const useTableContext = () => {
  const tableContext = useContext(TableContext);
  if (!tableContext) {
    throw new Error('useContext() can only be used  inside a table');
  }
  return tableContext;
};

const TableContextProvider = (
  properties: PropsWithChildren<TableContextProviderProps>
) => {
  return (
    <TableContext.Provider
      value={{
        activity: properties.activity,
        hasRightToEdit: properties.hasRightToEdit,
      }}
    >
      {properties.children}
    </TableContext.Provider>
  );
};

export default TableContextProvider;
