import AddIcon from '@mui/icons-material/Add';
import { ListItem, Typography } from '@mui/material';
import React from 'react';

interface FilterListItemProps {
  children: React.ReactFragment;
  onClick: () => void;
}

export default function FilterListItem({
  children,
  onClick,
}: FilterListItemProps) {
  return (
    <ListItem
      sx={{
        borderRadius: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        px: 0.5,
        my: 0.5,
        py: 0.5,
        ':hover': {
          backgroundColor: 'info.light',
          '& .icon': { display: 'block' },
        },
      }}
      onClick={onClick}
    >
      <Typography variant="bodyBold" color="info.main">
        {children}
      </Typography>
      <AddIcon className="icon" fontSize="small" sx={{ display: 'none' }} />
    </ListItem>
  );
}
