import AutorenewIcon from '@mui/icons-material/Autorenew';
import EditIcon from '@mui/icons-material/Edit';
import { Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {
  EmployeeWeeklyContractNode,
  useGetUserWeeklyContractQuery,
} from 'generated/graphql';
import React, { useState } from 'react';

import ContractualDayChip from './ContractualDayChip';
import UserContractualDaysModal from './UserContractualDaysModal';

function oneDaySummary(workingDay: boolean[], dayShortName: string) {
  return (
    <Grid item xs={4} sm={4} md={2}>
      <ContractualDayChip label={dayShortName} workingDay={workingDay} />
    </Grid>
  );
}

function renderWeeklyContractDaysSummary(
  weeklyContract: EmployeeWeeklyContractNode
) {
  return (
    <>
      {oneDaySummary(weeklyContract.monday, 'Lun')}
      {oneDaySummary(weeklyContract.tuesday, 'Mar')}
      {oneDaySummary(weeklyContract.wednesday, 'Mer')}
      {oneDaySummary(weeklyContract.thursday, 'Jeu')}
      {oneDaySummary(weeklyContract.friday, 'Ven')}
    </>
  );
}

interface ContractualDaysDisplayerProps {
  isEditable?: boolean;
  updateIsValidWorkingDays: (totalWorkingDays: number) => void;
}

export default function ContractualDaysDisplayer({
  isEditable,
  updateIsValidWorkingDays,
}: ContractualDaysDisplayerProps) {
  const [editContractualDaysVisibility, setEditContractualDaysVisibility] =
    useState(false);
  const { data, loading, refetch } = useGetUserWeeklyContractQuery();
  const userWeeklyContract =
    data?.userWeeklyContract as EmployeeWeeklyContractNode;
  return (
    <Grid container alignItems={'center'} spacing={0.5}>
      {!loading && (
        <>
          <UserContractualDaysModal
            open={editContractualDaysVisibility}
            handleClose={() => setEditContractualDaysVisibility(false)}
            refetchWeeklyContract={refetch}
            userWeeklyContract={userWeeklyContract}
            updateIsValidWorkingDays={updateIsValidWorkingDays}
          />
          {renderWeeklyContractDaysSummary(userWeeklyContract)}
        </>
      )}
      {isEditable && (
        <Grid item xs={4} sm={4} md={1.25}>
          <IconButton
            size="small"
            color="primary"
            onClick={() => setEditContractualDaysVisibility(true)}
            disabled={loading}
          >
            {loading ? (
              <AutorenewIcon sx={{ height: '18px' }} />
            ) : (
              <EditIcon sx={{ height: '18px' }} />
            )}
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}
