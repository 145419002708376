import FieldDisplay from 'components/commons/FieldDisplay/FieldDisplay';
import { BillingModalities } from 'generated/graphql';
import React from 'react';

export interface ModalityViewProps {
  modality: BillingModalities;
}

export default function ModalityEmailView(properties: ModalityViewProps) {
  return (
    <>
      <FieldDisplay
        title={'Nom du destinataire'}
        value={properties.modality.billingName}
        flexDirection="column"
        noWrap={false}
        showColon={false}
        titleVariant="bodyBold"
        valueVariant="bodySemiBold"
      />
      <FieldDisplay
        title={'Adresse mail'}
        value={properties.modality.billingEmail}
        flexDirection="column"
        noWrap={false}
        showColon={false}
        titleVariant="bodyBold"
        valueVariant="bodySemiBold"
      />
    </>
  );
}
