import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'indicatorTables',
  initialState: {
    dashboardTable: {
      pinnedColumns: {
        left: ['activity.name'],
        right: ['activity.expirationDate'],
      },
      preferencePanel: { open: false },
      sorting: {
        sortModel: [{ field: 'activity.expirationDate', sort: 'asc' }],
      },
      pagination: {
        paginationModel: { pageSize: 25, page: 0 },
      },
    } as GridInitialStatePro,
    analysisTable: {
      pinnedColumns: {
        left: ['activity.name'],
      },
      preferencePanel: { open: false },
      pagination: {
        paginationModel: { pageSize: 25, page: 0 },
      },
    } as GridInitialStatePro,
  },
  reducers: {
    setDashboardTable: (state, action: PayloadAction<GridInitialStatePro>) => {
      state.dashboardTable = action.payload;
    },
    setAnalysisTable: (state, action: PayloadAction<GridInitialStatePro>) => {
      state.analysisTable = action.payload;
    },
  },
});

export default slice.reducer;
export const { setDashboardTable, setAnalysisTable } = slice.actions;
