import 'chart.js/auto';

import { useTheme } from '@mui/material';
import { TooltipItem } from 'chart.js';
import { BillingPurchaseOrderCurrencyChoices } from 'generated/graphql';
import _ from 'lodash';
import { totalFormat } from 'pages/ActivityPage/utils';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';

export interface ChartItem {
  color: string;
  label: string;
  total: number;
  totalPrice: number;
}

const doughnutExtract = (ChartItems: ChartItem[]) => {
  const labels: string[] = [];
  const values: number[] = [];
  const colors: string[] = [];
  const totalPrice: number[] = [];
  ChartItems.forEach((element) => {
    labels.push(element.label);
    values.push(element.totalPrice);
    colors.push(element.color);
    totalPrice.push(element.totalPrice);
  });
  return { colors, labels, values, totalPrice };
};

export const DoughnutGraph = (properties: {
  data: ChartItem[];
  currency: BillingPurchaseOrderCurrencyChoices;
  title?: string[];
}): JSX.Element => {
  const theme = useTheme();

  const { labels, values, colors, totalPrice } = doughnutExtract(
    properties.data
  );

  const pieData = {
    totalPrice: totalPrice,
    labels: labels,
    datasets: [
      {
        data: values,
        backgroundColor: colors,
        borderColor: theme.palette.background.paper,
      },
    ],
  };

  const defaultChartOptions = {
    maintainAspectRatio: false,
    plugins: {
      title: {
        text: properties.title,
      },
      legend: {
        position: 'right',
      },
    },
    responsive: false,
    rotation: Math.PI / 2,
  };

  const tooltipCallback = (context: TooltipItem<'doughnut'>) => {
    const label = context.label || '';
    const chart = _.find(properties.data, (chart) => chart.label === label);
    if (!chart) {
      return '';
    }
    if (label === 'Restant') {
      return ` ${totalFormat(chart.totalPrice, properties.currency)}`;
    }
    return ` ${chart.total} facture(s), ${totalFormat(
      chart.totalPrice,
      properties.currency
    )}`;
  };

  return (
    <Doughnut
      data={pieData}
      options={{
        ...defaultChartOptions,
        plugins: {
          tooltip: {
            callbacks: {
              label: tooltipCallback,
            },
            bodyFont: {
              size: 14,
              family: ['Outfit', 'Open Sans'].join(','),
            },
            position: 'average',
          },
          legend: {
            position: 'right',
          },
          title: {
            display: !!properties.title,
          },
        },
        rotation: Math.PI / 2,
        responsive: false,
      }}
    />
  );
};
