import BlockIcon from '@mui/icons-material/Block';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import SendIcon from '@mui/icons-material/Send';
import { Icon, Stack, Typography } from '@mui/material';
import {
  RiCheckboxCircleFill,
  RiCloseCircleFill,
  RiFileTextFill,
  RiForbid2Line,
  RiSendPlane2Fill,
  RiSendPlaneFill,
} from '@remixicon/react';
import clsx from 'clsx';
import styles from 'components/MissionFollowUp/OverallListBill/DisplayStatus/styles/Status.module.scss';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import { BillingBillStatusChoices } from 'generated/graphql';
import React from 'react';

interface DisplayBillStatusProps {
  status: BillingBillStatusChoices;
  iconOnly?: boolean;
  checked?: boolean;
  disabled?: boolean;
  justify?: 'center' | 'end' | 'flex-end' | 'flex-start' | 'start';
  iconSize?: string;
}

export default function DisplayBillStatus({
  status,
  iconOnly,
  checked,
  disabled,
  justify = 'center',
  iconSize,
}: DisplayBillStatusProps) {
  const SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED =
    !!useEnvironmentVariable('send_bill_email');

  const billStatusItem = (
    icon: JSX.Element,
    className: string,
    title: string
  ) => {
    if (iconOnly) {
      return <span className={styles.statusContainer}>{icon}</span>;
    }
    return (
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={justify}
        height={'100%'}
      >
        <Icon>{icon}</Icon>
        <Typography
          noWrap
          variant="inherit"
          className={clsx(
            checked ? styles.checked : className,
            styles.billTitle
          )}
        >
          {title}
        </Typography>
      </Stack>
    );
  };

  const getIconStyle = (style: string) => {
    if (checked) {
      return styles.checked;
    }
    if (disabled) {
      return styles.disabled;
    }
    return style;
  };

  const pickIcon = () => {
    switch (status) {
      case BillingBillStatusChoices.Draft:
        return billStatusItem(
          SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED ? (
            <RiFileTextFill
              className={getIconStyle(styles.draft)}
              style={{ fontSize: iconSize ? iconSize : '20px' }}
            />
          ) : (
            <DescriptionIcon
              className={getIconStyle(styles.draft)}
              sx={{ fontSize: iconSize ? iconSize : '20px' }}
            />
          ),
          styles.draft,
          'Brouillon'
        );
      case BillingBillStatusChoices.ToBill:
        return billStatusItem(
          SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED ? (
            <RiSendPlane2Fill
              className={getIconStyle(styles.toBillNew)}
              style={{ fontSize: iconSize ? iconSize : '20px' }}
            />
          ) : (
            <SendIcon
              className={getIconStyle(styles.toBill)}
              sx={{ fontSize: iconSize ? iconSize : '20px' }}
            />
          ),
          SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED
            ? styles.toBillNew
            : styles.toBill,
          'À facturer'
        );
      case BillingBillStatusChoices.Billed:
        return billStatusItem(
          SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED ? (
            <RiCheckboxCircleFill
              className={getIconStyle(styles.billed)}
              style={{ fontSize: iconSize ? iconSize : '20px' }}
            />
          ) : (
            <CheckCircleIcon
              className={getIconStyle(styles.sent)}
              sx={{ fontSize: iconSize ? iconSize : '20px' }}
            />
          ),
          SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED ? styles.billed : styles.sent,
          SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED ? 'Comptabilisée' : 'Facturée'
        );
      case BillingBillStatusChoices.Sent:
        return billStatusItem(
          <RiSendPlaneFill
            className={getIconStyle(styles.sent)}
            fontSize={iconSize ? iconSize : '20px'}
          />,
          styles.sent,
          'Envoyée'
        );
      case BillingBillStatusChoices.ToCancel:
        return billStatusItem(
          SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED ? (
            <RiForbid2Line
              className={getIconStyle(styles.toCancel)}
              style={{ fontSize: iconSize ? iconSize : '20px' }}
            />
          ) : (
            <BlockIcon
              className={getIconStyle(styles.toCancel)}
              sx={{ fontSize: iconSize ? iconSize : '20px' }}
            />
          ),
          styles.toCancel,
          'À annuler'
        );
      case BillingBillStatusChoices.Canceled:
        return billStatusItem(
          SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED ? (
            <RiCloseCircleFill
              className={getIconStyle(styles.canceled)}
              style={{ fontSize: iconSize ? iconSize : '20px' }}
            />
          ) : (
            <CancelIcon
              className={getIconStyle(styles.canceled)}
              sx={{ fontSize: iconSize ? iconSize : '20px' }}
            />
          ),
          styles.canceled,
          'Annulée'
        );
      default:
        return <></>;
    }
  };
  return pickIcon();
}
