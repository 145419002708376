import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';

interface CardHomePageProps {
  title: string;
  content?: string | JSX.Element | JSX.Element[];
  setModalVisibility?: () => void;
}

const CardHomePage = ({
  title,
  content,
  setModalVisibility,
}: CardHomePageProps) => {
  return (
    <Card
      sx={{
        width: 1,
        height: 200,
        backgroundColor: 'background.default',
      }}
    >
      <CardActionArea
        sx={{
          width: 1,
          height: 1,
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'self-start',
        }}
        onClick={setModalVisibility}
      >
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              flexDirection: 'column',
              padding: '18px 20px',
            }}
          >
            <Stack spacing={2} direction={'row'} alignItems={'center'}>
              <Typography variant={'h2Bold'} component="h2">
                {title}
              </Typography>
            </Stack>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'left',
                textAlign: 'left',
                width: 1,
                marginTop: 3,
                fontSize: '18px',
                fontFamily: ['Outfit', 'Open Sans'].join(','),
              }}
            >
              {content}
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CardHomePage;
