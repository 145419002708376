import {
  Avatar,
  Box,
  Chip,
  ListItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { getEmployeeStatusChip } from 'components/EmployeePage/utils';
import { EmployeeNode } from 'generated/graphql';
import { useImageRequest } from 'hooks/useImageRequest';
import { AVATAR_PONGO_PATH } from 'poly-constants';
import React, { useEffect, useState } from 'react';
import { displayEmployeeNode } from 'utils';

import { getEmployeeOccupationInfo } from '../EmployeeCard/EmployeeOccupationIndicator/EmployeeOccupationIndicator';
import { EmployeeStatusTextChoices } from '../utils';

interface SearchBarItemProps {
  props?: React.HTMLAttributes<HTMLLIElement>;
  style: React.CSSProperties;
  employee: EmployeeNode;
}

export default function PolyTrombiSearchBarItem({
  props,
  employee,
  style,
}: SearchBarItemProps) {
  const theme = useTheme();
  const { response: imageReceived, loading } = useImageRequest(
    employee?.minifiedProfilePicture?.filename
  );
  const [image, setImage] = useState<string | undefined>(undefined);

  const { label: employeeStatusChipLabel } = getEmployeeStatusChip(employee);

  useEffect(() => {
    if (employee?.minifiedProfilePicture?.filename) {
      if (!loading) {
        setImage(imageReceived);
      }
    } else {
      setImage(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageReceived, loading]);

  return (
    <ListItem
      key={employee.id}
      {...props}
      sx={{
        mx: 2,
        my: 1,
        width: 'fill-available',
        borderRadius: 1,
        '&.MuiAutocomplete-option.Mui-focused': {
          backgroundColor: theme.palette.info.light + '99',
        },
        "&.MuiAutocomplete-option[aria-selected='true']": {
          backgroundColor: theme.palette.info.light + 'D9',
        },
        "&.MuiAutocomplete-option.Mui-focused[aria-selected='true']": {
          backgroundColor: theme.palette.info.light,
        },
        position: 'absolute',
        top: `${style.top}px`,
      }}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        width="inherit"
      >
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Box
            sx={{
              borderRadius: '50%',
              backgroundColor: theme.palette.lightGrey.main,
            }}
          >
            <Avatar src={image || AVATAR_PONGO_PATH} />
          </Box>
          <Stack>
            <Typography variant="bodyBold">
              {displayEmployeeNode(employee, false)}
            </Typography>
            <Typography>
              {
                getEmployeeOccupationInfo(employee.occupation, true)
                  .occupationName
              }
            </Typography>
          </Stack>
        </Stack>
        {employeeStatusChipLabel === EmployeeStatusTextChoices.COMING && (
          <Chip
            label={EmployeeStatusTextChoices.COMING}
            variant={'upcoming'}
            size="small"
          />
        )}
      </Stack>
    </ListItem>
  );
}
