import { Grid } from '@mui/material';
import UploadFileForm from 'components/commons/FileTransfer/UploadFile/UploadFileForm';
import GridItem from 'components/MissionFollowUp/GridItem';
import PolyFooter from 'components/MUIOverload/PolyFooter';
import PongoButton from 'components/MUIOverload/PongoButton';
import {
  AttachmentInput,
  BillNode,
  PurchaseOrderNode,
  useAddPoAttachmentsMutation,
} from 'generated/graphql';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { UploadButtonName } from 'poly-constants';
import React, { useMemo } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { setCurrentPurchaseOrder } from 'store/purchaseOrder';

import { AttachmentType } from '../BillDetail/utils';

interface PoAddAttachmentsProps {
  validCallback: () => void;
  attachmentsToDelete: AttachmentInput[];
}

interface BillFormType {
  poId: string;
  attachments: AttachmentType[];
}

export function getAttachmentsToKeep(
  initialObject: PurchaseOrderNode | BillNode,
  attachmentsToDelete: AttachmentInput[]
): AttachmentInput[] {
  return _(initialObject.attachments)
    .map((attachment) => ({
      id: attachment.id,
    }))
    .filter(
      (attachment) =>
        !_.some(attachmentsToDelete, (a) => attachment.id === a.id)
    )
    .value();
}

export default function PoAddAttachments(properties: PoAddAttachmentsProps) {
  const currentPo = useSelector(
    (state) => state.purchaseOrder.currentPurchaseOrder
  );
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const form = useForm({
    mode: 'onChange',
    defaultValues: useMemo(() => {
      return {
        poId: currentPo.id,
        attachments: [] as AttachmentType[],
      };
    }, [currentPo.id]),
    criteriaMode: 'firstError',
    shouldUnregister: false,
  });
  const { handleSubmit, control } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'attachments',
    keyName: 'key',
  });

  const [addPoAttachments, { loading }] = useAddPoAttachmentsMutation({
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    onCompleted: (data) => {
      const po = data.addPoAttachments?.purchaseOrder as PurchaseOrderNode;
      if (po) {
        dispatch(
          setCurrentPurchaseOrder({ ...currentPo, attachments: po.attachments })
        );
      }
      properties.validCallback();
      enqueueSnackbar(`Le bon de commande « ${po.name} » a été mise à jour`, {
        variant: 'success',
      });
    },
  });

  const onSubmit = async (submitValues: BillFormType) => {
    if (loading) {
      return;
    }
    const attachments: AttachmentInput[] = _.flatMap(
      submitValues.attachments,
      (x) => {
        return {
          id: x.id,
          file: x.file,
        };
      }
    );

    await addPoAttachments({
      variables: {
        poId: currentPo.id,
        attachments: attachments.concat(
          getAttachmentsToKeep(currentPo, properties.attachmentsToDelete)
        ),
      },
    });
  };

  const appendField = () => {
    append({
      id: '',
      filename: '',
      file: undefined,
    });
  };

  // when there is no document or all document are already set, append upload field
  if (!_.find(fields, { filename: '' })) {
    appendField();
  }

  return (
    <React.Fragment>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit((d) => onSubmit(d))}>
          <Grid container>
            <GridItem customspacingtop={20} sizegrid={12}>
              {_.map(fields, (field, index) => {
                const name = `attachments[${index}]`;
                return (
                  <UploadFileForm
                    key={field.key}
                    callback={() => appendField()}
                    deleteCallback={() => remove(index)}
                    formname={name}
                    isEditing={true}
                  />
                );
              })}
            </GridItem>
          </Grid>
          <PolyFooter>
            <PongoButton
              onClick={() => {
                properties.validCallback();
              }}
              sx={{ mr: 0.5 }}
            >
              Fermer
            </PongoButton>
            <PongoButton
              type={'submit'}
              variant={'contained'}
              disabled={
                loading ||
                (!form.formState.isDirty &&
                  properties.attachmentsToDelete.length === 0)
              }
            >
              {UploadButtonName}
            </PongoButton>
          </PolyFooter>
        </form>
      </FormProvider>
    </React.Fragment>
  );
}
