import { DateRange } from 'components/commons/Tables/utils';
import {
  BillingPurchaseOrderCurrencyChoices,
  MeEmployeeFragment,
  TmActivityFragment,
} from 'generated/graphql';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { WorkingDayMoment } from 'pages/UserPage/ContractualDays/utils';
import { CurrenciesSymbols } from 'poly-constants';

import { Activity } from './PrincipalTablePage';

export type employeeType = MeEmployeeFragment | null | undefined;
type updateAuthorizationType = DeepExtractTypeSkipArrays<
  MeEmployeeFragment,
  ['MeEmployeeFragment', 'updateAuthorization']
>;

export type AssignmentType = DeepExtractTypeSkipArrays<
  TmActivityFragment,
  ['assignments']
>;

/**
 * Each date key maps to an object with activity IDs as keys, and their values are objects with timeSpent.
 */
export type GroupedActivityMonitoring = {
  [date: string]: {
    [activityId: string]: {
      timeSpent: string;
    };
  };
};

export const isEditableCell = (
  employee: employeeType,
  today: Moment,
  weekDay: Moment,
  startDate: Moment
) => {
  return (
    isAfterHiringDate(employee, weekDay) &&
    isBeforeLeavingDate(employee, weekDay) &&
    (isCurrentMonth(today, weekDay) ||
      hasUpdateAMAuthValid(
        employee?.updateAuthorization,
        employee?.updateAuthorization?.months,
        startDate
      ))
  );
};

export const isEditableCellV2 = (
  employee: employeeType,
  today: Moment,
  startDate: Moment,
  endDate: Moment
) => {
  return (
    isAfterHiringDate(employee, startDate) &&
    isBeforeLeavingDate(employee, endDate) &&
    (isCurrentMonth(today, startDate) ||
      hasUpdateAMAuthValid(
        employee?.updateAuthorization,
        employee?.updateAuthorization?.months,
        startDate
      ))
  );
};

export const hasUpdateAMAuthValid = (
  has_auth: updateAuthorizationType | undefined,
  months: string[] | undefined,
  startDate: Moment | undefined
) => {
  if (!has_auth) {
    return false;
  }

  return (
    months?.find(
      (month) =>
        startDate?.isSame(moment(month), 'month') &&
        startDate?.isSame(moment(month), 'year')
    ) !== undefined || false
  );
};

export const checkBusinessDayValidation = (today: Moment, weekDay: Moment) => {
  const lastDayToValidate = weekDay.clone().add(1, 'months').startOf('month');
  // we allow to modify last month AM until the 7th of the next month
  lastDayToValidate.add(6, 'days');
  return today.isSameOrBefore(lastDayToValidate, 'days');
};

const isCurrentMonth = (today: Moment, weekDay: Moment) => {
  const currentThursday = today.clone().weekday(3);
  return (
    (weekDay.isSameOrAfter(currentThursday, 'month') &&
      weekDay.isSameOrAfter(currentThursday, 'year')) ||
    weekDay.isAfter(currentThursday, 'year') ||
    checkBusinessDayValidation(today, weekDay)
  );
};

export const isAfterHiringDate = (employee: employeeType, weekday: Moment) => {
  if (employee?.hiringDate) {
    const lastDayOfWeek = weekday.clone().weekday(4);
    return lastDayOfWeek.isSameOrAfter(moment(employee?.hiringDate), 'day');
  }
  return true;
};

const daysOnWeekAfterLeaving = (
  employee: employeeType,
  week: Moment,
  weekContract: number
) => {
  const lastDayOfWeek = week.clone().day(weekContract);
  if (
    !employee?.leavingDate ||
    moment(employee.leavingDate).isSameOrAfter(lastDayOfWeek, 'days')
  ) {
    return 0;
  }
  return lastDayOfWeek.diff(moment(employee.leavingDate), 'days');
};

const daysOnWeekBeforeHiringDate = (employee: employeeType, week: Moment) => {
  const firstDayOfWeek = week.clone().day(1);
  if (
    !employee?.hiringDate ||
    moment(employee.hiringDate).isSameOrBefore(firstDayOfWeek, 'days')
  ) {
    return 0;
  }
  return moment(employee.hiringDate).diff(firstDayOfWeek, 'days');
};

export const totalDaysToWork = (employee: employeeType, week: Moment) => {
  let weekContract = _.find(employee?.weeklyContracts, function (item) {
    return moment(item.date).isSame(week, 'week');
  });
  if (!weekContract) {
    weekContract = _.first(employee?.weeklyContracts);
  }
  const daysWorked = weekContract?.weeklyContract ?? 5;
  return (
    daysWorked -
    daysOnWeekAfterLeaving(employee, week, daysWorked) -
    daysOnWeekBeforeHiringDate(employee, week)
  );
};

export const totalDaysToWorkV2 = (
  employee: employeeType,
  start: Moment,
  end: Moment
) => {
  const contracts = _.filter(
    employee?.weeklyContracts,
    (weeklyContract) => weeklyContract?.date < end.format('YYYY-MM-DD')
  );
  const weekContract =
    _.maxBy(contracts, (contract) => contract.date) ||
    _.first(employee?.weeklyContracts);
  const weekLength = end.diff(start, 'days') + 1;

  if (!weekContract) {
    return weekLength;
  }

  let daysToWork = 0;
  const dayIterator = start.clone();

  while (dayIterator <= end) {
    if (
      !employee?.hiringDate ||
      dayIterator.format('YYYY-MM-DD') < employee.hiringDate ||
      (employee?.leavingDate &&
        dayIterator.format('YYYY-MM-DD') > employee.leavingDate)
    ) {
      dayIterator.add(1, 'days');
      continue;
    }
    const dayNbr = dayIterator.isoWeekday();
    const daysInfos = [
      weekContract.monday,
      weekContract.tuesday,
      weekContract.wednesday,
      weekContract.thursday,
      weekContract.friday,
      weekContract.saturday,
      weekContract.sunday,
    ];
    if (daysInfos[dayNbr - 1][WorkingDayMoment.AM]) {
      daysToWork += 0.5;
    }
    if (daysInfos[dayNbr - 1][WorkingDayMoment.PM]) {
      daysToWork += 0.5;
    }
    dayIterator.add(1, 'days');
  }
  return daysToWork;
};

export const isBeforeLeavingDate = (
  employee: employeeType,
  weekday: Moment
) => {
  if (employee?.leavingDate) {
    const firstDayOfWeek = weekday.clone().weekday(0);
    return firstDayOfWeek.isSameOrBefore(moment(employee?.leavingDate), 'day');
  }
  return true;
};

export const roundNumber = (numberToRound: number) => {
  return Math.round(numberToRound * 1e2) / 1e2;
};

export const roundNumberFromString = (numberStringToRound: string) => {
  const numberToRound = Number.parseFloat(numberStringToRound);
  return roundNumber(numberToRound);
};

export const inputDataFormat = (value: number) => {
  return value === 0 ? '' : value.toString().replace(',', '.');
};

export const dotToComa = (numberToModify?: number | string) => {
  return numberToModify?.toString().replace('.', ',') || '';
};

function formatNumber(
  numberToFormat: number,
  displayCurrency?: BillingPurchaseOrderCurrencyChoices,
  unit = ''
) {
  return displayCurrency
    ? `${numberToFormat?.toLocaleString('fr-FR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} ${unit}${CurrenciesSymbols[displayCurrency]}`
    : numberToFormat?.toLocaleString('fr-FR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
}

export const totalFormat = (
  numberToFormat = 0,
  displayCurrency?: BillingPurchaseOrderCurrencyChoices,
  wrapThousands?: boolean
) => {
  if (wrapThousands && numberToFormat !== 0) {
    return formatNumber(numberToFormat / 1000, displayCurrency, 'K');
  }

  return formatNumber(numberToFormat, displayCurrency);
};

export const comaStringToFloat = (stringToFormat?: string | number) => {
  return Number.parseFloat(
    stringToFormat?.toString().replace(',', '.').replace(/\s/g, '') || '0'
  );
};

// Take an array of overlapping assignments and return one longer assignment
export const getCompressedAssignmentWithMaxRange = (
  assignments: Array<AssignmentType>
): AssignmentType => {
  if (assignments.length > 1) {
    const employee = assignments[0].employee;
    const minBeginningDate = _.min(_.map(assignments, 'beginningDate'));
    const maxExpirationDate = _.max(_.map(assignments, 'expirationDate'));
    return {
      employee: employee,
      beginningDate: minBeginningDate,
      expirationDate: maxExpirationDate,
    };
  } else {
    return assignments[0];
  }
};

/**
 * This function flattens the array of activities, extracting information from each activity monitoring.
 * It creates an array of objects with properties date, id (activity id), and timeSpent.
 * Groups the array of objects by the date property.
 * Creates an object where keys are unique dates, and values are arrays of objects with the same date.
 * For each group (objects with the same date):
 *    - Uses reduce to accumulate results for each object within the group.
 *    - The accumulator (acc) is an object where keys are IDs, and values are objects with timeSpent.
 */
export const getAMPerActivityPerStartDate = (
  activities: Array<Activity> | undefined
): GroupedActivityMonitoring => {
  return _.chain(activities)
    .flatMap((activity) =>
      activity.activityMonitoring?.map((am) => ({
        date: am.date,
        id: activity.id,
        timeSpent: am.timeSpent,
      }))
    )
    .groupBy('date')
    .mapValues((groupedAMs) =>
      _.reduce(
        groupedAMs,
        (acc: { [key: string]: { timeSpent: string } }, am) => {
          if (am?.id) {
            acc[am?.id] = {
              timeSpent: am.timeSpent.toString(),
            };
          }
          return acc;
        },
        {}
      )
    )
    .value();
};

export const buildDateRange = (
  date: Moment,
  startOffset = 7,
  endOffset = 2
): DateRange => {
  const start = date.clone().subtract(startOffset, 'month').startOf('month');
  const end = date.clone().add(endOffset, 'month').endOf('month');

  return { start: start, end: end };
};

const utils = {
  isEditableCell,
  totalDaysToWork,
  checkBusinessDayValidation,
  dotToComa,
  comaStringToFloat,
  getCompressedAssignmentWithMaxRange,
  totalFormat,
};

export default utils;
