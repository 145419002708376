import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Stack, Tooltip } from '@mui/material';
import FieldDisplay from 'components/commons/FieldDisplay/FieldDisplay';
import { useEstimatedContext } from 'components/Revenue/Estimated/context/EstimatedContextProvider';
import { BillingPurchaseOrderCurrencyChoices } from 'generated/graphql';
import _ from 'lodash';
import { totalFormat } from 'pages/ActivityPage/utils';
import React, { useMemo } from 'react';

interface EstimatedRevenueSummaryWrappedProperties {
  objective?: number;
}

export default function EstimatedRevenueSummaryWrapped(
  properties: EstimatedRevenueSummaryWrappedProperties
) {
  const { clients, totalObjective, referenceDate } = useEstimatedContext();

  const totalActual = useMemo(() => {
    return _.sumBy(clients, (client) => _.sumBy(client.actualTotals, 'amount'));
  }, [clients]);

  const companyObjective = properties.objective || 0;
  const estimatedDelta = companyObjective - totalObjective;
  const totalToDo = companyObjective - totalActual;
  const monthlyToDo = totalToDo / (12 - referenceDate.month());

  return (
    <Stack display={'flex'} direction={'row'} spacing={6}>
      <Stack
        sx={{ pt: 1, pb: 2 }}
        display={'flex'}
        direction={'column'}
        spacing={1}
        flexWrap={'wrap'}
      >
        <Stack
          display={'flex'}
          justifyContent={'flex-start'}
          direction={'row'}
          alignItems={'center'}
        >
          <Tooltip
            title={
              'Objectif annuel Poly - Somme des objectifs annuels missions'
            }
            placement={'top'}
            sx={{ mr: '2px' }}
          >
            <InfoIcon sx={{ color: 'text.secondary' }} fontSize={'small'} />
          </Tooltip>
          <FieldDisplay
            title={'Delta prévisionnel'}
            value={totalFormat(
              estimatedDelta,
              BillingPurchaseOrderCurrencyChoices.Eur
            )}
          />
        </Stack>
        <Stack
          display={'flex'}
          justifyContent={'flex-start'}
          direction={'row'}
          alignItems={'center'}
        >
          <Tooltip
            title={'Somme du CA réalisé de toutes les missions à date'}
            placement={'top'}
            sx={{ mr: '2px' }}
          >
            <InfoIcon sx={{ color: 'text.secondary' }} fontSize={'small'} />
          </Tooltip>
          <FieldDisplay
            title={'Réalisé à date'}
            value={totalFormat(
              totalActual,
              BillingPurchaseOrderCurrencyChoices.Eur
            )}
          />
        </Stack>
      </Stack>
      <Stack
        sx={{ pt: 1, pb: 2 }}
        display={'flex'}
        direction={'column'}
        spacing={1}
        flexWrap={'wrap'}
      >
        <Stack
          display={'flex'}
          justifyContent={'flex-start'}
          direction={'row'}
          alignItems={'center'}
        >
          <Tooltip
            title={'Objectif annuel Poly - Somme du réalisé à date'}
            placement={'top'}
            sx={{ mr: '2px' }}
          >
            <InfoIcon sx={{ color: 'text.secondary' }} fontSize={'small'} />
          </Tooltip>
          <FieldDisplay
            title={'Reste à faire'}
            value={totalFormat(
              totalToDo > 0 ? totalToDo : 0,
              BillingPurchaseOrderCurrencyChoices.Eur
            )}
          />
        </Stack>
        <Stack
          display={'flex'}
          justifyContent={'flex-start'}
          direction={'row'}
          alignItems={'center'}
        >
          <Tooltip
            title={'Reste à faire / Nombre de mois restants'}
            placement={'top'}
            sx={{ mr: '2px' }}
          >
            <InfoIcon sx={{ color: 'text.secondary' }} fontSize={'small'} />
          </Tooltip>
          <FieldDisplay
            title={'Objectif mensuel restant'}
            value={totalFormat(
              monthlyToDo > 0 ? monthlyToDo : 0,
              BillingPurchaseOrderCurrencyChoices.Eur
            )}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
