import { once } from 'lodash';
import React, { createContext, ReactNode, useContext, useState } from 'react';

export interface PolyDataGridContextProps {
  children?: ReactNode;
}

export interface PolyDataGridContext<T> {
  hasGlobalChecked?: { checked: boolean };
  setHasGlobalChecked: (p: { checked: boolean }) => void;
  checkableRows: Set<T>;
  setCheckableRows: (p: Set<T>) => void;
  checkedRows: Set<T>;
  setCheckedRows: (p: Set<T>) => void;
}

export const createPolyDataGridContext = once(<T,>() =>
  createContext<PolyDataGridContext<T>>({
    hasGlobalChecked: { checked: false },
    setHasGlobalChecked: () => {
      throw new Error('function setHasGlobalChecked not set');
    },
    checkableRows: new Set([]),
    setCheckableRows: () => {
      throw new Error('function setCheckableRows not set');
    },
    checkedRows: new Set([]),
    setCheckedRows: () => {
      throw new Error('function setCheckedRows not set');
    },
  })
);

export const usePolyDataTableContext = <T,>() => {
  const tableContext = useContext(createPolyDataGridContext<T>());
  if (!tableContext) {
    throw new Error('useContext() can only be used  inside a table');
  }
  return tableContext;
};

const PolyDataGridContextProvider = <T,>(
  properties: PolyDataGridContextProps
) => {
  const [hasGlobalChecked, setHasGlobalChecked] = useState({ checked: false });
  const [checkableRows, setCheckableRows] = useState(new Set<T>([]));
  const [checkedRows, setCheckedRows] = useState(new Set<T>([]));

  const PolyDataGridContext = createPolyDataGridContext<T>();
  return (
    <>
      <PolyDataGridContext.Provider
        value={{
          hasGlobalChecked,
          setHasGlobalChecked,
          checkedRows,
          setCheckedRows,
          checkableRows,
          setCheckableRows,
        }}
      >
        {properties.children}
      </PolyDataGridContext.Provider>
    </>
  );
};

export default PolyDataGridContextProvider;
