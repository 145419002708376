import { TableCell } from '@mui/material';
import clsx from 'clsx';
import { useTableContext } from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/context/TableContextProvider';
import { getPoMode } from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/RevenueRows/PoRow';
import RevenueCellOutOfRange from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/RevenueTableCells/CellTypes/RevenueCellOutOfRange';
import { totalRevenuesForPoMonth } from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/RevenueTableCells/TotalTableCell';
import styles from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/styles/TableCell.module.scss';
import {
  isInRange,
  isInValidatedRangeMonths,
  isMonthValidated,
} from 'components/Revenue/Tables/util';
import {
  BillingPurchaseOrderCurrencyChoices,
  PurchaseOrderNode,
} from 'generated/graphql';
import _ from 'lodash';
import { Moment } from 'moment';
import { totalFormat } from 'pages/ActivityPage/utils';
import { POLY_DATE_MONTH } from 'poly-constants';
import React from 'react';
import { useFormContext } from 'react-hook-form';

interface PoTotalRowProps {
  purchaseOrder: PurchaseOrderNode;
  month?: Moment;
}
const PoTotalRow = ({ purchaseOrder, month }: PoTotalRowProps) => {
  const { displayedMonths, activity, rangeValidatedMonth } = useTableContext();
  const { watch } = useFormContext();
  const key = `${purchaseOrder.id}-total-`;
  const months = month ? [month] : displayedMonths;
  return (
    <>
      {_.map(months, (month) => {
        const monthString = month.format(POLY_DATE_MONTH);
        if (
          !isInRange(
            purchaseOrder.periodBeginning,
            purchaseOrder.periodEnding,
            month
          )
        ) {
          return (
            <RevenueCellOutOfRange
              key={`${key}-${monthString}`}
              isValidated={isInValidatedRangeMonths(rangeValidatedMonth, month)}
            />
          );
        }
        return (
          <TableCell
            className={clsx(styles.regularTableCell, {
              [styles.oldMonthCell]: isMonthValidated(month, purchaseOrder),
            })}
            key={`${key}-${monthString}`}
          >
            {totalFormat(
              totalRevenuesForPoMonth(
                getPoMode(activity, purchaseOrder),
                purchaseOrder,
                month,
                watch
              ),
              BillingPurchaseOrderCurrencyChoices.Eur
            )}
          </TableCell>
        );
      })}
    </>
  );
};

export default PoTotalRow;
