import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Button } from '@mui/material';
import React from 'react';

import styles from '../styles/NavigationButton.module.scss';

interface NavigationButtonProps {
  navigation: () => void;
  type: 'before' | 'next';
}

export default function NavigationButton({
  navigation,
  type,
}: NavigationButtonProps) {
  return (
    <Button
      sx={{
        minWidth: '13px',
        width: '13px',
        height: '60px',
        borderRadius: 0,
        padding: '0',
      }}
      aria-label={type === 'before' ? 'Précédent' : 'Suivant'}
      onClick={navigation}
      className={styles.changeMonthButton}
    >
      {type === 'before' ? <NavigateBeforeIcon /> : <NavigateNextIcon />}
    </Button>
  );
}
