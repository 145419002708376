import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Box,
  CardContent,
  IconButton,
  TextField,
} from '@mui/material';
import Card from '@mui/material/Card';
import { getFormattedMonthsSet } from 'components/MissionFollowUp/BillDetail/BillDetail/BillCollaboratorDays';
import {
  AverageDailyRateCollaboratorNode,
  BillingNode,
  BillingPurchaseOrderCurrencyChoices,
  FetchAssignmentsForActivityAndEmployeesQuery,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment/moment';
import { totalFormat } from 'pages/ActivityPage/utils';
import { requiredForm } from 'pages/MissionFollowUp/formValidators';
import { POLY_DATE_MONTH } from 'poly-constants';
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
} from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'store';

import { getFormatStringCollaboratorADR } from './utils';

interface BillCollaboratorDaysFormProps {
  collaboratorIds: string[];
  collaboratorADR?: AverageDailyRateCollaboratorNode;
  options: AverageDailyRateCollaboratorNode[];
  currency: BillingPurchaseOrderCurrencyChoices;
  activityId?: string;
  assignments?: FetchAssignmentsForActivityAndEmployeesQuery;
  selfDelete?: () => void;
  formName: string;
  disabled: boolean;
  nestedIndex?: number;
  setExpandedAccordions?: Dispatch<SetStateAction<Set<number>>>;
}

const BillCollaboratorDaysForm = ({
  collaboratorIds,
  collaboratorADR,
  options,
  currency,
  activityId,
  assignments,
  selfDelete,
  formName,
  disabled,
  nestedIndex,
  setExpandedAccordions,
}: BillCollaboratorDaysFormProps) => {
  const form = useFormContext();
  const collaboratorsBillings = useSelector(
    (state) => state.purchaseOrder.currentPurchaseOrderBilling
  );
  const collaboratorADRValue = form.watch(`${formName}.collaboratorRate`);
  const getDaysNumbersForCollab = useCallback(
    (collaboratorADRValue?: AverageDailyRateCollaboratorNode) => {
      return _.filter(collaboratorsBillings, (collaboratorsBilling) => {
        if (activityId && collaboratorsBilling.activity?.id !== activityId) {
          return false;
        }
        return (
          collaboratorsBilling.employee?.id ===
          collaboratorADRValue?.collaborator?.id
        );
      });
    },
    [collaboratorsBillings, activityId]
  );

  const daysNumbers = getDaysNumbersForCollab(collaboratorADRValue);

  useEffect(() => {
    if (!_.isEqual(form.watch(`${formName}.daysNumbers`), daysNumbers)) {
      form.setValue(`${formName}.daysNumbers`, daysNumbers, {
        shouldValidate: true,
      });
    }
  }, [
    collaboratorADRValue,
    collaboratorsBillings,
    daysNumbers,
    formName,
    form,
  ]);
  const getNumberOfMonthsAssignedInRange = (collaboratorId: string): number => {
    const formMonthsRange = form.watch('billMonthRange');
    const formattedFormMonthsSet = getFormattedMonthsSet(
      formMonthsRange.from,
      formMonthsRange.to
    );
    const collaboratorAssignments =
      assignments?.allAssignmentsForActivityAndEmployees &&
      assignments.allAssignmentsForActivityAndEmployees.filter(
        (assigment) => assigment.employee.id === collaboratorId
      );
    let totalAssignmentsMonthsSet = new Set<string>();
    _.map(collaboratorAssignments, (assignment) => {
      const assignmentMonthsSet = getFormattedMonthsSet(
        moment(assignment.beginningDate).startOf('month'),
        moment(assignment.expirationDate).startOf('month')
      );
      totalAssignmentsMonthsSet = new Set([
        ...totalAssignmentsMonthsSet,
        ...assignmentMonthsSet,
      ]);
    });

    const monthsAssignedInFormSet = new Set(
      [...totalAssignmentsMonthsSet].filter((month) =>
        formattedFormMonthsSet.has(month)
      )
    );
    return monthsAssignedInFormSet.size;
  };
  const monthWork = getNumberOfMonthsAssignedInRange(
    form.watch(`${formName}.collaboratorRate.collaborator.id`)
  );
  const verifyDaysNumbers = useCallback(
    (daysNumbers: BillingNode[]) => {
      const daysNumberDict = _.groupBy(daysNumbers, (dn) =>
        moment(dn.dateBilling).format(POLY_DATE_MONTH)
      );
      const keysLength = Object.keys(daysNumberDict).length;
      if (monthWork === undefined) {
        return true;
      }
      if (monthWork === 0) {
        return 'Ce collaborateur ne possède pas d’assignement sur la période sélectionnée';
      }
      if (keysLength !== monthWork) {
        return `Temps non validés (${keysLength} mois validé(s) sur ${monthWork} mois)`;
      }
      return true;
    },
    [monthWork]
  );

  const getTotalTimeForContract = () => {
    if (!collaboratorADRValue) {
      return '';
    }
    return _.sumBy(daysNumbers, 'timeForTmContracts');
  };

  const getSousTotal = () => {
    if (!collaboratorADRValue) {
      return 0;
    }
    return (
      _.sumBy(daysNumbers, 'timeForTmContracts') *
      collaboratorADRValue.dailyRate
    );
  };

  const getError = () => {
    return _.get(form.errors, `${formName}.collaboratorRate`);
  };

  const validateAdr = useCallback(
    (collaboratorADRValue?: AverageDailyRateCollaboratorNode) => {
      if (!collaboratorADRValue) {
        return true;
      }
      const daysNumbers = getDaysNumbersForCollab(collaboratorADRValue);
      return verifyDaysNumbers(daysNumbers);
    },
    [getDaysNumbersForCollab, verifyDaysNumbers]
  );

  const error = getError();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getCollabError = () => {
    if (error && error.type === 'required') {
      return error;
    }
  };

  const getDaysNumbersError = () => {
    if (error && error.type !== 'required') {
      return error;
    }
  };

  useEffect(() => {
    const isValid = validateAdr(collaboratorADRValue);
    if (collaboratorADRValue && isValid !== true && !error) {
      form.setError(`${formName}.collaboratorRate`, {
        type: 'valid',
        message: isValid,
      });
      if (setExpandedAccordions) {
        setExpandedAccordions((expAcc: Set<number>) => {
          if (nestedIndex !== undefined) {
            expAcc.add(nestedIndex);
          }
          return new Set(expAcc);
        });
      }
    } else if (collaboratorADRValue && isValid === true && error) {
      form.clearErrors(`${formName}.collaboratorRate`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    collaboratorADRValue,
    error,
    validateAdr,
    nestedIndex,
    setExpandedAccordions,
  ]);

  return (
    <Card
      sx={{ width: '100%', display: 'flex', alignItems: 'center', my: 1 }}
      variant="outlined"
    >
      <CardContent
        sx={{
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          gap: '5px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Controller
          name={`${formName}.collaboratorRate`}
          control={form.control}
          rules={{
            required: requiredForm,
            validate: {
              valid: validateAdr,
            },
          }}
          defaultValue={collaboratorADRValue || collaboratorADR || null}
          render={({ value }) => (
            <Autocomplete
              onClose={() => form.trigger(`${formName}.collaboratorRate`)}
              autoComplete
              autoHighlight
              noOptionsText={'Pas de collaborateurs disponibles'}
              disabled={disabled}
              id={`${formName}-select-collaboratorDays`}
              value={value || null}
              title={getFormatStringCollaboratorADR(value, currency)}
              onChange={(
                event: ChangeEvent<{}>,
                newValue: AverageDailyRateCollaboratorNode | null
              ) => {
                form.setValue(`${formName}.collaboratorRate`, newValue);
              }}
              options={options}
              getOptionDisabled={(option) =>
                collaboratorIds.includes(option.collaborator.id)
              }
              isOptionEqualToValue={(option, value) =>
                option && value ? value.id === option.id : true
              }
              getOptionLabel={(option) =>
                getFormatStringCollaboratorADR(option, currency)
              }
              renderInput={(parameters) => (
                <TextField
                  {...parameters}
                  required
                  label={'Libellé / Nom / TJM (HT) du collaborateur'}
                  value={getFormatStringCollaboratorADR(value, currency)}
                  variant={'outlined'}
                  size={'small'}
                  error={!!getCollabError()?.message}
                  helperText={getCollabError()?.message}
                  sx={{ width: '400px', color: 'text.secondary' }}
                />
              )}
            />
          )}
        />
        <TextField
          required={false}
          variant="outlined"
          size={'small'}
          error={!!getDaysNumbersError()?.message}
          helperText={getDaysNumbersError()?.message}
          InputProps={{
            itemType: 'number',
          }}
          label={'Nombre de jours'}
          disabled
          value={getTotalTimeForContract()}
          sx={{ maxWidth: '150px', color: 'text.secondary' }}
        />
        <TextField
          required={false}
          variant="outlined"
          size={'small'}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            itemType: 'number',
          }}
          label={'Sous total'}
          disabled
          value={totalFormat(getSousTotal(), currency)}
          sx={{ maxWidth: '150px', color: 'text.secondary' }}
        />
      </CardContent>
      {!!selfDelete && !disabled && (
        <Box sx={{ height: '100%', padding: '0' }}>
          <IconButton
            onClick={selfDelete}
            sx={{
              width: '30px',
              height: '100%',
              borderRadius: '0px',
            }}
            color="error"
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </Card>
  );
};

BillCollaboratorDaysForm.defaultProps = {
  disabled: false,
};

export default BillCollaboratorDaysForm;
