import AddIcon from '@mui/icons-material/Add';
import BusinessClientModal from 'components/MissionFollowUp/BusinessClient/BusinessClientModal';
import PongoButton from 'components/MUIOverload/PongoButton';
import React, { useState } from 'react';

interface BusinessClientAddButtonProps {
  refetch: () => void;
}

export default function BusinessClientAddButton({
  refetch,
}: BusinessClientAddButtonProps) {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <PongoButton
        variant={'contained'}
        onClick={() => setOpen(true)}
        startIcon={<AddIcon />}
      >
        Ajouter un client
      </PongoButton>
      <BusinessClientModal
        open={open}
        close={() => setOpen(false)}
        onSuccess={() => refetch()}
      />
    </>
  );
}
