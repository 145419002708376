import { Stack, SxProps } from '@mui/material';
import React from 'react';
import { designSystem } from 'theme';

interface Props {
  children: string | JSX.Element | JSX.Element[];
  color?: keyof typeof designSystem.palette | 'default';
  sx?: SxProps;
}

const Section = ({ children, color = 'default', sx }: Props) => {
  return (
    <Stack
      sx={{
        border: '2px solid',
        borderColor:
          color === 'default' ? 'standardGrey.dark' : `${color}.main`,
        borderRadius: '20px',
        py: 2.5,
        px: 3.5,
        gap: 3,
        ...sx,
      }}
    >
      {children}
    </Stack>
  );
};

export default Section;
