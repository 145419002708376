import { Grid } from '@mui/material';
import AddItem from 'components/commons/ItemInteractions/AddItem/AddItem';
import { getModalityName } from 'components/MissionFollowUp/BillDetail/BillDetail/utils';
import { BillingBillingModalitiesBillingTypeChoices } from 'generated/graphql';
import _ from 'lodash';
import React from 'react';
import { UseFieldArrayMethods, useFormContext } from 'react-hook-form';

import ModalityEmail from './ModalitiesTemplates/ModalityEmail';
import ModalityPostal from './ModalitiesTemplates/ModalityPostal';
import ModalityUpload from './ModalitiesTemplates/ModalityUpload';
import ModalityCard from './ModalityCard';

interface ModalitiesMissionFormProps
  extends Partial<Pick<UseFieldArrayMethods, 'fields' | 'append' | 'remove'>> {
  clientAddress: string;
}

function ModalitiesMissionForm(properties: ModalitiesMissionFormProps) {
  const form = useFormContext();

  function addModality() {
    if (properties.append) {
      properties.append({
        billingType: BillingBillingModalitiesBillingTypeChoices.Email,
        billingAddress: properties.clientAddress,
      });
    }
  }

  return (
    <>
      {_.map(properties.fields, (modality, index) => {
        if (!modality || !modality.billingType) {
          return <div key={`modality-${modality.key}`} />;
        }
        return (
          <ModalityCard
            selectName={getModalityName(index, 'billingType')}
            selector={modality.billingType}
            key={`modality-${modality.key}`}
            index={index}
            onDeleteModality={properties.remove}
          >
            <Grid container item xs={12}>
              {form.watch(getModalityName(index, 'billingType')) ===
                BillingBillingModalitiesBillingTypeChoices.Email && (
                <ModalityEmail modality={modality} index={index} />
              )}
              {form.watch(getModalityName(index, 'billingType')) ===
                BillingBillingModalitiesBillingTypeChoices.Upload && (
                <ModalityUpload index={index} modality={modality} />
              )}
              {form.watch(getModalityName(index, 'billingType')) ===
                BillingBillingModalitiesBillingTypeChoices.Postal && (
                <ModalityPostal index={index} modality={modality} />
              )}
            </Grid>
          </ModalityCard>
        );
      })}
      <AddItem onClick={addModality} description={'Nouvelle modalité'} />
    </>
  );
}

export default ModalitiesMissionForm;
