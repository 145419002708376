import { GridProps, GridSize } from '@mui/material';
import PolyGrid from 'components/MUIOverload/PolyGrid';
import React from 'react';

import styles from './styles/GridItem.module.scss';

interface GridItemProps {
  customtitle?: JSX.Element;
  sizegrid?: GridSize;
  customspacingtop?: number;
  customspacingside?: number;
  customtend?: JSX.Element;
  required?: boolean;
}

const GridItem = (properties: GridItemProps & GridProps) => {
  return (
    <PolyGrid
      item
      xs={properties.sizegrid}
      customspacingtop={properties.customspacingtop}
      customspacingside={properties.customspacingside}
    >
      {(properties.title || properties.customtitle) && (
        <div className={styles.title}>
          {properties.title || properties.customtitle}
          {properties.required && <span className={styles.asterisk}> *</span>}
          {properties.customtend}
        </div>
      )}
      <div>{properties.children}</div>
    </PolyGrid>
  );
};

GridItem.defaultProps = {
  sizegrid: 4,
  customspacingtop: 30,
  customspacingside: 15,
  required: false,
};

export default GridItem;
