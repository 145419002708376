import MessageIcon from '@mui/icons-material/Message';
import { IconButton } from '@mui/material';
import ProviderCommentModal from 'components/ActivityMonitoring/ProviderCommentModal';
import Cell, { CellType } from 'components/ActivityPage/common/Cell';
import { Assignment, useUserInfo } from 'components/User/UserProvider';
import { ActivitiesActivityTypeChoices } from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import {
  Activity,
  ActivityMonitoring,
} from 'pages/ActivityPage/PrincipalTablePage';
import { useTableContext } from 'pages/ActivityPage/PrincipalTablePage/context/TableContextProvider';
import { useValueContext } from 'pages/ActivityPage/PrincipalTablePage/context/ValueContextProvider';
import InputCell, {
  TimeMaterialType,
} from 'pages/ActivityPage/PrincipalTablePage/InputCell';
import {
  dotToComa,
  inputDataFormat,
  isEditableCellV2,
  roundNumber,
} from 'pages/ActivityPage/utils';
import React, { PropsWithoutRef, useState } from 'react';

export interface DataCellsProps {
  activityMonitoring: Array<ActivityMonitoring>;
  activity: Activity;
  timeAndMaterialType: TimeMaterialType;
  disabled?: boolean;
  isHidden?: boolean;
}

const DataCells = ({
  activityMonitoring,
  activity,
  timeAndMaterialType,
  disabled = false,
  isHidden,
}: PropsWithoutRef<DataCellsProps>) => {
  const { weekRange } = useTableContext();
  const { invalidWeeks, lockedWeeks, needToAddCommentWeeks } =
    useValueContext();
  const { employee } = useUserInfo();
  const [commentModalOpen, setCommentModalOpen] = useState<boolean>(false);
  const [providerComment, setProviderComment] = useState<string>('');

  const today = moment();
  let assignments: Array<Assignment> = [];
  if (employee?.assignments) {
    assignments = assignments.concat(
      employee.assignments.filter((assignment: Assignment) => {
        return assignment.activity && assignment.activity.id === activity.id;
      })
    );
  }
  let index = 0;

  const myRows = _.map(weekRange, (week) => {
    index += 1;
    const isExpired =
      !!activity.expirationDate &&
      moment(activity.expirationDate, 'YYYY-MM-DD') < week.start;
    const isStarted =
      !activity.startDate ||
      moment(activity.startDate, 'YYYY-MM-DD') < week.end;
    const editable = isEditableCellV2(employee, today, week.start, week.end);
    const isAssignedOnWeek =
      _.some(assignments, (assignemnt) => {
        return (
          moment(assignemnt.beginningDate, 'YYYY-MM-DD') <= week.end &&
          moment(assignemnt.expirationDate, 'YYYY-MM-DD') >= week.start
        );
      }) ||
      activity.isShared ||
      ['COMAN', 'COMEX'].includes(activity.name);
    const isDisabledCell =
      isExpired || !isStarted || !editable || !isAssignedOnWeek || disabled;
    const tooltipContent =
      isExpired || !isStarted || !isAssignedOnWeek
        ? "Vous n'êtes pas assigné sur la mission cette semaine"
        : '';
    const amForThisWeek = _.find(
      activityMonitoring,
      (am: ActivityMonitoring) => {
        return moment(am.date).isBetween(week.start, week.end, undefined, '[]');
      }
    );
    const daysWorked = amForThisWeek?.timeSpent || 0;
    const comment = amForThisWeek?.comment;

    const formatedDate = week.start.format('YYYY-MM-DD');

    if (isHidden) {
      return (
        <Cell
          isInvalid={
            invalidWeeks[formatedDate] ||
            needToAddCommentWeeks.includes(formatedDate)
          }
          cellType={CellType.Old}
          key={`${activity.id}-${index}-modifiable`}
        />
      );
    }

    if (!editable) {
      const lockedAM = {
        activityId: activity.id,
        timeSpent: daysWorked,
      };
      if (!(formatedDate in lockedWeeks)) {
        lockedWeeks[formatedDate] = [];
      }
      if (daysWorked > 0) {
        const lockedAMExists = lockedWeeks[formatedDate].some(
          (am) => am.activityId === lockedAM.activityId
        );
        if (!lockedAMExists) {
          lockedWeeks[formatedDate].push(lockedAM);
        }
      }

      return (
        <Cell
          isInvalid={
            invalidWeeks[formatedDate] ||
            needToAddCommentWeeks.includes(formatedDate)
          }
          cellType={CellType.Old}
          key={`timeSpent-${activity.id}-${formatedDate}`}
        >
          {daysWorked === 0 ? '-' : dotToComa(roundNumber(daysWorked))}
          {comment && (
            <IconButton
              onClick={() => {
                setProviderComment(comment || '');
                setCommentModalOpen(true);
              }}
              size="large"
            >
              <MessageIcon color="secondary" />
            </IconButton>
          )}
        </Cell>
      );
    }
    return (
      <InputCell
        disabled={isDisabledCell}
        hatched={tooltipContent}
        idActivity={activity.id}
        dateAm={week.start}
        defaultValue={inputDataFormat(roundNumber(daysWorked))}
        formDataIndex={index}
        timeMaterialType={timeAndMaterialType}
        key={`input-${activity.id}-${index}`}
        isVacation={activity.type === ActivitiesActivityTypeChoices.Vct}
        tabIndex={isDisabledCell || tooltipContent ? -1 : index + 1}
        isInvalid={invalidWeeks[formatedDate]}
        comment={comment}
      />
    );
  });
  return (
    <>
      {myRows}
      <ProviderCommentModal
        open={commentModalOpen}
        setOpen={setCommentModalOpen}
        comment={providerComment}
      />
    </>
  );
};
DataCells.defaultProps = {
  billingTotal: 0,
};

export default DataCells;
