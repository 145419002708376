import { Typography } from '@mui/material';
import { isTrimesterStart } from 'components/commons/Tables/Header/ArrowsUpdateTrimesterTableHeader';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import CollapsePolyTableCell from 'components/MUIOverload/PolyTableCell/CollapsePolyTableCell';
import ChildCollapsePolyTableRow from 'components/MUIOverload/PolyTableRow/ChildCollapsePolyTableRow';
import TransitionPolyTableRow from 'components/MUIOverload/PolyTableRow/TransitionPolyTableRow';
import { ClientType } from 'components/Reporting/Profitability/GlobalView/ProfitabilityTableBody/ProfitabilityTableBody';
import ProfitabilityMissionRow from 'components/Reporting/Profitability/GlobalView/ProfitabilityTableBody/Rows/ProfitabilityMissionRow';
import styles from 'components/Revenue/Estimated/EstimatedRevenueTables/EstimatedRevenueGlobalTable/styles/TableCell.module.scss';
import OutOfRangeTableCell from 'components/Revenue/Tables/RevenueTableCells/OutOfRangeTableCell';
import { isInRange } from 'components/Revenue/Tables/util';
import {
  BillingClientNode,
  ClientProfitNode,
  PublicHoliday,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment/moment';
import React, { useState } from 'react';
import { TransitionStatus } from 'react-transition-group/Transition';
import { getMissionLastActiveMonth } from 'utils';

import ProfitabilityDataComingCell from './Cells/ProfitabilityDataComingCell';
import { CallProfitabilityRenderValues, getYearToDateProfit } from './utils';

function getRowValues(
  month: moment.Moment,
  client: ClientType,
  isTrimesterView: boolean | undefined,
  polyconseilClient: BillingClientNode,
  nonInitExternalMissionsClient: BillingClientNode
) {
  if (isTrimesterView && !isTrimesterStart(month)) {
    return (
      <React.Fragment
        key={`profitability-${client.corporateName}-${month.format('MMMM')}`}
      />
    );
  }

  const clientProfit = _.find(client.clientProfits, (profit) =>
    month.isSame(
      moment(isTrimesterView ? profit.trimester : profit.month),
      'month'
    )
  );

  if (clientProfit) {
    return (
      <CallProfitabilityRenderValues
        key={`profitability-${client.corporateName}-${month.format('MMMM')}`}
        month={month}
        profit={clientProfit}
        isTrimesterView={isTrimesterView}
      />
    );
  }

  const hasActiveActivity = containsActiveActivity(month, client);
  if (!hasActiveActivity) {
    return (
      <OutOfRangeTableCell
        key={`profitability-${client.corporateName}-${month.format('MMMM')}`}
        title="Le client n’a pas de mission active sur ce mois"
        colSpan={5}
      />
    );
  }

  const isPolyconseilClient = (client: ClientType) => {
    return client.id === polyconseilClient.id;
  };
  const isNonInitExternalMissionsClient = (client: ClientType) => {
    return client.id === nonInitExternalMissionsClient.id;
  };

  const hasActivePurchaseOrder = containsActivePurchaseOrder(month, client);
  if (
    !hasActivePurchaseOrder &&
    !isPolyconseilClient(client) &&
    !isNonInitExternalMissionsClient(client)
  ) {
    return (
      <OutOfRangeTableCell
        key={`profitability-${client.corporateName}-${month.format('MMMM')}`}
        title="Le client n’a pas de BDC actif sur ce mois"
        colSpan={5}
      />
    );
  }

  return (
    <ProfitabilityDataComingCell
      key={`profitability-${client.corporateName}-${month.format('MMMM')}`}
      reason={
        isTrimesterView ||
        isPolyconseilClient(client) ||
        isNonInitExternalMissionsClient(client)
          ? undefined
          : 'CA non validé'
      }
      colSpan={5}
    />
  );
}
interface ProfitabilityClientRowProps {
  status: TransitionStatus;
  client: ClientType;
  isTrimesterView?: boolean;
  polyconseilClient: BillingClientNode;
  nonInitExternalMissionsClient: BillingClientNode;
  publicHolidays?: PublicHoliday[];
  currentYear: moment.Moment;
  yearToDateMonthWorkDays?: number;
}

export default function ProfitabilityClientRow({
  status,
  client,
  isTrimesterView,
  polyconseilClient,
  nonInitExternalMissionsClient,
  publicHolidays,
  currentYear,
  yearToDateMonthWorkDays,
}: ProfitabilityClientRowProps) {
  const [openCollapse, setOpenCollapse] = useState(false);
  const { displayedMonths } = useTableHeaderContext();
  const hasActiveActivityThisMonth = displayedMonths.some((month) =>
    containsActiveActivity(month, client)
  );
  const yearToDateClientProfit = !isTrimesterView
    ? (getYearToDateProfit(
        client.clientProfits,
        displayedMonths,
        publicHolidays as PublicHoliday[]
      ) as ClientProfitNode)
    : undefined;
  return (
    <>
      <ChildCollapsePolyTableRow status={status}>
        <CollapsePolyTableCell
          className={styles.tableCell}
          open={openCollapse}
          setOpen={setOpenCollapse}
          hasProfits={!isTrimesterView || hasActiveActivityThisMonth}
        >
          <Typography variant={'body2'} sx={{ width: '167px' }}>
            {client.corporateName}
          </Typography>
        </CollapsePolyTableCell>
        {_.map(displayedMonths, (month) => {
          return getRowValues(
            month,
            client,
            isTrimesterView,
            polyconseilClient,
            nonInitExternalMissionsClient
          );
        })}
        {yearToDateClientProfit && (
          <CallProfitabilityRenderValues
            key={`profitability-year-to-date-${
              client.corporateName
            }-${yearToDateClientProfit?.month.format('MMMM')}`}
            month={yearToDateClientProfit?.month}
            profit={yearToDateClientProfit}
            isTrimesterView={isTrimesterView}
            isEtpCumulated={true}
            yearToDateMonthWorkDays={yearToDateMonthWorkDays}
          />
        )}
      </ChildCollapsePolyTableRow>
      {_.map(client.activities, (activity) => {
        return (
          <TransitionPolyTableRow
            open={openCollapse}
            key={`profitability-mission-${activity.id}`}
          >
            {(status) => {
              return (
                <ProfitabilityMissionRow
                  currentYear={currentYear}
                  status={status}
                  activity={activity}
                  isTrimesterView={isTrimesterView}
                  yearToDateMonthWorkDays={yearToDateMonthWorkDays}
                />
              );
            }}
          </TransitionPolyTableRow>
        );
      })}
    </>
  );
}

function containsActiveActivity(month: moment.Moment, client: ClientType) {
  return _.some(client.activities, (activity) =>
    isInRange(activity.startDate, getMissionLastActiveMonth(activity), month)
  );
}

function containsActivePurchaseOrder(month: moment.Moment, client: ClientType) {
  return _.some(client.activities, (activity) =>
    _.some(
      activity.billingInformation?.purchaseOrders,
      ({ periodBeginning, periodEnding }) =>
        isInRange(periodBeginning, periodEnding, month)
    )
  );
}
