import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useRequestLazyQuery } from 'hooks/useRequestLazyQuery';
import { useCallback, useEffect, useState } from 'react';

export function useRequestQuery<T>(
  config: AxiosRequestConfig,
  onSuccess: (response: AxiosResponse) => T,
  url?: string,
  isTimeStamped?: boolean
): {
  response: T | undefined;
  error: Error | undefined;
  loading: boolean;
  refetch: (newUrl?: string) => void;
} {
  const [response, setResponse] = useState<T>();
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(true);

  const fetch = useRequestLazyQuery(config, onSuccess);
  const fetchImage = useCallback(
    async (url?: string, isTimeStamped?: boolean) => {
      setLoading(true);
      const { response, error } = await fetch(url, isTimeStamped);
      setResponse(response);
      setError(error);
      setLoading(false);
    },
    [fetch]
  );

  useEffect(() => {
    void fetchImage(url, isTimeStamped);
  }, [fetchImage, url, isTimeStamped]);

  const refetch = (newUrl?: string) => {
    void fetchImage(newUrl || url);
  };

  return { response, error, loading, refetch };
}
