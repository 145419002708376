import { ColumnLabelsV2 } from 'components/commons/Tables/utils';
import moment from 'moment';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';
import { functionNotSet } from 'utils';

function builDefaultColumnLabels() {
  const defaultColumnLabels: ColumnLabelsV2[] = [];
  for (let i = 1; i <= 4; i++) {
    defaultColumnLabels.push({
      weekNumber: i,
      start: moment('2020-01-01'),
      end: moment('2020-01-01'),
      weekPart: 0,
    });
  }
  return defaultColumnLabels;
}

export interface TableContext {
  columnLabels: ColumnLabelsV2[];
  setColumnLabels: (p: ColumnLabelsV2[]) => void;
}

const TableHeaderContext = createContext<TableContext>({
  columnLabels: [],
  setColumnLabels: () => functionNotSet(),
});

export const useTableHeaderContext = () => {
  const tableContext = useContext(TableHeaderContext);
  if (!tableContext) {
    throw new Error('useContext() can only be used  inside a table');
  }
  return tableContext;
};

const TableHeaderContextProvider = (properties: PropsWithChildren<{}>) => {
  const [columnLabels, setColumnLabels] = useState<ColumnLabelsV2[]>(
    builDefaultColumnLabels()
  );
  return (
    <TableHeaderContext.Provider value={{ columnLabels, setColumnLabels }}>
      {properties.children}
    </TableHeaderContext.Provider>
  );
};

export default TableHeaderContextProvider;
