import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';

type BackdropLoadingProps = {
  open: boolean;
};

const BackdropLoading = ({ open }: BackdropLoadingProps) => {
  return (
    <Backdrop sx={{ zIndex: 1 }} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default BackdropLoading;
