import SyncIcon from '@mui/icons-material/Sync';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import PongoButton from 'components/MUIOverload/PongoButton';
import PolyTrombiMissionSearchFilter from 'components/Phonebook/PolyTrombiSearchFilters/PolyTrombiMissionSearchFilter';
import PolyTrombiRoleSearchFilter from 'components/Phonebook/PolyTrombiSearchFilters/PolyTrombiRoleSearchFilter';
import PolyTrombiStatusSearchFilter from 'components/Phonebook/PolyTrombiSearchFilters/PolyTrombiStatusSearchFilter';
import { usePolyTrombiContext } from 'contexts/Phonebook/PolyTrombiContextProvider';
import React, { useState } from 'react';

export const EMPTY_SEARCH_INPUT = '';

export default function PolyTrombiSearchFilterBar() {
  const {
    selectedMissions,
    setSelectedMissions,
    selectedStatus,
    setSelectedStatus,
    selectedOccupations,
    setSelectedOccupations,
    setShowSkeleton,
  } = usePolyTrombiContext(); // TODO: Add other filters just below
  const theme = useTheme();
  const isLargeDisplay = useMediaQuery(theme.breakpoints.up('xl'));
  const [missionFilterSearchInput, setMissionFilterSearchInput] =
    useState<string>(EMPTY_SEARCH_INPUT);

  const isAnyMissionSearched = missionFilterSearchInput !== EMPTY_SEARCH_INPUT;
  const isAnySelectedMission = selectedMissions.length > 0;
  const isAnySelectedStatus = selectedStatus.length > 0;
  const isAnySelectedOccupation = selectedOccupations.length > 0;

  const isEveryFilterInactive = !(
    isAnyMissionSearched ||
    isAnySelectedMission ||
    isAnySelectedStatus ||
    isAnySelectedOccupation
  );

  const handleFilterReset = () => {
    setMissionFilterSearchInput(EMPTY_SEARCH_INPUT);

    setSelectedMissions([]);
    setSelectedStatus([]);
    setSelectedOccupations([]);
    setShowSkeleton(true);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <PolyTrombiMissionSearchFilter
        searchInput={missionFilterSearchInput}
        setSearchInput={setMissionFilterSearchInput}
      />
      <PolyTrombiStatusSearchFilter />
      <PolyTrombiRoleSearchFilter />
      <PongoButton
        sx={{ borderRadius: 4, minWidth: '64px' }}
        variant="contained"
        buttonStyle="input"
        uppercase={false}
        size="small"
        endIcon={<SyncIcon />}
        onClick={handleFilterReset}
        autoHeight
        disabled={isEveryFilterInactive}
      >
        {isLargeDisplay && <Box sx={{ mr: 0.5 }}>Réinitialiser</Box>}
      </PongoButton>
    </>
  );
}
