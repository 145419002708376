import { GroupAdd } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import PongoButton from 'components/MUIOverload/PongoButton';
import { ActivityNode } from 'generated/graphql';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import PoFormCollaborators from './PoFormCollaborators';
import { PoFormMode } from './PurchaseOrderForm';

interface PurchaseOrderTasksFormProps {
  fieldName: string;
  subMission: ActivityNode;
  mode?: PoFormMode;
}

export default function PoFormSubMission({
  fieldName,
  subMission,
  mode,
}: PurchaseOrderTasksFormProps) {
  const { errors, register } = useFormContext();

  register(`${fieldName}.id`);
  const [includeAllCollaborators, setIncludeAllCollaborators] = useState(false);
  const [isExpanded, setIsExpanded] = useState(mode === PoFormMode.CREATE);

  useEffect(() => {
    const fieldId = fieldName.split('.')[1];
    if (errors.poSubMissions && fieldId in errors.poSubMissions) {
      setIsExpanded(true);
    }
  }, [errors.poSubMissions, fieldName]);

  return (
    <Accordion
      sx={{ width: 1 }}
      onChange={(event, expanded) => setIsExpanded(expanded)}
      expanded={isExpanded}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack direction={'row'} alignItems={'center'}>
          <Typography>{subMission.name}</Typography>
          {isExpanded && (
            <PongoButton
              variant={'contained'}
              sx={{ ml: 2 }}
              buttonStyle="secondary"
              size={'small'}
              startIcon={<GroupAdd />}
              onClick={(e) => {
                setIncludeAllCollaborators(true);
                e.stopPropagation();
              }}
            >
              Ajouter tous les collaborateurs
            </PongoButton>
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <PoFormCollaborators
            fieldName={`${fieldName}.collaborators`}
            includeAllCollaborators={includeAllCollaborators}
            setIncludeAllCollaborators={setIncludeAllCollaborators}
            mode={mode}
          />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
