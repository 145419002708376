import { ClickAwayListener } from '@mui/base';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Chip, Grid, Popper } from '@mui/material';
import { EventNode } from 'generated/graphql';
import _ from 'lodash';
import React from 'react';

import { useCarouselContext } from './CarouselContextProvider';
import { generateColorFromString } from './utils';

export interface CarouselFilterPopupProps {
  anchorElement: null | HTMLElement;
  setAnchorElement: (param: null | HTMLElement) => void;
}

interface EventTagProps {
  tag: string;
  color: string | number;
}

const CarouselFilterPopup = ({
  anchorElement,
  setAnchorElement,
}: CarouselFilterPopupProps) => {
  const { filterList, setFilterList, allEvents, setFilteredEvents } =
    useCarouselContext();

  const eventTags = _.reduce(
    allEvents,
    (tags: EventTagProps[], event: EventNode) => {
      const tag = event.tag;
      if (!tags.some((t) => t.tag === event.tag)) {
        tags.push({
          tag: tag,
          color: generateColorFromString(tag),
        });
      }
      return tags;
    },
    []
  );

  const handleChipClick = (filter: string) => {
    let newFilterList: string[] = [];

    if (_.some(filterList, (element) => element === filter)) {
      newFilterList = _.filter(filterList, (element) => filter !== element);
    } else {
      newFilterList = [...filterList, filter];
    }
    setFilterList(newFilterList);

    if (newFilterList.length === 0) {
      setFilteredEvents(allEvents);
    } else {
      setFilteredEvents(
        _.filter(allEvents, (event) =>
          _.some(newFilterList, (element) => element === event.tag)
        )
      );
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setAnchorElement(null)}>
      <Popper
        id={'simple-popper'}
        open={true}
        anchorEl={anchorElement}
        disablePortal={true}
        placement={'bottom-start'}
        style={{ zIndex: 1550 }}
      >
        <Box
          sx={{
            width: '284px',
            padding: '24px',
            backgroundColor: 'white',
            borderRadius: '2px',
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25);',
          }}
        >
          <Grid container columns={6} spacing={2}>
            {_(eventTags)
              .map(({ tag, color }) => {
                return (
                  <Grid item xs={3} key={tag}>
                    <Chip
                      label={tag}
                      variant={
                        _.includes(filterList, tag) ? 'filled' : 'outlined'
                      }
                      sx={{
                        borderColor: color,
                        backgroundColor: _.includes(filterList, tag)
                          ? color
                          : 'transparent',
                        color: _.includes(filterList, tag) ? 'white' : color,
                      }}
                      onClick={() => handleChipClick(tag)}
                    />
                  </Grid>
                );
              })
              .value()}
          </Grid>
          <Box sx={{ width: 1, display: 'flex', justifyContent: 'end', pt: 2 }}>
            <Chip
              icon={<DeleteIcon />}
              label={'Réinitialiser'}
              onClick={() => {
                setFilterList([]);
                setFilteredEvents(allEvents);
              }}
            />
          </Box>
        </Box>
      </Popper>
    </ClickAwayListener>
  );
};

export default CarouselFilterPopup;
