import { EmployeeOccupations, useUserInfo } from 'components/User/UserProvider';
import {
  EmployeesEmployeeContractTypeChoices,
  EmployeesEmployeeGradeChoices,
} from 'generated/graphql';
import ErrorPage from 'pages/ErrorPage';
import React from 'react';

interface AccessWrapperProps {
  occupations?: EmployeeOccupations[];
  grades?: EmployeesEmployeeGradeChoices[];
  restrictMissionChiefWithTmActivity?: boolean;
  restrictMissionChief: boolean;
  restrictMissionDirector: boolean;
  restrictFullTimeCollaborator: boolean;
  restrictStandardUser: boolean;
}

const AccessWrapper = ({
  occupations,
  grades,
  restrictMissionChiefWithTmActivity,
  restrictMissionChief,
  restrictMissionDirector,
  restrictFullTimeCollaborator,
  restrictStandardUser,
  children,
}: React.PropsWithChildren<AccessWrapperProps>) => {
  const { employee, isSuperuser, isStandardUser } = useUserInfo();

  if (isSuperuser) {
    return <>{children}</>;
  }

  const checkOccupations =
    !occupations ||
    (employee &&
      occupations.includes(employee.occupation as EmployeeOccupations));

  const checkGrades =
    !grades || (employee && employee.grade && grades.includes(employee.grade));

  const checkManagingTmActivity = employee && employee.isTmActivityMissionChief;
  if (restrictMissionChiefWithTmActivity && checkManagingTmActivity) {
    return <>{children}</>;
  }

  const checkDirector = employee && employee.isMissionDirector;

  if (restrictMissionDirector && checkDirector) {
    return <>{children}</>;
  }

  const checkChief = employee && employee.isMissionChief;
  if (restrictMissionChief && checkChief) {
    return <>{children}</>;
  }

  if (restrictStandardUser && isStandardUser) {
    return <>{children}</>;
  }

  const checkFullTimeCollaborator =
    employee &&
    employee.contractType === EmployeesEmployeeContractTypeChoices.Cdi &&
    employee.weeklyContract === 5 &&
    isStandardUser;

  if (restrictFullTimeCollaborator && checkFullTimeCollaborator) {
    return <>{children}</>;
  }

  if (occupations && checkOccupations) {
    return <>{children}</>;
  }

  if (grades && checkGrades) {
    return <>{children}</>;
  }

  return <ErrorPage />;
};

AccessWrapper.defaultProps = {
  restrictMissionChief: false,
  restrictMissionDirector: false,
  restrictFullTimeCollaborator: false,
  restrictStandardUser: false,
};

export default AccessWrapper;
