import { Delete } from '@mui/icons-material';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import ContentCutOutlinedIcon from '@mui/icons-material/ContentCutOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import {
  Box,
  DialogActions,
  Stack,
  Step,
  StepButton,
  Stepper,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import PolyAlert from 'components/commons/PolyAlert';
import PolyAlertTitle from 'components/commons/PolyAlertTitle';
import { getStringAddress } from 'components/MissionFollowUp/DisplayAddress';
import PolyDialogActions from 'components/MUIOverload/PolyDialog/DefaultComponents/PolyDialogActions';
import PongoButton from 'components/MUIOverload/PongoButton';
import { getUrlList } from 'components/Navigation/DesktopNavbar/utils';
import {
  ActivitiesActivityBillingTypeChoices,
  ActivitiesActivityTypeChoices,
  ActivityNode,
  BillingBillingModalitiesBillingTypeChoices,
  BillingClientNode,
  BusinessClientNode,
  EmployeeNode,
  useAllActiveAndFutureComexQuery,
  useAllActiveAndFutureEmployeesQuery,
  useAllBillingClientQuery,
  useAllBusinessClientsQuery,
  useDeleteActivityMutation,
  useInitializeOrUpdateExternalActivityMutation,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import {
  InitializeButtonName,
  NextButtonName,
  PreviousButtonName,
  SaveButtonName,
} from 'poly-constants';
import { default as React, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { graphQlDateFormatter } from 'utils';

import ExternalActivityContactForm from './ExternalActivityContactForm';
import ExternalActivityInfoForm from './ExternalActivityInfoForm';
import ExternalActivityModalitiesForm from './ModalitiesForm/ExternalActivityModalitiesForm';
import ExternalActivitySubActivitiesForm from './SubActivitiesForm/ExternalActivitySubActivitiesForm';

type ModalityType = {
  billingType?: BillingBillingModalitiesBillingTypeChoices;
  billingName?: string;
  billingEmail?: string;
  billingCode?: string;
  billingUrl?: string;
  billingAddress?: string;
};

interface ExternalActivityFormProps {
  activity: ActivityNode;
  defaultActiveStep: number;
  handleCompleted: (createdActivity?: ActivityNode) => void;
  onClose: () => void;
}

export const ExternalActivityForm = ({
  activity,
  defaultActiveStep,
  handleCompleted,
  onClose,
}: ExternalActivityFormProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [billingClientsArray, setBillingClientsArray] = useState<
    BillingClientNode[]
  >([]);
  const [businessClientsArray, setBusinessClientsArray] = useState<
    BusinessClientNode[]
  >([]);
  const isTM = activity.billingType === ActivitiesActivityBillingTypeChoices.Tm;
  const isMissionInitialized = !!activity.billingInformation;
  const [activeStep, setActiveStep] = React.useState(defaultActiveStep);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  useAllBillingClientQuery({
    onCompleted: (data) => {
      if (data.allBillingClient) {
        setBillingClientsArray(data.allBillingClient as BillingClientNode[]);
      }
    },
  });

  useAllBusinessClientsQuery({
    onCompleted: (data) => {
      if (data.allBusinessClients) {
        setBusinessClientsArray(
          data.allBusinessClients as BusinessClientNode[]
        );
      }
    },
  });

  const [employees, setEmployees] = useState<EmployeeNode[]>([]);
  useAllActiveAndFutureEmployeesQuery({
    onCompleted: (data) => {
      if (data?.allActiveAndFutureEmployees) {
        setEmployees(data.allActiveAndFutureEmployees as EmployeeNode[]);
      }
    },
  });

  const [allComexEmployees, setAllComexEmployees] = useState<EmployeeNode[]>(
    []
  );
  useAllActiveAndFutureComexQuery({
    onCompleted: (data) => {
      if (data?.allActiveAndFutureComex) {
        setAllComexEmployees(data.allActiveAndFutureComex as EmployeeNode[]);
      }
    },
  });

  const billingModalitiesDefault: ModalityType[] = [];

  activity.billingInformation?.billingModalities?.forEach((modality) => {
    billingModalitiesDefault.push({ ...modality });
  });

  if (billingModalitiesDefault.length === 0) {
    billingModalitiesDefault.push({
      billingType: BillingBillingModalitiesBillingTypeChoices.Email,
      billingEmail: '',
      billingName: '',
    });
  }

  const subActivitiesDefault = _.map(activity.subActivities, (subActivity) => {
    return {
      ...subActivity,
      period: [subActivity.startDate, subActivity.expirationDate],
    };
  });

  const defaultValues = {
    name: activity.name,
    type: ActivitiesActivityTypeChoices.Ext,
    billingType: activity.billingType,
    isTm: isTM,
    client: activity.client,
    selectedBillingClient:
      activity.billingInformation?.billingClient ||
      (undefined as unknown as BillingClientNode),
    selectedBusinessClient:
      activity.businessClient || (undefined as unknown as BusinessClientNode),
    refMarket: activity.billingInformation?.refMarket || '',
    selectedDirector:
      activity.director || (undefined as unknown as EmployeeNode),
    selectedChiefs: activity.chiefs || ([] as EmployeeNode[]),
    selectedLeadDevs: activity.leadDevs || ([] as EmployeeNode[]),
    startDate: activity.startDate || moment().format('YYYY-MM-DD'),
    expirationDate: activity.expirationDate || moment().format('YYYY-MM-DD'),
    description: activity.description || '',
    techStack: activity.techStack || '',
    contactName: activity.billingInformation?.contactName || '',
    contactEmail: activity.billingInformation?.contactEmail || '',
    contactPhone: activity.billingInformation?.contactPhone || '',
    accountantName: activity.billingInformation?.accountantName || '',
    accountantEmail: activity.billingInformation?.accountantEmail || '',
    accountantPhone: activity.billingInformation?.accountantPhone || '',
    billingModalities: billingModalitiesDefault,
    subActivities: subActivitiesDefault || [],
  };

  const form = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: defaultValues,
    criteriaMode: 'firstError',
  });

  const { errors, handleSubmit, control, trigger } = form;

  const initializedOrUpdatedStr = isMissionInitialized
    ? 'mise à jour'
    : 'initialisée';

  const [
    createOrUpdateExternalActivityMutation,
    { loading: updateOrCreateIsLoading },
  ] = useInitializeOrUpdateExternalActivityMutation({
    onError: (error) => {
      if (error.message === 'Invalid format for phone number') {
        enqueueSnackbar(`Mauvais format de numéro de téléphone`, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      }
    },
    onCompleted: (data) => {
      const createdActivity = data.initializeOrUpdateExternalActivity
        ?.activity as ActivityNode;
      enqueueSnackbar(
        `La mission « ${createdActivity.name} » a bien été ${initializedOrUpdatedStr}`,
        {
          variant: 'success',
        }
      );
      handleCompleted?.(createdActivity);
    },
  });

  function isFormRight() {
    return _.isEmpty(errors);
  }

  const onSubmit = async (submitValues: typeof defaultValues) => {
    let modalities = [];
    let subActivities = [];

    modalities = submitValues.billingModalities;
    modalities.forEach((modality: ModalityType) => {
      modality.billingAddress = getStringAddress(
        submitValues.selectedBillingClient
      );
    });

    subActivities = _.map(submitValues.subActivities, (subActivity) => {
      return {
        name: subActivity.name,
        id: subActivity.id,
        startDate: graphQlDateFormatter(
          subActivity.period?.[0] ||
            moment(submitValues.startDate).format('YYYY-MM-DD')
        ),
        expirationDate: graphQlDateFormatter(
          subActivity.period?.[1] ||
            moment(submitValues.expirationDate).format('YYYY-MM-DD')
        ),
      };
    });

    await createOrUpdateExternalActivityMutation({
      variables: {
        activityId: activity.id,
        name: submitValues.name || '',
        type: ActivitiesActivityTypeChoices.Ext,
        billingType: activity.billingType || '',
        client: submitValues.selectedBusinessClient?.name || '',
        billingClientId: submitValues.selectedBillingClient?.id || '',
        businessClientId: submitValues.selectedBusinessClient?.id || '',
        refMarket: submitValues.refMarket || '',
        directorId: submitValues.selectedDirector?.id || '',
        chiefsIds: _.flatMap(submitValues.selectedChiefs, 'id') || '',
        leadDevsIds: _.flatMap(submitValues.selectedLeadDevs, 'id') || '',
        startDate: moment(submitValues.startDate).format('YYYY-MM-DD'),
        expirationDate: moment(submitValues.expirationDate).format(
          'YYYY-MM-DD'
        ),
        description: submitValues.description || '',
        techStack: submitValues.techStack || '',
        contactName: submitValues.contactName || '',
        contactEmail: submitValues.contactEmail || '',
        contactPhone: submitValues.contactPhone || '',
        accountantName: submitValues.accountantName || '',
        accountantEmail: submitValues.accountantEmail || '',
        accountantPhone: submitValues.accountantPhone || '',
        billingModalities: modalities,
        subActivities: subActivities,
      },
    });
  };

  const steps = [
    {
      label: 'Informations',
      display: true,
      icon: <InfoOutlinedIcon />,
      validationFields: [
        'name',
        'selectedBusinessClient',
        'selectedDirector',
        'selectedChiefs',
        'startDate',
        'expirationDate',
      ],
      additionalValidation: true,
      form: (
        <ExternalActivityInfoForm
          employees={employees}
          allComexEmployees={allComexEmployees}
          businessClients={businessClientsArray}
        />
      ),
    },
    {
      label: 'Contact',
      display: true,
      icon: <CallOutlinedIcon />,
      validationFields: [
        'accountantName',
        'accountantEmail',
        'contactName',
        'contactEmail',
      ],
      additionalValidation: true,
      form: <ExternalActivityContactForm />,
    },
    {
      label: 'Modalités de facturation',
      display: true,
      icon: <SendOutlinedIcon />,
      validationRegEx: /^billingModalities/,
      validationFields: ['selectedBillingClient'],
      additionalValidation: true,
      form: (
        <ExternalActivityModalitiesForm billingClients={billingClientsArray} />
      ),
    },
    {
      label: 'Découpage',
      display: isTM,
      icon: <ContentCutOutlinedIcon />,
      form: <ExternalActivitySubActivitiesForm />,
    },
  ];

  const filteredSteps = steps.filter((step) => step.display);

  const totalSteps = () => {
    return filteredSteps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveStep(newValue);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const validateStep = async (): Promise<boolean> => {
    const currentFieldsRefKeys = Object.keys(control.fieldsRef.current);
    const validationFields = filteredSteps[activeStep].validationFields;
    const validationRegEx = filteredSteps[activeStep].validationRegEx;
    const additionalValidation =
      !!filteredSteps[activeStep].additionalValidation;
    let result = true;

    if (Array.isArray(validationFields)) {
      result = await trigger(filteredSteps[activeStep].validationFields);
    }
    if (validationRegEx) {
      for (const fieldRefKey of currentFieldsRefKeys) {
        if (validationRegEx.test(fieldRefKey)) {
          const currentValidation = await trigger(fieldRefKey);
          if (!currentValidation) {
            result = false;
          }
        }
      }
    }
    return result && additionalValidation;
  };

  const handleNext = async () => {
    if (filteredSteps.length > 1 && filteredSteps.length > activeStep + 1) {
      if (await validateStep()) {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const navTabs = (
    <>
      {isMissionInitialized ? (
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            mb: 2,
            mt: 1,
            minWidth: 852,
          }}
        >
          <Tabs centered value={activeStep} onChange={handleTabChange}>
            {filteredSteps.map((step, index) => (
              <Tab
                key={step.label}
                icon={step.icon}
                iconPosition="start"
                label={step.label}
                aria-label={`nav-tab-${index}`}
                sx={{ display: step.display ? 'inherit' : 'none' }}
              />
            ))}
          </Tabs>
        </Box>
      ) : (
        <Box sx={{ width: '100%', mt: 5, mb: 2, minWidth: 852 }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {filteredSteps.map((step, index) => (
              <Step
                key={step.label}
                completed={completed[index]}
                sx={{ display: step.display ? 'inherit' : 'none' }}
              >
                <StepButton
                  aria-label={`stepper-tab-${index}`}
                  color="inherit"
                  onClick={handleStep(index)}
                >
                  <Typography
                    textTransform={'uppercase'}
                    variant="bodyS"
                    color={index === activeStep ? 'primary' : 'inherit'}
                  >
                    {step.label}
                  </Typography>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>
      )}
    </>
  );

  const history = useHistory();
  const { activities } = getUrlList();

  const [deleteActivityMutation] = useDeleteActivityMutation({
    onCompleted: () => {
      enqueueSnackbar(`La mission « ${activity.name} » a été supprimée`, {
        variant: 'success',
      });
      onClose();
      history.push(activities);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });
  const handleDelete = () => {
    deleteActivityMutation({
      variables: {
        activityId: activity.id,
      },
    });
  };

  const displayDeleteButton = activeStep === 0;

  const actionButtons = (
    <Stack
      flexDirection="row"
      justifyContent={displayDeleteButton ? 'space-between' : 'end'}
      display="flex"
      mx={1}
    >
      <DialogActions sx={{ display: displayDeleteButton ? 'inherit' : 'none' }}>
        <PongoButton
          onClick={handleDelete}
          variant="contained"
          color="error"
          startIcon={<Delete />}
        >
          Supprimer la mission
        </PongoButton>
      </DialogActions>
      {isMissionInitialized ? (
        <PolyDialogActions
          key={`actions-${activeStep}`}
          primaryButtonName={SaveButtonName}
          handleSecondary={onClose}
          primaryButtonType={'submit'}
          isPrimaryButtonDisabled={!isFormRight() || updateOrCreateIsLoading}
        />
      ) : (
        <PolyDialogActions
          key={`actions-${activeStep}`}
          primaryButtonName={
            isLastStep() ? InitializeButtonName : NextButtonName
          }
          secondaryButtonName={PreviousButtonName}
          handlePrimary={handleNext}
          handleSecondary={handleBack}
          primaryButtonType={isLastStep() ? 'submit' : 'button'}
          isPrimaryButtonDisabled={
            isLastStep()
              ? !isFormRight() || updateOrCreateIsLoading
              : !filteredSteps[activeStep].additionalValidation
          }
          isSecondaryButtonDisabled={activeStep === 0}
        />
      )}
    </Stack>
  );

  const alertMessage = isMissionInitialized
    ? `Si vous modifiez ces données, les changements affecteront toutes les factures
       de cette mission.`
    : `Les informations suivantes sont indispensables à la création de bons de
        commande, prestations ou factures.`;

  return (
    <FormProvider {...form}>
      <PolyAlert severity="warning" variant="outlined" sx={{ mb: 2, mx: 2 }}>
        <PolyAlertTitle color="warning">Attention ! </PolyAlertTitle>
        {alertMessage}
      </PolyAlert>
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        {navTabs}
        {filteredSteps.map((step, index) => (
          <Box
            key={`external-mission-form-step-${index}`}
            sx={{ display: activeStep === index ? 'inherit' : 'none' }}
          >
            {step.form}
          </Box>
        ))}
        {actionButtons}
      </form>
    </FormProvider>
  );
};
