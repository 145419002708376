import { Box, TableCell, Typography } from '@mui/material';
import clsx from 'clsx';
import collapseStyles from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/styles/CollapseStyles.module.scss';
import styles from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/styles/TableCell.module.scss';
import React from 'react';

export const TypeCell = {
  primary: 0,
  secondary: 1,
  italic: 2,
};

interface RevenueCellDisplayValueProperties {
  value: string;
  isCollapsable?: boolean;
  customAdornment?: JSX.Element;
  typeOfCell?: number;
}

export default function RevenueCellDisplayValue({
  value,
  isCollapsable = false,
  customAdornment,
  typeOfCell,
}: RevenueCellDisplayValueProperties) {
  return (
    <TableCell
      className={clsx(
        isCollapsable ? collapseStyles.collapseCell : styles.regularTableCell,
        styles.oldMonthCell
      )}
    >
      <Box
        className={clsx(styles.cellsContent, {
          [styles.black]: typeOfCell === TypeCell.primary,
          [styles.italic]: typeOfCell === TypeCell.italic,
        })}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Typography style={{ fontSize: '14px' }}>{value}</Typography>
        {customAdornment && (
          <Box className={styles.customAdornment}>{customAdornment}</Box>
        )}
      </Box>
    </TableCell>
  );
}
