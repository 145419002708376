import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ExceptionalActivitiesDotButton from 'components/ExceptionalActivities/ExceptionalActivitiesDotButton';
import stylesEdit from 'components/ExceptionalActivities/ExceptionalActivitiesDotButton/styles/ButtonEdit.module.scss';
import stylesSee from 'components/ExceptionalActivities/ExceptionalActivitiesDotButton/styles/ButtonSee.module.scss';
import stylesValidate from 'components/ExceptionalActivities/ExceptionalActivitiesDotButton/styles/ButtonValidate.module.scss';
import { DotOption } from 'components/ExceptionalActivities/ExceptionalActivitiesDotButton/utils';
import DateCell from 'components/MUIOverload/PolyDataGrid/components/DateCell';
import DurationCell from 'components/MUIOverload/PolyDataGrid/components/DurationCell';
import ExceptionalActivitiesStatus from 'components/MUIOverload/PolyDataGrid/components/ExceptionalActivitiesStatus/ExceptionalActivitiesStatus';
import StringCell from 'components/MUIOverload/PolyDataGrid/components/StringCell';
import UndefinedCell from 'components/MUIOverload/PolyDataGrid/components/UndefinedCell/UndefinedCell';
import { PolyDataGridColumn } from 'components/MUIOverload/PolyDataGrid/PolyDataGridHead';
import {
  OvertimeRequestsQuery,
  useOvertimeRequestsQuery,
} from 'generated/graphql';
import { useSnackbar } from 'notistack';
import { ExceptionalActivitiesTitles } from 'poly-constants';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { displayEmployee } from 'utils';

import { isUndefined } from '../utils';
import ExceptionalActivitiesRequestPanel from './ExceptionalActivitiesRequestPanel';

const body =
  "Vous visualisez ici une déclaration d'activité exceptionnelle d'un collaborateur.\n" +
  "Vous pouvez en confirmer la bonne exécution, la modifier ou l'annuler si elle n’a pas eu lieu.";

export type AuthorizationDataType = DeepExtractTypeSkipArrays<
  OvertimeRequestsQuery,
  ['overtimeRequests']
>;

export interface RequestLookupState {
  query: AuthorizationDataType;
  action: 'see' | 'modify';
  asManager: boolean;
}

const ExceptionalActivitiesRequestPanelManager = () => {
  const history = useHistory();

  const handleEdit = (data: AuthorizationDataType) => {
    const state: RequestLookupState = {
      action: 'modify',
      query: data,
      asManager: true,
    };
    history.push('/request-lookup', state);
  };

  const handleValidate = (data: AuthorizationDataType) => {
    const state: RequestLookupState = {
      action: 'see',
      query: data,
      asManager: true,
    };
    history.push('/request-lookup', state);
  };

  const handleSee = (data: AuthorizationDataType) => {
    const state: RequestLookupState = {
      action: 'see',
      query: data,
      asManager: true,
    };
    history.push('/request-lookup', state);
  };
  const columns: PolyDataGridColumn<AuthorizationDataType>[] = [
    {
      headerName: 'Date demande',
      isSortable: false,
      width: '90px',
      render: (data) => DateCell({ date: data.createdAt }),
    },
    {
      headerName: 'Jour travaillé',
      isSortable: false,
      width: '90px',
      render: (data) => DateCell({ date: data.overtimeDateAt }),
    },
    {
      headerName: 'Collaborateur',
      isSortable: false,
      width: '120px',
      render: (data) =>
        StringCell({
          data: displayEmployee(
            data.authorization.employee.firstName,
            data.authorization.employee.lastName
          ),
        }),
    },
    {
      headerName: 'Mission',
      isSortable: false,
      width: '200px',
      render: (data) => StringCell({ data: data.authorization.activity.name }),
    },
    {
      headerName: 'Durée',
      isSortable: false,
      width: '40px',
      render: (data) => DurationCell({ duration: data.duration }),
    },
    {
      headerName: 'Tps de récup',
      isSortable: false,
      width: '80px',
      render: (data) =>
        UndefinedCell({
          isUndefined: isUndefined(data.status),
          render: () => DurationCell({ duration: data.recoveryRemaining }),
        }),
    },
    {
      headerName: 'Date limite',
      isSortable: false,
      render: (data) =>
        UndefinedCell({
          isUndefined: isUndefined(data.status),
          render: () => DateCell({ date: data.expireAt }),
        }),
    },
    {
      headerName: 'Statut',
      isSortable: false,
      render: (data) => ExceptionalActivitiesStatus(true, data.status),
    },
    {
      headerName: '',
      isSortable: false,
      render: (data) =>
        ExceptionalActivitiesDotButton({
          data: data,
          options:
            data.status === 'Requested' ? toConfirmDotOptions : otherDotOptions,
        }),
    },
  ];

  const toConfirmDotOptions: DotOption<AuthorizationDataType>[] = [
    {
      name: 'Modifier',
      handle: handleEdit,
      Icon: EditIcon,
      styles: stylesEdit,
    },
    {
      name: 'Valider ou Refuser',
      handle: handleValidate,
      Icon: CheckCircleOutlinedIcon,
      styles: stylesValidate,
    },
  ];

  const otherDotOptions: DotOption<AuthorizationDataType>[] = [
    {
      name: 'Voir',
      handle: handleSee,
      Icon: VisibilityOutlinedIcon,
      styles: stylesSee,
    },
  ];

  const { enqueueSnackbar } = useSnackbar();
  const { data, loading } = useOvertimeRequestsQuery({
    variables: { asManager: true },
    onError: () => {
      enqueueSnackbar('Problème de récuperation des données', {
        variant: 'error',
      });
    },
  });

  return (
    <ExceptionalActivitiesRequestPanel
      title={ExceptionalActivitiesTitles.declarationManager}
      body={body}
      columns={columns}
      asManager={true}
      overtimeRequestsData={data}
      loadingOvertimeRequests={loading}
    />
  );
};
export default ExceptionalActivitiesRequestPanelManager;
