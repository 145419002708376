import {
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import PolyDialog from 'components/MUIOverload/PolyDialog';
import PolyFooter from 'components/MUIOverload/PolyFooter';
import PongoButton from 'components/MUIOverload/PongoButton';
import { useTableContext } from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/context/TableContextProvider';
import TotalTableCell from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/RevenueTableCells/TotalTableCell';
import ValidationModalPoRow from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/ValidationButton/ValidationModalPoRow';
import _ from 'lodash';
import { Moment } from 'moment';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import revenueTableStyles from '../styles/TableCell.module.scss';

interface ValidationModalProps {
  open: boolean;
  closeModal?: () => void;
  onSuccess?: () => void;
  month: Moment;
}

const ValidationModal = ({
  open,
  closeModal,
  onSuccess,
  month,
}: ValidationModalProps) => {
  const { purchaseOrders, validateMonth } = useTableContext();
  const { errors } = useFormContext();
  const [isValidating, setIsValidating] = useState(false);

  const handleValidateMonth = async () => {
    setIsValidating(true);
    await validateMonth(month);
    if (closeModal) {
      closeModal();
    }
    if (onSuccess) {
      onSuccess();
    }
    setIsValidating(false);
  };

  return (
    <PolyDialog
      fullWidth={true}
      maxWidth={'sm'}
      open={open}
      onClose={closeModal}
      disableEnforceFocus={true}
    >
      <DialogContent>
        <Typography
          sx={{ mb: 1, color: 'primary.main' }}
          variant="h3Bold"
          component="h3"
        >
          Validation du mois de {month.format('MMMM')}
        </Typography>
        <div>Êtes-vous sûr(e) de vouloir valider les données de ce mois ?</div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell
                align="center"
                variant="head"
                key={`header-${month.format('MMMM')}`}
              >
                {month.format('MMMM')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(purchaseOrders, (po) => {
              return (
                <ValidationModalPoRow
                  month={month}
                  po={po}
                  key={`validate-po-row-${po.id}`}
                />
              );
            })}
            <TableRow key={'total-valid'} className={revenueTableStyles.poRow}>
              <TableCell
                className={clsx(
                  revenueTableStyles.regularTableCell,
                  revenueTableStyles.boldTotal
                )}
              >
                CA Réalisé
              </TableCell>
              <TotalTableCell month={month} />
            </TableRow>
          </TableBody>
        </Table>
        <PolyFooter>
          <PongoButton onClick={closeModal}>Annuler</PongoButton>
          <PongoButton
            variant={'contained'}
            color={'primary'}
            disabled={isValidating || !_.isEmpty(errors)}
            onClick={handleValidateMonth}
          >
            Valider
          </PongoButton>
        </PolyFooter>
      </DialogContent>
    </PolyDialog>
  );
};

export default ValidationModal;
