import ExceptionalActivitiesDotButton from 'components/ExceptionalActivities/ExceptionalActivitiesDotButton';
import { createDeleteAndEditOptions } from 'components/ExceptionalActivities/ExceptionalActivitiesDotButton/utils';
import DateCell from 'components/MUIOverload/PolyDataGrid/components/DateCell';
import DateRangeCell from 'components/MUIOverload/PolyDataGrid/components/DateRangeCell';
import DurationCell from 'components/MUIOverload/PolyDataGrid/components/DurationCell';
import StringCell from 'components/MUIOverload/PolyDataGrid/components/StringCell';
import { PolyDataGridColumn } from 'components/MUIOverload/PolyDataGrid/PolyDataGridHead';
import {
  OvertimeAuthorizationsQuery,
  useDeleteOvertimeAuthorizationMutation,
  useOvertimeAuthorizationsLazyQuery,
} from 'generated/graphql';
import { useSnackbar } from 'notistack';
import { ExceptionalActivitiesTitles } from 'poly-constants';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { displayEmployee } from 'utils';

import { OvertimeAuthorization, OvertimeAuthorizationData } from '../types';
import { computeTotalDuration } from '../utils';
import ExceptionalActivitiesAuthorizationsPanel from './ExceptionalActivitiesAuthorizationsPanel';

const content =
  "Vous visualisez ici l'historique des autorisations des activités exceptionnelles de vos collaborateurs subordonnés. Vous pouvez les modifier ou les supprimer tant qu’une demande n’a pas été effectuée.";
const getColumns = (
  handleDelete: (data: OvertimeAuthorizationData) => Promise<void>,
  handleEdit: (data: OvertimeAuthorizationData) => void
) => {
  const columns: PolyDataGridColumn<
    DeepExtractTypeSkipArrays<
      OvertimeAuthorizationsQuery,
      ['overtimeAuthorizations']
    >
  >[] = [
    {
      headerName: 'Date',
      isSortable: false,
      render: (data) => DateCell({ date: data.createdAt }),
    },
    {
      headerName: 'Collaborateur',
      isSortable: false,
      width: '110px',
      render: (data) =>
        StringCell({
          data: displayEmployee(
            data.employee.firstName,
            data.employee.lastName
          ),
        }),
    },
    {
      headerName: 'Mission',
      isSortable: false,
      width: '340px',
      render: (data) => StringCell({ data: data.activity.name }),
    },
    {
      headerName: 'Période',
      isSortable: false,
      render: (data) =>
        DateRangeCell({ startDate: data.periodStart, endDate: data.periodEnd }),
    },
    {
      headerName: 'Demandes',
      isSortable: false,
      render: (data) => DurationCell({ duration: computeTotalDuration(data) }),
    },
    {
      headerName: '',
      isSortable: false,
      render: (data) =>
        ExceptionalActivitiesDotButton({
          data: data,
          options: createDeleteAndEditOptions(handleDelete, handleEdit),
        }),
    },
  ];
  return columns;
};

const ExceptionalActivitiesAuthorizationsGrantedPanel = () => {
  const [loading, setLoading] = useState(true);
  const [deleteOvertimeAuthorization] =
    useDeleteOvertimeAuthorizationMutation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const handleEdit = (data: OvertimeAuthorizationData) => {
    history.replace(
      '/mission-followup/activity/exceptional-activities/update_overtime_authorization',
      {
        authorizationData: data,
      }
    );
  };
  const fetchData = () => {
    try {
      fetchOvertimeAuthorization({
        variables: { asManager: true },
      });
    } catch {
      enqueueSnackbar('Impossible de recupérer les données', {
        variant: 'error',
      });
    }
  };
  const handleDelete = async (data: OvertimeAuthorizationData) => {
    try {
      await deleteOvertimeAuthorization({
        variables: {
          objectId: data.id,
        },
      });
    } catch {
      enqueueSnackbar(
        'Impossible de supprimer les données : une demande a déjà été effectuée sur cette autorisation',
        {
          variant: 'error',
        }
      );
    } finally {
      // TODO: not ideal but redirect doesn't work nicely nor a state to force rerender
      // eslint-disable-next-line
      setOvertimeData(undefined);
      fetchData();
    }
  };
  const [overtimeData, setOvertimeData] = useState<OvertimeAuthorization>();
  const [fetchOvertimeAuthorization] = useOvertimeAuthorizationsLazyQuery({
    onCompleted: (data) => {
      setOvertimeData(data);
      setLoading(false);
    },
    onError: () => {
      enqueueSnackbar('Problème de récuperation des données', {
        variant: 'error',
      });
    },
  });
  useEffect(() => {
    setLoading(true);
    fetchOvertimeAuthorization({ variables: { asManager: true } });
  }, [fetchOvertimeAuthorization]);
  return (
    <ExceptionalActivitiesAuthorizationsPanel
      title={ExceptionalActivitiesTitles.authorizationGranted}
      body={content}
      loading={loading}
      columns={getColumns(handleDelete, handleEdit)}
      asManager={true}
      overtimeAuthorizationsData={overtimeData}
    />
  );
};

export default ExceptionalActivitiesAuthorizationsGrantedPanel;
