import NoResultIcon from 'components/commons/NoResultIcon/NoResultIcon';
import { usePolyTrombiContext } from 'contexts/Phonebook/PolyTrombiContextProvider';
import _ from 'lodash';
import React, { useEffect } from 'react';

import EmployeeTable from './EmployeeTable';
import MissionEmployeeList from './missions/MissionEmployeeList';
import EmployeeTableSkeleton from './Skeleton/EmployeeTableSkeleton';

export default function EmployeeList() {
  const {
    displayedEmployees,
    selectedMissions,
    showSkeleton,
    setShowSkeleton,
    isLoading,
  } = usePolyTrombiContext();

  useEffect(() => {
    if (!showSkeleton) {
      return;
    }
    const timer = setTimeout(() => {
      setShowSkeleton(false);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [showSkeleton, setShowSkeleton]);

  if (showSkeleton || isLoading) {
    return <EmployeeTableSkeleton />;
  } else if (selectedMissions.length > 0) {
    return (
      <>
        {_.map(selectedMissions, (selectedMission) => {
          return (
            <MissionEmployeeList
              mission={selectedMission}
              key={`activity-${selectedMission.id}`}
            />
          );
        })}
      </>
    );
  } else if (displayedEmployees.length > 0) {
    return <EmployeeTable employees={displayedEmployees} />;
  } else {
    return <NoResultIcon />;
  }
}
