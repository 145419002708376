import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'download',
  initialState: {
    waitingDownload: true as Boolean,
    numberOfPollsSinceFirstEmpty: 0,
  },
  reducers: {
    setWaitingDownload: (state, action: PayloadAction<Boolean>) => {
      state.waitingDownload = action.payload;
    },
    setNumberOfPollsSinceFirstEmpty: (state, action: PayloadAction<number>) => {
      state.numberOfPollsSinceFirstEmpty = action.payload;
    },
    downloadTriggered: (state) => {
      state.waitingDownload = true;
      state.numberOfPollsSinceFirstEmpty = 0;
    },
  },
});

export default slice.reducer;
export const {
  setWaitingDownload,
  setNumberOfPollsSinceFirstEmpty,
  downloadTriggered,
} = slice.actions;
