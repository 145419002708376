import { TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import React from 'react';

import type { Options } from '.';

interface CollaboratorsSelectorProps {
  allEmployees: Options[];
  handleChange: (options: Options[]) => Options[];
}

export default function CollaboratorsSelector({
  allEmployees,
  handleChange,
}: CollaboratorsSelectorProps) {
  return (
    <Autocomplete
      ListboxProps={{
        sx: {
          maxHeight: '28vh',
        },
      }}
      multiple
      filterSelectedOptions
      options={allEmployees}
      onChange={(_event, newValue) => {
        handleChange(newValue);
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant="standard"
            label="Veuillez sélectionner les collaborateurs à qui vous souhaitez
              rendre la main."
          />
        );
      }}
    />
  );
}
