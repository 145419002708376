import { GridCellParams, GridColDef, GridToolbar } from '@mui/x-data-grid-pro';
import MuixTable from 'components/commons/MuixTable';
import { AllBusinessClientsQuery, BusinessClientNode } from 'generated/graphql';
import React, { useState } from 'react';

import BusinessClientModal from './BusinessClientModal';

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Client métier',
    type: 'string',
    minWidth: 150,
    flex: 2,
    valueGetter: (params) => params.value,
  },
];

interface BusinessClientTableProps {
  data?: AllBusinessClientsQuery;
  loading: boolean;
  refetch: () => void;
}

export default function BusinessClientTable({
  data,
  loading,
  refetch,
}: BusinessClientTableProps): JSX.Element {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedClient, setSelectedClient] = useState<BusinessClientNode>();
  const handleCellClick = (params: GridCellParams): void => {
    setOpen(true);
    setSelectedClient(params.row);
  };
  const closeModal = (): void => {
    setOpen(false);
  };
  return (
    <>
      <MuixTable
        rows={data?.allBusinessClients || []}
        columns={columns}
        loading={loading}
        rowSelection={false}
        pageSizeOptions={[10, 15, 25, 50]}
        sx={{
          mt: 4,
          '& .MuiDataGrid-cell:hover': {
            cursor: 'pointer',
          },
        }}
        pagination
        autoHeight
        ignoreDiacritics
        density="compact"
        onCellClick={handleCellClick}
        initialState={{
          preferencePanel: { open: false },
          pagination: {
            paginationModel: { pageSize: 50, page: 0 },
          },
        }}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
      {open && (
        <BusinessClientModal
          open={open}
          close={closeModal}
          onSuccess={refetch}
          businessClient={selectedClient}
        />
      )}
    </>
  );
}
