import DatePicker from 'components/commons/DatePicker';
import moment from 'moment';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import styles from './styles/FieldDate.module.scss';

interface FieldDateProps {
  expireAt: string;
}
const FieldDate = ({ expireAt }: FieldDateProps) => {
  const { control } = useFormContext();
  return (
    <div className={styles.root}>
      <div className={styles.text}>Date de récupération</div>
      <div className={styles.field}>
        <Controller
          render={({ onChange, value }) => (
            <DatePicker
              onChange={onChange}
              maxDate={moment(expireAt).toDate()}
              value={value}
            />
          )}
          name={'date'}
          defaultValue={new Date()}
          control={control}
        />
      </div>
    </div>
  );
};

export default FieldDate;
