import { TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  borderWidth: '1px 1px 1px 1px',
  borderColor: 'darkGrey.main',
  backgroundColor: theme.palette.action.hover,
}));

export default function ProviderAdrTableHeader() {
  return (
    <StyledTableHead>
      <TableRow>
        <TableCell>
          <Typography fontWeight={'bold'}>Nom</Typography>
        </TableCell>
        <TableCell>
          <Typography fontWeight={'bold'}>Rôle</Typography>
        </TableCell>
        <TableCell align="center" width="100px">
          <Typography fontWeight={'bold'}>TJM Coût</Typography>
        </TableCell>
      </TableRow>
    </StyledTableHead>
  );
}
