import PolyFooter from 'components/MUIOverload/PolyFooter';
import PongoButton from 'components/MUIOverload/PongoButton';
import { SaveButtonName } from 'poly-constants';
import React from 'react';

interface CreateOrUpdateFooterProps {
  onCancel?: () => void;
  disabled: boolean;
  isCreating: boolean;
}

const CreateOrUpdateFooter = ({
  onCancel,
  disabled,
  isCreating,
}: CreateOrUpdateFooterProps) => {
  return (
    <PolyFooter>
      <PongoButton variant="text" onClick={onCancel} sx={{ mr: 1 }}>
        Annuler
      </PongoButton>
      <PongoButton type={'submit'} variant={'contained'} disabled={disabled}>
        {isCreating ? 'Créer' : SaveButtonName}
      </PongoButton>
    </PolyFooter>
  );
};

export default CreateOrUpdateFooter;
