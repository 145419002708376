import { TableBody } from '@mui/material';
import { EmployeeNode } from 'generated/graphql';
import _ from 'lodash';
import React from 'react';

import ProviderAdrHistoryTableRow from './ProviderAdrHistoryTableRow';

interface ProviderAdrHistoryTableBodyProps {
  providers: EmployeeNode[];
}

export default function ProviderAdrHistoryTableBody({
  providers,
}: ProviderAdrHistoryTableBodyProps) {
  return (
    <TableBody>
      {_.map(providers, (providerEmployee) => (
        <ProviderAdrHistoryTableRow
          provider={providerEmployee}
          key={providerEmployee.id}
        />
      ))}
    </TableBody>
  );
}
