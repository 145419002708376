import { Box } from '@mui/material';
import DownloadFile from 'components/commons/FileTransfer/DownloadFile/DownloadFile';
import styles from 'components/MissionFollowUp/DownloadFilesModal/styles/DownloadFilesModal.module.scss';
import PolyTooltip from 'components/MUIOverload/PolyTooltip';
import { AttachmentNode } from 'generated/graphql';
import _ from 'lodash';
import React from 'react';
import { getFileNameFromPath } from 'utils';

interface DownloadAttachmentsProps {
  attachments: AttachmentNode[] | undefined;
  onDelete: (idToDelete: string) => Promise<void>;
}

export default function DownloadAttachments(
  properties: DownloadAttachmentsProps
) {
  return (
    <Box className={styles.filesContainer}>
      {_.isEmpty(properties.attachments) ? (
        <Box className={styles.element}>Aucun fichier</Box>
      ) : (
        _.map(properties.attachments, (file) => {
          const filename = getFileNameFromPath(file.filename);
          return (
            <Box key={'file-' + file.id} className={styles.element}>
              <PolyTooltip title={filename}>
                <Box className={styles.textContainer}>{filename}</Box>
              </PolyTooltip>
              <DownloadFile
                filename={file.filename || ''}
                title={'Télécharger'}
                onDelete={() => properties.onDelete(file.id)}
              />
            </Box>
          );
        })
      )}
    </Box>
  );
}
