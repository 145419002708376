import { Global } from '@emotion/react';
import { SwipeableDrawer } from '@mui/material';
import {
  EmployeeNode,
  useAllActivitiesForUserQuery,
  useAllEmployeeTrophiesQuery,
} from 'generated/graphql';
import React from 'react';
import { graphQlDateFormatter } from 'utils';

import EmployeeProfileMobile from './EmployeeProfileMobile';

interface EmployeeProfileDrawerProps {
  open: boolean;
  onClose: () => void;
  employee: EmployeeNode;
}

const DRAWER_WIDTH = '100vw';
const DISTANCE_FROM_TOP = '15vh';

export default function EmployeeProfileDrawer({
  open,
  onClose,
  employee,
}: EmployeeProfileDrawerProps) {
  const { data } = useAllActivitiesForUserQuery({
    variables: {
      dateFrom: graphQlDateFormatter(new Date()),
      dateTo: graphQlDateFormatter(new Date()),
      employeeId: employee.id,
      withSubmission: false,
    },
  });
  const { data: trophiesData } = useAllEmployeeTrophiesQuery({
    variables: {
      employeeId: employee.id,
    },
  });

  return (
    <>
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            width: DRAWER_WIDTH,
            top: DISTANCE_FROM_TOP,
            backgroundColor: 'transparent',
            overflow: 'hidden',
          },
        }}
      />
      <SwipeableDrawer
        open={open}
        onClose={onClose}
        onOpen={() => {
          // do nothing
        }}
        anchor="bottom"
        sx={{
          zIndex: 1300,
        }}
        disableDiscovery
        disableSwipeToOpen
      >
        <EmployeeProfileMobile
          userActivities={data}
          trophiesData={trophiesData}
          onGoBack={onClose}
          employee={employee}
        />
      </SwipeableDrawer>
    </>
  );
}
