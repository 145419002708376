import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import { Box, Button, useTheme } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import clsx from 'clsx';
import ContractTypeIcon from 'components/commons/ContractTypeIcon';
import { useTableContext } from 'contexts/ActivityMonitoringTable/TableContextProvider';
import { EmployeesEmployeeContractTypeChoices } from 'generated/graphql';
import React from 'react';

import styles from '../styles/TableHeader.module.scss';

export default function ChipsForFilters() {
  const {
    filterLatecomers,
    setFilterLatecomers,
    filterProvider,
    setFilterProvider,
    isFiltered,
    setPage,
  } = useTableContext();
  const { palette } = useTheme();
  return (
    <TableCell
      colSpan={2}
      className={clsx(styles.noUnderline, styles.exportCell)}
    >
      <Box className={styles.exportCellContainer}>
        {!isFiltered && (
          <Box className={clsx(styles.invalidWeekChip)}>
            <Button
              onClick={() => {
                setPage(0);
                setFilterLatecomers(!filterLatecomers);
              }}
              sx={{
                borderRadius: '20px',
                letterSpacing: 'normal',
                textTransform: 'none',
                color: filterLatecomers ? 'white' : 'black.main',
                ...(filterLatecomers && {
                  backgroundColor: palette.primary.main,
                }),
              }}
            >
              <AccessAlarmIcon />
              Semaines incomplètes
            </Button>
          </Box>
        )}
        <Box className={clsx(styles.invalidWeekChip)}>
          <Button
            onClick={() => {
              setPage(0);
              setFilterProvider(!filterProvider);
            }}
            sx={{
              borderRadius: '20px',
              letterSpacing: 'normal',
              textTransform: 'none',
              color: filterProvider ? 'white' : 'black.main',
              ...(filterProvider && {
                backgroundColor: palette.primary.main,
              }),
            }}
          >
            <ContractTypeIcon
              type={EmployeesEmployeeContractTypeChoices.Provider}
              className={styles.contractIcon}
            />
            Prestataire
          </Button>
        </Box>
      </Box>
    </TableCell>
  );
}
