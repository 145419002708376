import { Close } from '@mui/icons-material';
import { Box, SxProps, Theme, Typography, useTheme } from '@mui/material';
import AvatarInitial from 'components/commons/AvatarInitial/AvatarInitial';
import PongoButton from 'components/MUIOverload/PongoButton';
import React from 'react';

import { EmailOption } from './utils';

export default function BillEmailAutocompleteChip({
  option,
  sx,
  props,
  canBeDeleted = false,
  tagProps,
}: {
  option: EmailOption;
  sx?: SxProps<Theme>;
  props?: React.HTMLAttributes<HTMLElement>;
  canBeDeleted?: boolean;
  tagProps?: {
    key: number;
    className: string;
    disabled: boolean;
    'data-tag-index': number;
    tabIndex: -1;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onDelete: (event: any) => void;
  };
}) {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        '& > img': { mr: 2 },
        ...sx,
      }}
      display="flex"
      flexDirection="row"
      alignContent="center"
      alignItems="center"
      bgcolor={palette.standardGrey.dark}
      padding={0.5}
      key={tagProps?.key}
      {...props}
    >
      <AvatarInitial
        employee={option.employee || undefined}
        sx={{ width: '25px', height: '25px', fontSize: '14px' }}
      />
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Typography
          variant="body"
          maxWidth="250px"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {option.email}
        </Typography>
        {option.occupation && (
          <Typography variant="bodySLight">{option.occupation}</Typography>
        )}
      </Box>
      {canBeDeleted && (
        <PongoButton
          onClick={tagProps?.onDelete}
          buttonStyle="secondary"
          sx={{
            width: 2,
            height: 2,
            minWidth: 2,
            mx: 0.5,
            color: palette.text.secondary,
            backgroundColor: 'transparent',
            p: 1.5,
            borderRadius: '100%',
            '& :hover': {
              color: palette.text.primary,
            },
          }}
        >
          <Close fontSize="small" />
        </PongoButton>
      )}
    </Box>
  );
}
