import { Typography } from '@mui/material';
import FormSystem from 'components/commons/FormSystem';
import PolyFooter from 'components/MUIOverload/PolyFooter';
import PongoButton from 'components/MUIOverload/PongoButton';
import { useCreateOvertimeRequestMutation } from 'generated/graphql';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { graphQlDateFormatter } from 'utils';

import { overtimeAuthorizationsType, OvertimeRequestData } from '../types';
import RequestContentComment from './RequestContentComment';
import RequestContentGrid from './RequestContentGrid';
import styles from './styles/ExceptionalActivitiesNewRequestEmployee.module.scss';

interface RequestContentProps {
  authorizationData: overtimeAuthorizationsType;
}

export interface RequestDay {
  id: number;
  workedDate: Moment;
  workedDuration: number;
  periodStart: Moment;
  periodEnd: Moment;
}

const RequestContent = (properties: RequestContentProps) => {
  const [collaboratorComment, setCollaboratorComment] = useState('');
  const [createOvertimeRequest] = useCreateOvertimeRequestMutation();
  const [requestsDay, setRequestsDay] = useState<RequestDay[]>([]);
  const [indexRequestDay, setIndexRequestDay] = useState(0);

  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  const onClickValidate = async () => {
    const overtimeRequestToSend: OvertimeRequestData[] = _.map(
      requestsDay,
      (requestDay) => {
        return {
          collaboratorComment: collaboratorComment,
          authorizationId: properties.authorizationData.id,
          overtimeDateAt: graphQlDateFormatter(requestDay.workedDate),
          duration: requestDay.workedDuration,
        };
      }
    );
    try {
      await createOvertimeRequest({
        variables: {
          input: overtimeRequestToSend,
        },
      });
    } catch {
      enqueueSnackbar('Problème de récupération des activités', {
        variant: 'error',
      });
    } finally {
      onClickCancel();
    }
  };

  const onClickCancel = () => {
    history.push('my_authorization');
  };

  const onUpdateCollaboratorComment = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCollaboratorComment(event.target.value);
  };

  const deleteRequestItem = (itemRequest: RequestDay) => {
    const filteredRequests = _.filter(requestsDay, (element) => {
      return element !== itemRequest;
    });
    setRequestsDay([...filteredRequests]);
  };

  const createItemRequest = () => {
    const itemRequest: RequestDay = {
      id: indexRequestDay,
      workedDate: moment(properties.authorizationData.periodStart),
      workedDuration: 0.25,
      periodStart: moment(properties.authorizationData.periodStart),
      periodEnd: moment(properties.authorizationData.periodEnd),
    };

    setIndexRequestDay(indexRequestDay + 1);
    setRequestsDay([...requestsDay, itemRequest]);
  };

  return (
    <FormSystem onSubmit={onClickValidate}>
      <div>
        <div>
          <Typography className={styles.informationTitle}>
            {'Demande'}
          </Typography>
          <RequestContentGrid
            listToDisplay={requestsDay}
            addItemRequest={createItemRequest}
            deleteItemRequest={deleteRequestItem}
          />
          <p className={styles.information}>
            {'Justification'}
            <span className={styles.asterisk}>{' *'}</span>
          </p>
          <RequestContentComment
            collaboratorComment={collaboratorComment}
            onUpdateCollaboratorComment={onUpdateCollaboratorComment}
          />
        </div>
        <div>
          <PolyFooter>
            <PongoButton onClick={onClickCancel}>Annuler</PongoButton>
            <PongoButton
              type={'submit'}
              variant={'contained'}
              color={'primary'}
            >
              Valider
            </PongoButton>
          </PolyFooter>
        </div>
      </div>
    </FormSystem>
  );
};

export default RequestContent;
