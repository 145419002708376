import { Add } from '@mui/icons-material';
import { Stack } from '@mui/material';
import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import PurchaseOrderModal from 'components/commons/PurchaseOrderModal';
import { PoFormMode } from 'components/commons/PurchaseOrderModal/PurchaseOrderForm';
import TabHeader from 'components/commons/TabHeader';
import TablePurchaseOrders from 'components/MissionFollowUp/BillDetail/ListPurchaseOrder/TablePurchaseOrders';
import BillingTablePagination from 'components/MissionFollowUp/OverallListBill/BillingTablePagination';
import PongoButton from 'components/MUIOverload/PongoButton';
import { useUserInfo } from 'components/User/UserProvider';
import {
  PurchaseOrderNode,
  useAllPurchaseOrdersPaginatedQuery,
} from 'generated/graphql';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import InternalMissionPage from 'pages/ErrorPage/InternalMissionPage';
import { PageTitles } from 'poly-constants';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import {
  setCurrentPurchaseOrder,
  setPurchaseOrders,
} from 'store/purchaseOrder';
import { checkIfAllMissionsAreInternal } from 'utils';

import ChooseActivityModalToCreatePurchaseOrder from './ChooseActivityModalToCreatePurchaseOrder';
import PurchaseOrdersExportButton from './PurchaseOrdersExportButton';

export default function PurchaseOrdersPage() {
  const userInfo = useUserInfo();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [nbrPurchaseOrders, setNbrPurchaseOrders] = useState(0);
  const [pageNbr, setPageNbr] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [filter, setFilter] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const handleClickAdd = () => {
    dispatch(setCurrentPurchaseOrder({} as PurchaseOrderNode));
    setOpenModal(true);
  };

  const selectedMissions = useSelector(
    (state) => state.activity.selectedMissions
  );
  const currentMission = useSelector((state) => state.activity.currentMission);
  const missionIds = currentMission?.id
    ? [currentMission.id]
    : _.map(selectedMissions, (mission) => mission.id);

  function closeModal() {
    setOpenModal(false);
  }

  const [orderBy, setOrderBy] = useState([
    '-period_beginning',
    '-period_ending',
  ]);

  useEffect(() => {
    if (filter && filter.includes('dates')) {
      if (filter.includes('-')) {
        setOrderBy(['-period_beginning', '-period_ending']);
      } else {
        setOrderBy(['period_beginning', 'period_ending']);
      }
    } else {
      setOrderBy(filter ? [filter] : ['-period_beginning', '-period_ending']);
    }
  }, [filter]);

  const { loading } = useAllPurchaseOrdersPaginatedQuery({
    variables: {
      activityIds: missionIds,
      pageNbr: pageNbr,
      pageSize: pageSize,
      orderBy: orderBy,
    },
    onCompleted: (data) => {
      if (data.allPurchaseOrdersPaginated?.purchaseOrders) {
        dispatch(
          setPurchaseOrders(
            data.allPurchaseOrdersPaginated
              ?.purchaseOrders as PurchaseOrderNode[]
          )
        );
      }
      setNbrPurchaseOrders(
        data.allPurchaseOrdersPaginated?.purchaseOrdersNumber || 0
      );
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  if (checkIfAllMissionsAreInternal(selectedMissions)) {
    return <InternalMissionPage page={PageTitles.purchaseOrdersPage} />;
  }

  const canUserAddBDC =
    userInfo?.employee?.isMissionChief || userInfo?.employee?.isMissionDirector;

  return (
    <div>
      <TabHeader
        title={PageTitles.purchaseOrdersPage}
        count={nbrPurchaseOrders}
        actionComponents={
          <>
            <PurchaseOrdersExportButton />
            {canUserAddBDC && (
              <>
                <PongoButton
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={handleClickAdd}
                  startIcon={<Add />}
                >
                  Ajouter
                </PongoButton>
                {missionIds.length === 1 ? (
                  <PurchaseOrderModal
                    open={openModal}
                    closeModal={closeModal}
                    mode={PoFormMode.CREATE}
                    selectedActivity={selectedMissions[0]}
                  />
                ) : (
                  <ChooseActivityModalToCreatePurchaseOrder
                    openModal={openModal}
                    closeModal={closeModal}
                    mode={PoFormMode.CREATE}
                  />
                )}
              </>
            )}
          </>
        }
      />

      <Stack>
        <BillingTablePagination
          totalLen={nbrPurchaseOrders}
          pageNbr={pageNbr}
          setPageNbr={setPageNbr}
          pageSize={pageSize}
          setPageSize={setPageSize}
          paginationLabel="BDC par page"
        />
        {loading && <LoadingPlaceholder />}
        {!loading && <TablePurchaseOrders setFilter={setFilter} />}
      </Stack>
    </div>
  );
}
