import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Stack } from '@mui/material';
import Section from 'components/commons/Section';
import SectionTitle from 'components/commons/SectionTitle';
import PongoButton from 'components/MUIOverload/PongoButton';
import { ActivityNode } from 'generated/graphql';
import React, { useState } from 'react';

import ActivityInfoDrawer from './ActivityInfoDrawer';
import MissionInformations from './MissionInformations';

interface MissionDetailsProps {
  mission: ActivityNode;
}

export const getDefaultText = (text: string | undefined): string => {
  if (!text || text.trim() === '') {
    return 'À définir';
  }

  return text;
};

export default function MissionDetails({
  mission,
}: MissionDetailsProps): JSX.Element {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <Section sx={{ my: 3, flex: 0.5, justifyContent: 'space-between' }}>
      <Stack gap={2}>
        <SectionTitle>Informations mission</SectionTitle>
        <MissionInformations currentMission={mission} />
      </Stack>
      <PongoButton
        variant="contained"
        buttonStyle="secondary"
        endIcon={<ArrowForwardIosRoundedIcon sx={{ height: '16px' }} />}
        onClick={() => setOpenDrawer(true)}
      >
        Voir plus
      </PongoButton>
      <ActivityInfoDrawer
        activity={mission}
        open={openDrawer}
        setOpenDrawer={setOpenDrawer}
      />
    </Section>
  );
}
