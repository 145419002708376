import PolyExportButton from 'components/commons/PolyExportButton';
import React, { useState } from 'react';

import ExportProfitabilityModal from './ExportProfitabilityModal';

interface ProfitabilityExportButtonProps {
  year: number;
  isTrimester?: boolean;
  isExcludingIntern?: boolean;
  lastValidatedMonthEndDate?: string;
}

export default function ProfitabilityExportButton({
  year,
  isTrimester,
  isExcludingIntern,
  lastValidatedMonthEndDate,
}: ProfitabilityExportButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <PolyExportButton onClick={toggleModal} sx={{ mr: 1 }}>
        Exporter ce tableau
      </PolyExportButton>
      <ExportProfitabilityModal
        year={year}
        isTrimester={isTrimester}
        isExcludingIntern={isExcludingIntern}
        lastValidatedMonthEndDate={lastValidatedMonthEndDate}
        isOpen={isOpen}
        handleClose={toggleModal}
        title={'Export données de rentabilité'}
        body={
          'Téléchargez ici le tableau de rentabilité précédent en choisissant les données que vous souhaitez exporter.'
        }
      />
    </>
  );
}
