import { Box, SxProps, Theme } from '@mui/material';
import ContractTypeIcon from 'components/commons/ContractTypeIcon';
import { EmployeeType } from 'components/Reporting/Profitability/MissionView/ProfitabilityTableBody/ProfitabilityMissionViewTableBody';
import {
  EmployeeNode,
  EmployeesEmployeeContractTypeChoices,
} from 'generated/graphql';
import React from 'react';
import { displayEmployee } from 'utils';
interface EmployeeCellProps {
  employee: EmployeeType | EmployeeNode;
  effectiveEmployeeContractType: EmployeesEmployeeContractTypeChoices | null;
  sx?: SxProps<Theme>;
}
export default function EmployeeCell({
  employee,
  effectiveEmployeeContractType,
  sx,
}: EmployeeCellProps) {
  const boxStyle = {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    ...sx,
  };
  return (
    <Box sx={boxStyle}>
      <ContractTypeIcon
        type={effectiveEmployeeContractType ?? employee.contractType}
      />
      {displayEmployee(employee.firstName, employee.lastName)}
    </Box>
  );
}
