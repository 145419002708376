import { Grid } from '@mui/material';
import UploadFileForm from 'components/commons/FileTransfer/UploadFile/UploadFileForm';
import { getAttachmentsToKeep } from 'components/MissionFollowUp/DownloadFilesModal/PoAddAttachments';
import GridItem from 'components/MissionFollowUp/GridItem';
import PolyFooter from 'components/MUIOverload/PolyFooter';
import PongoButton from 'components/MUIOverload/PongoButton';
import {
  AttachmentInput,
  BillNode,
  useAddBillAttachmentsMutation,
} from 'generated/graphql';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { UploadButtonName } from 'poly-constants';
import React, { useMemo } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import {
  setBillForCurrentPurchaseOrder,
  setCurrentBill,
} from 'store/purchaseOrder';

import { AttachmentType } from '../BillDetail/utils';

interface BillAddAttachmentsProps {
  billId: string;
  validCallback: () => void;
  attachmentsToDelete: AttachmentInput[];
}

interface BillFormType {
  billId: string;
  attachments: AttachmentType[];
}

export default function BillAddAttachments(
  properties: BillAddAttachmentsProps
) {
  const currentBill = useSelector((state) => state.purchaseOrder.currentBill);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const form = useForm({
    mode: 'onChange',
    defaultValues: useMemo(() => {
      return {
        billId: properties.billId,
        attachments: [] as AttachmentType[],
      };
    }, [properties.billId]),
    criteriaMode: 'firstError',
    shouldUnregister: false,
  });
  const { handleSubmit, control } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'attachments',
    keyName: 'key',
  });

  const [addBillAttachments, { loading }] = useAddBillAttachmentsMutation({
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    onCompleted: (data) => {
      const bill = data.addBillAttachments?.bill as BillNode;
      if (bill) {
        dispatch(setBillForCurrentPurchaseOrder(bill));
        dispatch(setCurrentBill(bill));
      }
      properties.validCallback();
      enqueueSnackbar(`La facture « ${bill.title} » a été mise à jour`, {
        variant: 'success',
      });
    },
  });

  const onSubmit = async (submitValues: BillFormType) => {
    if (loading) {
      return;
    }
    const attachments: AttachmentInput[] = _.flatMap(
      submitValues.attachments,
      (x) => {
        return {
          id: x.id,
          file: x.file,
        };
      }
    );

    await addBillAttachments({
      variables: {
        billId: properties.billId,
        attachments: attachments.concat(
          getAttachmentsToKeep(currentBill, properties.attachmentsToDelete)
        ),
      },
    });
  };

  const appendField = () => {
    append({
      id: '',
      filename: '',
      file: undefined,
    });
  };

  // when there is no document or all document are already set, append upload field
  if (!_.find(fields, { filename: '' })) {
    appendField();
  }

  return (
    <React.Fragment>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit((d) => onSubmit(d))}>
          <Grid container>
            <GridItem customspacingtop={20} sizegrid={12}>
              {_.map(fields, (field, index) => {
                const name = `attachments[${index}]`;
                return (
                  <UploadFileForm
                    key={field.key}
                    callback={() => appendField()}
                    deleteCallback={() => remove(index)}
                    formname={name}
                    isEditing={true}
                  />
                );
              })}
            </GridItem>
          </Grid>
          <PolyFooter>
            <PongoButton
              onClick={() => {
                properties.validCallback();
              }}
              sx={{ mr: 0.5 }}
            >
              Fermer
            </PongoButton>
            <PongoButton
              type={'submit'}
              variant={'contained'}
              disabled={
                loading ||
                (!form.formState.isDirty &&
                  properties.attachmentsToDelete.length === 0)
              }
            >
              {UploadButtonName}
            </PongoButton>
          </PolyFooter>
        </form>
      </FormProvider>
    </React.Fragment>
  );
}
