import Add from '@mui/icons-material/Add';
import PsychologyTwoToneIcon from '@mui/icons-material/PsychologyTwoTone';
import { Stack, Typography } from '@mui/material';
import PongoButton from 'components/MUIOverload/PongoButton';
import React from 'react';

interface NoSkillsProps {
  onAddClick?: () => void;
}

export default function NoSkills({ onAddClick }: NoSkillsProps) {
  return (
    <Stack
      spacing={2}
      sx={{
        mb: 6,
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Stack
        component="span"
        sx={{
          border: '1px dashed',
          color: 'info.main',
          width: 54,
          height: 54,
          borderRadius: '50%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <PsychologyTwoToneIcon
          color="info"
          sx={{
            fontSize: '30px',
          }}
        />
      </Stack>
      <Typography color={'text.secondary'} textAlign={'center'}>
        Compétences non renseignées
      </Typography>
      {onAddClick && (
        <PongoButton
          variant="contained"
          size="small"
          startIcon={<Add />}
          onClick={onAddClick}
        >
          Ajouter
        </PongoButton>
      )}
    </Stack>
  );
}
