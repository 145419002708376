import {
  ActivityNode,
  BillingClientNode,
  EmployeeNode,
} from 'generated/graphql';
import moment, { Moment } from 'moment';
import { ROWS_PER_PAGE_MONITORING } from 'poly-constants';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';
import { functionNotSet } from 'utils';

export interface TableContext {
  displayedMonth: Moment;
  setDisplayedMonth: (p: Moment) => void;
  displayedWeeks: Moment[];
  setDisplayedWeeks: (p: Moment[]) => void;
  sharedMissions: ActivityNode[];
  filterMissionsId: string[];
  setFilterMissionsId: (p: string[]) => void;
  isFiltered: boolean;
  filterCollaboratorsId: string[];
  setFilterCollaboratorsId: (p: string[]) => void;
  filterLatecomers: boolean;
  setFilterLatecomers: (p: boolean) => void;
  filterProvider: boolean;
  setFilterProvider: (p: boolean) => void;
  allCollaborators: EmployeeNode[];
  setAllCollaborators: (p: EmployeeNode[]) => void;
  page: number;
  setPage: (p: number) => void;
  rowsPerPage: number;
  setRowsPerPage: (p: number) => void;
  paginationCount: number;
  allClients: BillingClientNode[];
  setAllClients: (p: BillingClientNode[]) => void;
  filterClientsId: string[];
  setFilterClientsId: (p: string[]) => void;
  isContextCreated: boolean;
}

export interface TableContextProps {
  sharedActivities?: ActivityNode[] | undefined;
}

const TableContext = createContext<TableContext>({
  displayedMonth: moment(),
  setDisplayedMonth: () => functionNotSet(),
  displayedWeeks: [],
  setDisplayedWeeks: () => functionNotSet(),
  allCollaborators: [],
  setAllCollaborators: () => functionNotSet(),
  filterCollaboratorsId: [],
  setFilterCollaboratorsId: () => functionNotSet(),
  filterLatecomers: false,
  setFilterLatecomers: () => functionNotSet(),
  filterProvider: false,
  setFilterProvider: () => functionNotSet(),
  filterMissionsId: [],
  setFilterMissionsId: () => functionNotSet(),
  filterClientsId: [],
  setFilterClientsId: () => functionNotSet(),
  isFiltered: false,
  sharedMissions: [],
  page: 0,
  setPage: () => functionNotSet(),
  rowsPerPage: 50,
  setRowsPerPage: () => functionNotSet(),
  paginationCount: 0,
  allClients: [],
  setAllClients: () => functionNotSet(),
  isContextCreated: false,
});

export const useTableContext = () => {
  const tableContext = useContext(TableContext);
  if (!tableContext) {
    return {} as TableContext;
  }
  return tableContext;
};

const TableContextProvider = (
  properties: PropsWithChildren<TableContextProps>
) => {
  const [displayedMonth, setDisplayedMonth] = useState<Moment>(
    moment().startOf('month')
  );
  const [displayedWeeks, setDisplayedWeeks] = useState<Moment[]>([]);
  const [filterMissionsId, setFilterMissionsId] = useState<string[]>([]);
  const [filterCollaboratorsId, setFilterCollaboratorsId] = useState<string[]>(
    []
  );
  const [filterLatecomers, setFilterLatecomers] = useState<boolean>(false);
  const [filterProvider, setFilterProvider] = useState<boolean>(false);
  const [allCollaborators, setAllCollaborators] = useState<EmployeeNode[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    Number(localStorage.getItem(ROWS_PER_PAGE_MONITORING) || 50)
  );
  const [allClients, setAllClients] = useState<BillingClientNode[]>([]);
  const [filterClientsId, setFilterClientsId] = useState<string[]>([]);
  const paginationCount = allCollaborators?.length;
  return (
    <TableContext.Provider
      value={{
        displayedMonth,
        setDisplayedMonth,
        displayedWeeks,
        setDisplayedWeeks,
        allCollaborators,
        paginationCount,
        setAllCollaborators,
        filterCollaboratorsId,
        setFilterCollaboratorsId,
        filterLatecomers,
        setFilterLatecomers,
        filterProvider,
        setFilterProvider,
        filterMissionsId,
        setFilterMissionsId,
        filterClientsId,
        setFilterClientsId,
        isFiltered: !!filterMissionsId.length || !!filterClientsId.length,
        sharedMissions: properties.sharedActivities || [],
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        allClients,
        setAllClients,
        isContextCreated: true,
      }}
    >
      {properties.children}
    </TableContext.Provider>
  );
};

export default TableContextProvider;
