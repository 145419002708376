import { TableCell, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PongoButton from 'components/MUIOverload/PongoButton';
import {
  BillingPurchaseOrderCurrencyChoices,
  EmployeeNode,
} from 'generated/graphql';
import { totalFormat } from 'pages/ActivityPage/utils';
import React, { useState } from 'react';
import { displayEmployee } from 'utils';

import { ProviderAdrModal } from './ProviderAdrModal';

interface OccupationAdrTableRowProps {
  provider: EmployeeNode;
  isEdit: boolean;
  refetchProviders: () => void;
}

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export default function ProviderAdrTableRow({
  provider,
  isEdit,
  refetchProviders,
}: OccupationAdrTableRowProps) {
  const [open, setOpen] = useState<boolean>(false);
  const formattedAdr = totalFormat(
    provider.contractorReportingParameters?.[0]?.adr || 0,
    BillingPurchaseOrderCurrencyChoices.Eur
  );

  return (
    <StyledTableRow>
      <TableCell>
        <Typography>
          {displayEmployee(provider.firstName, provider.lastName)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>{provider.occupation}</Typography>
      </TableCell>
      <TableCell align="center">
        {isEdit ? (
          <PongoButton
            size="small"
            id={provider.id}
            variant="contained"
            buttonStyle="secondary"
            onClick={() => setOpen(true)}
          >
            {formattedAdr}
          </PongoButton>
        ) : (
          <Typography>{formattedAdr}</Typography>
        )}
      </TableCell>
      <ProviderAdrModal
        open={open}
        setOpen={setOpen}
        provider={provider}
        refetchProviders={refetchProviders}
      />
    </StyledTableRow>
  );
}
