import { TableBody, useTheme } from '@mui/material';
import { BorderedTableCell } from 'components/commons/Tables/FlatTable';
import { useUserInfo } from 'components/User/UserProvider';
import { ActivityNode, BillingClientNode, SectorNode } from 'generated/graphql';
import _ from 'lodash';
import { Moment } from 'moment';
import React from 'react';
import { Transition } from 'react-transition-group';

import { ClientType, transformClientsBySector } from './ProfitabilityTableBody';
import ProfitabilityMissionRow from './Rows/ProfitabilityMissionRow';
import ProfitabilitySectorRow from './Rows/ProfitabilitySectorRow';

export interface ProfitabilityTrimesterTableInterface {
  profitsBySectors: SectorNode[];
  activityProfitsByClients: ClientType[];
  activities?: ActivityNode[];
  polyconseilClient: BillingClientNode;
  nonInitExternalMissionsClient: BillingClientNode;
  filteredMission?: boolean;
  currentYear: Moment;
}

export default function ProfitabilityTrimesterTableBody({
  profitsBySectors,
  activityProfitsByClients,
  activities,
  polyconseilClient,
  nonInitExternalMissionsClient,
  filteredMission,
  currentYear,
}: ProfitabilityTrimesterTableInterface) {
  const { palette } = useTheme();
  const { isControl, isComex, isSuperuser } = useUserInfo();
  return (
    <TableBody>
      {!filteredMission &&
      (isControl || isComex || isSuperuser) &&
      profitsBySectors ? (
        <>
          {_.map(
            transformClientsBySector(
              activityProfitsByClients,
              profitsBySectors
            ),
            (sector) => {
              return (
                <Transition
                  mountOnEnter
                  unmountOnExit
                  timeout={250}
                  key={`secteur-${sector.id}`}
                  in={true}
                >
                  {(status) => {
                    return (
                      <>
                        <BorderedTableCell
                          sx={{ backgroundColor: palette.background.default }}
                          colSpan={11}
                        />
                        <ProfitabilitySectorRow
                          currentYear={currentYear}
                          status={status}
                          sector={sector}
                          isTrimesterView={true}
                          polyconseilClient={polyconseilClient}
                          nonInitExternalMissionsClient={
                            nonInitExternalMissionsClient
                          }
                        />
                      </>
                    );
                  }}
                </Transition>
              );
            }
          )}
          <BorderedTableCell
            sx={{ backgroundColor: palette.background.default }}
            colSpan={11}
          />
        </>
      ) : (
        _.map(activities, (activity) => {
          return (
            <Transition
              mountOnEnter
              unmountOnExit
              timeout={250}
              key={`Activities-as-chief-for-user`}
              in={true}
            >
              {(status) => {
                return (
                  <ProfitabilityMissionRow
                    currentYear={currentYear}
                    status={status}
                    activity={activity}
                    isTrimesterView={true}
                  />
                );
              }}
            </Transition>
          );
        })
      )}
    </TableBody>
  );
}
