import ActivityMonitoringTable from 'components/ActivityMonitoring/ActivityMonitoringTable';
import PolySwitch from 'components/Navigation/PolySwitch';
import AccessWrapper from 'components/User/AccessWrapper';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import { EmployeeOccupations, useUserInfo } from 'components/User/UserProvider';
import AbsencePage from 'pages/AbsencePage';
import ValidateTmActivityMonitoringPage from 'pages/ActivityPage/ValidateTmActivityMonitoringPage';
import ExceptionalActivities from 'pages/ExceptionalActivities';
import React from 'react';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';
import urljoin from 'url-join';

import PrincipalTablePage from './PrincipalTablePage';

const ActivityPage = () => {
  const userInfo = useUserInfo();
  const { path } = useRouteMatch();

  const VALIDATION_REGIE_FLAG = useEnvironmentVariable('validation_regie');
  const ABSENCE_FLAG = useEnvironmentVariable('absences');
  const should_display_regie = VALIDATION_REGIE_FLAG !== '' && userInfo;

  return (
    <>
      <PolySwitch>
        <Redirect
          exact
          from={'/mission-followup/activity'}
          to={'/mission-followup/activity/collaborators'}
        />
        <Route exact path={urljoin(path, 'working-days')}>
          <AccessWrapper
            restrictStandardUser
            occupations={[
              EmployeeOccupations.CONTROL,
              EmployeeOccupations.OFFICE_MANAGEMENT,
              EmployeeOccupations.RECRUITING,
              EmployeeOccupations.HR,
              EmployeeOccupations.COMMERCIAL,
            ]}
          >
            <PrincipalTablePage />
          </AccessWrapper>
        </Route>
        <Route exact path={urljoin(path, 'absences')}>
          <AccessWrapper
            restrictStandardUser
            occupations={[
              EmployeeOccupations.CONTROL,
              EmployeeOccupations.OFFICE_MANAGEMENT,
              EmployeeOccupations.RECRUITING,
              EmployeeOccupations.HR,
              EmployeeOccupations.COMMERCIAL,
            ]}
          >
            {ABSENCE_FLAG && <AbsencePage />}
          </AccessWrapper>
        </Route>
        <Route path={urljoin(path, 'exceptional-activities')}>
          <ExceptionalActivities />
        </Route>
        <Route path={urljoin(path, 'collaborators')}>
          <AccessWrapper
            restrictMissionChief
            occupations={[EmployeeOccupations.DG, EmployeeOccupations.CONTROL]}
          >
            <ActivityMonitoringTable />
          </AccessWrapper>
        </Route>
        <Route path={urljoin(path, 'validate_activity_monitoring')}>
          <AccessWrapper
            restrictMissionChiefWithTmActivity
            occupations={[EmployeeOccupations.CONTROL, EmployeeOccupations.DG]}
          >
            {should_display_regie && <ValidateTmActivityMonitoringPage />}
          </AccessWrapper>
        </Route>
      </PolySwitch>
    </>
  );
};

export default ActivityPage;
