import { getModalityName } from 'components/MissionFollowUp/BillDetail/BillDetail/utils';
import LabelTextFieldGrid from 'components/MissionFollowUp/LabelTextFieldGrid';
import {
  checkEmailFormat,
  requiredForm,
} from 'pages/MissionFollowUp/formValidators';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Modality } from '../Types';

interface ModalityEmailProps {
  index: number;
  modality: Modality;
}

export default function ModalityEmail(properties: ModalityEmailProps) {
  const form = useFormContext();

  return (
    <>
      <LabelTextFieldGrid
        name={getModalityName(properties.index, 'billingName')}
        customspacingtop={0}
        sizegrid={6}
        defaultValue={`${properties.modality.billingName || ''}`}
        inputRef={form.register({ required: requiredForm })}
        errorform={
          form.errors.billingModalities?.[properties.index]?.billingName
        }
        title="Nom du destinataire"
        required={true}
      />
      <LabelTextFieldGrid
        inputRef={form.register({
          required: requiredForm,
          validate: {
            invalidFormatEmail: (email) => checkEmailFormat(email),
          },
        })}
        name={getModalityName(properties.index, 'billingEmail')}
        defaultValue={`${properties.modality.billingEmail || ''}`}
        customspacingtop={0}
        customspacingside={0}
        sizegrid={6}
        errorform={
          form.errors.billingModalities?.[properties.index]?.billingEmail
        }
        title="Adresse mail"
        required={true}
      />
    </>
  );
}
