import { SxProps, Theme } from '@mui/material/styles';
import {
  ActivityProfitNode,
  ClientProfitNode,
  PublicHoliday,
  SectorProfitNode,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment-business-days';
import { POLY_DATE_MONTH } from 'poly-constants';
import React from 'react';

import ProfitabilityDataComingCell from './Cells/ProfitabilityDataComingCell';
import ProfitabilityRenderValues from './Cells/ProfitabilityRenderValues';

interface CallProfitabilityRenderValuesProps {
  month: moment.Moment;
  profit: SectorProfitNode | ClientProfitNode | ActivityProfitNode | undefined;
  isTrimesterView: boolean | undefined;
  sx?: SxProps<Theme>;
  isEtpCumulated?: boolean;
  yearToDateMonthWorkDays?: number;
}

export function CallProfitabilityRenderValues({
  month,
  profit,
  isTrimesterView,
  sx,
  isEtpCumulated,
  yearToDateMonthWorkDays,
}: CallProfitabilityRenderValuesProps) {
  if (!profit || Object.keys(profit).length === 0) {
    return <ProfitabilityDataComingCell colSpan={5} />;
  }
  return (
    <ProfitabilityRenderValues
      month={month}
      etp={profit.etp}
      averageAdr={profit.averageAdr}
      totalRevenue={profit.totalRevenue}
      etpCumulated={profit.etpCumulated}
      etpCumulatedWithoutIntern={profit.etpCumulatedWithoutIntern}
      totalWorkedDaysToMonth={profit.totalWorkedDaysToMonth}
      totalWorkedDaysToMonthWithoutIntern={
        profit.totalWorkedDaysToMonthWithoutIntern
      }
      isEtpCumulated={isEtpCumulated}
      cost={profit.cost}
      totalWorkedDays={profit.totalWorkedDays}
      totalWorkedDaysWithoutIntern={profit.totalWorkedDaysWithoutIntern || 0}
      workDays={profit.workDays}
      hasValidatedRevenue={!!profit.hasValidatedRevenue}
      notDisplayedReason={isTrimesterView ? undefined : 'CA non validé'}
      key={`value-${month.format(POLY_DATE_MONTH)}`}
      yearToDateMonthWorkDays={yearToDateMonthWorkDays}
      sx={sx}
    />
  );
}

export function shouldDisplayDataIncoming(
  hasValidatedRevenue: boolean,
  month: moment.Moment | undefined
) {
  return !hasValidatedRevenue && moment(month).isSameOrAfter(moment(), 'month');
}

interface Profit {
  averageAdr: number;
  cost: number;
  hasValidatedRevenue: boolean;
  margin: number;
  marginRate: number;
  month?: moment.Moment;
  totalRevenue: number;
  totalWorkedDays: number;
  totalWorkedDaysWithoutIntern?: number;
  trimester?: boolean | null;
  workDays: number;
  etp?: number;
  etpCumulated?: number;
  etpCumulatedWithoutIntern?: number;
  totalWorkedDaysToMonth?: number;
  totalWorkedDaysToMonthWithoutIntern?: number;
}

function getYearToDateWorkDays(
  yearToDateMonth: moment.Moment,
  publicHolidays: PublicHoliday[]
): number {
  const startDate = yearToDateMonth.clone().startOf('year');
  const endDate = yearToDateMonth.clone().endOf('month');
  const holidayDates = _.map(publicHolidays, (publicHoliday) =>
    moment(publicHoliday.date)
  );
  const holidaysInRangeWeeks = _.reduce(
    holidayDates,
    (acc, holidayDate) => {
      if (
        holidayDate.isoWeekday() < 6 &&
        holidayDate.isBetween(startDate, endDate, undefined, '[]')
      ) {
        acc++;
      }
      return acc;
    },
    0
  );

  if (yearToDateMonth.isSameOrAfter(moment('2023-01-01'))) {
    return startDate.businessDiff(endDate) - holidaysInRangeWeeks;
  }

  const thursday = startDate.clone().day(4);
  let yearToDateWorkDays = 0;

  while (thursday.isSameOrBefore(endDate)) {
    if (thursday.isBetween(startDate, endDate, undefined, '[]')) {
      yearToDateWorkDays += 5;
    }
    thursday.add(7, 'days');
  }

  return yearToDateWorkDays - holidaysInRangeWeeks;
}

export function getYearToDateProfit(
  profits: Profit[] | undefined,
  displayedMonths: moment.Moment[],
  publicHolidays: PublicHoliday[],
  isFooter?: boolean
): Profit {
  if (!profits || profits.length === 0) {
    return {} as Profit;
  }

  const yearToDateMonth = displayedMonths[displayedMonths.length - 1];
  const validProfitsInRange = _.filter(profits, (profit) => {
    return (
      yearToDateMonth.isSameOrAfter(profit.month, 'month') &&
      !shouldDisplayDataIncoming(profit.hasValidatedRevenue, profit.month)
    );
  });

  const totalCost = _.sumBy(validProfitsInRange, 'cost');
  const totaletp = _.sumBy(validProfitsInRange, 'etp');
  let totalEtpCumulated;
  let totalEtpCumulatedWithoutIntern;
  let totalWorkedDaysToMonth;
  let totalWorkedDaysToMonthWithoutIntern;

  if (isFooter) {
    const filteredMissions = _.filter(validProfitsInRange, (profits) =>
      yearToDateMonth.isSame(profits.month, 'month')
    );
    totalEtpCumulated = _.sumBy(filteredMissions, 'etpCumulated');
    totalEtpCumulatedWithoutIntern = _.sumBy(
      filteredMissions,
      'etpCumulatedWithoutIntern'
    );
    totalWorkedDaysToMonth = _.sumBy(
      filteredMissions,
      'totalWorkedDaysToMonth'
    );
    totalWorkedDaysToMonthWithoutIntern = _.sumBy(
      filteredMissions,
      'totalWorkedDaysToMonthWithoutIntern'
    );
  } else {
    totalEtpCumulated =
      _.find(validProfitsInRange, (profits) =>
        yearToDateMonth.isSame(profits.month, 'month')
      )?.etpCumulated || 0;
    totalEtpCumulatedWithoutIntern =
      _.find(validProfitsInRange, (profits) =>
        yearToDateMonth.isSame(profits.month, 'month')
      )?.etpCumulatedWithoutIntern || 0;
    totalWorkedDaysToMonth =
      _.find(validProfitsInRange, (profits) =>
        yearToDateMonth.isSame(profits.month, 'month')
      )?.totalWorkedDaysToMonth || 0;
    totalWorkedDaysToMonthWithoutIntern =
      _.find(validProfitsInRange, (profits) =>
        yearToDateMonth.isSame(profits.month, 'month')
      )?.totalWorkedDaysToMonthWithoutIntern || 0;
  }
  const totalRevenue = _.sumBy(validProfitsInRange, 'totalRevenue');
  const totalWorkedDays = _.sumBy(validProfitsInRange, 'totalWorkedDays');
  const totalWorkedDaysWithoutIntern = _.sumBy(
    validProfitsInRange,
    'totalWorkedDaysWithoutIntern'
  );

  const workDays = getYearToDateWorkDays(yearToDateMonth, publicHolidays);
  return {
    averageAdr: totalWorkedDays !== 0 ? totalRevenue / totalWorkedDays : 0,
    cost: totalCost,
    hasValidatedRevenue: _.some(
      validProfitsInRange,
      (profit) =>
        !shouldDisplayDataIncoming(profit.hasValidatedRevenue, profit.month)
    ),
    margin: _.sumBy(validProfitsInRange, 'margin'),
    marginRate:
      totalRevenue !== 0
        ? ((totalRevenue - totalCost) / totalRevenue) * 100
        : 0,
    month: yearToDateMonth,
    totalRevenue: totalRevenue,
    totalWorkedDays: totalWorkedDays,
    totalWorkedDaysWithoutIntern: totalWorkedDaysWithoutIntern,
    trimester: null,
    workDays: workDays,
    etp: totaletp,
    etpCumulated: totalEtpCumulated,
    totalWorkedDaysToMonth: totalWorkedDaysToMonth,
    totalWorkedDaysToMonthWithoutIntern: totalWorkedDaysToMonthWithoutIntern,
    etpCumulatedWithoutIntern: totalEtpCumulatedWithoutIntern,
  };
}
