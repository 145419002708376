import { AddCircleOutline } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { overtimeAuthorizationsType } from 'pages/ExceptionalActivities/types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

interface ExceptionalActivitiesRequestButtonProps {
  authorizationData: overtimeAuthorizationsType;
  periodStart: string;
  periodEnd: string;
  mission: string;
  managerFirstName?: string;
  managerLastName?: string;
}

const RequestButton = (properties: ExceptionalActivitiesRequestButtonProps) => {
  const history = useHistory();

  const handleClick = () => {
    history.push({
      pathname: '/my-activity/exceptional-activities/new_overtime_request',
      search: '?mission_id=' + properties.authorizationData.id,
    });

    setOpenRequest(!openRequest);
  };
  const [openRequest, setOpenRequest] = useState(false);

  return (
    <Stack flexDirection={'column'} alignItems={'center'}>
      <Button onClick={handleClick}>
        <Stack
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          color="primary"
        >
          <AddCircleOutline sx={{ m: 0.5 }} />
          <Typography variant="bodySBold" sx={{ color: 'primary.main' }}>
            Nouvelle demande
          </Typography>
        </Stack>
      </Button>
    </Stack>
  );
};

export default RequestButton;
