import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Divider, Paper, Typography } from '@mui/material';
import clsx from 'clsx';
import moment from 'moment';
import { POLY_DATE } from 'poly-constants';
import React from 'react';

import { AuthorizationDataType } from '../ExceptionalActivitiesRequest/ExceptionalActivitiesRequestPanelManager';
import styles from './styles/lookup.module.scss';

interface PanelSummaryProps {
  data: AuthorizationDataType;
}

const get_styles = (status: string | undefined) => {
  switch (status) {
    case 'Approved':
      return styles.summaryContent_green;
    case 'Denied':
      return styles.summaryContent_red;
    default:
      return styles.summaryContent_grey;
  }
};
const getText = (data: AuthorizationDataType) => {
  switch (data.status) {
    case 'Approved':
      return (
        <div>
          <Typography>{`Cette demande a été confirmée avec le motif suivant:`}</Typography>
          <Typography className={styles.reason}>
            {data.managerResponse}
          </Typography>
        </div>
      );
    case 'Denied':
      return (
        <div>
          <Typography>{`Cette demande a été refusée avec le motif suivant:`}</Typography>
          <Typography className={styles.reason}>
            {data.managerResponse}
          </Typography>
        </div>
      );
    case 'Lost':
      return (
        <Typography>{`Le temps de récupération associé à cette demande a été perdu le ${moment(
          data.expireAt
        ).format(POLY_DATE)}`}</Typography>
      );
    default:
      return (
        <Typography>
          {'Le temps de récupération associé à cette demande a été récupéré'}
        </Typography>
      );
  }
};

const PanelSummary = ({ data }: PanelSummaryProps) => {
  return (
    <Paper
      elevation={0}
      className={clsx(styles.summaryContainer, get_styles(data.status))}
    >
      <div className={styles.summaryContent}>
        <InfoIcon className={styles.summaryContent_icon} />
        <Divider
          orientation="vertical"
          className={styles.summaryContent_divider}
        />
        {getText(data)}
      </div>
    </Paper>
  );
};

export default PanelSummary;
