import { TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

function TableCellCentered(name: string) {
  return (
    <TableCell align="center">
      <Typography fontWeight={'bold'}>{name}</Typography>
    </TableCell>
  );
}

export default function OccupationAdrTableHeader() {
  const StyledTableHead = styled(TableHead)(({ theme }) => ({
    borderWidth: '1px 1px 1px 1px',
    borderColor: 'darkGrey.main',
    backgroundColor: theme.palette.action.hover,
  }));

  return (
    <StyledTableHead>
      <TableRow>
        <TableCell>
          <Typography fontWeight={'bold'}>Rôle</Typography>
        </TableCell>
        {TableCellCentered('Stage')}
        {TableCellCentered('Collaborateur')}
        {TableCellCentered('Coman')}
        {TableCellCentered('Comex')}
        <TableCell width={20}></TableCell>
      </TableRow>
    </StyledTableHead>
  );
}
