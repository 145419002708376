import { Stack, Tooltip, Typography } from '@mui/material';
import MissionTypeIcon from 'components/commons/MissionTypeIcon';
import { displaySecondaryInformation } from 'components/Phonebook/utils';
import {
  ActivitiesActivityBillingTypeChoices,
  ActivityNode,
} from 'generated/graphql';
import React from 'react';

interface DisplayMissionProps {
  mission: ActivityNode;
  isClientDisplayed?: boolean;
  missionTooltip?: boolean;
  textMaxWidth?: number;
}

function MissionTypography(
  mission: ActivityNode,
  isClientDisplayed: boolean,
  textMaxWidth: number
) {
  return (
    <Stack sx={{ flexDirection: 'column' }}>
      <Typography
        noWrap
        variant="bodySemiBold"
        color={'info.main'}
        sx={{
          maxWidth: textMaxWidth,
        }}
      >
        {mission.name}
      </Typography>
      {isClientDisplayed &&
        mission.client &&
        displaySecondaryInformation(mission.client)}
    </Stack>
  );
}

export default function DisplayMission({
  mission,
  isClientDisplayed = false,
  missionTooltip = true,
  textMaxWidth = 480,
}: DisplayMissionProps) {
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      width={'100%'}
    >
      <Stack direction={'row'} alignItems={'center'} spacing={1}>
        <MissionTypeIcon
          isTmContracts={
            mission.billingType === ActivitiesActivityBillingTypeChoices.Tm
          }
          sx={{ fontSize: '20px', color: 'info.main' }}
          type={mission.type}
          tooltip={true}
        />
        {missionTooltip ? (
          <Tooltip title={mission.name} placement="bottom-start" arrow>
            {MissionTypography(mission, isClientDisplayed, textMaxWidth)}
          </Tooltip>
        ) : (
          MissionTypography(mission, isClientDisplayed, textMaxWidth)
        )}
      </Stack>
    </Stack>
  );
}
