import PolyAlert from 'components/commons/PolyAlert';
import PolyFooter from 'components/MUIOverload/PolyFooter';
import PongoButton from 'components/MUIOverload/PongoButton';
import moment from 'moment';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { AuthorizationDataType } from '../ExceptionalActivitiesRequest/ExceptionalActivitiesRequestPanelManager';
import Comment from './Comment';
import LimitDate from './LimitDate';
import Mission from './Mission';
import OvertimeDate from './OvertimeDate';
import styles from './styles/lookup.module.scss';
import WorkedDuration from './WorkedDuration';

interface RequestLookupContentProps {
  queryData: AuthorizationDataType;
  action: string;
  handleOnClick: (action: string) => void;
  asManager: boolean;
}

const RequestLookupContent = ({
  queryData,
  action,
  handleOnClick,
  asManager,
}: RequestLookupContentProps) => {
  const isModifiable = queryData.status === 'Requested' && action === 'modify';
  const { errors } = useFormContext();

  const resolveButtons = () => {
    if (queryData.status !== 'Requested') {
      return;
    }
    return action === 'see' ? (
      <PolyFooter>
        <PongoButton
          variant={'contained'}
          buttonStyle="secondary"
          onClick={() => {
            handleOnClick('refuse');
          }}
          className={styles.spanningButton}
          type={'submit'}
        >
          {'Je refuse la demande'}
        </PongoButton>
        <PongoButton
          variant={'contained'}
          color={'primary'}
          className={styles.spanningButton}
          onClick={() => {
            handleOnClick('validate');
          }}
          type={'submit'}
        >
          {'Je valide la demande'}
        </PongoButton>
      </PolyFooter>
    ) : (
      <PolyFooter>
        <PongoButton
          variant={'contained'}
          color={'primary'}
          className={styles.singleButton}
          onClick={() => {
            handleOnClick('save');
          }}
          type={'submit'}
        >
          {'Enregistrer les modifications'}
        </PongoButton>
      </PolyFooter>
    );
  };

  return (
    <div className={styles.mainComponent}>
      <div className={styles.subTitleContainer}>
        <h2 className={styles.subTitle}>Informations</h2>
      </div>
      <Mission missionName={queryData.authorization.activity.name} />
      <Comment
        label={'Commentaire Autorisation'}
        name={'comment_manager'}
        comment={queryData.authorization.managerComment}
        disabled={true}
      />
      <OvertimeDate date={moment(queryData.overtimeDateAt, 'YYYY-MM-DD')} />
      <WorkedDuration
        defaultDuration={queryData.duration}
        disabled={!isModifiable}
      />
      <Comment
        label={'Commentaire du collaborateur'}
        name={'collaboratorComment'}
        comment={queryData.collaboratorComment}
        disabled={!isModifiable || (isModifiable && asManager)}
      />
      {(asManager || !isModifiable) && (
        <LimitDate defaultDate={queryData.expireAt} disabled={!isModifiable} />
      )}
      {action === 'see' && (
        <div>
          <Comment
            label={'Motif De Décision *'}
            comment={queryData.managerResponse}
            disabled={queryData.status !== 'Requested'}
            name={'reason'}
            require
          />
          {errors.reason && (
            <PolyAlert variant="outlined" severity="error">
              {'Merci de remplir le motif de décision'}
            </PolyAlert>
          )}
        </div>
      )}
      {resolveButtons()}
    </div>
  );
};

export default RequestLookupContent;
