import Box from '@mui/material/Box';
import PageWrapper from 'components/commons/PageWrapper';
import { useNavbarContextProvider } from 'components/Navigation/DesktopNavbar/NavbarContextProvider';
import PolySwitch from 'components/Navigation/PolySwitch';
import TopHorizontalSearchBar, {
  TOP_HORIZONTAL_SEARCH_BAR_HEIGHT,
} from 'components/Navigation/SearchMissionBar/TopHorizontalSearchBar';
import AccessWrapper from 'components/User/AccessWrapper';
import { EmployeeOccupations } from 'components/User/UserProvider';
import { EmployeesEmployeeGradeChoices } from 'generated/graphql';
import React from 'react';
import { Route, useLocation, useRouteMatch } from 'react-router-dom';

import ClientManagementPage from './ClientManagementPage';

const ClientPage = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const { drawerWidth } = useNavbarContextProvider();
  const businessClientsUrl = 'business_clients';
  const hideSearchbar = pathname.includes(businessClientsUrl);
  const topOffset = hideSearchbar ? '0px' : TOP_HORIZONTAL_SEARCH_BAR_HEIGHT;

  return (
    <Box>
      {!hideSearchbar && <TopHorizontalSearchBar />}
      <Box
        sx={{
          position: 'fixed',
          top: `calc(${topOffset})`,
          left: drawerWidth,
          width: `calc(100% - ${drawerWidth})`,
          height: `calc(100% - ${topOffset} )`,
          overflowX: 'auto',
          overflowY: 'scroll',
        }}
      >
        <PageWrapper noLimit>
          <PolySwitch>
            <AccessWrapper
              occupations={[
                EmployeeOccupations.CONTROL,
                EmployeeOccupations.DG,
              ]}
              restrictMissionChief
            >
              <PolySwitch>
                <Route path={path}>
                  <AccessWrapper
                    occupations={[EmployeeOccupations.CONTROL]}
                    grades={[EmployeesEmployeeGradeChoices.Comex]}
                    restrictMissionChief
                  >
                    <ClientManagementPage />
                  </AccessWrapper>
                </Route>
              </PolySwitch>
            </AccessWrapper>
          </PolySwitch>
        </PageWrapper>
      </Box>
    </Box>
  );
};

export default ClientPage;
