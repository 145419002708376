import { Box, Stack, SxProps, Typography } from '@mui/material';
import MissionNameDisplay from 'components/commons/MissionNameDisplay';
import { getUrlList } from 'components/Navigation/DesktopNavbar/utils';
import {
  ActivitiesActivityBillingTypeChoices,
  ActivityNode,
  AssignmentNode,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { POLY_DATE } from 'poly-constants';
import React from 'react';
import { useHistory } from 'react-router-dom';
import urljoin from 'url-join';
import { scrollbarParams } from 'utils';

import MissionsSkeleton from './MissionsSkeleton';
import NoMission from './NoMission';

interface MissionsListProps {
  activities?: ActivityNode[];
  assignments?: AssignmentNode[];
  sx?: SxProps;
  showTitle?: boolean;
  onClose?: () => void;
}
let key = 0;

export default function MissionsList({
  activities,
  assignments,
  sx,
  showTitle = true,
}: MissionsListProps) {
  const { activities: activitiesPath } = getUrlList();
  const history = useHistory();

  const handleSelectMission = (targetMission: ActivityNode) => {
    if (window.location.pathname !== '/phonebook') {
      return;
    }
    if (targetMission.id) {
      history.push({
        pathname: urljoin(
          activitiesPath,
          targetMission.id as string,
          'general_info'
        ),
        search: `?origin=${history.location.pathname}${history.location.search}`,
      });
    }
  };

  const activitiesDict = _.groupBy(activities, (activity) => {
    if (activity.client === null) {
      return 'Polyconseil';
    }
    return activity.client;
  });

  const getMostRecentAssignment = (
    assignments: AssignmentNode[]
  ): AssignmentNode | undefined => {
    return _.maxBy(assignments, (assignment) =>
      new Date(assignment.expirationDate).getTime()
    );
  };

  const transformAssignments = (assignments: AssignmentNode[] | undefined) => {
    const groupedAssignments = _.groupBy(
      assignments,
      (assignment) => assignment.activity?.id
    );
    return _.mapValues(groupedAssignments, getMostRecentAssignment);
  };

  const assignmentsByActivityId = transformAssignments(assignments);

  return (
    <Stack sx={sx}>
      {showTitle && <Typography variant={'bodyBold'}>Missions</Typography>}
      {activities && activities.length === 0 && <NoMission />}
      <Stack
        spacing={1}
        sx={{
          pt: showTitle ? 1 : 0,
          ...scrollbarParams,
        }}
      >
        {_.map(activitiesDict, (activitiesPerClient, client) => {
          const listActivities = _.map(
            activitiesPerClient,
            (activity, index) => {
              const assignment = (assignmentsByActivityId[
                activity.id
              ] as AssignmentNode) || {
                beginningDate: null,
                expirationDate: null,
              };
              const { beginningDate, expirationDate, staffingRate } =
                assignment;
              return (
                <Stack
                  key={index}
                  direction={'row'}
                  alignItems={'center'}
                  spacing={1}
                  sx={{ mt: 0.5 }}
                >
                  <Box
                    component={'span'}
                    onClick={() => handleSelectMission(activity)}
                    sx={{
                      cursor:
                        window.location.pathname === '/phonebook'
                          ? 'pointer'
                          : 'default',
                    }}
                  >
                    <MissionNameDisplay
                      mission={activity.name || ''}
                      type={activity.type}
                      isTmContracts={
                        activity.billingType ===
                        ActivitiesActivityBillingTypeChoices.Tm
                      }
                      haveBorder={false}
                    />
                  </Box>
                  <Typography
                    variant="body2"
                    color={'text.secondary'}
                    fontSize={12}
                  >
                    {beginningDate &&
                      expirationDate &&
                      `${moment(beginningDate).format(POLY_DATE)} - ${moment(
                        expirationDate
                      ).format(POLY_DATE)}`}
                    {staffingRate && ` (${staffingRate}\u00A0%)`}
                  </Typography>
                </Stack>
              );
            }
          );

          return (
            <Stack key={key++}>
              <Typography color={'text.secondary'}>{client}</Typography>
              {listActivities}
            </Stack>
          );
        })}
        {!activities && <MissionsSkeleton />}
      </Stack>
    </Stack>
  );
}
