import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { BorderedTableCell } from 'components/commons/Tables/FlatTable';
import _ from 'lodash';
import React from 'react';

export default function ContractualDaysTableHeader() {
  const weekDays = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi'];
  return (
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        {_.map(weekDays, (day) => {
          return (
            <BorderedTableCell sx={{ textAlign: 'center' }}>
              {day}
            </BorderedTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
