import { isInRange } from 'components/Revenue/Tables/util';
import { PurchaseOrderNode } from 'generated/graphql';
import _ from 'lodash';
import moment, { Moment } from 'moment';

export function isMonthEditableForActualRevenueTable(month: Moment) {
  const currentMonthBeginning = moment().startOf('month');
  return month.isSameOrAfter(currentMonthBeginning);
}

export function isMonthValidated(
  purchaseOrders: PurchaseOrderNode[],
  refMonth: Moment
): boolean {
  const purchaseOrdersOnMonth = _.filter(
    purchaseOrders,
    ({ periodBeginning, periodEnding }) =>
      isInRange(periodBeginning, periodEnding, refMonth)
  );
  // a month without purchase order is considered validated revenue wise
  if (purchaseOrdersOnMonth.length === 0) {
    return true;
  }
  return _.some(purchaseOrdersOnMonth, ({ revenues }) =>
    _.some(
      revenues,
      ({ month, validated }) =>
        moment(month).isSame(refMonth, 'month') && validated
    )
  );
}

export function isMonthAutoValidated(
  purchaseOrders: PurchaseOrderNode[],
  refMonth: Moment
): boolean {
  const purchaseOrdersOnMonth = _.filter(
    purchaseOrders,
    ({ periodBeginning, periodEnding }) =>
      isInRange(periodBeginning, periodEnding, refMonth)
  );
  return (
    purchaseOrdersOnMonth.length === 0 ||
    _.some(purchaseOrdersOnMonth, ({ revenues }) =>
      _.some(
        revenues,
        ({ month, isAutoValidated }) =>
          moment(month).isSame(refMonth, 'month') && isAutoValidated
      )
    )
  );
}
