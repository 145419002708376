import { DialogActions, DialogContent, Typography } from '@mui/material';
import PolyDialog from 'components/MUIOverload/PolyDialog';
import PolyModalHeader from 'components/MUIOverload/PolyDialog/DefaultComponents/PolyModalHeader';
import PongoButton from 'components/MUIOverload/PongoButton';
import React, { Dispatch, SetStateAction } from 'react';

interface ProviderCommentModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  comment?: string;
}

export default function ProviderCommentModal({
  open,
  setOpen,
  comment,
}: ProviderCommentModalProps) {
  return (
    <PolyDialog
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{ sx: { width: '600px' } }}
    >
      <DialogContent sx={{ padding: 2, paddingBottom: 1 }}>
        <PolyModalHeader
          handleClose={() => setOpen(false)}
          polyDialogTitle={'Justification temps supplémentaire'}
          sx={{ paddingBottom: 2 }}
        />
        <Typography>{comment}</Typography>
      </DialogContent>
      <DialogActions>
        <PongoButton onClick={() => setOpen(false)}>Fermer</PongoButton>
      </DialogActions>
    </PolyDialog>
  );
}
