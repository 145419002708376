import { Add } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Stack, Typography } from '@mui/material';
import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import PageWrapper from 'components/commons/PageWrapper';
import PurchaseOrderModal from 'components/commons/PurchaseOrderModal';
import { PoFormMode } from 'components/commons/PurchaseOrderModal/PurchaseOrderForm';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import PongoButton from 'components/MUIOverload/PongoButton';
import RevenueMissionTable from 'components/Revenue/ActualRevenueTables/RevenueMissionTable';
import TableContextProvider from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/context/TableContextProvider';
import ExportActualRevenueButton from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/ExportActualRevenueButton';
import {
  getPoMode,
  PoMode,
} from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/RevenueRows/PoRow';
import DisplayDirector from 'components/Revenue/Tables/DisplayDirector';
import { isInRange, RangeMonth } from 'components/Revenue/Tables/util';
import { useUserInfo } from 'components/User/UserProvider';
import {
  ActivitiesActivityBillingTypeChoices,
  EmployeeNode,
  PurchaseOrderNode,
  PurchaseOrderTaskNode,
  RevenuesForPoInput,
  useFetchAllRevenuesForActivityQuery,
  useSubmitRevenuesForActivityMutation,
  useValidateRevenuesForActivityMutation,
} from 'generated/graphql';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import { POLY_DATE_MONTH } from 'poly-constants';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { setCurrentPurchaseOrder } from 'store/purchaseOrder';
import { graphQlDateFormatter, isChiefOrDirectorOfActivity } from 'utils';

import { ActualRevenueTabHeader } from '.';

interface RevenueTypeSubmit {
  month: Moment;
  amount: number;
  validated: boolean;
  task?: PurchaseOrderTaskNode;
  comment?: string;
}

export interface RevenuesTypeForm {
  [month: string]: { amount: number; comment?: string; validated: boolean };
}

export interface RevenuesAdrTypeForm {
  [adrId: string]: RevenuesTypeForm;
}

function formatRevenuesToForm(
  revenues?: RevenueTypeSubmit[]
): RevenuesTypeForm | RevenuesAdrTypeForm {
  // yYJjZDAJDZAJDZZD==.06/2021.amount
  // id.mois.amount --> valeur de l'input dans le form
  const form: RevenuesTypeForm | RevenuesAdrTypeForm = _.reduce(
    revenues,
    (accumulator, revenue) => {
      const monthKey = moment(revenue.month).format(POLY_DATE_MONTH);
      const optionalId = `${revenue.task?.id || ''}`;
      if (optionalId) {
        return {
          ...accumulator,
          [optionalId]: {
            ..._.get(accumulator, optionalId),
            [`${monthKey}`]: {
              amount: revenue.amount,
              comment: revenue.comment,
              validated: revenue.validated,
            },
          },
        };
      }
      return {
        ...accumulator,
        [`${monthKey}`]: {
          amount: revenue.amount,
          comment: revenue.comment,
          validated: revenue.validated,
        },
      };
    },
    {}
  );
  return form;
}

function formatRevenuesFormToSubmit(
  revenues?: RevenuesTypeForm,
  month?: Moment,
  task?: PurchaseOrderTaskNode
) {
  if (month) {
    if (
      task &&
      !month?.isBetween(
        moment(task.periodBeginning),
        moment(task.periodEnding),
        'month',
        '[]'
      )
    ) {
      return [];
    }
    const monthKey = moment(month).format(POLY_DATE_MONTH);
    const revenueInput = revenues?.[monthKey];
    const amount =
      revenueInput?.amount || revenueInput?.amount === 0
        ? revenueInput?.amount
        : undefined;
    return [
      {
        task: task?.id,
        month: graphQlDateFormatter(month),
        amount: amount,
        comment: revenueInput?.comment,
      },
    ];
  }
  return _.flatMap(revenues, (input, key) => {
    const keyMonth = moment(key, POLY_DATE_MONTH);
    const amount =
      input.amount || input.amount === 0 ? input.amount : undefined;
    return !input.validated
      ? {
          task: task?.id,
          month: graphQlDateFormatter(keyMonth),
          amount: amount,
          comment: input.comment,
        }
      : [];
  });
}

export default function ActualRevenueMissionPage() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const currentMission = useSelector((state) => state.activity.currentMission);
  const missionId = currentMission?.id;
  const { employee, isControl, isSuperuser } = useUserInfo();
  const { currentYear, setCurrentYear } = useTableHeaderContext();

  const [openModal, setOpenModal] = useState(false);

  const isActivityChiefOrDirector = isChiefOrDirectorOfActivity(
    currentMission,
    employee as EmployeeNode
  );

  const [exceptionalMonth, setExceptionalMonth] = useState<
    Moment | undefined
  >();
  const [isFormSaving, setIsFormSaving] = useState(false);

  const isMissionEditable =
    isControl || isActivityChiefOrDirector || isSuperuser;

  const { loading, data, refetch } = useFetchAllRevenuesForActivityQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      activityId: missionId || '',
    },
  });

  const purchaseOrders = useMemo(() => {
    return (
      (data?.allRevenuesForActivity?.purchaseOrders as PurchaseOrderNode[]) ||
      []
    );
  }, [data?.allRevenuesForActivity?.purchaseOrders]);

  const activityEarliestPoStart = _.minBy(purchaseOrders, 'periodBeginning');
  const activityLatestPoEnd = _.maxBy(purchaseOrders, 'periodEnding');

  const [submitRevenuesForActivity] = useSubmitRevenuesForActivityMutation({
    onCompleted: () => {
      enqueueSnackbar('Données enregistrées', {
        variant: 'success',
      });
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const submitRevenues = async (revenuesByPo: RevenuesForPoInput[]) => {
    await submitRevenuesForActivity({
      variables: {
        activityId: missionId || '',
        revenuesByPo: revenuesByPo,
      },
    });
  };

  const [validateRevenuesForActivity] = useValidateRevenuesForActivityMutation({
    onCompleted: () => {
      enqueueSnackbar('Données validées', {
        variant: 'success',
      });
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const validateRevenues = async (revenuesByPo: RevenuesForPoInput[]) => {
    await validateRevenuesForActivity({
      variables: {
        activityId: missionId || '',
        revenuesByPo: revenuesByPo,
      },
    });
    await refetch();
  };
  const handleClickAdd = () => {
    dispatch(setCurrentPurchaseOrder({} as PurchaseOrderNode));
    setOpenModal(true);
  };

  const getTmContractHelper = () => {
    if (
      data?.allRevenuesForActivity?.activity?.billingType ===
      ActivitiesActivityBillingTypeChoices.Tm
    ) {
      return (
        <div>
          Les cellules vides sont préremplies avec la somme des TJMs du BDC
          multipliée par le nombre de jours ouvrés du mois.
        </div>
      );
    }
    return (
      <div>
        Les cellules vides sont préremplies avec le montant restant du bon de
        commande lissé sur le nombre de mois non validés restants.
      </div>
    );
  };

  const computeInputValues = useMemo(() => {
    // Format fetched revenue for default form
    const revenuesByPo: {
      [poId: string]: {
        revenues: RevenuesTypeForm | RevenuesAdrTypeForm;
      };
    } = _.reduce(
      purchaseOrders,
      (accumulator, po) => {
        return {
          ...accumulator,
          [po.id]: {
            revenues: formatRevenuesToForm(po.revenues),
          },
        };
      },
      {}
    );

    return {
      revenuesByPo: revenuesByPo,
      total: {} as { [month: string]: number },
    };
  }, [purchaseOrders]);

  const form = useForm({
    defaultValues: computeInputValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldUnregister: false,
  });
  const { reset } = form;
  useEffect(() => {
    reset(computeInputValues);
  }, [computeInputValues, reset]);

  function formatPoRevenuesFormToSubmit(
    po: PurchaseOrderNode,
    revenues: RevenuesTypeForm | RevenuesAdrTypeForm,
    month?: Moment
  ) {
    const poMode = getPoMode(currentMission, po);
    if (poMode === PoMode.SIMPLE) {
      return formatRevenuesFormToSubmit(revenues as RevenuesTypeForm, month);
    } else {
      let result: ReturnType<typeof formatRevenuesFormToSubmit> = [];
      for (const task of po.tasks || []) {
        result = [
          ...result,
          ...formatRevenuesFormToSubmit(
            revenues[task.id] as RevenuesTypeForm,
            month,
            task
          ),
        ];
      }
      return result;
    }
  }

  const convertFormToSubmit = (
    submitValues: typeof computeInputValues,
    month?: Moment
  ) => {
    return _.flatMap(purchaseOrders, (po) => {
      if (
        month &&
        !isInRange(
          po.periodBeginning,
          moment(po.periodEnding).add(1, 'months'),
          month
        )
      ) {
        return [];
      }
      return {
        purchaseOrderId: po.id,
        revenues: formatPoRevenuesFormToSubmit(
          po,
          submitValues.revenuesByPo[po.id].revenues,
          month
        ),
      };
    });
  };

  const onSave = async (submitValues: typeof computeInputValues) => {
    setIsFormSaving(true);
    const revenuesByPo: RevenuesForPoInput[] =
      convertFormToSubmit(submitValues);
    await submitRevenues(revenuesByPo);
    setIsFormSaving(false);
  };

  const onValidate = async (
    submitValues: typeof computeInputValues,
    month: Moment
  ) => {
    const revenuesByPo: RevenuesForPoInput[] = convertFormToSubmit(
      submitValues,
      month.startOf('month')
    );
    await validateRevenues(revenuesByPo);
  };

  const handleValidateMonth = async (month: Moment) => {
    await form.handleSubmit((data) => {
      onValidate(data, month);
    })();
  };

  const getRangeValidatedMonth = () => {
    let rangeValidatedMonths: RangeMonth | undefined;
    for (const purchaseOrder of purchaseOrders) {
      if (!purchaseOrder.revenues) {
        continue;
      }
      for (const revenue of purchaseOrder.revenues) {
        const month = moment(revenue.month);
        if (!rangeValidatedMonths) {
          rangeValidatedMonths = {
            from: month,
            to: month,
          };
        }
        if (month.isAfter(rangeValidatedMonths.to)) {
          rangeValidatedMonths.to = month;
        }
        if (month.isBefore(rangeValidatedMonths.from)) {
          rangeValidatedMonths.from = month;
        }
      }
    }
    return rangeValidatedMonths;
  };

  return (
    <PageWrapper missionView>
      <ActualRevenueTabHeader
        isDetailedView
        actionComponents={
          <>
            {isActivityChiefOrDirector && (
              <>
                <PongoButton
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={handleClickAdd}
                  startIcon={<Add />}
                >
                  Ajouter un BDC
                </PongoButton>
                <PurchaseOrderModal
                  open={openModal}
                  validCallBack={async () => await refetch()}
                  closeModal={() => setOpenModal(false)}
                  mode={PoFormMode.CREATE}
                />
              </>
            )}
            <ExportActualRevenueButton />
          </>
        }
      />
      {loading ? (
        <LoadingPlaceholder />
      ) : (
        <FormProvider {...form}>
          <form>
            <TableContextProvider
              exceptionalMonth={exceptionalMonth}
              setExceptionalMonth={setExceptionalMonth}
              rangeValidatedMonth={getRangeValidatedMonth()}
              resetValues={() => reset(computeInputValues)}
              validateMonth={handleValidateMonth}
              purchaseOrders={purchaseOrders}
              activity={currentMission}
              isMissionEditable={isMissionEditable}
              purchaseOrdersRange={{
                start: moment(activityEarliestPoStart?.periodBeginning),
                end: moment(activityLatestPoEnd?.periodEnding),
              }}
              currentYear={currentYear}
              setCurrentYear={setCurrentYear}
            >
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <DisplayDirector activity={currentMission} />
              </Stack>
              {!!data?.allRevenuesForActivity?.purchaseOrders?.length && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'left',
                    mt: 1,
                    mb: 3,
                  }}
                >
                  <InfoOutlinedIcon sx={{ mr: 1 }} />
                  <Typography variant="label">
                    {getTmContractHelper()}
                  </Typography>
                </Box>
              )}
              <RevenueMissionTable
                exceptionalMonth={exceptionalMonth}
                isMissionEditable={isMissionEditable}
                form={form}
                onSave={onSave}
                isFormSaving={isFormSaving}
              />
            </TableContextProvider>
          </form>
        </FormProvider>
      )}
    </PageWrapper>
  );
}
