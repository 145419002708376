import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import { FlatTable } from 'components/commons/Tables/FlatTable';
import TableHeaderContextProvider from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import ProfitabilityTableBody, {
  transformProfitsByClient,
} from 'components/Reporting/Profitability/GlobalView/ProfitabilityTableBody/ProfitabilityTableBody';
import ProfitabilityTableFooterMissions from 'components/Reporting/Profitability/GlobalView/ProfitabilityTableFooterMissions';
import ProfitabilityTableFooterSectors from 'components/Reporting/Profitability/GlobalView/ProfitabilityTableFooterSectors';
import ProfitabilityTableHeader from 'components/Reporting/Profitability/GlobalView/ProfitabilityTableHeader';
import { useUserInfo } from 'components/User/UserProvider';
import {
  ActivityNode,
  BillingClientNode,
  PublicHoliday,
  SectorNode,
  useActivitiesMonthProfitsQuery,
  useFetchYearPublicHolidaysQuery,
  useGetNonInitExternalMissionsClientQuery,
  useGetpolyconseilClientQuery,
  useYearToDateMonthWorkDaysLazyQuery,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'store';

export interface ReportingProfitabilityMonthlyTableProps {
  currentYear: moment.Moment;
  setCurrentYear: (newYear: moment.Moment) => void;
  isYearSubtracted: boolean;
  setIsYearSubtracted: (isYearSubtracted: boolean) => void;
  isYearAdded: boolean;
  setIsYearAdded: (isYearAdded: boolean) => void;
}
export default function ReportingProfitabilityMonthlyTable({
  currentYear,
  setCurrentYear,
  isYearSubtracted,
  setIsYearSubtracted,
  isYearAdded,
  setIsYearAdded,
}: ReportingProfitabilityMonthlyTableProps) {
  const { isControl, isComex, isSuperuser } = useUserInfo();
  const [yearToDateMonthWorkDays, setyearToDateMonthWorkDays] = useState(0);
  const [currentYearHolidays, setCurrentYearHolidays] = useState([]);

  const selectedMissions: ActivityNode[] = useSelector(
    (state) => state.activity.selectedMissions
  );
  const areMissionsFiltered: boolean = selectedMissions.length > 0;
  const selectedMissionsIds: string[] = _.map(
    selectedMissions,
    (mission) => mission.id
  );

  const { loading, data } = useActivitiesMonthProfitsQuery({
    variables: {
      year: currentYear.year(),
      needClientData: isControl || isComex || isSuperuser,
      needSectorData: isControl || isComex || isSuperuser,
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });
  const activities = data?.activitiesMonthProfits as ActivityNode[];

  const filteredMissions = (
    areMissionsFiltered && activities
      ? _.filter(activities, (activity) =>
          _.includes(selectedMissionsIds, activity.id)
        )
      : []
  ) as ActivityNode[];
  const { enqueueSnackbar } = useSnackbar();

  useFetchYearPublicHolidaysQuery({
    variables: { year: currentYear.year() },
    onCompleted: (data) => {
      setCurrentYearHolidays(data.publicHolidays as []);
    },
  });

  const polyconseilClient = useGetpolyconseilClientQuery({
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  }).data?.polyconseilClient;

  const nonInitExternalMissionsClient =
    useGetNonInitExternalMissionsClientQuery({
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
    }).data?.nonInitExternalMissionsClient;

  const profitsByClient = useMemo(() => {
    if (
      !data ||
      !polyconseilClient ||
      !nonInitExternalMissionsClient ||
      !data.clientsMonthProfits
    ) {
      return [];
    }
    return transformProfitsByClient(
      activities,
      data?.clientsMonthProfits as BillingClientNode[],
      polyconseilClient as BillingClientNode,
      nonInitExternalMissionsClient as BillingClientNode
    );
  }, [data, activities, polyconseilClient, nonInitExternalMissionsClient]);

  const [getYearToDateMonthWorkDays] = useYearToDateMonthWorkDaysLazyQuery({
    onCompleted: (data) => {
      setyearToDateMonthWorkDays(data?.yearToDateMonthWorkDays || 0);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });
  useEffect(() => {
    getYearToDateMonthWorkDays({
      variables: {
        month: moment(currentYear.toDate()).format('YYYY-MM-DD'),
      },
    });
  }, [currentYear, getYearToDateMonthWorkDays]);

  return (
    <>
      {!data || loading ? (
        <LoadingPlaceholder />
      ) : (
        <TableHeaderContextProvider
          currentYear={currentYear}
          setCurrentYear={setCurrentYear}
          monthRangeSize={1}
          monthOffset={0}
          isYearSubtracted={isYearSubtracted}
          isYearAdded={isYearAdded}
          globalView={true}
        >
          <FlatTable size={'small'}>
            <ProfitabilityTableHeader
              currentYear={currentYear}
              setCurrentYear={setCurrentYear}
              setIsYearSubtracted={setIsYearSubtracted}
              setIsYearAdded={setIsYearAdded}
            />
            <ProfitabilityTableBody
              currentYear={currentYear}
              profitsBySectors={data?.sectorsMonthProfits as SectorNode[]}
              profitsByClient={profitsByClient}
              activities={areMissionsFiltered ? filteredMissions : activities}
              polyconseilClient={polyconseilClient as BillingClientNode}
              nonInitExternalMissionsClient={
                nonInitExternalMissionsClient as BillingClientNode
              }
              filteredMission={areMissionsFiltered}
              publicHolidays={currentYearHolidays as PublicHoliday[]}
              yearToDateMonthWorkDays={yearToDateMonthWorkDays}
            />
            {!areMissionsFiltered &&
              (isControl || isComex || isSuperuser) &&
              !!data?.sectorsMonthProfits?.length && (
                <ProfitabilityTableFooterSectors
                  profitsBySectors={data?.sectorsMonthProfits as SectorNode[]}
                  isTrimesterView={false}
                  publicHolidays={currentYearHolidays as PublicHoliday[]}
                  yearToDateMonthWorkDays={yearToDateMonthWorkDays}
                />
              )}
            {(areMissionsFiltered || !(isControl || isComex || isSuperuser)) &&
              (areMissionsFiltered
                ? filteredMissions.length > 1
                : activities.length > 1) && (
                <ProfitabilityTableFooterMissions
                  profitsByMissions={
                    areMissionsFiltered ? filteredMissions : activities
                  }
                  isTrimesterView={false}
                  publicHolidays={currentYearHolidays as PublicHoliday[]}
                  yearToDateMonthWorkDays={yearToDateMonthWorkDays}
                />
              )}
          </FlatTable>
        </TableHeaderContextProvider>
      )}
    </>
  );
}
