import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { useTableContext } from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/context/TableContextProvider';
import { Moment } from 'moment';
import React from 'react';

import styles from './styles/ValidationModal.module.scss';

interface EditValidationButtonProps {
  month: Moment;
}
export const EditValidationButton = ({ month }: EditValidationButtonProps) => {
  const { setExceptionalMonth, resetValues } = useTableContext();

  return (
    <div className={styles.editContainer}>
      <IconButton
        onClick={() => {
          resetValues();
          setExceptionalMonth(month);
        }}
        className={styles.checkIconButton}
        title={'Modifier ce mois'}
        size="large"
      >
        <EditIcon sx={{ height: '17px', width: '17px' }} />
      </IconButton>
    </div>
  );
};
