import { Box, Grid, Stack, Typography } from '@mui/material';
import InfoTooltip from 'components/commons/InfoTooltip';
import SectionTitle from 'components/commons/SectionTitle';
import GridSubTitle from 'components/MissionFollowUp/GridSubTitle';
import { ActivityNode } from 'generated/graphql';
import moment from 'moment';
import { POLY_DATE } from 'poly-constants';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import SubActivitiesFields from './SubActivitiesFields';

export default function ExternalActivitySubActivitiesForm(): JSX.Element {
  const { control, getValues } = useFormContext();

  const subActivitiesFieldArray = useFieldArray({
    control,
    keyName: 'key',
    name: 'subActivities',
  });

  function getMainActivityDatesString() {
    const mainActivityValues = getValues() as ActivityNode;
    return `Du ${moment(mainActivityValues.startDate).format(
      POLY_DATE
    )} au ${moment(mainActivityValues.expirationDate).format(POLY_DATE)}`;
  }

  return (
    <Grid container>
      <GridSubTitle customspacingtop={20}>
        <Stack direction="row" alignItems={'center'} spacing={1}>
          <SectionTitle variant="h3Bold">Découpage de la mission</SectionTitle>
          <InfoTooltip
            title={
              'Découper votre mission en sous-missions permettra à vos collaborateurs de déclarer du ' +
              'temps sur celles-ci au lieu de créer plusieurs missions sur PYS.' +
              'Vous aurez aussi la possibilité de facturer sur base des sous-missions si vous le souhaitez.'
            }
          />
        </Stack>
        <Typography color="textSecondary" fontSize={14} ml={1.7} mt={1}>
          {getMainActivityDatesString()}
        </Typography>
      </GridSubTitle>
      <Box sx={{ width: '100%', pb: 1, px: 1.5 }}>
        <SubActivitiesFields
          fields={subActivitiesFieldArray.fields}
          append={subActivitiesFieldArray.append}
          remove={subActivitiesFieldArray.remove}
        />
      </Box>
    </Grid>
  );
}
