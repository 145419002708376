import { Person } from '@mui/icons-material';
import { Autocomplete, Box, Grid, InputAdornment } from '@mui/material';
import { DateRangePicker } from 'components/commons/DateRangePicker';
import MissionTypeIcon from 'components/commons/MissionTypeIcon';
import PageContent from 'components/commons/PageContent';
import PageWrapper from 'components/commons/PageWrapper';
import PolyTitle from 'components/commons/PolyTitle';
import GridItem from 'components/MissionFollowUp/GridItem';
import LabelTextFieldGrid from 'components/MissionFollowUp/LabelTextFieldGrid';
import PolyFooter from 'components/MUIOverload/PolyFooter';
import PongoButton from 'components/MUIOverload/PongoButton';
import {
  ActivitiesActivityBillingTypeChoices,
  useAllAssignmentsByEmployeeLazyQuery,
  useAllChiefedActivitiesQuery,
  useCreateOvertimeAuthorizationMutation,
  useEmployeeAuthorizedToDoOvertimesLazyQuery,
} from 'generated/graphql';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { ExceptionalActivitiesTitles } from 'poly-constants';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import { displayEmployee, graphQlDateFormatter } from 'utils';

import styles from './styles/OvertimeAuthorization.module.scss';

interface InputForms {
  selectedDates: [Date, Date];
  selectedMission: ActivityOption;
  selectedCollaborator: EmployeeOption;
  description: string;
}

interface ActivityOption {
  name: string;
  id: string;
  type: string;
  isTmContracts: boolean;
}

interface EmployeeOption {
  value: string;
  id: string;
}

interface DateRangeOption {
  beginningDate: Date;
  endingDate: Date;
}

const isEmpty = (object: EmployeeOption | DateRangeOption) => {
  return Object.entries(object).length === 0;
};

const OvertimeAuthorization = () => {
  const [allActivities, setAllActivities] =
    useState<Map<string, ActivityOption>>();
  const [allEmployees, setAllEmployees] =
    useState<Map<string, EmployeeOption>>();
  const [selectedActivity, setSelectedActivity] = useState<
    ActivityOption | undefined
  >();
  const [selectedCollaborator, setSelectedCollaborator] =
    useState<EmployeeOption>({} as EmployeeOption);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeOption>();
  const [selectedAssignmentRange, setSelectedAssignmentRange] = useState<
    DateRangeOption | undefined
  >();
  const [isRedirect, setRedirect] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleDateRangeChange = (dateRange?: [Date, Date]) => {
    dateRange
      ? setSelectedDateRange({
          beginningDate: dateRange[0],
          endingDate: dateRange[1],
        })
      : setSelectedDateRange({} as DateRangeOption);
  };

  useAllChiefedActivitiesQuery({
    onCompleted: (data) => {
      if (data?.allChiefedActivities) {
        const allActivitiesMap = new Map();
        for (const activity of data.allChiefedActivities) {
          allActivitiesMap.set(activity.name, {
            name: activity.name,
            id: activity.id,
            type: activity.type,
            isTmContracts:
              activity.billingType === ActivitiesActivityBillingTypeChoices.Tm,
          });
        }
        setAllActivities(allActivitiesMap);
      }
    },
    onError: () => {
      enqueueSnackbar('Erreur lors de la récupération des missions', {
        variant: 'error',
      });
    },
  });

  const [getEmployees, { loading: employeeLoading }] =
    useEmployeeAuthorizedToDoOvertimesLazyQuery({
      onCompleted: (data) => {
        if (data?.employeesAuthorizedToDoOvertimes && selectedActivity) {
          const AllEmployeesMap = new Map();
          for (const employee of data.employeesAuthorizedToDoOvertimes) {
            const value = displayEmployee(
              employee.firstName,
              employee.lastName
            );
            AllEmployeesMap.set(value, {
              value: value,
              id: employee.id,
            });
          }
          setAllEmployees(AllEmployeesMap);
        }
      },
    });

  const [getAssignmentDateRange, { loading: dateRangeLoading }] =
    useAllAssignmentsByEmployeeLazyQuery({
      onCompleted: (data) => {
        if (data?.allAssignmentsByEmployee && !isEmpty(selectedCollaborator)) {
          const dateAssignmentRange = data.allAssignmentsByEmployee.map(
            (assignments) => {
              return {
                beginning: assignments.beginningDate,
                ending: assignments.expirationDate,
              };
            }
          );
          if (dateAssignmentRange) {
            setSelectedAssignmentRange({
              beginningDate: dateAssignmentRange[0].beginning,
              endingDate:
                dateAssignmentRange[dateAssignmentRange.length - 1].ending,
            });
          }
        }
      },
    });

  const defaultValues = {
    selectedDates: [moment().toDate(), moment().toDate()],
    selectedMission: selectedActivity,
    selectedCollaborator: {} as EmployeeOption,
    description: '',
  };

  const onSubmit = async (data: typeof defaultValues) => {
    await createOvertimeAuthorization({
      variables: {
        activity:
          allActivities?.get(data.selectedMission?.toString() || '')?.id || '',
        employee:
          allEmployees?.get(data.selectedCollaborator?.toString() || '')?.id ||
          '',
        periodStart: graphQlDateFormatter(
          selectedDateRange?.beginningDate
            ? selectedDateRange?.beginningDate
            : moment().toDate()
        ),
        periodEnd: graphQlDateFormatter(
          selectedDateRange?.endingDate
            ? selectedDateRange?.endingDate
            : moment().toDate()
        ),
        managerComment: data.description,
      },
    });
  };

  const { register, handleSubmit, control } = useForm<InputForms>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: defaultValues,
  });

  const [createOvertimeAuthorization] = useCreateOvertimeAuthorizationMutation({
    onCompleted: () => {
      enqueueSnackbar('Données enregistrées', { variant: 'success' });
      setRedirect(true);
    },
    onError: () => {
      enqueueSnackbar("Erreurs lors de la création de l'autorisation", {
        variant: 'error',
      });
    },
  });

  const activityOptions: ActivityOption[] = allActivities
    ? [...allActivities.values()]
    : [];

  return (
    <>
      {isRedirect && (
        <Redirect
          to="/mission-followup/activity/exceptional-activities/authorization_granted"
          push={true}
        />
      )}
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <PageWrapper noLimit>
          <PolyTitle>{ExceptionalActivitiesTitles.authorization}</PolyTitle>
          <PageContent>
            <div className={styles.pageDescription}>
              <p className={styles.descriptionParagraph}>
                {"Ici, vous pouvez autoriser un ou des collaborateurs à réaliser une demande d'activité exceptionnelle.\n" +
                  'Pour rappel : le temps de travail contractuel est de 39h hebdomadaires (35h pour les stagiaires et alternants).'}
              </p>
              <p className={styles.descriptionParagraph}>
                {'Pour compenser les heures qui pourraient être effectuées en dépassement de ces 39h hebdomadaires, 8 jours de RTT sont attribués aux collaborateurs (tel que prévu à l’article 3.2 de l’accord collectif relatif à l’aménagement du temps de travail).\n' +
                  'Les stagiaires et les alternants ne sont pas concernés par les RTT.'}
              </p>
              <p className={styles.descriptionParagraph}>
                {'Dans le cadre d’heures effectuées, à titre exceptionnel, le soir et le samedi, ces heures pourront donner lieu à récupération.\n' +
                  'Cette activité dite exceptionnelle est saisissable au quart de jour (0,25). Les journées de récupération devront ensuite être posées dans un délai maximum de 30 jours.'}
              </p>
              <p className={styles.descriptionParagraph}>
                {
                  "Il est important d'être vigilant sur les périodes d'autorisations accordées : les collaborateurs pourront saisir librement des temps d'activité exceptionnelle sur cette base. L'activité exceptionnelle doit faire l'objet d'une discussion préalable entre le manager et le collaborateur. "
                }
              </p>
            </div>
          </PageContent>
          <Grid container spacing={0}>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Autocomplete
                  id={'selected-mission'}
                  data-testid={'selected-mission'}
                  autoComplete
                  autoHighlight
                  options={activityOptions}
                  isOptionEqualToValue={(
                    option: ActivityOption,
                    value: ActivityOption
                  ) => (option ? value.id === option.id : true)}
                  getOptionLabel={(mission: ActivityOption) => mission.name}
                  renderOption={(props, option: ActivityOption) => (
                    <Box component="li" {...props}>
                      <MissionTypeIcon
                        type={option.type}
                        isTmContracts={option.isTmContracts}
                        className={styles.autocompleteIcon}
                      />
                      {option.name}
                    </Box>
                  )}
                  onChange={(_, value) => {
                    setSelectedCollaborator({} as EmployeeOption);
                    setSelectedActivity(value ? value : undefined);
                    if (value) {
                      getEmployees({
                        variables: {
                          activityId: value.id,
                        },
                      });
                    }
                  }}
                  renderInput={(parameters) => (
                    <LabelTextFieldGrid
                      required
                      title="Mission associée"
                      sizegrid={12}
                      name={'selectedMission'}
                      {...parameters}
                      inputRef={register({ required: true })}
                      InputProps={{
                        ...parameters.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <MissionTypeIcon
                              type={
                                selectedActivity ? selectedActivity.type : 'EXT'
                              }
                              isTmContracts={selectedActivity?.isTmContracts}
                              className={styles.personIcon}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                {selectedActivity && !employeeLoading && (
                  <Autocomplete
                    id={'selected-collaborator'}
                    data-testid={'selected-collaborator'}
                    autoComplete
                    autoHighlight
                    options={allEmployees ? [...allEmployees.values()] : []}
                    getOptionLabel={(employee: EmployeeOption) =>
                      employee.value
                    }
                    isOptionEqualToValue={(
                      option: EmployeeOption,
                      value: EmployeeOption
                    ) => (option ? value.id === option.id : true)}
                    onChange={(_, value: EmployeeOption | null) => {
                      handleDateRangeChange();
                      setSelectedCollaborator(
                        value ? value : ({} as EmployeeOption)
                      );
                      if (value) {
                        getAssignmentDateRange({
                          variables: {
                            activityId: selectedActivity.id,
                            employeeId: value.id,
                          },
                        });
                      }
                    }}
                    renderInput={(parameters) => (
                      <LabelTextFieldGrid
                        required
                        title="Collaborateur"
                        sizegrid={12}
                        name={'selectedCollaborator'}
                        {...parameters}
                        inputRef={register}
                        InputProps={{
                          ...parameters.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <Person className={styles.personIcon} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={6} className={styles.dateRange}>
                {selectedActivity &&
                  !isEmpty(selectedCollaborator) &&
                  !dateRangeLoading && (
                    <GridItem customspacingside={40} customspacingtop={0}>
                      <Box width="400px">
                        <DateRangePicker
                          dateRange={[moment().toDate(), moment().toDate()]}
                          disableFuture={false}
                          handleDateRangeChange={handleDateRangeChange}
                          minDate={selectedAssignmentRange?.beginningDate}
                          maxDate={selectedAssignmentRange?.endingDate}
                        />
                      </Box>
                    </GridItem>
                  )}
              </Grid>
            </Grid>
            <GridItem title="Commentaire autorisation">
              <Controller
                control={control}
                name={'description'}
                render={({ onChange }) => (
                  <textarea
                    className={styles.descriptionField}
                    name={'description'}
                    onChange={onChange}
                  />
                )}
              />
            </GridItem>
          </Grid>
        </PageWrapper>
        <PolyFooter>
          <PongoButton
            disabled={
              !selectedActivity ||
              isEmpty(selectedCollaborator) ||
              isEmpty(
                selectedDateRange ? selectedDateRange : ({} as DateRangeOption)
              )
            }
            type={'submit'}
            variant={'contained'}
            color={'primary'}
          >
            VALIDER
          </PongoButton>
        </PolyFooter>
      </form>
    </>
  );
};

export default OvertimeAuthorization;
