import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import TextFieldNumberFormat from 'components/commons/TextFieldFormat/TextFieldNumberFormat';
import PolyGridInputForm from 'components/MissionFollowUp/PolyGridInputForm';
import { handleSuggestionOnEnter } from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/RevenueTableCells/CellTypes/RevenueCellInput';
import { verifyEmployeePermissions } from 'components/Revenue/Estimated/utils';
import { useUserInfo } from 'components/User/UserProvider';
import {
  ActivityNode,
  BillingPurchaseOrderCurrencyChoices,
  EmployeesEmployeeGradeChoices,
} from 'generated/graphql';
import { totalFormat } from 'pages/ActivityPage/utils';
import { isNumberNullOrPositive } from 'pages/MissionFollowUp/formValidators';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

interface EstimatedObjectiveFormProps {
  activity?: ActivityNode;
  objective?: number;
  company?: boolean;
  sx?: SxProps;
}
export const EstimatedObjectiveForm = (props: EstimatedObjectiveFormProps) => {
  const [isEdit, setEdit] = useState(false);
  const { objective, company, activity } = props;
  const { employee, isDG } = useUserInfo();

  const isComex = employee?.grade === EmployeesEmployeeGradeChoices.Comex;
  const { isDirector, isChief } = verifyEmployeePermissions(activity, employee);

  const form = useFormContext();
  useEffect(() => {
    form.setValue('objective', objective || '');
  }, [form, objective]);

  return (
    <Stack direction={'row'} sx={{ mb: 4, clear: 'both', ...props.sx }}>
      <Typography mr={1} fontSize={20}>
        {`Objectif annuel ${company ? 'Polyconseil' : ''} :`}
      </Typography>
      {isEdit ? (
        <React.Fragment>
          <PolyGridInputForm
            hiddenLabel
            formName={'objective'}
            onKeyPress={handleSuggestionOnEnter}
            defaultValue={objective || ''}
            InputProps={{
              inputComponent: TextFieldNumberFormat as never,
            }}
            inputProps={{
              sx: { p: 1, textAlign: 'center' },
              currency: BillingPurchaseOrderCurrencyChoices.Eur,
            }}
            validateRules={{
              validate: {
                isNumberPositive: isNumberNullOrPositive,
              },
            }}
          />
          <IconButton
            size="small"
            onClick={() => {
              setEdit(false);
            }}
          >
            <CheckIcon />
          </IconButton>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography fontWeight={'bold'} fontSize={20}>
            {objective
              ? totalFormat(objective, BillingPurchaseOrderCurrencyChoices.Eur)
              : 'N/A'}
          </Typography>
          {((company && (isDG || isComex)) ||
            (!company && (isDirector || isChief))) && (
            <IconButton
              sx={{ ml: 1 }}
              size="small"
              type={'submit'}
              onClick={() => {
                setEdit(true);
              }}
              color="primary"
            >
              <EditIcon />
            </IconButton>
          )}
        </React.Fragment>
      )}
    </Stack>
  );
};
