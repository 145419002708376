import LaunchIcon from '@mui/icons-material/Launch';
import LinkIcon from '@mui/icons-material/Link';
import { Chip, Link, Tooltip } from '@mui/material';
import PongoButton from 'components/MUIOverload/PongoButton';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import React from 'react';

const DeclareAbsenceChip = () => {
  const GOOGLE_CALENDAR_LINK = useEnvironmentVariable('GOOGLE_CALENDAR_LINK');
  const toolTipContent =
    "Les informations sur vos absences doivent être renseignées à ce lien en tant qu'événement. Pour une demi-journée, rentrez un événement de 9h à 12h (matin) ou de 13h à 18h (après-midi).";

  const ABSENCES_LINK = '/my-activity/absences';
  const ABSENCES_FEATURE_FLAG = useEnvironmentVariable('absences');

  return (
    <>
      {ABSENCES_FEATURE_FLAG ? (
        <Link
          href={ABSENCES_LINK}
          target={'_blank'}
          rel={'noopener noreferrer'}
        >
          <PongoButton
            variant="contained"
            buttonStyle="secondary"
            color="primary"
            endIcon={<LaunchIcon />}
            uppercase={false}
            sx={{ height: '100%' }}
          >
            Saisir ses absences
          </PongoButton>
        </Link>
      ) : (
        GOOGLE_CALENDAR_LINK !== '' && (
          <Tooltip
            title={`${String.fromCodePoint(128279)} ${toolTipContent}`}
            arrow
          >
            <Chip
              icon={<LinkIcon />}
              component="a"
              label="Saisir ses absences"
              variant="outlined"
              color="info"
              size="small"
              href={GOOGLE_CALENDAR_LINK}
              target={'_blank'}
              rel={'noopener noreferrer'}
              clickable
            />
          </Tooltip>
        )
      )}
    </>
  );
};

export default DeclareAbsenceChip;
