import { Typography } from '@mui/material';
import React, { HTMLAttributes } from 'react';

const PageTitle = (properties: HTMLAttributes<HTMLHeadingElement>) => {
  return (
    <Typography
      variant="h1Bold"
      className={properties.className}
      sx={{ mt: 2.5, mb: -1.25 }}
      component="h1"
    >
      {properties.children}
    </Typography>
  );
};

export default PageTitle;
