import { Grid } from '@mui/material';
import InfoTooltip from 'components/commons/InfoTooltip';
import SectionTitle from 'components/commons/SectionTitle';
import GridSubTitle from 'components/MissionFollowUp/GridSubTitle';
import LabelTextFieldGrid from 'components/MissionFollowUp/LabelTextFieldGrid';
import {
  checkEmailFormat,
  requiredForm,
} from 'pages/MissionFollowUp/formValidators';
import React from 'react';

interface MissionContactFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: any;
}

const MissionContactForm = ({ errors, register }: MissionContactFormProps) => {
  return (
    <Grid container spacing={0} sx={{ paddingBottom: 2 }}>
      <GridSubTitle customspacingtop={20}>
        <SectionTitle variant="h3Bold">Contact</SectionTitle>
      </GridSubTitle>

      <GridSubTitle>Contact client opérationnel</GridSubTitle>
      <Grid container item xs={12}>
        <LabelTextFieldGrid
          inputRef={register({ required: requiredForm })}
          name={'contactName'}
          errorform={errors.contactName}
          required
          title="Nom"
        />
        <LabelTextFieldGrid
          inputRef={register({
            required: requiredForm,
            validate: {
              invalidFormatEmail: (email: string) => checkEmailFormat(email),
            },
          })}
          name={'contactEmail'}
          errorform={errors.contactEmail}
          required
          title="Adresse Mail"
        />
        <LabelTextFieldGrid
          inputRef={register}
          name={'contactPhone'}
          title="Numéro de téléphone"
        />
      </Grid>
      <GridSubTitle>
        Contact facturation client
        <InfoTooltip
          title={'Votre interlocuteur privilégié pour la facturation'}
        />
      </GridSubTitle>
      <Grid container item xs={12}>
        <LabelTextFieldGrid
          inputRef={register({ required: requiredForm })}
          name={'accountantName'}
          errorform={errors.accountantName}
          required
          title="Nom"
        />
        <LabelTextFieldGrid
          inputRef={register({
            required: requiredForm,
            validate: {
              invalidFormatEmail: (email: string) => checkEmailFormat(email),
            },
          })}
          name={'accountantEmail'}
          errorform={errors.accountantEmail}
          required
          title="Adresse Mail"
        />
        <LabelTextFieldGrid
          inputRef={register}
          name={'accountantPhone'}
          title="Numéro de téléphone"
        />
      </Grid>
    </Grid>
  );
};

export default MissionContactForm;
