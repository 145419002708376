import { Typography } from '@mui/material';
import React, { HTMLAttributes } from 'react';

const PolySubTitle = (properties: HTMLAttributes<HTMLHeadingElement>) => {
  return (
    <Typography variant={'bodyBold'} className={properties.className}>
      {properties.children}
    </Typography>
  );
};

export default PolySubTitle;
