import {
  ButtonBase,
  Stack,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import { getWeekSummaryTextColor } from 'components/ActivityPage/utils';
import { ColumnLabelsV2 } from 'components/commons/Tables/utils';
import { useUserInfo } from 'components/User/UserProvider';
import moment from 'moment';
import { getIsTotalInvalid } from 'pages/ActivityPage/PrincipalTablePage/TotalRow';
import {
  dotToComa,
  hasUpdateAMAuthValid,
  isEditableCellV2,
  totalDaysToWorkV2,
} from 'pages/ActivityPage/utils';
import { WEEK_DATE } from 'poly-constants';
import React from 'react';
import { OpacitySuffix } from 'utils';

interface ActivityWeekCardProps {
  week: ColumnLabelsV2;
  daysWorked: number;
  loading: boolean;
  sx?: SxProps;
  showBorder?: boolean;
  onClick?: () => void;
}

const ActivityWeekCard = ({
  week,
  daysWorked,
  loading,
  sx,
  showBorder = false,
  onClick,
}: ActivityWeekCardProps) => {
  const { palette, typography } = useTheme();
  const { employee } = useUserInfo();
  const isCurrentWeek = moment().isSame(week.start, 'week');
  const isBeforeCurrentWeek = week.start < moment().startOf('weeks');
  const daysToWork = totalDaysToWorkV2(employee, week.start, week.end);
  const isTotalInvalid = getIsTotalInvalid(
    daysWorked,
    daysToWork,
    week,
    employee
  );
  const isEditable = isEditableCellV2(employee, moment(), week.start, week.end);
  const isAutorizedToUpdateAM =
    isEditable &&
    hasUpdateAMAuthValid(
      employee?.updateAuthorization,
      employee?.updateAuthorization?.months,
      week.start
    );
  const textColor = getWeekSummaryTextColor(
    isEditable,
    isAutorizedToUpdateAM,
    isCurrentWeek,
    isBeforeCurrentWeek,
    isTotalInvalid
  );

  const weekLabel =
    'Semaine ' +
    (week.weekPart ? week.weekNumber + ' - ' + week.weekPart : week.weekNumber);

  return (
    <ButtonBase
      aria-label={weekLabel}
      onClick={onClick}
      disableTouchRipple={!onClick}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 88,
        width: 'inherit',
        my: 1.5,
        px: 1.5,
        backgroundColor:
          !loading && isCurrentWeek
            ? palette.primary.light + OpacitySuffix.FIFTY_PERCENT
            : palette.standardGrey.dark + OpacitySuffix.FIFTY_PERCENT,
        borderRadius: '4px',
        border: !loading && (isCurrentWeek || showBorder) ? '2px solid' : '',
        borderColor: showBorder ? textColor : palette.primary.main,
        fontFamily: typography.fontFamily,
        ...sx,
      }}
    >
      {!loading && (
        <>
          <Stack direction="column" alignItems="flex-start">
            <Typography variant="bodyBold" color={textColor}>
              {weekLabel}
            </Typography>
            <Typography variant="bodySBold" color="text.secondary">
              {week.start.format(WEEK_DATE)} au {week.end.format(WEEK_DATE)}
            </Typography>
          </Stack>
          <Typography variant="bodyBold" color={textColor}>
            {(daysWorked ? dotToComa(daysWorked) : '-') +
              (!isEditable ? '' : '/' + daysToWork)}
          </Typography>
        </>
      )}
    </ButtonBase>
  );
};

export default ActivityWeekCard;
