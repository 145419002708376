import { RangeMonth } from 'components/Revenue/Tables/util';
import { ActivityNode, PurchaseOrderNode } from 'generated/graphql';
import moment, { Moment } from 'moment';
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { functionNotSet } from 'utils';

export interface TableContextProps {
  resetValues: () => void;
  displayedMonths: Moment[];
  incrementMonth: () => void;
  decrementMonth: () => void;
  exceptionalMonth: Moment | undefined;
  setExceptionalMonth: (month?: Moment) => void;
  rangeValidatedMonth: RangeMonth | undefined;
  setRangeValidatedMonth: (months?: RangeMonth | undefined) => void;
  dateRange: { start: Moment; end: Moment };
  setDateRange: (p: { start: Moment; end: Moment }) => void;
  totalMission: { [month: string]: number };
  setTotalMission: (p: { [month: string]: number }) => void;
  purchaseOrders: PurchaseOrderNode[];
  activity: ActivityNode;
  validateMonth: (month: Moment) => Promise<void>;
  isMissionEditable: boolean;
  purchaseOrdersRange: {
    start: Moment;
    end: Moment;
  };
  currentYear: Moment;
  setCurrentYear: (year: Moment) => void;
}

export interface TableContextProviderProps {
  children?: React.ReactNode;
  resetValues: () => void;
  exceptionalMonth: Moment | undefined;
  setExceptionalMonth: (month?: Moment) => void;
  rangeValidatedMonth: RangeMonth | undefined;
  purchaseOrders: PurchaseOrderNode[];
  activity: ActivityNode;
  isMissionEditable: boolean;
  purchaseOrdersRange: {
    start: Moment;
    end: Moment;
  };
  validateMonth: (month: Moment) => void;
  currentYear: Moment;
  setCurrentYear: (p: Moment) => void;
}

const TableContext = createContext<TableContextProps>({
  resetValues: () => functionNotSet(),
  displayedMonths: [],
  incrementMonth: () => functionNotSet(),
  decrementMonth: () => functionNotSet(),
  exceptionalMonth: undefined,
  setExceptionalMonth: () => functionNotSet(),
  rangeValidatedMonth: undefined,
  setRangeValidatedMonth: () => functionNotSet(),
  dateRange: { end: moment(), start: moment() },
  setDateRange: () => functionNotSet(),
  totalMission: {},
  setTotalMission: () => functionNotSet(),
  validateMonth: () => functionNotSet(),
  purchaseOrders: [],
  isMissionEditable: false,
  activity: {} as ActivityNode,
  purchaseOrdersRange: {
    start: moment(),
    end: moment(),
  },
  currentYear: moment(),
  setCurrentYear: () => functionNotSet(),
});

export const useTableContext = () => {
  const tableContext = useContext(TableContext);
  if (!tableContext) {
    throw new Error('useContext() can only be used  inside a table');
  }
  return tableContext;
};

const defaultDateRange = {
  start: moment().subtract(3, 'months'),
  end: moment().add(2, 'months'),
};

const TableContextProvider = (
  properties: PropsWithChildren<TableContextProviderProps>
) => {
  const [dateRange, setDateRange] = useState(defaultDateRange);

  const [totalMission, setTotalMission] = useState<{ [month: string]: number }>(
    {}
  );

  const [rangeValidatedMonth, setRangeValidatedMonth] = useState<
    RangeMonth | undefined
  >(properties.rangeValidatedMonth);

  const incrementMonth = () => {
    const start = dateRange.start.clone().add(1, 'month');
    const end = dateRange.end.clone().add(1, 'month');
    setDateRange({
      start: start,
      end: end,
    });
    if (properties.currentYear.year() === start.year()) {
      return;
    }
    properties.setCurrentYear(properties.currentYear.clone().add(1, 'year'));
  };

  const decrementMonth = () => {
    const start = dateRange.start.clone().subtract(1, 'month');
    const end = dateRange.end.clone().subtract(1, 'month');
    setDateRange({
      start: start,
      end: end,
    });
    if (properties.currentYear.year() === start.year()) {
      return;
    }
    properties.setCurrentYear(
      properties.currentYear.clone().subtract(1, 'year')
    );
  };

  const getDisplayedMonths = useCallback(() => {
    const months: Moment[] = [];
    const start = dateRange.start.clone();

    while (
      dateRange.end > start ||
      start.format('M') === dateRange.end.format('M')
    ) {
      months.push(start.clone());
      start.add(1, 'month');
    }

    return months;
  }, [dateRange.end, dateRange.start]);

  const [displayedMonths, setDisplayedMonths] = useState<Moment[]>(
    getDisplayedMonths()
  );
  useEffect(() => {
    if (dateRange.start.year() === properties.currentYear.year()) {
      return;
    }
    if (properties.currentYear.year() === moment().year()) {
      setDateRange(defaultDateRange);
      return;
    }
    const start = properties.currentYear.clone().startOf('year');
    const end = start.clone().add(5, 'month');
    setDateRange({ start, end });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties.currentYear]);

  useEffect(() => {
    setDisplayedMonths(getDisplayedMonths());
  }, [dateRange, getDisplayedMonths]);

  return (
    <TableContext.Provider
      value={{
        resetValues: properties.resetValues,
        dateRange,
        incrementMonth,
        decrementMonth,
        displayedMonths,
        exceptionalMonth: properties.exceptionalMonth,
        setExceptionalMonth: properties.setExceptionalMonth,
        rangeValidatedMonth,
        setRangeValidatedMonth,
        setDateRange,
        totalMission,
        setTotalMission,
        isMissionEditable: properties.isMissionEditable,
        purchaseOrders: properties.purchaseOrders,
        activity: properties.activity,
        purchaseOrdersRange: properties.purchaseOrdersRange,
        validateMonth: async (month) => properties.validateMonth(month),
        currentYear: properties.currentYear,
        setCurrentYear: properties.setCurrentYear,
      }}
    >
      {properties.children}
    </TableContext.Provider>
  );
};

export default TableContextProvider;
