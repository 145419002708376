import {
  DialogContent,
  DialogContentText,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { InputBaseComponentProps } from '@mui/material/InputBase/InputBase';
import AvatarInitial from 'components/commons/AvatarInitial/AvatarInitial';
import PolyModal from 'components/MUIOverload/PolyDialog/PolyModal';
import PolyTooltip from 'components/MUIOverload/PolyTooltip';
import {
  BillingCellType,
  useTableContext,
} from 'contexts/ValidateTmActivityMonitoring/TableContextProvider';
import {
  ActivityNode,
  BillingEmployeeInput,
  BillingNode,
  EmployeeNode,
  useMonthWorkDaysQuery,
  useUpdateBillingsMutation,
} from 'generated/graphql';
import _, { round } from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { getBillingKey } from 'pages/ActivityPage/ValidateTmActivityMonitoringPage/ValidateTable';
import React, { Dispatch, FormEvent, SetStateAction } from 'react';
import NumberFormat from 'react-number-format';
import { displayEmployeeNode, graphQlDateFormatter } from 'utils';

interface EditBillingTimeModalProps {
  open: boolean;
  setOpen: (p: boolean) => void;
  billingCell: BillingCellType;
  daysToConsider: number;
  setDaysToConsider: Dispatch<SetStateAction<number>>;
}

const NumberFormatCustom = (props: InputBaseComponentProps) => {
  const { defaultValue, onChange, ...baseProps } = props;
  return (
    <NumberFormat
      {...baseProps}
      defaultValue={defaultValue as number}
      decimalScale={2}
      thousandSeparator={' '}
      decimalSeparator={','}
      renderText={(props) => props}
      onValueChange={(values) => {
        const { floatValue } = values;
        if (floatValue !== undefined) {
          const roundedValue = round(floatValue / 0.25, 0) * 0.25;
          if (onChange) {
            const event = { target: { value: roundedValue } };
            onChange(
              event as unknown as FormEvent<
                HTMLInputElement | HTMLTextAreaElement
              >
            );
          }
        }
      }}
      displayType={'input'}
    />
  );
};

export const EditBillingTimeModal = ({
  open,
  setOpen,
  billingCell,
  daysToConsider,
  setDaysToConsider,
}: EditBillingTimeModalProps) => {
  const {
    billings,
    billingCells,
    setSelectedBillingValidations,
    refetchTimeSpent,
  } = useTableContext();
  const { enqueueSnackbar } = useSnackbar();

  const [updateBillings] = useUpdateBillingsMutation({
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    onCompleted: (data) => {
      enqueueSnackbar(`Temps mis à jour`, {
        variant: 'success',
      });
      setSelectedBillingValidations(new Set<BillingCellType>());
      _.forEach(data.updateBillings?.billings, (billing) => {
        const key = getBillingKey(
          billing.employee as EmployeeNode,
          billing.activity as ActivityNode,
          moment(billing.dateBilling)
        );
        billings[key] = billing as BillingNode;
        billingCells[key].billing = billing as BillingNode;
      });
      setOpen(false);
    },
  });

  const validateBillings = async () => {
    const billings: BillingEmployeeInput[] = [
      {
        billingId: billingCell.billing?.id,
        dateBilling: graphQlDateFormatter(
          billingCell.month.clone().endOf('month')
        ),
        employeeId: billingCell.employee.id,
        activityId: billingCell.mission.id,
        timeForTmContracts: daysToConsider,
      },
    ];
    await updateBillings({
      variables: {
        billings: billings,
        validating: false,
      },
    });
    setDaysToConsider(daysToConsider);
    refetchTimeSpent();
  };

  const { data } = useMonthWorkDaysQuery({
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const numberOfBusinessDaysInThisMonth = _.find(data?.monthWorkDays, (value) =>
    moment(value?.month).isSame(billingCell.month, 'month')
  )?.workDays;

  return (
    <PolyModal
      open={open}
      onClose={() => setOpen(false)}
      polyDialogTitle={'Édition des temps collaborateurs'}
      handlePrimary={() => void validateBillings()}
    >
      <DialogContent>
        <DialogContentText>
          <Typography sx={{ color: 'text.secondary' }}>
            Edition du temps mensuel pour la mission :
          </Typography>
          <Typography fontWeight={'bold'}>
            {billingCell.mission.name}
          </Typography>
        </DialogContentText>
        <Table sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell
                sx={{
                  width: '25%',
                  border: `1px solid`,
                  borderColor: 'darkGrey.main',
                }}
                align={'center'}
              >
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  alignContent={'center'}
                  justifyContent={'center'}
                >
                  <Typography>
                    {billingCell.month.format('MMM YYYY')}
                  </Typography>
                  <Typography color={'textSecondary'} ml={1}>
                    {numberOfBusinessDaysInThisMonth}j
                  </Typography>
                </Stack>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                sx={{
                  border: `1px solid`,
                  borderColor: 'darkGrey.main',
                }}
              >
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                  <PolyTooltip
                    title={displayEmployeeNode(billingCell.employee, false)}
                  >
                    <span>
                      <AvatarInitial employee={billingCell.employee} />
                    </span>
                  </PolyTooltip>
                  <Typography>
                    {displayEmployeeNode(billingCell.employee)}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell
                sx={{
                  border: `1px solid`,
                  borderColor: 'darkGrey.main',
                }}
                align={'center'}
              >
                <TextField
                  variant="standard"
                  size={'small'}
                  value={daysToConsider}
                  defaultValue={daysToConsider}
                  onChange={(event) => {
                    const value = event.target.value as unknown as number;
                    setDaysToConsider(value);
                  }}
                  inputProps={{ sx: { textAlign: 'center' } }}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
    </PolyModal>
  );
};
