import { Chip } from '@mui/material';
import React from 'react';

interface AbsenceStatusProps {
  type: 'passed' | 'pending' | 'upcoming';
  title?: string;
}

function AbsenceStatus({ type, title }: AbsenceStatusProps) {
  switch (type) {
    case 'passed':
      title = 'Passé';
      break;
    case 'pending':
      title = 'En cours';
      break;
    case 'upcoming':
      title = 'À venir';
      break;
    default:
      break;
  }

  return <Chip label={title} variant={type} />;
}

export default AbsenceStatus;
