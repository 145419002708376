import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

const ScrollToTop = (props: { children: JSX.Element }) => {
  const location = useLocation();
  useEffect(() => {
    const match = 'phonebook';
    const res = new RegExp(match);
    if (!res.test(location.pathname)) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return <>{props.children}</>;
};

export default ScrollToTop;
