import { Typography, useMediaQuery } from '@mui/material';
import { SxProps, useTheme } from '@mui/material/styles';
import React from 'react';
interface PolyTitleInterface {
  sx?: SxProps;
  color?: string;
  children?: React.ReactNode;
  clientName?: string;
}
export default function PolyTitle({ sx, color, children }: PolyTitleInterface) {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Typography
      variant={isPhone ? 'h2Bold' : 'h1Bold'}
      component={isPhone ? 'h2' : 'h1'}
      sx={sx}
      color={color}
      textAlign={'left'}
    >
      {children}
    </Typography>
  );
}
