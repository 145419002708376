import DayInput from 'components/commons/DayInput';
import InputContainer from 'components/commons/InputContainer/InputContainer';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface WorkedDurationProps {
  defaultDuration: number;
  disabled: boolean;
}

const WorkedDuration = ({ defaultDuration, disabled }: WorkedDurationProps) => {
  const { control } = useFormContext();
  return (
    <InputContainer label={'Durée Travaillée'}>
      <Controller
        control={control}
        name={'workedDuration'}
        render={({ onChange }) => (
          <DayInput
            defaultValue={defaultDuration}
            disabledOption={disabled}
            handleChange={onChange}
          />
        )}
      />
    </InputContainer>
  );
};

export default WorkedDuration;
