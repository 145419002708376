import { Stack, TableRow, Tooltip } from '@mui/material';
import MultiTableCell from '@mui/material/TableCell';
import DataCells from 'components/ActivityPage/common/DataCells';
import DeclareAbsenceChip from 'components/ActivityPage/common/DeclareAbsenceChip';
import MissionNameDisplay from 'components/commons/MissionNameDisplay';
import { ActivitiesActivityBillingTypeChoices } from 'generated/graphql';
import { DisabledActivityInputName } from 'poly-constants';
import React, { PropsWithChildren } from 'react';

import { Activity, ActivityMonitoring } from '.';
import { TimeMaterialType } from './InputCell';

export interface AbsenceRowProps {
  name: string;
  activity: Activity;
  category?: string;
  activityMonitoring: Array<ActivityMonitoring>;
  description: string;
  disabled: boolean;
  display?: boolean;
  order?: number;
}

const AbsenceRow = ({
  disabled = false,
  display = true,
  ...properties
}: PropsWithChildren<AbsenceRowProps>) => {
  return (
    <TableRow
      sx={{
        display: display ? 'show' : 'none',
      }}
    >
      <Tooltip title={properties.description} placement="top-start">
        <MultiTableCell
          sx={{
            borderBottom: 'none',
            py: 1,
          }}
        >
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <MissionNameDisplay
              key={`mission-${properties.activity.name}-name-display`}
              mission={properties.activity.name}
              type={properties.activity.type}
              isTmContracts={
                properties.activity.billingType ===
                ActivitiesActivityBillingTypeChoices.Tm
              }
              haveBorder={false}
            />
            {properties.activity.name === 'Absences' && <DeclareAbsenceChip />}
          </Stack>
        </MultiTableCell>
      </Tooltip>
      <DataCells
        activity={properties.activity}
        activityMonitoring={properties.activityMonitoring}
        timeAndMaterialType={TimeMaterialType.None}
        disabled={
          disabled ||
          DisabledActivityInputName.includes(properties.name.toLowerCase())
        }
      />
    </TableRow>
  );
};

export default AbsenceRow;
