import { SxProps, Theme, Tooltip } from '@mui/material';
import ProfitabilityDataComingCell from 'components/Reporting/Profitability/GlobalView/ProfitabilityTableBody/Rows/Cells/ProfitabilityDataComingCell';
import {
  ProfitabilityMarginPercentageCell,
  StyledCell,
} from 'components/Reporting/Profitability/utils';
import { BillingPurchaseOrderCurrencyChoices } from 'generated/graphql';
import { Moment } from 'moment';
import { roundNumber, totalFormat } from 'pages/ActivityPage/utils';
import React from 'react';

import { useProfitabilityTableContext } from '../../../ProfitabilityTableContextProvider';
import { shouldDisplayDataIncoming } from '../utils';

interface ProfitabilityRenderValuesProps {
  month: Moment;
  averageAdr: number;
  totalRevenue: number;
  cost: number;
  totalWorkedDays: number;
  totalWorkedDaysWithoutIntern: number;
  workDays: number;
  etpCumulated?: number;
  totalWorkedDaysToMonth?: number;
  totalWorkedDaysToMonthWithoutIntern?: number;
  etp?: number;
  hasValidatedRevenue: boolean;
  notDisplayedReason?: string;
  isFooter?: boolean;
  isEtpCumulated?: boolean;
  sx?: SxProps<Theme>;
  yearToDateMonthWorkDays?: number;
  etpCumulatedWithoutIntern?: number;
}

export default function ProfitabilityRenderValues({
  month,
  averageAdr,
  totalRevenue,
  cost,
  totalWorkedDays,
  etpCumulated,
  etpCumulatedWithoutIntern,
  yearToDateMonthWorkDays,
  totalWorkedDaysWithoutIntern,
  totalWorkedDaysToMonth,
  totalWorkedDaysToMonthWithoutIntern,
  workDays,
  hasValidatedRevenue,
  notDisplayedReason,
  etp,
  isFooter = false,
  isEtpCumulated = false,
  sx = [],
}: ProfitabilityRenderValuesProps) {
  const { isShowingMarginRate, isExcludingIntern } =
    useProfitabilityTableContext();

  if (shouldDisplayDataIncoming(hasValidatedRevenue, month)) {
    return (
      <ProfitabilityDataComingCell reason={notDisplayedReason} colSpan={5} />
    );
  }

  const averageAdrValue = () => {
    if (isExcludingIntern === true) {
      return totalWorkedDaysWithoutIntern
        ? totalRevenue / totalWorkedDaysWithoutIntern
        : 0;
    } else {
      return averageAdr ? averageAdr : 0;
    }
  };

  const sxRenderValues = { fontSize: '0.8rem', ...sx };

  const getEtpColumnValue = () => {
    if (isEtpCumulated) {
      return (
        <div>
          {' '}
          {roundNumber(
            (isExcludingIntern ? etpCumulatedWithoutIntern : etpCumulated) || 0
          )}
        </div>
      );
    }
    if (!etp) {
      return <div>0</div>;
    }
    if (isExcludingIntern) {
      return (
        <div>
          {totalFormat(
            roundNumber(totalWorkedDaysWithoutIntern) / roundNumber(workDays)
          )}
        </div>
      );
    }
    return (
      <div>
        {totalFormat(roundNumber(totalWorkedDays) / roundNumber(workDays))}
      </div>
    );
  };

  const getTooltipCalcul = () => {
    if (isEtpCumulated) {
      return `${totalFormat(
        roundNumber(
          isExcludingIntern
            ? totalWorkedDaysToMonthWithoutIntern || 0
            : totalWorkedDaysToMonth || 0
        )
      )} / ${totalFormat(roundNumber(yearToDateMonthWorkDays || 0))}`;
    }
    return `${totalFormat(
      roundNumber(
        isExcludingIntern ? totalWorkedDaysWithoutIntern : totalWorkedDays
      )
    )} / ${totalFormat(roundNumber(workDays))}`;
  };

  return (
    <>
      <StyledCell sx={sxRenderValues}>
        <Tooltip title={getTooltipCalcul()} arrow>
          {getEtpColumnValue()}
        </Tooltip>
      </StyledCell>

      <StyledCell sx={sxRenderValues}>
        <Tooltip
          title={`${totalFormat(roundNumber(totalRevenue))} / ${totalFormat(
            roundNumber(
              isExcludingIntern ? totalWorkedDaysWithoutIntern : totalWorkedDays
            )
          )}`}
          arrow
        >
          <div>
            {totalFormat(
              averageAdrValue(),
              BillingPurchaseOrderCurrencyChoices.Eur
            )}
          </div>
        </Tooltip>
      </StyledCell>

      <StyledCell
        sx={sxRenderValues}
        id={isFooter ? `actualRevenueTotal${month.month()}` : ''}
      >
        <Tooltip
          title={totalFormat(
            totalRevenue,
            BillingPurchaseOrderCurrencyChoices.Eur
          )}
          id={isFooter ? `actualRevenueTotal${month.month()}-tooltip` : ''}
          arrow
        >
          <div>
            {totalFormat(
              totalRevenue ? totalRevenue : 0,
              BillingPurchaseOrderCurrencyChoices.Eur,
              true
            )}
          </div>
        </Tooltip>
      </StyledCell>
      <StyledCell sx={sxRenderValues}>
        <Tooltip
          title={totalFormat(cost, BillingPurchaseOrderCurrencyChoices.Eur)}
          arrow
        >
          <div>
            {totalFormat(
              cost ? cost : 0,
              BillingPurchaseOrderCurrencyChoices.Eur,
              true
            )}
          </div>
        </Tooltip>
      </StyledCell>
      <ProfitabilityMarginPercentageCell
        isShowingMarginRate={isShowingMarginRate}
        ca={totalRevenue ? totalRevenue : 0}
        cost={cost ? cost : 0}
        sx={sxRenderValues}
        cellSx={{ borderRight: '1px solid', borderRightColor: 'darkGrey.main' }}
        wrapThousands
      />
    </>
  );
}
