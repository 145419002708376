import { Box, Typography } from '@mui/material';
import PageTitle from 'components/commons/PageTitle';
import React from 'react';

interface HomeBodyProps {
  title?: string;
  subTitle?: string;
}

const HomeBody = ({
  title,
  children,
  subTitle,
}: React.PropsWithChildren<HomeBodyProps>) => {
  return (
    <Box sx={{ animation: '3s linear' }}>
      {title && <PageTitle>{title}</PageTitle>}
      {subTitle && (
        <Typography variant="h1Bold" component="h1">
          {subTitle}
        </Typography>
      )}
      {children}
    </Box>
  );
};

export default HomeBody;
