import PolyDialog from 'components/MUIOverload/PolyDialog';
import React from 'react';

import HomeGridItem from './HomeGridItem';

interface ModalHomePageProps {
  title: string;
  content?: string | JSX.Element | JSX.Element[];
  size?: number;
  headerIcon?: JSX.Element;
  onClose?: () => void;
  modalVisibility: boolean;
  setModalVisibility?: () => void;
}

const ModalHomePage = ({
  title,
  content,
  headerIcon,
  modalVisibility,
  setModalVisibility,
}: ModalHomePageProps) => {
  return (
    <PolyDialog open={modalVisibility} onClose={setModalVisibility}>
      <HomeGridItem
        title={title}
        content={content}
        headerIcon={headerIcon}
        hasButtonClose
        onClose={setModalVisibility}
      />
    </PolyDialog>
  );
};

export default ModalHomePage;
