import LaunchIcon from '@mui/icons-material/Launch';
import { Grid } from '@mui/material';
import ButtonLink from 'components/commons/ButtonLink';
import DatePicker from 'components/commons/DatePicker';
import PolyAlert from 'components/commons/PolyAlert';
import PolyAlertTitle from 'components/commons/PolyAlertTitle';
import BillActionModal, {
  BillNumberInput,
} from 'components/MissionFollowUp/BillDetail/Modals/BillActionModal/BillActionModal';
import styles from 'components/MissionFollowUp/BillDetail/Modals/CreateOrUpdateBillModal/styles/BillModal.module.scss';
import DisplayBillStatus from 'components/MissionFollowUp/OverallListBill/DisplayStatus/DisplayBillStatus';
import {
  BillingBillingAddressCategoryChoices,
  BillingBillSendingMethodChoices,
  BillingBillStatusChoices,
  BillNode,
  useUpdateBillStatusMutation,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { isDate, requiredForm } from 'pages/MissionFollowUp/formValidators';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'store';
import { setCurrentBill } from 'store/purchaseOrder';
import { graphQlDateFormatter } from 'utils';

import DisplayBillSendingMethod from '../../DisplayBillSendingMethod';
import { isBillAlreadyBilled } from '../../utils';
const getModalTitle = (
  desiredBillStatus: BillingBillStatusChoices,
  isCurrentBillAlreadyBilled: boolean,
  multipleSelectedBills: boolean
): string => {
  if (
    desiredBillStatus === BillingBillStatusChoices.Canceled &&
    isCurrentBillAlreadyBilled
  ) {
    return 'Annuler la facture/Générer un avoir';
  }
  if (desiredBillStatus === BillingBillStatusChoices.Canceled) {
    return 'Annuler la facture';
  }
  return `Changer le statut  ${
    multipleSelectedBills ? 'des factures' : 'de la facture'
  }`;
};

interface TransitionModalManagementControlProps {
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  desiredBillStatus: BillingBillStatusChoices;
  desiredSendingMethod?: BillingBillSendingMethodChoices | undefined;
  groupUpdate?: boolean;
}

export default function ControlUpdateBillModal({
  desiredBillStatus = BillingBillStatusChoices.Billed,
  desiredSendingMethod,
  open,
  onClose,
  onSuccess,
  groupUpdate,
}: TransitionModalManagementControlProps) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const currentBill = useSelector((state) => state.purchaseOrder.currentBill);
  const selectedBills = useSelector(
    (state) => state.purchaseOrder.selectedBills
  );
  const multipleSelectedBills = selectedBills.length > 1;

  const isCurrentBillAlreadyBilled = isBillAlreadyBilled(currentBill);

  const isUpdatingBillStatusToSent =
    desiredBillStatus === BillingBillStatusChoices.Sent;

  const defaultValues = {
    billingDate: new Date(),
    dueDate: moment().add(2, 'M').toDate(),
    bills: [] as BillNumberInput[],
    status: desiredBillStatus,
  };
  const form = useForm({
    defaultValues: defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldUnregister: false,
  });

  const [updateStatus] = useUpdateBillStatusMutation({
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    onCompleted: (data) => {
      if (groupUpdate) {
        enqueueSnackbar(`Les statuts des factures ont été mis à jour`, {
          variant: 'success',
        });
        if (onSuccess) {
          onSuccess();
        }
        return;
      }

      const bill = data.updateBillStatus?.bills?.[0] as BillNode;
      dispatch(setCurrentBill(bill));
      enqueueSnackbar(
        `Le statut de la facture « ${currentBill.title} » a été mis à jour`,
        { variant: 'success' }
      );

      if (onSuccess) {
        onSuccess();
        return;
      }

      history.push({
        pathname,
        search: `?mission_id=${currentBill.purchaseOrder.billingActivityInformation.activity?.id}&po_id=${currentBill.purchaseOrder.id}&bill_id=${currentBill.id}`,
      });

      if (onClose) {
        onClose();
      }
    },
  });

  const onSubmit = async (submitValues: typeof defaultValues) => {
    await updateStatus({
      variables: {
        status: submitValues.status,
        sendingMethod: desiredSendingMethod,
        billingDate: submitValues.billingDate
          ? graphQlDateFormatter(submitValues.billingDate)
          : undefined,
        dueDate: submitValues.dueDate
          ? graphQlDateFormatter(submitValues.dueDate)
          : undefined,
        bills: _.map(submitValues.bills, (bill) => {
          return {
            id: bill.id || '',
            billNumber: bill.number,
          };
        }),
      },
    });
  };

  const dueLimit = () => {
    if (form.getValues('billingDate') > form.getValues('dueDate')) {
      form.setError('dueDate', { type: 'dueLimit' });
      return "La date d'échéance doit être postérieure à la date de facturation";
    }
    form.clearErrors('dueDate');
    return true;
  };

  function updateBillingDate(date: Date, onChange: (date: Date) => void) {
    form.setValue('dueDate', moment(date).add(2, 'M').toDate());
    onChange(date);
  }

  const isToBillAndClientIsEuropeanWithNoVatNumber = (
    bill: BillNode
  ): boolean => {
    const billingClient =
      bill.purchaseOrder?.billingActivityInformation?.billingClient;
    const isToBill = bill.status === BillingBillStatusChoices.ToBill;
    const hasNoVat = !billingClient?.vatNumber?.trim();
    const isEuropean =
      bill.purchaseOrder?.billingAddress?.category ===
        BillingBillingAddressCategoryChoices.Fr ||
      bill.purchaseOrder?.billingAddress?.category ===
        BillingBillingAddressCategoryChoices.Ue;
    return isToBill && hasNoVat && isEuropean;
  };

  const findEuropeanBillsWithNoVatNumber = (bills: BillNode[]): BillNode[] => {
    const billsToFilter: BillNode[] = [...bills];
    if (billsToFilter.length === 0) {
      billsToFilter.push(currentBill);
    }
    return billsToFilter.filter((bill) =>
      isToBillAndClientIsEuropeanWithNoVatNumber(bill)
    );
  };

  const noVatEuropeanBills = findEuropeanBillsWithNoVatNumber(selectedBills);

  const shouldBlockSubmit =
    noVatEuropeanBills.length > 0 &&
    desiredBillStatus === BillingBillStatusChoices.Billed;

  const alerts = (
    <>
      {shouldBlockSubmit && (
        <PolyAlert variant="outlined" severity="error">
          <PolyAlertTitle color="error">
            Erreur numéro de TVA !{' '}
            {noVatEuropeanBills.length > 1 && (
              <>({noVatEuropeanBills.length})</>
            )}
          </PolyAlertTitle>
          <ul>
            {noVatEuropeanBills.map((row) => (
              <li key={'alert-' + row.id}>
                Validation de la facture{' '}
                <ButtonLink
                  href={
                    '/mission-followup/missions/details/bill' +
                    `?po_id=${row.purchaseOrder.id}` +
                    `&mission_id=${row.purchaseOrder.billingActivityInformation.activity?.id}` +
                    `&bill_id=${row.id}`
                  }
                  color="error"
                  size="small"
                  icon={<LaunchIcon />}
                  textMaxWidth={200}
                  openNewTab
                  showTooltip
                >
                  {row?.title}
                </ButtonLink>{' '}
                impossible : pas de numéro de TVA renseigné pour{' '}
                <ButtonLink
                  href={
                    '/mission-followup/missions/billing-clients' +
                    `?client_id=${row.purchaseOrder.billingActivityInformation.billingClient?.id}`
                  }
                  color="error"
                  size="small"
                  icon={<LaunchIcon />}
                  textMaxWidth={200}
                  openNewTab
                  showTooltip
                >
                  {
                    row?.purchaseOrder.billingActivityInformation.billingClient
                      ?.corporateName
                  }
                </ButtonLink>
              </li>
            ))}
          </ul>
        </PolyAlert>
      )}
      {isUpdatingBillStatusToSent && (
        <PolyAlert variant="outlined" severity="warning">
          <PolyAlertTitle color="warning">Attention !</PolyAlertTitle>
          Cette action est irréversible.
        </PolyAlert>
      )}
    </>
  );

  return (
    <BillActionModal
      open={open}
      title={getModalTitle(
        desiredBillStatus,
        isCurrentBillAlreadyBilled,
        multipleSelectedBills
      )}
      formProvider={{
        form: form,
        submit: () => form.handleSubmit(onSubmit)(),
      }}
      closeModal={onClose}
      groupAction={groupUpdate}
      billed
      alerts={alerts}
      disabled={shouldBlockSubmit}
      bodyModal={
        <>
          <p className={styles.text}>
            <span>
              {`Souhaitez-vous marquer ${
                multipleSelectedBills ? 'ces factures' : 'cette facture'
              } comme`}
            </span>
            <span className={styles.statusIcon}>
              <DisplayBillStatus status={desiredBillStatus} />
            </span>
            {isUpdatingBillStatusToSent && desiredSendingMethod && (
              <>
                par
                <span className={styles.statusIcon}>
                  <DisplayBillSendingMethod
                    sendingMethod={desiredSendingMethod}
                  />
                </span>
                ?
              </>
            )}
          </p>
          {desiredBillStatus === BillingBillStatusChoices.Billed && (
            <Grid container className={styles.pickers}>
              <Grid container item xs={12}>
                <Grid item xs={4} className={styles.datePicker}>
                  <Controller
                    name={'billingDate'}
                    control={form.control}
                    rules={{
                      required: requiredForm,
                      validate: {
                        dueLimit: dueLimit,
                        validDate: isDate,
                      },
                    }}
                    render={({ onChange, value }) => (
                      <DatePicker
                        value={value}
                        label={'Date de facturation'}
                        required
                        error={form.errors?.dueDate?.type === 'dueLimit'}
                        errorForm={form.errors?.billingDate}
                        onChange={(date) => {
                          if (date) {
                            updateBillingDate(date, onChange);
                          } else {
                            onChange(date);
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4} className={styles.datePicker}>
                  <Controller
                    name={'dueDate'}
                    control={form.control}
                    rules={{
                      required: requiredForm,
                      validate: {
                        dueLimit: dueLimit,
                        validDate: isDate,
                      },
                    }}
                    render={({ onChange, value }) => (
                      <DatePicker
                        value={value}
                        onChange={onChange}
                        label={"Date d'échéance"}
                        required
                        error={form.errors?.billingDate?.type === 'dueLimit'}
                        errorForm={form.errors?.dueDate}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      }
    />
  );
}
