import { StepConnector, StepConnectorProps } from '@mui/material';
import React from 'react';

const PolyStepConnector = (properties: StepConnectorProps) => {
  return (
    <StepConnector
      {...properties}
      sx={{
        top: '22px',
        left: 'calc(-50% + 25px)',
        right: 'calc(50% + 27px)',
        borderTopWidth: '20px',
        borderColor: 'inherit',
        color: 'inherit',
      }}
    />
  );
};

export default PolyStepConnector;
