import Stepper, { StepperProps } from '@mui/material/Stepper';
import React from 'react';

import PolyStepConnector from '../PolyStepConnector';

const PolyStepper = (properties: StepperProps) => {
  return (
    <Stepper
      {...properties}
      connector={<PolyStepConnector />}
      alternativeLabel
    />
  );
};

export default PolyStepper;
