import { Stack, Typography } from '@mui/material';
import AvatarInitial from 'components/commons/AvatarInitial/AvatarInitial';
import {
  formatTimeStamp,
  getDescription,
  HistoryChange,
} from 'components/MissionFollowUp/BillDetail/Modals/BillHistoryModal/utils';
import { EmployeeNode } from 'generated/graphql';
import _ from 'lodash';
import React from 'react';
import { displayEmployee } from 'utils';

interface HistoryElementProps {
  employee: EmployeeNode;
  date: string;
  changes: Array<HistoryChange>;
  PDFFilename?: string;
}

const HistoryBillModified = (properties: HistoryElementProps) => {
  const isPDFGenerated = _.some(properties.changes, (change) => {
    return change.field === 'bill_pdf';
  });

  return (
    <Stack direction="row" alignItems="center" sx={{ width: 500, pb: 2 }}>
      <Stack sx={{ width: 40, mr: 1 }}>
        <AvatarInitial employee={properties.employee} />
      </Stack>
      <Stack sx={{ flex: 1 }}>
        <div>
          {/* We don't dislay the employee name in the history for the PDF generation */}
          {!isPDFGenerated &&
            displayEmployee(
              properties.employee?.firstName,
              properties.employee?.lastName,
              false
            )}
          {_.isEmpty(properties.changes)
            ? ' a créé la facture'
            : getDescription(
                properties.changes,
                isPDFGenerated,
                properties.PDFFilename
              )}
        </div>
        <Typography color={'textSecondary'}>
          {formatTimeStamp(properties.date)}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default HistoryBillModified;
