import React, { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import {
  DeepMap,
  DeepPartial,
  FieldError,
  FieldValues,
  FormProvider,
  UnpackNestedValue,
  useForm,
  UseFormOptions,
} from 'react-hook-form';

interface FormSystemProps<InputType extends FieldValues> {
  onSubmit?: (data: InputType) => Promise<void>;
  useFormArgs?: UseFormOptions<InputType>;
}

type FieldErrorsProp<TFieldValues extends FieldValues = FieldValues> = DeepMap<
  TFieldValues,
  FieldError
>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface FormSystemValidationProps<InputType> {
  readonly successOpen: boolean;
  setSuccessOpen: Dispatch<SetStateAction<boolean>>;
  readonly failureOpen: boolean;
  setFailureOpen: Dispatch<SetStateAction<boolean>>;
  errors: FieldErrorsProp; // Errors from react hook form checks
  backErrorMessage: string | undefined; // Possible error message coming from back end
  setBackErrorMessage: Dispatch<SetStateAction<string | undefined>>;
}

const FormSystem = <InputType extends FieldValues>(
  properties: PropsWithChildren<FormSystemProps<InputType>>
) => {
  const methods = useForm<InputType>(properties.useFormArgs);

  const onSubmit = async (data: UnpackNestedValue<DeepPartial<InputType>>) => {
    if (properties.onSubmit) {
      await properties.onSubmit(data as InputType);
      methods.reset(data, {
        errors: true,
        isDirty: false,
        isSubmitted: true,
        touched: true,
        isValid: true,
        submitCount: true,
        dirtyFields: true,
      });
    }
    methods.clearErrors();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {properties.children}
      </form>
    </FormProvider>
  );
};

export default FormSystem;
