import { FlatTable } from 'components/commons/Tables/FlatTable';
import PongoButton from 'components/MUIOverload/PongoButton';
import _ from 'lodash';
import { Moment } from 'moment';
import React from 'react';

import RevenueTableBodyByPO from './RevenueTableBodyByPo';
import RevenueTableHeader from './RevenueTableHeader';
import styles from './styles/TableCell.module.scss';

interface RevenueMissionTableProps {
  exceptionalMonth: Moment | undefined;
  isMissionEditable: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSave: (submitValues: any) => Promise<void>;
  isFormSaving: boolean;
}

const RevenueMissionTable = ({
  exceptionalMonth,
  isMissionEditable,
  form,
  onSave,
  isFormSaving,
}: RevenueMissionTableProps) => {
  return (
    <>
      <FlatTable size={'small'}>
        <RevenueTableHeader />
        <RevenueTableBodyByPO />
      </FlatTable>

      {!exceptionalMonth && isMissionEditable && (
        <div className={styles.saveButtonContainer}>
          <PongoButton
            onClick={form.handleSubmit(onSave)}
            color={'primary'}
            variant={'contained'}
            type={'submit'}
            disabled={
              isFormSaving || !form.formState.isDirty || !_.isEmpty(form.errors)
            }
          >
            Enregistrer
          </PongoButton>
        </div>
      )}
    </>
  );
};

export default RevenueMissionTable;
