import { TableBody } from '@mui/material';
import { useTableContext } from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/context/TableContextProvider';
import PoRow from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/RevenueRows/PoRow';
import TotalRow from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/RevenueRows/TotalRow';
import RevenueTableEmptyRow from 'components/Revenue/Tables/RevenueTableEmptyRow';
import {
  ActivitiesActivityBillingTypeChoices,
  PurchaseOrderNode,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Transition } from 'react-transition-group';

import styles from './styles/TableCell.module.scss';

const RevenueTableBodyByPo = () => {
  const { purchaseOrders, displayedMonths, activity } = useTableContext();

  const getLastActiveMonth = (purchaseOrder: PurchaseOrderNode) => {
    return activity.billingType === ActivitiesActivityBillingTypeChoices.Tm
      ? moment(purchaseOrder.periodEnding).add(1, 'M')
      : moment(purchaseOrder.periodEnding);
  };

  return (
    <TableBody className={styles.tableBody}>
      {_.map(purchaseOrders, (po) => {
        const isHidden =
          _.first(displayedMonths)?.isAfter(getLastActiveMonth(po), 'month') ||
          _.last(displayedMonths)?.isBefore(po.periodBeginning, 'month');
        return (
          <Transition
            mountOnEnter
            unmountOnExit
            timeout={250}
            key={`BDC ${po.id}`}
            in={!isHidden}
          >
            {(status) => {
              return <PoRow purchaseOrder={po} status={status} />;
            }}
          </Transition>
        );
      })}
      {_.isEmpty(purchaseOrders) && (
        <RevenueTableEmptyRow
          emptyText={
            'Aucun bon de commande n’existe pour la mission sélectionnée.' +
            ' Cliquez sur le bouton ci-dessus afin de rajouter un premier bon de' +
            ' commande.'
          }
        />
      )}
      <TotalRow />
    </TableBody>
  );
};

export default RevenueTableBodyByPo;
