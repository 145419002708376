import { Divider, Stack, SwipeableDrawer, Typography } from '@mui/material';
import React from 'react';

interface ActivityInfoDrawerProps {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (value: React.SetStateAction<boolean>) => void;
  drawerTitle: string;
  drawerContent: string;
}

const ActivityInfoDrawer = ({
  isDrawerOpen,
  setIsDrawerOpen,
  drawerTitle,
  drawerContent,
}: ActivityInfoDrawerProps) => {
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isDrawerOpen}
      onOpen={() => setIsDrawerOpen(true)}
      onClose={() => setIsDrawerOpen(false)}
      sx={{
        zIndex: 1300,
      }}
      PaperProps={{
        sx: {
          borderTopLeftRadius: 30,
          borderTopRightRadius: 30,
          px: 5,
          py: 3,
        },
      }}
      disableDiscovery
      disableSwipeToOpen
    >
      <Stack direction="column" spacing={2.5}>
        <Typography variant="h3Bold" color="primary" component="h3">
          {drawerTitle}
        </Typography>
        <Divider />
        <Typography variant="bodySemiBold" color="text.secondary">
          {drawerContent}
        </Typography>
      </Stack>
    </SwipeableDrawer>
  );
};

export default ActivityInfoDrawer;
