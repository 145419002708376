import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import FieldDisplay from 'components/commons/FieldDisplay/FieldDisplay';
import { displayRangeMonthPeriod } from 'components/MissionFollowUp/BillDetail/BillDetail/utils';
import { ActivitiesActivityBillingTypeChoices } from 'generated/graphql';
import { totalFormat } from 'pages/ActivityPage/utils';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'store';

export default function ResumePoView() {
  const currentMission = useSelector((state) => state.activity.currentMission);
  const currentPurchaseOrder = useSelector(
    (state) => state.purchaseOrder.currentPurchaseOrder
  );
  const history = useHistory();
  const theme = useTheme();

  return (
    <Stack direction={'column'} spacing={2} sx={{ px: 2 }}>
      <Typography
        variant={'h3Bold'}
        component="h3"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        Résumé BDC
        <IconButton
          onClick={() => {
            history.push(
              '/mission-followup/missions/details/purchase_order?mission_id=' +
                currentMission.id +
                '&po_id=' +
                currentPurchaseOrder.id
            );
          }}
          sx={{
            backgroundColor: theme.palette.primary.darker,
          }}
          size="small"
        >
          <ArrowForwardIcon
            sx={{
              color: theme.palette.contrastText.main,
            }}
          />
        </IconButton>
      </Typography>
      <Stack direction={'column'} justifyContent={'space-between'} spacing={3}>
        <Stack direction={'column'}>
          <Typography
            color={'textSecondary'}
            variant={'bodyBold'}
            sx={{ whiteSpace: 'nowrap' }}
          >{`Nom`}</Typography>
          <Typography color={'textPrimary'} variant={'bodySemiBold'}>
            {currentPurchaseOrder.name}
          </Typography>
        </Stack>
        <FieldDisplay
          title={'Numéro'}
          value={currentPurchaseOrder.number}
          flexDirection="column"
          noWrap={false}
          showColon={false}
          titleVariant={'bodyBold'}
          valueVariant={'bodySemiBold'}
        />
        <FieldDisplay
          title={'Période'}
          value={displayRangeMonthPeriod(
            currentPurchaseOrder.periodBeginning,
            currentPurchaseOrder.periodEnding
          )}
          flexDirection="column"
          noWrap={false}
          showColon={false}
          titleVariant={'bodyBold'}
          valueVariant={'bodySemiBold'}
        />
        {currentPurchaseOrder.total && (
          <FieldDisplay
            title={
              currentMission.billingType ===
              ActivitiesActivityBillingTypeChoices.Tm
                ? 'Montant maximal régie'
                : 'Montant'
            }
            value={totalFormat(
              currentPurchaseOrder.total,
              currentPurchaseOrder.currency
            )}
            flexDirection="column"
            noWrap={false}
            showColon={false}
            titleVariant={'bodyBold'}
            valueVariant={'bodySemiBold'}
          />
        )}
      </Stack>
    </Stack>
  );
}
