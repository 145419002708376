import { Box } from '@mui/material';
import {
  EmployeesEmployeeContractTypeChoices,
  EmployeesEmployeeGradeChoices,
} from 'generated/graphql';
import React from 'react';

interface DisplayStatusProps {
  status: EmployeesEmployeeContractTypeChoices | EmployeesEmployeeGradeChoices;
}

export function DisplayStatus({ status }: DisplayStatusProps) {
  const isComan = status === EmployeesEmployeeGradeChoices.Coman;
  const isComex = status === EmployeesEmployeeGradeChoices.Comex;
  const isCollaborator = status === EmployeesEmployeeGradeChoices.Collaborator;
  const isProvider = status === EmployeesEmployeeContractTypeChoices.Provider;
  const isIntern = status === EmployeesEmployeeContractTypeChoices.Intern;
  const isAlternant =
    status === EmployeesEmployeeContractTypeChoices.StudentApprentice;
  const isCdi = status === EmployeesEmployeeContractTypeChoices.Cdi;
  const isCdd = status === EmployeesEmployeeContractTypeChoices.Cdd;

  return (
    <Box
      sx={{
        color: 'white',
        borderRadius: '5px',
        padding: '2px 5px 2px 5px',
        ...(isComan && { backgroundColor: 'comanBlue.main' }),
        ...(isComex && { backgroundColor: 'comexOrange.main' }),
        ...(!isComan && !isComex && { backgroundColor: 'darkGrey.dark' }),
      }}
    >
      {isComan && 'COMAN'}
      {isComex && 'COMEX'}
      {isCollaborator && 'COLLABORATEUR'}
      {isIntern && 'STAGIAIRE'}
      {isProvider && 'PRESTATAIRE'}
      {isAlternant && 'ALTERNANT'}
      {isCdi && 'CDI'}
      {isCdd && 'CDD'}
    </Box>
  );
}
