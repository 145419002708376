import { Link } from '@mui/material';
import PolyAlert from 'components/commons/PolyAlert';
import PolyAlertTitle from 'components/commons/PolyAlertTitle';
import React from 'react';

function AbsenceWarningTip(): JSX.Element {
  return (
    <PolyAlert severity="warning" variant="outlined" sx={{ mb: 2 }}>
      <PolyAlertTitle color="warning">Attention ! </PolyAlertTitle>
      Assurez-vous d’avoir bien déclaré et validé vos congés sur{' '}
      <Link
        sx={{ cursor: 'pointer', textDecoration: 'underline' }}
        href="https://rh.polyconseil.fr/"
        target="_blank"
        rel="noopener"
      >
        le site RH
      </Link>{' '}
      avant de créer une absence ici.
    </PolyAlert>
  );
}

export default AbsenceWarningTip;
