import React from 'react';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';

interface TransitionPolyTableRowProps {
  open: boolean;
  children: (status: TransitionStatus) => React.ReactElement;
}

const TransitionPolyTableRow = ({
  open,
  children,
}: TransitionPolyTableRowProps) => {
  return (
    <Transition unmountOnExit timeout={250} in={open}>
      {(status) => {
        return children(status);
      }}
    </Transition>
  );
};

export default TransitionPolyTableRow;
