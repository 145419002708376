import { Stack, Typography } from '@mui/material';
import React from 'react';

interface SubMissionNameDisplayProps {
  name: string;
}

const SubMissionNameDisplay = ({ name }: SubMissionNameDisplayProps) => {
  return (
    <Stack direction={'row'} spacing={1} alignItems={'center'}>
      <Typography component={'span'} variant="bodyS">
        {'└─'}
      </Typography>
      <Typography component={'span'} variant="bodyS">
        {name}
      </Typography>
    </Stack>
  );
};

export default SubMissionNameDisplay;
