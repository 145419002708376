import { TableCell, TableHead, TableRow, Typography } from '@mui/material';
import commonStyles from 'components/commons/css/commons.module.scss';
import { BorderedTableCell } from 'components/commons/Tables/FlatTable';
import ArrowsUpdateTrimesterTableHeader, {
  getTrimesterNumber,
  isCurrentTrimester,
  isTrimesterStart,
} from 'components/commons/Tables/Header/ArrowsUpdateTrimesterTableHeader';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import { useUserInfo } from 'components/User/UserProvider';
import _ from 'lodash';
import React from 'react';

import { useProfitabilityTableContext } from './ProfitabilityTableContextProvider';
import ProfitabilityTableHeaderColumnsNames from './ProfitabilityTableHeaderColumnsNames';

export default function ProfitabilityTrimesterTableHeader() {
  const { displayedMonths } = useTableHeaderContext();
  const { isControl, isComex, isSuperuser } = useUserInfo();
  const { isShowingMarginRate, toggleShowMarginRate, isExcludingIntern } =
    useProfitabilityTableContext();

  return (
    <TableHead className={commonStyles.stickyHeader}>
      <TableRow>
        <TableCell sx={{ width: 200 }} />
        {_.map(displayedMonths, (month) => {
          if (isTrimesterStart(month)) {
            return (
              <React.Fragment key={`header-${month.format('MMM')}`}>
                <TableCell sx={{ width: '7%' }} />
                <TableCell sx={{ width: '7%' }} />
                <TableCell sx={{ width: '12%' }} />
                <TableCell sx={{ width: '12%' }} />
                <TableCell sx={{ width: '12%' }} />
              </React.Fragment>
            );
          }
        })}
      </TableRow>
      <TableRow>
        <BorderedTableCell />
        {_.map(displayedMonths, (month) => {
          if (isTrimesterStart(month)) {
            return (
              <BorderedTableCell
                key={`header-month-name-${month.format('MMM')}`}
                sx={{ textTransform: 'capitalize', p: 0 }}
                colSpan={5}
              >
                <ArrowsUpdateTrimesterTableHeader month={month}>
                  <Typography
                    fontWeight={isCurrentTrimester(month) ? 'bold' : 'normal'}
                  >
                    {`Trimestre ${getTrimesterNumber(month)} (${month.format(
                      'MMM'
                    )} - ${month.clone().add(2, 'month').format('MMM')})`}
                  </Typography>
                </ArrowsUpdateTrimesterTableHeader>
              </BorderedTableCell>
            );
          }
          return (
            <React.Fragment key={`header-month-name-${month.format('MMM')}`} />
          );
        })}
      </TableRow>
      <TableRow sx={{ tableLayout: 'fixed', width: '100%' }}>
        <BorderedTableCell>
          {isControl || isComex || isSuperuser ? 'Clients' : 'Missions'}
        </BorderedTableCell>
        {_.map(displayedMonths, (month) => {
          if (isTrimesterStart(month)) {
            return (
              <ProfitabilityTableHeaderColumnsNames
                key={`header-column-name-${month.format('MMM')}`}
                month={month}
                isShowingMarginRate={isShowingMarginRate}
                toggleShowMarginRate={toggleShowMarginRate}
                isExcludingIntern={isExcludingIntern}
              />
            );
          }
          return (
            <React.Fragment key={`header-column-name-${month.format('MMM')}`} />
          );
        })}
      </TableRow>
    </TableHead>
  );
}
