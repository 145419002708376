import PolyTrombiContextProvider from 'contexts/Phonebook/PolyTrombiContextProvider';
import PolyTrombi from 'pages/Polytrombi/PolyTrombi';
import * as React from 'react';

export default function PolyTrombiWrapper() {
  return (
    <PolyTrombiContextProvider>
      <PolyTrombi />
    </PolyTrombiContextProvider>
  );
}
