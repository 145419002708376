import moment, { Moment } from 'moment';
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { functionNotSet } from 'utils';

export interface TableHeaderContextProps {
  displayedMonths: Moment[];
  currentYear: Moment;
  setCurrentYear: (year: Moment) => void;
  incrementMonth: (amount?: number) => void;
  decrementMonth: (amount?: number) => void;
  dateRange: { start: Moment; end: Moment };
  setDateRange: (p: { start: Moment; end: Moment }) => void;
  isShowingMarginRate: boolean;
  toggleShowMarginRate: () => void;
  isYearSubtracted: boolean;
  isYearAdded: boolean;
  globalView?: boolean;
}

export interface TableHeaderContextProviderProps {
  currentYear: Moment;
  setCurrentYear?: (p: Moment) => void;
  monthRangeSize?: number;
  monthOffset?: number;
  isYearSubtracted?: boolean;
  isYearAdded?: boolean;
  globalView?: boolean;
}

const TableHeaderContext = createContext<TableHeaderContextProps>({
  displayedMonths: [],
  currentYear: moment(),
  isYearSubtracted: false,
  isYearAdded: false,
  setCurrentYear: () => functionNotSet(),
  incrementMonth: () => functionNotSet(),
  decrementMonth: () => functionNotSet(),
  setDateRange: () => functionNotSet(),
  dateRange: { end: moment(), start: moment() },
  isShowingMarginRate: false,
  toggleShowMarginRate: () => functionNotSet(),
});

export const useTableHeaderContext = () => {
  const tableContext = useContext(TableHeaderContext);
  if (!tableContext) {
    throw new Error('useContext() can only be used  inside a table');
  }
  return tableContext;
};

function getRangeInDateYear(currentDate: Moment, monthToDisplay: number) {
  if (monthToDisplay === 1) {
    return {
      start: currentDate,
      end: currentDate,
    };
  }

  const startDate = currentDate.clone().subtract(1, 'month');
  const endDate = currentDate.clone().add(monthToDisplay - 2, 'month');
  const isStartInCurrentYear = currentDate.year() === startDate.year();
  const isEndInCurrentYear = currentDate.year() === endDate.year();

  if (isStartInCurrentYear && isEndInCurrentYear) {
    return {
      start: startDate,
      end: endDate,
    };
  }

  const monthsToAddForCorrection = isStartInCurrentYear
    ? -(endDate.month() + 1)
    : 12 - startDate.month();
  return {
    start: startDate.add(monthsToAddForCorrection, 'month'),
    end: endDate.add(monthsToAddForCorrection, 'month'),
  };
}

const getDefaultDateRange = (
  referenceDate: Moment,
  monthToDisplay: number,
  monthOffset: number,
  isYearSubtracted: boolean,
  isYearAdded: boolean
) => {
  const startYear = moment().startOf('year');
  const offsetDate = referenceDate.clone().add(monthOffset, 'month');
  if (isYearSubtracted) {
    const newStart = referenceDate.clone().endOf('year');
    return {
      start: newStart.subtract(monthToDisplay - 1, 'month'),
      end: referenceDate.endOf('year'),
    };
  } else if (isYearAdded) {
    return {
      start: startYear,
      end: startYear.clone().add(monthToDisplay - 1, 'month'),
    };
  } else if (offsetDate.isSameOrBefore(startYear, 'month')) {
    return {
      start: startYear,
      end: startYear.clone().add(monthToDisplay - 1, 'month'),
    };
  }
  return getRangeInDateYear(offsetDate, monthToDisplay);
};

export default function TableHeaderContextProvider({
  currentYear,
  setCurrentYear = () => functionNotSet(),
  monthRangeSize = 6,
  monthOffset = -1,
  isYearSubtracted = false,
  isYearAdded = false,
  globalView = false,
  children,
}: PropsWithChildren<TableHeaderContextProviderProps>) {
  const [isShowingMarginRate, setShowingMarginRate] = useState(false);
  const [dateRange, setDateRange] = useState(
    getDefaultDateRange(
      currentYear,
      monthRangeSize,
      monthOffset,
      isYearSubtracted,
      isYearAdded
    )
  );
  const incrementMonth = (amount?: number) => {
    setDateRange({
      start: dateRange.start.clone().add(amount || 1, 'month'),
      end: dateRange.end.clone().add(amount || 1, 'month'),
    });
  };
  const path = useHistory()?.location?.pathname;
  if (path?.includes('/mission-followup/revenue/estimated')) {
    monthRangeSize = 5;
  }
  const decrementMonth = (amount?: number) => {
    setDateRange({
      start: dateRange.start.clone().subtract(amount || 1, 'month'),
      end: dateRange.end.clone().subtract(amount || 1, 'month'),
    });
  };

  const getDisplayedMonths = useCallback(() => {
    const months: Moment[] = [];
    const start = dateRange.start.clone();
    while (
      dateRange.end > start ||
      start.format('M') === dateRange.end.format('M')
    ) {
      months.push(start.clone());
      start.add(1, 'month');
    }
    return months;
  }, [dateRange.end, dateRange.start]);

  const [displayedMonths, setDisplayedMonths] = useState<Moment[]>(
    getDisplayedMonths()
  );

  useEffect(() => {
    const globalViewCheckMonth = globalView
      ? currentYear.isSame(moment(), 'month')
      : true;
    if (currentYear.isSame(moment(), 'year') && globalViewCheckMonth) {
      setDateRange(
        getDefaultDateRange(
          moment(),
          monthRangeSize,
          monthOffset,
          isYearSubtracted,
          isYearAdded
        )
      );
    } else {
      let start;
      if (isYearSubtracted) {
        start = currentYear
          .clone()
          .endOf('year')
          .subtract(monthRangeSize - 1, 'month');
      } else if (isYearAdded) {
        start = currentYear.clone().startOf('year');
      } else {
        start = currentYear;
      }
      const end = start.clone().add(monthRangeSize - 1, 'month');
      setDateRange({ start, end });
    }
  }, [
    monthRangeSize,
    monthOffset,
    isYearSubtracted,
    isYearAdded,
    globalView,
    currentYear,
  ]);

  useEffect(() => {
    setDisplayedMonths(getDisplayedMonths());
  }, [dateRange, getDisplayedMonths]);

  return (
    <TableHeaderContext.Provider
      value={{
        currentYear,
        setCurrentYear,
        dateRange,
        incrementMonth,
        decrementMonth,
        displayedMonths,
        isYearSubtracted,
        isYearAdded,
        setDateRange,
        isShowingMarginRate,
        toggleShowMarginRate: () =>
          setShowingMarginRate((isShowingMarginRate) => !isShowingMarginRate),
      }}
    >
      {children}
    </TableHeaderContext.Provider>
  );
}
