import { Autocomplete, Box, Grid, Typography } from '@mui/material';
import InfoTooltip from 'components/commons/InfoTooltip';
import AddItem from 'components/commons/ItemInteractions/AddItem/AddItem';
import SectionTitle from 'components/commons/SectionTitle';
import { getModalityName } from 'components/MissionFollowUp/BillDetail/BillDetail/utils';
import {
  DisplayAddress,
  getStringAddress,
  parseAddress,
} from 'components/MissionFollowUp/DisplayAddress';
import GridItem from 'components/MissionFollowUp/GridItem';
import GridSubTitle from 'components/MissionFollowUp/GridSubTitle';
import LabelTextFieldGrid from 'components/MissionFollowUp/LabelTextFieldGrid';
import {
  BillingBillingModalitiesBillingTypeChoices,
  BillingClientNode,
  BillingModalitiesNode,
} from 'generated/graphql';
import _ from 'lodash';
import { requiredForm } from 'pages/MissionFollowUp/formValidators';
import React from 'react';
import {
  Controller,
  FieldError,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';

import ModalityEmail from './ModalitiesTemplates/ModalityEmail';
import ModalityPostal from './ModalitiesTemplates/ModalityPostal';
import ModalityUpload from './ModalitiesTemplates/ModalityUpload';
import ModalityCard from './ModalityCard';

interface ExternalActivityModalitiesFormProps {
  billingClients: BillingClientNode[];
}

export default function ExternalActivityModalitiesForm({
  billingClients,
}: ExternalActivityModalitiesFormProps): JSX.Element {
  const { control, errors, watch, trigger } = useFormContext();

  const { fields, remove, append } = useFieldArray({
    control,
    keyName: 'keyName',
    name: 'billingModalities',
  });

  const selectedBillingClient = watch('selectedBillingClient');
  const parsedAddress = parseAddress(selectedBillingClient);
  const stringAddress = getStringAddress(selectedBillingClient);
  const addressTitle = (
    <span>
      Adresse de facturation
      <InfoTooltip
        title={
          'Contactez la compta pour changer cette adresse: compta-clients@polyconseil.fr'
        }
      />
    </span>
  );

  function addModality() {
    append({
      billingType: BillingBillingModalitiesBillingTypeChoices.Email,
      billingAddress: stringAddress,
    });
  }

  return (
    <Grid container spacing={0} sx={{ paddingBottom: 2 }}>
      <GridSubTitle customspacingtop={20}>
        <SectionTitle variant="h3Bold">Modalités de facturation</SectionTitle>
      </GridSubTitle>

      <Grid item xs={6}>
        <Controller
          name={'selectedBillingClient'}
          rules={{ required: requiredForm }}
          control={control}
          render={(properties) => (
            <Autocomplete
              id={'selected-billing-client'}
              autoComplete
              autoHighlight
              {...properties}
              options={billingClients}
              isOptionEqualToValue={(option, value) =>
                option ? value.id === option.id : true
              }
              onChange={(_, data) => {
                properties.onChange(data);
                trigger('selectedBillingClient');
              }}
              getOptionLabel={(client) => client.corporateName}
              renderInput={(parameters) => (
                <LabelTextFieldGrid
                  required
                  title="Client à facturer"
                  sizegrid={12}
                  customElementInTitle={
                    <InfoTooltip
                      style={{ pointerEvents: 'auto' }}
                      title={
                        'La liste des clients est celle de Talentia. Si votre client ne figure pas dans la liste, veuillez contacter le service comptable'
                      }
                    />
                  }
                  {...parameters}
                  errorform={errors.selectedBillingClient as FieldError}
                  InputProps={{
                    ...parameters.InputProps,
                  }}
                />
              )}
            />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <Controller
          name="refMarket"
          render={(properties) => (
            <LabelTextFieldGrid
              {...properties}
              onChange={(e) => {
                properties.onChange(e);
                trigger('refMarket');
              }}
              errorform={errors.refMarket as FieldError}
              name={'refMarket'}
              title="Identifiant du marché"
              customElementInTitle={
                <InfoTooltip
                  style={{ pointerEvents: 'auto' }}
                  title={
                    'Identifiant du marché qui figurera sur la facture pour que le client reconnaisse le contrat facturé.'
                  }
                />
              }
              sizegrid={12}
            />
          )}
        />
      </Grid>

      {Object.keys(parsedAddress).length > 0 && (
        <Grid container item xs={12}>
          <GridSubTitle>{addressTitle}</GridSubTitle>
          <GridItem sizegrid={12}>
            <DisplayAddress
              addressInformations={parsedAddress}
              displayTabTitle={true}
            />
          </GridItem>
        </Grid>
      )}

      <Box sx={{ width: '100%', py: 1, px: 1.5 }}>
        {fields.length === 0 && (
          <Typography color={'error'} my={4}>
            Il est obligatoire d&apos;ajouter au moins une modalité de
            facturation.
          </Typography>
        )}

        {_.map(fields, (modality, index) => {
          if (!modality || !modality.billingType) {
            return <div key={`modality-${modality.keyName}`} />;
          }
          return (
            <ModalityCard
              selectName={getModalityName(index, 'billingType')}
              selector={modality.billingType}
              key={`modality-${modality.keyName}`}
              index={index}
              onDeleteModality={remove}
            >
              <Grid container item xs={12}>
                {watch(getModalityName(index, 'billingType')) ===
                  BillingBillingModalitiesBillingTypeChoices.Email && (
                  <ModalityEmail
                    modality={modality as BillingModalitiesNode}
                    index={index}
                  />
                )}
                {watch(getModalityName(index, 'billingType')) ===
                  BillingBillingModalitiesBillingTypeChoices.Upload && (
                  <ModalityUpload
                    index={index}
                    modality={modality as BillingModalitiesNode}
                  />
                )}
                {watch(getModalityName(index, 'billingType')) ===
                  BillingBillingModalitiesBillingTypeChoices.Postal && (
                  <ModalityPostal
                    index={index}
                    modality={modality as BillingModalitiesNode}
                  />
                )}
              </Grid>
            </ModalityCard>
          );
        })}
        <AddItem onClick={addModality} description={'Nouvelle modalité'} />
      </Box>
    </Grid>
  );
}
