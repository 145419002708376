import { Typography } from '@mui/material';
import { Stack, Switch, Tooltip } from '@mui/material';
import { CenteredTableCell } from 'components/commons/Tables/FlatTable';
import { Moment } from 'moment';
import React from 'react';

interface ProfitabilityTableHeaderColumnsNamesProps {
  month: Moment;
  isShowingMarginRate: boolean;
  toggleShowMarginRate: () => void;
  isExcludingIntern: boolean;
  yearToDate?: boolean;
}

export default function ProfitabilityTableHeaderColumnsNames({
  month,
  isShowingMarginRate,
  toggleShowMarginRate,
  isExcludingIntern,
  yearToDate,
}: ProfitabilityTableHeaderColumnsNamesProps) {
  function getEtpTooltipTitle(): React.ReactNode {
    if (isExcludingIntern) {
      return `Jours réels réalisés par CDI / Jours ouvrés${
        yearToDate ? ' depuis le 1er Janvier' : ''
      }`;
    }
    return `Jours réels réalisés / Jours ouvrés${
      yearToDate ? ' depuis le 1er Janvier' : ''
    }`;
  }

  return (
    <React.Fragment
      key={`headerTitle-${yearToDate ? 'year-to-date' : month.format('MMM')}`}
    >
      <CenteredTableCell sx={{ whiteSpace: 'normal' }}>
        <Tooltip title={getEtpTooltipTitle()} arrow>
          <div>ETP</div>
        </Tooltip>
      </CenteredTableCell>

      <CenteredTableCell sx={{ whiteSpace: 'normal' }}>
        <Tooltip
          title={
            isExcludingIntern
              ? 'CA / Jours réalisés par CDI'
              : 'CA / Jours réalisés'
          }
          arrow
        >
          <div>
            <Stack alignItems="center">TJM vendu</Stack>
          </div>
        </Tooltip>
      </CenteredTableCell>

      <CenteredTableCell sx={{ whiteSpace: 'normal' }}>CA</CenteredTableCell>
      <CenteredTableCell sx={{ whiteSpace: 'normal' }}>Coût</CenteredTableCell>

      <CenteredTableCell sx={{ whiteSpace: 'normal' }}>
        <Tooltip
          title={
            isShowingMarginRate
              ? '(Marge / CA Réalisé) x 100'
              : 'CA Réalisé - Coût'
          }
          arrow
        >
          <div>
            <Stack alignItems="center">
              Marge
              <Stack direction="row">
                <Typography color={'text.secondary'}>€</Typography>
                <Switch
                  onClick={toggleShowMarginRate}
                  checked={isShowingMarginRate}
                  size="small"
                />
                <Typography color={'text.secondary'}>%</Typography>
              </Stack>
            </Stack>
          </div>
        </Tooltip>
      </CenteredTableCell>
    </React.Fragment>
  );
}
