import { DialogContent } from '@mui/material';
import ListCommentaries from 'components/commons/ListCommentaries';
import styles from 'components/MissionFollowUp/DownloadFilesModal/styles/DownloadFilesModal.module.scss';
import PolyModal from 'components/MUIOverload/PolyDialog/PolyModal';
import {
  BillNode,
  CommentaryNode,
  useFetchCommentariesLazyQuery,
} from 'generated/graphql';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { setCurrentBill } from 'store/purchaseOrder';

interface ModalBillCommentariesProps {
  open: boolean;
  onClose: () => void;
}

export default function ModalBillCommentaries(
  properties: ModalBillCommentariesProps
) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const currentBill = useSelector((state) => state.purchaseOrder.currentBill);

  const [fetchCommentaries] = useFetchCommentariesLazyQuery({
    onError: () => {
      enqueueSnackbar('Erreur lors de la récupération des commentaires', {
        variant: 'error',
      });
      properties.onClose();
    },
    onCompleted: (data) => {
      if (_.isEmpty(data.commentaries)) {
        return;
      }
      const bill: BillNode = {
        ...currentBill,
        commentaries: data.commentaries as CommentaryNode[],
      };
      dispatch(setCurrentBill(bill));
    },
  });

  useEffect(() => {
    if (currentBill.id) {
      fetchCommentaries({
        variables: {
          billId: currentBill.id || '',
        },
      });
    }
  }, [currentBill.id, fetchCommentaries]);

  return (
    <PolyModal
      maxWidth={'lg'}
      open={properties.open}
      onClose={properties.onClose}
      disableEnforceFocus={true}
      polyDialogTitle={`Commentaires pour ${currentBill.title}`}
    >
      <div className={styles.root}>
        <DialogContent sx={{ py: 0 }}>
          <ListCommentaries />
        </DialogContent>
      </div>
    </PolyModal>
  );
}
