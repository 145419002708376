import { Stack } from '@mui/material';
import FilterContextProvider from 'components/commons/FilterContext/FilterContextProvider';
import TabHeader from 'components/commons/TabHeader';
import TableHeaderContextProvider, {
  useTableHeaderContext,
} from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import YearSelector from 'components/commons/YearSelector/YearSelector';
import PolySwitch from 'components/Navigation/PolySwitch';
import NonValidatedRevenueButton from 'components/Revenue/ActualRevenueTables/NonValidatedRevenueButton';
import FilterDirector from 'components/Revenue/RevenueGlobalTable/Filters/FilterDirector';
import AccessWrapper from 'components/User/AccessWrapper';
import { EmployeeOccupations, useUserInfo } from 'components/User/UserProvider';
import { EmployeeNode, EmployeesEmployeeGradeChoices } from 'generated/graphql';
import moment from 'moment/moment';
import InternalMissionPage from 'pages/ErrorPage/InternalMissionPage';
import ActualRevenueHomePage from 'pages/RevenuePage/ActualRevenuePage/ActualRevenueHomePage';
import ActualRevenueMissionPage from 'pages/RevenuePage/ActualRevenuePage/ActualRevenueMissionPage';
import { PageTitles } from 'poly-constants';
import React, { useEffect, useState } from 'react';
import {
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { useSelector } from 'store';
import urlJoin from 'url-join';
import { checkIfAllMissionsAreInternal } from 'utils';

export default function ActualRevenuePage() {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const selectedYear = urlParams.has('year')
    ? urlParams.get('year')
    : moment().year().toString();
  const [currentYear, setCurrentYear] = useState(moment(selectedYear));
  const currentMissionId = useSelector(
    (state) => state.activity.currentMission?.id
  );
  const selectedMissions = useSelector(
    (state) => state.activity.selectedMissions
  );

  useEffect(
    () => {
      const urlYear = urlParams.get('year') || '';
      const yearStr = currentYear.year().toString();

      if (urlYear !== yearStr) {
        urlParams.set('year', yearStr);
        history.push({
          search: urlParams.toString(),
        });
      }
    },
    // eslint-disable-next-line
    [currentYear, history]
  );

  if (
    selectedMissions.length > 0 &&
    checkIfAllMissionsAreInternal(selectedMissions)
  ) {
    return <InternalMissionPage page={PageTitles.actualRevenuePage} />;
  }

  return (
    <PolySwitch>
      <AccessWrapper
        occupations={[EmployeeOccupations.CONTROL, EmployeeOccupations.DG]}
        grades={[EmployeesEmployeeGradeChoices.Comex]}
        restrictMissionChief={true}
        restrictMissionDirector={true}
      >
        <TableHeaderContextProvider
          currentYear={currentYear}
          setCurrentYear={setCurrentYear}
        >
          <FilterContextProvider>
            <Route
              exact
              path={urlJoin(path, '')}
              component={
                currentMissionId
                  ? ActualRevenueMissionPage
                  : ActualRevenueHomePage
              }
            />
          </FilterContextProvider>
        </TableHeaderContextProvider>
      </AccessWrapper>
    </PolySwitch>
  );
}

export function ActualRevenueTabHeader({
  directors,
  actionComponents,
  isDetailedView,
}: {
  directors?: EmployeeNode[];
  actionComponents?: JSX.Element;
  isDetailedView?: Boolean;
}) {
  const { currentYear, setCurrentYear } = useTableHeaderContext();
  const { employee, isControl, isSuperuser } = useUserInfo();
  const isComex = employee?.grade === EmployeesEmployeeGradeChoices.Comex;

  const isFilterVisible = directors && (isComex || isControl || isSuperuser);
  const directorFilter = isFilterVisible && (
    <FilterDirector directors={directors} />
  );
  return (
    <TabHeader
      title={PageTitles.actualRevenuePage}
      yearSelectorComponent={
        <YearSelector
          currentYear={currentYear}
          setCurrentYear={setCurrentYear}
        />
      }
      filterComponents={
        <Stack gap={2} direction={'row'} alignItems={'center'}>
          {directorFilter}
          {!isDetailedView && <NonValidatedRevenueButton />}
        </Stack>
      }
      actionComponents={actionComponents}
    />
  );
}
