import { StepIcon, StepIconProps } from '@mui/material';
import React from 'react';

const PolyStepIcon = (properties: StepIconProps) => {
  return (
    <StepIcon
      {...properties}
      sx={{
        width: '35px',
        height: '35px',
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
        mt: 0.5,
        color: 'inherit',
      }}
    />
  );
};

export default PolyStepIcon;
