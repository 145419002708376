import { checkEmployeeStatusWithFilter } from 'components/Phonebook/PhonebookFinder/utils';
import { checkEmployeeRolesWithFilter } from 'components/Phonebook/PhonebookFinder/utils';
import {
  ActivityNode,
  EmployeeNode,
  EmployeesEmployeeContractTypeChoices,
  EmployeesEmployeeGradeChoices,
  useAllEmployeesAndActivitiesQuery,
} from 'generated/graphql';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';

export interface PolyTrombiContext {
  allEmployees: EmployeeNode[];
  allMissions: ActivityNode[];
  employeesFilteredByMissions: EmployeeNode[];
  employeesFilteredByStatus: EmployeeNode[];
  employeesFilteredByRole: EmployeeNode[];
  displayedEmployees: EmployeeNode[];
  searchedEmployees: EmployeeNode[];
  setSearchedEmployees: Dispatch<SetStateAction<EmployeeNode[]>>;
  selectedMissions: ActivityNode[];
  setSelectedMissions: Dispatch<SetStateAction<ActivityNode[]>>;
  selectedStatus: (
    | EmployeesEmployeeGradeChoices
    | EmployeesEmployeeContractTypeChoices
  )[];
  setSelectedStatus: Dispatch<
    SetStateAction<
      (EmployeesEmployeeGradeChoices | EmployeesEmployeeContractTypeChoices)[]
    >
  >;
  selectedOccupations: string[];
  setSelectedOccupations: Dispatch<SetStateAction<string[]>>;
  showSkeleton: boolean;
  setShowSkeleton: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
}

export const PolyTrombiContext = createContext<PolyTrombiContext>({
  allEmployees: [],
  allMissions: [],
  employeesFilteredByMissions: [],
  employeesFilteredByStatus: [],
  employeesFilteredByRole: [],
  displayedEmployees: [],
  searchedEmployees: [],
  setSearchedEmployees: () => {
    throw new Error('Function setSearchEmployees not yet initialized.');
  },
  selectedMissions: [],
  setSelectedMissions: () => {
    throw new Error('Function setSelectedMissions not yet initialized.');
  },
  selectedStatus: [],
  setSelectedStatus: () => {
    throw new Error('Function setSelectedStatus not set.');
  },
  selectedOccupations: [],
  setSelectedOccupations: () => {
    throw new Error('Function setSelectedOccupations not yet initialized.');
  },
  showSkeleton: false,
  setShowSkeleton: () => {
    throw new Error('Function setShowSkeleton not yet initialized.');
  },
  isLoading: true,
});

export function usePolyTrombiContext() {
  const polyTrombiContext = useContext(PolyTrombiContext);
  if (!polyTrombiContext) {
    throw new Error('useContext() can only be used inside a table');
  }
  return polyTrombiContext;
}

interface PolyTrombiContextProps {
  children: React.ReactFragment;
}

export default function PolyTrombiContextProvider({
  children,
}: PolyTrombiContextProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedMissions, setSelectedMissions] = useState<ActivityNode[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<
    (EmployeesEmployeeGradeChoices | EmployeesEmployeeContractTypeChoices)[]
  >([]);
  const [selectedOccupations, setSelectedOccupations] = useState<string[]>([]);
  const [searchedEmployees, setSearchedEmployees] = useState<EmployeeNode[]>(
    []
  );
  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);
  const { loading: isLoading, data } = useAllEmployeesAndActivitiesQuery({
    onCompleted: (data) => {
      setSearchedEmployees((data?.allActiveEmployees as EmployeeNode[]) || []);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const allEmployees = (data?.allActiveEmployees as EmployeeNode[]) || [];
  const allMissions = (data?.allActivities as ActivityNode[]) || [];

  const employeesFilteredByMissions =
    selectedMissions.length === 0
      ? allEmployees
      : _.reduce(
          selectedMissions,
          (acc, mission) => {
            const filteredAssignments = _.filter(
              mission.assignments,
              (assignment) =>
                _.some(allEmployees, (e) => e.id === assignment.employee.id) &&
                !_.some(acc, (e) => e.id === assignment.employee.id)
            );
            return acc.concat(
              _.map(filteredAssignments, (assignment) => assignment.employee)
            );
          },
          [] as EmployeeNode[]
        );

  const employeesFilteredByStatus = _.filter(allEmployees, (employee) =>
    checkEmployeeStatusWithFilter(selectedStatus, employee)
  );

  const employeesFilteredByRole = _.filter(allEmployees, (employee) =>
    checkEmployeeRolesWithFilter(selectedOccupations, employee.occupation)
  );

  const displayedEmployees = [
    searchedEmployees,
    employeesFilteredByMissions,
    employeesFilteredByStatus,
    employeesFilteredByRole,
  ].reduce((array1, array2) =>
    array1.filter((employee1) =>
      array2.some((employee2) => employee2.id === employee1.id)
    )
  );

  return (
    <PolyTrombiContext.Provider
      value={{
        allEmployees,
        allMissions,
        employeesFilteredByMissions,
        employeesFilteredByStatus,
        employeesFilteredByRole,
        displayedEmployees,
        searchedEmployees,
        setSearchedEmployees,
        selectedMissions,
        setSelectedMissions,
        selectedStatus,
        setSelectedStatus,
        selectedOccupations,
        setSelectedOccupations,
        showSkeleton,
        setShowSkeleton,
        isLoading,
      }}
    >
      {children}
    </PolyTrombiContext.Provider>
  );
}
