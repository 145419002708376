import { TableCell, TableRow, Typography } from '@mui/material';
import ArrowsUpdateMonthsTableHeader from 'components/commons/Tables/Header/ArrowsUpdateMonthsTableHeader';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

import {
  enableMonthDecrement,
  enableMonthIncrement,
} from '../Profitability/utils';
import { StyledTableHead } from './ProviderAdrTableHeader';

export default function ProviderAdrHistoryTableHeader() {
  const { displayedMonths } = useTableHeaderContext();
  return (
    <StyledTableHead>
      <TableRow>
        <TableCell width={'20%'}>
          <Typography fontWeight={'bold'}>Nom</Typography>
        </TableCell>
        <TableCell>
          <Typography fontWeight={'bold'}>Rôle</Typography>
        </TableCell>
        {_.map(displayedMonths, (month) => {
          return (
            <TableCell key={`header-${month.format('MMM')}`} sx={{ p: 0 }}>
              <ArrowsUpdateMonthsTableHeader
                month={month}
                enableMonthIncrement={enableMonthIncrement(month)}
                enableMonthDecrement={enableMonthDecrement(month)}
              >
                <Typography
                  fontWeight={
                    month.isSame(moment(), 'month') ? 'bold' : 'normal'
                  }
                  textTransform={'capitalize'}
                >
                  {month.format('MMM')}
                </Typography>
              </ArrowsUpdateMonthsTableHeader>
            </TableCell>
          );
        })}
      </TableRow>
    </StyledTableHead>
  );
}
