import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box } from '@mui/material';
import PolyIconButton from 'components/MUIOverload/PolyIconButton';
import PolyPopper from 'components/MUIOverload/PolyPopper';
import React, { useState } from 'react';

import ExceptionalActivitiesButton from './ExceptionalActivitiesButton';
import { DotOption } from './utils';

interface ExceptionalActivitiesDotButtonProps<T> {
  data: T;
  options: Array<DotOption<T>>;
}

const ExceptionalActivitiesDotButton = <T,>({
  data,
  options,
}: ExceptionalActivitiesDotButtonProps<T>) => {
  const [open, setOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>();
  const handleClickOpen = () => {
    setOpen((state) => !state);
  };

  return (
    <PolyPopper
      anchorEl={anchorElement}
      open={open}
      disablePortal={false}
      renderToggle={
        <div ref={(reference) => setAnchorElement(reference)}>
          <PolyIconButton onClick={handleClickOpen}>
            <MoreVertIcon />
          </PolyIconButton>
        </div>
      }
      setOpen={setOpen}
    >
      {options.map((option, index) => {
        return (
          <React.Fragment key={`button-${index}-${option.name}`}>
            <ExceptionalActivitiesButton data={data} {...option} />
            {index < options.length - 1 && (
              <Box
                sx={{ mt: '5px', mb: '5px', height: '1px' }}
                key={`separator-${index}-${option.name}`}
              />
            )}
          </React.Fragment>
        );
      })}
    </PolyPopper>
  );
};

export default ExceptionalActivitiesDotButton;
