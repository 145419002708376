import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Popover,
  Radio,
  RadioGroup,
  Stack,
  Switch,
} from '@mui/material';
import { SectorNode, SectorRevenuesNode } from 'generated/graphql';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import {
  AdrGraphMissionFilters,
  Curve,
  useAdrGraphContext,
} from './context/AdrGraphContextProvider';

interface AdrGraphCurveMenuProps {
  curve: Curve;
  sectors: SectorNode[];
}

export default function AdrGraphCurveMenu({
  curve,
  sectors,
}: AdrGraphCurveMenuProps) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { graphData, setDisplayedCurves, displayedCurves, setLoading } =
    useAdrGraphContext();

  const [missionFilter, setMissionFilter] = useState(
    curve.options.selectionType
  );
  const [includeInterns, setIncludeInterns] = useState(
    curve.options.includeInterns
  );
  const [selectedSectorsIds, setSelectedSectorsIds] = useState(
    curve.options.sectorsIds
  );

  const isSectorSelectionEnabled =
    missionFilter === AdrGraphMissionFilters.MISSIONS_BY_SECTOR;
  const canRemoveCurve = displayedCurves.length > 1;

  const getSelectedSectorsData = (allDataPerSector: SectorRevenuesNode[]) => {
    const monthsCount = allDataPerSector[0].monthsRevenues?.length || 0;
    const sectorsRevenuesPerMonth = new Array(monthsCount).fill(0);
    const sectorsWorkedDaysPerMonth = new Array(monthsCount).fill(0);
    const selectedSectorsCurve: number[] = [];
    for (const sectorData of allDataPerSector) {
      if (
        sectorData.sectorId &&
        selectedSectorsIds.includes(sectorData.sectorId)
      ) {
        for (let i = 0; i < monthsCount; i++) {
          sectorsRevenuesPerMonth[i] += sectorData?.monthsRevenues?.[i];
          sectorsWorkedDaysPerMonth[i] += sectorData?.monthsWorkedDays?.[i];
        }
      }
    }

    for (let i = 0; i < monthsCount; i++) {
      selectedSectorsCurve.push(
        sectorsRevenuesPerMonth[i] / sectorsWorkedDaysPerMonth[i]
      );
    }

    return selectedSectorsCurve;
  };

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleMissionFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.value;
    setMissionFilter(newValue as AdrGraphMissionFilters);
  };

  const toggleIncludeIntern = () => {
    setIncludeInterns(!includeInterns);
  };

  const handleSectorChange = (sectorId: string, checked: boolean) => {
    if (checked) {
      setSelectedSectorsIds([...selectedSectorsIds, sectorId]);
    } else {
      setSelectedSectorsIds(
        _.filter(selectedSectorsIds, (id) => id !== sectorId)
      );
    }
  };

  const deleteCurve = () => {
    setLoading(true);
    setDisplayedCurves(
      _.filter(displayedCurves, (currentCurve) => curve.id !== currentCurve.id)
    );
  };

  useEffect(() => {
    setLoading(false);
  }, [displayedCurves, setLoading]);

  useEffect(
    () => {
      let newData: number[] | undefined = [];
      let newName = '';
      if (graphData) {
        if (!includeInterns) {
          switch (missionFilter) {
            case AdrGraphMissionFilters.BILLABLE_MISSIONS:
              newData = graphData?.billableMissionsDataWithoutIntern;
              newName = 'Missions facturables sans stagiaires';
              break;
            case AdrGraphMissionFilters.MISSIONS_BY_SECTOR:
              newData = getSelectedSectorsData(
                graphData?.missionsPerSectorDataWithoutIntern || []
              );
              newName = 'Tri par secteur sans stagiaires';
              break;
            default:
              newData = graphData?.allMissionsDataWithoutIntern;
              newName = 'Toutes les missions sans stagiaires';
              break;
          }
        } else {
          switch (missionFilter) {
            case AdrGraphMissionFilters.BILLABLE_MISSIONS:
              newData = graphData?.billableMissionsData;
              newName = 'Missions facturables';
              break;
            case AdrGraphMissionFilters.MISSIONS_BY_SECTOR:
              newData = getSelectedSectorsData(
                graphData?.missionsPerSectorData || []
              );
              newName = 'Tri par secteur';
              break;
            default:
              newData = graphData?.allMissionsData;
              newName = 'Toutes les missions';
              break;
          }
        }
        setDisplayedCurves(
          _.map(displayedCurves, (displayedCurve: Curve) => {
            if (displayedCurve.id === curve.id) {
              displayedCurve.data = newData || [];
              displayedCurve.name = newName;
            }
            return displayedCurve;
          })
        );
      }
    },
    // eslint-disable-next-line
    [
      missionFilter,
      includeInterns,
      isSectorSelectionEnabled,
      selectedSectorsIds,
      setDisplayedCurves,
      graphData,
    ]
  );

  return (
    <Stack direction={'row'} alignItems="center">
      <Button
        size="small"
        variant="contained"
        sx={{
          fontSize: 10,
          color: 'text.primary',
          backgroundColor: 'white',
          ':hover': {
            backgroundColor: 'white',
          },
        }}
        onClick={openMenu}
        startIcon={<CircleIcon sx={{ color: curve.color }} />}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {curve.name}
      </Button>
      {canRemoveCurve && (
        <IconButton size="small" onClick={deleteCurve}>
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        <Box sx={{ padding: 2 }}>
          <FormControlLabel
            control={<Switch />}
            label="Sans stagiaires"
            checked={!includeInterns}
            onChange={toggleIncludeIntern}
          />
          <RadioGroup
            aria-label="mission-filter"
            value={missionFilter}
            onChange={handleMissionFilterChange}
          >
            <FormControlLabel
              control={<Radio />}
              label="Toutes les missions"
              value={AdrGraphMissionFilters.ALL_MISSIONS}
            />
            <FormControlLabel
              control={<Radio />}
              label="Uniquement les missions facturables"
              value={AdrGraphMissionFilters.BILLABLE_MISSIONS}
            />
            <FormControlLabel
              control={<Radio />}
              label="Par secteur"
              value={AdrGraphMissionFilters.MISSIONS_BY_SECTOR}
            />
          </RadioGroup>
          <Stack sx={{ marginLeft: 2 }}>
            {_.map(sectors, (sector) => {
              return (
                <FormControlLabel
                  key={sector.id}
                  control={<Checkbox />}
                  label={sector.name}
                  checked={_.some(selectedSectorsIds, (id) => id === sector.id)}
                  disabled={!isSectorSelectionEnabled}
                  onChange={(event, checked) =>
                    handleSectorChange(sector.id, checked)
                  }
                />
              );
            })}
          </Stack>
          {canRemoveCurve && (
            <Stack alignItems={'flex-end'}>
              <Button onClick={deleteCurve}>Supprimer</Button>
            </Stack>
          )}
        </Box>
      </Popover>
    </Stack>
  );
}
