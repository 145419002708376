import { Search } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';

type SkillSearchBarProps = TextFieldProps & {
  setSearchTerm: Dispatch<SetStateAction<string>>;
};

export default function SkillSearchBar({
  setSearchTerm,
  ...props
}: SkillSearchBarProps) {
  return (
    <TextField
      {...props}
      autoFocus
      size="small"
      name="skill"
      label="Rechercher"
      placeholder="Rechercher une compétence"
      variant="outlined"
      sx={{
        width: 300,
        transition: 'width 0.3s ease-in-out',
        '&:focus-within': {
          width: 350,
        },
      }}
      inputProps={{ maxLength: 50 }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment
            position="end"
            onClick={() => {
              setSearchTerm('');
            }}
          >
            <IconButton size="small">
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
