import { Box, Grid, Typography } from '@mui/material';
import InfoTooltip from 'components/commons/InfoTooltip';
import SectionTitle from 'components/commons/SectionTitle';
import ModalitiesMissionForm from 'components/MissionFollowUp/BillDetail/MissionInitialization/ModalitiesMissionForm';
import {
  AddressType,
  DisplayAddress,
  getStringAddress,
} from 'components/MissionFollowUp/DisplayAddress';
import GridItem from 'components/MissionFollowUp/GridItem';
import GridSubTitle from 'components/MissionFollowUp/GridSubTitle';
import { ActivityNode } from 'generated/graphql';
import React from 'react';
import { UseFieldArrayMethods } from 'react-hook-form';

interface MissionModalityFormProps {
  address: AddressType;
  mission: ActivityNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modalitiesFieldArray: UseFieldArrayMethods<Record<string, any>, 'key'>;
  isThereModalities?: boolean;
}

const MissionModalityForm = ({
  address,
  mission,
  modalitiesFieldArray,
  isThereModalities = false,
}: MissionModalityFormProps) => {
  const addressTitle = (
    <span>
      Adresse de facturation
      <InfoTooltip
        title={
          'Contactez la compta pour changer cette adresse: compta-clients@polyconseil.fr'
        }
      />
    </span>
  );

  return (
    <Grid container spacing={0} sx={{ paddingBottom: 2 }}>
      <GridSubTitle customspacingtop={20}>
        <SectionTitle variant="h3Bold">
          Modalités de l&apos;envoi de la facturation
        </SectionTitle>
      </GridSubTitle>

      {Object.keys(address).length > 0 && (
        <Grid container item xs={12}>
          <GridSubTitle>{addressTitle}</GridSubTitle>
          <GridItem sizegrid={12}>
            <DisplayAddress
              addressInformations={address}
              displayTabTitle={true}
            />
          </GridItem>
        </Grid>
      )}

      <Box sx={{ width: '100%', py: 1, px: 1.5 }}>
        {!isThereModalities && (
          <Typography color={'error'} my={4}>
            Il est obligatoire d&apos;ajouter au moins une modalité de
            facturation.
          </Typography>
        )}
        <ModalitiesMissionForm
          {...modalitiesFieldArray}
          clientAddress={getStringAddress(
            mission.billingInformation?.billingClient
          )}
        />
      </Box>
    </Grid>
  );
};

export default MissionModalityForm;
