import {
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from '@mui/material/styles';
import React, { createContext, useContext } from 'react';
import { designSystem } from 'theme';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

type ITheme = 'light' | 'dark';
type IThemeProviderProps = React.PropsWithChildren<{}>;

interface ThemeContext {
  theme: string;
  setTheme: (theme: ITheme) => void;
}

export const ThemeContext = createContext<ThemeContext | null>(null);

export const useTheme = (): ThemeContext => {
  const themeContext = useContext(ThemeContext);
  if (themeContext === null) {
    throw new Error(
      'useTheme context must be call inside ThemeContext wrapper'
    );
  }
  return themeContext;
};

const ThemeProviderCustom = ({ children }: IThemeProviderProps) => {
  const theme: ITheme = 'light';
  document.documentElement.className = '';
  document.documentElement.classList.add(`theme-${theme}`);

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={designSystem}>{children}</ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

export default ThemeProviderCustom;
