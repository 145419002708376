import MessageIcon from '@mui/icons-material/Message';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';
import ProviderEditCommentModal from 'components/ActivityMonitoring/ProviderEditCommentModal';
import Cell, { CellType } from 'components/ActivityPage/common/Cell';
import {
  basicWorkedDayValidationRules,
  formatValue,
  validationWrapper,
} from 'components/commons/Tables/utils';
import { useUserInfo } from 'components/User/UserProvider';
import { EmployeesEmployeeContractTypeChoices } from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import React, { PropsWithRef, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styles from 'styles/ActivityPage/InputCell.module.scss';

import { dotToComa } from '../utils';
import { Activity } from '.';
import { useValueContext } from './context/ValueContextProvider';

export enum TimeMaterialType {
  None,
  Real,
  Billed,
}

interface InputCellProps {
  defaultValue: string;
  idActivity: Activity['id'];
  dateAm: moment.Moment;
  formDataIndex: number;
  timeMaterialType: TimeMaterialType;
  disabled: boolean;
  hatched: string;
  isVacation?: boolean;
  tabIndex?: number;
  billing?: boolean;
  isInvalid?: boolean;
  comment?: string;
}

const InputCell = ({
  defaultValue,
  idActivity,
  dateAm,
  disabled = false,
  hatched = '',
  isVacation = false,
  tabIndex = -1,
  billing = false,
  isInvalid,
  comment,
}: PropsWithRef<InputCellProps>) => {
  const { employee } = useUserInfo();
  const isProvider =
    employee?.contractType === EmployeesEmployeeContractTypeChoices.Provider;
  const { register, errors, getValues, watch, trigger } = useFormContext();
  const { invalidWeeks, providerOvertimeWeeks, needToAddCommentWeeks } =
    useValueContext();
  const inputDate = dateAm.format('YYYY-MM-DD');
  const watchAllFields = watch();
  const [inputValue, setInputValue] = useState<string>(dotToComa(defaultValue));
  const [openProviderCommentModal, setOpenProviderCommentModal] =
    useState<boolean>(false);
  const [providerComment, setProviderComment] = useState<string>(comment || '');

  const getFormReferenceName = useCallback(() => {
    let response = `activityMonitoring[${inputDate}][${idActivity}]`;
    response = `${response}.timeSpent`;
    if (billing) {
      const month = dateAm
        .clone()
        .startOf('isoWeek')
        .endOf('month')
        .format('YYYY-MM-DD');
      response = `billing[${month}][${idActivity}].total`;
    }

    return response;
  }, [inputDate, idActivity, billing, dateAm]);

  const validationError = _.get(errors, getFormReferenceName());

  const isInputValid = (value: string) => {
    if (isProvider) {
      return formatValue(value) >= 0;
    } else {
      return formatValue(value) >= 0 && formatValue(value) <= 5;
    }
  };

  const shouldDisplayProviderComment =
    providerOvertimeWeeks[inputDate] && inputValue && !isVacation && !billing;

  useEffect(() => {
    if (getValues(getFormReferenceName())) {
      trigger(getFormReferenceName());
    }
  }, [getFormReferenceName, getValues, trigger, watchAllFields]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setInputValue(event.target.value);

  return (
    <>
      <Cell
        cellType={CellType.Old}
        isInvalid={
          isInvalid ||
          ((invalidWeeks[inputDate] ||
            needToAddCommentWeeks.includes(inputDate)) &&
            !billing)
        }
        isCurrentWeek={moment().isSame(dateAm, 'week')}
      >
        <Tooltip
          title={hatched || (validationError && validationError.message) || ''}
          arrow
        >
          <span>
            <TextField
              variant={isVacation ? 'standard' : 'outlined'}
              size="small"
              aria-label={getFormReferenceName()}
              error={!!validationError}
              focused={!!validationError}
              color="secondary"
              className={clsx(styles.input, {
                [styles.input_disabled]: disabled,
                [styles.input_hatched]: hatched,
                [styles.vacation]: isVacation,
              })}
              sx={{
                width: 0.6,
                '& fieldset': {
                  border: 'solid 1px',
                  borderColor:
                    !hatched && (isInvalid || validationError)
                      ? 'error.main'
                      : 'darkGrey.dark',
                  borderRadius: '8px',
                },
              }}
              inputProps={{
                sx: {
                  borderRadius: '8px',
                  height: '20px',
                  fontSize: '0.875rem',
                  fontWeight: 'bold',
                  padding: '3px 0px',
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: shouldDisplayProviderComment ? 'end' : 'center',
                  width: shouldDisplayProviderComment ? '50%' : '100%',
                  ...(!hatched &&
                    !isVacation && {
                      backgroundColor: 'standardGrey.main',
                    }),
                },
                tabIndex: tabIndex,
                'aria-label': getFormReferenceName(),
              }}
              InputProps={{
                ...(shouldDisplayProviderComment && {
                  endAdornment: (
                    <InputAdornment position="end" sx={{ width: '50%' }}>
                      <IconButton
                        aria-label="toggle comment modal"
                        onClick={() => setOpenProviderCommentModal(true)}
                        edge="end"
                        disableFocusRipple
                        disableRipple
                        sx={{
                          pr: 0,
                          pl: 0.5,
                          ':hover': { backgroundColor: 'transparent' },
                        }}
                        size="large"
                      >
                        {providerComment ? (
                          <MessageIcon />
                        ) : (
                          <MessageOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }),
                ...(isVacation && {
                  disableUnderline: true,
                }),
              }}
              inputRef={register({
                required: false,
                validate: {
                  ...basicWorkedDayValidationRules,
                  isInRange: (value) =>
                    validationWrapper(
                      value,
                      billing
                        ? formatValue(value) >= 0 ||
                            'La valeur saisie doit être positive'
                        : isInputValid(value) ||
                            (isProvider
                              ? 'La valeur saisie doit être supérieure 0.'
                              : 'La valeur saisie doit être entre 0 et 5.')
                    ),
                },
              })}
              name={getFormReferenceName()}
              onChange={handleChange}
              defaultValue={dotToComa(defaultValue)}
              placeholder={isVacation ? '-' : ''}
            />
          </span>
        </Tooltip>
      </Cell>
      <ProviderEditCommentModal
        open={openProviderCommentModal}
        handleClose={() => setOpenProviderCommentModal(false)}
        week={inputDate}
        activityId={idActivity}
        comment={providerComment}
        setComment={setProviderComment}
        formReferenceName={`activityMonitoring[${inputDate}][${idActivity}].comment`}
      />
    </>
  );
};

export default InputCell;
