import EuroIcon from '@mui/icons-material/Euro';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { Stack } from '@mui/material';
import ToggleButton from 'components/commons/ToggleButton';
import {
  ActivitiesActivityBillingTypeChoices,
  ActivityNode,
} from 'generated/graphql';
import _ from 'lodash';
import React from 'react';

interface Props {
  mission: ActivityNode;
}

const MissionToggles = ({ mission }: Props) => {
  const isInitialized = !!mission.billingInformation;
  const isTM = mission.billingType === ActivitiesActivityBillingTypeChoices.Tm;
  const params = [
    {
      icon: <NoteAltIcon />,
      title: 'Suivi des temps',
      description:
        'Ce paramétrage permettra à vos collaborateurs de déclarer du temps sur les sous-missions',
      checked: isTM /* || mission.toggle_time_followup */,
      disabled: isInitialized || isTM,
    },
    {
      icon: <EuroIcon />,
      title: 'Chiffre d’affaires',
      description:
        'Ce paramétrage vous permettra de déclarer du CA sur les sous-missions',
      checked: false,
      disabled: isInitialized,
    },
    {
      icon: <ReceiptLongIcon />,
      title: 'Facturation',
      description:
        'Ce paramétrage vous permettra de découper vos factures par sous-missions',
      checked: false,
      disabled: isInitialized,
    },
  ];

  return (
    <Stack display="flex" flexDirection="row" gap={2} pt={2}>
      {_.map(params, (param, index) => {
        return (
          <ToggleButton
            key={index}
            icon={param.icon}
            title={param.title}
            checked={param.checked}
            disabled={param.disabled}
          >
            {param.description}
          </ToggleButton>
        );
      })}
    </Stack>
  );
};

export default MissionToggles;
