import { Chip } from '@mui/material';
import { EmployeeStatusTextChoices } from 'components/Phonebook/utils';
import { EmployeeNode } from 'generated/graphql';
import moment from 'moment';
import React from 'react';

export const getEmployeeStatusChip = (employee: EmployeeNode) => {
  const { hiringDate, leavingDate } = employee;
  let label = EmployeeStatusTextChoices.PRESENT;
  let variant: 'passed' | 'upcoming' | 'pending' = 'passed';
  if (moment(hiringDate).isAfter(moment.now())) {
    label = EmployeeStatusTextChoices.COMING;
    variant = 'upcoming';
  } else if (moment(leavingDate).isBefore(moment.now(), 'day')) {
    label = EmployeeStatusTextChoices.LEFT;
    variant = 'pending';
  }

  return {
    label,
    jsx: <Chip label={label} variant={variant} size="small" />,
  };
};
