import { Chip, useTheme } from '@mui/material';
import { DisplayStatus } from 'components/Phonebook/PhonebookFinder/StatusChip';
import {
  EmployeesEmployeeContractTypeChoices,
  EmployeesEmployeeGradeChoices,
} from 'generated/graphql';
import React from 'react';

interface GraphFilterProps {
  status: EmployeesEmployeeContractTypeChoices | EmployeesEmployeeGradeChoices;
  onClick: (
    arg: EmployeesEmployeeContractTypeChoices | EmployeesEmployeeGradeChoices
  ) => void;
  isSelected?: boolean;
}

export default function GraphFilterChip({
  status,
  onClick,
  isSelected,
}: GraphFilterProps) {
  const theme = useTheme();
  return (
    <Chip
      variant="outlined"
      label={<DisplayStatus status={status} />}
      onClick={() => onClick(status)}
      sx={{
        width: 'fit-content',
        height: '38px',
        mt: 2,
        borderRadius: '20px',
        ...(isSelected && { borderColor: theme.palette.primary.main }),
        ...(isSelected && { borderWidth: '2px' }),
      }}
    />
  );
}
