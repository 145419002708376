import { TableCell } from '@mui/material';
import { useFilterContext } from 'components/commons/FilterContext/FilterContextProvider';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import CollapsePolyTableCell from 'components/MUIOverload/PolyTableCell/CollapsePolyTableCell';
import ChildCollapsePolyTableRow from 'components/MUIOverload/PolyTableRow/ChildCollapsePolyTableRow';
import TransitionPolyTableRow from 'components/MUIOverload/PolyTableRow/TransitionPolyTableRow';
import { verifyEmployeePermissions } from 'components/Revenue/Estimated/utils';
import {
  TableMode,
  useTableContext,
} from 'components/Revenue/RevenueGlobalTable/context/TableContextProvider';
import MissionRow from 'components/Revenue/RevenueGlobalTable/RevenueRows/MissionRow';
import {
  BillingActivityInformationRevenue,
  BillingClientRevenue,
} from 'components/Revenue/RevenueGlobalTable/RevenueTableBodyByClient';
import styles from 'components/Revenue/RevenueGlobalTable/styles/TableCell.module.scss';
import DisplayValueTableCell from 'components/Revenue/Tables/RevenueTableCells/DisplayValueTableCell';
import OutOfRangeTableCell from 'components/Revenue/Tables/RevenueTableCells/OutOfRangeTableCell';
import { useUserInfo } from 'components/User/UserProvider';
import {
  ActivityNode,
  BillingClientNode,
  BillingPurchaseOrderCurrencyChoices,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { totalFormat } from 'pages/ActivityPage/utils';
import { POLY_DATE_MONTH } from 'poly-constants';
import React, { useEffect, useState } from 'react';
import { TransitionStatus } from 'react-transition-group/Transition';

interface PoRowProps {
  clientRevenue: BillingClientRevenue;
  billingMissionsRevenue: BillingActivityInformationRevenue[];
  filterNotValidatedRevenue?: boolean;
  notValidatedMissions?: BillingActivityInformationRevenue[];
  status: TransitionStatus;
  amountPerMonthForActivities?: {
    amounts: number[] | undefined;
    activity: ActivityNode | undefined;
    client: BillingClientNode | undefined;
  }[];
  highlightNotValidatedMonth: Boolean;
}

export default function ClientRow({
  clientRevenue,
  billingMissionsRevenue,
  filterNotValidatedRevenue,
  notValidatedMissions,
  status,
  amountPerMonthForActivities,
  highlightNotValidatedMonth,
}: PoRowProps) {
  const { displayedMonths, mode } = useTableContext();
  const { filteredID } = useFilterContext();
  const [openCollapse, setOpenCollapse] = useState(false);
  const { currentYear } = useTableHeaderContext();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const { employee } = useUserInfo();

  const { billingMissions } = clientRevenue;
  useEffect(() => {
    if (isFirstRender) {
      const checkIsChiefOrDirector = () => {
        return _.some(billingMissions, (billingMission) => {
          const { isDirector, isChief } = verifyEmployeePermissions(
            billingMission?.billingMission?.activity,
            employee
          );
          return isDirector || isChief;
        });
      };
      setOpenCollapse(!!filteredID || checkIsChiefOrDirector());
      setIsFirstRender(false);
    }
  }, [filteredID, billingMissions, isFirstRender, employee]);

  const totalClient = _.sum(
    amountPerMonthForActivities
      ?.filter(
        (amountPerMonthForActivity) =>
          amountPerMonthForActivity.client?.id === clientRevenue.client.id
      )
      .map((amountPerMonthForActivity) =>
        _.sum(amountPerMonthForActivity.amounts)
      )
  );

  return (
    <>
      <ChildCollapsePolyTableRow
        status={status}
        className={styles.revenueRowHeight}
      >
        <CollapsePolyTableCell
          className={styles.clientNameCell}
          open={openCollapse}
          setOpen={setOpenCollapse}
        >
          <div className={styles.nameText}>
            {clientRevenue.client.corporateName}
          </div>
        </CollapsePolyTableCell>
        {_.map(displayedMonths, (month) => {
          const isActiveOnMonth = _.some(billingMissionsRevenue, (revenue) => {
            return _.some(revenue.billingMission, (mission) => {
              return month.isSameOrBefore(mission.missionEnd, 'month');
            });
          });
          if (
            !month.isBetween(
              clientRevenue.dateRange.start,
              clientRevenue.dateRange.end,
              'month',
              '[]'
            ) &&
            mode === TableMode.ACTUAL
          ) {
            return (
              <OutOfRangeTableCell
                key={`total-client-${clientRevenue.client.id}-${month.format(
                  POLY_DATE_MONTH
                )}`}
                title={'Le client n’a pas de missions actives sur ce mois'}
              />
            );
          }
          const currentClientRevenue = _.find(clientRevenue.totals, (total) =>
            total.month.isSame(month)
          );
          if (currentClientRevenue?.amount || mode === TableMode.ACTUAL) {
            return (
              <DisplayValueTableCell
                key={`total-client-${clientRevenue.client.id}-${month.format(
                  POLY_DATE_MONTH
                )}`}
                title={
                  highlightNotValidatedMonth &&
                  isActiveOnMonth &&
                  moment().isSame(month, 'month') &&
                  moment().isSame(currentYear, 'year')
                    ? 'Les CAs des missions de ce client n’ont pas tous été validé'
                    : ''
                }
                style={{
                  ...(highlightNotValidatedMonth &&
                    isActiveOnMonth &&
                    moment().isSame(month, 'month') &&
                    moment().isSame(currentYear, 'year') && {
                      borderColor: 'orange.main',
                    }),
                }}
              >
                {totalFormat(
                  currentClientRevenue?.amount || 0,
                  BillingPurchaseOrderCurrencyChoices.Eur
                )}
              </DisplayValueTableCell>
            );
          }
          return (
            <OutOfRangeTableCell
              title="Aucun CA facturé ce mois"
              key={`total-client-${clientRevenue.client.id}-${month.format(
                POLY_DATE_MONTH
              )}`}
            />
          );
        })}
        <TableCell className={styles.totalColumn} sx={{ textAlign: 'center' }}>
          {totalFormat(
            mode === TableMode.BILLED ? totalClient : clientRevenue.total,
            BillingPurchaseOrderCurrencyChoices.Eur
          )}
        </TableCell>
      </ChildCollapsePolyTableRow>
      <TransitionPolyTableRow open={openCollapse}>
        {(status) => {
          return (
            <React.Fragment>
              {_.map(billingMissionsRevenue, (billingMission) => {
                if (
                  !filterNotValidatedRevenue ||
                  _.some(
                    notValidatedMissions,
                    (notValidatedMission) =>
                      notValidatedMission.billingMission.id ===
                      billingMission.billingMission.id
                  )
                ) {
                  const amountPerMonthForActivity =
                    amountPerMonthForActivities?.find(
                      ({ activity }) =>
                        activity?.id ===
                        billingMission.billingMission.activity?.id
                    );
                  return (
                    <MissionRow
                      billingMission={billingMission}
                      currentMonthNotValidated={
                        _.some(
                          notValidatedMissions,
                          (mission) => mission === billingMission
                        ) && !!highlightNotValidatedMonth
                      }
                      status={status}
                      key={`mission-${billingMission.billingMission.id}`}
                      amountPerMonthForActivity={amountPerMonthForActivity}
                    />
                  );
                }
              })}
            </React.Fragment>
          );
        }}
      </TransitionPolyTableRow>
    </>
  );
}
