import { Box, DialogContent, Typography } from '@mui/material';
import { billModalColumns } from 'components/MissionFollowUp/BillDetail/Modals/BillActionModal/BillActionModal';
import LabelTextFieldGrid from 'components/MissionFollowUp/LabelTextFieldGrid';
import PolyDataGrid from 'components/MUIOverload/PolyDataGrid';
import PolyModal from 'components/MUIOverload/PolyDialog/PolyModal';
import {
  BillingBillStatusChoices,
  BillNode,
  useUpdateBillStatusMutation,
} from 'generated/graphql';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { setCurrentBill } from 'store/purchaseOrder';

interface BillActionModalProps {
  open: boolean;
  closeModal: () => void;
}

const CancelBillModal = (properties: BillActionModalProps) => {
  const currentBill = useSelector((state) => state.purchaseOrder.currentBill);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const isToCancel = currentBill.status === BillingBillStatusChoices.ToCancel;

  const modalTitle = 'Annuler la facture/Générer un avoir';

  const primaryButtonName = 'Annuler la facture/Générer avoir';

  const getRows = (_orderBy?: string | string[], _order?: 'asc' | 'desc') => {
    return [currentBill];
  };

  const [updateStatus] = useUpdateBillStatusMutation({
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    onCompleted: (data) => {
      const bill = data.updateBillStatus?.bills?.[0] as BillNode;
      if (bill) {
        dispatch(setCurrentBill(bill));
      }
      properties.closeModal();
      enqueueSnackbar(`La facture ${currentBill.title} a bien été annulée`, {
        variant: 'success',
      });
    },
  });

  const onUpdateStatus = async (status: BillingBillStatusChoices) => {
    await updateStatus({
      variables: {
        bills: [
          {
            id: currentBill.id,
          },
        ],
        status: status,
      },
    });
  };

  const cancelBill = () => {
    void onUpdateStatus(BillingBillStatusChoices.Canceled);
  };

  return (
    <>
      <PolyModal
        open={properties.open}
        onClose={properties.closeModal}
        polyDialogTitle={modalTitle}
        maxWidth={'lg'}
        handlePrimary={cancelBill}
        primaryButtonName={primaryButtonName}
      >
        <DialogContent sx={{ py: 0 }}>
          <Box sx={{ my: 2.5 }}>
            <PolyDataGrid columns={billModalColumns} getRows={getRows} />
          </Box>
          {isToCancel && (
            <LabelTextFieldGrid
              title={"Commentaire justifiant la demande d'annulation"}
              multiline
              rows={4}
              disabled
              sizegrid={12}
              customspacingtop={0}
              customspacingside={0}
              sx={{ mb: 2.5 }}
              value={_.get(currentBill, 'cancellationCommentary.message')}
            />
          )}
          <Box>
            <Typography>
              Souhaitez-vous annuler cette facture? Un avoir correspondant sera
              automatiquement généré et marqué comme &apos;À facturer&apos;.
            </Typography>
            <Typography variant="bodyBold">
              Attention : Si la facture a déjà été créée dans Talentia, vous
              devez absolument l&apos;annuler et créer un avoir.
            </Typography>
          </Box>
        </DialogContent>
      </PolyModal>
    </>
  );
};

export default CancelBillModal;
