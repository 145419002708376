import { Box, useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import React from 'react';

interface PageWrapperProps {
  large?: boolean;
  className?: string;
  noLimit?: boolean;
  missionView?: boolean;
  topRef?: React.RefObject<HTMLDivElement>;
}

const PageWrapper = ({
  large = false,
  className,
  children,
  noLimit,
  topRef,
  missionView,
}: React.PropsWithChildren<PageWrapperProps>) => {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxSizing: 'border-box',
        width: '100%',
      }}
      className={className}
      ref={topRef}
    >
      <Box
        sx={{
          width: '100%',
          ...(noLimit &&
            !missionView &&
            !isPhone && {
              padding: '30px 50px 20px',
              boxSizing: 'border-box',
            }),
          ...(noLimit &&
            !missionView &&
            isPhone && {
              padding: '20px 20px 20px',
              boxSizing: 'border-box',
            }),
          ...(!noLimit &&
            !missionView && {
              maxWidth: '1200px',
              padding: '30px 50px 20px',
              boxSizing: 'border-box',
            }),
          ...(!noLimit && large && !missionView && { maxWidth: '1700px' }),
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default PageWrapper;
