import FormSystem from 'components/commons/FormSystem';
import { ColumnLabelsV2 } from 'components/commons/Tables/utils';
import moment from 'moment';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

import { DateRange } from '..';

export interface SubmitProps {
  activityMonitoring: {
    [week: string]: {
      [activity: string]: {
        timeSpent: number;
        date: Date;
        comment: string;
      };
    };
  };
}

export interface TableContextProps {
  numberOfWeeks: number;
  numberOfModifiableWeeks: number;
  numberOfDisplayedWeeks: number;
  dateRange: DateRange;
  onSubmit?: (data: SubmitProps) => Promise<void>;
  hasTMActivity: boolean;
}

export interface TableContext {
  numberOfWeeks: number;
  numberOfDisplayedWeeks: number;
  numberOfModifiableWeeks: number;
  totalErrors: { [week: string]: boolean };
  invalidFirstElementWeeks: { [week: string]: boolean };
  setInvalidFirstElementWeeks: (p: { [week: string]: boolean }) => void;
  dateRange: DateRange;
  onSubmit?: (data: SubmitProps) => Promise<void>;
  hasTMActivity: boolean;
  weekRange: ColumnLabelsV2[];
  setWeekRange: (p: ColumnLabelsV2[]) => void;
}

export const TableContext = createContext<TableContext>({
  numberOfWeeks: 4,
  numberOfDisplayedWeeks: 4,
  numberOfModifiableWeeks: 4,
  invalidFirstElementWeeks: {},
  setInvalidFirstElementWeeks: () => {
    throw new Error('function setInvalidFirstElementWeeks not set');
  },
  totalErrors: {},
  dateRange: { start: moment(), end: moment() },
  onSubmit: () => {
    throw new Error('function onSubmit not set');
  },
  hasTMActivity: false,
  weekRange: [],
  setWeekRange: () => {
    throw new Error('function setWeekRange not set');
  },
});

export const useTableContext = () => {
  const tableContext = useContext(TableContext);
  if (!tableContext) {
    throw new Error('useContext() can only be used  inside a table');
  }
  return tableContext;
};

const TableContextProvider = (
  properties: PropsWithChildren<TableContextProps>
) => {
  const [invalidFirstElementWeeks, setInvalidFirstElementWeeks] = useState({});
  const [weekRange, setWeekRange] = useState<ColumnLabelsV2[]>([]);

  return (
    <>
      <TableContext.Provider
        value={{
          ...properties,
          invalidFirstElementWeeks,
          setInvalidFirstElementWeeks,
          weekRange,
          setWeekRange,
          totalErrors: {},
          numberOfWeeks: properties.numberOfWeeks || 1,
        }}
      >
        <FormSystem
          useFormArgs={{
            mode: 'onChange',
            criteriaMode: 'all',
            reValidateMode: 'onChange',
            shouldUnregister: false,
            shouldFocusError: true,
          }}
          onSubmit={async (data) => {
            if (properties.onSubmit) {
              await properties.onSubmit(data as SubmitProps);
            }
          }}
        >
          {properties.children}
        </FormSystem>
      </TableContext.Provider>
    </>
  );
};

export default TableContextProvider;
