import React, {
  createContext,
  PropsWithChildren,
  ReactElement,
  useContext,
  useState,
} from 'react';
import { functionNotSet } from 'utils';

interface ProfitabilityTableContextProps {
  isShowingMarginRate: boolean;
  toggleShowMarginRate: () => void;
  isExcludingIntern: boolean;
  toggleExcludeIntern: () => void;
}

const ProfitabilityTableContext = createContext<ProfitabilityTableContextProps>(
  {
    isShowingMarginRate: false,
    toggleShowMarginRate: () => functionNotSet(),
    isExcludingIntern: false,
    toggleExcludeIntern: () => functionNotSet(),
  }
);

export function useProfitabilityTableContext(): ProfitabilityTableContextProps {
  const tableContext = useContext(ProfitabilityTableContext);
  if (!tableContext) {
    throw new Error(
      'useProfitabilityTableContext can only be used inside a ProfitabilityTableContextProvider'
    );
  }
  return tableContext;
}

export default function ProfitabilityTableContextProvider({
  children,
  isExcludingIntern,
  toggleExcludingIntern,
}: PropsWithChildren<{
  isExcludingIntern: boolean;
  toggleExcludingIntern: () => void;
}>): ReactElement {
  const [isShowingMarginRate, setShowingMarginRate] = useState(false);
  return (
    <ProfitabilityTableContext.Provider
      value={{
        isShowingMarginRate,
        toggleShowMarginRate: () =>
          setShowingMarginRate((isShowingMarginRate) => !isShowingMarginRate),
        isExcludingIntern: isExcludingIntern,
        toggleExcludeIntern: toggleExcludingIntern,
      }}
    >
      {children}
    </ProfitabilityTableContext.Provider>
  );
}
