import { Box, Tooltip, Typography } from '@mui/material';
import DisplayMission from 'components/commons/DisplayMission';
import ResumeBillsStatus from 'components/MissionFollowUp/OverallListBill/ResumeBillsStatus/ResumeBillsStatus';
import DateRangeDisplayCell from 'components/MUIOverload/PolyDataGrid/components/DateRangeCell/DateRangeDisplayCell';
import StringCell from 'components/MUIOverload/PolyDataGrid/components/StringCell';
import { PolyDataGridColumn } from 'components/MUIOverload/PolyDataGrid/PolyDataGridHead';
import {
  ActivityNode,
  AllPurchaseOrderQuery,
  BillNode,
  PurchaseOrderNode,
} from 'generated/graphql';
import { totalFormat } from 'pages/ActivityPage/utils';
import React from 'react';

function addColumnTotal(
  columns: PolyDataGridColumn<
    DeepExtractTypeSkipArrays<
      AllPurchaseOrderQuery,
      ['allPurchaseOrder', 'purchaseOrders']
    >
  >[]
) {
  columns.push({
    headerName: 'Montant (HT)',
    isSortable: true,
    propertyName: 'total',
    width: '70px',
    render: (data) => {
      return StringCell({
        data: data.total ? totalFormat(data.total, data.currency) || '0' : '',
      });
    },
  });
}

function addColumnsActivityNameAndTotal(
  columns: PolyDataGridColumn<
    DeepExtractTypeSkipArrays<
      AllPurchaseOrderQuery,
      ['allPurchaseOrder', 'purchaseOrders']
    >
  >[]
) {
  columns.push({
    headerName: 'Mission',
    isSortable: true,
    propertyName: 'missionName',
    width: '170px',
    render: (data) => (
      <DisplayMission
        mission={data?.billingActivityInformation?.activity as ActivityNode}
        missionTooltip
      />
    ),
  });
  addColumnTotal(columns);
}

export function getColumnsForTablePurchaseOrders() {
  const columns: PolyDataGridColumn<
    DeepExtractTypeSkipArrays<
      AllPurchaseOrderQuery,
      ['allPurchaseOrder', 'purchaseOrders']
    >
  >[] = [
    {
      headerName: 'Nom',
      isSortable: true,
      propertyName: 'name',
      width: '170px',
      render: (data) => {
        const purchaseOrder = data as PurchaseOrderNode;
        return (
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            height={50}
          >
            <Tooltip title={purchaseOrder.name} placement="bottom-start" arrow>
              <Typography noWrap sx={{ fontSize: '14px' }}>
                {purchaseOrder.name}
              </Typography>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      headerName: 'N° BDC',
      isSortable: true,
      propertyName: 'number',
      width: '80px',
      render: (data) => StringCell({ data: data.number }),
    },
  ];

  addColumnsActivityNameAndTotal(columns);

  columns.push(
    {
      headerName: 'Période',
      isSortable: true,
      propertyName: 'dates',
      width: '120px',
      render: (
        data: DeepExtractTypeSkipArrays<
          AllPurchaseOrderQuery,
          ['allPurchaseOrder', 'purchaseOrders']
        >
      ) =>
        DateRangeDisplayCell({
          startDate: data.periodBeginning,
          endDate: data.periodEnding,
        }),
    },
    {
      headerName: 'Factures',
      isSortable: false,
      propertyName: 'bills',
      width: '130px',
      render: (
        data: DeepExtractTypeSkipArrays<
          AllPurchaseOrderQuery,
          ['allPurchaseOrder', 'purchaseOrders']
        >
      ) => ResumeBillsStatus(data.bills as BillNode[], data.currency),
    }
  );

  return columns;
}
