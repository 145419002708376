import {
  Box,
  DialogContentText,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { DateRangePicker } from 'components/commons/DateRangePicker';
import { createCsvFile } from 'components/commons/PolyExportButton';
import PolyModal from 'components/MUIOverload/PolyDialog/PolyModal';
import {
  List_Purchase_Order_Filterable_Periods,
  useExportPurchaseOrdersLazyQuery,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useSelector } from 'store';

const filterablePeriodFieldsCorrespondance = {
  [List_Purchase_Order_Filterable_Periods.ActivePo]:
    'BDC actifs sur la période',
  [List_Purchase_Order_Filterable_Periods.CreatedAt]: 'Date de création',
};

interface PurchaseOrdersExportModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

export default function PurchaseOrdersExportModal({
  isOpen,
  handleClose,
}: PurchaseOrdersExportModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    moment().startOf('month').toDate(),
    moment().endOf('month').toDate(),
  ]);
  const selectedMissions = useSelector(
    (state) => state.activity.selectedMissions
  );
  const currentMission = useSelector((state) => state.activity.currentMission);
  const missionIds = currentMission?.id
    ? [currentMission.id]
    : _.map(selectedMissions, (mission) => mission.id);
  const filterablePeriodFields = Object.values(
    List_Purchase_Order_Filterable_Periods
  );
  const [period, setPeriod] = React.useState(filterablePeriodFields[0]);

  const [exportPurchaseOrdersQuery] = useExportPurchaseOrdersLazyQuery({
    onCompleted: (data) => {
      if (data && data.exportPurchaseOrders) {
        createCsvFile(
          data.exportPurchaseOrders,
          'bons_de_commande_polyconseil.csv'
        );
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const handleExport = () => {
    exportPurchaseOrdersQuery({
      variables: {
        activityIds: missionIds,
        dateFrom: moment(dateRange[0]).format('YYYY-MM-DD'),
        dateTo: moment(dateRange[1]).format('YYYY-MM-DD'),
        periodToFilter: period,
      },
    });

    if (handleClose) {
      handleClose();
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    setPeriod(event.target.value as List_Purchase_Order_Filterable_Periods);
  };

  return (
    <PolyModal
      open={isOpen}
      onClose={handleClose}
      polyDialogTitle="Export données de bons de commande"
      handlePrimary={handleExport}
      primaryButtonName="Télécharger"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEnforceFocus={true}
    >
      <>
        <DialogContentText id="alert-dialog-description">
          Téléchargez ici les bons de commande apparaissant dans le tableau
          précédent selon la période de votre choix.
        </DialogContentText>

        <Grid container justifyContent="space-between" mb={2} mt={3}>
          <Grid item xs={4}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel>Filtrer sur</InputLabel>
              <Select
                value={period}
                label="Filtrer sur"
                onChange={handleChange}
                sx={{ height: 40 }}
              >
                {_.map(filterablePeriodFields, (filterablePeriodField) => {
                  return (
                    <MenuItem
                      key={filterablePeriodField}
                      value={filterablePeriodField}
                    >
                      {
                        filterablePeriodFieldsCorrespondance[
                          filterablePeriodField
                        ]
                      }
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={7}>
            <Box>
              <DateRangePicker
                dateRange={dateRange}
                disableFuture={false}
                handleDateRangeChange={setDateRange}
                externallyControlled
              />
            </Box>
          </Grid>
        </Grid>
      </>
    </PolyModal>
  );
}
