import BackdropLoading from 'components/commons/BackdropLoading';
import PageContent from 'components/commons/PageContent';
import PageWrapper from 'components/commons/PageWrapper';
import PolyTitle from 'components/commons/PolyTitle';
import {
  OvertimeRequestsQuery,
  RecoveryMomentsQuery,
  useCreateRecoveryMomentMutation,
  useOvertimeRequestsLazyQuery,
  useRecoveryMomentsLazyQuery,
} from 'generated/graphql';
import { useSnackbar } from 'notistack';
import { ExceptionalActivitiesTitles } from 'poly-constants';
import React, { useEffect, useState } from 'react';
import { graphQlDateFormatter } from 'utils';

import ActivityTable from './ActivityTable';
import RecoveryMomentsTable from './RecoveryMomentsTable';
import TotalRecuperationTime from './TotalRecuperationTime';

const content =
  "Vous vous apprêtez à faire une demande de récupération de vos activités exceptionnelles réalisées.\nAssurez-vous d'en avoir discuté au préalable avec votre manager afin d'assurer la bonne répartition des tâches au sein de l'équipe.";

const ExceptionalActivitiesRecuperation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [overtimeRequestsData, setOvertimeRequestsData] =
    useState<OvertimeRequestsQuery>();

  const [recoveryRequestsData, setRecoveryRequestsData] =
    useState<RecoveryMomentsQuery>();

  const [fetchOvertimeRequest] = useOvertimeRequestsLazyQuery({
    onCompleted: (data) => {
      setOvertimeRequestsData(data);
      setLoading(false);
    },
    onError: () => {
      enqueueSnackbar('Problème de récupération des données', {
        variant: 'error',
      });
    },
  });

  const [fetchRecoveryRequest] = useRecoveryMomentsLazyQuery({
    onCompleted: (data) => {
      setRecoveryRequestsData(data);
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
      enqueueSnackbar('Problème de récupération des données', {
        variant: 'error',
      });
    },
  });

  const [createRecoveryMoment] = useCreateRecoveryMomentMutation({
    onCompleted: () => {
      // eslint-disable-next-line
      setOvertimeRequestsData(undefined);
      fetchOvertimeRequest({ variables: { asManager: false } });
      fetchRecoveryRequest();
    },
    onError: () => {
      setLoading(false);
      enqueueSnackbar(
        'Une erreur est survenu lors de création de votre temps de récupération',
        {
          variant: 'error',
        }
      );
    },
  });

  const handleSummit = async (
    id: string,
    date: Date,
    duration: number,
    dayMoment: string
  ) => {
    setLoading(true);
    await createRecoveryMoment({
      variables: {
        id: id,
        date: graphQlDateFormatter(date),
        duration: duration,
        dayMoment: dayMoment,
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    fetchOvertimeRequest({ variables: { asManager: false } });
    fetchRecoveryRequest();
  }, [fetchOvertimeRequest, fetchRecoveryRequest]);

  return (
    <PageWrapper noLimit>
      <PolyTitle>{ExceptionalActivitiesTitles.recuperation}</PolyTitle>
      <PageContent>{content}</PageContent>
      {!loading && (
        <>
          <TotalRecuperationTime />
          <ActivityTable
            handleSummit={handleSummit}
            overtimeRequestsData={overtimeRequestsData}
          />
          <RecoveryMomentsTable recoveryRequestsData={recoveryRequestsData} />
        </>
      )}
      {loading && <BackdropLoading open={loading} />}
    </PageWrapper>
  );
};

export default ExceptionalActivitiesRecuperation;
