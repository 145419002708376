import { STANDARD_SIDEBAR_DRAWER_WIDTH } from 'pages/MainPage';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';

interface NavbarContextProviderProps {
  children: React.ReactFragment;
  isDrawerOpen: boolean;
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
  drawerWidth: string;
}

export interface NavbarContextProps {
  isDrawerOpen: boolean;
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
  drawerWidth: string;
}

export const NavbarContext = createContext<NavbarContextProps>({
  isDrawerOpen: true,
  setIsDrawerOpen: () => {
    throw new Error('Function setIsDrawerOpen not yet initialized.');
  },
  drawerWidth: STANDARD_SIDEBAR_DRAWER_WIDTH,
});

export function useNavbarContextProvider() {
  const navbarContext = useContext(NavbarContext);
  if (!navbarContext) {
    throw new Error('useContext() can only be used inside a table');
  }
  return navbarContext;
}

export default function NavbarContextProvider({
  children,
  isDrawerOpen,
  setIsDrawerOpen,
  drawerWidth,
}: NavbarContextProviderProps) {
  return (
    <NavbarContext.Provider
      value={{
        isDrawerOpen,
        setIsDrawerOpen,
        drawerWidth,
      }}
    >
      {children}
    </NavbarContext.Provider>
  );
}
