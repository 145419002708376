import MessageIcon from '@mui/icons-material/Message';
import { Box, IconButton, TableCell, Tooltip } from '@mui/material';
import clsx from 'clsx';
import DisplayValueTableCell from 'components/ActivityMonitoring/ActivityMonitoringTable/Table/DisplayValueTableCell';
import { getDaysToWorkInCurrentWeek } from 'components/ActivityMonitoring/ActivityMonitoringTable/utils';
import ProviderCommentModal from 'components/ActivityMonitoring/ProviderCommentModal';
import ChildCollapsePolyTableRow from 'components/MUIOverload/PolyTableRow/ChildCollapsePolyTableRow';
import { useTableContext } from 'contexts/ActivityMonitoringTable/TableContextProvider';
import {
  EmployeeNode,
  EmployeesEmployeeContractTypeChoices,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { TransitionStatus } from 'react-transition-group/Transition';

import styles from '../../../styles/TableCell.module.scss';
import { FormattedMission } from '../utils';
import {
  CellEmployeeNotHiredOnWeek,
  checkWeekError,
  DisplayMissionAndClient,
  getAmWithComment,
  getCellStyleError,
  getCurrentWeekPartTimeSpent,
  isEmployeeHiredOnWeek,
  TypographyDisplayWeekValue,
} from './utils';

interface MissionRowProp {
  missionDetail: FormattedMission;
  employee?: EmployeeNode;
  headAndTailMissionName: { head: string; tail: string };
  status?: TransitionStatus;
  isSubMission?: boolean;
}

export default function MissionRow({
  missionDetail,
  employee,
  headAndTailMissionName,
  status,
  isSubMission,
}: MissionRowProp) {
  const { displayedWeeks, isFiltered } = useTableContext();
  const [commentModalOpen, setCommentModalOpen] = useState(false);
  const [comment, setComment] = useState<string>('');
  const isProvider =
    employee?.contractType === EmployeesEmployeeContractTypeChoices.Provider;

  let total = 0;

  const cellsList = _.map(displayedWeeks, (displayedWeek) => {
    return getWeekCell(displayedWeek);
  });
  return (
    <ChildCollapsePolyTableRow status={status || 'entered'}>
      <DisplayValueTableCell
        warning={false}
        className={styles.missionCell}
        sx={{ p: 1 }}
      >
        {!isSubMission ? (
          <DisplayMissionAndClient missionDetail={missionDetail} />
        ) : (
          <div className={styles.subMissionWrapper}>
            <Box ml={3}>{missionDetail.name}</Box>
            <div className={styles.horizontalLine} />
          </div>
        )}
      </DisplayValueTableCell>
      {cellsList}
      <DisplayValueTableCell
        warning={!missionDetail.isAssigned}
        align={'center'}
      >
        {total}
      </DisplayValueTableCell>
    </ChildCollapsePolyTableRow>
  );

  function getWeekCell(week: moment.Moment) {
    if (!isEmployeeHiredOnWeek(employee, week)) {
      return <CellEmployeeNotHiredOnWeek week={week} sx={{ padding: 1 }} />;
    }
    const amWithComment = isProvider
      ? getAmWithComment(employee as EmployeeNode, week, missionDetail.id)
      : undefined;
    const endMissionDate = missionDetail.end
      ? moment(missionDetail.end)
      : undefined;
    const startMissionDate = missionDetail.start
      ? moment(missionDetail.start)
      : undefined;

    const currentWeekPartTimeSpent = getCurrentWeekPartTimeSpent(
      missionDetail,
      week
    );
    total += currentWeekPartTimeSpent;

    const isOnError = checkWeekError(employee, week);

    if (
      endMissionDate &&
      startMissionDate &&
      (endMissionDate.isBefore(week.clone().day(1)) ||
        startMissionDate.isAfter(week.clone().day(5)))
    ) {
      return (
        <Tooltip
          title="Le collaborateur n’est pas assigné sur la mission cette semaine"
          arrow
          key={`row-team${week}`}
        >
          <TableCell
            sx={{ p: 1 }}
            className={clsx(
              styles.hatchedCell,
              isOnError
                ? getCellStyleError(
                    missionDetail.name === headAndTailMissionName.head,
                    missionDetail.name === headAndTailMissionName.tail
                  )
                : ''
            )}
          />
        </Tooltip>
      );
    }

    if (isOnError && !isFiltered) {
      return (
        <DisplayValueTableCell
          key={`week-` + week}
          warning={false}
          sx={{ p: 1, backgroundColor: 'background.default' }}
          align={'center'}
          className={clsx(
            styles.commonCell,
            getCellStyleError(
              missionDetail.name === headAndTailMissionName.head,
              missionDetail.name === headAndTailMissionName.tail
            )
          )}
          tooltipTitle={`Le collaborateur a une saisie incomplète. Jours travaillés attendus : ${getDaysToWorkInCurrentWeek(
            week,
            employee
          )}`}
        >
          <TypographyDisplayWeekValue weekValue={currentWeekPartTimeSpent} />
        </DisplayValueTableCell>
      );
    }

    if (isProvider && amWithComment) {
      return (
        <>
          <DisplayValueTableCell
            warning={!missionDetail.isAssigned}
            key={`row-team${week}`}
            align={'center'}
            sx={{ p: 1 }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <TypographyDisplayWeekValue
                weekValue={currentWeekPartTimeSpent}
              />
              <IconButton
                onClick={() => {
                  setComment(amWithComment.comment || '');
                  setCommentModalOpen(true);
                }}
                size="large"
              >
                <MessageIcon sx={{ color: 'text.secondary' }} />
              </IconButton>
            </Box>
          </DisplayValueTableCell>
          <ProviderCommentModal
            open={commentModalOpen}
            setOpen={setCommentModalOpen}
            comment={comment}
          />
        </>
      );
    }
    return (
      <DisplayValueTableCell
        warning={!missionDetail.isAssigned}
        key={`row-team${week}`}
        sx={{ p: 1, backgroundColor: 'background.default' }}
        align={'center'}
      >
        <TypographyDisplayWeekValue weekValue={currentWeekPartTimeSpent} />
      </DisplayValueTableCell>
    );
  }
}
