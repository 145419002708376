import { Grid } from '@mui/material';
import InfoTooltip from 'components/commons/InfoTooltip';
import SectionTitle from 'components/commons/SectionTitle';
import GridSubTitle from 'components/MissionFollowUp/GridSubTitle';
import LabelTextFieldGrid from 'components/MissionFollowUp/LabelTextFieldGrid';
import {
  checkEmailFormat,
  requiredForm,
} from 'pages/MissionFollowUp/formValidators';
import React from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';

export default function ExternalActivityContactForm() {
  const { errors, trigger } = useFormContext();

  return (
    <Grid container spacing={0} sx={{ paddingBottom: 2 }}>
      <GridSubTitle customspacingtop={20}>
        <SectionTitle variant="h3Bold">Contact</SectionTitle>
      </GridSubTitle>

      <GridSubTitle>Contact client opérationnel</GridSubTitle>
      <Grid container item xs={12}>
        <Controller
          name="contactName"
          rules={{ required: requiredForm }}
          render={(properties) => (
            <LabelTextFieldGrid
              {...properties}
              onChange={(e) => {
                properties.onChange(e);
                trigger('contactName');
              }}
              required
              errorform={errors.contactName as FieldError}
              name="contactName"
              title="Nom"
              sizegrid={12}
            />
          )}
        />

        <Controller
          name="contactEmail"
          rules={{
            required: requiredForm,
            validate: {
              invalidFormatEmail: (email: string) => checkEmailFormat(email),
            },
          }}
          render={(properties) => (
            <LabelTextFieldGrid
              {...properties}
              onChange={(e) => {
                properties.onChange(e);
                trigger('contactEmail');
              }}
              required
              errorform={errors.contactEmail as FieldError}
              name="contactEmail"
              title="Adresse Email"
              sizegrid={12}
            />
          )}
        />

        <Controller
          name="contactPhone"
          render={(properties) => (
            <LabelTextFieldGrid
              {...properties}
              onChange={(e) => {
                properties.onChange(e);
                trigger('contactPhone');
              }}
              errorform={errors.contactPhone as FieldError}
              name="contactPhone"
              title="Numéro de téléphone"
              sizegrid={12}
            />
          )}
        />
      </Grid>

      <GridSubTitle>
        Contact facturation client
        <InfoTooltip
          title={'Votre interlocuteur privilégié pour la facturation'}
        />
      </GridSubTitle>

      <Grid container item xs={12}>
        <Controller
          name="accountantName"
          rules={{ required: requiredForm }}
          render={(properties) => (
            <LabelTextFieldGrid
              {...properties}
              onChange={(e) => {
                properties.onChange(e);
                trigger('accountantName');
              }}
              required
              errorform={errors.accountantName as FieldError}
              name="accountantName"
              title="Nom"
              sizegrid={12}
            />
          )}
        />

        <Controller
          name="accountantEmail"
          rules={{
            required: requiredForm,
            validate: {
              invalidFormatEmail: (email: string) => checkEmailFormat(email),
            },
          }}
          render={(properties) => (
            <LabelTextFieldGrid
              {...properties}
              onChange={(e) => {
                properties.onChange(e);
                trigger('accountantEmail');
              }}
              required
              errorform={errors.accountantEmail as FieldError}
              name="accountantEmail"
              title="Adresse Email"
              sizegrid={12}
            />
          )}
        />

        <Controller
          name="accountantPhone"
          render={(properties) => (
            <LabelTextFieldGrid
              {...properties}
              onChange={(e) => {
                properties.onChange(e);
                trigger('accountantPhone');
              }}
              errorform={errors.accountantPhone as FieldError}
              name="accountantPhone"
              title="Numéro de téléphone"
              sizegrid={12}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
