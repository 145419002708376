import LoadingPage from 'components/commons/LoadingPage';
import { useAuth } from 'components/User/Authenticator';
import { FetchConfigQuery, useFetchConfigQuery } from 'generated/graphql';
import _ from 'lodash';
import React, { createContext, useContext, useEffect } from 'react';

// eslint-disable-next-line
const ConfigContext = createContext<FetchConfigQuery['config']>(undefined);

export const useEnvironmentVariable = (name: string) => {
  const config = useContext(ConfigContext);
  if (!config) {
    throw new Error(
      'useEnvironmentVariable was called outside context or useFetchConfigQuery returned a falsy value'
    );
  }
  const environmentVariable = _.find(config.envVariables, { name }) || false;
  return environmentVariable ? String(environmentVariable.value) : '';
};

export const ConfigProvider = ({
  children,
}: React.PropsWithChildren<{}>): JSX.Element => {
  const auth = useAuth();
  const { loading, data = {}, error } = useFetchConfigQuery();

  useEffect(() => {
    if (!loading && (error || !data)) {
      auth.logout('Erreur lors du chargement de la configuration');
    }
  }, [auth, data, error, loading]);

  if (loading) {
    return <LoadingPage loading={loading} />;
  }

  return (
    <ConfigContext.Provider value={data.config}>
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;
export { ConfigContext };
