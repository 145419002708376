import BadgeTwoToneIcon from '@mui/icons-material/BadgeTwoTone';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import EditCalendarTwoToneIcon from '@mui/icons-material/EditCalendarTwoTone';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import EventRepeatTwoToneIcon from '@mui/icons-material/EventRepeatTwoTone';
import HistoryEduTwoToneIcon from '@mui/icons-material/HistoryEduTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import PhoneTwoToneIcon from '@mui/icons-material/PhoneTwoTone';
import WorkTwoToneIcon from '@mui/icons-material/WorkTwoTone';
import {
  Grid,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AvatarNameAndOccupationStack from 'components/commons/AvatarNameAndOccupationStack/AvatarNameAndOccupationStack';
import EmployeeSeniority from 'components/commons/EmployeeSeniority/EmployeeSeniority';
import Section from 'components/commons/Section';
import SectionTitle from 'components/commons/SectionTitle';
import EmployeeAvatarRenderer from 'components/Phonebook/EmployeeCard/EmployeeAvatarRenderer/EmployeeAvatarRenderer';
import { getEmployeeOccupationInfo } from 'components/Phonebook/EmployeeCard/EmployeeOccupationIndicator/EmployeeOccupationIndicator';
import { GradeChip } from 'components/Phonebook/EmployeeCard/GradeChip';
import {
  AvatarDisplayMode,
  AvatarDisplaySize,
  EmployeeContractTextChoices,
  EmployeeExperienceTextChoices,
} from 'components/Phonebook/utils';
import {
  EmployeeNode,
  EmployeesEmployeeContractTypeChoices,
  useUpdateEmployeePhoneNumberMutation,
} from 'generated/graphql';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import PhoneNumberForm from 'pages/UserPage/PhoneNumberForm';
import { EMPTY_FIELD, POLY_DATE } from 'poly-constants';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import ContractualDaysDisplayer from './ContractualDays/ContractualDaysDisplayer';

interface UserInfoProps {
  employee: EmployeeNode;
  isEditable?: boolean;
  isUserPageView?: boolean;
}

const UserInfo = ({ employee, isEditable, isUserPageView }: UserInfoProps) => {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();

  const [isEdit, setEdit] = useState(false);

  const defaultValues = {
    phoneNumber: employee?.phoneNumber || '',
  };
  const form = useForm({ defaultValues, shouldUnregister: false });
  const [phoneNumberMutation] = useUpdateEmployeePhoneNumberMutation({
    onCompleted: () => {
      enqueueSnackbar(`Numéro de téléphone mis à jour`, {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar(
        `Erreur lors de l'enregistrement du numéro de téléphone`,
        {
          variant: 'error',
        }
      );
    },
  });

  const changePhoneNumber = async (data: typeof defaultValues) => {
    await phoneNumberMutation({
      variables: {
        phoneNumber: data.phoneNumber,
      },
    });
    setEdit(false);
  };

  const [isValidWorkingDays, setIsValidWorkingDays] = useState(true);
  function updateIsValidWorkingDays(totalWorkingDays: number) {
    setIsValidWorkingDays(totalWorkingDays === employee?.weeklyContract);
  }

  return (
    <Section>
      <Stack
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Grid container spacing={3}>
          <Grid item sm={12} md={6}>
            <SectionTitle>Informations</SectionTitle>
          </Grid>
          <Grid item sm={12} md={6}>
            <Stack direction="row" justifyContent="flex-end">
              <EmployeeSeniority employee={employee} />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <Grid container spacing={4}>
        <Grid item md={12} lg={'auto'}>
          <EmployeeAvatarRenderer
            employee={employee}
            parameters={{
              size: isPhone
                ? AvatarDisplaySize.PHONE
                : AvatarDisplaySize.STANDARD,
              mode: AvatarDisplayMode.PROFILE,
              backgroundColor: theme.palette.grey[100],
            }}
          />
        </Grid>
        <Grid item md={12} lg={3}>
          <Stack key={'employeeInfos'}>
            <Typography variant={'h2Bold'} component="h2">
              {employee.firstName}
            </Typography>
            <Typography variant={'h2Bold'} component="h2">
              {employee.lastName}
            </Typography>
            <Typography variant={'bodyBold'} sx={{ mt: 1, mb: 1.5 }}>
              {
                getEmployeeOccupationInfo(employee.occupation, false)
                  .occupationName
              }
            </Typography>
            <Stack sx={{ mb: 1 }}>
              <GradeChip employee={employee} displayAllGrades showLabel />
            </Stack>

            <Stack flexDirection={'row'} alignItems={'center'}>
              <PhoneTwoToneIcon color="info" sx={{ height: '18px' }} />
              {isEditable ? (
                <FormProvider {...form}>
                  <form onSubmit={form.handleSubmit(changePhoneNumber)}>
                    <PhoneNumberForm isEdit={isEdit} setEdit={setEdit} />
                  </form>
                </FormProvider>
              ) : (
                <Typography variant="bodyBold" color={'text.secondary'}>
                  {employee.phoneNumber || 'Non renseigné'}
                </Typography>
              )}
            </Stack>

            <Stack
              flexDirection={'row'}
              alignItems={'center'}
              sx={{ mt: 0.25 }}
            >
              <EmailTwoToneIcon color="info" sx={{ height: '18px' }} />
              <Tooltip title={employee.email}>
                <Typography variant="bodyBold" color={'text.secondary'} noWrap>
                  {employee.email || EMPTY_FIELD}
                </Typography>
              </Tooltip>
            </Stack>
          </Stack>
        </Grid>
        <Grid item md={12} lg={6.5}>
          <Stack direction="column" spacing={3}>
            <Grid container fontSize={16} rowGap={3}>
              <Grid item xs={12} sm={6} lg={4}>
                <Stack direction="column" spacing={0.75}>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <CalendarMonthTwoToneIcon
                      fontSize="small"
                      color="secondary"
                    />
                    <Typography color={'text.secondary'}>
                      Date d&apos;embauche
                    </Typography>
                  </Stack>
                  <Typography variant="bodySemiBold">
                    {moment(employee.hiringDate).format(POLY_DATE)}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Stack direction="column" spacing={0.75}>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <HistoryEduTwoToneIcon fontSize="small" color="secondary" />
                    <Typography color={'text.secondary'}>
                      Contrat de travail
                    </Typography>
                  </Stack>
                  <Typography variant="bodySemiBold">
                    {(employee &&
                      EmployeeContractTextChoices[
                        employee.contractType as EmployeesEmployeeContractTypeChoices
                      ]) ||
                      EMPTY_FIELD}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <Stack direction="column" spacing={0.75}>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <PeopleAltTwoToneIcon fontSize="small" color="secondary" />
                    <Typography color={'text.secondary'}>
                      Talent Manager
                    </Typography>
                  </Stack>

                  {employee.peopleManager ? (
                    <Stack width={'95%'}>
                      <AvatarNameAndOccupationStack
                        employee={employee.peopleManager}
                        isClickable={!isUserPageView}
                      />
                    </Stack>
                  ) : (
                    <Typography variant="bodySemiBold">
                      {EMPTY_FIELD}
                    </Typography>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Stack direction="column" spacing={0.75}>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <WorkTwoToneIcon fontSize="small" color="secondary" />
                    <Typography noWrap color={'text.secondary'}>
                      Expérience
                    </Typography>
                  </Stack>

                  <Typography variant="bodySemiBold">
                    {(employee?.experience &&
                      EmployeeExperienceTextChoices[employee?.experience]) ||
                      EMPTY_FIELD}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Stack direction="column" spacing={0.75}>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <BadgeTwoToneIcon fontSize="small" color="secondary" />
                    <Typography noWrap color={'text.secondary'}>
                      Matricule
                    </Typography>
                  </Stack>

                  <Typography variant="bodySemiBold">
                    {employee?.matricule || EMPTY_FIELD}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Stack direction="column" spacing={0.75}>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <EventRepeatTwoToneIcon
                      fontSize="small"
                      color="secondary"
                    />
                    <Typography noWrap color={'text.secondary'}>
                      Jours contractuels
                    </Typography>
                  </Stack>
                  <Typography variant="bodySemiBold">
                    {employee?.weeklyContract || EMPTY_FIELD}
                  </Typography>
                </Stack>
              </Grid>
              {isUserPageView &&
                employee?.weeklyContract &&
                employee.weeklyContract < 5 && (
                  <Grid item md={12} lg={8}>
                    <Stack direction="column" spacing={0.75}>
                      <Stack direction="row" alignItems="center" spacing={0.5}>
                        <EditCalendarTwoToneIcon
                          fontSize="small"
                          color="secondary"
                        />
                        <Typography color={'text.secondary'}>
                          Jours travaillés{' '}
                          {!isValidWorkingDays && (
                            <Typography
                              component="span"
                              color={'error.main'}
                              sx={{
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                                fontSize: '0.75rem',
                              }}
                            >
                              (invalides)
                            </Typography>
                          )}
                        </Typography>
                      </Stack>
                      <ContractualDaysDisplayer
                        isEditable={!!isEditable}
                        updateIsValidWorkingDays={updateIsValidWorkingDays}
                      />
                    </Stack>
                  </Grid>
                )}
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </Section>
  );
};

export default UserInfo;
