import { TableBody, TableCell, TableRow } from '@mui/material';
import { useTableContext } from 'contexts/ActivityMonitoringTable/TableContextProvider';
import React from 'react';

import LoadingPlaceholder from '../../../../commons/LoadingPlaceholder';
import styles from '../../styles/MonitoringTable.module.scss';

export default function LoadingTableBody() {
  const { displayedWeeks } = useTableContext();

  return (
    <TableBody className={styles.tableBody}>
      <TableRow>
        <TableCell
          colSpan={displayedWeeks.length !== 0 ? displayedWeeks.length + 3 : 7}
          sx={{
            textAlign: 'center',
            border: '1px solid',
            borderTop: 0,
            borderColor: 'darkGrey.main',
          }}
        >
          <LoadingPlaceholder />
        </TableCell>
      </TableRow>
    </TableBody>
  );
}
