import {
  ClickAwayListener,
  Grid,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import clsx from 'clsx';
import MissionNameDisplay from 'components/commons/MissionNameDisplay';
import SubMissionNameDisplay from 'components/commons/SubMissionNameDisplay';
import {
  basicWorkedDayValidationRules,
  ColumnLabelsV2,
  formatValue,
  validationWrapper,
} from 'components/commons/Tables/utils';
import { Assignment, useUserInfo } from 'components/User/UserProvider';
import {
  ActivitiesActivityBillingTypeChoices,
  ActivitiesActivityTypeChoices,
  EmployeesEmployeeContractTypeChoices,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { Activity } from 'pages/ActivityPage/PrincipalTablePage';
import { dotToComa } from 'pages/ActivityPage/utils';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import styles from 'styles/ActivityPage/InputCell.module.scss';

interface ActivityWeekRowProps {
  activity: Activity;
  week: ColumnLabelsV2;
  showActivityInputField?: boolean;
  setEnteredTotal: Dispatch<SetStateAction<number>>;
  setAccordionTotal: Dispatch<SetStateAction<number>>;
  disabled?: boolean;
}

const ActivityWeekRow = ({
  activity,
  week,
  showActivityInputField = false,
  setEnteredTotal,
  setAccordionTotal,
  disabled = false,
}: ActivityWeekRowProps) => {
  const theme = useTheme();
  const { employee } = useUserInfo();
  const { register, errors, getValues, watch, trigger } = useFormContext();
  const getFormReferenceName = useCallback(() => {
    let response = `activityMonitoring[${week.start.format('YYYY-MM-DD')}][${
      activity.id
    }]`;
    response = `${response}.timeSpent`;
    return response;
  }, [week.start, activity.id]);
  const watchField = watch(getFormReferenceName());

  const activityMonitoringValue =
    activity.activityMonitoring && activity.activityMonitoring?.length > 0
      ? activity.activityMonitoring[0].timeSpent
      : 0;
  const [fieldValue, setFieldValue] = useState(activityMonitoringValue);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setIsTooltipOpen(true);
  };

  useEffect(
    () => {
      if (getValues(getFormReferenceName())) {
        trigger(getFormReferenceName());
      }
    },
    // eslint-disable-next-line
    [watchField]
  );

  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = Number(e.target.value.replaceAll(',', '.'));
    if (!Number.isNaN(value)) {
      setEnteredTotal((oldTotal) => oldTotal - fieldValue + value);
      setAccordionTotal((oldTotal) => oldTotal - fieldValue + value);
      setFieldValue(value);
    }
  };

  const isMainActivity = !activity.mainActivity;

  const isExpired =
    !!activity.expirationDate &&
    moment(activity.expirationDate, 'YYYY-MM-DD') < week.start;

  const isStarted =
    !activity.startDate || moment(activity.startDate, 'YYYY-MM-DD') < week.end;

  let assignments: Array<Assignment> = [];
  if (employee?.assignments) {
    assignments = assignments.concat(
      employee.assignments.filter((assignment: Assignment) => {
        return assignment.activity && assignment.activity.id === activity.id;
      })
    );
  }

  const isAssignedOnWeek =
    _.some(assignments, (assignemnt) => {
      return (
        moment(assignemnt.beginningDate, 'YYYY-MM-DD') <= week.end &&
        moment(assignemnt.expirationDate, 'YYYY-MM-DD') >= week.start
      );
    }) ||
    activity.isShared ||
    ['COMAN', 'COMEX'].includes(activity.name);

  const isProvider =
    employee?.contractType === EmployeesEmployeeContractTypeChoices.Provider;
  const isInputValid = (value: string) => {
    if (isProvider) {
      return formatValue(value) >= 0;
    } else {
      return formatValue(value) >= 0 && formatValue(value) <= 5;
    }
  };

  const isVacation = activity.type === ActivitiesActivityTypeChoices.Vct;

  const validationError = _.get(errors, getFormReferenceName());

  const tooltipContent =
    isExpired || !isStarted || !isAssignedOnWeek
      ? "Vous n'êtes pas assigné sur la mission cette semaine"
      : '';

  const activityNameDisplay = isMainActivity ? (
    <MissionNameDisplay
      key={`mission-${activity.name}-name-display`}
      mission={activity.name}
      type={activity.type}
      isTmContracts={
        activity.billingType === ActivitiesActivityBillingTypeChoices.Tm
      }
      haveBorder={false}
    />
  ) : (
    <SubMissionNameDisplay
      key={`sub-mission-${activity.name}-name-display`}
      name={activity.name}
    />
  );

  let inputBorderColor = 'darkGrey.main';

  if (isVacation) {
    inputBorderColor = 'background.paper';
  } else if (validationError) {
    inputBorderColor = 'error.main';
  }
  return (
    <>
      <Grid
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        columnSpacing={1}
        mt={1}
      >
        <Grid item xs={showActivityInputField ? 9.5 : 12}>
          {activityNameDisplay}
        </Grid>
        <Grid item xs={showActivityInputField ? 2.5 : 0}>
          {showActivityInputField && (
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={isTooltipOpen}
                title={tooltipContent}
                arrow
              >
                <span onClick={handleTooltipOpen}>
                  <TextField
                    className={clsx(styles.input, {
                      [styles.input_disabled]: disabled,
                      [styles.input_hatched]: !!tooltipContent,
                      [styles.vacation]: isVacation,
                    })}
                    aria-label={getFormReferenceName()}
                    name={getFormReferenceName()}
                    inputRef={register({
                      required: false,
                      validate: {
                        ...basicWorkedDayValidationRules,
                        isInRange: (value) =>
                          validationWrapper(
                            value,
                            isInputValid(value) ||
                              (isProvider
                                ? 'La valeur saisie doit être supérieure 0.'
                                : 'La valeur saisie doit être entre 0 et 5.')
                          ),
                      },
                    })}
                    size="small"
                    color={validationError ? 'error' : 'primary'}
                    error={!!validationError}
                    focused={!!validationError}
                    defaultValue={fieldValue > 0 ? dotToComa(fieldValue) : ''}
                    placeholder={isVacation ? '-' : ''}
                    onChange={(e) => handleValueChange(e)}
                    sx={{
                      borderRadius: '5px',
                      backgroundColor: 'lightGrey.main',
                      '& fieldset': {
                        borderColor: inputBorderColor,
                      },
                    }}
                    inputProps={{
                      inputMode: 'decimal',
                      step: 0.25,
                      min: 0,
                      max: 5,
                      style: {
                        height: 18,
                        textAlign: 'center',
                        color: validationError
                          ? theme.palette.error.main
                          : 'inherit',
                      },
                    }}
                  />
                </span>
              </Tooltip>
            </ClickAwayListener>
          )}
        </Grid>
      </Grid>

      {validationError && (
        <Typography
          key={`${activity.id}-error`}
          color={'error'}
          variant={'bodyS'}
        >
          {validationError.message}
        </Typography>
      )}
    </>
  );
};

export default ActivityWeekRow;
