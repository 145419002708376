import { Grid, Skeleton, Stack } from '@mui/material';
import React from 'react';

export default function TrophiesSkeleton({
  xs = 4,
  skeletonNumber = 9,
}: {
  xs?: number;
  skeletonNumber?: number;
}) {
  return (
    <>
      {Array.from({ length: skeletonNumber }, (_, i) => (
        <Grid item key={i} xs={xs}>
          <Stack
            height={50}
            width={50}
            borderRadius={10}
            overflow="hidden"
            sx={{
              margin: 'auto',
            }}
          >
            <Skeleton
              variant="circular"
              width={50}
              height={50}
              animation="wave"
            />
          </Stack>
        </Grid>
      ))}
    </>
  );
}
