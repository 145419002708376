import { TableCell } from '@mui/material';
import ChildCollapsePolyTableRow from 'components/MUIOverload/PolyTableRow/ChildCollapsePolyTableRow';
import MonthRevenueInputCells from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/RevenueRows/MonthRevenueInputCells';
import styles from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/styles/CollapseStyles.module.scss';
import { PurchaseOrderNode, PurchaseOrderTaskNode } from 'generated/graphql';
import _ from 'lodash';
import { totalFormat } from 'pages/ActivityPage/utils';
import React, { useState } from 'react';
import { TransitionStatus } from 'react-transition-group/Transition';

interface TaskRowProps {
  purchaseOrder: PurchaseOrderNode;
  task: PurchaseOrderTaskNode;
  status: TransitionStatus;
  isTmContracts: boolean;
}

const getOldTakRevenue = (
  purchaseOrder: PurchaseOrderNode,
  task: PurchaseOrderTaskNode
) => {
  return _(purchaseOrder.revenues)
    .filter((revenue) => revenue.validated && revenue.task?.id === task.id)
    .sumBy('amount');
};

export default function TaskRow({
  purchaseOrder,
  task,
  status,
  isTmContracts,
}: TaskRowProps) {
  const [poFormName] = useState(`revenuesByPo.${purchaseOrder.id}`);
  const [keyRevenues] = useState(`${poFormName}.revenues.${task.id}`);
  const totalTask = task?.amount || 0;
  const oldTaskRevenue = getOldTakRevenue(purchaseOrder, task);

  return (
    <ChildCollapsePolyTableRow status={status}>
      <TableCell className={styles.nameCell}>{task.name}</TableCell>
      <TableCell className={styles.totalCell}>
        {totalFormat(totalTask, purchaseOrder.currency)}
      </TableCell>
      <MonthRevenueInputCells
        isTmContracts={isTmContracts}
        purchaseOrder={purchaseOrder}
        period={{
          start: task.periodBeginning,
          end: task.periodEnding,
        }}
        oldRevenue={oldTaskRevenue}
        keyForm={keyRevenues}
        total={totalTask}
      />
    </ChildCollapsePolyTableRow>
  );
}
