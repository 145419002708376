import moment from 'moment';

export function getSeniorityMessage(hiringDate: Date): string {
  const monthsSumInPoly = -1 * moment(hiringDate).diff(moment.now(), 'months');
  const yearsInPoly = Math.floor(monthsSumInPoly / 12);
  const monthsInPoly = monthsSumInPoly % 12;

  let seniorityMessage: string;

  if (yearsInPoly < 1) {
    if (monthsInPoly < 1) {
      seniorityMessage = `moins d'un mois`;
    } else {
      seniorityMessage = `${monthsInPoly} mois`;
    }
  } else {
    if (yearsInPoly === 1) {
      seniorityMessage = `1 an et ${monthsInPoly} mois`;
    } else {
      seniorityMessage = `${yearsInPoly} ans et ${monthsInPoly} mois`;
    }
  }

  return seniorityMessage;
}
