import { ApolloQueryResult } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import SectionTitle from 'components/commons/SectionTitle';
import SkillChip from 'components/commons/SkillChip';
import NoSkills from 'components/EmployeePage/EmployeeSkills/NoSkills';
import PolyDialogActions from 'components/MUIOverload/PolyDialog/DefaultComponents/PolyDialogActions';
import {
  AllEmployeeSkillsForEmployeeQuery,
  EmployeeNode,
  EmployeeSkillInputObjectType,
  EmployeeSkillNode,
  Exact,
  Scalars,
  SkillCategoryNode,
  SkillNode,
  useAllSkillCategoriesQuery,
  useUpdateEmployeeSkillsMutation,
} from 'generated/graphql';
import _ from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { SaveButtonName } from 'poly-constants';
import React, { useEffect, useMemo, useState } from 'react';
import { scrollbarParams } from 'utils';

import SkillDirectionsInfoTooltipTitle from '../SkillDirectionsInfoTooltipTitle';
import SkillLevelsInfoTooltipTitle from '../SkillLevelsInfoTooltipTitle';
import SkillSearchBar from './SkillSearchBar';
import SkillSearchNoResult from './SkillSearchNoResult';
import {
  addSkillToEmployeeSkills,
  addSkillToSkillCategories,
  removeEmployeeSkillsFromSkillCategories,
  removeSkillFromEmployeeSkills,
  removeSkillFromSkillCategories,
  updateSkillLevelInEmployeeSkills,
} from './utils';

interface SkillsModalProps {
  isOpen: boolean;
  employee: EmployeeNode;
  employeeSkills: EmployeeSkillNode[];
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetchEmployeeSkills: (
    variables?:
      | Partial<
          Exact<{
            employeeId: Scalars['ID'];
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<AllEmployeeSkillsForEmployeeQuery>>;
}

export default function SkillsModal({
  isOpen,
  employee,
  employeeSkills: savedEmployeeSkills,
  setIsOpen,
  refetchEmployeeSkills,
}: SkillsModalProps) {
  const theme = useTheme();
  const [activePannel, setActivePannel] = useState(0);
  const [isDirty, setIsDirty] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [employeeSkills, setEmployeeSkills] = useState(savedEmployeeSkills);
  const [skillCategories, setSkillCategories] = useState<SkillCategoryNode[]>(
    []
  );

  const { loading: allSkillCategoriesIsLoading } = useAllSkillCategoriesQuery({
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    onCompleted: (data) => {
      if (data?.allSkillCategories) {
        const skillCategoriesWithoutEmployeeSkills =
          removeEmployeeSkillsFromSkillCategories(
            data?.allSkillCategories as SkillCategoryNode[],
            savedEmployeeSkills
          );
        setSkillCategories(
          skillCategoriesWithoutEmployeeSkills as SkillCategoryNode[]
        );
      }
    },
  });

  const [
    updateEmployeeSkillsMutation,
    { loading: updateEmployeeSkillsIsLoading },
  ] = useUpdateEmployeeSkillsMutation({
    onError: () => {
      enqueueSnackbar(
        "Une erreur s'est produite lors de la mise à jour des compétences.",
        {
          variant: 'error',
        }
      );
    },
    onCompleted: (data) => {
      const employeeSkillsCount = data.updateEmployeeSkills
        ?.employeeSkillsCount as number;
      let snackbarMessage = '';
      switch (employeeSkillsCount) {
        case 0:
          snackbarMessage = 'Vos compétences ont été supprimées avec succès.';
          break;
        case 1:
          snackbarMessage = 'Une compétence a été enregistrée avec succès.';
          break;
        default:
          snackbarMessage = `${employeeSkillsCount} compétences ont été enregistrées avec succès.`;
      }
      enqueueSnackbar(snackbarMessage, {
        variant: 'success',
      });
      refetchEmployeeSkills();
      handleClose();
    },
  });

  const filteredCategories = useMemo(() => {
    return _.chain(skillCategories)
      .map((category) => ({
        ...category,
        skills: _.filter(
          category.skills,
          (skill) =>
            skill && _.includes(_.toLower(skill.name), _.toLower(searchTerm))
        ),
      }))
      .filter((category) => category.skills && category.skills.length > 0)
      .value();
  }, [skillCategories, searchTerm]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    if (activePannel !== 0) {
      setActivePannel(0);
    }
  };

  const handleAddSkill = (skill: SkillNode, level?: number) => {
    const newEmployeeSkill: EmployeeSkillNode = {
      id: '',
      skill: skill,
      employee: employee,
      level: skill.levels.length > 0 ? level : undefined,
      createdAt: undefined,
      updatedAt: undefined,
    };
    setEmployeeSkills((state) =>
      addSkillToEmployeeSkills(state, newEmployeeSkill)
    );
    setSkillCategories((state) =>
      removeSkillFromSkillCategories(state, skill.id)
    );
    setIsDirty(true);
    if (!_.some(filteredCategories, { id: skill.category.id })) {
      setActivePannel(0);
    }
  };

  const handleDeleteSkill = (skill: SkillNode) => {
    setEmployeeSkills((state) =>
      removeSkillFromEmployeeSkills(state, skill.id)
    );
    setSkillCategories((state) => addSkillToSkillCategories(state, skill));
    setIsDirty(true);
  };

  const handleUpdateSkillLevel = (skillId: string, newLevel: number) => {
    setEmployeeSkills((state) =>
      updateSkillLevelInEmployeeSkills(state, skillId, newLevel)
    );
    setIsDirty(true);
  };

  const handleSubmit = async () => {
    const employeeSkillsArray: EmployeeSkillInputObjectType[] = _.map(
      employeeSkills,
      (employeeSkill) => {
        return {
          skillId: employeeSkill.skill.id,
          level: employeeSkill.level,
        };
      }
    );
    await updateEmployeeSkillsMutation({
      variables: {
        employeeId: employee.id,
        employeeSkills: employeeSkillsArray,
      },
    });
  };

  useEffect(() => {
    if (filteredCategories.length < activePannel + 1) {
      setActivePannel(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredCategories.length]);

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth={false}>
      <DialogContent
        sx={{
          overflow: 'hidden',
          width: 800,
          backgroundColor: theme.palette.modalBackground.main,
          pt: 0,
        }}
        data-testid={'staffing-modal'}
      >
        <Stack
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Stack flexDirection={'row'} alignItems={'center'} sx={{ py: 2 }}>
            <Typography color="text.secondary" variant="h3Bold">
              Compétences
            </Typography>
          </Stack>
          <IconButton edge={'end'} onClick={handleClose} size="large">
            <CloseIcon />
          </IconButton>
        </Stack>
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            borderRadius: '8px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              ml: 4,
              mr: 1.5,
              mb: 1,
              pt: 1.5,
            }}
          >
            <SectionTitle
              variant="h3Bold"
              infoTooltipTitle={<SkillDirectionsInfoTooltipTitle />}
            >
              Toutes les compétences
            </SectionTitle>
            <SkillSearchBar
              value={searchTerm}
              onChange={handleSearchChange}
              setSearchTerm={setSearchTerm}
              disabled={allSkillCategoriesIsLoading}
            />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              bgcolor: 'background.paper',
              display: 'flex',
              borderBottom: 1,
              borderColor: 'divider',
              height: 252,
            }}
          >
            {allSkillCategoriesIsLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <LoadingPlaceholder />
              </Box>
            ) : (
              <>
                {filteredCategories.length === 0 ? (
                  <SkillSearchNoResult searchTerm={searchTerm} />
                ) : (
                  <>
                    <Tabs
                      orientation="vertical"
                      variant="scrollable"
                      value={activePannel}
                      indicatorColor={'secondary'}
                      textColor={'secondary'}
                      TabScrollButtonProps={{ sx: { p: 0 } }}
                      sx={{
                        minWidth: 300,
                        height: 252,
                        borderRight: 1,
                        borderColor: 'divider',
                      }}
                    >
                      {_.map(filteredCategories, (category, index) => {
                        return (
                          <Tab
                            key={`skill-category-tab-${index}`}
                            label={`${category.name} (${category.skills?.length})`}
                            onClick={() => setActivePannel(index)}
                            sx={{
                              textTransform: 'none',
                              minHeight: 36,
                              padding: 0,
                            }}
                          />
                        );
                      })}
                    </Tabs>
                    {_.map(filteredCategories, (category, index) => {
                      return (
                        <Box
                          key={`skill-category-panel-${index}`}
                          sx={{
                            my: 1.25,
                            ml: 4,
                            mr: 1.5,
                            display: activePannel === index ? 'inline' : 'none',
                          }}
                        >
                          <Grid
                            container
                            gap={1}
                            sx={{
                              pr: 0.5,
                              maxHeight: 232,
                              ...scrollbarParams,
                            }}
                          >
                            {_.map(category.skills, (skill, index) => {
                              return (
                                <Grid item key={`skill-item-${index}`}>
                                  <SkillChip
                                    label={skill.name}
                                    disabled={updateEmployeeSkillsIsLoading}
                                    OnClick={
                                      skill.levels.length > 0
                                        ? (newLevel) =>
                                            handleAddSkill(skill, newLevel)
                                        : () => handleAddSkill(skill)
                                    }
                                    onClickDisplayMenu={skill.levels.length > 0}
                                  />
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Box>
                      );
                    })}
                  </>
                )}
              </>
            )}
          </Box>

          <Stack
            spacing={2.5}
            sx={{
              my: 2.5,
              ml: 4,
              mr: employeeSkills.length === 0 ? 4 : 1.5,
              height: 170,
            }}
          >
            <SectionTitle
              variant="h3Bold"
              infoTooltipTitle={<SkillLevelsInfoTooltipTitle />}
            >
              Mes compétences
            </SectionTitle>
            {employeeSkills.length === 0 ? (
              <NoSkills />
            ) : (
              <Grid
                container
                gap={1}
                sx={{
                  my: 1,
                  pr: 1,
                  ...scrollbarParams,
                }}
              >
                {_.map(employeeSkills, (employeeSkill, index) => {
                  return (
                    <Grid item key={`skill-item-${index}`}>
                      <SkillChip
                        label={employeeSkill.skill.name}
                        level={employeeSkill.level}
                        disabled={updateEmployeeSkillsIsLoading}
                        onDelete={() => handleDeleteSkill(employeeSkill.skill)}
                        OnClick={
                          employeeSkill.level
                            ? (newLevel) =>
                                newLevel &&
                                handleUpdateSkillLevel(
                                  employeeSkill.skill.id,
                                  newLevel
                                )
                            : undefined
                        }
                        onClickDisplayMenu={
                          employeeSkill.skill.levels.length > 0
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Stack>

          <Box sx={{ px: 0.5, pb: 0.5 }}>
            <PolyDialogActions
              primaryButtonName={SaveButtonName}
              handlePrimary={handleSubmit}
              handleSecondary={handleClose}
              isPrimaryButtonDisabled={
                !isDirty ||
                allSkillCategoriesIsLoading ||
                updateEmployeeSkillsIsLoading
              }
              isPrimaryButtonLoading={updateEmployeeSkillsIsLoading}
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
