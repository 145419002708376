import { Box, TableCell, TableRow } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';
import Cell, { CellType } from 'components/ActivityPage/common/Cell';
import { getWeekSummaryTextColor } from 'components/ActivityPage/utils';
import commonStyles from 'components/commons/css/commons.module.scss';
import { ColumnLabelsV2 } from 'components/commons/Tables/utils';
import { useUserInfo } from 'components/User/UserProvider';
import {
  EmployeesEmployeeContractTypeChoices,
  MeEmployeeFragment,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import {
  dotToComa,
  hasUpdateAMAuthValid,
  roundNumber,
  totalDaysToWorkV2,
} from 'pages/ActivityPage/utils';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import styles from 'styles/ActivityPage/TotalRow.module.scss';
import { graphQlDateFormatter } from 'utils';

import { useTableContext } from './context/TableContextProvider';
import { useValueContext } from './context/ValueContextProvider';
import Warnings from './Warnings';

export interface TotalRowProps {
  footer: React.ReactNode;
}

export const getIsTotalInvalid = (
  daysWorked: number,
  DaysToWork: number,
  week: ColumnLabelsV2,
  employee: MeEmployeeFragment | null | undefined
) => {
  const isBefore = week.start < moment().startOf('weeks');
  const isProvider =
    employee?.contractType === EmployeesEmployeeContractTypeChoices.Provider;
  if (isProvider) {
    return isBefore && daysWorked < DaysToWork;
  } else {
    return isBefore ? daysWorked !== DaysToWork : daysWorked > DaysToWork;
  }
};

const getTimeSpent = (activities: { [id: string]: { timeSpent: string } }) => {
  return _.map(activities, (activity) => {
    if (activity.timeSpent) {
      const result = Number.parseFloat(activity.timeSpent.replace(',', '.'));
      if (Number.isNaN(result)) {
        return 0;
      }
      if (result % 0.25 !== 0) {
        return 0;
      }
      return result;
    }
    return 0;
  });
};
const TotalRow = ({ footer }: TotalRowProps) => {
  const { employee } = useUserInfo();
  const isProvider =
    employee?.contractType === EmployeesEmployeeContractTypeChoices.Provider;
  const {
    invalidWeeks,
    setInvalidWeeks,
    providerOvertimeWeeks,
    setProviderOvertimeWeeks,
    lockedWeeks,
    needToAddCommentWeeks,
  } = useValueContext();
  const { invalidFirstElementWeeks, totalErrors, weekRange } =
    useTableContext();
  const { getValues } = useFormContext();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const computedInvalidWeeks: { [week: string]: boolean } = {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const computedProviderOvertimeWeeks: { [week: string]: boolean } = {};
  const weekActivities = getValues();

  const cellsList = _.map(weekRange, (week) => {
    const formatedDate = graphQlDateFormatter(week.start);
    computedInvalidWeeks[formatedDate] = false;
    computedProviderOvertimeWeeks[formatedDate] = false;
    const employeeDaysToWork = totalDaysToWorkV2(
      employee,
      week.start,
      week.end
    );
    const isWeekLocked = formatedDate in lockedWeeks;
    if (isWeekLocked) {
      const lockedWeekTotal = _.sumBy(lockedWeeks[formatedDate], 'timeSpent');
      return (
        <Cell cellType={CellType.Current} key={`total-'${formatedDate}`}>
          <Box my={2}>
            {dotToComa(roundNumber(lockedWeekTotal)) +
              (' / ' + employeeDaysToWork)}
          </Box>
        </Cell>
      );
    } else {
      const activities = _.get(
        weekActivities['activityMonitoring'],
        formatedDate
      );
      const timeSpent = activities ? getTimeSpent(activities) : [];
      let tooltipTitle = '';
      let isProviderOvertime = false;
      const timeSpentOnWeek = _.sum(timeSpent);
      const isTotalInvalid = getIsTotalInvalid(
        timeSpentOnWeek,
        employeeDaysToWork,
        week,
        employee
      );
      if (isProvider) {
        isProviderOvertime = timeSpentOnWeek > employeeDaysToWork;
        if (isTotalInvalid) {
          tooltipTitle = `Le total saisi sur la semaine doit être égal à ${employeeDaysToWork} jours.`;
        }
        if (needToAddCommentWeeks.includes(formatedDate)) {
          tooltipTitle = `Le total saisi sur la semaine est supérieur à ${employeeDaysToWork} jours, veuillez renseigner une justification dans la case associée.`;
        }
      } else {
        if (isTotalInvalid) {
          tooltipTitle =
            timeSpentOnWeek > employeeDaysToWork
              ? `Le temps saisi excède votre temps contractuel de ${employeeDaysToWork} jours.`
              : `Le total saisi sur la semaine doit être égal à ${employeeDaysToWork} jours.`;
        }
      }
      computedInvalidWeeks[formatedDate] = isTotalInvalid;
      computedProviderOvertimeWeeks[formatedDate] = isProviderOvertime;
      invalidFirstElementWeeks[formatedDate] =
        isTotalInvalid || isProviderOvertime;
      if (isTotalInvalid) {
        totalErrors[formatedDate] = isTotalInvalid;
      } else {
        delete totalErrors[formatedDate];
      }
      const isCurrentWeek = moment().isSame(week.start, 'week');
      const isBeforeCurrentWeek = week.start < moment().startOf('weeks');
      const isAutorizedToUpdateAM = hasUpdateAMAuthValid(
        employee?.updateAuthorization,
        employee?.updateAuthorization?.months,
        week.start
      );
      const textColor = getWeekSummaryTextColor(
        true,
        isAutorizedToUpdateAM,
        isCurrentWeek,
        isBeforeCurrentWeek,
        isTotalInvalid
      );

      return (
        <Cell key={`total-'${formatedDate}`} cellType={CellType.Current}>
          <Tooltip title={tooltipTitle} arrow>
            <Box my={2} color={textColor}>
              {(timeSpentOnWeek
                ? dotToComa(roundNumber(timeSpentOnWeek))
                : '-') +
                (' / ' + employeeDaysToWork)}
            </Box>
          </Tooltip>
        </Cell>
      );
    }
  });

  useEffect(() => {
    if (!_.isEqual(invalidWeeks, computedInvalidWeeks)) {
      setInvalidWeeks(computedInvalidWeeks);
    }
    if (
      isProvider &&
      !_.isEmpty(computedProviderOvertimeWeeks) &&
      !_.isEqual(providerOvertimeWeeks, computedProviderOvertimeWeeks)
    ) {
      setProviderOvertimeWeeks(computedProviderOvertimeWeeks);
    }
  }, [
    invalidWeeks,
    computedInvalidWeeks,
    setInvalidWeeks,
    isProvider,
    providerOvertimeWeeks,
    setProviderOvertimeWeeks,
    computedProviderOvertimeWeeks,
    getValues,
  ]);

  return (
    <>
      <TableRow className={clsx(styles.row, commonStyles.stickyFooter)}>
        <TableCell rowSpan={2} className={styles.cell} padding={'none'}>
          <Warnings />
        </TableCell>
        <TableCell
          className={styles.cell}
          colSpan={cellsList.length}
          padding={'none'}
        >
          <TableRow>{cellsList}</TableRow>
          <TableRow>
            <TableCell
              className={styles.cell}
              colSpan={cellsList.length}
              padding={'none'}
            >
              {footer}
            </TableCell>
          </TableRow>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TotalRow;
