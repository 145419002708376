import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Chip, ClickAwayListener, Paper, Popper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PongoButton from 'components/MUIOverload/PongoButton';
import React, { useEffect, useState } from 'react';

const DEFAULT_SCROLL_PARAMS: ScrollParams = {
  currentDistanceToTop: 0,
  totalScrollableHeight: null,
  elementHeight: null,
};

export interface Props {
  children: React.ReactFragment;
  title: string;
  filterCount: number;
  isScrollable?: boolean;
  maxHeight?: number;
}

interface ScrollParams {
  totalScrollableHeight: number | null;
  currentDistanceToTop: number;
  elementHeight: number | null;
}

const isScrollAtTop = (params: ScrollParams): boolean => {
  return params.currentDistanceToTop === 0;
};

const isScrollAtBottom = (params: ScrollParams): boolean => {
  const isEveryScrollParamSet =
    params.totalScrollableHeight !== null && params.elementHeight !== null;
  return (
    isEveryScrollParamSet &&
    (params.elementHeight as number) >
      (params.totalScrollableHeight as number) - params.currentDistanceToTop - 1
  );
};

const isMenuOpened = (anchorEl: HTMLButtonElement | null): boolean => {
  return Boolean(anchorEl);
};

export default function PolyTrombiSearchFilterDropdownMenu({
  title,
  children,
  filterCount,
  isScrollable,
  maxHeight,
}: Props) {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [scrollParams, setScrollParams] = useState<ScrollParams>(
    DEFAULT_SCROLL_PARAMS
  );

  useEffect(
    function resetScrollVariableOnClose() {
      if (!isMenuOpened(anchorEl)) {
        setScrollParams(DEFAULT_SCROLL_PARAMS);
      }
    },
    [anchorEl]
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const displayFilterCount = () => {
    return (
      <Chip
        label={filterCount}
        sx={{
          'span.MuiChip-label': {
            color: 'white',
            fontSize: '16px',
            px: '0px',
          },
          height: '20px',
          width: '20px',
        }}
        color="primary"
      />
    );
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    setScrollParams({
      currentDistanceToTop: event.currentTarget.scrollTop,
      totalScrollableHeight: event.currentTarget.scrollHeight,
      elementHeight: event.currentTarget.clientHeight,
    });
  };

  return (
    <>
      <PongoButton
        onClick={handleClick}
        endIcon={
          isMenuOpened(anchorEl) ? <ExpandLessIcon /> : <ExpandMoreIcon />
        }
        variant="contained"
        isFocus={isMenuOpened(anchorEl)}
        buttonStyle="input"
        uppercase={false}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: '8px',
          'span.MuiButton-endIcon': {
            ml: '0px',
          },
        }}
      >
        {title} {filterCount > 0 && displayFilterCount()}
      </PongoButton>
      <Popper
        open={isMenuOpened(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-start"
        disablePortal
      >
        <Paper
          onScroll={handleScroll}
          elevation={4}
          sx={{
            mt: 1,
            color: palette.info.main,
            maxHeight: maxHeight ?? '',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            scrollbarWidth: 'none',
            msOverflowStyle: 'non',
          }}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <Box sx={{ padding: 1, color: palette.info.main }}>{children}</Box>
          </ClickAwayListener>
          {isScrollable && (
            <>
              <Box
                className="top-shadow"
                sx={{
                  mt: 1,
                  opacity: isScrollAtTop(scrollParams) ? 0 : 1,
                  transition: 'opacity 0.2s',
                  position: 'absolute',
                  top: 0,
                  width: '100%',
                  height: '50px',
                  borderRadius: 1,
                  background:
                    'linear-gradient(to bottom, white 0%, transparent 100%)',
                  pointerEvents: 'none',
                }}
              />
              <Box
                className="bottom-shadow"
                sx={{
                  opacity: isScrollAtBottom(scrollParams) ? 0 : 1,
                  transition: 'opacity 0.2s',
                  position: 'absolute',
                  bottom: 0,
                  width: '100%',
                  height: '50px',
                  borderRadius: 1,
                  background:
                    'linear-gradient(to top, white 0%, transparent 100%)',
                  pointerEvents: 'none',
                }}
              />
            </>
          )}
        </Paper>
      </Popper>
    </>
  );
}
