import { Box, Stack, SxProps, Typography } from '@mui/material';
import React from 'react';
import { designSystem } from 'theme';

import InfoTooltip from '../InfoTooltip';

interface Props {
  children: string;
  infoTooltipTitle?: JSX.Element | string;
  variant?: 'h1Bold' | 'h2Bold' | 'h3Bold';
  color?: keyof typeof designSystem.palette;
  sx?: SxProps;
}

const IconStyle = {
  h1Bold: { width: 7, height: 38, spacing: 1.5 },
  h2Bold: { width: 6, height: 30, spacing: 1.25 },
  h3Bold: { width: 5, height: 24, spacing: 1 },
};

const getComponentNameByVariant = (
  variant: 'h1Bold' | 'h2Bold' | 'h3Bold'
): 'h1' | 'h2' | 'h3' | 'span' => {
  switch (variant.charAt(1)) {
    case '1':
      return 'h1';
    case '2':
      return 'h2';
    case '3':
      return 'h3';
    default:
      return 'span';
  }
};

const SectionTitle = ({
  children,
  infoTooltipTitle,
  variant = 'h2Bold',
  color = 'primary',
  sx,
}: Props) => {
  return (
    <Stack
      direction="row"
      spacing={IconStyle[variant].spacing}
      alignItems={'center'}
      sx={{ ...sx }}
    >
      <Box
        sx={{
          width: IconStyle[variant].width,
          height: IconStyle[variant].height,
          backgroundColor: `${color}.main`,
          borderRadius: '14px',
        }}
      />
      <Typography
        variant={variant}
        component={getComponentNameByVariant(variant)}
      >
        {children}
      </Typography>
      {infoTooltipTitle && <InfoTooltip title={infoTooltipTitle} />}
    </Stack>
  );
};

export default SectionTitle;
