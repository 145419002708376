import PolyTableCell from 'components/MUIOverload/PolyTableCell';
import PolyTableRow from 'components/MUIOverload/PolyTableRow';
import React from 'react';

import PolyTableBody from '../PolyTableBody';
import styles from './styles/PolyDataGrid.module.scss';

interface PolyDataGridEmptyBodyProps {
  columnsNumber: number;
}

export default function PolyDataGridEmptyBody({
  columnsNumber,
}: PolyDataGridEmptyBodyProps) {
  return (
    <PolyTableBody>
      <PolyTableRow>
        <PolyTableCell colSpan={columnsNumber}>
          <div className={styles.noResult}>Pas de résultat</div>
        </PolyTableCell>
      </PolyTableRow>
    </PolyTableBody>
  );
}
