import { TableCell, TableCellProps, Tooltip } from '@mui/material';
import clsx from 'clsx';
import styles from 'components/Revenue/RevenueGlobalTable/styles/TableCell.module.scss';
import React from 'react';

export default function OutOfRangeTableCell(properties: TableCellProps) {
  const { title, ...tableCellProperties } = properties;
  if (title) {
    return (
      <Tooltip title={title} arrow>
        <TableCell className={styles.invalidCell} {...tableCellProperties}>
          <div className={styles.emptyContent} />
        </TableCell>
      </Tooltip>
    );
  }
  return (
    <TableCell
      {...properties}
      className={clsx(properties.className, styles.invalidCell)}
    />
  );
}
