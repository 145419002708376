import { ActivityNode, PurchaseOrderNode } from 'generated/graphql';
import moment, { Moment } from 'moment';
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { functionNotSet } from 'utils';

export interface TableContextProps {
  displayedMonths: Moment[];
  incrementMonth: () => void;
  decrementMonth: () => void;
  dateRange: { start: Moment; end: Moment };
  setDateRange: (p: { start: Moment; end: Moment }) => void;
  purchaseOrders: PurchaseOrderNode[];
  activity: ActivityNode;
  currentYear: Moment;
  setCurrentYear: (year: Moment) => void;
}

export interface TableContextProviderProps {
  children?: React.ReactNode;
  purchaseOrders: PurchaseOrderNode[];
  activity: ActivityNode;
  currentYear: Moment;
  setCurrentYear: (year: Moment) => void;
}

const TableContext = createContext<TableContextProps>({
  displayedMonths: [],
  incrementMonth: () => functionNotSet(),
  decrementMonth: () => functionNotSet(),
  setDateRange: () => functionNotSet(),
  dateRange: { end: moment(), start: moment() },
  purchaseOrders: [],
  activity: {} as ActivityNode,
  currentYear: moment(),
  setCurrentYear: () => functionNotSet(),
});

export const useTableContext = () => {
  const tableContext = useContext(TableContext);
  if (!tableContext) {
    throw new Error('useContext() can only be used  inside a table');
  }
  return tableContext;
};

const TableContextProvider = (
  properties: PropsWithChildren<TableContextProviderProps>
) => {
  const [dateRange, setDateRange] = useState({
    start: moment().subtract(3, 'months'),
    end: moment().add(2, 'months'),
  });

  const incrementMonth = () => {
    setDateRange({
      start: dateRange.start.clone().add(1, 'month'),
      end: dateRange.end.clone().add(1, 'month'),
    });
  };

  const decrementMonth = () => {
    setDateRange({
      start: dateRange.start.clone().subtract(1, 'month'),
      end: dateRange.end.clone().subtract(1, 'month'),
    });
  };

  const getDisplayedMonths = useCallback(() => {
    const months: Moment[] = [];
    const start = dateRange.start.clone();

    while (
      dateRange.end > start ||
      start.format('M') === dateRange.end.format('M')
    ) {
      months.push(start.clone());
      start.add(1, 'month');
    }

    return months;
  }, [dateRange.end, dateRange.start]);

  const [displayedMonths, setDisplayedMonths] = useState<Moment[]>(
    getDisplayedMonths()
  );

  useEffect(() => {
    if (!properties.currentYear.isSame(moment(), 'year')) {
      const start = properties.currentYear.clone().startOf('year');
      const end = start.clone().add(5, 'month');
      setDateRange({ start, end });
    }
  }, [properties.currentYear]);

  useEffect(() => {
    setDisplayedMonths(getDisplayedMonths());
  }, [dateRange, getDisplayedMonths]);

  return (
    <TableContext.Provider
      value={{
        dateRange,
        incrementMonth,
        decrementMonth,
        displayedMonths,
        setDateRange,
        purchaseOrders: properties.purchaseOrders,
        activity: properties.activity,
        currentYear: properties.currentYear,
        setCurrentYear: properties.setCurrentYear,
      }}
    >
      {properties.children}
    </TableContext.Provider>
  );
};

export default TableContextProvider;
