import {
  Box,
  Button,
  Divider,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import React from 'react';
import { useForm } from 'react-hook-form';
import urlJoin from 'url-join';
import { isDev } from 'utils';

import styles from './styles/LoginPage.module.scss';

export interface LoginFormData {
  username: string;
  password: string;
}

export type FailureType =
  | false
  | 'email'
  | 'google'
  | 'DOMAIN_DELETED'
  | 'USER_NOT_FOUND';

interface LoginPageProps {
  clientID?: string;
  onValidate: (formData: LoginFormData) => void;
  onGoogleSuccess: (response: CredentialResponse) => Promise<void>;
  onGoogleFailure: () => void;
}

const LoginPage = ({
  clientID,
  onValidate,
  onGoogleSuccess,
  onGoogleFailure,
}: LoginPageProps) => {
  const { handleSubmit, register } = useForm<LoginFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  return (
    <Box sx={{ height: '100vh', width: '100vw' }}>
      <Stack sx={{ height: '100%', flexDirection: 'row' }}>
        <Box className={styles.cover}>
          <img
            alt="polyconseil logo"
            src={urlJoin(
              '/',
              process.env.PUBLIC_URL,
              'img',
              'logo-white-polyconseil.png'
            )}
            className={styles.cover_logo}
          />
          <div className={styles.cover_accroche}>
            We <span>make </span> innovation <span>happen</span>... for{' '}
            <span>real!</span>
          </div>
        </Box>
        <Stack
          sx={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Stack
            sx={{
              flexDirection: 'column',
              alignItems: 'center',
              p: '35px 60px',
              borderRadius: '3px',
              boxShadow: 1,
            }}
          >
            <Stack sx={{ alignSelf: 'flex-start', mb: 4 }}>
              <Typography variant="h3Bold" component="h3">
                Bienvenue
              </Typography>
            </Stack>
            {clientID && (
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  onGoogleSuccess(credentialResponse as CredentialResponse);
                }}
                onError={onGoogleFailure}
                useOneTap
              />
            )}
            {isDev && (
              <>
                <Divider
                  sx={{
                    width: '60%',
                    m: 2,
                    color: 'secondary.main',
                  }}
                />
                <form onSubmit={handleSubmit(onValidate)}>
                  <Stack
                    sx={{
                      flexDirection: 'column',
                      mt: 2.5,
                      width: '100%',
                    }}
                  >
                    <Stack sx={{ mb: 2 }}>
                      <InputLabel>
                        <Typography variant="label">Username</Typography>
                      </InputLabel>
                      <TextField
                        name="username"
                        inputRef={register({ required: true })}
                        sx={{ mt: 1, height: '40px' }}
                        size="small"
                      />
                    </Stack>
                    <Stack>
                      <InputLabel>
                        <Typography variant="label">Mot de passe</Typography>
                      </InputLabel>
                      <TextField
                        type="password"
                        name="password"
                        inputRef={register({ required: true })}
                        sx={{ mt: 1, height: '40px' }}
                        size="small"
                      />
                    </Stack>

                    <Button
                      sx={{
                        color: 'white',
                        backgroundColor: 'primary.main',
                        mt: 2.5,
                      }}
                      type="submit"
                    >
                      Se connecter
                    </Button>
                  </Stack>
                </form>
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default LoginPage;
