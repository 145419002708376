import { ActivityNode, EmployeeNode } from 'generated/graphql';
import { concat } from 'lodash';
import { LanguageRegionEnum } from 'poly-constants';
import { formatDateUStoFR } from 'utils';

export interface EmailOption {
  email: string;
  employee?: EmployeeNode;
  occupation?: string;
}

export interface LanguageOption {
  value: string;
  label: string;
}

export interface TemplateParts {
  [lang: string]: {
    billLabel: string;
    greeting: string;
    body1: string;
    body2: string;
    thanks: string;
    closing: string;
    vatSuffix: string;
    to: string;
  };
}

export const getRecipientsEmails = (
  currentMission: ActivityNode
): EmailOption[] => {
  return currentMission.billingInformation?.billingModalities
    ? currentMission.billingInformation?.billingModalities
        .map((modality) => ({ email: modality.billingEmail || '' }))
        .filter((element) => element.email !== '')
    : [];
};

export const getCcRecipientsEmails = (
  currentMission: ActivityNode
): EmailOption[] => {
  const directorEmail: EmailOption = {
    email: currentMission.director?.email || '',
    employee: currentMission.director,
    occupation: 'Directeur de mission',
  };

  const chiefsEmails: EmailOption[] = [];

  currentMission.chiefs?.forEach((chief) => {
    if (chief.email !== directorEmail.email) {
      chiefsEmails.push({
        email: chief.email || '',
        employee: chief,
        occupation: 'Chef de mission',
      });
    }
  });

  const comptaEmail: EmailOption = {
    email: 'compta-clients@polyconseil.fr',
    occupation: 'Compta',
  };

  return concat([directorEmail, comptaEmail], chiefsEmails);
};

export const billEmailLanguageOptions: LanguageOption[] = [
  { value: LanguageRegionEnum.fr_FR, label: 'Français (France)' },
  {
    value: LanguageRegionEnum.en_GB,
    label: 'Anglais (Royaume-Uni)',
  },
];

export const templateParts: TemplateParts = {
  fr_FR: {
    billLabel: 'Facture Polyconseil N°',
    greeting: 'Madame, Monsieur',
    body1:
      "Veuillez trouver ci-joint la facturation de notre prestation, d'un montant de",
    body2: "avec une date d'échéance au",
    thanks: 'En vous remerciant par avance',
    closing: 'Bonne journée',
    vatSuffix: ' TTC',
    to: 'à',
  },
  en_GB: {
    billLabel: 'Polyconseil Invoice No. ',
    greeting: 'Dear Sir/Madam',
    body1: 'Please find attached the invoice for our services, amounting to',
    body2: 'due on the',
    thanks: 'Thank you in advance',
    closing: 'Best regards',
    vatSuffix: ' VAT included',
    to: 'to',
  },
};

export const getEmailTemplate = (
  formatedAmount: string,
  isClientFrench: boolean, // no VAT for non-French clients
  dueDate: string,
  billNumber?: string,
  emailLang: string = LanguageRegionEnum.fr_FR
) => {
  const { greeting, body1, body2, vatSuffix, thanks, closing } =
    templateParts[emailLang];
  const formattedDueDate =
    emailLang === LanguageRegionEnum.fr_FR
      ? formatDateUStoFR(dueDate)
      : dueDate;
  return `${greeting},
  
${body1} ${formatedAmount}${
    isClientFrench ? vatSuffix : ''
  }, ${body2} ${formattedDueDate}.

${thanks}.

${closing},

Facture N°${billNumber}.pdf`;
};

export const getEmailSubject = (
  billNumber?: string,
  client?: string,
  emailLang: string = LanguageRegionEnum.fr_FR
) => {
  const { billLabel, to } = templateParts[emailLang];
  return `${billLabel}${billNumber} ${to} ${client}`;
};
