import { TableBody } from '@mui/material';
import { isTrimesterStart } from 'components/commons/Tables/Header/ArrowsUpdateTrimesterTableHeader';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import PolyDataGridEmptyBody from 'components/MUIOverload/PolyDataGrid/PolyDataGridEmptyBody';
import ProfitabilityEmployeeRow, {
  getEffectiveEmployeeContractType,
  hasActivityMonitoring,
  isAssigned,
} from 'components/Reporting/Profitability/MissionView/ProfitabilityTableBody/Rows/ProfitabilityEmployeeRow';
import {
  ActivityNode,
  ActivityProfitNode,
  AllEmployeesWithWorkInfosQueryHookResult,
  EmployeeHistoryNode,
  EmployeeNode,
  EmployeeReportingParameterNode,
  EmployeesEmployeeContractTypeChoices,
  EmployeeWorkInfosNode,
  ReportingEmployeeReportingParameterGradeChoices,
  useActivityWithProfitsQuery,
  useAllEmployeesWithWorkInfosQuery,
} from 'generated/graphql';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import { EMPLOYEE_HISTORIZATION_START } from 'poly-constants';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'store';

import { DEFAULT_OCCUPATION } from '../../utils';
import { isEmployeeMatchingForReportingGrade } from '../../utils';
import ProfitabilityMissionTotalRow from './ProfitabilityMissionTotalRow';

export interface EmployeeType extends EmployeeNode {
  employeeReportingParameters: EmployeeReportingParameterNode[];
  employeeHistory: EmployeeHistoryNode[] | undefined;
  monthToEmployeeReportingParameter: Map<
    number,
    EmployeeReportingParameterNode[]
  >;
}

export interface monthTotalTimeAndRevenue {
  month: moment.Moment;
  totalTime: number | undefined;
  totalRevenue: number | undefined;
}

function getEmployeeHistory(
  employees: EmployeeWorkInfosNode[],
  employeeId: String
): EmployeeHistoryNode[] | undefined {
  const employeeIdToHistory: Map<String, EmployeeHistoryNode[] | undefined> =
    new Map();
  _.forEach(employees, (employee) => {
    employeeIdToHistory.set(employee.id, employee.history);
  });
  const employeeHistory = employeeIdToHistory.get(employeeId);
  return employeeHistory;
}

function mapEmployeeToEmployeeWithEffectiveContractType(
  employee: EmployeeType,
  monthToDisplay: Moment
) {
  const effectiveContractType = getEffectiveEmployeeContractType(
    employee,
    monthToDisplay
  );
  employee.contractType = effectiveContractType
    ? effectiveContractType
    : employee.contractType;
  return employee;
}

/*
 ** Returns the latest history before the start of the
 ** given month
 */
function getLastHistoryForDate(
  month: moment.Moment,
  history: EmployeeHistoryNode[]
) {
  if (!history) {
    return undefined;
  }

  const sortedHistory = _.sortBy(history, 'historyDate');
  if (
    sortedHistory.length === 1 ||
    month.isSameOrBefore(EMPLOYEE_HISTORIZATION_START)
  ) {
    return sortedHistory[0];
  }
  let currentLastHistory: EmployeeHistoryNode | undefined = sortedHistory[0];

  for (const currentHistory of sortedHistory) {
    if (currentHistory.historyDate > month.format('YYYY-MM-01')) {
      return currentLastHistory;
    }
    currentLastHistory = currentHistory;
  }

  return currentLastHistory;
}

/*
 ** Returns the EmployeeReportingParameterNode matching the employee
 ** contract at the time selected
 */
function computeEmployeeReportingParameterForMonth(
  month: moment.Moment,
  reportingParameters: EmployeeReportingParameterNode[],
  employeeHistory: EmployeeHistoryNode[]
) {
  const lastHistoryForMonth = getLastHistoryForDate(month, employeeHistory);
  if (!lastHistoryForMonth) {
    return [];
  }
  for (const param of reportingParameters) {
    if (
      lastHistoryForMonth.contractType &&
      ['intern pre job', 'intern', 'student_apprentice'].includes(
        lastHistoryForMonth.contractType
      )
    ) {
      if (
        param.grade.toUpperCase() ===
        EmployeesEmployeeContractTypeChoices.Intern
      ) {
        return [param];
      }
    } else if (
      param.grade.toUpperCase() === lastHistoryForMonth.grade?.toUpperCase()
    ) {
      return [param];
    }
  }
  return [];
}

function transformEmployeesWithWorkInfos(
  queryResult: AllEmployeesWithWorkInfosQueryHookResult,
  displayedMonths: moment.Moment[]
): EmployeeType[] {
  const parameters = (queryResult.data?.allEmployeeReportingParameters ||
    []) as EmployeeReportingParameterNode[];
  const employeeWorkInfos = (queryResult.data
    ?.allEmployeesWorkInformationForActivity || []) as EmployeeWorkInfosNode[];
  const defaultADRs = _.filter(parameters, (parameter) => {
    return parameter.occupation === DEFAULT_OCCUPATION;
  });
  return _.map(employeeWorkInfos, (employee) => {
    const monthToReportingParameters = new Map<
      number,
      EmployeeReportingParameterNode[]
    >();

    function computeEmployeeReportingParameter(month: moment.Moment) {
      const employeeHistory = getEmployeeHistory(
        employeeWorkInfos,
        employee.id
      );
      return computeEmployeeReportingParameterForMonth(
        month,
        parameters,
        employeeHistory || []
      );
    }
    displayedMonths.forEach((month) => {
      const employeeReportingParameter =
        computeEmployeeReportingParameter(month);
      monthToReportingParameters.set(month.month(), employeeReportingParameter);
    });
    const employeeReportingParameterForFirstMonth =
      computeEmployeeReportingParameter(displayedMonths[0]);

    const employeeHistory = getEmployeeHistory(employeeWorkInfos, employee.id);
    if (employeeReportingParameterForFirstMonth.length > 0) {
      return {
        ...(employee as unknown as EmployeeNode),
        employeeReportingParameters: employeeReportingParameterForFirstMonth,
        employeeHistory: employeeHistory,
        monthToEmployeeReportingParameter: monthToReportingParameters,
      };
    }
    const defaultAdrMatchingGrade = _.filter(defaultADRs, (adr) => {
      return isEmployeeMatchingForReportingGrade(
        employee as unknown as EmployeeNode,
        adr.grade
      );
    });
    const monthToDefaultAdrMatchingGrade = new Map<
      number,
      EmployeeReportingParameterNode[]
    >();
    displayedMonths.forEach((month) => {
      const employeeType = {
        ...employee,
        employeeReportingParameter: [],
        employeeHistory: employeeHistory,
      } as unknown as EmployeeType;
      const employeeWithEffectiveContractType =
        mapEmployeeToEmployeeWithEffectiveContractType(employeeType, month);
      const defaultAdrMatchingGradeForEffectiveContractType = _.filter(
        defaultADRs,
        (adr) => {
          return isEmployeeMatchingForReportingGrade(
            employeeWithEffectiveContractType as unknown as EmployeeNode,
            adr.grade
          );
        }
      );
      monthToDefaultAdrMatchingGrade.set(
        month.month(),
        defaultAdrMatchingGradeForEffectiveContractType
      );
    });

    return {
      ...(employee as unknown as EmployeeNode),
      employeeReportingParameters: defaultAdrMatchingGrade,
      employeeHistory: employeeHistory,
      monthToEmployeeReportingParameter: monthToDefaultAdrMatchingGrade,
    };
  });
}

interface ProfitabilityMissionViewTableBodyProps {
  isTrimesterView: boolean;
  isExcludingIntern: boolean;
}

export default function ProfitabilityMissionViewTableBody({
  isTrimesterView,
  isExcludingIntern,
}: ProfitabilityMissionViewTableBodyProps) {
  const { currentYear } = useTableHeaderContext();
  const { enqueueSnackbar } = useSnackbar();
  const activityId = useSelector((state) => state.activity.currentMission.id);
  const { displayedMonths } = useTableHeaderContext();
  const monthsToDisplay = isTrimesterView
    ? _.filter(displayedMonths, (month) => isTrimesterStart(month))
    : displayedMonths;
  const queryResult = useAllEmployeesWithWorkInfosQuery({
    variables: {
      activityId: activityId || '',
      year: currentYear.year(),
    },
    onError: (error) => {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    },
  });
  const employeesWithWorkInfos = useMemo(() => {
    return transformEmployeesWithWorkInfos(queryResult, displayedMonths);
  }, [queryResult, displayedMonths]);

  const [activityProfits, setActivityProfits] = useState<ActivityProfitNode[]>(
    []
  );
  const [activity, setActivity] = useState<ActivityNode>({} as ActivityNode);

  useActivityWithProfitsQuery({
    variables: {
      activityId: activityId || '',
      year: currentYear.year(),
    },
    onCompleted: (data) => {
      if (data?.activityWithProfits) {
        setActivityProfits(
          data.activityWithProfits?.activityProfits as ActivityProfitNode[]
        );
        setActivity(data.activityWithProfits as ActivityNode);
      }
    },
    onError: (error) => {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    },
  });

  const displayTable =
    !!employeesWithWorkInfos.length ||
    (activityProfits.length &&
      _.find(activityProfits, (activityProfit) =>
        currentYear.isSame(activityProfit.month, 'year')
      ));

  const totalTimeAndRevenues = _.map(monthsToDisplay, (month) => {
    if (!isTrimesterView || isTrimesterStart(month)) {
      const totalRevenue = isTrimesterView
        ? getProfitByTrimester(activityProfits, month)
        : getProfitByMonth(activityProfits, month);
      const totalTime = getTotalTime(activityProfits, month, isTrimesterView);
      return { month, totalTime, totalRevenue } as monthTotalTimeAndRevenue;
    }
  }) as monthTotalTimeAndRevenue[];

  return (
    <>
      {displayTable !== undefined ? (
        <TableBody>
          {_.map(employeesWithWorkInfos, (employee, index) => {
            const isEmployeeAssignedOnMonthsToDisplay = _.some(
              monthsToDisplay,
              (month) => {
                return (
                  isAssigned(month, isTrimesterView, employee.assignments) ||
                  hasActivityMonitoring(
                    month,
                    isTrimesterView,
                    employee.activityMonitoring
                  )
                );
              }
            );
            return (
              isEmployeeAssignedOnMonthsToDisplay &&
              (!isExcludingIntern ||
                !isEmployeeMatchingForReportingGradeForPeriod(
                  employee,
                  monthsToDisplay
                )) && (
                <ProfitabilityEmployeeRow
                  key={`employee-${index}`}
                  employee={employee}
                  isTrimesterView={isTrimesterView}
                  totalTimeAndRevenues={totalTimeAndRevenues}
                  activity={activity}
                  isExcludingIntern={isExcludingIntern}
                />
              )
            );
          })}
          <ProfitabilityMissionTotalRow
            key={`total-activity-${activity.id}`}
            employees={employeesWithWorkInfos}
            isTrimesterView={isTrimesterView}
            totalTimeAndRevenues={totalTimeAndRevenues}
            sx={{ fontSize: '0.8rem' }}
          />
        </TableBody>
      ) : (
        <PolyDataGridEmptyBody columnsNumber={10} />
      )}
    </>
  );
}

function isEmployeeMatchingForReportingGradeForPeriod(
  employee: EmployeeType,
  monthsToDisplay: Moment[]
): boolean {
  const employeeWithEffectiveContractType =
    mapEmployeeToEmployeeWithEffectiveContractType(
      employee,
      monthsToDisplay[2]
    );
  return isEmployeeMatchingForReportingGrade(
    employeeWithEffectiveContractType,
    ReportingEmployeeReportingParameterGradeChoices.Intern
  );
}

export function getProfitByTrimester(
  profits: ActivityProfitNode[],
  month: moment.Moment
) {
  return _.find(profits, (profit) => {
    return month.isSame(moment(profit.trimester), 'month');
  })?.totalRevenue;
}

export function getProfitByMonth(
  profits: ActivityProfitNode[],
  month: moment.Moment
) {
  return _.find(profits, (profit) => {
    return month.isSame(moment(profit.month), 'month');
  })?.totalRevenue;
}

export function getTotalTime(
  profits: ActivityProfitNode[],
  month: moment.Moment,
  isTrimesterView: boolean
) {
  return _.find(profits, (profit) => {
    return month.isSame(
      moment(isTrimesterView ? profit.trimester : profit.month),
      'month'
    );
  })?.totalWorkedDays;
}
