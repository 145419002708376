import {
  Icon,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { BillingBillSendingMethodChoices } from 'generated/graphql';
import _ from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';

import { getSendingMethodIcon, getSendingMethodWording } from './utils';

interface SendBillMenuProps {
  anchorEl: HTMLElement | null;
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
  setOpenBillEmail: Dispatch<SetStateAction<boolean>>;
  setOpenControlSend: Dispatch<SetStateAction<boolean>>;
  setSelectedSendingMethod: React.Dispatch<
    React.SetStateAction<BillingBillSendingMethodChoices | undefined>
  >;
}

export default function SendBillMenu(props: SendBillMenuProps) {
  const sendBillMenuOpened = Boolean(props.anchorEl);

  const handleMenuItemClick = (
    value: BillingBillSendingMethodChoices | undefined
  ) => {
    if (value === BillingBillSendingMethodChoices.Email) {
      props.setOpenBillEmail(true);
    } else {
      props.setOpenControlSend(true);
      props.setSelectedSendingMethod(value);
    }
    handleCloseSendBillMenu();
  };

  const handleCloseSendBillMenu = () => {
    props.setAnchorEl(null);
  };

  const menuOptions = [
    {
      value: BillingBillSendingMethodChoices.Email,
      icon: getSendingMethodIcon(BillingBillSendingMethodChoices.Email),
      label: getSendingMethodWording(BillingBillSendingMethodChoices.Email),
    },
    {
      value: BillingBillSendingMethodChoices.Mail,
      icon: getSendingMethodIcon(BillingBillSendingMethodChoices.Mail),
      label: getSendingMethodWording(BillingBillSendingMethodChoices.Mail),
    },
    {
      value: BillingBillSendingMethodChoices.Platform,
      icon: getSendingMethodIcon(BillingBillSendingMethodChoices.Platform),
      label: getSendingMethodWording(BillingBillSendingMethodChoices.Platform),
    },
  ];

  return (
    <Menu
      id="send-bill-menu"
      anchorEl={props.anchorEl}
      open={sendBillMenuOpened}
      onClose={handleCloseSendBillMenu}
      MenuListProps={{
        'aria-labelledby': 'send-bill-button',
      }}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: 2,
        },
      }}
    >
      {_.map(menuOptions, (option) => {
        return (
          <MenuItem
            key={`menu-item-${option.value}`}
            onClick={() => handleMenuItemClick(option.value)}
          >
            <ListItemIcon>
              <Icon color="primary">{option.icon}</Icon>
            </ListItemIcon>
            <ListItemText>{option.label}</ListItemText>
          </MenuItem>
        );
      })}
    </Menu>
  );
}
