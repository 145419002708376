import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

export interface FilterContextProps {
  children?: React.ReactNode;
}

export interface FilterContextInterface {
  children?: React.ReactNode;
  filteredID?: string;
  filterNotValidatedRevenue?: boolean;
  setFilteredID: (p?: string) => void;
  setFilterNotValidatedRevenue: (p: boolean) => void;
}

export const FilterContext = createContext<FilterContextInterface>({
  filteredID: undefined,
  filterNotValidatedRevenue: undefined,
  setFilterNotValidatedRevenue: () => {
    throw new Error('function setFilterNotValidatedRevenue not set');
  },
  setFilteredID: () => {
    throw new Error('function setFilteredID not set');
  },
});

export const useFilterContext = () => {
  const filterContext = useContext(FilterContext);
  return filterContext;
};

const FilterContextProvider = (
  properties: PropsWithChildren<FilterContextProps>
) => {
  const [filteredID, setFilteredID] = useState<string | undefined>(undefined);
  const [filterNotValidatedRevenue, setFilterNotValidatedRevenue] =
    useState<boolean>(false);
  return (
    <FilterContext.Provider
      value={{
        filteredID,
        setFilteredID,
        filterNotValidatedRevenue,
        setFilterNotValidatedRevenue,
      }}
    >
      {properties.children}
    </FilterContext.Provider>
  );
};

export default FilterContextProvider;
