import PolyExportButton from 'components/commons/PolyExportButton';
import BilledRevenueExportModal from 'pages/RevenuePage/BilledRevenuePage/BilledRevenueExportModal';
import React, { useState } from 'react';

const BilledRevenueExportButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <React.Fragment>
      <PolyExportButton onClick={toggleModal}>
        Exporter ce tableau
      </PolyExportButton>
      <BilledRevenueExportModal
        isOpen={isOpen}
        handleClose={toggleModal}
        title={"Exporter le chiffre d'affaires facturé"}
        body={
          "Téléchargez ici le chiffre d'affaires facturé en choisissant la période qui vous intéresse"
        }
      />
    </React.Fragment>
  );
};

export default BilledRevenueExportButton;
