import { Skeleton, Stack } from '@mui/material';
import _ from 'lodash';
import React from 'react';

import AdrGraphCurveMenu from './AdrGraphCurveMenu';
import { Curve, useAdrGraphContext } from './context/AdrGraphContextProvider';

export default function AdrGraphHeader() {
  const { displayedCurves, sectors } = useAdrGraphContext();

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'center'}
      spacing={2}
      flexWrap="wrap"
    >
      {displayedCurves.length > 0 ? (
        <>
          {_.map(displayedCurves, (curve: Curve) => {
            return (
              <AdrGraphCurveMenu
                key={curve.id}
                curve={curve}
                sectors={sectors}
              />
            );
          })}
        </>
      ) : (
        <Skeleton variant="rounded" width={180} height={26} />
      )}
    </Stack>
  );
}
