import { TableCell, TableHead, TableRow, Typography } from '@mui/material';
import commonStyles from 'components/commons/css/commons.module.scss';
import { BorderedTableCell } from 'components/commons/Tables/FlatTable';
import ArrowsUpdateMonthsTableHeader from 'components/commons/Tables/Header/ArrowsUpdateMonthsTableHeader';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import { useUserInfo } from 'components/User/UserProvider';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import React from 'react';

import { enableMonthDecrement, enableMonthIncrement } from '../utils';
import { useProfitabilityTableContext } from './ProfitabilityTableContextProvider';
import ProfitabilityTableHeaderColumnsNames from './ProfitabilityTableHeaderColumnsNames';

function oneColumnSizeCells(key: string) {
  return (
    <React.Fragment key={key}>
      <TableCell sx={{ width: '7%' }} />
      <TableCell sx={{ width: '7%' }} />
      <TableCell sx={{ width: '12%' }} />
      <TableCell sx={{ width: '12%' }} />
      <TableCell sx={{ width: '12%' }} />
    </React.Fragment>
  );
}
export interface ProfitabilityTableHeaderInterface {
  currentYear: Moment;
  setCurrentYear: (newYear: Moment) => void;
  setIsYearSubtracted: (changeYear: boolean) => void;
  setIsYearAdded: (changeYear: boolean) => void;
}

export default function ProfitabilityTableHeader({
  currentYear,
  setCurrentYear,
  setIsYearSubtracted,
  setIsYearAdded,
}: ProfitabilityTableHeaderInterface) {
  const { displayedMonths } = useTableHeaderContext();
  const { isControl, isComex, isSuperuser } = useUserInfo();
  const { isShowingMarginRate, toggleShowMarginRate, isExcludingIntern } =
    useProfitabilityTableContext();

  const yearToDateCurrentMonth = displayedMonths[displayedMonths.length - 1];
  return (
    <TableHead className={commonStyles.stickyHeader}>
      <TableRow>
        <TableCell sx={{ width: 200 }} />
        {_.map(displayedMonths, (month) => {
          return oneColumnSizeCells(`header-${month.format('MMMM')}`);
        })}
        {oneColumnSizeCells(`header-year-to-date`)}
      </TableRow>
      <TableRow>
        <BorderedTableCell />
        {_.map(displayedMonths, (month) => {
          return (
            <BorderedTableCell
              sx={{ textTransform: 'capitalize', p: 0 }}
              key={`header-month-name-${month.format('MMMM')}`}
              colSpan={5}
            >
              <ArrowsUpdateMonthsTableHeader
                month={month}
                currentYear={currentYear}
                setCurrentYear={setCurrentYear}
                setIsYearSubtracted={setIsYearSubtracted}
                setIsYearAdded={setIsYearAdded}
                enableMonthIncrement={enableMonthIncrement(month)}
                enableMonthDecrement={enableMonthDecrement(month)}
                isGlobalView={true}
              >
                <Typography
                  fontWeight={
                    month.isSame(moment(), 'month') ? 'bold' : 'normal'
                  }
                >
                  {month.format('MMMM')}
                </Typography>
              </ArrowsUpdateMonthsTableHeader>
            </BorderedTableCell>
          );
        })}
        <BorderedTableCell
          sx={{ p: 0 }}
          key={`header-year-to-date-month-name`}
          colSpan={5}
        >
          <Typography fontWeight={'bold'} sx={{ textAlign: 'center' }}>
            Rentabilité cumulée à{' '}
            {yearToDateCurrentMonth.format('MMM').charAt(0).toUpperCase() +
              yearToDateCurrentMonth.format('MMM').slice(1)}
          </Typography>
        </BorderedTableCell>
      </TableRow>
      <TableRow sx={{ tableLayout: 'fixed', width: '100%' }}>
        <BorderedTableCell>
          {isControl || isComex || isSuperuser ? 'Clients' : 'Missions'}
        </BorderedTableCell>
        {_.map(displayedMonths, (month) => (
          <ProfitabilityTableHeaderColumnsNames
            key={`header-column-name-${month.format('MMMM')}`}
            month={month}
            isShowingMarginRate={isShowingMarginRate}
            toggleShowMarginRate={toggleShowMarginRate}
            isExcludingIntern={isExcludingIntern}
          />
        ))}
        <ProfitabilityTableHeaderColumnsNames
          key={`header-year-to-date-column-name`}
          month={yearToDateCurrentMonth}
          isShowingMarginRate={isShowingMarginRate}
          toggleShowMarginRate={toggleShowMarginRate}
          isExcludingIntern={isExcludingIntern}
          yearToDate
        />
      </TableRow>
    </TableHead>
  );
}
