import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';

interface PhoneNumberFormProps {
  isEdit: boolean;
  setEdit: (value: boolean) => void;
}

const PhoneNumberForm = ({ isEdit, setEdit }: PhoneNumberFormProps) => {
  const form = useFormContext();

  return (
    <Stack direction={'row'} alignItems={'center'} height={20}>
      <Typography variant="bodyBold">
        {!isEdit && _.isEmpty(form.watch('phoneNumber')) ? (
          'N/A'
        ) : (
          <Stack sx={{ maxWidth: isEdit ? 116 : '100%' }}>
            <Controller
              render={(controllerProperties) => (
                <NumberFormat
                  type={'text'}
                  format={'+## # ## ## ## ##'}
                  mask={'_'}
                  placeholder={'+33 0 00 00 00 00'}
                  displayType={isEdit ? 'input' : 'text'}
                  {...controllerProperties}
                />
              )}
              name={'phoneNumber'}
              control={form.control}
            />
          </Stack>
        )}
      </Typography>
      {isEdit ? (
        <IconButton
          size="small"
          color="primary"
          type={'submit'}
          data-testid={'collapseButton'}
        >
          <CheckIcon sx={{ height: '18px' }} />
        </IconButton>
      ) : (
        <IconButton
          size="small"
          color="primary"
          onMouseUpCapture={() => {
            setEdit(true);
          }}
          data-testid={'collapseButton'}
        >
          <EditIcon sx={{ height: '18px' }} />
        </IconButton>
      )}
    </Stack>
  );
};

export default PhoneNumberForm;
