import PageWrapper from 'components/commons/PageWrapper';
import HomeBody from 'components/HomePage/HomeBody';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import React from 'react';

import HomeContent from './HomeContent';

const HomePage = () => {
  const EVENTS_FEATURE_FLAG = useEnvironmentVariable('events');

  return (
    <PageWrapper large>
      <HomeBody title={EVENTS_FEATURE_FLAG ? '' : 'BIENVENUE SUR PONGO !'}>
        <HomeContent />
      </HomeBody>
    </PageWrapper>
  );
};

export default HomePage;
