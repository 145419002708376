import clsx from 'clsx';
import PolyGrid from 'components/MUIOverload/PolyGrid';
import React, { HTMLAttributes } from 'react';

import styles from './styles/GridSubTitle.module.scss';

interface GridSubTitleProps {
  customspacingtop?: number;
  customspacingside?: number;
}

const GridSubTitle = (
  properties: HTMLAttributes<GridSubTitleProps> & GridSubTitleProps
) => {
  return (
    <PolyGrid
      item
      xs={12}
      customspacingtop={properties.customspacingtop}
      customspacingside={properties.customspacingside}
    >
      <h1 className={clsx(styles.title, properties.className)}>
        {properties.children}
      </h1>
    </PolyGrid>
  );
};

GridSubTitle.defaultProps = {
  customspacingtop: 30,
  customspacingside: 15,
};

export default GridSubTitle;
