import _ from 'lodash';

export enum WorkingDayMoment {
  AM = 0,
  PM = 1,
}

export interface WorkingDayState {
  values: boolean[];
  setValues: React.Dispatch<React.SetStateAction<boolean[]>>;
}

export interface WorkingDay {
  values: boolean[];
}

export const getTotalWorkingDays = (
  workingDays: WorkingDayState[] | WorkingDay[]
) => {
  let totalWorkingDays = 0;
  _.forEach(workingDays, (workingDay) => {
    _.forEach(workingDay.values, (isWorking) => {
      if (isWorking) {
        totalWorkingDays += 0.5;
      }
    });
  });
  return totalWorkingDays;
};
