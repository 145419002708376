import { Button, TableCell, Typography } from '@mui/material';
import { EmployeeReportingParameterNode } from 'generated/graphql';
import React, { useState } from 'react';

import OccupationAdrModal from '../OccupationAdrModal';

interface OccupationAdrTableCellEditableProps {
  erp: EmployeeReportingParameterNode;
  isEdit: boolean;
}

export default function OccupationAdrTableCellEditable({
  erp,
  isEdit,
}: OccupationAdrTableCellEditableProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [currentAdr, setCurrentAdr] = useState<number>(erp.adr);
  return (
    <>
      <TableCell align="center">
        {isEdit ? (
          <Button
            size="small"
            id={`${erp.occupation}-${erp.grade}`}
            variant="outlined"
            sx={{ color: 'text.secondary' }}
            onClick={() => setOpen(true)}
          >
            {currentAdr}
            {' €'}
          </Button>
        ) : (
          <Typography>{currentAdr} €</Typography>
        )}
      </TableCell>
      <OccupationAdrModal
        open={open}
        setOpen={setOpen}
        data={erp}
        setCurrentAdr={setCurrentAdr}
        currentAdr={currentAdr}
      />
    </>
  );
}
