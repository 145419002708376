import AddCircleIcon from '@mui/icons-material/AddCircle';
import WorkIcon from '@mui/icons-material/Work';
import IconButton from '@mui/material/IconButton';
import { BorderedTableCell } from 'components/commons/Tables/FlatTable';
import _ from 'lodash';
import React from 'react';

import { WorkingDayMoment, WorkingDayState } from '../../utils';

interface UserWorkingDaysProps {
  workingDaysStates: WorkingDayState[];
  workingDayMoment: WorkingDayMoment;
}

function toggleWorkingDayValue(
  workingDayMoment: WorkingDayMoment,
  workingDayState: WorkingDayState
) {
  switch (workingDayMoment) {
    case WorkingDayMoment.AM:
      workingDayState.setValues([
        !workingDayState.values[WorkingDayMoment.AM],
        workingDayState.values[WorkingDayMoment.PM],
      ]);
      break;
    case WorkingDayMoment.PM:
      workingDayState.setValues([
        workingDayState.values[WorkingDayMoment.AM],
        !workingDayState.values[WorkingDayMoment.PM],
      ]);
      break;
    default:
      break;
  }
}

export default function UserWorkingDays({
  workingDaysStates,
  workingDayMoment,
}: UserWorkingDaysProps) {
  return (
    <>
      {_.map(workingDaysStates, (workingDayState) => {
        return (
          <BorderedTableCell align={'center'}>
            <IconButton
              onClick={() =>
                toggleWorkingDayValue(workingDayMoment, workingDayState)
              }
              size="large"
            >
              {workingDayState.values[workingDayMoment] ? (
                <WorkIcon sx={{ fontSize: 45 }} color={'primary'} />
              ) : (
                <AddCircleIcon sx={{ fontSize: 45 }} />
              )}
            </IconButton>
          </BorderedTableCell>
        );
      })}
    </>
  );
}
