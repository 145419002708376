import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import {
  Icon,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import PongoButton from 'components/MUIOverload/PongoButton';
import {
  ActivitiesActivityBillingTypeChoices,
  ActivityNode,
  useCftActivityToExternalActivityMutation,
} from 'generated/graphql';
import { enqueueSnackbar } from 'notistack';
import React, { MouseEvent, useState } from 'react';

interface ExternalActivityMenuProps {
  activity: ActivityNode;
  refetchActivityInfo: () => void;
}

const menuOptions = [
  {
    value: ActivitiesActivityBillingTypeChoices.Package,
    icon: <AssignmentIcon />,
    label: 'Mission Forfait',
  },
  {
    value: ActivitiesActivityBillingTypeChoices.Tm,
    icon: <AssignmentIndIcon />,
    label: 'Mission Régie',
  },
];

export default function CftToExternalActivityHandler({
  activity,
  refetchActivityInfo,
}: ExternalActivityMenuProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const [cftActivityToExternalActivityMutation] =
    useCftActivityToExternalActivityMutation({
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
      onCompleted: (data) => {
        const activity = data.cftActivityToExternalActivity
          ?.activity as ActivityNode;

        enqueueSnackbar(
          `La mission « ${activity.name} » a bien été mise à jour`,
          {
            variant: 'success',
          }
        );
        refetchActivityInfo();
      },
    });

  const toggleBilledActivityMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = async (
    value: ActivitiesActivityBillingTypeChoices
  ) => {
    await cftActivityToExternalActivityMutation({
      variables: {
        activityId: activity.id,
        billingType: value,
      },
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <PongoButton variant="contained" onClick={toggleBilledActivityMenu}>
        Passer en facturable
      </PongoButton>
      <Menu
        id="billed-activity-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: 2,
            width: '197.467px',
          },
        }}
      >
        {menuOptions.map((option) => (
          <MenuItem
            key={`menu-item-${option.value}`}
            onClick={() => handleMenuItemClick(option.value)}
          >
            <ListItemIcon>
              <Icon>{option.icon}</Icon>
            </ListItemIcon>
            <ListItemText>{option.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
