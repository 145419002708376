import { Typography } from '@mui/material';
import DownloadFile from 'components/commons/FileTransfer/DownloadFile/DownloadFile';
import DownloadAttachments from 'components/MissionFollowUp/DownloadFilesModal/DownloadAttachments';
import PoAddAttachments from 'components/MissionFollowUp/DownloadFilesModal/PoAddAttachments';
import styles from 'components/MissionFollowUp/DownloadFilesModal/styles/DownloadFilesModal.module.scss';
import PolyModal from 'components/MUIOverload/PolyDialog/PolyModal';
import { AttachmentInput } from 'generated/graphql';
import _ from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'store';
import { getFileNameFromPath } from 'utils';

interface DownloadFilesModalProps {
  open: boolean;
  onClose: () => void;
}

export default function DownloadPoFilesModal(
  properties: DownloadFilesModalProps
) {
  const currentPurchaseOrders = useSelector(
    (state) => state.purchaseOrder.currentPurchaseOrder
  );
  const [attachmentsToDelete, setAttachmentsToDelete] = useState<
    AttachmentInput[]
  >([]);

  async function deleteFile(idToDelete: string) {
    setAttachmentsToDelete([...attachmentsToDelete, { id: idToDelete }]);
  }

  return (
    <PolyModal
      maxWidth={'md'}
      open={properties.open}
      onClose={properties.onClose}
      disableEnforceFocus={true}
      polyDialogTitle={'Pièces jointes'}
      noSecondaryButton
    >
      <>
        <Typography sx={{ fontWeight: 'medium', fontSize: 18, py: 2 }}>
          Bon de commande
        </Typography>
        <div className={styles.element}>
          <div className={styles.textContainer}>
            {getFileNameFromPath(currentPurchaseOrders.document?.filename)}
          </div>
          <DownloadFile
            filename={currentPurchaseOrders.document?.filename || ''}
            title={'Télécharger'}
          />
        </div>
        <Typography sx={{ fontWeight: 'medium', fontSize: 18, py: 2 }}>
          Fichiers annexes
        </Typography>
        <DownloadAttachments
          attachments={_.filter(
            currentPurchaseOrders.attachments,
            (a) =>
              !_.some(
                attachmentsToDelete,
                (attachment) => attachment.id === a.id
              )
          )}
          onDelete={deleteFile}
        />
        <PoAddAttachments
          validCallback={properties.onClose}
          attachmentsToDelete={attachmentsToDelete}
        />
      </>
    </PolyModal>
  );
}
