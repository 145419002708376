import { Chip, Tooltip } from '@mui/material';
import { useTheme } from '@mui/styles';
import React from 'react';

import { WorkingDayMoment } from './utils';

interface ContractualDayChipProps {
  label: string;
  workingDay: boolean[];
}

const ContractualDayChip = ({ label, workingDay }: ContractualDayChipProps) => {
  const theme = useTheme();

  let tooltipTitle = 'Absent(e)';
  if (workingDay[WorkingDayMoment.AM] && workingDay[WorkingDayMoment.PM]) {
    tooltipTitle = 'Journée entière';
  } else if (workingDay[WorkingDayMoment.AM]) {
    tooltipTitle = 'Matin';
  } else if (workingDay[WorkingDayMoment.PM]) {
    tooltipTitle = 'Après-midi';
  }

  let customStyle = {};
  if (workingDay[WorkingDayMoment.AM] !== workingDay[WorkingDayMoment.PM]) {
    customStyle = {
      '&.MuiChip-root': {
        position: 'relative',
        border: 0,
      },
      '&.MuiChip-root::before': {
        background: `linear-gradient(to ${
          workingDay[WorkingDayMoment.AM] ? 'right' : 'left'
        }, ${theme.palette.info.main} 50%, ${
          theme.palette.standardGrey.dark
        } 50%)`,
        content: '""',
        position: 'absolute',
        inset: 0,
        'border-radius': '4px',
        padding: '2px',
        mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
        'mask-composite': 'xor',
      },
    };
  }

  return (
    <Tooltip key={label} title={tooltipTitle}>
      <Chip
        label={label}
        color={
          !workingDay[WorkingDayMoment.AM] && !workingDay[WorkingDayMoment.PM]
            ? 'default'
            : 'info'
        }
        variant={
          workingDay[WorkingDayMoment.AM] !== workingDay[WorkingDayMoment.PM]
            ? 'outlined'
            : 'filled'
        }
        sx={{
          width: '54px',
          height: '24px',
          borderRadius: '4px',
          fontSize: '14px',
          ...customStyle,
        }}
      />
    </Tooltip>
  );
};

export default ContractualDayChip;
