import { Chip } from '@mui/material';
import DisplayMission from 'components/commons/DisplayMission';
import { ActivityNode } from 'generated/graphql';
import React from 'react';

interface MissionChipsProps {
  activity: ActivityNode;
  removeMission: () => void;
  setNewCurrentMission: () => void;
  removeCurrentMission: () => void;
  isCurrentMission: boolean;
  textMaxWidth?: number;
}

export default function MissionChips({
  activity,
  removeMission,
  setNewCurrentMission,
  removeCurrentMission,
  isCurrentMission,
  textMaxWidth = 200,
}: MissionChipsProps) {
  const backgroundColor = isCurrentMission ? 'white' : 'info.light';
  const variant = isCurrentMission ? 'outlined' : 'filled';

  const handleClick = () => {
    isCurrentMission ? removeCurrentMission() : setNewCurrentMission();
  };

  return (
    <Chip
      label={<DisplayMission mission={activity} textMaxWidth={textMaxWidth} />}
      size={'medium'}
      sx={{
        fontSize: '0.9rem',
        backgroundColor: backgroundColor,
        borderColor: 'info.main',
      }}
      onClick={handleClick}
      onDelete={removeMission}
      variant={variant}
    />
  );
}
