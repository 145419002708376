import { Stack, Typography } from '@mui/material';
import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import BillingTablePagination from 'components/MissionFollowUp/OverallListBill/BillingTablePagination';
import ExportBillsButton from 'components/MissionFollowUp/OverallListBill/ExportBillsButton';
import FilterStatus from 'components/MissionFollowUp/OverallListBill/Filters/FilterStatus';
import StatusGroupEditButtons from 'components/MissionFollowUp/OverallListBill/GroupEditButtons/StatusGroupEditButtons';
import ManagerBillsTable from 'components/MissionFollowUp/OverallListBill/ManagerBillsTable/ManagerBillsTable';
import BillsTableContextProvider from 'components/MissionFollowUp/OverallListBill/TablePaginationListBills/BillsTableContextProvider';
import BillsTabHeader from 'components/MissionFollowUp/TabHeaders/BillsTabHeader';
import { useUserInfo } from 'components/User/UserProvider';
import {
  ActivityNode,
  BillingPurchaseOrderCurrencyChoices,
  BillNode,
  EmployeesEmployeeGradeChoices,
  useAllBillsPaginatedQuery,
} from 'generated/graphql';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { totalFormat } from 'pages/ActivityPage/utils';
import InternalMissionPage from 'pages/ErrorPage/InternalMissionPage';
import styles from 'pages/MissionFollowUp/styles/MissionFollowUp.module.scss';
import { PageTitles } from 'poly-constants';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { setAllBills } from 'store/purchaseOrder';
import {
  checkIfAllMissionsAreInternal,
  isChiefOrDirectorOfActivity,
} from 'utils';

const ManagerBillsPage = () => {
  const { employee } = useUserInfo();
  const isComex = employee?.grade === EmployeesEmployeeGradeChoices.Comex;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const filterStatus = useSelector((state) => state.purchaseOrder.statusFilter);
  const currentMission = useSelector((state) => state.activity.currentMission);
  const selectedMissions = useSelector(
    (state) => state.activity.selectedMissions as ActivityNode[]
  );
  const missionIds = currentMission?.id
    ? [currentMission.id]
    : _.map(selectedMissions, (mission) => mission.id);
  const [nbrBills, setNbrBills] = useState(0);
  const [pageNbr, setPageNbr] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [filter, setFilter] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);

  const { loading } = useAllBillsPaginatedQuery({
    variables: {
      status: filterStatus,
      activityIds: missionIds,
      directorId: !missionIds && isComex ? employee?.id : undefined,
      orderBy: filter ? [filter] : ['-period_beginning', '-period_ending'],
      pageNbr: pageNbr,
      pageSize: pageSize,
    },
    onCompleted: (data) => {
      dispatch(setAllBills(data.allBillsPaginated?.bills as BillNode[]));
      setNbrBills(data.allBillsPaginated?.billsNumber || 0);
      setTotalAmount(data.allBillsPaginated?.totalAmount || 0);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const isActivityChiefOrDirector = selectedMissions.every((selectedMission) =>
    isChiefOrDirectorOfActivity(selectedMission, employee)
  );

  if (checkIfAllMissionsAreInternal(selectedMissions)) {
    return <InternalMissionPage page={PageTitles.billsPage} />;
  }

  return (
    <React.Fragment>
      <BillsTableContextProvider>
        <BillsTabHeader
          count={nbrBills}
          filterComponents={
            <Stack direction="column" spacing={1}>
              <FilterStatus />
              {!!filterStatus.length && (
                <Typography
                  fontWeight="bold"
                  textAlign="start"
                  sx={{
                    mt: 1,
                  }}
                >
                  Montant total (HT) :{' '}
                  {totalFormat(
                    totalAmount,
                    BillingPurchaseOrderCurrencyChoices.Eur
                  )}
                </Typography>
              )}
            </Stack>
          }
          actionComponents={
            <>
              {(!missionIds || isActivityChiefOrDirector) && (
                <StatusGroupEditButtons isInActionsMenu />
              )}
              <ExportBillsButton sx={{ ml: 1 }} />
            </>
          }
        />
      </BillsTableContextProvider>

      {!loading ? (
        <BillsTableContextProvider>
          <div className={styles.billTable} key={'billTable'}>
            <BillingTablePagination
              totalLen={nbrBills}
              pageNbr={pageNbr}
              setPageNbr={setPageNbr}
              pageSize={pageSize}
              setPageSize={setPageSize}
              paginationLabel="Factures par page"
              sx={{
                p: 1,
                pr: 0,
              }}
            />
            <ManagerBillsTable setFilter={setFilter} />
          </div>
        </BillsTableContextProvider>
      ) : (
        <div className={styles.loader} key={'loader'}>
          <LoadingPlaceholder />
        </div>
      )}
    </React.Fragment>
  );
};

export default ManagerBillsPage;
