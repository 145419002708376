import PolyFooter from 'components/MUIOverload/PolyFooter';
import PongoButton from 'components/MUIOverload/PongoButton';
import React from 'react';

import styles from '../styles/ActiveBody.module.scss';

export const finalStepTitle = 'Configuration terminée';
export const finalStepBody =
  'Félicitations ! La configuration de votre compte est terminée. Vous pouvez désormais accéder à Pongo.';
interface FinalStepProps {
  onHandleBack: () => void;
  onHandleNext: () => void;
}

const FinalStep = (properties: FinalStepProps) => {
  return (
    <div>
      <div className={styles.root}>
        <div className={styles.title}>{finalStepTitle}</div>
        <div className={styles.body}>{finalStepBody}</div>
      </div>
      <PolyFooter>
        <div className={styles.btnMargin}>
          <PongoButton
            buttonStyle="secondary"
            variant="contained"
            onClick={properties.onHandleBack}
          >
            Precedent
          </PongoButton>
          <PongoButton
            color="primary"
            variant="contained"
            onClick={properties.onHandleNext}
          >
            Acces Pongo
          </PongoButton>
        </div>
      </PolyFooter>
    </div>
  );
};

export default FinalStep;
