import PolyExportButton, {
  createCsvFile,
} from 'components/commons/PolyExportButton';
import { useFetchExportProvidersAdrsLazyQuery } from 'generated/graphql';
import { useSnackbar } from 'notistack';
import React from 'react';

interface ButtonProps {
  year: number;
}
const ProviderAdrExportButton = ({ year }: ButtonProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [exportQuery, { data }] = useFetchExportProvidersAdrsLazyQuery({
    onCompleted: () => {
      if (data && data.exportProvidersAdrs) {
        const filename = `TJM_couts_prestataires_${year.toString()}.csv`;
        createCsvFile(data.exportProvidersAdrs, filename);
      }
    },
    onError: () => {
      enqueueSnackbar('Erreur lors de la récupération des données', {
        variant: 'error',
      });
    },
  });

  const exportTable = () => {
    exportQuery({
      variables: {
        year: year,
      },
    });
  };

  return (
    <PolyExportButton onClick={exportTable} sx={{ mx: 1 }}>
      Exporter ce tableau
    </PolyExportButton>
  );
};

export default ProviderAdrExportButton;
