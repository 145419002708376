import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Divider, Drawer, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useUserInfo } from 'components/User/UserProvider';
import {
  ActivityNode,
  EmployeeNode,
  useActivityInformationQuery,
} from 'generated/graphql';
import _ from 'lodash';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setEditMissionModalVisibility } from 'store/activity';
import { formatDateUStoFR, isChiefOrDirectorOfActivity } from 'utils';

import AvatarNameAndOccupationStack from '../../../commons/AvatarNameAndOccupationStack/AvatarNameAndOccupationStack';
import DescriptionSection from './DescriptionSection';

interface DescriptionDrawerProps {
  drawerOpened: boolean;
  setDrawerOpened: Dispatch<SetStateAction<boolean>>;
  activityId: string;
}

export default function DescriptionDrawer({
  drawerOpened,
  setDrawerOpened,
  activityId,
}: DescriptionDrawerProps) {
  const { palette } = useTheme();
  const { employee } = useUserInfo();
  const history = useHistory();
  const dispatch = useDispatch();
  const [activityInfo, setActivityInfo] = useState<ActivityNode>(
    {} as ActivityNode
  );

  const { data } = useActivityInformationQuery({
    variables: {
      activityId: activityId,
    },
    onCompleted: () => {
      setActivityInfo(data?.activityInformation as ActivityNode);
    },
  });

  enum DrawerItemKey {
    DESCRIPTION = 'description',
    CLIENT_NAME = 'nom du client',
    START_DATE = 'date de début',
    END_DATE = 'date de fin',
    DIRECTOR = 'directeur de mission',
    MISSION_CHIEF = 'manager de mission',
    MISSION_CHIEFS = 'managers de mission',
    LEAD_DEV = 'lead dev',
    LEAD_DEVS = 'lead devs',
    TECH_STACK = 'stack technique',
  }

  const closeDrawer = () => setDrawerOpened(false);

  const isActivityChiefOrDirector = isChiefOrDirectorOfActivity(
    activityInfo,
    employee
  );

  const TypographyBodyItem = (
    body: string | undefined,
    key: string,
    feminine = false
  ) => {
    return (
      <Typography
        variant="bodySemiBold"
        color={palette.text.secondary}
        sx={{ whiteSpace: 'pre-wrap' }}
      >
        {body ? body : `Pas de ${key} renseigné${feminine ? 'e' : ''}`}
      </Typography>
    );
  };

  return (
    <Drawer anchor={'right'} open={drawerOpened} onClose={closeDrawer}>
      <Stack direction="column" sx={{ width: 500, padding: 5, gap: 1 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          color="black"
          sx={{ height: '30px' }}
        >
          <Typography variant="h2Bold" component="h2">
            Description de la mission
          </Typography>
          <IconButton onClick={() => setDrawerOpened(false)}>
            <CloseIcon sx={{ color: palette.text.primary }} />
          </IconButton>
        </Stack>
        <Typography
          variant="h2Bold"
          component="h2"
          color={palette.primary.main}
        >
          {activityInfo?.name}{' '}
          {isActivityChiefOrDirector && (
            <IconButton
              onClick={() => {
                dispatch(setEditMissionModalVisibility(true));
                history.push({
                  pathname: '/mission-followup/dashboard',
                  search: '?mission_id=' + activityId,
                });
              }}
            >
              <EditIcon sx={{ color: palette.primary.main }} />
            </IconButton>
          )}
        </Typography>

        <Divider sx={{ marginBottom: 1 }} />
        <DescriptionSection title={DrawerItemKey.DESCRIPTION}>
          {TypographyBodyItem(
            activityInfo?.description,
            DrawerItemKey.DESCRIPTION,
            true
          )}
        </DescriptionSection>
        <DescriptionSection title={DrawerItemKey.CLIENT_NAME}>
          {TypographyBodyItem(activityInfo?.client, DrawerItemKey.CLIENT_NAME)}
        </DescriptionSection>
        <DescriptionSection title="Date">
          <Stack direction="column">
            {TypographyBodyItem(
              activityInfo?.startDate
                ? `Date de début : ${formatDateUStoFR(activityInfo?.startDate)}`
                : undefined,
              DrawerItemKey.START_DATE,
              true
            )}
            {TypographyBodyItem(
              activityInfo?.expirationDate
                ? `Date de fin : ${formatDateUStoFR(
                    activityInfo?.expirationDate
                  )}`
                : undefined,
              DrawerItemKey.END_DATE,
              true
            )}
          </Stack>
        </DescriptionSection>
        <DescriptionSection title={DrawerItemKey.DIRECTOR}>
          {activityInfo?.director ? (
            <AvatarNameAndOccupationStack
              employee={activityInfo?.director as EmployeeNode}
            />
          ) : (
            TypographyBodyItem(undefined, DrawerItemKey.DIRECTOR)
          )}
        </DescriptionSection>
        <DescriptionSection
          title={
            activityInfo?.chiefs && activityInfo?.chiefs.length >= 2
              ? DrawerItemKey.MISSION_CHIEFS
              : DrawerItemKey.MISSION_CHIEF
          }
        >
          {activityInfo?.chiefs?.length
            ? _.map(activityInfo?.chiefs, (chief) => {
                return (
                  <AvatarNameAndOccupationStack
                    employee={chief as EmployeeNode}
                    key={`chief-${chief.id}`}
                  />
                );
              })
            : TypographyBodyItem(undefined, DrawerItemKey.MISSION_CHIEF)}
        </DescriptionSection>
        <DescriptionSection
          title={
            activityInfo?.leadDevs && activityInfo?.leadDevs.length >= 2
              ? DrawerItemKey.LEAD_DEVS
              : DrawerItemKey.LEAD_DEV
          }
        >
          {activityInfo?.leadDevs?.length
            ? _.map(activityInfo?.leadDevs, (leadDev) => {
                return (
                  <AvatarNameAndOccupationStack
                    employee={leadDev as EmployeeNode}
                    key={`leadDev-${leadDev.id}`}
                  />
                );
              })
            : TypographyBodyItem(undefined, DrawerItemKey.LEAD_DEV)}
        </DescriptionSection>
        <DescriptionSection title={DrawerItemKey.TECH_STACK}>
          {TypographyBodyItem(
            activityInfo?.techStack,
            DrawerItemKey.TECH_STACK,
            true
          )}
        </DescriptionSection>
      </Stack>
    </Drawer>
  );
}
