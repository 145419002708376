import { TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { EmployeeNode } from 'generated/graphql';
import { clone } from 'lodash';
import { checkEmailFormat } from 'pages/MissionFollowUp/formValidators';
import React from 'react';

import BillEmailAutocompleteChip from './BillEmailAutocompleteChip';
import { EmailOption } from './utils';

interface BillEmailAutocompleteProps {
  options: EmailOption[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  controllerOnChange: (...event: any[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  controllerProps: any;
  allEmployees: EmployeeNode[];
  required: boolean;
}

const filter = createFilterOptions<EmailOption>();

export default function BillEmailAutocomplete({
  options,
  controllerProps,
  controllerOnChange,
  allEmployees,
  required,
}: BillEmailAutocompleteProps) {
  return (
    <Autocomplete
      autoHighlight
      multiple
      freeSolo
      autoComplete
      filterSelectedOptions
      options={options}
      // The freesolo option forces the value created by typing into the textbox to be a string, regardless of the type of the options.
      // Typing the data as any avoids type error.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(e, data: any[]) => {
        const lastElement = clone(data[data.length - 1]);
        const formattedArray: EmailOption[] = clone(data).slice(0, -1);
        if (lastElement) {
          if (typeof lastElement === 'string') {
            const userDetails = allEmployees.find(
              (employee) => employee.email === lastElement
            );
            const formattedData: EmailOption = {
              email: lastElement,
              employee: userDetails,
            };
            formattedArray.push(formattedData);
          } else {
            formattedArray.push(lastElement);
          }
        }

        controllerOnChange(formattedArray);
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) => inputValue === option.title
        );
        if (
          inputValue !== '' &&
          !isExisting &&
          checkEmailFormat(inputValue) !== "Format d'adresse email invalide"
        ) {
          filtered.push({
            email: `${inputValue}`,
          });
        }

        return filtered;
      }}
      isOptionEqualToValue={(option, value) =>
        option.email === (typeof value !== 'string' ? value.email : value)
      }
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.email
      }
      renderOption={(props, option) => (
        <BillEmailAutocompleteChip
          option={option}
          props={props}
          sx={{ bgcolor: 'transparent' }}
        />
      )}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => {
          return (
            <BillEmailAutocompleteChip
              option={option}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                ml: 1,
                mt: 1,
                borderRadius: 10,
              }}
              key={`option${index}`}
              tagProps={getTagProps({ index })}
              canBeDeleted
            />
          );
        });
      }}
      renderInput={(parameters) => (
        <TextField
          {...parameters}
          label={controllerProps.label}
          size={'small'}
          fullWidth
          type="email"
          required={required}
          sx={{ maxWidth: '42rem', height: 'fit-content', mt: 3 }}
        />
      )}
      {...controllerProps}
    />
  );
}
