import TabHeader from 'components/commons/TabHeader';
import SectorModal from 'components/MissionFollowUp/Sector/SectorModal';
import SectorTable from 'components/MissionFollowUp/Sector/SectorTable';
import PongoButton from 'components/MUIOverload/PongoButton';
import {
  BillingClientNode,
  SectorNode,
  useAllSectorsWithClientsQuery,
} from 'generated/graphql';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { PageTitles } from 'poly-constants';
import React, { useState } from 'react';

const SectorPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<boolean>(false);
  const [allSectors, setAllSectors] = useState<SectorNode[]>([]);

  const { data, refetch } = useAllSectorsWithClientsQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      const allSectorsCopy = _.cloneDeep(data?.allSectors);
      if (allSectorsCopy) {
        const noSectorIndex = _.findIndex(allSectorsCopy, function (sector) {
          return sector.name === 'Pas de secteur';
        });
        allSectorsCopy.push(allSectorsCopy.splice(noSectorIndex, 1)[0]);
        setAllSectors(allSectorsCopy as SectorNode[]);
      }
    },
    onError: () => {
      enqueueSnackbar('Erreur lors de la récupération des données', {
        variant: 'error',
      });
    },
  });
  const allClients = (data?.allBillingClient || []) as BillingClientNode[];

  return (
    <>
      <TabHeader
        title={PageTitles.billingSectorsPage}
        count={allSectors.length}
        actionComponents={
          <PongoButton
            variant={'contained'}
            color={'primary'}
            onClick={() => {
              setOpen(true);
            }}
          >
            Nouveau secteur
          </PongoButton>
        }
      />
      {allSectors && (
        <SectorTable
          allClients={allClients as BillingClientNode[]}
          refetch={refetch}
          sectors={allSectors as SectorNode[]}
        />
      )}
      <SectorModal
        mode={'create'}
        isOpen={open}
        handleClose={() => setOpen(false)}
        refetch={refetch}
        allClients={allClients as BillingClientNode[]}
      />
    </>
  );
};

export default SectorPage;
