import { Stack, Typography } from '@mui/material';
import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import PageWrapper from 'components/commons/PageWrapper';
import PongoButton from 'components/MUIOverload/PongoButton';
import TrophyModal from 'components/Phonebook/TrophyModal';
import DeleteTrophyModal from 'components/Phonebook/TrophyModal/DeleteTrophyModal';
import TrophyTable from 'components/Phonebook/TrophyTable';
import {
  EmployeeNode,
  TrophyNode,
  useAllEmployeesQuery,
  useAllTrophiesQuery,
} from 'generated/graphql';
import React, { useState } from 'react';

export default function TrophyPage() {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [currentTrophy, setCurrentTrophy] = useState<TrophyNode | undefined>(
    undefined
  );
  const [allEmployees, setAllEmployees] = useState<EmployeeNode[]>([]);

  const { data, refetch, loading } = useAllTrophiesQuery({
    notifyOnNetworkStatusChange: true,
  });

  const allTrophies = (data?.allTrophies || []) as TrophyNode[];

  useAllEmployeesQuery({
    onCompleted: (data) => {
      if (data.allEmployees) {
        setAllEmployees(data.allEmployees as EmployeeNode[]);
      }
    },
  });

  return (
    <PageWrapper noLimit>
      <Stack
        justifyContent={'space-between'}
        direction={'row'}
        alignItems={'center'}
      >
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <Typography variant={'h1Bold'}>Trophées </Typography>
        </Stack>
        <PongoButton
          variant={'contained'}
          color={'primary'}
          onClick={() => {
            setCurrentTrophy(undefined);
            setOpen(true);
            setOpenDeleteModal(false);
          }}
        >
          Nouveau trophée
        </PongoButton>
      </Stack>
      {loading ? (
        <LoadingPlaceholder />
      ) : (
        <TrophyTable
          trophies={allTrophies}
          setOpenEditModal={() => {
            setOpen(true);
          }}
          refetch={refetch}
          setCurrentTrophy={setCurrentTrophy}
          setOpenDeleteModal={() => {
            setOpenDeleteModal(true);
          }}
        />
      )}

      <TrophyModal
        trophy={currentTrophy}
        isOpen={isOpen && !openDeleteModal}
        handleClose={() => {
          setOpen(false);
        }}
        refetch={refetch}
        allEmployees={allEmployees}
        handleOpenDelete={() => {
          setOpen(false);
          setOpenDeleteModal(true);
        }}
      />
      <DeleteTrophyModal
        isOpen={openDeleteModal}
        handleClose={() => {
          setCurrentTrophy(undefined);
          setOpen(false);
          setOpenDeleteModal(false);
        }}
        refetch={refetch}
        trophy={currentTrophy}
      />
    </PageWrapper>
  );
}
