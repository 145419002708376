import BillStatusChip from 'components/MissionFollowUp/BillChips/BillStatusChip';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import { BillingBillStatusChoices } from 'generated/graphql';
import _ from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { setStatusFilter } from 'store/purchaseOrder';

import styles from './styles/Filters.module.scss';

const FilterStatus = () => {
  const SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED =
    useEnvironmentVariable('send_bill_email');
  const dispatch = useDispatch();
  const currentStatus = useSelector(
    (state) => state.purchaseOrder.statusFilter
  );
  const billStatusChoices = SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED
    ? [
        BillingBillStatusChoices.Draft,
        BillingBillStatusChoices.ToBill,
        BillingBillStatusChoices.Billed,
        BillingBillStatusChoices.Sent,
        BillingBillStatusChoices.ToCancel,
        BillingBillStatusChoices.Canceled,
      ]
    : [
        BillingBillStatusChoices.Draft,
        BillingBillStatusChoices.ToBill,
        BillingBillStatusChoices.Billed,
        BillingBillStatusChoices.ToCancel,
        BillingBillStatusChoices.Canceled,
      ];

  const setFilter = (status: BillingBillStatusChoices) => {
    if (_.includes(currentStatus, status)) {
      const filteredStatus = _.filter(
        currentStatus,
        (current) => current !== status
      );
      dispatch(setStatusFilter(filteredStatus));
    } else {
      dispatch(setStatusFilter([...currentStatus, status]));
    }
  };

  return (
    <div>
      {_.map(billStatusChoices, (status) => {
        return (
          <span className={styles.statusChip} key={`chip-${status}`}>
            <BillStatusChip
              status={status}
              onClick={() => setFilter(status)}
              isActive={currentStatus?.includes(status) || false}
            />
          </span>
        );
      })}
    </div>
  );
};

export default FilterStatus;
