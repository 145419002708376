import { Search } from '@mui/icons-material';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Chip,
  InputAdornment,
  Stack,
  TextField,
  useTheme,
} from '@mui/material';
import AvatarNameAndOccupationStack from 'components/commons/AvatarNameAndOccupationStack/AvatarNameAndOccupationStack';
import { getEmployeeStatusChip } from 'components/EmployeePage/utils';
import { EmployeeStatusTextChoices } from 'components/Phonebook/utils';
import {
  EmployeeNode,
  useAllActiveAndFutureEmployeesQuery,
} from 'generated/graphql';
import { enqueueSnackbar } from 'notistack';
import React, { useMemo, useState } from 'react';
import { displayEmployeeNode } from 'utils';

interface EmployeeSearchBarProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  setEmployee: React.Dispatch<React.SetStateAction<EmployeeNode | undefined>>;
  label?: string;
}

export default function EmployeeSearchBar({
  value,
  setEmployee,
  label = 'Collaborateur',
}: EmployeeSearchBarProps) {
  const { data } = useAllActiveAndFutureEmployeesQuery({
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const allEmployees = useMemo(
    () => (data?.allActiveAndFutureEmployees as EmployeeNode[]) || [],
    [data?.allActiveAndFutureEmployees]
  );
  const handleOnChange = async (
    _event: object,
    value: EmployeeNode | string | null
  ) => {
    if (typeof value === 'object' && value?.id) {
      setEmployee(value);
    } else {
      setEmployee(undefined);
    }
  };

  return (
    <Autocomplete
      freeSolo
      value={value}
      options={allEmployees}
      popupIcon={false}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        return displayEmployeeNode(option, false);
      }}
      onChange={handleOnChange}
      groupBy={() => 'Résultats'}
      renderOption={(props, option: EmployeeNode) => {
        const { label: employeeStatusChipLabel } =
          getEmployeeStatusChip(option);
        return (
          <Box component={'li'} {...props} key={'option-' + option.id}>
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              width="100%"
            >
              <AvatarNameAndOccupationStack employee={option} />
              {employeeStatusChipLabel === EmployeeStatusTextChoices.COMING && (
                <Chip
                  label={EmployeeStatusTextChoices.COMING}
                  variant={'upcoming'}
                  size="small"
                />
              )}
            </Stack>
          </Box>
        );
      }}
      renderInput={(params) => AutocompleteInputRenderer(params, label)}
      sx={{ width: { xs: '100%' } }}
    />
  );
}

function AutocompleteInputRenderer(
  props: AutocompleteRenderInputParams,
  label: string
) {
  const theme = useTheme();
  const [isFocused, setIsFocused] = useState(false);

  return (
    <TextField
      {...props}
      required
      size="small"
      label={label}
      placeholder="Rechercher un collaborateur"
      variant="outlined"
      sx={{
        boxSizing: 'border-box',
        width: '100%',
        input: {
          '&::placeholder': {
            opacity: isFocused ? 0.5 : 1,
          },
        },
      }}
      InputProps={{
        ...props.InputProps,
        startAdornment: (
          <InputAdornment sx={{ marginLeft: 1 }} position="start">
            <Search sx={{ color: theme.palette.text.primary }} />
          </InputAdornment>
        ),
        onFocus: () => setIsFocused(true),
        onBlur: () => setIsFocused(false),
      }}
    />
  );
}
