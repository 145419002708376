import React from 'react';

interface UndefinedCellProps {
  isUndefined: boolean;
  undefinedRender?: string;
  render: () => React.ReactElement;
}

const UndefinedCell = ({
  isUndefined,
  undefinedRender = '-',
  render,
}: UndefinedCellProps) => {
  return isUndefined ? <>{undefinedRender}</> : render();
};

export default UndefinedCell;
