import PolyDataGrid from 'components/MUIOverload/PolyDataGrid';
import DateCell from 'components/MUIOverload/PolyDataGrid/components/DateCell';
import NumberCell from 'components/MUIOverload/PolyDataGrid/components/NumberCell';
import StringCell from 'components/MUIOverload/PolyDataGrid/components/StringCell';
import { PolyDataGridColumn } from 'components/MUIOverload/PolyDataGrid/PolyDataGridHead';
import { RecoveryMomentsQuery } from 'generated/graphql';
import React from 'react';

import styles from './styles/RecoveryMomentsTableTitle.module.scss';

const title = 'Historique de récupération';

const displayHalfDay = {
  AD: '-',
  AF: 'Après-midi',
  MO: 'Matin',
};

const columns: PolyDataGridColumn<
  DeepExtractTypeSkipArrays<RecoveryMomentsQuery, ['recoveryMoments']>
>[] = [
  {
    headerName: 'Date de récupération',
    isSortable: false,
    width: '150px',
    render: (data) => DateCell({ date: data.date }),
  },
  {
    headerName: 'Mission',
    isSortable: false,
    width: '470px',
    render: (data) =>
      StringCell({
        data: data.overtimeRequest.authorization.activity.name,
      }),
  },
  {
    headerName: 'Temps de récupération',
    isSortable: false,
    render: (data) => NumberCell({ data: data.duration }),
  },
  {
    headerName: 'Demi-journée',
    isSortable: false,
    render: (data) => StringCell({ data: displayHalfDay[data.dayMoment] }),
  },
];

interface RecoveryMomentsTableProps {
  recoveryRequestsData: RecoveryMomentsQuery | undefined;
}

const RecoveryMomentsTable = ({
  recoveryRequestsData,
}: RecoveryMomentsTableProps) => {
  const getRows = (_orderBy?: string | string[], _order?: 'asc' | 'desc') => {
    if (
      recoveryRequestsData === undefined ||
      recoveryRequestsData.recoveryMoments === undefined
    ) {
      return [];
    }

    return recoveryRequestsData.recoveryMoments;
  };

  return (
    <>
      <h1 className={styles.title}>{title}</h1>
      <PolyDataGrid columns={columns} getRows={getRows} />
    </>
  );
};

export default RecoveryMomentsTable;
