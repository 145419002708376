import { SxProps } from '@mui/system';
import PolyExportButton from 'components/commons/PolyExportButton';
import React, { useState } from 'react';
import { useSelector } from 'store';

import ExportActualRevenueMissionModal from './ExportActualRevenueMissionModal';
import ExportActualRevenueModal from './ExportActualRevenueModal';

interface ExportButtonInterface {
  sx?: SxProps;
}
export default function ExportActualRevenueButton({
  sx,
}: ExportButtonInterface) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <React.Fragment>
      <ExportModal isOpen={isOpen} toggleModal={toggleModal} />
      <PolyExportButton onClick={toggleModal} sx={sx}>
        Exporter ce tableau
      </PolyExportButton>
    </React.Fragment>
  );
}

function ExportModal({
  isOpen,
  toggleModal,
}: {
  isOpen: boolean;
  toggleModal: () => void;
}) {
  const currentMission = useSelector((state) => state.activity.currentMission);
  const isMissionSelected =
    currentMission !== undefined && currentMission.id !== undefined;

  if (isMissionSelected) {
    return (
      <ExportActualRevenueMissionModal
        isOpen={isOpen}
        handleClose={toggleModal}
        title={"Exporter le chiffre d'affaires réalisé"}
        body={
          "Téléchargez ici le chiffre d'affaires réalisé en choisissant la période qui vous intéresse"
        }
        activity={currentMission}
      />
    );
  }
  return (
    <ExportActualRevenueModal
      isOpen={isOpen}
      handleClose={toggleModal}
      title={"Exporter le chiffre d'affaires réalisé"}
      body={
        "Téléchargez ici le chiffre d'affaires réalisé en choisissant la période qui vous intéresse"
      }
    />
  );
}
