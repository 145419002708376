import { Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import PolyDataGrid from 'components/MUIOverload/PolyDataGrid';
import DateCell from 'components/MUIOverload/PolyDataGrid/components/DateCell';
import StringCell from 'components/MUIOverload/PolyDataGrid/components/StringCell';
import { PolyDataGridColumn } from 'components/MUIOverload/PolyDataGrid/PolyDataGridHead';
import PolyModal from 'components/MUIOverload/PolyDialog/PolyModal';
import PolyDialogTitle from 'components/MUIOverload/PolyDialogTitle';
import {
  AllUpdateAuthorizationQuery,
  useAllUpdateAuthorizationLazyQuery,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { displayEmployee } from 'utils';

import modalStyles from './styles/HistoryModal.module.scss';

export type AMAuthorizationType = DeepExtractTypeSkipArrays<
  AllUpdateAuthorizationQuery,
  ['allUpdateAuthorization']
>;

const displayMonths = (months: string[] | undefined) => {
  return (
    <>
      {_.map(months, (month) => {
        return (
          <Typography sx={{ fontSize: '14px' }}>
            {moment(month).format('MMMM YYYY')}
          </Typography>
        );
      })}
    </>
  );
};

interface HistoryModalProps {
  open: boolean;
  setOpen: (isopen: boolean) => void;
}

export default function HistoryModal({ open, setOpen }: HistoryModalProps) {
  const [loading, setLoading] = useState(true);
  const [activeUpdateAuthorizations, setActiveUpdateAuthorizations] = useState<
    Array<AMAuthorizationType>
  >([]);
  const [expiredUpdateAuthorizations, setExpiredUpdateAuthorizations] =
    useState<Array<AMAuthorizationType>>([]);
  const { enqueueSnackbar } = useSnackbar();

  const columns: PolyDataGridColumn<
    DeepExtractTypeSkipArrays<
      AllUpdateAuthorizationQuery,
      ['allUpdateAuthorization']
    >
  >[] = [
    {
      headerName: '',
      isSortable: false,
      width: '150px',
      render: (data) =>
        StringCell({
          data: displayEmployee(
            data.employee.firstName,
            data.employee.lastName
          ),
        }),
    },
    {
      headerName: 'Date de début',
      isSortable: false,
      width: '150px',
      render: (data) => DateCell({ date: data.createdAt }),
    },
    {
      headerName: 'Date de fin',
      isSortable: false,
      width: '150px',
      render: (data) => DateCell({ date: data.validUntil }),
    },
    {
      headerName: 'Mois',
      isSortable: false,
      width: '150px',
      render: (data) => displayMonths(data.months),
    },
  ];

  const rowsActive = (
    _orderBy?: string | string[],
    _order?: 'asc' | 'desc'
  ) => {
    return activeUpdateAuthorizations;
  };
  const rowsExpired = (
    _orderBy?: string | string[],
    _order?: 'asc' | 'desc'
  ) => {
    return expiredUpdateAuthorizations;
  };

  const [fetchUpdateAuthorization] = useAllUpdateAuthorizationLazyQuery({
    onCompleted: (data) => {
      setLoading(false);
      if (data.allUpdateAuthorization) {
        setActiveUpdateAuthorizations(
          data.allUpdateAuthorization
            .filter((updateAuth) => {
              return moment(updateAuth.validUntil).isSameOrAfter(
                moment(),
                'day'
              );
            })
            .filter(
              (auth, index, self) =>
                self.findIndex(
                  (value) =>
                    value.employee.firstName === auth.employee.firstName &&
                    value.employee.lastName === auth.employee.lastName
                ) === index
            )
        );
        setExpiredUpdateAuthorizations(
          data.allUpdateAuthorization.filter((updateAuth) => {
            return moment(updateAuth.validUntil).isBefore(moment(), 'day');
          })
        );
      }
    },
    onError: () => {
      enqueueSnackbar('Problème de récupération des collaborateurs', {
        variant: 'error',
      });
    },
  });

  useEffect(() => {
    if (open) {
      fetchUpdateAuthorization();
    } else {
      setLoading(true);
    }
  }, [fetchUpdateAuthorization, open, setLoading]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <PolyModal
      open={open}
      onClose={handleClose}
      polyDialogTitle={'Collaborateurs ayant la main'}
      secondaryButtonName="Fermer"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={'md'}
      fullWidth={true}
    >
      <>
        <div className={modalStyles.fixedHeight}>
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '500px',
              }}
            >
              <CircularProgress color="primary" />
            </Box>
          ) : (
            <div className={modalStyles.scroller}>
              <PolyDataGrid columns={columns} getRows={rowsActive} />
            </div>
          )}
        </div>
        <div className={modalStyles.fixedHeight}>
          <PolyDialogTitle
            id="alert-dialog-title"
            className={modalStyles.title}
            sx={{ padding: 0, marginTop: 2 }}
          >
            <div>{'Historique "Rendre la main"'}</div>
          </PolyDialogTitle>
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '500px',
              }}
            >
              <CircularProgress color="primary" />
            </Box>
          ) : (
            <div className={modalStyles.scroller}>
              <PolyDataGrid columns={columns} getRows={rowsExpired} />
            </div>
          )}
        </div>
      </>
    </PolyModal>
  );
}
