import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip, TooltipProps } from '@mui/material';
import React from 'react';

const InfoTooltip = (properties: TooltipProps) => {
  return (
    <Tooltip {...properties} placement={properties.placement || 'right'}>
      <InfoIcon
        fontSize={'small'}
        sx={{ ml: 0.25, mb: -0.75, color: 'text.secondary' }}
      />
    </Tooltip>
  );
};

InfoTooltip.defaultProps = {
  children: '',
};

export default InfoTooltip;
