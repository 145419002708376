import HelpIcon from '@mui/icons-material/Help';
import ErrorPage from 'pages/ErrorPage/index';
import React from 'react';

export default function PageNotFound() {
  return (
    <ErrorPage
      text={"Erreur 404: Cette page n'existe pas"}
      logo={<HelpIcon sx={{ width: '100%', height: '100%' }} />}
    />
  );
}
