import { TableBody } from '@mui/material';
import { useTableContext } from 'components/Revenue/BilledRevenueTables/BilledRevenueMissionTable/context/TableContextProvider';
import PurchaserOrderRow from 'components/Revenue/BilledRevenueTables/BilledRevenueMissionTable/RevenueRows/PurchaserOrderRow';
import TotalRow from 'components/Revenue/BilledRevenueTables/BilledRevenueMissionTable/RevenueRows/TotalRow';
import { getMaxBillingDateAndEndForPos } from 'components/Revenue/RevenueGlobalTable/RevenueTableBodyByClient';
import RevenueTableEmptyRow from 'components/Revenue/Tables/RevenueTableEmptyRow';
import { getPoAmountPerMonths } from 'components/Revenue/utils/utils';
import { PurchaseOrderNode } from 'generated/graphql';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import React, { useCallback, useMemo } from 'react';
import { Transition } from 'react-transition-group';
import { billHasBeenBilled } from 'utils';

import styles from './styles/TableCell.module.scss';

export interface PurchaseOrderRevenue {
  po: PurchaseOrderNode;
  totals: { total: number; month: Moment }[];
  poEnd: Moment;
  total: number;
}

export function totalBilledPoForMonth(
  po: PurchaseOrderNode,
  month: Moment,
  applyConversionRate?: boolean
) {
  const totalPoForMonth = _.sumBy(po.bills, (bill) => {
    const isBilled = !!bill.billNumber;
    if (!isBilled) {
      return 0;
    }
    const conversionRate = applyConversionRate
      ? bill.currencyConversionRate
      : 1;
    const billingDate = moment(bill.billingDate);
    return month.isSame(billingDate, 'month') ? bill.total / conversionRate : 0;
  });
  return totalPoForMonth;
}

export function transformToPurchaseOrderRevenue(
  po: PurchaseOrderNode,
  months: Moment[]
): PurchaseOrderRevenue {
  const totalPo = _(po.bills)
    .map((bill) => {
      const isBilled = billHasBeenBilled(bill);
      const isSameYearOfMonths = months[0].isSame(
        moment(bill.billingDate),
        'year'
      );
      return isBilled && isSameYearOfMonths
        ? bill.total / bill.currencyConversionRate
        : 0;
    })
    .sum();

  const totals = _.map(months, (month) => {
    const totalPoForMonth = totalBilledPoForMonth(po, month, true);
    return {
      total: totalPoForMonth,
      month: month,
    };
  });

  const maxDate = getMaxBillingDateAndEndForPos([po], po.periodEnding);

  return {
    po: po,
    poEnd: maxDate,
    totals: totals,
    total: totalPo,
  };
}

const RevenueTableBodyByPo = () => {
  const { purchaseOrders, displayedMonths } = useTableContext();

  const transformPurchaseOrder = useCallback(
    (po: PurchaseOrderNode): PurchaseOrderRevenue => {
      return transformToPurchaseOrderRevenue(po, displayedMonths);
    },
    [displayedMonths]
  );

  const numberOfDisplayedMonths = displayedMonths.length;
  const totalPerDisplayedMonths = new Array(numberOfDisplayedMonths).fill(0);

  const purchaseOrdersRevenues = useMemo(() => {
    return _.map(purchaseOrders, transformPurchaseOrder);
  }, [purchaseOrders, transformPurchaseOrder]);
  return (
    <TableBody className={styles.tableBody}>
      {_.map(purchaseOrdersRevenues, (poRevenue) => {
        const amountPerDisplayedMonths = getPoAmountPerMonths(
          poRevenue.po,
          displayedMonths,
          numberOfDisplayedMonths
        );

        amountPerDisplayedMonths.forEach(
          (amount, index) => (totalPerDisplayedMonths[index] += amount)
        );
        const isHidden =
          _.first(displayedMonths)?.isAfter(poRevenue.poEnd, 'month') ||
          _.last(displayedMonths)?.isBefore(
            poRevenue.po.periodBeginning,
            'month'
          );
        return (
          <Transition
            mountOnEnter
            unmountOnExit
            timeout={250}
            key={`client-${poRevenue.po.id}`}
            in={!isHidden}
          >
            {(status) => {
              return (
                <PurchaserOrderRow
                  poRevenue={poRevenue}
                  status={status}
                  amountPerDisplayedMonths={amountPerDisplayedMonths}
                />
              );
            }}
          </Transition>
        );
      })}
      {_.isEmpty(purchaseOrdersRevenues) && (
        <RevenueTableEmptyRow emptyText={'Aucun BDC déclaré'} />
      )}
      <TotalRow totalPerDisplayedMonths={totalPerDisplayedMonths} />
    </TableBody>
  );
};

export default RevenueTableBodyByPo;
