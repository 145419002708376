import { ApolloError } from '@apollo/client';
import {
  EmployeeOccupations,
  UserContextProps,
} from 'components/User/UserProvider';
import {
  ActivitiesActivityBillingTypeChoices,
  ActivitiesActivityTypeChoices,
  ActivityNode,
  BillingBillPaymentStatusChoices,
  BillingBillStatusChoices,
  BillNode,
  EmployeeNode,
  MeEmployeeFragment,
  PurchaseOrderNode,
} from 'generated/graphql';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { ehpUrl, POLY_DATE } from 'poly-constants';

import { PongoCollaboratorActivity } from './poly-constants';
import SnackbarUtils from './services/Snackbar';

const url: string = window.location.href;
export const isEhp: boolean = url.includes(ehpUrl);
export const isDev: boolean = process.env.NODE_ENV === 'development';
export const isProd = process.env.NODE_ENV === 'production' && !isEhp;
export const isTest: boolean = process.env.NODE_ENV === 'test';

export const isEmployeeSupport = (
  employee: MeEmployeeFragment | null | undefined
): boolean => {
  const supportOccupations = new Set([
    EmployeeOccupations.COMMERCIAL,
    EmployeeOccupations.OFFICE_MANAGEMENT,
    EmployeeOccupations.RECRUITING,
  ]);
  return supportOccupations.has(employee?.occupation as EmployeeOccupations);
};

export const failWithShouldNotHappen = (_: never): never => {
  throw new Error('Should not happen');
};

export const getMissionTypeString = (mission: ActivityNode): string => {
  if (
    mission?.type === ActivitiesActivityTypeChoices.Int ||
    mission?.type === ActivitiesActivityTypeChoices.Cft
  ) {
    return 'interne';
  }
  return mission?.billingType === ActivitiesActivityBillingTypeChoices.Tm
    ? 'régie'
    : 'forfait';
};

export const getThursday = (date?: Date | Moment) => {
  if (date) {
    return moment(date).day(4).endOf('day').local();
  }
  return moment().day(4).endOf('day').local();
};

export const getLastMonday = (date: Date | Moment) => {
  return moment(date).isoWeekday(-6);
};

export const displayEmployee = (
  firstName: string | undefined,
  lastName: string | undefined,
  lastBeforeFirst = true
) => {
  return lastBeforeFirst
    ? `${(lastName || '').toUpperCase()} ${firstName || ''}`
    : `${firstName || ''} ${(lastName || '').toUpperCase()}`;
};

export const displayEmployeeNode = (
  employee?: EmployeeNode,
  lastBeforeFirst = true
) => {
  return displayEmployee(
    employee?.firstName,
    employee?.lastName,
    lastBeforeFirst
  );
};

export const getFirstMondayOfMonth = (date?: Date | Moment) => {
  let firstMondayOfMonth;

  firstMondayOfMonth = date
    ? moment(date).startOf('month').startOf('isoWeek')
    : moment(date).startOf('month').startOf('isoWeek');
  const clone = firstMondayOfMonth.clone();
  if (clone.day(4).date() > 7) {
    firstMondayOfMonth = clone.day(8);
  }
  return firstMondayOfMonth.toDate();
};

export const getLastSundayOfMonth = (date?: Date | Moment) => {
  let lastSundayOfMonth;

  lastSundayOfMonth = date
    ? moment(date).endOf('month').startOf('isoWeek').day(7)
    : moment().endOf('month').startOf('isoWeek').day(7);
  const clone = lastSundayOfMonth.clone();
  if (clone.date() > 3 && lastSundayOfMonth.date() < 7) {
    lastSundayOfMonth = clone.day(-7);
  }
  return lastSundayOfMonth.toDate();
};

export const functionNotSet = () => {
  throw new Error('function context not set');
};

const getOrder = (field: string | number | Array<BillNode> | Array<string>) => {
  if (Array.isArray(field)) {
    return field.length.toString();
  }
  switch (field) {
    case BillingBillStatusChoices.Draft:
      return '0';
    case BillingBillStatusChoices.ToBill:
      return '1';
    case BillingBillStatusChoices.Billed:
      return '2';
    case BillingBillStatusChoices.ToCancel:
      return '3';
    case BillingBillStatusChoices.Canceled:
      return '4';
    case BillingBillPaymentStatusChoices.Pending:
      return '0';
    case BillingBillPaymentStatusChoices.Litigation:
      return '1';
    case BillingBillPaymentStatusChoices.Recovering:
      return '2';
    case BillingBillPaymentStatusChoices.Paid:
      return '3';
    default:
      return typeof field !== 'number'
        ? field?.toString().toLocaleLowerCase()
        : field;
  }
};

export const compareFacturationTable = (
  _orderBy?: string | string[],
  _order?: 'asc' | 'desc'
) => {
  function compare<T>(billingObject1: T, billingObject2: T) {
    let comparedValue1, comparedValue2;
    if (typeof _orderBy === 'object') {
      let firstItem = '';
      let secondItem = '';
      for (const property of _orderBy) {
        firstItem = firstItem.concat(
          _.get(
            _order === 'asc' ? billingObject1 : billingObject2,
            property || ''
          )
        );
        secondItem = secondItem.concat(
          _.get(
            _order === 'asc' ? billingObject2 : billingObject1,
            property || ''
          )
        );
      }
      comparedValue1 = getOrder(firstItem);
      comparedValue2 = getOrder(secondItem);
    } else {
      comparedValue1 = getOrder(
        _.get(
          _order === 'asc' ? billingObject1 : billingObject2,
          _orderBy || ''
        )
      );
      comparedValue2 = getOrder(
        _.get(
          _order === 'asc' ? billingObject2 : billingObject1,
          _orderBy || ''
        )
      );
    }
    return typeof comparedValue1 !== 'number' &&
      typeof comparedValue2 !== 'number'
      ? comparedValue1?.localeCompare(comparedValue2, undefined)
      : (comparedValue1 as number) - (comparedValue2 as number);
  }
  return compare;
};

export const getMonthsBetweenDates = (begin: Moment, end: Moment) => {
  const periodMonths = [] as Moment[];
  let startDate = moment(begin, 'YYYY-MM');
  const endDate = moment(end, 'YYYY-MM');
  while (startDate.isSameOrBefore(endDate)) {
    periodMonths.push(moment(startDate, 'YYYY-MM'));
    startDate = startDate.add(1, 'month');
  }
  return periodMonths;
};

export const getBills = (po: PurchaseOrderNode) => {
  return (
    po.bills &&
    po.bills.map((bill) => {
      if (billHasBeenBilled(bill)) {
        return {
          total: bill.total as number,
          months: getMonthsBetweenDates(
            bill.periodBeginning,
            bill.periodEnding
          ),
          currencyConversionRate: bill.currencyConversionRate,
        };
      }
      return {
        total: 0,
        months: [],
        currencyConversionRate: bill.currencyConversionRate,
      };
    })
  );
};

export const getFormattedBills = (
  po: PurchaseOrderNode,
  SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED = false
) => {
  let total_amount = 0;
  const chartFormat: {
    status: BillingBillStatusChoices | undefined;
    color: string;
    label: string;
    total: number;
    totalPrice: number;
  }[] = [
    {
      status: BillingBillStatusChoices.Draft,
      color: '#939393',
      label: 'Brouillon',
      total: 0,
      totalPrice: 0,
    },
    {
      status: BillingBillStatusChoices.ToBill,
      color: SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED ? '#356496' : '#48A7DF',
      label: 'À facturer',
      total: 0,
      totalPrice: 0,
    },
    {
      status: BillingBillStatusChoices.Billed,
      color: SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED ? '#252AD0' : '#219653',
      label: SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED
        ? 'Comptabilisée'
        : 'Facturée',
      total: 0,
      totalPrice: 0,
    },
    {
      status: BillingBillStatusChoices.Sent,
      color: '#219653',
      label: 'Envoyée',
      total: 0,
      totalPrice: 0,
    },
  ];

  _.forEach(po.bills, (bill) => {
    const chart = _.find(
      chartFormat,
      (chart) => chart.status === bill.status && !bill.billReference
    );
    if (chart) {
      chart.total += 1;
      chart.totalPrice += bill.total;
      total_amount += bill.total;
    }
  });

  if (po.total) {
    chartFormat.push({
      status: undefined,
      color: '#EFEFEF',
      label: 'Restant',
      total: 0,
      totalPrice: po.total - total_amount,
    });
  }

  return chartFormat;
};

/**
 * Don't trust the bill status because bill with status TO_CANCEL could be not yet billed
 * if the cancellation request has been made when the bill was TO_BILL
 */
export const billHasBeenBilled = (bill: BillNode): boolean => {
  return !!bill.billNumber;
};

export interface PhonebookChipData {
  name: string;
  id: string;
  __typename: 'PhonebookChipDataStatus' | 'PhonebookChipDataRole';
  firstName?: string;
  lastName?: string;
}

export const handleApolloError = (error: ApolloError) => {
  SnackbarUtils.error(error.message);
};

export const graphQlDateFormatter = (date: Date | Moment) => {
  return moment(date).format('YYYY-MM-DD');
};

export const formatDateUStoFR = (date: string) => {
  return moment(date).format(POLY_DATE);
};

export const isUserPongoCollaborator = (userInfo: UserContextProps): boolean =>
  _.some(userInfo.employee?.assignments, (assignment) => {
    return assignment.activity?.id === PongoCollaboratorActivity.PONGO;
  });

const hasPurchaseOrderLastMonth = (mission: ActivityNode): boolean => {
  return _.some(mission.billingInformation?.purchaseOrders, (po) => {
    return moment(po.periodEnding).isSame(mission.expirationDate, 'month');
  });
};

const shouldDisplayOneMonthAfterExpiration = (
  mission: ActivityNode
): boolean => {
  return (
    mission.billingType === ActivitiesActivityBillingTypeChoices.Tm &&
    hasPurchaseOrderLastMonth(mission)
  );
};

export function getMissionLastActiveMonth(mission: ActivityNode): Moment {
  return shouldDisplayOneMonthAfterExpiration(mission)
    ? moment(mission.expirationDate).add(1, 'M')
    : moment(mission.expirationDate);
}

export const getParameterValue = (
  params: URLSearchParams,
  searched: string
): string | undefined => {
  return params.get(searched) ?? undefined;
};

export const addMissionIdToPath = (
  path: string,
  search: string,
  fallbackPath?: string
): string => {
  const params = new URLSearchParams(search);
  if (params.has('mission_id')) {
    return `${path}?mission_id=${getParameterValue(params, 'mission_id')}`;
  }
  return `${fallbackPath || path}`;
};

export function isEmployeeChiefOfActivity(
  mission: ActivityNode,
  employee: MeEmployeeFragment | null | undefined
): boolean {
  if (!employee) {
    return false;
  }
  return mission?.chiefs?.some((chief) => employee?.id === chief.id) || false;
}

export function isEmployeeDirectorOfActivity(
  mission: ActivityNode,
  employee: MeEmployeeFragment | null | undefined
): boolean {
  if (!employee) {
    return false;
  }
  return mission?.director?.id === employee.id;
}

export function isChiefOrDirectorOfActivity(
  mission: ActivityNode,
  employee: MeEmployeeFragment | null | undefined
): boolean {
  if (!employee) {
    return false;
  }
  return (
    isEmployeeDirectorOfActivity(mission, employee) ||
    isEmployeeChiefOfActivity(mission, employee)
  );
}

export function checkIfAllMissionsAreInternal(missions: ActivityNode[]) {
  if (missions.length === 0) {
    return false;
  }
  return missions.every(
    (currentMission) =>
      !!currentMission.type &&
      currentMission.type === ActivitiesActivityTypeChoices.Int
  );
}

/**
 * Returns a string representation of the search parameters in the
 * searchParams object.
 * Smilar to URLSearchParams.toString() but allows to print only the selected
 * parameter.
 * @param searchParams The search parameters to print
 * @param key_to_print The key of the parameter to print. If undefined, same as URLSearchParams.toString()
 */
export function parameterToString(
  searchParams: URLSearchParams,
  key_to_print?: string
): string {
  const params = [...searchParams.entries()]
    .map(([key, value]) => {
      if (key_to_print === undefined) {
        return `${key}=${value}`;
      }
      if (key === key_to_print) {
        return `${key}=${value}`;
      }
      return undefined;
    })
    .filter(Boolean);
  return params.join('&');
}

export enum OpacitySuffix {
  NINETY_PERCENT = 'E6',
  EIGHTY_PERCENT = 'CC',
  SEVENTY_PERCENT = 'B3',
  SIXTY_PERCENT = '99',
  FIFTY_PERCENT = '80',
  FORTY_PERCENT = '66',
  THIRTY_PERCENT = '4D',
  TWENTY_PERCENT = '33',
  TEN_PERCENT = '1A',
}

export const scrollbarParams = {
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: '0.5em',
  },
  '&::-webkit-scrollbar-track': {
    background: '#F0F0F0',
    borderRadius: 6,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#BBB',
    borderRadius: 6,
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#666',
  },
};

export const getFileNameFromPath = (path?: string) => {
  if (!path) {
    return '';
  }
  return path?.split(/(\\|\/)/g).pop() || '';
};

export const getMinDate = (date1?: Date, date2?: Date): Date | undefined => {
  if (!date1 && !date2) {
    return undefined;
  }
  if (!date1) {
    return date2;
  }
  if (!date2) {
    return date1;
  }
  return date1 < date2 ? date1 : date2;
};

export const getMaxDate = (date1?: Date, date2?: Date): Date | undefined => {
  if (!date1 && !date2) {
    return undefined;
  }
  if (!date1) {
    return date2;
  }
  if (!date2) {
    return date1;
  }
  return date1 > date2 ? date1 : date2;
};
