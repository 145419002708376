import { Skeleton, useMediaQuery, useTheme } from '@mui/material';
import { AvatarDisplaySize, mapToAvatarSize } from 'components/Phonebook/utils';
import React from 'react';

export default function EmployeeAvatarSkeleton({
  size,
}: {
  size?: AvatarDisplaySize;
}) {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));
  const defaultAvatarSize: AvatarDisplaySize = isPhone
    ? AvatarDisplaySize.PHONE
    : AvatarDisplaySize.STANDARD;
  const avatarSize = mapToAvatarSize(size ?? defaultAvatarSize);
  return (
    <Skeleton
      variant="rounded"
      sx={{
        width: avatarSize,
        height: 'auto',
        aspectRatio: '1 / 1',
      }}
      animation="wave"
    />
  );
}
