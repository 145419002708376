import { GridColDef, GridToolbar, useGridApiRef } from '@mui/x-data-grid-pro';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import MuixTable from 'components/commons/MuixTable';
import {
  ActivityNode,
  BillingActivityInformationNode,
} from 'generated/graphql';
import { isEqual } from 'lodash';
import moment from 'moment';
import { EMPTY_FIELD, POLY_DATE } from 'poly-constants';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'store';
import { setActivitiesTable } from 'store/adminTables';
import urljoin from 'url-join';

import MissionTypeCell from './MissionTypeCell';
import {
  ActivityStatusEnum,
  ActivityTypeEnum,
  getActivityStatus,
  getActivityStatusChip,
  getActivityTypeName,
  getAllSectorsNames,
} from './utils';

interface ActivitiesTableProps {
  activities: ActivityNode[];
}

export default function ActivitiesTable({
  activities,
}: ActivitiesTableProps): JSX.Element {
  const activitiesTableState = useSelector(
    (state) => state.adminTables.activitiesTable
  );
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();

  const { path } = useRouteMatch();
  const history = useHistory();
  const allSectorsNames = getAllSectorsNames(activities);

  const tableState = useRef<GridInitialStatePro>();

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Mission',
      flex: 2,
      minWidth: 150,
      type: 'string',
      disableReorder: true,
    },
    {
      field: 'missionType',
      headerName: 'Type de mission',
      flex: 1,
      minWidth: 150,
      type: 'singleSelect',
      disableReorder: true,
      valueOptions: Object.values(ActivityTypeEnum),
      valueGetter: (params) => {
        return params.row ? getActivityTypeName(params.row) : EMPTY_FIELD;
      },
      renderCell: (params) => <MissionTypeCell activity={params.row} />,
    },
    {
      field: 'businessClient.name',
      headerName: 'Client',
      flex: 1,
      minWidth: 150,
      type: 'string',
      disableReorder: true,
      valueGetter: (params) => params.row.businessClient?.name || EMPTY_FIELD,
    },
    {
      field: 'startDate',
      headerName: 'Date de début',
      flex: 1,
      minWidth: 150,
      type: 'date',
      disableReorder: true,
      valueGetter: (params) => {
        return params.value ? new Date(params.value) : undefined;
      },
      renderCell: (params) =>
        params.value ? moment(params.value).format(POLY_DATE) : EMPTY_FIELD,
    },
    {
      field: 'endDate',
      headerName: 'Date de fin',
      flex: 1,
      minWidth: 150,
      type: 'date',
      disableReorder: true,
      valueGetter: (params) => {
        return params.row.expirationDate
          ? new Date(params.row.expirationDate)
          : undefined;
      },
      renderCell: (params) =>
        params.row.expirationDate
          ? moment(params.row.expirationDate).format(POLY_DATE)
          : EMPTY_FIELD,
    },
    {
      field: 'billingInformation.billingClient.sector.name',
      headerName: 'Secteur',
      flex: 1,
      minWidth: 150,
      type: 'singleSelect',
      disableReorder: true,
      valueOptions: allSectorsNames,
      valueGetter: (params) => {
        const billingInformation = params.row
          .billingInformation as unknown as BillingActivityInformationNode;

        if (
          !billingInformation ||
          !billingInformation.billingClient ||
          !billingInformation.billingClient.sector
        ) {
          return EMPTY_FIELD;
        }
        return billingInformation.billingClient.sector.name;
      },
    },
    {
      field: 'expirationDate',
      headerName: 'Statut',
      align: 'center',
      flex: 1,
      minWidth: 150,
      type: 'singleSelect',
      disableReorder: true,
      valueOptions: Object.values(ActivityStatusEnum),
      valueGetter: (params) => {
        return getActivityStatus(params.row.startDate, params.value);
      },
      renderCell: (params) => getActivityStatusChip(params.value),
      sortComparator: (v1, v2) => {
        const order = Object.values(ActivityStatusEnum);
        return order.indexOf(v1) - order.indexOf(v2);
      },
    },
  ];

  return (
    <MuixTable
      apiRef={apiRef}
      onStateChange={() => {
        const currentState = apiRef.current.exportState();
        delete currentState.columns;
        if (
          !isEqual(currentState, activitiesTableState) &&
          !!currentState.filter
        ) {
          tableState.current = currentState;
          dispatch(setActivitiesTable(currentState));
        }
      }}
      rows={activities}
      columns={columns}
      slots={{ toolbar: GridToolbar }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
        },
      }}
      autoHeight
      ignoreDiacritics
      containerWidth={'100%'}
      pagination={true}
      pageSizeOptions={[10, 15, 25, 50]}
      autosizeOnMount
      initialState={activitiesTableState}
      onRowClick={({ id }) => {
        history.push(urljoin(path, id as string));
      }}
      sx={{
        '& .MuiDataGrid-cell': {
          ':focus': {
            outline: 'none',
          },
        },
        '& .MuiDataGrid-columnHeader': {
          ':focus , :focus-within': {
            outline: 'none',
          },
        },
        '.MuiDataGrid-row:hover': {
          cursor: 'pointer',
          backgroundColor: 'primary.light',
        },
        '.MuiDataGrid-row.Mui-hovered': {
          backgroundColor: 'primary.light',
        },
        borderRadius: '8px',
      }}
      rowSelection={false}
    />
  );
}
