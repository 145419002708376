import AssignmentIcon from '@mui/icons-material/Assignment';
import { Tooltip } from '@mui/material';
import clsx from 'clsx';
import InputContainer from 'components/commons/InputContainer/InputContainer';
import React from 'react';

import lookupStyles from './styles/lookup.module.scss';
import styles from './styles/mission.module.scss';

interface MissionProps {
  missionName: string;
}

const Mission = ({ missionName }: MissionProps) => {
  return (
    <InputContainer label={'Mission associée'}>
      <div
        className={clsx(
          lookupStyles.disabledInputContainer,
          styles.missionContainer
        )}
      >
        <AssignmentIcon
          className={clsx(styles.verticallyCentered, styles.missionIcon)}
        />
        <Tooltip title={missionName}>
          <span className={clsx(styles.verticallyCentered, styles.missionText)}>
            {missionName}
          </span>
        </Tooltip>
      </div>
    </InputContainer>
  );
};

export default Mission;
