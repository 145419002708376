import { ApolloQueryResult } from '@apollo/client';
import { Divider, Stack, Typography } from '@mui/material';
import { ColumnLabelsV2, formatValue } from 'components/commons/Tables/utils';
import { useUserInfo } from 'components/User/UserProvider';
import {
  Exact,
  FetchUserActivityQuery,
  useUpdateActivityMonitoringMutation,
} from 'generated/graphql';
import _ from 'lodash';
import transform from 'lodash/transform';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import {
  Activity,
  ActivityGroupType,
  ActivityMonitoring,
} from 'pages/ActivityPage/PrincipalTablePage';
import TableContextProvider, {
  SubmitProps,
} from 'pages/ActivityPage/PrincipalTablePage/context/TableContextProvider';
import ValueContextProvider from 'pages/ActivityPage/PrincipalTablePage/context/ValueContextProvider';
import { getIsTotalInvalid } from 'pages/ActivityPage/PrincipalTablePage/TotalRow';
import { isEditableCellV2, totalDaysToWorkV2 } from 'pages/ActivityPage/utils';
import { CategoryOrder } from 'poly-constants';
import React, { useEffect, useMemo, useState } from 'react';

import { computeWorkedDays } from '../../utils';
import ActivityWeekAccordion from './ActivityWeekAccordion';
import ActivityWeekFooter from './ActivityWeekFooter';

interface ActivitySubmit {
  activityId: string;
  date: string;
  timeSpent: number;
  comment?: string;
}
interface ActivityWeekViewProps {
  week: ColumnLabelsV2;
  weekActivities: ActivityGroupType;
  refetchUserActivity: (
    variables?:
      | Partial<Exact<{ dateFrom: moment.Moment; dateTo: moment.Moment }>>
      | undefined
  ) => Promise<ApolloQueryResult<FetchUserActivityQuery>>;
  setIsDrawerOpen: (value: React.SetStateAction<boolean>) => void;
}

const ActivityWeekView = ({
  week,
  weekActivities,
  refetchUserActivity,
  setIsDrawerOpen,
}: ActivityWeekViewProps) => {
  const [enteredTotal, setEnteredTotal] = useState(0);
  const [isTotalInvalid, setIsTotalInvalid] = useState(true);

  const mergedActivities = _.flatMap(weekActivities, (category) => category);
  const daysWorked = useMemo(
    () => computeWorkedDays(mergedActivities),
    [mergedActivities]
  );

  const { employee } = useUserInfo();

  const daysToWork = useMemo(
    () => totalDaysToWorkV2(employee, week.start, week.end),
    [employee, week]
  );
  useEffect(() => {
    setEnteredTotal(daysWorked);
  }, [daysWorked]);

  useEffect(() => {
    setIsTotalInvalid(
      getIsTotalInvalid(enteredTotal, daysToWork, week, employee)
    );
  }, [enteredTotal, daysToWork, week, employee]);

  const { enqueueSnackbar } = useSnackbar();

  const isEditable = isEditableCellV2(employee, moment(), week.start, week.end);

  const [updateActivityMonitoring, { loading }] =
    useUpdateActivityMonitoringMutation({
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
      onCompleted: () => {
        enqueueSnackbar("Suivi d'activité enregistré", {
          variant: 'success',
        });
      },
    });

  const onSubmit = async (data: SubmitProps) => {
    const activityMonitoringToSend: {
      listActivity: Array<ActivityMonitoring>;
    } = { listActivity: [] };
    const listActivity = Object.keys(data['activityMonitoring']).map(
      (activityDate) => {
        return transform(
          data['activityMonitoring'][activityDate],
          (
            listActivityMonitoring: Array<ActivityMonitoring>,
            value: ActivityMonitoring,
            key: Activity['id']
          ) => {
            const submitData: ActivitySubmit = {
              activityId: key,
              date: moment(activityDate).format('YYYY-MM-DD'),
              timeSpent: value.timeSpent ? formatValue(value.timeSpent) : 0,
              comment: value.comment || '',
            };
            listActivityMonitoring.push(submitData);
          },
          []
        );
      }
    );
    activityMonitoringToSend['listActivity'] = _.flatten(listActivity);
    await updateActivityMonitoring({
      variables: {
        input: activityMonitoringToSend,
      },
    });
    refetchUserActivity();
    setIsDrawerOpen(false);
  };

  return (
    <TableContextProvider
      dateRange={{ start: week.start, end: week.end }}
      numberOfDisplayedWeeks={1}
      numberOfWeeks={1}
      numberOfModifiableWeeks={1}
      onSubmit={onSubmit}
      hasTMActivity={false}
    >
      <ValueContextProvider>
        <Stack m={2}>
          <Typography variant="bodyBold">
            {'Semaine ' +
              (week.weekPart
                ? week.weekNumber + ' - ' + week.weekPart
                : week.weekNumber)}
          </Typography>
        </Stack>
        <Stack
          direction="column"
          px={2}
          sx={{
            maxHeight: Number(window.innerHeight * 0.5),
            overflowY: 'auto',
          }}
        >
          {_.orderBy(Object.keys(weekActivities), (value: string) =>
            _.get(CategoryOrder, value, 0)
          ).map((category, index) => {
            return (
              <ActivityWeekAccordion
                key={`category-${category}`}
                defaultExpanded={index === 0}
                week={week}
                category={category}
                listActivity={weekActivities[category]}
                disabled={!isEditable}
                setEnteredTotal={setEnteredTotal}
              />
            );
          })}
          <Divider />
        </Stack>

        <ActivityWeekFooter
          week={week}
          enteredTotal={enteredTotal}
          disabled={loading || isTotalInvalid || !isEditable}
        ></ActivityWeekFooter>
      </ValueContextProvider>
    </TableContextProvider>
  );
};

export default ActivityWeekView;
