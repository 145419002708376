import {
  Box,
  Stack,
  Switch,
  SxProps,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

interface Props {
  icon?: React.ReactElement;
  title: string;
  children?: string;
  checked?: boolean;
  disabled?: boolean;
  sx?: SxProps;
}

const ToggleButton = ({
  icon,
  title,
  children,
  checked: isChecked,
  disabled,
  sx,
}: Props) => {
  const [checked, setChecked] = useState(isChecked);
  const handleClick = () => {
    if (!disabled) {
      setChecked((oldState) => !oldState);
    }
  };
  return (
    <Tooltip
      title={disabled && (checked ? 'Activé' : 'Désactivé')}
      followCursor
    >
      <Stack
        flex={1}
        onClick={handleClick}
        sx={{
          border: '1px solid',
          borderColor: checked ? 'primary.main' : 'darkGrey.dark',
          backgroundColor: 'lightGrey.main',
          borderRadius: '8px',
          cursor: disabled ? 'not-allowed' : 'pointer',
          userSelect: 'none',
          p: 1.5,
          gap: 1,
          ...sx,
        }}
      >
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: 38,
          }}
        >
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 1,
            }}
          >
            {icon && (
              <Box
                sx={{
                  overflow: 'hidden',
                  borderRadius: '6px',
                  width: 34,
                  height: 34,
                  backgroundColor: checked ? 'primary.light' : 'darkGrey.main',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {React.cloneElement(icon, {
                  sx: {
                    color: checked ? 'primary.main' : 'black.main',
                    fontSize: 26,
                  },
                })}
              </Box>
            )}

            <Typography
              variant="bodySemiBold"
              sx={{ color: checked ? 'primary.main' : 'black.main' }}
            >
              {title}
            </Typography>
          </Stack>
          {!disabled && (
            <Switch
              disabled={disabled}
              checked={checked}
              sx={{
                mr: -1.25,
              }}
            />
          )}
        </Stack>
        {children && (
          <Typography variant="bodyS" color="text.secondary">
            {children}
          </Typography>
        )}
      </Stack>
    </Tooltip>
  );
};

export default ToggleButton;
