import ClearIcon from '@mui/icons-material/Clear';
import BillActionModal, {
  BillNumberInput,
} from 'components/MissionFollowUp/BillDetail/Modals/BillActionModal/BillActionModal';
import styles from 'components/MissionFollowUp/BillDetail/Modals/CreateOrUpdateBillModal/styles/BillModal.module.scss';
import LabelTextFieldGrid from 'components/MissionFollowUp/LabelTextFieldGrid';
import DisplayBillStatus from 'components/MissionFollowUp/OverallListBill/DisplayStatus/DisplayBillStatus';
import PongoButton from 'components/MUIOverload/PongoButton';
import {
  BillingBillStatusChoices,
  BillNode,
  useUpdateBillStatusMutation,
} from 'generated/graphql';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { setCurrentBill } from 'store/purchaseOrder';

interface ControlUpdateToBeCanceledBillModalProps {
  open: boolean;
  oldStatus: BillingBillStatusChoices;
  onClose: () => void;
}

function ControlUpdateToBeCanceledBillModal(
  properties: ControlUpdateToBeCanceledBillModalProps
) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const currentBill = useSelector((state) => state.purchaseOrder.currentBill);

  const [updateStatus] = useUpdateBillStatusMutation({
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    onCompleted: (data) => {
      const bill = data.updateBillStatus?.bills?.[0] as BillNode;
      enqueueSnackbar(
        `Le statut de la facture « ${currentBill.title} » a été mis à jour`,
        {
          variant: 'success',
        }
      );
      dispatch(setCurrentBill(bill));
      properties.onClose();
    },
  });

  const defaultValues = {
    bills: [] as BillNumberInput[],
    status: BillingBillStatusChoices.ToBill,
  };

  const form = useForm({
    defaultValues: defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldUnregister: false,
  });

  const onSubmit = async (submitValues: typeof defaultValues) => {
    await updateStatus({
      variables: {
        status: properties.oldStatus,
        bills: _.map(submitValues.bills, (bill) => {
          return {
            id: bill.id || '',
            billNumber: bill.number,
          };
        }),
      },
    });
  };

  return (
    <BillActionModal
      open={properties.open}
      title={"Ignorer la demande d'annulation"}
      formProvider={{
        form: form,
        submit: () => form.handleSubmit(onSubmit)(),
      }}
      closeModal={properties.onClose}
      bodyModal={
        <p className={styles.infosContainer}>
          <LabelTextFieldGrid
            title={"Commentaire justifiant la demande d'annulation"}
            multiline
            rows={4}
            disabled
            sizegrid={12}
            customspacingtop={0}
            customspacingside={0}
            className={styles.deletionComment}
            value={_.get(currentBill, 'cancellationCommentary.message')}
          />
          <span>
            Êtes-vous sûr(e) de vouloir ignorer la demande d&apos;annulation
            pour cette facture?
            <span className={styles.underMainText}>
              La facture sera remise dans son état initial et sera marquée comme
              <span className={styles.statusContainer}>
                <DisplayBillStatus status={properties.oldStatus} />
              </span>
            </span>
          </span>
        </p>
      }
      customFooter={
        <PongoButton
          variant={'contained'}
          buttonStyle="secondary"
          onClick={form.handleSubmit(onSubmit)}
          startIcon={<ClearIcon />}
        >
          Ignorer la demande
        </PongoButton>
      }
    />
  );
}

export default ControlUpdateToBeCanceledBillModal;
