import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import React, { useState } from 'react';

import EventModal from './EventModal';
import { formatDateRange, generateColorFromString } from './utils';

export interface EventDaysProps {
  tag: string;
  title: string;
  startDate?: string;
  endDate?: string;
  // the backend can return null times
  startTime?: string | null;
  endTime?: string | null;
  description?: string;
  isNew?: boolean;
}

const EventCard = ({
  tag,
  title,
  startDate,
  startTime,
  endDate,
  endTime,
  description,
  isNew,
}: EventDaysProps) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const formattedDateRange = formatDateRange(
    startDate,
    startTime,
    endDate,
    endTime
  );

  const cleanHTML = DOMPurify.sanitize(description || '', {
    USE_PROFILES: { html: true },
  });

  const tagColor = generateColorFromString(tag);

  return (
    <>
      <Badge
        badgeContent={'NEW !'}
        color="primary"
        invisible={!isNew}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        sx={{ width: '100%', marginLeft: 3, marginTop: 2 }}
      >
        <Card
          sx={{
            height: 200,
            marginRight: 3,
            paddingRight: 2,
            marginBottom: 1,
            overflow: 'hidden',
            cursor: 'pointer',
            backgroundColor: 'background.default',
            width: '100%',
          }}
          onClick={() => setModalOpen(true)}
        >
          <CardContent
            sx={{
              height: '100%',
              width: '100%',
              paddingLeft: 1,
              overflow: 'hidden',
              '-webkit-mask-image':
                'linear-gradient(0deg, transparent 40px, white 75px)',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                variant="h5"
                component="div"
                mr={1}
                mb={1}
                sx={{
                  display: '-webkit-box',
                  '-webkitLineClamp': '2',
                  '-webkit-box-orient': 'vertical',
                  overflow: 'hidden',
                  fontWeight: 'bold',
                }}
              >
                {title}
              </Typography>
              <Chip
                label={tag}
                variant="outlined"
                sx={{
                  borderColor: tagColor,
                  color: tagColor,
                }}
              />
            </Box>
            <Typography variant="bodySemiBold" color="text.secondary" mb={1}>
              {formattedDateRange}
            </Typography>
            <Typography variant="body">{parse(cleanHTML)}</Typography>
          </CardContent>
        </Card>
      </Badge>
      <EventModal
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        tag={tag}
        title={title}
        startDate={startDate}
        startTime={startTime}
        endDate={endDate}
        endTime={endTime}
        description={description}
      />
    </>
  );
};

export default EventCard;
