import { Grid } from '@mui/material';
import DatePicker from 'components/commons/DatePicker';
import MonthPicker from 'components/commons/MonthPicker';
import { getRangeFromDate } from 'components/commons/MonthPicker/MonthPickerTools';
import GridItem from 'components/MissionFollowUp/GridItem';
import { BillingBillStatusChoices } from 'generated/graphql';
import moment from 'moment';
import { isDate, requiredForm } from 'pages/MissionFollowUp/formValidators';
import React, { ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'store';

interface BillDatePickersProps {
  isEditing: boolean;
  isViewing: boolean;
}

export default function BillDatePickers({
  isEditing,
  isViewing,
}: BillDatePickersProps): ReactElement {
  const currentPurchaseOrder = useSelector(
    (state) => state.purchaseOrder.currentPurchaseOrder
  );

  const currentBill = useSelector((state) => state.purchaseOrder.currentBill);
  const isBillBilled = currentBill.status === BillingBillStatusChoices.Billed;

  const form = useFormContext();
  const { control, errors, setValue } = form;

  return (
    <>
      <Grid container item xs={12}>
        <GridItem sizegrid={6}>
          <Controller
            name="date"
            control={control}
            rules={{ required: requiredForm, validate: { isDate: isDate } }}
            render={({ onChange, value }) => (
              <DatePicker
                label="À facturer le"
                errorForm={errors.date}
                value={value}
                disabled={!isEditing && isViewing}
                onChange={(date) => {
                  onChange(date);
                }}
                required
              />
            )}
          />
        </GridItem>
        {isBillBilled && (
          <>
            <Grid container item xs={12}>
              <GridItem sizegrid={6} customspacingtop={30}>
                <DatePicker
                  label="Date de facturation"
                  value={
                    currentBill.billingDate
                      ? new Date(currentBill.billingDate)
                      : undefined
                  }
                  disabled
                />
              </GridItem>
              <GridItem sizegrid={6} customspacingtop={30}>
                <DatePicker
                  label="Date d'échéance"
                  value={
                    currentBill.dueDate
                      ? new Date(currentBill.dueDate)
                      : undefined
                  }
                  disabled
                />
              </GridItem>
            </Grid>
          </>
        )}
        <Grid container item xs={12}>
          <GridItem sizegrid={6} customspacingtop={30}>
            <MonthPicker
              rules={{ required: requiredForm }}
              form={form}
              label={'Mois facturés'}
              range={getRangeFromDate(
                currentPurchaseOrder.periodBeginning,
                currentPurchaseOrder.periodEnding
              )}
              name={'billMonthRange'}
              disabled={!isEditing && isViewing}
              onChange={(rangeMonth) => {
                const newRangeMonthFrom = moment(rangeMonth.from);
                setValue('billMonthRange.from', newRangeMonthFrom);

                const newRangeMonthTo = moment(rangeMonth.to);
                setValue('billMonthRange.to', newRangeMonthTo);
              }}
            />
          </GridItem>
        </Grid>
      </Grid>
    </>
  );
}
