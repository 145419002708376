import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Box, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { Chart } from 'chart.js';
import PongoButton from 'components/MUIOverload/PongoButton';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { Line } from 'react-chartjs-2';

import NoDataForThisYearAlert from '../NoDataForThisYearAlert';
import { MONTH_IN_YEAR_LABELS } from '../utils';
import AdrGraphHeader from './AdrGraphHeader';
import {
  Curve,
  defaultOptions,
  useAdrGraphContext,
} from './context/AdrGraphContextProvider';

export default function MultiAdrGraph({
  isInJanuary,
}: {
  isInJanuary: boolean;
}) {
  const {
    displayedCurves,
    setDisplayedCurves,
    graphData,
    loading,
    setLoading,
  } = useAdrGraphContext();

  const TJMData = {
    labels: MONTH_IN_YEAR_LABELS,
    datasets: _.map(displayedCurves, (curve: Curve) => {
      return {
        data: curve.data,
        label: curve.name,
      };
    }),
  };

  const plugins = [
    {
      id: 'set-legend-color',
      beforeRender: (chart: Chart) => {
        const legendItems = chart.legend?.legendItems;
        if (
          _.some(
            displayedCurves,
            (curve: Curve, index) =>
              curve.color !== legendItems?.[index].strokeStyle
          )
        ) {
          setDisplayedCurves(
            _.map(displayedCurves, (curve: Curve, index) => {
              curve.color = legendItems?.[index].strokeStyle as string;
              return curve;
            })
          );
        }
      },
    },
  ];

  const options = {
    responsive: true,
    lineTension: 0.2,
    plugins: {
      colors: {
        enabled: true,
        forceOverride: true,
      },
      legend: {
        display: false,
      },
    },
  };

  const getLastFreeId = () => {
    return _.reduce(
      displayedCurves,
      (acc, curve: Curve) => {
        if (curve.id >= acc) {
          return curve.id + 1;
        }
        return acc;
      },
      0
    );
  };

  const addNewCurve = () => {
    setLoading(true);
    setDisplayedCurves([
      ...displayedCurves,
      {
        id: getLastFreeId(),
        data: graphData?.allMissionsData || [],
        options: defaultOptions,
        name: 'Toutes les missions',
      },
    ]);
  };

  useEffect(() => {
    if (displayedCurves.length > 0) {
      setLoading(false);
    }
  }, [displayedCurves, setLoading]);

  return (
    <Box sx={{ mt: 2 }}>
      <Stack direction="row" sx={{ alignItems: 'center', mb: 2 }}>
        <Typography variant={'h2Bold'} component="h2" ml={4}>
          TJMs vendus
        </Typography>
        <Tooltip
          arrow
          title={
            <Box alignItems={'center'}>
              <Typography color="white" fontSize={'14px'} fontWeight={'bold'}>
                TJM : CA / Jours réalisés
              </Typography>
              <Typography color="white" fontSize={'14px'} fontWeight={'bold'}>
                TJM sans stagiaires : CA / Jours réalisés CDI
              </Typography>
            </Box>
          }
          placement={'bottom'}
        >
          <InfoIcon
            sx={{
              transform: 'scale(0.7)',
              position: 'relative',
              color: 'text.secondary',
            }}
          />
        </Tooltip>
        {!isInJanuary && (
          <PongoButton
            variant="contained"
            buttonStyle="secondary"
            onClick={addNewCurve}
            size="small"
            startIcon={<AddIcon />}
            sx={{ ml: 1 }}
          >
            Ajouter une courbe
          </PongoButton>
        )}
      </Stack>
      {isInJanuary ? (
        <NoDataForThisYearAlert />
      ) : (
        <>
          <AdrGraphHeader />
          <Box
            sx={{
              width: 1,
              display: 'flex',
              justifyContent: 'space-around',
              mt: 2,
            }}
          >
            <Box sx={{ width: 0.8, aspectRatio: '2' }}>
              {loading ? (
                <Skeleton variant="rounded" height="100%" />
              ) : (
                <Line data={TJMData} options={options} plugins={plugins} />
              )}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}
