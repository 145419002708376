import React, { PropsWithChildren } from 'react';

import styles from './styles/PolyFooter.module.scss';

const PolyFooter = (properties: PropsWithChildren<{}>) => {
  return (
    <div className={styles.root}>
      <div className={styles.footer_root}>{properties.children}</div>
    </div>
  );
};

export default PolyFooter;
