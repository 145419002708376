import BackdropLoading from 'components/commons/BackdropLoading';
import PageContent from 'components/commons/PageContent';
import PageWrapper from 'components/commons/PageWrapper';
import PolyTitle from 'components/commons/PolyTitle';
import PolyDataGrid from 'components/MUIOverload/PolyDataGrid';
import { PolyDataGridColumn } from 'components/MUIOverload/PolyDataGrid/PolyDataGridHead';
import { OvertimeRequestsQuery } from 'generated/graphql';
import React from 'react';

interface ExceptionalActivitiesRequestPanelProps {
  title: string;
  body: string;
  columns: PolyDataGridColumn<
    DeepExtractTypeSkipArrays<OvertimeRequestsQuery, ['overtimeRequests']>
  >[];
  asManager: boolean;
  loadingOvertimeRequests: boolean;
  overtimeRequestsData: OvertimeRequestsQuery | undefined;
}

const ExceptionalActivitiesRequestPanel = ({
  body,
  title,
  columns,
  overtimeRequestsData,
  loadingOvertimeRequests,
}: ExceptionalActivitiesRequestPanelProps) => {
  const getRows = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    orderBy: string | string[] | undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    order: 'asc' | 'desc' | undefined
  ) => {
    if (
      overtimeRequestsData === undefined ||
      overtimeRequestsData.overtimeRequests === undefined
    ) {
      return [];
    }

    return overtimeRequestsData.overtimeRequests;
  };
  return (
    <>
      <PageWrapper noLimit>
        <PolyTitle>{title}</PolyTitle>
        <PageContent>{body}</PageContent>
        {!loadingOvertimeRequests && (
          <PolyDataGrid columns={columns} getRows={getRows} />
        )}
      </PageWrapper>
      {loadingOvertimeRequests && (
        <BackdropLoading open={loadingOvertimeRequests} />
      )}
    </>
  );
};

export default ExceptionalActivitiesRequestPanel;
