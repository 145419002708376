import { Tooltip } from '@mui/material';
import DisplayBillStatus from 'components/MissionFollowUp/OverallListBill/DisplayStatus/DisplayBillStatus';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import {
  BillingBillPaymentStatusChoices,
  BillingBillStatusChoices,
  BillingPurchaseOrderCurrencyChoices,
  BillNode,
} from 'generated/graphql';
import { totalFormat } from 'pages/ActivityPage/utils';
import React from 'react';

import DisplayBillPaymentStatus from '../DisplayStatus/DisplayBillPaymentStatus';
import styles from './styles/ResumeBillsStatus.module.scss';

interface purchaseOrderStatusStats {
  billsCount: number;
  billsSum: number;
}

interface purchaseOrderStats {
  [key: string]: purchaseOrderStatusStats;
}

const statusStats = () => {
  return { billsCount: 0, billsSum: 0 };
};

export default function ResumeBillsStatus(
  bills: BillNode[],
  currency: BillingPurchaseOrderCurrencyChoices
) {
  const SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED =
    !!useEnvironmentVariable('send_bill_email');
  const billStatusWhenReadyForPayment = SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED
    ? BillingBillStatusChoices.Sent
    : BillingBillStatusChoices.Billed;
  const purchaseOrderStats: purchaseOrderStats = {
    [BillingBillStatusChoices.Draft]: statusStats(),
    [BillingBillStatusChoices.ToBill]: statusStats(),
    [BillingBillStatusChoices.Billed]: statusStats(),
    [BillingBillPaymentStatusChoices.Pending]: statusStats(),
    [BillingBillPaymentStatusChoices.Recovering]: statusStats(),
    [BillingBillPaymentStatusChoices.Paid]: statusStats(),
  };

  bills?.forEach((bill) => {
    if (bill.billReference) {
      return;
    }
    if (bill.status in purchaseOrderStats) {
      purchaseOrderStats[bill.status].billsCount += 1;
      purchaseOrderStats[bill.status].billsSum += bill.total;
    }
    if (
      bill.status === billStatusWhenReadyForPayment &&
      bill.paymentStatus in purchaseOrderStats
    ) {
      purchaseOrderStats[bill.paymentStatus].billsCount += 1;
      purchaseOrderStats[bill.paymentStatus].billsSum += bill.total;
    }
  });
  return (
    <div className={styles.container}>
      <Tooltip
        title={`Montant en brouillon : ${totalFormat(
          purchaseOrderStats[BillingBillStatusChoices.Draft].billsSum,
          currency
        )}`}
      >
        <div className={styles.element}>
          {DisplayBillStatus({
            status: BillingBillStatusChoices.Draft,
            iconOnly: true,
          })}
          {purchaseOrderStats[BillingBillStatusChoices.Draft].billsCount}
        </div>
      </Tooltip>
      <Tooltip
        title={`Montant à ${
          SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED
            ? 'comptabiliser ou à envoyer'
            : 'facturer'
        } : ${totalFormat(
          purchaseOrderStats[BillingBillStatusChoices.ToBill].billsSum +
            (SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED
              ? purchaseOrderStats[BillingBillStatusChoices.Billed].billsSum
              : 0),
          currency
        )}`}
      >
        <div className={styles.element}>
          {DisplayBillStatus({
            status: BillingBillStatusChoices.ToBill,
            iconOnly: true,
          })}
          {purchaseOrderStats[BillingBillStatusChoices.ToBill].billsCount +
            (SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED
              ? purchaseOrderStats[BillingBillStatusChoices.Billed].billsCount
              : 0)}
        </div>
      </Tooltip>
      <Tooltip
        title={`Montant en attente de paiement : ${totalFormat(
          purchaseOrderStats[BillingBillPaymentStatusChoices.Pending].billsSum,
          currency
        )}`}
      >
        <div className={styles.element}>
          {DisplayBillPaymentStatus({
            status: BillingBillPaymentStatusChoices.Pending,
            iconOnly: true,
          })}
          {
            purchaseOrderStats[BillingBillPaymentStatusChoices.Pending]
              .billsCount
          }
        </div>
      </Tooltip>
      <Tooltip
        title={`Montant en recouvrement : ${totalFormat(
          purchaseOrderStats[BillingBillPaymentStatusChoices.Recovering]
            .billsSum,
          currency
        )}`}
      >
        <div className={styles.element}>
          {DisplayBillPaymentStatus({
            status: BillingBillPaymentStatusChoices.Recovering,
            iconOnly: true,
          })}
          {
            purchaseOrderStats[BillingBillPaymentStatusChoices.Recovering]
              .billsCount
          }
        </div>
      </Tooltip>
      <Tooltip
        title={`Montant payé : ${totalFormat(
          purchaseOrderStats[BillingBillPaymentStatusChoices.Paid].billsSum,
          currency
        )} `}
      >
        <div className={styles.element}>
          {DisplayBillPaymentStatus({
            status: BillingBillPaymentStatusChoices.Paid,
            iconOnly: true,
          })}
          {purchaseOrderStats[BillingBillPaymentStatusChoices.Paid].billsCount}
        </div>
      </Tooltip>
    </div>
  );
}
