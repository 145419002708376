import { Box, DialogContentText, Grid, Typography } from '@mui/material';
import FieldSelection from 'components/commons/FieldSelection';
import { useFilterContext } from 'components/commons/FilterContext/FilterContextProvider';
import MonthPicker from 'components/commons/MonthPicker';
import { getRangeFromDate } from 'components/commons/MonthPicker/MonthPickerTools';
import { createCsvFile } from 'components/commons/PolyExportButton';
import PolyModal from 'components/MUIOverload/PolyDialog/PolyModal';
import {
  ActivitiesActivityBillingTypeChoices,
  ActivityNode,
  List_Field_Export_Actual_Revenue,
  useFetchExportActualRevenueMissionViewLazyQuery,
} from 'generated/graphql';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { graphQlDateFormatter } from 'utils';

import { useTableContext } from './context/TableContextProvider';

interface ModalProps {
  isOpen: boolean;
  handleClose: () => void;
  title: string;
  body: string;
  activity: ActivityNode;
}

const actualRevenueExportFieldsCorrespondance = {
  [List_Field_Export_Actual_Revenue.Tasks]: 'Chantiers',
  [List_Field_Export_Actual_Revenue.MissionName]: 'Mission',
  [List_Field_Export_Actual_Revenue.PurchaseOrderName]: 'Nom BDC',
  [List_Field_Export_Actual_Revenue.TotalAmountPo]: 'Montant BDC (HT)',
};

export default function ExportActualRevenueMissionModal({
  isOpen,
  handleClose,
  title,
  body,
  activity,
}: ModalProps) {
  useFilterContext();
  const form = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const { displayedMonths } = useTableContext();
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    moment.min(displayedMonths).clone().startOf('month').toDate(),
    moment.max(displayedMonths).clone().endOf('month').toDate(),
  ]);

  const selectableFields = GetSelectableFields(
    activity.billingType === ActivitiesActivityBillingTypeChoices.Tm
  );
  const [listFields, setListFields] =
    useState<List_Field_Export_Actual_Revenue[]>(selectableFields);
  const [exportQuery] = useFetchExportActualRevenueMissionViewLazyQuery({
    onCompleted: (data) => {
      if (data && data.exportActualRevenueMissionView) {
        const dateFromString: string = dateRange[0].toLocaleDateString(
          undefined,
          {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }
        );
        const dateToString: string = dateRange[1].toLocaleDateString(
          undefined,
          {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }
        );
        const filename = `revenues_realises_${dateFromString}_${dateToString}_${activity.name}.csv`;
        createCsvFile(data.exportActualRevenueMissionView, filename);
        handleClose();
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
      handleClose();
    },
  });

  useEffect(() => {
    setDateRange([
      moment.min(displayedMonths).clone().startOf('month').toDate(),
      moment.max(displayedMonths).clone().endOf('month').toDate(),
    ]);
  }, [displayedMonths]);

  const exportTable = () => {
    exportQuery({
      variables: {
        dateFrom: graphQlDateFormatter(dateRange[0]),
        dateTo: graphQlDateFormatter(dateRange[1]),
        activityId: activity.id,
        listFields: listFields,
      },
    });
  };

  return (
    <PolyModal
      open={isOpen}
      onClose={handleClose}
      polyDialogTitle={title}
      handlePrimary={exportTable}
      primaryButtonName={'Télécharger'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Grid
        container
        spacing={2}
        alignItems="center"
        sx={{
          mb: 2,
          pr: 2,
        }}
      >
        <Grid item xs={12}>
          <DialogContentText id="alert-dialog-description">
            {body}
          </DialogContentText>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="bodyBold" sx={{ ml: 1 }}>
            Dates :
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ my: 2 }}>
            <MonthPicker
              form={form}
              label={'Mois facturés'}
              range={getRangeFromDate(
                moment.min(displayedMonths).clone().add(-20, 'year').toDate(),
                moment.min(displayedMonths).clone().add(20, 'year').toDate()
              )}
              name={'actualRevenueMonthRange'}
              onChange={(rangeMonth) => {
                setDateRange([
                  moment(rangeMonth.from).startOf('month').toDate(),
                  moment(rangeMonth.to).endOf('month').toDate(),
                ]);
              }}
              value={{
                from: {
                  month: moment.min(displayedMonths).month() + 1,
                  year: moment.min(displayedMonths).year(),
                },
                to: {
                  month: moment.max(displayedMonths).month() + 1,
                  year: moment.max(displayedMonths).year(),
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="bodyBold" sx={{ ml: 1 }}>
            Champs de données :
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <FieldSelection
            listFields={listFields}
            setListFields={setListFields}
            selectableFields={selectableFields}
            fieldsCorrespondance={actualRevenueExportFieldsCorrespondance}
          />
        </Grid>
      </Grid>
    </PolyModal>
  );
}
function GetSelectableFields(isTmContracts: boolean) {
  if (isTmContracts) {
    const fields = Object.keys(
      actualRevenueExportFieldsCorrespondance
    ) as List_Field_Export_Actual_Revenue[];
    return fields.filter(
      (field) => field !== List_Field_Export_Actual_Revenue.Tasks
    );
  }
  return Object.keys(
    actualRevenueExportFieldsCorrespondance
  ) as List_Field_Export_Actual_Revenue[];
}
