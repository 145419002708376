import { Stack, Typography } from '@mui/material';
import React from 'react';

export default function NoResultIcon() {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      padding={5}
    >
      <img src="/img/no_result.svg" alt="No user found" />
      <Typography variant="h3">Aucun résultat trouvé</Typography>
    </Stack>
  );
}
