import { SxProps, TextField } from '@mui/material';
import { CalendarPickerView } from '@mui/x-date-pickers-pro';
import { DesktopDatePicker as DatePickerMui } from '@mui/x-date-pickers/DesktopDatePicker';
import React, { PropsWithChildren } from 'react';
import { FieldError } from 'react-hook-form';

interface DatePickerProps {
  minDate?: Date;
  maxDate?: Date;
  onChange: (date: Date | null) => void;
  disabled?: boolean;
  views?: CalendarPickerView[];
  error?: boolean;
  errorMessage?: string;
  errorForm?: FieldError;
  value?: Date | null;
  label?: string;
  required?: boolean;
  sx?: SxProps;
}
const getInputFormat = (views: CalendarPickerView[] | undefined) => {
  if (!views) {
    return 'dd/MM/yyyy';
  }
  let format = '';
  if (views.includes('day')) {
    format = `dd`;
  }
  if (views.includes('month')) {
    format = `${format}${format ? '/MM' : 'MM'}`;
  }
  if (views.includes('year')) {
    format = `${format}${format ? '/yyyy' : 'yyyy'}`;
  }
  return format;
};
const DatePicker = (properties: PropsWithChildren<DatePickerProps>) => {
  return (
    <>
      <DatePickerMui
        label={properties.label}
        value={properties.value}
        disabled={properties.disabled}
        onChange={properties.onChange}
        views={properties.views}
        minDate={properties.minDate}
        maxDate={properties.maxDate}
        inputFormat={getInputFormat(properties.views)}
        toolbarTitle={'Choisir une date'}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={params.inputRef}
            required={properties.required}
            error={properties.error || !!properties.errorForm}
            helperText={
              properties.errorMessage || properties.errorForm?.message
            }
            variant="standard"
          />
        )}
      />
    </>
  );
};

export default DatePicker;

DatePicker.defaultProps = {
  onChange: () => {
    return;
  },
};
