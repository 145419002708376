import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

/* we use the useRef hook to store the mutable value to keep track
   if it's the first time the useEffect function is being run.
*/
const useOnMountEffect = (effect: EffectCallback, deps?: DependencyList) => {
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return effect();
    }
  }, [effect, deps]);
};

export default useOnMountEffect;
