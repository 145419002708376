import Close from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { SxProps } from '@mui/material/styles';
import PolyDialogTitle from 'components/MUIOverload/PolyDialogTitle';
import React from 'react';

interface PolyModalHeaderProps {
  handleClose: () => void;
  polyDialogTitle: string | React.ReactElement;
  polyDialogIcon?: React.ReactElement;
  sx?: SxProps;
}

export default function PolyModalHeader({
  handleClose,
  polyDialogTitle,
  polyDialogIcon,
  sx,
}: PolyModalHeaderProps) {
  return (
    <Stack
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'space-between'}
      sx={sx}
    >
      <PolyDialogTitle color="primary" sx={{ pt: 0 }} maxWidth="90%">
        {polyDialogIcon}
        {polyDialogTitle}
      </PolyDialogTitle>
      <IconButton
        onClick={handleClose}
        color={'primary'}
        sx={{ p: 0, height: '36px', width: '36px' }}
        size="large"
      >
        <Close />
      </IconButton>
    </Stack>
  );
}
