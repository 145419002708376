import { Chip, ChipProps } from '@mui/material';
import clsx from 'clsx';
import DisplayBillPaymentStatus from 'components/MissionFollowUp/OverallListBill/DisplayStatus/DisplayBillPaymentStatus';
import { BillingBillPaymentStatusChoices } from 'generated/graphql';
import _ from 'lodash';
import React from 'react';

import styles from './styles/BillStatusChip.module.scss';

interface BillStatusPaymentChipProps {
  status: BillingBillPaymentStatusChoices;
  onClick: () => void;
  isActive: boolean;
}

const BillStatusPaymentChip = (
  properties: BillStatusPaymentChipProps & ChipProps
) => {
  const stylesChecked = {
    PENDING: styles.pendingChecked,
    PAID: styles.paidChecked,
    RECOVERING: styles.recoveringChecked,
    LITIGATION: styles.litigationChecked,
  };

  return (
    <Chip
      className={clsx(styles.billChip, properties.className, {
        [_.get(stylesChecked, properties.status)]: properties.isActive,
      })}
      variant={properties.isActive ? 'filled' : 'outlined'}
      label={DisplayBillPaymentStatus({
        status: properties.status,
        checked: properties.isActive,
        iconSize: '25px',
      })}
      onClick={properties.onClick}
    />
  );
};

export default BillStatusPaymentChip;
