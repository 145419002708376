import { DialogContent, TextField } from '@mui/material';
import PolyDialog from 'components/MUIOverload/PolyDialog';
import PolyDialogActions from 'components/MUIOverload/PolyDialog/DefaultComponents/PolyDialogActions';
import PolyModalHeader from 'components/MUIOverload/PolyDialog/DefaultComponents/PolyModalHeader';
import _ from 'lodash';
import {
  providerCommentsInterface,
  useValueContext,
} from 'pages/ActivityPage/PrincipalTablePage/context/ValueContextProvider';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

interface ProviderEditCommentModalProps {
  handleClose: () => void;
  open: boolean;
  week: string;
  activityId: string;
  comment: string;
  setComment: (comment: string) => void;
  formReferenceName: string;
}

export default function ProviderEditCommentModal({
  handleClose,
  open,
  week,
  activityId,
  comment,
  setComment,
  formReferenceName,
}: ProviderEditCommentModalProps) {
  const { providerComments, setProviderComments, providerOvertimeWeeks } =
    useValueContext();
  const { register, setValue } = useFormContext();

  useEffect(() => {
    if (providerOvertimeWeeks[week]) {
      setValue(formReferenceName, comment);
    } else {
      // set comment to empty string if week had a comment but is not anymore in overtime
      setValue(formReferenceName, '');
    }
  }, [setValue, formReferenceName, comment, providerOvertimeWeeks, week]);

  const hasAlreadyExistingComment = _.some(providerComments, {
    activityId: activityId,
    week: week,
  });

  const updateProviderComments = () => {
    const updatedProviderComments = _.map(
      providerComments,
      (commentObject: providerCommentsInterface) => {
        if (
          commentObject.activityId === activityId &&
          commentObject.week === week
        ) {
          return { ...commentObject, comment: comment };
        }
        return commentObject;
      }
    );
    setProviderComments(updatedProviderComments);
  };

  const handleSubmit = () => {
    if (hasAlreadyExistingComment) {
      updateProviderComments();
    } else {
      setProviderComments([
        ...providerComments,
        { activityId: activityId, week: week, comment: comment },
      ]);
    }
    handleClose();
  };

  return (
    <PolyDialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: '600px' } }}
    >
      <DialogContent sx={{ padding: 2, paddingBottom: 1 }}>
        <PolyModalHeader
          handleClose={handleClose}
          polyDialogTitle={'Justification temps supplémentaire'}
          sx={{ paddingBottom: 2 }}
        />
        <TextField
          sx={{ width: 1, mr: 2, mb: 2, color: 'text.secondary' }}
          defaultValue={comment}
          onChange={(event) => setComment(event.target.value)}
          id="providerComment"
          placeholder="Veuillez renseigner ici la raison pour laquelle vous avez dépassé votre
          temps contractuel"
          variant="outlined"
          multiline
          rows={4}
          inputRef={register()}
          name={formReferenceName}
        />
      </DialogContent>
      <PolyDialogActions
        handlePrimary={handleSubmit}
        handleSecondary={handleClose}
        isPrimaryButtonDisabled={!hasAlreadyExistingComment && !comment.length}
      />
    </PolyDialog>
  );
}
