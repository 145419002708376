import { Grid, Stack, Typography } from '@mui/material';
import Section from 'components/commons/Section';
import SectionTitle from 'components/commons/SectionTitle';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import { ehpUrl } from 'poly-constants';
import React from 'react';

import packageJson from '../../../package.json';

const VersionDisplay = () => {
  const BACK_VERSION = useEnvironmentVariable('VERSION');
  const backVersion: string =
    BACK_VERSION && BACK_VERSION !== '' ? BACK_VERSION : '0';
  const frontVersion = packageJson.version;

  const url: string = window.location.href;
  const shouldDisplay: boolean = url.includes(ehpUrl);
  if (shouldDisplay) {
    return (
      <Grid item xs={12}>
        <Section>
          <SectionTitle>Version</SectionTitle>
          <Stack>
            <Typography>Backend: {backVersion}</Typography>
            <Typography>Frontend: {frontVersion}</Typography>
          </Stack>
        </Section>
      </Grid>
    );
  }
  return <></>;
};

export default VersionDisplay;
