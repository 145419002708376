import { Dialog, DialogProps } from '@mui/material';
import PolyFooter from 'components/MUIOverload/PolyFooter';
import React, { ReactNode } from 'react';

import styles from './styles/PolyDialog.module.scss';

interface PolyDialogProps {
  footer?: ReactNode;
}

export default function PolyDialog(props: DialogProps & PolyDialogProps) {
  return (
    <Dialog {...props} className={styles.paper}>
      {props.children}
      {props.footer && <PolyFooter>{props.footer}</PolyFooter>}
    </Dialog>
  );
}
