import { Stack, Typography } from '@mui/material';
import React from 'react';

interface DescriptionSectionProps {
  title: string;
  children: React.ReactNode;
}

export default function DescriptionSection({
  title,
  children,
}: DescriptionSectionProps) {
  const capitalizedTitle = title.charAt(0).toUpperCase() + title.slice(1);
  return (
    <Stack direction="column" sx={{ marginBottom: 2 }}>
      <Typography variant="h3Bold" component="h3" sx={{ marginBottom: 1 }}>
        {capitalizedTitle} :
      </Typography>
      <Stack direction="column" sx={{ gap: 1 }}>
        {children}
      </Stack>
    </Stack>
  );
}
