import { Typography } from '@mui/material';
import { isTrimesterStart } from 'components/commons/Tables/Header/ArrowsUpdateTrimesterTableHeader';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import { NO_SECTOR_NAME } from 'components/MissionFollowUp/Sector/utils';
import CollapsePolyTableCell from 'components/MUIOverload/PolyTableCell/CollapsePolyTableCell';
import ChildCollapsePolyTableRow from 'components/MUIOverload/PolyTableRow/ChildCollapsePolyTableRow';
import TransitionPolyTableRow from 'components/MUIOverload/PolyTableRow/TransitionPolyTableRow';
import {
  ClientType,
  SectorType,
} from 'components/Reporting/Profitability/GlobalView/ProfitabilityTableBody/ProfitabilityTableBody';
import styles from 'components/Revenue/Estimated/EstimatedRevenueTables/EstimatedRevenueGlobalTable/styles/TableCell.module.scss';
import OutOfRangeTableCell from 'components/Revenue/Tables/RevenueTableCells/OutOfRangeTableCell';
import { isInRange } from 'components/Revenue/Tables/util';
import {
  ActivitiesActivityBillingTypeChoices,
  BillingClientNode,
  PublicHoliday,
  SectorProfitNode,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import { getMissionLastActiveMonth } from 'utils';

import ProfitabilityDataComingCell from './Cells/ProfitabilityDataComingCell';
import ProfitabilityClientRow from './ProfitabilityClientRow';
import { CallProfitabilityRenderValues, getYearToDateProfit } from './utils';

function getRowValues(
  month: moment.Moment,
  sector: SectorType,
  isTrimesterView: boolean | undefined
) {
  if (isTrimesterView && !isTrimesterStart(month)) {
    return (
      <React.Fragment
        key={`profitability-${sector.name}-${month.format('MMMM')}`}
      />
    );
  }

  const sectorProfit = _.find(sector.sectorProfits, (sectorProfit) =>
    month.isSame(
      moment(isTrimesterView ? sectorProfit.trimester : sectorProfit.month),
      'month'
    )
  );

  if (sectorProfit) {
    return (
      <CallProfitabilityRenderValues
        key={`profitability-${sector.name}-${month.format('MMMM')}`}
        month={month}
        profit={sectorProfit}
        isTrimesterView={isTrimesterView}
      />
    );
  }

  const hasActiveActivity = containsActiveActivity(month, sector.clients);
  if (!hasActiveActivity) {
    return (
      <OutOfRangeTableCell
        key={`profitability-${sector.name}-${month.format('MMMM')}`}
        title="Ce secteur n'a pas de mission active sur ce mois"
        colSpan={5}
      />
    );
  }

  const hasActivePurchaseOrder = containsActivePurchaseOrder(
    month,
    sector.clients
  );
  if (!hasActivePurchaseOrder) {
    return (
      <OutOfRangeTableCell
        key={`profitability-${sector.name}-${month.format('MMMM')}`}
        title="Les clients de ce secteur n’ont pas de BDC actif sur ce mois"
        colSpan={5}
      />
    );
  }

  return (
    <ProfitabilityDataComingCell
      key={`profitability-${sector.name}-${month.format('MMMM')}`}
      reason={isTrimesterView ? undefined : 'Aucun CA validé'}
      colSpan={5}
    />
  );
}

interface ProfitabilitySectorRowProps {
  status: TransitionStatus;
  sector: SectorType;
  isTrimesterView?: boolean;
  polyconseilClient: BillingClientNode;
  nonInitExternalMissionsClient: BillingClientNode;
  publicHolidays?: PublicHoliday[];
  currentYear: moment.Moment;
  yearToDateMonthWorkDays?: number;
}

export default function ProfitabilitySectorRow({
  status,
  sector,
  isTrimesterView,
  polyconseilClient,
  nonInitExternalMissionsClient,
  publicHolidays,
  currentYear,
  yearToDateMonthWorkDays,
}: ProfitabilitySectorRowProps) {
  const [openCollapse, setOpenCollapse] = useState(false);
  const { displayedMonths } = useTableHeaderContext();

  const yearToDateSectorProfit = !isTrimesterView
    ? (getYearToDateProfit(
        sector.sectorProfits,
        displayedMonths,
        publicHolidays as PublicHoliday[]
      ) as SectorProfitNode)
    : undefined;

  return (
    <>
      <ChildCollapsePolyTableRow status={status}>
        <CollapsePolyTableCell
          className={styles.tableCell}
          open={openCollapse}
          setOpen={setOpenCollapse}
        >
          <Typography sx={{ fontWeight: 'bold', width: '167px' }}>
            {sector.name}
          </Typography>
        </CollapsePolyTableCell>
        {_.map(displayedMonths, (month) => {
          return getRowValues(month, sector, isTrimesterView);
        })}
        {yearToDateSectorProfit && (
          <CallProfitabilityRenderValues
            key={`profitability-year-to-date-${
              sector.name
            }-${yearToDateSectorProfit?.month.format('MMMM')}`}
            month={yearToDateSectorProfit?.month}
            profit={yearToDateSectorProfit}
            isTrimesterView={isTrimesterView}
            yearToDateMonthWorkDays={yearToDateMonthWorkDays}
            isEtpCumulated={true}
          />
        )}
      </ChildCollapsePolyTableRow>
      <TransitionPolyTableRow open={openCollapse}>
        {() => {
          return (
            <>
              {orderedClients(
                sector,
                nonInitExternalMissionsClient.corporateName
              ).map((client) => {
                return (
                  <Transition
                    mountOnEnter
                    unmountOnExit
                    timeout={250}
                    key={`client-${client.id}`}
                    in={true}
                  >
                    {(status) => {
                      return (
                        <ProfitabilityClientRow
                          currentYear={currentYear}
                          status={status}
                          client={client}
                          isTrimesterView={isTrimesterView}
                          polyconseilClient={polyconseilClient}
                          nonInitExternalMissionsClient={
                            nonInitExternalMissionsClient
                          }
                          publicHolidays={publicHolidays as PublicHoliday[]}
                          yearToDateMonthWorkDays={yearToDateMonthWorkDays}
                        />
                      );
                    }}
                  </Transition>
                );
              })}
            </>
          );
        }}
      </TransitionPolyTableRow>
    </>
  );
}

function containsActiveActivity(month: moment.Moment, clients: ClientType[]) {
  return _.some(clients, (client) =>
    _.some(client.activities, (activity) =>
      isInRange(activity.startDate, getMissionLastActiveMonth(activity), month)
    )
  );
}

function containsActivePurchaseOrder(
  month: moment.Moment,
  clients: ClientType[]
) {
  return _.some(clients, (client) =>
    _.some(client.activities, (activity) =>
      activity.billingType === ActivitiesActivityBillingTypeChoices.Tm
        ? _.some(activity.billingInformation?.purchaseOrders, (purchaseOrder) =>
            isInRange(
              purchaseOrder.periodBeginning,
              moment(purchaseOrder.periodEnding).add(1, 'M'),
              month
            )
          )
        : _.some(activity.billingInformation?.purchaseOrders, (purchaseOrder) =>
            isInRange(
              purchaseOrder.periodBeginning,
              moment(purchaseOrder.periodEnding),
              month
            )
          )
    )
  );
}

const orderedClients = (
  sector: SectorType,
  nonInitExternalMissionsClientName: string | undefined
) => {
  if (sector.name === NO_SECTOR_NAME) {
    return sector.clients.sort((a, b) => {
      if (
        a.corporateName === 'Polyconseil' ||
        a.corporateName === nonInitExternalMissionsClientName
      ) {
        return 1;
      }
      if (
        b.corporateName === 'Polyconseil' ||
        b.corporateName === nonInitExternalMissionsClientName
      ) {
        return -1;
      } else {
        return 0;
      }
    });
  } else {
    return sector.clients;
  }
};
