import PackageIcon from '@mui/icons-material/Assignment';
import TmIcon from '@mui/icons-material/AssignmentInd';
import VacationIcon from '@mui/icons-material/BeachAccess';
import CallForTendersIcon from '@mui/icons-material/Explore';
import HrIcon from '@mui/icons-material/LocalLibrary';
import InternIcon from '@mui/icons-material/Pages';
import { Tooltip } from '@mui/material';
import { SxProps } from '@mui/system';
import React from 'react';

export interface MissionTypeIconProps {
  type: string;
  isTmContracts?: boolean;
  className?: string;
  sx?: SxProps;
  tooltip?: boolean;
}

const MissionTypeIcon = ({
  type,
  isTmContracts,
  className,
  sx,
  tooltip,
}: MissionTypeIconProps) => {
  switch (type) {
    case 'EXT':
      return isTmContracts ? (
        <Tooltip arrow title={tooltip ? 'Régie' : ''}>
          <TmIcon sx={sx} className={className} />
        </Tooltip>
      ) : (
        <Tooltip arrow title={tooltip ? 'Forfait' : ''}>
          <PackageIcon sx={sx} className={className} />
        </Tooltip>
      );
    case 'INT':
      return (
        <Tooltip arrow title={tooltip ? 'Mission interne' : ''}>
          <InternIcon sx={sx} className={className} />
        </Tooltip>
      );
    case 'CFT':
      return (
        <Tooltip arrow title={tooltip ? "Appel d'offre " : ''}>
          <CallForTendersIcon sx={sx} className={className} />
        </Tooltip>
      );
    case 'HR':
      return (
        <Tooltip arrow title={tooltip ? 'Vie du cabinet' : ''}>
          <HrIcon sx={sx} className={className} />
        </Tooltip>
      );
    default:
      return (
        <Tooltip arrow title={tooltip ? 'Toutes absences' : ''}>
          <VacationIcon sx={sx} className={className} />
        </Tooltip>
      );
  }
};

export default MissionTypeIcon;
