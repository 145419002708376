import { BillingPurchaseOrderCurrencyChoices } from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { totalFormat } from 'pages/ActivityPage/utils';
import React, { ReactElement } from 'react';
import { useSelector } from 'store';

import CreatePurchaseOrderForm, {
  CreatePurchaseOrderFormProps,
} from './CreatePurchaseOrderForm';

export default function DuplicatePurchaseOrderForm({
  validCallback,
  cancelCallback,
}: Omit<CreatePurchaseOrderFormProps, 'defaultValues'>): ReactElement {
  const currentPurchaseOrder = useSelector(
    (state) => state.purchaseOrder.currentPurchaseOrder
  );
  const currentMission = useSelector((state) => state.activity.currentMission);

  const defaultValues = {
    purchaseOrderId: '',
    purchaseOrderName: `CLONE - ${currentPurchaseOrder.name}`,
    purchaseOrderNum: '',
    collaborators: _.map(currentPurchaseOrder.collaborators, removeIdField),
    isSubMission: (currentPurchaseOrder.poSubMissions?.length || 0) > 0,
    total: totalFormat(currentPurchaseOrder.total),
    currency:
      currentPurchaseOrder.currency || BillingPurchaseOrderCurrencyChoices.Eur,
    tasks: currentPurchaseOrder.tasks || [],
    poSubMissions: _.map(currentPurchaseOrder.poSubMissions, (poSubMission) => {
      const newSubMission = _.cloneDeep(poSubMission);
      newSubMission.id = '';
      newSubMission.collaborators = _.map(
        newSubMission.collaborators,
        removeIdField
      );
      return newSubMission;
    }),
    smAdrs: true,
    periodStart: currentPurchaseOrder.periodBeginning
      ? new Date(currentPurchaseOrder.periodBeginning)
      : moment().startOf('month').toDate(),
    periodEnd: currentPurchaseOrder.periodEnding
      ? new Date(currentPurchaseOrder.periodEnding)
      : moment().endOf('month').toDate(),
    document: { id: '', filename: '', file: undefined },
    hasTemporaryDocument: currentPurchaseOrder.hasTemporaryDocument,
    attachments: [],
    address: currentPurchaseOrder.billingAddress?.id || '',
    billingAddresses:
      currentMission.billingInformation?.billingClient?.addresses || [],
  };
  return (
    <CreatePurchaseOrderForm
      defaultValues={defaultValues}
      validCallback={validCallback}
      cancelCallback={cancelCallback}
    />
  );
}

function removeIdField<T extends { id: string }>(obj: T): T {
  const newObj = _.cloneDeep(obj);
  newObj.id = '';
  return newObj;
}
