import { Check } from '@mui/icons-material';
import GroupEditButtons from 'components/MissionFollowUp/OverallListBill/GroupEditButtons/index';
import PongoButton from 'components/MUIOverload/PongoButton';
import { BillNode, useAllBillsPaginatedLazyQuery } from 'generated/graphql';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import StateSelectionModal from 'pages/MissionFollowUp/PaymentFollowUpPage/StateSelectionModal';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import {
  setAllBills,
  setGroupEdit,
  setSelectedBills,
} from 'store/purchaseOrder';

import { useBillsTableContext } from '../TablePaginationListBills/BillsTableContextProvider';

const PaymentStatusGroupEditButtons = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { page, rowsPerPage } = useBillsTableContext();

  const [modalOpen, setModalOpen] = useState(false);
  const groupEdit = useSelector((state) => state.purchaseOrder.groupEdit);
  const selectedMissions = useSelector(
    (state) => state.activity.selectedMissions
  );
  const filterPaymentStatus = useSelector(
    (state) => state.purchaseOrder.statusPaymentFilter
  );
  const directorFilter = useSelector((state) => state.users.directorFilter);
  const creatorFilter = useSelector((state) => state.users.creatorFilter);

  const [refreshAllBills] = useAllBillsPaginatedLazyQuery({
    onCompleted: (data) => {
      dispatch(setAllBills(data.allBillsPaginated?.bills as BillNode[]));
    },
    onError: () => {
      enqueueSnackbar(`Erreur lors de la récupération des données`, {
        variant: 'error',
      });
    },
  });

  function closeModal() {
    setModalOpen(!modalOpen);
  }

  function onSuccess() {
    refreshAllBills({
      variables: {
        // no need to filter by status, isPayment option applies the filter in the back
        isPayment: true,
        paymentStatus: filterPaymentStatus,
        activityIds: _.map(selectedMissions, 'id'),
        directorId: directorFilter?.id,
        creatorId: creatorFilter?.id,
        pageNbr: page,
        pageSize: rowsPerPage,
      },
    });
    dispatch(setGroupEdit(!groupEdit));
    dispatch(setSelectedBills([]));
  }

  const validateButton = (
    <PongoButton
      size={'small'}
      onClick={() => setModalOpen(true)}
      startIcon={<Check />}
      variant={'contained'}
    >
      Valider édition
    </PongoButton>
  );

  return (
    <React.Fragment>
      <GroupEditButtons validateButton={validateButton} />

      <StateSelectionModal
        open={modalOpen}
        groupAction
        onClose={closeModal}
        onSuccess={onSuccess}
      />
    </React.Fragment>
  );
};

export default PaymentStatusGroupEditButtons;
