import { Search } from '@mui/icons-material';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import PurchaseOrderModal from 'components/commons/PurchaseOrderModal';
import { PoFormMode } from 'components/commons/PurchaseOrderModal/PurchaseOrderForm';
import PolyModal from 'components/MUIOverload/PolyDialog/PolyModal';
import {
  ActivityNode,
  EmployeeNode,
  useAllActivitiesAsChiefOrDirectorQuery,
  useAllEmployeesForActivityLazyQuery,
} from 'generated/graphql';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setEmployeesForCurrentActivity } from 'store/users';

interface ChooseActivityModalToCreatePurchaseOrderProps {
  openModal: boolean;
  closeModal: () => void;
  mode: PoFormMode;
}

export default function ChooseActivityModalToCreatePurchaseOrder({
  openModal,
  closeModal,
  mode,
}: ChooseActivityModalToCreatePurchaseOrderProps) {
  const { data, loading } = useAllActivitiesAsChiefOrDirectorQuery({
    variables: { initialisedAndBillables: true },
  });
  const [selectedActivity, setSelectedActivity] = useState<
    ActivityNode | undefined
  >(undefined);
  const [isActivityChosen, setIsActivityChosen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [executeUseAllEmployeesForSelectedActivity] =
    useAllEmployeesForActivityLazyQuery({
      onCompleted: (data) => {
        if (data?.allEmployeesForActivity) {
          dispatch(
            setEmployeesForCurrentActivity(
              data.allEmployeesForActivity as EmployeeNode[]
            )
          );
        }
      },
      onError: (error) => {
        enqueueSnackbar(`Error : ${error.message}`, {
          variant: 'error',
        });
      },
    });

  function handleValidateModal() {
    executeUseAllEmployeesForSelectedActivity({
      variables: {
        activityId: selectedActivity?.id || '',
        dateFrom: selectedActivity?.startDate,
        dateTo: selectedActivity?.expirationDate,
      },
    });
    setIsActivityChosen(true);
  }

  function handleCloseModal() {
    setSelectedActivity(undefined);
    setIsActivityChosen(false);
    closeModal();
  }

  if (isActivityChosen) {
    return (
      <PurchaseOrderModal
        open={openModal}
        closeModal={handleCloseModal}
        mode={mode}
        selectedActivity={selectedActivity}
      />
    );
  }

  return (
    <PolyModal
      open={openModal}
      onClose={handleCloseModal}
      polyDialogTitle={'Veuillez sélectionner une mission'}
      handlePrimary={handleValidateModal}
      primaryButtonName={'Sélectionner'}
      isPrimaryButtonDisabled={selectedActivity === undefined}
    >
      {loading ? (
        <LoadingPlaceholder />
      ) : (
        <Autocomplete
          sx={{ width: 500, paddingBottom: 2 }}
          options={data?.allActivitiesAsChiefOrDirector as ActivityNode[]}
          getOptionLabel={(option) => {
            return option?.name || '';
          }}
          onChange={(event, value) => setSelectedActivity(value || undefined)}
          renderInput={(parameters) => (
            <TextField
              label="Missions"
              variant={'standard'}
              {...parameters}
              InputProps={{
                ...parameters.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      )}
    </PolyModal>
  );
}
