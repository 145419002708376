import { Box } from '@mui/material';
import {
  buildColumnLabels,
  buildColumnLabelsV2,
  ColumnLabelsV2,
} from 'components/commons/Tables/utils';
import { MOBILE_NAVBAR_MIN_HEIGHT } from 'components/Navigation/MobileNavbar';
import {
  EmployeeWeeklyContractNode,
  useFetchUserActivityQuery,
  useGetUserWeeklyContractQuery,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import InvalidWorkingDaysModal from 'pages/ActivityPage/InvalidWorkingDaysModal';
import {
  ActivityGroupType,
  ActivityMonitoring,
  buildDateRange,
} from 'pages/ActivityPage/PrincipalTablePage';
import { getIsValidTotalWorkingDays } from 'pages/ActivityPage/PrincipalTablePage/PrincipalTable';
import { ACTIVITY_DATE_FORMAT } from 'poly-constants';
import React, { useEffect, useState } from 'react';

import ActivityMonthPicker from '../ActivityMonthPicker';
import { extractActivities } from '../utils';
import {
  ActivityWeekCard,
  ActivityWeekDrawer,
  ActivityWeekView,
} from './ActivityWeek';

interface ActivityMobileMonthViewProps {
  month: moment.Moment;
  incrementPeriod: () => void;
  decrementPeriod: () => void;
}

const ActivityMobileMonthView = ({
  month,
  incrementPeriod,
  decrementPeriod,
}: ActivityMobileMonthViewProps) => {
  const [hasTMActivity, setHasTMActivity] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [selectedWeek, setSelectedWeek] = useState<ColumnLabelsV2 | undefined>(
    undefined
  );
  const [selectedWeekActivities, setselectedWeekActivities] = useState<
    ActivityGroupType | undefined
  >(undefined);
  const weekLabels = month.isSameOrAfter(moment('2023-01-01'))
    ? buildColumnLabelsV2(month.format(ACTIVITY_DATE_FORMAT))
    : buildColumnLabels(month.format(ACTIVITY_DATE_FORMAT));
  const { enqueueSnackbar } = useSnackbar();
  const dateRange = buildDateRange(month);
  const {
    loading: loadingUserActivity,
    data: dataUserActivity,
    refetch: refetchUserActivity,
  } = useFetchUserActivityQuery({
    variables: {
      dateFrom: dateRange.start.format('YYYY-MM-DD'),
      dateTo: dateRange.end.format('YYYY-MM-DD'),
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const { data } = useGetUserWeeklyContractQuery();
  const userWeeklyContract =
    data?.userWeeklyContract as EmployeeWeeklyContractNode;

  const weeklyContract = userWeeklyContract
    ? userWeeklyContract.weeklyContract
    : 5;

  const isValidTotalWorkingDays = getIsValidTotalWorkingDays(
    userWeeklyContract,
    weeklyContract
  );

  const [invalidWorkingDaysModalOpen, setInvalidWorkingDaysModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    setInvalidWorkingDaysModalOpen(!isValidTotalWorkingDays);
  }, [isValidTotalWorkingDays]);

  return (
    <>
      <InvalidWorkingDaysModal
        open={invalidWorkingDaysModalOpen}
        setOpen={setInvalidWorkingDaysModalOpen}
        weeklyContract={weeklyContract}
      />
      <Box
        position="sticky"
        zIndex={1}
        sx={{
          py: 1,
          backgroundColor: 'white',
          top: MOBILE_NAVBAR_MIN_HEIGHT,
        }}
      >
        <ActivityMonthPicker
          viewMonth={month.format(ACTIVITY_DATE_FORMAT)}
          incrementPeriod={incrementPeriod}
          decrementPeriod={decrementPeriod}
        />
      </Box>
      {_.map(weekLabels, (week) => {
        const activityMonitorings = dataUserActivity
          ? (_.filter(
              _.flatMap(
                dataUserActivity['allActivitiesForUser'],
                (activity) => activity.activityMonitoring
              ),
              (am) =>
                moment(am?.date).isBetween(
                  week.start,
                  week.end,
                  undefined,
                  '[]'
                )
            ) as ActivityMonitoring[])
          : [];

        const daysWorked = _.sumBy(activityMonitorings, (am) => am.timeSpent);
        return (
          <ActivityWeekCard
            key={`week-${week.weekNumber}-${week.weekPart}`}
            week={week}
            daysWorked={daysWorked}
            loading={loadingUserActivity}
            onClick={() => {
              setIsDrawerOpen(true);
              setSelectedWeek(week);
              setselectedWeekActivities(
                extractActivities(
                  dataUserActivity,
                  { start: week.start, end: week.end },
                  hasTMActivity,
                  setHasTMActivity
                )
              );
            }}
          />
        );
      })}
      {selectedWeek && selectedWeekActivities && (
        <ActivityWeekDrawer
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
        >
          <ActivityWeekView
            key={`week-view-${selectedWeek.weekNumber}-${selectedWeek.weekPart}`}
            week={selectedWeek}
            weekActivities={selectedWeekActivities}
            refetchUserActivity={refetchUserActivity}
            setIsDrawerOpen={setIsDrawerOpen}
          />
        </ActivityWeekDrawer>
      )}
    </>
  );
};

export default ActivityMobileMonthView;
