import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Tooltip } from '@mui/material';
import React from 'react';

interface IconCellProps {
  tooltipText: string;
  onClick: () => void;
}

export default function DeleteIconWithTooltip({
  tooltipText,
  onClick,
}: IconCellProps) {
  return (
    <IconButton color="primary" size="large">
      <Tooltip title={tooltipText} placement="bottom-start" arrow>
        <DeleteIcon onClick={onClick} />
      </Tooltip>
    </IconButton>
  );
}
