import { Collapse, Divider, Grid } from '@mui/material';
import React from 'react';

interface DrawerCollapseProps {
  children: React.ReactNode;
  in: boolean;
}

export default function DrawerCollapse({
  children,
  in: inProp,
}: DrawerCollapseProps) {
  const timeoutRef = React.useRef(null as NodeJS.Timeout | null);

  return (
    <Collapse
      in={inProp}
      timeout="auto"
      onExiting={(node) => {
        timeoutRef.current = setTimeout(() => {
          node.style.display = 'none';
        }, 150);
      }}
      onExited={() => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      }}
      onEnter={(node) => {
        node.style.display = 'block';
      }}
      sx={{
        display: inProp ? 'block' : 'none',
        mt: 1,
      }}
    >
      <Grid container sx={{ py: 1 }}>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ mx: 1, py: -1, px: 0.8, borderRightWidth: 2, mr: 3 }}
        />
        <Grid
          item
          xs
          component={'ul'}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          {children}
        </Grid>
      </Grid>
    </Collapse>
  );
}
