import MissionPageWrapper from 'components/commons/MissionPageWrapper';
import PolyModal from 'components/MUIOverload/PolyDialog/PolyModal';
import { ActivityNode } from 'generated/graphql';
import React from 'react';

interface UninitialisedMissionModalProps {
  missionModalVisibility: boolean;
  isModalContentLoading: boolean;
  handleClose: () => void;
  getMissionTypeString: (activity: ActivityNode) => string;
  mission: ActivityNode;
  setMissionHasBeenInit: (b: boolean) => void;
  setIsModalContentLoading: (b: boolean) => void;
}

export default function UninitialisedMissionModal({
  missionModalVisibility,
  isModalContentLoading,
  handleClose,
  getMissionTypeString,
  mission,
  setMissionHasBeenInit,
  setIsModalContentLoading,
}: UninitialisedMissionModalProps) {
  return (
    <PolyModal
      open={missionModalVisibility}
      onClose={handleClose}
      sx={{ overflow: 'scroll' }}
      maxWidth={getMissionTypeString(mission) === 'interne' ? 'md' : 'xl'}
      polyDialogTitle={
        mission?.billingInformation
          ? mission.name
          : `Initialisation de la mission ${getMissionTypeString(mission)} : ${
              mission.name
            } `
      }
      noSecondaryButton
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      isLoading={isModalContentLoading}
    >
      <MissionPageWrapper
        isEditMode={!!mission.billingInformation}
        closeModal={handleClose}
        hasSuccessfullyInit={() => setMissionHasBeenInit(true)}
        handleLoadedContent={() => setIsModalContentLoading(false)}
        mission={mission}
      />
    </PolyModal>
  );
}
