import { BillNode } from 'generated/graphql';
import _ from 'lodash';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';
import { useSelector } from 'store';
import { functionNotSet } from 'utils';

export interface TableContext {
  getFilteredBills: () => BillNode[];
  page: number;
  setPage: (page: number) => void;
  rowsPerPage: number;
  setRowsPerPage: (nb: number) => void;
  allBills: BillNode[];
}

const TableContext = createContext<TableContext>({
  getFilteredBills: () => functionNotSet(),
  page: 0,
  setPage: () => functionNotSet(),
  rowsPerPage: 100,
  setRowsPerPage: () => functionNotSet(),
  allBills: [],
});

export const useBillsTableContext = () => {
  const tableContext = useContext(TableContext);
  if (!tableContext) {
    throw new Error('useContext() can only be used  inside a table');
  }
  return tableContext;
};

const BillsTableContextProvider = (properties: PropsWithChildren<{}>) => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(100);
  const allBills = useSelector((state) => state.purchaseOrder.allBills);

  function getFilteredBills() {
    return _.slice(allBills, page * rowsPerPage, (page + 1) * rowsPerPage);
  }

  return (
    <TableContext.Provider
      value={{
        getFilteredBills,
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        allBills,
      }}
    >
      {properties.children}
    </TableContext.Provider>
  );
};

export default BillsTableContextProvider;
