import { Box } from '@mui/material';
import { useFilterContext } from 'components/commons/FilterContext/FilterContextProvider';
import { FlatTable } from 'components/commons/Tables/FlatTable';
import TableHeaderContextProvider from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import PongoButton from 'components/MUIOverload/PongoButton';
import { useEstimatedContext } from 'components/Revenue/Estimated/context/EstimatedContextProvider';
import { DisplayedRevenueProspective } from 'components/Revenue/Estimated/EstimatedRevenueTables/EstimatedRevenueGlobalTable/context/TableContextProvider';
import RevenueTableBody from 'components/Revenue/Estimated/EstimatedRevenueTables/EstimatedRevenueGlobalTable/RevenueTableBody';
import RevenueTableHeader from 'components/Revenue/Estimated/EstimatedRevenueTables/RevenueTableHeader';
import {
  ActivityNode,
  RevenueProspectiveNode,
  useSubmitObjectivesForYearMutation,
} from 'generated/graphql';
import _ from 'lodash';
import { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { handleApolloError } from 'utils';

import TotalRow from './RevenueRows/TotalRow';

interface FormType {
  revenueObjectives: { [missionId: string]: number };
  revenueProspectivesByClient: {
    [clientId: string]: DisplayedRevenueProspective;
  };
  revenueProspectives: DisplayedRevenueProspective[];
}

interface RevenueGlobalTableProps {
  missions: ActivityNode[];
  currentYear: Moment;
  setCurrentYear: (p: Moment) => void;
}

export default function EstimatedRevenueGlobalTable({
  missions,
  currentYear,
  setCurrentYear,
}: RevenueGlobalTableProps) {
  const { filteredID } = useFilterContext();
  const { setMissions, revenueProspectives, setRevenueProspectives } =
    useEstimatedContext();
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues: FormType = {
    revenueObjectives: {},
    revenueProspectivesByClient: {},
    revenueProspectives: getDisplayedRevenueProspectives(revenueProspectives),
  };

  const form = useForm({
    shouldUnregister: false,
    defaultValues: defaultValues,
  });

  useEffect(() => {
    const displayedRevenuesProspectivesState =
      getDisplayedRevenueProspectives(revenueProspectives);
    if (
      _.isEqual(
        displayedRevenuesProspectivesState,
        form.getValues('revenueProspectives')
      )
    ) {
      return;
    }
    form.setValue('revenueProspectives', displayedRevenuesProspectivesState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(revenueProspectives)]);

  const [submitObjective, { loading }] = useSubmitObjectivesForYearMutation({
    onCompleted: (data) => {
      enqueueSnackbar('Objectif mis à jour', {
        variant: 'success',
      });
      if (data.createOrUpdateObjectives?.revenueProspectives) {
        setRevenueProspectives(
          data.createOrUpdateObjectives
            .revenueProspectives as RevenueProspectiveNode[]
        );
        form.formState.isDirty = false;
        form.clearErrors('revenueProspectives');
      }
    },
    onError: handleApolloError,
  });

  useEffect(() => {
    if (filteredID) {
      setMissions(
        _.filter(
          missions,
          (estimatedActivity) => estimatedActivity.director?.id === filteredID
        )
      );
    } else {
      setMissions(missions);
    }
  }, [filteredID, setMissions, missions]);

  const updateObjectives = async (submitValues: FormType) => {
    const revenueProspectivesByClient = _.map(
      submitValues.revenueProspectivesByClient,
      (dpr, clientId) => {
        return {
          revenueProspectiveId: dpr.id,
          name: dpr.name,
          clientId,
          objective: dpr.objective,
        };
      }
    );
    const revenueProspectives = _.flatMap(
      submitValues.revenueProspectives,
      (dpr) => {
        if (!dpr || !dpr.objective || !dpr.name) {
          return [];
        }
        return {
          revenueProspectiveId: dpr.id,
          name: dpr.name,
          objective: dpr.objective,
        };
      }
    );
    await submitObjective({
      variables: {
        year: currentYear.year(),
        revenueProspectives: [
          ...revenueProspectivesByClient,
          ...revenueProspectives,
        ],
        revenueObjectives: _.map(
          submitValues.revenueObjectives,
          (objective, activity) => {
            return {
              activity,
              objective,
            };
          }
        ),
      },
    });
  };
  function getDisplayedRevenueProspectives(
    revenueProspectives: RevenueProspectiveNode[]
  ) {
    return _.map(revenueProspectives, (rp) => {
      return {
        id: rp.id,
        name: rp.name,
        objective: rp.objective,
      } as DisplayedRevenueProspective;
    });
  }
  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit((d) => updateObjectives(d))}>
        <TableHeaderContextProvider
          currentYear={currentYear}
          setCurrentYear={setCurrentYear}
        >
          <FlatTable
            size={'small'}
            sx={{ '& .MuiTableCell-root:last-child': { paddingRight: 0 } }}
          >
            <RevenueTableHeader />
            <RevenueTableBody />
            <TotalRow />
          </FlatTable>
          <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
            <PongoButton
              loading={loading}
              color={'primary'}
              variant={'contained'}
              type={'submit'}
              disabled={!form.formState.isDirty || !_.isEmpty(form.errors)}
              sx={{ mx: 0 }}
            >
              Enregistrer
            </PongoButton>
          </Box>
        </TableHeaderContextProvider>
      </form>
    </FormProvider>
  );
}
