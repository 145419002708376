import {
  ActivityNode,
  AverageDailyRateCollaboratorCreateInput,
  AverageDailyRateCollaboratorNode,
  AverageDailyRateCollaboratorUpdateInput,
  BillingPurchaseOrderCurrencyChoices,
  CreatePurchaseOrderMutationVariables,
  Currencies,
  PurchaseOrderSubMissionNode,
  PurchaseOrderTaskNode,
  UpdatePurchaseOrderMutationVariables,
} from 'generated/graphql';
import _ from 'lodash';
import { comaStringToFloat } from 'pages/ActivityPage/utils';
import { graphQlDateFormatter } from 'utils';

import {
  AddressType,
  AttachmentType,
} from '../../MissionFollowUp/BillDetail/utils';

export interface PurchaseOrderFormDefaultValues {
  purchaseOrderName: string;
  purchaseOrderNum: string;
  collaborators: AverageDailyRateCollaboratorNode[];
  isSubMission: boolean;
  total: string;
  currency: BillingPurchaseOrderCurrencyChoices;
  tasks: PurchaseOrderTaskNode[];
  poSubMissions: PurchaseOrderSubMissionNode[];
  smAdrs: boolean;
  periodStart: Date;
  periodEnd: Date;
  document: Omit<AttachmentType, 'key'>;
  hasTemporaryDocument: boolean;
  attachments: AttachmentType[];
  address: string;
  billingAddresses: AddressType[];
}

export function translatePurchaseOrderErrorMessage(
  errorMessage: string
): string {
  let message = `Erreur de l'enregistrement`;
  if (errorMessage === 'The collaborator is already in a bill') {
    message =
      'Vous ne pouvez pas modifier un collaborateur déjà affecté à une facture';
  } else if (
    errorMessage ===
    'Purchase order ending declaration is lower than current month and lower than previous declaration'
  ) {
    message = 'Impossible de retirer un mois dont le CA a déjà été déclaré';
  } else if (
    errorMessage ===
    "Purchase order beginning's period is already declared in revenue"
  ) {
    message = 'Impossible de retirer un mois dont le CA a déjà été déclaré';
  } else if (
    errorMessage ===
    'Purchase order period must be declared in between activity period'
  ) {
    message =
      'Impossible de créer un BDC sur une période hors des dates de la mission';
  }
  return message;
}

export function buildCreatePurchaseOrderMutationVariables(
  submitValues: PurchaseOrderFormDefaultValues,
  currentMission: ActivityNode
): CreatePurchaseOrderMutationVariables {
  return {
    billingActivityInformationId: currentMission.billingInformation?.id || '',
    name: submitValues.purchaseOrderName,
    number: submitValues.purchaseOrderNum,
    periodBeginning: graphQlDateFormatter(submitValues.periodStart),
    periodEnding: graphQlDateFormatter(submitValues.periodEnd),
    total: comaStringToFloat(submitValues.total) || undefined,
    currency: getTypeCurrency(submitValues.currency),
    addressId: submitValues.address,
    listAverageDailyRateCollaborator: submitValues.isSubMission
      ? []
      : getADRs(submitValues, 'collaborators'),
    document: {
      id: submitValues.document.id,
      file: submitValues.document.file,
    },
    hasTemporaryDocument: submitValues.hasTemporaryDocument,
    tasks: _.map(submitValues.tasks, (task) => {
      return {
        name: task.name,
        amount: comaStringToFloat(task.amount),
        periodBeginning: graphQlDateFormatter(task.periodBeginning),
        periodEnding: graphQlDateFormatter(task.periodEnding),
      };
    }),
    poSubMissions: submitValues.isSubMission
      ? _.map(currentMission.subActivities, (subActivity, index) => {
          return {
            id: _.get(submitValues.poSubMissions, `${index}.id`),
            subMissionId: subActivity.id,
            listAverageDailyRateCollaborator: getADRs(
              submitValues,
              `poSubMissions.${index}.collaborators`
            ),
          };
        })
      : [],
    attachments: _.flatMap(submitValues.attachments, ({ id, file }) => ({
      id,
      file,
    })),
  };
}

function getADRs(
  submitValues: PurchaseOrderFormDefaultValues,
  fieldName: string
): AverageDailyRateCollaboratorCreateInput[] {
  return _.map(
    _.get(submitValues, fieldName),
    ({ title, dailyRate, collaborator }) => {
      return {
        title: title,
        dailyRate: comaStringToFloat(dailyRate),
        collaboratorId: collaborator?.id || '',
      };
    }
  );
}

function getTypeCurrency(
  currency: string | BillingPurchaseOrderCurrencyChoices
): Currencies {
  return _.find(Currencies, (value) => value === currency) || Currencies.Eur;
}

export function buildUpdatePurchaseOrderMutationVariables(
  submitValues: PurchaseOrderFormDefaultValues,
  currentMission: ActivityNode,
  purchaseOrderId: string
): UpdatePurchaseOrderMutationVariables {
  return {
    purchaseOrderId: purchaseOrderId || '',
    billingActivityInformationId: currentMission.billingInformation?.id || '',
    name: submitValues.purchaseOrderName,
    number: submitValues.purchaseOrderNum,
    periodBeginning: graphQlDateFormatter(submitValues.periodStart),
    periodEnding: graphQlDateFormatter(submitValues.periodEnd),
    total: comaStringToFloat(submitValues.total) || undefined,
    currency: getTypeCurrency(submitValues.currency),
    addressId: submitValues.address,
    listAverageDailyRateCollaborator: submitValues.isSubMission
      ? []
      : getADRsWithId(submitValues, 'collaborators'),
    document: {
      id: submitValues.document.id,
      file: submitValues.document.file,
    },
    hasTemporaryDocument: submitValues.hasTemporaryDocument,
    tasks: _.map(submitValues.tasks, (task) => {
      return {
        id: task.id,
        name: task.name,
        amount: comaStringToFloat(task.amount),
        periodBeginning: graphQlDateFormatter(task.periodBeginning),
        periodEnding: graphQlDateFormatter(task.periodEnding),
      };
    }),
    poSubMissions: submitValues.isSubMission
      ? _.map(currentMission.subActivities, (subActivity, index) => {
          return {
            id: _.get(submitValues.poSubMissions, `${index}.id`),
            subMissionId: subActivity.id,
            listAverageDailyRateCollaborator: getADRsWithId(
              submitValues,
              `poSubMissions.${index}.collaborators`
            ),
          };
        })
      : [],
    attachments: _.flatMap(submitValues.attachments, ({ id, file }) => ({
      id,
      file,
    })),
  };
}

function getADRsWithId(
  submitValues: PurchaseOrderFormDefaultValues,
  fieldName: string
): AverageDailyRateCollaboratorUpdateInput[] {
  return _.map(
    _.get(submitValues, fieldName),
    ({ id, title, dailyRate, collaborator }) => {
      return {
        id: id || '',
        title: title,
        dailyRate: comaStringToFloat(dailyRate),
        collaboratorId: collaborator?.id || '',
      };
    }
  );
}
