import PolyDataGrid from 'components/MUIOverload/PolyDataGrid';
import { PolyDataGridColumn } from 'components/MUIOverload/PolyDataGrid/PolyDataGridHead';
import { BillNode } from 'generated/graphql';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { addSelectedBill, removeSelectedBill } from 'store/purchaseOrder';
import { compareFacturationTable } from 'utils';

import { getBackFilter } from '../BillDetail/utils';
import styles from './styles/OverallListBills.module.scss';
import { useBillsTableContext } from './TablePaginationListBills/BillsTableContextProvider';

interface OverallListBillTableProps {
  columns: PolyDataGridColumn<DeepExtractTypeSkipArrays<BillNode, []>>[];
  onClickCallback?: (bill: BillNode) => void;
  isRowSelectEnabled?: (bill: BillNode) => boolean;
  isPayment?: boolean;
  setFilter: (p: string) => void;
}

const getBillId = (bill: BillNode) => {
  return `${bill.id}-${bill.status}-${bill.paymentStatus}`;
};

export default function OverallListBillTable({
  columns,
  onClickCallback,
  isRowSelectEnabled,
  isPayment,
  setFilter,
}: OverallListBillTableProps) {
  const dispatch = useDispatch();
  const { allBills } = useBillsTableContext();
  const groupEdit = useSelector((state) => state.purchaseOrder.groupEdit);

  const rowsActive = (
    _orderBy?: string | string[],
    _order?: 'asc' | 'desc'
  ) => {
    if (_orderBy && !Array.isArray(_orderBy)) {
      setFilter(getBackFilter(_orderBy as string, _order));
    }
    if (isPayment) {
      return (
        allBills
          .filter((bill) => !bill.billReference)
          .sort(compareFacturationTable(_orderBy, _order)) || []
      );
    }
    return allBills;
  };

  function selectedCallback(element: BillNode, selected: boolean) {
    if (selected) {
      dispatch(addSelectedBill(element));
    } else {
      dispatch(removeSelectedBill(element));
    }
  }

  return (
    <PolyDataGrid
      columns={columns}
      getRows={rowsActive}
      className={styles.billTable}
      isSelectable={groupEdit}
      selectedRowsCallback={selectedCallback}
      isRowSelectEnabled={isRowSelectEnabled}
      getRowId={getBillId}
      rowsCallback={onClickCallback}
    />
  );
}
