export interface GraphProps {
  year: number;
  isInJanuary: boolean;
}

export const MONTH_IN_YEAR_LABELS = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
];
