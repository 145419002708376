import {
  Autocomplete,
  Box,
  ClickAwayListener,
  Popper,
  Stack,
  TextField,
} from '@mui/material';
import PongoButton from 'components/MUIOverload/PongoButton';
import { useCredential } from 'components/User/Authenticator/hooks/useCredentials';
import { EmployeeNode, useAllEmployeesQuery } from 'generated/graphql';
import React, { useCallback, useState } from 'react';
import { logAs } from 'services/login';

const SandboxRibbon = () => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [selectedEmployee, setSelectedEmployee] = useState<string>('');
  const [allEmployees, setAllEmployees] = React.useState<EmployeeNode[]>([]);
  const handlePopper = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(anchorElement ? null : event.currentTarget);
  };
  const open = Boolean(anchorElement);
  const id = open ? 'simple-popper' : undefined;
  useAllEmployeesQuery({
    onCompleted: (data) => {
      if (data.allEmployees) {
        setAllEmployees(data.allEmployees as EmployeeNode[]);
      }
    },
  });

  const [, setCredential] = useCredential();
  const handleLogAs = useCallback(
    async (employee: string) => {
      const response = await logAs({ username: employee, password: '' });
      if (!response.error) {
        setCredential(response.response);
        window.location.reload();
      }
    },
    [setCredential]
  );
  return (
    <Stack sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
      <PongoButton
        onClick={handlePopper}
        variant={'contained'}
        buttonStyle="secondary"
        sx={{ width: '100%' }}
      >
        Log as
      </PongoButton>
      {anchorElement && (
        <ClickAwayListener onClickAway={() => setAnchorElement(null)}>
          <Popper
            id={id}
            open={open}
            anchorEl={anchorElement}
            disablePortal={true}
            placement={'top'}
            sx={{ zIndex: 1 }}
          >
            <Box
              sx={{
                boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
                borderRadius: '2px',
                padding: '16px 32px',
                marginTop: '5px',
                position: 'relative',
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Autocomplete
                disablePortal
                onChange={(
                  event: React.SyntheticEvent<Element, Event>,
                  newValue: EmployeeNode | null
                ) => {
                  setSelectedEmployee(newValue?.email || '');
                }}
                id="combo-box-demo"
                options={allEmployees}
                sx={{ width: 300 }}
                getOptionLabel={(option) => {
                  return option?.email || '';
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Employee"
                    sx={{}}
                  />
                )}
              />
              <PongoButton
                sx={{ ml: 1 }}
                variant={'contained'}
                buttonStyle="secondary"
                onClick={() => handleLogAs(selectedEmployee)}
              >
                Log as
              </PongoButton>
            </Box>
          </Popper>
        </ClickAwayListener>
      )}
    </Stack>
  );
};

export default SandboxRibbon;
