import { Stack, Typography } from '@mui/material';
import { ActivityNode } from 'generated/graphql';
import React from 'react';
import { displayEmployeeNode } from 'utils';

import AvatarInitial from '../../commons/AvatarInitial/AvatarInitial';

interface DisplayDirectorProps {
  activity: ActivityNode;
}

export default function DisplayDirector(props: DisplayDirectorProps) {
  const director = props.activity?.director;
  if (!director) {
    return <></>;
  }
  return (
    <Stack direction={'row'} alignItems={'center'}>
      <Typography color={'textSecondary'} mr={1}>
        Directeur de mission :
      </Typography>
      <AvatarInitial
        employee={director}
        sx={{ height: '32px', width: '32px' }}
      />
      <Typography fontWeight={'bold'}>
        {displayEmployeeNode(director, false)}
      </Typography>
    </Stack>
  );
}
