import { AlertTitle } from '@mui/material';
import { AlertProps } from '@mui/material/Alert';
import React from 'react';

const PolyAlertTitle: React.FC<AlertProps> = (props) => {
  const { color } = props;
  return <AlertTitle {...props} sx={{ ...props.sx, color: color + '.main' }} />;
};

export default PolyAlertTitle;
