import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import { BorderedTableCell } from 'components/commons/Tables/FlatTable';
import React from 'react';

import { WorkingDayMoment, WorkingDayState } from '../utils';
import UserWorkingDays from './SubComponents/UserWorkingDays';

interface ContractualDaysTableBodyProps {
  workingDaysStates: WorkingDayState[];
}

export default function ContractualDaysTableBody({
  workingDaysStates,
}: ContractualDaysTableBodyProps) {
  return (
    <TableBody>
      <TableRow>
        <BorderedTableCell align={'center'} sx={{ padding: 1 }}>
          Matin
        </BorderedTableCell>
        <UserWorkingDays
          workingDaysStates={workingDaysStates}
          workingDayMoment={WorkingDayMoment.AM}
        />
      </TableRow>
      <TableRow>
        <BorderedTableCell align={'center'} sx={{ padding: 1 }}>
          Après-midi
        </BorderedTableCell>
        <UserWorkingDays
          workingDaysStates={workingDaysStates}
          workingDayMoment={WorkingDayMoment.PM}
        />
      </TableRow>
    </TableBody>
  );
}
