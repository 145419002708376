import AddAlarmIcon from '@mui/icons-material/AddAlarm';
import AlarmIcon from '@mui/icons-material/Alarm';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import CachedIcon from '@mui/icons-material/Cached';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PageWrapper from 'components/commons/PageWrapper';
import TabHeader from 'components/commons/TabHeader';
import SubTabsNavigation from 'components/Navigation/SubTabsNavigation/SubTabsNavigation';
import AccessWrapper from 'components/User/AccessWrapper';
import { EmployeeOccupations } from 'components/User/UserProvider';
import _ from 'lodash';
import { ExceptionalActivitiesTitles, PageTitles } from 'poly-constants';
import React from 'react';
import { useLocation } from 'react-router-dom';

import ExceptionalActivitiesAuthorizationsGrantedPanel from './ExceptionalActivitiesAuthorizations/ExceptionalActivitiesAuthorizationsGrantedPanel';
import ExceptionalActivitiesMyAuthorizationsPanel from './ExceptionalActivitiesAuthorizations/ExceptionalActivitiesMyAuthorizationsPanel';
import ExceptionalActivitiesRecuperation from './ExceptionalActivitiesRecuperation';
import ExceptionalActivitiesRequestPanelEmployee from './ExceptionalActivitiesRequest/ExceptionalActivitiesRequestPanelEmployee';
import ExceptionalActivitiesRequestPanelManager from './ExceptionalActivitiesRequest/ExceptionalActivitiesRequestPanelManager';
import OvertimeAuthorization from './OvertimeAuthorization';

function v2TabItemsExceptionalActivities(isV2: boolean) {
  const tabItemsExceptionalActivities = isV2
    ? [
        {
          name: ExceptionalActivitiesTitles.authorization,
          to: 'authorization',
          icon: <AddAlarmIcon />,
          componentRendered: (
            <AccessWrapper
              restrictMissionChief
              occupations={[EmployeeOccupations.DG]}
            >
              <OvertimeAuthorization />
            </AccessWrapper>
          ),
        },
        {
          name: ExceptionalActivitiesTitles.authorizationGranted,
          to: 'authorization_granted',
          icon: <AlarmOnIcon />,
          componentRendered: (
            <AccessWrapper
              restrictMissionChief
              occupations={[EmployeeOccupations.DG]}
            >
              <ExceptionalActivitiesAuthorizationsGrantedPanel />
            </AccessWrapper>
          ),
        },
        {
          name: ExceptionalActivitiesTitles.declarationManager,
          to: 'declaration_manager',
          icon: <PermContactCalendarIcon />,
          componentRendered: (
            <AccessWrapper
              restrictMissionChief
              occupations={[EmployeeOccupations.DG]}
            >
              <ExceptionalActivitiesRequestPanelManager />,
            </AccessWrapper>
          ),
        },
      ]
    : [
        {
          name: ExceptionalActivitiesTitles.myAuthorization,
          to: 'my_authorization',
          icon: <EventAvailableIcon />,
          componentRendered: (
            <AccessWrapper
              restrictFullTimeCollaborator
              occupations={[EmployeeOccupations.DG]}
            >
              <ExceptionalActivitiesMyAuthorizationsPanel />
            </AccessWrapper>
          ),
        },
        {
          name: ExceptionalActivitiesTitles.declaration,
          to: 'declaration',
          icon: <AlarmIcon />,
          componentRendered: (
            <AccessWrapper
              restrictFullTimeCollaborator
              occupations={[EmployeeOccupations.DG]}
            >
              <ExceptionalActivitiesRequestPanelEmployee />,
            </AccessWrapper>
          ),
        },
        {
          name: ExceptionalActivitiesTitles.recuperation,
          to: 'recuperation',
          icon: <CachedIcon />,
          componentRendered: (
            <AccessWrapper
              restrictFullTimeCollaborator
              occupations={[EmployeeOccupations.DG]}
            >
              <ExceptionalActivitiesRecuperation />,
            </AccessWrapper>
          ),
        },
      ];
  return tabItemsExceptionalActivities;
}

export default function ExceptionalActivities() {
  const { pathname } = useLocation();
  const isV2 = _.includes(pathname.split('/'), 'mission-followup');
  return (
    <PageWrapper noLimit>
      <TabHeader title={PageTitles.exceptionalActivities} />
      <SubTabsNavigation
        items={v2TabItemsExceptionalActivities(isV2)}
        variant={'scrollable'}
      />
    </PageWrapper>
  );
}
