import CloseIcon from '@mui/icons-material/Close';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import {
  Chip,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import EmployeeSeniority from 'components/commons/EmployeeSeniority/EmployeeSeniority';
import EmployeeAvatarRenderer from 'components/Phonebook/EmployeeCard/EmployeeAvatarRenderer/EmployeeAvatarRenderer';
import {
  AvatarDisplayMode,
  AvatarDisplaySize,
} from 'components/Phonebook/utils';
import {
  ActivityNode,
  EmployeeNode,
  TrophyNode,
  useAllActivitiesForUserQuery,
  useAllEmployeeTrophiesQuery,
} from 'generated/graphql';
import moment from 'moment';
import React from 'react';
import { graphQlDateFormatter } from 'utils';

import { getEmployeeOccupationInfo } from '../EmployeeOccupationIndicator/EmployeeOccupationIndicator';
import { GradeChip } from '../GradeChip';
import MissionsList from '../Missions/MissionsList';
import TrophiesList from '../Trophies/TrophiesList';

interface EmployeeProfileModalProps {
  open: boolean;
  onClose: () => void;
  employee: EmployeeNode;
}

export default function EmployeeProfileModal({
  open,
  onClose,
  employee,
}: EmployeeProfileModalProps) {
  const theme = useTheme();
  const { data } = useAllActivitiesForUserQuery({
    variables: {
      dateFrom: graphQlDateFormatter(new Date()),
      dateTo: graphQlDateFormatter(new Date()),
      employeeId: employee.id,
      withSubmission: false,
    },
  });
  const { data: trophiesData } = useAllEmployeeTrophiesQuery({
    variables: {
      employeeId: employee.id,
    },
  });
  return (
    <Dialog open={open} onClose={onClose} maxWidth={false}>
      <DialogContent
        sx={{
          overflowX: 'hidden',
          width: '679px',
          backgroundColor: theme.palette.modalBackground.main,
          pt: 0,
        }}
        data-testid={'profile-modal'}
      >
        <Stack
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Stack flexDirection={'row'} alignItems={'center'} sx={{ py: 2 }}>
            <Typography variant="h3Bold" component="h3" sx={{ mr: 2 }}>
              Infos Collaborateur
            </Typography>
            {employee.absenceReturnDate && (
              <Chip
                sx={{
                  borderRadius: 1,
                  color: theme.palette.contrastText.main,
                  fontWeight: '700',
                  fontSize: '0.75rem',
                  height: '20px',
                }}
                color="holidayGrey"
                label={`De retour le ${moment(
                  employee.absenceReturnDate
                ).format('DD/MM/YYYY')}`}
              />
            )}
          </Stack>
          <IconButton edge={'end'} onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack
          sx={{
            backgroundColor: theme.palette.background.paper,
            p: 1.5,
            borderRadius: '8px',
          }}
          flexDirection={'row'}
        >
          {open && (
            <EmployeeAvatarRenderer
              employee={employee}
              parameters={{
                mode: AvatarDisplayMode.PHONEBOOK,
                size: AvatarDisplaySize.STANDARD,
                backgroundColor: theme.palette.grey[100],
              }}
            />
          )}
          <Stack sx={{ ml: 3 }} key={'employeeInfos'} width="100%">
            <Stack
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Typography variant={'h2Bold'} component="h2">
                {employee.firstName}
              </Typography>
              <EmployeeSeniority employee={employee} />
            </Stack>
            <Typography variant={'h2Bold'} component="h2">
              {employee.lastName}
            </Typography>
            <Typography variant={'bodyBold'} sx={{ mt: 1, mb: 1.5 }}>
              {
                getEmployeeOccupationInfo(employee.occupation, false)
                  .occupationName
              }
            </Typography>
            <Stack sx={{ mb: 1 }}>
              <GradeChip employee={employee} displayAllGrades />
            </Stack>
            {employee.phoneNumber && (
              <Stack flexDirection={'row'} alignItems={'center'}>
                <PhoneIcon sx={{ color: 'text.secondary', height: '18px' }} />
                <Typography variant="bodyBold" color={'text.secondary'}>
                  {employee.phoneNumber}
                </Typography>
              </Stack>
            )}
            <Stack
              flexDirection={'row'}
              alignItems={'center'}
              sx={{ mt: 0.25 }}
            >
              <MailOutlineIcon
                sx={{ color: 'text.secondary', height: '18px' }}
              />
              <Typography variant="bodyBold" color={'text.secondary'}>
                {employee.email}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack flexDirection={'row'} sx={{ mt: 1.5 }}>
          <MissionsList
            sx={{
              width: '458px',
              height: '214px',
              backgroundColor: theme.palette.background.paper,
              borderRadius: '8px',
              mr: 1.5,
              padding: 1.5,
              pr: 1.5,
              overflow: 'auto',
            }}
            activities={data?.allActivitiesForUser as ActivityNode[]}
            onClose={onClose}
          />
          <TrophiesList
            sx={{
              height: '214px',
              width: '214px',
              backgroundColor: theme.palette.background.paper,
              borderRadius: '8px',
              padding: 1.5,
              overflow: 'auto',
            }}
            trophies={trophiesData?.employee?.trophies as TrophyNode[]}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
