import { TableCell, TableCellProps } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import styles from './styles/PolyTableCell.module.scss';

const PolyTableCell = (properties: TableCellProps) => {
  return (
    <TableCell
      {...properties}
      classes={{
        root: clsx(
          styles.root,
          properties.align === 'right' ? styles.alignRight : null
        ),
      }}
    />
  );
};

export default PolyTableCell;
