import {
  BillingActivityInformationNode,
  PurchaseOrderNode,
} from 'generated/graphql';
import { Moment } from 'moment';
import { getBills } from 'utils';

export const getPoAmountPerMonths = (
  po: PurchaseOrderNode,
  months: Moment[],
  numberOfMonths: number
) => {
  const bills = getBills(po);

  const amountPerMonths: number[] = new Array(numberOfMonths).fill(0);
  bills?.forEach((bill) => {
    if (bill?.months.length === 0) {
      return 0;
    }
    bill?.months.forEach((month) => {
      // Get the index in amontPerMonths according to the parameter 'months'
      const index = month
        .add(-1 * months[0].get('month'), 'month')
        .get('month');
      const totalInEuro = (bill.total || 0) / bill.currencyConversionRate;
      if (
        index >= 0 &&
        index < numberOfMonths &&
        month.isSame(months[0], 'year')
      ) {
        amountPerMonths[index] += totalInEuro / bill.months.length;
      }
    });
  });

  return amountPerMonths;
};

export const getAmountPerMonthForActivities = (
  billingMissions: BillingActivityInformationNode[],
  year: Moment
) => {
  return billingMissions.map((billingMission) => {
    const months = [] as Moment[];
    for (let index = 0; index < 12; index++) {
      months.push(year.clone().startOf('year').add(index, 'month'));
    }
    const poAmounts: number[] | undefined = billingMission.purchaseOrders
      ?.map((po) => po && getPoAmountPerMonths(po, months, 12))
      .reduce(
        (prev, next) => prev.map((value, index) => value + next[index]),
        new Array(12).fill(0)
      );

    return {
      amounts: poAmounts,
      activity: billingMission.activity,
      client: billingMission.billingClient,
    };
  });
};
