import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Box } from '@mui/material';
import BillCollaboratorDaysForm from 'components/MissionFollowUp/BillDetail/BillCollaboratorDaysForm';
import PongoButton from 'components/MUIOverload/PongoButton';
import {
  AverageDailyRateCollaboratorNode,
  PurchaseOrderSubMissionNode,
  useFetchAssignmentsForActivityAndEmployeesQuery,
} from 'generated/graphql';
import useOnMountEffect from 'hooks/useOnMountEffect';
import _ from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'store';

import { getEmployeesIds } from './BillCollaboratorDays';

interface BillFormSubMissionProps {
  poSubMission: Partial<PurchaseOrderSubMissionNode>;
  nestedIndex: number;
  isEditable: boolean;
  isCreating: boolean;
  handleRefreshData: () => void;
  setExpandedAccordions: Dispatch<SetStateAction<Set<number>>>;
}

export default function BillFormSubMission(
  properties: BillFormSubMissionProps
) {
  const { id: activityId } = useSelector(
    (state) => state.activity.currentMission
  );
  const currentPurchaseOrder = useSelector(
    (state) => state.purchaseOrder.currentPurchaseOrder
  );
  const form = useFormContext();

  const subMissionCollaboratorsFieldName = `subMissions.${properties.nestedIndex}.collaborators`;
  const {
    fields: subMissionCollaboratorsFields,
    append: subMissionCollaboratorsAppend,
    remove: subMissionCollaboratorsRemove,
  } = useFieldArray({
    control: form.control,
    name: subMissionCollaboratorsFieldName,
    keyName: 'key',
  });

  function initialiseCollaboratorDay(
    collaborators: AverageDailyRateCollaboratorNode[] | undefined
  ) {
    subMissionCollaboratorsRemove();
    _.map(collaborators, (collaborator) => {
      subMissionCollaboratorsAppend({
        collaboratorRate: collaborator,
        daysNumbers: [],
        subMissionId: properties.poSubMission.subMission?.id,
      });
    });
  }

  function addCollaboratorDay() {
    subMissionCollaboratorsAppend({
      collaboratorRate: undefined,
      daysNumbers: [],
      subMissionId: properties.poSubMission.subMission?.id,
    });
  }

  useOnMountEffect(() => {
    if (properties.isCreating && subMissionCollaboratorsFields.length === 0) {
      initialiseCollaboratorDay(properties.poSubMission.collaborators);
    }
  });

  const { data: assignments } = useFetchAssignmentsForActivityAndEmployeesQuery(
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        employeesId: getEmployeesIds(currentPurchaseOrder),
        activityId: activityId,
      },
    }
  );

  const collaboratorOptionsRef = useRef<AverageDailyRateCollaboratorNode[]>([]);

  useEffect(() => {
    const selectedCollaboratorsIds = new Set(
      form.watch(subMissionCollaboratorsFieldName)
        ? _.map(
            form.watch(subMissionCollaboratorsFieldName),
            (field) => field.collaboratorRate?.id
          )
        : []
    );
    collaboratorOptionsRef.current =
      _.map(properties.poSubMission.collaborators, (collaborator) => ({
        ...collaborator,
        display: !selectedCollaboratorsIds.has(collaborator?.id),
      })) || [];
    // eslint-disable-next-line
  }, [JSON.stringify(form.watch(subMissionCollaboratorsFieldName))]);

  return (
    <>
      {_(subMissionCollaboratorsFields)
        .map((collaboratorField, index) => {
          const formName = `${subMissionCollaboratorsFieldName}.${index}`;
          const collaboratorIds = _.reduce(
            subMissionCollaboratorsFields,
            function (ids: string[], collaboratorField) {
              if (collaboratorField.collaboratorRate) {
                ids.push(collaboratorField.collaboratorRate.collaborator.id);
              }
              return ids;
            },
            []
          );

          return (
            <BillCollaboratorDaysForm
              collaboratorIds={collaboratorIds}
              collaboratorADR={collaboratorField.collaboratorADR}
              options={collaboratorOptionsRef.current}
              assignments={assignments}
              formName={formName}
              disabled={!properties.isEditable}
              currency={currentPurchaseOrder.currency}
              key={collaboratorField.key}
              activityId={properties.poSubMission.subMission?.id}
              selfDelete={() => subMissionCollaboratorsRemove(index)}
              nestedIndex={properties.nestedIndex}
              setExpandedAccordions={properties.setExpandedAccordions}
            />
          );
        })
        .value()}
      {properties.isEditable && (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            mt: 2,
            gap: 1,
          }}
        >
          <PongoButton
            buttonStyle="secondary"
            variant="contained"
            size={'small'}
            startIcon={<AddCircleOutlineOutlinedIcon />}
            onClick={addCollaboratorDay}
            disabled={
              subMissionCollaboratorsFields.length ===
              properties.poSubMission?.collaborators?.length
            }
          >
            Nouveau collaborateur
          </PongoButton>
          <PongoButton
            buttonStyle="secondary"
            variant="contained"
            size={'small'}
            startIcon={<PlaylistAddIcon />}
            onClick={() =>
              initialiseCollaboratorDay(properties.poSubMission.collaborators)
            }
            disabled={
              subMissionCollaboratorsFields.length ===
              properties.poSubMission?.collaborators?.length
            }
          >
            Ajouter tous les collaborateurs du BDC
          </PongoButton>
          <PongoButton
            buttonStyle="secondary"
            variant="contained"
            size={'small'}
            startIcon={<DeleteOutlinedIcon />}
            onClick={() => subMissionCollaboratorsRemove()}
            disabled={subMissionCollaboratorsFields.length === 0}
          >
            Supprimer tous les collaborateurs
          </PongoButton>
        </Box>
      )}
    </>
  );
}
