import { SupervisorAccount, WorkOutline } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import clsx from 'clsx';
import { DateRangePicker } from 'components/commons/DateRangePicker';
import PolyFooter from 'components/MUIOverload/PolyFooter';
import PongoButton from 'components/MUIOverload/PongoButton';
import { OvertimeAuthorizationNode } from 'generated/graphql';
import moment from 'moment';
import React, { useState } from 'react';
import { displayEmployee, graphQlDateFormatter } from 'utils';

import styles from '../ExceptionalActivitiesNewRequest/styles/ExceptionalActivitiesNewRequestEmployee.module.scss';
import { OvertimeAuthorizationDataToSend } from '../types';

interface AuthorizationContentProps {
  authorizationData: OvertimeAuthorizationNode;
  handleCancel?: () => void;
  handleValidate?: (data: OvertimeAuthorizationDataToSend) => void;
  editable?: boolean;
}

const format_data = (data: OvertimeAuthorizationNode) => {
  const { managerComment, id, periodEnd, periodStart } = data;
  return {
    objectId: id,
    periodStart: periodStart,
    periodEnd: periodEnd,
    managerComment: managerComment ? managerComment : '',
  };
};
const AuthorizationContent = (properties: AuthorizationContentProps) => {
  const [handledData, setHandledData] =
    useState<OvertimeAuthorizationDataToSend>(
      format_data(properties.authorizationData)
    );

  const handleDateRangeChange = (period: [Date, Date]) => {
    handledData.periodStart = graphQlDateFormatter(period[0]);
    handledData.periodEnd = graphQlDateFormatter(period[1]);
    setHandledData(handledData);
  };
  const handleComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    handledData.managerComment = event.target.value;
    setHandledData(handledData);
  };
  const handleCancel = () => {
    if (properties.handleCancel) {
      properties.handleCancel();
    }
  };

  const handleValidate = () => {
    if (properties.handleValidate) {
      properties.handleValidate(handledData);
    }
  };

  return (
    <div>
      {!properties.editable && (
        <p className={styles.informationTitle}>{'Information'}</p>
      )}
      <p className={styles.information}>{'Période'}</p>
      <DateRangePicker
        dateRange={[
          moment(handledData.periodStart).toDate(),
          moment(handledData.periodEnd).toDate(),
        ]}
        disableFuture={false}
        handleDateRangeChange={handleDateRangeChange}
        disabled={!properties.editable}
      />
      <div className={styles.missionManagerContainer}>
        <div className={styles.missionInput}>
          <p className={styles.information}>{'Mission Associée'}</p>
          <TextField
            id="input-with-icon-textfield"
            className={clsx(styles.inputSmall, styles.inputSmall_disabled)}
            disabled={true}
            variant={'outlined'}
            defaultValue={properties.authorizationData.activity.name}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <WorkOutline />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className={styles.managerInput}>
          <p className={styles.information}>{'Manager'}</p>
          <TextField
            id="input-with-icon-textfield"
            className={clsx(styles.inputSmall, styles.inputSmall_disabled)}
            disabled={true}
            variant={'outlined'}
            defaultValue={displayEmployee(
              properties.authorizationData.manager.firstName,
              properties.authorizationData.manager.lastName
            )}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SupervisorAccount />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
      <p className={styles.information}>{'Commentaire'}</p>
      <TextField
        id="input-with-icon-textfield"
        className={clsx(
          styles.input,
          !properties.editable ? styles.input_disabled : styles.input_enabled
        )}
        disabled={!properties.editable}
        variant={'outlined'}
        defaultValue={handledData.managerComment}
        onChange={handleComment}
      />
      {properties.editable && (
        <PolyFooter>
          <PongoButton onClick={handleCancel}>{'Annuler'}</PongoButton>
          <PongoButton
            type={'submit'}
            variant={'contained'}
            color={'primary'}
            onClick={handleValidate}
          >
            {'Valider'}
          </PongoButton>
        </PolyFooter>
      )}
    </div>
  );
};
export default AuthorizationContent;
