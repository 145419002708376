import FieldDisplay from 'components/commons/FieldDisplay/FieldDisplay';
import { ModalityViewProps } from 'components/MissionFollowUp/BillDetail/BillDetail/BillView/MissionSummaryView/ModalitiesView/ModalityEmailView';
import React from 'react';

export default function ModalityPostalView(properties: ModalityViewProps) {
  return (
    <FieldDisplay
      title={'Nom du destinataire'}
      value={properties.modality.billingName}
      flexDirection="column"
      noWrap={false}
      showColon={false}
      titleVariant="bodyBold"
      valueVariant="bodySemiBold"
    />
  );
}
