import PongoButton from 'components/MUIOverload/PongoButton';
import React, { useState } from 'react';

import AuthorizationModal from '../AuthorizationModal';
import HistoryModal from '../HistoryModal';
import ExportButton from './Table/Header/ExportButton';

export default function AuthAndHistoryButtonsAndModals() {
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const [openHistoryModal, setOpenHistoryModal] = useState(false);

  const clickOpenAuthModal = () => {
    setOpenAuthModal(true);
  };

  const clickOpenHistoryModal = () => {
    setOpenHistoryModal(true);
  };

  return (
    <>
      <PongoButton
        onClick={clickOpenAuthModal}
        variant="contained"
        color="primary"
      >
        Rendre la main
      </PongoButton>
      <PongoButton
        onClick={clickOpenHistoryModal}
        variant="contained"
        color="primary"
      >
        Historique
      </PongoButton>
      <ExportButton displayIcon={false} />
      <>
        <AuthorizationModal open={openAuthModal} setOpen={setOpenAuthModal} />
        <HistoryModal
          open={openHistoryModal}
          setOpen={(p: boolean) => {
            setOpenHistoryModal(p);
          }}
        />
      </>
    </>
  );
}
