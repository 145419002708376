// Enum with same types as in back-end
import {
  BillingBillingModalitiesBillingTypeChoices,
  Scalars,
} from 'generated/graphql';

// Displayed text
export const ModalityTypeDescription = [
  {
    key: BillingBillingModalitiesBillingTypeChoices.Email,
    displayed: 'Email',
  },
  {
    key: BillingBillingModalitiesBillingTypeChoices.Postal,
    displayed: 'Courrier',
  },
  {
    key: BillingBillingModalitiesBillingTypeChoices.Upload,
    displayed: 'Dépôt sur une plateforme',
  },
];

export interface Modality {
  billingType?: BillingBillingModalitiesBillingTypeChoices;
  id?: Scalars['ID'];
  billingName?: string;
  billingEmail?: string;
  billingCode?: string;
  billingUrl?: string;
  billingAddress?: string;
}
