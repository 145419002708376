import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { EmployeeNode } from 'generated/graphql';
import _ from 'lodash';
import React from 'react';

import EmployeeCard from '../EmployeeCard/EmployeeCard';
interface EmployeeTableProps {
  employees: EmployeeNode[];
}

export const EMPLOYEE_TABLE_STYLE = {
  display: 'grid',
  justifyContent: 'space-between',
  gridColumnGap: '15px',
  gridTemplateColumns: 'repeat(auto-fill, minmax(208px, 208px))',
};

export const EMPLOYEE_TABLE_PHONE_STYLE = {
  display: 'grid',
  boxSizing: 'border-box',
  gridColumnGap: '10px',
  gridTemplateColumns: 'repeat(2, 1fr)',
};

export default function EmployeeTable({ employees }: EmployeeTableProps) {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid
      container
      rowSpacing={{ xs: 1.25, sm: 2.5 }}
      sx={isPhone ? EMPLOYEE_TABLE_PHONE_STYLE : EMPLOYEE_TABLE_STYLE}
    >
      {_(employees)
        .orderBy(['lastName', 'firstName'])
        .map((employee) => (
          <Grid item key={employee.id} sx={{ minWidth: 0 }}>
            <EmployeeCard employee={employee} />
          </Grid>
        ))
        .value()}
    </Grid>
  );
}
