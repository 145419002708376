import { FilterData } from 'components/Reporting/Indicators/Filter/utils';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

export interface BillableStaffingContext {
  optionsSelected: FilterData[];
  setOptionsSelected: (param: FilterData[]) => void;
}

export const BillableStaffingContext = createContext<BillableStaffingContext>({
  optionsSelected: [],
  setOptionsSelected: () => {
    throw new Error('function setOptionSelected not set');
  },
});

export function useBillableStaffingContext() {
  const billableStaffingContext = useContext(BillableStaffingContext);
  if (!billableStaffingContext) {
    throw new Error('useContext() cannot be called on such a page');
  }
  return billableStaffingContext;
}

export default function BillableStaffingContextProvider(
  properties: PropsWithChildren<{}>
) {
  const [optionsSelected, setOptionsSelected] = useState<FilterData[]>([]);
  return (
    <BillableStaffingContext.Provider
      value={{
        optionsSelected: optionsSelected,
        setOptionsSelected: setOptionsSelected,
      }}
    >
      {properties.children}
    </BillableStaffingContext.Provider>
  );
}
