import { Person } from '@mui/icons-material';
import { Delete } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  DialogActions,
  Grid,
  InputAdornment,
  Stack,
  Step,
  StepButton,
  Stepper,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import SectionTitle from 'components/commons/SectionTitle';
import GridSubTitle from 'components/MissionFollowUp/GridSubTitle';
import LabelTextFieldGrid from 'components/MissionFollowUp/LabelTextFieldGrid';
import PolyDialogActions from 'components/MUIOverload/PolyDialog/DefaultComponents/PolyDialogActions';
import PongoButton from 'components/MUIOverload/PongoButton';
import { getUrlList } from 'components/Navigation/DesktopNavbar/utils';
import {
  ActivitiesActivityTypeChoices,
  ActivityNode,
  BusinessClientNode,
  EmployeeNode,
  useAllActiveAndFutureEmployeesQuery,
  useAllBusinessClientsQuery,
  useComexQuery,
  useCreateOrUpdateInternalActivityMutation,
  useDeleteActivityMutation,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { isArrayFilled } from 'pages/MissionFollowUp/formValidators';
import { requiredForm } from 'pages/MissionFollowUp/formValidators';
import { COMPANY_NAME, SaveButtonName } from 'poly-constants';
import { default as React, useState } from 'react';
import { Controller, FieldError, FormProvider, useForm } from 'react-hook-form';
import { useHistory, useRouteMatch } from 'react-router-dom';
import urljoin from 'url-join';
import { displayEmployee, graphQlDateFormatter } from 'utils';

interface InternalActivityFormProps {
  onClose: () => void;
  isCftActivity: boolean;
  activity?: ActivityNode;
  refetchActivityInfo?: () => void;
}

export const InternalActivityForm = ({
  onClose,
  isCftActivity,
  activity,
  refetchActivityInfo,
}: InternalActivityFormProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { path } = useRouteMatch();
  const history = useHistory();
  const { activities } = getUrlList();

  const isEdit = !!activity;
  const createdOrUpdatedStr = isEdit ? 'mise à jour' : 'créée';

  const [comex, setComex] = useState<EmployeeNode[]>([]);
  useComexQuery({
    onCompleted: (data) => {
      if (data?.comex) {
        setComex(
          data.comex.filter(
            (employee) =>
              employee.leavingDate > moment() || !employee.leavingDate
          ) as EmployeeNode[]
        );
      }
    },
  });

  const defaultValues = {
    name: activity?.name || '',
    selectedClient: { __typename: '', id: '', name: '' },
    selectedDirector: activity?.director,
    selectedChiefs: activity?.chiefs || [],
    selectedLeadDevs: activity?.leadDevs || [],
    description: activity?.description || '',
    techStack: activity?.techStack || '',
    startDate: activity?.startDate || moment().format('YYYY-MM-DD'),
    expirationDate: activity?.expirationDate || moment().format('YYYY-MM-DD'),
  };

  const form = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: defaultValues,
    criteriaMode: 'firstError',
  });

  const { errors, handleSubmit, control, reset } = form;

  const [businessClients, setBusinessClients] = useState<BusinessClientNode[]>(
    []
  );
  useAllBusinessClientsQuery({
    onCompleted: (data) => {
      if (data.allBusinessClients) {
        setBusinessClients(data.allBusinessClients as BusinessClientNode[]);
        const defaultClient = data.allBusinessClients.find(
          (client) => client.name === (isEdit ? activity.client : COMPANY_NAME)
        );
        if (!isCftActivity || isEdit) {
          reset({
            ...defaultValues,
            selectedClient: defaultClient,
          });
        }
      }
    },
  });

  const [createOrUpdateInternalActivityMutation] =
    useCreateOrUpdateInternalActivityMutation({
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
      onCompleted: (data) => {
        const activity = data.createOrUpdateInternalActivity
          ?.activity as ActivityNode;

        enqueueSnackbar(
          `La mission « ${activity.name} » a bien été ${createdOrUpdatedStr}`,
          {
            variant: 'success',
          }
        );

        onClose();

        if (isEdit) {
          refetchActivityInfo && refetchActivityInfo();
        } else {
          activity && history.push(urljoin(path, activity.id as string));
        }
      },
    });

  const [activityChiefs, setActivityChiefs] = useState<EmployeeNode[]>([]);
  const [leadDevs, setLeadDevs] = useState<EmployeeNode[]>([]);
  useAllActiveAndFutureEmployeesQuery({
    onCompleted: (data) => {
      if (data?.allActiveAndFutureEmployees) {
        setActivityChiefs(data.allActiveAndFutureEmployees as EmployeeNode[]);
        setLeadDevs(data.allActiveAndFutureEmployees as EmployeeNode[]);
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const onSubmit = async (submitValues: typeof defaultValues) => {
    await createOrUpdateInternalActivityMutation({
      variables: {
        activityId: activity?.id,
        directorId: submitValues.selectedDirector?.id || '',
        chiefsIds: _.flatMap(submitValues.selectedChiefs, 'id') || '',
        leadDevsIds: _.flatMap(submitValues.selectedLeadDevs, 'id') || '',
        description: submitValues.description,
        techStack: submitValues.techStack,
        client: !isCftActivity
          ? COMPANY_NAME
          : submitValues.selectedClient?.name || '',
        businessClientId: submitValues.selectedClient?.id || '',
        expirationDate: graphQlDateFormatter(
          moment(submitValues.expirationDate)
        ),
        name: submitValues.name,
        startDate: graphQlDateFormatter(moment(submitValues.startDate)),
        type: isCftActivity
          ? ActivitiesActivityTypeChoices.Cft
          : ActivitiesActivityTypeChoices.Int,
      },
    });
  };

  const navTabs = (
    <Box sx={{ width: '100%', mt: 4, minWidth: 10 }}>
      <Stepper activeStep={0} alternativeLabel>
        <Step key={'Informations'} sx={{ display: 'inherit' }}>
          <StepButton color="inherit">
            <Typography
              textTransform={'uppercase'}
              variant="bodyS"
              color={'primary'}
            >
              Informations
            </Typography>
          </StepButton>
        </Step>
      </Stepper>
    </Box>
  );

  const [deleteActivityMutation] = useDeleteActivityMutation({
    onCompleted: () => {
      enqueueSnackbar(`La mission « ${activity?.name} » a été supprimée`, {
        variant: 'success',
      });
      onClose();
      history.push(activities);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const handleDelete = () => {
    deleteActivityMutation({
      variables: {
        activityId: activity?.id || '',
      },
    });
  };

  const displayDeleteButton = isEdit;

  const actionButtons = (
    <Stack
      flexDirection="row"
      justifyContent={displayDeleteButton ? 'space-between' : 'end'}
      display="flex"
      mx={1}
    >
      <DialogActions sx={{ display: displayDeleteButton ? 'inherit' : 'none' }}>
        <PongoButton
          onClick={handleDelete}
          variant="contained"
          color="error"
          startIcon={<Delete />}
        >
          Supprimer la mission
        </PongoButton>
      </DialogActions>
      <PolyDialogActions
        primaryButtonName={SaveButtonName}
        handleSecondary={onClose}
        primaryButtonType={'submit'}
        isPrimaryButtonDisabled={!_.isEmpty(errors)}
      />
    </Stack>
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        {navTabs}
        <Box sx={{ display: 'inherit' }}>
          <Grid container spacing={0} sx={{ paddingBottom: 2 }}>
            <GridSubTitle customspacingtop={20}>
              <SectionTitle variant="h3Bold">
                Informations de la mission
              </SectionTitle>
            </GridSubTitle>
            <Grid
              container
              item
              columns={{
                xs: 8,
              }}
            >
              <Controller
                name="name"
                rules={{ required: requiredForm }}
                render={(properties) => (
                  <LabelTextFieldGrid
                    {...properties}
                    onChange={(e) => {
                      properties.onChange(e);
                    }}
                    required
                    errorform={errors.name as FieldError}
                    name={'name'}
                    title="Nom de la mission"
                    sizegrid={8}
                  />
                )}
              />
              <Grid item xs={4}>
                <Controller
                  name="selectedClient"
                  rules={{ required: requiredForm }}
                  control={control}
                  required
                  render={(properties) => {
                    return (
                      <Autocomplete
                        id={'selected-client'}
                        autoComplete
                        autoHighlight
                        disabled={!isCftActivity}
                        {...properties}
                        options={businessClients}
                        value={properties.value || null}
                        isOptionEqualToValue={(option, value) =>
                          option ? value.id === option.id : true
                        }
                        onChange={(_, data) => {
                          properties.onChange(data);
                        }}
                        getOptionLabel={(client) => client.name}
                        renderInput={(parameters) => (
                          <LabelTextFieldGrid
                            required
                            title="Client métier"
                            sizegrid={12}
                            {...parameters}
                            errorform={errors.selectedClient as FieldError}
                            InputProps={{
                              ...parameters.InputProps,
                            }}
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name={'selectedDirector'}
                  rules={{ required: requiredForm }}
                  render={(properties) => (
                    <Autocomplete
                      {...properties}
                      autoComplete
                      id={'selected-director'}
                      autoHighlight
                      options={comex}
                      value={properties.value || null}
                      isOptionEqualToValue={(option, value) =>
                        option ? value.id === option.id : true
                      }
                      onChange={(_, data) => {
                        properties.onChange(data);
                      }}
                      getOptionLabel={(option) =>
                        displayEmployee(option.firstName, option.lastName)
                      }
                      renderInput={(parameters) => (
                        <LabelTextFieldGrid
                          required
                          name={'selected-director'}
                          title="Directeur de mission"
                          sizegrid={12}
                          {...parameters}
                          errorform={errors.selectedDirector as FieldError}
                          InputProps={{
                            ...parameters.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <Person />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                  control={control}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name={'selectedChiefs'}
                  rules={{
                    required: requiredForm,
                    validate: {
                      isArrayFilled,
                    },
                  }}
                  render={(properties) => (
                    <Autocomplete
                      autoComplete
                      multiple
                      id={'selected-chiefs'}
                      autoHighlight
                      {...properties}
                      size={'small'}
                      options={activityChiefs}
                      isOptionEqualToValue={(option, value) =>
                        option ? value.id === option.id : true
                      }
                      onChange={(_, data) => properties.onChange(data)}
                      getOptionLabel={(option) =>
                        displayEmployee(option.firstName, option.lastName)
                      }
                      renderInput={(parameters) => (
                        <LabelTextFieldGrid
                          required
                          name={'selected-chief(s)'}
                          title="Chef(s) de mission"
                          sizegrid={12}
                          {...parameters}
                          errorform={
                            errors.selectedChiefs as unknown as FieldError
                          }
                          InputProps={{
                            ...parameters.InputProps,
                          }}
                        />
                      )}
                    />
                  )}
                  control={control}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name={'selectedLeadDevs'}
                  render={(properties) => (
                    <Autocomplete
                      autoComplete
                      multiple
                      id={'selected-lead-devs'}
                      {...properties}
                      size={'small'}
                      options={leadDevs}
                      isOptionEqualToValue={(option, value) =>
                        option ? value.id === option.id : true
                      }
                      onChange={(_, data) => properties.onChange(data)}
                      getOptionLabel={(option) =>
                        displayEmployee(option.firstName, option.lastName)
                      }
                      renderInput={(parameters) => (
                        <LabelTextFieldGrid
                          name={'selected-lead-dev(s)'}
                          title="Lead dev(s)"
                          sizegrid={12}
                          {...parameters}
                          errorform={
                            errors.selectedLeadDevs as unknown as FieldError
                          }
                          InputProps={{
                            ...parameters.InputProps,
                          }}
                        />
                      )}
                    />
                  )}
                  control={control}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="startDate"
                  rules={{ required: requiredForm }}
                  render={(properties) => (
                    <DatePicker
                      {...properties}
                      renderInput={(props) => (
                        <LabelTextFieldGrid
                          {...props}
                          required
                          title="Date de début"
                          name={'startDate'}
                          errorform={errors.startDate as FieldError}
                          sizegrid={12}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="expirationDate"
                  rules={{ required: requiredForm }}
                  render={(properties) => (
                    <DatePicker
                      {...properties}
                      renderInput={(props) => (
                        <LabelTextFieldGrid
                          {...props}
                          required
                          title="Date de fin"
                          name={'expirationDate'}
                          errorform={errors.expirationDate as FieldError}
                          sizegrid={12}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Controller
                name="description"
                render={(properties) => (
                  <LabelTextFieldGrid
                    {...properties}
                    onChange={(e) => {
                      properties.onChange(e);
                    }}
                    errorform={errors.description as FieldError}
                    name={'description'}
                    title="Description de la mission"
                    type="description"
                    sizegrid={8}
                    multiline
                    rows={4}
                  />
                )}
              />
              <Controller
                name="techStack"
                render={(properties) => (
                  <LabelTextFieldGrid
                    {...properties}
                    onChange={(e) => {
                      properties.onChange(e);
                    }}
                    errorform={errors.techStack as FieldError}
                    name={'techStack'}
                    title="Stack technique"
                    type="techStack"
                    sizegrid={8}
                    multiline
                    rows={4}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        {actionButtons}
      </form>
    </FormProvider>
  );
};
