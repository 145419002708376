import { DialogActions, DialogContent, DialogContentText } from '@mui/material';
import PolyDialog from 'components/MUIOverload/PolyDialog';
import PolyDialogTitle from 'components/MUIOverload/PolyDialogTitle';
import PongoButton from 'components/MUIOverload/PongoButton';
import React, { PropsWithRef } from 'react';

interface ModalsProps {
  body: string;
  title: string;
  open: boolean;
  handleClose: () => void;
  handleSave: () => void;
  nameButton: string;
}

const Modals = (properties: PropsWithRef<ModalsProps>) => {
  return (
    <PolyDialog
      open={properties.open}
      onClose={properties.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <PolyDialogTitle>{properties.title}</PolyDialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {properties.body}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <PongoButton onClick={properties.handleClose}>Annuler</PongoButton>
        <PongoButton variant={'contained'} onClick={properties.handleSave}>
          {properties.nameButton}
        </PongoButton>
      </DialogActions>
    </PolyDialog>
  );
};

export default Modals;
