import { ClickAwayListener, Fade, Popper, PopperProps } from '@mui/material';
import React, { Dispatch, PropsWithChildren, SetStateAction } from 'react';

import PolyPopperDrawer from './PolyPopperDrawer';

interface PolyPopperProps {
  anchorEl: PopperProps['anchorEl'];
  open: boolean;
  orientation?: 'left' | 'right';
  renderToggle: JSX.Element;
  disablePortal?: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  variant?: 'small' | 'medium' | 'big';
}

const PolyPopper = ({
  anchorEl,
  children,
  open,
  orientation = 'left',
  renderToggle,
  setOpen,
  disablePortal = true,
  variant = 'small',
}: PropsWithChildren<PolyPopperProps>) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {renderToggle}
        <Popper
          anchorEl={anchorEl}
          open={open}
          disablePortal={disablePortal}
          transition
          modifiers={[
            {
              name: 'flip',
              enabled: true,
            },
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                boundariesElement: 'viewport',
              },
            },
          ]}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <div>
                <PolyPopperDrawer orientation={orientation} variant={variant}>
                  {children}
                </PolyPopperDrawer>
              </div>
            </Fade>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default PolyPopper;
