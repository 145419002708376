import { Table, TableProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import EditButton from 'components/ActivityMonitoring/ValidateTmActivityMonitoring/EditButton';
import { EnterValidationModeButton } from 'components/ActivityMonitoring/ValidateTmActivityMonitoring/ValidateButton';
import TabHeader from 'components/commons/TabHeader';
import TableContextProvider, {
  ITimeSpent,
} from 'contexts/ValidateTmActivityMonitoring/TableContextProvider';
import {
  ActivityNode,
  BillingNode,
  EmployeeNode,
  SumActivityMonitoringNode,
  useFetchAllManagedTmActivitiesLazyQuery,
  useFetchBillingLazyQuery,
  useFetchTimeSpentTotalLazyQuery,
  useFetchTimeSpentYearLazyQuery,
} from 'generated/graphql';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import ValidateTableBody from 'pages/ActivityPage/ValidateTmActivityMonitoringPage/ValidateTableBody';
import ValidateTableHeader from 'pages/ActivityPage/ValidateTmActivityMonitoringPage/ValidateTableHeader';
import { PageTitles, POLY_DATE_MONTH } from 'poly-constants';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'store';
import { graphQlDateFormatter } from 'utils';

import { buildDateRange } from '../utils';

type DataFetchCacheType = {
  lastFetchedMonth: Moment | null;
  fetchedMissions: string[];
};

const StyledValidateTable = styled(Table)<TableProps>(({ theme }) => ({
  '.MuiTableRow-root': {
    height: '48px',
    border: `solid`,
    borderWidth: '0 1px 0 1px',
    borderColor: `${theme.palette.darkGrey.dark}`,
  },
  '.MuiTableCell-sizeSmall:last-child': {
    padding: '5px',
  },
  '.MuiTableCell-head.MuiTableCell-sizeSmall:last-child': {
    padding: '0px',
  },
  '.MuiTableCell-root': {
    height: 1,
  },
  '@-moz-document url-prefix()': {
    '.MuiTableCell-root': {
      height: '100%',
    },
  },
  border: `solid`,
  borderWidth: '1px 1px 1px 1px',
  borderColor: `${theme.palette.darkGrey.dark}`,
  tableLayout: 'fixed',
}));

export const getBillingKey = (
  employee?: EmployeeNode,
  mission?: ActivityNode,
  month?: Moment
) => {
  return `${employee?.id}-${mission?.id}-${month?.format(POLY_DATE_MONTH)}`;
};
const ValidateTable = () => {
  const [month, setMonth] = useState<Moment>(moment().startOf('month'));
  const dateRange = buildDateRange(month, 4, 0);
  const [dataActivity, setDataActivity] = useState<ActivityNode[]>();
  const [dataBillings, setDataBillings] = useState<BillingNode[]>();
  const sumOfActivities = React.useRef<_.Dictionary<ITimeSpent>>({});
  const { enqueueSnackbar } = useSnackbar();
  const currentMission: ActivityNode = useSelector(
    (state) => state.activity.currentMission
  );
  const selectedMissions: ActivityNode[] = useSelector(
    (state) => state.activity.selectedMissions
  );
  const filteredMissionsIds: string[] = currentMission?.id
    ? [currentMission.id]
    : _.map(selectedMissions, (mission) => mission.id);

  const missionsToFetch = React.useRef<string[]>([]);
  const fetchedMissionsCache = React.useRef<DataFetchCacheType>({
    lastFetchedMonth: null,
    fetchedMissions: [],
  });
  const [isComputing, setIsComputing] = useState<{
    billing: boolean;
    timeSpentYear: boolean;
    timeSpentTotal: boolean;
  }>({
    billing: true,
    timeSpentYear: true,
    timeSpentTotal: true,
  });

  const [
    fetchAllManagedTmActivities,
    { loading: loadingAllManagedTmActivities },
  ] = useFetchAllManagedTmActivitiesLazyQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data?.allTmActivities) {
        const allTmActivities = data.allTmActivities as ActivityNode[];
        missionsToFetch.current = _.map(
          allTmActivities,
          (mission) => mission.id
        );
        setDataActivity(allTmActivities as ActivityNode[]);
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const [fetchAllBillings, { loading: loadingAllBillings }] =
    useFetchBillingLazyQuery({
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data?.allBillings) {
          setDataBillings(data.allBillings as BillingNode[]);
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
    });

  const [refetchTimeSpentYear, { loading: loadingFetchTimeSpentYear }] =
    useFetchTimeSpentYearLazyQuery({
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data?.timeSpentYear) {
          cacheFetchedData(fetchedMissionsCache, missionsToFetch, month);

          _.forEach(
            data.timeSpentYear as SumActivityMonitoringNode[],
            (currentYearlySum) => {
              const key = `${currentYearlySum.activity}-${currentYearlySum.employee}`;
              sumOfActivities.current[key] = {
                ...sumOfActivities.current[key],
                yearlySum: currentYearlySum.yearlyTimeSpent || 0,
              };
            }
          );
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
    });

  const [refetchTimeSpentTotal, { loading: loadingFetchTimeSpentTotal }] =
    useFetchTimeSpentTotalLazyQuery({
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data?.timeSpentTotal) {
          cacheFetchedData(fetchedMissionsCache, missionsToFetch, month);

          _.forEach(
            data.timeSpentTotal as SumActivityMonitoringNode[],
            (currentTotalSum) => {
              const key = `${currentTotalSum.activity}-${currentTotalSum.employee}`;
              sumOfActivities.current[key] = {
                ...sumOfActivities.current[key],
                totalSum: currentTotalSum.totalTimeSpent || 0,
              };
            }
          );
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
    });

  function refetchTimeSpent() {
    refetchTimeSpentYear({
      variables: {
        currentDate: graphQlDateFormatter(month.endOf('month')),
        missionIds: missionsToFetch.current,
      },
    });
    refetchTimeSpentTotal({
      variables: {
        currentDate: graphQlDateFormatter(month.endOf('month')),
        missionIds: missionsToFetch.current,
      },
    });
  }

  useEffect(() => {
    fetchAllManagedTmActivities({
      variables: {
        dateFrom: graphQlDateFormatter(dateRange.start.startOf('month')),
        dateTo: graphQlDateFormatter(dateRange.end.endOf('month')),
        missionIds: filteredMissionsIds,
      },
    });
    // eslint-disable-next-line
  }, [JSON.stringify(filteredMissionsIds), month]);

  useEffect(() => {
    fetchAllBillings({
      variables: {
        dateFrom: graphQlDateFormatter(dateRange.start.startOf('month')),
        dateTo: graphQlDateFormatter(dateRange.end.endOf('month')),
        missionIds: missionsToFetch.current,
      },
    });

    if (missionsToFetch.current.length === 0) {
      return;
    }
    if (month.isSame(fetchedMissionsCache.current.lastFetchedMonth)) {
      const anyMissionsToFetch = _.difference(
        missionsToFetch.current,
        fetchedMissionsCache.current.fetchedMissions
      ).length;
      if (!anyMissionsToFetch) {
        return;
      }
    }

    refetchTimeSpent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingAllManagedTmActivities, month]);

  // with keyBy billing values can be picked in O(1)
  const billingDict = useMemo(() => {
    return _.keyBy(dataBillings, (billing) =>
      getBillingKey(
        billing.employee,
        billing.activity,
        moment(billing.dateBilling)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(dataBillings)]);

  useEffect(() => {
    setIsComputing({
      billing: loadingAllBillings,
      timeSpentYear: loadingFetchTimeSpentYear,
      timeSpentTotal: loadingFetchTimeSpentTotal,
    });
  }, [
    loadingAllBillings,
    loadingFetchTimeSpentYear,
    loadingFetchTimeSpentTotal,
  ]);

  return (
    <TableContextProvider
      dateRange={dateRange}
      baseMonth={month}
      setBaseMonth={setMonth}
      currentMonth={month}
      billings={billingDict}
      missions={dataActivity as ActivityNode[]}
      sumOfActivities={sumOfActivities.current}
      refetchTimeSpent={refetchTimeSpent}
      isComputing={isComputing}
    >
      <TabHeader
        title={PageTitles.validateTmActivityMonitoringPage}
        actionComponents={
          <>
            <EditButton />
            <EnterValidationModeButton />
          </>
        }
      />
      <StyledValidateTable
        size={'small'}
        sx={{
          mt: 2,
        }}
      >
        <ValidateTableHeader />
        <ValidateTableBody dataActivity={dataActivity} />
      </StyledValidateTable>
    </TableContextProvider>
  );
};

export default ValidateTable;

function cacheFetchedData(
  fetchedMissionsCache: React.MutableRefObject<DataFetchCacheType>,
  missionsToFetch: React.MutableRefObject<string[]>,
  month: moment.Moment
) {
  fetchedMissionsCache.current.lastFetchedMonth = month;

  fetchedMissionsCache.current.fetchedMissions = _.uniq(
    fetchedMissionsCache.current.fetchedMissions.concat(missionsToFetch.current)
  );
}
