import { History } from '@mui/icons-material';
import React, { ReactElement } from 'react';

import PongoButton from '../../MUIOverload/PongoButton';
import { useAdrContext } from './AdrContextProvider';

export default function ShowProviderAdrHistoryButton(): ReactElement {
  const { setIsEdit, isShowingHistory, setIsShowingHistory } = useAdrContext();

  return (
    <PongoButton
      variant={'contained'}
      buttonStyle={isShowingHistory ? 'primary' : 'secondary'}
      startIcon={<History />}
      onClick={() => {
        if (!isShowingHistory) {
          setIsEdit(false);
        }
        setIsShowingHistory(!isShowingHistory);
      }}
      size="small"
    >
      Historique
    </PongoButton>
  );
}
