import TableBody from '@mui/material/TableBody';
import _ from 'lodash';
import { CategoryOrder } from 'poly-constants';
import React, { useEffect } from 'react';

import { ActivityGroupType } from '.';
import {
  providerCommentsInterface,
  useValueContext,
} from './context/ValueContextProvider';
import MissionCategory from './MissionCategory';
import TotalRow from './TotalRow';

interface PrincipalTableBody {
  activities: ActivityGroupType;
  providerComments: providerCommentsInterface[];
  footer: React.ReactNode;
}

const PrincipalTableBody = ({
  activities,
  providerComments,
  footer,
}: PrincipalTableBody) => {
  const { setProviderComments } = useValueContext();

  useEffect(() => {
    setProviderComments(providerComments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableBody>
      {_.orderBy(Object.keys(activities), (value: string) =>
        _.get(CategoryOrder, value, 0)
      ).map((category, index) => {
        return (
          <MissionCategory
            key={`category-${category}`}
            category={category}
            listActivity={activities[category]}
            defaultExpanded={index < 2}
          />
        );
      })}
      <TotalRow footer={footer} />
    </TableBody>
  );
};

export default PrincipalTableBody;
