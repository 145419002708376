import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import PolyModal from 'components/MUIOverload/PolyDialog/PolyModal';
import { UnbillableActivityStaffingNode } from 'generated/graphql';
import _ from 'lodash';
import { totalFormat } from 'pages/ActivityPage/utils';
import React, { ReactElement } from 'react';

import { StyledAdrTable } from '../Adr/ProviderAdrTable';
import { StyledTableHead } from '../Adr/ProviderAdrTableHeader';
import { StyledTableRow } from '../Adr/ProviderAdrTableRow';

interface UnbillableActivityStaffing {
  activityName: string;
  fullTimeEquivalent: string;
  percentage: string;
}

interface UnbillableActivityModalProps {
  title: string;
  onClose: () => void;
  unbillableActivityStaffings?: UnbillableActivityStaffingNode[];
  monthWorkDays: number;
}

export default function UnbillableActivityModal({
  title,
  onClose,
  unbillableActivityStaffings,
  monthWorkDays,
}: UnbillableActivityModalProps): ReactElement {
  const totalWorkedDays = _.sumBy(unbillableActivityStaffings, 'workedDays');

  const unbillableStaffings: UnbillableActivityStaffing[] = _.map(
    unbillableActivityStaffings,
    ({ activity, workedDays }) => ({
      activityName: activity.name,
      fullTimeEquivalent: totalFormat(workedDays / monthWorkDays),
      percentage: totalFormat((workedDays / totalWorkedDays) * 100),
    })
  );

  return (
    <PolyModal
      open={true}
      onClose={onClose}
      polyDialogTitle={title}
      noSecondaryButton
    >
      <StyledAdrTable>
        <StyledTableHead>
          <TableRow>
            <TableCell>
              <Typography fontWeight="bold">Missions Internes</Typography>
            </TableCell>
            <TableCell>
              <Typography fontWeight="bold" sx={{ textAlign: 'center' }}>
                ETP non facturés
              </Typography>
            </TableCell>
            <TableCell>
              <Typography fontWeight="bold" sx={{ textAlign: 'center' }}>
                Pourcentage
              </Typography>
            </TableCell>
          </TableRow>
        </StyledTableHead>

        <TableBody>
          {unbillableStaffings.map(
            ({ activityName, fullTimeEquivalent, percentage }) => (
              <StyledTableRow key={`${activityName}-billablesectormodal`}>
                <TableCell>
                  <Typography>{activityName}</Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ textAlign: 'center' }}>
                    {fullTimeEquivalent}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{ textAlign: 'center' }}
                  >{`${percentage}%`}</Typography>
                </TableCell>
              </StyledTableRow>
            )
          )}
          <StyledTableRow>
            <TableCell>
              <Typography fontWeight="bold">Total</Typography>
            </TableCell>
            <TableCell>
              <Typography fontWeight="bold" sx={{ textAlign: 'center' }}>
                {totalFormat(totalWorkedDays / monthWorkDays)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography fontWeight="bold" sx={{ textAlign: 'center' }}>
                100%
              </Typography>
            </TableCell>
          </StyledTableRow>
        </TableBody>
      </StyledAdrTable>
    </PolyModal>
  );
}
