import { TableCell, TableRow } from '@mui/material';
import clsx from 'clsx';
import commonStyles from 'components/commons/css/commons.module.scss';
import { useTableContext } from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/context/TableContextProvider';
import TotalTableCell from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/RevenueTableCells/TotalTableCell';
import styles from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/styles/TableCell.module.scss';
import _ from 'lodash';
import React from 'react';

const TotalRow = () => {
  const { displayedMonths } = useTableContext();

  return (
    <TableRow className={clsx(commonStyles.stickyFooter, styles.revenueRowIn)}>
      <TableCell
        className={clsx(styles.totalColumn, styles.boldTotal)}
        colSpan={2}
      >
        CA Réalisé
      </TableCell>
      {_.map(displayedMonths, (month) => {
        return <TotalTableCell month={month} key={`total-${month}`} />;
      })}
    </TableRow>
  );
};

export default TotalRow;
