import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import ActivityInfoDrawer from 'components/ActivityPage/ActivityPageMobile/ActivityInfoDrawer';
import ActivityMobileMonthView from 'components/ActivityPage/ActivityPageMobile/ActivityMobileMonthView';
import PageWrapper from 'components/commons/PageWrapper';
import TabHeader from 'components/commons/TabHeader';
import { FetchUserActivityQuery } from 'generated/graphql';
import moment, { Moment } from 'moment';
import { PageTitles } from 'poly-constants';
import React, { useState } from 'react';

import PrincipalTable from './PrincipalTable';

const dateFormat = 'MMMM yyyy';
const tooltipContent =
  'Saisissez votre temps de travail hebdomadaire à 0,25J près. Le total doit être égal à votre temps contractuel.';

export type Activity = DeepExtractTypeSkipArrays<
  FetchUserActivityQuery,
  ['allActivitiesForUser']
>;

export type ActivityMonitoring = DeepExtractTypeSkipArrays<
  FetchUserActivityQuery,
  ['allActivitiesForUser', 'activityMonitoring']
>;

export interface ActivityGroupType {
  [id: string]: Array<Activity>;
}

export interface DateRange {
  start: Moment;
  end: Moment;
}

const getFridayOfEndOfMonth = (date: Moment): Moment => {
  date.endOf('month');

  while (date.day() !== 5) {
    date.add(1, 'day');
  }

  return date;
};

const getMondayOfStartOfMonth = (date: Moment): Moment => {
  date.startOf('month');

  while (date.day() !== 1) {
    date.subtract(1, 'day');
  }

  return date;
};

export const buildDateRange = (date: Moment): DateRange => {
  const end = getFridayOfEndOfMonth(date.clone());
  const start = getMondayOfStartOfMonth(date.clone());

  return { start: start, end: end };
};

const PrincipalTablePage = () => {
  const [month, setMonth] = useState<Moment>(
    moment().startOf('week').startOf('month')
  );

  const incrementPeriod = () => {
    const new_period = moment(month, dateFormat).add(1, 'months');
    setMonth(new_period);
  };

  const decrementPeriod = () => {
    const new_period = moment(month, dateFormat).subtract(1, 'months');
    setMonth(new_period);
  };

  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  return (
    <PageWrapper noLimit>
      <TabHeader
        title={PageTitles.activityPage}
        titleIcon={
          isPhone && (
            <InfoIcon color="primary" onClick={() => setIsDrawerOpen(true)} />
          )
        }
        ignoreMissionHeader
      />
      {!isPhone && (
        <Typography color={'text.secondary'} mt={2} mb={3}>
          {tooltipContent}
        </Typography>
      )}
      {isPhone ? (
        <>
          <ActivityMobileMonthView
            month={month}
            incrementPeriod={incrementPeriod}
            decrementPeriod={decrementPeriod}
          />
          <ActivityInfoDrawer
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            drawerTitle={"Déclaration d'activité"}
            drawerContent={tooltipContent}
          />
        </>
      ) : (
        <PrincipalTable
          dateRange={buildDateRange(month)}
          viewMonth={month.format(dateFormat)}
          incrementPeriod={incrementPeriod}
          decrementPeriod={decrementPeriod}
        />
      )}
    </PageWrapper>
  );
};

export default PrincipalTablePage;
