import InfoIcon from '@mui/icons-material/InfoOutlined';
import { TableCell, TableRow } from '@mui/material';
import cellStyles from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/styles/TableCell.module.scss';
import styles from 'components/Revenue/RevenueGlobalTable/styles/TableCell.module.scss';
import React from 'react';

interface RevenueTableEmptyRowProps {
  colSpan?: number;
  emptyText: string;
}

export default function RevenueTableEmptyRow(
  properties: RevenueTableEmptyRowProps
) {
  return (
    <TableRow>
      <TableCell
        colSpan={properties.colSpan || 8}
        className={styles.regularTableCell}
      >
        <div className={cellStyles.emptyCell}>
          <InfoIcon className={styles.infoIcon} />
          <div>{properties.emptyText} </div>
        </div>
      </TableCell>
    </TableRow>
  );
}
