import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Buffer } from 'buffer';
import { useRequestQuery } from 'hooks/useRequestQuery';
import { S3_URL_PICTURE } from 'services/s3';

const onSuccess = (response: AxiosResponse) => {
  return `data:${response.headers['content-type']};base64,${Buffer.from(
    response.data as string
  ).toString('base64')}`;
};

const config: AxiosRequestConfig = {
  baseURL: S3_URL_PICTURE,
  responseType: 'arraybuffer',
};

export function useImageRequest(
  url?: string,
  isTimeStamped?: boolean
): {
  response: string | undefined;
  error: Error | undefined;
  loading: boolean;
  refetch: (newUrl?: string) => void;
} {
  return useRequestQuery(config, onSuccess, url, isTimeStamped);
}
