import { EmployeesEmployeeOccupationChoices } from 'components/Phonebook/utils';
import {
  EmployeesEmployeeContractTypeChoices,
  EmployeesEmployeeGradeChoices,
} from 'generated/graphql';

export enum FilterOptionTypes {
  Contract = 'FilterOptionTypeContractType',
  Grade = 'FilterOptionTypeGrade',
  Occupation = 'FilterOptionTypeOccupation',
}

export interface FilterData {
  id: string | EmployeesEmployeeOccupationChoices;
  __typename: FilterOptionTypes;
}

export const gradeList = [
  EmployeesEmployeeGradeChoices.Collaborator,
  EmployeesEmployeeGradeChoices.Coman,
  EmployeesEmployeeGradeChoices.Comex,
];

export const contractTypeList = [
  EmployeesEmployeeContractTypeChoices.Intern,
  EmployeesEmployeeContractTypeChoices.Cdd,
  EmployeesEmployeeContractTypeChoices.Cdi,
  EmployeesEmployeeContractTypeChoices.Provider,
  EmployeesEmployeeContractTypeChoices.StudentApprentice,
];
