import { TableRow, Typography } from '@mui/material';
import MultiTableCell from '@mui/material/TableCell';
import DataCells from 'components/ActivityPage/common/DataCells';
import MissionNameDisplay from 'components/commons/MissionNameDisplay';
import SubMissionNameDisplay from 'components/commons/SubMissionNameDisplay';
import {
  ActivitiesActivityBillingTypeChoices,
  ActivityNode,
  AssignmentNodeActivity,
} from 'generated/graphql';
import _ from 'lodash';
import {
  ActivitySectionEnum,
  SharedActivityNameEnum,
  VctActivityOrder,
} from 'poly-constants';
import React, { PropsWithChildren } from 'react';

import { Activity, ActivityMonitoring } from '.';
import AbsenceRow, {
  AbsenceRowProps as AbsenceRowProperties,
} from './AbsenceRow';
import EmptyCells from './EmptyCells';
import { TimeMaterialType } from './InputCell';

interface MissionRowsProps {
  category: keyof typeof ActivitySectionEnum;
  listActivity: Array<Activity>;
  disabled?: boolean;
  display?: boolean;
}

interface MainMissionProps {
  activity: ActivityNode;
  disabled: boolean;
  display?: boolean;
}

const MainMissionRow = (props: MainMissionProps) => {
  const activity = props.activity;
  const display = props.display;
  const hasSubActivities =
    activity.subActivities && activity.subActivities.length;
  const currentAssignment = activity.currentAssignments?.length
    ? activity.currentAssignments[0]
    : undefined;
  return (
    <TableRow
      key={`nameRow-${activity.id}-${activity.name}`}
      sx={{
        display: display ? 'show' : 'none',
      }}
    >
      <MultiTableCell
        sx={{
          borderBottom: 'none',
          py: 1,
        }}
      >
        <MissionNameDisplay
          key={`mission-${activity.name}-name-display`}
          mission={activity.name}
          missionId={activity.id}
          currentAssignment={currentAssignment}
          type={activity.type}
          isTmContracts={
            activity.billingType === ActivitiesActivityBillingTypeChoices.Tm
          }
          haveBorder={false}
        />
      </MultiTableCell>
      {hasSubActivities ? (
        <EmptyCells />
      ) : (
        <DataCells
          key={`dataCells-${activity.id}-${activity.name}`}
          activity={activity}
          disabled={props.disabled}
          activityMonitoring={
            activity.activityMonitoring as Array<ActivityMonitoring>
          }
          timeAndMaterialType={TimeMaterialType.Real}
        />
      )}
    </TableRow>
  );
};

const SubMissionRow = (props: MainMissionProps) => {
  const activity = props.activity;
  const display = props.display;
  return (
    <TableRow
      key={`nameRow-${activity.id}${activity.name}`}
      sx={{
        display: display ? 'show' : 'none',
      }}
    >
      <MultiTableCell
        sx={{
          borderBottom: 'none',
          py: 1,

          width: '100%',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <SubMissionNameDisplay
          key={`sub-mission-${activity.name}-name-display`}
          name={activity.name}
        />
      </MultiTableCell>
      <DataCells
        key={`dataCells-${activity.id}${activity.name}`}
        activity={activity}
        disabled={props.disabled}
        activityMonitoring={
          activity.activityMonitoring as Array<ActivityMonitoring>
        }
        timeAndMaterialType={TimeMaterialType.Real}
      />
    </TableRow>
  );
};

const MissionRows = ({
  disabled = false,
  display = true,
  ...properties
}: PropsWithChildren<MissionRowsProps>) => {
  const activityRow: React.ReactNode[] = [];
  const absenceRow: Array<AbsenceRowProperties> = [];
  const subMissions: {
    [key: string]: Activity[];
  } = _.groupBy(properties.listActivity, (mission) => mission.mainActivity?.id);

  _.chain(properties.listActivity)
    .groupBy('businessClient.name')
    .forEach((activities, client) => {
      const hasClient: boolean = !!client && client !== 'undefined';
      if (hasClient) {
        activityRow.push(
          <TableRow
            key={`client-row-${client}`}
            sx={{
              display: display ? 'show' : 'none',
            }}
          >
            <MultiTableCell
              sx={{
                borderBottom: 'none',
                pt: 1,
              }}
            >
              <Typography color={'text.secondary'}>{client}</Typography>
            </MultiTableCell>
            <EmptyCells key={`${client}-EmptyCells`} id={client} />
          </TableRow>
        );
      }
      activities.forEach((activity: Activity) => {
        if (activity.billingType === ActivitiesActivityBillingTypeChoices.Tm) {
          if (!activity.mainActivity) {
            activityRow.push(
              <MainMissionRow
                key={`activity-${activity.id}`}
                activity={activity as ActivityNode}
                disabled={disabled}
                display={display}
              />
            );
          }
          if (activity.subActivities?.length) {
            _.forEach(subMissions[activity.id], (activity) => {
              activityRow.push(
                <SubMissionRow
                  key={`subactivity-${activity.id}`}
                  activity={activity as ActivityNode}
                  disabled={disabled}
                  display={display}
                />
              );
            });
          }
        } else if (
          ActivitySectionEnum[properties.category] === ActivitySectionEnum.VCT
        ) {
          const actMonitoring =
            activity.activityMonitoring as Array<ActivityMonitoring>;
          if (
            activity.name === SharedActivityNameEnum.RECUP &&
            actMonitoring.length === 0
          ) {
            return;
          }

          absenceRow.push({
            name: activity.name,
            activity: activity,
            disabled: disabled,
            description: '',
            order: _.get(VctActivityOrder, activity.name.toLowerCase(), 0),
            activityMonitoring: actMonitoring,
          });
        } else {
          const currentAssignment = activity.currentAssignments?.length
            ? (activity.currentAssignments[0] as AssignmentNodeActivity)
            : undefined;
          activityRow.push(
            <TableRow
              key={`nameRow-${activity.id}${activity.name}`}
              sx={{
                display: display ? 'show' : 'none',
              }}
            >
              <MultiTableCell
                sx={{
                  borderBottom: 'none',
                  py: 1,
                }}
              >
                <MissionNameDisplay
                  key={`mission-${activity.name}-name-display`}
                  mission={activity.name}
                  missionId={activity.id}
                  currentAssignment={currentAssignment}
                  type={activity.type}
                  isTmContracts={false}
                  haveBorder={false}
                />
              </MultiTableCell>
              <DataCells
                key={`dataCells-${activity.id}${activity.name}`}
                activity={activity}
                disabled={disabled}
                activityMonitoring={
                  activity.activityMonitoring as Array<ActivityMonitoring>
                }
                timeAndMaterialType={TimeMaterialType.Real}
              />
            </TableRow>
          );
        }
      });
    })
    .value();
  return (
    <>
      {activityRow}
      {_.orderBy(absenceRow, ['order'], ['asc']).map(
        (properties: AbsenceRowProperties) => (
          <AbsenceRow key={properties.name} display={display} {...properties} />
        )
      )}
    </>
  );
};

export default MissionRows;
