import ClearIcon from '@mui/icons-material/Clear';
import {
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import DatePicker from 'components/commons/DatePicker';
import DayInput from 'components/commons/DayInput';
import moment from 'moment';
import React, { useState } from 'react';

import { RequestDay } from './RequestContent';

interface RequestItemProps {
  requestDay: RequestDay;
  deleteSelf: () => void;
}

const RequestItem = (properties: RequestItemProps) => {
  const [valueDate, setValueDate] = useState<Date>(
    properties.requestDay.workedDate.toDate()
  );

  const onChangeDate = (date: Date | null) => {
    if (date) {
      setValueDate(date);
      properties.requestDay.workedDate = moment(date);
    }
  };

  const onChangeDay = (day: number) => {
    properties.requestDay.workedDuration = day;
  };

  return (
    <Card sx={{ m: 0.5, height: '200px' }} variant="outlined">
      <IconButton
        sx={{ float: 'right', color: 'primary.main' }}
        aria-label="delete"
        onClick={() => properties.deleteSelf()}
        size="large"
      >
        <ClearIcon />
      </IconButton>
      <CardContent>
        <Stack sx={{ flexDirection: 'column', mt: 2.5 }}>
          <Typography variant="label" sx={{ pb: 1 }}>
            {'Date d’activité exceptionnelle'}
          </Typography>
          <DatePicker
            minDate={properties.requestDay.periodStart.toDate()}
            maxDate={properties.requestDay.periodEnd.toDate()}
            value={valueDate}
            onChange={onChangeDate}
          />
        </Stack>
        <Stack sx={{ flexDirection: 'column', mt: 2.5 }}>
          <Typography variant="label" sx={{ pb: 1 }}>
            {'Durée Travaillée'}
          </Typography>
          <DayInput defaultValue={0.25} handleChange={onChangeDay} />
        </Stack>
      </CardContent>
    </Card>
  );
};

export default RequestItem;
