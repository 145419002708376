import PolyFooter from 'components/MUIOverload/PolyFooter';
import PongoButton from 'components/MUIOverload/PongoButton';
import React from 'react';

import styles from '../styles/ActiveBody.module.scss';

export const confirmationStepDisableTitle = 'Suivi d’Activité';
export const confirmationStepDisableBody =
  'Félicitations, vous avez déjà confirmé que les informations saisies sont valides.';

interface StepOneProps {
  onHandleBack: () => void;
  onHandleNext: () => void;
}

const ConfirmationStepDisable = (properties: StepOneProps) => {
  return (
    <div>
      <div className={styles.root}>
        <div className={styles.title}>{confirmationStepDisableTitle}</div>
        <div className={styles.body}>{confirmationStepDisableBody}</div>
      </div>
      <PolyFooter>
        <div className={styles.btnMargin}>
          <PongoButton
            buttonStyle="secondary"
            variant="contained"
            onClick={properties.onHandleBack}
          >
            Precedent
          </PongoButton>
          <PongoButton
            color="primary"
            variant="contained"
            onClick={properties.onHandleNext}
          >
            Suivant
          </PongoButton>
        </div>
      </PolyFooter>
    </div>
  );
};

export default ConfirmationStepDisable;
