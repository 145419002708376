import { Box, SwipeableDrawer } from '@mui/material';
import React from 'react';

interface ActivityWeekDrawerProps {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (value: React.SetStateAction<boolean>) => void;
  children: JSX.Element;
}

function Puller() {
  return (
    <Box
      sx={{
        borderTopLeftRadius: 30,
        borderTopRightRadius: 30,
        backgroundColor: 'primary.main',
        zIndex: 1,
        width: '100%',
        height: 46,
        minHeight: 46,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: 66,
          height: 4,
          backgroundColor: 'white',
          borderRadius: 20,
          position: 'absolute',
          top: 20,
        }}
      />
    </Box>
  );
}

const ActivityWeekDrawer = ({
  isDrawerOpen,
  setIsDrawerOpen,
  children,
}: ActivityWeekDrawerProps) => {
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isDrawerOpen}
      onOpen={() => setIsDrawerOpen(true)}
      onClose={() => setIsDrawerOpen(false)}
      sx={{
        zIndex: 1300,
      }}
      PaperProps={{
        sx: {
          borderTopLeftRadius: 30,
          borderTopRightRadius: 30,
        },
      }}
      disableDiscovery
      disableSwipeToOpen
    >
      <Puller />
      {children}
    </SwipeableDrawer>
  );
};

export default ActivityWeekDrawer;
