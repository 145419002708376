import {
  Box,
  FormControl,
  Grid,
  Step,
  StepButton,
  Stepper,
  Typography,
} from '@mui/material';
import PolyAlert from 'components/commons/PolyAlert';
import SectionTitle from 'components/commons/SectionTitle';
import GridSubTitle from 'components/MissionFollowUp/GridSubTitle';
import LabelTextFieldGrid from 'components/MissionFollowUp/LabelTextFieldGrid';
import { requiredForm } from 'pages/MissionFollowUp/formValidators';
import React from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';

interface HrActivityForm {
  control: UseFormMethods<{ name: string }>['control'];
  errors: UseFormMethods<{ name: string }>['errors'];
}

export default function HrActivityForm({ control, errors }: HrActivityForm) {
  return (
    <FormControl fullWidth>
      <PolyAlert severity="info" variant="outlined">
        Tous les collaborateurs pourront déclarer du temps sur cette mission.
      </PolyAlert>
      <Box sx={{ width: '100%', mt: 4, minWidth: 10 }}>
        <Stepper activeStep={0} alternativeLabel>
          <Step key="Informations" sx={{ display: 'inherit' }}>
            <StepButton color="inherit">
              <Typography
                textTransform="uppercase"
                variant="bodyS"
                color="primary"
              >
                Informations
              </Typography>
            </StepButton>
          </Step>
        </Stepper>
      </Box>
      <Box sx={{ display: 'inherit' }}>
        <Grid container spacing={0} sx={{ paddingBottom: 2 }}>
          <GridSubTitle customspacingtop={20}>
            <SectionTitle variant="h3Bold">
              Information de la mission
            </SectionTitle>
          </GridSubTitle>
          <Grid container item columns={{ xs: 8 }}>
            <Controller
              control={control}
              name="name"
              rules={{ required: requiredForm }}
              defaultValue=""
              render={({ value, onChange }) => (
                <LabelTextFieldGrid
                  title="Nom de la mission"
                  value={value}
                  onChange={onChange}
                  errorform={errors.name}
                  sizegrid={8}
                  required
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </FormControl>
  );
}
