import { Box, Divider, Tab, Tabs } from '@mui/material';
import PolySwitch from 'components/Navigation/PolySwitch';
import UpdateExceptionalActivitiesAuthorization from 'pages/ExceptionalActivities/ExceptionalActivitiesAuthorizations/UpdateExceptionalActivitiesAuthorization';
import ExceptionalActivitiesNewRequest from 'pages/ExceptionalActivities/ExceptionalActivitiesNewRequest';
import React from 'react';
import {
  NavLink,
  Redirect,
  Route,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { designSystem } from 'theme';
import urljoin from 'url-join';

export interface SubTabItem {
  name: string;
  to: string;
  icon: JSX.Element;
  componentRendered: JSX.Element;
}

interface NavigationTabsProps {
  items: SubTabItem[];
  variant?: 'scrollable' | 'standard' | 'fullWidth' | undefined;
  titleSize?: 'small' | 'large';
}

const useActiveTab = (): string => {
  const { pathname } = useLocation();
  const parsedPathname = pathname.split('/').filter(Boolean);
  return parsedPathname.pop() || '';
};

const getIndexTab = (tab: string, items: SubTabItem[]) => {
  const index = items.findIndex((element) => element.to === tab);
  return index === -1 ? false : index;
};

const SubTabsNavigation = ({
  items,
  variant,
  titleSize,
}: NavigationTabsProps) => {
  const { path } = useRouteMatch();
  const { search } = useLocation();

  const activeTab = useActiveTab();
  const fontSize =
    titleSize === 'large' ? designSystem.typography.body.fontSize : '11px';

  return (
    <div>
      <Tabs
        variant={variant}
        value={getIndexTab(activeTab, items)}
        sx={{ color: 'primary' }}
      >
        {items.map((item) => (
          <Tab
            color="primary"
            key={item.to}
            label={
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  height: '20px',
                }}
              >
                <Box sx={{ transform: 'scale(0.9)' }}>{item.icon}</Box>
                <Box
                  sx={{
                    ml: 1,
                    fontSize,
                    letterSpacing: '0em',
                    fontWeight: activeTab === item.to ? 600 : 400,
                  }}
                >
                  {item.name}
                </Box>
              </Box>
            }
            component={NavLink}
            to={{ pathname: item.to, search }}
          />
        ))}
      </Tabs>
      <Divider />
      <PolySwitch>
        <Redirect exact from={path} to={urljoin(path, items[0].to)} />
        {items.map((item) => (
          <Route exact key={item.to} path={urljoin(path, item.to)}>
            {item.componentRendered}
          </Route>
        ))}
        <Route
          path={urljoin(path, 'update_overtime_authorization')}
          component={UpdateExceptionalActivitiesAuthorization}
        />
        <Route
          path={urljoin(path, 'new_overtime_request')}
          component={ExceptionalActivitiesNewRequest}
        />
      </PolySwitch>
    </div>
  );
};

export default SubTabsNavigation;
