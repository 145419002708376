import 'react-multi-carousel/lib/styles.css';

import { FilterList } from '@mui/icons-material';
import LinkIcon from '@mui/icons-material/Link';
import { Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import PolyTitle from 'components/commons/PolyTitle';
import PongoButton from 'components/MUIOverload/PongoButton';
import _ from 'lodash';
import { eventCalendarUrl } from 'poly-constants';
import React from 'react';
import Carousel from 'react-multi-carousel';

import { useCarouselContext } from './CarouselContextProvider';
import CarouselFilterPopup from './CarouselFilterPopup';
import { ButtonGroup } from './CustomArrows';
import EventCard from './EventCard';
import styles from './styles/HomeCarousel.module.scss';
import { getFormattedDate, getFormattedTime } from './utils';

const responsive = {
  extraLargeDesktop: {
    breakpoint: { max: 4000, min: 2000 },
    items: 4,
    partialVisibilityGutter: 50,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: { max: 1024, min: 700 },
    items: 2,
    partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: { max: 700, min: 0 },
    items: 1,
    partialVisibilityGutter: 40,
  },
};

const EventCarousel = () => {
  const { filteredEvents } = useCarouselContext();
  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(
    null
  );

  const handlePopper = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(anchorElement ? null : event.currentTarget);
  };

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <PolyTitle>Évènements à venir</PolyTitle>
        <PongoButton
          startIcon={<FilterList />}
          variant={'contained'}
          buttonStyle="secondary"
          onClick={handlePopper}
          sx={{ marginLeft: 3, marginTop: 0.5, zIndex: 1500 }}
        >
          Filtres
        </PongoButton>
        <Link href={eventCalendarUrl} target="_blank" underline="hover">
          <PongoButton
            startIcon={<LinkIcon />}
            variant={'contained'}
            buttonStyle="secondary"
            sx={{ marginLeft: 3, marginTop: 0.5, zIndex: 1500 }}
          >
            Calendrier
          </PongoButton>
        </Link>
      </Box>
      {anchorElement && (
        <CarouselFilterPopup
          anchorElement={anchorElement}
          setAnchorElement={setAnchorElement}
        />
      )}
      {filteredEvents.length !== 0 ? (
        <Carousel
          draggable={false}
          responsive={responsive}
          autoPlay={false}
          shouldResetAutoplay={false}
          removeArrowOnDeviceType={['tablet', 'mobile']}
          partialVisible
          renderButtonGroupOutside={true}
          containerClass={styles.carouselContainer}
          itemClass={styles.eventCard}
          customButtonGroup={<ButtonGroup />}
          arrows={false}
        >
          {_.map(filteredEvents, (polyEvent, index) => {
            return (
              <EventCard
                key={index}
                tag={polyEvent.tag}
                title={polyEvent.title}
                startDate={getFormattedDate(polyEvent.startDatetime)}
                startTime={getFormattedTime(polyEvent.startDatetime)}
                endDate={getFormattedDate(polyEvent.endDatetime)}
                endTime={getFormattedTime(polyEvent.endDatetime)}
                description={polyEvent.description}
                isNew={polyEvent.isNew}
              />
            );
          })}
        </Carousel>
      ) : (
        <Typography variant="h6" sx={{ color: 'text.secondary' }} py={2} pl={7}>
          Pas d&apos;évenèments à venir
        </Typography>
      )}
    </div>
  );
};

export default EventCarousel;
