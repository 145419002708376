import { Search } from '@mui/icons-material';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import { SxProps } from '@mui/system';
import { useFilterContext } from 'components/commons/FilterContext/FilterContextProvider';
import { useUserInfo } from 'components/User/UserProvider';
import { EmployeeNode } from 'generated/graphql';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'store';
import { displayEmployee } from 'utils';

import styles from './styles/Filters.module.scss';

interface FilterDirectorInterface {
  sx?: SxProps;
  directors: EmployeeNode[];
}

export default function FilterDirector({
  sx,
  directors,
}: FilterDirectorInterface) {
  const { setFilteredID } = useFilterContext();
  const label = 'Directeur de mission';
  const allDirectors = _.sortBy(_.uniqBy(directors, 'id'), [
    'lastName',
    'firstName',
  ]);
  const { employee } = useUserInfo();
  const selectedMissions = useSelector(
    (state) => state.activity.selectedMissions
  );
  const [filterValue, setFilterValue] = useState(
    employee?.isMissionDirector ? employee : null
  );

  useEffect(() => {
    if (selectedMissions.length !== 0) {
      setFilterValue(null);
    } else {
      setFilterValue(employee?.isMissionDirector ? employee : null);
    }
  }, [selectedMissions, employee]);

  useEffect(() => {
    setFilteredID(filterValue ? filterValue.id : undefined);
  }, [setFilteredID, filterValue]);

  return (
    <Autocomplete
      autoHighlight
      autoComplete
      id="filter-select-creator"
      options={allDirectors}
      className={styles.filterSelect}
      sx={{ width: 250, ...sx }}
      onChange={(event, value) => {
        setFilterValue(value);
      }}
      value={filterValue}
      getOptionLabel={(director) => {
        if (director) {
          return displayEmployee(director.firstName, director.lastName);
        }
        return '';
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(parameters) => (
        <TextField
          variant="standard"
          label={label}
          {...parameters}
          InputProps={{
            ...parameters.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
