import PolyExportButton, {
  createCsvFile,
} from 'components/commons/PolyExportButton';
import { useFetchExportBillingClientsLazyQuery } from 'generated/graphql';
import { useSnackbar } from 'notistack';
import React from 'react';

const BillingClientsExportButton = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [exportQuery, { data }] = useFetchExportBillingClientsLazyQuery({
    onCompleted: () => {
      if (data && data.exportBillingClients) {
        const filename = `clients_export.csv`;
        createCsvFile(data.exportBillingClients, filename);
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const exportBillingClients = () => {
    exportQuery();
  };

  return (
    <PolyExportButton onClick={exportBillingClients} sx={{ mr: 1 }}>
      Exporter
    </PolyExportButton>
  );
};

export default BillingClientsExportButton;
