import PolyDataGrid from 'components/MUIOverload/PolyDataGrid';
import DateCell from 'components/MUIOverload/PolyDataGrid/components/DateCell';
import NumberCell from 'components/MUIOverload/PolyDataGrid/components/NumberCell';
import StringCell from 'components/MUIOverload/PolyDataGrid/components/StringCell';
import { PolyDataGridColumn } from 'components/MUIOverload/PolyDataGrid/PolyDataGridHead';
import { OvertimeRequestsQuery } from 'generated/graphql';
import React from 'react';

import RecoveryMomentFormModal from './RecoveryMomentFormModal';
import { filterOvertimeRequest } from './utils';

const getColumn = (
  handleSummit: (
    id: string,
    date: Date,
    duration: number,
    dayMoment: string
  ) => Promise<void>
) => {
  const columns: PolyDataGridColumn<
    DeepExtractTypeSkipArrays<OvertimeRequestsQuery, ['overtimeRequests']>
  >[] = [
    {
      headerName: 'Date de la demande',
      isSortable: false,
      width: '150px',
      render: (data) => DateCell({ date: data.createdAt }),
    },
    {
      headerName: 'Date limite récupération',
      isSortable: false,
      width: '160px',
      render: (data) =>
        data.expireAt
          ? DateCell({
              date: data.expireAt,
            })
          : StringCell({ data: '-' }),
    },
    {
      headerName: 'Mission',
      isSortable: false,
      width: '300px',
      render: (data) => StringCell({ data: data.authorization.activity.name }),
    },
    {
      headerName: 'Temps de récupération',
      isSortable: false,
      render: (data) => NumberCell({ data: data.recoveryRemaining }),
    },
    {
      headerName: '',
      isSortable: false,
      render: (data) =>
        RecoveryMomentFormModal({
          overtimeAuthorizationId: data.id,
          expireAt: data.expireAt,
          handleSummit: handleSummit,
          maxRecoveryDuration: data.recoveryRemaining,
        }),
    },
  ];

  return columns;
};

interface ActivityTableProps {
  overtimeRequestsData: OvertimeRequestsQuery | undefined;
  handleSummit: (
    id: string,
    date: Date,
    duration: number,
    dayMoment: string
  ) => Promise<void>;
}

const ActivityTable = ({
  overtimeRequestsData,
  handleSummit,
}: ActivityTableProps) => {
  const getRows = (_orderBy?: string | string[], _order?: 'asc' | 'desc') => {
    const result = filterOvertimeRequest(
      overtimeRequestsData?.overtimeRequests
    );

    if (result === undefined) {
      return [];
    }

    return result;
  };

  return (
    <>
      <PolyDataGrid columns={getColumn(handleSummit)} getRows={getRows} />
    </>
  );
};

export default ActivityTable;
