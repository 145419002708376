import { TableCell, TableRow, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import { useEstimatedContext } from 'components/Revenue/Estimated/context/EstimatedContextProvider';
import { estimatedRevenueBackgroundColorSx } from 'components/Revenue/Estimated/utils';
import { BillingPurchaseOrderCurrencyChoices } from 'generated/graphql';
import _ from 'lodash';
import { totalFormat } from 'pages/ActivityPage/utils';
import React, { useMemo } from 'react';
import { useSelector } from 'store';

const borderProps: SxProps = {
  borderColor: 'black.main',
  borderStyle: 'solid',
};

const TotalRow = () => {
  const isMissionFiltered = useSelector(
    (state) => !!state.activity.selectedMissions.length
  );
  const { clients, revenueProspectives } = useEstimatedContext();
  const { displayedMonths } = useTableHeaderContext();
  const { setTotalObjective } = useEstimatedContext();
  const objective = useMemo(() => {
    const totalObjective = isMissionFiltered
      ? _.sumBy(clients, 'objective')
      : _.sumBy(clients, 'objective') +
        _.sumBy(revenueProspectives, (dpr) => dpr.objective);
    setTotalObjective(totalObjective);
    return totalObjective;
  }, [clients, revenueProspectives, setTotalObjective, isMissionFiltered]);

  return (
    <TableRow
      sx={{
        position: 'sticky',
        backgroundColor: 'background.paper',
        bottom: 0,
      }}
    >
      <TableCell sx={{ ...borderProps, borderWidth: `1px 0 1px 1px` }}>
        <Typography fontWeight={'bold'} fontSize={15}>
          Total CA
        </Typography>
      </TableCell>
      {_.map(displayedMonths, (month) => {
        return (
          <TableCell
            key={`total-${month.format('MMM')}`}
            sx={{
              textAlign: 'center',
              ...borderProps,
              borderWidth: `1px 0 1px 0`,
              p: 0,
              fontSize: '0.875rem',
              ...estimatedRevenueBackgroundColorSx,
            }}
          >
            {
              <Typography
                fontSize={15}
                id={`actualRevenueTotal${month.month()}`}
              >
                {totalFormat(
                  _.sumBy(
                    clients,
                    (client) =>
                      _.find(client.estimatedTotals, (total) =>
                        total.month.isSame(month, 'month')
                      )?.amount || 0
                  ),
                  BillingPurchaseOrderCurrencyChoices.Eur
                )}
              </Typography>
            }
          </TableCell>
        );
      })}
      <TableCell
        align={'center'}
        sx={{
          textAlign: 'center',
          ...borderProps,
          borderWidth: `1px 1px 1px 0`,
          p: 0,
          whiteSpace: 'nowrap',
          fontSize: '0.875rem',
        }}
      >
        <Typography fontWeight={'bold'} fontSize={15}>
          {totalFormat(objective, BillingPurchaseOrderCurrencyChoices.Eur)}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default TotalRow;
