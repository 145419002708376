import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useRequestLazyQuery } from 'hooks/useRequestLazyQuery';
import { useCallback } from 'react';
import { S3_URL } from 'services/s3';

export const TYPES_FILE_DISPLAYABLE = [
  'png',
  'bmp',
  'pdf',
  'jpg',
  'jpeg',
  'gif',
];

function getExtensionTypeOption(url: string | undefined) {
  const extension = getFileExtension(url);

  switch (extension?.toLowerCase()) {
    case 'png':
      return { type: 'image/png' };
    case 'bmp':
      return { type: 'image/bmp' };
    case 'pdf':
      return { type: 'application/pdf' };
    case 'jpg':
      return { type: 'image/jpeg' };
    case 'jpeg':
      return { type: 'image/jpeg' };
    case 'gif':
      return { type: 'image/gif' };
    default:
      return { type: '' };
  }
}

export function getFileExtension(url: string | undefined) {
  if (url) {
    return url.split('.').pop();
  }
  return undefined;
}

const onSuccess = (response: AxiosResponse) => {
  const option = getExtensionTypeOption(response.config.url);
  const blob = new Blob([response.data as string], option);
  const blobURL =
    window.URL && window.URL.createObjectURL
      ? window.URL.createObjectURL(blob)
      : window.webkitURL.createObjectURL(blob);
  return blobURL;
};

const config: AxiosRequestConfig = {
  baseURL: S3_URL,
  responseType: 'blob',
};

export function useFileRequest(
  url?: string
): () => Promise<{ response: string | undefined; error: Error | undefined }> {
  const fetch = useRequestLazyQuery(config, onSuccess);

  const fetchFile = useCallback(async () => {
    const { response, error } = await fetch(url);
    return { response, error };
  }, [fetch, url]);

  return fetchFile;
}
