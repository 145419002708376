import { FlatTable } from 'components/commons/Tables/FlatTable';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import RevenueTableBodyByPo from 'components/Revenue/BilledRevenueTables/BilledRevenueMissionTable/RevenueTableBodyByPo';
import { ActivityNode, PurchaseOrderNode } from 'generated/graphql';
import { Moment } from 'moment';
import React from 'react';

import TableContextProvider from './context/TableContextProvider';
import RevenueTableHeader from './RevenueTableHeader';

interface RevenueGlobalTableProps {
  purchaseOrders: PurchaseOrderNode[];
  activity: ActivityNode;
  currentYear: Moment;
  setCurrentYear: (p: Moment) => void;
}

const BilledRevenueMissionTable = ({
  purchaseOrders,
  activity,
}: RevenueGlobalTableProps) => {
  const { currentYear, setCurrentYear } = useTableHeaderContext();

  return (
    <TableContextProvider
      purchaseOrders={purchaseOrders}
      activity={activity}
      currentYear={currentYear}
      setCurrentYear={setCurrentYear}
    >
      <FlatTable size={'small'}>
        <RevenueTableHeader />
        <RevenueTableBodyByPo />
      </FlatTable>
    </TableContextProvider>
  );
};

export default BilledRevenueMissionTable;
