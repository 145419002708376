import 'dayjs/locale/fr';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { DateRange, DateRangePickerProps } from '@mui/lab';
import { PaperProps, TextField } from '@mui/material';
import {
  DateRangePicker as MuiDateRangePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePickerSlotsComponentsProps } from '@mui/x-date-pickers-pro/DateRangePicker/DateRangePicker';
import clsx from 'clsx';
import { isSameDay, isValid } from 'date-fns';
import React, { useEffect, useState } from 'react';

import styles from './styles/DateRangePicker.module.scss';

interface Props extends DateRangePickerProps {
  dateRange: [Date, Date];
  maxDate?: Date;
  minDate?: Date;
  handleDateRangeChange?: (d: [Date, Date]) => void;
  handleDateRangeError?: () => void;
  handleDateRangeClearError?: () => void;
  disableFuture?: boolean;
  allowSameDayRange?: boolean;
  disabled?: boolean;
  externallyControlled?: boolean;
  className?: string;
  textDisabled?: boolean;
  inputFormat?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  components?: any;
  componentsProps?: Partial<DateRangePickerSlotsComponentsProps>;
  PaperProps?: Partial<PaperProps>;
}

export const DateRangePicker = ({
  dateRange,
  maxDate,
  minDate,
  handleDateRangeChange,
  handleDateRangeError,
  handleDateRangeClearError,
  disableFuture,
  allowSameDayRange,
  disabled,
  externallyControlled,
  className,
  textDisabled,
  inputFormat,
  components,
  componentsProps,
  PaperProps,
  ...props
}: Props) => {
  const today = new Date();

  const [rangeError, setRangeError] = useState<[boolean, boolean]>([
    false,
    false,
  ]);

  const [internalDateRange, setInternalDateRange] =
    useState<[Date, Date]>(dateRange);

  const handleDateRangeChangeLocal = (dateRange: DateRange<Date>) => {
    const startDateString = dateRange[0]?.toString();
    const endDateString = dateRange[1]?.toString();
    const startDate = new Date(startDateString ? startDateString : '');
    const endDate = new Date(endDateString ? endDateString : '');

    if (
      isValid(startDate) &&
      isValid(endDate) &&
      startDate <= endDate &&
      // Avoid rendering graph when disablefuture is set
      !(disableFuture && endDate > today)
    ) {
      setInternalDateRange(dateRange as [Date, Date]);
      if (handleDateRangeChange) {
        const externalDateRange = [startDate, endDate] as [Date, Date];
        handleDateRangeChange(externalDateRange);
      }
      setRangeError([false, false]);
    }
    if (isValid(startDate) && isValid(endDate)) {
      if (startDate > endDate) {
        setRangeError([true, true]);
      }
      if (minDate && startDate < minDate) {
        setRangeError((state) => [true, state[1]]);
      }
      if (maxDate && endDate > maxDate) {
        setRangeError((state) => [state[0], true]);
      }
    }
  };

  useEffect(() => {
    if (handleDateRangeError && (rangeError[0] || rangeError[1])) {
      handleDateRangeError();
    } else if (handleDateRangeClearError) {
      handleDateRangeClearError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangeError[0], rangeError[1]]);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale="fr"
      localeText={{ start: 'Date de début', end: 'Date de fin' }}
    >
      <MuiDateRangePicker
        {...props}
        value={externallyControlled ? dateRange : internalDateRange}
        inputFormat={inputFormat ?? 'DD/MM/YYYY'}
        onChange={handleDateRangeChangeLocal}
        onAccept={handleDateRangeChangeLocal}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        disableFuture={disableFuture}
        onError={([startReason]) => {
          const startDate = new Date(internalDateRange[0]);
          const endDate = new Date(internalDateRange[1]);
          if (
            startReason === 'invalidRange' &&
            isSameDay(startDate, endDate) &&
            allowSameDayRange
          ) {
            setRangeError([false, false]);
            return;
          } else if (startDate <= endDate) {
            setRangeError([false, false]);
            return;
          }
          setRangeError([true, true]);
        }}
        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <div className={clsx(styles.container, className)}>
              <TextField
                {...startProps}
                disabled={textDisabled}
                error={rangeError[0]}
                inputProps={{
                  ...startProps.inputProps,
                }}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
                className={clsx(styles.onFocus, {})}
                variant="outlined"
                size="small"
              />
              <ArrowForwardIcon sx={{ mx: 1 }} />
              <TextField
                {...endProps}
                disabled={textDisabled}
                error={rangeError[1]}
                inputProps={{
                  ...endProps.inputProps,
                }}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
                className={clsx(styles.onFocus, {})}
                variant="outlined"
                size="small"
              />
            </div>
          </React.Fragment>
        )}
        components={components}
        componentsProps={componentsProps}
        PaperProps={PaperProps}
      />
    </LocalizationProvider>
  );
};
