import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { IconButton, TableCell } from '@mui/material';
import { Stack } from '@mui/material';
import clsx from 'clsx';
import {
  buildColumnLabels,
  buildColumnLabelsV2,
} from 'components/commons/Tables/utils';
import { useTableContext } from 'contexts/ActivityMonitoringTable/TableContextProvider';
import { useTableHeaderContext } from 'contexts/ActivityMonitoringTable/TableHeaderContextProvider';
import _ from 'lodash';
import moment from 'moment';
import MonthWithNumberOfBusinessDaysDisplayer from 'pages/ActivityPage/PrincipalTablePage/MonthWithNumberOfBusinessDaysDisplayer';
import React, { useEffect } from 'react';

import styles from '../styles/TableHeader.module.scss';

export default function CurrentMonthDisplayer() {
  const {
    displayedMonth,
    setDisplayedMonth,
    displayedWeeks,
    setDisplayedWeeks,
    setPage,
  } = useTableContext();

  const { setColumnLabels } = useTableHeaderContext();

  const viewMonth = displayedMonth.format('MMMM yyyy');

  const incrementPeriod = () => {
    const new_period = displayedMonth.clone().add(1, 'months');
    setDisplayedMonth(new_period);
    setPage(0);
  };

  const decrementPeriod = () => {
    const new_period = displayedMonth.clone().subtract(1, 'months');
    setDisplayedMonth(new_period);
    setPage(0);
  };

  const updatesForTableHeader = (displayedMonth: moment.Moment): void => {
    const viewMonth = displayedMonth.format('MMMM yyyy');
    const columnLabels = displayedMonth.isSameOrAfter(
      moment('2023-01-01'),
      'year'
    )
      ? buildColumnLabelsV2(viewMonth, true)
      : buildColumnLabels(viewMonth);
    setColumnLabels(columnLabels);
    const newWeeks = _.map(columnLabels, (week) => moment(week.start.clone()));
    setDisplayedWeeks(newWeeks);
  };

  useEffect(() => {
    updatesForTableHeader(displayedMonth);
    // eslint-disable-next-line
  }, [displayedMonth]);

  return (
    <TableCell
      colSpan={displayedWeeks.length !== 0 ? displayedWeeks.length + 1 : 5}
      className={clsx(styles.noUnderline, styles.currentMonthDisplayer)}
    >
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Stack sx={{ paddingLeft: 4 }}>
          <IconButton
            aria-label={'Précédent'}
            onClick={() => {
              decrementPeriod();
            }}
            sx={{ p: 1 }}
            size="large"
          >
            <NavigateBeforeIcon />
          </IconButton>
        </Stack>
        <MonthWithNumberOfBusinessDaysDisplayer
          viewMonth={viewMonth}
          month={displayedMonth}
        />
        <Stack sx={{ paddingRight: 4 }}>
          <IconButton
            aria-label={'Suivant'}
            onClick={() => {
              incrementPeriod();
            }}
            sx={{ p: 1 }}
            size="large"
          >
            <NavigateNextIcon />
          </IconButton>
        </Stack>
      </Stack>
    </TableCell>
  );
}
