import AccountBoxIcon from '@mui/icons-material/AccountBox';
import BarChartIcon from '@mui/icons-material/BarChart';
import BrushIcon from '@mui/icons-material/Brush';
import BuildIcon from '@mui/icons-material/Build';
import CloudIcon from '@mui/icons-material/Cloud';
import EngineeringIcon from '@mui/icons-material/Engineering';
import GroupsIcon from '@mui/icons-material/Groups';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import WorkIcon from '@mui/icons-material/Work';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { getRolesName } from 'components/Phonebook/utils';
import React from 'react';

import styles from './styles/EmployeeOccupation.module.scss';

const checkMixte = (occupation: string) => {
  return occupation.includes(' / ');
};

export function getOccupationNameAndIcon(
  occupation: string,
  short: boolean | undefined
) {
  let icon;
  let occupationName = undefined;
  let isOther = false;
  if (occupation.includes('Mixte')) {
    icon = <ShuffleIcon />;
  } else if (
    occupation.includes('Product Manager') ||
    occupation.includes('PM')
  ) {
    icon = <EngineeringIcon />;
    occupationName = short ? 'PM' : 'Product Manager';
  } else if (
    occupation.includes('Digital Consultant') ||
    occupation.includes('DC')
  ) {
    icon = <WorkIcon />;
    occupationName = short ? 'DC' : 'Digital Consultant';
  } else if (occupation.includes('Validation')) {
    icon = <HowToRegIcon />;
  } else if (occupation.includes('Data')) {
    icon = <BarChartIcon />;
  } else if (occupation.includes('DevOps')) {
    icon = <CloudIcon />;
    occupationName = short ? 'DevOps' : 'Infra DevOps';
  } else if (occupation.includes('Dév') || occupation.includes('Dev')) {
    icon = <BuildIcon />;
    occupationName = short ? 'Dev' : 'Développeur';
  } else if (occupation.includes('Finance')) {
    icon = <MonetizationOnIcon />;
  } else if (
    occupation.includes('UI-UX Designer') ||
    occupation.includes('ui')
  ) {
    icon = <BrushIcon />;
    occupationName = 'UI-UX Designer';
  } else if (occupation.includes('RH')) {
    icon = <GroupsIcon />;
  } else if (occupation.includes('Recrutement')) {
    icon = <PersonSearchIcon />;
  } else {
    icon = <AccountBoxIcon />;
    isOther = true;
  }
  if (occupationName === undefined) {
    occupationName = occupation;
  }

  return { icon: icon, occupationName: occupationName, isOther: isOther };
}

export function getEmployeeOccupationInfo(
  occupation: string,
  short: boolean | undefined
) {
  let info = null;
  let isOther = false;
  if (checkMixte(occupation)) {
    const roles = getRolesName(occupation);
    const icon = <ShuffleIcon />;
    const occupations = [] as string[];
    roles.forEach((role) => {
      const informations = getOccupationNameAndIcon(role, short);
      isOther = informations.isOther || isOther;
      occupations.push(informations.occupationName);
    });
    const occupationsName = short ? 'Mixte' : occupations.join(' / ');
    info = { icon: icon, occupationName: occupationsName };
  } else {
    info = getOccupationNameAndIcon(occupation, false);
    isOther = info.isOther;
  }
  return {
    icon: info?.icon,
    occupationName: info?.occupationName,
    isOther: isOther,
  };
}

interface OccupationChipProps {
  occupation: string;
  className?: string;
  short?: boolean;
}

export default function EmployeeOccupationIndicator({
  occupation,
  className,
  short,
}: OccupationChipProps) {
  const { icon, occupationName } = getEmployeeOccupationInfo(occupation, short);

  return (
    <Box alignContent={'center'} className={clsx(styles.container, className)}>
      {icon}
      <Typography sx={{ marginLeft: 0.5, fontSize: '14px' }}>
        {occupationName}
      </Typography>
    </Box>
  );
}
