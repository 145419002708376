import { Edit } from '@mui/icons-material';
import { Button } from '@mui/material';
import PongoButton from 'components/MUIOverload/PongoButton';
import { useTableContext } from 'contexts/ValidateTmActivityMonitoring/TableContextProvider';
import React from 'react';

function EditButton() {
  const { mode, setMode } = useTableContext();

  return (
    <Button
      onClick={() => setMode('edit')}
      variant={mode === 'edit' ? 'contained' : 'outlined'}
      disabled={mode && mode !== 'edit'}
      color={'success'}
      startIcon={<Edit />}
    >
      {'éditer des temps'}
    </Button>
  );
}

function QuitEditButton() {
  const { setMode } = useTableContext();

  return (
    <PongoButton
      onClick={() => setMode(undefined)}
      variant={'contained'}
      buttonStyle={'secondary'}
      sx={{
        textTransform: 'uppercase',
      }}
    >
      {'Quitter le mode édition'}
    </PongoButton>
  );
}

export default EditButton;
export { QuitEditButton };
