import { Box, Grid, Stack, TextField } from '@mui/material';
import DatePicker from 'components/commons/DatePicker';
import BillStatusPaymentChip from 'components/MissionFollowUp/BillChips/BillStatusPaymentChip';
import { displayRangeMonthPeriod } from 'components/MissionFollowUp/BillDetail/BillDetail/utils';
import PolyDataGrid from 'components/MUIOverload/PolyDataGrid';
import DateCell from 'components/MUIOverload/PolyDataGrid/components/DateCell';
import StringCell from 'components/MUIOverload/PolyDataGrid/components/StringCell';
import { PolyDataGridColumn } from 'components/MUIOverload/PolyDataGrid/PolyDataGridHead';
import PolyModal from 'components/MUIOverload/PolyDialog/PolyModal';
import PongoButton from 'components/MUIOverload/PongoButton';
import {
  BillingBillPaymentStatusChoices,
  BillNode,
  useUpdatePaymentStatusMutation,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { totalFormat } from 'pages/ActivityPage/utils';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { setCurrentBill } from 'store/purchaseOrder';
import { graphQlDateFormatter } from 'utils';

interface StateSelectionModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  groupAction?: boolean;
}

export default function StateSelectionModal({
  open,
  onClose,
  onSuccess,
  groupAction,
}: StateSelectionModalProps) {
  const currentBill = useSelector((state) => state.purchaseOrder.currentBill);
  const selectedBills = useSelector(
    (state) => state.purchaseOrder.selectedBills
  );
  const multipleSelectedBills = selectedBills.length > 1;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [statusSelected, setStatusSelected] =
    useState<BillingBillPaymentStatusChoices>(currentBill.paymentStatus);
  const [date, setDate] = useState<Date>(
    currentBill.paymentDate ? currentBill.paymentDate : new Date()
  );
  const [comment, setComment] = useState<string>('');

  useEffect(() => {
    if (currentBill.paymentDate) {
      setDate(new Date(currentBill.paymentDate));
    }
    if (currentBill.paymentStatus) {
      setStatusSelected(currentBill.paymentStatus);
    }
  }, [currentBill]);

  const columns: PolyDataGridColumn<DeepExtractTypeSkipArrays<BillNode, []>>[] =
    [
      {
        headerName: 'Facture N°',
        propertyName: 'billNumber',
        isSortable: true,
        width: '70px',
        render: (data) =>
          StringCell({
            data: data.billNumber || '',
          }),
      },
      {
        headerName: 'Libellé facture',
        propertyName: 'title',
        isSortable: true,
        width: '130px',
        render: (data) =>
          StringCell({
            data: data.title,
          }),
      },
      {
        headerName: 'Mois facturé(s)',
        propertyName: 'billingMonth',
        isSortable: true,
        width: '130px',
        render: (data) =>
          StringCell({
            data: displayRangeMonthPeriod(
              data.periodBeginning,
              data.periodEnding
            ),
          }),
      },
      {
        headerName: 'Facturée le',
        propertyName: 'billingDate',
        isSortable: true,
        width: '80px',
        render: (data) => DateCell({ date: data.billingDate }),
      },
      {
        headerName: "Date d'échéance",
        propertyName: 'dueDate',
        isSortable: true,
        width: '80px',
        render: (data) => DateCell({ date: data.dueDate }),
      },
      {
        headerName: 'Montant (HT)',
        propertyName: 'total',
        isSortable: true,
        width: '70px',
        render: (data) =>
          StringCell({
            data: totalFormat(data.total, data.purchaseOrder.currency),
          }),
      },
    ];

  function rowsActive(_orderBy?: string | string[], _order?: 'asc' | 'desc') {
    return groupAction ? selectedBills : [currentBill];
  }

  function redirectBill() {
    const win = window.open(
      `/mission-followup/missions/details/bill?po_id=${currentBill.purchaseOrder.id}&mission_id=${currentBill.purchaseOrder.billingActivityInformation.activity?.id}&bill_id=${currentBill.id}`,
      '_blank'
    );
    if (win) {
      win.focus();
    }
  }

  function closeModal() {
    if (currentBill.paymentStatus !== statusSelected) {
      setStatusSelected(currentBill.paymentStatus);
    }
    onClose();
  }

  const [updatePaymentStatus] = useUpdatePaymentStatusMutation({
    onError: () => {
      enqueueSnackbar(`Erreur de l'enregistrement`, {
        variant: 'error',
      });
    },
    onCompleted: (data) => {
      if (groupAction) {
        enqueueSnackbar(`Le statut des factures ont été mis à jour`, {
          variant: 'success',
        });
      } else {
        const bill = data.updatePaymentStatus?.bills?.[0] as BillNode;
        if (bill) {
          dispatch(setCurrentBill(bill));
        }
        enqueueSnackbar(
          `Le statut de la facture « ${currentBill.title} » a été mis à jour`,
          {
            variant: 'success',
          }
        );
      }
      onSuccess();
      onClose();
    },
  });

  async function submitPaymentStatus() {
    await updatePaymentStatus({
      variables: {
        billsId: groupAction
          ? _.map(selectedBills, (bill) => bill.id)
          : [currentBill.id],
        paymentStatus: statusSelected,
        paymentDate:
          statusSelected === BillingBillPaymentStatusChoices.Paid
            ? graphQlDateFormatter(date)
            : undefined,
        litigationComment: comment,
      },
    });
  }

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const isDateValid = moment(date).isValid();
  const minDate = moment(
    _.maxBy([currentBill, ...selectedBills], (bill) =>
      moment(bill.billingDate).toDate()
    )?.billingDate
  ).toDate();
  const error =
    statusSelected === BillingBillPaymentStatusChoices.Paid &&
    (!isDateValid || date < minDate);
  let errorMessage = '';
  if (error) {
    if (!isDateValid) {
      errorMessage = 'Une date de paiement valide est requise';
    } else {
      errorMessage =
        'La date de paiement doit être supérieur à la date de facturation';
    }
  }

  return (
    <PolyModal
      open={open}
      onClose={closeModal}
      polyDialogTitle={`Changer le statut ${
        multipleSelectedBills ? 'des factures' : 'de la facture'
      }`}
      handlePrimary={submitPaymentStatus}
      primaryButtonName={'Valider'}
      isPrimaryButtonDisabled={
        error ||
        (statusSelected !== BillingBillPaymentStatusChoices.Paid &&
          statusSelected !== BillingBillPaymentStatusChoices.Litigation)
      }
      disableEnforceFocus={true}
      maxWidth={'md'}
      fullWidth={true}
    >
      <Stack>
        <Box sx={{ my: 1 }}>
          <PolyDataGrid columns={columns} getRows={rowsActive} />
        </Box>
        {!groupAction && (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 1 }}>
            <PongoButton
              variant={'contained'}
              buttonStyle="secondary"
              onClick={redirectBill}
            >
              Voir la facture
            </PongoButton>
          </Box>
        )}
        <Box sx={{ mt: 1 }}>
          {multipleSelectedBills
            ? 'Souhaitez-vous marquer ces factures comme :'
            : 'Souhaitez-vous marquer cette facture comme :'}
        </Box>
        <Box
          sx={{ display: 'flex', justifyContent: 'center', mt: 2.5, mb: 2.5 }}
        >
          <BillStatusPaymentChip
            sx={{ mr: 2, ml: 2 }}
            status={BillingBillPaymentStatusChoices.Paid}
            onClick={() =>
              setStatusSelected(BillingBillPaymentStatusChoices.Paid)
            }
            isActive={statusSelected === BillingBillPaymentStatusChoices.Paid}
          />
          <BillStatusPaymentChip
            sx={{ mr: 2, ml: 2 }}
            status={BillingBillPaymentStatusChoices.Litigation}
            onClick={() =>
              setStatusSelected(BillingBillPaymentStatusChoices.Litigation)
            }
            isActive={
              statusSelected === BillingBillPaymentStatusChoices.Litigation
            }
          />
        </Box>
        {statusSelected === BillingBillPaymentStatusChoices.Litigation && (
          <>
            <Box>Ajoutez un commentaire pour la mise en litigation :</Box>
            <Box
              sx={{
                width: 1,
                display: 'flex',
                justifyContent: 'center',
                py: 2,
              }}
            >
              <TextField
                variant="standard"
                id="comment-textfield"
                multiline
                label={'Commentaire de litigation'}
                rows={4}
                value={comment}
                onChange={handleCommentChange}
                sx={{ width: 1 }}
              />
            </Box>
          </>
        )}
        <Grid container item xs={4}>
          {statusSelected === BillingBillPaymentStatusChoices.Paid && (
            <DatePicker
              label={'Date de paiement de la facture'}
              required
              value={date}
              error={error}
              errorMessage={errorMessage}
              minDate={minDate}
              disabled={false}
              onChange={(newDate) => newDate && setDate(newDate)}
            />
          )}
        </Grid>
      </Stack>
    </PolyModal>
  );
}
