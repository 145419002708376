import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import DeleteIconWithTooltip from 'components/MUIOverload/PolyDataGrid/components/DeleteIconWithTooltip';
import {
  EmployeeReportingParameterNode,
  ReportingEmployeeReportingParameterGradeChoices,
} from 'generated/graphql';
import _ from 'lodash';
import { ADRByRole } from 'pages/ReportingPage/OccupationAdr/OccupationAdrPage';
import React, { useState } from 'react';

import { DEFAULT_OCCUPATION } from '../../../Profitability/utils';
import OccupationAdrDeleteModal from '../OccupationAdrDeleteModal';
import OccupationAdrTableCellEditable from './OccupationAdrTableCellEditable';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

interface OccupationAdrTableRowProps {
  adrByRole: ADRByRole;
  isEdit: boolean;
  refetch: () => void;
}

export default function OccupationAdrTableRow({
  adrByRole,
  isEdit,
  refetch,
}: OccupationAdrTableRowProps) {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const occupation = adrByRole.occupation;
  const GradesOrderedWithAdr = [
    {
      adr: adrByRole.intern,
      grade: ReportingEmployeeReportingParameterGradeChoices.Intern,
    },
    {
      adr: adrByRole.collaborator,
      grade: ReportingEmployeeReportingParameterGradeChoices.Collaborator,
    },
    {
      adr: adrByRole.coman,
      grade: ReportingEmployeeReportingParameterGradeChoices.Coman,
    },
    {
      adr: adrByRole.comex,
      grade: ReportingEmployeeReportingParameterGradeChoices.Comex,
    },
  ];
  return (
    <StyledTableRow>
      <TableCell key={occupation}>
        <Typography>
          {occupation === DEFAULT_OCCUPATION
            ? 'TJM coûts par défaut'
            : occupation}
        </Typography>
      </TableCell>
      {_.map(GradesOrderedWithAdr, (gradeWithAdr) => {
        return (
          <OccupationAdrTableCellEditable
            isEdit={isEdit}
            erp={
              {
                occupation: occupation,
                grade: gradeWithAdr.grade,
                adr: gradeWithAdr.adr,
              } as EmployeeReportingParameterNode
            }
            key={`${occupation}-${gradeWithAdr.grade}`}
          />
        );
      })}
      <TableCell padding="none">
        {isEdit && occupation !== DEFAULT_OCCUPATION && (
          <DeleteIconWithTooltip
            tooltipText={`Supprimer les coûts du rôle "${occupation}"`}
            onClick={() => setIsDeleteModalOpen(true)}
          />
        )}
      </TableCell>
      <OccupationAdrDeleteModal
        occupation={occupation}
        open={isDeleteModalOpen}
        handleValidation={() => refetch()}
        handleClose={() => setIsDeleteModalOpen(false)}
      />
    </StyledTableRow>
  );
}
