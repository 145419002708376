import React from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';

function renderRow(properties: ListChildComponentProps) {
  const { data, index, style } = properties;
  const groupHeaderPadding = 10;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + groupHeaderPadding,
    },
  });
}

const OuterElementContext = React.createContext({});

// eslint-disable-next-line react/display-name
const OuterElementType = React.forwardRef<HTMLDivElement>(
  (properties, reference) => {
    const outerProperties = React.useContext(OuterElementContext);
    return <div ref={reference} {...properties} {...outerProperties} />;
  }
);

function useResetCache(data: unknown) {
  const reference = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (reference.current) {
      reference.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return reference;
}

// Adapter for react-window
export const ListBoxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListBoxComponent(properties, reference) {
  const { children, ...other } = properties;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  const itemSize = 68;
  const groupHeaderSize = 22;
  const getHeight = () => {
    if (itemCount > 5) {
      return 5 * itemSize + groupHeaderSize;
    }
    return (itemData.length - 1) * itemSize + groupHeaderSize;
  };

  const gridReference = useResetCache(itemCount);

  return (
    <div ref={reference}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          ref={gridReference}
          outerElementType={OuterElementType}
          innerElementType="ul"
          height={getHeight()}
          width="100%"
          itemData={itemData}
          itemCount={itemCount}
          itemSize={(index) => (index === 0 ? groupHeaderSize : itemSize)}
          overscanCount={5}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});
