import { Autocomplete, TextField } from '@mui/material';
import { Moment } from 'moment';
import React from 'react';

interface MonthsSelectorProps {
  allMonths: Moment[];
  handleChangeMonths: (options: Moment[]) => Moment[];
}

export default function MonthsSelector({
  allMonths,
  handleChangeMonths,
}: MonthsSelectorProps) {
  return (
    <Autocomplete
      sx={{ marginTop: 8 }}
      multiple
      filterSelectedOptions
      options={allMonths}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label="Veuillez sélectionner les mois sur lesquels vous voulez rendre
        la main."
        />
      )}
      getOptionLabel={(option) => option.format('MMMM YYYY')}
      onChange={(_event, newValue) => handleChangeMonths(newValue)}
    />
  );
}
