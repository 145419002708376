import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import InitializedMissionRoute from 'components/MissionFollowUp/BillDetail/MissionInitialization/InitializedMissionRoute';
import {
  ActivityNode,
  BillingClientNode,
  EmployeeNode,
  useActivityChiefsLazyQuery,
  useAllBillingClientQuery,
  useAllEmployeesForActivityLazyQuery,
  useComexQuery,
} from 'generated/graphql';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'store';
import { setClients } from 'store/activity';
import {
  setComex,
  setCurrentManagersActivity,
  setEmployeesForCurrentActivity,
} from 'store/users';

const MissionBillingPage = () => {
  const currentMission: ActivityNode = useSelector(
    (state) => state.activity.currentMission
  );
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const displayError = () => {
    enqueueSnackbar(`Erreur lors de la récupération des données`, {
      variant: 'error',
    });
  };

  const { loading: allBillingClientQuery } = useAllBillingClientQuery({
    onCompleted: (data) => {
      if (data?.allBillingClient) {
        dispatch(setClients(data.allBillingClient as BillingClientNode[]));
      }
    },
    onError: displayError,
  });

  const [
    allEmployeesForActivityQuery,
    { loading: employeesForActivityLoading },
  ] = useAllEmployeesForActivityLazyQuery({
    onCompleted: (data) => {
      if (data?.allEmployeesForActivity) {
        dispatch(
          setEmployeesForCurrentActivity(
            data.allEmployeesForActivity as EmployeeNode[]
          )
        );
      }
    },
    onError: displayError,
  });

  const { loading: comexLoading } = useComexQuery({
    onCompleted: (data) => {
      if (data?.comex) {
        dispatch(setComex(data.comex as EmployeeNode[]));
      }
    },
    onError: displayError,
  });

  const [activityChiefsQuery, { loading: activityChiefsLoading }] =
    useActivityChiefsLazyQuery({
      onCompleted: (data) => {
        if (data?.activityChiefs) {
          dispatch(
            setCurrentManagersActivity(data.activityChiefs as EmployeeNode[])
          );
        }
      },
      onError: displayError,
    });

  useEffect(() => {
    if (currentMission?.id) {
      allEmployeesForActivityQuery({
        variables: {
          activityId: currentMission.id,
          dateFrom: currentMission.startDate,
          dateTo: currentMission.expirationDate,
        },
      });
      activityChiefsQuery({ variables: { activityIds: [currentMission.id] } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMission]);

  const loading =
    allBillingClientQuery ||
    employeesForActivityLoading ||
    comexLoading ||
    activityChiefsLoading;

  if (loading) {
    return <LoadingPlaceholder />;
  }

  return (
    <>
      {currentMission && !currentMission.billingInformation ? (
        <Redirect to={`/mission-followup/dashboard`} />
      ) : (
        <InitializedMissionRoute />
      )}
    </>
  );
};

export default MissionBillingPage;
