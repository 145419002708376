import clsx from 'clsx';
import InputContainer from 'components/commons/InputContainer/InputContainer';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import styles from './styles/comment.module.scss';
import lookupStyles from './styles/lookup.module.scss';

interface ManagerCommentProps {
  label: string;
  comment: string | undefined;
  disabled: boolean;
  name?: string;
  require?: boolean;
}

const Comment = ({
  label,
  comment,
  disabled,
  name,
  require = false,
}: ManagerCommentProps) => {
  const { register } = useFormContext();
  return (
    <InputContainer label={label}>
      <textarea
        disabled={disabled}
        className={clsx(
          styles.commentAuthorization,
          disabled && lookupStyles.disabledInputContainer
        )}
        defaultValue={comment}
        ref={register({
          required: require,
        })}
        name={name}
      />
    </InputContainer>
  );
};

export default Comment;
