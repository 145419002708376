import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import EstimatedContextProvider from 'components/Revenue/Estimated/context/EstimatedContextProvider';
import {
  ActivityNode,
  RevenueProspectiveNode,
  useFetchAllEstimatedRevenuesLazyQuery,
} from 'generated/graphql';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import EstimatedRevenueHomePage from 'pages/RevenuePage/EstimatedRevenuePage/EstimatedRevenueHomePage';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'store';

export default function EstimatedRevenueHomePageWrapper() {
  const { currentYear, setCurrentYear } = useTableHeaderContext();
  const selectedMissions = useSelector(
    (state) => state.activity.selectedMissions
  );
  const { enqueueSnackbar } = useSnackbar();
  const [estimatedRevenues, setEstimatedRevenues] = useState(
    [] as ActivityNode[]
  );
  const [revenueProspectives, setRevenueProspectives] = useState(
    [] as RevenueProspectiveNode[]
  );

  const [fetchAllEstimatedRevenues, { loading }] =
    useFetchAllEstimatedRevenuesLazyQuery({
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        setEstimatedRevenues(
          data.allEstimatedRevenues?.estimatedRevenues as ActivityNode[]
        );
        setRevenueProspectives(
          data.allEstimatedRevenues
            ?.revenueProspectives as RevenueProspectiveNode[]
        );
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
    });

  useEffect(() => {
    fetchAllEstimatedRevenues({
      variables: {
        year: currentYear.year(),
        missionsIds: _.map(selectedMissions, (mission) => mission.id),
      },
    });
  }, [selectedMissions, currentYear, fetchAllEstimatedRevenues]);

  return (
    <>
      <EstimatedContextProvider
        initialMissions={estimatedRevenues}
        revenueProspectives={revenueProspectives}
        setRevenueProspectives={setRevenueProspectives}
        currentYear={currentYear}
      >
        <EstimatedRevenueHomePage
          currentYear={currentYear}
          setCurrentYear={setCurrentYear}
          loading={loading}
          estimatedRevenues={estimatedRevenues}
        />
      </EstimatedContextProvider>
    </>
  );
}
