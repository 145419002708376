import { Chip, ChipProps } from '@mui/material';
import {
  EmployeeNode,
  EmployeesEmployeeContractTypeChoices,
  EmployeesEmployeeGradeChoices,
} from 'generated/graphql';
import React from 'react';

import { getEmployeeGradeVariant, getRoleName } from '../utils';

const PROVIDER_MINIFIED_NAME = 'PRESTA';

interface OccupationChipProps {
  employee: EmployeeNode;
  displayAllGrades?: boolean;
  showLabel?: boolean;
}

export const GradeChip = ({
  employee,
  displayAllGrades,
  showLabel,
  ...chipProps
}: OccupationChipProps & ChipProps) => {
  if (
    !displayAllGrades &&
    employee.grade !== EmployeesEmployeeGradeChoices.Coman &&
    employee.grade !== EmployeesEmployeeGradeChoices.Comex &&
    employee.contractType !== EmployeesEmployeeContractTypeChoices.Provider
  ) {
    return <></>;
  }

  const gradeVariant = getEmployeeGradeVariant(employee);

  const roleName = getRoleName(employee);
  const chipLabel =
    !displayAllGrades &&
    employee.contractType === EmployeesEmployeeContractTypeChoices.Provider
      ? PROVIDER_MINIFIED_NAME
      : roleName;
  return (
    <>
      <Chip
        sx={{
          display: { xs: showLabel ? 'flex' : 'none', sm: 'flex' },
        }}
        label={chipLabel}
        {...chipProps}
        variant={gradeVariant as 'coman' | 'comex' | 'grade'}
      />
      {!showLabel && (
        <Chip
          sx={{
            display: { xs: 'flex', sm: 'none' },
            width: '20px',
            height: '20px',
          }}
          {...chipProps}
          variant={gradeVariant as 'coman' | 'comex' | 'grade'}
        />
      )}
    </>
  );
};
