import {
  ActivityNode,
  EmployeeNode,
  MeEmployeeFragment,
  RevenueObjectiveNode,
} from 'generated/graphql';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { isEmployeeChiefOfActivity, isEmployeeDirectorOfActivity } from 'utils';

export const getMonthsWithActualRevenue = (activity: ActivityNode) => {
  const allRevenuesForActivities = _.flatMap(
    activity.billingInformation?.purchaseOrders,
    (po) => po.revenues || []
  );
  const allRevenuesMonths = _.map(allRevenuesForActivities, 'month');

  return _.uniq(allRevenuesMonths);
};

export const getInputsToFill = (
  refDate: Moment,
  activity: ActivityNode,
  filledEstimated = 0,
  objective?: RevenueObjectiveNode
) => {
  if (!objective) {
    return 0;
  }

  const currentYear = moment(objective.year, 'YYYY');
  const endDate = moment
    .min(currentYear.clone().endOf('year'), moment(activity.expirationDate))
    .endOf('month');
  const startDate = moment
    .max([
      refDate,
      moment(activity.startDate),
      moment(`${objective.year}-01-01`),
    ])
    .startOf('month');

  const monthsWithActualRevenue = _.map(
    getMonthsWithActualRevenue(activity),
    (month) => moment(month)
  );
  const futureMonthsWithActualRevenue = _.filter(
    monthsWithActualRevenue,
    (month) => month.isSameOrAfter(startDate)
  );
  const monthCount =
    endDate.diff(startDate, 'month') + 1 - futureMonthsWithActualRevenue.length;
  if (monthCount <= 0) {
    return 0;
  }

  const inputsToFill = monthCount - filledEstimated;
  if (inputsToFill <= 0) {
    return 0;
  }
  return inputsToFill;
};

export const getEstimatedMonthly = (
  objective: RevenueObjectiveNode,
  previousMonthsTotal: number,
  refDate: Moment,
  activity?: ActivityNode,
  estimatedTotal = 0,
  filledEstimated = 0
) => {
  if (!activity) {
    return 0;
  }

  const inputsToFill = getInputsToFill(
    refDate,
    activity,
    filledEstimated,
    objective
  );
  // skip division by 0
  if (inputsToFill === 0) {
    return 0;
  }

  const result =
    (objective.objective - previousMonthsTotal - estimatedTotal) / inputsToFill;
  return result < 0 ? 0 : result;
};

export const getTotalEstimatedRevenueForRange = (
  start: Moment,
  end: Moment,
  refDate: Moment,
  mission: ActivityNode
) => {
  if (end.isBefore(start, 'month')) {
    return 0;
  }
  const revenueTotal = _.sumBy(mission.revenueEstimations, (revenue) => {
    if (
      moment(revenue.month).isBetween(start, end, 'month', '[]') &&
      moment(revenue.month).isBefore(refDate, 'month')
    ) {
      return revenue.amount;
    }
    return 0;
  });

  return revenueTotal;
};

// useful to know where is the estimated start
export const getRefDate = (currentYear: Moment) => {
  const today = moment();
  if (today.isSame(currentYear, 'year')) {
    return today;
  }
  if (today.isBefore(currentYear, 'year')) {
    return currentYear.clone().startOf('year');
  } else {
    // estimated start doesn't exist in the past so returning a non-displayable date
    return currentYear.clone().add(1, 'year').startOf('year');
  }
};

export const findActualAmountOrUndefined = (
  month: Moment,
  activity: ActivityNode
): number | undefined => {
  const allRevenuesForActivities = _.flatMap(
    activity.billingInformation?.purchaseOrders,
    (po) => po.revenues || []
  );

  const monthRevenues = _.filter(allRevenuesForActivities, (revenue) =>
    month.isSame(moment(revenue.month), 'month')
  );

  const noRevenuesFound = _.isEmpty(monthRevenues);
  if (noRevenuesFound) {
    return undefined;
  }

  const revenueTotal = _.sumBy(monthRevenues, 'amount');
  return revenueTotal;
};

export const findEstimatedAmountForMonth = (
  month: Moment,
  mission: ActivityNode
) => {
  const revenueEstimation = _.find(mission.revenueEstimations, (estimation) =>
    month.isSame(moment(estimation.month), 'month')
  );
  return revenueEstimation;
};

export const verifyEmployeePermissions = (
  mission: ActivityNode | undefined | null,
  employee: EmployeeNode | MeEmployeeFragment | undefined | null
) => {
  if (!employee || !mission) {
    return {
      isDirector: false,
      isChief: false,
    };
  }
  const isDirector = isEmployeeDirectorOfActivity(mission, employee);
  const isChief = isEmployeeChiefOfActivity(mission, employee);
  return {
    isDirector,
    isChief,
  };
};

export const estimatedRevenueBackgroundColorSx = {
  backgroundColor: 'background.default',
};
