import { Search } from '@mui/icons-material';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  InputAdornment,
  TextField,
  useTheme,
} from '@mui/material';
import DisplayMission from 'components/commons/DisplayMission';
import { filterSearchbarOptions } from 'components/Navigation/SearchMissionBar';
import { useUserInfo } from 'components/User/UserProvider';
import {
  ActivityNode,
  EmployeeNode,
  useAllChiefedActivitiesQuery,
} from 'generated/graphql';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'store';
import { isChiefOrDirectorOfActivity } from 'utils';

interface ActivitySearchBarProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  setActivity: React.Dispatch<React.SetStateAction<ActivityNode | undefined>>;
  disabled?: boolean;
}

export default function ActivitySearchBar({
  value,
  setActivity,
  disabled,
}: ActivitySearchBarProps) {
  const { employee, isControl, isComex } = useUserInfo();
  const { data } = useAllChiefedActivitiesQuery({ skip: isControl });
  const userActivitiesAsChief = data?.allChiefedActivities;

  const ownCurrentMissions = useSelector(
    (state) => state.activity.ownCurrentMissions
  );
  const otherCurrentMissions = useSelector(
    (state) => state.activity.otherCurrentMissions
  );
  const ownPastMissions = useSelector(
    (state) => state.activity.ownPastMissions
  );
  const otherPastMissions = useSelector(
    (state) => state.activity.otherPastMissions
  );

  const handleOnChange = async (
    _event: object,
    value: ActivityNode | string | null
  ) => {
    if (typeof value === 'object' && value?.id) {
      setActivity(value);
    } else {
      setActivity(undefined);
    }
  };

  return (
    <Autocomplete
      disabled={disabled}
      freeSolo
      value={value || null}
      options={[
        ...ownCurrentMissions,
        ...otherCurrentMissions,
        ...ownPastMissions,
        ...otherPastMissions,
      ]}
      filterOptions={filterSearchbarOptions}
      popupIcon={false}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        return option.name;
      }}
      onChange={handleOnChange}
      groupBy={(option) => {
        const isCurrent = moment().isBefore(option?.expirationDate);
        if (
          (!isControl || isComex) &&
          (isChiefOrDirectorOfActivity(option, employee as EmployeeNode) ||
            userActivitiesAsChief?.some(
              (activitiesAsChief) => activitiesAsChief.id === option.id
            ))
        ) {
          return `Mes missions ${isCurrent ? '' : 'passées'}`;
        }
        return `Missions ${isCurrent ? 'courantes' : 'passées'}`;
      }}
      renderOption={(props, option: ActivityNode) => (
        <Box component={'li'} {...props} key={'option-' + option.id}>
          <DisplayMission
            mission={option}
            isClientDisplayed
            missionTooltip={false}
            textMaxWidth={380}
          />
        </Box>
      )}
      renderInput={AutocompleteInputRenderer}
      sx={{ width: { xs: '100%' } }}
    />
  );
}

function AutocompleteInputRenderer(props: AutocompleteRenderInputParams) {
  const theme = useTheme();
  const [isFocused, setIsFocused] = useState(false);

  return (
    <TextField
      {...props}
      required
      size="small"
      label="Mission"
      placeholder="Rechercher une mission"
      variant="outlined"
      sx={{
        boxSizing: 'border-box',
        width: '100%',
        input: {
          '&::placeholder': {
            opacity: isFocused ? 0.5 : 1,
          },
        },
      }}
      InputProps={{
        ...props.InputProps,
        startAdornment: (
          <InputAdornment sx={{ marginLeft: 1 }} position="start">
            <Search sx={{ color: theme.palette.text.primary }} />
          </InputAdornment>
        ),
        onFocus: () => setIsFocused(true),
        onBlur: () => setIsFocused(false),
      }}
    />
  );
}
