import { Stack } from '@mui/material';
import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import PageWrapper from 'components/commons/PageWrapper';
import TabHeader from 'components/commons/TabHeader';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import YearSelector from 'components/commons/YearSelector/YearSelector';
import BilledRevenueMissionTable from 'components/Revenue/BilledRevenueTables/BilledRevenueMissionTable';
import DisplayDirector from 'components/Revenue/Tables/DisplayDirector';
import {
  PurchaseOrderNode,
  useFetchAllPoForActivityLazyQuery,
} from 'generated/graphql';
import { useSnackbar } from 'notistack';
import { PageTitles } from 'poly-constants';
import React, { useEffect } from 'react';
import { useSelector } from 'store';

export default function BilledRevenueMissionPage() {
  const { enqueueSnackbar } = useSnackbar();
  const currentMission = useSelector((state) => state.activity.currentMission);
  const missionId = currentMission?.id;
  const { currentYear, setCurrentYear } = useTableHeaderContext();
  const [purchaseOrders, setPurchaseOrders] = React.useState<
    PurchaseOrderNode[]
  >([]);

  const [fetchAllPo, { loading }] = useFetchAllPoForActivityLazyQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data?.allRevenuesForActivity?.purchaseOrders) {
        setPurchaseOrders(
          data.allRevenuesForActivity.purchaseOrders as PurchaseOrderNode[]
        );
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  useEffect(() => {
    fetchAllPo({
      variables: {
        activityId: missionId || '',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentYear.year()]);

  return (
    <PageWrapper missionView>
      <TabHeader
        title={PageTitles.billedRevenuePage}
        yearSelectorComponent={
          <YearSelector
            currentYear={currentYear}
            setCurrentYear={setCurrentYear}
          />
        }
      />
      {loading ? (
        <LoadingPlaceholder />
      ) : (
        <>
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{ mb: 3 }}
          >
            <DisplayDirector activity={currentMission} />
          </Stack>
          <BilledRevenueMissionTable
            purchaseOrders={purchaseOrders}
            activity={currentMission}
            currentYear={currentYear}
            setCurrentYear={setCurrentYear}
          />
        </>
      )}
    </PageWrapper>
  );
}
