import { TableCell, TableRow } from '@mui/material';
import clsx from 'clsx';
import commonStyles from 'components/commons/css/commons.module.scss';
import {
  TableMode,
  useTableContext,
} from 'components/Revenue/RevenueGlobalTable/context/TableContextProvider';
import { BillingClientRevenue } from 'components/Revenue/RevenueGlobalTable/RevenueTableBodyByClient';
import styles from 'components/Revenue/RevenueGlobalTable/styles/TableCell.module.scss';
import OutOfRangeTableCell from 'components/Revenue/Tables/RevenueTableCells/OutOfRangeTableCell';
import TotalTableCell from 'components/Revenue/Tables/RevenueTableCells/TotalTableCell';
import {
  ActivityNode,
  BillingClientNode,
  BillingPurchaseOrderCurrencyChoices,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { totalFormat } from 'pages/ActivityPage/utils';
import React from 'react';

interface PoRowProps {
  clientRevenues: BillingClientRevenue[];
  amountPerMonthForActivities?: {
    amounts: number[] | undefined;
    activity: ActivityNode | undefined;
    client: BillingClientNode | undefined;
  }[];
}

export default function TotalRow({
  clientRevenues,
  amountPerMonthForActivities,
}: PoRowProps) {
  const { displayedMonths, mode } = useTableContext();

  const startClient = _.minBy(
    clientRevenues,
    (client) => client.dateRange.start
  );

  const endClient = _.maxBy(clientRevenues, (client) => client.dateRange.end);

  const totalYear = _.sumBy(
    amountPerMonthForActivities,
    (amountPerMonthForActivity) => _.sum(amountPerMonthForActivity.amounts)
  );

  return (
    <TableRow
      className={clsx(
        commonStyles.stickyFooter,
        styles.revenueRowIn,
        styles.revenueRowHeight
      )}
    >
      <TableCell className={clsx(styles.totalColumn, styles.boldTotal)}>
        CA {mode === TableMode.BILLED ? 'Facturé' : 'Réalisé'}
      </TableCell>
      {_.map(displayedMonths, (month) => {
        if (
          !month.isBetween(
            startClient?.dateRange.start || moment(),
            endClient?.dateRange.end || moment(),
            'month',
            '[]'
          )
        ) {
          return <OutOfRangeTableCell />;
        }
        return (
          <TotalTableCell
            key={`total-${month}`}
            id={`actualRevenueTotal${month.month()}`}
          >
            {totalFormat(
              mode === TableMode.BILLED
                ? _.sum(
                    amountPerMonthForActivities?.map(
                      (amountPerMonthForActivity) =>
                        amountPerMonthForActivity.amounts?.[month.get('month')]
                    )
                  )
                : _.sumBy(
                    clientRevenues,
                    (client) =>
                      _.find(client.totals, (total) =>
                        total.month.isSame(month, 'month')
                      )?.amount || 0
                  ),
              BillingPurchaseOrderCurrencyChoices.Eur
            )}
          </TotalTableCell>
        );
      })}
      <TableCell className={clsx(styles.totalColumn, styles.boldTotal)}>
        {totalFormat(
          mode === TableMode.BILLED
            ? totalYear
            : _.sumBy(clientRevenues, (client) => client.total),
          BillingPurchaseOrderCurrencyChoices.Eur
        )}
      </TableCell>
    </TableRow>
  );
}
