import { TableBody } from '@mui/material';
import { EmployeeNode } from 'generated/graphql';
import _ from 'lodash';
import React from 'react';

import { useAdrContext } from './AdrContextProvider';
import ProviderAdrTableRow from './ProviderAdrTableRow';

interface ProviderAdrTableBodyProps {
  providers: EmployeeNode[];
  refetchProviders: () => void;
}

export default function ProviderAdrTableBody({
  providers,
  refetchProviders,
}: ProviderAdrTableBodyProps) {
  const { isEdit } = useAdrContext();
  return (
    <TableBody>
      {_.map(providers, (providerEmployee) => (
        <ProviderAdrTableRow
          provider={providerEmployee}
          refetchProviders={refetchProviders}
          isEdit={isEdit}
          key={providerEmployee.id}
        />
      ))}
    </TableBody>
  );
}
