import { useTableContext } from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/context/TableContextProvider';
import RevenueCellDisplayValue, {
  TypeCell,
} from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/RevenueTableCells/CellTypes/RevenueCellDisplayValue';
import RevenueCellInput from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/RevenueTableCells/CellTypes/RevenueCellInput';
import RevenueCellOutOfRange from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/RevenueTableCells/CellTypes/RevenueCellOutOfRange';
import {
  checkExceptionalMonth,
  isInRange,
  isInValidatedRangeMonths,
} from 'components/Revenue/Tables/util';
import { useUserInfo } from 'components/User/UserProvider';
import {
  BillingPurchaseOrderCurrencyChoices,
  PurchaseOrderNode,
} from 'generated/graphql';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { totalFormat } from 'pages/ActivityPage/utils';
import { isNumberNullOrPositive } from 'pages/MissionFollowUp/formValidators';
import { POLY_DATE_MONTH } from 'poly-constants';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  isMonthEditableForActualRevenueTable,
  isMonthValidated,
} from '../utils';

interface RevenueFormTableCellProps {
  purchaseOrder: PurchaseOrderNode;
  period: { start: Moment; end: Moment };
  month: Moment;
  keyForm: string;
  viewOnly?: boolean;
  totalRow?: number;
  suggestedValue?: number;
  isSimple?: boolean;
}

const RevenueFormTableCell = ({
  purchaseOrder,
  period,
  month,
  keyForm,
  viewOnly,
  totalRow,
  suggestedValue,
  isSimple,
}: RevenueFormTableCellProps) => {
  const { exceptionalMonth, rangeValidatedMonth, isMissionEditable } =
    useTableContext();
  const { watch, trigger } = useFormContext();
  const po_start = period.start;
  const po_end = period.end;
  const totalPo = totalRow || Infinity;
  const [key] = useState(`${keyForm}.${month.format(POLY_DATE_MONTH)}`);

  const inputRevenues = watch(keyForm);
  const textDisplayValidation = purchaseOrder.tasks?.length
    ? 'chantier'
    : 'BDC';

  const { isControl, isSuperuser } = useUserInfo();

  // Get all po inputs from form and sum them to get total
  const totalOtherInputRevenue = () => {
    return _(inputRevenues)
      .map((input, key) => {
        if (!moment(key, POLY_DATE_MONTH).isSame(month, 'month')) {
          return input.amount || 0;
        }
        return 0;
      })
      .sum();
  };

  const revenue = watch(key);

  // Verify if po is valid this period
  if (!isInRange(po_start, po_end, month)) {
    return (
      <RevenueCellOutOfRange
        key={key}
        isValidated={isInValidatedRangeMonths(rangeValidatedMonth, month)}
      />
    );
  }

  if (viewOnly || !isMissionEditable) {
    return (
      <RevenueCellDisplayValue
        value={totalFormat(
          revenue?.amount || 0,
          BillingPurchaseOrderCurrencyChoices.Eur
        )}
        typeOfCell={isSimple ? TypeCell.primary : TypeCell.secondary}
      />
    );
  }

  const isExceptionalMonth = checkExceptionalMonth(month, exceptionalMonth);
  const isCellEditable =
    (isControl || isSuperuser || isMonthEditableForActualRevenueTable(month)) &&
    (!isMonthValidated([purchaseOrder], month) || isExceptionalMonth);
  if (isCellEditable) {
    return (
      <RevenueCellInput
        key={key}
        disabled={exceptionalMonth && !isExceptionalMonth}
        suggestedValue={suggestedValue}
        formName={`${key}.amount`}
        currency={BillingPurchaseOrderCurrencyChoices.Eur}
        triggerChange={() => {
          const po = purchaseOrder;
          const baseKey = `revenuesByPo.${po.id}.revenues`;
          _.forEach(watch(baseKey), async (object, key) => {
            const newKey = `${baseKey}.${key}`;
            if (moment(key, POLY_DATE_MONTH, true).isValid()) {
              await trigger(`${newKey}.amount`);
              await trigger(`${newKey}.amount`);
            } else {
              _.forEach(watch(newKey), async (object, key) => {
                const taskAdrKey = `${newKey}.${key}`;
                await trigger(`${taskAdrKey}.amount`);
                await trigger(`${taskAdrKey}.amount`);
              });
            }
          });
        }}
        validateRules={{
          validate: {
            isNumberPositive: isNumberNullOrPositive,
            maxTotal(value) {
              const total = totalOtherInputRevenue() + (value || 0);
              return (
                totalPo >= total ||
                `La somme des montants saisis ne doit pas dépasser le montant total du ${textDisplayValidation}`
              );
            },
          },
        }}
      />
    );
  }
  return (
    <RevenueCellDisplayValue
      key={key}
      value={totalFormat(
        revenue?.amount || 0,
        BillingPurchaseOrderCurrencyChoices.Eur
      )}
      typeOfCell={isSimple ? TypeCell.primary : TypeCell.secondary}
    />
  );
};

export default RevenueFormTableCell;
