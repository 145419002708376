import MissionTypeIcon from 'components/commons/MissionTypeIcon';
import PolyModal from 'components/MUIOverload/PolyDialog/PolyModal';
import { ActivitiesActivityTypeChoices, ActivityNode } from 'generated/graphql';
import React from 'react';

import { InternalActivityForm } from './InternalActivityForm';

interface ActivityModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  activity?: ActivityNode;
  isCftActivity?: boolean;
  refetchActivityInfo?: () => void;
}

export const InternalActivityModal = ({
  open,
  onClose,
  title,
  activity,
  isCftActivity = false,
  refetchActivityInfo,
}: ActivityModalProps): JSX.Element => {
  return (
    <PolyModal
      open={open}
      onClose={onClose}
      polyDialogTitle={title}
      polyDialogIcon={
        <MissionTypeIcon
          type={
            isCftActivity
              ? ActivitiesActivityTypeChoices.Cft
              : ActivitiesActivityTypeChoices.Int
          }
          tooltip
        />
      }
      maxWidth="md"
      noSecondaryButton
    >
      <InternalActivityForm
        onClose={onClose}
        isCftActivity={isCftActivity}
        activity={activity}
        refetchActivityInfo={refetchActivityInfo}
      />
    </PolyModal>
  );
};
