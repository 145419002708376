import {
  Box,
  Chip,
  DialogContent,
  DialogContentText,
  Typography,
} from '@mui/material';
import PolyModal from 'components/MUIOverload/PolyDialog/PolyModal';
import { SectorNode } from 'generated/graphql';
import { useDeleteSectorMutation } from 'generated/graphql';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';

import { getNameFormatted } from './utils';

export interface SectorModalProps {
  isOpen: boolean;
  handleClose: () => void;
  sector: SectorNode;
  refetch: () => void;
}

const DeleteSectorModal = ({
  isOpen,
  handleClose,
  sector,
  refetch,
}: SectorModalProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteSector] = useDeleteSectorMutation({
    onCompleted: () => {
      enqueueSnackbar(`Le secteur « ${sector.name} » a bien été supprimé`, {
        variant: 'success',
      });
      refetch();
      handleClose();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const handleDelete = () => {
    deleteSector({
      variables: {
        sectorId: sector.id,
      },
    });
  };

  const sectorHasClients = !!sector.billingClients?.length;

  return (
    <PolyModal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEnforceFocus={true}
      polyDialogTitle={'Supprimer un secteur'}
      primaryButtonName={'Supprimer secteur'}
      handlePrimary={handleDelete}
    >
      <DialogContent sx={{ width: '500px', py: 0 }}>
        {sectorHasClients ? (
          <>
            <DialogContentText>
              Clients associés à {sector.name} :
            </DialogContentText>
            <Box sx={{ display: 'flex', w: 1, flexWrap: 'wrap' }}>
              {_.map(sector.billingClients, (client) => (
                <Chip
                  label={getNameFormatted(client.corporateName)}
                  sx={{ ml: 1, mt: 1 }}
                  size={'small'}
                  key={`client-${client.id}`}
                />
              ))}
            </Box>
          </>
        ) : (
          <DialogContentText>
            Le secteur {sector.name} n&apos;a pas de clients associés.
          </DialogContentText>
        )}
        <Typography sx={{ mt: 2 }}>
          Souhaitez-vous supprimer ce secteur ?
        </Typography>
        {sectorHasClients && (
          <Typography sx={{ fontWeight: 'bold' }}>
            Attention: sa suppression entrainera la migration automatique des
            clients vers &quot;Pas de secteur&quot;.
          </Typography>
        )}
      </DialogContent>
    </PolyModal>
  );
};

export default DeleteSectorModal;
