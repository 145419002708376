import BackdropLoading from 'components/commons/BackdropLoading';
import PageContent from 'components/commons/PageContent';
import PageWrapper from 'components/commons/PageWrapper';
import PolyTitle from 'components/commons/PolyTitle';
import PolyDataGrid from 'components/MUIOverload/PolyDataGrid';
import { PolyDataGridColumn } from 'components/MUIOverload/PolyDataGrid/PolyDataGridHead';
import { OvertimeAuthorizationsQuery } from 'generated/graphql';
import React from 'react';

interface ExceptionalActivitiesAuthorizationsPanelProps {
  title: string;
  body: string;
  loading: boolean;
  columns: PolyDataGridColumn<
    DeepExtractTypeSkipArrays<
      OvertimeAuthorizationsQuery,
      ['overtimeAuthorizations']
    >
  >[];
  asManager: boolean;
  overtimeAuthorizationsData:
    | DeepExtractTypeSkipArrays<OvertimeAuthorizationsQuery, []>
    | undefined;
}

const ExceptionalActivitiesAuthorizationsPanel = ({
  title,
  body,
  columns,
  loading,
  overtimeAuthorizationsData,
}: ExceptionalActivitiesAuthorizationsPanelProps) => {
  const getRows = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    orderBy: string | string[] | undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    order: 'asc' | 'desc' | undefined
  ) => {
    if (
      overtimeAuthorizationsData === undefined ||
      overtimeAuthorizationsData.overtimeAuthorizations === undefined
    ) {
      return [];
    }

    return overtimeAuthorizationsData.overtimeAuthorizations;
  };

  return (
    <>
      <PageWrapper noLimit>
        <PolyTitle>{title}</PolyTitle>
        <PageContent>{body}</PageContent>
        {!loading && <PolyDataGrid columns={columns} getRows={getRows} />}
      </PageWrapper>
      {loading && <BackdropLoading open={loading} />}
    </>
  );
};

export default ExceptionalActivitiesAuthorizationsPanel;
