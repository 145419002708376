import { Credentials } from 'components/User/Authenticator/utils';
import urlJoin from 'url-join';

import { get, post } from './api';

interface RefreshTokenRequest {
  refresh: string;
  grant_type: 'refresh_token';
}

interface LoginRequest {
  username: string;
  password: string;
}

type GoogleClientIdResponse = string;

interface GoogleLogin {
  tokenId: string;
}

export const logAs = async (request: LoginRequest) =>
  await post<LoginRequest, Credentials>(
    urlJoin('/', 'api', 'login', 'log_as/'),
    request
  );

export const logIn = async (request: LoginRequest) =>
  await post<LoginRequest, Credentials>(
    urlJoin('/', 'api', 'login', 'local/'),
    request
  );

export const logOut = async () =>
  await post<LoginRequest, Credentials>(
    urlJoin('/', 'api', 'login', 'logout/')
  );

export const getGoogleClientId = async (): Promise<string> => {
  let clientID = '';

  if (process.env.REACT_APP_GOOGLE_CLIENT_ID) {
    clientID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  }

  const clientIdUrl = urlJoin('/', 'api', 'login', 'google', 'client-id');
  try {
    const resp = await get<GoogleClientIdResponse>(clientIdUrl, true);
    if (resp.response) {
      clientID = resp.response;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return clientID;
};

export const logInGoogle = async (request: GoogleLogin) =>
  await post<GoogleLogin, Credentials>('/api/login/google/  ', request);

export const refreshToken = (request: RefreshTokenRequest) =>
  post<RefreshTokenRequest, Credentials>('/api/login/refresh/', request);
