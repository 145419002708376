import { SxProps } from '@mui/material';
import React from 'react';

import PolyTableBody from '../PolyTableBody';
import { PolyDataGridColumn } from './PolyDataGridHead';
import PolyDataGridRow from './PolyDataGridRow';

interface PolyDataGridBodyProps<T> {
  columns: PolyDataGridColumn<T>[];
  rows: T[];
  rowsCallback?: (element: T) => void;
  selectedRowsCallback?: (element: T, selected: boolean) => void;
  isRowSelectEnabled?: (element: T) => boolean;
  className?: string;
  getRowId?: (element: T) => string;
  isSelectable?: boolean;
  sx?: SxProps;
}

export default function PolyDataGridBody<T>({
  columns,
  rows,
  rowsCallback,
  selectedRowsCallback,
  isRowSelectEnabled,
  className,
  getRowId,
  isSelectable,
  sx,
}: PolyDataGridBodyProps<T>) {
  return (
    <PolyTableBody>
      {rows.map((row, index) => (
        <PolyDataGridRow
          key={getRowId ? getRowId(row) : index}
          row={row}
          isSelectableProp={isSelectable}
          columns={columns}
          rowsCallback={rowsCallback}
          selectedRowsCallback={selectedRowsCallback}
          isRowSelectEnabled={isRowSelectEnabled}
          className={className}
          sx={{ cursor: 'pointer', ...sx }}
        />
      ))}
    </PolyTableBody>
  );
}
