import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import { FlatTable } from 'components/commons/Tables/FlatTable';
import TableHeaderContextProvider from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import {
  ClientType,
  transformProfitsByClient,
} from 'components/Reporting/Profitability/GlobalView/ProfitabilityTableBody/ProfitabilityTableBody';
import ProfitabilityTrimesterTableBody from 'components/Reporting/Profitability/GlobalView/ProfitabilityTableBody/ProfitabilityTrimesterTableBody';
import ProfitabilityTableFooterMissions from 'components/Reporting/Profitability/GlobalView/ProfitabilityTableFooterMissions';
import ProfitabilityTableFooterSectors from 'components/Reporting/Profitability/GlobalView/ProfitabilityTableFooterSectors';
import ProfitabilityTrimesterTableHeader from 'components/Reporting/Profitability/GlobalView/ProfitabilityTrimesterTableHeader';
import { useUserInfo } from 'components/User/UserProvider';
import {
  ActivityNode,
  BillingClientNode,
  EmployeesEmployeeGradeChoices,
  SectorNode,
  useActivitiesTrimesterProfitsQuery,
  useGetNonInitExternalMissionsClientQuery,
  useGetpolyconseilClientQuery,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment/moment';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import { useSelector } from 'store';

interface ReportingProfitabilityTrimesterTableProps {
  currentYear: moment.Moment;
  setCurrentYear: (newYear: moment.Moment) => void;
}
export default function ReportingProfitabilityTrimesterTable({
  currentYear,
  setCurrentYear,
}: ReportingProfitabilityTrimesterTableProps) {
  const { employee, isControl, isSuperuser } = useUserInfo();
  const isComex = employee?.grade === EmployeesEmployeeGradeChoices.Comex;
  const selectedMissions: ActivityNode[] = useSelector(
    (state) => state.activity.selectedMissions
  );
  const areMissionsFiltered: boolean = selectedMissions.length > 0;
  const selectedMissionsIds: string[] = _.map(
    selectedMissions,
    (mission) => mission.id
  );

  const { enqueueSnackbar } = useSnackbar();

  const { loading, data } = useActivitiesTrimesterProfitsQuery({
    variables: {
      year: currentYear.year(),
      needClientData: isControl || isComex || isSuperuser,
      needSectorData: isControl || isComex || isSuperuser,
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const polyconseilClient = useGetpolyconseilClientQuery({
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  }).data?.polyconseilClient;

  const nonInitExternalMissionsClient =
    useGetNonInitExternalMissionsClientQuery({
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
    }).data?.nonInitExternalMissionsClient;

  const activities = data?.activitiesTrimesterProfits as ActivityNode[];

  const profitsByClient: ClientType[] = useMemo(() => {
    if (
      !data ||
      !polyconseilClient ||
      !nonInitExternalMissionsClient ||
      !data.clientsTrimesterProfits
    ) {
      return [];
    }
    return transformProfitsByClient(
      activities,
      data?.clientsTrimesterProfits as BillingClientNode[],
      polyconseilClient as BillingClientNode,
      nonInitExternalMissionsClient as BillingClientNode
    );
  }, [data, activities, polyconseilClient, nonInitExternalMissionsClient]);

  const filteredMissions = (
    areMissionsFiltered && activities
      ? _.filter(activities, (activity) =>
          _.includes(selectedMissionsIds, activity.id)
        )
      : []
  ) as ActivityNode[];

  return (
    <div>
      {!data || loading ? (
        <LoadingPlaceholder />
      ) : (
        <TableHeaderContextProvider
          currentYear={currentYear}
          setCurrentYear={setCurrentYear}
          monthRangeSize={6}
          monthOffset={-4}
        >
          <FlatTable size={'small'}>
            <ProfitabilityTrimesterTableHeader />
            <ProfitabilityTrimesterTableBody
              currentYear={currentYear}
              profitsBySectors={data?.sectorsTrimesterProfits as SectorNode[]}
              activityProfitsByClients={profitsByClient}
              activities={areMissionsFiltered ? filteredMissions : activities}
              polyconseilClient={polyconseilClient as BillingClientNode}
              nonInitExternalMissionsClient={
                nonInitExternalMissionsClient as BillingClientNode
              }
              filteredMission={areMissionsFiltered}
            />
            {!areMissionsFiltered && (isControl || isComex || isSuperuser) && (
              <ProfitabilityTableFooterSectors
                profitsBySectors={data?.sectorsTrimesterProfits as SectorNode[]}
                isTrimesterView={true}
              />
            )}
            {(areMissionsFiltered ||
              (!areMissionsFiltered &&
                !(isControl || isComex || isSuperuser))) &&
              (areMissionsFiltered
                ? filteredMissions.length > 1
                : activities.length > 1) && (
                <ProfitabilityTableFooterMissions
                  profitsByMissions={
                    areMissionsFiltered ? filteredMissions : activities
                  }
                  isTrimesterView={true}
                />
              )}
          </FlatTable>
        </TableHeaderContextProvider>
      )}
    </div>
  );
}
