import { getModalityName } from 'components/MissionFollowUp/BillDetail/BillDetail/utils';
import LabelTextFieldGrid from 'components/MissionFollowUp/LabelTextFieldGrid';
import { requiredForm } from 'pages/MissionFollowUp/formValidators';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Modality } from '../Types';

interface ModalityUploadProps {
  index: number;
  modality: Modality;
}

export default function ModalityUpload(properties: ModalityUploadProps) {
  const form = useFormContext();

  return (
    <>
      <LabelTextFieldGrid
        inputRef={form.register({ required: requiredForm })}
        name={getModalityName(properties.index, 'billingUrl')}
        defaultValue={`${properties.modality.billingUrl || ''}`}
        customspacingtop={0}
        sizegrid={6}
        errorform={
          form.errors.billingModalities?.[properties.index]?.billingUrl
        }
        title="URL"
        required={true}
      />
      <LabelTextFieldGrid
        inputRef={form.register({ required: requiredForm })}
        name={getModalityName(properties.index, 'billingCode')}
        defaultValue={`${properties.modality.billingCode || ''}`}
        customspacingtop={0}
        customspacingside={0}
        sizegrid={6}
        error={
          !!(
            form.errors.billingModalities &&
            form.errors.billingModalities[properties.index]?.billingCode
          )
        }
        errorform={
          form.errors.billingModalities?.[properties.index]?.billingCode
        }
        title="Code du service exécutant"
        required={true}
      />
    </>
  );
}
