import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Box,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';

interface ActivityMonthPickerProps {
  viewMonth: string;
  incrementPeriod: () => void;
  decrementPeriod: () => void;
}

const ActivityMonthPicker = ({
  viewMonth,
  incrementPeriod,
  decrementPeriod,
}: ActivityMonthPickerProps) => {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        height: isPhone ? 60 : 70,
        backgroundColor: 'primary.light',
        borderRadius: isPhone ? '8px' : 0,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: '75%', maxWidth: 400 }}
      >
        <IconButton
          aria-label={'Précédent'}
          onClick={decrementPeriod}
          size="large"
        >
          <KeyboardArrowLeftIcon color="primary" />
        </IconButton>
        <Typography
          variant="bodyBold"
          sx={{
            textTransform: 'capitalize',
          }}
        >
          {viewMonth}
        </Typography>
        <IconButton
          aria-label={'Suivant'}
          onClick={incrementPeriod}
          size="large"
        >
          <KeyboardArrowRightIcon color="primary" />
        </IconButton>
      </Stack>
    </Box>
  );
};

export default ActivityMonthPicker;
