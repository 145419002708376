import { TableHead, TableRow, Typography } from '@mui/material';
import { stickyHeaderSx } from 'components/commons/SX/commonSx';
import { BorderedTableCell } from 'components/commons/Tables/FlatTable';
import ArrowsUpdateMonthsTableHeader from 'components/commons/Tables/Header/ArrowsUpdateMonthsTableHeader';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import _ from 'lodash';
import moment from 'moment';
import React, { PropsWithChildren } from 'react';

import { enableMonthDecrement, enableMonthIncrement } from '../utils';
import { ProfitabilityMissionViewTableHeaderColumnsNames } from './ProfitabilityMissionViewTableHeaderColumnsNames';

interface ProfitabilityMissionViewMonthTableHeaderProperties {
  setCurrentYear?: (newYear: moment.Moment) => void;
  setIsYearSubtracted?: (changeYear: boolean) => void;
  setIsYearAdded?: (changeYear: boolean) => void;
}

export default function ProfitabilityMissionViewMonthTableHeader({
  setCurrentYear,
  setIsYearSubtracted,
  setIsYearAdded,
}: PropsWithChildren<ProfitabilityMissionViewMonthTableHeaderProperties>) {
  const { displayedMonths } = useTableHeaderContext();
  return (
    <TableHead sx={stickyHeaderSx}>
      <TableRow>
        <BorderedTableCell sx={{ width: 200 }} />
        {_.map(displayedMonths, (month) => {
          return (
            <BorderedTableCell
              sx={{ textTransform: 'capitalize', p: 0 }}
              key={`header-${month.format('MMM')}`}
              colSpan={3}
            >
              <ArrowsUpdateMonthsTableHeader
                month={month}
                setIsYearSubtracted={setIsYearSubtracted}
                setIsYearAdded={setIsYearAdded}
                setCurrentYear={setCurrentYear}
                enableMonthIncrement={enableMonthIncrement(month)}
                enableMonthDecrement={enableMonthDecrement(month)}
              >
                <Typography
                  fontWeight={
                    month.isSame(moment(), 'month') ? 'bold' : 'normal'
                  }
                >
                  {month.format('MMM')}
                </Typography>
              </ArrowsUpdateMonthsTableHeader>
            </BorderedTableCell>
          );
        })}
      </TableRow>
      <ProfitabilityMissionViewTableHeaderColumnsNames
        monthsToDisplay={displayedMonths}
      />
    </TableHead>
  );
}
