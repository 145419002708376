import InfoIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Grid,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  useTheme,
} from '@mui/material';
import { stickyHeaderSx } from 'components/commons/SX/commonSx';
import NavigationButton from 'components/Revenue/utils/NavigationButton';
import {
  ActivitiesActivityBillingTypeChoices,
  BillingBillStatusChoices,
  PurchaseOrderNode,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';

import { useTableContext } from './context/TableContextProvider';

const tableCellSx = { bb: '0px solid', p: 0, height: '30px' };
interface PurchaseOrderRevenue {
  totals: { total: number; month: moment.Moment; toBillCount: number }[];
}

const RevenueTableHeader = () => {
  const {
    displayedMonths,
    incrementMonth,
    decrementMonth,
    purchaseOrders,
    activity,
  } = useTableContext();

  const { palette } = useTheme();

  const yearsSet = new Set();
  _.forEach(displayedMonths, (month) => yearsSet.add(month.format('YYYY')));
  const years = [...yearsSet];

  const transformPurchaseOrder = useCallback(
    (po: PurchaseOrderNode): PurchaseOrderRevenue => {
      const totals = _.map(displayedMonths, (month) => {
        const totalPoForMonth = _.sumBy(po.bills, (bill) => {
          if (BillingBillStatusChoices.ToBill !== bill.status) {
            return 0;
          }
          const date = moment(bill.date);
          return month.isSame(date, 'month') ? bill.total : 0;
        });
        const nbrBillsToBill = _.sumBy(po.bills, (bill) => {
          if (BillingBillStatusChoices.ToBill !== bill.status) {
            return 0;
          }
          const date = moment(bill.date);
          return month.isSame(date, 'month') ? 1 : 0;
        });
        return {
          total: totalPoForMonth,
          month: month,
          toBillCount: nbrBillsToBill,
        };
      });

      return {
        totals: totals,
      };
    },
    [displayedMonths]
  );
  const purchaseOrdersRevenues = useMemo(() => {
    return _.map(purchaseOrders, transformPurchaseOrder);
  }, [purchaseOrders, transformPurchaseOrder]);

  const totalColumnLabel =
    activity?.billingType === ActivitiesActivityBillingTypeChoices.Tm
      ? 'Montant Max (HT)'
      : 'Montant (HT)';

  return (
    <TableHead sx={stickyHeaderSx}>
      <TableRow sx={tableCellSx}>
        <TableCell sx={{ width: '185px', ...tableCellSx }} />
        <TableCell sx={tableCellSx} />
        <TableCell colSpan={6} sx={tableCellSx}>
          <Box sx={{ textAlign: 'center' }}>
            {years[0]}
            {years.length > 1 && ` - ${years[1]}`}
          </Box>
        </TableCell>
      </TableRow>
      <TableRow sx={tableCellSx}>
        <TableCell
          sx={{
            width: '185px',
            p: 0,
            position: 'relative',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          Bon de commande (BDC)
        </TableCell>
        <TableCell
          sx={{
            p: 0,
            position: 'relative',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          {totalColumnLabel}
        </TableCell>
        {_.map(displayedMonths, (month) => {
          let billsCount = 0;
          let sumOfBills = 0;

          _.each(purchaseOrdersRevenues, (revenue) => {
            _.each(revenue.totals, (billData) => {
              if (billData.month === month) {
                billsCount += billData.toBillCount;
                sumOfBills += billData.total;
              }
            });
          });
          const tooltipContent = (
            <span>
              Il reste {billsCount} facture{billsCount > 1 ? 's' : ''} à
              facturer sur ce mois d&apos;une valeur totale de{' '}
              {sumOfBills.toFixed(2)} €
            </span>
          );

          return (
            <TableCell
              sx={{
                textTransform: 'capitalize',
                p: 0,
                position: 'relative',
                textAlign: 'center',
                fontWeight: 'bold',
              }}
              style={{
                ...(month.isSame(moment(), 'month') && {
                  color: palette.primary.main,
                  boxShadow: `inset 0 0 ${palette.primary.main}, inset 0 -4px ${palette.primary.main}`,
                }),
              }}
              key={`header-${month.format('MMM')}`}
            >
              <Grid container direction={'row'} alignItems={'center'}>
                <Grid item xs={2}>
                  {_.first(displayedMonths) === month && (
                    <NavigationButton
                      navigation={() => decrementMonth()}
                      type={'before'}
                    />
                  )}
                </Grid>
                <Grid item xs={8} justifyContent={'center'} display={'flex'}>
                  <Box
                    sx={{
                      position: 'relative',
                      display: 'flex',
                      justifyContent: 'center',
                      height: '100%',
                    }}
                  >
                    {month.format('MMM')}
                  </Box>
                  {billsCount > 0 && (
                    <Tooltip title={tooltipContent} placement={'right'}>
                      <InfoIcon
                        sx={{
                          mr: 1,
                          color: 'warning',
                          position: 'absolute',
                          left: '65%',
                          justifyContent: 'center',
                        }}
                      />
                    </Tooltip>
                  )}
                </Grid>
                <Grid item xs={2} display={'flex'} justifyContent={'end'}>
                  {_.last(displayedMonths) === month && (
                    <NavigationButton
                      navigation={() => incrementMonth()}
                      type={'next'}
                    />
                  )}
                </Grid>
              </Grid>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default RevenueTableHeader;
