import { Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import { DateRangePicker } from 'components/commons/DateRangePicker';
import AddItem from 'components/commons/ItemInteractions/AddItem/AddItem';
import DeleteItem from 'components/commons/ItemInteractions/DeleteItem/DeleteItem';
import LabelTextFieldGrid from 'components/MissionFollowUp/LabelTextFieldGrid';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import { ActivityNode } from 'generated/graphql';
import _, { isEmpty } from 'lodash';
import moment from 'moment';
import { requiredForm } from 'pages/MissionFollowUp/formValidators';
import React, { useEffect } from 'react';
import { UseFieldArrayMethods, useFormContext } from 'react-hook-form';

import MissionToggles from './MissionToggles';

type SubMissionsFormProps = Partial<
  Pick<UseFieldArrayMethods, 'fields' | 'append' | 'remove'>
> & { mission?: ActivityNode };

export default function SubMissionsForm(properties: SubMissionsFormProps) {
  const form = useFormContext();
  const mission = isEmpty(properties.mission)
    ? (form.getValues() as ActivityNode)
    : properties.mission;
  const activityTogglesFeatureFlag = useEnvironmentVariable('activity_toggles');
  function addSubMission() {
    if (properties.append) {
      if (properties.fields?.length === 0) {
        properties.append({
          name: '',
          period: [
            moment(mission.startDate).toDate(),
            moment(mission.expirationDate).toDate(),
          ],
        });
      }
      properties.append({
        name: '',
        period: [
          moment(mission.startDate).toDate(),
          moment(mission.expirationDate).toDate(),
        ],
      });
    }
    form.clearErrors();
  }

  function removeSubMission(index: number) {
    if (properties.remove) {
      properties.remove(index);
    }
  }

  useEffect(() => {
    if (
      properties.fields?.length === 1 &&
      !form.errors.subActivities?.[0]?.name
    ) {
      form.setError('subActivities.0.name', {
        type: 'SubActivitiesNumber',
        message: `Il n'est pas possible d'ajouter qu'une seule sous mission`,
      });
    }
  }, [properties.fields, form]);

  useEffect(() => {
    _.forEach(properties.fields, (subActivity, index) => {
      form.register(`subActivities.${index}.period`);
    });
  });

  function updateRangePickerValue(value: [Date, Date], index: number) {
    form.setValue(`subActivities.${index}.period`, value);
  }

  return (
    <>
      {activityTogglesFeatureFlag && !!properties.fields?.length && (
        <MissionToggles mission={mission} />
      )}
      {_.map(properties.fields, (subActivity, index) => {
        return (
          <Grid
            key={`submission-${index}-${subActivity.key}`}
            container
            sx={{
              border: '2px solid',
              borderColor: 'standardGrey.dark',
              borderRadius: '10px',
              py: 2.5,
              px: 1.5,
              my: 2.5,
            }}
          >
            <Grid item xs={5}>
              <LabelTextFieldGrid
                name={`subActivities.${index}.name`}
                sizegrid={11.5}
                defaultValue={subActivity.name}
                inputRef={form.register({ required: requiredForm })}
                errorform={form.errors.subActivities?.[index]?.name}
                title={`Sous-mission ${index + 1}`}
                required={true}
                customspacingside={0}
                customspacingtop={0}
              />
            </Grid>

            <Grid item xs={6.5}>
              <DateRangePicker
                dateRange={[
                  subActivity.period?.[0] || moment(mission.startDate).toDate(),
                  subActivity.period?.[1] ||
                    moment(mission.expirationDate).toDate(),
                ]}
                handleDateRangeChange={(value) =>
                  updateRangePickerValue(value, index)
                }
                minDate={moment(mission.startDate).toDate()}
                maxDate={moment(mission.expirationDate).toDate()}
              />
            </Grid>

            <Grid item xs={0.5}>
              <DeleteItem
                onClick={() => removeSubMission(index)}
                sx={{ mt: '3px', ml: 0.75 }}
                disableWrapStyle
              />
            </Grid>
          </Grid>
        );
      })}
      <Stack pt={properties.fields?.length === 0 ? 2 : 0}>
        <AddItem
          onClick={addSubMission}
          description={'Nouvelle sous-mission'}
        />
      </Stack>
    </>
  );
}
