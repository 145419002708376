import { TableRow } from '@mui/material';
import clsx from 'clsx';
import { getDaysToWorkInCurrentWeek } from 'components/ActivityMonitoring/ActivityMonitoringTable/utils';
import CollapsePolyTableCell from 'components/MUIOverload/PolyTableCell/CollapsePolyTableCell';
import TransitionPolyTableRow from 'components/MUIOverload/PolyTableRow/TransitionPolyTableRow';
import { useTableContext } from 'contexts/ActivityMonitoringTable/TableContextProvider';
import { EmployeeNode } from 'generated/graphql';
import _ from 'lodash';
import { Moment } from 'moment';
import React, { useState } from 'react';
import cellStyles from 'styles/ActivityPage/Cell.module.scss';

import styles from '../../../styles/TableCell.module.scss';
import DisplayValueTableCell from '../../DisplayValueTableCell';
import { FormattedMission } from '../utils';
import MissionRow from './MissionRow';
import {
  CellEmployeeNotHiredOnWeek,
  checkWeekError,
  getCellStyleError,
  getCurrentWeekPartTimeSpent,
  isEmployeeHiredOnWeek,
  TypographyDisplayWeekValue,
} from './utils';

interface MissionRowProp {
  sharedMissions: FormattedMission[];
  employee?: EmployeeNode;
  headAndTailMissionName: { head: string; tail: string };
  setOpen: (open: boolean) => void;
  open: boolean;
}

export default function SharedMissionRow({
  sharedMissions,
  employee,
  headAndTailMissionName,
  setOpen,
  open,
}: MissionRowProp) {
  const { displayedWeeks, filterMissionsId } = useTableContext();
  const [openCollapse, setOpenCollapse] = useState(false);

  const getTotalCellForWeek = (
    isOnError: boolean,
    week: Moment,
    totalWeek: number
  ) => {
    return (
      <DisplayValueTableCell
        warning={false}
        key={`row-team${week}`}
        className={clsx(
          styles.commonCell,
          isOnError && !open
            ? getCellStyleError(
                !!_.find(
                  sharedMissions,
                  (mission) => mission.name === headAndTailMissionName.head
                ),
                !!_.find(
                  sharedMissions,
                  (mission) => mission.name === headAndTailMissionName.tail
                )
              )
            : '',
          {
            [cellStyles.isInvalid]: open && isOnError,
          }
        )}
        align={'left'}
        {...(isOnError && {
          tooltipTitle: `Le collaborateur a une saisie incomplète. Jours travaillés attendus : ${getDaysToWorkInCurrentWeek(
            week,
            employee
          )}`,
        })}
      >
        <TypographyDisplayWeekValue weekValue={totalWeek} />
      </DisplayValueTableCell>
    );
  };

  let total = 0;
  return (
    <React.Fragment>
      <TableRow>
        <CollapsePolyTableCell
          open={openCollapse}
          setOpen={setOpenCollapse}
          className={styles.missionCell}
          sx={{ p: 1 }}
        >
          Activités hors missions
        </CollapsePolyTableCell>
        {_.map(displayedWeeks, (displayedWeek) => {
          if (!isEmployeeHiredOnWeek(employee, displayedWeek)) {
            return (
              <CellEmployeeNotHiredOnWeek
                key={'week-' + displayedWeek}
                week={displayedWeek}
              />
            );
          }

          const isOnError =
            _.isEmpty(filterMissionsId) &&
            checkWeekError(employee, displayedWeek);

          const totalWeek = _.reduce(
            sharedMissions,
            (accumulator, mission) => {
              const currentWeekPartTimeSpent = getCurrentWeekPartTimeSpent(
                mission,
                displayedWeek
              );
              return accumulator + currentWeekPartTimeSpent;
            },
            0
          );
          total += totalWeek;
          return getTotalCellForWeek(isOnError, displayedWeek, totalWeek);
        })}
        <DisplayValueTableCell warning={false} className={styles.totalCell}>
          {total}
        </DisplayValueTableCell>
      </TableRow>
      <TransitionPolyTableRow open={openCollapse}>
        {(status) => {
          if (status === 'exited' && open !== openCollapse) {
            setOpen(openCollapse);
          }
          return (
            <React.Fragment>
              {_.map(sharedMissions, (mission) => {
                return (
                  <MissionRow
                    key={`missionrow-${mission.id}-${mission.name}`}
                    missionDetail={mission}
                    employee={employee}
                    headAndTailMissionName={headAndTailMissionName}
                    status={status}
                  />
                );
              })}
            </React.Fragment>
          );
        }}
      </TransitionPolyTableRow>
    </React.Fragment>
  );
}
