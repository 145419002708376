import { TableBody } from '@mui/material';
import _ from 'lodash';
import { ADRByRole } from 'pages/ReportingPage/OccupationAdr/OccupationAdrPage';
import React from 'react';

import { useAdrContext } from '../../AdrContextProvider';
import OccupationAdrTableRow from './OccupationAdrTableRow';

interface OccupationAdrTableBodyProps {
  allEmployeeReportingByRole: ADRByRole[];
  refetch: () => void;
}

export default function OccupationAdrTableBody({
  allEmployeeReportingByRole,
  refetch,
}: OccupationAdrTableBodyProps) {
  const { isEdit } = useAdrContext();

  return (
    <TableBody>
      {_.map(allEmployeeReportingByRole, (adrByRole) => {
        return (
          <OccupationAdrTableRow
            adrByRole={adrByRole}
            isEdit={isEdit}
            refetch={refetch}
            key={`row-${adrByRole.occupation}`}
          />
        );
      })}
    </TableBody>
  );
}
