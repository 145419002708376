import { Box, ListItem, Skeleton, Stack, useTheme } from '@mui/material';
import React from 'react';

interface SearchBarItemSkeletonProps {
  props: React.HTMLAttributes<HTMLLIElement>;
  style: React.CSSProperties;
}

export default function PolyTrombiSearchBarItemSkeleton({
  props,
  style,
}: SearchBarItemSkeletonProps) {
  const theme = useTheme();

  return (
    <ListItem
      {...props}
      sx={{
        mx: 2,
        my: 1,
        width: 'fill-available',
        borderRadius: 1,
        position: 'absolute',
        top: `${style.top}px`,
      }}
    >
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Box
          sx={{
            borderRadius: '50%',
            backgroundColor: theme.palette.lightGrey.main,
          }}
        >
          <Skeleton variant={'circular'} width={40} height={40} />
        </Box>
        <Stack>
          <Skeleton
            variant={'rectangular'}
            width={200}
            height={12}
            sx={{ mb: 1 }}
          />
          <Skeleton variant={'rectangular'} width={180} height={12} />
        </Stack>
      </Stack>
    </ListItem>
  );
}
