import InfoTooltip from 'components/commons/InfoTooltip';
import BillActionModal from 'components/MissionFollowUp/BillDetail/Modals/BillActionModal/BillActionModal';
import LabelTextFieldGrid from 'components/MissionFollowUp/LabelTextFieldGrid';
import {
  BillingBillStatusChoices,
  BillNode,
  useUpdateBillStatusMutation,
} from 'generated/graphql';
import { useSnackbar } from 'notistack';
import { requiredForm } from 'pages/MissionFollowUp/formValidators';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { setCurrentBill } from 'store/purchaseOrder';

interface AskAnnulationModalProps {
  open: boolean;
  closeModal: () => void;
}

const AskAnnulationModal = (properties: AskAnnulationModalProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const currentBill = useSelector((state) => state.purchaseOrder.currentBill);
  const form = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });
  const [updateStatus] = useUpdateBillStatusMutation({
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    onCompleted: (data) => {
      const bill = data.updateBillStatus?.bills?.[0] as BillNode;
      if (bill) {
        dispatch(setCurrentBill(bill));
      }
      properties.closeModal();
      enqueueSnackbar(
        `La demande d'annulation de la facture ${currentBill.title} a bien été transmise`,
        {
          variant: 'success',
        }
      );
    },
  });
  const onSubmit = async (cancellationCommentary: string) => {
    await updateStatus({
      variables: {
        bills: [
          {
            id: currentBill.id,
          },
        ],
        status: BillingBillStatusChoices.ToCancel,
        cancellationCommentary: cancellationCommentary,
      },
    });
  };
  return (
    <BillActionModal
      open={properties.open}
      title={"Demande d'annulation"}
      closeModal={properties.closeModal}
      validateModal={() => {
        form.handleSubmit((d) => onSubmit(d.cancellationCommentary))();
      }}
      bodyModal={
        <>
          <LabelTextFieldGrid
            title={'Commentaire justifiant la demande'}
            name={'cancellationCommentary'}
            required
            multiline
            rows={4}
            sizegrid={12}
            customspacingtop={0}
            customspacingside={0}
            sx={{ pb: 2.5 }}
            inputRef={form.register({ required: requiredForm })}
            errorform={form.errors.cancellationCommentary}
            customElementInTitle={
              <InfoTooltip
                style={{ pointerEvents: 'auto' }}
                title={
                  "Veuillez entrer un commentaire justifiant votre demande d'annulation"
                }
              />
            }
          />
          {
            "Souhaitez-vous que cette facture soit annulée ? Une demande d'annulation sera transmise au service comptable."
          }
        </>
      }
    />
  );
};

export default AskAnnulationModal;
