import { Avatar, Chip, ChipProps, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';

interface Props extends ChipProps {
  label: string;
  level?: number;
  onClickDisplayMenu?: boolean;
  OnClick?: (newLevel?: number) => void;
  onDelete?: () => void;
}

const menuOptions = [
  { level: 1, levelLabel: 'Junior' },
  { level: 2, levelLabel: 'Confirmé' },
  { level: 3, levelLabel: 'Senior' },
];

const SkillChip = ({
  label,
  level,
  onClickDisplayMenu,
  OnClick,
  onDelete,
  ...props
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isEditable = !!OnClick;
  const handleChipClick = (event: React.MouseEvent<HTMLElement>) => {
    onClickDisplayMenu && setAnchorEl(event.currentTarget);
    !onClickDisplayMenu && OnClick && OnClick();
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (
    _event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    OnClick && OnClick(index + 1);
    handleMenuClose();
  };

  return (
    <>
      <Chip
        {...props}
        avatar={level ? <Avatar>{level}</Avatar> : undefined}
        label={label}
        size="small"
        onClick={isEditable ? handleChipClick : undefined}
        onDelete={onDelete}
        sx={{
          backgroundColor: 'info.light',
          color: 'info.main',
          '.MuiChip-avatar': {
            backgroundColor: 'standardGrey.main',
            color: 'info.main',
          },
        }}
      />
      {onClickDisplayMenu && (
        <Menu
          id={`${label}-skill-menu`}
          key={`${label}-skill-menu`}
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
        >
          {menuOptions.map((option, index) => (
            <MenuItem
              key={`${label}-skill-menu-level-${option.level}`}
              value={option.level}
              selected={option.level === level}
              onClick={(event) => handleMenuItemClick(event, index)}
              sx={{
                color: 'info.main',
                fontSize: 12,
                gap: 1,
              }}
            >
              <Avatar
                sx={{
                  width: 18,
                  height: 18,
                  backgroundColor: 'info.main',
                  color: 'white',
                  fontSize: 10,
                }}
              >
                {option.level}
              </Avatar>
              {option.levelLabel}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

export default SkillChip;
