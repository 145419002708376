import { EventNode } from 'generated/graphql';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

export interface PhonebookContextProps {
  allEvents: EventNode[];
}

export interface CarouselContext {
  filterList: string[];
  setFilterList: (param: string[]) => void;
  allEvents: EventNode[];
  filteredEvents: EventNode[];
  setFilteredEvents: (param: EventNode[]) => void;
}

export const CarouselContext = createContext<CarouselContext>({
  filterList: [],
  setFilterList: () => {
    throw new Error('function setFilterList not set');
  },
  allEvents: [],
  filteredEvents: [],
  setFilteredEvents: () => {
    throw new Error('function setFilteredEvents not set');
  },
});

export const useCarouselContext = () => {
  const carouselContext = useContext(CarouselContext);
  if (!carouselContext) {
    throw new Error('useContext() can only be used  inside a table');
  }
  return carouselContext;
};

const CarouselContextProvider = (
  properties: PropsWithChildren<PhonebookContextProps>
) => {
  const [filterList, setFilterList] = useState<string[]>([]);
  const [filteredEvents, setFilteredEvents] = useState<EventNode[]>(
    properties.allEvents
  );

  return (
    <CarouselContext.Provider
      value={{
        filterList,
        setFilterList,
        filteredEvents,
        setFilteredEvents,
        ...properties,
      }}
    >
      {properties.children}
    </CarouselContext.Provider>
  );
};

export default CarouselContextProvider;
