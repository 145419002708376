import { TableCell, Tooltip } from '@mui/material';
import styles from 'components/Revenue/RevenueGlobalTable/styles/TableCell.module.scss';
import React from 'react';

export default function ProfitabilityNotAssignedCell() {
  return (
    <Tooltip
      title="Le collaborateur n'est pas assigné sur la mission sur cette période"
      arrow
    >
      <TableCell
        align={'center'}
        colSpan={3}
        className={styles.invalidCell}
      ></TableCell>
    </Tooltip>
  );
}
