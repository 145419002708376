import {
  Chip,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import PolyDialog from 'components/MUIOverload/PolyDialog';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import React, { ReactElement } from 'react';

import { EventDaysProps } from './EventCard';
import { formatDateRange } from './utils';

export default function EventModal({
  open,
  onClose,
  tag,
  title,
  startDate,
  startTime,
  endDate,
  endTime,
  description,
}: EventDaysProps & DialogProps): ReactElement {
  const formattedDateRange = formatDateRange(
    startDate,
    startTime,
    endDate,
    endTime
  );

  const cleanHTML = DOMPurify.sanitize(description || '', {
    USE_PROFILES: { html: true },
  });

  return (
    <PolyDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography variant="h6" fontWeight="bold" color="primary">
          Détail de l&apos;évènement
        </Typography>

        <Stack direction="row" flexWrap="wrap">
          <Typography variant="h5">{title}</Typography>
          <Stack direction="row" ml="auto" spacing={1}>
            <Typography variant="h5">{formattedDateRange}</Typography>
            <Chip label={tag} variant="outlined" />
          </Stack>
        </Stack>
      </DialogTitle>

      {description && (
        <DialogContent sx={{ mb: 1 }}>
          <DialogContentText whiteSpace="pre-wrap">
            {parse(cleanHTML)}
          </DialogContentText>
        </DialogContent>
      )}
    </PolyDialog>
  );
}
