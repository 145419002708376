const MISSION_FOLLOW_UP_URL_PREFIX = '/mission-followup';
export const DRAWER_TRANSITION_DURATION = 500;
export const TRANSITION_STYLE = `${DRAWER_TRANSITION_DURATION}ms cubic-bezier(0.4, 0, 0.2, 1)`;
export interface DrawerStates {
  home: boolean;
  phonebook: boolean;
  applications: boolean;
  myActivity: boolean;
  myAbsences: boolean;
  myActivityReporting: boolean;
  myExceptionalActivities: boolean;
  employees: boolean;
  activities: boolean;
  clients: boolean;
  dashboard: boolean;
  activity: boolean;
  collaborators: boolean;
  activityMonitoring: boolean;
  exeptionalActivities: boolean;
  revenue: boolean;
  actualRevenue: boolean;
  estimatedRevenue: boolean;
  billedRevenue: boolean;
  billing: boolean;
  billingFollowUp: boolean;
  purchaseOrders: boolean;
  paymentFollowUp: boolean;
  reporting: boolean;
  profitability: boolean;
  analysis: boolean;
  sectors: boolean;
}

interface ToggleStates {
  myActivity: boolean;
  activity: boolean;
  revenue: boolean;
  billing: boolean;
  reporting: boolean;
}

export enum DrawerTypes {
  USER,
  HOME,
  PHONEBOOK,
  MY_ACTIVITY,
  MY_ACTIVITY_REPORTING,
  MY_ABSENCES,
  MY_EXCEPTIONAL_ACTIVITIES,
  APPLICATIONS,
  DASHBOARD,
  ACTIVITY,
  COLLABORATORS,
  ACTIVITY_MONITORING,
  EXCEPTIONAL_ACTIVITIES,
  REVENUE,
  ACTUAL_REVENUE,
  ESTIMATED_REVENUE,
  BILLED_REVENUE,
  BILLING,
  BILLING_FOLLOW_UP,
  CLIENTS,
  REPORTING,
  PROFITABILITY,
  ANALYSIS,
  PURCHASE_ORDER,
  PAYMENT_FOLLOW_UP,
  SECTORS,
  EMPLOYEES,
  ACTIVITIES,
}

export enum ToggleCollapseTypes {
  MY_ACTIVITY,
  ACTIVITY,
  REVENUE,
  BILLING,
  REPORTING,
  UNDEFINED,
}

export function getUrlList() {
  return {
    user: '/user',
    home: '/home',
    phonebook: '/phonebook',
    applications: '/applications',
    myActivityReporting: '/my-activity/working-days',
    myAbsences: '/my-activity/absences',
    myExceptionalActivities:
      '/my-activity/exceptional-activities/my_authorization',
    employees: '/employees',
    activities: '/activities',
    clients: '/clients',
    dashboard: MISSION_FOLLOW_UP_URL_PREFIX + '/dashboard',
    collaborators: MISSION_FOLLOW_UP_URL_PREFIX + '/activity/collaborators',
    activityMonitoring:
      MISSION_FOLLOW_UP_URL_PREFIX + '/activity/validate_activity_monitoring',
    exceptionalActivities:
      MISSION_FOLLOW_UP_URL_PREFIX +
      '/activity/exceptional-activities/authorization',
    estimatedRevenue: MISSION_FOLLOW_UP_URL_PREFIX + '/revenue/estimated',
    actualRevenue: MISSION_FOLLOW_UP_URL_PREFIX + '/revenue/actual',
    billedRevenue: MISSION_FOLLOW_UP_URL_PREFIX + '/revenue/billed',
    purchaseOrders: MISSION_FOLLOW_UP_URL_PREFIX + '/missions/purchase-orders',
    billingFollowUp:
      MISSION_FOLLOW_UP_URL_PREFIX + '/missions/billing-followup',
    paymentFollowUp:
      MISSION_FOLLOW_UP_URL_PREFIX + '/missions/payments-followup',
    purchaseOrderDetail:
      MISSION_FOLLOW_UP_URL_PREFIX + '/missions/details/purchase_order',
    billDetail: MISSION_FOLLOW_UP_URL_PREFIX + '/missions/details/bill',
    sectors: MISSION_FOLLOW_UP_URL_PREFIX + '/missions/client-sectors',
    profitability: MISSION_FOLLOW_UP_URL_PREFIX + '/reporting/profitability',
    analysis: MISSION_FOLLOW_UP_URL_PREFIX + '/reporting/analysis',
  };
}

export const defaultDrawerStates: DrawerStates = {
  home: false,
  phonebook: false,
  applications: false,
  myActivity: false,
  myAbsences: false,
  myActivityReporting: false,
  myExceptionalActivities: false,
  employees: false,
  activities: false,
  clients: false,
  dashboard: false,
  activity: false,
  collaborators: false,
  activityMonitoring: false,
  exeptionalActivities: false,
  revenue: false,
  actualRevenue: false,
  estimatedRevenue: false,
  billedRevenue: false,
  billing: false,
  billingFollowUp: false,
  purchaseOrders: false,
  paymentFollowUp: false,
  reporting: false,
  profitability: false,
  analysis: false,
  sectors: false,
};

export enum DrawerTitles {
  home = 'Accueil',
  phonebook = 'Polytrombi',
  applications = 'Applications',
  myActivity = 'Mon activité',
  myAbsences = 'Mes absences',
  myActivityReporting = 'Déclaration',
  myExceptionalActivities = 'Activités exceptionnelles',
  employees = 'Collaborateurs',
  activities = 'Missions',
  clients = 'Clients',
  dashboard = 'Dashboard',
  activity = "Suivi d'activités",
  collaborators = 'Suivi temps équipes',
  activityMonitoring = 'Validation suivi régie',
  exeptionalActivities = 'Activités exceptionnelles',
  revenue = "Chiffre d'affaires",
  actualRevenue = 'Réalisé',
  estimatedRevenue = 'Prévisionnel',
  billedRevenue = 'Facturé',
  billing = 'Facturation',
  billingFollowUp = 'Suivi factures',
  purchaseOrders = 'Bons de commande',
  paymentFollowUp = 'Suivi paiements',
  reporting = 'Reporting',
  profitability = 'Rentabilité mission',
  analysis = 'Indicateurs clés',
  sectors = 'Gestion secteurs',
}

export const defaultToggleStates: ToggleStates = {
  myActivity: false,
  activity: false,
  revenue: false,
  billing: false,
  reporting: false,
};

export function drawersReducer(
  state: DrawerStates,
  action: DrawerTypes
): DrawerStates {
  switch (action) {
    case DrawerTypes.USER:
      return { ...defaultDrawerStates };
    case DrawerTypes.HOME:
      return { ...defaultDrawerStates, home: true };
    case DrawerTypes.PHONEBOOK:
      return { ...defaultDrawerStates, phonebook: true };
    case DrawerTypes.APPLICATIONS:
      return { ...defaultDrawerStates, applications: true };
    case DrawerTypes.MY_ACTIVITY:
      return { ...defaultDrawerStates, myActivity: true };
    case DrawerTypes.MY_ACTIVITY_REPORTING:
      return {
        ...defaultDrawerStates,
        myActivity: true,
        myActivityReporting: true,
      };
    case DrawerTypes.MY_ABSENCES:
      return {
        ...defaultDrawerStates,
        myActivity: true,
        myAbsences: true,
      };
    case DrawerTypes.MY_EXCEPTIONAL_ACTIVITIES:
      return {
        ...defaultDrawerStates,
        myActivity: true,
        myExceptionalActivities: true,
      };

    case DrawerTypes.EMPLOYEES:
      return { ...defaultDrawerStates, employees: true };
    case DrawerTypes.ACTIVITIES:
      return { ...defaultDrawerStates, activities: true };
    case DrawerTypes.CLIENTS:
      return { ...defaultDrawerStates, clients: true };
    case DrawerTypes.DASHBOARD:
      return { ...defaultDrawerStates, dashboard: true };
    case DrawerTypes.ACTIVITY:
      return { ...defaultDrawerStates, activity: true, collaborators: true };
    case DrawerTypes.COLLABORATORS:
      return { ...defaultDrawerStates, activity: true, collaborators: true };
    case DrawerTypes.ACTIVITY_MONITORING:
      return {
        ...defaultDrawerStates,
        activity: true,
        activityMonitoring: true,
      };
    case DrawerTypes.EXCEPTIONAL_ACTIVITIES:
      return {
        ...defaultDrawerStates,
        activity: true,
        exeptionalActivities: true,
      };
    case DrawerTypes.REVENUE:
      return { ...defaultDrawerStates, revenue: true, actualRevenue: true };
    case DrawerTypes.ACTUAL_REVENUE:
      return { ...defaultDrawerStates, revenue: true, actualRevenue: true };
    case DrawerTypes.ESTIMATED_REVENUE:
      return { ...defaultDrawerStates, revenue: true, estimatedRevenue: true };
    case DrawerTypes.BILLED_REVENUE:
      return { ...defaultDrawerStates, revenue: true, billedRevenue: true };
    case DrawerTypes.BILLING:
      return { ...defaultDrawerStates, billing: true, billingFollowUp: true };
    case DrawerTypes.BILLING_FOLLOW_UP:
      return { ...defaultDrawerStates, billing: true, billingFollowUp: true };
    case DrawerTypes.PURCHASE_ORDER:
      return { ...defaultDrawerStates, billing: true, purchaseOrders: true };
    case DrawerTypes.PAYMENT_FOLLOW_UP:
      return { ...defaultDrawerStates, billing: true, paymentFollowUp: true };
    case DrawerTypes.REPORTING:
      return { ...defaultDrawerStates, reporting: true, profitability: true };
    case DrawerTypes.PROFITABILITY:
      return { ...defaultDrawerStates, reporting: true, profitability: true };
    case DrawerTypes.ANALYSIS:
      return { ...defaultDrawerStates, reporting: true, analysis: true };
    case DrawerTypes.SECTORS:
      return { ...defaultDrawerStates, billing: true, sectors: true };
    default:
      throw new Error('Mission followup drawer error');
  }
}

export function toggleReducer(
  state: ToggleStates,
  action: ToggleCollapseTypes
): ToggleStates {
  switch (action) {
    case ToggleCollapseTypes.MY_ACTIVITY:
      return { ...defaultToggleStates, myActivity: !state.myActivity };
    case ToggleCollapseTypes.ACTIVITY:
      return { ...defaultToggleStates, activity: !state.activity };
    case ToggleCollapseTypes.REVENUE:
      return { ...defaultToggleStates, revenue: !state.revenue };
    case ToggleCollapseTypes.BILLING:
      return { ...defaultToggleStates, billing: !state.billing };
    case ToggleCollapseTypes.REPORTING:
      return { ...defaultToggleStates, reporting: !state.reporting };
    default:
      throw new Error('Mission followup toggle error');
  }
}
