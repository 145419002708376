import { Box, Paper } from '@mui/material';
import PongoButton from 'components/MUIOverload/PongoButton';
import { EmployeeNode } from 'generated/graphql';
import { Moment } from 'moment';
import React from 'react';

import GridItemHeader from './GridItemHeader';
import styles from './styles/HomeGridItem.module.scss';

interface HomeGridItemProps {
  title: string;
  content?: string | JSX.Element | JSX.Element[];
  creator?: EmployeeNode;
  date?: Moment;
  size?: number;
  headerIcon?: JSX.Element;
  hasButtonClose?: boolean;
  onClose?: () => void;
}

const HomeGridItem = (properties: HomeGridItemProps) => {
  return (
    <Paper
      elevation={2}
      className={styles.gridContainer}
      sx={{ backgroundColor: 'background.default' }}
    >
      <GridItemHeader
        title={properties.title}
        icon={properties.headerIcon}
        creator={properties.creator}
        date={properties.date}
      />
      <Box sx={{ mt: 2 }}>{properties.content}</Box>
      {properties.hasButtonClose && (
        <Box
          sx={{
            width: '100%',
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <PongoButton onClick={properties.onClose}>Fermer</PongoButton>
        </Box>
      )}
    </Paper>
  );
};

export default HomeGridItem;
