import {
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
  Stack,
  Typography,
} from '@mui/material';
import { DateRangePicker } from 'components/commons/DateRangePicker';
import FieldSelection from 'components/commons/FieldSelection';
import PolyDialog from 'components/MUIOverload/PolyDialog';
import PolyDialogTitle from 'components/MUIOverload/PolyDialogTitle';
import PongoButton from 'components/MUIOverload/PongoButton';
import { useTableContext } from 'contexts/ActivityMonitoringTable/TableContextProvider';
import { List_Field_Am_Export, useExportAmLazyQuery } from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { closeSnackbar, useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { downloadTriggered } from 'store/download';
import { graphQlDateFormatter } from 'utils';

const amExportFieldsCorrespondance = {
  [List_Field_Am_Export.Email]: 'Email',
  [List_Field_Am_Export.CollabName]: 'Nom Collaborateur',
  [List_Field_Am_Export.CollabFname]: 'Prénom Collaborateur',
  [List_Field_Am_Export.Mission]: 'Mission',
  [List_Field_Am_Export.SubMission]: 'Sous-mission',
  [List_Field_Am_Export.Contract]: 'Contrat',
  [List_Field_Am_Export.CollabPysId]: 'Collaborateur PYS ID',
  [List_Field_Am_Export.Matricule]: 'Matricule',
  [List_Field_Am_Export.MissionPysId]: 'Mission PYS ID',
  [List_Field_Am_Export.ClientName]: 'Nom du client',
  [List_Field_Am_Export.Week]: 'Semaine',
  [List_Field_Am_Export.DayStart]: 'Jour début',
  [List_Field_Am_Export.DayEnd]: 'Jour fin',
  [List_Field_Am_Export.Month]: 'Mois',
  [List_Field_Am_Export.Year]: 'Année',
  [List_Field_Am_Export.TimeDeclared]: 'Temps déclaré',
  [List_Field_Am_Export.Comment]: 'Commentaire',
};

interface ModalProps {
  isOpen: boolean;
  handleClose: () => void;
  title: string;
  body: string;
}

export default function ExportModal({
  isOpen,
  handleClose,
  title,
  body,
}: ModalProps) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    filterMissionsId,
    filterCollaboratorsId,
    displayedWeeks,
    filterLatecomers,
    filterProvider,
  } = useTableContext();
  const minDate = moment.min(displayedWeeks).clone();
  const maxDate = moment.max(displayedWeeks).clone();
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    minDate.year() < 2023
      ? minDate.startOf('week').toDate()
      : minDate.startOf('month').toDate(),
    maxDate.year() < 2023
      ? maxDate.day(5).toDate()
      : maxDate.endOf('month').toDate(),
  ]);

  const selectableFields = _.filter(
    Object.values(List_Field_Am_Export),
    (field) => field !== List_Field_Am_Export.SubMission
  );
  const [listFields, setListFields] =
    useState<List_Field_Am_Export[]>(selectableFields);
  const [exportQuery, { data }] = useExportAmLazyQuery({
    onCompleted: () => {
      if (data) {
        closeSnackbar();
        if (data.exportAm && data.exportAm[0].ready) {
          enqueueSnackbar('Téléchargement en cours', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar("Préparation de l'export", {
            variant: 'info',
            autoHideDuration: null,
          });
        }
      }
      handleClose();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
      handleClose();
    },
  });

  useEffect(() => {
    setDateRange([
      minDate.year() < 2023
        ? minDate.startOf('week').toDate()
        : minDate.startOf('month').toDate(),
      maxDate.year() < 2023
        ? maxDate.day(5).toDate()
        : maxDate.endOf('month').toDate(),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedWeeks]);

  const setWeekRange = (dates: [Date, Date]) => {
    setDateRange(dates);
  };

  const exportTable = () => {
    if (isOpen) {
      exportQuery({
        variables: {
          dateFrom: graphQlDateFormatter(dateRange[0]),
          dateTo: graphQlDateFormatter(dateRange[1]),
          missionsId: filterMissionsId,
          employeesId: filterCollaboratorsId,
          filterLateComers: filterLatecomers,
          filterProvider: filterProvider,
          listFields: listFields,
        },
      });
      dispatch(downloadTriggered());
    }
  };

  return (
    <PolyDialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEnforceFocus={true}
      maxWidth="md"
    >
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '600px',
        }}
      >
        <PolyDialogTitle>{title}</PolyDialogTitle>
        <DialogContentText id="alert-dialog-description" sx={{ mb: 2 }}>
          {body}
        </DialogContentText>

        <Stack
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Typography fontWeight="bold">Dates :</Typography>
          <Box width="400px">
            <DateRangePicker
              dateRange={dateRange}
              disableFuture={false}
              handleDateRangeChange={setWeekRange}
              externallyControlled
            />
          </Box>
        </Stack>

        <Stack
          alignItems="center"
          flexDirection="row"
          marginTop={2.5}
          justifyContent="space-between"
        >
          <Typography fontWeight="bold">Champs de données :</Typography>
          <Box width="400px">
            <FieldSelection
              listFields={listFields}
              setListFields={setListFields}
              selectableFields={selectableFields}
              fieldsCorrespondance={amExportFieldsCorrespondance}
            />
          </Box>
        </Stack>
      </DialogContent>

      <DialogActions>
        <PongoButton onClick={handleClose} color="primary">
          Annuler
        </PongoButton>
        <PongoButton
          variant={'contained'}
          color={'primary'}
          onClick={exportTable}
        >
          Télécharger
        </PongoButton>
      </DialogActions>
    </PolyDialog>
  );
}
