import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import HrIcon from '@mui/icons-material/LocalLibrary';
import PagesIcon from '@mui/icons-material/Pages';
import {
  Icon,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { RiCompass3Fill } from '@remixicon/react';
import { EmployeeOccupations, useUserInfo } from 'components/User/UserProvider';
import { ActivitiesActivityBillingTypeChoices } from 'generated/graphql';
import _ from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';

enum ActivityTypeMenuChoices {
  Int = 'Int',
  Cft = 'Cft',
  Package = 'Package',
  Tm = 'Tm',
  Hr = 'Hr',
}

interface NewActivityMenuProps {
  anchorEl: HTMLElement | null;
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
  setOpenNewInternalActivityModal: Dispatch<SetStateAction<boolean>>;
  setOpenNewExternalActivityModal: (
    type: ActivitiesActivityBillingTypeChoices
  ) => void;
  setOpenNewCftActivityModal: Dispatch<SetStateAction<boolean>>;
  setOpenNewHrActivityModal: Dispatch<SetStateAction<boolean>>;
}

export default function NewActivityMenu({
  anchorEl,
  setAnchorEl,
  setOpenNewInternalActivityModal,
  setOpenNewExternalActivityModal,
  setOpenNewCftActivityModal,
  setOpenNewHrActivityModal,
}: NewActivityMenuProps) {
  const newActivityMenuOpened = Boolean(anchorEl);

  const { employee, isComex, isSuperuser } = useUserInfo();

  const shouldDisplayHrActivities =
    employee?.occupation === EmployeeOccupations.HR || isComex || isSuperuser;

  const handleMenuItemClick = (value: ActivityTypeMenuChoices | undefined) => {
    if (value === ActivityTypeMenuChoices.Int) {
      setOpenNewInternalActivityModal(true);
    } else if (value === ActivityTypeMenuChoices.Cft) {
      setOpenNewCftActivityModal(true);
    } else if (value === ActivityTypeMenuChoices.Package) {
      setOpenNewExternalActivityModal(
        ActivitiesActivityBillingTypeChoices.Package
      );
    } else if (value === ActivityTypeMenuChoices.Tm) {
      setOpenNewExternalActivityModal(ActivitiesActivityBillingTypeChoices.Tm);
    } else if (value === ActivityTypeMenuChoices.Hr) {
      setOpenNewHrActivityModal(true);
    }

    handleCloseNewActivityMenu();
  };

  const handleCloseNewActivityMenu = () => {
    setAnchorEl(null);
  };

  const menuOptions = [
    {
      value: ActivityTypeMenuChoices.Int,
      icon: <PagesIcon />,
      label: 'Mission Interne',
      display: true,
    },
    {
      value: ActivityTypeMenuChoices.Cft,
      icon: <RiCompass3Fill />,
      label: "Appel d'offre",
      display: true,
    },
    {
      value: ActivityTypeMenuChoices.Package,
      icon: <AssignmentIcon />,
      label: 'Mission Forfait',
      display: true,
    },
    {
      value: ActivityTypeMenuChoices.Tm,
      icon: <AssignmentIndIcon />,
      label: 'Mission Régie',
      display: true,
    },
    {
      value: ActivityTypeMenuChoices.Hr,
      icon: <HrIcon />,
      label: 'Mission Vie du cabinet',
      display: shouldDisplayHrActivities,
    },
  ];

  return (
    <Menu
      id="new-activity-menu"
      anchorEl={anchorEl}
      open={newActivityMenuOpened}
      onClose={handleCloseNewActivityMenu}
      MenuListProps={{
        'aria-labelledby': 'create-mission-button',
      }}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: 2,
          width: '13.5rem',
        },
      }}
    >
      {_.map(menuOptions, (option) => {
        if (!option.display) {
          return null;
        }

        return (
          <MenuItem
            key={`menu-item-${option.value}`}
            onClick={() => handleMenuItemClick(option.value)}
          >
            <ListItemIcon>
              <Icon>{option.icon}</Icon>
            </ListItemIcon>
            <ListItemText>{option.label}</ListItemText>
          </MenuItem>
        );
      })}
    </Menu>
  );
}
