import { ArrowForward } from '@mui/icons-material';
import {
  Box,
  Button,
  Popper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { OpacitySuffix } from 'utils';

export interface SubNavigationItem {
  name: string;
  to: string;
  isActive: boolean;
}

interface DrawerHoverItemProps {
  anchorElement: HTMLElement | null | undefined;
  text: string;
  isOpen: boolean;
  handleMouseOver?: () => void;
  handleMouseLeave?: () => void;
  subItems?: SubNavigationItem[];
}

const DrawerHoverItem = ({
  anchorElement,
  text,
  isOpen,
  handleMouseOver,
  handleMouseLeave,
  subItems,
}: DrawerHoverItemProps) => {
  const { palette } = useTheme();

  return (
    <Popper
      open={isOpen}
      anchorEl={anchorElement}
      disablePortal={true}
      placement={subItems ? 'right-start' : 'right'}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onClick={handleMouseLeave}
      sx={{ pl: 2 }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: palette.primary.darker,
          borderRadius: '5px',
          position: 'relative',
        }}
      >
        {!subItems && (
          <Typography
            variant="bodySSemiBold"
            sx={{
              color: 'white',
              textTransform: 'capitalize',
              mx: 1,
              my: 0.5,
            }}
          >
            {text}
          </Typography>
        )}
        {subItems && (
          <Stack spacing={1} sx={{ m: 1 }}>
            {subItems.map((item, index) => (
              <Button
                key={index}
                component={NavLink}
                to={item.to}
                sx={{
                  justifyContent: 'start',
                  backgroundColor: item.isActive
                    ? palette.text.secondary + OpacitySuffix.SIXTY_PERCENT
                    : '',
                  ':hover': {
                    backgroundColor:
                      palette.text.secondary + OpacitySuffix.SIXTY_PERCENT,
                  },
                  '&& .MuiTouchRipple-child': {
                    backgroundColor:
                      palette.text.secondary + OpacitySuffix.SIXTY_PERCENT,
                  },
                  py: 0.5,
                }}
                endIcon={<ArrowForward sx={{ color: 'white' }} />}
              >
                <Typography
                  variant="bodySSemiBold"
                  sx={{ color: 'white', textTransform: 'none' }}
                >
                  {item.name}
                </Typography>
              </Button>
            ))}
          </Stack>
        )}
      </Box>
    </Popper>
  );
};

export default DrawerHoverItem;
