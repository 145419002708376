import { Box, Dialog, DialogContentText, Grid, Stack } from '@mui/material';
import LabelTextFieldGrid from 'components/MissionFollowUp/LabelTextFieldGrid';
import PolyDialogTitle from 'components/MUIOverload/PolyDialogTitle';
import PolyFooter from 'components/MUIOverload/PolyFooter';
import PongoButton from 'components/MUIOverload/PongoButton';
import { DisplayedRevenueProspective } from 'components/Revenue/Estimated/EstimatedRevenueTables/EstimatedRevenueGlobalTable/context/TableContextProvider';
import { useDeleteRevenueProspectiveMutation } from 'generated/graphql';
import { useSnackbar } from 'notistack';
import { requiredForm } from 'pages/MissionFollowUp/formValidators';
import { SaveButtonName } from 'poly-constants';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { handleApolloError } from 'utils';

type ModalMode = 'create' | 'edit';

interface BillingClientModalProps {
  mode: ModalMode;
  revenueProspective?: DisplayedRevenueProspective;
  open: boolean;
  close: () => void;
  onSuccess?: (dpr: DisplayedRevenueProspective) => void;
  onDelete?: (dpr: DisplayedRevenueProspective) => void;
}

export const ProspectiveModal = (props: BillingClientModalProps) => {
  const { close, open, onSuccess, revenueProspective, mode, onDelete } = props;
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = useMemo(() => {
    return {
      id: revenueProspective?.id || '',
      name: revenueProspective?.name || '',
      objective: revenueProspective?.objective || 0,
    };
  }, [revenueProspective]);

  const { errors, handleSubmit, register, formState, reset } = useForm({
    defaultValues,
  });

  register('id');
  register('name');

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const [deleteRevenueProspective] = useDeleteRevenueProspectiveMutation({
    onCompleted: () => {
      enqueueSnackbar('Client prospectif supprimé', {
        variant: 'success',
      });
      if (onDelete && revenueProspective) {
        onDelete(revenueProspective);
      }
      close();
    },
    onError: handleApolloError,
  });

  const onSubmit = async (submitValues: typeof defaultValues) => {
    if (onSuccess) {
      onSuccess({
        id: submitValues.id,
        name: submitValues.name,
        objective: submitValues.objective,
      });
    }
    close();
    return false;
  };

  return (
    <Dialog open={open} onClose={() => close()}>
      <Box ml={3} mt={2.5}>
        {mode === 'create' && (
          <PolyDialogTitle>Création d&apos;un prospect</PolyDialogTitle>
        )}
        {mode === 'edit' && (
          <PolyDialogTitle>Édition du prospect</PolyDialogTitle>
        )}
      </Box>
      <DialogContentText ml={3} mr={3}>
        Vous pouvez ajouter un prospect vous permettant d&apos;indiquer du CA
        prévisionnel sur l&apos;année en amont de la signature d&apos;un contrat
        potentiel.
      </DialogContentText>
      <Grid container spacing={0}>
        <Grid container item xs={12}>
          <LabelTextFieldGrid
            inputRef={register({
              required: requiredForm,
            })}
            required
            name={'name'}
            errorform={errors.name}
            title="Nom de prospect / mission"
            defaultValue={defaultValues.name}
            sizegrid={12}
          />
        </Grid>
      </Grid>
      <PolyFooter>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          width={'100%'}
        >
          {mode === 'edit' ? (
            <PongoButton
              onClick={() => {
                if (mode === 'edit' && revenueProspective?.id) {
                  void deleteRevenueProspective({
                    variables: {
                      revenueProspectiveId: revenueProspective.id,
                    },
                  });
                } else {
                  if (onDelete && revenueProspective) {
                    onDelete(revenueProspective);
                  }
                  close();
                }
              }}
              variant={'contained'}
              buttonStyle="secondary"
            >
              Supprimer
            </PongoButton>
          ) : (
            <Box />
          )}
          <Box>
            <PongoButton onClick={close}>Annuler</PongoButton>
            <PongoButton
              disabled={!formState.isDirty && !!errors}
              onClick={handleSubmit(onSubmit)}
              variant={'contained'}
              color={'primary'}
            >
              {SaveButtonName}
            </PongoButton>
          </Box>
        </Stack>
      </PolyFooter>
    </Dialog>
  );
};
