import { Person } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Grid,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import InfoTooltip from 'components/commons/InfoTooltip';
import MissionTypeIcon from 'components/commons/MissionTypeIcon';
import SectionTitle from 'components/commons/SectionTitle';
import {
  AddressType,
  parseAddress,
} from 'components/MissionFollowUp/DisplayAddress';
import GridItem from 'components/MissionFollowUp/GridItem';
import GridSubTitle from 'components/MissionFollowUp/GridSubTitle';
import LabelTextFieldGrid from 'components/MissionFollowUp/LabelTextFieldGrid';
import {
  ActivitiesActivityBillingTypeChoices,
  ActivityNode,
  BillingClientNode,
  EmployeeNode,
} from 'generated/graphql';
import {
  isArrayFilled,
  requiredForm,
} from 'pages/MissionFollowUp/formValidators';
import React from 'react';
import { Controller, FieldError } from 'react-hook-form';
import { EmployeeType } from 'store/users';
import { displayEmployee, getMissionTypeString } from 'utils';

interface MissionInfoFormProps {
  mission: ActivityNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: any;
  isUnbillable: boolean;
  billingClient: BillingClientNode;
  billingClients: BillingClientNode[];
  activityChiefs: EmployeeNode[];
  employees: EmployeeType[];
  comex: EmployeeNode[];
  setAddress: React.Dispatch<React.SetStateAction<AddressType>>;
  setBillingClient: React.Dispatch<React.SetStateAction<BillingClientNode>>;
}

const MissionInfoForm = ({
  mission,
  form,
  isUnbillable,
  billingClient,
  billingClients,
  activityChiefs,
  employees,
  comex,
  setAddress,
  setBillingClient,
}: MissionInfoFormProps) => {
  const { register, errors, control } = form;
  return (
    <Grid container spacing={0} sx={{ paddingBottom: 2 }}>
      <GridSubTitle customspacingtop={20}>
        <SectionTitle variant="h3Bold">Informations de la mission</SectionTitle>
      </GridSubTitle>
      <Grid
        container
        item
        columns={{
          xs: getMissionTypeString(mission) === 'interne' ? 8 : 12,
        }}
      >
        <Tooltip title={'Information provenant de Napta'} arrow>
          <Grid item xs={4}>
            <LabelTextFieldGrid
              disabled
              inputRef={register}
              name={'client'}
              title="Client métier"
              sizegrid={12}
            />
          </Grid>
        </Tooltip>
        <Tooltip title={'Information provenant de Napta'} arrow>
          <Grid item xs={4}>
            <LabelTextFieldGrid
              disabled
              inputRef={register}
              name={'pysId'}
              title="Code mission"
              sizegrid={12}
            />
          </Grid>
        </Tooltip>
        {!isUnbillable && (
          <Grid item xs={4}>
            <LabelTextFieldGrid
              disabled
              inputRef={register}
              name={'isTm'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MissionTypeIcon
                      isTmContracts={
                        mission.billingType ===
                        ActivitiesActivityBillingTypeChoices.Tm
                      }
                      type={mission.type}
                      sx={{
                        margin: 0.5,
                        width: '18px',
                        color: 'darkGrey.darker',
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              title="Facturation"
              sizegrid={12}
            />
          </Grid>
        )}
        {(mission.client || billingClients) && !isUnbillable && (
          <Grid item xs={4}>
            <Controller
              name={'selectedBillingClient'}
              rules={{ required: requiredForm }}
              defaultValue={undefined}
              render={(properties) => (
                <Autocomplete
                  id={'selected-client'}
                  autoComplete
                  autoHighlight
                  {...properties}
                  options={billingClients}
                  isOptionEqualToValue={(option, value) =>
                    option ? value.id === option.id : true
                  }
                  onChange={(_, data) => {
                    properties.onChange(data);
                    if (data) {
                      setAddress(parseAddress(data));
                      setBillingClient(data);
                    }
                  }}
                  getOptionLabel={(client) => client.corporateName}
                  renderInput={(parameters) => (
                    <LabelTextFieldGrid
                      required
                      title="Client à facturer"
                      sizegrid={12}
                      name={'selected-client'}
                      customElementInTitle={
                        <InfoTooltip
                          style={{ pointerEvents: 'auto' }}
                          title={
                            'La liste des clients est celle de Talentia. Si votre client ne figure pas dans la liste, veuillez contacter le service comptable'
                          }
                        />
                      }
                      {...parameters}
                      errorform={errors.selectedBillingClient as FieldError}
                      InputProps={{
                        ...parameters.InputProps,
                      }}
                    />
                  )}
                />
              )}
              control={control}
            />
          </Grid>
        )}
        {!isUnbillable && (
          <LabelTextFieldGrid
            inputRef={register}
            name={'refMarket'}
            title="Référence"
            customElementInTitle={
              <InfoTooltip
                style={{ pointerEvents: 'auto' }}
                title={
                  'Numéro du marché qui figurera sur la facture pour que le client reconnaisse le contrat facturé.'
                }
              />
            }
          />
        )}
        {!isUnbillable && (
          <GridItem
            title={'Code Client Talentia'}
            sizegrid={4}
            customspacingtop={20}
            customspacingside={20}
          >
            <Box sx={{ position: 'relative', bottom: '8px' }}>
              {billingClient.talentiaId}
            </Box>
          </GridItem>
        )}
        <Grid item xs={4}>
          {(mission.director || employees) && (
            <Controller
              name={'selectedDirector'}
              rules={{ required: requiredForm }}
              render={(properties) => (
                <Autocomplete
                  autoComplete
                  id={'selected-director'}
                  autoHighlight
                  {...properties}
                  options={comex}
                  isOptionEqualToValue={(option, value) =>
                    option ? value.id === option.id : true
                  }
                  onChange={(_, data) => properties.onChange(data)}
                  getOptionLabel={(option) =>
                    displayEmployee(option.firstName, option.lastName)
                  }
                  renderInput={(parameters) => (
                    <LabelTextFieldGrid
                      required
                      name={'selected-director'}
                      title="Directeur de mission"
                      sizegrid={12}
                      {...parameters}
                      errorform={errors.selectedDirector as FieldError}
                      InputProps={{
                        ...parameters.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Person />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              )}
              control={control}
            />
          )}
        </Grid>
        {(mission.chiefs || employees) && (
          <Tooltip title={'Information provenant de Napta'} arrow>
            <Grid item xs={4}>
              <Controller
                name={'selectedChiefs'}
                rules={{
                  required: requiredForm,
                  validate: {
                    isArrayFilled,
                  },
                }}
                render={(properties) => (
                  <Autocomplete
                    autoComplete
                    multiple
                    id={'selected-chiefs'}
                    autoHighlight
                    {...properties}
                    size={'small'}
                    disableClearable={true}
                    disabled={true}
                    options={activityChiefs}
                    isOptionEqualToValue={(option, value) =>
                      option ? value.id === option.id : true
                    }
                    onChange={(_, data) => properties.onChange(data)}
                    getOptionLabel={(option) =>
                      displayEmployee(option.firstName, option.lastName)
                    }
                    renderInput={(parameters) => (
                      <LabelTextFieldGrid
                        name={'selected-chief(s)'}
                        title="Chef(s) de mission"
                        sizegrid={12}
                        {...parameters}
                        errorform={
                          errors.selectedChiefs as unknown as FieldError
                        }
                        InputProps={{
                          ...parameters.InputProps,
                        }}
                      />
                    )}
                  />
                )}
                control={control}
              />
            </Grid>
          </Tooltip>
        )}
        <Grid item xs={4}>
          <Controller
            name={'selectedLeadDevs'}
            render={(properties) => (
              <Autocomplete
                autoComplete
                multiple
                id={'selected-lead-devs'}
                {...properties}
                size={'small'}
                options={employees}
                isOptionEqualToValue={(option, value) =>
                  option ? value.id === option.id : true
                }
                onChange={(_, data) => properties.onChange(data)}
                getOptionLabel={(option) =>
                  displayEmployee(option.firstName, option.lastName)
                }
                renderInput={(parameters) => (
                  <LabelTextFieldGrid
                    name={'selected-lead-dev(s)'}
                    title="Lead dev(s)"
                    sizegrid={12}
                    {...parameters}
                    errorform={errors.selectedLeadDevs as unknown as FieldError}
                    InputProps={{
                      ...parameters.InputProps,
                    }}
                  />
                )}
              />
            )}
            control={control}
          />
        </Grid>
        <LabelTextFieldGrid
          inputRef={register}
          name={'description'}
          title="Description de la mission"
          sizegrid={isUnbillable ? 8 : 6}
          multiline
          rows={4}
        />
        <LabelTextFieldGrid
          inputRef={register}
          name={'techStack'}
          title="Stack technique"
          sizegrid={isUnbillable ? 8 : 6}
          multiline
          rows={4}
        />
      </Grid>
    </Grid>
  );
};

export default MissionInfoForm;
