import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface EmployeeType {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
}

const slice = createSlice({
  name: 'users',
  initialState: {
    employees: [] as EmployeeType[],
    comex: [] as EmployeeType[],
    currentActivityManagers: [] as EmployeeType[],
    currentActivityEmployees: [] as EmployeeType[],
    directorFilter: null as EmployeeType | null,
    creatorFilter: null as EmployeeType | null,
  },
  reducers: {
    setEmployees: (state, action: PayloadAction<EmployeeType[]>) => {
      state.employees = action.payload;
    },
    setEmployeesForCurrentActivity: (
      state,
      action: PayloadAction<EmployeeType[]>
    ) => {
      state.currentActivityEmployees = action.payload;
    },
    setComex: (state, action: PayloadAction<EmployeeType[]>) => {
      state.comex = action.payload;
    },
    setCurrentManagersActivity: (
      state,
      action: PayloadAction<EmployeeType[]>
    ) => {
      state.currentActivityManagers = action.payload;
    },
    setDirectorFilter: (state, action: PayloadAction<EmployeeType | null>) => {
      state.directorFilter = action.payload;
    },
    setCreatorFilter: (state, action: PayloadAction<EmployeeType | null>) => {
      state.creatorFilter = action.payload;
    },
  },
});

export default slice.reducer;
export const {
  setEmployees,
  setCurrentManagersActivity,
  setComex,
  setEmployeesForCurrentActivity,
  setDirectorFilter,
  setCreatorFilter,
} = slice.actions;
