import FilterContextProvider from 'components/commons/FilterContext/FilterContextProvider';
import TableHeaderContextProvider from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import PolySwitch from 'components/Navigation/PolySwitch';
import AccessWrapper from 'components/User/AccessWrapper';
import { EmployeeOccupations } from 'components/User/UserProvider';
import { EmployeesEmployeeGradeChoices } from 'generated/graphql';
import moment from 'moment/moment';
import InternalMissionPage from 'pages/ErrorPage/InternalMissionPage';
import BilledRevenueHomePage from 'pages/RevenuePage/BilledRevenuePage/BilledRevenueHomePage';
import BilledRevenueMissionPage from 'pages/RevenuePage/BilledRevenuePage/BilledRevenueMissionPage';
import { PageTitles } from 'poly-constants';
import React, { useEffect, useState } from 'react';
import {
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { useSelector } from 'store';
import urlJoin from 'url-join';
import { checkIfAllMissionsAreInternal } from 'utils';

export default function BilledRevenuePage() {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { search } = useLocation();

  const urlParams = new URLSearchParams(search);
  const selectedYear = urlParams.has('year')
    ? moment(urlParams.get('year'))
    : moment();
  const [currentYear, setCurrentYear] = useState(selectedYear);

  const selectedMissions = useSelector(
    (state) => state.activity.selectedMissions
  );
  const currentMissionId = useSelector(
    (state) => state.activity.currentMission?.id
  );

  useEffect(
    () => {
      const urlYear = urlParams.get('year') || '';
      const yearStr = currentYear.year().toString();

      if (urlYear !== yearStr) {
        urlParams.set('year', yearStr);
        history.push({
          search: urlParams.toString(),
        });
      }
    },
    // eslint-disable-next-line
    [currentYear.year(), history]
  );

  if (
    selectedMissions.length > 0 &&
    checkIfAllMissionsAreInternal(selectedMissions)
  ) {
    return <InternalMissionPage page={PageTitles.billedRevenuePage} />;
  }

  return (
    <PolySwitch>
      <AccessWrapper
        occupations={[EmployeeOccupations.CONTROL, EmployeeOccupations.DG]}
        grades={[EmployeesEmployeeGradeChoices.Comex]}
        restrictMissionChief={true}
        restrictMissionDirector={true}
      >
        <TableHeaderContextProvider
          currentYear={currentYear}
          setCurrentYear={setCurrentYear}
        >
          <FilterContextProvider>
            <Route
              exact
              path={urlJoin(path, '')}
              component={
                currentMissionId
                  ? BilledRevenueMissionPage
                  : BilledRevenueHomePage
              }
            />
          </FilterContextProvider>
        </TableHeaderContextProvider>
      </AccessWrapper>
    </PolySwitch>
  );
}
