import { Box, Grid, Stack } from '@mui/material';
import InfoTooltip from 'components/commons/InfoTooltip';
import SectionTitle from 'components/commons/SectionTitle';
import SubMissionsForm from 'components/MissionFollowUp/BillDetail/MissionInitialization/SubMissionsForm/SubMissionsForm';
import GridSubTitle from 'components/MissionFollowUp/GridSubTitle';
import { ActivityNode } from 'generated/graphql';
import React from 'react';
import { UseFieldArrayMethods } from 'react-hook-form';

interface MissionSplitFormProps {
  mission: ActivityNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subActivitiesFieldArray: UseFieldArrayMethods<Record<string, any>, 'key'>;
}

const MissionSplitForm = ({
  mission,
  subActivitiesFieldArray,
}: MissionSplitFormProps) => {
  return (
    <Grid container>
      <GridSubTitle customspacingtop={20}>
        <Stack direction="row" alignItems={'center'} spacing={1}>
          <SectionTitle variant="h3Bold">Découpage de la mission</SectionTitle>
          <InfoTooltip
            title={
              'Découper votre mission en sous-missions permettra à vos collaborateurs de déclarer du ' +
              'temps sur celles-ci au lieu de créer plusieurs missions sur PYS.' +
              'Vous aurez aussi la possibilité de facturer sur base des sous-missions si vous le souhaitez.'
            }
          />
        </Stack>
      </GridSubTitle>
      <Box sx={{ width: '100%', pb: 1, px: 1.5 }}>
        <SubMissionsForm
          fields={subActivitiesFieldArray.fields}
          append={subActivitiesFieldArray.append}
          remove={subActivitiesFieldArray.remove}
          mission={mission}
        />
      </Box>
    </Grid>
  );
};

export default MissionSplitForm;
