// Format name for form elements, used to get objects values
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import MailIcon from '@mui/icons-material/Mail';
import {
  BillingBillSendingMethodChoices,
  BillingBillStatusChoices,
  BillNode,
} from 'generated/graphql';
import moment from 'moment';
import { POLY_DATE_MONTH } from 'poly-constants';
import React from 'react';

export enum BillingBillSendingMethodWording {
  Email = 'Email automatique',
  Mail = 'Courrier',
  Platform = 'Dépôt sur plateforme',
}

export function getModalityName(index: number, field = '') {
  return `billingModalities[${index}]${field !== '' ? '.' + field : ''}`;
}

export function displayRangeMonthPeriod(from: Date, to: Date) {
  return moment(from).isSame(to, 'month')
    ? moment(from).format(POLY_DATE_MONTH)
    : `${moment(from).format(POLY_DATE_MONTH)} à ${moment(to).format(
        POLY_DATE_MONTH
      )}`;
}

export const getBillPreviousStatus = (bill: BillNode) => {
  if (bill.sendingDatetime) {
    return BillingBillStatusChoices.Sent;
  } else {
    return bill.billNumber
      ? BillingBillStatusChoices.Billed
      : BillingBillStatusChoices.ToBill;
  }
};

export const getSendingMethodWording = (
  sendingMethod: BillingBillSendingMethodChoices
) => {
  switch (sendingMethod) {
    case BillingBillSendingMethodChoices.Email:
      return BillingBillSendingMethodWording.Email;

    case BillingBillSendingMethodChoices.Mail:
      return BillingBillSendingMethodWording.Mail;

    case BillingBillSendingMethodChoices.Platform:
      return BillingBillSendingMethodWording.Platform;

    default:
      throw new Error('Invalid sending method');
  }
};

export const getSendingMethodIcon = (
  sendingMethod: BillingBillSendingMethodChoices
) => {
  switch (sendingMethod) {
    case BillingBillSendingMethodChoices.Email:
      return <AlternateEmailIcon />;

    case BillingBillSendingMethodChoices.Mail:
      return <MailIcon />;

    case BillingBillSendingMethodChoices.Platform:
      return <CloudUploadIcon />;

    default:
      throw new Error('Invalid sending method');
  }
};
