import { TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';
import PolyTooltip, {
  PolyTooltipMode,
} from 'components/MUIOverload/PolyTooltip';
import _ from 'lodash';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';

interface PolyGridInputFormProps {
  formName: string;
  validateRules: RegisterOptions;
  variant?: 'standard' | 'filled' | 'outlined';
  alignment?:
    | 'start'
    | 'end'
    | 'left'
    | 'right'
    | 'center'
    | 'justify'
    | 'match-parent';
  defaultViewValue?: string;
}

const PolyGridInputForm = ({
  formName,
  validateRules,
  defaultViewValue,
  alignment,
  variant,
  inputProps,
  defaultValue,
  ...textFieldProperties
}: PolyGridInputFormProps & TextFieldProps) => {
  const [tooltip, setTooltip] = useState<boolean | undefined>(false);
  const form = useFormContext();
  const message = _.get(form.errors, `${formName}.message`);
  return (
    <Controller
      defaultValue={defaultValue}
      render={({ onChange, value, ref }) => (
        <PolyTooltip
          title={message || ''}
          placement={'top'}
          open={tooltip}
          mode={PolyTooltipMode.NORMAL}
        >
          <TextField
            {...textFieldProperties}
            inputProps={{
              ...inputProps,
              style: alignment && { textAlign: alignment },
            }}
            inputRef={ref}
            value={value}
            sx={{ color: 'text.secondary' }}
            variant={variant ? variant : 'outlined'}
            size={'small'}
            onMouseEnter={() => {
              if (message) {
                setTooltip(true);
              }
            }}
            onMouseLeave={() => {
              if (message) {
                setTooltip(false);
              }
            }}
            onFocusCapture={() => setTooltip(true)}
            onBlurCapture={() => setTooltip(false)}
            onChangeCapture={() => setTooltip(true)}
            onChange={(event) => {
              onChange(event.target.value);
            }}
            defaultValue={form.getValues(formName) || defaultViewValue}
            error={!!_.get(form.errors, formName)}
          />
        </PolyTooltip>
      )}
      rules={validateRules}
      control={form.control}
      name={formName}
    />
  );
};

export default PolyGridInputForm;
