import { configureStore } from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useSelector as useReduxSelector,
} from 'react-redux';
import { combineReducers } from 'redux';
import activity from 'store/activity';
import adminTables from 'store/adminTables';
import download from 'store/download';
import indicatorTables from 'store/indicatorTables';
import purchaseOrder from 'store/purchaseOrder';
import users from 'store/users';

const reducer = combineReducers({
  activity,
  purchaseOrder,
  users,
  download,
  adminTables,
  indicatorTables,
});

const store = configureStore({
  reducer,
});

export default store;

type IRootState = ReturnType<typeof reducer>;
export const useSelector: TypedUseSelectorHook<IRootState> = useReduxSelector;
