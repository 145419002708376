import React from 'react';

interface NumberCellProps {
  data: number;
}

const NumberCell = (properties: NumberCellProps) => {
  return <>{properties.data}</>;
};

export default NumberCell;
