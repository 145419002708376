import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'adminTables',
  initialState: {
    activitiesTable: {
      pinnedColumns: { left: ['name'], right: ['expirationDate'] },
      preferencePanel: { open: false },
      filter: {},
      sorting: {
        sortModel: [{ field: 'expirationDate', sort: 'asc' }],
      },
      pagination: {
        paginationModel: { pageSize: 50, page: 0 },
      },
    } as GridInitialStatePro,
    employeesTable: {
      pinnedColumns: { left: ['name'], right: ['status'] },
      preferencePanel: { open: false },
      sorting: {
        sortModel: [{ field: 'status', sort: 'desc' }],
      },
      pagination: {
        paginationModel: { pageSize: 50, page: 0 },
      },
    } as GridInitialStatePro,
  },
  reducers: {
    setActivitiesTable: (state, action: PayloadAction<GridInitialStatePro>) => {
      state.activitiesTable = action.payload;
    },
    setEmployeesTable: (state, action: PayloadAction<GridInitialStatePro>) => {
      state.employeesTable = action.payload;
    },
  },
});

export default slice.reducer;
export const { setActivitiesTable, setEmployeesTable } = slice.actions;
