import clsx from 'clsx';
import InputContainer from 'components/commons/InputContainer/InputContainer';
import { Moment } from 'moment';
import { POLY_DATE } from 'poly-constants';
import React from 'react';

import lookupStyles from './styles/lookup.module.scss';
import styles from './styles/overtimeDate.module.scss';

interface OvertimeDate {
  date: Moment;
}

const OvertimeDate = ({ date }: OvertimeDate) => {
  return (
    <InputContainer label={"Date D'Activité exceptionnelle"}>
      <div
        className={clsx(lookupStyles.disabledInputContainer, styles.container)}
      >
        {date.format(POLY_DATE)}
      </div>
    </InputContainer>
  );
};

export default OvertimeDate;
