import { SxProps } from '@mui/material';
import PolyDataGridEmptyBody from 'components/MUIOverload/PolyDataGrid/PolyDataGridEmptyBody';
import React, { useEffect, useState } from 'react';

import PolyTable from '../PolyTable';
import PolyDataGridContextProvider from './context/PolyDataGridContextProvider';
import PolyDataGridBody from './PolyDataGridBody';
import PolyDataGridHead, { PolyDataGridColumn } from './PolyDataGridHead';

type PolyDataGridProps<T> = {
  columns: PolyDataGridColumn<T>[];
  getRows: (
    orderBy: string | string[] | undefined,
    order: 'asc' | 'desc' | undefined
  ) => T[];
  rowsCallback?: (element: T) => void;
  selectedRowsCallback?: (element: T, selected: boolean) => void;
  isRowSelectEnabled?: (element: T) => boolean;
  getRowId?: (element: T) => string;
  isSelectable?: boolean;
  className?: string;
  sx?: SxProps;
};

export default function PolyDataGrid<T>({
  columns,
  getRows,
  rowsCallback,
  selectedRowsCallback,
  isRowSelectEnabled,
  getRowId,
  isSelectable,
  className,
  sx,
}: PolyDataGridProps<T>) {
  const [order, setOrder] = useState<'asc' | 'desc'>();
  const [orderBy, setOrderBy] = useState<string | string[]>();
  const [rows, setRows] = useState<T[]>([]);

  useEffect(() => {
    setRows(getRows(orderBy, order));
  }, [getRows, order, orderBy]);

  const isAlreadySelected = (field: string | string[]) => {
    return orderBy === field;
  };

  const resetOrder = () => {
    //eslint-disable-next-line
    setOrder(undefined);
    setOrderBy('');
  };

  const setNextOrderState = () => {
    switch (order) {
      case 'asc':
        setOrder('desc');
        break;
      case 'desc':
        resetOrder();
        break;
      default:
        setOrderBy('asc');
        break;
    }
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    field: string | string[]
  ) => {
    if (isAlreadySelected(field)) {
      setNextOrderState();
    } else {
      setOrder('asc');
      setOrderBy(field);
    }
  };

  return (
    <PolyDataGridContextProvider>
      <PolyTable>
        <PolyDataGridHead
          columns={columns}
          onRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
          isCheckable={isSelectable}
        />
        <PolyDataGridBody
          columns={columns}
          rows={rows}
          rowsCallback={rowsCallback}
          selectedRowsCallback={selectedRowsCallback}
          isRowSelectEnabled={isRowSelectEnabled}
          getRowId={getRowId}
          className={className}
          isSelectable={isSelectable}
          sx={sx}
        />
        {rows.length === 0 && (
          <PolyDataGridEmptyBody columnsNumber={columns.length} />
        )}
      </PolyTable>
    </PolyDataGridContextProvider>
  );
}
