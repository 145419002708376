import { TableBody } from '@mui/material';
import { buildDateRange } from 'components/commons/Tables/utils';
import { useTableContext } from 'contexts/ActivityMonitoringTable/TableContextProvider';
import {
  BillingClientNode,
  EmployeeNode,
  useManagedActivityMonitoringByEmployeeQuery,
} from 'generated/graphql';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import { graphQlDateFormatter } from 'utils';

import styles from '../styles/MonitoringTable.module.scss';
import CollaboratorRow from './Body/CollaboratorRow';
import LoadingTableBody from './Body/LoadingTableBody';
import NoResultTableBody from './Body/NoResultTableBody';

export default function TableBodyByUser() {
  const { enqueueSnackbar } = useSnackbar();
  const {
    displayedMonth,
    setAllCollaborators,
    setAllClients,
    filterCollaboratorsId,
    filterMissionsId,
    filterClientsId,
    filterLatecomers,
    filterProvider,
    page,
    rowsPerPage,
  } = useTableContext();

  const dateRange = buildDateRange(displayedMonth);

  const { data, loading } = useManagedActivityMonitoringByEmployeeQuery({
    variables: {
      dateFrom: graphQlDateFormatter(dateRange.start),
      dateTo: graphQlDateFormatter(dateRange.end),
      employeesId: filterCollaboratorsId,
      missionsId: filterMissionsId,
      listClientsPk: filterClientsId,
      filterLatecomers: filterLatecomers,
      filterProviders: filterProvider,
      pageNbr: page,
      pageSize: rowsPerPage,
    },
    onCompleted: (data) => {
      // Only set clients and collaborators in provider if page is equal to 0 because when a filter changes
      // the page is set to 0 and that's the only time it is needed to set these, not when the page changes.
      if (page === 0) {
        setAllClients(
          data.managedActivityMonitoringByEmployeeInRange
            ?.clients as BillingClientNode[]
        );
        setAllCollaborators(
          data.managedActivityMonitoringByEmployeeInRange
            ?.allCollaborators as EmployeeNode[]
        );
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const employeesToDisplay: EmployeeNode[] = useMemo(
    function () {
      return data?.managedActivityMonitoringByEmployeeInRange
        ?.collaboratorsToDisplay as EmployeeNode[];
    },
    [data]
  );

  if (loading) {
    return <LoadingTableBody />;
  }

  if (!employeesToDisplay || employeesToDisplay?.length === 0) {
    return <NoResultTableBody />;
  }

  return (
    <TableBody className={styles.tableBody}>
      {_.map(employeesToDisplay, (employee) => (
        <CollaboratorRow
          key={`collaborator-row-${employee.id}`}
          employee={employee}
        />
      ))}
    </TableBody>
  );
}
