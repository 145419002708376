import { Box } from '@mui/material';
import { DateRangePicker } from 'components/commons/DateRangePicker';
import moment from 'moment';
import React from 'react';

interface DateRangeCellProps {
  startDate: string;
  endDate: string;
  className?: string;
}

const DateRangeCell = (properties: DateRangeCellProps) => {
  return (
    <Box sx={{ mt: '5px' }}>
      <DateRangePicker
        dateRange={[
          moment(properties.startDate).toDate(),
          moment(properties.endDate).toDate(),
        ]}
        disableFuture
        disabled
        className={properties.className ? properties.className : ''}
      />
    </Box>
  );
};

export default DateRangeCell;
