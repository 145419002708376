import { PixelCrop } from 'react-image-crop';

export async function cropImage(
  src: string,
  pixelCrop: PixelCrop
): Promise<string> {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = src;
    image.addEventListener('load', () => {
      const canvas = document.createElement('canvas');
      canvas.width = pixelCrop.width;
      canvas.height = pixelCrop.height;
      const ctx = canvas.getContext('2d');

      ctx?.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
      );
      resolve(canvas.toDataURL('image/jpeg', '1.0'));
    });
  });
}
