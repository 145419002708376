import { TableRow } from '@mui/material';
import { useTableContext } from 'contexts/ActivityMonitoringTable/TableContextProvider';
import React from 'react';

import styles from '../../../styles/TableCell.module.scss';

export default function BorderRow() {
  const { displayedWeeks } = useTableContext();
  return (
    <TableRow>
      <td colSpan={displayedWeeks.length + 3} className={styles.borderRow} />
    </TableRow>
  );
}
