import { DialogContentText, Grid } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { MonthFormFilter } from './ExportAccountantForm';

const EXPORT_UE_BILLS_DATA_BODY =
  "Téléchargez ici les factures facturés des clients de l'Union Européenne (hors France)  \
  selon le mois facturé de votre choix.";

export default function ExportEuBillStateForm() {
  const form = useFormContext();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DialogContentText id="alert-dialog-description" sx={{ py: 2 }}>
          {EXPORT_UE_BILLS_DATA_BODY}
        </DialogContentText>
      </Grid>
      <Controller
        name="exportUeBillState.month"
        control={form.control}
        render={({ onChange, value }) => (
          <MonthFormFilter
            labelText="Date de facturation :"
            onChange={(value: Date | null) => {
              onChange(value);
            }}
            value={value}
          />
        )}
      />
    </Grid>
  );
}
