import { List } from '@mui/material';
import SubMissionDetailView from 'components/MissionFollowUp/BillDetail/BillDetail/BillView/SubMissionDetailView';
import _ from 'lodash';
import React from 'react';
import { useSelector } from 'store';

export default function SubMissionsCollaboratorsView() {
  const currentMission = useSelector((state) => state.activity.currentMission);

  return (
    <List>
      {_.map(currentMission.subActivities, (subActivity, index) => (
        <SubMissionDetailView
          key={'subactivity-' + subActivity.id}
          subActivity={subActivity}
          index={index}
        />
      ))}
    </List>
  );
}
