import './styles/MonthPicker.scss';

import DateRangeIcon from '@mui/icons-material/DateRange';
import { Box, IconButton, useTheme } from '@mui/material';
import {
  getDateFormat,
  getFormatMonthYearValue,
  getMonthYearDate,
} from 'components/commons/MonthPicker/MonthPickerTools';
import {
  DateFormatMonthRange,
  RangeMonthLimitType,
  RangeMonthType,
} from 'components/commons/MonthPicker/MonthPickerTypes';
import LabelTextFieldGrid from 'components/MissionFollowUp/LabelTextFieldGrid';
import { POLY_MONTH_CALENDAR } from 'poly-constants';
import React, { useState } from 'react';
import {
  DeepMap,
  FieldError,
  UseControllerOptions,
  UseFormMethods,
} from 'react-hook-form';
import ReactMonthPicker from 'react-month-picker';
import { OpacitySuffix } from 'utils';

interface MonthPickerProperties
  extends Partial<Pick<UseControllerOptions, 'rules'>> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormMethods<any>;
  range: RangeMonthLimitType;
  value?: RangeMonthType;
  onChange?: (rangeMonth: DateFormatMonthRange) => void;
  name: string;
  disabled?: boolean;
  label: string;
  error?: boolean;
  errorForm?: DeepMap<RangeMonthType, FieldError>;
}

const MonthPickerState = {
  fromEdit: 1,
  toEdit: 2,
  hidden: 3,
};

export default function MonthPicker({
  rules,
  form,
  range,
  value,
  onChange,
  name,
  disabled = false,
  label,
  error,
  errorForm,
}: MonthPickerProperties) {
  const { palette } = useTheme();
  const [monthPickerState, setMonthPickerState] = useState(
    MonthPickerState.hidden
  );
  const monthPickerRef = React.useRef(null);

  const formValue = form?.watch(name);
  const defaultMonthYear =
    value || getMonthYearDate(formValue.from, formValue.to);
  const [rangeMonth, setRangeMonth] =
    useState<RangeMonthType>(defaultMonthYear);

  function showMonthPicker() {
    if (monthPickerState !== MonthPickerState.fromEdit) {
      setMonthPickerState(MonthPickerState.fromEdit);
    }
    if (monthPickerRef && monthPickerRef.current) {
      // @ts-ignore
      monthPickerRef.current?.show();
    }
  }

  const handleOnDismiss = () => {
    setMonthPickerState(MonthPickerState.hidden);
  };

  function updateNewValue(newValue: RangeMonthType) {
    const dateFormat = getDateFormat(newValue);
    setRangeMonth(newValue);
    form?.setValue(name, dateFormat as never);
    if (onChange) {
      onChange(dateFormat);
    }
  }

  const handleOnChange = (year: number, month: number, index: number) => {
    let newStatus = monthPickerState;
    if (index === 0) {
      newStatus =
        monthPickerState === MonthPickerState.toEdit
          ? MonthPickerState.hidden
          : MonthPickerState.fromEdit;
      const newValue = {
        from: { year: year, month: month },
        to: rangeMonth.to || { year: year, month: month },
      };
      updateNewValue(newValue);
    } else if (index === 1) {
      newStatus =
        monthPickerState === MonthPickerState.fromEdit
          ? MonthPickerState.hidden
          : MonthPickerState.toEdit;
      const newValue = {
        from: rangeMonth.from || { year: year, month: month },
        to: { year: year, month: month },
      };
      updateNewValue(newValue);
    }
    setMonthPickerState(newStatus);
    if (newStatus === 3) {
      setMonthPickerState(MonthPickerState.hidden);
    }
  };

  form?.register(name, rules);

  return (
    <>
      <LabelTextFieldGrid
        value={getFormatMonthYearValue(rangeMonth)}
        required={!!rules?.required}
        name={name}
        InputProps={{
          startAdornment: (
            <IconButton
              onFocus={() => showMonthPicker()}
              disabled={disabled}
              sx={{ p: 0 }}
              size="large"
            >
              <DateRangeIcon />
            </IconButton>
          ),
          spellCheck: 'false',
        }}
        inputProps={{
          sx: { pl: 0.5, textTransform: 'uppercase' },
        }}
        customspacingtop={0}
        customspacingside={0}
        title={label}
        sizegrid={12}
        disabled={disabled}
        errorform={
          errorForm?.from?.month ||
          errorForm?.from?.year ||
          errorForm?.to?.month ||
          errorForm?.to?.year
        }
        error={error || !!errorForm}
        onFocus={() => showMonthPicker()}
      />
      <Box
        sx={{
          '& .month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn.select':
            {
              backgroundColor: `${palette.darkGrey.main}`,
              color: 'rgba(0, 0, 0, 0.7)',
              '&:hover': {
                backgroundColor: `${
                  palette.primary.main + OpacitySuffix.SIXTY_PERCENT
                }`,
              },
            },
          '& .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active':
            {
              backgroundColor: `${palette.primary.main}`,
              color: 'white',
              '&:hover': {
                backgroundColor: `${
                  palette.primary.main + OpacitySuffix.NINETY_PERCENT
                }`,
              },
            },
        }}
      >
        <ReactMonthPicker
          ref={monthPickerRef}
          lang={POLY_MONTH_CALENDAR}
          years={range}
          value={rangeMonth}
          onChange={handleOnChange}
          onDismiss={handleOnDismiss}
        />
      </Box>
    </>
  );
}
