import { CallMade } from '@mui/icons-material';
import { DialogContent, Stack, Typography } from '@mui/material';
import PolyDialog from 'components/MUIOverload/PolyDialog';
import PolyModalHeader from 'components/MUIOverload/PolyDialog/DefaultComponents/PolyModalHeader';
import PolyFooter from 'components/MUIOverload/PolyFooter';
import PongoButton from 'components/MUIOverload/PongoButton';
import React, { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';

interface ModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  weeklyContract: number;
}

export default function InvalidWorkingDaysModal({
  open,
  setOpen,
  weeklyContract,
}: ModalProps) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <PolyDialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEnforceFocus
      PaperProps={{ sx: { width: '600px' } }}
    >
      <DialogContent>
        <PolyModalHeader
          handleClose={handleClose}
          polyDialogTitle="Jours travaillés incohérents"
          sx={{ paddingBottom: 2 }}
        />
        <Typography>
          Vos jours contractuels ont été modifiés à {weeklyContract} jours par
          semaine mais vous n&apos;avez pas sélectionné quels jours de la
          semaine vous travaillez.
        </Typography>
        <Typography>
          Veuillez compléter cette information sur votre page profil afin de
          pouvoir déclarer du temps de travail.
        </Typography>
        <PolyFooter>
          <Stack
            display={'flex'}
            justifyContent={'flex-end'}
            flexDirection={'row'}
            sx={{ width: 1 }}
          >
            <PongoButton
              onClick={() => {
                handleClose();
              }}
              size="small"
            >
              Fermer
            </PongoButton>
            <Link to="/user">
              <PongoButton
                sx={{ ml: 0, mr: 0 }}
                variant="contained"
                buttonStyle="secondary"
                startIcon={<CallMade />}
                size="small"
              >
                Mon profil
              </PongoButton>
            </Link>
          </Stack>
        </PolyFooter>
      </DialogContent>
    </PolyDialog>
  );
}
