import { TableRow, TableRowProps } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { TransitionStatus } from 'react-transition-group/Transition';

import styles from './styles/PolyTableRow.module.scss';

interface PolyTableRowProps {
  status: TransitionStatus;
  className?: string;
}

const ChildCollapsePolyTableRow = (
  properties: PolyTableRowProps & TableRowProps
) => {
  const { status, className, ...tableRowProperties } = properties;
  return (
    <TableRow
      {...tableRowProperties}
      className={clsx(
        className,
        status === 'exiting' ? styles.rowOut : styles.rowIn
      )}
    />
  );
};

export default ChildCollapsePolyTableRow;
