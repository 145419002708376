import Typography from '@mui/material/Typography';
import PolyModal from 'components/MUIOverload/PolyDialog/PolyModal';
import { useDeleteEmployeeReportingParameterMutation } from 'generated/graphql';
import { useSnackbar } from 'notistack';
import React from 'react';

interface OccupationAdrDeleteModalProps {
  open: boolean;
  handleValidation: () => void;
  handleClose: () => void;
  occupation: string;
}

export default function OccupationAdrDeleteModal({
  open,
  handleValidation,
  handleClose,
  occupation,
}: OccupationAdrDeleteModalProps) {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteEmployeeReportingParameter] =
    useDeleteEmployeeReportingParameterMutation({
      onCompleted: () => {
        enqueueSnackbar(
          `Les coûts du rôle « ${occupation} » ont bien été supprimés`,
          {
            variant: 'success',
          }
        );
        handleValidation();
        handleClose();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
    });

  const handleDelete = () =>
    deleteEmployeeReportingParameter({ variables: { occupation } });

  return (
    <PolyModal
      open={open}
      onClose={handleClose}
      polyDialogTitle="Supprimer les coûts d'un rôle"
      handlePrimary={handleDelete}
      primaryButtonName="Supprimer"
    >
      <>
        <Typography>
          Souhaitez-vous supprimer les coûts du rôle &quot;{occupation}&quot; ?
        </Typography>
        <Typography fontWeight="bold">
          Note : les employés ayant ce rôle seront associés aux TJM coûts par
          défaut.
        </Typography>
      </>
    </PolyModal>
  );
}
