import { Grid } from '@mui/material';
import { ApplicationCard } from 'components/Applications';
import PageWrapper from 'components/commons/PageWrapper';
import PolyTitle from 'components/commons/PolyTitle';
import { PageTitles } from 'poly-constants';
import React from 'react';

export interface AppItem {
  name: string;
  description: string;
  icon: string;
  url: string;
}

export const POLY_APPS: AppItem[] = [
  {
    name: 'Wiki Confluence',
    description: "Informations liées à la vie d'entreprise.",
    icon: '/apps/polyconseil.svg',
    url: 'https://polyconseil.atlassian.net/wiki/spaces/POL/overview',
  },
  {
    name: 'Helpdesk',
    description: "Demandes de matériel ou d'assistance informatique.",
    icon: '/apps/polyconseil.svg',
    url: 'https://polyconseil.atlassian.net/servicedesk/customer/portals',
  },
  {
    name: 'Oneflex',
    description: 'Gestion des réservations de bureau / télétravail.',
    icon: '/apps/oneflex.png',
    url: 'https://polyconseil.coviflex.com/',
  },
  {
    name: 'Hello Talent',
    description: 'Plateforme de recrutement.',
    icon: '/apps/hello-talent.png',
    url: 'https://app.hellotalent.com/',
  },
  {
    name: 'Jenji',
    description: 'Gestion des notes de frais.',
    icon: '/apps/jenji.svg',
    url: 'https://app.jenji.io/login',
  },
  {
    name: 'SAP Concur',
    description: "Gestion des voyages et dépenses d'entreprise.",
    icon: '/apps/sap-concur.svg',
    url: 'https://www.concursolutions.com',
  },
  {
    name: 'Espace RH',
    description: 'Gestion des absences et des plannings.',
    icon: '/apps/polyconseil.svg',
    url: 'https://rh.polyconseil.fr/',
  },
  {
    name: 'PolyAcademy',
    description: 'Plateforme de formations Polyconseil.',
    icon: '/apps/PolyAcademy.png',
    url: 'https://polyconseil.atlassian.net/wiki/spaces/ACADEMY/overview',
  },
  {
    name: 'BUniversity',
    description: 'Plateforme de formations du groupe.',
    icon: '/apps/buniversity.png',
    url: 'https://buniversity.bollore.com/Directory/Login/Login.aspx',
  },
  {
    name: 'BPeople',
    description: 'Annuaire du groupe.',
    icon: '/apps/bpeople.svg',
    url: 'https://bpeople.polyconseil.fr/',
  },
];

const ApplicationsPage = () => {
  return (
    <PageWrapper large>
      <PolyTitle sx={{ mb: 3.75 }}>{PageTitles.applicationsPage}</PolyTitle>
      <Grid container spacing={3}>
        {POLY_APPS.map((app) => (
          <Grid item key={app.name} xs={12} sm={6} md={4} lg={3}>
            <ApplicationCard application={app} />
          </Grid>
        ))}
      </Grid>
    </PageWrapper>
  );
};

export default ApplicationsPage;
