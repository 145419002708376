import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import { useNavbarContextProvider } from 'components/Navigation/DesktopNavbar/NavbarContextProvider';
import React from 'react';

import { TRANSITION_STYLE } from './utils';

export const COLLAPSE_DRAWER_BUTTON_SIZE = 36;

export default function CollapseDrawerButton() {
  const { isDrawerOpen, setIsDrawerOpen } = useNavbarContextProvider();
  const { palette } = useTheme();

  return (
    <Box sx={{ transition: `left ${TRANSITION_STYLE}` }}>
      <Button
        sx={{
          maxWidth: COLLAPSE_DRAWER_BUTTON_SIZE,
          minWidth: COLLAPSE_DRAWER_BUTTON_SIZE,
          maxHeight: COLLAPSE_DRAWER_BUTTON_SIZE,
          minHeight: COLLAPSE_DRAWER_BUTTON_SIZE,
          backgroundColor: `${palette.primary.main}`,
          color: `${palette.contrastText.main}`,
          borderRadius: COLLAPSE_DRAWER_BUTTON_SIZE / 2,
          border: '1px solid',
          ':hover': {
            backgroundColor: `${palette.primary.main}`,
          },
          borderColor: `${palette.primary.main}`,
        }}
        onClick={() => setIsDrawerOpen((oldState) => !oldState)}
      >
        {isDrawerOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </Button>
    </Box>
  );
}
