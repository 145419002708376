import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client';
import { CredentialResponse } from '@react-oauth/google';
import LoadingPage from 'components/commons/LoadingPage';
import ConfigProvider from 'components/User/ConfigProvider';
import UserProvider from 'components/User/UserProvider';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { logIn, logInGoogle, logOut } from 'services/login';

import { buildApolloClient } from './apollo';
import AuthProvider from './AuthProvider';
import { buildFetch } from './fetch';
import { getNewCredentials, useCredential } from './hooks/useCredentials';
import LoginPage from './LoginPage';

interface AuthenticatorProps {
  googleClientId: string;
}

const Authenticator = ({
  children,
  googleClientId,
}: React.PropsWithChildren<AuthenticatorProps>) => {
  const { enqueueSnackbar } = useSnackbar();
  const [credentials, handleCredentials, clearCredentials] = useCredential();
  const [loading, setLoading] = useState(false);
  const [isLogged, setIsLogged] = useState(!!credentials.current);
  const [clients, setClients] = useState<{
    apollo: ApolloClient<NormalizedCacheObject>;
    customFetch: (input: RequestInfo, init?: RequestInit) => Promise<Response>;
  }>();

  useEffect(() => {
    const refreshSession = async () => {
      const newCredentials = await getNewCredentials(logout);
      handleCredentials(newCredentials);
    };

    const customFetch = buildFetch(credentials, refreshSession);
    setClients({
      apollo: buildApolloClient(customFetch),
      customFetch,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = useCallback(
    async (data: { username: string; password: string }) => {
      setLoading(true);
      const response = await logIn({
        username: data.username,
        password: data.password,
      });

      if (response.error) {
        enqueueSnackbar('Local login failed', { variant: 'error' });
      } else {
        handleCredentials(response.response);
        setIsLogged(true);
      }
      setLoading(false);
    },
    [enqueueSnackbar, handleCredentials]
  );

  const loginGoogleSuccess = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (googleData: CredentialResponse): Promise<void> => {
      try {
        const response = await logInGoogle({
          tokenId: googleData.credential as string,
        });
        if (!response.error) {
          handleCredentials(response.response);
          setIsLogged(true);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [setLoading, handleCredentials, setIsLogged]
  );

  const loginGoogleFail = () => {
    enqueueSnackbar('Google login failed', {
      variant: 'error',
    });
    setLoading(false);
  };

  const logout = useCallback(
    async (message = '') => {
      setLoading(true);
      const response = await logOut();

      if (response.error) {
        enqueueSnackbar('Logout failed', { variant: 'error' });
      } else {
        clearCredentials();
        setIsLogged(false);
        if (message) {
          enqueueSnackbar(message, { variant: 'error' });
        }
      }
      setLoading(false);
    },
    [enqueueSnackbar, clearCredentials]
  );

  if (loading) {
    return <LoadingPage loading={loading} />;
  }

  if (!clients || !credentials.current || !isLogged) {
    return (
      <LoginPage
        onValidate={login}
        clientID={googleClientId}
        onGoogleSuccess={loginGoogleSuccess}
        onGoogleFailure={loginGoogleFail}
      />
    );
  }

  const buildContext = () => {
    return {
      logout,
    };
  };

  return (
    <AuthProvider value={buildContext()}>
      <ApolloProvider client={clients.apollo}>
        <ConfigProvider>
          <UserProvider>{children}</UserProvider>
        </ConfigProvider>
      </ApolloProvider>
    </AuthProvider>
  );
};

export default Authenticator;
export { useAuth } from './AuthProvider';
