import { SxProps } from '@mui/system';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import YearSelector from 'components/commons/YearSelector/YearSelector';
import { ActivityNode } from 'generated/graphql';
import moment from 'moment';
import React from 'react';

interface EstimatedRevenueYearProps {
  mission?: ActivityNode;
  sx?: SxProps;
}

export const EstimatedRevenueYear = ({
  mission,
  sx,
}: EstimatedRevenueYearProps) => {
  const { currentYear, setCurrentYear } = useTableHeaderContext();
  const start = mission ? moment(mission.startDate) : moment(2020, 'YYYY');
  const end = mission
    ? moment(mission.expirationDate)
    : moment().add(2, 'year');

  return (
    <YearSelector
      currentYear={currentYear}
      setCurrentYear={setCurrentYear}
      sx={sx}
      from={start}
      to={end}
    />
  );
};
