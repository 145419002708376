import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import * as React from 'react';

import styles from './styles/HomeCarousel.module.scss';

/*
 disabling no-explicit-any linter for this file to enable
react-multi-carousel library onClick inheritance
*/
/* eslint-disable @typescript-eslint/no-explicit-any */

const isInRightEnd = (
  currentSlide: number,
  totalItems: number,
  slidesToShow: number
): boolean => {
  return !(currentSlide + slidesToShow < totalItems);
};

const getLeftArrowStyle = (currentSlide: number): string => {
  if (currentSlide === 0) {
    return styles.disabled;
  } else {
    return styles.leftArrow;
  }
};

const getRightArrowStyle = (
  currentSlide: number,
  totalItems: number,
  slidesToShow: number
): string => {
  if (isInRightEnd(currentSlide, totalItems, slidesToShow)) {
    return styles.disabled;
  } else {
    return styles.rightArrow;
  }
};

const CustomLeftArrow = ({ onClick, currentSlide }: any) => {
  return (
    <ArrowBackIosNewIcon
      onClick={() => onClick()}
      className={getLeftArrowStyle(currentSlide)}
    />
  );
};

const CustomRightArrow = ({
  onClick,
  currentSlide,
  totalItems,
  slidesToShow,
}: any) => {
  return (
    <ArrowForwardIosIcon
      onClick={() => onClick()}
      className={getRightArrowStyle(currentSlide, totalItems, slidesToShow)}
    />
  );
};

const ButtonGroup = ({ next, previous, ...rest }: any) => {
  const {
    carouselState: { currentSlide, totalItems, slidesToShow },
  } = rest;
  return (
    <div className={styles.buttonGroup}>
      <CustomLeftArrow onClick={() => previous()} currentSlide={currentSlide} />
      <CustomRightArrow
        onClick={() => next()}
        currentSlide={currentSlide}
        totalItems={totalItems}
        slidesToShow={slidesToShow}
      />
    </div>
  );
};

export { ButtonGroup };
