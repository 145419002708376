import TablePagination from '@mui/material/TablePagination';
import PolyTablePaginationActions from 'components/MUIOverload/PolyTablePaginationActions/PolyTablePaginationActions';
import { useTableContext as useActivityMonitoringTableContext } from 'contexts/ActivityMonitoringTable/TableContextProvider';
import { ROWS_PER_PAGE_MONITORING } from 'poly-constants';
import React from 'react';

interface TablePaginationMonitoringProps {
  label?: string;
  rowsPerPageOptions?: number[];
}

export default function TablePaginationMonitoring({
  label = 'Collaborateurs par page',
  rowsPerPageOptions = [5, 10, 20, 50],
}: TablePaginationMonitoringProps) {
  const ActivityMonitoringTableContext = useActivityMonitoringTableContext();
  let paginationCount = 0;
  let page = 0;
  let rowsPerPage = 5;
  let setPage: (page: number) => void;
  let setRowsPerPage: (rowsPerPage: number) => void;

  if (ActivityMonitoringTableContext.isContextCreated) {
    paginationCount = ActivityMonitoringTableContext.paginationCount;
    page = ActivityMonitoringTableContext.page;
    rowsPerPage = ActivityMonitoringTableContext.rowsPerPage;
    setPage = ActivityMonitoringTableContext.setPage;
    setRowsPerPage = ActivityMonitoringTableContext.setRowsPerPage;
  } else {
    throw new Error('TablePaginationMonitoring: No context found');
  }

  const handlePageChange = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    saveRowsPerPageInBrowser(event);
    setRowsPerPage(Number.parseInt(event.target.value, 10));
    setPage(0);
  };

  function saveRowsPerPageInBrowser(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    if (ActivityMonitoringTableContext) {
      localStorage.setItem(ROWS_PER_PAGE_MONITORING, event.target.value);
    }
  }

  return (
    <TablePagination
      sx={{ p: 0 }}
      onPageChange={handlePageChange}
      rowsPerPageOptions={rowsPerPageOptions}
      count={paginationCount}
      rowsPerPage={rowsPerPage}
      page={page}
      SelectProps={{
        inputProps: { 'aria-label': label },
        native: true,
      }}
      labelRowsPerPage={label}
      labelDisplayedRows={({ from, to, count }) => {
        return '' + from + '-' + to + ' sur ' + count;
      }}
      onRowsPerPageChange={handleRowsPerPageChange}
      ActionsComponent={PolyTablePaginationActions}
    />
  );
}
