import { Search } from '@mui/icons-material';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import { ActivityNode } from 'generated/graphql';
import _ from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { EmployeeType, setDirectorFilter } from 'store/users';
import { displayEmployee } from 'utils';

import styles from './styles/Filters.module.scss';

interface FilterDirectorProps {
  filterWidth: number;
}

const FilterDirector = ({ filterWidth }: FilterDirectorProps) => {
  const dispatch = useDispatch();
  const directorFilter = useSelector((state) => state.users.directorFilter);
  const allMissions: ActivityNode[] = useSelector(
    (state) => state.activity.activities
  );
  const allDirectors = _.sortBy(
    _.uniqBy(
      _.flatMap(allMissions, (activity) => {
        return (activity.director as EmployeeType) || [];
      }),
      'id'
    ),
    ['lastName', 'firstName']
  );

  return (
    <Autocomplete
      autoHighlight
      autoComplete
      id="filter-select-creator"
      value={directorFilter}
      options={allDirectors}
      className={styles.filterSelect}
      onChange={(event, value: EmployeeType | null) => {
        dispatch(setDirectorFilter(value));
      }}
      getOptionLabel={(director) => {
        if (director) {
          return displayEmployee(director.firstName, director.lastName);
        }
        return '';
      }}
      sx={{ width: filterWidth }}
      renderInput={(parameters) => (
        <TextField
          variant="standard"
          label={'Directeur de mission'}
          {...parameters}
          InputProps={{
            ...parameters.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default FilterDirector;
