import { Divider, Stack } from '@mui/material';
import { ColumnLabelsV2 } from 'components/commons/Tables/utils';
import PongoButton from 'components/MUIOverload/PongoButton';
import _ from 'lodash';
import { SaveButtonName } from 'poly-constants';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import ActivityWeekCard from './ActivityWeekCard';

interface ActivityWeekFooterProps {
  week: ColumnLabelsV2;
  disabled: boolean;
  enteredTotal: number;
}

const ActivityWeekFooter = ({
  week,
  disabled,
  enteredTotal,
}: ActivityWeekFooterProps) => {
  const { errors } = useFormContext();
  return (
    <>
      <Stack m={2}>
        <ActivityWeekCard
          key={`week-card-${week.weekNumber}-${week.weekPart}`}
          week={week}
          daysWorked={enteredTotal}
          loading={false}
          sx={{ my: 0 }}
          showBorder
        />
      </Stack>
      <Divider />
      <Stack direction={'row'} justifyContent={'center'} m={2}>
        <PongoButton
          variant="contained"
          disabled={disabled || !_.isEmpty(errors)}
          sx={{ width: '90%' }}
          type={'submit'}
        >
          {SaveButtonName}
        </PongoButton>
      </Stack>
    </>
  );
};

export default ActivityWeekFooter;
