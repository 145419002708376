import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import PolyAlert from 'components/commons/PolyAlert';
import PolyAlertTitle from 'components/commons/PolyAlertTitle';
import BillActionModal from 'components/MissionFollowUp/BillDetail/Modals/BillActionModal/BillActionModal';
import PongoButton from 'components/MUIOverload/PongoButton';
import {
  BillingBillingAddressCategoryChoices,
  BillingBillSendingMethodChoices,
  BillingBillStatusChoices,
  BillNode,
  EmployeeNode,
  useAllEmployeesAndActivitiesQuery,
  useUpdateBillStatusMutation,
} from 'generated/graphql';
import { useSnackbar } from 'notistack';
import { totalFormat } from 'pages/ActivityPage/utils';
import { FRENCH_VAT_RATE, LanguageRegionEnum } from 'poly-constants';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { setCurrentBill } from 'store/purchaseOrder';

import BillEmailAutocomplete from './BillEmailAutocomplete';
import {
  billEmailLanguageOptions,
  EmailOption,
  getCcRecipientsEmails,
  getEmailSubject,
  getEmailTemplate,
  getRecipientsEmails,
} from './utils';

interface BillActionModalProps {
  open: boolean;
  closeModal: () => void;
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
}

const BillEmailModal = (properties: BillActionModalProps) => {
  const currentBill = useSelector((state) => state.purchaseOrder.currentBill);
  const currentMission = useSelector((state) => state.activity.currentMission);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const { data: allEmployeeData } = useAllEmployeesAndActivitiesQuery({
    onCompleted: (data) => {
      setAllEmployees(data?.allActiveEmployees as EmployeeNode[]);
    },
  });

  const [allEmployees, setAllEmployees] = useState<EmployeeNode[]>(
    allEmployeeData?.allActiveEmployees as EmployeeNode[]
  );

  const [recipientsEmails, setRecipientsEmails] = useState<EmailOption[]>(
    getRecipientsEmails(currentMission) || []
  );

  const [ccRecipientsEmails, setCcRecipientsEmails] = useState<EmailOption[]>(
    getCcRecipientsEmails(currentMission) || []
  );

  const isClientFrench =
    currentBill.purchaseOrder?.billingAddress?.category ===
    BillingBillingAddressCategoryChoices.Fr;

  const formatedTotal = totalFormat(
    currentBill.total * (isClientFrench ? 1 + FRENCH_VAT_RATE : 1),
    currentBill.purchaseOrder?.currency
  );

  const [emailLang, setEmailLang] = useState<string>(LanguageRegionEnum.fr_FR);

  const [emailSubject, setEmailSubject] = useState<string>(
    getEmailSubject(currentBill.billNumber, currentMission.client, emailLang)
  );

  const [emailTemplate, setEmailTemplate] = useState<string>(
    getEmailTemplate(
      formatedTotal,
      isClientFrench,
      currentBill.dueDate,
      currentBill.billNumber,
      emailLang
    )
  );

  const defaultValues = useMemo(
    () => ({
      emailLang: emailLang,
      emailSubject: emailSubject,
      recipients: recipientsEmails,
      ccRecipients: ccRecipientsEmails,
    }),
    [ccRecipientsEmails, emailLang, emailSubject, recipientsEmails]
  );

  const form = useForm({
    defaultValues: defaultValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  const { fields: recipients } = useFieldArray({
    control: form.control,
    name: 'recipients',
  });
  const { fields: ccRecipients } = useFieldArray({
    control: form.control,
    name: 'ccRecipients',
  });

  const [updateStatus] = useUpdateBillStatusMutation({
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    onCompleted: (data) => {
      const bill = data.updateBillStatus?.bills?.[0] as BillNode;
      if (bill) {
        dispatch(setCurrentBill(bill));
      }
      properties.closeModal();
      enqueueSnackbar(`La facture ${currentBill.title} a bien été envoyée`, {
        variant: 'success',
      });
    },
  });

  const sendBill = async (submitValues: typeof defaultValues) => {
    const recipientsEmailsValue = submitValues.recipients.map(
      (recipient) => recipient.email
    );
    const ccRecipientsEmailsValue = submitValues.ccRecipients.map(
      (recipient) => {
        return recipient.email;
      }
    );

    await updateStatus({
      variables: {
        bills: [
          {
            id: currentBill.id,
          },
        ],
        status: BillingBillStatusChoices.Sent,
        sendingMethod: BillingBillSendingMethodChoices.Email,
        emailLang: submitValues.emailLang,
        emailSubject: submitValues.emailSubject,
        recipients: recipientsEmailsValue,
        ccRecipients: ccRecipientsEmailsValue,
      },
    });
  };

  const modalFooter = (
    <>
      <PongoButton
        variant={'text'}
        onClick={properties.closeModal}
        sx={{ mr: 1 }}
      >
        Annuler
      </PongoButton>
      <PongoButton onClick={form.handleSubmit(sendBill)} variant={'contained'}>
        Envoyer
      </PongoButton>
    </>
  );

  useEffect(() => {
    setEmailTemplate(
      getEmailTemplate(
        formatedTotal,
        isClientFrench,
        currentBill.dueDate,
        currentBill.billNumber,
        emailLang
      )
    );
  }, [
    formatedTotal,
    isClientFrench,
    currentBill.dueDate,
    currentBill.billNumber,
    emailLang,
  ]);

  useEffect(() => {
    if (currentMission && currentBill) {
      setRecipientsEmails(getRecipientsEmails(currentMission));
      setCcRecipientsEmails(getCcRecipientsEmails(currentMission));
    }
  }, [currentBill, currentMission]);

  useEffect(() => {
    if (currentBill.billNumber && currentMission.client) {
      setEmailSubject(
        getEmailSubject(
          currentBill.billNumber,
          currentMission.client,
          emailLang
        )
      );
      form.setValue(
        'emailSubject',
        getEmailSubject(
          currentBill.billNumber,
          currentMission.client,
          emailLang
        )
      );
    }
  }, [currentBill.billNumber, currentMission.client, emailLang, form]);

  return (
    <BillActionModal
      open={properties.open}
      title={'Envoyer la facture par e-mail'}
      closeModal={properties.closeModal}
      displayBillRows={false}
      formProvider={{
        form: form,
        submit: () => form.handleSubmit(sendBill)(),
      }}
      customFooter={modalFooter}
      bodyModal={
        <>
          <PolyAlert
            severity="warning"
            variant="outlined"
            sx={{
              my: 2,
              maxWidth: '40rem',
            }}
          >
            <PolyAlertTitle color="warning">Attention ! </PolyAlertTitle>
            Cliquer sur le bouton &quot;Envoyer&quot; enverra automatiquement un
            e-mail aux destinataires sélectionnés et le statut de votre facture
            passera au statut &quot;Envoyé&quot;
          </PolyAlert>

          <Stack direction={'row'} sx={{ my: 3, gap: 2 }}>
            <Controller
              control={form.control}
              name="emailSubject"
              render={({ value, onChange }) => (
                <TextField
                  value={value}
                  required
                  label="Objet"
                  size={'small'}
                  onChange={(event) => onChange(event.target.value)}
                  fullWidth
                />
              )}
            />

            <FormControl sx={{ width: 80 }} size="small">
              <InputLabel id="lang-select-label">Langue</InputLabel>
              <Controller
                name="emailLang"
                control={form.control}
                defaultValue={emailLang}
                render={({ onChange, ...props }) => (
                  <Select
                    required
                    labelId="lang-select-label"
                    id="lang-select"
                    onChange={(e) => {
                      setEmailLang(e.target.value);
                      form.setValue('emailLang', emailLang);
                      onChange(e);
                    }}
                    size="small"
                    label="Langue"
                    {...props}
                  >
                    {billEmailLanguageOptions.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          srcSet={`https://flagcdn.com/w40/${option.value
                            .slice(3, 5)
                            .toLowerCase()}.png 2x`}
                          src={`https://flagcdn.com/w20/${option.value
                            .slice(3, 5)
                            .toLowerCase()}.png`}
                          alt={option.label}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Stack>

          <TextField
            label="Template e-mail"
            value={emailTemplate}
            size={'small'}
            multiline
            fullWidth
            disabled
          />

          <Controller
            name={`recipients`}
            control={form.control}
            defaultValue={recipients || []}
            render={({ onChange, ...props }) => (
              <BillEmailAutocomplete
                controllerOnChange={onChange}
                controllerProps={{ label: 'Destinataires', ...props }}
                options={recipientsEmails}
                allEmployees={allEmployees}
                required
              />
            )}
          />

          <Controller
            name={`ccRecipients`}
            control={form.control}
            defaultValue={ccRecipients || []}
            render={({ onChange, ...props }) => (
              <BillEmailAutocomplete
                controllerOnChange={onChange}
                controllerProps={{ label: 'Copie', ...props }}
                options={ccRecipientsEmails}
                allEmployees={allEmployees}
                required={false}
              />
            )}
          />
        </>
      }
    />
  );
};

export default BillEmailModal;
