import { ArrowForward } from '@mui/icons-material';
import { Stack, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';

interface DateRangeDisplayCellProps {
  startDate: string;
  endDate: string;
}

const formatDate = (date: string) => {
  return moment(date).format('DD/MM/yyyy').toString();
};

const DateRangeDisplayCell = ({
  startDate,
  endDate,
}: DateRangeDisplayCellProps) => {
  const isExpired = moment(endDate).isBefore(moment());
  return isExpired ? (
    <Tooltip title="Expiré" arrow followCursor>
      <Stack sx={{ position: 'relative', bottom: '5px' }}>
        <Typography
          sx={{ verticalAlign: 'middle', color: 'darkRed.main' }}
          variant="label"
        >
          {formatDate(startDate)}
          <ArrowForward
            sx={{
              padding: 0,
              p: '0 5px',
              transform: 'translate(0,7px) scale(0.9)',
              color: 'darkRed.main',
            }}
          />
          {formatDate(endDate)}
        </Typography>
      </Stack>
    </Tooltip>
  ) : (
    <Stack
      sx={{ width: '30%', position: 'relative', bottom: '5px' }}
      alignContent={'left'}
    >
      <Typography sx={{ verticalAlign: 'middle' }} variant="label">
        {formatDate(startDate)}
        <ArrowForward
          sx={{
            padding: 0,
            p: '0 5px',
            transform: 'translate(0,7px) scale(0.9)',
            color: 'text.primary',
          }}
        />
        {formatDate(endDate)}
      </Typography>
    </Stack>
  );
};

export default DateRangeDisplayCell;
