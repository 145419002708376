import { AddCircleOutline, GroupAdd } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Fade,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { DateRangePicker } from 'components/commons/DateRangePicker';
import UploadFileForm from 'components/commons/FileTransfer/UploadFile/UploadFileForm';
import InfoTooltip from 'components/commons/InfoTooltip';
import GridItem from 'components/MissionFollowUp/GridItem';
import LabelTextFieldGrid from 'components/MissionFollowUp/LabelTextFieldGrid';
import PolyFooter from 'components/MUIOverload/PolyFooter';
import PongoButton from 'components/MUIOverload/PongoButton';
import {
  ActivitiesActivityBillingTypeChoices,
  ActivityNode,
  Currencies,
  PurchaseOrderNode,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import {
  comaStringToFloat,
  dotToComa,
  totalFormat,
} from 'pages/ActivityPage/utils';
import {
  isNumberPositive,
  requiredForm,
} from 'pages/MissionFollowUp/formValidators';
import {
  CurrenciesList,
  CurrenciesSymbols,
  SaveButtonName,
} from 'poly-constants';
import React, { ReactElement, useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'store';
import { getFileNameFromPath } from 'utils';

import { AddressType } from '../../MissionFollowUp/BillDetail/utils';
import PolyAlert from '../PolyAlert';
import PolyAlertTitle from '../PolyAlertTitle';
import PoFormCollaborators from './PoFormCollaborators';
import PoFormSubMission from './PoFormSubMission';
import PurchaseOrderFormTasks from './PurchaseOrderFormTasks';

function getHasBillingOutsideDate(
  currentPurchaseOrder: PurchaseOrderNode,
  period: [Date, Date]
) {
  return _.some(
    currentPurchaseOrder.bills,
    (bill) =>
      moment(bill.periodEnding).isAfter(period[1], 'month') ||
      moment(bill.periodBeginning).isBefore(period[0], 'month')
  );
}

function getHasRevenueOutsideDate(
  currentPurchaseOrder: PurchaseOrderNode,
  period: [Date, Date]
) {
  return _.some(currentPurchaseOrder.revenues, (revenue) => {
    return (
      !moment(revenue.month).isBetween(period[0], period[1], 'month', '[]') &&
      revenue.amount &&
      revenue.amount !== 0
    );
  });
}

interface Task {
  id: string;
  name: string;
  amount: string;
  periodBeginning: string;
  periodEnding: string;
}

interface DefaultValues {
  periodStart: Date;
  periodEnd: Date;
  isSubMission: boolean;
  billingAddresses: Array<AddressType>;
}

interface PurchaseOrderFormProps {
  defaultValues: DefaultValues;
  cancelCallback?: () => void;
  mode?: PoFormMode;
  loading: boolean;
  selectedActivity?: ActivityNode;
}

// TODO delete this enum in favor of separate components
export enum PoFormMode {
  CREATE,
  EDIT,
  DUPLICATE,
}

export default function PurchaseOrderForm({
  defaultValues,
  cancelCallback,
  loading,
  mode,
  selectedActivity,
}: PurchaseOrderFormProps): ReactElement {
  const currentMissionInStore = useSelector(
    (state) => state.activity.currentMission as ActivityNode
  );
  const currentMission = selectedActivity
    ? selectedActivity
    : currentMissionInStore;
  const isTmContracts =
    currentMission.billingType === ActivitiesActivityBillingTypeChoices.Tm;
  const currentPurchaseOrder = useSelector(
    (state) => state.purchaseOrder.currentPurchaseOrder
  );
  const allOtherPurchaseOrder = useSelector(
    (state) => state.purchaseOrder.purchaseOrders
  ).filter((po) => {
    return po.id !== currentPurchaseOrder.id;
  });
  const [hasBillingOutsideDate, setHasBillingOutsideDate] =
    useState<boolean>(false);
  const [hasRevenueOutsideDate, setHasRevenueOutsideDate] =
    useState<boolean>(false);
  const [hasTasksOutsideOfDates, setHasTasksOutsideOfDates] =
    useState<boolean>(false);
  const {
    register,
    errors,
    setError,
    clearErrors,
    setValue,
    watch,
    control,
    getValues,
  } = useFormContext();

  const tasks = watch('tasks') as Task[];
  const isSubMission = watch('isSubMission') as boolean;
  const currentCurrency = watch('currency') as Currencies;
  const smAdrs = _.flatMap(watch('poSubMissions'), (adr) => adr.collaborators);

  const hoverText =
    'Ce champ provient des informations de mission que vous pouvez modifier à partir du dashboard.';

  const handleDateRangeChange = (period: [Date, Date]) => {
    setValue('periodStart', period[0]);
    setValue('periodEnd', period[1]);

    if (mode === PoFormMode.EDIT) {
      setHasBillingOutsideDate(
        getHasBillingOutsideDate(currentPurchaseOrder, period)
      );
      setHasRevenueOutsideDate(
        getHasRevenueOutsideDate(currentPurchaseOrder, period)
      );
    }

    setHasTasksOutsideOfDates(
      _.some(tasks, (task) => {
        return !(
          moment(task.periodBeginning).isBetween(
            moment(period[0]),
            moment(period[1]),
            'day',
            '[]'
          ) &&
          moment(task.periodEnding).isBetween(
            moment(period[0]),
            moment(period[1]),
            'day',
            '[]'
          )
        );
      })
    );
  };
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'attachments',
    keyName: 'key',
  });

  const {
    fields: taskFields,
    append: taskAppend,
    remove: taskRemove,
  } = useFieldArray({
    control,
    name: 'tasks',
    keyName: 'key',
  });

  const getErrorsAlerts = () => {
    const listError = [];
    if (errors.smAdrs?.type === 'missingFieldTM') {
      listError.push(
        <PolyAlert
          variant="outlined"
          severity={'error'}
          sx={{ mt: 2.5 }}
          key={'smMissingFieldTM'}
        >
          Veuillez ajouter au moins un collaborateur ou une prestation
        </PolyAlert>
      );
    }
    if (errors.document?.file?.type === 'required') {
      listError.push(
        <PolyAlert
          variant="outlined"
          severity={'error'}
          sx={{ mt: 2.5 }}
          key={'attachment'}
        >
          Veuillez téléverser un bon de commande
        </PolyAlert>
      );
    } else if (errors.document?.file) {
      listError.push(
        <PolyAlert
          variant="outlined"
          severity={'error'}
          sx={{ mt: 2.5 }}
          key={errors?.document?.file?.message}
        >
          {errors?.document?.file?.message}
        </PolyAlert>
      );
    }

    _.forEach(errors.attachments, (error) => {
      if (error?.file) {
        listError.push(
          <PolyAlert
            variant="outlined"
            severity={'error'}
            sx={{ mt: 2.5 }}
            key={error?.file?.message}
          >
            {error?.file?.message}
          </PolyAlert>
        );
      }
    });

    return listError;
  };

  const appendField = () => {
    append({
      id: '',
      filename: '',
      file: undefined,
    });
  };

  const total = watch('total')?.toString() || '';
  if (!isTmContracts && total.includes('.')) {
    setValue('total', dotToComa(total));
  }

  // when there is no document or all document are already set, append upload field
  if (!_.find(fields, { filename: '' })) {
    appendField();
  }

  const validateFiles = (file: FileList, isDocument: boolean) => {
    if (file && file.length > 0) {
      const filename = getFileNameFromPath(file.item(0)?.name);
      if (
        isDocument &&
        _.find(
          fields,
          (field) => getFileNameFromPath(field.filename) === filename
        )
      ) {
        return `Le fichier "${filename}" est déjà présent en tant que bon de commande / justificatif.`;
      } else {
        const documentName = getFileNameFromPath(
          getValues('document.filename')
        );
        if (documentName === filename) {
          return `Le fichier "${filename}" est déjà présent en tant que bon de commande / justificatif.`;
        }
        return true;
      }
    }
    return true;
  };

  const validateTasksLength = () => {
    if (tasks.length === 1 && !_.get(errors, 'tasks.0.name')) {
      setError('tasks.0.name', {
        type: 'taskNumber',
        message: `Il n'est pas possible d'ajouter qu'un seul chantier`,
      });
    } else if (
      tasks.length !== 1 &&
      _.get(errors, 'tasks.0.name')?.type === 'taskNumber'
    ) {
      clearErrors('tasks.0.name');
    }
  };
  const validateTasksTotal = () => {
    if (tasks.length > 1) {
      const totalNumber = comaStringToFloat(total);
      const totalTasks = _.round(
        _.sumBy(tasks, (tasks) => comaStringToFloat(tasks.amount)),
        2
      );
      if (totalNumber !== totalTasks && !_.get(errors, 'total')) {
        setError('total', {
          type: 'totalTask',
          message:
            'La somme des montants de vos chantiers doit être égale au montant total',
        });
      } else if (
        totalNumber === totalTasks &&
        _.get(errors, 'total') &&
        totalNumber > 0
      ) {
        clearErrors('total');
      }
    }
  };
  const cancelTasksValidation = () => {
    if (
      _.get(errors, 'total')?.type === 'totalTask' ||
      _.get(errors, 'tasks.0.name')
    ) {
      clearErrors('total');
      clearErrors('tasks.0.name');
    }
  };

  const validatedCA = _.sumBy(currentPurchaseOrder.revenues, (revenue) =>
    revenue.validated || revenue.isAutoValidated ? revenue.amount : 0
  );
  function isSuperiorOrEqual(value?: string) {
    if (value !== undefined && comaStringToFloat(value) >= validatedCA) {
      return true;
    }
    return 'Le montant doit être supérieur ou égal au CA validé du BDC';
  }

  useEffect(() => {
    validateTasksLength();
    validateTasksTotal();
    if (tasks.length === 0) {
      cancelTasksValidation();
    }
  });

  useEffect(() => {
    if (isSubMission && _.isEmpty(smAdrs) && !errors.smAdrs) {
      setError('smAdrs', { type: 'missingFieldTM' });
    } else if (!_.isEmpty(smAdrs) && errors.smAdrs) {
      clearErrors('smAdrs');
    }
  }, [clearErrors, errors, isSubMission, setError, smAdrs]);

  const [includeAllCollaborators, setIncludeAllCollaborators] = useState(false);
  // TODO: migrate all Grids with style to Grid using component Box with sx

  const purchaseOrderBillsSum = _.sumBy(
    currentPurchaseOrder.bills,
    (bill) => bill.total
  );

  const totalIsTooLow =
    watch('total') && comaStringToFloat(watch('total')) < purchaseOrderBillsSum;

  return (
    <>
      {mode === PoFormMode.EDIT && totalIsTooLow && (
        <PolyAlert variant="outlined" severity="error" sx={{ mt: 2 }}>
          <PolyAlertTitle color="error">
            Impossible d&apos;éditer ce bon de commande !
          </PolyAlertTitle>
          Le montant du bon de commande doit être supérieur ou égal à la somme
          des factures{' '}
          <b>
            ({totalFormat(purchaseOrderBillsSum, currentPurchaseOrder.currency)}
            )
          </b>
          .
        </PolyAlert>
      )}

      <Grid container spacing={0}>
        <Grid container item xs={12}>
          <Tooltip title={hoverText} arrow>
            <Grid container xs={6}>
              <LabelTextFieldGrid
                disabled
                title="Nom de mission"
                defaultValue={currentMission.name}
                sizegrid={12}
              />
            </Grid>
          </Tooltip>
          {currentMission.billingInformation?.refMarket && (
            <Tooltip title={hoverText} arrow>
              <Grid container xs={6}>
                <LabelTextFieldGrid
                  disabled
                  title="Référence"
                  defaultValue={currentMission.billingInformation?.refMarket}
                  sizegrid={12}
                />
              </Grid>
            </Tooltip>
          )}
        </Grid>
        <Grid container item xs={12}>
          <LabelTextFieldGrid
            customspacingtop={20}
            inputRef={register({ required: requiredForm })}
            name={'purchaseOrderName'}
            errorform={errors.purchaseOrderName}
            sizegrid={6}
            required
            title="Nom de bon de commande"
          />
          <LabelTextFieldGrid
            customspacingtop={20}
            inputRef={register({
              required: requiredForm,
              validate: {
                duplicateNumber: (poNumber) =>
                  !_.find(allOtherPurchaseOrder, { number: poNumber }) ||
                  'Un bon de commande avec ce numéro existe déjà',
              },
            })}
            name={'purchaseOrderNum'}
            errorform={errors.purchaseOrderNum}
            sizegrid={6}
            required
            title="Numéro de bon de commande"
          />
        </Grid>
        <Grid container item xs={12}>
          <LabelTextFieldGrid
            customspacingtop={20}
            inputRef={register({
              required: {
                value: !isTmContracts && requiredForm.value,
                message: requiredForm.message,
              },
              validate: !isTmContracts
                ? {
                    positive: isNumberPositive,
                    superiorOrEqual: isSuperiorOrEqual,
                  }
                : {},
            })}
            inputProps={{
              itemType: 'number',
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position={'start'}>
                  {CurrenciesSymbols[currentCurrency]}
                </InputAdornment>
              ),
            }}
            name={'total'}
            errorform={errors.total}
            sizegrid={6}
            required={!isTmContracts}
            title={
              isTmContracts
                ? 'Montant maximal régie (optionnel)'
                : 'Montant total (HT)'
            }
          />
          <Controller
            render={({ value, onChange }) => {
              return (
                <LabelTextFieldGrid
                  value={value}
                  select
                  sizegrid={6}
                  customspacingtop={20}
                  title={'Monnaie'}
                  onChange={(event) => onChange(event.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position={'start'}>
                        {CurrenciesSymbols[currentCurrency]}
                      </InputAdornment>
                    ),
                  }}
                >
                  {CurrenciesList.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </LabelTextFieldGrid>
              );
            }}
            name={'currency'}
            control={control}
          />
          <GridItem customspacingtop={20} sizegrid={6}>
            <DateRangePicker
              disableFuture={false}
              minDate={moment(currentMission.startDate).toDate()}
              maxDate={moment(currentMission.expirationDate).toDate()}
              handleDateRangeChange={handleDateRangeChange}
              dateRange={[defaultValues.periodStart, defaultValues.periodEnd]}
            />
            {mode === PoFormMode.EDIT &&
            (hasBillingOutsideDate || hasRevenueOutsideDate) ? (
              <Typography
                sx={{
                  color: 'error.main',
                  ml: '14px',
                  mt: '4px',
                  fontSize: '12px',
                }}
              >
                Le BDC a des{' '}
                {hasRevenueOutsideDate ? 'CA déclarés' : 'factures'} hors des
                nouvelles dates.
              </Typography>
            ) : (
              <></>
            )}
            {hasTasksOutsideOfDates && (
              <Typography
                sx={{
                  color: 'error.main',
                  ml: '14px',
                  mt: '4px',
                  fontSize: '12px',
                }}
              >
                Il y a au moins un chantier dont les dates sont hors de celles
                du BDC
              </Typography>
            )}
          </GridItem>
          <Grid container item xs={6} alignItems="center">
            <Controller
              render={({ value, onChange }) => {
                return (
                  <LabelTextFieldGrid
                    value={value}
                    select
                    sizegrid={12}
                    customspacingtop={20}
                    title={'Adresse'}
                    onChange={(event) => onChange(event.target.value)}
                    errorform={errors.address}
                    required
                  >
                    {defaultValues.billingAddresses.map((address, index) => (
                      <MenuItem key={index} value={address.id}>
                        {address.label}
                      </MenuItem>
                    ))}
                  </LabelTextFieldGrid>
                );
              }}
              name={'address'}
              control={control}
            />
            {/* TODO: add tooltip to display address info */}
            {/* <InfoTooltip
              title={getValues('address')}
              sx={{ pt: 2 }}
              placement="bottom"
            /> */}
          </Grid>
        </Grid>
        <Stack direction={'column'}>
          <Stack direction={'row'} alignItems={'center'} mt={2}>
            {!!currentMission.subActivities?.length && (
              <Box>
                <Controller
                  name={'isSubMission'}
                  render={({ onChange, value }) => {
                    return (
                      <Switch
                        value={value}
                        defaultChecked={defaultValues.isSubMission}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={(_, checked) => {
                          if (checked) {
                            clearErrors('collaborators');
                          } else {
                            clearErrors('subMissions');
                          }
                          onChange(checked);
                        }}
                      />
                    );
                  }}
                />
                Découpage en sous missions
              </Box>
            )}
            {!isSubMission && isTmContracts && (
              <PongoButton
                variant={'contained'}
                sx={{ ml: 2 }}
                buttonStyle="secondary"
                size={'small'}
                startIcon={<GroupAdd />}
                onClick={() => setIncludeAllCollaborators(true)}
              >
                Ajouter tous les collaborateurs
              </PongoButton>
            )}
          </Stack>
          {!!currentPurchaseOrder.bills?.length && (
            <Typography sx={{ fontSize: 12, color: 'gray', mt: 1, ml: '15px' }}>
              Toute modification n’affectera pas les factures déjà créées.
            </Typography>
          )}
        </Stack>
        <Grid component={Box} container item xs={12} sx={{ marginTop: '10px' }}>
          {isTmContracts &&
            isSubMission &&
            _.map(currentMission.subActivities, (subActivity, index) => {
              return (
                <PoFormSubMission
                  key={`poSubMissions.${index}`}
                  fieldName={`poSubMissions.${index}`}
                  subMission={subActivity as ActivityNode}
                  mode={mode}
                />
              );
            })}
          {isTmContracts && !isSubMission && (
            <PoFormCollaborators
              fieldName={'collaborators'}
              includeAllCollaborators={includeAllCollaborators}
              setIncludeAllCollaborators={setIncludeAllCollaborators}
              mode={mode}
            />
          )}
          {!isTmContracts && (
            <React.Fragment>
              {_.map(taskFields, (fields, index) => {
                return (
                  <Fade in={true} key={fields.key}>
                    <Grid item xs={12}>
                      <PurchaseOrderFormTasks
                        formName={`tasks.${index}`}
                        selfDelete={() => taskRemove(index)}
                        purchaseOrdersRange={{
                          start: watch('periodStart'),
                          end: watch('periodEnd'),
                        }}
                      />
                    </Grid>
                  </Fade>
                );
              })}
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Button
                  onClick={() => {
                    const defaultValues = {
                      name: '',
                      amount: '',
                      periodBeginning: watch('periodStart'),
                      periodEnding: watch('periodEnd'),
                    };
                    if (taskFields.length === 0) {
                      taskAppend(defaultValues);
                    }
                    taskAppend(defaultValues);
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      color: 'primary',
                    }}
                  >
                    <AddCircleOutline sx={{ m: 0.5 }} />
                    <span>
                      {taskFields.length === 0
                        ? 'Découper en chantiers'
                        : 'Ajouter un chantier'}
                    </span>
                  </Box>
                </Button>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <GridItem
          title={'Bon de commande / justificatif'}
          customtend={
            !watch('document').filename ? (
              <>
                <InfoTooltip
                  title={
                    'Ajouter le document du bon de commande ou tout justificatif contractuel de la commande du client'
                  }
                  placement="top"
                />
                <Controller
                  render={({ value, onChange }) => (
                    <FormControlLabel
                      label={
                        <Typography variant="label">
                          Pas encore de justificatif
                        </Typography>
                      }
                      checked={value}
                      control={
                        <Checkbox
                          onChange={({ target: { checked } }) => {
                            onChange(checked);
                            checked && clearErrors('document');
                          }}
                          size="small"
                        />
                      }
                      sx={{ ml: 3, mr: 0.5 }}
                    />
                  )}
                  name="hasTemporaryDocument"
                  control={control}
                  style={{ fontSize: '12px' }}
                />
                <InfoTooltip
                  title={
                    'Vous devrez ajouter un justificatif plus tard afin de créer des factures'
                  }
                />
              </>
            ) : (
              <></>
            )
          }
          customspacingtop={20}
          sizegrid={12}
          required={!watch('hasTemporaryDocument')}
        >
          {!watch('hasTemporaryDocument') && (
            <UploadFileForm
              required={true}
              formname={'document'}
              validate={validateFiles}
              isEditing={true}
            />
          )}
        </GridItem>
      </Grid>
      <Grid container item xs={12}>
        <GridItem
          title={'Fichiers Annexes'}
          customspacingtop={20}
          sizegrid={12}
        >
          {_.map(fields, (field, index) => {
            const name = `attachments[${index}]`;
            return (
              <UploadFileForm
                key={field.key}
                callback={() => appendField()}
                deleteCallback={() => remove(index)}
                formname={name}
                validate={validateFiles}
                isEditing={true}
              />
            );
          })}
        </GridItem>
      </Grid>
      {getErrorsAlerts()}
      <PolyFooter>
        {cancelCallback && (
          <PongoButton variant="text" sx={{ mr: 1 }} onClick={cancelCallback}>
            Annuler
          </PongoButton>
        )}
        <PongoButton
          disabled={
            !_.isEmpty(errors) ||
            (mode === PoFormMode.EDIT &&
              (hasBillingOutsideDate ||
                hasRevenueOutsideDate ||
                totalIsTooLow)) ||
            hasTasksOutsideOfDates
          }
          loading={loading}
          type={'submit'}
          variant={'contained'}
          color={'primary'}
        >
          {SaveButtonName}
        </PongoButton>
      </PolyFooter>
    </>
  );
}
