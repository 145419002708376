import _ from 'lodash';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

export interface providerCommentsInterface {
  activityId: string;
  week: string;
  comment: string;
}

export interface TableContext {
  invalidWeeks: { [week: string]: boolean };
  setInvalidWeeks: (p: { [week: string]: boolean }) => void;
  providerOvertimeWeeks: { [week: string]: boolean };
  setProviderOvertimeWeeks: (p: { [week: string]: boolean }) => void;
  lockedWeeks: {
    [week: string]: Array<{
      activityId: string;
      timeSpent: number;
    }>;
  };
  providerComments: providerCommentsInterface[] | [];
  setProviderComments: (p: providerCommentsInterface[] | []) => void;
  needToAddCommentWeeks: Array<string>;
}

export const ValueContext = createContext<TableContext>({
  invalidWeeks: {},
  lockedWeeks: {},
  setInvalidWeeks: () => {
    throw new Error('function setInvalidWeeks not set');
  },
  providerOvertimeWeeks: {},
  setProviderOvertimeWeeks: () => {
    throw new Error('function setProviderOvertimeWeeks not set');
  },
  providerComments: [],
  setProviderComments: () => {
    throw new Error('function setProviderComments not set');
  },
  needToAddCommentWeeks: [],
});

export const useValueContext = () => {
  const tableContext = useContext(ValueContext);
  if (!tableContext) {
    throw new Error('useContext() can only be used  inside a table');
  }
  return tableContext;
};

const ValueContextProvider = (properties: PropsWithChildren<{}>) => {
  const [invalidWeeks, setInvalidWeeks] = useState({});
  const [providerOvertimeWeeks, setProviderOvertimeWeeks] = useState({});
  const [providerComments, setProviderComments] = useState<
    providerCommentsInterface[]
  >([]);

  const weeksWithOvertime: string[] = [];
  Object.keys(providerOvertimeWeeks).forEach((week) => {
    if (providerOvertimeWeeks[week as keyof typeof providerOvertimeWeeks]) {
      weeksWithOvertime.push(week);
    }
  });

  const needToAddCommentWeeks: string[] = [];
  if (weeksWithOvertime.length > 0) {
    _.map(weeksWithOvertime, (week) => {
      const weekHasComment = _.some(providerComments, (providerComment) => {
        return providerComment.comment.length && providerComment.week === week;
      });
      if (!weekHasComment) {
        needToAddCommentWeeks.push(week);
      }
    });
  }

  return (
    <ValueContext.Provider
      value={{
        invalidWeeks,
        setInvalidWeeks,
        providerOvertimeWeeks,
        setProviderOvertimeWeeks,
        lockedWeeks: {},
        providerComments,
        setProviderComments,
        needToAddCommentWeeks,
      }}
    >
      {properties.children}
    </ValueContext.Provider>
  );
};

export default ValueContextProvider;
