import { Stack } from '@mui/material';
import MissionTypeIcon from 'components/commons/MissionTypeIcon';
import { ActivityNode } from 'generated/graphql';
import { ActivitiesActivityBillingTypeChoices } from 'generated/graphql';
import React from 'react';

import { getActivityTypeName } from './utils';

interface MissionTypeCellProps {
  activity: ActivityNode;
}

export default function MissionTypeCell({ activity }: MissionTypeCellProps) {
  return (
    <Stack display="flex" flexDirection="row" alignItems="center" gap={0.75}>
      <MissionTypeIcon
        type={activity.type}
        isTmContracts={
          activity.billingType === ActivitiesActivityBillingTypeChoices.Tm
        }
        tooltip
      />
      {getActivityTypeName(activity)}
    </Stack>
  );
}
