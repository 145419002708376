import { Tooltip, Typography } from '@mui/material';
import React from 'react';

interface StringCellProps {
  data?: string;
  noTooltip?: boolean;
}

const StringCell = ({ data, noTooltip }: StringCellProps) => {
  return noTooltip ? (
    <Typography noWrap sx={{ fontSize: '14px' }}>
      {data || ''}
    </Typography>
  ) : (
    <Tooltip title={data || ''} placement="bottom-start" arrow>
      <Typography noWrap sx={{ fontSize: '14px' }}>
        {data || ''}
      </Typography>
    </Tooltip>
  );
};

export default StringCell;
