import PolyDataGrid from 'components/MUIOverload/PolyDataGrid';
import NumberCell from 'components/MUIOverload/PolyDataGrid/components/NumberCell';
import StringCell from 'components/MUIOverload/PolyDataGrid/components/StringCell';
import { PolyDataGridColumn } from 'components/MUIOverload/PolyDataGrid/PolyDataGridHead';
import { NumberOfDaysCollaboratorNode } from 'generated/graphql';
import _ from 'lodash';
import { totalFormat } from 'pages/ActivityPage/utils';
import React from 'react';
import { useSelector } from 'store';
import { compareFacturationTable, displayEmployeeNode } from 'utils';

interface CollaboratorsListViewProps {
  collaborators: NumberOfDaysCollaboratorNode[] | undefined;
}

export default function CollaboratorsListView(
  properties: CollaboratorsListViewProps
) {
  const currency = useSelector(
    (state) => state.purchaseOrder.currentPurchaseOrder.currency
  );

  const columns: PolyDataGridColumn<
    DeepExtractTypeSkipArrays<NumberOfDaysCollaboratorNode, []>
  >[] = [
    {
      headerName: 'Libellé de prestation',
      propertyName: 'data.collaboratorRate.title',
      isSortable: false,
      width: '40px',
      render: (data) =>
        StringCell({
          data: data.collaboratorRate.title,
        }),
    },
    {
      headerName: 'Collaborateur',
      isSortable: false,
      propertyName: 'data.collaboratorRate.collaborator.lastname',
      width: '35px',
      render: (data) =>
        StringCell({
          data: displayEmployeeNode(data.collaboratorRate.collaborator, false),
        }),
    },
    {
      headerName: 'Nombre de jours',
      propertyName: 'date',
      isSortable: false,
      width: '20px',
      render: (data) =>
        NumberCell({
          data: _.sumBy(data.daysNumbers, 'timeForTmContracts'),
        }),
    },
    {
      headerName: 'TJM',
      propertyName: 'TJM',
      isSortable: false,
      width: '20px',
      render: (data) =>
        StringCell({
          data: totalFormat(data.collaboratorRate.dailyRate, currency),
        }), // ajouter formatage currency
    },
    {
      headerName: 'Total',
      propertyName: 'date',
      isSortable: false,
      align: 'right',
      width: '10px',
      render: (data) =>
        StringCell({
          data: totalFormat(
            _.sumBy(data.daysNumbers, 'timeForTmContracts') *
              (data.collaboratorRate.dailyRate || 0),
            currency
          ),
        }),
    },
  ];

  const rowsActive = (
    _orderBy?: string | string[],
    _order?: 'asc' | 'desc'
  ) => {
    return _.clone(properties.collaborators || []).sort(
      compareFacturationTable(_orderBy, _order)
    );
  };

  return (
    <PolyDataGrid
      columns={columns}
      getRows={rowsActive}
      sx={{ height: '50px', cursor: 'auto' }}
    />
  );
}
