import { ApolloQueryResult } from '@apollo/client';
import { Add } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import Section from 'components/commons/Section';
import SectionTitle from 'components/commons/SectionTitle';
import NoAssignments from 'components/MissionPage/NoAssignments';
import PongoButton from 'components/MUIOverload/PongoButton';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import { useUserInfo } from 'components/User/UserProvider';
import {
  AssignmentNode,
  EmployeeNode,
  EmployeeQuery,
  Exact,
  Scalars,
} from 'generated/graphql';
import _ from 'lodash';
import { SECTION_BODY_HEIGHT } from 'poly-constants';
import React, { useMemo, useState } from 'react';

import EmployeeStaffingTable from './EmployeeStaffingTable';
import StaffingModal from './StaffingModal';
import StaffingSelect from './StaffingSelect';
import { getFilteredAssigments, StaffingStatusEnum } from './utils';

interface EmployeeStaffingProps {
  employee: EmployeeNode;
  isUserPage?: boolean;
  refetchEmployee?: (
    variables?:
      | Partial<
          Exact<{
            employeeId: Scalars['ID'];
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<EmployeeQuery>>;
}

const EmployeeStaffing = ({
  employee,
  isUserPage,
  refetchEmployee,
}: EmployeeStaffingProps) => {
  const ADMIN_BACK_OFFICE_FLAG = useEnvironmentVariable('admin_back_office');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAssignment, setSelectedAssignment] =
    useState<AssignmentNode>();

  const [staffingFilter, setStaffingFilter] = useState<StaffingStatusEnum>(
    StaffingStatusEnum.ALL
  );
  const filteredAssignments = useMemo(() => {
    const assignments = _.filter(
      employee.assignments,
      (assignment) =>
        !assignment.activity?.isShared && !assignment.activity?.mainActivity
    );
    return getFilteredAssigments(
      assignments as AssignmentNode[],
      staffingFilter
    );
  }, [employee.assignments, staffingFilter]);

  const { isSuperuser, isComex, isMissionChief } = useUserInfo();
  const isUserAllowedToEditStaffing = isSuperuser || isComex || isMissionChief;

  return (
    <Section>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <SectionTitle>Affectations</SectionTitle>
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <StaffingSelect
            staffingFilter={staffingFilter}
            setStaffingFilter={setStaffingFilter}
          />
          {ADMIN_BACK_OFFICE_FLAG &&
            isUserAllowedToEditStaffing &&
            !isUserPage && (
              <PongoButton
                variant="contained"
                size="small"
                startIcon={<Add />}
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                Ajouter
              </PongoButton>
            )}
        </Stack>
      </Stack>

      <Box
        sx={{
          height: SECTION_BODY_HEIGHT,
        }}
      >
        {filteredAssignments.length > 0 ? (
          <EmployeeStaffingTable
            assignments={filteredAssignments as AssignmentNode[]}
            setIsOpen={setIsModalOpen}
            setAssignment={setSelectedAssignment}
            isUserPage={isUserPage}
          />
        ) : (
          <NoAssignments activeFilter={staffingFilter} />
        )}
      </Box>

      <StaffingModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        employee={employee}
        assignment={selectedAssignment}
        setAssignment={setSelectedAssignment}
        refetchAllAssignments={refetchEmployee}
      />
    </Section>
  );
};

export default EmployeeStaffing;
