import PolyExportButton, {
  createCsvFile,
} from 'components/commons/PolyExportButton';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import {
  ActivityNode,
  useFetchExportProfitabilityMissionViewLazyQuery,
} from 'generated/graphql';
import { useSnackbar } from 'notistack';
import React from 'react';

interface ButtonProps {
  year: number;
  activity: ActivityNode;
  isTrimester?: boolean;
  isExcludingIntern?: boolean;
}

const ProfitabilityMissionViewExportButton = ({
  year,
  activity,
  isTrimester,
  isExcludingIntern,
}: ButtonProps) => {
  const { isShowingMarginRate } = useTableHeaderContext();
  const { enqueueSnackbar } = useSnackbar();
  const [exportQuery, { data }] =
    useFetchExportProfitabilityMissionViewLazyQuery({
      onCompleted: () => {
        if (data && data.exportProfitabilityMissionView) {
          const filename = `Rentabilite_${activity.name}.csv`;
          createCsvFile(data.exportProfitabilityMissionView, filename);
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
    });

  const exportTable = () => {
    exportQuery({
      variables: {
        year: year,
        activityId: activity.id,
        isTrimester: isTrimester,
        isExcludingIntern: isExcludingIntern,
        isProfitabilityInPercentage: isShowingMarginRate,
      },
    });
  };

  return (
    <PolyExportButton onClick={exportTable} sx={{ mr: 1 }}>
      Exporter ce tableau
    </PolyExportButton>
  );
};

export default ProfitabilityMissionViewExportButton;
