import { TextField } from '@mui/material';
import PolyAlert from 'components/commons/PolyAlert';
import React from 'react';
import { useFormContext } from 'react-hook-form';

interface RequestContentCommentProps {
  collaboratorComment: string;
  onUpdateCollaboratorComment: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
}
const RequestContentComment = (properties: RequestContentCommentProps) => {
  const { register, errors } = useFormContext();
  return (
    <div>
      <TextField
        id="input-with-icon-textfield"
        sx={{
          borderRadius: '5px',
          p: '3px 0',
          m: 'auto',
          width: '100%',
          minHeight: '40px',
          textAlign: 'center',
        }}
        name={'collaboratorComment'}
        defaultValue={properties.collaboratorComment}
        inputRef={register({
          required: true,
        })}
        variant={'outlined'}
        onChange={properties.onUpdateCollaboratorComment}
      />
      {errors.collaboratorComment && (
        <PolyAlert variant="outlined" severity="error">
          {'Merci de remplir la justification'}
        </PolyAlert>
      )}
    </div>
  );
};

export default RequestContentComment;
