import PageWrapper from 'components/commons/PageWrapper';
import PolySwitch from 'components/Navigation/PolySwitch';
import AccessWrapper from 'components/User/AccessWrapper';
import { EmployeeOccupations } from 'components/User/UserProvider';
import { EmployeesEmployeeGradeChoices } from 'generated/graphql';
import ActualRevenuePage from 'pages/RevenuePage/ActualRevenuePage';
import BilledRevenuePage from 'pages/RevenuePage/BilledRevenuePage';
import EstimatedRevenuePage from 'pages/RevenuePage/EstimatedRevenuePage';
import React from 'react';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';
import urlJoin from 'url-join';

const RevenuePage = () => {
  const { path } = useRouteMatch();

  return (
    <PageWrapper noLimit>
      <PolySwitch>
        <Redirect exact from={path} to={urlJoin(path, '/actual')} />
        <AccessWrapper
          occupations={[EmployeeOccupations.CONTROL, EmployeeOccupations.DG]}
          restrictMissionChief
          grades={[EmployeesEmployeeGradeChoices.Comex]}
        >
          <PolySwitch>
            <Route
              path={urlJoin(path, '/actual')}
              component={ActualRevenuePage}
            />
            <Route
              path={urlJoin(path, '/billed')}
              component={BilledRevenuePage}
            />
            <AccessWrapper
              occupations={[EmployeeOccupations.CONTROL]}
              grades={[EmployeesEmployeeGradeChoices.Comex]}
              restrictMissionChief
            >
              <Route
                path={urlJoin(path, '/estimated')}
                component={EstimatedRevenuePage}
              />
            </AccessWrapper>
          </PolySwitch>
        </AccessWrapper>
      </PolySwitch>
    </PageWrapper>
  );
};

export default RevenuePage;
