import { Stack, Typography } from '@mui/material';
import { BorderedTableCell } from 'components/commons/Tables/FlatTable';
import { isTrimesterStart } from 'components/commons/Tables/Header/ArrowsUpdateTrimesterTableHeader';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import ChildCollapsePolyTableRow from 'components/MUIOverload/PolyTableRow/ChildCollapsePolyTableRow';
import OutOfRangeTableCell from 'components/Revenue/Tables/RevenueTableCells/OutOfRangeTableCell';
import { isInRange } from 'components/Revenue/Tables/util';
import {
  ActivityNode,
  ActivityProfitNode,
  PublicHoliday,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { TransitionStatus } from 'react-transition-group/Transition';
import { getMissionLastActiveMonth } from 'utils';

import ProfitabilityDataComingCell from './Cells/ProfitabilityDataComingCell';
import { CallProfitabilityRenderValues, getYearToDateProfit } from './utils';

function getRowValues(
  month: moment.Moment,
  activity: ActivityNode,
  subActivity: ActivityNode,
  isTrimesterView: boolean | undefined
) {
  if (isTrimesterView && !isTrimesterStart(month)) {
    return (
      <React.Fragment
        key={`profitability-sub-mission-${subActivity.id}-${month.format(
          'MMMM'
        )}`}
      />
    );
  }
  const profitForMonth = _.find(subActivity.activityProfits, (profit) =>
    moment(isTrimesterView ? profit.trimester : profit.month).isSame(
      month,
      'month'
    )
  );

  if (profitForMonth) {
    return (
      <CallProfitabilityRenderValues
        key={`profitability-sub-mission-${subActivity.id}-${month.format(
          'MMMM'
        )}`}
        month={month}
        profit={profitForMonth}
        isTrimesterView={isTrimesterView}
      />
    );
  }

  const isSubActivityActive =
    isInRange(subActivity.startDate, subActivity.expirationDate, month) ||
    (!subActivity.startDate && !subActivity.expirationDate);

  if (!isSubActivityActive) {
    return (
      <OutOfRangeTableCell
        key={`profitability-sub-mission-${subActivity.id}-${month.format(
          'MMMM'
        )}`}
        title="La mission n'est pas active sur ce mois"
        colSpan={5}
      />
    );
  }

  const hasActivePurchaseOrder = _.some(
    activity.billingInformation?.purchaseOrders,
    ({ periodBeginning, periodEnding }) =>
      isInRange(periodBeginning, periodEnding, month)
  );

  if (!hasActivePurchaseOrder && !!activity.billingInformation) {
    return (
      <OutOfRangeTableCell
        key={`profitability-sub-mission-${subActivity.id}-${month.format(
          'MMMM'
        )}`}
        title="La mission n’a pas de BDC actif sur ce mois"
        colSpan={5}
      />
    );
  }

  return (
    <ProfitabilityDataComingCell
      key={`profitability-sub-mission-${subActivity.id}-${month.format(
        'MMMM'
      )}`}
      reason={
        isTrimesterView || !activity.billingInformation
          ? undefined
          : 'CA non validé'
      }
      colSpan={5}
    />
  );
}

interface ProfitabilitySubMissionRowProps {
  activity: ActivityNode;
  subActivity: ActivityNode;
  status: TransitionStatus;
  isTrimesterView?: boolean;
  publicHolidays?: PublicHoliday[];
  yearToDateMonthWorkDays?: number;
}

export default function ProfitabilitySubMissionRow({
  activity,
  subActivity,
  status,
  isTrimesterView,
  publicHolidays,
  yearToDateMonthWorkDays,
}: ProfitabilitySubMissionRowProps) {
  const { displayedMonths } = useTableHeaderContext();

  if (
    isTrimesterView &&
    (subActivity.activityProfits?.length === 0 ||
      (!_.some(displayedMonths, (month) =>
        isInRange(
          subActivity.startDate,
          getMissionLastActiveMonth(subActivity),
          month
        )
      ) &&
        subActivity.startDate &&
        subActivity.expirationDate))
  ) {
    return <></>;
  }

  const yearToDateActivityProfit = !isTrimesterView
    ? (getYearToDateProfit(
        subActivity.activityProfits,
        displayedMonths,
        publicHolidays as PublicHoliday[]
      ) as ActivityProfitNode)
    : undefined;

  return (
    <ChildCollapsePolyTableRow status={status}>
      <BorderedTableCell>
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <Typography variant={'body2'} color={'text.secondary'} sx={{ ml: 2 }}>
            {subActivity.name}
          </Typography>
        </Stack>
      </BorderedTableCell>
      {_.map(displayedMonths, (month) => {
        return getRowValues(month, activity, subActivity, isTrimesterView);
      })}
      {yearToDateActivityProfit && (
        <CallProfitabilityRenderValues
          key={`profitability-sub-mission-year-to-date-${
            subActivity.id
          }-${yearToDateActivityProfit?.month.format('MMMM')}`}
          month={yearToDateActivityProfit?.month}
          profit={yearToDateActivityProfit}
          yearToDateMonthWorkDays={yearToDateMonthWorkDays}
          isTrimesterView={isTrimesterView}
          isEtpCumulated={true}
        />
      )}
    </ChildCollapsePolyTableRow>
  );
}
