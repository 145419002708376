import WorkTwoToneIcon from '@mui/icons-material/WorkTwoTone';
import { Stack, Typography } from '@mui/material';
import React from 'react';

export default function NoMission() {
  return (
    <Stack
      spacing={1}
      sx={{
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Stack
        component="span"
        sx={{
          border: '1px dashed',
          color: 'info.main',
          width: 54,
          height: 54,
          borderRadius: '50%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <WorkTwoToneIcon
          color="info"
          sx={{
            fontSize: '30px',
          }}
        />
      </Stack>
      <Typography color={'text.secondary'} textAlign={'center'}>
        Aucune mission assignée
      </Typography>
    </Stack>
  );
}
