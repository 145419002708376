import { Stack } from '@mui/system';
import { GridColDef, GridToolbar, useGridApiRef } from '@mui/x-data-grid-pro';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import AvatarNameAndOccupationStack from 'components/commons/AvatarNameAndOccupationStack/AvatarNameAndOccupationStack';
import MuixTable from 'components/commons/MuixTable';
import SkillChip from 'components/commons/SkillChip';
import { GradeChip } from 'components/Phonebook/EmployeeCard/GradeChip';
import {
  EmployeeExperienceTextChoices,
  EmployeeRoleTextChoices,
  EmployeesEmployeeOccupationChoices,
  EmployeeStatusTextChoices,
} from 'components/Phonebook/utils';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import {
  EmployeeNode,
  EmployeesEmployeeContractTypeChoices,
  EmployeesEmployeeExperienceChoices,
  EmployeesEmployeeGradeChoices,
  EmployeeSkillNode,
} from 'generated/graphql';
import { isEqual } from 'lodash';
import moment from 'moment';
import { EMPTY_FIELD, POLY_DATE } from 'poly-constants';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'store';
import { setEmployeesTable } from 'store/adminTables';
import urljoin from 'url-join';

import { getEmployeeStatusChip } from './utils';

interface EmployeeTableProps {
  employees: EmployeeNode[];
}

const getGradeName = (employee: EmployeeNode) => {
  const contractType = employee.contractType;
  const grade = employee.grade;

  switch (grade) {
    case EmployeesEmployeeGradeChoices.Coman:
      return 'Coman';
    case EmployeesEmployeeGradeChoices.Comex:
      return 'Comex';
    default:
      break;
  }

  switch (contractType) {
    case EmployeesEmployeeContractTypeChoices.Provider:
      return 'Prestataire';
    case EmployeesEmployeeContractTypeChoices.StudentApprentice:
      return 'Alternant';
    case EmployeesEmployeeContractTypeChoices.Cdi:
      return 'CDI';
    case EmployeesEmployeeContractTypeChoices.Intern:
      return 'Stagiaire';
    default:
      break;
  }

  return '';
};

const EmployeeTable = ({ employees }: EmployeeTableProps) => {
  const EMPLOYEE_TABLE_SKILLS_COLUMN_FF = useEnvironmentVariable(
    'employee_table_skills_column'
  );
  const employeesTableState = useSelector(
    (state) => state.adminTables.employeesTable
  );
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();

  const { path } = useRouteMatch();
  const history = useHistory();

  const tableState = useRef<GridInitialStatePro>();

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Collaborateur',
      width: 250,
      disableReorder: true,
      valueGetter: ({ row: { firstName, lastName } }) =>
        `${lastName} ${firstName}`,
      renderCell: ({ row }) => (
        <AvatarNameAndOccupationStack employee={row} withOccupation={false} />
      ),
    },
    {
      field: 'occupation',
      headerName: 'Métier',
      type: 'singleSelect',
      disableReorder: true,
      valueOptions: [
        ...Object.values(EmployeesEmployeeOccupationChoices),
        EMPTY_FIELD,
      ],
      width: 150,
    },
    {
      field: 'experience',
      headerName: 'Expérience',
      type: 'singleSelect',
      disableReorder: true,
      valueOptions: [
        ...Object.values(EmployeeExperienceTextChoices),
        EMPTY_FIELD,
      ],
      width: 120,
      valueGetter: (params) =>
        params.value
          ? EmployeeExperienceTextChoices[
              params.value as EmployeesEmployeeExperienceChoices
            ]
          : EMPTY_FIELD,
    },
    {
      field: 'grade',
      headerName: 'Grade',
      type: 'singleSelect',
      disableReorder: true,
      valueOptions: Object.values(EmployeeRoleTextChoices),
      width: 150,
      valueGetter: (params) => {
        return params.row ? getGradeName(params.row) : EMPTY_FIELD;
      },
      renderCell: (params) => {
        return <GradeChip employee={params.row} displayAllGrades showLabel />;
      },
    },
    {
      field: 'hiringDate',
      headerName: "Date d'embauche",
      type: 'date',
      disableReorder: true,
      width: 150,
      valueGetter: (params) =>
        params.value ? new Date(params.value) : undefined,
      renderCell: (params) =>
        params.value ? moment(params.value).format(POLY_DATE) : EMPTY_FIELD,
    },
    {
      field: 'leavingDate',
      headerName: 'Date de départ',
      type: 'date',
      disableReorder: true,
      width: 150,
      valueGetter: (params) =>
        params.value ? new Date(params.value) : undefined,
      renderCell: (params) =>
        params.value ? moment(params.value).format(POLY_DATE) : EMPTY_FIELD,
    },
    { field: 'weeklyContract', headerName: 'Jours contractuels', width: 150 },
    {
      field: 'status',
      headerName: 'Statut',
      type: 'singleSelect',
      disableReorder: true,
      valueOptions: Object.values(EmployeeStatusTextChoices),
      width: 150,
      align: 'center',
      valueGetter: (params) => getEmployeeStatusChip(params.row).label,
      renderCell: (params) => getEmployeeStatusChip(params.row).jsx,
      sortComparator: (v1, v2) => {
        const order = Object.values(EmployeeStatusTextChoices);
        return order.indexOf(v1) - order.indexOf(v2);
      },
    },
  ];

  if (EMPLOYEE_TABLE_SKILLS_COLUMN_FF) {
    columns.splice(-1, 0, {
      field: 'skills',
      headerName: 'Compétences',
      disableReorder: true,
      width: 250,
      valueGetter: (params) => {
        return params.value.length > 0
          ? params.value.flatMap(
              (employeeSkill: EmployeeSkillNode) => employeeSkill.skill.name
            )
          : undefined;
      },
      renderCell: (params) => {
        return params.row.skills.length > 0 ? (
          <Stack direction="row" gap={1}>
            <SkillChip
              label={params.row.skills[0].skill.name}
              level={params.row.skills[0].level}
            />
            {params.row.skills.length > 1 && <SkillChip label="..." />}
          </Stack>
        ) : (
          EMPTY_FIELD
        );
      },
    });
  }

  return (
    <MuixTable
      apiRef={apiRef}
      onStateChange={() => {
        const currentState = apiRef.current.exportState();
        delete currentState.columns;
        if (
          !isEqual(currentState, employeesTableState) &&
          !!currentState.filter
        ) {
          tableState.current = currentState;
          dispatch(setEmployeesTable(currentState));
        }
      }}
      autoHeight
      ignoreDiacritics
      containerWidth={'100%'}
      rows={employees}
      columns={columns}
      pagination={true}
      pageSizeOptions={[10, 15, 25, 50]}
      initialState={employeesTableState}
      slots={{ toolbar: GridToolbar }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
        },
      }}
      onRowClick={({ id }) => {
        history.push(urljoin(path, id as string));
      }}
      sx={{
        '& .MuiDataGrid-cell': {
          ':focus': {
            outline: 'none',
          },
        },
        '& .MuiDataGrid-columnHeader': {
          ':focus , :focus-within': {
            outline: 'none',
          },
        },
        '.MuiDataGrid-row:hover': {
          cursor: 'pointer',
          backgroundColor: 'primary.light',
        },
        '.MuiDataGrid-row.Mui-hovered': {
          backgroundColor: 'primary.light',
        },
        /**
         * Positions the sort and filter buttons next to the header title on hover.
         * It works only on the last unpinned column.
         **/
        '.MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:last-of-type .MuiDataGrid-columnHeaderTitleContainer':
          {
            maxWidth: '113.2px', // Ensure the total width matches the header title plus the width of the sort button
          },
        borderRadius: '8px',
      }}
      rowSelection={false}
    />
  );
};

export default EmployeeTable;
