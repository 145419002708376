import { Box, Divider } from '@mui/material';
import {
  RiAccountBoxFill,
  RiBarChartBoxFill,
  RiBillFill,
  RiBriefcase4Fill,
  RiContactsBook2Fill,
  RiGridFill,
  RiHomeSmile2Fill,
  RiLayoutMasonryFill,
  RiLineChartFill,
  RiMenuAddFill,
  RiTeamFill,
  RiUserSearchFill,
} from '@remixicon/react';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import { useUserInfo } from 'components/User/UserProvider';
import {
  ActivityNode,
  EmployeesEmployeeContractTypeChoices,
  useAllActivitiesForMissionFollowupSplitQuery,
} from 'generated/graphql';
import { useSnackbar } from 'notistack';
import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  setActivities,
  setOtherCurrentMissions,
  setOtherPastMissions,
  setOwnCurrentMissions,
  setOwnPastMissions,
} from 'store/activity';
import { parameterToString } from 'utils';

import DrawerHoverMainMenu from './DrawerHoverMainMenu';
import DrawerList from './DrawerList';
import DrawerListItem from './DrawerListItem';
import { useNavbarContextProvider } from './NavbarContextProvider';
import {
  defaultDrawerStates,
  defaultToggleStates,
  drawersReducer,
  DrawerTitles,
  DrawerTypes,
  getUrlList,
  ToggleCollapseTypes,
  toggleReducer,
} from './utils';

export default function DrawerMainMenu() {
  const dispatch = useDispatch();
  const { isDrawerOpen } = useNavbarContextProvider();
  const ABSENCE_FLAG = useEnvironmentVariable('absences');
  const userInfo = useUserInfo();
  const {
    isSuperuser,
    isControl,
    isComex,
    isMissionChief,
    isExternalMissionChief,
    isTmActivityMissionChief,
    isDG,
  } = userInfo;
  const contract_type = userInfo.employee?.contractType;
  const weeklyContract = userInfo.employee?.weeklyContract;
  const isFullTimeCollaborator =
    contract_type === EmployeesEmployeeContractTypeChoices.Cdi &&
    weeklyContract === 5 &&
    userInfo.isStandardUser;
  const { enqueueSnackbar } = useSnackbar();

  const { pathname, search } = useLocation();
  const params = new URLSearchParams(search);

  const [drawerStates, dispatchDrawer] = useReducer(
    drawersReducer,
    defaultDrawerStates
  );
  const [collapseStates, toggleCollapseState] = useReducer(
    toggleReducer,
    defaultToggleStates
  );

  const lastToggledCollapse = React.useRef<ToggleCollapseTypes>();

  const urlList = getUrlList();
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>();
  const [tabOpen, setTabOpen] = useState('');
  let currentlyHovering = false;

  const handlePath = (path: string, fallbackPath?: string): string => {
    if (params.has('mission_id')) {
      return `${path}?${parameterToString(params, 'mission_id')}`;
    }
    return `${fallbackPath || path}`;
  };

  function handleHover() {
    currentlyHovering = true;
  }

  function handleClose() {
    setTabOpen('');
  }

  const handleMouseOver = (
    tabTo: string,
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    handleHover();
    setAnchorElement(event.currentTarget);
    setTabOpen(tabTo);
  };

  function handleMouseOut() {
    currentlyHovering = false;
    setTimeout(() => {
      if (!currentlyHovering) {
        handleClose();
      }
    }, 200);
  }

  const toggleCollapse = (
    toggleCollapseTypes: ToggleCollapseTypes,
    auto = false
  ) => {
    if (auto && toggleCollapseTypes === lastToggledCollapse.current) {
      return;
    }
    toggleCollapseState(toggleCollapseTypes);
    lastToggledCollapse.current = toggleCollapseTypes;
  };

  const hasAccessToMissionFollowUp =
    isControl || isComex || isDG || isMissionChief || isSuperuser;

  const { refetch } = useAllActivitiesForMissionFollowupSplitQuery({
    skip: !hasAccessToMissionFollowUp,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data.allActivitiesForMissionFollowupSplit) {
        dispatch(
          setOtherCurrentMissions(
            data.allActivitiesForMissionFollowupSplit
              ?.otherCurrent as ActivityNode[]
          )
        );
        dispatch(
          setOwnCurrentMissions(
            data.allActivitiesForMissionFollowupSplit
              .ownCurrent as ActivityNode[]
          )
        );
        dispatch(
          setOwnPastMissions(
            data.allActivitiesForMissionFollowupSplit.ownPast as ActivityNode[]
          )
        );
        dispatch(
          setOtherPastMissions(
            data.allActivitiesForMissionFollowupSplit
              .otherPast as ActivityNode[]
          )
        );
        dispatch(
          setActivities([
            ...(data.allActivitiesForMissionFollowupSplit
              .otherCurrent as ActivityNode[]),
            ...(data.allActivitiesForMissionFollowupSplit
              .ownCurrent as ActivityNode[]),
            ...(data.allActivitiesForMissionFollowupSplit
              .ownPast as ActivityNode[]),
            ...(data.allActivitiesForMissionFollowupSplit
              .otherPast as ActivityNode[]),
          ])
        );
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  function dispatchUser() {
    dispatchDrawer(DrawerTypes.USER);
  }

  function dispatchHome() {
    dispatchDrawer(DrawerTypes.HOME);
  }

  function dispatchPhonebook() {
    dispatchDrawer(DrawerTypes.PHONEBOOK);
  }

  function dispatchApplications() {
    dispatchDrawer(DrawerTypes.APPLICATIONS);
  }

  function dispatchMyActivity() {
    toggleCollapse(ToggleCollapseTypes.MY_ACTIVITY, true);
    if (pathname.includes(urlList['myActivityReporting'])) {
      dispatchDrawer(DrawerTypes.MY_ACTIVITY_REPORTING);
    } else if (pathname.includes(urlList['myAbsences'])) {
      dispatchDrawer(DrawerTypes.MY_ABSENCES);
    } else if (pathname.includes(urlList['myExceptionalActivities'])) {
      dispatchDrawer(DrawerTypes.MY_EXCEPTIONAL_ACTIVITIES);
    }
  }

  function dispatchEmployees() {
    dispatchDrawer(DrawerTypes.EMPLOYEES);
  }

  function dispatchActivities() {
    dispatchDrawer(DrawerTypes.ACTIVITIES);
  }

  function dispatchClients() {
    dispatchDrawer(DrawerTypes.CLIENTS);
  }

  function dispatchDashboard() {
    dispatchDrawer(DrawerTypes.DASHBOARD);
  }

  function dispatchActivity() {
    toggleCollapse(ToggleCollapseTypes.ACTIVITY, true);
    if (pathname.includes(urlList['collaborators'])) {
      dispatchDrawer(DrawerTypes.COLLABORATORS);
    } else if (pathname.includes(urlList['activityMonitoring'])) {
      dispatchDrawer(DrawerTypes.ACTIVITY_MONITORING);
    } else if (pathname.includes(urlList['exceptionalActivities'])) {
      dispatchDrawer(DrawerTypes.EXCEPTIONAL_ACTIVITIES);
    }
  }

  function dispatchRevenue() {
    toggleCollapse(ToggleCollapseTypes.REVENUE, true);
    if (pathname.includes(urlList['actualRevenue'])) {
      dispatchDrawer(DrawerTypes.ACTUAL_REVENUE);
    } else if (pathname.includes(urlList['estimatedRevenue'])) {
      dispatchDrawer(DrawerTypes.ESTIMATED_REVENUE);
    } else if (pathname.includes(urlList['billedRevenue'])) {
      dispatchDrawer(DrawerTypes.BILLED_REVENUE);
    }
  }

  function dispatchBilling() {
    toggleCollapse(ToggleCollapseTypes.BILLING, true);
    if (pathname.includes(urlList['purchaseOrders'])) {
      dispatchDrawer(DrawerTypes.PURCHASE_ORDER);
    } else if (pathname.includes(urlList['billingFollowUp'])) {
      dispatchDrawer(DrawerTypes.BILLING_FOLLOW_UP);
    } else if (pathname.includes(urlList['billDetail'])) {
      dispatchDrawer(DrawerTypes.BILLING_FOLLOW_UP);
    } else if (pathname.includes(urlList['paymentFollowUp'])) {
      dispatchDrawer(DrawerTypes.PAYMENT_FOLLOW_UP);
    } else if (pathname.includes(urlList['purchaseOrderDetail'])) {
      dispatchDrawer(DrawerTypes.PURCHASE_ORDER);
    } else if (pathname.includes(urlList['sectors'])) {
      dispatchDrawer(DrawerTypes.SECTORS);
    }
  }

  function dispatchReporting() {
    toggleCollapse(ToggleCollapseTypes.REPORTING, true);
    if (pathname.includes(urlList['profitability'])) {
      dispatchDrawer(DrawerTypes.PROFITABILITY);
    } else if (pathname.includes(urlList['analysis'])) {
      dispatchDrawer(DrawerTypes.ANALYSIS);
    }
  }

  useEffect(() => {
    if (pathname.includes(urlList['dashboard'])) {
      refetch();
      dispatchDashboard();
    } else if (pathname.includes(urlList['user'])) {
      dispatchUser();
    } else if (pathname.includes(urlList['home'])) {
      dispatchHome();
    } else if (pathname.includes(urlList['phonebook'])) {
      dispatchPhonebook();
    } else if (pathname.includes(urlList['applications'])) {
      dispatchApplications();
    } else if (pathname.includes('/my-activity/')) {
      dispatchMyActivity();
    } else if (pathname.includes(urlList['activities'])) {
      dispatchActivities();
    } else if (pathname.includes(urlList['employees'])) {
      dispatchEmployees();
    } else if (pathname.includes(urlList['clients'])) {
      dispatchClients();
    } else if (pathname.includes('/activity/')) {
      dispatchActivity();
    } else if (pathname.includes('/revenue/')) {
      dispatchRevenue();
    } else if (pathname.includes('/missions/')) {
      dispatchBilling();
    } else if (pathname.includes('/reporting/')) {
      dispatchReporting();
    } //eslint-disable-next-line
  }, [pathname]);
  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          minHeight: 0,
          overflowY: 'auto',
          overflowX: 'hidden',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          scrollbarWidth: 'none',
        }}
      >
        <DrawerList title="Menu Principal" isDrawerOpen={isDrawerOpen}>
          <DrawerListItem
            text={DrawerTitles.home}
            path={handlePath(`${urlList['home']}`)}
            icon={<RiHomeSmile2Fill />}
            showActiveStyle={drawerStates.home}
            handleMouseOver={handleMouseOver}
            handleMouseOut={handleMouseOut}
          />
          <DrawerListItem
            text={DrawerTitles.phonebook}
            path={handlePath(`${urlList['phonebook']}`)}
            icon={<RiAccountBoxFill />}
            showActiveStyle={drawerStates.phonebook}
            handleMouseOver={handleMouseOver}
            handleMouseOut={handleMouseOut}
          />
          <DrawerListItem
            text={DrawerTitles.applications}
            path={handlePath(`${urlList['applications']}`)}
            icon={<RiGridFill />}
            showActiveStyle={drawerStates.applications}
            handleMouseOver={handleMouseOver}
            handleMouseOut={handleMouseOut}
          />
          <DrawerListItem
            text={DrawerTitles.myActivity}
            path={handlePath(`${urlList['myActivityReporting']}`)}
            icon={<RiBriefcase4Fill />}
            showActiveStyle={drawerStates.myActivity}
            isMenuExpanded={collapseStates.myActivity}
            handleCollapse={() =>
              toggleCollapse(ToggleCollapseTypes.MY_ACTIVITY)
            }
            handleMouseOver={handleMouseOver}
            handleMouseOut={handleMouseOut}
          >
            <DrawerListItem
              text={DrawerTitles.myActivityReporting}
              showActiveStyle={drawerStates.myActivityReporting}
              path={handlePath(`${urlList['myActivityReporting']}`)}
            />
            {ABSENCE_FLAG && (
              <DrawerListItem
                text={DrawerTitles.myAbsences}
                showActiveStyle={drawerStates.myAbsences}
                path={handlePath(`${urlList['myAbsences']}`)}
              />
            )}
            {(isFullTimeCollaborator || isSuperuser) && (
              <DrawerListItem
                text={DrawerTitles.myExceptionalActivities}
                showActiveStyle={drawerStates.myExceptionalActivities}
                path={handlePath(`${urlList['myExceptionalActivities']}`)}
              />
            )}
          </DrawerListItem>
        </DrawerList>
        <Divider
          sx={{
            my: 2,
          }}
        />
        <DrawerList title="admin" isDrawerOpen={isDrawerOpen}>
          <DrawerListItem
            text={DrawerTitles.employees}
            icon={<RiUserSearchFill />}
            showActiveStyle={drawerStates.employees}
            path={handlePath(`${urlList['employees']}`)}
            handleMouseOver={handleMouseOver}
            handleMouseOut={handleMouseOut}
          />
          <DrawerListItem
            text={DrawerTitles.activities}
            icon={<RiMenuAddFill />}
            showActiveStyle={drawerStates.activities}
            path={handlePath(`${urlList['activities']}`)}
            handleMouseOver={handleMouseOver}
            handleMouseOut={handleMouseOut}
          />
          {(isControl || isSuperuser || isComex || isMissionChief) && (
            <DrawerListItem
              text={DrawerTitles.clients}
              icon={<RiContactsBook2Fill />}
              showActiveStyle={drawerStates.clients}
              path={handlePath(`${urlList['clients']}`)}
              handleMouseOver={handleMouseOver}
              handleMouseOut={handleMouseOut}
            />
          )}
        </DrawerList>

        {hasAccessToMissionFollowUp && (
          <>
            <Divider
              sx={{
                my: 2,
              }}
            />
            <DrawerList title="Suivi mission" isDrawerOpen={isDrawerOpen}>
              {!isControl && (
                <DrawerListItem
                  text={DrawerTitles.dashboard}
                  path={handlePath(`${urlList['dashboard']}`)}
                  icon={<RiLayoutMasonryFill />}
                  showActiveStyle={drawerStates.dashboard}
                  handleMouseOver={handleMouseOver}
                  handleMouseOut={handleMouseOut}
                />
              )}
              <DrawerListItem
                text={DrawerTitles.activity}
                path={handlePath(`${urlList['collaborators']}`)}
                icon={<RiTeamFill />}
                isMenuExpanded={collapseStates.activity}
                showActiveStyle={drawerStates.activity}
                handleCollapse={() =>
                  toggleCollapse(ToggleCollapseTypes.ACTIVITY)
                }
                handleMouseOver={handleMouseOver}
                handleMouseOut={handleMouseOut}
              >
                <DrawerListItem
                  text={DrawerTitles.collaborators}
                  showActiveStyle={drawerStates.collaborators}
                  path={handlePath(`${urlList['collaborators']}`)}
                />
                {(isTmActivityMissionChief ||
                  isControl ||
                  isComex ||
                  isSuperuser) && (
                  <DrawerListItem
                    text={DrawerTitles.activityMonitoring}
                    showActiveStyle={drawerStates.activityMonitoring}
                    path={handlePath(`${urlList['activityMonitoring']}`)}
                  />
                )}
                {(!!isMissionChief || isSuperuser) && (
                  <DrawerListItem
                    text={DrawerTitles.exeptionalActivities}
                    showActiveStyle={drawerStates.exeptionalActivities}
                    path={handlePath(`${urlList['exceptionalActivities']}`)}
                    isExact={false}
                    isStrict
                  />
                )}
              </DrawerListItem>
              {(isExternalMissionChief ||
                isComex ||
                isControl ||
                isSuperuser) && (
                <DrawerListItem
                  text={DrawerTitles.revenue}
                  icon={<RiLineChartFill />}
                  isMenuExpanded={collapseStates.revenue}
                  showActiveStyle={drawerStates.revenue}
                  handleCollapse={() =>
                    toggleCollapse(ToggleCollapseTypes.REVENUE)
                  }
                  path={handlePath(`${urlList['estimatedRevenue']}`)}
                  handleMouseOver={handleMouseOver}
                  handleMouseOut={handleMouseOut}
                >
                  <DrawerListItem
                    text={DrawerTitles.estimatedRevenue}
                    showActiveStyle={drawerStates.estimatedRevenue}
                    path={handlePath(`${urlList['estimatedRevenue']}`)}
                  />
                  <DrawerListItem
                    text={DrawerTitles.actualRevenue}
                    showActiveStyle={drawerStates.actualRevenue}
                    path={handlePath(`${urlList['actualRevenue']}`)}
                  />
                  <DrawerListItem
                    text={DrawerTitles.billedRevenue}
                    showActiveStyle={drawerStates.billedRevenue}
                    path={handlePath(`${urlList['billedRevenue']}`)}
                  />
                </DrawerListItem>
              )}
              <DrawerListItem
                text={DrawerTitles.billing}
                icon={<RiBillFill />}
                isMenuExpanded={collapseStates.billing}
                handleCollapse={() =>
                  toggleCollapse(ToggleCollapseTypes.BILLING)
                }
                showActiveStyle={drawerStates.billing}
                path={
                  isMissionChief || isComex || isControl || isSuperuser
                    ? handlePath(`${urlList['purchaseOrders']}`)
                    : handlePath(`${urlList['billingFollowUp']}`)
                }
                handleMouseOver={handleMouseOver}
                handleMouseOut={handleMouseOut}
              >
                {(isMissionChief || isComex || isControl || isSuperuser) && (
                  <DrawerListItem
                    text={DrawerTitles.purchaseOrders}
                    showActiveStyle={drawerStates.purchaseOrders}
                    path={handlePath(`${urlList['purchaseOrders']}`)}
                  />
                )}
                <DrawerListItem
                  text={DrawerTitles.billingFollowUp}
                  showActiveStyle={drawerStates.billingFollowUp}
                  path={handlePath(`${urlList['billingFollowUp']}`)}
                />
                <DrawerListItem
                  text={DrawerTitles.paymentFollowUp}
                  showActiveStyle={drawerStates.paymentFollowUp}
                  path={handlePath(`${urlList['paymentFollowUp']}`)}
                />
                {(isControl || isComex || isSuperuser) && (
                  <DrawerListItem
                    text={DrawerTitles.sectors}
                    showActiveStyle={drawerStates.sectors}
                    path={handlePath(`${urlList['sectors']}`)}
                    keepFilterWithSecondClick
                  />
                )}
              </DrawerListItem>
              {(isMissionChief || isComex || isControl || isSuperuser) && (
                <DrawerListItem
                  text={DrawerTitles.reporting}
                  icon={<RiBarChartBoxFill />}
                  isMenuExpanded={collapseStates.reporting}
                  handleCollapse={() =>
                    toggleCollapse(ToggleCollapseTypes.REPORTING)
                  }
                  showActiveStyle={drawerStates.reporting}
                  path={handlePath(`${urlList['profitability']}`)}
                  handleMouseOver={handleMouseOver}
                  handleMouseOut={handleMouseOut}
                >
                  <DrawerListItem
                    text={DrawerTitles.profitability}
                    showActiveStyle={drawerStates.profitability}
                    path={handlePath(`${urlList['profitability']}`)}
                  />
                  {(isControl || isComex || isSuperuser) && (
                    <DrawerListItem
                      text={DrawerTitles.analysis}
                      showActiveStyle={drawerStates.analysis}
                      path={handlePath(`${urlList['analysis']}`)}
                      keepFilterWithSecondClick
                    />
                  )}
                </DrawerListItem>
              )}
            </DrawerList>
          </>
        )}
      </Box>
      {!isDrawerOpen && (
        <DrawerHoverMainMenu
          anchorElement={anchorElement}
          tabOpen={tabOpen}
          drawerStates={drawerStates}
          handleClose={handleClose}
          handleHover={handleHover}
        />
      )}
    </>
  );
}
