import { Stack, Typography } from '@mui/material';
import AvatarInitial from 'components/commons/AvatarInitial/AvatarInitial';
import { EmployeeNode } from 'generated/graphql';
import { Moment } from 'moment';
import { POLY_DATE } from 'poly-constants';
import React from 'react';
import { displayEmployeeNode } from 'utils';

interface GridItemHeaderProps {
  title: string;
  icon?: JSX.Element;
  creator?: EmployeeNode;
  date?: Moment;
}

const GridItemHeader = (properties: GridItemHeaderProps) => {
  const { title, icon, creator, date } = properties;
  return (
    <Stack spacing={1}>
      <Stack spacing={2} direction={'row'} alignItems={'center'}>
        {icon && icon}
        <Typography variant={'h2Bold'} component="h2">
          {title}
        </Typography>
      </Stack>
      {(creator || date) && (
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <AvatarInitial employee={creator} />
          {displayEmployeeNode(creator)}
          <Typography>le {date?.format(POLY_DATE)}</Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default GridItemHeader;
