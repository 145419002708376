import PolyExportButton, {
  createCsvFile,
} from 'components/commons/PolyExportButton';
import { useFetchExportEmployeeReportingParametersLazyQuery } from 'generated/graphql';
import { useSnackbar } from 'notistack';
import React from 'react';

interface ButtonProps {
  year: number;
}
const OccupationAdrExportButton = ({ year }: ButtonProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [exportQuery, { data }] =
    useFetchExportEmployeeReportingParametersLazyQuery({
      onCompleted: () => {
        if (data && data.exportEmployeeReportingParameters) {
          const filename = `TJM_couts_par_role_${year.toString()}.csv`;
          createCsvFile(data.exportEmployeeReportingParameters, filename);
        }
      },
      onError: () => {
        enqueueSnackbar('Erreur lors de la récupération des données', {
          variant: 'error',
        });
      },
    });

  const exportTable = () => {
    exportQuery({
      variables: {
        year: year,
      },
    });
  };

  return (
    <PolyExportButton onClick={exportTable} sx={{ mr: 1 }}>
      Exporter ce tableau
    </PolyExportButton>
  );
};

export default OccupationAdrExportButton;
