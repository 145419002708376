import { Box, DialogContent } from '@mui/material';
import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import HistoryBillModified from 'components/MissionFollowUp/BillDetail/Modals/BillHistoryModal/HistoryDescription/HistoryBillModified';
import { HistoryChange } from 'components/MissionFollowUp/BillDetail/Modals/BillHistoryModal/utils';
import PolyModal from 'components/MUIOverload/PolyDialog/PolyModal';
import {
  EmployeeNode,
  FetchHistoryQuery,
  useFetchHistoryLazyQuery,
} from 'generated/graphql';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'store';
import { getFileNameFromPath } from 'utils';

interface BillHistoryModalProps {
  open: boolean;
  closeModal: () => void;
}

const BillHistoryModal = (properties: BillHistoryModalProps) => {
  const currentBill = useSelector((state) => state.purchaseOrder.currentBill);
  const { enqueueSnackbar } = useSnackbar();
  const [billHistory, setBillHistory] = useState({} as FetchHistoryQuery);

  const [getLoadingHistory, { loading: loadingHistory }] =
    useFetchHistoryLazyQuery({
      onCompleted: (data) => {
        setBillHistory(data);
      },
      onError: () => {
        enqueueSnackbar(`Impossible de récupérer l'historique`, {
          variant: 'error',
        });
      },
    });
  useEffect(() => {
    if (currentBill.id) {
      getLoadingHistory({ variables: { billId: currentBill.id } });
    }
  }, [properties.open, currentBill.id, getLoadingHistory]);

  return (
    <PolyModal
      maxWidth={'lg'}
      disableEnforceFocus
      open={properties.open}
      onClose={properties.closeModal}
      scroll={'paper'}
      polyDialogTitle={'Historique'}
      secondaryButtonName={'Fermer'}
    >
      <DialogContent sx={{ py: 0, maxHeight: 500 }}>
        {loadingHistory ? (
          <Box sx={{ width: 500 }}>
            <LoadingPlaceholder />
          </Box>
        ) : (
          <>
            {billHistory.history?.map((history, index) => {
              const changes =
                billHistory.history?.length === index + 1
                  ? []
                  : (history.changedFields as Array<HistoryChange>);

              return (
                <HistoryBillModified
                  key={index}
                  changes={changes}
                  employee={history.historyUser as EmployeeNode}
                  date={history.historyDate}
                  PDFFilename={getFileNameFromPath(
                    currentBill.billPdf?.filename
                  )}
                />
              );
            })}
          </>
        )}
      </DialogContent>
    </PolyModal>
  );
};

export default BillHistoryModal;
