import { Typography } from '@mui/material';
import { BillingPurchaseOrderCurrencyChoices } from 'generated/graphql';
import { totalFormat } from 'pages/ActivityPage/utils';
import React from 'react';

interface BillsAmountSumProps {
  euroSum: number;
}

export default function BillsAmountSum({ euroSum }: BillsAmountSumProps) {
  return (
    <Typography fontWeight="bold" textAlign="start">
      Montant total (HT) :{' '}
      {totalFormat(euroSum, BillingPurchaseOrderCurrencyChoices.Eur)}
    </Typography>
  );
}
