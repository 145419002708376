import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Button, Grid } from '@mui/material';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import _ from 'lodash';
import { Moment } from 'moment';
import moment from 'moment/moment';
import React, { PropsWithChildren } from 'react';

interface ArrowsUpdateTrimesterTableHeaderProperties {
  month: Moment;
  indicator?: JSX.Element;
}

export function getMonthNumber(month: Moment) {
  return Number.parseInt(month.format('M'));
}

export function getTrimesterNumber(month: Moment) {
  return Math.ceil(getMonthNumber(month) / 3);
}

export function isTrimesterStart(month: Moment) {
  return Number.parseInt(month.format('M')) % 3 === 1;
}

export function isCurrentTrimester(month: Moment) {
  const today = moment();
  return (
    month.isSame(today, 'year') &&
    getTrimesterNumber(month) === getTrimesterNumber(moment())
  );
}

export default function ArrowsUpdateTrimesterTableHeader({
  month,
  indicator,
  children,
}: PropsWithChildren<ArrowsUpdateTrimesterTableHeaderProperties>) {
  const { displayedMonths, incrementMonth, decrementMonth, currentYear } =
    useTableHeaderContext();

  const isBeforeArrowVisible = () => {
    return (
      _.some(
        displayedMonths.slice(0, 3),
        (displayedMonth) => displayedMonth === month
      ) && !currentYear.clone().startOf('years').isSame(month, 'month')
    );
  };

  const isNextArrowVisible = () => {
    return (
      _.some(
        displayedMonths.slice(-3),
        (displayedMonth) => displayedMonth === month
      ) && !currentYear.clone().set('month', 9).isSame(month, 'month')
    );
  };

  return (
    <Grid
      container
      sx={{ height: 1, width: '100%' }}
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Grid item xs={2} sx={{ height: '100%' }}>
        {isBeforeArrowVisible() ? (
          <Button
            sx={{
              minWidth: '10px',
              width: '10px',
              height: '47px',
              borderRadius: 0,
              color: 'white',
              backgroundColor: 'darkGrey.darker',
            }}
            aria-label={'Précédent'}
            onClick={() => {
              decrementMonth(3);
            }}
          >
            <NavigateBeforeIcon />
          </Button>
        ) : (
          <Box sx={{ minWidth: '10px', width: '10px' }} />
        )}
      </Grid>
      <Grid item xs={8} display={'flex'} justifyContent={'center'}>
        {children}
      </Grid>
      <Grid
        item
        xs={2}
        display={'flex'}
        justifyContent={'flex-end'}
        sx={{ height: '100%' }}
      >
        <>
          {indicator}
          {isNextArrowVisible() ? (
            <Button
              sx={{
                minWidth: '10px',
                width: '10px',
                borderRadius: 0,
                height: '47px',
                ml: 1,
                color: 'white',
                backgroundColor: 'darkGrey.darker',
              }}
              aria-label={'Suivant'}
              onClick={() => {
                incrementMonth(3);
              }}
            >
              <NavigateNextIcon />
            </Button>
          ) : (
            <Box sx={{ minWidth: '10px', width: '10px' }} />
          )}
        </>
      </Grid>
    </Grid>
  );
}
