import { List } from '@mui/material';
import React from 'react';

interface DrawerListProps {
  isDrawerOpen: boolean;
  children: React.ReactNode;
  title: string;
}

const GAP_BETWEEN_LIST_ITEMS = 2;

export default function DrawerList({
  isDrawerOpen,
  children,
}: DrawerListProps) {
  return (
    <List
      sx={{
        ...(!isDrawerOpen && { py: 0 }),
        display: 'flex',
        flexDirection: 'column',
        gap: GAP_BETWEEN_LIST_ITEMS,
      }}
    >
      {children}
    </List>
  );
}
