import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconButton, Stack, TableCell, TableCellProps } from '@mui/material';
import { SxProps } from '@mui/system';
import React from 'react';

interface CollapsePolyTableCellProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  hasProfits?: boolean;
  hasSubActivities?: boolean;
  className?: string;
  sx?: SxProps;
}

const CollapsePolyTableCell = (
  properties: CollapsePolyTableCellProps & TableCellProps
) => {
  const {
    open,
    setOpen,
    hasProfits = true,
    hasSubActivities = true,
    ...tableCellProperties
  } = properties;
  return (
    <TableCell
      {...tableCellProperties}
      className={properties.className}
      sx={properties.sx}
      onClick={() => setOpen(!open)}
      style={{ cursor: 'pointer' }}
    >
      <Stack
        justifyContent={'space-between'}
        alignItems={'center'}
        direction={'row'}
      >
        {properties.children}
        {hasProfits && hasSubActivities && (
          <IconButton size="small" data-testid={'collapseButton'}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        )}
      </Stack>
    </TableCell>
  );
};

export default CollapsePolyTableCell;
