import { DialogTitle, DialogTitleProps } from '@mui/material';
import React from 'react';

const PolyDialogTitle = (properties: DialogTitleProps) => {
  return (
    <DialogTitle
      {...properties}
      variant={'h3Bold'}
      sx={{ display: 'flex', pl: 0, pt: '5px', alignItems: 'center', gap: 1 }}
    />
  );
};

export default PolyDialogTitle;
