import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

import {
  EMPLOYEE_TABLE_PHONE_STYLE,
  EMPLOYEE_TABLE_STYLE,
} from '../EmployeeTable';
import EmployeeCardSkeleton from './EmployeeCardSkeleton';

export default function EmployeeTableSkeleton() {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Grid
        container
        rowSpacing={{ xs: 1.25, sm: 2.5 }}
        sx={isPhone ? EMPLOYEE_TABLE_PHONE_STYLE : EMPLOYEE_TABLE_STYLE}
      >
        {Array.from({ length: 72 }, (_, i) => (
          <Grid item key={i} sx={{ minWidth: 0 }}>
            <EmployeeCardSkeleton />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
