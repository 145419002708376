const ROOT_URL = `${window.location.protocol}//${window.location.host}`;

export const BASE_URL =
  process.env.REACT_APP_API_BASE_URL || ROOT_URL || '/api/';

interface ApiError {
  httpCode: string;
  code: string;
  data: string;
}

export const call = async <T, R>(
  method: string,
  path: string,
  request?: T,
  raw = false
): Promise<{
  response?: R;
  error?: ApiError;
}> => {
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  return await fetch(`${BASE_URL}${path}`, {
    method: method,
    headers,
    body: request && JSON.stringify(request),
  }).then((response) => {
    if (!response.ok) {
      return response
        .text()
        .then((text) => {
          if (!text) {
            return {};
          }
          try {
            return raw ? text : JSON.parse(text);
          } catch {
            return {};
          }
        })
        .then((responseJ: ApiError) => {
          return {
            error: {
              status: String(response.status),
              httpCode: responseJ.httpCode,
              code: responseJ.code,
              data: responseJ.data,
            },
          };
        });
    }
    return response
      .text()
      .then((text) => (text ? (raw ? text : JSON.parse(text)) : {}))
      .then((responseJ: R) => {
        return { response: responseJ };
      });
  });
};

export const get = async <R>(
  path: string,
  raw?: boolean
): Promise<{
  response?: R;
  error?: ApiError;
}> => await call<undefined, R>('GET', path, undefined, raw);

export const post = async <T, R>(
  path: string,
  request?: T
): Promise<{
  response?: R;
  error?: ApiError;
}> => await call<T, R>('POST', path, request);

export const put = async <T, R>(
  path: string,
  request: T
): Promise<{
  response?: R;
  error?: ApiError;
}> => await call<T, R>('PUT', path, request);

export const getFileUrl = (id: string): string => {
  return `${BASE_URL}/api/files/${id}`;
};
