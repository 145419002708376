import PageWrapper from 'components/commons/PageWrapper';
import PolySwitch from 'components/Navigation/PolySwitch';
import AccessWrapper from 'components/User/AccessWrapper';
import { EmployeeOccupations } from 'components/User/UserProvider';
import { EmployeesEmployeeGradeChoices } from 'generated/graphql';
import * as H from 'history';
import IndicatorsPage from 'pages/ReportingPage/Indicators/IndicatorsPage';
import OccupationAdrPage from 'pages/ReportingPage/OccupationAdr/OccupationAdrPage';
import ReportingProfitabilityGlobalView from 'pages/ReportingPage/ReportingProfitabilityGlobalView';
import React from 'react';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'store';
import urlJoin from 'url-join';

import ReportingProfitabilityMissionView from './ReportingProfitabilityMissionView';

export default function Reporting() {
  const { path } = useRouteMatch();
  const missionId = useSelector((state) => state.activity.currentMission.id);
  return (
    <PageWrapper noLimit>
      <PolySwitch>
        <Redirect exact from={path} to={urlJoin(path, '/profitability')} />
        <AccessWrapper
          occupations={[EmployeeOccupations.CONTROL, EmployeeOccupations.DG]}
          grades={[EmployeesEmployeeGradeChoices.Comex]}
          restrictMissionChief={true}
        >
          <Route
            exact
            path={urlJoin(path, '/profitability')}
            component={
              missionId
                ? ReportingProfitabilityMissionView
                : ReportingProfitabilityGlobalView
            }
          />
          <Route
            exact
            path={urlJoin(path, '/profitability/trimester-view')}
            component={
              missionId
                ? ReportingProfitabilityMissionView
                : ReportingProfitabilityGlobalView
            }
          />
          <Route
            exact
            path={urlJoin(path, '/role-adr')}
            component={OccupationAdrPage}
          />
          <Route
            exact
            path={urlJoin(path, '/analysis')}
            component={IndicatorsPage}
          />
        </AccessWrapper>
      </PolySwitch>
    </PageWrapper>
  );
}

export function redirectViewTrimester(
  history: H.History<H.LocationState>,
  search: string
) {
  history.push({
    pathname: '/mission-followup/reporting/profitability/trimester-view',
    search: search,
  });
}
export function redirectViewMonthly(
  history: H.History<H.LocationState>,
  search: string
) {
  history.push({
    pathname: '/mission-followup/reporting/profitability',
    search: search,
  });
}
