import { BillingClientModal } from 'components/MissionFollowUp/BillingClient/BillingClientModal';
import PongoButton from 'components/MUIOverload/PongoButton';
import { SectorNode } from 'generated/graphql';
import React, { useState } from 'react';

interface BillingClientAddButtonProps {
  refetch: () => void;
  sectorList: SectorNode[];
}

export default function BillingClientAddButton({
  refetch,
  sectorList,
}: BillingClientAddButtonProps) {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <PongoButton
        variant={'contained'}
        onClick={() => {
          setOpen(true);
        }}
      >
        Ajouter un client
      </PongoButton>
      <BillingClientModal
        mode={'create'}
        open={open}
        close={() => setOpen(false)}
        onSuccess={() => refetch()}
        sectorList={sectorList}
      />
    </React.Fragment>
  );
}
