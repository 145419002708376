import moment from 'moment';
import { POLY_DATE } from 'poly-constants';
import React from 'react';

interface DateCellProps {
  date: string;
  format?: string;
}

const DateCell = (properties: DateCellProps) => {
  return (
    <>
      {moment(properties.date).format(
        properties.format ? properties.format : POLY_DATE
      )}
    </>
  );
};

export default DateCell;
