import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import PageWrapper from 'components/commons/PageWrapper';
import { createCsvFile } from 'components/commons/PolyExportButton';
import PolyTitle from 'components/commons/PolyTitle';
import PongoButton from 'components/MUIOverload/PongoButton';
import { MOBILE_NAVBAR_MIN_HEIGHT } from 'components/Navigation/MobileNavbar';
import EmployeeList from 'components/Phonebook/PhonebookFinder/EmployeeList';
import PolyTrombiSearchBar from 'components/Phonebook/PolyTrombiSearchBar/PolyTrombiSearchBar';
import PolyTrombiSearchFilterBar from 'components/Phonebook/PolyTrombiSearchFilters/PolyTrombiSearchFilterBar';
import { useUserInfo } from 'components/User/UserProvider';
import { usePolyTrombiContext } from 'contexts/Phonebook/PolyTrombiContextProvider';
import { useFetchExportPhonebookLazyQuery } from 'generated/graphql';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { PageTitles } from 'poly-constants';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { isUserPongoCollaborator } from 'utils';

export default function PolyTrombi() {
  const { displayedEmployees, selectedMissions, allEmployees } =
    usePolyTrombiContext();
  const { enqueueSnackbar } = useSnackbar();

  const [exportPolytrombi] = useFetchExportPhonebookLazyQuery({
    onCompleted: (data) => {
      if (data && data.exportPhonebook) {
        const filename = 'polytrombi_export.csv';
        createCsvFile(data.exportPhonebook, filename);
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const handlePolytrombiExport = () => {
    exportPolytrombi({
      variables: {
        employeesId: _.map(displayedEmployees, (employee) => employee.id),
      },
    });
  };

  const history = useHistory();

  const goToTrophyPage = () => {
    history.push({
      pathname: '/phonebook/trophy',
    });
  };

  const userInfo = useUserInfo();
  const isPongoCollaborator = isUserPongoCollaborator(userInfo);
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));
  const isLargeDisplay = useMediaQuery(theme.breakpoints.up('xl'));

  return (
    <PageWrapper noLimit>
      <Stack
        direction="row"
        gap={isPhone ? 1 : 2}
        alignItems="center"
        sx={{ mb: 1 }}
      >
        <PolyTitle>{PageTitles.polytrombiPage}</PolyTitle>
        <Typography
          variant={isPhone ? 'bodyBold' : 'h2Bold'}
          component={isPhone ? 'span' : 'h2'}
          color={'text.secondary'}
        >
          (
          {selectedMissions.length === 0
            ? displayedEmployees.length
            : allEmployees.length}
          )
        </Typography>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        position="sticky"
        zIndex={1}
        sx={{
          py: { xs: 1, sm: 2 },
          mb: { xs: 1, sm: 2 },
          backgroundColor: 'white',
          top: isPhone ? MOBILE_NAVBAR_MIN_HEIGHT : 0,
        }}
      >
        <Stack direction="row" alignItems="center" spacing={2} width={'100%'}>
          <PolyTrombiSearchBar />
          {!isPhone && <PolyTrombiSearchFilterBar />}
        </Stack>
        {!isPhone && (
          <Stack direction="row" alignItems="center" spacing={2}>
            <PongoButton
              variant="contained"
              startIcon={<GetAppIcon />}
              onClick={handlePolytrombiExport}
              sx={{ minWidth: '64px' }}
            >
              {isLargeDisplay && <Box>EXPORTER</Box>}
            </PongoButton>
            {isPongoCollaborator && (
              <PongoButton
                variant="contained"
                startIcon={<EmojiEventsIcon />}
                onClick={goToTrophyPage}
                sx={{ minWidth: '64px' }}
              >
                {isLargeDisplay && <Box>TROPHÉES</Box>}
              </PongoButton>
            )}
          </Stack>
        )}
      </Stack>
      <EmployeeList />
    </PageWrapper>
  );
}
