import { Box } from '@mui/material';
import PolyAlert from 'components/commons/PolyAlert';
import { FlatTable } from 'components/commons/Tables/FlatTable';
import TableHeaderContextProvider from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import PongoButton from 'components/MUIOverload/PongoButton';
import TableContextProvider from 'components/Revenue/Estimated/EstimatedRevenueTables/EstimatedRevenueMissionTable/context/TableContextProvider';
import RevenueTableBody from 'components/Revenue/Estimated/EstimatedRevenueTables/EstimatedRevenueMissionTable/RevenueTableBody';
import RevenueTableHeader from 'components/Revenue/Estimated/EstimatedRevenueTables/RevenueTableHeader';
import {
  getEstimatedMonthly,
  getInputsToFill,
  getMonthsWithActualRevenue,
  getRefDate,
  verifyEmployeePermissions,
} from 'components/Revenue/Estimated/utils';
import { useUserInfo } from 'components/User/UserProvider';
import {
  ActivityNode,
  AmountInput,
  useSubmitEstimationsForActivityMutation,
} from 'generated/graphql';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import { POLY_DATE_MONTH } from 'poly-constants';
import React, { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { graphQlDateFormatter } from 'utils';

interface RevenueGlobalTableProps {
  activity: ActivityNode;
  objective?: number;
  currentYear: Moment;
  setCurrentYear: (p: Moment) => void;
}

const EstimatedRevenueMissionTable = ({
  activity,
  objective,
  currentYear,
  setCurrentYear,
}: RevenueGlobalTableProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { employee } = useUserInfo();
  const [total, setTotal] = useState(0);

  const monthsWithActualRevenue = getMonthsWithActualRevenue(activity);
  const refDate = getRefDate(currentYear);
  const estimatedRevenues = useMemo(() => {
    return _.filter(
      activity.revenueEstimations,
      (estimation) =>
        moment(estimation.month).isSameOrAfter(refDate, 'month') &&
        !_.some(monthsWithActualRevenue, (month) =>
          moment(month).isSame(estimation.month)
        )
    );
  }, [activity.revenueEstimations, monthsWithActualRevenue, refDate]);

  const defaultValues = _.mapValues(
    _.keyBy(estimatedRevenues, (estimation) =>
      moment(estimation.month).format(POLY_DATE_MONTH)
    ),
    'amount'
  );

  const form = useForm({
    defaultValues: defaultValues,
    shouldUnregister: false,
  });

  const [submitEstimation] = useSubmitEstimationsForActivityMutation({
    onCompleted: () => {
      enqueueSnackbar('Données enregistrées', {
        variant: 'success',
      });
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const onSubmit = async (submitValues: Record<string, number>) => {
    const estimationInputs: AmountInput[] = _.map(
      submitValues,
      (amount, monthStr) => {
        return {
          amount: _.isNumber(amount) ? amount : undefined,
          month: graphQlDateFormatter(moment(monthStr, POLY_DATE_MONTH)),
        };
      }
    );
    await submitEstimation({
      variables: {
        activityId: activity.id,
        estimations: estimationInputs,
      },
    });
  };

  const { isDirector, isChief } = verifyEmployeePermissions(activity, employee);
  const hasRightToEdit = isChief || isDirector;

  const filledValues = _.filter(
    _.values(form.watch()),
    (value) => value.toString() !== '' && value !== undefined
  );

  const emptyValues = useCallback(() => {
    if (activity.revenueObjectives) {
      return getInputsToFill(
        refDate,
        activity,
        filledValues.length,
        activity.revenueObjectives[0]
      );
    }
    return 0;
  }, [activity, filledValues, refDate]);

  const getTotalRevenues = useCallback(() => {
    return _.sumBy(_.values(form.watch()), (value) => value || 0);
  }, [form]);

  const hasObjective = !!objective;

  const toBeEstimated = useMemo(() => {
    if (activity.revenueObjectives) {
      return getEstimatedMonthly(
        activity.revenueObjectives[0],
        total,
        refDate,
        activity,
        getTotalRevenues(),
        filledValues.length
      );
    }
    return 0;
  }, [activity, total, refDate, getTotalRevenues, filledValues]);

  function isTotalValid() {
    if (objective) {
      const totalEstimated =
        total + getTotalRevenues() + toBeEstimated * emptyValues();
      if (emptyValues() === 0) {
        return objective === totalEstimated;
      } else {
        return objective >= totalEstimated;
      }
    }
    return false;
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit((d) => onSubmit(d))}>
        <TableContextProvider
          activity={activity}
          hasRightToEdit={hasRightToEdit}
        >
          <TableHeaderContextProvider
            currentYear={currentYear}
            setCurrentYear={setCurrentYear}
          >
            <FlatTable size={'small'} sx={{ tableLayout: 'auto' }}>
              <RevenueTableHeader />
              <RevenueTableBody setTotal={setTotal} />
            </FlatTable>
            {!hasObjective ? (
              <PolyAlert
                variant="outlined"
                severity={'error'}
                key={'totalRevenueIncorrect'}
                sx={{ mt: 2 }}
              >
                Veuillez renseigner un objectif.
              </PolyAlert>
            ) : (
              !isTotalValid() && (
                <PolyAlert
                  variant="outlined"
                  severity={'error'}
                  key={'totalRevenueIncorrect'}
                  sx={{ mt: 2 }}
                >
                  Le total ne correspond pas à l&apos;objectif de la mission.
                  Veuillez modifier les données.
                </PolyAlert>
              )
            )}
            <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
              <PongoButton
                color={'primary'}
                variant={'contained'}
                type={'submit'}
                disabled={
                  !form.formState.isDirty ||
                  !_.isEmpty(form.errors) ||
                  !isTotalValid()
                }
              >
                Enregistrer
              </PongoButton>
            </Box>
          </TableHeaderContextProvider>
        </TableContextProvider>
      </form>
    </FormProvider>
  );
};

export default EstimatedRevenueMissionTable;
