import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AverageDailyRateCollaboratorNode,
  BillingBillPaymentStatusChoices,
  BillingBillStatusChoices,
  BillingNode,
  BillNode,
  PurchaseOrderNode,
} from 'generated/graphql';
import _ from 'lodash';

const slice = createSlice({
  name: 'purchaseOrder',
  initialState: {
    purchaseOrders: [] as PurchaseOrderNode[],
    currentPurchaseOrder: {} as PurchaseOrderNode,
    currentBill: {} as BillNode,
    allBills: [] as BillNode[],
    currentPurchaseOrderBilling: undefined as BillingNode[] | undefined,
    statusFilter: [] as BillingBillStatusChoices[],
    statusPaymentFilter: [] as BillingBillPaymentStatusChoices[],
    billNumberFilter: '',
    groupEdit: false,
    selectedBills: [] as BillNode[],
  },
  reducers: {
    setPurchaseOrders: (state, action: PayloadAction<PurchaseOrderNode[]>) => {
      state.purchaseOrders = action.payload;
    },
    setCurrentPurchaseOrder: (
      state,
      action: PayloadAction<PurchaseOrderNode>
    ) => {
      state.currentPurchaseOrder = action.payload;
    },
    setBillForCurrentPurchaseOrder: (
      state,
      action: PayloadAction<BillNode>
    ) => {
      state.currentPurchaseOrder.bills = [
        action.payload,
        ...(state.currentPurchaseOrder.bills || []),
      ];
    },
    setCurrentBill: (state, action: PayloadAction<BillNode>) => {
      state.currentBill = action.payload;
    },
    setCurrentPurchaseOrderBilling: (
      state,
      action: PayloadAction<BillingNode[] | undefined>
    ) => {
      state.currentPurchaseOrderBilling = action.payload;
    },
    setAllBills: (state, action: PayloadAction<BillNode[]>) => {
      state.allBills = action.payload;
      state.groupEdit = false;
      state.selectedBills = [];
    },
    updateBills: (state, action: PayloadAction<BillNode[]>) => {
      const filteredList = _.filter(state.allBills, (bill) => {
        return _.findIndex(action.payload, ['id', bill.id]) < 0;
      });
      setAllBills([...action.payload, ...filteredList]);
    },
    setSelectedBills: (state, action: PayloadAction<BillNode[]>) => {
      state.selectedBills = action.payload;
    },
    addSelectedBill: (state, action: PayloadAction<BillNode>) => {
      state.selectedBills = [...state.selectedBills, action.payload];
    },
    removeSelectedBill: (state, action: PayloadAction<BillNode>) => {
      state.selectedBills = _.filter(state.selectedBills, (bill) => {
        return bill.id !== action.payload.id;
      });
    },
    setGroupEdit: (state, action: PayloadAction<boolean>) => {
      state.groupEdit = action.payload;
    },
    setStatusFilter: (
      state,
      action: PayloadAction<BillingBillStatusChoices[]>
    ) => {
      state.statusFilter = action.payload;
    },
    setStatusPaymentFilter: (
      state,
      action: PayloadAction<BillingBillPaymentStatusChoices[]>
    ) => {
      state.statusPaymentFilter = action.payload;
    },
    setBillNumberFilter: (state, action: PayloadAction<string>) => {
      state.billNumberFilter = action.payload;
    },
    addPurchaseOrder: (state, action: PayloadAction<PurchaseOrderNode>) => {
      state.purchaseOrders = [action.payload, ...state.purchaseOrders];
    },
    removePurchaseOrder: (state, action: PayloadAction<string>) => {
      state.purchaseOrders.forEach((entry, index) => {
        if (entry.id === action.payload) {
          state.purchaseOrders.splice(index, 1);
        }
      });
    },
    removeBill: (state, action: PayloadAction<string>) => {
      state.currentPurchaseOrder.bills?.forEach((entry, index) => {
        if (entry.id === action.payload) {
          state.currentPurchaseOrder.bills?.splice(index, 1);
        }
      });
    },
    setCollaboratorsForCurrentPurchaseOrder: (
      state,
      action: PayloadAction<AverageDailyRateCollaboratorNode>
    ) => {
      state.currentPurchaseOrder.collaborators?.push(action.payload);
    },
    removeCollaboratorForCurrentPurchaseOrder: (
      state,
      action: PayloadAction<string>
    ) => {
      state.currentPurchaseOrder.collaborators?.forEach((entry, index) => {
        if (entry.id === action.payload) {
          state.currentPurchaseOrder.collaborators?.splice(index, 1);
        }
      });
    },
  },
});

export default slice.reducer;
export const {
  setPurchaseOrders,
  setCurrentPurchaseOrder,
  setBillForCurrentPurchaseOrder,
  setCurrentPurchaseOrderBilling,
  addPurchaseOrder,
  setCurrentBill,
  setAllBills,
  updateBills,
  setStatusFilter,
  setStatusPaymentFilter,
  setBillNumberFilter,
  removePurchaseOrder,
  removeBill,
  setCollaboratorsForCurrentPurchaseOrder,
  removeCollaboratorForCurrentPurchaseOrder,
  setGroupEdit,
  setSelectedBills,
  addSelectedBill,
  removeSelectedBill,
} = slice.actions;
