import { Chip } from '@mui/material';
import Cell, { CellType } from 'components/ActivityPage/common/Cell';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import cellStyles from 'styles/ActivityPage/Cell.module.scss';
import styles from 'styles/ActivityPage/UnderlinedRow.module.scss';

import { dotToComa, getAMPerActivityPerStartDate } from '../utils';
import { Activity } from '.';
import { useTableContext } from './context/TableContextProvider';
import { useValueContext } from './context/ValueContextProvider';

interface EmptyCellsProps {
  id?: string;
  underlined?: boolean;
  showCategoryTotal?: boolean;
  listActivity?: Array<Activity>;
}

const getCellStyle = (
  isInvalid: boolean,
  isCurrentWeek: boolean,
  firstElement: boolean,
  isUnderlined?: boolean
) => {
  if (isInvalid && firstElement) {
    return styles.firstInvalidCell;
  } else if (isInvalid && isUnderlined) {
    return styles.underlinedInvalidCell;
  } else if (isInvalid && !isUnderlined) {
    return cellStyles.isInvalid;
  } else if (isUnderlined) {
    return styles.underlinedCell;
  } else if (isCurrentWeek) {
    return styles.currentWeekCell;
  } else {
    return '';
  }
};

const EmptyCells = ({
  underlined,
  id,
  showCategoryTotal = false,
  listActivity,
}: EmptyCellsProps) => {
  const { numberOfModifiableWeeks, invalidFirstElementWeeks } =
    useTableContext();
  const { invalidWeeks, needToAddCommentWeeks } = useValueContext();
  const emptyCells = [];
  const weekRange = Object.keys(invalidWeeks);

  const { getValues } = useFormContext();
  const weekActivities = getValues();
  const categoryActivitiesIds = _.flatMap(
    listActivity,
    (activity) => activity.id
  );

  const oldAMs = getAMPerActivityPerStartDate(listActivity);

  const allAMs = { ...oldAMs, ...weekActivities['activityMonitoring'] };

  for (let index = 0; index < numberOfModifiableWeeks; index++) {
    const isInvalid =
      invalidWeeks[weekRange[index]] ||
      needToAddCommentWeeks.includes(weekRange[index]);
    const isFirst =
      invalidFirstElementWeeks[weekRange[index]] ||
      invalidFirstElementWeeks[`${id}-${index}-modifiable`];

    const isCurrentWeek = moment().isSame(weekRange[index], 'week');

    if (isFirst && isInvalid) {
      // the first element in this week 'reserve' his style with his unique key
      invalidFirstElementWeeks[weekRange[index]] = false;
      invalidFirstElementWeeks[`${id}-${index}-modifiable`] = true;
    }
    const className = getCellStyle(
      isInvalid,
      isCurrentWeek,
      isFirst,
      underlined
    );

    const weekAMs = _.get(allAMs, weekRange[index]);
    const sumOfCategory = weekAMs
      ? _.sumBy(
          categoryActivitiesIds,
          (id) =>
            Number.parseFloat(weekAMs[id]?.timeSpent.replace(',', '.')) || 0
        )
      : 0;

    emptyCells.push(
      <Cell
        cellType={CellType.Old}
        className={className}
        key={`${id}-${index}-modifiable`}
      >
        {showCategoryTotal && listActivity && sumOfCategory !== 0 && (
          <Chip
            label={dotToComa(sumOfCategory)}
            color={isInvalid ? 'error' : 'info'}
            size="small"
            sx={{
              borderRadius: '4px',
            }}
          />
        )}
      </Cell>
    );
  }

  return <>{emptyCells}</>;
};

export default EmptyCells;
