import { SxProps } from '@mui/system';
import PolyExportButton from 'components/commons/PolyExportButton';
import React, { useState } from 'react';

import ExportBillsModal from './ExportBillsModal';

interface ExportBillsButtonProps {
  isPayment?: boolean;
  sx?: SxProps;
}

export default function ExportBillsButton({
  isPayment,
  sx,
}: ExportBillsButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <PolyExportButton onClick={toggleModal} sx={sx}>
        Exporter
      </PolyExportButton>
      <ExportBillsModal
        isPayment={isPayment}
        isOpen={isOpen}
        handleClose={toggleModal}
        title={'Export données de factures'}
      />
    </>
  );
}
