import { Box, Stack } from '@mui/material';
import { ActiveElement, Chart, ChartEvent } from 'chart.js';
import { useBillableStaffingContext } from 'contexts/Reporting/BillableStaffingContextProvider';
import {
  StaffingAnalysisNode,
  useStaffingAnalysisLazyQuery,
} from 'generated/graphql';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { totalFormat } from 'pages/ActivityPage/utils';
import React, {
  MouseEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Bar, getDatasetAtEvent, getElementAtEvent } from 'react-chartjs-2';

import BillableSectorModal from '../BillableSectorModal';
import { FilterOptionTypes } from '../Filter/utils';
import GraphSkeleton from '../GraphSkeleton';
import NoDataForThisYearAlert from '../NoDataForThisYearAlert';
import UnbillableActivityModal from '../UnbillableActivityModal';
import { GraphProps, MONTH_IN_YEAR_LABELS } from '../utils';

export default function BillableStaffingGraph({
  year,
  isInJanuary,
}: GraphProps): ReactElement {
  const [datasetIndex, setDatasetIndex] = useState<number>();
  const [monthIndex, setMonthIndex] = useState<number>();
  const [isPointer, setIsPointer] = useState<boolean>(false);

  const { optionsSelected } = useBillableStaffingContext();

  const contractFilter = _.filter(
    optionsSelected,
    (option) => option.__typename === FilterOptionTypes.Contract
  ).map((option) => option.id);
  const gradeFilter = _.filter(
    optionsSelected,
    (option) => option.__typename === FilterOptionTypes.Grade
  ).map((option) => option.id);
  const occupationFilter = _.filter(
    optionsSelected,
    (option) => option.__typename === FilterOptionTypes.Occupation
  ).map((option) => option.id);

  const [billableStaffingGraphQuery, { data: queryData, loading }] =
    useStaffingAnalysisLazyQuery({
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
    });

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    billableStaffingGraphQuery({
      variables: {
        year: year,
        contractFilter: contractFilter,
        gradeFilter: gradeFilter,
        occupationFilter: occupationFilter,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, optionsSelected, billableStaffingGraphQuery]);

  const staffingAnalysisList =
    queryData?.staffingAnalysis as StaffingAnalysisNode[];

  // assuming staffing analysis is already sorted by month
  const billedEtps = {
    label: 'ETP Facturés',
    data: _.map(
      staffingAnalysisList,
      ({ billableSectorStaffings, monthWorkDays }) =>
        Number.parseFloat(
          totalFormat(
            _.sumBy(billableSectorStaffings, 'workedDays') / monthWorkDays
          ).replace(',', '.')
        )
    ),
    backgroundColor: 'rgb(34, 109, 104)',
    hoverBackgroundColor: 'rgba(34, 109, 104, 0.7)',
  };

  const unbillableEtps = {
    label: 'ETP Non Facturés',
    data: _.map(
      staffingAnalysisList,
      ({ unbillableActivityStaffings, monthWorkDays }) =>
        Number.parseFloat(
          totalFormat(
            _.sumBy(unbillableActivityStaffings, 'workedDays') / monthWorkDays
          ).replace(',', '.')
        )
    ),
    backgroundColor: 'rgb(74, 113, 174)',
    hoverBackgroundColor: 'rgba(74, 113, 174, 0.7)',
  };

  const undeclaredEtps = {
    label: 'ETP Non Déclarés',
    data: _.map(
      staffingAnalysisList,
      ({ undeclaredWorkedDays, monthWorkDays }) =>
        Number.parseFloat(
          totalFormat(undeclaredWorkedDays / monthWorkDays).replace(',', '.')
        )
    ),
    backgroundColor: 'rgb(255, 99, 132)',
  };

  const leaveEtps = {
    label: 'Congés et Autres Absences',
    data: _.map(staffingAnalysisList, ({ leaveDays, monthWorkDays }) =>
      Number.parseFloat(
        totalFormat(leaveDays / monthWorkDays).replace(',', '.')
      )
    ),
    backgroundColor: 'rgb(209, 211, 210)',
  };

  const chartData = {
    labels: MONTH_IN_YEAR_LABELS,
    datasets: [billedEtps, unbillableEtps, undeclaredEtps, leaveEtps],
  };

  function isSelectingBillableEtp(datasetIndex: number) {
    return chartData.datasets[datasetIndex].label === billedEtps.label;
  }

  function isSelectingUnbillableEtp(datasetIndex: number) {
    return chartData.datasets[datasetIndex].label === unbillableEtps.label;
  }

  const options = {
    responsive: true,
    scales: {
      x: { stacked: true },
      y: { stacked: true },
    },
    onHover: (event: ChartEvent, chartElement: ActiveElement[]) => {
      if (!chartElement[0]) {
        setIsPointer(false);
        return;
      }
      if (
        chartElement[0].datasetIndex === 0 ||
        chartElement[0].datasetIndex === 1
      ) {
        setIsPointer(true);
        return;
      }
      setIsPointer(false);
    },
  };

  const chartRef = useRef<Chart<'bar'>>();

  if (loading) {
    return <GraphSkeleton />;
  }

  return (
    <>
      {isInJanuary ? (
        <NoDataForThisYearAlert />
      ) : (
        <Stack
          direction="row"
          justifyContent="space-around"
          sx={{ cursor: isPointer ? 'pointer' : 'default' }}
        >
          <Box sx={{ width: 0.8, aspectRatio: '2' }}>
            <Bar
              ref={chartRef}
              data={chartData}
              options={options}
              onClick={(event: MouseEvent<HTMLCanvasElement>) => {
                const { current: chart } = chartRef;
                if (!chart) {
                  return;
                }
                const datasets = getDatasetAtEvent(chart, event);
                if (!datasets[0]) {
                  return;
                }
                const { datasetIndex } = datasets[0];
                if (
                  !isSelectingBillableEtp(datasetIndex) &&
                  !isSelectingUnbillableEtp(datasetIndex)
                ) {
                  return;
                }

                const elements = getElementAtEvent(chart, event);
                const { index: monthIndex } = elements[0];

                setDatasetIndex(datasetIndex);
                setMonthIndex(monthIndex);
              }}
            />
          </Box>
        </Stack>
      )}
      {monthIndex !== undefined &&
        datasetIndex !== undefined &&
        ((isSelectingBillableEtp(datasetIndex) && (
          <BillableSectorModal
            title={`${chartData.datasets[datasetIndex].label} : ${chartData.labels[monthIndex]}`}
            onClose={() => {
              setDatasetIndex(undefined);
              setMonthIndex(undefined);
            }}
            billableSectorStaffings={
              staffingAnalysisList[monthIndex].billableSectorStaffings
            }
            monthWorkDays={staffingAnalysisList[monthIndex].monthWorkDays}
          />
        )) ||
          (isSelectingUnbillableEtp(datasetIndex) && (
            <UnbillableActivityModal
              title={`${chartData.datasets[datasetIndex].label} : ${chartData.labels[monthIndex]}`}
              onClose={() => {
                setDatasetIndex(undefined);
                setMonthIndex(undefined);
              }}
              unbillableActivityStaffings={
                staffingAnalysisList[monthIndex].unbillableActivityStaffings
              }
              monthWorkDays={staffingAnalysisList[monthIndex].monthWorkDays}
            />
          )))}
    </>
  );
}
