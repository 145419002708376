import { SxProps } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import styles from 'components/MissionFollowUp/OverallListBill/styles/OverallListBills.module.scss';
import PolyTablePaginationActions from 'components/MUIOverload/PolyTablePaginationActions/PolyTablePaginationActions';
import React from 'react';

interface TablePaginationListV2Props {
  totalLen: number;
  pageNbr: number;
  setPageNbr: (p: number) => void;
  pageSize: number;
  setPageSize: (p: number) => void;
  paginationLabel: string;
  sx?: SxProps;
}

export default function BillingTablePagination({
  totalLen,
  pageNbr,
  setPageNbr,
  pageSize,
  setPageSize,
  paginationLabel,
  sx,
}: TablePaginationListV2Props) {
  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ): void => {
    setPageNbr(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setPageSize(Number.parseInt(event.target.value, 10));
    setPageNbr(0);
  };

  return (
    <TablePagination
      classes={{ root: styles.pagination, input: styles.input }}
      onPageChange={handlePageChange}
      rowsPerPageOptions={[25, 50, 100, 200]}
      colSpan={8}
      count={totalLen}
      rowsPerPage={pageSize}
      page={pageNbr}
      SelectProps={{
        inputProps: { 'aria-label': paginationLabel },
        native: true,
      }}
      labelRowsPerPage={paginationLabel}
      labelDisplayedRows={({ from, to, count }) => {
        return '' + from + '-' + to + ' sur ' + count;
      }}
      onRowsPerPageChange={handleRowsPerPageChange}
      ActionsComponent={PolyTablePaginationActions}
      sx={sx}
    />
  );
}
