import MultiTableCell from '@mui/material/TableCell';
import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';
import styles from 'styles/ActivityPage/Cell.module.scss';

export enum CellType {
  Old,
  Modifiable,
  Current,
}

const cellTypeClassNames = [
  styles.oldBackground,
  styles.modifiableBackground,
  styles.currentBackground,
];

interface CellProps {
  cellType: CellType;
  className?: string;
  isInvalid?: boolean;
  isCurrentWeek?: boolean;
  children?: React.ReactNode;
}

const Cell = ({
  cellType,
  className,
  isInvalid,
  isCurrentWeek,
  children,
}: PropsWithChildren<CellProps>) => {
  return (
    <MultiTableCell
      className={clsx(
        styles.cell,
        cellTypeClassNames[cellType],
        className,
        isInvalid ? styles.isInvalid : '',
        isCurrentWeek && !isInvalid ? styles.isCurrentWeek : ''
      )}
    >
      {children}
    </MultiTableCell>
  );
};

export default Cell;
