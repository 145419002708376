import { Box } from '@mui/material';
import PolyDataGrid from 'components/MUIOverload/PolyDataGrid';
import DeleteIconWithTooltip from 'components/MUIOverload/PolyDataGrid/components/DeleteIconWithTooltip';
import StringCell from 'components/MUIOverload/PolyDataGrid/components/StringCell';
import { PolyDataGridColumn } from 'components/MUIOverload/PolyDataGrid/PolyDataGridHead';
import { BillingClientNode, SectorNode } from 'generated/graphql';
import React, { useCallback, useMemo, useState } from 'react';

import DeleteSectorModal from './DeleteSectorModal';
import SectorModal from './SectorModal';
import { getNameFormatted, NO_SECTOR_NAME } from './utils';

interface SectorTableProps {
  sectors: SectorNode[];
  refetch: () => void;
  allClients: BillingClientNode[];
}

const SectorTable = ({ sectors, refetch, allClients }: SectorTableProps) => {
  const [sector, setSector] = useState<SectorNode>();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const columns: PolyDataGridColumn<
    DeepExtractTypeSkipArrays<SectorNode, []>
  >[] = useMemo(
    () => [
      {
        headerName: 'Secteur',
        propertyName: 'sector',
        isSortable: false,
        width: '55%',
        render: (data) =>
          StringCell({ data: getNameFormatted(data.name), noTooltip: true }),
      },
      {
        headerName: 'Nombre de clients',
        propertyName: 'clientNumber',
        isSortable: false,
        width: '40%',
        render: (data) =>
          StringCell({
            data: data.billingClients?.length.toString(),
            noTooltip: true,
          }),
      },
      {
        headerName: '',
        propertyName: 'deleteSector',
        isSortable: false,
        width: '5%',
        render: (data) =>
          data.name !== NO_SECTOR_NAME ? (
            DeleteIconWithTooltip({
              tooltipText: 'Supprimer le secteur',
              onClick: () => setOpenDeleteModal(true),
            })
          ) : (
            <></>
          ),
      },
    ],
    []
  );

  const onClickCallBack = useCallback((sector: SectorNode) => {
    setSector(sector);
  }, []);

  const rowsActive = useCallback(
    (_orderBy?: string | string[], _order?: 'asc' | 'desc') => {
      return sectors;
    },
    [sectors]
  );

  const handleCloseDeleteModal = () => {
    setSector(undefined);
    setOpenDeleteModal(false);
  };

  return (
    <Box mt={4}>
      <PolyDataGrid
        columns={columns}
        getRows={rowsActive}
        rowsCallback={onClickCallBack}
      />
      {sector && (
        <>
          <SectorModal
            mode={'edit'}
            sector={sector}
            isOpen={!openDeleteModal}
            handleClose={() => setSector(undefined)}
            refetch={refetch}
            allClients={allClients}
          />
          <DeleteSectorModal
            sector={sector}
            isOpen={openDeleteModal}
            handleClose={() => handleCloseDeleteModal()}
            refetch={refetch}
          />
        </>
      )}
    </Box>
  );
};

export default SectorTable;
