import PolyIconButton from 'components/MUIOverload/PolyIconButton';
import React, { useState } from 'react';

import Modals from './Modals';
import { DotOption } from './utils';

interface ButtonDeleteProps<T> extends DotOption<T> {
  data: T;
}

const ExceptionalActivitiesButton = <T,>({
  data,
  handle,
  name,
  hasModalVerification,
  Icon,
  modalTitle,
  modalBody,
  styles,
}: ButtonDeleteProps<T>) => {
  const [openDelete, setOpenDelete] = useState(false);

  const handleModalDelete = () => {
    setOpenDelete(!openDelete);
  };

  const handleDelete = () => {
    if (hasModalVerification) {
      handle(data);
      setOpenDelete(!openDelete);
    }
  };

  const handleClickOpenDelete = () => {
    if (hasModalVerification) {
      setOpenDelete(!openDelete);
    } else {
      handle(data);
    }
  };

  return (
    <div className={styles.root}>
      <PolyIconButton onClick={handleClickOpenDelete}>
        <Icon className={styles.icon} />
        <div className={styles.text}>{name}</div>
      </PolyIconButton>
      <Modals
        body={modalBody ?? 'Êtes-vous sûr(e) de vouloir supprimer cet élément?'}
        title={modalTitle ?? 'Suppression'}
        open={openDelete}
        handleClose={handleModalDelete}
        handleSave={handleDelete}
        nameButton={'Supprimer'}
      />
    </div>
  );
};

export default ExceptionalActivitiesButton;
