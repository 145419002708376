import { DialogContent } from '@mui/material';
import PolyDialog from 'components/MUIOverload/PolyDialog';
import PongoButton from 'components/MUIOverload/PongoButton';
import React, { useState } from 'react';

import RecoveryMomentForm from './RecoveryMomentForm';

interface RecoveryMomentFormModalProps {
  overtimeAuthorizationId: string;
  handleSummit: (
    id: string,
    date: Date,
    duration: number,
    dayMoment: string
  ) => Promise<void>;
  maxRecoveryDuration: number;
  expireAt: string;
}

const RecoveryMomentFormModal = ({
  overtimeAuthorizationId,
  handleSummit,
  maxRecoveryDuration,
  expireAt,
}: RecoveryMomentFormModalProps) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <PongoButton
        buttonStyle="secondary"
        variant="contained"
        size="small"
        onClick={handleClick}
      >
        Récupérer
      </PongoButton>
      <PolyDialog
        open={open}
        onClose={closeModal}
        maxWidth={false}
        disableEnforceFocus
      >
        <DialogContent>
          <RecoveryMomentForm
            handleClose={closeModal}
            expireAt={expireAt}
            overtimeAuthorizationId={overtimeAuthorizationId}
            createRecoveryMoment={handleSummit}
            maxRecoveryDuration={maxRecoveryDuration}
          />
        </DialogContent>
      </PolyDialog>
    </>
  );
};

export default RecoveryMomentFormModal;
