import { Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import EmployeeStaffing from 'components/commons/EmployeeStaffing/EmployeeStaffing';
import PageWrapper from 'components/commons/PageWrapper';
import PolyTitle from 'components/commons/PolyTitle';
import EmployeeSkills from 'components/EmployeePage/EmployeeSkills';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import { useUserInfo } from 'components/User/UserProvider';
import { EmployeeNode } from 'generated/graphql';
import UserInfo from 'pages/UserPage/UserInfo';
import React from 'react';

import UserMissionsList from './UserMissionsList';
import VersionDisplay from './VersionDisplay';

const UserPage = () => {
  const ADMIN_BACK_OFFICE_FLAG = useEnvironmentVariable('admin_back_office');
  const theme = useTheme();
  const { employee } = useUserInfo();
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <PageWrapper noLimit={isPhone} large={!isPhone}>
      <PolyTitle sx={{ mb: 3.75 }}>Mon profil</PolyTitle>
      <Grid container spacing={3}>
        <VersionDisplay />
        <Grid item xs={12}>
          <UserInfo
            isEditable
            isUserPageView
            employee={employee as EmployeeNode}
          />
        </Grid>
        {ADMIN_BACK_OFFICE_FLAG && (
          <Grid item xs={12}>
            <EmployeeSkills employee={employee as EmployeeNode} isEditable />
          </Grid>
        )}
        <Grid item xs={12}>
          {isPhone ? (
            <UserMissionsList employee={employee as EmployeeNode} />
          ) : (
            <EmployeeStaffing employee={employee as EmployeeNode} isUserPage />
          )}
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default UserPage;
