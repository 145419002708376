import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import ModalitiesView from 'components/MissionFollowUp/BillDetail/BillDetail/BillView/MissionSummaryView/ModalitiesView/ModalitiesView';
import React from 'react';

interface ModalitiesBillingViewProps {
  setMissionModalStep: React.Dispatch<React.SetStateAction<number>>;
  setMissionModalVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ModalitiesBillingView({
  setMissionModalStep,
  setMissionModalVisibility,
}: ModalitiesBillingViewProps) {
  const theme = useTheme();

  return (
    <Stack direction={'column'} sx={{ px: 2 }}>
      <Typography
        variant="h3Bold"
        component="h3"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        Modalités facturation
        <IconButton
          onClick={() => {
            setMissionModalStep(2);
            setMissionModalVisibility(true);
          }}
          size="small"
          sx={{
            backgroundColor: theme.palette.primary.darker,
          }}
        >
          <ModeEditIcon
            sx={{
              color: theme.palette.contrastText.main,
            }}
          />
        </IconButton>
      </Typography>
      <ModalitiesView />
    </Stack>
  );
}
