import {
  ActivitiesActivityBillingTypeChoices,
  ActivitiesActivityTypeChoices,
  FetchUserActivityQuery,
} from 'generated/graphql';
import _ from 'lodash';
import transform from 'lodash/transform';
import moment from 'moment';
import {
  Activity,
  ActivityGroupType,
  DateRange,
} from 'pages/ActivityPage/PrincipalTablePage';

export const groupByCategory = (list: Array<Activity>) => {
  return transform(
    list,
    (result: ActivityGroupType, item: Activity) => {
      (result[item.type] || (result[item.type] = [])).push(item);
    },
    {}
  );
};

export const getHasTMActivity = (listActivities: Activity[]) => {
  for (const activity of listActivities) {
    if (activity.billingType === ActivitiesActivityBillingTypeChoices.Tm) {
      return true;
    }
  }
  return false;
};

export const extractActivities = (
  dataUserActivity: FetchUserActivityQuery | undefined,
  dateRange: DateRange,
  hasTMActivity: boolean,
  setHasTMActivity: (hasTMActivity: boolean) => void
): ActivityGroupType => {
  if (dataUserActivity === undefined) {
    return {};
  }
  const supportHideDate = moment('2023-03-31');
  const result = dateRange.start.isAfter(supportHideDate)
    ? dataUserActivity['allActivitiesForUser']?.filter(
        (activity) =>
          !(
            activity.name === 'Support' &&
            activity.type === ActivitiesActivityTypeChoices.Int
          )
      )
    : dataUserActivity['allActivitiesForUser'];

  if (result === null || result === undefined) {
    return {};
  }

  const newHasTMActivity = getHasTMActivity(result);
  if (newHasTMActivity !== hasTMActivity) {
    setHasTMActivity(newHasTMActivity);
  }

  const filteredResult = _.map(result, (activity) => {
    const filteredActivity = {
      ...activity,
      activityMonitoring: _.filter(activity.activityMonitoring, (am) => {
        return moment(am?.date).isBetween(
          dateRange.start,
          dateRange.end,
          undefined,
          '[]'
        );
      }),
    };
    return filteredActivity;
  });
  return groupByCategory(filteredResult);
};

export const computeWorkedDays = (mergedActivities: Array<Activity>) => {
  const mergedActivityMonitorings = _.flatMap(
    mergedActivities,
    (activity) => activity.activityMonitoring
  );
  const daysWorked = _.sumBy(mergedActivityMonitorings, (am) =>
    am ? am.timeSpent : 0
  );
  return daysWorked;
};

export const getWeekSummaryTextColor = (
  isEditable: boolean,
  isAutorizedToUpdateAM: boolean,
  isCurrentWeek: boolean,
  isBeforeCurrentWeek: boolean,
  isTotalInvalid: boolean
) => {
  if (isEditable) {
    if (isAutorizedToUpdateAM) {
      return 'orange.main';
    } else if (isCurrentWeek) {
      return 'primary.main';
    } else if (isBeforeCurrentWeek) {
      return isTotalInvalid ? 'error.main' : 'success.main';
    }
  }
  return 'text.primary';
};
