import { BillingPurchaseOrderCurrencyChoices } from 'generated/graphql';
import { CurrenciesSymbols } from 'poly-constants';
import React, { FocusEvent } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat<number> | null) => void;
  onChange: (event: { target: { value: number | string | undefined } }) => void;
  currency?: BillingPurchaseOrderCurrencyChoices;
  suggestedValue?: number;
}

const TextFieldNumberFormat = React.forwardRef(
  (properties: NumberFormatCustomProps & NumberFormatProps, ref) => {
    const { inputRef, onChange, suggestedValue, currency, ...other } =
      properties;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef || ref}
        thousandSeparator={' '}
        decimalSeparator={','}
        onBlur={(event: FocusEvent) => {
          const value = Number((event.target as HTMLInputElement).value);
          if (value) {
            onChange({ target: { value: value } });
          }
        }}
        onFocus={(event: FocusEvent) => {
          const eventTarget = event.target as HTMLInputElement;
          if (suggestedValue && !eventTarget.value) {
            eventTarget.value = suggestedValue.toString() || '';
            eventTarget.select();
          }
        }}
        decimalScale={2}
        suffix={` ${currency ? CurrenciesSymbols[currency] : '€'}`}
        fixedDecimalScale
        onValueChange={(values) => {
          onChange({
            target: {
              value:
                values.floatValue === undefined
                  ? values.value
                  : values.floatValue,
            },
          });
        }}
        isNumericString
      />
    );
  }
);
TextFieldNumberFormat.displayName = 'TextFieldNumberFormat';
export default TextFieldNumberFormat;
