import { Search } from '@mui/icons-material';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import { useTableContext } from 'contexts/ActivityMonitoringTable/TableContextProvider';
import { EmployeeNode } from 'generated/graphql';
import React from 'react';
import { displayEmployee } from 'utils';

export default function CollaboratorFilter() {
  const { allCollaborators, setFilterCollaboratorsId, setPage } =
    useTableContext();

  return (
    <Autocomplete
      autoHighlight
      autoComplete
      id="filter-collaborator"
      options={allCollaborators}
      onChange={(event, value) => {
        const collaborator = value as EmployeeNode;
        if (collaborator) {
          setFilterCollaboratorsId([collaborator.id]);
        } else {
          setFilterCollaboratorsId([]);
        }
        setPage(0);
      }}
      getOptionLabel={(option) => {
        if (option) {
          return displayEmployee(option.firstName, option.lastName);
        }
        return '';
      }}
      sx={{ width: 300 }}
      renderInput={(parameters) => (
        <TextField
          label="Mes collaborateurs"
          {...parameters}
          variant="standard"
          InputProps={{
            ...parameters.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
