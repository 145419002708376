import { Box, Grid } from '@mui/material';
import { getModalityName } from 'components/MissionFollowUp/BillDetail/BillDetail/utils';
import {
  DisplayAddress,
  parseAddress,
} from 'components/MissionFollowUp/DisplayAddress';
import GridItem from 'components/MissionFollowUp/GridItem';
import LabelTextFieldGrid from 'components/MissionFollowUp/LabelTextFieldGrid';
import { requiredForm } from 'pages/MissionFollowUp/formValidators';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Modality } from '../Types';

interface ModalityPostalProps {
  index: number;
  modality: Modality;
}

export default function ModalityPostal(properties: ModalityPostalProps) {
  const form = useFormContext();
  const billingInformationWatcher = form.watch('selectedBillingClient');

  return (
    <>
      <Grid container item xs={12}>
        <LabelTextFieldGrid
          inputRef={form.register({ required: requiredForm })}
          name={getModalityName(properties.index, 'billingName')}
          defaultValue={`${properties.modality.billingName || ''}`}
          sizegrid={6}
          customspacingtop={0}
          errorform={
            form.errors.billingModalities?.[properties.index]?.billingName
          }
          title="Nom du destinataire"
          required
        />
        <GridItem
          title="Adresse de facturation"
          sizegrid={6}
          customspacingtop={0}
          customspacingside={0}
        >
          <Box sx={{ wordWrap: 'break-word', whiteSpace: 'initial' }}>
            {DisplayAddress({
              addressInformations: parseAddress(billingInformationWatcher),
              displayVertical: true,
            })}
          </Box>
        </GridItem>
      </Grid>
    </>
  );
}
