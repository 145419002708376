import { TableCell, TableRow } from '@mui/material';
import clsx from 'clsx';
import ColumnLabel from 'components/commons/Tables/ColumnLabel';
import { useTableContext } from 'contexts/ActivityMonitoringTable/TableContextProvider';
import { useTableHeaderContext } from 'contexts/ActivityMonitoringTable/TableHeaderContextProvider';
import _ from 'lodash';
import React from 'react';

import cellStyles from '../styles/TableCell.module.scss';
import ChipsForFilters from './Header/ChipsForFilters';
import CurrentMonthDisplayer from './Header/CurrentMonthDisplayer';
import styles from './styles/TableHeader.module.scss';

export default function MonitoringTableHeader() {
  const { displayedWeeks } = useTableContext();
  const { columnLabels } = useTableHeaderContext();
  return (
    <>
      <TableRow className={styles.noUnderline}>
        <ChipsForFilters />
        <CurrentMonthDisplayer />
      </TableRow>
      <TableRow className={styles.fullUnderline}>
        <TableCell className={cellStyles.nameHeadCell}>
          Mes collaborateurs
        </TableCell>
        <TableCell className={cellStyles.missionHeadCell}>Missions</TableCell>
        {columnLabels.length !== 0 ? (
          _.map(columnLabels, (column) => {
            return (
              <ColumnLabel
                className={clsx(
                  displayedWeeks.length === 4
                    ? cellStyles.normalCell
                    : cellStyles.smallCell
                )}
                key={column.weekNumber}
                startDate={column.start}
                endDate={column.end}
                weekPart={column.weekPart}
              />
            );
          })
        ) : (
          <TableCell colSpan={3}></TableCell>
        )}
        <TableCell className={cellStyles.totalHeadCell}>
          <div className={styles.totalSpan}>Total mois</div>
        </TableCell>
      </TableRow>
    </>
  );
}
