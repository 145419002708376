import { AddCircleOutlineOutlined } from '@mui/icons-material';
import { Button, TableRow, Typography } from '@mui/material';
import {
  BorderedTableCell,
  CenteredTableCell,
} from 'components/commons/Tables/FlatTable';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import CollapsePolyTableCell from 'components/MUIOverload/PolyTableCell/CollapsePolyTableCell';
import TransitionPolyTableRow from 'components/MUIOverload/PolyTableRow/TransitionPolyTableRow';
import { useEstimatedContext } from 'components/Revenue/Estimated/context/EstimatedContextProvider';
import { DisplayedRevenueProspective } from 'components/Revenue/Estimated/EstimatedRevenueTables/EstimatedRevenueGlobalTable/context/TableContextProvider';
import { ProspectiveModal } from 'components/Revenue/Estimated/EstimatedRevenueTables/EstimatedRevenueGlobalTable/RevenueRows/ProspectiveModal';
import ProspectiveRow from 'components/Revenue/Estimated/EstimatedRevenueTables/EstimatedRevenueGlobalTable/RevenueRows/ProspectiveRow';
import styles from 'components/Revenue/Estimated/EstimatedRevenueTables/EstimatedRevenueGlobalTable/styles/TableCell.module.scss';
import { estimatedRevenueBackgroundColorSx } from 'components/Revenue/Estimated/utils';
import { BillingPurchaseOrderCurrencyChoices } from 'generated/graphql';
import _ from 'lodash';
import { totalFormat } from 'pages/ActivityPage/utils';
import React, { useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

const ProspectiveClientRow = () => {
  const [openModal, setOpenModal] = useState(false);
  const { displayedMonths } = useTableHeaderContext();
  const { revenueProspectives } = useEstimatedContext();

  const [openCollapse, setOpenCollapse] = useState(false);

  const objective = useMemo(() => {
    return _.sumBy(revenueProspectives, (dpr) => dpr.objective);
  }, [revenueProspectives]);

  const form = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'revenueProspectives',
    keyName: 'key',
  });

  return (
    <React.Fragment>
      <TableRow>
        <CollapsePolyTableCell
          className={styles.tableCell}
          open={openCollapse}
          setOpen={setOpenCollapse}
        >
          <Typography fontSize="0.875rem">
            Autres missions (non signées)
          </Typography>
        </CollapsePolyTableCell>
        {_.map(displayedMonths, (month) => {
          return (
            <CenteredTableCell
              sx={{
                ...estimatedRevenueBackgroundColorSx,
              }}
              key={`prospective-client-row-${month.format('MMM')}`}
            />
          );
        })}
        <CenteredTableCell>
          <Typography fontWeight={'bold'} fontSize="0.875rem">
            {totalFormat(objective, BillingPurchaseOrderCurrencyChoices.Eur)}
          </Typography>
        </CenteredTableCell>
      </TableRow>
      <TransitionPolyTableRow open={openCollapse}>
        {(status) => {
          return (
            <React.Fragment>
              {_.map(fields, (field, index) => {
                return (
                  <ProspectiveRow
                    key={`prospective-row-${field.key}`}
                    dpr={field as DisplayedRevenueProspective}
                    selfRemove={() => remove(index)}
                    index={index}
                    status={status}
                  />
                );
              })}
              <ProspectiveModal
                mode={'create'}
                open={openModal}
                close={() => setOpenModal(false)}
                onSuccess={(dpr) => {
                  append(dpr);
                }}
              />
              <TableRow>
                <BorderedTableCell>
                  <Button
                    startIcon={<AddCircleOutlineOutlined />}
                    onClick={() => setOpenModal(true)}
                  >
                    Ajouter
                  </Button>
                </BorderedTableCell>
                {_.map(displayedMonths, (month) => {
                  return (
                    <CenteredTableCell
                      sx={{
                        ...estimatedRevenueBackgroundColorSx,
                      }}
                      key={`add-body-${month.format('MMM')}`}
                    />
                  );
                })}
                <CenteredTableCell />
                <BorderedTableCell />
              </TableRow>
            </React.Fragment>
          );
        }}
      </TransitionPolyTableRow>
    </React.Fragment>
  );
};

export default ProspectiveClientRow;
