import { TableCell, TableCellProps, Tooltip } from '@mui/material';
import clsx from 'clsx';
import styles from 'components/ActivityMonitoring/ActivityMonitoringTable/styles/TableCell.module.scss';
import React from 'react';

interface DisplayValueTableCellProps {
  warning: boolean;
  tooltipTitle?: string;
}

export default function DisplayValueTableCell({
  warning,
  tooltipTitle,
  ...properties
}: DisplayValueTableCellProps & TableCellProps) {
  if (warning) {
    return (
      <Tooltip
        title={
          "Le collaborateur a déclaré du temps mais n'est plus assigné à cette mission."
        }
        arrow
        placement={'bottom'}
        data-testid={'tooltip-error'}
      >
        <TableCell
          {...properties}
          className={clsx(styles.commonCell, styles.warningCell)}
          sx={{ p: 1, color: 'text.secondary' }}
        />
      </Tooltip>
    );
  } else {
    return (
      <>
        {tooltipTitle ? (
          <Tooltip
            title={tooltipTitle}
            arrow
            placement={'bottom'}
            data-testid={'tooltip-error'}
          >
            <TableCell
              className={clsx(styles.commonCell, properties.className)}
              sx={{
                backgroundColor: 'background.default',
                justifyContent: 'center',
                color: 'text.secondary',
                textAlign: 'center',
                p: '7px 5px 7px 5px',
              }}
              {...properties}
            />
          </Tooltip>
        ) : (
          <TableCell
            className={clsx(styles.commonCell, properties.className)}
            sx={{
              backgroundColor: 'background.default',
              justifyContent: 'center',
              color: 'text.secondary',
              textAlign: 'center',
              p: '7px 5px 7px 5px',
            }}
            {...properties}
          />
        )}
      </>
    );
  }
}
