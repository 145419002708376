import AppBar from '@mui/material/AppBar';
import React from 'react';
import { useSelector } from 'store';

import { useNavbarContextProvider } from '../DesktopNavbar/NavbarContextProvider';
import SearchMissionBar from '.';

export const TOP_HORIZONTAL_SEARCH_BAR_HEIGHT = '54px';

interface TopHorizontalSearchBarProps {
  hideSearchBar?: boolean;
}

export default function TopHorizontalSearchBar({
  hideSearchBar = false,
}: TopHorizontalSearchBarProps) {
  const { drawerWidth } = useNavbarContextProvider();
  const selectedMissions = useSelector(
    (state) => state.activity.selectedMissions
  );

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        width: `calc(100% - ${drawerWidth})`,
        left: drawerWidth,
        height:
          hideSearchBar && selectedMissions.length <= 0
            ? 0
            : TOP_HORIZONTAL_SEARCH_BAR_HEIGHT,
        mt: 2.25,
        backgroundColor: 'white',
        zIndex: 2,
      }}
    >
      <SearchMissionBar hideSearchBar={hideSearchBar} />
    </AppBar>
  );
}
