import { TableHead, TableHeadProps } from '@mui/material';
import React from 'react';

import styles from './styles/PolyTableHead.module.scss';

const PolyTableHead = (properties: TableHeadProps) => {
  return <TableHead {...properties} classes={styles} />;
};

export default PolyTableHead;
