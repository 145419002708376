import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import ContentCutOutlinedIcon from '@mui/icons-material/ContentCutOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import {
  Box,
  Stack,
  Step,
  StepButton,
  Stepper,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import {
  AddressType,
  getStringAddress,
  parseAddress,
} from 'components/MissionFollowUp/DisplayAddress';
import PolyDialogActions from 'components/MUIOverload/PolyDialog/DefaultComponents/PolyDialogActions';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import {
  ActivitiesActivityBillingTypeChoices,
  ActivitiesActivityTypeChoices,
  ActivityNode,
  BillingBillingModalitiesBillingTypeChoices,
  BillingClientNode,
  EmployeeNode,
  useActivityChiefsQuery,
  useAllAssignmentsForActivityForAdminQuery,
  useAllBillingClientQuery,
  useComexQuery,
  useCreateOrUpdateBillingInformationMutation,
  useCreateOrUpdateSubActivitiesMutation,
  useGetpolyconseilClientQuery,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import {
  InitializeButtonName,
  NextButtonName,
  PreviousButtonName,
  SaveButtonName,
} from 'poly-constants';
import React, { useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import {
  setCurrentMission,
  setEditMissionModalVisibility,
} from 'store/activity';
import { graphQlDateFormatter } from 'utils';

import MissionContactForm from './MissionContactForm';
import MissionInfoForm from './MissionInfoForm';
import MissionModalityForm from './MissionModalityForm';
import MissionSplitForm from './MissionSplitForm';
import { Modality } from './ModalitiesMissionForm/Types';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface MissionFormProps {
  closeModal?: () => void;
  hasSuccessfullyInit?: () => void;
  refetch?: () => void;
  onLoaded?: () => void;
  mission: ActivityNode;
  defaultActiveStep?: number;
}

const MissionForm = ({
  closeModal,
  hasSuccessfullyInit,
  refetch,
  onLoaded,
  mission,
  defaultActiveStep = 0,
}: MissionFormProps) => {
  const activityTogglesFeatureFlag = useEnvironmentVariable('activity_toggles');
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [billingClients, setBillingClients] = useState<BillingClientNode[]>([]);
  const isUnbillable =
    mission.type === ActivitiesActivityTypeChoices.Int ||
    mission.type === ActivitiesActivityTypeChoices.Cft;
  const isInitialized = !!mission.billingInformation;
  const isTM = mission.billingType === ActivitiesActivityBillingTypeChoices.Tm;
  const [activeStep, setActiveStep] = React.useState(defaultActiveStep);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  useAllBillingClientQuery({
    onCompleted: (data) => {
      if (data.allBillingClient) {
        setBillingClients(data.allBillingClient as BillingClientNode[]);
      }
    },
  });

  const { refetch: refetchActivityAssignments } =
    useAllAssignmentsForActivityForAdminQuery({
      variables: { activityId: mission.id },
    });

  const employees = useSelector(
    (state) => state.users.currentActivityEmployees
  );
  const polyconseilClient = useGetpolyconseilClientQuery({
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  }).data?.polyconseilClient;
  const [activityChiefs, setActivityChiefs] = useState<EmployeeNode[]>([]);
  const [comex, setComex] = useState<EmployeeNode[]>([]);
  const currentMissionId = useSelector(
    (state) => state.activity.currentMission.id
  );
  const missionId = mission?.id ?? currentMissionId;
  useActivityChiefsQuery({
    variables: { activityIds: missionId ? [missionId] : [] },
    onCompleted: (data) => {
      if (data?.activityChiefs) {
        setActivityChiefs(data.activityChiefs as EmployeeNode[]);
      }
    },
  });
  useComexQuery({
    onCompleted: (data) => {
      if (data?.comex) {
        setComex(data.comex as EmployeeNode[]);
      }
    },
  });

  const handleClose = () => {
    if (closeModal) {
      closeModal();
    }
  };

  const billingModalitiesDefault: Modality[] = [];
  const subActivitiesDefault = _.map(mission.subActivities, (subActivity) => {
    return {
      ...subActivity,
      period: [subActivity.startDate, subActivity.expirationDate],
    };
  });

  mission.billingInformation?.billingModalities?.forEach((modality) => {
    billingModalitiesDefault.push({ ...modality });
  });

  if (billingModalitiesDefault.length === 0) {
    billingModalitiesDefault.push({
      billingType: BillingBillingModalitiesBillingTypeChoices.Email,
      billingEmail: '',
      billingName: '',
    });
  }

  const defaultValues = {
    client: mission.client || '',
    pysId: mission.pysId?.toString() || '',
    isTm: isTM ? 'Régie' : 'Forfait',
    startDate: mission.startDate,
    expirationDate: mission.expirationDate,
    refMarket: mission.billingInformation?.refMarket || '',
    contactName: mission.billingInformation?.contactName || '',
    contactEmail: mission.billingInformation?.contactEmail || '',
    contactPhone: mission.billingInformation?.contactPhone || '',
    accountantName: mission.billingInformation?.accountantName || '',
    accountantEmail: mission.billingInformation?.accountantEmail || '',
    accountantPhone: mission.billingInformation?.accountantPhone || '',
    billingModalities: billingModalitiesDefault || ([] as Modality[]),
    subActivities: subActivitiesDefault,
    selectedChiefs: mission.chiefs || ([] as EmployeeNode[]),
    selectedDirector: mission.director || undefined,
    selectedBillingClient:
      mission.billingInformation?.billingClient || undefined,
    selectedLeadDevs: mission.leadDevs || ([] as EmployeeNode[]),
    description: mission.description,
    techStack: mission.techStack,
  };

  const form = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: defaultValues,
    criteriaMode: 'firstError',
  });

  const { register, errors, handleSubmit, reset, control, setValue, trigger } =
    form;

  const modalitiesFieldArray = useFieldArray({
    control,
    keyName: 'key',
    name: 'billingModalities',
  });

  const subActivitiesFieldArray = useFieldArray({
    control,
    keyName: 'key',
    name: 'subActivities',
  });

  const [isThereModalities, setIsThereModalities] = useState(true);

  const [billingClient, setBillingClient] = useState(
    mission.billingInformation?.billingClient || ({} as BillingClientNode)
  );

  const [createOrUpdateBillingInformationMutation] =
    useCreateOrUpdateBillingInformationMutation({
      onError: (error) => {
        if (error.message === 'Invalid format for phone number') {
          enqueueSnackbar(`Mauvais format de numéro de téléphone`, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(error.message, {
            variant: 'error',
          });
        }
      },
      onCompleted: (data) => {
        if (hasSuccessfullyInit) {
          hasSuccessfullyInit();
        }
        const activity = data.createBillingInformation
          ?.activity as ActivityNode;
        enqueueSnackbar(
          mission.billingInformation
            ? `La mission « ${mission.name} » a été mise à jour`
            : `La mission « ${activity.name} » a bien été initialisée`,
          {
            variant: 'success',
          }
        );
        mission.subActivities?.length
          ? dispatch(setEditMissionModalVisibility(false))
          : handleClose();
        if (refetch) {
          refetch();
        }
        refetchActivityAssignments();
      },
    });

  const [createOrUpdateSubActivitiesMutation] =
    useCreateOrUpdateSubActivitiesMutation({
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
      onCompleted: (data) => {
        const activity = data.createOrUpdateSubActivities
          ?.activity as ActivityNode;
        if (activity) {
          dispatch(setCurrentMission(activity));
        }
        handleClose();
      },
    });

  useEffect(() => {
    if (modalitiesFieldArray.fields.length > 0) {
      setIsThereModalities(true);
    } else {
      setIsThereModalities(false);
    }
  }, [modalitiesFieldArray]);

  function isFormRight() {
    return _.isEmpty(errors) && isThereModalities;
  }

  const [address, setAddress] = useState({} as AddressType);

  useEffect(() => {
    if (mission.id) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mission, reset]);

  useEffect(() => {
    setValue('selectedChiefs', mission.chiefs || activityChiefs);
    setValue('selectedDirector', mission.director || comex[0]);
    setValue('selectedLeadDevs', mission.leadDevs);
  }, [
    mission.billingInformation,
    mission.chiefs,
    mission.director,
    mission.leadDevs,
    employees,
    setValue,
    comex,
    activityChiefs,
  ]);

  useEffect(() => {
    setValue(
      'selectedBillingClient',
      mission.billingInformation?.billingClient || undefined
    );
    setAddress(
      mission?.billingInformation?.billingClient
        ? parseAddress(mission.billingInformation?.billingClient)
        : ({} as AddressType)
    );
  }, [mission, mission.billingInformation, billingClients, setValue]);

  _.forEach(subActivitiesFieldArray.fields, (subActivity, index) => {
    form.register(`subActivities.${index}.id`);
    form.register(`subActivities.${index}.period`);
  });
  _.forEach(modalitiesFieldArray.fields, (modality, index) => {
    form.register(`billingModalities.${index}.id`);
  });

  useEffect(() => {
    _.forEach(subActivitiesFieldArray.fields, (subActivity, index) => {
      if (form.getValues(`subActivities.${index}.id`) !== subActivity.id) {
        form.setValue(`subActivities.${index}.id`, subActivity.id);
        form.setValue(`subActivities.${index}.period`, subActivity.period);
      }
    });
    _.forEach(modalitiesFieldArray.fields, (modality, index) => {
      if (form.getValues(`billingModalities.${index}.id`) !== modality.id) {
        form.setValue(`billingModalities.${index}.id`, modality.id);
      }
    });
  });

  useEffect(() => {
    if (onLoaded && billingClients.length > 0) {
      onLoaded();
    }
  }, [billingClients, onLoaded]);

  if (!mission.name || billingClients.length === 0) {
    return <></>;
  }

  const onSubmit = async (submitValues: typeof defaultValues) => {
    let modalities = [];
    if (isUnbillable) {
      modalities.push({
        billingName: 'Polyconseil',
        billingType: BillingBillingModalitiesBillingTypeChoices.Email,
      });
    } else {
      modalities = submitValues.billingModalities;
      modalities.forEach((modality: Modality) => {
        modality.billingAddress = getStringAddress(
          submitValues.selectedBillingClient
        );
      });
    }

    const { errors } = await createOrUpdateBillingInformationMutation({
      variables: {
        activityId: mission.id,
        billingClientId:
          submitValues.selectedBillingClient?.id || polyconseilClient?.id || '',
        refMarket: submitValues.refMarket,
        directorId: submitValues.selectedDirector?.id || '',
        chiefsIds: _.flatMap(submitValues.selectedChiefs, 'id') || '',
        leadDevsIds: _.flatMap(submitValues.selectedLeadDevs, 'id') || '',
        description: submitValues.description,
        techStack: submitValues.techStack,
        contactName: submitValues.contactName || '',
        contactEmail: submitValues.contactEmail || '',
        contactPhone: submitValues.contactPhone || '',
        accountantName: submitValues.accountantName || '',
        accountantEmail: submitValues.accountantEmail || '',
        accountantPhone: submitValues.accountantPhone || '',
        billingModalities: modalities,
      },
    });

    if (!errors) {
      await createOrUpdateSubActivitiesMutation({
        variables: {
          mainActivityId: mission.id,
          subActivities: _.map(submitValues.subActivities, (subActivity) => {
            return {
              name: subActivity.name,
              id: subActivity.id,
              startDate: graphQlDateFormatter(
                subActivity.period?.[0] || moment(mission.startDate).toDate()
              ),
              expirationDate: graphQlDateFormatter(
                subActivity.period?.[1] ||
                  moment(mission.expirationDate).toDate()
              ),
            };
          }),
        },
      });
    }
  };

  const steps = [
    {
      label: 'Informations',
      display: true,
      icon: <InfoOutlinedIcon />,
      validationFields: [
        'selectedBillingClient',
        'selectedChiefs',
        'selectedDirector',
      ],
      additionalValidation: true,
      form: (
        <MissionInfoForm
          mission={mission}
          form={form}
          isUnbillable={isUnbillable}
          billingClient={billingClient}
          billingClients={billingClients}
          activityChiefs={activityChiefs}
          employees={employees}
          comex={comex}
          setAddress={setAddress}
          setBillingClient={setBillingClient}
        />
      ),
    },
    {
      label: 'Contact',
      display: !isUnbillable,
      icon: <CallOutlinedIcon />,
      validationFields: [
        'accountantName',
        'accountantEmail',
        'contactName',
        'contactEmail',
      ],
      additionalValidation: true,
      form: <MissionContactForm errors={errors} register={register} />,
    },
    {
      label: 'Modalités de facturation',
      display: !isUnbillable,
      icon: <SendOutlinedIcon />,
      validationRegEx: /^billingModalities/,
      additionalValidation: isThereModalities,
      form: (
        <MissionModalityForm
          address={address}
          mission={mission}
          modalitiesFieldArray={modalitiesFieldArray}
          isThereModalities={isThereModalities}
        />
      ),
    },
    {
      label: 'Découpage',
      display: !isUnbillable && (activityTogglesFeatureFlag || isTM),
      icon: <ContentCutOutlinedIcon />,
      form: (
        <MissionSplitForm
          mission={mission}
          subActivitiesFieldArray={subActivitiesFieldArray}
        />
      ),
    },
  ];

  const filteredSteps = steps.filter((step) => step.display);

  const totalSteps = () => {
    return filteredSteps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveStep(newValue);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const validateStep = async (): Promise<boolean> => {
    const currentFieldsRefKeys = Object.keys(control.fieldsRef.current);
    const validationFields = filteredSteps[activeStep].validationFields;
    const validationRegEx = filteredSteps[activeStep].validationRegEx;
    const additionalValidation =
      !!filteredSteps[activeStep].additionalValidation;
    let result = true;

    if (Array.isArray(validationFields)) {
      result = await trigger(filteredSteps[activeStep].validationFields);
    } else if (validationRegEx) {
      for (const fieldRefKey of currentFieldsRefKeys) {
        if (validationRegEx.test(fieldRefKey)) {
          const currentValidation = await trigger(fieldRefKey);
          if (!currentValidation) {
            result = false;
          }
        }
      }
    }
    return result && additionalValidation;
  };

  const handleNext = async () => {
    if (filteredSteps.length > 1 && filteredSteps.length > activeStep + 1) {
      if (await validateStep()) {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const navTabs = (
    <>
      {isInitialized ? (
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            mb: 2,
            mt: 1,
            minWidth: 852,
          }}
        >
          <Tabs
            centered
            value={activeStep}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            {filteredSteps.map((step, index) => (
              <Tab
                key={step.label}
                icon={step.icon}
                iconPosition="start"
                label={step.label}
                aria-label={`nav-tab-${index}`}
                {...a11yProps(index)}
                sx={{ display: step.display ? 'inherit' : 'none' }}
              />
            ))}
          </Tabs>
        </Box>
      ) : (
        <Box sx={{ width: '100%', mt: 4, minWidth: 852 }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {filteredSteps.map((step, index) => (
              <Step
                key={step.label}
                completed={completed[index]}
                sx={{ display: step.display ? 'inherit' : 'none' }}
              >
                <StepButton
                  aria-label={`stepper-tab-${index}`}
                  color="inherit"
                  onClick={handleStep(index)}
                >
                  <Typography
                    textTransform={'uppercase'}
                    variant="bodyS"
                    color={index === activeStep ? 'primary' : 'inherit'}
                  >
                    {step.label}
                  </Typography>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>
      )}
    </>
  );

  const actionButtons = (
    <>
      {!isInitialized ? (
        <PolyDialogActions
          key={`actions-${activeStep}`}
          primaryButtonName={
            isLastStep() ? InitializeButtonName : NextButtonName
          }
          secondaryButtonName={PreviousButtonName}
          handlePrimary={handleNext}
          handleSecondary={handleBack}
          primaryButtonType={isLastStep() ? 'submit' : 'button'}
          isPrimaryButtonDisabled={
            isLastStep()
              ? !isFormRight()
              : !filteredSteps[activeStep].additionalValidation
          }
          isSecondaryButtonDisabled={activeStep === 0}
        />
      ) : (
        <PolyDialogActions
          key={`actions-${activeStep}`}
          primaryButtonName={SaveButtonName}
          handleSecondary={closeModal}
          primaryButtonType={'submit'}
          isPrimaryButtonDisabled={!isFormRight()}
        />
      )}
    </>
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit((d) => onSubmit(d))}>
        {navTabs}
        {filteredSteps.map((step, index) => (
          <Box
            key={`mission-form-step-${index}`}
            sx={{ display: activeStep === index ? 'inherit' : 'none' }}
          >
            {step.form}
          </Box>
        ))}
        <Stack direction={'row'} justifyContent={'right'} alignItems={'center'}>
          {actionButtons}
        </Stack>
      </form>
    </FormProvider>
  );
};

export default MissionForm;
