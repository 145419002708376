import {
  Box,
  FormControlLabel,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import PageWrapper from 'components/commons/PageWrapper';
import TabHeader from 'components/commons/TabHeader';
import { FlatTable } from 'components/commons/Tables/FlatTable';
import TableHeaderContextProvider from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import YearSelector from 'components/commons/YearSelector/YearSelector';
import ProfitabilityMissionViewExportButton from 'components/Reporting/Profitability/MissionView/ProfitabilityMissionViewExportButton';
import ProfitabilityMissionViewMonthTableHeader from 'components/Reporting/Profitability/MissionView/ProfitabilityMissionViewMonthTableHeader';
import ProfitabilityMissionViewTrimesterTableHeader from 'components/Reporting/Profitability/MissionView/ProfitabilityMissionViewTrimesterTableHeader';
import ProfitabilityMissionViewTableBody from 'components/Reporting/Profitability/MissionView/ProfitabilityTableBody/ProfitabilityMissionViewTableBody';
import moment from 'moment/moment';
import { PageTitles } from 'poly-constants';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'store';
import urlJoin from 'url-join';

export default function ReportingProfitabilityMissionView() {
  const { pathname, search } = useLocation();
  const urlParam = new URLSearchParams(search);
  const selectedYear = urlParam.has('year')
    ? urlParam.get('year')
    : moment().year().toString();
  const [isYearSubtracted, setIsYearSubtracted] = useState(false);
  const [isYearAdded, setIsYearAdded] = useState(false);
  const [currentYear, setCurrentYear] = useState(moment(selectedYear));
  const pathHasTrimesterView = pathname.includes('trimester-view');
  const [isTrimesterView, setIsTrimesterView] = useState(pathHasTrimesterView);
  const [isExcludingIntern, setisExcludingIntern] = useState(false);
  const [monthRangeSize, setMonthRangeSize] = useState(isTrimesterView ? 9 : 3);
  const currentMission = useSelector((state) => state.activity.currentMission);
  function changeView() {
    setIsTrimesterView(!isTrimesterView);
    setMonthRangeSize(isTrimesterView ? 3 : 9);
  }

  function handleWithInternChange(event: React.SyntheticEvent) {
    const target = event.target as HTMLInputElement;
    setisExcludingIntern(target.checked);
  }

  return (
    <PageWrapper missionView>
      <TableHeaderContextProvider
        currentYear={currentYear}
        setCurrentYear={setCurrentYear}
        monthRangeSize={monthRangeSize}
        monthOffset={isTrimesterView ? -7 : -1}
        isYearSubtracted={isYearSubtracted}
        isYearAdded={isYearAdded}
      >
        <TabHeader
          title={PageTitles.profitabilityPage}
          yearSelectorComponent={
            <YearSelector
              currentYear={currentYear}
              setCurrentYear={(NewYear: moment.Moment) => {
                setCurrentYear(NewYear);
                setIsYearSubtracted(false);
                setIsYearAdded(false);
              }}
              from={moment('2022-01-01')}
              to={moment()}
            />
          }
          filterComponents={
            <Stack direction={'row'} alignItems={'center'} gap={2} mb={0.5}>
              <FormControlLabel
                label={
                  <Tooltip title="Vue trimestrielle">
                    <Typography>Vue trimestrielle</Typography>
                  </Tooltip>
                }
                control={<Switch />}
                onChange={changeView}
                checked={isTrimesterView}
                labelPlacement="end"
              />
              <FormControlLabel
                label={
                  <Tooltip title="Sans stagiaires">
                    <Box
                      component="img"
                      alt="without intern logo"
                      src={urlJoin(
                        '/',
                        process.env.PUBLIC_URL,
                        'img',
                        'without-intern.png'
                      )}
                      sx={{ height: '1.5em', width: '1.5em' }}
                    />
                  </Tooltip>
                }
                control={<Switch />}
                onChange={handleWithInternChange}
                checked={isExcludingIntern}
                disableTypography={true}
                labelPlacement="end"
              />
            </Stack>
          }
          actionComponents={
            <ProfitabilityMissionViewExportButton
              year={currentYear.year()}
              activity={currentMission}
              isTrimester={isTrimesterView}
              isExcludingIntern={isExcludingIntern}
            />
          }
        />
        <FlatTable size={'small'} sx={{ tableLayout: 'fixed' }}>
          {isTrimesterView ? (
            <ProfitabilityMissionViewTrimesterTableHeader />
          ) : (
            <ProfitabilityMissionViewMonthTableHeader
              setCurrentYear={setCurrentYear}
              setIsYearSubtracted={setIsYearSubtracted}
              setIsYearAdded={setIsYearAdded}
            />
          )}
          <ProfitabilityMissionViewTableBody
            isTrimesterView={isTrimesterView}
            isExcludingIntern={isExcludingIntern}
          />
        </FlatTable>
      </TableHeaderContextProvider>
    </PageWrapper>
  );
}
