import { DoneAll } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import {
  Box,
  Grid,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { stickyHeaderSx } from 'components/commons/SX/commonSx';
import { ValidationButton } from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/ValidationButton';
import { CancelEditValidationButton } from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/ValidationButton/CancelValidationButton';
import { EditValidationButton } from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/ValidationButton/EditValidationButton';
import {
  checkExceptionalMonth,
  isInRange,
} from 'components/Revenue/Tables/util';
import NavigationButton from 'components/Revenue/utils/NavigationButton';
import { useUserInfo } from 'components/User/UserProvider';
import { ActivitiesActivityBillingTypeChoices } from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';

import { useTableContext } from './context/TableContextProvider';
import { isMonthAutoValidated, isMonthValidated } from './utils';

export const howTotalIsCalculated =
  'Si un montant maximal n’a pas été renseigné, le total d’un BDC régie est le produit de la somme des TJMs des collaborateurs et du nombre de jours ouvrés du BDC';

const tableCellSx = { bb: '0px solid', p: 0, height: '30px' };

const RevenueTableHeader = () => {
  const {
    displayedMonths,
    incrementMonth,
    decrementMonth,
    setTotalMission,
    purchaseOrders,
    activity,
    purchaseOrdersRange,
    exceptionalMonth,
    isMissionEditable,
  } = useTableContext();
  const { palette } = useTheme();
  const { isControl, isSuperuser } = useUserInfo();
  const yearsSet = new Set();
  _.forEach(displayedMonths, (month) => yearsSet.add(month.format('YYYY')));
  const years = [...yearsSet];

  const lastMonthToCheckForValidation =
    activity?.billingType === ActivitiesActivityBillingTypeChoices.Tm
      ? moment(purchaseOrdersRange.end).add(1, 'month')
      : purchaseOrdersRange.end;

  const firstUnvalidatedMonth = useMemo(() => {
    const m = moment(purchaseOrdersRange.start).startOf('month');
    for (; m.isSameOrBefore(lastMonthToCheckForValidation); m.add(1, 'month')) {
      if (!isMonthValidated(purchaseOrders, m)) {
        return m;
      }
    }
    return m;
  }, [
    purchaseOrdersRange.start,
    purchaseOrders,
    lastMonthToCheckForValidation,
  ]);

  const totalColumnLabel =
    activity?.billingType === ActivitiesActivityBillingTypeChoices.Tm
      ? 'Montant Max (HT)'
      : 'Montant (HT)';

  return (
    <TableHead sx={stickyHeaderSx}>
      <TableRow sx={tableCellSx}>
        <TableCell sx={{ width: '185px', ...tableCellSx }} />
        <TableCell sx={tableCellSx} />
        <TableCell colSpan={6} sx={tableCellSx}>
          <Box sx={{ textAlign: 'center' }}>
            {years[0]}
            {years.length > 1 && ` - ${years[1]}`}
          </Box>
        </TableCell>
      </TableRow>
      <TableRow sx={tableCellSx}>
        <TableCell
          sx={{
            width: '185px',
            p: 0,
            position: 'relative',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          Bon de commande (BDC)
        </TableCell>
        <TableCell
          sx={{
            p: 0,
            position: 'relative',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          {totalColumnLabel}
        </TableCell>
        {_.map(displayedMonths, (month, index) => {
          const isValidated = month.isBefore(firstUnvalidatedMonth, 'month');
          const isAutoValidated = isMonthAutoValidated(purchaseOrders, month);
          const isExceptionalMonth = checkExceptionalMonth(
            month,
            exceptionalMonth
          );
          const toBeValidated = month.isSame(firstUnvalidatedMonth, 'month');
          const isPOListNotEmpty = !(purchaseOrders.length === 0);
          const isRangeValid = isInRange(
            purchaseOrdersRange.start,
            lastMonthToCheckForValidation,
            month
          );

          function toBold(text: string) {
            return (
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color: 'white',
                  fontSize: '12px',
                  display: 'inline',
                }}
              >
                {text}
              </Typography>
            );
          }

          const isValidatedBy = () => {
            if (isAutoValidated) {
              return 'Mois validé automatiquement';
            }
            const purchaseOrdersOnMonth = _.filter(
              purchaseOrders,
              ({ periodBeginning, periodEnding }) =>
                isInRange(periodBeginning, periodEnding, month)
            );
            for (const po of purchaseOrdersOnMonth) {
              const indexRevenue = po.revenues?.findIndex(
                (revenue) =>
                  revenue?.validatedBy && month.isSame(revenue?.month, 'month')
              );
              if (indexRevenue && indexRevenue !== -1) {
                const revenue = po.revenues?.[indexRevenue];
                return (
                  revenue !== undefined && (
                    <Typography sx={{ color: 'white', fontSize: '12px' }}>
                      Mois validé par
                      {toBold(
                        ` ${revenue.validatedBy?.firstName} ${revenue.validatedBy?.lastName}`
                      )}{' '}
                      le
                      {toBold(
                        ` ${moment(revenue?.validationDate).format(
                          'DD/MM/YYYY'
                        )}`
                      )}
                    </Typography>
                  )
                );
              }
            }
            return 'Mois validé manuellement';
          };

          return (
            <TableCell
              sx={{
                textTransform: 'capitalize',
                p: 0,
                position: 'relative',
                textAlign: 'center',
                fontWeight: 'bold',
              }}
              style={{
                ...(month.isSame(moment(), 'month') && {
                  color: palette.primary.main,
                  boxShadow: `inset 0 0 ${palette.primary.main}, inset 0 -4px ${palette.primary.main}`,
                }),
              }}
              key={`header-${month.format('MMM')}`}
            >
              <Grid container direction={'row'} alignItems={'center'}>
                <Grid item xs={2}>
                  {index === 0 && (
                    <NavigationButton
                      navigation={() => {
                        decrementMonth();
                        setTotalMission({});
                      }}
                      type={'before'}
                    />
                  )}
                </Grid>
                <Grid item xs={8} justifyContent={'center'} display={'flex'}>
                  {isValidated &&
                    isRangeValid &&
                    isPOListNotEmpty &&
                    !exceptionalMonth && (
                      <Tooltip title={isValidatedBy()}>
                        {isAutoValidated ? (
                          <CheckIcon
                            sx={{
                              position: 'absolute',
                              ml: '56%',
                              color: 'success.main',
                            }}
                          />
                        ) : (
                          <DoneAll
                            sx={{
                              position: 'absolute',
                              ml: '56%',
                              color: 'success.main',
                            }}
                          />
                        )}
                      </Tooltip>
                    )}

                  {isRangeValid &&
                    isMissionEditable &&
                    isPOListNotEmpty &&
                    ((toBeValidated && (isControl || isSuperuser)) ||
                      (isExceptionalMonth && isControl)) && (
                      <ValidationButton month={month} />
                    )}

                  {isRangeValid &&
                    isMissionEditable &&
                    isPOListNotEmpty &&
                    isExceptionalMonth &&
                    (isControl || isSuperuser) && (
                      <CancelEditValidationButton />
                    )}
                  {isRangeValid &&
                    isValidated &&
                    isMissionEditable &&
                    !exceptionalMonth &&
                    isControl &&
                    isPOListNotEmpty && <EditValidationButton month={month} />}
                  {month.format('MMM')}
                </Grid>
                <Grid item xs={2} display={'flex'} justifyContent={'end'}>
                  {index === 5 && (
                    <NavigationButton
                      navigation={() => {
                        incrementMonth();
                        setTotalMission({});
                      }}
                      type={'next'}
                    />
                  )}
                </Grid>
              </Grid>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default RevenueTableHeader;
