import CallForTendersIcon from '@mui/icons-material/Explore';
import HrIcon from '@mui/icons-material/LocalLibrary';
import InternIcon from '@mui/icons-material/Pages';
import { Stack, Typography } from '@mui/material';
import AvatarInitial from 'components/commons/AvatarInitial/AvatarInitial';
import { isTrimesterStart } from 'components/commons/Tables/Header/ArrowsUpdateTrimesterTableHeader';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import CollapsePolyTableCell from 'components/MUIOverload/PolyTableCell/CollapsePolyTableCell';
import ChildCollapsePolyTableRow from 'components/MUIOverload/PolyTableRow/ChildCollapsePolyTableRow';
import TransitionPolyTableRow from 'components/MUIOverload/PolyTableRow/TransitionPolyTableRow';
import PolyTooltip from 'components/MUIOverload/PolyTooltip';
import styles from 'components/Revenue/Estimated/EstimatedRevenueTables/EstimatedRevenueGlobalTable/styles/TableCell.module.scss';
import OutOfRangeTableCell from 'components/Revenue/Tables/RevenueTableCells/OutOfRangeTableCell';
import { isInRange } from 'components/Revenue/Tables/util';
import {
  ActivitiesActivityTypeChoices,
  ActivityNode,
  ActivityProfitNode,
  PublicHoliday,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment/moment';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { TransitionStatus } from 'react-transition-group/Transition';
import { displayEmployeeNode, getMissionLastActiveMonth } from 'utils';

import ProfitabilityDataComingCell from './Cells/ProfitabilityDataComingCell';
import ProfitabilitySubMissionRow from './ProfitabilitySubMissionRow';
import { CallProfitabilityRenderValues, getYearToDateProfit } from './utils';

function getRowValues(
  month: moment.Moment,
  activity: ActivityNode,
  isTrimesterView: boolean | undefined
) {
  if (isTrimesterView && !isTrimesterStart(month)) {
    return (
      <React.Fragment
        key={`profitability-mission-${activity.id}-${month.format('MMMM')}`}
      />
    );
  }

  const profitForMonth = _.find(activity.activityProfits, (profit) =>
    moment(isTrimesterView ? profit.trimester : profit.month).isSame(
      month,
      'month'
    )
  );
  if (profitForMonth) {
    return (
      <CallProfitabilityRenderValues
        key={`profitability-mission-${activity.id}-${month.format('MMMM')}`}
        month={month}
        profit={profitForMonth}
        isTrimesterView={isTrimesterView}
      />
    );
  }

  const isActivityActive =
    isInRange(activity.startDate, activity.expirationDate, month) ||
    (!activity.startDate && !activity.expirationDate);
  if (!isActivityActive) {
    return (
      <OutOfRangeTableCell
        key={`profitability-mission-${activity.id}-${month.format('MMMM')}`}
        title="La mission n'est pas active sur ce mois"
        colSpan={5}
      />
    );
  }

  const hasActivePurchaseOrder = _.some(
    activity.billingInformation?.purchaseOrders,
    ({ periodBeginning, periodEnding }) =>
      isInRange(periodBeginning, periodEnding, month)
  );
  if (!hasActivePurchaseOrder && !!activity.billingInformation) {
    return (
      <OutOfRangeTableCell
        key={`profitability-mission-${activity.id}-${month.format('MMMM')}`}
        title="La mission n’a pas de BDC actif sur ce mois"
        colSpan={5}
      />
    );
  }

  return (
    <ProfitabilityDataComingCell
      key={`profitability-mission-${activity.id}-${month.format('MMMM')}`}
      reason={
        isTrimesterView || !activity.billingInformation
          ? undefined
          : 'CA non validé'
      }
      colSpan={5}
    />
  );
}
interface ProfitabilityMissionRowProps {
  activity: ActivityNode;
  status: TransitionStatus;
  isTrimesterView?: boolean;
  currentYear: moment.Moment;
  publicHolidays?: PublicHoliday[];
  yearToDateMonthWorkDays?: number;
}

export default function ProfitabilityMissionRow({
  activity,
  status,
  isTrimesterView,
  publicHolidays,
  currentYear,
  yearToDateMonthWorkDays,
}: ProfitabilityMissionRowProps) {
  const { displayedMonths } = useTableHeaderContext();
  const history = useHistory();
  const [openCollapse, setOpenCollapse] = useState(false);

  if (
    isTrimesterView &&
    (activity.activityProfits?.length === 0 ||
      (!_.some(displayedMonths, (month) =>
        isInRange(
          activity.startDate,
          getMissionLastActiveMonth(activity),
          month
        )
      ) &&
        activity.startDate &&
        activity.expirationDate))
  ) {
    return <></>;
  }
  const yearToDateActivityProfit = !isTrimesterView
    ? (getYearToDateProfit(
        activity.activityProfits,
        displayedMonths,
        publicHolidays as PublicHoliday[]
      ) as ActivityProfitNode)
    : undefined;

  return (
    <>
      <ChildCollapsePolyTableRow
        status={status}
        key={`mission-${activity.id}-${
          activity.activityProfits?.[0].month ||
          activity.activityProfits?.[0].trimester
        }`}
      >
        <CollapsePolyTableCell
          className={styles.tableCell}
          open={openCollapse}
          setOpen={setOpenCollapse}
          hasSubActivities={activity.subActivities?.length !== 0}
        >
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <PolyTooltip title={getActivityTooltip(activity)}>
              <span>{getActivityIcon(activity)}</span>
            </PolyTooltip>
            {activity.isShared ? (
              <Typography variant={'body2'} color={'text.secondary'}>
                {activity.name}
              </Typography>
            ) : (
              <Link
                to={history.createHref({
                  pathname: '/mission-followup/reporting/profitability',
                  search: `?mission_id=${activity.id}&name=${
                    activity.name
                  }&year=${currentYear.year()}`,
                  state: { isTrimesterView: isTrimesterView },
                })}
                rel={'noopener noreferrer'}
              >
                <Typography variant={'body2'} color={'text.secondary'}>
                  {activity.name}
                </Typography>
              </Link>
            )}
          </Stack>
        </CollapsePolyTableCell>
        {_.map(displayedMonths, (month) => {
          return getRowValues(month, activity, isTrimesterView);
        })}
        {yearToDateActivityProfit && (
          <CallProfitabilityRenderValues
            key={`profitability-mission-year-to-date-${
              activity.id
            }-${yearToDateActivityProfit?.month.format('MMMM')}`}
            month={yearToDateActivityProfit?.month}
            profit={yearToDateActivityProfit}
            yearToDateMonthWorkDays={yearToDateMonthWorkDays}
            isTrimesterView={isTrimesterView}
            isEtpCumulated={true}
          />
        )}
      </ChildCollapsePolyTableRow>
      {activity.subActivities &&
        _.map(activity.subActivities, (subActivity) => {
          return (
            <TransitionPolyTableRow
              open={openCollapse}
              key={`profitability-sub-mission-${subActivity.id}`}
            >
              {(status) => {
                return (
                  <>
                    <ProfitabilitySubMissionRow
                      status={status}
                      activity={activity}
                      subActivity={subActivity}
                      isTrimesterView={isTrimesterView}
                      yearToDateMonthWorkDays={yearToDateMonthWorkDays}
                    />
                  </>
                );
              }}
            </TransitionPolyTableRow>
          );
        })}
    </>
  );
}

const getActivityTooltip = (activity: ActivityNode) => {
  if (activity.type === ActivitiesActivityTypeChoices.Int) {
    return 'Mission interne';
  }
  if (activity.type === ActivitiesActivityTypeChoices.Hr) {
    return 'Vie du cabinet';
  }
  if (activity.type === ActivitiesActivityTypeChoices.Cft) {
    return "Appel d'offre";
  }
  if (
    activity.type === ActivitiesActivityTypeChoices.Ext &&
    !activity.billingInformation
  ) {
    return '';
  } else {
    return displayEmployeeNode(activity.director, false);
  }
};

const getActivityIcon = (activity: ActivityNode) => {
  if (activity.type === ActivitiesActivityTypeChoices.Int) {
    return <InternIcon />;
  }
  if (activity.type === ActivitiesActivityTypeChoices.Hr) {
    return <HrIcon />;
  }
  if (activity.type === ActivitiesActivityTypeChoices.Cft) {
    return <CallForTendersIcon />;
  } else {
    return <AvatarInitial employee={activity.director} />;
  }
};
