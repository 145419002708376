import { Chip, useTheme } from '@mui/material';
import EmployeeOccupationIndicator from 'components/Phonebook/EmployeeCard/EmployeeOccupationIndicator/EmployeeOccupationIndicator';
import React from 'react';

interface RoleProps {
  role: string;
  onClick: (arg: string) => void;
  isSelected?: boolean;
}

export default function RoleChip({ role, onClick, isSelected }: RoleProps) {
  const { palette } = useTheme();
  return (
    <Chip
      sx={{
        borderRadius: '20px',
        width: 'fit-content',
        mt: 2,
        height: '38px',
        backgroundColor: 'lightGrey.main',
        ...(isSelected && {
          border: '2px solid',
          borderColor: palette.primary.main,
        }),
      }}
      variant="outlined"
      label={<EmployeeOccupationIndicator occupation={role} />}
      key={role}
      onClick={() => onClick(role)}
    />
  );
}
