import { DialogContent, Typography } from '@mui/material';
import BillForm, {
  BillFormMode,
} from 'components/MissionFollowUp/BillDetail/BillDetail/BillForm';
import PolyDialog from 'components/MUIOverload/PolyDialog';
import { ActivitiesActivityBillingTypeChoices } from 'generated/graphql';
import React from 'react';
import { useSelector } from 'store';

interface CreateOrUpdateBillModalProps {
  isCloningBill?: boolean;
  isEditingBill?: boolean;
  open: boolean;
  onClose: () => void;
}

const CreateOrUpdateBillModal = (properties: CreateOrUpdateBillModalProps) => {
  const currentMission = useSelector((state) => state.activity.currentMission);
  const missionType =
    currentMission.billingType === ActivitiesActivityBillingTypeChoices.Tm
      ? 'régie'
      : 'forfait';

  const closeModal = () => {
    properties.onClose();
  };

  function getSettings() {
    if (properties.isCloningBill) {
      return {
        title: `Dupliquer une facture ${missionType}`,
        mode: BillFormMode.DUPLICATE,
      };
    } else if (properties.isEditingBill) {
      return {
        title: `Éditer une facture ${missionType}`,
        mode: BillFormMode.EDIT,
      };
    } else {
      return {
        title: `Créer une nouvelle facture ${missionType}`,
        mode: BillFormMode.CREATE,
      };
    }
  }

  const settings = getSettings();

  return (
    <PolyDialog
      fullWidth={true}
      maxWidth={'lg'}
      open={properties.open}
      onClose={closeModal}
      disableEnforceFocus={true}
    >
      <DialogContent>
        <Typography
          variant="h3Bold"
          component="h3"
          sx={{ mb: 3, color: 'primary.main' }}
        >
          {settings.title}
        </Typography>
        <BillForm
          validCallback={closeModal}
          cancelCallback={closeModal}
          mode={settings.mode}
        />
      </DialogContent>
    </PolyDialog>
  );
};

export default CreateOrUpdateBillModal;
