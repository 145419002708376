import { Typography } from '@mui/material';
import {
  EmployeeNode,
  EmployeesEmployeeContractTypeChoices,
  EmployeesEmployeeExperienceChoices,
  EmployeesEmployeeGradeChoices,
} from 'generated/graphql';
import React from 'react';

export enum EmployeesEmployeeOccupationChoices {
  tenders = "Appels D'offres",
  other = 'Autre',
  commercial = 'Commercial',
  data = 'Data',
  dev = 'Dév',
  digitalConsultant = 'Digital Consultant',
  finance = 'Finance',
  infraDevOps = 'Infra DevOps',
  mixte = 'Mixte',
  productManager = 'Product Manager',
  recrutement = 'Recrutement',
  rh = 'RH',
  designer = 'UI-UX Designer',
  validation = 'Validation',
}

export const EmployeeExperienceTextChoices: {
  [key in EmployeesEmployeeExperienceChoices]: string;
} = {
  LESS_1_YEAR: '< 1 an',
  LESS_2_YEARS: '1 < xp < 2 ans',
  LESS_5_YEARS: '2 < xp < 5 ans',
  LESS_10_YEARS: '5 < xp < 10 ans',
  MORE_10_YEARS: '> 10 ans',
};

export const EmployeeContractTextChoices: {
  [key in EmployeesEmployeeContractTypeChoices]: string;
} = {
  CDI: 'CDI',
  CDD: 'CDD',
  INTERN: 'Stage',
  INTERN_PRE_JOB: "Stage fin d'études",
  PROVIDER: 'Prestataire',
  STUDENT_APPRENTICE: 'Alternant',
};

export const EmployeeGradeTextChoices: {
  [key in EmployeesEmployeeGradeChoices]: string;
} = {
  COLLABORATOR: 'Collaborateur',
  COMAN: 'COMAN',
  COMEX: 'COMEX',
  PRESIDENT: 'Président',
};

export const EmployeeRoleTextChoices: {
  [key in string]: string;
} = {
  COMAN: 'Coman',
  COMEX: 'Comex',
  CDI: 'CDI',
  INTERN: 'Stagiaire',
  PROVIDER: 'Prestataire',
  STUDENT_APPRENTICE: 'Alternant',
};

export const EmployeeStatusTextChoices: {
  [key in string]: string;
} = {
  LEFT: 'Parti',
  PRESENT: 'Présent',
  COMING: 'Futur',
};

export function getStatusName(
  status: EmployeesEmployeeGradeChoices | EmployeesEmployeeContractTypeChoices
) {
  let statusText = '';
  switch (status) {
    case EmployeesEmployeeContractTypeChoices.StudentApprentice:
      statusText = 'Alternant';
      break;
    case EmployeesEmployeeContractTypeChoices.Cdi:
      statusText = 'CDI';
      break;
    case EmployeesEmployeeGradeChoices.Coman:
      statusText = 'Coman';
      break;
    case EmployeesEmployeeGradeChoices.Comex:
      statusText = 'Comex';
      break;
    case EmployeesEmployeeContractTypeChoices.Provider:
      statusText = 'Prestataire';
      break;
    case EmployeesEmployeeContractTypeChoices.Intern:
      statusText = 'Stagiaire';
      break;
    default:
      break;
  }

  return statusText;
}

export const getEmployeeGradeVariant = (employee: EmployeeNode) => {
  switch (employee.grade) {
    case EmployeesEmployeeGradeChoices.Coman:
      return 'coman';
    case EmployeesEmployeeGradeChoices.Comex:
    case EmployeesEmployeeGradeChoices.President:
      return 'comex';
    default:
      return 'grade';
  }
};

export function getRoleName(employee: EmployeeNode) {
  const isComan = employee.grade === EmployeesEmployeeGradeChoices.Coman;
  const isComex =
    employee.grade === EmployeesEmployeeGradeChoices.Comex ||
    employee.grade === EmployeesEmployeeGradeChoices.President;
  const isProvider =
    employee.contractType === EmployeesEmployeeContractTypeChoices.Provider;
  const isIntern =
    employee.contractType === EmployeesEmployeeContractTypeChoices.Intern ||
    employee.contractType === EmployeesEmployeeContractTypeChoices.InternPreJob;
  const isAlternant =
    employee.contractType ===
    EmployeesEmployeeContractTypeChoices.StudentApprentice;
  if (isComan) {
    return getStatusName(EmployeesEmployeeGradeChoices.Coman).toUpperCase();
  } else if (isComex) {
    return getStatusName(EmployeesEmployeeGradeChoices.Comex).toUpperCase();
  } else if (isProvider) {
    return getStatusName(
      EmployeesEmployeeContractTypeChoices.Provider
    ).toUpperCase();
  } else if (isIntern) {
    return getStatusName(
      EmployeesEmployeeContractTypeChoices.Intern
    ).toUpperCase();
  } else if (isAlternant) {
    return getStatusName(
      EmployeesEmployeeContractTypeChoices.StudentApprentice
    ).toUpperCase();
  }
  return EmployeesEmployeeContractTypeChoices.Cdi;
}

export const displaySecondaryInformation = (
  secondaryInformation: string | undefined
) => {
  if (!secondaryInformation) {
    return;
  }
  return (
    <Typography variant="label" sx={{ color: 'text.secondary' }}>
      {secondaryInformation}
    </Typography>
  );
};

export const getRolesName = (occupation: string) => {
  return occupation.split(' / ').map((role) => role.trim());
};

export enum AvatarDisplaySize {
  MOBILEDRAWER,
  PHONE,
  STANDARD,
  LARGE,
}

export enum AvatarDisplayMode {
  NONE,
  PROFILE,
  INITIALIZATION,
  PHONEBOOK,
}

export function mapToAvatarSize(
  sizeQualifier: AvatarDisplaySize | undefined
): string {
  switch (sizeQualifier) {
    case AvatarDisplaySize.MOBILEDRAWER:
      return '140px';
    case AvatarDisplaySize.PHONE:
      return '100%';
    case AvatarDisplaySize.STANDARD:
      return '184px';
    case AvatarDisplaySize.LARGE:
      return '291px';
    default:
      return '184px';
  }
}
