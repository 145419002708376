import {
  ActivityNode,
  EmployeeNode,
  EmployeesEmployeeContractTypeChoices,
  EmployeesEmployeeGradeChoices,
} from 'generated/graphql';
import _ from 'lodash';
import { PhonebookChipData } from 'utils';

import { getEmployeeOccupationInfo } from '../EmployeeCard/EmployeeOccupationIndicator/EmployeeOccupationIndicator';
import { EmployeesEmployeeOccupationChoices, getRolesName } from '../utils';

export type PhonebookOptionSelected =
  | EmployeeNode
  | ActivityNode
  | PhonebookChipData;

export const checkEmployeeStatusWithFilter = (
  filteredStatus: (
    | EmployeesEmployeeGradeChoices
    | EmployeesEmployeeContractTypeChoices
  )[],
  employee: EmployeeNode
) => {
  const filterStatus = _.flatMap(filteredStatus, (status) => {
    if (status === EmployeesEmployeeContractTypeChoices.Intern) {
      return [status, EmployeesEmployeeContractTypeChoices.InternPreJob];
    }
    return status as
      | EmployeesEmployeeContractTypeChoices
      | EmployeesEmployeeGradeChoices;
  });

  return (
    filteredStatus.length <= 0 ||
    _.includes(filterStatus, employee.contractType) ||
    _.includes(filterStatus, employee.grade)
  );
};

export const checkEmployeeRolesWithFilter = (
  filteredRoles: string[],
  employeeOccupation: string
) => {
  const { isOther, occupationName } = getEmployeeOccupationInfo(
    employeeOccupation,
    false
  );
  const isOtherFiltered = filteredRoles.includes(
    EmployeesEmployeeOccupationChoices.other
  );
  const employeeOccupations = getRolesName(employeeOccupation);

  const checkRoleFilter =
    (employeeOccupations.length > 1 &&
      filteredRoles.includes(EmployeesEmployeeOccupationChoices.mixte)) ||
    filteredRoles.some((role) => occupationName.includes(role)) ||
    (isOtherFiltered && isOther);

  return filteredRoles.length === 0 || checkRoleFilter;
};
