import { TableCell } from '@mui/material';
import clsx from 'clsx';
import collapseStyles from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/styles/CollapseStyles.module.scss';
import styles from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/styles/TableCell.module.scss';
import React from 'react';

interface RevenueCellOutOfRangeProperties {
  isCollapsable?: boolean;
  isValidated?: boolean;
}

export default function RevenueCellOutOfRange({
  isCollapsable = true,
  isValidated,
}: RevenueCellOutOfRangeProperties) {
  return (
    <TableCell
      className={clsx(styles.invalidCell, {
        [collapseStyles.collapseCell]: isCollapsable,
        [styles.invalidValidatedCellBackground]: isValidated,
        [styles.invalidCellBackground]: !isValidated,
      })}
    />
  );
}
