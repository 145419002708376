import { TableCell } from '@mui/material';
import clsx from 'clsx';
import TextFieldNumberFormat from 'components/commons/TextFieldFormat/TextFieldNumberFormat';
import PolyGridInputForm from 'components/MissionFollowUp/PolyGridInputForm';
import collapseStyles from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/styles/CollapseStyles.module.scss';
import styles from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/styles/TableCell.module.scss';
import { BillingPurchaseOrderCurrencyChoices } from 'generated/graphql';
import { totalFormat } from 'pages/ActivityPage/utils';
import React from 'react';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';

interface RevenueCellInput {
  suggestedValue?: number;
  validateRules: RegisterOptions;
  isCollapsable?: boolean;
  disabled?: boolean;
  customAdornment?: JSX.Element;
  currency?: BillingPurchaseOrderCurrencyChoices;
  triggerChange?: () => void;
  formName: string;
}

export function handleSuggestionOnEnter(event: React.KeyboardEvent) {
  if (event.key === 'Enter') {
    event.preventDefault();
    const element = event.target as HTMLElement;
    const form = element.closest('form');
    const left = element.getBoundingClientRect().left;
    const top = element.getBoundingClientRect().top;

    let index = Array.prototype.indexOf.call(form, element);
    if (form) {
      (form.elements[index] as HTMLElement).blur();
    }

    while (form?.elements && index < form.elements.length) {
      if (
        form.elements[index].nodeName === 'INPUT' &&
        form.elements[index].getBoundingClientRect().top !== top &&
        form.elements[index].getBoundingClientRect().left === left
      ) {
        (form.elements[index] as HTMLElement).focus();
        return;
      }
      index++;
    }
  }
}

export default function RevenueCellInput({
  suggestedValue,
  validateRules,
  isCollapsable,
  disabled,
  customAdornment,
  currency,
  triggerChange,
  formName,
}: RevenueCellInput) {
  return (
    <TableCell
      className={clsx(
        isCollapsable ? collapseStyles.collapseCell : styles.regularTableCell,
        {
          [styles.oldMonthCell]: disabled,
        }
      )}
    >
      <PolyGridInputForm
        hiddenLabel
        formName={formName}
        disabled={disabled}
        className={styles.inputRevenue}
        placeholder={
          suggestedValue ? totalFormat(suggestedValue, currency) : undefined
        }
        onKeyPress={handleSuggestionOnEnter}
        onChange={() => {
          if (triggerChange) {
            triggerChange();
          }
        }}
        validateRules={validateRules}
        InputProps={{
          endAdornment: customAdornment,
          inputComponent: TextFieldNumberFormat as never,
        }}
        inputProps={{
          suggestedValue: suggestedValue,
          currency: currency,
        }}
      />
    </TableCell>
  );
}

RevenueCellInput.defaultProps = {
  isCollapsable: false,
};
