import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, Grid } from '@mui/material';
import AddItem from 'components/commons/ItemInteractions/AddItem/AddItem';
import DeleteItem from 'components/commons/ItemInteractions/DeleteItem/DeleteItem';
import PolyAlert from 'components/commons/PolyAlert';
import PolyAlertTitle from 'components/commons/PolyAlertTitle';
import SectionTitle from 'components/commons/SectionTitle';
import LabelTextFieldGrid from 'components/MissionFollowUp/LabelTextFieldGrid';
import PolyModal from 'components/MUIOverload/PolyDialog/PolyModal';
import {
  BillingClientNode,
  SectorNode,
  useCorporateInfoFromSirenLazyQuery,
  useCreateOrUpdateBillingClientMutation,
} from 'generated/graphql';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { requiredForm } from 'pages/MissionFollowUp/formValidators';
import React, { ChangeEvent } from 'react';
import {
  Controller,
  FieldError,
  useFieldArray,
  useForm,
} from 'react-hook-form';

import BillingClientAddress, { ModalAddress } from './BillingClientAddress';

const translateErrorMessage = (errorMessage: string): string => {
  let message = '';
  errorMessage === "Can't delete an address if it's linked to a purchase order"
    ? (message =
        'Impossible de supprimer une adresse liée à un ou plusieurs BDCs')
    : (message = errorMessage);
  return message;
};

const MAX_NUMBER_OF_ADDRESSES_PER_CLIENT = 3;

const TOO_MANY_ADDRESSES_SNACKBAR_MESSAGE =
  'Nombre maximal d’adresses atteint, veuillez supprimer une adresse';

type ModalMode = 'create' | 'edit';

interface BillingClientModalProps {
  mode: ModalMode;
  billingClient?: BillingClientNode;
  open: boolean;
  close: () => void;
  onSuccess?: () => void;
  sectorList: SectorNode[];
}

export function BillingClientModal({
  close,
  open,
  onSuccess,
  billingClient,
  mode,
  sectorList,
}: BillingClientModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const defaultValues = {
    id: billingClient?.id || '',
    corporateName: billingClient?.corporateName || '',
    talentiaId: billingClient?.talentiaId || '',
    addresses: (billingClient?.addresses || []) as ModalAddress[],
    sector: billingClient?.sector || ({} as SectorNode),
    vatNumber: billingClient?.vatNumber || '',
    sirenNumber: billingClient?.sirenNumber || '',
  };

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const {
    errors,
    handleSubmit,
    register,
    formState,
    control,
    reset,
    watch,
    setValue,
    setError,
  } = useForm({
    defaultValues,
  });

  const {
    fields: addresses,
    append: appendAddress,
    remove,
    move,
  } = useFieldArray({
    control,
    name: 'addresses',
    keyName: 'key',
  });

  const [createOrUpdateBillingClient] = useCreateOrUpdateBillingClientMutation({
    onCompleted: (data) => {
      setIsSubmitting(false);
      const clientName =
        data.createOrUpdateBillingClient?.billingClient?.corporateName || '';
      enqueueSnackbar(
        mode === 'create'
          ? `Client « ${clientName} » créé`
          : `Client « ${clientName} » mis à jour`,
        {
          variant: 'success',
        }
      );
      if (mode === 'create' && onSuccess) {
        onSuccess();
      }
      close();
    },
    onError: (error) => {
      setIsSubmitting(false);
      const errorMessage = translateErrorMessage(error.message);
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        style: { maxWidth: '600px' },
      });
      reset();
    },
  });

  const onSubmit = async (submitValues: typeof defaultValues) => {
    setIsSubmitting(true);
    if (!submitValues.sector.id) {
      setError('sector', {
        type: 'required',
        message: 'Veuillez sélectionner un secteur associé',
      });
      setIsSubmitting(false);
      return false;
    }

    await createOrUpdateBillingClient({
      variables: {
        billingClientId: billingClient?.id,
        corporateName: submitValues.corporateName,
        talentiaId: submitValues.talentiaId,
        addresses: submitValues.addresses,
        sectorId: submitValues.sector.id,
        tvaNumber: submitValues.vatNumber,
        sirenNumber: submitValues.sirenNumber,
      },
    });
    return false;
  };

  const handleAddAddress = () => {
    if (addresses.length < MAX_NUMBER_OF_ADDRESSES_PER_CLIENT) {
      appendAddress({ automatic: false });
    } else {
      enqueueSnackbar(TOO_MANY_ADDRESSES_SNACKBAR_MESSAGE, {
        variant: 'warning',
      });
    }
  };
  function getSectorAutocompleteError() {
    return formState.errors.sector as FieldError | undefined;
  }

  const [getEntrepriseInfoFromSiren, loading] =
    useCorporateInfoFromSirenLazyQuery({
      onCompleted: (data) => {
        const { corporateName } = data?.corporateInfoFromSiren || {};

        setValue(`corporateName`, corporateName, {
          shouldValidate: true,
          shouldDirty: true,
        });
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
    });

  function handleVerifySiren(): void {
    const sirenNumber = watch('sirenNumber');
    getEntrepriseInfoFromSiren({
      variables: {
        siren: sirenNumber,
      },
    });
  }

  return (
    <PolyModal
      open={open}
      onClose={close}
      polyDialogTitle={
        mode === 'create'
          ? "Création d'une fiche client"
          : 'Édition de la fiche client'
      }
      handlePrimary={handleSubmit(onSubmit)}
      isPrimaryButtonDisabled={
        (!formState.isDirty && !!errors) || !addresses.length
      }
      childrenInForm
      loadingButton={isSubmitting}
    >
      <>
        {mode === 'edit' && (
          <PolyAlert severity="warning" variant="outlined">
            <PolyAlertTitle color="warning">Attention ! </PolyAlertTitle>
            Vos modifications pourraient impacter toute mission avec ce client.
          </PolyAlert>
        )}
        <Grid container spacing={0}>
          <Grid container sx={{ my: 3 }} spacing={2}>
            <Grid item xs={4}>
              <LabelTextFieldGrid
                inputRef={register({
                  required: requiredForm,
                })}
                required
                name={'talentiaId'}
                errorform={errors.talentiaId}
                title="N° Talentia"
                defaultValue={defaultValues.talentiaId}
                sizegrid={12}
                customspacingside={0}
                customspacingtop={0}
              />
            </Grid>
            <Grid item xs={8} sx={{ justifyContent: 'space-between' }}>
              <Box display={'flex'} sx={{ alignItems: 'center' }}>
                <LabelTextFieldGrid
                  inputRef={register()}
                  name={'sirenNumber'}
                  title={'N° SIREN'}
                  defaultValue={defaultValues.sirenNumber}
                  label={'N° SIREN'}
                  sizegrid={9}
                  sx={{ alignItems: 'center' }}
                  customspacingside={0}
                  customspacingtop={0}
                  data-testid="siren-input"
                />

                <LoadingButton
                  variant="contained"
                  size="small"
                  onClick={handleVerifySiren}
                  sx={{ ml: 2 }}
                  loading={loading.loading}
                >
                  Vérifier
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
          <Grid container sx={{ mb: 2 }} spacing={2}>
            <Grid item xs={4}>
              <LabelTextFieldGrid
                inputRef={register()}
                name={'vatNumber'}
                title={'N° de TVA'}
                defaultValue={defaultValues.vatNumber}
                label={'N° de TVA'}
                sizegrid={12}
                sx={{ alignItems: 'center' }}
                customspacingside={0}
                customspacingtop={0}
                data-testid="vat-input"
              />
            </Grid>
            <Grid item xs={8}>
              <LabelTextFieldGrid
                inputRef={register({
                  required: requiredForm,
                })}
                required
                name={'corporateName'}
                errorform={errors.corporateName}
                title="Raison sociale"
                defaultValue={defaultValues.corporateName}
                sizegrid={12}
                customspacingside={0}
                customspacingtop={0}
                shrink={formState.dirtyFields.corporateName ?? false}
              />
            </Grid>
          </Grid>
          <SectionTitle variant="h3Bold" sx={{ mb: 2 }}>
            Secteur associé
          </SectionTitle>
          <Grid
            item
            xs={12}
            sx={{
              border: 1,
              borderRadius: 1,
              paddingTop: 0,
              paddingBottom: 3,
              borderColor: 'rgba(0, 0, 0, 0.12)',
            }}
          >
            <Controller
              name={'sector'}
              control={control}
              rules={{
                required: requiredForm,
              }}
              defaultValue={billingClient?.sector}
              render={({ onChange, value }) => (
                <Autocomplete
                  autoSelect={true}
                  options={sectorList}
                  getOptionLabel={({ name }) => name ?? ''}
                  isOptionEqualToValue={(
                    option: SectorNode,
                    value: SectorNode
                  ) => {
                    return option.id === value?.id;
                  }}
                  onChange={(
                    _event: ChangeEvent<{}>,
                    newValue: SectorNode | null
                  ) => {
                    onChange(newValue);
                  }}
                  value={value?.id ? value : undefined}
                  id={'sector-autocomplete-' + value?.id}
                  renderInput={(parameters) => (
                    <LabelTextFieldGrid
                      {...parameters}
                      required
                      errorform={getSectorAutocompleteError()}
                      error={!!getSectorAutocompleteError()}
                      title="Secteur associé"
                      sizegrid={12}
                    />
                  )}
                />
              )}
            />
          </Grid>
          {_.map(addresses, (item, index) => {
            return (
              <React.Fragment key={item.key}>
                {index === 0 && (
                  <SectionTitle variant="h3Bold" sx={{ my: 2 }}>
                    Adresse principale
                  </SectionTitle>
                )}
                {index === 1 && (
                  <SectionTitle variant="h3Bold" sx={{ my: 2 }}>
                    Adresse(s) secondaire(s)
                  </SectionTitle>
                )}
                <Grid
                  container
                  item
                  xs={12}
                  sx={{
                    border: 1,
                    borderRadius: 1,
                    py: 1,
                    borderColor: 'rgba(0, 0, 0, 0.12)',
                    ...(index >= 2 && { mt: 3 }),
                  }}
                >
                  <DeleteItem onClick={() => remove(index)} sx={{ mr: 1 }} />
                  <BillingClientAddress
                    register={register}
                    watch={watch}
                    index={index}
                    item={item as ModalAddress}
                    setValue={setValue}
                    move={move}
                    enqueueSnackbar={enqueueSnackbar}
                    errors={errors}
                    dirtyFields={formState.dirtyFields}
                  />
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
        <AddItem
          onClick={handleAddAddress}
          description={'ajouter une adresse'}
          sx={{ mt: 1 }}
        />
      </>
    </PolyModal>
  );
}
