import { Delete, Download, Visibility } from '@mui/icons-material';
import { ButtonGroup } from '@mui/material';
import DeleteModal from 'components/MissionFollowUp/Modals/DeleteModal';
import PolyTooltip from 'components/MUIOverload/PolyTooltip';
import PongoButton from 'components/MUIOverload/PongoButton';
import {
  getFileExtension,
  TYPES_FILE_DISPLAYABLE,
  useFileRequest,
} from 'hooks/useFileRequest';
import React, { useState } from 'react';
import { S3_URL } from 'services/s3';
import urljoin from 'url-join';
import { getFileNameFromPath } from 'utils';

interface DownloadFileProps {
  filename: string;
  title: string;
  isSingleButton?: boolean;
  onDelete?: () => Promise<void>;
}

function openInNewTab(url: string) {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) {
    newWindow.opener = null;
  }
}

export default function DownloadFile(properties: DownloadFileProps) {
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
  const fetchFile = useFileRequest(properties.filename);
  const DOWNLOAD_URL = properties.filename
    ? urljoin(S3_URL, properties.filename)
    : '';
  const isDisplayable = TYPES_FILE_DISPLAYABLE.includes(
    getFileExtension(DOWNLOAD_URL)?.toLowerCase() || ''
  );

  async function createHtmlAnchor(download: boolean) {
    const { response: blobUrl } = await fetchFile();
    if (!blobUrl) {
      return;
    }
    const HtmlAnchor = document.createElement('a');
    HtmlAnchor.style.display = 'none';
    HtmlAnchor.href = blobUrl;
    HtmlAnchor.setAttribute('download', properties.filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof HtmlAnchor.download === 'undefined') {
      HtmlAnchor.setAttribute('target', '_blank');
    }
    if (download) {
      document.body.append(HtmlAnchor);
      HtmlAnchor.click();

      setTimeout(function () {
        HtmlAnchor.remove();
        window.URL.revokeObjectURL(blobUrl);
      }, 200);
    }
    return HtmlAnchor;
  }

  async function openObject() {
    openInNewTab(
      await createHtmlAnchor(false).then((result) => result?.href || '')
    );
  }

  async function downloadFile() {
    await createHtmlAnchor(true);
  }

  return properties.isSingleButton ? (
    <PongoButton
      variant="contained"
      buttonStyle="secondary"
      startIcon={<Visibility />}
      onClick={openObject}
      uppercase={false}
    >
      {properties.title}
    </PongoButton>
  ) : (
    <ButtonGroup size={'small'}>
      {isDisplayable && (
        <PolyTooltip title={'Voir le document'}>
          <PongoButton
            variant="contained"
            buttonStyle="secondary"
            onClick={openObject}
          >
            <Visibility />
          </PongoButton>
        </PolyTooltip>
      )}
      {DOWNLOAD_URL !== '' && (
        <PolyTooltip title={'Télécharger le document'}>
          <PongoButton
            variant="contained"
            buttonStyle="secondary"
            onClick={downloadFile}
          >
            <Download />
          </PongoButton>
        </PolyTooltip>
      )}
      {properties.onDelete && (
        <DeleteModal
          open={deleteModalVisibility}
          onDelete={async () => {
            setDeleteModalVisibility(false);
            if (properties.onDelete) {
              await properties.onDelete();
            }
          }}
          closeModal={() => setDeleteModalVisibility(false)}
          title={`Supprimer le document "${getFileNameFromPath(
            properties.filename
          )}"`}
          content={
            'Êtes-vous sûr de vouloir supprimer définitivement ce document?'
          }
        />
      )}
      <PolyTooltip title={'Supprimer le document'}>
        <PongoButton
          variant="contained"
          buttonStyle="secondary"
          onClick={() => setDeleteModalVisibility(true)}
          disabled={!properties.onDelete}
        >
          <Delete />
        </PongoButton>
      </PolyTooltip>
    </ButtonGroup>
  );
}
