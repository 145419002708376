import { Circle, WarningRounded } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import {
  ActivitiesIndicatorClientSatisfactionChoices as ClientSatisfactionChoices,
  ActivitiesIndicatorEfficiencyChoices as EfficiencyChoices,
} from 'generated/graphql';
import React from 'react';

type Indicator = EfficiencyChoices | ClientSatisfactionChoices;

export const indicatorToColorMap: Record<
  Indicator,
  { color: 'error' | 'warning' | 'success'; text: string }
> = {
  [EfficiencyChoices.Critical]: {
    color: 'error',
    text: 'Critique',
  },
  [EfficiencyChoices.Decent]: {
    color: 'warning',
    text: 'Correct',
  },
  [EfficiencyChoices.Excellent]: {
    color: 'success',
    text: 'Excellent',
  },
};

export default function ActivityIndicator({
  indicator,
}: {
  indicator?: Indicator;
}) {
  if (!indicator) {
    return (
      <Stack alignItems="center" direction="row" mt={0.5} gap={0.5}>
        <WarningRounded color="error" />
        <Typography variant="bodySBold" color="error">
          À remplir
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack alignItems="center" direction="row" mt={0.5} gap={0.5}>
      <Circle
        sx={{ width: 12, height: 12 }}
        color={indicatorToColorMap[indicator].color}
      />
      <Typography variant="bodyBold">
        {indicatorToColorMap[indicator].text}
      </Typography>
    </Stack>
  );
}
