import { Box, Stack, Typography } from '@mui/material';
import FieldDisplay from 'components/commons/FieldDisplay/FieldDisplay';
import ModalityEmailView from 'components/MissionFollowUp/BillDetail/BillDetail/BillView/MissionSummaryView/ModalitiesView/ModalityEmailView';
import ModalityPostalView from 'components/MissionFollowUp/BillDetail/BillDetail/BillView/MissionSummaryView/ModalitiesView/ModalityPostalView';
import ModalityUploadView from 'components/MissionFollowUp/BillDetail/BillDetail/BillView/MissionSummaryView/ModalitiesView/ModalityUploadView';
import { getDisplayedType } from 'components/MissionFollowUp/BillDetail/MissionInitialization/ModalitiesMissionForm/ModalityCard';
import {
  DisplayAddress,
  parsePurchaseOrderAddress,
} from 'components/MissionFollowUp/DisplayAddress';
import {
  BillingAddressNode,
  BillingBillingModalitiesBillingTypeChoices,
  useFetchHistorizedBillInfosQuery,
} from 'generated/graphql';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'store';

export default function ModalitiesView() {
  const currentMission = useSelector((state) => state.activity.currentMission);

  const { enqueueSnackbar } = useSnackbar();

  const { search } = useLocation();
  const billId = new URLSearchParams(search).get('bill_id');

  const { data } = useFetchHistorizedBillInfosQuery({
    variables: { billId: billId ?? '' },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });
  const billingAddressHistory =
    data?.historizedBillInfos?.billingAddressHistory;
  const purchaseOrderAddress = parsePurchaseOrderAddress(
    billingAddressHistory as BillingAddressNode
  );

  let modalities = <></>;
  if (
    currentMission.billingInformation?.billingModalities &&
    currentMission.billingInformation?.billingModalities?.length > 0
  ) {
    const principalModality =
      currentMission.billingInformation?.billingModalities[0];
    modalities = (
      <Stack key={principalModality.id} direction={'column'} spacing={3}>
        <Stack direction={'column'}>
          <Typography
            color={'textSecondary'}
            variant={'bodyBold'}
            sx={{ whiteSpace: 'nowrap' }}
          >{`Mode d'envoi`}</Typography>
          <Typography variant={'bodySemiBold'}>
            {getDisplayedType(principalModality.billingType || '')}
          </Typography>
        </Stack>
        {principalModality.billingType ===
          BillingBillingModalitiesBillingTypeChoices.Email && (
          <ModalityEmailView modality={principalModality} />
        )}
        {principalModality.billingType ===
          BillingBillingModalitiesBillingTypeChoices.Postal && (
          <ModalityPostalView modality={principalModality} />
        )}
        {principalModality.billingType ===
          BillingBillingModalitiesBillingTypeChoices.Upload && (
          <ModalityUploadView modality={principalModality} />
        )}
      </Stack>
    );
  }
  return (
    <Stack direction={'column'} spacing={3} sx={{ my: 2 }}>
      {modalities}
      <Box>
        <FieldDisplay
          title="Adresse de facturation"
          flexDirection="column"
          noWrap={false}
          showColon={false}
          titleVariant="bodyBold"
          valueVariant="bodySemiBold"
        />
        <DisplayAddress
          addressInformations={purchaseOrderAddress}
          displayVertical
          typographyVariant="bodySemiBold"
        />
      </Box>
    </Stack>
  );
}
