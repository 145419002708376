import PageWrapper from 'components/commons/PageWrapper';
import PolyTitle from 'components/commons/PolyTitle';
import { useUpdateOvertimeAuthorizationMutation } from 'generated/graphql';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { OvertimeAuthorizationDataToSend } from '../types';
import AuthorizationContent from './AuthorizationContent';

interface UpdateExceptionalActivitiesAuthorizationProps {
  location: History;
}
const title = ' Modification d’autorisation d’activité exceptionnelle';

const UpdateExceptionalActivitiesAuthorization = (
  properties: UpdateExceptionalActivitiesAuthorizationProps
) => {
  const history = useHistory();
  const onClickCancel = () => {
    history.replace(
      '/mission-followup/activity/exceptional-activities/authorization_granted'
    );
  };
  const { enqueueSnackbar } = useSnackbar();
  const [updateOvertimeAuthorization] =
    useUpdateOvertimeAuthorizationMutation();
  const onClickValidate = async (data: OvertimeAuthorizationDataToSend) => {
    try {
      await updateOvertimeAuthorization({
        variables: {
          objectId: data.objectId,
          managerComment: data.managerComment,
          periodEnd: data.periodEnd,
          periodStart: data.periodStart,
        },
      });
      onClickCancel();
    } catch {
      enqueueSnackbar("Impossible de modifier l'autorisation", {
        variant: 'error',
      });
    }
  };
  return (
    <>
      <PageWrapper noLimit>
        <PolyTitle>{title}</PolyTitle>
        <AuthorizationContent
          editable={true}
          handleCancel={onClickCancel}
          handleValidate={onClickValidate}
          authorizationData={properties.location.state.authorizationData}
        />
      </PageWrapper>
    </>
  );
};

export default UpdateExceptionalActivitiesAuthorization;
