import { Box } from '@mui/material';
import { useUserInfo } from 'components/User/UserProvider';
import { useTableContext } from 'contexts/ActivityMonitoringTable/TableContextProvider';
import { EmployeesEmployeeGradeChoices } from 'generated/graphql';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'store';

import ClientFilter from './ClientFilter';
import CollaboratorFilter from './CollaboratorFilter';

export default function MissionFilter() {
  const { setFilterMissionsId, allCollaborators } = useTableContext();
  const { employee, isControl, isSuperuser } = useUserInfo();
  const isComex = employee?.grade === EmployeesEmployeeGradeChoices.Comex;
  const selectedMissions = useSelector(
    (state) => state.activity.selectedMissions
  );
  const currentMission = useSelector((state) => state.activity.currentMission);
  const missionIds = currentMission?.id
    ? [currentMission.id]
    : _.map(selectedMissions, (mission) => mission.id);

  useEffect(() => {
    if (missionIds) {
      setFilterMissionsId(missionIds);
    } else {
      setFilterMissionsId([]);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(missionIds), setFilterMissionsId]);

  return (
    <Box sx={{ display: 'flex' }}>
      {allCollaborators?.length > 0 && (
        <>
          <CollaboratorFilter />
          {(isComex || isControl || isSuperuser) && <ClientFilter />}
        </>
      )}
    </Box>
  );
}
