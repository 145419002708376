import { Typography } from '@mui/material';
import React from 'react';

export default function SkillLevelsInfoTooltipTitle() {
  return (
    <Typography variant="caption" color={'white'}>
      1 &#x2014; Junior &emsp; 2 &#x2014; Confirmé &emsp; 3 &#x2014; Senior
    </Typography>
  );
}
