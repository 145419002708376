import { Box, DialogContent, Typography } from '@mui/material';
import PolyDialog from 'components/MUIOverload/PolyDialog';
import PolyFooter from 'components/MUIOverload/PolyFooter';
import PongoButton from 'components/MUIOverload/PongoButton';
import React from 'react';

interface DeleteModalProps {
  open: boolean;
  onDelete: () => Promise<void>;
  closeModal: () => void;
  title: string;
  content: string;
}

const DeleteModal = ({
  open,
  onDelete,
  closeModal,
  title,
  content,
}: DeleteModalProps) => {
  return (
    <PolyDialog
      maxWidth={'md'}
      open={open}
      onClose={closeModal}
      disableEnforceFocus={true}
    >
      <DialogContent>
        <Typography
          sx={{ mb: 1, color: 'primary.main' }}
          variant="h3Bold"
          component="h3"
        >
          {title}
        </Typography>
        <Box sx={{ mt: 4, display: 'flex' }}>{content}</Box>
        <PolyFooter>
          <PongoButton variant={'text'} onClick={closeModal}>
            Annuler
          </PongoButton>
          <PongoButton onClick={onDelete} variant={'contained'}>
            Supprimer
          </PongoButton>
        </PolyFooter>
      </DialogContent>
    </PolyDialog>
  );
};

export default DeleteModal;
