import { Grid, TextField } from '@mui/material';
import LabelTextFieldGrid from 'components/MissionFollowUp/LabelTextFieldGrid';
import _ from 'lodash';
import { requiredForm } from 'pages/MissionFollowUp/formValidators';
import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'store';

interface BillInformationProps {
  isCreating: boolean;
  isEditing: boolean;
  isViewing: boolean;
}

export default function BillInformation({
  isCreating,
  isEditing,
  isViewing,
}: BillInformationProps): ReactElement {
  const currentBill = useSelector((state) => state.purchaseOrder.currentBill);
  const currentPurchaseOrder = useSelector(
    (state) => state.purchaseOrder.currentPurchaseOrder
  );
  const currentPurchaseOrderOtherBills = currentPurchaseOrder.bills?.filter(
    (bill) => isCreating || bill.id !== currentBill.id
  );

  const { errors, register } = useFormContext();

  return (
    <>
      <TextField
        name={'title'}
        disabled={!isEditing && isViewing}
        inputRef={register({
          required: requiredForm,
          validate: {
            duplicateTitle: (billTitle) =>
              !_.find(currentPurchaseOrderOtherBills, {
                title: billTitle,
              })
                ? true
                : 'Une facture avec le même libellé existe déjà',
          },
        })}
        sx={(theme) => ({
          '& .MuiOutlinedInput-input': {
            ...theme.typography.h1Bold,
            lineHeight: '1',
          },
        })}
        multiline
        error={!!errors.title}
        fullWidth
        required
        variant={'outlined'}
        placeholder="Nom de la facture"
        label={'Nom de la facture'}
      />
      {currentBill.billNumber && (
        <Grid container item xs={12}>
          <LabelTextFieldGrid
            sizegrid={6}
            title={'Numéro de facture'}
            disabled
            defaultValue={currentBill.billNumber}
          />
        </Grid>
      )}
    </>
  );
}
