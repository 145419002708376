import React, { useCallback } from 'react';
import { refreshToken } from 'services/login';

import { Credentials } from '../utils';

const CREDENTIALS_LOCAL_STORAGE_KEY = 'credential';
const HASH_ACCESS = '#access=';

let refreshPromise: Promise<Credentials | undefined> | null = null;

/**
 * This function is used to get new credentials when the current ones are expired
 * @param logout - The function to call to log out the user
 * @returns A promise that resolves to the new credentials
 */
export async function getNewCredentials(logout: (message: string) => void) {
  const credential = loadCredential();
  if (!credential || !credential.refresh_token) {
    return;
  }

  if (!refreshPromise) {
    refreshPromise = refreshToken({
      refresh: credential.refresh_token,
      grant_type: 'refresh_token',
    })
      .then((response) => {
        if (response.error) {
          clearCredentials();
          refreshPromise = null;
          logout('La session a expiré');
          return;
        } else if (
          response.response &&
          'access' in response.response &&
          'refresh' in response.response &&
          typeof response.response.access === 'string' &&
          typeof response.response.refresh === 'string'
        ) {
          const newCredentials: Credentials = {
            access_token: response.response.access,
            refresh_token: response.response.refresh,
          };
          saveCredentials(newCredentials);
          refreshPromise = null;
          return newCredentials;
        }
      })
      .catch((error) => {
        clearCredentials();
        refreshPromise = null;
        logout('La session a expiré');
        throw error;
      });
  }

  return refreshPromise;
}

function saveCredentials(credentials: Credentials) {
  localStorage.setItem(
    CREDENTIALS_LOCAL_STORAGE_KEY,
    JSON.stringify(credentials)
  );
}

function clearCredentials() {
  localStorage.removeItem(CREDENTIALS_LOCAL_STORAGE_KEY);
}

function loadCredential(): Credentials | undefined {
  if (window.location.hash && window.location.hash.startsWith(HASH_ACCESS)) {
    const access = JSON.parse(
      decodeURIComponent(window.location.hash.slice(HASH_ACCESS.length))
    );
    saveCredentials(access);
    window.location.hash = '';
    return access;
  }

  const credentialStored = localStorage.getItem(CREDENTIALS_LOCAL_STORAGE_KEY);
  if (!credentialStored) {
    return;
  }
  const credentialParsed = JSON.parse(credentialStored);
  if (!credentialParsed.access_token || !credentialParsed.refresh_token) {
    clearCredentials();
    return;
  }
  return credentialParsed;
}

export function useCredential(): [
  React.MutableRefObject<Credentials | undefined>,
  (v: Credentials | undefined) => void,
  () => void
] {
  const credentials = React.useRef(loadCredential());

  const clearCredential = useCallback(() => {
    clearCredentials();
  }, []);

  const handleCredentials = useCallback((newCredential?: Credentials) => {
    credentials.current = newCredential;
    if (!newCredential) {
      clearCredentials();
    } else {
      saveCredentials(newCredential);
    }
  }, []);

  return [credentials, handleCredentials, clearCredential];
}
