import AttachFileIcon from '@mui/icons-material/AttachFile';
import BlockIcon from '@mui/icons-material/Block';
import CancelIcon from '@mui/icons-material/Cancel';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import HistoryIcon from '@mui/icons-material/History';
import MessageIcon from '@mui/icons-material/Message';
import { useTheme } from '@mui/material';
import ActionsMenu from 'components/commons/ActionsMenu/ActionsMenu';
import { createCsvFile } from 'components/commons/PolyExportButton';
import AskAnnulationModal from 'components/MissionFollowUp/BillDetail/Modals/AskAnnulationModal/AskAnnulationModal';
import BillActionModal from 'components/MissionFollowUp/BillDetail/Modals/BillActionModal/BillActionModal';
import BillHistoryModal from 'components/MissionFollowUp/BillDetail/Modals/BillHistoryModal/BillHistoryModal';
import CancelBillModal from 'components/MissionFollowUp/BillDetail/Modals/CancelBillModal/CancelBillModal';
import CreateOrUpdateBillModal from 'components/MissionFollowUp/BillDetail/Modals/CreateOrUpdateBillModal';
import EditBillToBillModal from 'components/MissionFollowUp/BillDetail/Modals/EditBillToBillModal/EditBillToBillModal';
import ModalBillCommentaries from 'components/MissionFollowUp/BillDetail/Modals/ModalBillCommentaries/ModalBillCommentaries';
import DownloadBillFilesModal from 'components/MissionFollowUp/DownloadFilesModal/DownloadBillFilesModal';
import PongoButton from 'components/MUIOverload/PongoButton';
import { useUserInfo } from 'components/User/UserProvider';
import {
  ActivitiesActivityBillingTypeChoices,
  BillingBillStatusChoices,
  BillNode,
  useDeleteBillMutation,
  useExportEmployeesFromBillLazyQuery,
} from 'generated/graphql';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'store';
import { removeBill, setCurrentBill } from 'store/purchaseOrder';
import { isChiefOrDirectorOfActivity } from 'utils';

import ControlCancelModal from './Modals/ControlDeleteModal/ControlDeleteModal';

export default function BillOptions() {
  const { palette } = useTheme();
  const currentMission = useSelector((state) => state.activity.currentMission);
  const currentBill = useSelector((state) => state.purchaseOrder.currentBill);
  const history = useHistory();
  const { search } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { employee, isControl, isSuperuser } = useUserInfo();
  const missionId = useSelector((state) => state.activity.currentMission.id);
  const poId = new URLSearchParams(search).get('po_id');
  const dispatch = useDispatch();
  const [modalDeleteVisibility, setModalDeleteVisibility] = useState(false);
  const [modalCancelVisibility, setModalCancelVisibility] = useState(false);
  const [modalAskCancelVisibility, setModalAskCancelVisibility] =
    useState(false);
  const [modalDeleteControlVisibility, setModalDeleteControlVisibility] =
    useState(false);
  const [modalDownloadVisibility, setModalDownloadVisibility] = useState(false);
  const [modalHistoryVisibility, setModalHistoryVisibility] = useState(false);
  const [modalCommentariesVisibility, setModalCommentariesVisibility] =
    useState(false);
  const [modalDuplicateVisibility, setModalDuplicateVisibility] =
    useState(false);
  const [modalEditVisibility, setModalEditVisibility] = useState(false);
  const [modalWarningEditVisibility, setModalWarningEditVisibility] =
    useState(false);

  const isCredit = !!currentBill.billReference;
  const isDraft = currentBill.status === BillingBillStatusChoices.Draft;
  const isToBill = currentBill.status === BillingBillStatusChoices.ToBill;
  const isBilled = currentBill.status === BillingBillStatusChoices.Billed;
  const isSent = currentBill.status === BillingBillStatusChoices.Sent;
  const isTmContracts =
    currentBill?.purchaseOrder?.billingActivityInformation?.activity
      ?.billingType === ActivitiesActivityBillingTypeChoices.Tm;
  const isActivityChiefOrDirector = isChiefOrDirectorOfActivity(
    currentMission,
    employee
  );
  const isEditable =
    ((isControl || isSuperuser) && isToBill) ||
    (!isCredit && isDraft && (isActivityChiefOrDirector || isSuperuser));

  const [exportEmployeesFromBillQuery] = useExportEmployeesFromBillLazyQuery({
    onCompleted: (data) => {
      if (data && data.exportEmployeesFromBill) {
        createCsvFile(
          data.exportEmployeesFromBill,
          'collaborateurs_factures_polyconseil.csv'
        );
      }
    },
    onError: () => {
      enqueueSnackbar(`Erreur lors du téléchargement des données`, {
        variant: 'error',
      });
    },
  });

  const onClickExportEmployees = () => {
    exportEmployeesFromBillQuery({
      variables: {
        billId: currentBill.id,
      },
    });
  };

  const options = [
    {
      title: 'Exporter les collaborateurs',
      icon: <GetAppOutlinedIcon fontSize={'small'} />,
      onClick: () => onClickExportEmployees(),
      display: isControl || isActivityChiefOrDirector || isSuperuser,
    },
    {
      title: 'Pièces jointes',
      icon: <AttachFileIcon fontSize={'small'} />,
      onClick: () => setModalDownloadVisibility(true),
      display:
        isControl || isActivityChiefOrDirector || isTmContracts || isSuperuser,
    },
    {
      title: 'Commentaires',
      icon: <MessageIcon fontSize={'small'} />,
      onClick: () => setModalCommentariesVisibility(true),
      display: isControl || isActivityChiefOrDirector || isSuperuser,
    },
    {
      title: 'Historique',
      icon: <HistoryIcon fontSize={'small'} />,
      onClick: () => setModalHistoryVisibility(true),
      display: isControl || isActivityChiefOrDirector || isSuperuser,
    },
    {
      title: 'Dupliquer',
      icon: <ContentCopyIcon fontSize={'small'} />,
      onClick: () => setModalDuplicateVisibility(true),
      display: (isActivityChiefOrDirector || isSuperuser) && !isCredit,
    },
    {
      title: 'Editer la facture',
      icon: <CreateIcon fontSize={'small'} />,
      onClick: () => setModalEditVisibility(true),
      display: isEditable,
    },
    {
      title: 'Supprimer la facture',
      icon: <DeleteIcon fontSize={'small'} />,
      onClick: () => setModalDeleteVisibility(true),
      display:
        (isActivityChiefOrDirector || isSuperuser) && isDraft && !isCredit,
      style: { color: palette.error.main },
    },
    {
      title: "Demander l'annulation",
      icon: <BlockIcon fontSize={'small'} />,
      onClick: () => setModalAskCancelVisibility(true),
      display:
        (isActivityChiefOrDirector || isSuperuser) &&
        (isToBill || isBilled || isSent) &&
        !isCredit,
      style: { color: palette.error.main },
    },
    {
      title: 'Annuler la facture',
      icon: <CancelIcon fontSize={'small'} />,
      onClick: () => setModalCancelVisibility(true),
      display: (isControl || isSuperuser) && (isBilled || isSent) && !isCredit,
      style: { color: palette.error.main },
    },
    {
      title: 'Supprimer la facture',
      icon: <CancelIcon fontSize={'small'} />,
      onClick: () => setModalDeleteControlVisibility(true),
      display: (isControl || isSuperuser) && isToBill && !isCredit,
      style: { color: palette.error.main },
    },
  ];

  const [deleteBill] = useDeleteBillMutation({
    onCompleted: () => {
      enqueueSnackbar(`Facture « ${currentBill.title} » supprimée`, {
        variant: 'success',
      });
      dispatch(removeBill(currentBill.id));
      dispatch(setCurrentBill({} as BillNode));
      history.push({
        pathname: '/mission-followup/missions/details/purchase_order',
        search: `?mission_id=${missionId}&po_id=${poId}`,
      });
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const onDelete = async () => {
    await deleteBill({
      variables: {
        bill_id: currentBill.id,
      },
    });
  };

  return (
    <>
      <ActionsMenu options={options} />
      <BillHistoryModal
        open={modalHistoryVisibility}
        closeModal={() => setModalHistoryVisibility(false)}
      />
      <CreateOrUpdateBillModal
        isCloningBill
        open={modalDuplicateVisibility}
        onClose={() => setModalDuplicateVisibility(false)}
      />
      <CreateOrUpdateBillModal
        isEditingBill
        open={modalEditVisibility}
        onClose={() => setModalEditVisibility(false)}
      />
      <BillActionModal
        open={modalDeleteVisibility}
        title={'Supprimer la facture'}
        closeModal={() => setModalDeleteVisibility(false)}
        bodyModal={
          <p>
            Êtes-vous sûr(e) de vouloir supprimer définitivement cette facture?
            <br />
            Vous ne pourrez plus récupérer les données de cette dernière.
          </p>
        }
        customFooter={
          <PongoButton
            variant={'contained'}
            startIcon={<DeleteIcon />}
            onClick={onDelete}
          >
            Supprimer définitivement
          </PongoButton>
        }
      />
      <CancelBillModal
        open={modalCancelVisibility}
        closeModal={() => setModalCancelVisibility(false)}
      />
      <ControlCancelModal
        open={modalDeleteControlVisibility}
        closeModal={() => setModalDeleteControlVisibility(false)}
      />
      <DownloadBillFilesModal
        open={modalDownloadVisibility}
        onClose={() => setModalDownloadVisibility(!modalDownloadVisibility)}
      />
      <AskAnnulationModal
        open={modalAskCancelVisibility}
        closeModal={() =>
          setModalAskCancelVisibility(!modalAskCancelVisibility)
        }
      />
      <ModalBillCommentaries
        open={modalCommentariesVisibility}
        onClose={() =>
          setModalCommentariesVisibility(!modalCommentariesVisibility)
        }
      />
      <EditBillToBillModal
        open={modalWarningEditVisibility}
        onClose={() => setModalWarningEditVisibility(!modalEditVisibility)}
        onValidate={() => setModalEditVisibility(true)}
      />
    </>
  );
}
