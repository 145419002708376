import {
  Autocomplete,
  Card,
  CardContent,
  Fade,
  Grid,
  InputAdornment,
  Stack,
} from '@mui/material';
import DeleteItem from 'components/commons/ItemInteractions/DeleteItem/DeleteItem';
import PolySubTitle from 'components/commons/PolySubTitle';
import LabelTextFieldGrid from 'components/MissionFollowUp/LabelTextFieldGrid';
import {
  ActivitiesActivityBillingTypeChoices,
  AverageDailyRateCollaboratorNode,
  BillingPurchaseOrderCurrencyChoices,
} from 'generated/graphql';
import _ from 'lodash';
import { comaStringToFloat, dotToComa } from 'pages/ActivityPage/utils';
import { requiredForm } from 'pages/MissionFollowUp/formValidators';
import { CurrenciesSymbols } from 'poly-constants';
import React, { ChangeEvent } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { useSelector } from 'store';
import { EmployeeType } from 'store/users';
import { displayEmployee } from 'utils';

interface POCollaboratorCardProps {
  fieldName: string;
  index: number;
  itemCollaborator: AverageDailyRateCollaboratorNode;
  deleteCollaborator: () => void;
}

const PoFormCollaboratorCard = (properties: POCollaboratorCardProps) => {
  const currentMission = useSelector((state) => state.activity.currentMission);
  const employees = useSelector(
    (state) => state.users.currentActivityEmployees
  );
  const isTmContracts =
    currentMission.billingType === ActivitiesActivityBillingTypeChoices.Tm;

  const form = useFormContext();
  const { errors, register, watch } = form;
  const { fieldName, itemCollaborator, deleteCollaborator } = properties;
  const currentCurrency = watch(
    'currency'
  ) as BillingPurchaseOrderCurrencyChoices;

  return (
    <Fade in={true} key={itemCollaborator.id}>
      <Grid item xs={6}>
        <fieldset name={fieldName} key={fieldName}>
          <Card sx={{ margin: 1, position: 'relative' }} variant="outlined">
            <DeleteItem onClick={() => deleteCollaborator()} />
            <Stack sx={{ pt: 1, alignItems: 'center' }}>
              <PolySubTitle>
                Collaborateur associé au bon de commande
              </PolySubTitle>
            </Stack>
            <CardContent>
              <LabelTextFieldGrid
                defaultValue={itemCollaborator.title || ''}
                name={`${fieldName}.title`}
                errorform={_.get(errors, `${fieldName}.title`) as FieldError}
                sizegrid={12}
                required
                title="Libellé"
                customspacingtop={0}
                inputRef={register({
                  required: requiredForm,
                })}
              />
              <Grid item xs={12}>
                <Controller
                  name={`${fieldName}.collaborator`}
                  control={form.control}
                  rules={{
                    required: requiredForm,
                  }}
                  defaultValue={itemCollaborator.collaborator || null}
                  render={({ onChange, value }) => (
                    <Autocomplete
                      autoComplete
                      autoHighlight
                      id={`${fieldName}-select-collaborator`}
                      onChange={(
                        event: ChangeEvent<{}>,
                        newValue: EmployeeType | null
                      ) => {
                        onChange(newValue);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option ? value.id === option.id : true
                      }
                      value={value}
                      options={employees}
                      getOptionLabel={(option) =>
                        displayEmployee(option.firstName, option.lastName)
                      }
                      renderInput={(parameters) => (
                        <LabelTextFieldGrid
                          customspacingtop={15}
                          required
                          errorform={
                            _.get(
                              errors,
                              `${fieldName}.collaborator`
                            ) as FieldError
                          }
                          title="Nom du collaborateur"
                          variant={'outlined'}
                          sizegrid={12}
                          {...parameters}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <LabelTextFieldGrid
                customspacingtop={15}
                inputRef={register({
                  validate: {
                    positive: (value) =>
                      (!Number.isNaN(comaStringToFloat(value)) &&
                        comaStringToFloat(value) > 0) ||
                      'Le TJM doit être supérieur à 0',
                  },
                  required: isTmContracts && requiredForm,
                })}
                name={`${fieldName}.dailyRate`}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position={'start'}>
                      {CurrenciesSymbols[currentCurrency]}
                    </InputAdornment>
                  ),
                }}
                errorform={
                  _.get(errors, `${fieldName}.dailyRate`) as FieldError
                }
                defaultValue={dotToComa(itemCollaborator.dailyRate || '')}
                sizegrid={6}
                required
                title="TJM (HT)"
              />
            </CardContent>
          </Card>
        </fieldset>
      </Grid>
    </Fade>
  );
};

export default PoFormCollaboratorCard;
