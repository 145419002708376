import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Typography,
} from '@mui/material';
import { computeWorkedDays } from 'components/ActivityPage/utils';
import { ColumnLabelsV2 } from 'components/commons/Tables/utils';
import _, { get } from 'lodash';
import { Activity } from 'pages/ActivityPage/PrincipalTablePage';
import { dotToComa } from 'pages/ActivityPage/utils';
import { ActivitySectionEnum, SharedActivityNameEnum } from 'poly-constants';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';

import ActivityWeekRow from './ActivityWeekRow';

interface ActivityWeekAccordionProps {
  week: ColumnLabelsV2;
  defaultExpanded?: boolean;
  category: string;
  listActivity: Array<Activity>;
  disabled: boolean;
  setEnteredTotal: Dispatch<SetStateAction<number>>;
}

const ActivityWeekAccordion = ({
  week,
  defaultExpanded = false,
  category,
  listActivity,
  disabled = false,
  setEnteredTotal,
}: ActivityWeekAccordionProps) => {
  const [accordionTotal, setAccordionTotal] = useState(0);

  const categoryName = `${get(ActivitySectionEnum, category, category)[0]}${get(
    ActivitySectionEnum,
    category,
    category
  )
    .toLowerCase()
    .slice(1)}`;

  const daysWorked = useMemo(
    () => computeWorkedDays(listActivity),
    [listActivity]
  );
  useEffect(() => {
    setAccordionTotal(daysWorked);
  }, [daysWorked]);

  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      disableGutters
      elevation={0}
      sx={{
        '&:before': {
          display: 'none',
        },
        '.MuiButtonBase-root': {
          padding: '0px !important;',
        },
        '.MuiAccordionDetails-root': {
          padding: '0px !important;',
          mb: 2,
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography color={'primary'} variant="bodySBold">
          {categoryName}
          <Chip
            label={dotToComa(accordionTotal)}
            color="info"
            size="small"
            sx={{
              ml: 1,
              borderRadius: '4px',
            }}
          />
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {_.chain(listActivity)
          .groupBy('businessClient.name')
          .map((activities, client) => {
            const hasClient: boolean = !!client && client !== 'undefined';
            const mainActivities = _.filter(activities, (activity) => {
              return !activity.mainActivity;
            });
            return (
              <>
                {hasClient && (
                  <Typography key={`client-${client}`} color={'text.secondary'}>
                    {client}
                  </Typography>
                )}
                {mainActivities.map((mainActivity) => {
                  if (
                    mainActivity.name === SharedActivityNameEnum.RECUP &&
                    mainActivity.activityMonitoring?.length === 0
                  ) {
                    return null;
                  }
                  const subActivities = activities.filter(
                    (activity) =>
                      activity.mainActivity &&
                      activity.mainActivity.id === mainActivity.id
                  );
                  return (
                    <>
                      <ActivityWeekRow
                        key={`mainActivity-row-${mainActivity.id}`}
                        activity={mainActivity}
                        week={week}
                        showActivityInputField={
                          !mainActivity.subActivities?.length
                        }
                        disabled={disabled}
                        setEnteredTotal={setEnteredTotal}
                        setAccordionTotal={setAccordionTotal}
                      />
                      {subActivities.map((subActivity) => {
                        return (
                          <>
                            <ActivityWeekRow
                              key={`subActivity-row-${subActivity.id}`}
                              activity={subActivity}
                              week={week}
                              disabled={disabled}
                              setEnteredTotal={setEnteredTotal}
                              setAccordionTotal={setAccordionTotal}
                              showActivityInputField
                            />
                          </>
                        );
                      })}
                    </>
                  );
                })}
              </>
            );
          })
          .value()}
      </AccordionDetails>
    </Accordion>
  );
};

export default ActivityWeekAccordion;
