import PolyAlert from 'components/commons/PolyAlert';
import React from 'react';

export default function NoDataForThisYearAlert() {
  return (
    <PolyAlert severity="info">
      Pas encore de données disponibles pour cette année
    </PolyAlert>
  );
}
