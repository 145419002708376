import React from 'react';

import ConfirmationStepActive from './ConfirmationStepActive';
import ConfirmationStepDisable from './ConfirmationStepDisable';

interface ConfirmationStepProps {
  onHandleNext: () => void;
  onHandleBack: () => void;
  renderConfirmationStepActive: () => boolean;
  sendValidationBack: () => Promise<boolean | undefined>;
}

const ConfirmationStep = (properties: ConfirmationStepProps) => {
  return properties.renderConfirmationStepActive() ? (
    <ConfirmationStepActive {...properties} />
  ) : (
    <ConfirmationStepDisable {...properties} />
  );
};

export default ConfirmationStep;
