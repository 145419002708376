import { TableRow, TableRowProps } from '@mui/material';
import React from 'react';

interface PolyTableRowProps extends TableRowProps {
  className?: string;
}

const PolyTableRow = (properties: PolyTableRowProps) => {
  return <TableRow {...properties} className={properties.className} />;
};

export default PolyTableRow;
