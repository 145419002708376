import React from 'react';

interface DurationCellProps {
  duration: number;
}

const DurationCell = (properties: DurationCellProps) => {
  return <>{properties.duration} J</>;
};

export default DurationCell;
