import { Box, Skeleton, Stack } from '@mui/material';
import React from 'react';

const GraphSkeleton = () => {
  return (
    <Stack direction="row" justifyContent="space-around">
      <Box sx={{ width: 0.8, aspectRatio: '2', textAlign: 'center' }}>
        <Skeleton
          variant="rounded"
          animation="wave"
          width={80}
          sx={{ display: 'inline-block', mr: '10px' }}
        />
        <Skeleton
          variant="rounded"
          animation="wave"
          width={80}
          sx={{ display: 'inline-block', mr: '10px' }}
        />
        <Skeleton
          variant="rounded"
          animation="wave"
          width={80}
          sx={{ display: 'inline-block', mr: '10px' }}
        />
        <Skeleton
          variant="rounded"
          animation="wave"
          width={80}
          sx={{ display: 'inline-block', mr: '10px' }}
        />
        <Skeleton variant="rounded" animation="wave" height="90%" />
      </Box>
    </Stack>
  );
};

export default GraphSkeleton;
