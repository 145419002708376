import { Chip } from '@mui/material';
import { AssignmentNode } from 'generated/graphql';
import moment from 'moment';
import React from 'react';

export enum StaffingStatusEnum {
  CURRENT = 'Staffing actuel',
  HISTORY = 'Staffing passé',
  FUTURE = 'Staffing futur',
  ALL = 'Tout le staffing',
}

export const AssignmentStatusTextChoices: {
  [key in string]: string;
} = {
  ONGOING: 'En cours',
  COMING: 'Futur',
  ENDED: 'Passé',
};

export const getAssignmentStatusChip = (assignment: AssignmentNode) => {
  const { beginningDate, expirationDate } = assignment;
  let label = AssignmentStatusTextChoices.ONGOING;
  let variant: 'passed' | 'upcoming' | 'pending' = 'passed';
  if (moment(beginningDate).isAfter(moment.now())) {
    label = AssignmentStatusTextChoices.COMING;
    variant = 'upcoming';
  } else if (moment(expirationDate).isBefore(moment.now(), 'day')) {
    label = AssignmentStatusTextChoices.ENDED;
    variant = 'pending';
  }
  return {
    label: label,
    jsx: <Chip label={label} variant={variant} size="small" />,
  };
};

export const getFilteredAssigments = (
  assignments: AssignmentNode[],
  filter: StaffingStatusEnum
): AssignmentNode[] => {
  switch (filter) {
    case StaffingStatusEnum.CURRENT:
      return assignments.filter(
        (assignment: AssignmentNode) =>
          (moment(assignment.expirationDate).isSameOrAfter(
            moment.now(),
            'day'
          ) &&
            moment(assignment.beginningDate).isSameOrBefore(moment.now())) ||
          !assignment.expirationDate
      );
    case StaffingStatusEnum.FUTURE:
      return assignments.filter((assignment: AssignmentNode) =>
        moment(assignment.beginningDate).isAfter(moment.now())
      );
    case StaffingStatusEnum.HISTORY:
      return assignments.filter((assignment: AssignmentNode) =>
        moment(assignment.expirationDate).isBefore(moment.now(), 'day')
      );
    default:
      return assignments;
  }
};
