import BillStatusPaymentChip from 'components/MissionFollowUp/BillChips/BillStatusPaymentChip';
import { BillingBillPaymentStatusChoices } from 'generated/graphql';
import _ from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { setStatusPaymentFilter } from 'store/purchaseOrder';

import styles from './styles/Filters.module.scss';

const FilterPaymentStatus = () => {
  const dispatch = useDispatch();
  const currentStatus = useSelector(
    (state) => state.purchaseOrder.statusPaymentFilter
  );
  const paymentStatusChoices = [
    BillingBillPaymentStatusChoices.Pending,
    BillingBillPaymentStatusChoices.Recovering,
    BillingBillPaymentStatusChoices.Litigation,
    BillingBillPaymentStatusChoices.Paid,
  ];

  const setFilter = (status: BillingBillPaymentStatusChoices) => {
    if (_.includes(currentStatus, status)) {
      const filteredStatus = _.filter(
        currentStatus,
        (current) => current !== status
      );
      dispatch(setStatusPaymentFilter(filteredStatus));
    } else {
      dispatch(setStatusPaymentFilter([...currentStatus, status]));
    }
  };

  return (
    <div>
      {_.map(paymentStatusChoices, (status) => {
        return (
          <span className={styles.statusChip} key={`chip-${status}`}>
            <BillStatusPaymentChip
              status={status}
              onClick={() => setFilter(status)}
              isActive={currentStatus?.includes(status) || false}
            />
          </span>
        );
      })}
    </div>
  );
};

export default FilterPaymentStatus;
