import { Box, SxProps, useTheme } from '@mui/material';
import PolyTitle from 'components/commons/PolyTitle';
import BillView from 'components/MissionFollowUp/BillDetail/BillDetail/BillView/BillView';
import MissionSummaryView from 'components/MissionFollowUp/BillDetail/BillDetail/BillView/MissionSummaryView/MissionSummaryView';
import ResumePoView from 'components/MissionFollowUp/BillDetail/BillDetail/BillView/ResumePoView';
import styles from 'components/MissionFollowUp/BillDetail/Modals/styles/BillView.module.scss';
import { ExternalActivityModal } from 'components/MissionPage/ExternalActivityModal/ExternalActivityModal';
import {
  BillNode,
  PurchaseOrderNode,
  useFetchBillQuery,
  useFetchPurchaseOrderQuery,
} from 'generated/graphql';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'store';
import { setCurrentBill, setCurrentPurchaseOrder } from 'store/purchaseOrder';
import { OpacitySuffix } from 'utils';

import ModalitiesBillingView from './BillView/MissionSummaryView/ModalitiesBillingView';

const sxStyle: { [k: string]: SxProps } = {
  cardView: {
    paddingX: 1,
    paddingY: 2,
    borderRadius: 2,
    width: { xs: '100%', md: '100%', lg: '30%' },
    marginBottom: 1,
  },
};

export default function BillDetail() {
  const { palette } = useTheme();
  const { search } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const po_id = new URLSearchParams(search).get('po_id');
  const bill_id = new URLSearchParams(search).get('bill_id');
  const currentPurchaseOrders = useSelector(
    (state) => state.purchaseOrder.currentPurchaseOrder
  );
  const currentMission = useSelector((state) => state.activity.currentMission);
  const refetchCurrentMission = useSelector(
    (state) => state.activity.refetchCurrentMission
  );
  const [missionModalVisibility, setMissionModalVisibility] = useState(false);

  const [missionModalStep, setMissionModalStep] = useState(0);

  function handleCloseMissionModal() {
    setMissionModalVisibility(false);
  }

  useFetchBillQuery({
    variables: { billId: bill_id ? bill_id : '' },
    onCompleted: (data) => {
      dispatch(setCurrentBill(data.bill as BillNode));
    },
    onError: () => {
      enqueueSnackbar(`Erreur lors de la récupération des données`, {
        variant: 'error',
      });
    },
  });

  useFetchPurchaseOrderQuery({
    variables: { purchaseOrderId64: po_id ? po_id : '' },
    onCompleted: (data) => {
      dispatch(
        setCurrentPurchaseOrder(data.purchaseOrder as PurchaseOrderNode)
      );
    },
    onError: () => {
      enqueueSnackbar(`Erreur lors de la récupération des données`, {
        variant: 'error',
      });
    },
  });

  return (
    <>
      <div className={styles.billPage} key={'bill-page'}>
        <Box
          sx={{
            mb: 3,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <PolyTitle>{currentPurchaseOrders.name}</PolyTitle>
        </Box>
        <BillView />
        <Box
          sx={{
            mt: 4,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'column', lg: 'row' },
            justifyContent: 'space-between',
            alignItems: 'stretch',
            flexWrap: 'wrap',
          }}
        >
          <Box
            sx={{
              ...sxStyle.cardView,
              ...{ background: palette.primary.light },
            }}
          >
            <ResumePoView />
          </Box>
          <Box
            sx={{
              ...sxStyle.cardView,
              ...{
                background: `${palette.primary.light}${OpacitySuffix.SIXTY_PERCENT}`,
              },
            }}
          >
            <MissionSummaryView
              setMissionModalStep={setMissionModalStep}
              setMissionModalVisibility={setMissionModalVisibility}
            />
          </Box>
          <Box
            sx={{
              ...sxStyle.cardView,
              ...{
                background: `${palette.primary.light}${OpacitySuffix.TWENTY_PERCENT}`,
              },
            }}
          >
            <ModalitiesBillingView
              setMissionModalStep={setMissionModalStep}
              setMissionModalVisibility={setMissionModalVisibility}
            />
          </Box>
        </Box>
      </div>
      <ExternalActivityModal
        open={missionModalVisibility}
        onClose={handleCloseMissionModal}
        activity={currentMission}
        defaultActiveStep={missionModalStep}
        refetchActivityInfo={refetchCurrentMission}
      />
    </>
  );
}
