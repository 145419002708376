import { GridCellParams, GridColDef, GridToolbar } from '@mui/x-data-grid-pro';
import MuixTable from 'components/commons/MuixTable';
import { BillingClientModal } from 'components/MissionFollowUp/BillingClient/BillingClientModal';
import { useUserInfo } from 'components/User/UserProvider';
import { BillingClientNode, SectorNode } from 'generated/graphql';
import { EMPTY_FIELD } from 'poly-constants';
import React, { useState } from 'react';

const columns: GridColDef[] = [
  {
    field: 'corporateName',
    headerName: 'Raison sociale',
    type: 'string',
    minWidth: 100,
    flex: 1.5,
  },
  {
    field: 'talentiaId',
    headerName: 'N° Talentia',
    type: 'string',
    minWidth: 50,
    flex: 0.75,
  },
  {
    field: 'sirenNumber',
    headerName: 'N° Siren',
    type: 'string',
    minWidth: 50,
    flex: 0.75,
    valueFormatter: (params) => (params.value ? params.value : EMPTY_FIELD),
  },
  {
    field: 'sectorName',
    headerName: 'Secteur',
    type: 'string',
    minWidth: 75,
    flex: 1,
    valueGetter: (params) => params.row.sector.name,
  },
  {
    field: 'address',
    headerName: 'Adresse',
    type: 'string',
    minWidth: 75,
    flex: 1,
    valueGetter: (params) => params.row.mainAddress?.address || EMPTY_FIELD,
  },
  {
    field: 'city',
    headerName: 'Ville',
    type: 'string',
    minWidth: 100,
    flex: 1.5,
    valueGetter: (params) => params.row.mainAddress?.city || EMPTY_FIELD,
  },
  {
    field: 'zipCode',
    headerName: 'Code Postal',
    type: 'string',
    minWidth: 50,
    flex: 0.75,
    valueGetter: (params) => params.row.mainAddress?.zipCode || EMPTY_FIELD,
  },
  {
    field: 'country.mainAddress',
    headerName: 'Pays',
    type: 'string',
    minWidth: 50,
    flex: 1,
    valueGetter: (params) => params.row.mainAddress?.country || EMPTY_FIELD,
  },
];

interface BillingClientTableProps {
  data: BillingClientNode[];
  sectorList: SectorNode[];
  refetch: () => void;
}

export default function BillingClientTable({
  data,
  sectorList,
  refetch,
}: BillingClientTableProps) {
  const { isSuperuser, isControl } = useUserInfo();
  const isAuthorizedUser = isSuperuser || isControl;
  const [open, setOpen] = useState<boolean>(false);
  const [selectedClient, setSelectedClient] = useState<BillingClientNode>();
  const handleCellClick = (params: GridCellParams): void => {
    if (isAuthorizedUser) {
      setOpen(true);
      setSelectedClient(params.row);
    }
  };
  const closeModal = () => {
    setOpen(false);
  };
  return (
    <>
      <MuixTable
        rows={data}
        columns={columns}
        rowSelection={false}
        pageSizeOptions={[10, 15, 25, 50]}
        sx={{
          mt: 4,
          '& .MuiDataGrid-cell': {
            ':focus': {
              outline: 'none',
            },
          },
          '& .MuiDataGrid-columnHeader': {
            ':focus , :focus-within': {
              outline: 'none',
            },
          },
          '.MuiDataGrid-row:hover': {
            cursor: isAuthorizedUser ? 'pointer' : 'normal',
            backgroundColor: 'primary.light',
          },
          '.MuiDataGrid-row.Mui-hovered': {
            backgroundColor: 'primary.light',
          },
          borderRadius: '8px',
        }}
        pagination
        autoHeight
        ignoreDiacritics
        disableColumnReorder
        density="compact"
        onCellClick={handleCellClick}
        initialState={{
          preferencePanel: { open: false },
          pagination: {
            paginationModel: { pageSize: 50, page: 0 },
          },
        }}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
      {open && (
        <BillingClientModal
          mode={'edit'}
          open={open}
          close={closeModal}
          billingClient={selectedClient}
          sectorList={sectorList}
          onSuccess={refetch}
        />
      )}
    </>
  );
}
