import { Box, DialogContentText, Stack, Typography } from '@mui/material';
import { useFilterContext } from 'components/commons/FilterContext/FilterContextProvider';
import MonthPicker from 'components/commons/MonthPicker';
import { getRangeFromDate } from 'components/commons/MonthPicker/MonthPickerTools';
import { createCsvFile } from 'components/commons/PolyExportButton';
import PolyModal from 'components/MUIOverload/PolyDialog/PolyModal';
import { useTableContext } from 'components/Revenue/RevenueGlobalTable/context/TableContextProvider';
import { useFetchExportBilledRevenueLazyQuery } from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'store';
import { graphQlDateFormatter } from 'utils';

interface ModalProps {
  isOpen: boolean;
  handleClose: () => void;
  title: string;
  body: string;
}

export default function BilledRevenueExportModal({
  isOpen,
  handleClose,
  title,
  body,
}: ModalProps) {
  const form = useForm();
  const selectedMissions = useSelector(
    (state) => state.activity.selectedMissions
  );
  const { enqueueSnackbar } = useSnackbar();
  const { displayedMonths } = useTableContext();
  const { filteredID: filteredDirectorId } = useFilterContext();
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    moment.min(displayedMonths).clone().startOf('month').toDate(),
    moment.max(displayedMonths).clone().endOf('month').toDate(),
  ]);

  const [exportQuery] = useFetchExportBilledRevenueLazyQuery({
    onCompleted: (data) => {
      if (data && data.exportBilledRevenue) {
        const dateFromString: string = dateRange[0].toLocaleDateString(
          undefined,
          {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }
        );
        const dateToString: string = dateRange[1].toLocaleDateString(
          undefined,
          {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }
        );
        const filename = `revenue_facture_${dateFromString}_${dateToString}.csv`;
        createCsvFile(data.exportBilledRevenue, filename);
        handleClose();
      }
    },
    onError: () => {
      enqueueSnackbar('Erreur lors de la récupération des données', {
        variant: 'error',
      });
      handleClose();
    },
  });

  useEffect(() => {
    setDateRange([
      moment.min(displayedMonths).clone().startOf('month').toDate(),
      moment.max(displayedMonths).clone().endOf('month').toDate(),
    ]);
  }, [displayedMonths]);

  const exportTable = () => {
    exportQuery({
      variables: {
        dateFrom: graphQlDateFormatter(dateRange[0]),
        dateTo: graphQlDateFormatter(dateRange[1]),
        activityDirectorId: filteredDirectorId,
        missionsIds: _.map(selectedMissions, (mission) => mission.id),
      },
    });
  };

  return (
    <PolyModal
      open={isOpen}
      onClose={handleClose}
      polyDialogTitle={title}
      handlePrimary={exportTable}
      primaryButtonName={'Télécharger'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <>
        <DialogContentText id="alert-dialog-description">
          {body}
        </DialogContentText>
        <Stack direction={'row'} sx={{ alignItems: 'center' }}>
          <Typography sx={{ fontWeight: 'bold' }}>Dates :</Typography>
          <Box sx={{ marginLeft: 14, marginTop: 2 }}>
            <MonthPicker
              form={form}
              label={'Mois facturés'}
              range={getRangeFromDate(
                moment.min(displayedMonths).clone().add(-10, 'year').toDate(),
                moment.max(displayedMonths).clone().add(10, 'year').toDate()
              )}
              name={'actualRevenueMonthRange'}
              onChange={(rangeMonth) => {
                setDateRange([
                  moment(rangeMonth.from).startOf('month').toDate(),
                  moment(rangeMonth.to).endOf('month').toDate(),
                ]);
              }}
              value={{
                from: {
                  month: moment.min(displayedMonths).month() + 1,
                  year: moment.min(displayedMonths).year(),
                },
                to: {
                  month: moment.max(displayedMonths).month() + 1,
                  year: moment.max(displayedMonths).year(),
                },
              }}
            />
          </Box>
        </Stack>
      </>
    </PolyModal>
  );
}
