import { Box, Stack } from '@mui/material';
import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import BillingTablePagination from 'components/MissionFollowUp/OverallListBill/BillingTablePagination';
import BillsAmountSum from 'components/MissionFollowUp/OverallListBill/BillsAmountSum';
import ExportBillsButton from 'components/MissionFollowUp/OverallListBill/ExportBillsButton';
import FilterCreator from 'components/MissionFollowUp/OverallListBill/Filters/FilterCreator';
import FilterDirector from 'components/MissionFollowUp/OverallListBill/Filters/FilterDirector';
import FilterStatus from 'components/MissionFollowUp/OverallListBill/Filters/FilterStatus';
import StatusGroupEditButtons from 'components/MissionFollowUp/OverallListBill/GroupEditButtons/StatusGroupEditButtons';
import ManagementControlBillsTable from 'components/MissionFollowUp/OverallListBill/ManagementControlBillsTable/ManagementControlBillsTable';
import BillsTableContextProvider from 'components/MissionFollowUp/OverallListBill/TablePaginationListBills/BillsTableContextProvider';
import BillsTabHeader from 'components/MissionFollowUp/TabHeaders/BillsTabHeader';
import {
  ActivityNode,
  BillNode,
  useAllBillsPaginatedQuery,
} from 'generated/graphql';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import InternalMissionPage from 'pages/ErrorPage/InternalMissionPage';
import styles from 'pages/MissionFollowUp/styles/MissionFollowUp.module.scss';
import { PageTitles } from 'poly-constants';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { setAllBills } from 'store/purchaseOrder';
import { setCreatorFilter, setDirectorFilter } from 'store/users';
import { checkIfAllMissionsAreInternal } from 'utils';

const ManagementControlBills = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const filterStatus = useSelector((state) => state.purchaseOrder.statusFilter);
  const directorFilter = useSelector((state) => state.users.directorFilter);
  const creatorFilter = useSelector((state) => state.users.creatorFilter);
  useEffect(() => {
    dispatch(setDirectorFilter(null));
    dispatch(setCreatorFilter(null));
  }, [dispatch]);

  const currentMission = useSelector((state) => state.activity.currentMission);
  const selectedMissions = useSelector(
    (state) => state.activity.selectedMissions as ActivityNode[]
  );
  const missionIds = currentMission?.id
    ? [currentMission.id]
    : _.map(selectedMissions, (mission) => mission.id);

  const [nbrBills, setNbrBills] = useState(0);
  const [pageNbr, setPageNbr] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [filter, setFilter] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);

  const { loading } = useAllBillsPaginatedQuery({
    variables: {
      status: filterStatus,
      activityIds: missionIds,
      directorId: directorFilter?.id,
      creatorId: creatorFilter?.id,
      orderBy: filter ? [filter] : ['-period_beginning', '-period_ending'],
      pageNbr: pageNbr,
      pageSize: pageSize,
    },
    onCompleted: (data) => {
      dispatch(setAllBills(data.allBillsPaginated?.bills as BillNode[]));
      setNbrBills(data.allBillsPaginated?.billsNumber || 0);
      setTotalAmount(data.allBillsPaginated?.totalAmount || 0);
    },
    onError: () => {
      enqueueSnackbar(`Erreur lors de la récupération des données`, {
        variant: 'error',
      });
    },
  });

  if (checkIfAllMissionsAreInternal(selectedMissions)) {
    return <InternalMissionPage page={PageTitles.billsPage} />;
  }

  return (
    <>
      <BillsTabHeader
        count={nbrBills}
        filterComponents={
          <BillsTableContextProvider>
            <Stack direction="column" spacing={2}>
              <Stack direction="row" spacing={2}>
                <FilterDirector filterWidth={230} />
                <FilterCreator filterWidth={230} />
              </Stack>
              <FilterStatus />
              {!!filterStatus.length && !loading && (
                <BillsAmountSum euroSum={totalAmount} />
              )}
            </Stack>
          </BillsTableContextProvider>
        }
        actionComponents={
          <>
            <StatusGroupEditButtons isInActionsMenu />
            <ExportBillsButton sx={{ ml: 1 }} />
          </>
        }
      />
      <Box sx={{ marginTop: '10px', marginLeft: '1px' }}></Box>
      {!loading ? (
        <BillsTableContextProvider>
          <div className={styles.billTable} key={'billTable'}>
            <BillingTablePagination
              totalLen={nbrBills}
              pageNbr={pageNbr}
              setPageNbr={setPageNbr}
              pageSize={pageSize}
              setPageSize={setPageSize}
              paginationLabel="Factures par page"
              sx={{
                p: 1,
                pr: 0,
              }}
            />
            <ManagementControlBillsTable setFilter={setFilter} />
          </div>
        </BillsTableContextProvider>
      ) : (
        <div className={styles.loader} key={'loader'}>
          <LoadingPlaceholder />
        </div>
      )}
    </>
  );
};

export default ManagementControlBills;
