import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Button,
  ClickAwayListener,
  List,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { scrollbarParams } from 'utils';

interface OverflowingMissionsDropdownProps {
  count: number;
  children: React.ReactFragment;
  maxHeight?: number;
}

export default function OverflowingMissionsDropdown({
  count,
  children,
  maxHeight = 600,
}: OverflowingMissionsDropdownProps) {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isMenuOpened = (anchorEl: HTMLButtonElement | null): boolean => {
    return Boolean(anchorEl);
  };

  return (
    <>
      <Button
        onClick={handleClick}
        endIcon={
          isMenuOpened(anchorEl) ? <ExpandLessIcon /> : <ExpandMoreIcon />
        }
        variant="text"
        sx={{
          color: palette.text.secondary,
          gap: 1,
          'span.MuiButton-endIcon': {
            ml: 0,
          },
        }}
      >
        <Typography
          sx={{
            color: palette.primary.main,
            fontWeight: 'bold',
          }}
        >
          {count}
        </Typography>
        <Typography
          sx={{
            color: palette.text.secondary,
            textTransform: 'none',
          }}
        >
          missions sélectionnées
        </Typography>
      </Button>
      <Popper
        open={isMenuOpened(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-start"
        disablePortal
      >
        <Paper
          elevation={3}
          sx={{
            mt: 1,
            borderRadius: '8px',
            maxHeight: maxHeight,
            ...scrollbarParams,
          }}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <List>{children}</List>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
}
