import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Typography } from '@mui/material';
import { DateRangePicker } from 'components/commons/DateRangePicker';
import PolySubTitle from 'components/commons/PolySubTitle';
import GridItem from 'components/MissionFollowUp/GridItem';
import LabelTextFieldGrid from 'components/MissionFollowUp/LabelTextFieldGrid';
import { handleSuggestionOnEnter } from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/RevenueTableCells/CellTypes/RevenueCellInput';
import { Currencies, PurchaseOrderTaskNode } from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { comaStringToFloat, dotToComa } from 'pages/ActivityPage/utils';
import {
  isNumber,
  isNumberPositive,
  requiredForm,
} from 'pages/MissionFollowUp/formValidators';
import { CurrenciesSymbols } from 'poly-constants';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'store';

import { convertStringToNumber } from '../../MissionFollowUp/BillDetail/utils';

interface PurchaseOrderTasksFormProps {
  formName: string;
  selfDelete: () => void;
  purchaseOrdersRange: { start: Date; end: Date };
}

const PurchaseOrderFormTasks = ({
  formName,
  selfDelete,
  purchaseOrdersRange,
}: PurchaseOrderTasksFormProps) => {
  const {
    register,
    errors,
    getValues,
    setValue,
    watch,
    trigger,
    setError,
    clearErrors,
  } = useFormContext();
  const currentPurchaseOrder = useSelector(
    (state) => state.purchaseOrder.currentPurchaseOrder
  );
  const periodBeginning = watch(`${formName}.periodBeginning`);
  const periodEnding = watch(`${formName}.periodEnding`);
  const currentTaskId = getValues(`${formName}.id`);

  useEffect(() => {
    const amount = watch(`${formName}.amount`)?.toString() || '';
    if (amount.includes('.')) {
      setValue(`${formName}.amount`, dotToComa(amount));
    }
  });

  useEffect(() => {
    const hasRevenueOutsideOfDate = _.some(
      currentPurchaseOrder.revenues,
      (revenue) => {
        if (currentTaskId && revenue.task?.id === currentTaskId) {
          return !moment(revenue.month).isBetween(
            moment(periodBeginning),
            moment(periodEnding),
            'months',
            '[]'
          );
        }
        return false;
      }
    );

    const hasError =
      _.get(errors, `${formName}.periodBeginning`)?.type ===
      'revenueOutsideOfDate';
    if (currentTaskId && hasRevenueOutsideOfDate && !hasError) {
      setError(`${formName}.periodBeginning`, {
        type: 'revenueOutsideOfDate',
        message: `Le chantier a des revenus hors des dates données`,
      });
    } else if (hasError && !hasRevenueOutsideOfDate) {
      clearErrors(`${formName}.periodBeginning`);
    }
  }, [
    periodBeginning,
    periodEnding,
    currentTaskId,
    currentPurchaseOrder,
    clearErrors,
    formName,
    setError,
    errors,
  ]);

  function getPlaceholder() {
    const total = convertStringToNumber(watch('total'));
    const tasks = watch('tasks') as PurchaseOrderTaskNode[];
    const totalOfTasks = _.sumBy(tasks, (task) => {
      const value = convertStringToNumber(task.amount as unknown as string);
      return Number.isNaN(value) ? 0 : value;
    });
    const nbTasksWithValue = _.filter(tasks, (task) => !!task.amount).length;
    return total
      ? comaStringToFloat(
          (total - totalOfTasks) / (tasks?.length - nbTasksWithValue)
        ).toFixed(2)
      : undefined;
  }

  const suggestedValue = getPlaceholder();

  return (
    <Card sx={{ m: 0.5, position: 'relative' }} variant="outlined">
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Box>
              <PolySubTitle>Chantier associé au BDC</PolySubTitle>
              <IconButton
                aria-label="delete"
                onClick={() => selfDelete()}
                size="large"
                sx={{
                  position: 'absolute',
                  width: '30px',
                  height: '30px',
                  right: '4px',
                  top: '4px',
                  color: 'primary.main',
                }}
              >
                <ClearIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid container>
            <LabelTextFieldGrid
              title="Nom de chantier"
              required
              name={`${formName}.name`}
              defaultValue={getValues(`${formName}.name`)}
              inputRef={register({
                required: requiredForm,
              })}
              errorform={_.get(errors, `${formName}.name`)}
              sizegrid={3}
            />
            <LabelTextFieldGrid
              title="Montant du chantier"
              placeholder={suggestedValue}
              onKeyPress={handleSuggestionOnEnter}
              onFocus={(event) => {
                if (suggestedValue && !event.target.value) {
                  setValue(`${formName}.amount`, suggestedValue);
                  void trigger(['total', formName, `${formName}.amount`]);
                }
              }}
              required
              name={`${formName}.amount`}
              defaultValue={getValues(`${formName}.amount`)}
              inputRef={register({
                required: requiredForm,
                validate: {
                  isNumber,
                  isNumberPositive,
                },
              })}
              errorform={_.get(errors, `${formName}.amount`)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position={'start'}>
                    {CurrenciesSymbols[watch('currency') as Currencies]}
                  </InputAdornment>
                ),
              }}
              sizegrid={3}
            />
            <GridItem customspacingtop={30} sizegrid={6}>
              <DateRangePicker
                disableFuture={false}
                externallyControlled
                minDate={purchaseOrdersRange.start}
                maxDate={purchaseOrdersRange.end}
                handleDateRangeChange={(dates) => {
                  setValue(`${formName}.periodBeginning`, dates[0]);
                  setValue(`${formName}.periodEnding`, dates[1]);
                }}
                dateRange={[
                  watch(`${formName}.periodBeginning`),
                  watch(`${formName}.periodEnding`),
                ]}
              />
            </GridItem>
          </Grid>
        </Grid>
        {_.get(errors, `${formName}.periodBeginning`) && (
          <Typography
            sx={{
              color: 'error.main',
              ml: '14px',
              mt: '4px',
              fontSize: '12px',
            }}
          >
            {_.get(errors, `${formName}.periodBeginning`).message}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default PurchaseOrderFormTasks;
