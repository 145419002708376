import PolyExportButton from 'components/commons/PolyExportButton';
import React, { useState } from 'react';

import PurchaseOrdersExportModal from './PurchaseOrdersExportModal';

export default function PurchaseOrdersExportButton() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <PolyExportButton onClick={toggleModal} sx={{ mx: 1 }}>
        Exporter
      </PolyExportButton>
      <PurchaseOrdersExportModal isOpen={isOpen} handleClose={toggleModal} />
    </>
  );
}
