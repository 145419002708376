import PolyFooter from 'components/MUIOverload/PolyFooter';
import PongoButton from 'components/MUIOverload/PongoButton';
import React from 'react';

import styles from '../styles/ActiveBody.module.scss';

export const introductionStepTitle = 'Bienvenue chez Polyconseil';
export const introductionStepBody =
  "Bienvenue sur Pongo, l'intranet de Polyconseil. Avant de pouvoir accéder aux différentes fonctionnalités, vous devez configurer votre compte.";
interface StepOneProps {
  onHandleNext: () => void;
}

const IntroductionStep = (properties: StepOneProps) => {
  return (
    <div>
      <div className={styles.root}>
        <div className={styles.title}>{introductionStepTitle}</div>
        <div className={styles.body}>{introductionStepBody}</div>
      </div>
      <PolyFooter>
        <PongoButton
          color="primary"
          variant="contained"
          onClick={properties.onHandleNext}
        >
          Suivant
        </PongoButton>
      </PolyFooter>
    </div>
  );
};

export default IntroductionStep;
