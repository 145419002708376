import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import MuixTable from 'components/commons/MuixTable';
import { useAbsenceContext } from 'contexts/Absence/AbsenceContextProvider';
import { AbsenceNode, useFetchAllAbsencesQuery } from 'generated/graphql';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import React from 'react';

import AbsenceStatus from './AbsenceStatus';

const COLUMNS: GridColDef[] = [
  { field: 'label', headerName: 'Libellé', flex: 1 },
  {
    field: 'dateRange',
    headerName: 'Dates',
    flex: 1,
    sortComparator: (v1, v2) => {
      const date1 = moment(v1.from);
      const date2 = moment(v2.from);
      if (date1.isBefore(date2)) {
        return -1;
      }
      if (date1.isAfter(date2)) {
        return 1;
      }
      return 0;
    },
    renderCell: (params) => (
      <Box
        sx={{
          textTransform: 'capitalize',
        }}
      >
        {getFormattedDateRange(params.value.from, params.value.to)}
      </Box>
    ),
  },
  {
    field: 'numberOfDays',
    headerName: 'Nombre de jours',
    type: 'number',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    valueFormatter: ({ value }: { value: number }) =>
      value.toString().replace('.', ','), // replace . by , for french format
  },
  {
    field: 'status',
    headerName: 'Statut',
    renderCell: (params) => params.value as JSX.Element,
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    sortComparator: (v1, v2) => {
      //sort type by status
      const status1 = v1.props.type;
      const status2 = v2.props.type;
      if (status1 === status2) {
        return 0;
      }
      if (status1 === 'upcoming') {
        return -1;
      }
      if (status1 === 'passed') {
        return 1;
      }
      if (status1 === 'pending' && status2 === 'upcoming') {
        return 1;
      }
      if (status1 === 'pending' && status2 === 'passed') {
        return -1;
      }
      return 0;
    },
  },
];
const DATE_FORMAT = 'YYYY-MM-DD';
const DATE_HUMAN_READABLE_FORMAT = 'DD MMM YYYY';

function AbsenceTable(): JSX.Element {
  const { refetch, setModalMode, setModalOpen, setAbsenceToEdit } =
    useAbsenceContext();
  const { data, refetch: valRefetch } = useFetchAllAbsencesQuery();

  const rows =
    data?.listAbsences?.map((absence) => ({
      id: absence.id,
      label: absence.label,
      dateRange: {
        from: moment(absence.dateFrom).format(DATE_FORMAT),
        to: moment(absence.dateTo).format(DATE_FORMAT),
      },
      numberOfDays: absence.numberOfDays,
      status: getStatus(absence.dateFrom, absence.dateTo),
    })) ?? [];
  refetch.current = valRefetch;
  return (
    <MuixTable
      containerWidth={'100%'}
      columns={COLUMNS}
      rows={rows}
      autoHeight
      disableColumnMenu
      pagination={true}
      pageSizeOptions={[10, 20, 50]}
      initialState={{
        pagination: {
          paginationModel: { pageSize: 10, page: 0 },
        },
      }}
      getRowId={(row) => row.id}
      sx={{
        '& .MuiDataGrid-cell': {
          ':focus': {
            outline: 'none',
          },
        },
        '& .MuiDataGrid-columnHeader': {
          ':focus , :focus-within': {
            outline: 'none',
          },
        },
        '.MuiDataGrid-row:hover': {
          cursor: 'pointer',
          backgroundColor: 'primary.light',
        },

        borderRadius: '8px',
      }}
      rowSelection={false}
      onRowClick={({ id }) => {
        const absence = data?.listAbsences?.find(
          (absence) => absence.id === id
        );
        if (absence) {
          setAbsenceToEdit(absence as AbsenceNode);
          setModalMode('edit');
          setModalOpen(true);
        } else {
          enqueueSnackbar('Une erreur est survenue', { variant: 'error' });
        }
      }}
    />
  );
}

export default AbsenceTable;

function getStatus(dateFrom: string, dateTo: string) {
  const today = moment().format('YYYY-MM-DD');
  if (moment(dateFrom).isAfter(today)) {
    return <AbsenceStatus type="upcoming" />;
  }
  if (moment(dateTo).isBefore(today)) {
    return <AbsenceStatus type="passed" />;
  }
  return <AbsenceStatus type="pending" />;
}

function getFormattedDateRange(dateFrom: string, dateTo: string) {
  const dateFromFormatted = moment(dateFrom).format(DATE_HUMAN_READABLE_FORMAT);
  const dateToFormatted = moment(dateTo).format(DATE_HUMAN_READABLE_FORMAT);
  return `${dateFromFormatted} - ${dateToFormatted}`;
}
