import EditIcon from '@mui/icons-material/Edit';
import InventoryIcon from '@mui/icons-material/Inventory';
import { Stack, Typography } from '@mui/material';
import ActivityIndicator from 'components/commons/ActivityIndicator';
import FieldDisplay from 'components/commons/FieldDisplay/FieldDisplay';
import Section from 'components/commons/Section';
import SectionTitle from 'components/commons/SectionTitle';
import PongoButton from 'components/MUIOverload/PongoButton';
import {
  ActivityIndicatorFragment,
  ActivityNode,
  useLatestIndicatorQuery,
} from 'generated/graphql';
import moment from 'moment';
import { EMPTY_FIELD } from 'poly-constants';
import React, { useState } from 'react';
import { formatDateUStoFR } from 'utils';

import IndicatorHistoryModal from './IndicatorHistoryModal';
import IndicatorModal from './IndicatorModal';

interface ActivityIndicatorsProps {
  activity: ActivityNode;
  latestIndicator?: ActivityIndicatorFragment;
}

export default function ActivityIndicators({
  activity,
  latestIndicator,
}: ActivityIndicatorsProps): JSX.Element {
  const [indicatorModalVisibility, setIndicatorModalVisibility] =
    useState(false);
  const [indicatorHistoryModalVisibility, setIndicatorHistoryModalVisibility] =
    useState(false);
  const { refetch } = useLatestIndicatorQuery({
    variables: { activityId: activity.id },
  });
  const isEditable = moment(activity.expirationDate || undefined).isSameOrAfter(
    moment()
  );

  return (
    <Section
      color={latestIndicator ? 'default' : 'error'}
      sx={{
        my: 3,
        flex: 0.5,
        justifyContent: 'space-between',
      }}
    >
      <Stack gap={2}>
        <Stack direction="row" justifyContent="space-between">
          <SectionTitle color={latestIndicator ? 'primary' : 'error'}>
            Indicateurs
          </SectionTitle>
          {isEditable && latestIndicator && (
            <PongoButton
              variant="contained"
              startIcon={<EditIcon />}
              onClick={() => setIndicatorModalVisibility(true)}
            >
              Mettre à jour
            </PongoButton>
          )}
        </Stack>
        <Stack direction="row" spacing={4}>
          <FieldDisplay
            title="Équipe"
            value={
              <ActivityIndicator indicator={latestIndicator?.efficiency} />
            }
            flexDirection="column"
            titleVariant="bodySemiBold"
            showColon={false}
          />
          <FieldDisplay
            title="Client"
            value={
              <ActivityIndicator
                indicator={latestIndicator?.clientSatisfaction}
              />
            }
            flexDirection="column"
            titleVariant="bodySemiBold"
            showColon={false}
          />
          <FieldDisplay
            title="Dernière modification"
            value={
              latestIndicator
                ? `Le ${formatDateUStoFR(latestIndicator.createdAt)}`
                : EMPTY_FIELD
            }
            flexDirection="column"
            titleVariant="bodySemiBold"
            valueVariant="bodySemiBold"
            showColon={false}
          />
        </Stack>
        <Stack>
          <FieldDisplay
            title="Commentaire"
            value={
              latestIndicator?.comment ? (
                <Typography
                  variant="bodySemiBold"
                  sx={{ whiteSpace: 'pre-wrap' }}
                >
                  {latestIndicator?.comment}
                </Typography>
              ) : (
                <Typography variant="bodySBold">{EMPTY_FIELD}</Typography>
              )
            }
            flexDirection="column"
            titleVariant="bodySemiBold"
            showColon={false}
          />
        </Stack>
      </Stack>
      {latestIndicator ? (
        <PongoButton
          variant="contained"
          buttonStyle="secondary"
          endIcon={<InventoryIcon />}
          onClick={() => setIndicatorHistoryModalVisibility(true)}
        >
          Voir l’historique
        </PongoButton>
      ) : (
        <></>
      )}
      {isEditable && !latestIndicator ? (
        <PongoButton
          color="error"
          variant="contained"
          startIcon={<EditIcon />}
          onClick={() => setIndicatorModalVisibility(true)}
        >
          Remplir
        </PongoButton>
      ) : (
        <></>
      )}
      <IndicatorModal
        activityId={activity.id}
        open={indicatorModalVisibility}
        refetch={refetch}
        handleClose={() => setIndicatorModalVisibility(false)}
      />
      <IndicatorHistoryModal
        activityId={activity.id}
        open={indicatorHistoryModalVisibility}
        handleClose={() => setIndicatorHistoryModalVisibility(false)}
      />
    </Section>
  );
}
