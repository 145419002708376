import {
  ActivityNode,
  BillingPurchaseOrderCurrencyChoices,
  PurchaseOrderNode,
  useCreatePurchaseOrderMutation,
} from 'generated/graphql';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { ReactElement } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'store';
import { addPurchaseOrder } from 'store/purchaseOrder';

import PurchaseOrderForm, { PoFormMode } from './PurchaseOrderForm';
import {
  buildCreatePurchaseOrderMutationVariables,
  PurchaseOrderFormDefaultValues,
  translatePurchaseOrderErrorMessage,
} from './utils';

export interface CreatePurchaseOrderFormProps {
  defaultValues?: PurchaseOrderFormDefaultValues;
  validCallback?: () => void;
  cancelCallback?: () => void;
  selectedActivity?: ActivityNode;
}

export default function CreatePurchaseOrderForm({
  defaultValues,
  validCallback,
  cancelCallback,
  selectedActivity,
}: CreatePurchaseOrderFormProps): ReactElement {
  const currentMissionInStore = useSelector(
    (state) => state.activity.currentMission
  );
  const currentMission = selectedActivity
    ? selectedActivity
    : currentMissionInStore;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();

  const [createPurchaseOrder, { loading }] = useCreatePurchaseOrderMutation({
    onError: (error) => {
      const errorMessage = translatePurchaseOrderErrorMessage(error.message);
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        style: { maxWidth: '600px' },
      });
    },
    onCompleted: ({ createPurchaseOrder: newPo }) => {
      if (newPo) {
        dispatch(addPurchaseOrder(newPo as PurchaseOrderNode));
        if (defaultValues) {
          history.push(
            `/mission-followup/missions/details/purchase_order?mission_id=${currentMission.id}&po_id=${newPo.id}`
          );
        }
        enqueueSnackbar(
          `Le bon de commande « ${newPo.name} » a été créé avec succès`,
          { variant: 'success' }
        );
        if (validCallback) {
          validCallback();
        }
      }
    },
  });

  const formDefaultValues = defaultValues || {
    purchaseOrderName: '',
    purchaseOrderNum: '',
    collaborators: [],
    isSubMission: false,
    total: '',
    currency: BillingPurchaseOrderCurrencyChoices.Eur,
    tasks: [],
    poSubMissions: [],
    smAdrs: true,
    periodStart: moment().startOf('month').toDate(),
    periodEnd: moment().endOf('month').toDate(),
    document: {
      id: '',
      filename: '',
      file: undefined,
    },
    hasTemporaryDocument: false,
    attachments: [],
    address:
      currentMission.billingInformation?.billingClient?.addresses?.length === 1
        ? currentMission.billingInformation?.billingClient?.addresses[0].id
        : '',
    billingAddresses:
      currentMission.billingInformation?.billingClient?.addresses || [],
  };
  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: formDefaultValues,
    criteriaMode: 'firstError',
    shouldUnregister: false,
  });

  async function onSubmit(submitValues: typeof formDefaultValues) {
    if (loading || submitValues.collaborators === undefined) {
      return;
    }

    await createPurchaseOrder({
      variables: buildCreatePurchaseOrderMutationVariables(
        submitValues,
        currentMission
      ),
    });
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <PurchaseOrderForm
          defaultValues={formDefaultValues}
          cancelCallback={cancelCallback}
          loading={loading}
          mode={defaultValues ? PoFormMode.DUPLICATE : PoFormMode.CREATE}
          selectedActivity={selectedActivity}
        />
      </form>
    </FormProvider>
  );
}
