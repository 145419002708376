import { Close, Search } from '@mui/icons-material';
import {
  Box,
  Chip,
  Divider,
  List,
  ListItemButton,
  Tooltip,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import MissionTypeIcon from 'components/commons/MissionTypeIcon';
import PongoTextField from 'components/MUIOverload/PongoTextField';
import { EMPTY_SEARCH_INPUT } from 'components/Phonebook/PolyTrombiSearchFilters/PolyTrombiSearchFilterBar';
import PolyTrombiSearchFilterDropdownMenu from 'components/Phonebook/PolyTrombiSearchFilters/PolyTrombiSearchFilterDropdownMenu';
import { useUserInfo } from 'components/User/UserProvider';
import { usePolyTrombiContext } from 'contexts/Phonebook/PolyTrombiContextProvider';
import {
  ActivitiesActivityBillingTypeChoices,
  ActivitiesActivityTypeChoices,
  ActivityNode,
  AssignmentNode,
} from 'generated/graphql';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

const DEFAULT_MISSION_LIST_TITLE = 'Mes missions';
const MISSION_LIST_TITLE_WHEN_TYPING = 'Résultats';
const MAX_DISPLAYED_MISSIONS = 5;

interface MissionFilterProps {
  searchInput: string;
  setSearchInput: (searchInput: string) => void;
}

function isSearchMatch(
  lowerCaseMissionName: string,
  lowerCaseSearchInput: string
): boolean {
  return lowerCaseMissionName.includes(lowerCaseSearchInput);
}

function isFinished(assignment: AssignmentNode): boolean {
  const hasExpirationDate = assignment.expirationDate !== undefined;
  const expirationDate = Date.parse(assignment.expirationDate);
  return hasExpirationDate ? expirationDate < Date.now() : true;
}

export default function PolyTrombiMissionSearchFilter({
  searchInput,
  setSearchInput,
}: MissionFilterProps) {
  const { palette } = useTheme();
  const {
    selectedMissions,
    setSelectedMissions,
    allMissions,
    setShowSkeleton,
  } = usePolyTrombiContext();
  const { employee } = useUserInfo();
  const [
    isDeleteSearchInputIconDisplayed,
    setIsDeleteSearchInputIconDisplayed,
  ] = useState<boolean>(false);
  const [missionListTitle, setMissionListTitle] = useState<string>(
    DEFAULT_MISSION_LIST_TITLE
  );

  useEffect(() => {
    if (searchInput === EMPTY_SEARCH_INPUT) {
      setIsDeleteSearchInputIconDisplayed(false);
      setMissionListTitle(DEFAULT_MISSION_LIST_TITLE);
    } else {
      setIsDeleteSearchInputIconDisplayed(true);
      setMissionListTitle(MISSION_LIST_TITLE_WHEN_TYPING);
    }
  }, [searchInput]);

  const assignedMissions: ActivityNode[] = _.filter(allMissions, (mission) =>
    _.some(
      employee?.assignments as AssignmentNode[],
      (assignment) =>
        assignment !== undefined &&
        assignment?.activity?.id === mission.id &&
        !isFinished(assignment)
    )
  );

  const resultMissions: ActivityNode[] = searchInput
    ? _.filter(allMissions, (mission) =>
        isSearchMatch(mission.name.toLowerCase(), searchInput.toLowerCase())
      )
    : assignedMissions;

  const displayedMissions: ActivityNode[] = _.filter(
    resultMissions,
    (mission) =>
      !_.some(
        selectedMissions,
        (selectedMission) => selectedMission.id === mission.id
      )
  );

  const handleMissionSelect = (targetMission: ActivityNode) => {
    if (
      !_.some(
        selectedMissions,
        (selectedMission: ActivityNode) =>
          selectedMission.id === targetMission.id
      )
    ) {
      setSelectedMissions([...selectedMissions, targetMission]);
      setShowSkeleton(true);
      window.scrollTo(0, 0);
    }
  };

  const handleMissionDelete = (targetMission: ActivityNode) => {
    setSelectedMissions(
      _.filter(selectedMissions, (mission) => mission.id !== targetMission.id)
    );
    setShowSkeleton(true);
    window.scrollTo(0, 0);
  };

  const missionNameWithIcon = (mission: ActivityNode) => {
    return (
      <Box sx={{ width: 1, display: 'flex', alignItems: 'center' }}>
        <MissionTypeIcon
          type={mission?.type as ActivitiesActivityTypeChoices}
          isTmContracts={
            mission?.billingType === ActivitiesActivityBillingTypeChoices.Tm
          }
          sx={{ mr: 1, color: palette.info.main }}
        />
        <Tooltip title={mission.name} arrow>
          <Typography
            variant="bodyBold"
            sx={{ color: palette.info.main }}
            noWrap
          >
            {mission.name}
          </Typography>
        </Tooltip>
      </Box>
    );
  };

  return (
    <PolyTrombiSearchFilterDropdownMenu
      title="Missions"
      filterCount={selectedMissions.length}
    >
      <Box sx={{ width: 375 }}>
        <PongoTextField
          placeholder="Rechercher une mission"
          sx={{
            mb: 1,
          }}
          fieldStyle="secondary"
          value={searchInput}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const typedText = event.target.value;
            setSearchInput(typedText);
          }}
          startAdornment={
            <Search
              sx={{
                color: palette.info.main,
                width: '22px',
                height: '22px',
              }}
            />
          }
          endAdornment={
            isDeleteSearchInputIconDisplayed && (
              <IconButton
                onClick={() => {
                  setSearchInput(EMPTY_SEARCH_INPUT);
                }}
              >
                <Close
                  sx={{
                    width: '20px',
                    height: '20px',
                  }}
                />
              </IconButton>
            )
          }
          fullWidth
        />
        <Box sx={{ my: 1 }}>
          {_.map(selectedMissions, (mission: ActivityNode) => {
            return (
              <Chip
                key={'selected-mission-' + mission.id}
                label={missionNameWithIcon(mission)}
                sx={{ mr: 1, mb: 1, backgroundColor: palette.info.light }}
                onDelete={() => handleMissionDelete(mission)}
              />
            );
          })}
        </Box>
        <Divider sx={{ mb: 1, borderColor: palette.info.main }} />
        <Typography
          variant="bodySSemiBold"
          sx={{
            color: `${palette.secondary}`,
          }}
        >
          {missionListTitle}
        </Typography>
        <List
          disablePadding
          sx={{
            marginBottom: 1.5,
          }}
        >
          {_.map(
            displayedMissions.slice(0, MAX_DISPLAYED_MISSIONS),
            (mission: ActivityNode) => {
              return (
                <ListItemButton
                  key={'displayed-mission-' + mission.id}
                  onClick={() => handleMissionSelect(mission as ActivityNode)}
                  sx={{
                    padding: 0.5,
                    width: 1,
                    height: '30px',
                    marginTop: 1.5,
                  }}
                >
                  {missionNameWithIcon(mission)}
                </ListItemButton>
              );
            }
          )}
        </List>
      </Box>
    </PolyTrombiSearchFilterDropdownMenu>
  );
}
