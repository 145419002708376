import { Stack } from '@mui/material';
import { useFilterContext } from 'components/commons/FilterContext/FilterContextProvider';
import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import ExportActualRevenueButton from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/ExportActualRevenueButton';
import RevenueGlobalTable from 'components/Revenue/RevenueGlobalTable';
import TableContextProvider, {
  TableMode,
} from 'components/Revenue/RevenueGlobalTable/context/TableContextProvider';
import {
  BillingActivityInformationNode,
  EmployeeNode,
  FetchAllActualRevenuesQuery,
  useFetchAllActualRevenuesLazyQuery,
} from 'generated/graphql';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'store';
import { graphQlDateFormatter } from 'utils';

import { ActualRevenueTabHeader } from '.';

export default function ActualRevenueHomePage() {
  const { enqueueSnackbar } = useSnackbar();
  const { currentYear } = useTableHeaderContext();
  const [allRevenues, setAllRevenues] = useState<
    BillingActivityInformationNode[]
  >([]);

  const selectedMissions = useSelector(
    (state) => state.activity.selectedMissions
  );
  const directors = React.useRef<EmployeeNode[]>();
  const [fetchAllActualRevenues, { loading }] =
    useFetchAllActualRevenuesLazyQuery({
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data.allRevenues) {
          updateDirectors(data.allRevenues);
          setAllRevenues(data.allRevenues as BillingActivityInformationNode[]);
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
    });
  function updateDirectors(
    allRevenues: FetchAllActualRevenuesQuery['allRevenues']
  ) {
    if (directors === undefined) {
      return;
    }
    if (JSON.stringify(allRevenues) === JSON.stringify(directors.current)) {
      return;
    }
    directors.current = _.flatMap(allRevenues, (actualActivity) => {
      return actualActivity.activity?.director || [];
    }) as EmployeeNode[];
  }
  useEffect(() => {
    fetchAllActualRevenues({
      variables: {
        year: graphQlDateFormatter(currentYear),
        missionsIds: _.map(selectedMissions, (mission) => mission.id),
      },
    });
  }, [selectedMissions, fetchAllActualRevenues, currentYear]);

  const { filteredID } = useFilterContext();
  const filterMissions = (baiToFilter: BillingActivityInformationNode[]) => {
    const billingMissions = baiToFilter;
    if (filteredID) {
      return billingMissions.filter(
        (billingActivity) =>
          billingActivity.activity?.director?.id === filteredID
      );
    }

    return billingMissions;
  };

  return (
    <React.Fragment>
      <TableContextProvider
        mode={TableMode.ACTUAL}
        billingMissions={filterMissions(allRevenues)}
      >
        <ActualRevenueTabHeader
          directors={directors.current}
          actionComponents={<ExportActualRevenueButton />}
        />
        <Stack mt={2}>
          {loading ? (
            <LoadingPlaceholder />
          ) : (
            <RevenueGlobalTable billingMissions={filterMissions(allRevenues)} />
          )}
        </Stack>
      </TableContextProvider>
    </React.Fragment>
  );
}
