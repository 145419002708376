import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import urlJoin from 'url-join';

import { APP_NAME } from '../DesktopNavbar/DrawerHeader';
import styles from './styles/NavbarMenu.module.scss';
import { NavigationItem } from './utils';

interface NavbarDrawerProps {
  navigationItems: NavigationItem[];
  value: string | boolean;
}

const NavbarMenu = ({
  navigationItems,
  value,
}: React.PropsWithChildren<NavbarDrawerProps>) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen((state) => !state);
  };

  return (
    <Box sx={{ flexGrow: 1, ml: 2 }}>
      <IconButton
        edge="start"
        className={styles.icon}
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer}
        size="large"
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        classes={{ paper: styles.drawer }}
      >
        <Link to={'/'} className={styles.logoContainer}>
          <img
            alt={APP_NAME}
            className={styles.logo}
            src={urlJoin('/', process.env.PUBLIC_URL, 'img', 'logoPongo.svg')}
          />
        </Link>
        <Divider className={styles.divider} variant="middle" />
        <List>
          {navigationItems.map((item) => (
            <ListItem
              button
              key={item.name}
              to={item.to}
              component={Link}
              className={styles.item}
            >
              <ListItemText
                primary={item.name}
                sx={{
                  ...(value &&
                    value === item.to && {
                      fontWeight: 'bold',
                      '&:after': {
                        content: "' '",
                        display: 'block',
                        width: '50%',
                        pt: '5px',
                        borderBottom: '1px solid',
                        color: 'primary.main',
                      },
                    }),
                }}
              />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
};

export default NavbarMenu;
