import { SxProps, Theme, Tooltip } from '@mui/material';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import ProfitabilityDataComingCell from 'components/Reporting/Profitability/GlobalView/ProfitabilityTableBody/Rows/Cells/ProfitabilityDataComingCell';
import {
  ProfitabilityMarginPercentageCell,
  StyledCell,
} from 'components/Reporting/Profitability/utils';
import OutOfRangeTableCell from 'components/Revenue/Tables/RevenueTableCells/OutOfRangeTableCell';
import { BillingPurchaseOrderCurrencyChoices } from 'generated/graphql';
import { Moment } from 'moment';
import moment from 'moment/moment';
import { roundNumber, totalFormat } from 'pages/ActivityPage/utils';
import React from 'react';

interface ProfitabilityEmployeeRenderValuesProps {
  month: Moment;
  totalRevenue: number | undefined;
  totalTime: number | undefined;
  totalCollaboratorTime: number;
  adrCollaborator: number;
  sx?: SxProps<Theme>;
  isWarning?: boolean;
  isExcludingIntern: boolean;
  isEmployeeInternOnPeriod: boolean;
}

export default function ProfitabilityEmployeeRenderValues({
  month,
  totalRevenue,
  totalTime,
  totalCollaboratorTime,
  adrCollaborator,
  sx,
  isWarning,
  isExcludingIntern,
  isEmployeeInternOnPeriod,
}: ProfitabilityEmployeeRenderValuesProps) {
  const { isShowingMarginRate } = useTableHeaderContext();

  if (month.isAfter(moment(), 'month')) {
    return <ProfitabilityDataComingCell />;
  }

  const collaboratorEstimatedCA =
    totalRevenue && totalTime && totalTime !== 0
      ? (totalRevenue * totalCollaboratorTime) / totalTime
      : undefined;
  const collaboratorCost = totalCollaboratorTime * (adrCollaborator || 0);

  if (isExcludingIntern && isEmployeeInternOnPeriod) {
    return (
      <OutOfRangeTableCell
        title={'Le collaborateur est stagiaire sur cette période'}
        colSpan={3}
      />
    );
  }
  return (
    <>
      <StyledCell sx={sx}>
        <Tooltip
          title={`(${roundNumber(totalRevenue || 0)} * ${roundNumber(
            totalCollaboratorTime
          )}) / ${roundNumber(totalTime || 0)}`}
          arrow
        >
          <div>
            {collaboratorEstimatedCA
              ? totalFormat(
                  collaboratorEstimatedCA,
                  BillingPurchaseOrderCurrencyChoices.Eur
                )
              : '-'}
          </div>
        </Tooltip>
      </StyledCell>
      <StyledCell sx={sx}>
        <Tooltip
          title={`${roundNumber(totalCollaboratorTime)} * ${roundNumber(
            adrCollaborator || 0
          )}`}
          arrow
        >
          <div>
            {totalFormat(
              collaboratorCost,
              BillingPurchaseOrderCurrencyChoices.Eur
            )}
          </div>
        </Tooltip>
      </StyledCell>
      <ProfitabilityMarginPercentageCell
        isShowingMarginRate={isShowingMarginRate}
        ca={collaboratorEstimatedCA}
        cost={collaboratorCost}
        sx={isWarning ? { fontSize: '0.8rem' } : sx}
        cellSx={{
          border: '1px solid',
          borderColor: isWarning ? 'orange.main' : 'darkGrey.main',
        }}
      />
    </>
  );
}
