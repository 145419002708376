import { designSystem } from 'theme';

export const stickyHeaderSx = {
  position: 'sticky',
  top: 0,
  zIndex: 1,
  backgroundColor: 'background.paper',
  boxShadow: `0px 7px 5px -2px ${designSystem.palette.background.default}`,
};

export const stickyFooterSx = {
  position: 'sticky',
  top: 0,
  zIndex: 1,
  backgroundColor: 'background.paper',
  boxShadow: `0px 7px 5px -2px ${designSystem.palette.background.default}`,
};

export const tooltipSx = {
  textAlign: 'center',
  maxWidth: '150px',
  fontSize: '15px',
};
