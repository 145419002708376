import { Typography } from '@mui/material';
import { useFilterContext } from 'components/commons/FilterContext/FilterContextProvider';
import { CenteredTableCell } from 'components/commons/Tables/FlatTable';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import CollapsePolyTableCell from 'components/MUIOverload/PolyTableCell/CollapsePolyTableCell';
import ChildCollapsePolyTableRow from 'components/MUIOverload/PolyTableRow/ChildCollapsePolyTableRow';
import TransitionPolyTableRow from 'components/MUIOverload/PolyTableRow/TransitionPolyTableRow';
import MissionRow from 'components/Revenue/Estimated/EstimatedRevenueTables/EstimatedRevenueGlobalTable/RevenueRows/MissionRow';
import ProspectiveRow from 'components/Revenue/Estimated/EstimatedRevenueTables/EstimatedRevenueGlobalTable/RevenueRows/ProspectiveRow';
import { EstimatedClientForYear } from 'components/Revenue/Estimated/EstimatedRevenueTables/EstimatedRevenueGlobalTable/RevenueTableBody';
import styles from 'components/Revenue/Estimated/EstimatedRevenueTables/EstimatedRevenueGlobalTable/styles/TableCell.module.scss';
import {
  estimatedRevenueBackgroundColorSx,
  verifyEmployeePermissions,
} from 'components/Revenue/Estimated/utils';
import OutOfRangeTableCell from 'components/Revenue/Tables/RevenueTableCells/OutOfRangeTableCell';
import { useUserInfo } from 'components/User/UserProvider';
import { BillingPurchaseOrderCurrencyChoices } from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { totalFormat } from 'pages/ActivityPage/utils';
import { POLY_DATE_MONTH } from 'poly-constants';
import React, { useEffect, useState } from 'react';
import { TransitionStatus } from 'react-transition-group/Transition';
import { getMissionLastActiveMonth } from 'utils';

interface PoRowProps {
  status: TransitionStatus;
  estimatedClient: EstimatedClientForYear;
}

const ClientRow = ({ status, estimatedClient }: PoRowProps) => {
  const { client, objective, estimatedTotals, estimatedMissions, dateRange } =
    estimatedClient;

  const { filteredID } = useFilterContext();
  const { displayedMonths } = useTableHeaderContext();
  const { employee } = useUserInfo();

  const [openCollapse, setOpenCollapse] = useState(false);
  const startMonth = displayedMonths[0].clone().startOf('month');
  const endMonth = displayedMonths[displayedMonths.length - 1]
    .clone()
    .endOf('month');

  useEffect(() => {
    const checkIsComexAndChiefOrDirector = () => {
      if (employee?.grade === 'COMEX') {
        return _.some(estimatedMissions, (estimatedMission) => {
          const { isDirector, isChief } = verifyEmployeePermissions(
            estimatedMission.mission,
            employee
          );
          return isDirector || isChief;
        });
      }
      return false;
    };
    setOpenCollapse(!!filteredID || checkIsComexAndChiefOrDirector());
  }, [filteredID, estimatedMissions, employee]);

  return (
    <React.Fragment>
      <ChildCollapsePolyTableRow status={status}>
        <CollapsePolyTableCell
          className={styles.tableCell}
          open={openCollapse}
          setOpen={setOpenCollapse}
        >
          <Typography fontSize="0.875rem">{client.corporateName}</Typography>
        </CollapsePolyTableCell>
        {_.map(displayedMonths, (month) => {
          if (!month.isBetween(dateRange.start, dateRange.end, 'month', '[]')) {
            return (
              <OutOfRangeTableCell
                key={`total-client-${client.id}-${month.format(
                  POLY_DATE_MONTH
                )}`}
                title={'Pas de mission active sur ce mois'}
              />
            );
          }

          const estimatedRevenue = _.find(estimatedTotals, (estimated) =>
            month.isSame(estimated.month, 'month')
          );
          const hasComputedEstimation =
            !!estimatedRevenue?.hasComputedEstimation;
          return (
            <CenteredTableCell
              sx={{
                ...estimatedRevenueBackgroundColorSx,
              }}
              key={`body-${month.format('MMM')}`}
            >
              <Typography
                sx={{
                  fontSize: '0.875rem',
                  color: hasComputedEstimation ? 'gray' : '',
                }}
              >
                {totalFormat(
                  estimatedRevenue?.amount,
                  BillingPurchaseOrderCurrencyChoices.Eur
                )}
              </Typography>
            </CenteredTableCell>
          );
        })}
        <CenteredTableCell>
          <Typography fontWeight={'bold'} fontSize="0.875rem">
            {totalFormat(objective, BillingPurchaseOrderCurrencyChoices.Eur)}
          </Typography>
        </CenteredTableCell>
      </ChildCollapsePolyTableRow>
      <TransitionPolyTableRow open={openCollapse}>
        {(status) => {
          return (
            <React.Fragment>
              {_.map(estimatedMissions, (estimatedMission) => {
                if (
                  moment(estimatedMission.mission.startDate).isBefore(
                    endMonth
                  ) &&
                  getMissionLastActiveMonth(estimatedMission.mission).isAfter(
                    startMonth
                  )
                ) {
                  return (
                    <MissionRow
                      estimatedMission={estimatedMission}
                      status={status}
                      key={`mission-${estimatedMission.mission.id}`}
                    />
                  );
                }
              })}
              <ProspectiveRow client={client} status={status} />
            </React.Fragment>
          );
        }}
      </TransitionPolyTableRow>
    </React.Fragment>
  );
};

export default ClientRow;
