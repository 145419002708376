import { Table, TableCell, TableCellProps, TableProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FlatTable = styled(Table)<TableProps>(() => ({
  tableLayout: 'fixed',
  borderCollapse: 'separate',
  '.MuiTableRow-root': {
    height: '48px',
  },
  '.MuiTableCell-root': {
    height: 'auto',
  },
  '@-moz-document url-prefix()': {
    '.MuiTableCell-root': {
      height: 'auto',
    },
  },
}));

export const BorderedTableCell = styled(TableCell)<TableCellProps>(
  ({ theme }) => ({
    border: `1px solid`,
    borderColor: theme.palette.darkGrey.main,
  })
);

export const CenteredTableCell = styled(TableCell)<TableCellProps>(
  ({ theme }) => ({
    border: `1px solid`,
    borderColor: theme.palette.darkGrey.main,
    whiteSpace: 'nowrap',
    padding: '0px',
    textAlign: 'center',
  })
);
