import { TableBody, TableCell, TableRow } from '@mui/material';
import { useTableContext } from 'contexts/ActivityMonitoringTable/TableContextProvider';
import React from 'react';

import styles from '../../styles/MonitoringTable.module.scss';

export default function NoResultTableBody() {
  const { displayedWeeks } = useTableContext();

  return (
    <TableBody className={styles.tableBody}>
      <TableRow>
        <TableCell
          colSpan={displayedWeeks.length + 3}
          sx={{
            textAlign: 'center',
            border: '1px solid',
            borderTop: 0,
            borderColor: 'darkGrey.main',
          }}
        >
          Pas de résultat
        </TableCell>
      </TableRow>
    </TableBody>
  );
}
