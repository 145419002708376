import { Skeleton, Stack, Typography } from '@mui/material';
import React from 'react';

export default function MissionsSkeleton() {
  return (
    <>
      <Stack sx={{ mt: 0.5 }}>
        <Typography sx={{ mt: 1 }}>
          <Skeleton variant="rounded" animation="wave" width={80} height={20} />
        </Typography>
        <Typography sx={{ mt: 0.75 }}>
          <Skeleton
            variant="rounded"
            animation="wave"
            width={260}
            height={18}
          />
        </Typography>
        <Typography sx={{ mt: 0.75 }}>
          <Skeleton
            variant="rounded"
            animation="wave"
            width={260}
            height={18}
          />
        </Typography>
        <Typography sx={{ mt: 0.75 }}>
          <Skeleton
            variant="rounded"
            animation="wave"
            width={260}
            height={18}
          />
        </Typography>
      </Stack>
      <Stack sx={{ mt: 0.5 }}>
        <Typography sx={{ mt: 1 }}>
          <Skeleton variant="rounded" animation="wave" width={80} height={20} />
        </Typography>
        <Typography sx={{ mt: 0.75 }}>
          <Skeleton
            variant="rounded"
            animation="wave"
            width={260}
            height={18}
          />
        </Typography>
        <Typography sx={{ mt: 0.75 }}>
          <Skeleton
            variant="rounded"
            animation="wave"
            width={260}
            height={18}
          />
        </Typography>
      </Stack>
    </>
  );
}
