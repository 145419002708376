import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import PolyCropper from 'components/commons/PolyCropper/PolyCropper';
import PongoButton from 'components/MUIOverload/PongoButton';
import {
  EmployeeNode,
  useUploadProfilePictureMutation,
} from 'generated/graphql';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { DEFAULT_PROFILE_PICTURE_PATH, IMAGE_EXTENSIONS } from 'poly-constants';
import React, { useEffect, useRef, useState } from 'react';
import { Area } from 'react-easy-crop/types';
import { FormProvider, useForm } from 'react-hook-form';
import { OpacitySuffix } from 'utils';

import UploadProfilePictureForm from './UploadProfilePictureForm';

interface watchFileWithNameOnly {
  name: string;
}

function getIsCorrectUploadedFileFormat(
  watchFile: watchFileWithNameOnly | undefined
) {
  if (!watchFile) {
    return true;
  }

  const fileName: string = watchFile.name;

  const isCorrectFormat = _.some(IMAGE_EXTENSIONS.split(','), (format) => {
    return fileName.endsWith(format);
  });

  return isCorrectFormat;
}

interface UpdatePictureModalProperties {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  image: string | undefined;
  employee?: Partial<EmployeeNode>;
  onUpdate: (filename: string | undefined) => void;
}

export default function UpdatePictureModal({
  open,
  onClose,
  onDelete,
  image,
  employee,
  onUpdate,
}: UpdatePictureModalProperties) {
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm({
    shouldUnregister: true,
  });
  const theme = useTheme();

  const [fileInfos, setFileInfos] = useState<string | ArrayBuffer | null>(null);
  const [cropArea, setCropArea] = useState<Area | undefined>();
  const [isDelete, setIsDelete] = useState<Boolean>(false);
  const watchValue = form.watch('file');
  const watchFile = watchValue?.file?.[0];
  const watchFileName = watchValue?.filename;

  function readFile(file: File) {
    const reader = new FileReader();
    reader.onloadend = () => {
      setFileInfos(reader.result);
    };
    reader.readAsDataURL(file);
  }

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setCropArea(croppedAreaPixels);
  };

  const [uploadAvatar] = useUploadProfilePictureMutation({
    onError: () => {
      // TODO: manage format & size of file
      const message = "Erreur lors de l'enregistrement de votre photo";
      enqueueSnackbar(message, {
        variant: 'error',
        style: { maxWidth: '500px' },
      });
    },
    onCompleted: (data) => {
      form.reset();
      enqueueSnackbar(`Votre photo de profil a été mise à jour`, {
        variant: 'success',
      });
      onClose();
      onUpdate(data.uploadProfilePicture?.employee?.profilePicture?.filename);
    },
  });

  async function submitAvatar(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const variables = {
      variables: {
        picture: watchFile,
        crop: cropArea,
        employeeId: employee?.id || '',
      },
    };
    await uploadAvatar(variables);
  }

  async function deleteAvatar() {
    const variables = {
      variables: {
        employeeId: employee?.id || '',
      },
    };
    await uploadAvatar(variables);
    form.setValue('file.file', undefined);
    form.setValue('file.filename', '');
    setIsDelete(false);
    onDelete();
  }

  const isCorrectUploadedFileFormat = useRef<boolean>(true);
  useEffect(() => {
    if (watchFile) {
      readFile(watchFile);
    }
    isCorrectUploadedFileFormat.current =
      getIsCorrectUploadedFileFormat(watchFile);
  }, [watchFile]);

  const isImageUploaded =
    (watchFile || watchFileName) && isCorrectUploadedFileFormat.current;

  let modalTitle = 'Modifier ma photo de profil';
  if (isImageUploaded) {
    modalTitle = 'Recadrer ma photo';
  }
  if (isDelete) {
    modalTitle = 'Supprimer ma photo de profil';
  }

  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth={false}>
      <DialogContent
        sx={{
          overflowX: 'hidden',
          width: { sm: 'auto', md: 647 },
          backgroundColor: 'modalBackground.main',
          p: 2,
          pt: 0,
        }}
      >
        <Stack
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{ my: 1 }}
        >
          <Stack flexDirection={'row'} alignItems={'center'}>
            <Typography
              variant="h3Bold"
              sx={{ mr: 2 }}
              color="text.secondary"
              component="h3"
            >
              {modalTitle}
            </Typography>
          </Stack>
          <IconButton onClick={() => onClose()} edge={'end'}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <form onSubmit={submitAvatar}>
          <FormProvider {...form}>
            <Paper
              elevation={0}
              sx={{
                p: 1.5,
                borderRadius: '8px',
                backgroundColor: isImageUploaded ? 'black.main' : '',
              }}
            >
              {!isCorrectUploadedFileFormat.current && (
                <Typography color="#D32F2F" sx={{ marginTop: 1 }}>
                  Format d&apos;image invalide !<br />
                  Format acceptés : {IMAGE_EXTENSIONS}
                </Typography>
              )}
              <Stack
                sx={{
                  width: '100%',
                  pt: isDelete ? 4 : 1,
                  pb: isDelete ? 4 : 2,
                }}
                alignItems={'center'}
              >
                {isImageUploaded ? (
                  <PolyCropper
                    image={fileInfos?.toString() || image || ''}
                    onCropComplete={onCropComplete}
                    sx={{
                      position: 'relative',
                      width: '242px',
                      height: '242px',
                    }}
                  />
                ) : (
                  <>
                    {image &&
                      image !== DEFAULT_PROFILE_PICTURE_PATH &&
                      !isDelete && (
                        <Stack
                          sx={{
                            position: 'relative',
                            p: 0.75,
                            borderRadius: '39px',
                            backgroundColor: 'error.main',
                            margin: -2,
                            left: '120px',
                            cursor: 'pointer',
                          }}
                          onClick={() => setIsDelete(true)}
                        >
                          <DeleteIcon
                            sx={{
                              width: '20px',
                              height: '20px',
                              color: 'contrastText.main',
                            }}
                          />
                        </Stack>
                      )}
                    {image === DEFAULT_PROFILE_PICTURE_PATH ? (
                      <Stack
                        sx={{
                          width: '242px',
                          height: '242px',
                          borderRadius: '8px',
                          overflow: 'hidden',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: 'modalBackground.main',
                        }}
                      >
                        <img src={image} alt={'Avatar'} width={'50%'} />
                      </Stack>
                    ) : (
                      <Stack
                        sx={{
                          width: isDelete ? '184px' : '242px',
                          height: isDelete ? '184px' : '242px',
                          borderRadius: '8px',
                          overflow: 'hidden',
                        }}
                      >
                        <img src={image} alt={'Avatar'} />
                      </Stack>
                    )}
                  </>
                )}
              </Stack>
              {!isDelete && (
                <UploadProfilePictureForm
                  formName={'file'}
                  isCropping={isImageUploaded}
                  required
                />
              )}
              {isDelete && (
                <Stack justifyContent={'center'} direction="row">
                  <Typography
                    textAlign={'center'}
                    variant={'bodyBold'}
                    sx={{ width: '377px', height: '66px' }}
                  >
                    Voulez-vous vraiment supprimer votre photo ?<br />
                    Nous la remplacerons par un avatar Pongo par défaut.
                  </Typography>
                </Stack>
              )}
              <Divider
                sx={{
                  my: 3,
                  backgroundColor:
                    theme.palette.text.secondary + OpacitySuffix.FORTY_PERCENT,
                }}
              />
              <Stack
                sx={{
                  width: '100%',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <PongoButton
                  buttonStyle="secondary"
                  variant="contained"
                  sx={{
                    width: '126px',
                    height: '48px',
                    mr: 1.5,
                  }}
                  onClick={() => {
                    if (isDelete) {
                      setIsDelete(false);
                    } else if (watchFile || watchFileName) {
                      form.setValue('file.file', undefined);
                      form.setValue('file.filename', '');
                      form.trigger();
                    } else {
                      onClose();
                    }
                  }}
                >
                  Annuler
                </PongoButton>
                {isDelete ? (
                  <Button
                    variant={'contained'}
                    color={'error'}
                    onClick={deleteAvatar}
                    sx={{
                      width: '144px',
                    }}
                  >
                    Confirmer
                  </Button>
                ) : (
                  <Button
                    sx={{ width: '155px', height: '48px' }}
                    variant={'contained'}
                    disabled={!isImageUploaded}
                    type={'submit'}
                  >
                    Enregistrer
                  </Button>
                )}
              </Stack>
            </Paper>
          </FormProvider>
        </form>
      </DialogContent>
    </Dialog>
  );
}
