import DisplayMission from 'components/commons/DisplayMission';
import DisplayBillPaymentStatus from 'components/MissionFollowUp/OverallListBill/DisplayStatus/DisplayBillPaymentStatus';
import StringCell from 'components/MUIOverload/PolyDataGrid/components/StringCell';
import { PolyDataGridColumn } from 'components/MUIOverload/PolyDataGrid/PolyDataGridHead';
import { useUserInfo } from 'components/User/UserProvider';
import {
  ActivityNode,
  BillingBillPaymentStatusChoices,
  BillNode,
  useAllBillsPaginatedLazyQuery,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { totalFormat } from 'pages/ActivityPage/utils';
import StateSelectionModal from 'pages/MissionFollowUp/PaymentFollowUpPage/StateSelectionModal';
import { POLY_DATE } from 'poly-constants';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'store';
import { setAllBills, setCurrentBill } from 'store/purchaseOrder';

import OverallListBillTable from '../OverallListBillTable';
import styles from '../styles/OverallListBills.module.scss';

function isRowSelectEnabled(element: BillNode) {
  return (
    element.paymentStatus === BillingBillPaymentStatusChoices.Pending ||
    element.paymentStatus === BillingBillPaymentStatusChoices.Recovering
  );
}

interface PaymentFollowUpTableProps {
  pageNbr: number;
  pageSize: number;
  filter: string;
  setFilter: (p: string) => void;
  setNbrBills: (p: number) => void;
  setTotalAmount: (p: number) => void;
}

export default function PaymentFollowUpTable({
  pageNbr,
  pageSize,
  filter,
  setFilter,
  setNbrBills,
  setTotalAmount,
}: PaymentFollowUpTableProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { isControl, isSuperuser } = useUserInfo();
  const groupEdit = useSelector((state) => state.purchaseOrder.groupEdit);
  const statusPaymentFilter = useSelector(
    (state) => state.purchaseOrder.statusPaymentFilter
  );
  const directorFilter = useSelector((state) => state.users.directorFilter);
  const creatorFilter = useSelector((state) => state.users.creatorFilter);
  const billNumberFilter = useSelector(
    (state) => state.purchaseOrder.billNumberFilter
  );
  const currentMission = useSelector((state) => state.activity.currentMission);
  const selectedMissions = useSelector(
    (state) => state.activity.selectedMissions as ActivityNode[]
  );
  const missionIds = currentMission?.id
    ? [currentMission.id]
    : _.map(selectedMissions, (mission) => mission.id);
  const dispatch = useDispatch();

  const [refreshAllBills] = useAllBillsPaginatedLazyQuery({
    onCompleted: (data) => {
      dispatch(setAllBills(data.allBillsPaginated?.bills as BillNode[]));
      setNbrBills(data.allBillsPaginated?.billsNumber || 0);
      setTotalAmount(data.allBillsPaginated?.totalAmount || 0);
    },
    onError: () => {
      enqueueSnackbar(`Erreur lors de la récupération des données`, {
        variant: 'error',
      });
    },
  });

  const columns: PolyDataGridColumn<DeepExtractTypeSkipArrays<BillNode, []>>[] =
    [
      {
        headerName: '',
        isSortable: true,
        propertyName: 'paymentStatus',
        width: '25px',
        render: (data) =>
          DisplayBillPaymentStatus({
            status: data.paymentStatus,
            iconOnly: true,
          }),
      },
      {
        headerName: 'Facture N°',
        propertyName: 'billNumber',
        isSortable: true,
        width: '80px',
        render: (data) => StringCell({ data: data?.billNumber || '' }),
      },
      {
        headerName: 'Libellé facture',
        propertyName: 'title',
        isSortable: true,
        width: '170px',
        render: (data) =>
          StringCell({
            data: data.title,
          }),
      },
      {
        headerName: 'Mission',
        propertyName: 'purchaseOrder.billingActivityInformation.activity.name',
        isSortable: true,
        width: '170px',
        render: (data) =>
          DisplayMission({
            mission: data.purchaseOrder.billingActivityInformation
              .activity as ActivityNode,
          }),
      },
      {
        headerName: 'Client à facturer',
        propertyName:
          'purchaseOrder.billingActivityInformation.billingClient.corporateName',
        isSortable: true,
        width: '150px',
        render: (data) =>
          StringCell({
            data:
              data.purchaseOrder?.billingActivityInformation?.billingClient
                ?.corporateName || '',
          }),
      },
      {
        headerName: 'Facturée le',
        propertyName: 'billingDate',
        isSortable: true,
        width: '90px',
        render: (data) =>
          StringCell({
            data: moment(data.billingDate).format(POLY_DATE),
          }),
      },
      {
        headerName: "Date d'échéance",
        propertyName: 'dueDate',
        isSortable: true,
        width: '120px',
        render: (data) =>
          StringCell({ data: moment(data.dueDate).format(POLY_DATE) }),
      },
      {
        headerName: 'Montant (HT)',
        propertyName: 'total',
        isSortable: true,
        width: '100px',
        render: (data) =>
          StringCell({
            data: totalFormat(data.total, data.purchaseOrder.currency),
          }),
      },
    ];

  function redirectToDetailedView(bill: BillNode) {
    if (
      bill.paymentStatus !== BillingBillPaymentStatusChoices.Paid &&
      (isControl || isSuperuser)
    ) {
      dispatch(setCurrentBill(bill));
      setModalOpen(!modalOpen);
    } else {
      history.push({
        pathname: `/mission-followup/missions/details/bill`,
        search: `?po_id=${bill.purchaseOrder.id}&mission_id=${bill.purchaseOrder.billingActivityInformation.activity?.id}&bill_id=${bill.id}`,
      });
    }
  }

  function closeModal() {
    setModalOpen(!modalOpen);
  }

  function onSuccess() {
    refreshAllBills({
      variables: {
        // no need to filter by status, isPayment option applies the filter in the back
        isPayment: true,
        paymentStatus: statusPaymentFilter,
        activityIds: missionIds,
        billNumber: billNumberFilter,
        directorId: directorFilter?.id,
        creatorId: creatorFilter?.id,
        orderBy: filter ? [filter] : ['-billing_date'],
        pageNbr: pageNbr,
        pageSize: pageSize,
      },
    });
  }

  return (
    <div
      className={
        groupEdit ? styles.paiementTableSelectable : styles.paiementTable
      }
    >
      <OverallListBillTable
        isRowSelectEnabled={isRowSelectEnabled}
        columns={columns}
        onClickCallback={redirectToDetailedView}
        isPayment={true}
        setFilter={setFilter}
      />
      <StateSelectionModal
        open={modalOpen}
        onClose={closeModal}
        onSuccess={onSuccess}
      />
    </div>
  );
}
