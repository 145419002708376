/* eslint-disable @typescript-eslint/no-unused-vars */
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import { Box, Grid, Paper, Typography, useTheme } from '@mui/material';
import EmployeeSeniority from 'components/commons/EmployeeSeniority/EmployeeSeniority';
import {
  AvatarDisplayMode,
  AvatarDisplaySize,
} from 'components/Phonebook/utils';
import {
  ActivityNode,
  AllActivitiesForUserQuery,
  AllEmployeeTrophiesQuery,
  EmployeeNode,
  EmployeesEmployeeGradeChoices,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

import EmployeeAvatarRenderer from '../EmployeeAvatarRenderer/EmployeeAvatarRenderer';
import { getEmployeeOccupationInfo } from '../EmployeeOccupationIndicator/EmployeeOccupationIndicator';
import { GradeChip } from '../GradeChip';
import MissionsList from '../Missions/MissionsList';
import NoTrophy from '../Trophies/NoTrophy';
import TrophiesSkeleton from '../Trophies/TrophiesSkeleton';
import TrophyPictureDisplayer from '../Trophies/TrophyPictureDisplayer';

interface EmployeeProfilePageProps {
  employee: EmployeeNode;
  trophiesData: AllEmployeeTrophiesQuery | undefined;
  userActivities: AllActivitiesForUserQuery | undefined;
  onGoBack: () => void;
}

const AVATAR_SIZE = AvatarDisplaySize.MOBILEDRAWER;
const ELEMENTS_GAP = '16px';
const PADDING_LEFT = 37 / 8;
const PADDING_Y_AXIS = 1.5;
const GRADE_COVER_HEIGHT = '70px';
const TOP_BORDER_RADIUS = '24px';

const gradeCoverColor = (grade: EmployeesEmployeeGradeChoices) => {
  switch (grade) {
    case EmployeesEmployeeGradeChoices.Coman:
      return 'comanBlue.main';
    case EmployeesEmployeeGradeChoices.Comex:
      return 'comexOrange.main';
    default:
      return 'oxfordBlue.main';
  }
};

export default function EmployeeProfileMobile({
  trophiesData,
  userActivities,
  employee,
}: EmployeeProfilePageProps) {
  const theme = useTheme();

  return (
    <>
      <Puller grade={employee.grade} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: ELEMENTS_GAP,
          backgroundColor: 'lightGrey.main',
          overflow: 'auto',
        }}
      >
        <Paper
          sx={{
            width: '100%',
          }}
          elevation={0}
        >
          <GradeCover employee={employee} />
          <EmployeeOccupationInfo employee={employee} />
        </Paper>
        <EmployeeContactInfo employee={employee} />
        <MissionsList
          sx={{
            backgroundColor: theme.palette.background.paper,
            py: PADDING_Y_AXIS,
            pl: PADDING_LEFT,
          }}
          activities={userActivities?.allActivitiesForUser as ActivityNode[]}
        />
        <EmployeeTrophies trophiesData={trophiesData} />
      </Box>
    </>
  );
}

function Puller({ grade }: { grade: EmployeesEmployeeGradeChoices }) {
  return (
    <Box
      sx={{
        borderTopLeftRadius: TOP_BORDER_RADIUS,
        borderTopRightRadius: TOP_BORDER_RADIUS,
        backgroundColor: gradeCoverColor(grade),
        zIndex: 1,
        width: '100%',
        height: 46,
        minHeight: 46,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: 66,
          height: 4,
          backgroundColor: 'white',
          borderRadius: 20,
          position: 'absolute',
          top: 20,
        }}
      />
    </Box>
  );
}

function GradeCover({ employee }: { employee: EmployeeNode }) {
  const theme = useTheme();

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: gradeCoverColor(employee.grade),
          height: GRADE_COVER_HEIGHT,
          mb: GRADE_COVER_HEIGHT,
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          {
            <EmployeeAvatarRenderer
              employee={employee}
              parameters={{
                mode: AvatarDisplayMode.PHONEBOOK,
                size: AVATAR_SIZE,
                backgroundColor: theme.palette.grey[100],
              }}
            />
          }
        </Box>
      </Box>
    </Box>
  );
}

function EmployeeOccupationInfo({ employee }: { employee: EmployeeNode }) {
  return (
    <Box
      sx={{
        p: 1.5,
        pb: 3.8,
        paddingLeft: PADDING_LEFT,
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5,
      }}
    >
      <GradeChip employee={employee} displayAllGrades showLabel />
      <Box>
        <Typography variant={'h2Bold'} component="h2">
          {employee.firstName}
        </Typography>
        <Typography variant={'h2Bold'} component="h2">
          {employee.lastName}
        </Typography>
      </Box>
      <Typography variant={'bodySemiBold'}>
        {getEmployeeOccupationInfo(employee.occupation, false).occupationName}
      </Typography>
      {!!employee.absenceReturnDate && (
        <Box
          sx={{
            display: 'flex',
            backgroundColor: '#0006',
            width: 'fit-content',
            py: 0.25,
            px: 1,
            borderRadius: 0.75,
          }}
        >
          <Typography variant={'bodyBold'} color={'contrastText.main'}>
            {`Retour le ${moment(employee.absenceReturnDate).format(
              'DD/MM/YY'
            )}`}
          </Typography>
        </Box>
      )}
      <EmployeeSeniority employee={employee} isPhone />
    </Box>
  );
}

function EmployeeContactInfo({ employee }: { employee: EmployeeNode }) {
  const showInfoLabel = employee.email.length < 31;
  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        py: PADDING_Y_AXIS,
        px: PADDING_LEFT,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Typography variant={'h3Bold'} component="h3">
        Informations
      </Typography>
      {!!employee.phoneNumber && (
        <EmployeeContactInfoItem
          icon={<PhoneIcon sx={{ height: '20px' }} />}
          label={showInfoLabel ? 'Téléphone' : ''}
          value={employee.phoneNumber}
        />
      )}
      <EmployeeContactInfoItem
        icon={<MailOutlineIcon sx={{ height: '20px' }} />}
        label={showInfoLabel ? 'Mail' : ''}
        value={employee.email}
      />
    </Box>
  );
}

function EmployeeContactInfoItem({
  icon,
  label,
  value,
}: {
  icon: React.ReactNode;
  label: string;
  value: string;
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        mt: 0.25,
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: 'text.secondary',
        }}
      >
        {icon}
        <Typography variant="body2">{label}</Typography>
      </Box>
      <Typography noWrap variant="bodySSemiBold" color={'text.primary'}>
        {value}
      </Typography>
    </Box>
  );
}

function EmployeeTrophies({
  trophiesData,
}: {
  trophiesData: AllEmployeeTrophiesQuery | undefined;
}) {
  return (
    <Box
      key={'trophies'}
      sx={{
        backgroundColor: 'background.paper',
        py: PADDING_Y_AXIS,
        px: PADDING_LEFT,
      }}
    >
      <Typography variant={'bodyBold'}>Trophées</Typography>
      {trophiesData?.employee?.trophies &&
      trophiesData?.employee?.trophies.length === 0 ? (
        <NoTrophy />
      ) : (
        <Grid
          container
          sx={{
            pt: 3,
            display: 'grid',
            justifyContent: 'space-between',
            gridColumnGap: '15px',
            gridTemplateColumns: 'repeat(auto-fill, minmax(50px, 50px))',
          }}
          xs={12}
          rowSpacing={3}
        >
          {!trophiesData?.employee?.trophies && (
            <TrophiesSkeleton xs={3} skeletonNumber={12} />
          )}
          {_.map(trophiesData?.employee?.trophies, (trophy) => {
            return (
              <Grid item key={trophy.id} xs={3}>
                <TrophyPictureDisplayer
                  filename={trophy?.trophyPicture?.filename}
                  name={trophy?.name}
                  size={50}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
    </Box>
  );
}
