import FormSystem from 'components/commons/FormSystem';
import PageWrapper from 'components/commons/PageWrapper';
import ExceptionalActivitiesStatus from 'components/MUIOverload/PolyDataGrid/components/ExceptionalActivitiesStatus/ExceptionalActivitiesStatus';
import {
  useUpdateOvertimeRequestMutation,
  useValidateOvertimeRequestMutation,
} from 'generated/graphql';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { POLY_DATE } from 'poly-constants';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { displayEmployee, graphQlDateFormatter } from 'utils';

import { RequestLookupState } from '../ExceptionalActivitiesRequest/ExceptionalActivitiesRequestPanelManager';
import ArrowBack from './ArrowBack';
import PanelSummary from './PanelSummary';
import RequestLookupContent from './RequestLookupContent';
import styles from './styles/lookup.module.scss';

interface FormType {
  workedDuration: number;
  collaboratorComment: string;
  limitDate: Date;
  reason: string;
}

const RequestLookup = () => {
  const history = useHistory();
  const [updateOvertimeRequest] = useUpdateOvertimeRequestMutation();
  const [validateOvertimeRequest] = useValidateOvertimeRequestMutation();
  const castedState = history.location.state as RequestLookupState;
  const queryData = castedState.query;
  const { enqueueSnackbar } = useSnackbar();
  const employee = queryData.authorization.employee;
  const employeeName = displayEmployee(employee.firstName, employee.lastName);
  const [action, setAction] = useState('save');

  const defaultFormValues: FormType = {
    workedDuration: queryData.duration,
    limitDate: queryData.expireAt,
    collaboratorComment: queryData.collaboratorComment
      ? queryData.collaboratorComment
      : '',
    reason: queryData.managerResponse ? queryData.managerResponse : '',
  };

  const handleSubmit = async (data: FormType) => {
    try {
      await (action === 'save'
        ? updateOvertimeRequest({
            variables: {
              objectId: queryData.id,
              duration: data.workedDuration,
              expireAt: castedState.asManager
                ? graphQlDateFormatter(data.limitDate)
                : queryData.expireAt,
              collaboratorComment: castedState.asManager
                ? defaultFormValues.collaboratorComment
                : data.collaboratorComment,
              asManager: castedState.asManager,
            },
          })
        : validateOvertimeRequest({
            variables: {
              objectId: queryData.id,
              managerResponse: data.reason,
              validate: action === 'validate',
            },
          }));
      enqueueSnackbar('Données enregistrées', {
        variant: 'success',
      });
      history.goBack();
    } catch {
      enqueueSnackbar('Erreur lors de la modification', {
        variant: 'error',
      });
    }
  };

  const onClickButton = (action: string) => {
    setAction(action);
  };

  return (
    <PageWrapper large>
      <FormSystem
        onSubmit={handleSubmit}
        useFormArgs={{ defaultValues: defaultFormValues }}
      >
        <ArrowBack />
        <h1 className={styles.title}>
          {`Demande de ${employeeName} du ${moment(queryData.createdAt).format(
            POLY_DATE
          )}`}
        </h1>
        <div>
          <div className={styles.statusContainer}>
            {ExceptionalActivitiesStatus(true, queryData.status)}
          </div>
          {queryData.status === 'Approved' && (
            <div
              className={styles.daysRemainingContainer}
            >{`${queryData.recoveryRemaining} jours restants`}</div>
          )}
        </div>
        {queryData.status !== 'Requested' && <PanelSummary data={queryData} />}
        <RequestLookupContent
          queryData={queryData}
          action={castedState.action}
          asManager={castedState.asManager}
          handleOnClick={onClickButton}
        />
      </FormSystem>
    </PageWrapper>
  );
};

export default RequestLookup;
