import {
  ApolloClient,
  ApolloLink,
  defaultDataIdFromObject,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import result from 'generated/graphql';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      // eslint-disable-next-line no-console
      console.error(
        '[GraphQL error]: Message:',
        message,
        ', Location:',
        locations,
        ', Path:',
        path
      );
    });
  }
  if (networkError) {
    // eslint-disable-next-line no-console
    console.error(`[Network error]: ${networkError}`);
  }
});

export const buildApolloClient = (
  fetch: WindowOrWorkerGlobalScope['fetch']
) => {
  const authLink = createUploadLink({
    uri: process.env.REACT_APP_GRAPHQL_URL || '/api/graphql/',
    credentials: 'same-origin',
    fetch,
  });

  return new ApolloClient({
    link: ApolloLink.from([errorLink, authLink]),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
      },
    },
    cache: new InMemoryCache({
      possibleTypes: result.possibleTypes,
      dataIdFromObject: (object) => {
        const test = [
          'DomainConfiguration',
          'AuthenticationType',
          'GoogleType',
        ];
        return test.some((typeName) => object.__typename?.startsWith(typeName))
          ? object.__typename
          : defaultDataIdFromObject(object);
      },
    }),
  });
};
