import EditIcon from '@mui/icons-material/Edit';
import UploadIcon from '@mui/icons-material/Upload';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import UpdatePictureModal from 'components/Phonebook/EmployeeCard/EmployeeAvatarRenderer/UpdatePictureModal/UpdatePictureModal';
import EmployeeAvatarSkeleton from 'components/Phonebook/PhonebookFinder/Skeleton/EmployeeAvatarSkeleton';
import {
  AvatarDisplayMode,
  AvatarDisplaySize,
  mapToAvatarSize,
} from 'components/Phonebook/utils';
import { useUserInfo } from 'components/User/UserProvider';
import { EmployeeNode } from 'generated/graphql';
import { useImageRequest } from 'hooks/useImageRequest';
import moment from 'moment';
import { DEFAULT_PROFILE_PICTURE_PATH } from 'poly-constants';
import React, { useState } from 'react';

const DEFAULT_AVATAR_DISPLAY_PARAMETERS: AvatarDisplayParameters = {
  size: AvatarDisplaySize.STANDARD,
  source: DEFAULT_PROFILE_PICTURE_PATH,
  backgroundColor: 'white',
};

const DEFAULT_AVATAR_STYLE = {
  // Polypongo
  width: '50%',
  height: '50%',
};

const EMPLOYEE_PICTURE_STYLE = {
  // stretch image to full width
  width: '100%',
};

type AvatarDisplayParameters = {
  mode?: AvatarDisplayMode;
  size?: AvatarDisplaySize;
  source?: string;
  backgroundColor: string;
};

interface EmployeeAvatarRendererProps {
  employee?: EmployeeNode;
  parameters: AvatarDisplayParameters;
}

const getImage = (
  imageReceived: string | undefined,
  imageError: Error | undefined,
  employee: EmployeeNode | undefined
) => {
  if (!employee?.profilePicture?.filename || imageError) {
    return DEFAULT_PROFILE_PICTURE_PATH;
  } else if (imageReceived) {
    return imageReceived;
  }
};

export default function EmployeeAvatarRenderer({
  employee,
  parameters = DEFAULT_AVATAR_DISPLAY_PARAMETERS,
}: EmployeeAvatarRendererProps) {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));
  const [editAvatarVisibility, setEditAvatarVisibility] =
    useState<boolean>(false);
  // The default avatar is a penguin: polypongo.
  // It is displayed if the employee picture can't be or doesn't exist
  const { employee: user, isHR, refetch } = useUserInfo();

  const {
    response: imageReceived,
    error: imageError,
    loading,
    refetch: refetchImage,
  } = useImageRequest(employee?.profilePicture?.filename);

  const image = React.useRef(getImage(imageReceived, imageError, employee));

  const handleClickOnAvatar: () => void = () => {
    if (isEditable) {
      setEditAvatarVisibility(true);
    }
  };

  const isSelf = user?.id !== undefined && user.id === employee?.id;
  const canUserEdit =
    isSelf &&
    (parameters.mode === AvatarDisplayMode.PROFILE ||
      parameters.mode === AvatarDisplayMode.INITIALIZATION);
  const canHREdit = isHR && parameters.mode !== AvatarDisplayMode.NONE;
  const isEditable = canUserEdit || canHREdit;

  React.useEffect(() => {
    image.current = getImage(imageReceived, imageError, employee);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageReceived, loading, imageError]);

  return (
    <>
      {!image.current &&
      (parameters.mode === AvatarDisplayMode.NONE ||
        parameters.mode === AvatarDisplayMode.PHONEBOOK) ? (
        <EmployeeAvatarSkeleton size={parameters.size} />
      ) : (
        <>
          <Box
            sx={{
              overflow: 'hidden',
              borderRadius: '8px',
              textAlign: 'center',
              position: 'relative',
              flexShrink: 0,
              width: mapToAvatarSize(parameters.size),
              aspectRatio: '1 / 1',
              '&:hover': isEditable
                ? { backgroundColor: theme.palette.text.secondary }
                : {},
              backgroundColor: parameters.backgroundColor,
              top: '0px',
              left: '0px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={isEditable ? handleClickOnAvatar : undefined}
          >
            {parameters.mode === AvatarDisplayMode.NONE &&
              employee?.absenceReturnDate && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                  }}
                >
                  <Stack
                    sx={{
                      position: 'absolute',
                      top: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '26px',
                      width: { xs: '100%', sm: '162px' },
                      borderRadius: {
                        xs: '8px 8px 0px 0px',
                        sm: '0px 0px 5px 0px',
                      },
                      backgroundColor: theme.palette.primary.darker,
                    }}
                  >
                    <Typography
                      variant={isPhone ? 'label' : 'bodyBold'}
                      color={theme.palette.contrastText.main}
                    >
                      {`Retour le ${moment(employee.absenceReturnDate).format(
                        'DD/MM/YY'
                      )}`}
                    </Typography>
                  </Stack>
                </Box>
              )}
            <Box
              component="img"
              sx={
                image.current === DEFAULT_PROFILE_PICTURE_PATH
                  ? DEFAULT_AVATAR_STYLE
                  : EMPLOYEE_PICTURE_STYLE
              }
              src={image.current}
              alt={`Avatar de ${employee?.firstName} ${employee?.lastName}`}
            />
            {isEditable && (
              <Box
                sx={{
                  position: 'absolute',
                  transition: '0.1s ease',
                  backgroundColor: 'primary.main',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  color: 'white',
                  opacity:
                    parameters.mode === AvatarDisplayMode.INITIALIZATION
                      ? 0.6
                      : 0,
                  '&:hover': { opacity: 0.6 },
                }}
              >
                {employee?.profilePicture?.filename ? (
                  <EditIcon />
                ) : (
                  <UploadIcon />
                )}
              </Box>
            )}
          </Box>
          <UpdatePictureModal
            open={editAvatarVisibility}
            onDelete={() => {
              refetch();
              image.current = DEFAULT_PROFILE_PICTURE_PATH;
            }}
            onClose={() => {
              setEditAvatarVisibility(false);
            }}
            image={image.current}
            employee={employee}
            // use ImageRequest to update the image
            onUpdate={(filepath) => {
              refetch();
              if (filepath) {
                setTimeout(() => {
                  refetchImage(filepath);
                }, 200);
              }
            }}
          />
        </>
      )}
    </>
  );
}
