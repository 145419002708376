import FlagIcon from '@mui/icons-material/Flag';
import Step from '@mui/material/Step';
import PolyStepLabel from 'components/MUIOverload/PolyStepLabel';
import PolyStepper from 'components/MUIOverload/PolyStepper';
import React from 'react';

interface CustomStepperProps {
  activeStep: number;
  steps: string[];
}

const CustomStepper = (properties: CustomStepperProps) => {
  return (
    <PolyStepper
      activeStep={properties.activeStep}
      sx={{
        color: 'contrastText.main',
        '& .Mui-active': {
          color: 'contrastText.main',
          borderColor: 'primary.main',
          fontWeight: 'bold',
        },
        '& .Mui-active > .MuiStepConnector-line': {
          borderColor: 'primary.main',
        },
        '& .Mui-completed > .MuiStepConnector-line': {
          borderColor: 'primary.main',
        },
        '& MuiStepLabel-label > .Mui-active': {
          color: 'contrastText.main',
          fontWeight: 'bold',
        },
        '& .Mui-completed': {
          color: 'primary.light',
          borderColor: 'primary.main',
        },
        '& .PolyStepConnector_active': {
          borderColor: 'primary.main',
        },
      }}
    >
      <Step key={0}>
        <PolyStepLabel>{properties.steps[0]}</PolyStepLabel>
      </Step>

      <Step key={1}>
        <PolyStepLabel>{properties.steps[1]}</PolyStepLabel>
      </Step>

      <Step key={2}>
        <PolyStepLabel>{properties.steps[2]}</PolyStepLabel>
      </Step>

      <Step key={3}>
        <PolyStepLabel>{properties.steps[3]}</PolyStepLabel>
      </Step>

      <Step key={4}>
        <PolyStepLabel icon={<FlagIcon />}>{properties.steps[4]}</PolyStepLabel>
      </Step>
    </PolyStepper>
  );
};

export default CustomStepper;
