// create componenet bootstrap
import InfoIcon from '@mui/icons-material/InfoOutlined';
import LinkIcon from '@mui/icons-material/Link';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Link, Tooltip, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import PongoButton from 'components/MUIOverload/PongoButton';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { addMissionIdToPath } from 'utils';

import { BillSectionTitle } from './BillSectionTitle';

interface CollaboratorsFieldsTitleProps {
  refreshFunction: () => void;
}

export const CollaboratorsFieldsTitle = ({
  refreshFunction,
}: CollaboratorsFieldsTitleProps) => {
  const tooltipContent =
    'Seuls les jours validés pour le mois sélectionné apparaîtront pour le collaborateur en question';

  return (
    <BillSectionTitle
      Title={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'row',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            Collaborateurs associés à la facture{' '}
            <Tooltip title={tooltipContent} placement={'right'}>
              <InfoIcon sx={{ marginLeft: '3px', fontSize: '1.5rem' }} />
            </Tooltip>
          </Box>

          <PongoButton
            buttonStyle="secondary"
            variant="contained"
            sx={{ height: '1.5rem', padding: '0', ml: 2 }}
            startIcon={<LinkIcon sx={{ marginLeft: '8px' }} />}
          >
            <Link
              href={addMissionIdToPath(
                `/mission-followup/activity/validate_activity_monitoring`,
                useLocation().search
              )}
              target={'_blank'}
            >
              <Typography
                fontSize={'0.6rem'}
                color={'primary'}
                marginRight={'8px'}
              >
                Validation suivi régie
              </Typography>
            </Link>
          </PongoButton>
        </Box>
      }
      refreshFunctionComponent={
        <IconButton
          onClick={refreshFunction}
          color="primary"
          aria-label="Recharger la liste des collaborateurs"
          size="medium"
          sx={{ borderRadius: '1rem' }}
        >
          <RefreshIcon />
          <Box sx={{ fontSize: '1rem' }}>Actualiser</Box>
        </IconButton>
      }
    />
  );
};
