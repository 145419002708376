import DisplayMission from 'components/commons/DisplayMission';
import DisplayBillStatus from 'components/MissionFollowUp/OverallListBill/DisplayStatus/DisplayBillStatus';
import styles from 'components/MissionFollowUp/OverallListBill/styles/OverallListBills.module.scss';
import StringCell from 'components/MUIOverload/PolyDataGrid/components/StringCell';
import { PolyDataGridColumn } from 'components/MUIOverload/PolyDataGrid/PolyDataGridHead';
import {
  ActivityNode,
  BillingBillStatusChoices,
  BillNode,
} from 'generated/graphql';
import moment from 'moment';
import { totalFormat } from 'pages/ActivityPage/utils';
import { POLY_DATE } from 'poly-constants';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'store';
import { setCurrentBill } from 'store/purchaseOrder';
import { displayEmployee } from 'utils';

import OverallListBillTable from '../OverallListBillTable';

function isRowSelectEnabled(element: BillNode) {
  return element.status === BillingBillStatusChoices.Draft;
}

interface ManagerBillsTableProps {
  setFilter: (p: string) => void;
}

const ManagerBillsTable = ({ setFilter }: ManagerBillsTableProps) => {
  const groupEdit = useSelector((state) => state.purchaseOrder.groupEdit);
  const dispatch = useDispatch();
  const history = useHistory();

  const columns: PolyDataGridColumn<DeepExtractTypeSkipArrays<BillNode, []>>[] =
    [
      {
        headerName: '',
        isSortable: true,
        propertyName: 'status',
        width: '30px',
        render: (data) =>
          DisplayBillStatus({ status: data.status, iconOnly: true }),
      },
      {
        headerName: 'Libellé',
        propertyName: 'title',
        isSortable: true,
        width: '120px',
        render: (data) =>
          StringCell({
            data: data.title,
          }),
      },
      {
        headerName: 'Mission',
        propertyName: 'purchaseOrder.billingActivityInformation.activity.name',
        isSortable: true,
        width: '160px',
        render: (data) =>
          DisplayMission({
            mission: data.purchaseOrder.billingActivityInformation
              .activity as ActivityNode,
          }),
      },
      {
        headerName: 'Client métier',
        propertyName:
          'purchaseOrder.billingActivityInformation.activity.client',
        isSortable: true,
        width: '145px',
        render: (data) =>
          StringCell({
            data:
              data.purchaseOrder.billingActivityInformation.activity?.client ||
              '',
          }),
      },
      {
        headerName: 'Client à facturer',
        propertyName:
          'purchaseOrder.billingActivityInformation.billingClient.corporateName',
        isSortable: true,
        width: '145px',
        render: (data) =>
          StringCell({
            data:
              data.purchaseOrder.billingActivityInformation.billingClient
                ?.corporateName || '',
          }),
      },
      {
        headerName: 'Créateur de la facture',
        propertyName: 'creator.lastName',
        isSortable: true,
        width: '145px',
        render: (data) =>
          StringCell({
            data: displayEmployee(
              data.creator?.firstName,
              data.creator?.lastName
            ),
          }),
      },
      {
        headerName: 'À facturer le',
        propertyName: 'date',
        isSortable: true,
        width: '90px',
        render: (data) =>
          StringCell({ data: moment(data.date).format(POLY_DATE) }),
      },
      {
        headerName: 'Montant (HT)',
        propertyName: 'total',
        isSortable: true,
        width: '100px',
        render: (data) =>
          StringCell({
            data: totalFormat(data.total, data.purchaseOrder.currency),
          }),
      },
    ];

  function redirectToDetailedView(bill: BillNode) {
    dispatch(setCurrentBill(bill));
    history.push({
      pathname: `/mission-followup/missions/details/bill`,
      search: `?po_id=${bill.purchaseOrder.id}&mission_id=${bill.purchaseOrder.billingActivityInformation.activity?.id}&bill_id=${bill.id}`,
    });
  }

  return (
    <div
      className={
        groupEdit ? styles.controlBillTableSelectOn : styles.controlBillTable
      }
    >
      <OverallListBillTable
        columns={columns}
        onClickCallback={redirectToDetailedView}
        isRowSelectEnabled={isRowSelectEnabled}
        setFilter={setFilter}
      />
    </div>
  );
};

export default ManagerBillsTable;
