import {
  DateFormatMonthRange,
  RangeMonthType,
} from 'components/commons/MonthPicker/MonthPickerTypes';
import _ from 'lodash';
import moment from 'moment';
import { graphQlDateFormatter } from 'utils';

export function getRangeFromDate(min: Date, max: Date) {
  const range = {
    min: {
      month: moment(min).month() + 1,
      year: moment(min).year(),
    },
    max: {
      month: moment(max).month() + 1,
      year: moment(max).year(),
    },
  };
  return range;
}

export function getMonthYearDate(from: Date, to: Date) {
  return {
    from: {
      month: moment(from).month() + 1,
      year: moment(from).year(),
    },
    to: {
      month: moment(to).month() + 1,
      year: moment(to).year(),
    },
  };
}

function formatMonth(month: number) {
  return `${month < 10 ? '0' : ''}${month}`;
}

export function getFormatMonthYearValue(monthYear: RangeMonthType) {
  return _.isEqual(monthYear.from, monthYear.to)
    ? `${formatMonth(monthYear.from.month)}/${monthYear.from.year}`
    : `${formatMonth(monthYear.from.month)}/${
        monthYear.from.year
      } à ${formatMonth(monthYear.to.month)}/${monthYear.to.year}`;
}

export function getDateFormat(monthYear: RangeMonthType): DateFormatMonthRange {
  const from = moment(
    `${monthYear.from.month.toString(10)}-${monthYear.from.year.toString(10)}`,
    'MM-YYYY'
  );
  const to = moment(
    `${monthYear.to.month.toString(10)}-${monthYear.to.year.toString(10)}`,
    'MM-YYYY'
  );
  return {
    from: graphQlDateFormatter(from),
    to: graphQlDateFormatter(to),
  };
}
