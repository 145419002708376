import { Box } from '@mui/material';
import React, { PropsWithChildren } from 'react';

interface HomeGridProps {
  className?: string;
}

const HomeGrid = ({ children }: PropsWithChildren<HomeGridProps>) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'column',
        gap: 3.75,
        p: 2,
        flex: '1 0',
      }}
    >
      {children}
    </Box>
  );
};

export default HomeGrid;
