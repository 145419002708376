import { Avatar, AvatarProps, Box } from '@mui/material';
import { EmployeeNode } from 'generated/graphql';
import { useImageRequest } from 'hooks/useImageRequest';
import React from 'react';

interface AvatarInitialProps {
  employee?: EmployeeNode;
  imageSrc?: string;
}

const getImage = (
  imageReceived: string | undefined,
  imageError: Error | undefined,
  employee: EmployeeNode | undefined
) => {
  if (!employee?.profilePicture?.filename || imageError) {
    return 'none';
  } else if (imageReceived) {
    return imageReceived;
  }
};

export default function AvatarInitial({
  className,
  employee,
  imageSrc,
  ...avatarProperties
}: AvatarInitialProps & AvatarProps) {
  const { sx, ...remainingProps } = avatarProperties;

  const { response: imageReceived, error: imageError } = useImageRequest(
    employee?.profilePicture?.filename
  );

  const image = React.useRef(getImage(imageReceived, imageError, employee));
  image.current = getImage(imageReceived, imageError, employee);

  return (
    <Box sx={{ position: 'relative', mr: 1 }}>
      <Avatar
        src={imageSrc || image.current}
        sx={{
          backgroundColor: 'white',
          height: '40px',
          width: '40px',
          fontSize: '18px',
          border: '1px solid',
          color: 'text.secondary',
          borderColor: 'text.secondary',
          pt: '1px',
          '.MuiAvatar-img': {
            height: 'auto',
          },
          ...sx,
        }}
        className={className}
        {...remainingProps}
      >
        {employee?.firstName[0].concat(employee?.lastName[0])?.toUpperCase()}
      </Avatar>
    </Box>
  );
}
