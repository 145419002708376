import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { SxProps } from '@mui/system';
import React from 'react';

interface DeleteItemProps {
  onClick: () => void;
  className?: string;
  sx?: SxProps;
  disableWrapStyle?: boolean;
}

export default function DeleteItem({
  onClick,
  className,
  sx,
  disableWrapStyle = false,
}: DeleteItemProps) {
  return (
    <Box
      sx={
        disableWrapStyle
          ? {}
          : {
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }
      }
    >
      <IconButton
        className={className}
        onClick={onClick}
        sx={{ color: 'primary.main', ...sx }}
        size="small"
      >
        <DeleteOutlinedIcon />
      </IconButton>
    </Box>
  );
}
