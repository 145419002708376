import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';

export const getFormattedErrorGraphql = ({
  graphQLErrors,
  networkError,
}: ApolloError) => {
  return graphQLErrors
    ? `[Graphql error]: ${graphQLErrors
        .map((x: GraphQLError) => x.message)
        .join('\n')}`
    : `[Network error]: ${networkError}`;
};
