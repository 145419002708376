import { Box, Table, TableProps } from '@mui/material';
import React from 'react';

import styles from './styles/PolyTable.module.scss';

const PolyTable = (properties: TableProps) => {
  return (
    <Box sx={{ width: '100%', overflowX: 'auto' }}>
      <Table {...properties} classes={styles} />
    </Box>
  );
};

export default PolyTable;
