import { TableCell, TableRow } from '@mui/material';
import EmployeeCell from 'components/commons/Tables/EmployeeCell';
import { sortMissions } from 'components/commons/Tables/utils';
import { useTableContext } from 'contexts/ActivityMonitoringTable/TableContextProvider';
import {
  ActivitiesActivityBillingTypeChoices,
  ActivitiesActivityTypeChoices,
  ActivityMonitoringNode,
  ActivityNode,
  EmployeeNode,
} from 'generated/graphql';
import _ from 'lodash';
import {
  AssignmentType,
  getCompressedAssignmentWithMaxRange,
} from 'pages/ActivityPage/utils';
import React, { useState } from 'react';

import styles from '../../styles/TableCell.module.scss';
import BorderRow from './Row/BorderRow';
import MissionRow from './Row/MissionRow';
import SharedMissionRow from './Row/SharedMissionRow';
import SubMissionRow from './Row/SubMissionRow';
import { FormattedMission } from './utils';

function getFormattedMission(
  employee: EmployeeNode,
  activity: ActivityNode,
  activityMonitorings: ActivityMonitoringNode[]
) {
  const name = activity.name;
  const assignments = _.filter(employee.activityList, (assignment) => {
    return assignment.activity?.id === activity.id;
  });

  const compressedAssignment = getCompressedAssignmentWithMaxRange(
    assignments as Array<AssignmentType>
  );

  const isAssigned = assignments.length > 0 || activity.isShared === true;
  return {
    name: name,
    start: compressedAssignment?.beginningDate,
    end: compressedAssignment?.expirationDate,
    id: activity.id,
    type: activity.type,
    isTm: activity.billingType === ActivitiesActivityBillingTypeChoices.Tm,
    missions: activityMonitorings,
    mainActivity: activity.mainActivity,
    subActivities: activity.subActivities,
    clientId: activity.billingInformation?.billingClient?.id,
    clientName:
      activity.billingInformation?.billingClient?.corporateName ||
      activity.client,
    isAssigned: isAssigned,
  };
}

function getMissions(
  employee: EmployeeNode,
  sharedMissions: ActivityNode[],
  filterMissionsId: string[]
) {
  const missionsAM = _.map(employee.activityMonitoring, (am) => am.activity);
  const missionsAssigned = _.flatMap(
    employee.activityList,
    (activityAssignment) => activityAssignment.activity || []
  );
  const allMissions = _.concat(missionsAM, missionsAssigned);

  let totalMissions: ActivityNode[] = _.concat(sharedMissions, allMissions);

  if (filterMissionsId.length > 0) {
    totalMissions = _.filter(totalMissions, (mission) => {
      return (
        _.includes(filterMissionsId, mission.id) ||
        _.includes(filterMissionsId, mission.mainActivity?.id)
      );
    });
  }

  totalMissions = sortMissions(totalMissions);

  const missions = _.flatMap(totalMissions, (mission) => {
    const relatedActivityMonitoring = _.filter(
      employee.activityMonitoring,
      (activityMonitoring) => {
        return activityMonitoring.activity.id === mission.id;
      }
    );

    return [getFormattedMission(employee, mission, relatedActivityMonitoring)];
  });
  return missions;
}
interface CollaboratorRowProp {
  employee?: EmployeeNode | undefined;
}

export default function CollaboratorRow({ employee }: CollaboratorRowProp) {
  const { sharedMissions, filterMissionsId, isFiltered, filterClientsId } =
    useTableContext();
  const [openCollapse, setOpenCollapse] = useState(false);
  const [subMissionCollapse, setSubMissionCollapse] = useState<{
    [key: string]: number;
  }>({});

  if (!employee) {
    return <React.Fragment />;
  }

  const allMissions = getMissions(employee, sharedMissions, filterMissionsId);

  if (allMissions.length === 0) {
    return <React.Fragment />;
  }

  const allMissionsNoDuplicate = _.uniqWith(
    allMissions,
    (mission1, mission2) => mission1.id === mission2.id
  );

  const subMissions: {
    [key: string]: FormattedMission[];
  } = _.groupBy(allMissionsNoDuplicate, (mission) => mission.mainActivity?.id);

  let allMissionsNoDuplicateNoSubmission = _.filter(
    allMissionsNoDuplicate,
    (mission) => {
      return !mission.mainActivity;
    }
  );

  if (filterClientsId.length > 0) {
    allMissionsNoDuplicateNoSubmission = _.filter(
      allMissionsNoDuplicateNoSubmission,
      (mission) => {
        return _.includes(filterClientsId, mission.clientId);
      }
    );
  }

  const firstMissionName = allMissionsNoDuplicateNoSubmission[0]?.name || '';
  const lastMissionName = _.isEmpty(filterMissionsId)
    ? 'Jours fériés'
    : _.last(allMissionsNoDuplicateNoSubmission)?.name || '';

  const sharedFormattedMissions = _.filter(
    allMissionsNoDuplicateNoSubmission,
    (mission) =>
      mission.type === ActivitiesActivityTypeChoices.Vct ||
      mission.type === ActivitiesActivityTypeChoices.Hr
  );

  if (!isFiltered) {
    allMissionsNoDuplicateNoSubmission = _.difference(
      allMissionsNoDuplicateNoSubmission,
      sharedFormattedMissions
    );
  }

  let rowCount = allMissionsNoDuplicateNoSubmission.length + 1;
  if (!isFiltered) {
    rowCount += openCollapse ? sharedFormattedMissions.length + 1 : 1;
  }
  rowCount += _.sum(_.values(subMissionCollapse));
  return (
    <React.Fragment>
      <TableRow>
        <TableCell rowSpan={rowCount} className={styles.nameCell}>
          <EmployeeCell
            employee={employee}
            effectiveEmployeeContractType={null}
            sx={{ justifyContent: 'center' }}
          />
        </TableCell>
      </TableRow>
      {_.map(allMissionsNoDuplicateNoSubmission, (mission) => {
        if ((mission.subActivities?.length || 0) === 0) {
          return (
            <MissionRow
              key={`missionrow-${mission.id}-${mission.name}`}
              missionDetail={mission}
              employee={employee}
              headAndTailMissionName={{
                head: firstMissionName,
                tail: lastMissionName,
              }}
            />
          );
        } else if (mission.subActivities?.length !== 0) {
          return (
            <SubMissionRow
              key={`missionrow-${mission.id}-${mission.name}`}
              missionDetail={mission}
              open={mission.id in subMissionCollapse}
              setOpen={(isOpen: boolean) => {
                if (isOpen) {
                  setSubMissionCollapse({
                    ...subMissionCollapse,
                    [mission.id]: subMissions[mission.id]?.length || 0,
                  });
                } else {
                  setSubMissionCollapse(
                    _.omit(subMissionCollapse, [mission.id])
                  );
                }
              }}
              subActivities={subMissions[mission.id] || []}
              employee={employee}
              headAndTailMissionName={{
                head: firstMissionName,
                tail: lastMissionName,
              }}
            />
          );
        }
        return;
      })}
      {!isFiltered && (
        <SharedMissionRow
          open={openCollapse}
          setOpen={setOpenCollapse}
          employee={employee}
          sharedMissions={sharedFormattedMissions}
          headAndTailMissionName={{
            head: firstMissionName,
            tail: lastMissionName,
          }}
        />
      )}
      <BorderRow />
    </React.Fragment>
  );
}
