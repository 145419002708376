import { styled, TableBody, TableRow } from '@mui/material';
import commonStyles from 'components/commons/css/commons.module.scss';
import { BorderedTableCell } from 'components/commons/Tables/FlatTable';
import { isTrimesterStart } from 'components/commons/Tables/Header/ArrowsUpdateTrimesterTableHeader';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import {
  ActivityNode,
  ActivityProfitNode,
  PublicHoliday,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment/moment';
import React from 'react';

import ProfitabilityRenderValues from './ProfitabilityTableBody/Rows/Cells/ProfitabilityRenderValues';
import {
  CallProfitabilityRenderValues,
  getYearToDateProfit,
} from './ProfitabilityTableBody/Rows/utils';

export interface ProfitabilityTableFooterMissionsInterface {
  isTrimesterView: boolean;
  publicHolidays?: PublicHoliday[];
  profitsByMissions: ActivityNode[];
  yearToDateMonthWorkDays?: number;
}

export default function ProfitabilityTableFooterMissions({
  isTrimesterView,
  publicHolidays,
  profitsByMissions,
  yearToDateMonthWorkDays,
}: ProfitabilityTableFooterMissionsInterface) {
  const { displayedMonths } = useTableHeaderContext();

  const missionsProfits = _.flatten(
    _.map(profitsByMissions, (mission) => {
      return _.flatten(mission.activityProfits);
    })
  );

  const yearToDateTotalMissionProfit = !isTrimesterView
    ? (getYearToDateProfit(
        missionsProfits,
        displayedMonths,
        publicHolidays as PublicHoliday[],
        true
      ) as ActivityProfitNode)
    : undefined;

  const StyledBorderedTableCell = styled(BorderedTableCell)(({ theme }) => ({
    width: '300px',
    fontWeight: 'bold',
    fontSize: '1rem',
    border: `1px ${theme.palette.secondary['300']} solid`,
  }));

  return (
    <TableBody>
      <TableRow className={commonStyles.stickyFooter}>
        <StyledBorderedTableCell>Total</StyledBorderedTableCell>
        {_.map(displayedMonths, (month) => {
          if (!isTrimesterView || isTrimesterStart(month)) {
            const avrMissionsStats = _.filter(
              missionsProfits,
              (missionProfit) =>
                month.isSame(
                  moment(missionProfit.month || missionProfit.trimester),
                  'month'
                )
            );
            const totalDays = _.sumBy(avrMissionsStats, 'totalWorkedDays');
            const totalCost = _.sumBy(avrMissionsStats, 'cost');
            const totalRevenues = _.sumBy(avrMissionsStats, 'totalRevenue');
            const totalWorkedDays = _.sumBy(
              avrMissionsStats,
              'totalWorkedDays'
            );
            const totalWorkedDaysWithoutIntern = _.sumBy(
              avrMissionsStats,
              'totalWorkedDaysWithoutIntern'
            );
            const averageWorkDays = _.meanBy(avrMissionsStats, 'workDays');
            const filteredMissions = _.filter(avrMissionsStats, (profits) =>
              month.isSame(profits.month, 'month')
            );
            const etpCumulated = _.sumBy(filteredMissions, 'etpCumulated');
            const etp = _.sumBy(filteredMissions, 'etp');
            const totalWorkedDaysToMonth = _.sumBy(
              avrMissionsStats,
              'totalWorkedDaysToMonth'
            );
            return (
              <ProfitabilityRenderValues
                month={month}
                etp={etp}
                etpCumulated={etpCumulated}
                totalWorkedDaysToMonth={totalWorkedDaysToMonth}
                yearToDateMonthWorkDays={yearToDateMonthWorkDays}
                averageAdr={totalRevenues / totalDays}
                totalRevenue={totalRevenues}
                cost={totalCost}
                totalWorkedDays={totalWorkedDays}
                totalWorkedDaysWithoutIntern={totalWorkedDaysWithoutIntern}
                workDays={averageWorkDays}
                hasValidatedRevenue={
                  !!(totalCost && totalDays && totalRevenues)
                }
                notDisplayedReason="CA non validé"
                key={`value-${month.format()}`}
                isFooter
                sx={{ fontWeight: 'bold' }}
              />
            );
          }
        })}
        {yearToDateTotalMissionProfit && (
          <CallProfitabilityRenderValues
            month={yearToDateTotalMissionProfit?.month}
            profit={yearToDateTotalMissionProfit}
            isTrimesterView={isTrimesterView}
            sx={{ fontWeight: 'bold' }}
            isEtpCumulated
            yearToDateMonthWorkDays={yearToDateMonthWorkDays}
          />
        )}
      </TableRow>
    </TableBody>
  );
}
