import { DialogContent } from '@mui/material';
import styles from 'components/MissionFollowUp/BillDetail/Modals/EditBillToBillModal/EditBillToBillModal.module.scss';
import PolyDialog from 'components/MUIOverload/PolyDialog';
import PolyDialogTitle from 'components/MUIOverload/PolyDialogTitle';
import PolyFooter from 'components/MUIOverload/PolyFooter';
import PongoButton from 'components/MUIOverload/PongoButton';
import React from 'react';
import { useSelector } from 'store';

interface ModalBillCommentariesProps {
  open: boolean;
  onClose: () => void;
  onValidate: () => void;
}

export default function EditBillToBillModal(
  properties: ModalBillCommentariesProps
) {
  const currentBill = useSelector((state) => state.purchaseOrder.currentBill);
  const currentActivity =
    currentBill?.purchaseOrder?.billingActivityInformation?.activity;

  return (
    <PolyDialog
      maxWidth={'lg'}
      open={properties.open}
      onClose={properties.onClose}
      disableEnforceFocus={true}
    >
      <div>
        <DialogContent>
          <PolyDialogTitle>
            Éditer une facture &lsquo;À facturer&rsquo;
          </PolyDialogTitle>
          <div className={styles.subTitleModal}>
            {currentActivity?.client} \ {currentActivity?.name} &gt;{' '}
            {currentBill.title}
          </div>
          <div>
            Souhaitez-vous éditer cette facture ?<br />
            <br />
            <u>ATTENTION</u> : La facture que vous êtes sur le point
            d&apos;éditer est une facture que le manager de mission a passée en
            &lsquo;À facturer&rsquo;. Êtes vous sûr(e) de vouloir y apporter des
            modifications ?
          </div>
        </DialogContent>
      </div>
      <PolyFooter>
        <>
          <PongoButton onClick={properties.onClose}>Annuler</PongoButton>
          <PongoButton
            onClick={() => {
              properties.onValidate();
              properties.onClose();
            }}
            variant={'contained'}
          >
            Valider
          </PongoButton>
        </>
      </PolyFooter>
    </PolyDialog>
  );
}
