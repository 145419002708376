import { FlatTable } from 'components/commons/Tables/FlatTable';
import { useTableContext } from 'components/Revenue/RevenueGlobalTable/context/TableContextProvider';
import RevenueTableBodyByClient from 'components/Revenue/RevenueGlobalTable/RevenueTableBodyByClient';
import RevenueTableHeader from 'components/Revenue/RevenueGlobalTable/RevenueTableHeader';
import { BillingActivityInformationNode } from 'generated/graphql';
import React, { useMemo } from 'react';

interface RevenueGlobalTableProps {
  billingMissions?: BillingActivityInformationNode[];
}

const RevenueGlobalTable = ({ billingMissions }: RevenueGlobalTableProps) => {
  const { setBillingMissions } = useTableContext();
  useMemo(() => {
    if (billingMissions) {
      setBillingMissions(billingMissions);
    }
  }, [billingMissions, setBillingMissions]);
  return (
    <FlatTable size={'small'}>
      <RevenueTableHeader />
      <RevenueTableBodyByClient />
    </FlatTable>
  );
};

export default RevenueGlobalTable;
