import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Collapse,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import CollaboratorsListView from 'components/MissionFollowUp/BillDetail/BillDetail/BillView/CollaboratorsListView';
import {
  ActivityNode,
  BillingPurchaseOrderCurrencyChoices,
  NumberOfDaysCollaboratorNode,
} from 'generated/graphql';
import _ from 'lodash';
import { totalFormat } from 'pages/ActivityPage/utils';
import React, { useState } from 'react';
import { useSelector } from 'store';

interface SubMissionDetailView {
  subActivity: ActivityNode;
  index: number;
}

export default function SubMissionDetailView(properties: SubMissionDetailView) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const currentBill = useSelector((state) => state.purchaseOrder.currentBill);

  const collaborators: NumberOfDaysCollaboratorNode[] = _.filter(
    currentBill.collaboratorsDays,
    (collaboratorsDays) =>
      collaboratorsDays.collaboratorRate.poSubMissions?.[0]?.subMission.id ===
      properties.subActivity.id
  );

  const totalSubMission = _.sumBy(
    collaborators,
    (collaborator) =>
      _.sumBy(collaborator.daysNumbers, 'timeForTmContracts') *
      (collaborator.collaboratorRate?.dailyRate || 0)
  );

  return (
    <>
      <ListItemButton
        onClick={() => setIsOpen(!isOpen)}
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          backgroundColor: (theme) => theme.palette.action.hover,
        }}
      >
        {isOpen ? <ExpandLess /> : <ExpandMore />}
        <ListItemText sx={{ ml: 1, display: 'flex', flexGrow: 1 }}>
          <Typography variant={'h3Bold'} component="h3">
            {properties.subActivity.name}
          </Typography>
        </ListItemText>
        <ListItemText sx={{ marginLeft: 1, display: 'flex', flexGrow: 0 }}>
          <Typography
            variant={'h3Bold'}
            component="h3"
            color={(theme) => theme.palette.text.primary}
          >
            {totalFormat(
              totalSubMission,
              BillingPurchaseOrderCurrencyChoices.Eur
            )}
          </Typography>
        </ListItemText>
      </ListItemButton>
      <Collapse in={isOpen} timeout="auto" unmountOnExit sx={{ p: 2, pr: 7 }}>
        <CollaboratorsListView collaborators={collaborators} />
      </Collapse>
    </>
  );
}
