import { Box, TableCell, Tooltip, Typography } from '@mui/material';
import clsx from 'clsx';
import moment, { Moment } from 'moment';
import { useValueContext } from 'pages/ActivityPage/PrincipalTablePage/context/ValueContextProvider';
import { POLY_DATE, WEEK_DATE } from 'poly-constants';
import React from 'react';
import styles from 'styles/ActivityPage/ColumnLabel.module.scss';

interface ColumnLabelProps {
  id?: string;
  startDate: Moment;
  endDate: Moment;
  className?: string;
  hasUpdateAMAuthValid?: boolean;
  empty?: boolean;
  updateAmValidUntil?: string;
  betweenTwoMonth?: boolean;
  isEditable?: boolean;
  weekPart?: Number;
}

const ColumnLabel = ({
  id = '',
  startDate,
  endDate,
  className = '',
  hasUpdateAMAuthValid = false,
  betweenTwoMonth = false,
  empty = false,
  updateAmValidUntil,
  isEditable,
  weekPart,
}: ColumnLabelProps) => {
  const { invalidWeeks } = useValueContext();
  const isWeekInvalid = invalidWeeks[startDate.format('YYYY-MM-DD')];

  const isCurrentWeek = moment().isSame(startDate, 'week');
  const getTitleToolTip = () => {
    if (hasUpdateAMAuthValid && isEditable) {
      return `${'Vous pouvez corriger vos données jusqu’au '}${moment(
        updateAmValidUntil
      ).format(POLY_DATE)}`;
    }
    if (betweenTwoMonth) {
      return 'Attention : cette semaine est à cheval sur 2 mois';
    }
    return '';
  };

  const getClassesTooltip = () => {
    if (hasUpdateAMAuthValid && isEditable) {
      return {
        tooltip: clsx(styles.tooltip, styles.tooltip_orange),
        arrow: styles.arrow_orange,
      };
    }
    if (betweenTwoMonth) {
      return {
        tooltip: clsx(styles.tooltip, styles.tooltip_red),
        arrow: styles.arrow_red,
      };
    }
    return { tooltip: '', arrow: '' };
  };

  let weekLabelColor = 'black.main';
  if (isWeekInvalid) {
    weekLabelColor = 'error.main';
  } else if (isCurrentWeek) {
    weekLabelColor = 'primary.main';
  } else if (hasUpdateAMAuthValid && isEditable) {
    weekLabelColor = 'orange.main';
  }

  let weekRangeColor = 'info.main';
  if (isWeekInvalid) {
    weekRangeColor = 'error.main';
  } else if (isCurrentWeek) {
    weekRangeColor = 'primary.main';
  } else if (hasUpdateAMAuthValid && isEditable) {
    weekRangeColor = 'orange.main';
  }

  return (
    <TableCell
      className={className}
      key={`${id}-column`}
      sx={{
        paddingBlock: 0.5,
        whiteSpace: 'nowrap',
        borderBottom: isCurrentWeek ? '3px solid' : '',
        borderBottomColor: isCurrentWeek ? 'primary.main' : '',
      }}
    >
      <Tooltip
        classes={getClassesTooltip()}
        title={getTitleToolTip()}
        arrow
        placement={'top'}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {!empty && (
            <>
              <Typography
                color={weekLabelColor}
                variant="bodyS"
              >{`S${startDate.format('WW')}${
                weekPart ? ' - ' + weekPart : ''
              }`}</Typography>
              <Typography color={weekRangeColor} variant="bodySBold">
                {startDate.format(WEEK_DATE)} au {endDate.format(WEEK_DATE)}
              </Typography>
            </>
          )}
        </Box>
      </Tooltip>
    </TableCell>
  );
};

export default ColumnLabel;
