import { Stack } from '@mui/material';
import FieldDisplay from 'components/commons/FieldDisplay/FieldDisplay';
import { ActivityNode } from 'generated/graphql';
import _ from 'lodash';
import React from 'react';
import { displayEmployee } from 'utils';

import { getDefaultText } from '.';

interface MissionInformationsProps {
  currentMission: ActivityNode;
}

export default function MissionInformations({
  currentMission,
}: MissionInformationsProps): JSX.Element {
  return (
    <Stack direction="column" justifyContent="space-between" spacing={2}>
      <FieldDisplay
        title="Directeur de mission"
        value={getDefaultText(
          displayEmployee(
            currentMission.director?.firstName,
            currentMission.director?.lastName
          )
        )}
        flexDirection="column"
        noWrap={false}
        showColon={false}
        titleVariant="bodyBold"
        valueVariant="bodySemiBold"
      />
      <FieldDisplay
        title="Chef(s) de mission"
        value={getDefaultText(
          _.map(currentMission.chiefs, (chief) =>
            displayEmployee(chief.firstName, chief.lastName)
          ).join(', ')
        )}
        flexDirection="column"
        noWrap={false}
        showColon={false}
        titleVariant="bodyBold"
        valueVariant="bodySemiBold"
      />
    </Stack>
  );
}
