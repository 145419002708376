import { Grid, MenuItem, Select } from '@mui/material';
import DeleteItem from 'components/commons/ItemInteractions/DeleteItem/DeleteItem';
import { BillingBillingModalitiesBillingTypeChoices } from 'generated/graphql';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { ModalityTypeDescription } from './Types';

interface ModalityCardProps {
  selector: string;
  children: React.ReactNode;
  index: number;
  onDeleteModality:
    | ((index?: number | number[] | undefined) => void)
    | undefined;
  selectName: string;
}

export function getDisplayedType(type: string) {
  let matching = '';
  ModalityTypeDescription.forEach((modalityType) => {
    matching += modalityType.key === type ? modalityType.displayed : '';
  });
  return matching;
}

function ModalityCard(properties: ModalityCardProps) {
  const form = useFormContext();
  function deleteModality() {
    if (properties.onDeleteModality) {
      properties.onDeleteModality(properties.index);
    }
  }

  return (
    <Grid
      container
      sx={{
        border: '2px solid',
        borderColor: 'standardGrey.dark',
        borderRadius: '10px',
        py: 2.5,
        px: 1.5,
        my: 2.5,
      }}
    >
      <Grid item xs={2.5} sx={{ display: 'flex', alignContent: 'center' }}>
        <Controller
          render={(properties) => {
            return (
              <Select
                {...properties}
                readOnly={false}
                sx={{
                  display: 'flex',
                  width: '100%',
                  height: '40px',
                }}
                variant={'outlined'}
                value={properties.value}
                onChange={(event) => {
                  properties.onChange(event.target.value);
                  return event.target.value as string;
                }}
              >
                {Object.values(BillingBillingModalitiesBillingTypeChoices).map(
                  (type: string, index: number) => {
                    return (
                      <MenuItem value={type} key={`${type}${String(index)}`}>
                        {getDisplayedType(type)}
                      </MenuItem>
                    );
                  }
                )}
              </Select>
            );
          }}
          defaultValue={properties.selector}
          name={properties.selectName}
          control={form.control}
        />
      </Grid>
      <Grid item xs={9}>
        {properties.children}
      </Grid>
      <Grid item xs={0.5}>
        <DeleteItem
          onClick={deleteModality}
          sx={{ mt: '3px', ml: 0.75 }}
          disableWrapStyle
        />
      </Grid>
    </Grid>
  );
}

export default ModalityCard;
