import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import BillCollaboratorDaysForm from 'components/MissionFollowUp/BillDetail/BillCollaboratorDaysForm';
import BillFormSubMission from 'components/MissionFollowUp/BillDetail/BillDetail/BillFormSubMission';
import { CollaboratorsFieldsTitle } from 'components/MissionFollowUp/BillDetail/CollaboratorsFieldsTitle';
import styles from 'components/MissionFollowUp/BillDetail/Modals/CreateOrUpdateBillModal/styles/BillForm.module.scss';
import PongoButton from 'components/MUIOverload/PongoButton';
import {
  ActivityNode,
  AverageDailyRateCollaboratorNode,
  BillingNode,
  PurchaseOrderNode,
  PurchaseOrderSubMissionNode,
  useFetchAssignmentsForActivityAndEmployeesQuery,
  useFetchBillingForActivityAndEmployeesInMonthQuery,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { totalFormat } from 'pages/ActivityPage/utils';
import React, { ReactElement, useEffect, useState } from 'react';
import { UseFieldArrayMethods, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { setCurrentPurchaseOrderBilling } from 'store/purchaseOrder';
import { graphQlDateFormatter } from 'utils';

interface BillCollaboratorDaysProps {
  collaboratorsFieldArray: UseFieldArrayMethods;
  isCreating: boolean;
  isEditing: boolean;
  isViewing: boolean;
}

export const getFormattedMonthsSet = (
  fromDate: moment.Moment,
  toDate: moment.Moment
): Set<string> => {
  const diff = toDate.diff(fromDate, 'months');
  const formattedMonthsSet = new Set<string>();
  for (let i = 0; i <= diff; i++) {
    formattedMonthsSet.add(moment(fromDate).add(i, 'months').format('YYYY-MM'));
  }
  return formattedMonthsSet;
};

export default function BillCollaboratorDays({
  collaboratorsFieldArray,
  isEditing,
  isViewing,
  isCreating,
}: BillCollaboratorDaysProps): ReactElement {
  const { id: activityId } = useSelector(
    (state) => state.activity.currentMission as ActivityNode
  );
  const currentPurchaseOrder = useSelector(
    (state) => state.purchaseOrder.currentPurchaseOrder
  );

  const { watch } = useFormContext();

  const [expandedAccordions, setExpandedAccordions] = useState<Set<number>>(
    new Set()
  );
  const [collaboratorsLastState, setcollaboratorsLastState] = useState<
    AverageDailyRateCollaboratorNode[]
  >([]);
  const {
    fields: collaboratorsFields,
    append: appendCollaborator,
    remove: removeCollaborators,
  } = collaboratorsFieldArray;

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { loading: loadingBillings, refetch: refetchBilling } =
    useFetchBillingForActivityAndEmployeesInMonthQuery({
      notifyOnNetworkStatusChange: true,
      variables: {
        periodBeginning: graphQlDateFormatter(watch('billMonthRange.from')),
        periodEnding: graphQlDateFormatter(watch('billMonthRange.to')),
        employeesId: getEmployeesIds(currentPurchaseOrder),
        activityId: activityId,
      },
      onCompleted: (data) => {
        dispatch(
          setCurrentPurchaseOrderBilling(
            data.allBillingForActivityAndEmployeesInMonth as BillingNode[]
          )
        );
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
    });

  const {
    loading: loadingAssignments,
    refetch: refetchAssignments,
    data: assignments,
  } = useFetchAssignmentsForActivityAndEmployeesQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      employeesId: getEmployeesIds(currentPurchaseOrder),
      activityId: activityId,
    },
  });

  const handleAccordion = (index: number) => {
    setExpandedAccordions((expAcc) => {
      if (expAcc.has(index)) {
        expAcc.delete(index);
      } else {
        expAcc.add(index);
      }
      return new Set(expAcc);
    });
  };

  const collaboratorsBillings = useSelector(
    (state) => state.purchaseOrder.currentPurchaseOrderBilling
  );

  const getAdrForCollaborator = (
    collaborators: AverageDailyRateCollaboratorNode[] | undefined,
    collaboratorId?: string
  ) => {
    if (!collaboratorId) {
      return 0;
    }
    return (
      _.find(
        collaborators,
        (collaborator) => collaborator.collaborator?.id === collaboratorId
      )?.dailyRate || 0
    );
  };

  const getTotalForSubActivity = (
    billingNodes: BillingNode[] | undefined,
    po: PurchaseOrderSubMissionNode
  ) => {
    if (!billingNodes || !po.collaborators) {
      return 0;
    }
    const relevantBillings = _.filter(
      billingNodes,
      (billingNode) => billingNode.activity?.id === po.subMission.id
    );
    const allAmounts = _.map(relevantBillings, (relevantBilling) => {
      return (
        (relevantBilling.timeForTmContracts || 0) *
        getAdrForCollaborator(po.collaborators, relevantBilling.employee?.id)
      );
    });
    return _.sum(allAmounts);
  };

  const handleRefreshData = () => {
    setcollaboratorsLastState(
      _.map(watch('collaborators'), 'collaboratorRate').filter(Boolean)
    );
    removeCollaborators();
    refetchBilling();
    refetchAssignments();
  };

  function ListAllCollaboratorsInPo() {
    removeCollaborators();
    _.map(currentPurchaseOrder.collaborators, (collaborator) => {
      appendCollaborator({
        collaboratorRate: collaborator,
        daysNumbers: [],
      });
    });
  }

  function RefreshCollaborators() {
    const collaboratorsToRefresh = _.intersectionWith(
      currentPurchaseOrder.collaborators,
      collaboratorsLastState,
      (a, b) => a?.collaborator?.id === b?.collaborator?.id
    );
    _.map(collaboratorsToRefresh, (collaborator) => {
      appendCollaborator({
        collaboratorRate: collaborator,
        daysNumbers: [],
      });
    });
  }
  useEffect(() => {
    RefreshCollaborators();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collaboratorsLastState]);

  const subMissionsCollabs = currentPurchaseOrder.poSubMissions?.filter(
    (subMission) => subMission.collaborators
  );
  const hasCollabsInBdc =
    (currentPurchaseOrder.collaborators &&
      currentPurchaseOrder.collaborators.length > 0) ||
    (subMissionsCollabs && subMissionsCollabs.length > 0);
  const hasCollabsInBill = collaboratorsFields.length > 0;

  return (
    <>
      <Grid item container xs={12}>
        {loadingBillings || loadingAssignments ? (
          <Card
            className={styles.cardLoading}
            variant="outlined"
            key={'loadingCard'}
          >
            <div className={styles.loadingBilling}>
              <LoadingPlaceholder />
            </div>
          </Card>
        ) : (
          (hasCollabsInBdc || hasCollabsInBill) && (
            <Grid container>
              <CollaboratorsFieldsTitle refreshFunction={handleRefreshData} />
              {_.size(currentPurchaseOrder.poSubMissions) > 0 ? (
                <>
                  {_.map(
                    currentPurchaseOrder.poSubMissions,
                    (poSubMission, index) => {
                      return (
                        <Accordion
                          sx={{ width: 1 }}
                          key={`subMissionForm-${index}-${poSubMission.id}`}
                          expanded={expandedAccordions.has(index)}
                          onChange={() => handleAccordion(index)}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Stack>
                              <Typography>
                                {poSubMission.subMission.name}
                              </Typography>
                              <Typography color={'textSecondary'}>
                                {'Sous total: '}
                                {totalFormat(
                                  getTotalForSubActivity(
                                    collaboratorsBillings,
                                    poSubMission
                                  ),
                                  currentPurchaseOrder.currency
                                )}
                              </Typography>
                            </Stack>
                          </AccordionSummary>
                          <AccordionDetails sx={{ marginTop: '-32px' }}>
                            <BillFormSubMission
                              poSubMission={poSubMission}
                              nestedIndex={index}
                              isEditable={!(!isEditing && isViewing)}
                              isCreating={isCreating}
                              handleRefreshData={handleRefreshData}
                              setExpandedAccordions={setExpandedAccordions}
                            />
                          </AccordionDetails>
                        </Accordion>
                      );
                    }
                  )}
                </>
              ) : (
                <>
                  {_.map(collaboratorsFields, (collaboratorField, index) => {
                    const formName = `collaborators.${index}`;
                    const collaboratorIds = _.reduce(
                      collaboratorsFields,
                      function (ids: string[], collaboratorField) {
                        if (collaboratorField.collaboratorRate) {
                          ids.push(
                            collaboratorField.collaboratorRate.collaborator.id
                          );
                        }
                        return ids;
                      },
                      []
                    );

                    return (
                      <BillCollaboratorDaysForm
                        collaboratorIds={collaboratorIds}
                        collaboratorADR={collaboratorField.collaboratorRate}
                        options={currentPurchaseOrder.collaborators || []}
                        assignments={assignments}
                        disabled={!isEditing && isViewing}
                        formName={formName}
                        selfDelete={() => removeCollaborators(index)}
                        key={collaboratorField.key}
                        currency={currentPurchaseOrder.currency}
                      />
                    );
                  })}
                </>
              )}
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  margin: '5px',
                }}
              >
                {(isEditing || !isViewing) &&
                  !currentPurchaseOrder.poSubMissions?.length && (
                    <Stack flexDirection={'row'}>
                      <PongoButton
                        buttonStyle="secondary"
                        variant="contained"
                        size={'small'}
                        startIcon={<PlaylistAddIcon />}
                        onClick={() => ListAllCollaboratorsInPo()}
                        sx={{ mr: 1 }}
                      >
                        Ajouter tous les collaborateurs du BDC
                      </PongoButton>

                      <PongoButton
                        buttonStyle="secondary"
                        variant="contained"
                        size={'small'}
                        startIcon={<AddCircleOutlineOutlinedIcon />}
                        onClick={() =>
                          appendCollaborator({
                            collaboratorRate: undefined,
                            daysNumbers: [],
                          })
                        }
                      >
                        Nouveau collaborateur
                      </PongoButton>
                    </Stack>
                  )}
              </Box>
            </Grid>
          )
        )}
      </Grid>
    </>
  );
}

export function getEmployeesIds({
  poSubMissions,
  collaborators,
}: PurchaseOrderNode): string[] {
  if (poSubMissions?.length) {
    let collaborators: string[] = [];
    _.forEach(poSubMissions, (subMission) => {
      collaborators = collaborators.concat(
        _.map(
          subMission.collaborators,
          (collaborator) => collaborator.collaborator?.id || ''
        )
      );
    });
    return _.uniq(collaborators);
  } else {
    return (
      _.map(
        collaborators,
        (collaborator) => collaborator.collaborator?.id || ''
      ) || []
    );
  }
}
