import SentimentVeryDissatisfiedTwoToneIcon from '@mui/icons-material/SentimentVeryDissatisfiedTwoTone';
import { Stack, Typography } from '@mui/material';
import React from 'react';

interface SkillSearchNoResultProps {
  searchTerm?: string;
}

export default function SkillSearchNoResult({
  searchTerm,
}: SkillSearchNoResultProps) {
  return (
    <Stack
      spacing={2}
      sx={{
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Stack
        component="span"
        sx={{
          border: '1px dashed',
          color: 'info.main',
          width: 54,
          height: 54,
          borderRadius: '50%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SentimentVeryDissatisfiedTwoToneIcon
          color="info"
          sx={{
            fontSize: '30px',
          }}
        />
      </Stack>
      <Typography color={'text.secondary'} textAlign={'center'}>
        Aucune compétence trouvée
        {searchTerm && (
          <>
            {' '}
            pour &quot;
            <Typography variant="bodySemiBold" color={'primary'}>
              {searchTerm}
            </Typography>
            &quot;
          </>
        )}
      </Typography>
    </Stack>
  );
}
