import PageWrapper from 'components/commons/PageWrapper';
import PolyAlert from 'components/commons/PolyAlert';
import PolyAlertTitle from 'components/commons/PolyAlertTitle';
import TabHeader from 'components/commons/TabHeader';
import {
  ActivitiesActivityBillingTypeChoices,
  ActivityNode,
} from 'generated/graphql';
import _ from 'lodash';
import ValidateTable from 'pages/ActivityPage/ValidateTmActivityMonitoringPage/ValidateTable';
import { PageTitles } from 'poly-constants';
import React from 'react';
import { useSelector } from 'store';

const ValidateTmActivityMonitoringPage = () => {
  const currentMission = useSelector((state) => state.activity.currentMission);
  const selectedMissions = useSelector(
    (state) => state.activity.selectedMissions
  );
  const filteredMissions = currentMission?.id
    ? [currentMission]
    : selectedMissions;
  const areMissionsFiltered: boolean = filteredMissions.length > 0;
  const isTmContracts = _.some(
    filteredMissions,
    (mission) => mission.billingType === ActivitiesActivityBillingTypeChoices.Tm
  );

  return (
    <PageWrapper noLimit>
      {areMissionsFiltered ? (
        <>
          {isTmContracts ? (
            <ValidateTable />
          ) : (
            <NoTmActivityView filteredMissions={filteredMissions} />
          )}
        </>
      ) : (
        <>{noMissionsFilteredAlert}</>
      )}
    </PageWrapper>
  );
};

export default ValidateTmActivityMonitoringPage;

function NoTmActivityView({
  filteredMissions,
}: {
  filteredMissions: ActivityNode[];
}) {
  return (
    <>
      <TabHeader title={PageTitles.validateTmActivityMonitoringPage} />
      <PolyAlert severity="info" variant="outlined">
        <PolyAlertTitle color="info">Information</PolyAlertTitle>
        {filteredMissions.length === 1
          ? 'La mission sélectionnée n’est pas une mission régie.'
          : "Aucune des missions sélectionnées n'est une mission régie."}
      </PolyAlert>
    </>
  );
}

const noMissionsFilteredAlert = (
  <PolyAlert severity="info" variant="outlined">
    <PolyAlertTitle color="info">Information</PolyAlertTitle>
    Veuillez sélectionner une mission régie dans la barre de recherche.
  </PolyAlert>
);
