import {
  Box,
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PolyDialogTitle from 'components/MUIOverload/PolyDialogTitle';
import PolyFooter from 'components/MUIOverload/PolyFooter';
import PongoButton from 'components/MUIOverload/PongoButton';
import {
  ContractorParameterInput,
  EmployeeNode,
  useCreateContractorReportingParametersMutation,
} from 'generated/graphql';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { SaveButtonName } from 'poly-constants';
import React from 'react';
import { useForm } from 'react-hook-form';
import { displayEmployee, graphQlDateFormatter } from 'utils';

interface ModalProps {
  open: boolean;
  setOpen: (p: boolean) => void;
  provider?: EmployeeNode;
  refetchProviders: () => void;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: `solid`,
  borderWidth: '1px 1px 1px 1px',
  borderColor: theme.palette.secondary[300],
}));

export const ProviderAdrModal = ({
  open,
  setOpen,
  provider,
  refetchProviders,
}: ModalProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    adr: provider?.contractorReportingParameters?.[0]?.adr || 0,
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: defaultValues,
    reValidateMode: 'onSubmit',
  });

  const [callMutation] = useCreateContractorReportingParametersMutation({
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    onCompleted: (data) => {
      enqueueSnackbar(`TJM mis à jour`, {
        variant: 'success',
      });
      reset({ adr: data.createContractorReportingParameters?.[0].adr || 0 });
      refetchProviders();
      setOpen(false);
    },
  });

  const saveData = async (formValues: typeof defaultValues) => {
    const mutationParameter: ContractorParameterInput = {
      adr: Number.parseFloat(formValues.adr.toString()),
      contractorId: provider?.id || '',
    };

    await callMutation({
      variables: {
        month: graphQlDateFormatter(moment().startOf('month')),
        contractorParameterList: [mutationParameter],
      },
    });
  };

  return (
    <form>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box ml={3} mt={2.5}>
          <PolyDialogTitle>Édition de TJM des prestataires</PolyDialogTitle>
        </Box>
        <DialogContent>
          <Table sx={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <Typography fontWeight={'bold'}>Nom</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography fontWeight={'bold'}>Rôle</Typography>
                </StyledTableCell>
                <StyledTableCell align="center" width="100px">
                  <Typography fontWeight={'bold'}>TJM Coût</Typography>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledTableCell>
                  {displayEmployee(provider?.firstName, provider?.lastName)}
                </StyledTableCell>
                <StyledTableCell>{provider?.occupation}</StyledTableCell>
                <StyledTableCell>
                  <TextField
                    variant="standard"
                    size={'small'}
                    name="adr"
                    inputRef={register({
                      required: '* Champ requis',
                      min: {
                        value: 1,
                        message: '* Le TJM doit être supérieur à 0',
                      },
                      valueAsNumber: true,
                    })}
                    sx={{ color: 'text.secondary' }}
                    error={!!errors.adr?.message}
                  />
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
          {errors.adr && (
            <Typography sx={{ color: 'red', paddingTop: '20px' }}>
              {errors.adr.message}
            </Typography>
          )}
        </DialogContent>
        <PolyFooter>
          <PongoButton onClick={() => setOpen(false)}>Annuler</PongoButton>
          <PongoButton
            variant={'contained'}
            color={'primary'}
            type="submit"
            onClick={handleSubmit(saveData)}
          >
            {SaveButtonName}
          </PongoButton>
        </PolyFooter>
      </Dialog>
    </form>
  );
};
