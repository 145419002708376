import { useFilterContext } from 'components/commons/FilterContext/FilterContextProvider';
import PolyExportButton, {
  createCsvFile,
} from 'components/commons/PolyExportButton';
import { useFetchExportEstimatedRevenueLazyQuery } from 'generated/graphql';
import _ from 'lodash';
import { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useSelector } from 'store';
import { graphQlDateFormatter } from 'utils';

interface ButtonProps {
  year: Moment;
}
const EstimatedRevenueExportButton = ({ year }: ButtonProps) => {
  const selectedMissions = useSelector(
    (state) => state.activity.selectedMissions
  );
  const { enqueueSnackbar } = useSnackbar();
  const { filteredID: filteredDirectorId } = useFilterContext();
  const [exportQuery, { data }] = useFetchExportEstimatedRevenueLazyQuery({
    onCompleted: () => {
      if (data && data.exportEstimatedRevenue) {
        const yearString: string = year.format('YYYY');
        const filename = `estimated_revenue_${yearString}.csv`;
        createCsvFile(data.exportEstimatedRevenue, filename);
      }
    },
    onError: () => {
      enqueueSnackbar('Erreur lors de la récupération des données', {
        variant: 'error',
      });
    },
  });

  const exportTable = () => {
    exportQuery({
      variables: {
        year: graphQlDateFormatter(year),
        activityDirectorId: filteredDirectorId,
        missionsIds: _.map(selectedMissions, (mission) => mission.id),
      },
    });
  };

  return (
    <PolyExportButton onClick={exportTable}>
      Exporter ce tableau
    </PolyExportButton>
  );
};

export default EstimatedRevenueExportButton;
