import ClearIcon from '@mui/icons-material/Clear';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { IconButton } from '@mui/material';
import styles from 'components/commons/FileTransfer/UploadFile/styles/UploadFile.module.scss';
import { humanFileSize } from 'components/MissionFollowUp/BillDetail/utils';
import PongoButton from 'components/MUIOverload/PongoButton';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { getFileNameFromPath } from 'utils';

interface UploadFileFormProps {
  formname: string;
  required?: boolean;
  callback?: () => void;
  validate?: (files: FileList, isDocument: boolean) => string | boolean;
  deleteCallback?: () => void;
  isEditing?: boolean;
  fileFormatsToAccept?: string;
  isCorrectUploadedFileFormat?: React.MutableRefObject<boolean>;
}

export default function UploadFileForm({
  formname,
  required,
  callback,
  validate = () => {
    return true;
  },
  deleteCallback,
  isEditing,
  fileFormatsToAccept,
  isCorrectUploadedFileFormat,
}: UploadFileFormProps) {
  const { watch, setValue, register, trigger } = useFormContext();
  const fileName: string = watch(formname + '.filename');
  const file = watch(formname + '.file');
  const size = file ? (file[0] ? file[0].size : 0) : 0;
  const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    register(formname + '.filename');
    setValue(formname + '.filename', event.target.value);
    if (callback) {
      callback();
    }
  };

  const deleteFile = () => {
    setValue(formname + '.file', undefined);
    setValue(formname + '.filename', '');
    trigger();

    if (deleteCallback) {
      deleteCallback();
    }
  };

  return (
    <>
      {!fileName ||
      (isCorrectUploadedFileFormat && !isCorrectUploadedFileFormat?.current) ? (
        <PongoButton
          buttonStyle="secondary"
          variant={'contained'}
          className={styles.button}
          sx={{ marginLeft: 0 }}
        >
          <label className={styles.labelInputFile} htmlFor={formname + '.file'}>
            <div className={styles.uploadButton}>
              <NoteAddIcon className={styles.icon} />
              <div className={styles.text}>Parcourir</div>
              <input
                onChange={uploadFile}
                accept={fileFormatsToAccept}
                id={formname + '.file'}
                type={'file'}
                name={formname + '.file'}
                hidden
                className={styles.hidden}
                ref={register({
                  validate: {
                    unique: (v) => {
                      return validate
                        ? validate(v, formname === 'document')
                        : true;
                    },
                  },
                  required: !!required && !fileName,
                })}
              />
            </div>
          </label>
        </PongoButton>
      ) : (
        <div className={styles.filename}>
          {getFileNameFromPath(fileName)}
          <span className={styles.sizeText}>
            {size ? ` - ${humanFileSize(size)}` : ''}
          </span>
          {isEditing && (
            <IconButton
              aria-label="delete"
              size="small"
              onClick={deleteFile}
              className={styles.deleteIcon}
            >
              <ClearIcon fontSize="inherit" />
            </IconButton>
          )}
        </div>
      )}
    </>
  );
}
