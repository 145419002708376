import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Stack, Typography } from '@mui/material';
import styles from 'components/commons/FileTransfer/UploadFile/styles/UploadFile.module.scss';
import { IMAGE_EXTENSIONS } from 'poly-constants';
import React from 'react';
import { useFormContext } from 'react-hook-form';

interface UploadProfilePictureFormProps {
  formName: string;
  isCropping?: boolean;
  required?: boolean;
}

export default function UploadProfilePictureForm({
  formName,
  isCropping = false,
  required,
}: UploadProfilePictureFormProps) {
  const { watch, setValue, register } = useFormContext();
  const fileName: string = watch(formName + '.filename');
  const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    register(formName + '.filename');
    setValue(formName + '.filename', event.target.value);
  };
  return (
    <Stack justifyContent={'center'} direction="row">
      <label htmlFor={formName + '.file'}>
        <input
          onChange={uploadFile}
          accept={IMAGE_EXTENSIONS}
          id={formName + '.file'}
          type={'file'}
          name={formName + '.file'}
          hidden
          className={styles.hidden}
          ref={register({
            required: !!required && !fileName,
          })}
        />
        <Stack
          direction="row"
          spacing={1.5}
          sx={{
            width: '240px',
            height: '14px',
            py: 2,
            border: '1px dashed',
            borderColor: 'text.secondary',
            borderRadius: '7px',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            color: isCropping ? 'contrastText.main' : 'black.main',
            '&:hover': {
              backgroundColor: 'primary.light',
              color: 'black.main',
            },
          }}
        >
          <Stack
            sx={{
              p: 0.5,
              borderRadius: '39px',
              backgroundColor: 'primary.main',
            }}
          >
            <FileUploadIcon
              sx={{
                width: '20px',
                height: '20px',
                color: 'contrastText.main',
              }}
            />
          </Stack>
          <Stack>
            <Typography variant="bodyBold" color={'inherit'}>
              {isCropping ? 'Remplacer la photo' : 'Parcourir les fichiers'}
            </Typography>
          </Stack>
        </Stack>
      </label>
    </Stack>
  );
}
