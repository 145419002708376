import { Delete } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import { Stack } from '@mui/system';
import LabelTextFieldGrid from 'components/MissionFollowUp/LabelTextFieldGrid';
import PongoButton from 'components/MUIOverload/PongoButton';
import {
  BusinessClientNode,
  useCreateOrUpdateBusinessClientMutation,
  useDeleteBusinessClientMutation,
} from 'generated/graphql';
import { enqueueSnackbar } from 'notistack';
import { requiredForm } from 'pages/MissionFollowUp/formValidators';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

interface BusinessClientModal {
  open: boolean;
  close: () => void;
  onSuccess: () => void;
  businessClient?: BusinessClientNode;
}

const BusinessClientModal = ({
  open,
  close,
  onSuccess,
  businessClient,
}: BusinessClientModal): JSX.Element => {
  const theme = useTheme();
  const [createOrUpdateBusinessClientMutation] =
    useCreateOrUpdateBusinessClientMutation({
      onCompleted: (data) => {
        const clientName =
          data.createOrUpdateBusinessClient?.businessClient?.name || '';
        enqueueSnackbar(
          businessClient
            ? `Client « ${clientName} » mis à jour`
            : `Client « ${clientName} » créé`,
          {
            variant: 'success',
          }
        );
        onSuccess();
        close();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
    });
  const defaultValues = {
    corporateName: businessClient?.name || '',
  };
  const form = useForm({ defaultValues });
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] =
    useState<boolean>(true);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length > 0) {
      setIsSubmitButtonDisabled(false);
    } else {
      setIsSubmitButtonDisabled(true);
    }
  };
  const handleSave = async () => {
    const formValues = form.getValues();
    await createOrUpdateBusinessClientMutation({
      variables: {
        id: businessClient?.id,
        name: formValues.corporateName,
      },
    });
  };
  const [deleteBusinessClient] = useDeleteBusinessClientMutation({
    onCompleted: (data) => {
      enqueueSnackbar(
        `Client « ${data.deleteBusinessClient?.businessClient?.name} » supprimé.`,
        { variant: 'success' }
      );
      onSuccess();
      close();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });
  const handleDelete = async () => {
    if (businessClient) {
      await deleteBusinessClient({
        variables: {
          id: businessClient?.id,
        },
      });
    }
  };
  return (
    <Dialog open={open} maxWidth={false} onClick={close}>
      <DialogContent
        onClick={(e) => e.stopPropagation()}
        sx={{
          overflowX: 'hidden',
          width: '500px',
          backgroundColor: theme.palette.modalBackground.main,
          pt: 0,
        }}
      >
        <Stack
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Stack flexDirection={'row'} alignItems={'center'} sx={{ py: 2 }}>
            <Typography color="text.secondary" variant="h3Bold" sx={{ mr: 2 }}>
              {businessClient
                ? 'Édition du client métier'
                : "Création d'un client métier"}
            </Typography>
          </Stack>
          <IconButton edge={'end'} onClick={close} size="large">
            <CloseIcon />
          </IconButton>
        </Stack>
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            p: 1.5,
            borderRadius: '8px',
          }}
        >
          <FormProvider {...form}>
            <Grid item xs={8}>
              <LabelTextFieldGrid
                inputRef={form.register({
                  required: requiredForm,
                })}
                required
                name={'corporateName'}
                errorform={form.errors.corporateName}
                title="Nom"
                defaultValue={defaultValues.corporateName}
                sizegrid={12}
                customspacingside={0}
                customspacingtop={0}
                onChange={handleChange}
              />
            </Grid>
            <Stack
              height={198}
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              {businessClient && (
                <PongoButton
                  onClick={handleDelete}
                  variant="text"
                  color="error"
                  sx={{ mr: 'auto' }}
                  startIcon={<Delete />}
                >
                  Supprimer
                </PongoButton>
              )}
              <PongoButton
                variant="contained"
                buttonStyle="secondary"
                onClick={close}
                sx={{ mr: 3 }}
              >
                Annuler
              </PongoButton>
              <PongoButton
                variant="contained"
                buttonStyle="primary"
                disabled={isSubmitButtonDisabled}
                onClick={form.handleSubmit(handleSave)}
              >
                Enregistrer
              </PongoButton>
            </Stack>
          </FormProvider>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default BusinessClientModal;
