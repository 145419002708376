import { ActivityNode } from 'generated/graphql';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { ACTIVITY_DATE_FORMAT, CategoryOrder } from 'poly-constants';
import { Validate } from 'react-hook-form';

export const validationWrapper = (
  value: string,
  condition: boolean | string
): boolean | string => {
  if (!value) {
    return true;
  }
  return condition;
};

export const formatValue = (value: string | number) => {
  return Number.parseFloat(value.toString().replace(',', '.'));
};

export const basicWorkedDayValidationRules: Record<string, Validate> = {
  isNotANumber: (value) =>
    validationWrapper(
      value,
      value.match(/^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/) ||
        'Seuls les nombres entiers ou décimaux sont autorisés'
    ),
  isDayQuarter: (value) =>
    validationWrapper(
      value,
      formatValue(value) % 0.25 === 0 ||
        'Seuls les quarts de journée sont autorisés'
    ),
};

export const buildColumnLabel = (
  weekStepper: moment.Moment,
  endToMonthEnd?: boolean
) => {
  const weekStepperEndWeek = endToMonthEnd
    ? weekStepper.clone().endOf('month')
    : weekStepper.clone().add(4, 'day');
  const weekNumber = weekStepper.week();

  return {
    weekNumber: weekNumber,
    start: weekStepper.clone(),
    end: weekStepperEndWeek,
    weekPart: endToMonthEnd ? 1 : 0,
  };
};

export const getFirstMondayOfTheWeek = (period: string) => {
  const firstDay = moment(period, ACTIVITY_DATE_FORMAT).startOf('months');
  if (firstDay.weekday() >= 4) {
    return firstDay.add(7 - firstDay.weekday(), 'days');
  }

  return firstDay.weekday(0);
};

export const getFirstWorkingDayOfMonth = (period: string) => {
  const firstDay = moment(period, ACTIVITY_DATE_FORMAT).startOf('months');
  if (firstDay.weekday() >= 5) {
    return firstDay.add(7 - firstDay.weekday(), 'days');
  }
  return firstDay.weekday(0);
};

export const buildColumnLabels = (
  month: string,
  needOneMoreLastWeek?: boolean
) => {
  const weekStepper = getFirstMondayOfTheWeek(month);
  const current_month = moment(month, ACTIVITY_DATE_FORMAT).month();
  const columnLabels: ColumnLabelsV2[] = [];
  columnLabels.push(buildColumnLabel(weekStepper));
  weekStepper.add(7, 'days');
  weekStepper.weekday(3);
  while (weekStepper.month() === current_month) {
    weekStepper.weekday(0);
    columnLabels.push(buildColumnLabel(weekStepper));
    weekStepper.add(7, 'days');
    weekStepper.weekday(3);
  }
  if (needOneMoreLastWeek) {
    weekStepper.weekday(0);
    columnLabels.push(buildColumnLabel(weekStepper, true));
  }
  return columnLabels;
};

export const getWeekPart = (startDate: Moment, endDate: Moment) => {
  if (startDate.weekday() === 0 && endDate.weekday() === 4) {
    return 0;
  }
  if (startDate.weekday() === 0) {
    return 1;
  }
  return 2;
};

export const buildNextWeek = (startDate: Moment) => {
  const start = startDate.clone();
  const end = moment.min(
    start.clone().weekday(4),
    start.clone().endOf('months')
  );
  const weekNumber = start.week();
  const weekPart = getWeekPart(start, end);
  return {
    weekNumber: weekNumber,
    start: start,
    end: end,
    weekPart: weekPart,
  };
};

export interface ColumnLabelsV2 {
  weekNumber: number;
  start: Moment;
  end: Moment;
  weekPart: Number;
}

export const buildColumnLabelsV2 = (
  month: string,
  withoutOthersMonthsStraddlingWeeks?: boolean
) => {
  const startOfWeek = getFirstWorkingDayOfMonth(month);
  const columnLabels: ColumnLabelsV2[] = [];
  const month_value = moment(month, ACTIVITY_DATE_FORMAT).month();

  while (
    startOfWeek.clone().startOf('week').month() === month_value ||
    startOfWeek.clone().endOf('week').month() === month_value
  ) {
    const newLabel = buildNextWeek(startOfWeek);
    if (newLabel.end.weekday() === 4) {
      startOfWeek.add(7, 'days');
      startOfWeek.weekday(0);
    } else {
      startOfWeek.endOf('month').add(1, 'days').startOf('day');
    }
    if (
      !withoutOthersMonthsStraddlingWeeks ||
      (newLabel.start.month() === month_value &&
        newLabel.end.month() === month_value)
    ) {
      columnLabels.push(newLabel);
    }
  }
  return columnLabels;
};

export interface DateRange {
  start: Moment;
  end: Moment;
}

export const getLastThursdayOfMonth = (date: Moment): Moment => {
  date.endOf('month');

  while (date.day() !== 4) {
    date.subtract(1, 'day');
  }

  return date;
};

export const getFirstThursdayOfMonth = (date: Moment): Moment => {
  date.startOf('month');

  while (date.day() !== 4) {
    date.add(1, 'day');
  }

  return date;
};

export const buildDateRange = (date: Moment): DateRange => {
  if (date.year() < 2023) {
    const end = getLastThursdayOfMonth(date.clone());
    const start = getFirstThursdayOfMonth(date.clone());
    return { start: start, end: end };
  } else {
    const end = date.clone().endOf('month');
    const start = date.clone().startOf('month');
    return { start: start, end: end };
  }
};

export const sortMissions = (missions: ActivityNode[]) => {
  const sortedByName = _.orderBy(missions, (mission) => mission.name);
  const sortedByCategory = _.orderBy(sortedByName, (mission) =>
    _.get(CategoryOrder, mission.type, 0)
  );
  return sortedByCategory;
};
