import { Box, Table } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import { formatValue } from 'components/commons/Tables/utils';
import {
  ActivitiesActivityBillingTypeChoices,
  ActivitiesActivityTypeChoices,
  EmployeeWeeklyContractNode,
  useFetchUserActivityQuery,
  useGetUserWeeklyContractQuery,
  useUpdateActivityMonitoringMutation,
} from 'generated/graphql';
import _ from 'lodash';
import transform from 'lodash/transform';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { getTotalWorkingDays } from 'pages/UserPage/ContractualDays/utils';
import React, { useEffect, useState } from 'react';

import InvalidWorkingDaysModal from '../InvalidWorkingDaysModal';
import { Activity, ActivityGroupType, ActivityMonitoring, DateRange } from '.';
import TableContextProvider, {
  SubmitProps as SubmitProperties,
} from './context/TableContextProvider';
import ValueContextProvider, {
  providerCommentsInterface,
} from './context/ValueContextProvider';
import Footer from './Footer';
import PrincipalTableBody from './PrincipalTableBody';
import PrincipalTableHead from './PrincipalTableHead';

interface TableProps {
  dateRange: DateRange;
  viewMonth: string;
  incrementPeriod: () => void;
  decrementPeriod: () => void;
}

interface ActivitySubmit {
  activityId: string;
  date: string;
  timeSpent: number;
  comment?: string;
}

const groupByCategory = (list: Array<Activity>) => {
  return transform(
    list,
    (result: ActivityGroupType, item: Activity) => {
      (result[item.type] || (result[item.type] = [])).push(item);
    },
    {}
  );
};

const getHasTMActivity = (listActivities: Activity[]) => {
  for (const activity of listActivities) {
    if (activity.billingType === ActivitiesActivityBillingTypeChoices.Tm) {
      return true;
    }
  }
  return false;
};

export const getIsValidTotalWorkingDays = (
  userWeeklyContract: EmployeeWeeklyContractNode,
  weeklyContract: number
) => {
  const workingDays = [
    { values: userWeeklyContract ? userWeeklyContract.monday : [true, true] },
    { values: userWeeklyContract ? userWeeklyContract.tuesday : [true, true] },
    {
      values: userWeeklyContract ? userWeeklyContract.wednesday : [true, true],
    },
    { values: userWeeklyContract ? userWeeklyContract.thursday : [true, true] },
    { values: userWeeklyContract ? userWeeklyContract.friday : [true, true] },
  ];

  const totalWorkingDays = getTotalWorkingDays(workingDays);
  return totalWorkingDays === weeklyContract;
};

const PrincipalTable = ({
  dateRange,
  viewMonth,
  incrementPeriod,
  decrementPeriod,
}: TableProps) => {
  const [numberWeeks, setNumberWeeks] = useState(0);
  const [hasTMActivity, setHasTMActivity] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const startDateForQuery = dateRange.start.clone();
  const endDateForQuery = dateRange.end.clone();

  const {
    loading: loadingUserActivity,
    data: dataUserActivity,
    refetch: refetchUserActivity,
  } = useFetchUserActivityQuery({
    variables: {
      dateFrom: startDateForQuery.format('YYYY-MM-DD'),
      dateTo: endDateForQuery.format('YYYY-MM-DD'),
    },
  });

  const [updateActivityMonitoring, { loading }] =
    useUpdateActivityMonitoringMutation({
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
      onCompleted: () => {
        enqueueSnackbar("Suivi d'activité enregistré", {
          variant: 'success',
        });
      },
    });

  const onSubmit = async (data: SubmitProperties) => {
    if (!isValidTotalWorkingDays) {
      setInvalidWorkingDaysModalOpen(true);
      return;
    }
    const activityMonitoringToSend: {
      listActivity: Array<ActivitySubmit>;
    } = { listActivity: [] };

    const listActivity = Object.keys(data['activityMonitoring']).map(
      (activityDate) => {
        return transform(
          data['activityMonitoring'][activityDate],
          (
            listActivityMonitoring: Array<ActivityMonitoring>,
            value: ActivityMonitoring,
            key: Activity['id']
          ) => {
            const submitData: ActivitySubmit = {
              activityId: key,
              date: moment(activityDate).format('YYYY-MM-DD'),
              timeSpent: value.timeSpent ? formatValue(value.timeSpent) : 0,
              comment: value.comment || '',
            };
            listActivityMonitoring.push(submitData);
          },
          []
        );
      }
    );
    activityMonitoringToSend['listActivity'] = _.flatten(listActivity);

    await updateActivityMonitoring({
      variables: {
        input: activityMonitoringToSend,
      },
    });
    refetchUserActivity();
  };

  const extractActivities = (): ActivityGroupType => {
    if (dataUserActivity === undefined) {
      return {};
    }
    const supportHideDate = moment('2023-03-31');
    const result = dateRange.start.isAfter(supportHideDate)
      ? dataUserActivity['allActivitiesForUser']?.filter(
          (activity) =>
            !(
              activity.name === 'Support' &&
              activity.type === ActivitiesActivityTypeChoices.Int
            )
        )
      : dataUserActivity['allActivitiesForUser'];

    if (result === null || result === undefined) {
      return {};
    }

    const newHasTMActivity = getHasTMActivity(result);
    if (newHasTMActivity !== hasTMActivity) {
      setHasTMActivity(newHasTMActivity);
    }
    return groupByCategory(result);
  };

  const extractProviderComments = () => {
    const providerComments: providerCommentsInterface[] = [];
    if (dataUserActivity) {
      _.map(dataUserActivity['allActivitiesForUser'], (activity) => {
        return _.map(activity.activityMonitoring, (am) => {
          if (am.comment) {
            providerComments.push({
              activityId: activity.id,
              week: am.date,
              comment: am.comment,
            });
          }
        });
      });
    }
    return providerComments;
  };

  const { data } = useGetUserWeeklyContractQuery();
  const userWeeklyContract =
    data?.userWeeklyContract as EmployeeWeeklyContractNode;

  const weeklyContract = userWeeklyContract
    ? userWeeklyContract.weeklyContract
    : 5;

  const isValidTotalWorkingDays = getIsValidTotalWorkingDays(
    userWeeklyContract,
    weeklyContract
  );

  const [invalidWorkingDaysModalOpen, setInvalidWorkingDaysModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    setInvalidWorkingDaysModalOpen(!isValidTotalWorkingDays);
  }, [isValidTotalWorkingDays]);

  const footer = <Footer disabled={loadingUserActivity} loading={loading} />;

  return (
    <>
      <InvalidWorkingDaysModal
        open={invalidWorkingDaysModalOpen}
        setOpen={setInvalidWorkingDaysModalOpen}
        weeklyContract={weeklyContract}
      />
      <TableContextProvider
        dateRange={dateRange}
        numberOfDisplayedWeeks={numberWeeks}
        numberOfWeeks={numberWeeks}
        numberOfModifiableWeeks={numberWeeks}
        onSubmit={onSubmit}
        hasTMActivity={hasTMActivity}
      >
        <ValueContextProvider>
          <TableContainer sx={{ overflow: 'unset' }}>
            <Table padding="none">
              <PrincipalTableHead
                viewMonth={viewMonth}
                setDisplayedWeeks={setNumberWeeks}
                incrementPeriod={incrementPeriod}
                decrementPeriod={decrementPeriod}
                isLoading={loadingUserActivity}
              />
              {!loadingUserActivity && (
                <PrincipalTableBody
                  activities={extractActivities()}
                  providerComments={extractProviderComments()}
                  footer={footer}
                />
              )}
            </Table>
          </TableContainer>
          {loadingUserActivity && (
            <Box
              sx={{
                display: 'flex',
                height: '600px',
                alignItems: 'center',
              }}
            >
              <LoadingPlaceholder />
            </Box>
          )}
        </ValueContextProvider>
      </TableContextProvider>
    </>
  );
};

export default PrincipalTable;
