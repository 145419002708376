import { Typography } from '@mui/material';
import React from 'react';

export default function SkillDirectionsInfoTooltipTitle() {
  return (
    <Typography variant="caption" color={'white'}>
      Cliquez sur les compétences pour les ajouter à votre sélection.
    </Typography>
  );
}
