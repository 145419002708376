import PolySwitch from 'components/Navigation/PolySwitch';
import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import urljoin from 'url-join';

import MissionDetailPage from './MissionPage';
import MissionsPage from './MissionsPage';

const MissionPage = () => {
  const { path } = useRouteMatch();

  return (
    <PolySwitch>
      <Route exact path={path} component={MissionsPage} />
      <Route path={urljoin(path, ':id')} component={MissionDetailPage} />
    </PolySwitch>
  );
};

export default MissionPage;
