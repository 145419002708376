import { MenuItem, Select } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';

import { StaffingStatusEnum } from './utils';

interface StaffingSelectProps {
  staffingFilter: string;
  setStaffingFilter: Dispatch<SetStateAction<StaffingStatusEnum>>;
}

const StaffingSelect = ({
  staffingFilter,
  setStaffingFilter,
}: StaffingSelectProps) => {
  return (
    <Select
      value={staffingFilter}
      size="small"
      onChange={(e) => setStaffingFilter(e.target.value as StaffingStatusEnum)}
    >
      <MenuItem value={StaffingStatusEnum.CURRENT}>
        {StaffingStatusEnum.CURRENT}
      </MenuItem>
      <MenuItem value={StaffingStatusEnum.FUTURE}>
        {StaffingStatusEnum.FUTURE}
      </MenuItem>
      <MenuItem value={StaffingStatusEnum.HISTORY}>
        {StaffingStatusEnum.HISTORY}
      </MenuItem>
      <MenuItem value={StaffingStatusEnum.ALL}>
        {StaffingStatusEnum.ALL}
      </MenuItem>
    </Select>
  );
};

export default StaffingSelect;
