import { Stack } from '@mui/material';
import { useFilterContext } from 'components/commons/FilterContext/FilterContextProvider';
import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import TabHeader from 'components/commons/TabHeader';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import YearSelector from 'components/commons/YearSelector/YearSelector';
import RevenueGlobalTable from 'components/Revenue/RevenueGlobalTable';
import TableContextProvider, {
  TableMode,
} from 'components/Revenue/RevenueGlobalTable/context/TableContextProvider';
import FilterDirector from 'components/Revenue/RevenueGlobalTable/Filters/FilterDirector';
import { useUserInfo } from 'components/User/UserProvider';
import {
  BillingActivityInformationNode,
  EmployeeNode,
  EmployeesEmployeeGradeChoices,
  useFetchAllBilledRevenuesLazyQuery,
} from 'generated/graphql';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { PageTitles } from 'poly-constants';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'store';
import { graphQlDateFormatter } from 'utils';

import BilledRevenueExportButton from './BilledRevenueExportButton';

export default function BilledRevenueHomePage() {
  const selectedMissions = useSelector(
    (state) => state.activity.selectedMissions
  );
  const [allRevenues, setAllRevenues] = useState<
    BillingActivityInformationNode[]
  >([]);
  const { enqueueSnackbar } = useSnackbar();
  const { currentYear, setCurrentYear } = useTableHeaderContext();
  const { employee, isControl, isSuperuser } = useUserInfo();
  const isComex = employee?.grade === EmployeesEmployeeGradeChoices.Comex;

  const [fetchAllBilledRevenues, { loading }] =
    useFetchAllBilledRevenuesLazyQuery({
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data.allRevenues) {
          setAllRevenues(data.allRevenues as BillingActivityInformationNode[]);
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
    });

  useEffect(() => {
    fetchAllBilledRevenues({
      variables: {
        year: graphQlDateFormatter(currentYear),
        missionsIds: _.map(selectedMissions, (mission) => mission.id),
      },
    });
  }, [fetchAllBilledRevenues, currentYear, selectedMissions]);

  const directors = useMemo(
    () =>
      _.flatMap(allRevenues, (actualActivity) => {
        return actualActivity.activity?.director || [];
      }) as EmployeeNode[],
    [allRevenues]
  );

  const { filteredID } = useFilterContext();

  const filterMissions = (baiToFilter: BillingActivityInformationNode[]) => {
    const billingMissions = baiToFilter;
    if (filteredID) {
      return billingMissions.filter(
        (billingActivity) =>
          billingActivity.activity?.director?.id === filteredID
      );
    }

    return billingMissions;
  };

  return (
    <React.Fragment>
      <TableContextProvider
        mode={TableMode.BILLED}
        billingMissions={filterMissions(allRevenues)}
      >
        <TabHeader
          title={PageTitles.billedRevenuePage}
          yearSelectorComponent={
            <YearSelector
              currentYear={currentYear}
              setCurrentYear={setCurrentYear}
            />
          }
          filterComponents={
            (isComex || isControl || isSuperuser) && (
              <FilterDirector directors={directors as EmployeeNode[]} />
            )
          }
          actionComponents={<BilledRevenueExportButton />}
        />
        <Stack mt={2}>
          {loading ? (
            <LoadingPlaceholder />
          ) : (
            <RevenueGlobalTable billingMissions={filterMissions(allRevenues)} />
          )}
        </Stack>
      </TableContextProvider>
    </React.Fragment>
  );
}
