import { Search } from '@mui/icons-material';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import { useTableContext } from 'contexts/ActivityMonitoringTable/TableContextProvider';
import { BillingClientNode } from 'generated/graphql';
import React from 'react';

export default function ClientFilter() {
  const { allClients, setFilterClientsId, setPage } = useTableContext();

  return (
    <Autocomplete
      autoHighlight
      autoComplete
      id="filter-client"
      options={allClients}
      onChange={(event, value) => {
        const client = value as BillingClientNode;
        if (client) {
          setFilterClientsId([client.id]);
        } else {
          setFilterClientsId([]);
        }
        setPage(0);
      }}
      getOptionLabel={(option) => {
        return option?.corporateName || '';
      }}
      sx={{ width: 300, ml: 1 }}
      renderInput={(parameters) => (
        <TextField
          label="Clients"
          variant={'standard'}
          {...parameters}
          InputProps={{
            ...parameters.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
