import CancelIcon from '@mui/icons-material/Cancel';
import {
  Autocomplete,
  Chip,
  Popper,
  PopperProps,
  TextField,
} from '@mui/material';
import PolyAlert from 'components/commons/PolyAlert';
import PolyAlertTitle from 'components/commons/PolyAlertTitle';
import PolyModal from 'components/MUIOverload/PolyDialog/PolyModal';
import {
  BillingClientNode,
  SectorNode,
  useCreateOrUpdateSectorMutation,
} from 'generated/graphql';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { getNameFormatted, NO_SECTOR_NAME } from './utils';

type ModalMode = 'create' | 'edit';

export interface SectorModalProps {
  isOpen: boolean;
  handleClose: () => void;
  sector?: SectorNode;
  allClients: BillingClientNode[];
  refetch: () => void;
  mode: ModalMode;
}

const PopperCustom = (props: PopperProps) => {
  const modifiers = [
    {
      name: 'flip',
      options: {
        fallbackPlacements: [],
      },
    },
  ];

  return (
    <Popper
      {...props}
      modifiers={modifiers}
      popperOptions={{
        placement: 'bottom',
      }}
    />
  );
};

export default function SectorModal({
  isOpen,
  handleClose,
  sector,
  allClients,
  refetch,
  mode,
}: SectorModalProps) {
  const sortedClients = _.sortBy(sector?.billingClients, [
    function (client) {
      return client.corporateName?.toLowerCase();
    },
  ]);
  const [name, setName] = useState<string>(sector?.name || '');
  const [clientsSelected, setClientsSelected] =
    useState<BillingClientNode[]>(sortedClients);
  const [clientsFiltered, setClientsFiltered] = useState<BillingClientNode[]>(
    []
  );

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const filteredArray = _.filter(allClients, (client) => {
      return !_.some(clientsSelected, client);
    });
    setClientsFiltered(filteredArray);
  }, [allClients, clientsSelected]);

  const noSectorModale = sector && sector?.name === NO_SECTOR_NAME;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const defaultValues = {
    name: sector?.name || '',
  };

  const [createOrUpdateSectorMutation] = useCreateOrUpdateSectorMutation({
    onCompleted: () => {
      setName('');
      setClientsSelected([]);
      handleClose();
      refetch();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const createOrUpdateSector = (name: string, id?: string) => {
    const clients_ids = _.map(clientsSelected, (client) => client.id);
    createOrUpdateSectorMutation({
      variables: {
        sectorId: id,
        name: name,
        clientIds: clients_ids,
      },
    });
  };

  const onSubmit = (values: typeof defaultValues) => {
    sector
      ? createOrUpdateSector(values.name, sector.id)
      : createOrUpdateSector(values.name);
  };

  const { handleSubmit, register } = useForm({
    defaultValues,
    reValidateMode: 'onChange',
  });

  const onTagChange = (
    event: React.SyntheticEvent,
    valueTags: BillingClientNode[] | []
  ) => {
    setClientsSelected(valueTags);
  };

  const isNewClientSelected = _.some(
    clientsSelected,
    (client) => client.sector?.id !== sector?.id
  );

  return (
    <PolyModal
      open={isOpen}
      onClose={handleClose}
      polyDialogTitle={
        mode === 'create'
          ? "Création d'une fiche secteur"
          : 'Édition de la fiche secteur'
      }
      handlePrimary={handleSubmit(onSubmit)}
      isPrimaryButtonDisabled={noSectorModale}
      childrenInForm
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEnforceFocus={true}
      PaperProps={{ sx: { width: '600px' } }}
    >
      <>
        <PolyAlert severity="warning" variant="outlined" sx={{ mb: 2 }}>
          <PolyAlertTitle color="warning">Attention !</PolyAlertTitle>
          Chaque mission entourée en orange est déjà affectée à un autre
          secteur. Si vous continuez, les missions concernées seront retirées de
          leur ancien secteur.
        </PolyAlert>
        {isNewClientSelected && (
          <PolyAlert severity="info" variant="outlined" sx={{ mb: 2 }}>
            Les missions en gris clair seront rajoutées au secteur.
          </PolyAlert>
        )}
        <TextField
          sx={{ width: 1, mr: 2, mb: 2 }}
          inputProps={{ sx: { fontWeight: 'bold' } }}
          inputRef={register({ required: true })}
          name="name"
          id="sectorName"
          label="Nom du secteur"
          variant="outlined"
          value={name}
          onChange={handleChange}
          disabled={noSectorModale}
          required
        />
        <Autocomplete
          sx={{ width: 1 }}
          multiple
          id="clients"
          options={clientsFiltered}
          getOptionLabel={(option) => option?.corporateName || ''}
          defaultValue={clientsSelected}
          onChange={onTagChange}
          disabled={noSectorModale}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Ajouter un client"
            />
          )}
          PopperComponent={PopperCustom}
          ListboxProps={{ style: { maxHeight: 200 } }}
          renderTags={(tagValue, getTagProps) =>
            _.map(tagValue, (option, index) => {
              const isClientFromSameSector = option.sector?.id === sector?.id;
              const isClientAssignedToAnotherSector =
                option.sector?.name !== NO_SECTOR_NAME &&
                option.sector?.id !== sector?.id;

              return (
                <Chip
                  label={getNameFormatted(option.corporateName)}
                  {...getTagProps({ index })}
                  deleteIcon={!noSectorModale ? <CancelIcon /> : <></>}
                  sx={{
                    backgroundColor: isClientFromSameSector
                      ? 'darkGrey.main'
                      : 'standardGrey.main',
                    border: isClientAssignedToAnotherSector ? '2px solid' : '',
                    borderColor: isClientAssignedToAnotherSector
                      ? 'orange.main'
                      : '',
                    ml: 1,
                    mt: 1,
                  }}
                  key={`option-${option.id}`}
                />
              );
            })
          }
        />
      </>
    </PolyModal>
  );
}
