import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import TabHeader from 'components/commons/TabHeader';
import { EstimatedObjectiveForm } from 'components/Revenue/Estimated/EstimatedObjectiveForm';
import EstimatedRevenueSummaryWrapped from 'components/Revenue/Estimated/EstimatedRevenueSummaryWrapped';
import EstimatedRevenueGlobalTable from 'components/Revenue/Estimated/EstimatedRevenueTables/EstimatedRevenueGlobalTable';
import { EstimatedRevenueYear } from 'components/Revenue/Estimated/EstimatedRevenueYear';
import FilterDirector from 'components/Revenue/RevenueGlobalTable/Filters/FilterDirector';
import { useUserInfo } from 'components/User/UserProvider';
import {
  ActivityNode,
  EmployeeNode,
  EmployeesEmployeeGradeChoices,
  useFetchCompanyObjectiveQuery,
  useSubmitCompanyObjectiveMutation,
} from 'generated/graphql';
import _ from 'lodash';
import { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import { PageTitles } from 'poly-constants';
import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'store';

import EstimatedRevenueExportButton from './EstimatedRevenueExportButton';

interface EstimatedRevenueHomePageProperties {
  setCurrentYear: (newYear: Moment) => void;
  currentYear: Moment;
  estimatedRevenues: ActivityNode[];
  loading: boolean;
}

export default function EstimatedRevenueHomePage({
  setCurrentYear,
  currentYear,
  estimatedRevenues,
  loading,
}: EstimatedRevenueHomePageProperties) {
  const selectedMissions = useSelector(
    (state) => state.activity.selectedMissions
  );
  const { enqueueSnackbar } = useSnackbar();
  const { employee, isControl, isSuperuser } = useUserInfo();
  const isComex = employee?.grade === EmployeesEmployeeGradeChoices.Comex;
  const form = useForm({
    shouldUnregister: false,
  });

  const [submitCompanyObjective] = useSubmitCompanyObjectiveMutation({
    onCompleted: () => {
      enqueueSnackbar('Objectif mis à jour', {
        variant: 'success',
      });
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const updateObjective = async (submitValues: Record<string, number>) => {
    await submitCompanyObjective({
      variables: {
        year: currentYear.year(),
        objective: submitValues['objective'],
      },
    });
  };

  const { data: companyData } = useFetchCompanyObjectiveQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      year: currentYear.year(),
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const directors = useMemo(
    () =>
      _.flatMap(estimatedRevenues, (activity) => {
        return activity.director || [];
      }) as EmployeeNode[],
    [estimatedRevenues]
  );

  return (
    <React.Fragment>
      <TabHeader
        title={PageTitles.estimatedRevenuePage}
        yearSelectorComponent={<EstimatedRevenueYear />}
        filterComponents={
          <>
            {!selectedMissions.length && (
              <>
                <FormProvider {...form}>
                  <form onSubmit={form.handleSubmit((d) => updateObjective(d))}>
                    {(isComex || isControl || isSuperuser) && (
                      <EstimatedObjectiveForm
                        company
                        objective={companyData?.companyObjective?.objective}
                        sx={{ mb: 2 }}
                      />
                    )}
                  </form>
                </FormProvider>
                {(isComex || isControl || isSuperuser) && (
                  <EstimatedRevenueSummaryWrapped
                    objective={companyData?.companyObjective?.objective}
                  />
                )}
              </>
            )}
            {(isComex || isControl || isSuperuser) && (
              <FilterDirector directors={directors} />
            )}
          </>
        }
        actionComponents={<EstimatedRevenueExportButton year={currentYear} />}
      />

      {!estimatedRevenues || loading ? (
        <LoadingPlaceholder />
      ) : (
        <EstimatedRevenueGlobalTable
          missions={estimatedRevenues}
          currentYear={currentYear}
          setCurrentYear={setCurrentYear}
        />
      )}
    </React.Fragment>
  );
}
