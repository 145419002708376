import 'react-image-crop/dist/ReactCrop.css';

import PolyDialogActions from 'components/MUIOverload/PolyDialog/DefaultComponents/PolyDialogActions';
import React, { useState } from 'react';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';

import { cropImage } from './crop';

export interface TrophyImageResizerProps {
  src: string;
  onSave?(dataURL: string): void;
  handleClose: () => void;
}

export default function TrophyImageResizer({
  src,
  onSave,
  handleClose,
}: TrophyImageResizerProps) {
  const [cropContainer, setCropContainer] = useState<Crop>({
    x: 0,
    y: 0,
    width: 100,
    height: 100,
    aspect: 1,
  });
  const [needPercent, setNeedPercent] = useState<boolean>(true);
  const [pixelCrop, setPixelCrop] = useState<PixelCrop>({
    x: 0,
    y: 0,
    width: 100,
    height: 100,
  });

  const img = new Image();
  img.src = src;

  img.addEventListener('load', () => {
    if (needPercent) {
      if (img.height > img.width) {
        setCropContainer({
          x: 0,
          y: 0,
          width: 100,
          height: (100 * img.width) / img.height,
          aspect: 1,
        });
      } else if (img.width > img.height) {
        setCropContainer({
          x: 0,
          y: 0,
          height: 100,
          width: (100 * img.height) / img.width,
          aspect: 1,
        });
      }
      setNeedPercent(false);
    }
  });

  const handleSave = async () => {
    onSave && onSave(await cropImage(src, pixelCrop));
  };

  const handleCropperChange = async (crop: Crop, pixelCrop: PixelCrop) => {
    setCropContainer(crop);
    await setPixelCrop(pixelCrop);
  };

  return (
    <div>
      <div
        style={{
          alignSelf: 'center',
          maxHeight: 400,
          maxWidth: 400,
          minWidth: 400,
          minHeight: 400,
        }}
      >
        <ReactCrop
          src={src}
          crop={cropContainer}
          onChange={handleCropperChange}
          onComplete={handleCropperChange}
        />
      </div>
      <nav>
        <PolyDialogActions
          handleSecondary={handleClose}
          secondaryButtonName={'Annuler'}
          handlePrimary={handleSave}
          primaryButtonName={'Valider'}
        />
      </nav>
    </div>
  );
}
