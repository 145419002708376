import BillDetail from 'components/MissionFollowUp/BillDetail/BillDetail';
import PolySwitch from 'components/Navigation/PolySwitch';
import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import urljoin from 'url-join';

import ListBills from '../ListBills';

export default function InitializedMissionRoute() {
  const { path } = useRouteMatch();

  return (
    <React.Fragment>
      <PolySwitch>
        <Route exact path={urljoin(path, 'purchase_order')}>
          <ListBills />
        </Route>
        <Route exact path={urljoin(path, 'bill')}>
          <BillDetail />
        </Route>
      </PolySwitch>
    </React.Fragment>
  );
}
