import { DialogContentText, Grid, Typography } from '@mui/material';
import FieldSelection from 'components/commons/FieldSelection';
import { createCsvFile } from 'components/commons/PolyExportButton';
import PolyModal from 'components/MUIOverload/PolyDialog/PolyModal';
import {
  ActivityNode,
  List_Field_Profitability_Export,
  useFetchExportProfitabilityLazyQuery,
} from 'generated/graphql';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useSelector } from 'store';

const profitabilityExportFieldsCorrespondance = {
  [List_Field_Profitability_Export.Mission]: 'Mission',
  [List_Field_Profitability_Export.SubMission]: 'Sous-mission',
  [List_Field_Profitability_Export.Client]: 'Client',
  [List_Field_Profitability_Export.Sector]: 'Secteur',
  [List_Field_Profitability_Export.Director]: 'Directeur',
  [List_Field_Profitability_Export.Etp]: 'ETP',
  [List_Field_Profitability_Export.Adr]: 'TJM vendu',
  [List_Field_Profitability_Export.Revenue]: 'CA',
  [List_Field_Profitability_Export.Cost]: 'Coût',
  [List_Field_Profitability_Export.Margin]: 'Marge',
  [List_Field_Profitability_Export.MarginRate]: 'Taux de marge',
};

interface ExportProfitabilityModalProps {
  year: number;
  isTrimester?: boolean;
  isExcludingIntern?: boolean;
  lastValidatedMonthEndDate?: string;
  isOpen: boolean;
  handleClose: () => void;
  title: string;
  body: string;
}

export default function ExportProfitabilityModal({
  year,
  isTrimester,
  isExcludingIntern,
  lastValidatedMonthEndDate,
  isOpen,
  handleClose,
  title,
  body,
}: ExportProfitabilityModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const selectableFields = _.filter(
    Object.keys(profitabilityExportFieldsCorrespondance),
    (field) => field !== List_Field_Profitability_Export.Mission
  ) as List_Field_Profitability_Export[];
  const [listFields, setListFields] =
    useState<List_Field_Profitability_Export[]>(selectableFields);
  const selectedMissions = useSelector(
    (state) => state.activity.selectedMissions as ActivityNode[]
  );

  const [exportQuery, { data }] = useFetchExportProfitabilityLazyQuery({
    onCompleted: () => {
      if (data && data.exportProfitability) {
        const filename = `profitability_${year.toString()}_${
          isTrimester ? 'trimester' : 'month'
        }.csv`;
        createCsvFile(data.exportProfitability, filename);
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const onClickExport = () => {
    exportQuery({
      variables: {
        year: year,
        isTrimester: isTrimester,
        isExcludingIntern: isExcludingIntern,
        lastValidatedMonthEndDate: lastValidatedMonthEndDate,
        activityIds: _.map(selectedMissions, (mission) => mission.id),
        listFields: listFields,
      },
    });
    handleClose();
  };

  return (
    <PolyModal
      open={isOpen}
      onClose={handleClose}
      polyDialogTitle={title}
      handlePrimary={onClickExport}
      primaryButtonName={'Télécharger'}
      disableEnforceFocus={true}
    >
      <Grid container spacing={2} alignItems="center" marginBottom={2}>
        <Grid item xs={12}>
          <DialogContentText>{body}</DialogContentText>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="bodyBold" sx={{ ml: 1 }}>
            Champs de données :
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <FieldSelection
            listFields={listFields}
            setListFields={setListFields}
            selectableFields={selectableFields}
            fieldsCorrespondance={profitabilityExportFieldsCorrespondance}
          />
        </Grid>
      </Grid>
    </PolyModal>
  );
}
