import Box from '@mui/material/Box';
import PongoButton from 'components/MUIOverload/PongoButton';
import _ from 'lodash';
import { SaveButtonName } from 'poly-constants';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { useTableContext } from './context/TableContextProvider';
import { useValueContext } from './context/ValueContextProvider';

export interface FooterProps {
  disabled?: boolean;
  ignoreIsDirty?: boolean;
  loading: boolean;
}

const Footer = ({
  disabled = false,
  ignoreIsDirty = false,
  loading = false,
}: FooterProps) => {
  const { formState, errors } = useFormContext();
  const { needToAddCommentWeeks } = useValueContext();

  const { totalErrors } = useTableContext();
  const disableBecauseOfIsDirty = ignoreIsDirty ? false : !formState.isDirty;
  const isOnError = !_.isEmpty(totalErrors) || !_.isEmpty(errors);

  return (
    <Box>
      <PongoButton
        disabled={
          disableBecauseOfIsDirty ||
          disabled ||
          isOnError ||
          !!needToAddCommentWeeks.length
        }
        loading={loading}
        type={'submit'}
        color={'primary'}
        variant={'contained'}
        sx={{ float: 'right', mb: 2 }}
      >
        {SaveButtonName}
      </PongoButton>
    </Box>
  );
};

export default Footer;
