import { DialogContent, Typography } from '@mui/material';
import PolyDialog from 'components/MUIOverload/PolyDialog';
import {
  ActivitiesActivityBillingTypeChoices,
  ActivityNode,
} from 'generated/graphql';
import React, { ReactElement } from 'react';
import { useSelector } from 'store';

import CreatePurchaseOrderForm from './CreatePurchaseOrderForm';
import DuplicatePurchaseOrderForm from './DuplicatePurchaseOrderForm';
import EditPurchaseOrderForm from './EditPurchaseOrderForm';
import { PoFormMode } from './PurchaseOrderForm';

interface PurchaseOrderModalProps {
  open: boolean;
  validCallBack?: () => void;
  closeModal?: () => void;
  purchaseOrderId?: string;
  mode: PoFormMode;
  selectedActivity?: ActivityNode;
}

export default function PurchaseOrderModal({
  open,
  validCallBack,
  closeModal,
  purchaseOrderId,
  mode,
  selectedActivity,
}: PurchaseOrderModalProps) {
  const currentMissionInStore = useSelector(
    (state) => state.activity.currentMission
  );
  const currentMission = selectedActivity
    ? selectedActivity
    : currentMissionInStore;
  let titleModal = 'Créer un nouveau bon de commande';
  if (mode === PoFormMode.DUPLICATE) {
    titleModal = 'Dupliquer votre bon de commande';
  } else if (mode === PoFormMode.EDIT) {
    titleModal = 'Éditer votre bon de commande';
  }
  titleModal +=
    currentMission.billingType === ActivitiesActivityBillingTypeChoices.Tm
      ? ' régie'
      : ' forfait';

  const formValidCallBack = () => {
    if (validCallBack) {
      validCallBack();
    }
    if (closeModal) {
      closeModal();
    }
  };

  const PurchaseOrderForm = () =>
    getPurchaseOrderForm(
      formValidCallBack,
      closeModal,
      purchaseOrderId,
      mode,
      selectedActivity
    );

  return (
    <>
      <PolyDialog
        fullWidth={true}
        maxWidth={'md'}
        open={open}
        onClose={closeModal}
        disableEnforceFocus={true}
      >
        <DialogContent>
          <Typography
            sx={{ mb: 1, color: 'primary.main' }}
            variant={'h3Bold'}
            component="h3"
          >
            {titleModal}
          </Typography>
          <PurchaseOrderForm />
        </DialogContent>
      </PolyDialog>
    </>
  );
}

function getPurchaseOrderForm(
  validCallback: () => void,
  cancelCallback: (() => void) | undefined,
  purchaseOrderId: string | undefined,
  mode: PoFormMode,
  selectedActivity?: ActivityNode
): ReactElement {
  switch (mode) {
    case PoFormMode.EDIT:
      return (
        <EditPurchaseOrderForm
          purchaseOrderId={purchaseOrderId}
          validCallback={validCallback}
          cancelCallback={cancelCallback}
        />
      );
    case PoFormMode.DUPLICATE:
      return (
        <DuplicatePurchaseOrderForm
          validCallback={validCallback}
          cancelCallback={cancelCallback}
        />
      );
    default:
      return (
        <CreatePurchaseOrderForm
          validCallback={validCallback}
          cancelCallback={cancelCallback}
          selectedActivity={selectedActivity}
        />
      );
  }
}
