import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/700.css';
import '@fontsource/outfit/300.css';
import '@fontsource/outfit/400.css';
import '@fontsource/outfit/500.css';
import '@fontsource/outfit/600.css';
import '@fontsource/outfit/700.css';

import CloseIcon from '@mui/icons-material/Close';
import { Button, styled } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ThemeProviderCustom from 'components/ThemeProviderCustom';
import Authenticator from 'components/User/Authenticator';
import { fr as localeFr } from 'date-fns/locale';
import {
  MaterialDesignContent,
  SnackbarKey,
  SnackbarProvider,
} from 'notistack';
import HealthCheckPage from 'pages/HealthCheckPage';
import MainPage from 'pages/MainPage';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useGoogleClientId } from 'services/hooks/useGoogleClientId';
import { SnackbarUtilsConfigurator } from 'services/Snackbar';
import { designSystem } from 'theme';

import ScrollToTop from './components/Scroll/ScrollToTop';
import store from './store';

const App = (): JSX.Element => {
  const googleClientId = useGoogleClientId() as string;
  const notistackReference = React.createRef<SnackbarProvider>();
  const onClickDismiss = (key: SnackbarKey) => () => {
    notistackReference?.current?.closeSnackbar(key);
  };

  return (
    <Router>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={localeFr}
      >
        <Provider store={store}>
          <ThemeProviderCustom>
            <SnackbarProvider
              Components={{
                info: styled(MaterialDesignContent)(() => ({
                  '&.notistack-MuiContent-info': {
                    backgroundColor: designSystem.palette.secondary.main,
                  },
                })),
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              style={{ right: '20px', top: '70px' }}
              autoHideDuration={5000}
              maxSnack={3}
              ref={notistackReference}
              action={(key) => (
                <Button onClick={onClickDismiss(key)} sx={{ color: 'white' }}>
                  <CloseIcon />
                </Button>
              )}
            >
              <SnackbarUtilsConfigurator />
              <Switch>
                <Route exact path="/selftest/ping/" render={HealthCheckPage} />
                <ScrollToTop>
                  <Route>
                    <GoogleOAuthProvider clientId={googleClientId}>
                      <Authenticator googleClientId={googleClientId}>
                        <MainPage />
                      </Authenticator>
                    </GoogleOAuthProvider>
                  </Route>
                </ScrollToTop>
              </Switch>
            </SnackbarProvider>
          </ThemeProviderCustom>
        </Provider>
      </LocalizationProvider>
    </Router>
  );
};

export default App;
