import { Box, Fade } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import urlJoin from 'url-join';
import { isEhp } from 'utils';

import CollapseDrawerButton from './CollapseDrawerButton';
import { useNavbarContextProvider } from './NavbarContextProvider';
import { DRAWER_TRANSITION_DURATION } from './utils';

export const APP_NAME = 'Pongo';
const EHP_BANNER_FILE_NAME = 'ehpPongoBanner.svg';
const STANDARD_LOGO_FILE_NAME = 'logoPongo.svg';
const logoStyle = () => {
  if (isEhp) {
    return { width: '90%', height: '100%' };
  }
  return { maxHeight: '42px' };
};

const imageName = () => {
  if (isEhp) {
    return EHP_BANNER_FILE_NAME;
  }
  return STANDARD_LOGO_FILE_NAME;
};

export default function DrawerHeader() {
  const { isDrawerOpen } = useNavbarContextProvider();

  return (
    <Box
      sx={{
        display: 'flex',
        mb: 2,
        direction: 'row',
        alignItems: 'center',
        justifyContent: isDrawerOpen ? 'space-between' : 'center',
      }}
    >
      <Link to={'/home'}>
        <Fade
          in={isDrawerOpen}
          timeout={{
            enter: DRAWER_TRANSITION_DURATION,
            exit: 0,
          }}
          unmountOnExit
        >
          <img
            alt={APP_NAME}
            style={logoStyle()}
            src={urlJoin('/', process.env.PUBLIC_URL, 'img', imageName())}
          />
        </Fade>
      </Link>
      <CollapseDrawerButton />
    </Box>
  );
}
