import { InputBase, MenuItem, Select, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import React from 'react';

const createYearList = (startYear: number, endYear: number) => {
  const yearList = [];
  for (let year = endYear; year >= startYear; year--) {
    yearList.push(year);
  }
  return yearList;
};

interface YearSelectorProperties {
  currentYear: Moment;
  setCurrentYear: (newYear: Moment) => void;
  sx?: SxProps;
  from?: Moment;
  to?: Moment;
}

export default function YearSelector({
  currentYear,
  setCurrentYear,
  sx,
  from = moment('2020-01-01'),
  to = moment().add(1, 'year'),
}: YearSelectorProperties) {
  const yearList = createYearList(
    Math.min(from.year(), currentYear.year()),
    Math.max(to.year(), currentYear.year())
  );

  return (
    <Select
      labelId="year-select"
      id="year-select"
      value={currentYear.year()}
      onChange={(event) => {
        const value = event.target.value as number;
        setCurrentYear(moment(value, 'YYYY'));
      }}
      input={<InputBase />}
      MenuProps={{
        sx: {
          '& .MuiPaper-root': {
            bgcolor: 'yearSelectorBlue.main',
            color: 'yearSelectorBlue.contrastText',
          },
        },
      }}
      sx={{
        width: '100px',
        height: '40px',
        ...sx,
        '& .MuiInputBase-input': {
          borderRadius: '12px',
          border: '4px solid',
          borderColor: 'yearSelectorBlue.main',
          bgcolor: 'yearSelectorBlue.light',
          '& .MuiSelect-icon': {
            color: 'yearSelectorBlue.contrastText',
          },
        },
        '& .MuiSelect-select:focus': {
          borderRadius: '12px',
        },
        '& .MuiSelect-select': {
          pl: 1,
        },
      }}
    >
      {_.map(yearList, (year) => (
        <MenuItem
          key={`select-${year}`}
          value={year}
          sx={{
            '&.Mui-selected': {
              color: 'yearSelectorBlue.contrastText',
              bgcolor: 'yearSelectorBlue.light',
              '&:focus': {
                bgcolor: 'yearSelectorBlue.light',
              },
            },
            '&.Mui-selected:hover': {
              bgcolor: 'yearSelectorBlue.light',
            },
            '&:hover': {
              bgcolor: 'yearSelectorBlue.light',
            },
          }}
        >
          <Typography
            variant={'h2Bold'}
            component="h2"
            color={'yearSelectorBlue.contrastText'}
          >
            {year}
          </Typography>
        </MenuItem>
      ))}
    </Select>
  );
}
