import { DialogContentText, Grid, Typography } from '@mui/material';
import DatePicker from 'components/commons/DatePicker';
import moment from 'moment';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { INPUT_XS, LABEL_XS } from './ExportBillDataForm';

const EXPORT_UE_BILLS_DATA_BODY =
  'Téléchargez ici les données des factures facturés de tous les clients, \
    selon le mois de facturation de votre choix.';

export default function ExportAccountantForm() {
  const form = useFormContext();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DialogContentText id="alert-dialog-description" sx={{ py: 2 }}>
          {EXPORT_UE_BILLS_DATA_BODY}
        </DialogContentText>
      </Grid>
      <Controller
        name="exportAccountant.month"
        control={form.control}
        render={({ onChange, value }) => (
          <MonthFormFilter
            labelText="Date de facturation :"
            onChange={(value: Date | null) => {
              onChange(value);
            }}
            value={value}
          />
        )}
      />
    </Grid>
  );
}

export function MonthFormFilter({
  labelText,
  onChange,
  value,
}: {
  labelText: string;
  onChange: (value: Date | null) => void;
  value: Date;
}) {
  return (
    <>
      <Grid
        item
        xs={LABEL_XS}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }}>{labelText}</Typography>
      </Grid>
      <Grid
        item
        xs={INPUT_XS}
        sx={{
          '& .MuiFormControl-root': {
            width: '100%',
            '& .MuiInputBase-input': {
              textAlign: 'center',
            },
          },
        }}
      >
        <DatePicker
          views={['year', 'month']}
          onChange={onChange}
          value={value}
          maxDate={moment().toDate()}
          minDate={moment('2020-01-01').toDate()}
        />
      </Grid>
    </>
  );
}
