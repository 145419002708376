import { Stack, SxProps } from '@mui/material';
import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import { Area } from 'react-easy-crop/types';

interface PolyCropperProperties {
  image: string;
  onCropComplete: (croppedArea: Area, croppedAreaPixels: Area) => void;
  sx?: SxProps;
}

export default function PolyCropper({
  image,
  onCropComplete,
  sx,
}: PolyCropperProperties) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  return (
    <Stack sx={sx}>
      <Cropper
        image={image}
        crop={crop}
        zoom={zoom}
        zoomSpeed={0.25}
        aspect={1}
        onCropChange={setCrop}
        onZoomChange={setZoom}
        onCropComplete={onCropComplete}
        objectFit="horizontal-cover"
        restrictPosition
      />
    </Stack>
  );
}
