import { Chip } from '@mui/material';
import clsx from 'clsx';
import DisplayBillStatus from 'components/MissionFollowUp/OverallListBill/DisplayStatus/DisplayBillStatus';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import { BillingBillStatusChoices } from 'generated/graphql';
import _ from 'lodash';
import React from 'react';

import styles from './styles/BillStatusChip.module.scss';

interface BillStatusChipProps {
  status: BillingBillStatusChoices;
  onClick?: () => void;
  isActive: boolean;
}

const BillStatusChip = (properties: BillStatusChipProps) => {
  const SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED =
    useEnvironmentVariable('send_bill_email');

  const stylesChecked = {
    TO_BILL: SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED
      ? styles.toBillNewChecked
      : styles.toBillChecked,
    DRAFT: styles.draftChecked,
    TO_CANCEL: styles.toCancelChecked,
    CANCELED: styles.canceledChecked,
    BILLED: SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED
      ? styles.billedChecked
      : styles.sentChecked,
    SENT: styles.sentChecked,
  };

  return (
    <Chip
      className={clsx(styles.billChip, {
        [_.get(stylesChecked, properties.status)]: properties.isActive,
      })}
      variant={properties.isActive ? 'filled' : 'outlined'}
      label={DisplayBillStatus({
        status: properties.status,
        checked: properties.isActive,
        iconSize: '25px',
      })}
      onClick={properties.onClick}
      size={'small'}
    />
  );
};

export default BillStatusChip;
