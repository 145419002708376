import { Stack, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import PolyFooter from 'components/MUIOverload/PolyFooter';
import PongoButton from 'components/MUIOverload/PongoButton';
import EmployeeAvatarRenderer from 'components/Phonebook/EmployeeCard/EmployeeAvatarRenderer/EmployeeAvatarRenderer';
import {
  AvatarDisplayMode,
  AvatarDisplaySize,
} from 'components/Phonebook/utils';
import { useUserInfo } from 'components/User/UserProvider';
import { EmployeeNode } from 'generated/graphql';
import React from 'react';

import styles from '../styles/ActiveBody.module.scss';

interface StepFourProps {
  onHandleBack: () => void;
  onHandleNext: () => void;
}

export const addProfilePictureStepActiveTitle = 'Photo de profil';
export const addProfilePictureStepActiveBody =
  'Vous pouvez dès à présent ajouter une photo à votre profil.\n' +
  'Celle-ci permettra à vos collègues de vous reconnaitre plus facilement !';

const AddProfilePictureStep = (properties: StepFourProps) => {
  const theme = useTheme();
  const userInfo = useUserInfo();
  const onHandleNext = async () => {
    properties.onHandleNext();
  };

  return (
    <div>
      <div className={styles.root}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Box
            sx={{
              maxWidth: '60%',
            }}
          >
            <div className={styles.title}>
              {addProfilePictureStepActiveTitle}
            </div>
            <Box mt={10}>{addProfilePictureStepActiveBody}</Box>
          </Box>
          <EmployeeAvatarRenderer
            employee={userInfo.employee as EmployeeNode}
            parameters={{
              mode: AvatarDisplayMode.INITIALIZATION,
              size: AvatarDisplaySize.LARGE,
              backgroundColor: theme.palette.grey[100],
            }}
          />
        </Stack>
      </div>
      <PolyFooter>
        <div className={styles.btnMargin}>
          <PongoButton
            buttonStyle="secondary"
            variant="contained"
            onClick={properties.onHandleBack}
          >
            Precedent
          </PongoButton>
          <PongoButton
            color="primary"
            variant="contained"
            onClick={onHandleNext}
          >
            Suivant
          </PongoButton>
        </div>
      </PolyFooter>
    </div>
  );
};

export default AddProfilePictureStep;
