import { ClickAwayListener, Popper } from '@mui/base';
import LogoutIcon from '@mui/icons-material/Logout';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import {
  Avatar,
  Box,
  Button,
  Grow,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { getEmployeeOccupationInfo } from 'components/Phonebook/EmployeeCard/EmployeeOccupationIndicator/EmployeeOccupationIndicator';
import { useAuth } from 'components/User/Authenticator';
import { useUserInfo } from 'components/User/UserProvider';
import { EmployeeNode } from 'generated/graphql';
import { useImageRequest } from 'hooks/useImageRequest';
import { AVATAR_PONGO_PATH } from 'poly-constants';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { displayEmployeeNode, isDev, isEhp, OpacitySuffix } from 'utils';

import { useNavbarContextProvider } from './NavbarContextProvider';
import { DRAWER_TRANSITION_DURATION } from './utils';

const GAP_BETWEEN_ELEMENTS = 2;

export default function DrawerFooter() {
  const { employee } = useUserInfo();
  const { isDrawerOpen } = useNavbarContextProvider();
  const employeeNode = employee as EmployeeNode;
  const [image, setImage] = useState<string | undefined>(undefined);
  const theme = useTheme();

  const { response: imageReceived, loading } = useImageRequest(
    employee?.minifiedProfilePicture?.filename
  );

  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(
    null
  );
  const isPopperOpen = useRef(false);
  const id = isPopperOpen.current ? 'simple-popper' : undefined;

  const handlePopper = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (event) {
      event.stopPropagation();
    }
    const target = anchorElement ? null : event.currentTarget;
    if (!isPopperOpen.current) {
      isPopperOpen.current = !!target;
    }
    setAnchorElement(target);
  };

  const closePopper = (_event: MouseEvent | TouchEvent | null = null) => {
    isPopperOpen.current = false;
    setTimeout(() => {
      if (!isPopperOpen.current) {
        setAnchorElement(null);
      }
    }, 200);
  };

  useEffect(() => {
    if (employee?.minifiedProfilePicture?.filename) {
      if (!loading) {
        setImage(imageReceived);
      }
    } else {
      setImage(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageReceived, loading]);

  return (
    <DrawerFooterWrapper
      isDrawerOpen={isDrawerOpen}
      isPopperOpen={isPopperOpen.current}
      handlePopper={handlePopper}
      handlePopperClose={closePopper}
    >
      <Box
        component={isDrawerOpen ? 'div' : IconButton}
        sx={{
          borderRadius: '50%',
          width: '48px',
          height: '48px',
          ml: isDrawerOpen ? 3 : 0,
          mr: isDrawerOpen ? 1 : 0,
        }}
      >
        <Avatar
          src={image || AVATAR_PONGO_PATH}
          sx={{ width: '48px', height: '48px' }}
        />
      </Box>
      <Grow
        in={isDrawerOpen}
        timeout={{
          enter: DRAWER_TRANSITION_DURATION,
          exit: DRAWER_TRANSITION_DURATION / 3,
        }}
        unmountOnExit
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            width: 'fill-available',
            minWidth: 0,
          }}
        >
          <Stack
            alignItems={'flex-start'}
            sx={{
              minWidth: 0,
            }}
          >
            <Typography
              variant="bodyBold"
              sx={{
                display: 'inline-flex',
                width: 'fill-available',
                color: theme.palette.text.primary,
              }}
              noWrap
            >
              {displayEmployeeNode(employeeNode, false)}
            </Typography>
            <Typography
              sx={{
                display: 'inline-flex',
                width: 'fill-available',
                color: theme.palette.text.primary,
              }}
              noWrap
            >
              {
                getEmployeeOccupationInfo(employeeNode.occupation, true)
                  .occupationName
              }
            </Typography>
          </Stack>
        </Stack>
      </Grow>
      {anchorElement && (
        <ProfilePopper
          id={id}
          open={isPopperOpen.current}
          anchorElement={anchorElement}
          onClickAway={closePopper}
        />
      )}
    </DrawerFooterWrapper>
  );
}

function DrawerFooterWrapper({
  children,
  isPopperOpen,
  isDrawerOpen,
  handlePopper,
  handlePopperClose,
}: {
  children: React.ReactNode;
  isPopperOpen: boolean;
  isDrawerOpen: boolean;
  handlePopper: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handlePopperClose: () => void;
}): JSX.Element {
  const theme = useTheme();

  const getFooterBackgroundColor = () => {
    if (isEhp) {
      return theme.palette.secondary.dark + OpacitySuffix.THIRTY_PERCENT;
    }
    if (isDev) {
      return theme.palette.success.dark + OpacitySuffix.THIRTY_PERCENT;
    }
    return theme.palette.disableGrey.main + OpacitySuffix.THIRTY_PERCENT;
  };

  if (isDrawerOpen) {
    return (
      <Button
        sx={{
          minWidth: '',
          textTransform: 'none',
          ...(isPopperOpen && {
            backgroundColor:
              theme.palette.info.light + OpacitySuffix.TWENTY_PERCENT,
          }),
          '&& .MuiTouchRipple-child': {
            backgroundColor: theme.palette.info.light,
          },
          display: 'flex',
          flexDirection: 'row',
          gap: GAP_BETWEEN_ELEMENTS,
          mx: -3.5,
          mb: -3,
          p: 0,
          borderRadius: 0,
          height: 70,
          alignItems: 'center',
          backgroundColor: getFooterBackgroundColor(),
          color: theme.palette.disableGrey.main,
          justifyContent: 'center',
          '&:hover': {
            backgroundColor: theme.palette.primary.darker,
            '& .MuiTypography-root': { color: theme.palette.info.light },
          },
        }}
        onMouseEnter={handlePopper}
        onMouseLeave={handlePopperClose}
      >
        {children}
      </Button>
    );
  }

  return (
    <Button
      sx={{
        display: 'flex',
        ...(isPopperOpen && {
          backgroundColor:
            theme.palette.info.light + OpacitySuffix.TWENTY_PERCENT,
        }),
        top: 24,
        borderRadius: 0,
        minHeight: 70,
        alignItems: 'center',
        backgroundColor: getFooterBackgroundColor(),
        justifyContent: 'center',
        '&:hover': { backgroundColor: theme.palette.primary.darker },
      }}
      onMouseEnter={handlePopper}
      onMouseLeave={handlePopperClose}
    >
      {children}
    </Button>
  );
}

function ProfilePopper({
  id,
  open: isPopperOpen,
  anchorElement,
  onClickAway: closePopper,
}: {
  id: string | undefined;
  open: boolean;
  anchorElement: HTMLButtonElement | null;
  onClickAway: (event: MouseEvent | TouchEvent | null) => void;
}): JSX.Element {
  const theme = useTheme();
  const history = useHistory();
  const auth = useAuth();

  return (
    <ClickAwayListener
      onClickAway={closePopper}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <Popper
        id={id}
        open={isPopperOpen}
        anchorEl={anchorElement}
        disablePortal={true}
        placement={'right'}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor:
              theme.palette.primary.darker + OpacitySuffix.SEVENTY_PERCENT,
            borderRadius: '5px',
            position: 'relative',
            ml: 1,
          }}
        >
          <ProfilPopperButton
            title="Mon profil"
            icon={<PermIdentityIcon sx={{ color: 'white', pr: 1 }} />}
            onClick={() => {
              history.push('/user');
              closePopper(null);
            }}
          />
          <ProfilPopperButton
            title="Déconnexion"
            icon={<LogoutIcon sx={{ color: 'white', pr: 1 }} />}
            onClick={() => auth.logout()}
          />
        </Box>
      </Popper>
    </ClickAwayListener>
  );
}

function ProfilPopperButton({
  title,
  icon,
  onClick,
}: {
  title: string;
  icon: React.ReactNode;
  onClick: () => void;
}) {
  const theme = useTheme();
  return (
    <Button
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        '&:hover': {
          backgroundColor:
            theme.palette.primary.darker + OpacitySuffix.EIGHTY_PERCENT,
        },
      }}
      onClick={onClick}
    >
      {icon}
      <Typography sx={{ color: 'white', textTransform: 'capitalize' }}>
        {title}
      </Typography>
    </Button>
  );
}
