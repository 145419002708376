import PolyCheckbox from 'components/MUIOverload/PolyCheckbox';
import PolyFooter from 'components/MUIOverload/PolyFooter';
import PongoButton from 'components/MUIOverload/PongoButton';
import React, { useState } from 'react';

import styles from '../styles/ActiveBody.module.scss';

interface StepOneProps {
  onHandleBack: () => void;
  onHandleNext: () => void;
  sendValidationBack: () => Promise<boolean | undefined>;
}

export const confirmationStepActiveTitle = 'Suivi d’activité';
export const confirmationStepActiveBody =
  'Le temps de travail contractuel est de 39h hebdomadaires (35h pour les stagiaires et alternants).\n' +
  'Pour compenser les heures qui pourraient être effectuées en dépassement de ces 39h hebdomadaires, 8 jours de RTT sont attribués aux collaborateurs (tel que prévu à l’article 3.2 de l’accord collectif relatif à l’aménagement du temps de travail).\n' +
  'Les stagiaires et les alternants ne sont pas concernés par ces RTT.';
export const confirmationStepActiveCheckBox = 'J’ai compris';
const ConfirmationStepActive = (properties: StepOneProps) => {
  const [checked, setChecked] = useState(false);

  const onHandleNext = async () => {
    if (await properties.sendValidationBack()) {
      properties.onHandleNext();
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <div>
      <div className={styles.root}>
        <div className={styles.title}>{confirmationStepActiveTitle}</div>
        <div className={styles.body}>{confirmationStepActiveBody}</div>
        <div className={styles.checkbox}>
          <PolyCheckbox
            label={confirmationStepActiveCheckBox}
            properties={{
              name: 'certifyValidity',
              color: 'primary',
              onChange: onChange,
            }}
          />
        </div>
      </div>
      <PolyFooter>
        <div className={styles.btnMargin}>
          <PongoButton
            buttonStyle="secondary"
            variant="contained"
            onClick={properties.onHandleBack}
          >
            Precedent
          </PongoButton>
          <PongoButton
            color="primary"
            variant="contained"
            onClick={onHandleNext}
            disabled={!checked}
          >
            Suivant
          </PongoButton>
        </div>
      </PolyFooter>
    </div>
  );
};

export default ConfirmationStepActive;
