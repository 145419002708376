import { useEffect, useState } from 'react';
import { getGoogleClientId } from 'services/login';

export const useGoogleClientId = () => {
  const [googleClientId, setGoogleClientId] = useState<string>();

  useEffect(() => {
    const loadGoogleClientId = async () => {
      try {
        const id = await getGoogleClientId();
        setGoogleClientId(id);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    loadGoogleClientId();
  }, []);

  return googleClientId;
};
