import { Search } from '@mui/icons-material';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { EmployeeType, setCreatorFilter } from 'store/users';
import { displayEmployee } from 'utils';

import styles from './styles/Filters.module.scss';

interface FilterCreatorProps {
  filterWidth: number;
}

const FilterCreator = ({ filterWidth }: FilterCreatorProps) => {
  const dispatch = useDispatch();
  const creatorFilter = useSelector((state) => state.users.creatorFilter);
  const allBills = useSelector((state) => state.purchaseOrder.allBills);
  const creators = _.sortBy(
    _.uniqBy(
      _.flatMap(allBills, (bill) => {
        return (bill.creator as EmployeeType) || [];
      }),
      'id'
    ),
    ['lastName', 'firstName']
  );

  return (
    <Autocomplete
      autoHighlight
      autoComplete
      id="filter-select-creator"
      value={creatorFilter}
      options={creators}
      className={styles.filterSelect}
      onChange={(event, value: EmployeeType | null) => {
        dispatch(setCreatorFilter(value));
      }}
      getOptionLabel={(creator) => {
        if (creator) {
          return displayEmployee(creator.firstName, creator.lastName);
        }
        return '';
      }}
      sx={{ width: filterWidth }}
      renderInput={(parameters) => (
        <TextField
          variant="standard"
          label={'Créateur de facture'}
          {...parameters}
          InputProps={{
            ...parameters.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default FilterCreator;
