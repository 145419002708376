import { Box, LinearProgress } from '@mui/material';
import React from 'react';

interface Props {
  loading: boolean;
  fullHeight?: boolean;
}

export function LoadingProgress({ loading }: { loading: boolean }) {
  return loading ? <LinearProgress sx={{ color: 'primary.main' }} /> : <></>;
}

export default function LoadingPage({
  fullHeight,
  children,
  loading,
}: React.PropsWithChildren<Props>): JSX.Element {
  return (
    <Box sx={{ position: 'relative', ...(fullHeight && { height: '100%' }) }}>
      <LoadingProgress loading={loading} />
      {children}
    </Box>
  );
}
