import { createTheme } from '@mui/material/styles';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { CSSProperties } from 'react';
import { OpacitySuffix } from 'utils';

const FONT_WEIGHT_LIGHT = 300;
const FONT_WEIGHT_REGULAR = 400;
const FONT_WEIGHT_MEDIUM = 500;
const FONT_WEIGHT_SEMIBOLD = 600;
const FONT_WEIGHT_BOLD = 700;

const H1_BASE = {
  fontSize: '32px',
};
const H2_BASE = {
  fontSize: '24px',
};
const H3_BASE = {
  fontSize: '20px',
};
const BODY_BASE = {
  fontSize: '16px',
};
const BODYS_BASE = {
  fontSize: '14px',
};
const BUTTON_BASE = {
  fontSize: '14px',
};
const LABEL_BASE = {
  fontSize: '12px',
};

declare module '@mui/material/styles' {
  // eslint-disable-next-line
  export interface PaletteColor extends ColorPartial {}
  interface TypographyVariants {
    h1Light: CSSProperties;
    h1Medium: CSSProperties;
    h1SemiBold: CSSProperties;
    h1Bold: CSSProperties;

    h2Light: CSSProperties;
    h2Bold: CSSProperties;
    h2Medium: CSSProperties;
    h2SemiBold: CSSProperties;

    h3Light: CSSProperties;
    h3Medium: CSSProperties;
    h3SemiBold: CSSProperties;
    h3Bold: CSSProperties;

    bodyLight: CSSProperties;
    body: CSSProperties;
    bodyMedium: CSSProperties;
    bodySemiBold: CSSProperties;
    bodyBold: CSSProperties;

    bodySLight: CSSProperties;
    bodyS: CSSProperties;
    bodySMedium: CSSProperties;
    bodySSemiBold: CSSProperties;
    bodySBold: CSSProperties;

    buttonLight: CSSProperties;
    button: CSSProperties;
    buttonMedium: CSSProperties;
    buttonSemiBold: CSSProperties;
    buttonBold: CSSProperties;

    buttonTertiaryLight: CSSProperties;
    buttonTertiary: CSSProperties;
    buttonTertiaryMedium: CSSProperties;
    buttonTertiarySemiBold: CSSProperties;
    buttonTertiaryBold: CSSProperties;

    labelLight: CSSProperties;
    label: CSSProperties;
    labelMedium: CSSProperties;
    labelSemiBold: CSSProperties;
    labelBold: CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h1Light?: CSSProperties;
    h1Medium?: CSSProperties;
    h1SemiBold?: CSSProperties;
    h1Bold?: CSSProperties;

    h2Light?: CSSProperties;
    h2Bold?: CSSProperties;
    h2Medium?: CSSProperties;
    h2SemiBold?: CSSProperties;

    h3Light?: CSSProperties;
    h3Medium?: CSSProperties;
    h3SemiBold?: CSSProperties;
    h3Bold?: CSSProperties;

    bodyLight?: CSSProperties;
    body?: CSSProperties;
    bodyMedium?: CSSProperties;
    bodySemiBold?: CSSProperties;
    bodyBold?: CSSProperties;

    bodySLight?: CSSProperties;
    bodyS?: CSSProperties;
    bodySMedium?: CSSProperties;
    bodySSemiBold?: CSSProperties;
    bodySBold?: CSSProperties;

    buttonLight?: CSSProperties;
    button?: CSSProperties;
    buttonMedium?: CSSProperties;
    buttonSemiBold?: CSSProperties;
    buttonBold?: CSSProperties;

    buttonTertiaryLight?: CSSProperties;
    buttonTertiary?: CSSProperties;
    buttonTertiaryMedium?: CSSProperties;
    buttonTertiarySemiBold?: CSSProperties;
    buttonTertiaryBold?: CSSProperties;

    labelLight?: CSSProperties;
    label?: CSSProperties;
    labelMedium?: CSSProperties;
    labelSemiBold?: CSSProperties;
    labelBold?: CSSProperties;
  }

  interface Palette {
    oxfordBlue: Palette['primary'];
    comanBlue: Palette['primary'];
    comexOrange: Palette['primary'];
    darkGreen: Palette['primary'];
    orange: Palette['primary'];
    altOrange: Palette['primary'];
    purple: Palette['primary'];
    deepPurple: Palette['primary'];
    modalBackground: Palette['primary'];
    contrastText: Palette['primary'];
    lightGrey: Palette['primary'];
    darkGrey: Palette['primary'];
    standardGrey: Palette['primary'];
    holidayGrey: Palette['primary'];
    disableGrey: Palette['primary'];
    backgroundHover: Palette['primary'];
    black: Palette['primary'];
    darkRed: Palette['primary'];
    devGreen: Palette['primary'];
    ehpBlue: Palette['primary'];
    tabHeaderBlue: Palette['primary'];
    yearSelectorBlue: Palette['primary'];
  }
  interface PaletteOptions {
    oxfordBlue?: PaletteOptions['primary'];
    comanBlue?: PaletteOptions['primary'];
    comexOrange?: PaletteOptions['primary'];
    darkGreen?: PaletteOptions['primary'];
    orange?: PaletteOptions['primary'];
    altOrange?: PaletteOptions['primary'];
    purple?: PaletteOptions['primary'];
    deepPurple?: PaletteOptions['primary'];
    modalBackground?: PaletteOptions['primary'];
    contrastText?: PaletteOptions['primary'];
    lightGrey?: PaletteOptions['primary'];
    darkGrey?: PaletteOptions['primary'];
    standardGrey?: PaletteOptions['primary'];
    holidayGrey?: PaletteOptions['primary'];
    disableGrey?: PaletteOptions['primary'];
    backgroundHover?: PaletteOptions['primary'];
    black?: PaletteOptions['primary'];
    darkRed?: PaletteOptions['primary'];
    devGreen?: PaletteOptions['primary'];
    ehpBlue?: PaletteOptions['primary'];
    tabHeaderBlue?: PaletteOptions['primary'];
    yearSelectorBlue?: PaletteOptions['primary'];
  }
  interface PaletteColor {
    darker?: string;
  }

  interface SimplePaletteColorOptions {
    darker?: string;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    comex: true;
    coman: true;
    grade: true;
    passed: true;
    upcoming: true;
    pending: true;
  }
  interface ChipPropsColorOverrides {
    lightBlue: true;
    comanBlue: true;
    comexOrange: true;
    darkGreen: true;
    orange: true;
    altOrange: true;
    purple: true;
    deepPurple: true;
    altRed: true;
    holidayGrey: true;
    backgroundHover: true;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1Light: true;
    h1Medium: true;
    h1SemiBold: true;
    h1Bold: true;

    h2Light: true;
    h2Bold: true;
    h2Medium: true;
    h2SemiBold: true;

    h3Light: true;
    h3Medium: true;
    h3SemiBold: true;
    h3Bold: true;

    bodyLight: true;
    body: true;
    bodyMedium: true;
    bodySemiBold: true;
    bodyBold: true;

    bodySLight: true;
    bodyS: true;
    bodySMedium: true;
    bodySSemiBold: true;
    bodySBold: true;

    buttonLight: true;
    button: true;
    buttonMedium: true;
    buttonSemiBold: true;
    buttonBold: true;

    buttonTertiaryLight: true;
    buttonTertiary: true;
    buttonTertiaryMedium: true;
    buttonTertiarySemiBold: true;
    buttonTertiaryBold: true;

    labelLight: true;
    label: true;
    labelMedium: true;
    labelSemiBold: true;
    labelBold: true;
  }
}

const colorSystem = createTheme({
  typography: {
    fontFamily: [
      'Outfit',
      'Open Sans',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
    allVariants: {
      color: '#011638',
    },
    h1Light: { ...H1_BASE, fontWeight: FONT_WEIGHT_LIGHT },
    h1: { ...H1_BASE, fontWeight: FONT_WEIGHT_REGULAR },
    h1Medium: { ...H1_BASE, fontWeight: FONT_WEIGHT_MEDIUM },
    h1SemiBold: { ...H1_BASE, fontWeight: FONT_WEIGHT_SEMIBOLD },
    h1Bold: { ...H1_BASE, fontWeight: FONT_WEIGHT_BOLD },

    h2Light: { ...H2_BASE, fontWeight: FONT_WEIGHT_LIGHT },
    h2: { ...H2_BASE, fontWeight: FONT_WEIGHT_REGULAR },
    h2Medium: { ...H2_BASE, fontWeight: FONT_WEIGHT_MEDIUM },
    h2SemiBold: { ...H2_BASE, fontWeight: FONT_WEIGHT_SEMIBOLD },
    h2Bold: { ...H2_BASE, fontWeight: FONT_WEIGHT_BOLD },

    h3Light: { ...H3_BASE, fontWeight: FONT_WEIGHT_LIGHT },
    h3: { ...H3_BASE, fontWeight: FONT_WEIGHT_REGULAR },
    h3Medium: { ...H3_BASE, fontWeight: FONT_WEIGHT_MEDIUM },
    h3SemiBold: { ...H3_BASE, fontWeight: FONT_WEIGHT_SEMIBOLD },
    h3Bold: { ...H3_BASE, fontWeight: FONT_WEIGHT_BOLD },

    bodyLight: { ...BODY_BASE, fontWeight: FONT_WEIGHT_LIGHT },
    body: { ...BODY_BASE, fontWeight: FONT_WEIGHT_REGULAR },
    bodyMedium: { ...BODY_BASE, fontWeight: FONT_WEIGHT_MEDIUM },
    bodySemiBold: { ...BODY_BASE, fontWeight: FONT_WEIGHT_SEMIBOLD },
    bodyBold: { ...BODY_BASE, fontWeight: FONT_WEIGHT_BOLD },

    bodySLight: { ...BODYS_BASE, fontWeight: FONT_WEIGHT_LIGHT },
    bodyS: { ...BODYS_BASE, fontWeight: FONT_WEIGHT_REGULAR },
    bodySMedium: { ...BODYS_BASE, fontWeight: FONT_WEIGHT_MEDIUM },
    bodySSemiBold: { ...BODYS_BASE, fontWeight: FONT_WEIGHT_SEMIBOLD },
    bodySBold: { ...BODYS_BASE, fontWeight: FONT_WEIGHT_BOLD },

    buttonLight: { ...BUTTON_BASE, fontWeight: FONT_WEIGHT_LIGHT },
    button: { ...BUTTON_BASE, fontWeight: FONT_WEIGHT_REGULAR },
    buttonMedium: { ...BUTTON_BASE, fontWeight: FONT_WEIGHT_MEDIUM },
    buttonSemiBold: { ...BUTTON_BASE, fontWeight: FONT_WEIGHT_SEMIBOLD },
    buttonBold: { ...BUTTON_BASE, fontWeight: FONT_WEIGHT_BOLD },

    buttonTertiaryLight: { ...BUTTON_BASE, fontWeight: FONT_WEIGHT_LIGHT },
    buttonTertiary: { ...BUTTON_BASE, fontWeight: FONT_WEIGHT_REGULAR },
    buttonTertiaryMedium: { ...BUTTON_BASE, fontWeight: FONT_WEIGHT_MEDIUM },
    buttonTertiarySemiBold: {
      ...BUTTON_BASE,
      fontWeight: FONT_WEIGHT_SEMIBOLD,
    },
    buttonTertiaryBold: { ...BUTTON_BASE, fontWeight: FONT_WEIGHT_BOLD },

    labelLight: { ...LABEL_BASE, fontWeight: FONT_WEIGHT_LIGHT },
    label: { ...LABEL_BASE, fontWeight: FONT_WEIGHT_REGULAR },
    labelMedium: { ...LABEL_BASE, fontWeight: FONT_WEIGHT_MEDIUM },
    labelSemiBold: { ...LABEL_BASE, fontWeight: FONT_WEIGHT_SEMIBOLD },
    labelBold: { ...LABEL_BASE, fontWeight: FONT_WEIGHT_BOLD },
  },
  palette: {
    mode: 'light',
    // Figma colors
    primary: {
      main: '#2D33FF',
      dark: '#252AD0',
      darker: '#011638',
      light: '#DBE8FF',
    },
    secondary: {
      main: '#356496',
      light: '#DBE8FF',
      dark: '#284D75',
    },
    info: {
      main: '#356496',
      light: '#DBE8FF',
    },
    text: {
      primary: '#011638',
      secondary: '#7A8596',
    },
    warning: {
      main: '#ED6C02',
      dark: '#E65100',
      light: '#FFFCF8',
    },
    error: {
      main: '#D83A52',
      dark: '#B63546',
      light: '#F4C3CB',
    },
    success: {
      main: '#4AC04F',
      dark: '#3B9C3F',
      light: '#E6FBEA',
    },
    standardGrey: {
      main: '#F5F6F8',
      dark: '#F2F3F3',
      light: '#525966',
    },
    disableGrey: {
      main: '#C5C7D0',
    },
    background: {
      paper: '#FFFFFF',
      default: '#F4F4F4',
    },
    // Other colors
    black: {
      main: '#212124',
    },
    contrastText: {
      main: '#FFFFFF',
    },
    lightGrey: {
      main: '#F9F9F9',
    },
    darkGrey: {
      main: '#E5E5E5',
      dark: '#BFBFBF',
      darker: '#7D7D7D',
    },
    holidayGrey: {
      main: 'rgba(0, 0, 0, 0.4)',
      contrastText: '#fff',
      light: 'rgba(0, 0, 0, 0.4)',
      dark: 'rgba(0, 0, 0, 0.4)',
    },
    backgroundHover: {
      main: '#EEE',
      contrastText: '#fff',
      light: '#EEE',
      dark: '#EEE',
    },
    darkRed: {
      main: '#830000',
    },
    devGreen: {
      main: '#F0FFF0',
    },
    ehpBlue: {
      main: '#CBE3F0',
    },
    // colors for the Home page's carousel:
    oxfordBlue: {
      main: '#011638',
      contrastText: '#fff',
    },
    comanBlue: {
      main: '#2D33FF',
      contrastText: '#fff',
    },
    comexOrange: {
      main: '#FF962D',
      contrastText: '#fff',
    },
    darkGreen: {
      main: '#357a38',
      contrastText: '#fff',
    },
    orange: {
      main: '#ff9800',
      contrastText: '#fff',
    },
    altOrange: {
      main: '#FF5733',
      contrastText: '#fff',
    },
    purple: {
      main: '#A259FF',
      contrastText: '#fff',
    },
    deepPurple: {
      main: '#673ab7',
      contrastText: '#fff',
    },
    modalBackground: {
      main: '#F6F6F6',
    },
    tabHeaderBlue: {
      main: '#252AD0',
      light: '#DBE8FF',
    },
    yearSelectorBlue: {
      main: '#011638',
      light: '#4D566C',
      contrastText: '#DBE8FF',
    },
  },
});

export const designSystem = createTheme(colorSystem, {
  components: {
    MuiChip: {
      variants: [
        {
          props: { variant: 'grade' },
          style: {
            backgroundColor: colorSystem.palette.text.primary,
            borderRadius: '4px',
            color: 'white',
            fontWeight: '700',
            fontSize: '0.75rem',
            height: '20px',
            maxWidth: 'fit-content',
          },
        },
        {
          props: { variant: 'comex' },
          style: {
            backgroundColor: colorSystem.palette.comexOrange.main,
            borderRadius: '4px',
            color: 'white',
            fontWeight: '700',
            fontSize: '0.75rem',
            height: '20px',
            maxWidth: 'fit-content',
          },
        },
        {
          props: { variant: 'coman' },
          style: {
            backgroundColor: colorSystem.palette.comanBlue.main,
            borderRadius: '4px',
            color: 'white',
            fontWeight: '700',
            fontSize: '0.75rem',
            height: '20px',
            maxWidth: 'fit-content',
          },
        },
        {
          props: { variant: 'passed' },
          style: {
            backgroundColor: colorSystem.palette.primary.main,
            borderRadius: '6px',
            color: 'white',
            fontWeight: '700',
            fontSize: '0.75rem',
            height: '20px',
            width: '90px',
          },
        },
        {
          props: { variant: 'upcoming' },
          style: {
            backgroundColor: '#E6FBEA',
            borderRadius: '6px',
            color: '#4AC04F',
            fontWeight: '700',
            fontSize: '0.75rem',
            height: '20px',
            width: '90px',
          },
        },
        {
          props: { variant: 'pending' },
          style: {
            backgroundColor: colorSystem.palette.secondary.main,
            borderRadius: '6px',
            color: colorSystem.palette.common.white,
            fontWeight: '700',
            fontSize: '0.75rem',
            height: '20px',
            width: '90px',
          },
        },
      ],
    },
    MuiTypography: {
      defaultProps: {
        color: '#011638',
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: 'red' },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        },
        standardSuccess: {
          backgroundColor:
            colorSystem.palette.success.main + OpacitySuffix.TWENTY_PERCENT,
        },
        standardError: {
          backgroundColor:
            colorSystem.palette.error.main + OpacitySuffix.TWENTY_PERCENT,
        },
        standardWarning: {
          backgroundColor:
            colorSystem.palette.warning.main + OpacitySuffix.TWENTY_PERCENT,
        },
        standardInfo: {
          backgroundColor:
            colorSystem.palette.info.main + OpacitySuffix.TWENTY_PERCENT,
        },
        outlinedSuccess: {
          borderColor: colorSystem.palette.success.main,
          backgroundColor: colorSystem.palette.success.light,
        },
        outlinedError: {
          borderColor: colorSystem.palette.error.main,
          backgroundColor:
            colorSystem.palette.error.main + OpacitySuffix.TEN_PERCENT,
        },
        outlinedWarning: {
          borderColor: colorSystem.palette.warning.main,
          backgroundColor: colorSystem.palette.warning.light,
        },
        outlinedInfo: {
          borderColor: colorSystem.palette.info.main,
          backgroundColor: colorSystem.palette.info.light,
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          fontWeight: 600,
          color: 'inherit',
        },
      },
    },
  },
});
