import { TableCell } from '@mui/material';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import ChildCollapsePolyTableRow from 'components/MUIOverload/PolyTableRow/ChildCollapsePolyTableRow';
import PolyTooltip from 'components/MUIOverload/PolyTooltip';
import { useTableContext } from 'components/Revenue/BilledRevenueTables/BilledRevenueMissionTable/context/TableContextProvider';
import { PurchaseOrderRevenue } from 'components/Revenue/BilledRevenueTables/BilledRevenueMissionTable/RevenueTableBodyByPo';
import DisplayValueTableCell from 'components/Revenue/Tables/RevenueTableCells/DisplayValueTableCell';
import OutOfRangeTableCell from 'components/Revenue/Tables/RevenueTableCells/OutOfRangeTableCell';
import { useUserInfo } from 'components/User/UserProvider';
import {
  BillingPurchaseOrderCurrencyChoices,
  EmployeeNode,
} from 'generated/graphql';
import _ from 'lodash';
import { totalFormat } from 'pages/ActivityPage/utils';
import { POLY_DATE_MONTH } from 'poly-constants';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { TransitionStatus } from 'react-transition-group/Transition';
import { isChiefOrDirectorOfActivity } from 'utils';

import styles from '../styles/TableCell.module.scss';

interface PurchaserOrderRowProps {
  poRevenue: PurchaseOrderRevenue;
  status: TransitionStatus;
  amountPerDisplayedMonths?: number[];
}

const PurchaserOrderRow = ({
  poRevenue,
  status,
  amountPerDisplayedMonths,
}: PurchaserOrderRowProps) => {
  const { displayedMonths, activity } = useTableContext();
  const history = useHistory();
  const { employee, isControl, isSuperuser } = useUserInfo();

  const purchaseOrderHref = history.createHref({
    pathname: `/mission-followup/missions/details/purchase_order`,
    search: `?po_id=${poRevenue.po.id}&mission_id=${activity.id}`,
  });

  const isActivityChiefOrDirector = isChiefOrDirectorOfActivity(
    activity,
    employee as EmployeeNode
  );

  const canAccessLink =
    activity.chiefs && activity.director && employee
      ? isActivityChiefOrDirector || isControl || isSuperuser
      : false;

  const totalCell = poRevenue.po.total ? (
    <span>{totalFormat(poRevenue.po.total, poRevenue.po.currency)}</span>
  ) : (
    <span>Non renseigné</span>
  );

  return (
    <>
      <ChildCollapsePolyTableRow
        status={status}
        className={styles.revenueRowHeight}
      >
        <TableCell className={styles.clientNameCell}>
          {canAccessLink && (
            <Link to={purchaseOrderHref} className={styles.link}>
              <PolyTooltip title={poRevenue.po.name}>
                <Typography
                  sx={{ fontSize: '14px' }}
                  className={styles.nameText}
                >
                  {poRevenue.po.name}
                </Typography>
              </PolyTooltip>
            </Link>
          )}
          {!canAccessLink && (
            <Typography sx={{ fontSize: '14px' }} className={styles.nameText}>
              {poRevenue.po.name}
            </Typography>
          )}
        </TableCell>
        <TableCell className={clsx(styles.totalColumn)}>{totalCell}</TableCell>

        {_.map(displayedMonths, (month, index) => {
          if (
            month.isSameOrAfter(poRevenue.po.periodBeginning, 'month') &&
            month.isSameOrBefore(poRevenue.po.periodEnding, 'month')
          ) {
            return (
              <DisplayValueTableCell
                key={`total-client-${poRevenue.po.id}-${month.format(
                  POLY_DATE_MONTH
                )}`}
              >
                {poRevenue.po.currency !==
                  BillingPurchaseOrderCurrencyChoices.Eur && (
                  <>
                    {totalFormat(
                      amountPerDisplayedMonths?.[index],
                      poRevenue.po.currency
                    )}
                    <br />
                  </>
                )}
                {totalFormat(
                  (amountPerDisplayedMonths?.[index] || 0) /
                    poRevenue.po.currencyConversionRate,
                  BillingPurchaseOrderCurrencyChoices.Eur
                )}
              </DisplayValueTableCell>
            );
          }

          return (
            <OutOfRangeTableCell
              title="Aucune facture facturée ce mois"
              key={`total-client-${poRevenue.po.id}-${month.format(
                POLY_DATE_MONTH
              )}`}
            />
          );
        })}
      </ChildCollapsePolyTableRow>
    </>
  );
};

export default PurchaserOrderRow;
