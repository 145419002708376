import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useHistory } from 'react-router-dom';

import styles from './styles/back.module.scss';

const ArrowBack = () => {
  const history = useHistory();

  return (
    <Button onClick={() => history.goBack()}>
      <div className={styles.backArrowContainer}>
        <ArrowBackIcon
          className={clsx(
            styles.backArrowContainerElement,
            styles.backArrowIcon
          )}
        />
        <span
          className={clsx(
            styles.backArrowContainerElement,
            styles.backArrowText
          )}
        >
          Retour
        </span>
      </div>
    </Button>
  );
};

export default ArrowBack;
