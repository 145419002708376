import { BillingActivityInformationNode } from 'generated/graphql';
import moment, { Moment } from 'moment';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { functionNotSet } from 'utils';

export enum TableMode {
  ACTUAL,
  BILLED,
}

export interface TableContextProviderProps {
  billingMissions: BillingActivityInformationNode[];
  mode: TableMode;
  children?: React.ReactNode;
}

export interface TableContextProps {
  displayedMonths: Moment[];
  incrementMonth: () => void;
  decrementMonth: () => void;
  setYear: (year: number) => void;
  dateRange: { start: Moment; end: Moment };
  setDateRange: (p: { start: Moment; end: Moment }) => void;
  billingMissions: BillingActivityInformationNode[];
  setBillingMissions: (bais: BillingActivityInformationNode[]) => void;
  mode: TableMode;
}

const TableContext = createContext<TableContextProps>({
  displayedMonths: [],
  incrementMonth: () => functionNotSet(),
  decrementMonth: () => functionNotSet(),
  setDateRange: () => functionNotSet(),
  setYear: () => functionNotSet(),
  dateRange: { end: moment(), start: moment() },
  billingMissions: [],
  setBillingMissions: () => functionNotSet(),
  mode: TableMode.ACTUAL,
});

export const useTableContext = () => {
  const tableContext = useContext(TableContext);
  if (!tableContext) {
    throw new Error('useContext() can only be used  inside a table');
  }
  return tableContext;
};

// Set displayed months range to start after january & end before december
const initDateRange = () => {
  const now = moment();
  const min = now.month() - 3 < 0 ? 0 : now.month() - 3;
  const max = min + 6 > 11 ? 11 : min + 5;

  return {
    start: moment().set('months', min),
    end: moment().set('months', max),
  };
};

export default function TableContextProvider({
  billingMissions: billingMissionsProps,
  mode,
  children,
}: TableContextProviderProps) {
  const [dateRange, setDateRange] = useState(initDateRange());
  const [billingMissions, setBillingMissions] = useState(billingMissionsProps);

  const incrementMonth = () => {
    setDateRange({
      start: dateRange.start.clone().add(1, 'month'),
      end: dateRange.end.clone().add(1, 'month'),
    });
  };

  const decrementMonth = () => {
    setDateRange({
      start: dateRange.start.clone().subtract(1, 'month'),
      end: dateRange.end.clone().subtract(1, 'month'),
    });
  };

  const setYear = (year: number) => {
    setDateRange({
      start: dateRange.start.clone().set('year', year).set('month', 0),
      end: dateRange.end.clone().set('year', year).set('month', 5),
    });
  };

  const getDisplayedMonths = useCallback(() => {
    const months: Moment[] = [];
    const start = dateRange.start.clone();

    while (
      dateRange.end > start ||
      start.format('M') === dateRange.end.format('M')
    ) {
      months.push(start.clone());
      start.add(1, 'month');
    }

    return months;
  }, [dateRange.end, dateRange.start]);

  const [displayedMonths, setDisplayedMonths] = useState<Moment[]>(
    getDisplayedMonths()
  );

  useEffect(() => {
    setDisplayedMonths(getDisplayedMonths());
  }, [dateRange, getDisplayedMonths]);

  return (
    <TableContext.Provider
      value={{
        dateRange,
        incrementMonth,
        decrementMonth,
        setYear,
        displayedMonths,
        setDateRange,
        billingMissions: billingMissions,
        setBillingMissions,
        mode: mode,
      }}
    >
      {children}
    </TableContext.Provider>
  );
}
