import { Box, StepIconProps } from '@mui/material';
import React from 'react';

const PolyCustomStepIcon = ({ active, completed, icon }: StepIconProps) => {
  return (
    <Box
      sx={{
        color: 'inherit',
        backgroundColor: 'secondary.main',
        width: '35px',
        height: ' 35px',
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
        ...((active || completed) && {
          border: '4px solid',
          borderColor: 'inherit',
        }),
      }}
    >
      {icon}
    </Box>
  );
};

export default PolyCustomStepIcon;
