import { Stack, Typography } from '@mui/material';
import {
  ActivitiesActivityBillingTypeChoices,
  ActivityNode,
} from 'generated/graphql';
import React from 'react';

import MissionTypeIcon from '../MissionTypeIcon';

interface MissionTitleProps {
  mission: ActivityNode;
}
export function MissionTitle({ mission }: MissionTitleProps) {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <MissionTypeIcon
        isTmContracts={
          mission.billingType === ActivitiesActivityBillingTypeChoices.Tm
        }
        sx={{ fontSize: '24px', fontWeight: 700 }}
        type={mission.type}
        tooltip={true}
      />
      <Typography variant="h2Bold">{mission.name}</Typography>
    </Stack>
  );
}
