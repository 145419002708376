import {
  AverageDailyRateCollaboratorNode,
  BillingBillStatusChoices,
  BillingPurchaseOrderCurrencyChoices,
  BillNode,
  PurchaseOrderTaskNode,
} from 'generated/graphql';
import _ from 'lodash';
import { totalFormat } from 'pages/ActivityPage/utils';
import { displayEmployee } from 'utils';

export const getFormatStringCollaboratorADR = (
  collaboratorADR?: AverageDailyRateCollaboratorNode | null,
  currency?: BillingPurchaseOrderCurrencyChoices
) => {
  if (!collaboratorADR) {
    return '';
  }
  return `${collaboratorADR.title + ' / '}${displayEmployee(
    collaboratorADR.collaborator?.firstName,
    collaboratorADR.collaborator?.lastName
  )} / ${totalFormat(collaboratorADR.dailyRate, currency)}`;
};

export const getBackFilter = (filter: string, order?: 'asc' | 'desc') => {
  filter = filter.replace(/([A-Z])/g, '_$1').trim();
  filter = filter.replace(/(\.)/g, '__').toLowerCase();
  return `${order === 'desc' ? '-' : ''}${filter}`;
};

export const getFormatStringTask = (
  task: PurchaseOrderTaskNode,
  currency?: BillingPurchaseOrderCurrencyChoices
) => {
  return `${task.name} / ${totalFormat(task.amount, currency)}`;
};

export const humanFileSize = (size: number) => {
  const index = Math.floor(Math.log(size) / Math.log(1024));
  const test = size / Math.pow(1024, index);
  return test.toFixed(2) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][index];
};

export interface AttachmentType {
  id: string;
  key: string;
  filename: string;
  file: undefined;
}

export interface AddressType {
  id: string;
  label: string;
}

export function convertStringToNumber(str: string): number {
  return Number.parseFloat(_.replace(str, /\s/g, '').replace(',', '.'));
}

export function isBillProformaStatus(bill: BillNode): boolean {
  return (
    bill.status === BillingBillStatusChoices.Draft ||
    bill.status === BillingBillStatusChoices.ToBill
  );
}

export const isBillAlreadyBilled = (bill: BillNode): boolean => {
  return !!bill.billNumber;
};
