import InfoIcon from '@mui/icons-material/Info';
import { Stack, Tooltip, Typography } from '@mui/material';
import PolyModal from 'components/MUIOverload/PolyDialog/PolyModal';
import {
  PurchaseOrderHistoryNode,
  useFetchHistoryOfPurchaseOrderLazyQuery,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'store';

import PurchaseOrderHistoryUnit from './PurchaseOrderHistoryUnit';

interface PoHistoryModalProps {
  open: boolean;
  onClose: () => void;
}

export default function PurchaseOrderHistoryModal({
  open,
  onClose,
}: PoHistoryModalProps) {
  const currentPurchaseOrders = useSelector(
    (state) => state.purchaseOrder.currentPurchaseOrder
  );
  const [poHistory, setPoHistory] = useState<PurchaseOrderHistoryNode[]>([]);
  const [fetchHistory] = useFetchHistoryOfPurchaseOrderLazyQuery({
    variables: {
      poId: currentPurchaseOrders.id,
    },
    onCompleted: (data) => {
      if (data.poHistory) {
        setPoHistory(data.poHistory as PurchaseOrderHistoryNode[]);
      } else {
        setPoHistory([]);
      }
    },
  });

  const modalTitle = (
    <>
      Historique du bon de commande
      {moment(currentPurchaseOrders.createdAt).isBefore(
        moment('2023-01-01')
      ) && (
        <Tooltip title="Ce BDC a été créé avant le début de l'historisation, les informations affichées ici peuvent donc être incomplètes">
          <InfoIcon sx={{ pt: '6px', pl: 2 }} fontSize={'small'} />
        </Tooltip>
      )}
    </>
  );

  useEffect(() => {
    if (open) {
      fetchHistory();
    }
  }, [open, fetchHistory]);

  return (
    <PolyModal
      maxWidth={'md'}
      open={open}
      onClose={onClose}
      disableEnforceFocus={true}
      secondaryButtonName={'Fermer'}
      polyDialogTitle={modalTitle}
    >
      <Stack
        sx={{ minWidth: '400px', minHeight: '250px', pt: 2, pl: 1, pr: 1 }}
      >
        {poHistory.length > 0 ? (
          _.map(poHistory, (history) => {
            return (
              <PurchaseOrderHistoryUnit
                key={`${history.date}-${history.field}`}
                historyNode={history as PurchaseOrderHistoryNode}
              />
            );
          })
        ) : (
          <Typography>Aucun historique à afficher</Typography>
        )}
      </Stack>
    </PolyModal>
  );
}
